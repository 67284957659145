// Lib
import { FC, useEffect, useState } from "react";
// Api
import { useGetKitchenChannelLinksQuery } from "rtkQuery/query/kitchenLocationsAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Types
import { KitchenChannelLink } from "types/kitchen";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { LocationsIcon, SearchBlackIcon } from "icons";
// Components
import { Loader, NoItemsContent } from "components";
import { Input } from "components/Form";
import { LocationItem } from "./components";
// Styled
import { Typography } from "styled/Typography";
import { ContentBox, FlexContainer } from "styled/Box";
import { Description, InputContainer } from "./styled";
import { useAppSelector } from "hooks/redux";
import { getActiveKitchen } from "rtkQuery/selectors";

export const KitchenBusyMode: FC = () => {
  const { isLargeMobile } = useViewport();
  const { openNotification } = useNotification();

  const kitchen = useAppSelector(getActiveKitchen);

  const [search, setSearch] = useState("");

  const { data, isFetching, error } = useGetKitchenChannelLinksQuery(
    { siteId: kitchen?.id },
    {
      skip: !kitchen?.id,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const getSearched = (): KitchenChannelLink[] | [] => {
    if (!data) return [];
    if (!search) return data;

    return data.filter(channel => channel.name.includes(search));
  };

  const searchedItems = getSearched();

  return (
    <>
      {isFetching && <Loader />}

      <FlexContainer
        $fullwidth
        $column={isLargeMobile}
        $align={isLargeMobile ? "flex-start" : "center"}
        $justify="space-between"
        $gap={16}
        $margin="0 0 24px 0"
      >
        <FlexContainer $column $align="flex-start" $gap={4}>
          <Typography.H1>FM City</Typography.H1>

          <Description>All your store are opened</Description>
        </FlexContainer>

        <InputContainer $fullWidth={isLargeMobile}>
          <Input
            prefix={<SearchBlackIcon />}
            placeholder="Search store"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </InputContainer>
      </FlexContainer>

      <FlexContainer $fullwidth $column $gap={16}>
        {!isFetching &&
          !error &&
          !!searchedItems?.length &&
          searchedItems?.map(
            ({
              id,
              name,
              status,
              simphonyRevenuCenterId,
            }: KitchenChannelLink) => (
              <LocationItem
                key={id}
                id={id}
                name={name}
                status={status}
                simphonyRevenuCenterId={simphonyRevenuCenterId}
              />
            ),
          )}

        {!isFetching && !searchedItems?.length && (
          <ContentBox>
            <NoItemsContent
              Icon={LocationsIcon}
              message={"No kitchens to show"}
            />
          </ContentBox>
        )}
      </FlexContainer>
    </>
  );
};
