export const columns = [
  {
    title: "",
    dataIndex: "imageUrl",
    key: "imageUrl",
    width: 64,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "PLU",
    dataIndex: "plu",
    key: "plu",
    align: "right" as const,
    sorter: true,
    width: 150,
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    align: "right" as const,
    sorter: true,
    width: 150,
  },
];
