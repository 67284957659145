// Lib
import { FC, useEffect } from "react";
// Api
// Hooks
import { useExport, useNotification, useTable, useViewport } from "hooks";
import { useAppSelector } from "hooks/redux";
// Actions
// Selectors
import { getAnalyticsFilters } from "rtkQuery/selectors";
// Types
import { ETable } from "types/tableFilters";
// Theme
import { theme } from "theme";
// Constants
// Helpers
import { createTableData } from "./helpers";
import { generateDates } from "../../helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { FileUploadIcon } from "icons";
// Layouts
// Components
import { Export, Pagination, Table } from "components";
import { StatisticsSummary } from "../../components";
import { Filters } from "../../components/Filters";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import { PageTitleContainer, PageWrapper, StaticsGrid } from "./styled";

import { columns } from "./config";
import { mokedOrdersData } from "../../mockData";

export const AnalyticsMenuItems: FC = () => {
  const { downloadCSV } = useExport();

  const { isDesktop } = useViewport();

  const { openNotification } = useNotification();

  const {
    search,
    setSearch,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  } = useTable({ name: ETable.AnalyticsMenuItems });

  const { dateFilter, location } = useAppSelector(getAnalyticsFilters);

  const tableData = createTableData({
    dateFilter,
    location,
    data: mokedOrdersData,
  });

  useEffect(() => {
    console.log(generateDates(dateFilter));
  }, [dateFilter]);

  const getExportData = async (): Promise<Record<string, unknown>[]> => {
    try {
      //request here
      const data = [];

      return data;
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const dropDownMenuItems = [
    {
      key: "3",
      label: "Export",
      onClick: () =>
        downloadCSV({
          fileName: "product_sales",
          isDataLoading: false,
          columns: columns,
          getExportData: getExportData,
        }),
    },
  ];

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer
        $fullwidth
        $column
        $grow={1}
        $padding={isDesktop && "0 0 32px"}
      >
        <PageTitleContainer>
          <Typography.H1>Menu Items Analytics</Typography.H1>

          <Filters skipOrderStatus />
        </PageTitleContainer>

        <StaticsGrid>
          <StatisticsSummary title="Canceled Order" text={2} />

          <StatisticsSummary title="Total snoozed count" text={2} />

          <StatisticsSummary title="Total snoozed time" text={"27 hours"} />
        </StaticsGrid>

        <Table
          rowKey={"id"}
          dataSource={tableData}
          // dataSource={menuItemsAnalytics}
          columns={columns}
          header={{
            leftTitle: "Product Sales",
            search: { value: search, setValue: setSearch },
            suffix: isDesktop ? (
              <Export
                type="Base"
                fileName="product_sales"
                isLoading={false}
                isDataLoading={false}
                isDisabled={false}
                columns={columns}
                getExportData={getExportData}
              />
            ) : (
              <Button.SquaredIcon
                icon={<FileUploadIcon fill={theme.color.text.tertiary} />}
                onClick={() =>
                  downloadCSV({
                    fileName: "product_sales",
                    isDataLoading: false,
                    columns: columns,
                    getExportData: getExportData,
                  })
                }
              />
            ),
          }}
        />
      </FlexContainer>

      <Pagination
        onPage
        padding="12px 16px"
        pageSize={pageSize}
        showSizeChanger={!isDesktop}
        currentPage={currentPage}
        setPageSize={setPageSize}
        totalItems={tableData.length}
        setCurrentPage={setCurrentPage}
      />
    </PageWrapper>
  );
};
