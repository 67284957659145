import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const AddCircleIcon: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_8329_6466)">
      <path
        d="M7.99998 1.33301C4.31998 1.33301 1.33331 4.31967 1.33331 7.99967C1.33331 11.6797 4.31998 14.6663 7.99998 14.6663C11.68 14.6663 14.6666 11.6797 14.6666 7.99967C14.6666 4.31967 11.68 1.33301 7.99998 1.33301ZM10.6666 8.66634H8.66665V10.6663C8.66665 11.033 8.36665 11.333 7.99998 11.333C7.63331 11.333 7.33331 11.033 7.33331 10.6663V8.66634H5.33331C4.96665 8.66634 4.66665 8.36634 4.66665 7.99967C4.66665 7.63301 4.96665 7.33301 5.33331 7.33301H7.33331V5.33301C7.33331 4.96634 7.63331 4.66634 7.99998 4.66634C8.36665 4.66634 8.66665 4.96634 8.66665 5.33301V7.33301H10.6666C11.0333 7.33301 11.3333 7.63301 11.3333 7.99967C11.3333 8.36634 11.0333 8.66634 10.6666 8.66634Z"
        fill={fill || "black"}
      />
    </g>
    <defs>
      <clipPath id="clip0_8329_6466">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
