// Lib
import { FC, memo } from "react";
// Types
import { ChartLegendConfig } from "../LineChart/types";
//Icons
import { LegendIcon } from "icons";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";

interface LegendProps {
  legendConfig: ChartLegendConfig[];
}

export const Legend: FC<LegendProps> = memo(({ legendConfig }) => {
  return (
    <FlexContainer $fullwidth $gap={16} $wrap $margin="0 0 24px 0">
      {!!legendConfig?.length &&
        legendConfig.map(legend => (
          <FlexContainer key={legend.title} $align="center" $gap={8}>
            <LegendIcon fill={legend.color} />

            <Typography.Title
              style={legend.textColor && { color: legend.textColor }}
            >
              {legend.title}
            </Typography.Title>
          </FlexContainer>
        ))}
    </FlexContainer>
  );
});

Legend.displayName = "Legend";
