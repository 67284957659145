import { DiningIcon } from "icons";

import { ButtonLink } from "components";

import { FlexContainer } from "styled/Box";
import { MockedImage, TableImage } from "./styled";

export const createTableColumns = ({ columns, actions }) => {
  const columnData = columns.map((column, i) => {
    if (column.key === "image" || column.key === "imageUrl") {
      return {
        ...column,
        render: (src: string) =>
          src?.length ? (
            <TableImage key={i} src={src} />
          ) : (
            <MockedImage key={i}>
              <DiningIcon />
            </MockedImage>
          ),
      };
    }
    return column;
  });

  if (!actions) {
    return columnData;
  }

  const getActionsWidth = () => {
    const withTitle = actions.find(el => Boolean(el?.title));

    if (!withTitle) {
      return actions?.length * 30;
    }

    return undefined;
  };

  const tableActions = actions?.filter(f => !f?.disabled)?.length && [
    {
      title: "",
      key: "action",
      width: getActionsWidth(),
      align: "right" as const,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (_: any, record: any) => {
        return (
          <FlexContainer $gap={16}>
            {actions
              ?.filter(f => !f?.disabled && !record[f?.disabledIfFieldIsTrue])
              ?.map(({ title, Icon, onClick, type, loadingId, ...act }, i) => {
                const isDisabled = act?.disableFunction
                  ? act.disableFunction(record)
                  : false;

                return (
                  <ButtonLink
                    key={title + i}
                    loading={record?.id === loadingId}
                    icon={Icon ? <Icon /> : null}
                    disabled={isDisabled}
                    title={title}
                    danger={title === "Delete" || type === "Delete"}
                    grey={type === "Grey"}
                    onClick={() => onClick(record)}
                  />
                );
              })}
          </FlexContainer>
        );
      },
    },
  ];

  if (!tableActions?.length) return columnData;

  return columnData.concat(tableActions);
};
