import { styled } from "styled-components";
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { theme } from "theme";

export const BackIcon = styled(Button.SquaredIcon)`
  transform: rotate(90deg);
`;

export const ForwardIcon = styled(Button.SquaredIcon)`
  transform: rotate(270deg);
`;

export const TableColoredCell = styled.div<{
  $high: boolean;
  $low: boolean;
  $value: number;
}>`
  min-width: 70px;
  min-height: 40px;

  background: rgba(240, 242, 241, 1);
  color: rgba(3, 19, 6, 1);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10;
  border-radius: 8px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  ${({ $high }) => $high && `background: rgba(43, 97, 64, 1); color: white;`}
  ${({ $low, $value }) =>
    $low && `background: rgba(125, 217, 168, ${$value * 0.07}); `}
`;

export const TimeTitle = styled.div`
  color: rgba(100, 105, 101, 1);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const MobileTableDateWrapper = styled(FlexContainer)`
  padding: 16px 16px 16px;
  border-radius: 6px;
  background-color: ${theme.color.white};
  color: ${theme.color.white};
  box-shadow: ${theme.shadow.table};
`;
