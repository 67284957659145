import { TabsProps } from "antd/es/tabs";
import { dateTransform } from "helpers/dataHelpers";

export const items: TabsProps["items"] = [
  {
    key: "",
    label: `All`,
  },
  {
    key: "in_stock",
    label: `Available`,
  },
  {
    key: "out_of_stock",
    label: `Out of Stock`,
  },
];

export const columns = [
  {
    title: "",
    dataIndex: "imageUrl",
    key: "imageUrl",
    width: 40,
  },
  {
    title: "Name",
    dataIndex: "product",
    key: "productName",
    sorter: true,
  },
  {
    title: "Stock",
    dataIndex: "stockQuantity",
    key: "stock",
    width: 104,
    sorter: true,
  },

  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: 180,
    sorter: true,
    render: (date: string) => dateTransform({ date }),
  },
];
