import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const DiningIcon: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Dining">
      <g id="Group 653">
        <path
          id="Ellipse 39"
          d="M20.9 16.3004C20.9 13.94 19.9623 11.6762 18.2933 10.0071C16.6242 8.33807 14.3604 7.40039 12 7.40039C9.63958 7.40039 7.37583 8.33807 5.70676 10.0071C4.03768 11.6762 3.10001 13.94 3.10001 16.3004L12 16.3004H20.9Z"
          fill={fill || "#646965"}
        />
        <path
          id="Rectangle 442"
          d="M2.3 17.4C2.3 17.1791 2.47909 17 2.7 17H21.3C21.5209 17 21.7 17.1791 21.7 17.4V17.7C21.7 19.0255 20.6255 20.1 19.3 20.1H4.7C3.37452 20.1 2.3 19.0255 2.3 17.7V17.4Z"
          fill={fill || "#646965"}
        />
        <path
          id="Rectangle 443"
          d="M10.5 6.45039C10.5 5.59435 11.194 4.90039 12.05 4.90039V4.90039C12.906 4.90039 13.6 5.59435 13.6 6.45039V6.80039C13.6 6.80039 12.6553 6.65039 12.05 6.65039C11.4447 6.65039 10.5 6.80039 10.5 6.80039V6.45039Z"
          fill={fill || "#646965"}
        />
      </g>
    </g>
  </svg>
);
