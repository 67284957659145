// Lib
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import {
  ActiveKitchen,
  GetKitchenSettingsResponse,
  KitchenNotificationSoundDurationOptions,
  KitchenNotitficationSoundOptions,
  KitchenOrderFilterValues,
  KitchenSetting,
  KitchenState,
  OrderTransitionTimeOptions,
  PreparationTimeOptions,
} from "types/kitchen";

const initialState: KitchenState = {
  activeKitchen: null,
  filters: {
    orderBy: KitchenOrderFilterValues.PICKUP_TIME,
    orderDirection: KitchenOrderFilterValues.DESC,
  },
  settings: {
    automaticallyAcceptOrder: false,
    defaultPreparationTime: PreparationTimeOptions.PLUS_15_MIN,
    orderTransitionTime: OrderTransitionTimeOptions.PLUS_30_MIN,
    automaticallyFinalizeOrders: false,
    allowIndefiniteSnoozing: false,
    displayItemPrices: true,
    loadOrdersManually: false,
    allowCancelingOrders: true,
    displayCourierName: true,
    notificationPlaySound: true,
    notificationSoundDuration:
      KitchenNotificationSoundDurationOptions.FIVE_SEСONDS,
    notificationSound: KitchenNotitficationSoundOptions.ALERT,
    defaultDeliveryTime: 0,
    manualHandleDelivery: false,
    snoozeIfOutOfStock: false,
    unSnoozeIfStock: false,
  },
};

export const kitchenSlice = createSlice({
  name: "kitchen",
  initialState,
  reducers: {
    setActiveKitchen: (state, action: PayloadAction<ActiveKitchen>) => {
      state.activeKitchen = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<KitchenOrderFilterValues>) => {
      state.filters = { ...state.filters, orderBy: action.payload };
    },
    setDirection: (state, action: PayloadAction<KitchenOrderFilterValues>) => {
      state.filters = { ...state.filters, orderDirection: action.payload };
    },
    setAllSettings: (
      state,
      action: PayloadAction<GetKitchenSettingsResponse>,
    ) => {
      state.settings = {
        automaticallyAcceptOrder: action.payload.automaticallyAcceptOrder,
        defaultPreparationTime: action.payload.defaultPreparationTime,
        orderTransitionTime: action.payload.orderTransitionTime,
        automaticallyFinalizeOrders: action.payload.automaticallyFinalizeOrders,
        allowIndefiniteSnoozing: action.payload.allowIndefiniteSnoozing,
        displayItemPrices: action.payload.displayItemPrices,
        loadOrdersManually: action.payload.loadOrdersManually,
        allowCancelingOrders: action.payload.allowCancelingOrders,
        displayCourierName: action.payload.displayCourierName,
        notificationPlaySound: action.payload.notificationPlaySound,
        notificationSoundDuration: action.payload.notificationSoundDuration,
        notificationSound: action.payload.notificationSound,
        defaultDeliveryTime: action.payload.defaultDeliveryTime,
        manualHandleDelivery: action.payload.manualHandleDelivery,
        snoozeIfOutOfStock: action.payload.snoozeIfOutOfStock,
        unSnoozeIfStock: action.payload.unSnoozeIfStock,
      };
    },
    setOneSetting: (state, action: PayloadAction<KitchenSetting>) => {
      state.settings = {
        ...state.settings,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  setActiveKitchen,
  setOrderBy,
  setDirection,
  setOneSetting,
  setAllSettings,
} = kitchenSlice.actions;
export const kitchenSliceReducer = kitchenSlice.reducer;
