// Lib
import { FC, ReactNode, useCallback } from "react";
import { BlockerFunction, useBlocker, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
// Selectors
import { isUserSideBarOpenSelector } from "rtkQuery/selectors/ui";
// Types
import { SidebarMenuItemType } from "types/common";
// Icons
import { AlarmIcon } from "icons";
// Components
import { ConfirmDialog } from "components";
import { Header, MobileHeader, Navbar } from "./components";
// Styled
import {
  AntDLayout,
  ContentContainer,
  MobileContentContainer,
  Wrapper,
} from "./styled";
import { useViewport } from "hooks";

interface LayoutProps {
  sidebarMenuItems: SidebarMenuItemType[];
  children: ReactNode;
}

export const SIDEBAR_WIDTH_OPEN = 248;
export const SIDEBAR_WIDTH_COLLAPSED = 80;

export const Layout: FC<LayoutProps> = ({ sidebarMenuItems, children }) => {
  const collapsed = useSelector(isUserSideBarOpenSelector);

  const { isDesktop } = useViewport();

  const [searchParams] = useSearchParams();

  const isUnsaved = searchParams.get("unsaved");

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      isUnsaved === "true" &&
      currentLocation.pathname !== nextLocation.pathname,
    [isUnsaved],
  );

  const blocker = useBlocker(shouldBlock);

  return (
    <>
      {isDesktop ? (
        <Wrapper>
          <Header />

          <AntDLayout hasSider>
            <Navbar collapsed={collapsed} sidebarMenuItems={sidebarMenuItems} />
            <AntDLayout
              className="site-layout"
              style={{
                marginLeft: collapsed
                  ? SIDEBAR_WIDTH_COLLAPSED
                  : SIDEBAR_WIDTH_OPEN,
                transition: "margin 0.2s",
              }}
            >
              <ContentContainer>{children}</ContentContainer>
            </AntDLayout>
          </AntDLayout>
        </Wrapper>
      ) : (
        <div>
          <MobileHeader
            collapsed={collapsed}
            sidebarMenuItems={sidebarMenuItems}
          />
          <MobileContentContainer>{children}</MobileContentContainer>
        </div>
      )}

      <ConfirmDialog
        open={blocker.state === "blocked"}
        Icon={AlarmIcon}
        message="You have unsaved changes"
        description="Are you sure to continue this action?"
        onCancel={() => blocker.reset?.()}
        firstCTAButton={{
          title: "Leave",
          status: "danger",
          onClick: () => blocker.proceed?.(),
        }}
      />
    </>
  );
};
