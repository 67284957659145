// Lib
import { FC } from "react";
import { Image } from "antd";
// Components
import { Status } from "components";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { ImageContainer } from "./styled";

import fallback from "images/channelPlaceholder.png";

interface ChannelProps {
  title: string;
  status?: "success" | "warning" | "neutral" | "danger";
  statusType?: "primary" | "outlined" | "borderless";
  statusText?: string;
}

export const Channel: FC<ChannelProps> = ({
  title,
  status,
  statusType,
  statusText,
}) => {
  return (
    <FlexContainer $gap={16}>
      <ImageContainer>
        <Image src={fallback} preview={false} />
      </ImageContainer>

      <FlexContainer $column $gap={4}>
        <Typography.Title>{title}</Typography.Title>

        {statusText && (
          <Status type={statusType} text={statusText} status={status} />
        )}
      </FlexContainer>
    </FlexContainer>
  );
};
