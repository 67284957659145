// Lib
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api
import {
  useDeleteLocationMutation,
  useGetLocationsQuery,
  useUpdateChannelLinkMutation,
} from "rtkQuery/query/locationsAPI";
import { useLazySyncProductsQuery } from "rtkQuery/query/productsAPI";
// Hooks
import { useNotification, usePermissions, useViewport } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { ChannelLink, Location as LocationType } from "types/locations";
// Constants
import { ADMIN_ROUTES } from "consts";
// Icons
import { RightBurgerMenuIcon, TrashIcon } from "icons";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Accordion, ConfirmDialog, DropDown, Loader } from "components";
import { LocationItemBody, LocationItemTitle } from "./components";
// Styled
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import { PageButtonsContainer } from "styled/Box";
import { PageTitleContainer, Plus, Refresh } from "./styled";

export const Location: FC = () => {
  const { isDesktop } = useViewport();

  const navigate = useNavigate();

  const companyId = useAppSelector(getUserCompanyId);

  const { canCreateSite, canReadSite } = usePermissions();

  const { openNotification } = useNotification();

  const [deleteLocationModal, setDeleteLocationModal] = useState<
    LocationType | false
  >(false);

  const [sync, { isLoading: isSyncLoading }] = useLazySyncProductsQuery();
  const [remove, { isLoading: isLocationDeleteLoading }] =
    useDeleteLocationMutation();

  const {
    data,
    isFetching: isGetLocationsLoading,
    isError,
    error,
    refetch,
  } = useGetLocationsQuery(
    {
      companyId,
    },
    {
      skip: !companyId,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    },
  );

  useEffect(() => {
    if (isError) {
      errorHandler({ error, openNotification });
    }
  }, [isError, error]);

  const [updateLink, { isLoading: isUpdateLocationLoading }] =
    useUpdateChannelLinkMutation();

  const handleLocationCreate = () => {
    navigate(ADMIN_ROUTES.LOCATION_CREATE.path);
  };

  const updateChannelLinks = async ({
    data,
  }: {
    id: string;
    data: ChannelLink[];
  }) => {
    try {
      await Promise.all(
        data.map(
          async el =>
            await updateLink({
              channelId: el.id,
              siteId: el.siteId,
              data: {
                name: el.name,
                status: el.status,
                simphonyRevenuCenterId: el.simphonyRevenuCenterId,
              },
            }).unwrap(),
        ),
      );

      openNotification({ message: "Channel links updated" });
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleSyncProducts = async () => {
    try {
      await sync({ companyId }).unwrap();
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleDeleteLocation = (id: string) => {
    const location = data?.find(location => location.id === id);

    if (!location) {
      return;
    }

    setDeleteLocationModal(location);
  };

  const handleDeleteLocationConfirm = async () => {
    if (!deleteLocationModal) {
      return;
    }

    try {
      await remove({ id: deleteLocationModal.id }).unwrap();

      openNotification({ message: "Location successfuly deleted" });
      setDeleteLocationModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const items = data?.map(({ id, name, channels, isHomeSite }) => ({
    key: id,
    showArrow: canReadSite,
    label: (
      <LocationItemTitle
        title={name}
        description={`${channels?.length} channel links`}
        main={isHomeSite}
      />
    ),
    children: canReadSite ? (
      <LocationItemBody
        updateChannelLinks={updateChannelLinks}
        channelLinks={channels}
        locationId={id}
        locationName={name}
        handleDeleteLocation={() => handleDeleteLocation(id)}
      />
    ) : null,
  }));

  const dropDownMenuItems = [
    canCreateSite && {
      key: "1",
      label: "Add Location",
      onClick: handleLocationCreate,
    },
    {
      key: "2",
      label: "Sync All Products",
      onClick: handleSyncProducts,
    },
    {
      key: "3",
      label: "Refresh",
      onClick: refetch,
    },
  ];

  const isLoading = isUpdateLocationLoading || isGetLocationsLoading;

  return (
    <>
      {isLoading && <Loader />}

      <PageTitleContainer>
        <Typography.H1>Location</Typography.H1>

        {isDesktop ? (
          <PageButtonsContainer>
            {canCreateSite && (
              <Button.Heading
                type="primary"
                icon={<Plus />}
                onClick={handleLocationCreate}
              >
                Add Location
              </Button.Heading>
            )}

            <Button.Heading
              icon={<Refresh />}
              loading={isSyncLoading}
              onClick={handleSyncProducts}
            >
              Sync All Products
            </Button.Heading>

            <Button.Heading icon={<Refresh />} onClick={refetch}>
              Refresh
            </Button.Heading>
          </PageButtonsContainer>
        ) : (
          <DropDown trigger={["click"]} items={dropDownMenuItems}>
            <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
          </DropDown>
        )}
      </PageTitleContainer>

      <div>
        <Accordion disabled={!canReadSite} items={items} />
      </div>

      <ConfirmDialog
        open={!!deleteLocationModal}
        Icon={TrashIcon}
        message={
          !!deleteLocationModal && `${deleteLocationModal.name} will be deleted`
        }
        description="Are you sure to continue this action?"
        onCancel={() => setDeleteLocationModal(false)}
        firstCTAButton={{
          title: "Delete",
          status: "danger",
          loading: isLocationDeleteLoading,
          onClick: handleDeleteLocationConfirm,
        }}
      />
    </>
  );
};
