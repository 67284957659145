// Lib
import { FC } from "react";
import { Popover } from "antd";
// Hooks
import { useViewport } from "hooks";
import { useAppDispatch, useAppSelector } from "hooks/redux";
// Actions
import { setDirection, setOrderBy } from "rtkQuery/slices";
// Selectors
import { getKitchenOrderFilters } from "rtkQuery/selectors";
// Types
import {
  KitchenOrderFilterLabels,
  KitchenOrderFilterValues,
} from "types/kitchen";
// Icons
import { TuneBlackFilterIcon } from "icons";
// Components
import { DropDown } from "components";
// Styled
import { Button as MyButton } from "styled/Buttons";
import { FlexContainer } from "styled/Box";
import { OrderSegmentedButton, Title, Wrapper } from "./styled";

export const tabOptionsFirst = [
  {
    label: KitchenOrderFilterLabels[KitchenOrderFilterValues.PICKUP_TIME],
    value: KitchenOrderFilterValues.PICKUP_TIME,
  },
  {
    label: KitchenOrderFilterLabels[KitchenOrderFilterValues.CREATED_AT],
    value: KitchenOrderFilterValues.CREATED_AT,
  },
];

export const tabOptionsSecond = [
  {
    label: KitchenOrderFilterLabels[KitchenOrderFilterValues.ASC],
    value: KitchenOrderFilterValues.ASC,
  },
  {
    label: KitchenOrderFilterLabels[KitchenOrderFilterValues.DESC],
    value: KitchenOrderFilterValues.DESC,
  },
];

export const SortFilter: FC = () => {
  const dispatch = useAppDispatch();

  const { isMobile } = useViewport();

  const { orderBy, orderDirection } = useAppSelector(getKitchenOrderFilters);

  const renderDropdownContent = () => {
    return (
      <>
        <Title>SORT ORDERS</Title>

        <OrderSegmentedButton
          value={orderBy}
          setValue={(newVal: KitchenOrderFilterValues) =>
            dispatch(setOrderBy(newVal))
          }
          options={tabOptionsFirst}
        />
        <OrderSegmentedButton
          value={orderDirection}
          setValue={(newVal: KitchenOrderFilterValues) =>
            dispatch(setDirection(newVal))
          }
          options={tabOptionsSecond}
        />
      </>
    );
  };

  if (isMobile) {
    return (
      <Popover
        arrow={false}
        trigger="click"
        content={
          <FlexContainer $column $gap={4} $padding="8px 8px 4px">
            {renderDropdownContent()}
          </FlexContainer>
        }
      >
        <MyButton.SquaredIcon icon={<TuneBlackFilterIcon />} />
      </Popover>
    );
  }

  return (
    <DropDown
      placement="bottomRight"
      items={[{ label: "-", key: "-" }]}
      trigger={["click"]}
      dropdownRender={() => (
        <Wrapper $column $gap={4}>
          {renderDropdownContent()}
        </Wrapper>
      )}
    >
      <MyButton.SquaredIcon icon={<TuneBlackFilterIcon />} />
    </DropDown>
  );
};
