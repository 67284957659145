import { Permission } from "consts";
import { FC, ReactNode } from "react";

export interface ScheduledEvent {
  id: string;
  dataId: string;
  title: string;
  start: string;
  end: string;
}

export enum MenuTypesOptions {
  Delivery = "DELIVERY",
  Cold = "COLD",
}

export enum ModifierType {
  Addon = "add-on",
  Modifier = "modifier",
}

export enum StatusType {
  Active = "ACTIVE",
  Suspended = "SUSPENDED",
}

export type UrlQueryParams = Record<
  string,
  string | string[] | number | boolean | undefined | null
>;

export type Order = "ASC" | "DESC" | "";

export interface SvgIconConstituentValues {
  fill?: string;
  strokeColor?: string;
  strokeWidth?: string;
  strokeWidth2?: string;
  strokeWidth3?: string;
  strokeFill?: string;
  fillColor?: string;
  fillColor2?: string;
  fillColor3?: string;
  fillColor4?: string;
  fillColor5?: string;
  fillColor6?: string;
  fillColor7?: string;
  imageWidth?: string;
  imageHeight?: string;
  width?: string;
  height?: string;
  rotateCenter?: number;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export interface SidebarMenuItemType {
  Icon: FC;
  key: string;
  label: ReactNode;
  path?: string;
  type: "link" | "list";
  access?: boolean | Permission[];
  list?: {
    key: string;
    label: ReactNode;
    path: string;
    access?: boolean | Permission[];
  }[];
}

export interface SidebarMenuItemsType {
  loop: SidebarMenuItemType[];
  kitchenPwa: SidebarMenuItemType[];
}

export type DayType =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export interface ImageUploadItem {
  file: Blob;
  image: string | ArrayBuffer;
}

export interface TableAction {
  title: string;
  Icon: FC<SvgIconConstituentValues>;
  disabled?: boolean;
  disabledIfFieldIsTrue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disableFunction?: (record: any) => boolean;
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (arg: any) => void;
}

export enum EFilterDateValue {
  TODAY = "today",
  YESTERDAY = "yesterday",
  IN_THE_LAST_7_DAYS = "inTheLastSevenDays",
  IN_THE_LAST_30_DAYS = "inTheLastThirtyDays",
  IN_THE_LAST_90_DAYS = "inTheLastNinetyDays",
  IN_THE_12_MONTHS = "inTheTwelveMonths",
  CUSTOM = "custom",
}

export enum EFilterDateLabel {
  today = "Today",
  yesterday = "Yesterday",
  inTheLastSevenDays = "In the last 7 days",
  inTheLastThirtyDays = "In the last 30 days",
  inTheLastNinetyDays = "In the last 90 days",
  inTheTwelveMonths = "In the 12 months",
  custom = "Custom",
}
