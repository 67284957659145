import { useEffect, useState } from "react";

import alert from "./../sounds/cashPurchase.mp3";

interface useAudioConfig {
  url?: string;
  duration?: number;
}

export const useAudio = (config?: useAudioConfig) => {
  const [audio] = useState(new Audio(config?.url || alert));

  const [isPlaying, setIsPlaying] = useState(false);

  const duration = config?.duration || 3000;

  useEffect(() => {
    if (isPlaying) {
      audio.loop = true;
      audio.play();

      const timeout = setTimeout(() => {
        audio.pause();
        audio.currentTime = 0;
        setIsPlaying(false);
      }, duration);

      return () => {
        clearTimeout(timeout);
      };
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [isPlaying]);

  const toggle = () => setIsPlaying(!isPlaying);

  const play = () => {
    if (isPlaying) {
      stop();
    }
    setIsPlaying(true);
  };

  const stop = () => {
    setIsPlaying(false);
  };

  return { isPlaying, toggle, play, stop };
};
