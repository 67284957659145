// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  CreateMenuScheduleRequest,
  CreateMenuScheduleResponse,
  GetMenusScheduleCalendarRequest,
  GetMenusScheduleListRequest,
  MenusSchedule,
  MenusScheduleCalendarEvent,
  UpdateMenuScheduleRequest,
} from "types/menuScheduling";
// Helpers
import { menuScheduleUrlBuilder } from "./urlBuilder/menuSchedule";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";

export const menuSchedulingAPI = createApi({
  reducerPath: "menuSchedulingAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["menu-scheduling"],
  endpoints: build => ({
    getMenusScheduleCalendar: build.query<
      MenusScheduleCalendarEvent[],
      GetMenusScheduleCalendarRequest
    >({
      query: ({ companyId, query }) => {
        return {
          url: menuScheduleUrlBuilder.getMenusScheduleCalendar({
            companyId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["menu-scheduling"],
    }),
    getMenusScheduleList: build.query<
      MenusSchedule[],
      GetMenusScheduleListRequest
    >({
      query: ({ companyId, query }) => {
        return {
          url: menuScheduleUrlBuilder.getMenusScheduleList({
            companyId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["menu-scheduling"],
    }),
    getMenuSchedule: build.query<any, any>({
      query: ({ id }) => {
        return {
          url: menuScheduleUrlBuilder.getMenuSchedule({ id }),
          method: "GET",
        };
      },
      providesTags: ["menu-scheduling"],
    }),
    createMenuSchedule: build.mutation<
      CreateMenuScheduleResponse,
      CreateMenuScheduleRequest
    >({
      query: ({ companyId, ...data }) => {
        return {
          url: menuScheduleUrlBuilder.createMenuSchedule({ companyId }),
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["menu-scheduling"],
    }),
    updateMenuSchedule: build.mutation<any, UpdateMenuScheduleRequest>({
      query: ({ id, ...data }) => {
        return {
          url: menuScheduleUrlBuilder.updateMenuSchedule({ id }),
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: ["menu-scheduling"],
    }),
    deleteMenuSchedule: build.mutation<void, { id: string }>({
      query: ({ id }) => {
        return {
          url: menuScheduleUrlBuilder.deleteMenuSchedule({ id }),
          method: "DELETE",
        };
      },
      invalidatesTags: ["menu-scheduling"],
    }),
  }),
});

export const {
  useGetMenusScheduleCalendarQuery,
  useGetMenusScheduleListQuery,
  useGetMenuScheduleQuery,
  useCreateMenuScheduleMutation,
  useUpdateMenuScheduleMutation,
  useDeleteMenuScheduleMutation,
} = menuSchedulingAPI;
