// Lib
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import { userState } from "types/user";

const initialState: userState = {
  sub: null,
  email: null,
  roleId: null,
  homeSiteId: null,
  companyId: null,
  permissions: null,
  sites: null,
  iat: null,
  exp: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<userState>) => {
      state.sub = action.payload.sub;
      state.email = action.payload.email;
      state.roleId = action.payload.roleId;
      state.homeSiteId = action.payload.homeSiteId;
      state.companyId = action.payload.companyId;
      state.permissions = action.payload.permissions;
      state.sites = action.payload.sites;
      state.iat = action.payload.iat;
      state.exp = action.payload.exp;
    },
  },
});

export const { setUser } = userSlice.actions;
export const userSliceReducer = userSlice.reducer;
