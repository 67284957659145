import styled from "styled-components";
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { theme } from "theme";

export const PageWrapper = styled(FlexContainer)`
  min-height: 100%;
`;

export const SelectedItems = styled(Typography.Description)`
  color: ${theme.color.text.primary2};
`;

export const SelectContainer = styled.div`
  min-width: 200px;
`;
