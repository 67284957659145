import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

const schema = object({
  name: string()
    .trim()
    .min(3, "Product name must be more than 3 characters")
    .max(50, "Product name must be less than 50 characters")
    .required("Product name is required"),
  kcal: string().nullable(),
  plu: string().nullable().trim().required("PLU is required"),
  price: string().nullable(),
  tax: string().nullable(),
  labels: string(),
  // kcal: string().nullable().trim().required("Total Calorie is required"),
  // plu: string().nullable().trim().required("PLU is required"),
  // price: string().nullable().trim().required("Price is required"),
  // tax: string().nullable().trim().required("Tax is required"),
}).required();

export const resolver = yupResolver(schema);
