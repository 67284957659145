export enum AdditionalFilter {
  Users = "users",
}

export interface FilterCustomDate {
  date?: { start: string; end: string };
  time?: { start: string; end: string };
}

export interface FilterStateItem {
  name: string;
  value: string | string[];
  subValue?: null | FilterCustomDate;
  field: FilterField;
}

export enum FilterField {
  SELECT = "select",
  MULTISELECT = "multiselect",
  INPUT = "input",
  INPUT_SELECT = "input_select",
  BOOLEAN = "boolean",
  DATE_RANGE = "date_range",
  DATE = "date",
  MULTISELECT_SINGLE_DATE = "multiselect_single_date",
  USER_SELECT = "user_select",
}

export interface FilterFieldDropdownOption {
  type: string;
  label: string;
}
export type FieldDateRangeType = {
  field: FilterField.DATE_RANGE;
  options: FilterFieldDropdownOption[];
};

export type FieldCustomSingleDateType = {
  field: FilterField.MULTISELECT_SINGLE_DATE;
  options: FilterFieldDropdownOption[];
};

export type FieldDateType = {
  field: FilterField.DATE;
};

export type FieldInputType = {
  field: FilterField.INPUT;
};

export type FieldInputSelectType = {
  field: FilterField.INPUT_SELECT;
};

export type FieldSelectType = {
  field: FilterField.SELECT;
  options: FilterFieldDropdownOption[];
};

export type FieldMultiselectType = {
  field: FilterField.MULTISELECT;
  options: FilterFieldDropdownOption[];
};

export type FieldBooleanType = {
  field: FilterField.BOOLEAN;
};

export type FieldUserSelectType = {
  field: FilterField.USER_SELECT;
};
export type FilterOptionType = {
  // key: OrderFilterTypes;
  key: string;
  icon?: JSX.Element;
  label: string;
};

export type FilterOption = FilterOptionType &
  (
    | FieldInputType
    | FieldSelectType
    | FieldMultiselectType
    | FieldBooleanType
    | FieldDateRangeType
    | FieldCustomSingleDateType
    | FieldDateType
    | FieldInputSelectType
    | FieldUserSelectType
  );
export type TableFilterState = FilterStateItem[] | [];

export enum ETable {
  Orders = "orders",
  CustomerOrders = "customerOrders",
  Customers = "customers",
  OperationReports = "operationReports",
  InventoryHistory = "inventoryHistory",
  MenuScheduling = "MenuScheduling",
  SnoozedItemsModal = "SnoozedItemsModal",
  Tags = "Tags",
  Modifiers = "Modifiers",
  Products = "Products",
  OperationReportDetails = "OperationReportDetails",
  AddonModifierModal = "AddonModifierModal",
  AddProductsModal = "AddProductsModal",
  AnalyticsMenuItems = "AnalyticsMenuItems",
  AnalyticsLocations = "AnalyticsLocations",
  Inventory = "Inventory",
  InventoryDetails = "InventoryDetails",
  KitchenInventory = "KitchenInventory",
  Menus = "Menus",
  TabletsSettingsProfiles = "TabletsSettingsProfiles",
  TabletsSettingsDevices = "TabletsSettingsDevices",
}

export interface TableFiltersState {
  [ETable.Orders]: TableFilterState;
  [ETable.Customers]: TableFilterState;
  [ETable.CustomerOrders]: TableFilterState;
  [ETable.OperationReports]: TableFilterState;
  [ETable.InventoryHistory]: TableFilterState;
  [ETable.MenuScheduling]: TableFilterState;
  [ETable.SnoozedItemsModal]: TableFilterState;
  [ETable.Tags]: TableFilterState;
  [ETable.Modifiers]: TableFilterState;
  [ETable.Products]: TableFilterState;
  [ETable.OperationReportDetails]: TableFilterState;
  [ETable.AddonModifierModal]: TableFilterState;
  [ETable.AddProductsModal]: TableFilterState;
  [ETable.AnalyticsMenuItems]: TableFilterState;
  [ETable.Inventory]: TableFilterState;
  [ETable.InventoryDetails]: TableFilterState;
  [ETable.KitchenInventory]: TableFilterState;
  [ETable.Menus]: TableFilterState;
  [ETable.AnalyticsLocations]: TableFilterState;
  [ETable.TabletsSettingsDevices]: TableFilterState;
  [ETable.TabletsSettingsProfiles]: TableFilterState;
}

export interface TableFilters {
  include?: AdditionalFilter[];
  value?: string;
  selected: FilterStateItem[] | [];
  filters: FilterOption[];
  setValue: (arg: FilterStateItem[]) => void;
}
