import { Role } from "./roles";
import { generateUniqueId } from "helpers/dataHelpers";

export const locationAnalytics = Array.from({ length: 50 }).map(
  (product, i) => ({
    id: i,
    location: i % 2 == 0 ? "FM City" : "Jabriya",
    order: i % 2 == 0 ? "591 (87%)" : "87 (13%)",
    revenue: "KWD (4,865.800)",
    grossSales: "KWD (4,865.800)",
    avgOrderValue: "KWD 11.794",
  }),
);

export const menuItemsAnalytics = Array.from({ length: 50 }).map(
  (product, i) => ({
    id: generateUniqueId(),
    menuItem: i % 2 == 0 ? "Mango Yogurt" : "JabCaesar Potatoes Salad",
    totalSold: i % 2 == 0 ? "11500" : "10400",
    soldIndividually: "200",
    revenueBase: "KWD 4,865.800",
    revenueExtended: "KWD 11.794",
    revenueUpsells: "KWD 11.794",
    revenueVariations: "KWD 11.794",
  }),
);

export const revenueOrdersAnalytics = Array.from({ length: 12 }).map(
  (product, i) => ({
    date: `2023-${i + 1}`,
    revenue: i % 2 == 0 ? "KWD 261.500" : "KWD 5,760.200",
    totalDiscount: "KWD 0.000",
    fixedCost: "KWD 0.000",
    calculationTax: "KWD 0.000",
    deliveryCost: "KWD 0.000",
  }),
);

export const ordersPerHourAnalytics = Array.from({ length: 15 }).map(
  (product, i) => ({
    time: `${i + 8}:00 -  ${i + 9}:00`,
    mon: i % 2 == 0 ? 15 : 10,
    tue: i % 2 == 0 ? 2 : 1,
    wed: i % 2 == 0 ? 10 : 20,
    thu: 6,
    fri: i > 3 && i < 11 ? 20 : 0,
    sat: i > 4 && i < 9 ? 15 : 0,
    sun: i > 2 && i < 14 ? 22 : 1,
  }),
);

export const operationsTable = {
  items: Array.from({ length: 30 }).map((el, i) => ({
    id: `id=${i}`,
    start: "Jun 1, 2023 11:30 PM",
    type: "snooze_report",
    location: `FM City${i}`,
    channel: "-",
    channel_link: "-",
    status: "Finished",
  })),
  totalCount: 30,
};

export const operationsDetailsTable = {
  items: Array.from({ length: 30 }).map((el, i) => ({
    name: "beautiful name " + i,
    plu: `PLU ${i}`,
    type: "snooze_report",
    snooze_start: new Date(Date.now()),
    snooze_end: new Date(Date.now()),
  })),
  totalCount: 30,
};

export const usersSettingsTable = {
  items: Array.from({ length: 5 }).map((el, i) => ({
    id: `user_id_${i}`,
    avatar:
      "https://image.cnbcfm.com/api/v1/image/106165374-15702116509742019dodgechallengersrthellcatredeye01.jpg?v=1570212538&w=740&h=416&ffmt=webp&vtcrop=y",
    username: `user${i}`,
    role: i % 2 ? Role.Manager : i % 3 ? Role.AccountManager : Role.Admin,
    mail: `example${i}@example.com`,
    created_at: "5/23/2023 11:30 PM",
    updated_at: "5/23/2023 11:30 PM",
  })),
  totalCount: 5,
};
