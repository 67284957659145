// Lib
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Api
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
import { useGetOrdersQuery } from "rtkQuery/query/ordersAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useNotification, useTable, useViewport } from "hooks";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { OrderFilterTypes } from "types/orders";
import { ETable } from "types/tableFilters";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { DescriptionBlackIcon } from "icons";
// Components
import { Pagination, Table } from "components";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { PageTitleContainer, PageWrapper } from "./styled";

import { columns, filtersList } from "./config";
import { ADMIN_ROUTES } from "consts/routes";

export const Orders: FC = () => {
  const { isDesktop } = useViewport();
  const companyId = useAppSelector(getUserCompanyId);

  const navigate = useNavigate();
  const { openNotification } = useNotification();

  const {
    orderDirection,
    orderBy,
    currentPage,
    pageSize,
    search,
    debouncedSearch,
    debouncedFiltersQuery,
    selectedFilters,
    setCurrentPage,
    setPageSize,
    setSearch,
    handleSort,
    handleSetTableFilterValues,
  } = useTable({ name: ETable.Orders });

  const {
    data: locationsData,
    isError: isLocationsError,
    error: locationsError,
  } = useGetLocationsQuery(
    { companyId },
    { skip: !companyId, refetchOnMountOrArgChange: true },
  );

  const {
    data: ordersData,
    isFetching: isLoading,
    isError: isOrdersError,
    error: ordersError,
  } = useGetOrdersQuery(
    {
      companyId,
      query: {
        ...debouncedFiltersQuery,
        page: currentPage,
        size: pageSize,
        orderBy: orderBy,
        orderDirection: orderDirection,
        search: debouncedSearch,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (isLocationsError) {
      errorHandler({ error: locationsError, openNotification });
    }
  }, [isLocationsError]);

  useEffect(() => {
    if (isOrdersError) {
      errorHandler({ error: ordersError, openNotification });
    }
  }, [isOrdersError]);

  const filtersData = locationsData
    ? filtersList.map(el =>
        el.key === OrderFilterTypes.location
          ? {
              ...el,
              options: locationsData.map(l => ({ label: l.name, type: l.id })),
            }
          : el,
      )
    : filtersList;

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const onRow = record => {
    return {
      onClick: () => {
        navigate(`/loop/orders/${record.id}`, {
          state: {
            backPath: `${ADMIN_ROUTES.ORDERS.path}?size=${pageSize}`,
          },
        });
      },
    };
  };

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer
        $fullwidth
        $column
        $grow={1}
        $padding={isDesktop && "0 0 32px"}
      >
        <PageTitleContainer>
          <Typography.H1>Orders</Typography.H1>
        </PageTitleContainer>

        <Table
          isLoading={isLoading}
          dataSource={ordersData?.data}
          columns={columns}
          empty={{
            icon: DescriptionBlackIcon,
            title: "No order to show.",
            description: "Try changing sections or change the filters",
          }}
          header={{
            search: {
              placeholder: "Search customer, mobile number, or order ID",
              value: search,
              setValue: setSearch,
            },
            totalCount: ordersData?.total,
            filter: {
              filters: filtersData,
              selected: selectedFilters,
              setValue: handleSetTableFilterValues,
            },
          }}
          onChange={handleTableChange}
          onRow={onRow}
        />
      </FlexContainer>

      <Pagination
        onPage
        padding="12px 16px"
        pageSize={pageSize}
        showSizeChanger={!isDesktop}
        currentPage={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        totalItems={ordersData?.total}
      />
    </PageWrapper>
  );
};
