// Lib
import styled from "styled-components";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";

export const TitleContainer = styled(FlexContainer)`
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
`;

export const SubmitButton = styled(Button.Heading)`
  position: absolute;
  right: 32px;
  top: 36px;
`;

export const DropDownContainer = styled.div`
  position: absolute;
  right: 32px;
  top: 36px;
`;
