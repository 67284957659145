import { getUserRole } from "rtkQuery/selectors";
import { useAppSelector } from "./redux";
import { Role } from "consts";

export const useUserRole = () => {
  const role = useAppSelector(getUserRole);

  return {
    isAdminUser: role === Role.Admin,
    isAccountManagerUser: role === Role.AccountManager,
    isKitchenUser: role === Role.Manager,
  };
};
