import axios from "axios";

import { store } from "store";

import { userLogout } from "rtkQuery/slices";

import { token } from "./handleToken";

import { refreshAccessToken } from "./refreshAccessToken";

const API_URL = process.env.REACT_APP_BASE_URL;
const UNAUTHORIZED = 401;

const axiosAPI = axios.create({
  baseURL: API_URL,
});

axiosAPI.interceptors.request.use(
  config => {
    const access = token.access.get();

    if (access?.token) {
      config.headers["Authorization"] = `Bearer ${access.token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosAPI.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;

    if (error.response?.status === UNAUTHORIZED && !originalRequest._retry) {
      originalRequest._retry = true;

      return refreshAccessToken().then((newAccessToken: string | undefined) => {
        if (newAccessToken) {
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

          return axios(originalRequest);
        } else {
          store.dispatch(userLogout());
          return Promise.reject(error);
        }
      });
    }

    return Promise.reject(error);
  },
);

export default axiosAPI;
