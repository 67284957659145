import { dateTransform } from "helpers/dataHelpers";
import { SnoozeOptionsTypes, SnoozeValuesTypes } from "types/snooze";

export const snoozeOptions = [
  {
    label: SnoozeOptionsTypes.Snooze1H,
    value: SnoozeValuesTypes.Snooze1H,
  },
  {
    label: SnoozeOptionsTypes.Snooze2H,
    value: SnoozeValuesTypes.Snooze2H,
  },
  {
    label: SnoozeOptionsTypes.Snooze4H,
    value: SnoozeValuesTypes.Snooze4H,
  },
  {
    label: SnoozeOptionsTypes.Snooze6H,
    value: SnoozeValuesTypes.Snooze6H,
  },
  {
    label: SnoozeOptionsTypes.Snooze12H,
    value: SnoozeValuesTypes.Snooze12H,
  },
  {
    label: SnoozeOptionsTypes.SnoozeIndefinitely,
    value: SnoozeValuesTypes.SnoozeIndefinitely,
  },
  {
    label: SnoozeOptionsTypes.SnoozeUntil,
    value: SnoozeValuesTypes.SnoozeUntil,
  },
];

export const tableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "PLU",
    dataIndex: "plu",
    key: "plu",
    sorter: true,
  },
  {
    title: "Snooze start",
    dataIndex: "snooze_start",
    key: "snooze_start",
    sorter: true,
    render: text => dateTransform({ date: text }),
  },
  {
    title: "Snooze end",
    dataIndex: "snooze_end",
    key: "snooze_end",
    sorter: true,
    render: text => (text ? dateTransform({ date: text }) : "Indefinitely"),
  },
];
