// Lib
import { FC } from "react";
// Icons
import {
  AttachMoneyIcon,
  CancelIcon,
  CheckCircleIcon,
  CreditCardIcon,
} from "icons";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { SummaryTitle } from "./styled";
import { theme } from "theme";
import { EPaymentMethod } from "types/orders";
import { Skeleton } from "antd";

export interface SummaryItemProps {
  isLoading?: boolean;
  iconType?: EPaymentMethod | keyof typeof icons;
  label: string;
  value: string;
}

const icons = {
  [EPaymentMethod.CARD]: () => <CreditCardIcon />,
  [EPaymentMethod.CASH]: () => <AttachMoneyIcon />,
  check: () => <CheckCircleIcon />,
  cancel: () => <CancelIcon fill={theme.color.red} />,
};

export const SummaryItem: FC<SummaryItemProps> = ({
  isLoading,
  iconType,
  label,
  value,
}) => {
  const Icon = icons[iconType];

  return (
    <FlexContainer $justify="space-between" $margin="0 0 12px">
      <SummaryTitle>{label}</SummaryTitle>
      <FlexContainer>
        {!isLoading && Icon && (
          <FlexContainer $margin="0 4px 0 0">
            <Icon />
          </FlexContainer>
        )}

        {isLoading ? (
          <Skeleton.Input />
        ) : (
          <Typography.Title>{value || "No data"}</Typography.Title>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};
