import { LocationAddressForm } from "types/locations";

export const defaultValues: LocationAddressForm = {
  isHomeSite: false,
  name: "",
  country: "",
  street: "",
  number: "",
  city: "",
  postcode: "",
  phoneCode: "+965",
  phoneNumber: "",
  email: "",
  simphonyNodeId: "",
};
