import { TagProps as AntDTagProps } from "antd";
import { FC, ReactNode } from "react";
import { CloseIcon, StyledTag } from "./styled";

interface TagProps extends AntDTagProps {
  label: ReactNode;
}

export const Tag: FC<TagProps> = ({ label, ...props }) => {
  return (
    <StyledTag color="#d7ded7" closeIcon={<CloseIcon />} {...props}>
      {label}
    </StyledTag>
  );
};
