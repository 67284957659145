import { useContext } from "react";
import { ViewportContext, ViewportContextType } from "context/ViewportContext";

export const useViewport = () => {
  const { width } = useContext<ViewportContextType>(ViewportContext);

  return {
    width,
    isMobile: width < 480,
    isLargeMobile: width < 680,
    isTablet: width < 1024 && width > 479,
    isTabletPortrait: width < 768,
    isMinTablet: width < 1024,
    isDesktop: width > 1023,
    isMiddleDesktop: width > 1240,
  };
};
