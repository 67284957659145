import axios from "axios";
import { LocationAddressForm } from "types/locations";

export const getGeoCoordinates = async (data: LocationAddressForm) => {
  const { name, country, street, number, city, postcode } = data;

  try {
    const coordinates = await axios
      .get("https://maps.googleapis.com/maps/api/geocode/json", {
        params: {
          address: `${name},${country},${street}+${number},${city},${postcode}`,
          key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        },
      })
      .then(response => response.data.results);

    if (coordinates) {
      const location = coordinates[0]?.geometry?.location;

      return {
        lat: location?.lat?.toString() || null,
        lng: location?.lng?.toString() || null,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.error("Google API error:", error);
  }
};
