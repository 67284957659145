import { ReactNode } from "react";
import {
  MenuPublishTimes,
  MenuSchedulingFilterTypes,
  MenuSchedulingNames,
  ScheduleDayLabels,
} from "types/menuScheduling";
import { FilterField, FilterOption } from "types/tableFilters";
import { Location } from "types/locations";
import { GetMenusResponse } from "types/menus";
import { ListModalData } from "../../../../components/ListModal/types";

import { transformScheduleHoursTimeToLocalValues } from "helpers/dataHelpers";

import { FlexContainer } from "styled/Box";
import { TableRowButton } from "./components/MenuScheduling/styled";

import { columns } from "./components/MenuScheduling/config";
import { CreateCellValues, CreatePubllishTimeCellValues } from "./types";

interface createFiltersValues {
  filtersList: FilterOption[];
  locationsData: Location[];
  menusData: GetMenusResponse;
}

export const createFilters = ({
  filtersList,
  locationsData,
  menusData,
}: createFiltersValues): FilterOption[] => {
  return filtersList.map(filter => {
    if (filter.key === MenuSchedulingFilterTypes.sites) {
      if (!locationsData?.length) {
        filter;
      }

      return {
        ...filter,
        options: locationsData?.map(l => ({ label: l.name, type: l.id })),
      };
    }

    if (filter.key === MenuSchedulingFilterTypes.channels) {
      if (!locationsData?.length) {
        filter;
      }

      const options = locationsData?.flatMap(location =>
        location?.channels?.map(channel => ({
          label: channel.name,
          type: channel.id,
        })),
      );
      return {
        ...filter,
        options,
      };
    }

    if (filter.key === MenuSchedulingFilterTypes.menus) {
      if (!menusData?.data?.length) {
        return filter;
      }

      const options = menusData?.data?.map(menu => ({
        label: menu.name,
        type: menu.id,
      }));

      return {
        ...filter,
        options,
      };
    }

    return filter;
  });
};

const createCell = ({
  title,
  data,
  handleOpenListModal,
}: CreateCellValues): ReactNode => {
  if (!data || !data.length) {
    return null;
  }

  if (data.length === 1) {
    return data[0]?.name || null;
  }

  if (data.length > 1) {
    const list = data?.map(el => el?.name);
    const otherElements = data.slice(1);
    return (
      <FlexContainer $gap={4} $align="center">
        <span>{data[0]?.name}</span>
        <TableRowButton
          type="link"
          onClick={() => handleOpenListModal({ title, list })}
        >{`+ ${otherElements.length} More`}</TableRowButton>
      </FlexContainer>
    );
  }
};

const createPublishTimesCell = ({
  title,
  data,
  handleOpenListModal,
}: CreatePubllishTimeCellValues): ReactNode => {
  if (!data || !data.length) {
    return null;
  }

  const publishData = data.map(el => {
    const days = el.days.map(day => ScheduleDayLabels[day]).join(", ");
    const time = transformScheduleHoursTimeToLocalValues(el.time);

    return { name: `${days}: ${time}` };
  });

  return createCell({ title, data: publishData, handleOpenListModal });
};

interface createColumnsValues {
  handleOpenListModal: (data: ListModalData) => void;
}

export const createColumns = ({ handleOpenListModal }: createColumnsValues) => {
  return columns.map(column => {
    if (column?.key === "sites") {
      return {
        ...column,
        render: (data: any[]) =>
          createCell({ title: "Locations", data, handleOpenListModal }),
      };
    }
    if (column?.key === "channels") {
      return {
        ...column,
        render: (data: any[]) =>
          createCell({ title: "Channel links", data, handleOpenListModal }),
      };
    }
    if (column?.key === "menus") {
      return {
        ...column,
        render: (data: any[]) =>
          createCell({ title: "Menus", data, handleOpenListModal }),
      };
    }
    if (column?.key === "publishTimes") {
      return {
        ...column,
        render: (data: MenuPublishTimes[]) =>
          createPublishTimesCell({
            title: "Publish Times",
            data,
            handleOpenListModal,
          }),
      };
    }

    return column;
  });
};

export const filtersList: FilterOption[] = [
  {
    key: MenuSchedulingFilterTypes.sites,
    label: MenuSchedulingNames[MenuSchedulingFilterTypes.sites],
    field: FilterField.MULTISELECT,
    options: [],
  },
  {
    key: MenuSchedulingFilterTypes.channels,
    label: MenuSchedulingNames[MenuSchedulingFilterTypes.channels],
    field: FilterField.MULTISELECT,
    options: [],
  },
  {
    key: MenuSchedulingFilterTypes.menus,
    label: MenuSchedulingNames[MenuSchedulingFilterTypes.menus],
    field: FilterField.MULTISELECT,
    options: [],
  },
];
