import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const LegendIcon: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    width="31"
    height="10"
    viewBox="0 0 31 10"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.300049"
      y1="5"
      x2="30.3"
      y2="5"
      stroke={fill || "#1A8636"}
      strokeWidth="2"
    />
    <circle
      cx="15.3"
      cy="5"
      r="4"
      fill="white"
      stroke={fill || "#1A8636"}
      strokeWidth="2"
    />
  </svg>
);
