import dayjs from "dayjs";
import { MenuTypesOptions, UrlQueryParams } from "./common";
import { ProductTag } from "./products";
import { Modifier } from "./modifiers";

export enum PublishOutOfStock {
  IGNORE = "ignore",
  NOT_IGNORE = "not_ignore",
}

export enum PublishResponseStatus {
  SUCCESS = "success",
  WARNING = "warning",
}

export interface MenuItemFormTypes {
  name: string;
  plu: string;
  price: string;
  description: string;
  tax: string;
  image: string | null;
}

export interface MenuFormTypes {
  name: string;
  internalName: string;
  internalType: MenuTypesOptions;
  location: string;
  workStartTime: string;
  workEndTime: string;
}

export interface MenuCategoryItemType {
  id: string;
  disabled: boolean;
  order: number;
  checked?: boolean;
  product: MenuItemProduct;
  isNew?: boolean;
  modifiers: Modifier[];
}

export interface MenuCategoryType {
  id: string;
  name: string;
  image?: string;
  description?: string;
  disabled?: boolean;
  items: MenuCategoryItemType[];
  order: number;
  isNew?: boolean;
}

export interface MenuType {
  name: string;
  internal_name: string;
  type: string;
  location: string;
  categories: MenuCategoryType[];
}

export interface EditCategoryFormTypes {
  name: string;
  description?: string;
  image?: string | null;
}

export interface MenuItemProduct {
  id: string;
  name: string;
  imageUrl: string;
  description: string;
  price: string;
  kcal: string;
  tax: string;
  plu: string;
  tags: ProductTag[];
  highlights: ProductTag[];
  alergents: ProductTag[];
  labels: ProductTag[];
}

export interface SnoozingCategoryItemType {
  id: string;
  image: string;
  name: string;
  description: string;
  kcal: string;
  price: string;
  tags: string[];
  labels: ProductTag[];
  isSnoozed: boolean;
  disabled: boolean;
  snoozedUntil: dayjs.Dayjs | null;
  snoozedAt: dayjs.Dayjs | null;
  plu: string;
  tax: string;
  modifiers: Modifier[];
}

export interface SnoozingCategoryType {
  id: string;
  name: string;
  image?: string;
  description?: string;
  isSnoozed?: boolean;
  disabled?: boolean;
  snoozedUntil: dayjs.Dayjs | null;
  items: SnoozingCategoryItemType[];
}

export interface GetMenusRequest {
  companyId: string;
  query?: UrlQueryParams;
}

export interface ListMenuItem {
  id: string;
  companyId: string;
  name: string;
  active: boolean;
  siteId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface GetMenusResponse {
  data: ListMenuItem[];
  page: number;
  size: number;
  total: number;
  totalPages: number;
}
export interface GetKitchenMenuResponse {
  id: string;
  internalType: string;
  name: string;
}

export interface DeleteMenusRequest {
  data: {
    ids: string[];
  };
}

interface MenuItem {
  id?: string;
  position: number;
  isSnoozed?: boolean;
  isDisabled?: boolean;
  snoozedUntil?: string;
  snoozedAt?: string;
  product: MenuItemProduct;
  modifiers: Modifier[];
}

export interface CategoryType {
  id: string;
  name: string;
  description: string;
  position: number;
  imageUrl: string;
  isDisabled?: boolean;
  menuItems: MenuItem[];
}

export interface GetMenuRequest {
  menuId: string;
}

export interface GetMenuResponse {
  id: string;
  companyId: string;
  name: string;
  internalName: string;
  siteId: string;
  internalType: MenuTypesOptions;
  categories: CategoryType[];
  workStartTime: string;
  workEndTime: string;
}

export interface UpdateCategoryType {
  id?: string;
  name: string;
  description: string;
  orderNumber: number;
  imageUrl: string | null;
  menuItems: MenuItem[];
}

export interface UpdateMenuRequest {
  menuId: string;
  data: {
    name: string;
    internalName: string;
    internalType: MenuTypesOptions;
    siteId: string;
    categories: {
      id?: string;
      menuId: string;
      name: string;
      description: string;
      position: number;
      imageUrl: string;
      isDisabled: boolean;
      menuItems: {
        id?: string;
        position: number;
        productId: string;
        modifiers: string[];
      }[];
    }[];
    workStartTime: string;
    workEndTime: string;
  };
}
export interface CreateMenuRequest {
  companyId: string;
  data: {
    name: string;
    internalName: string;
    internalType: MenuTypesOptions;
    siteId: string;
    categories: {
      name: string;
      description: string;
      position: number;
      imageUrl: string;
      menuItems: {
        position: number;
        productId: string;
        modifiers: string[];
      }[];
    }[];
    workStartTime: string;
    workEndTime: string;
  };
}

export interface CreateUpdateMenuResponse {
  id: string;
}

export interface PublishMenuResponse {
  id?: string;
  outOfStockItems?: {
    id: string;
    categoryId: string;
    stockQuantity: number;
    product: {
      id: string;
      name: string;
    };
  }[];
  status: PublishResponseStatus;
}

export interface PublishMenuRequest {
  id: string;
  data: {
    outOfStock: PublishOutOfStock;
  };
}
