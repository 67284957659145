import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const AvatarIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 42 42"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="19.5" fill="#99AB9C" stroke="#BEC9BD" />
    <mask
      id="mask0_1100_15092"
      // style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width={props.width || "40"}
      height={props.height || "40"}
    >
      <circle cx="20" cy="20" r="19.5" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1100_15092)">
      <path
        d="M20.9998 26.7166C18.9046 26.7166 17.1109 25.9706 15.6189 24.4785C14.1268 22.9865 13.3808 21.1928 13.3808 19.0976C13.3808 17.0023 14.1268 15.2087 15.6189 13.7166C17.1109 12.2245 18.9046 11.4785 20.9998 11.4785C23.0951 11.4785 24.8887 12.2245 26.3808 13.7166C27.8728 15.2087 28.6189 17.0023 28.6189 19.0976C28.6189 21.1928 27.8728 22.9865 26.3808 24.4785C24.8887 25.9706 23.0951 26.7166 20.9998 26.7166ZM5.76172 41.9547V36.6214C5.76172 35.542 6.03981 34.5496 6.596 33.6442C7.15093 32.7401 7.8887 32.0499 8.80934 31.5738C10.7776 30.5896 12.7776 29.8512 14.8093 29.3585C16.8411 28.8671 18.9046 28.6214 20.9998 28.6214C23.0951 28.6214 25.1585 28.8671 27.1903 29.3585C29.222 29.8512 31.222 30.5896 33.1903 31.5738C34.1109 32.0499 34.8487 32.7401 35.4036 33.6442C35.9598 34.5496 36.2379 35.542 36.2379 36.6214V41.9547H5.76172Z"
        fill="#002E07"
      />
    </g>
  </svg>
);
