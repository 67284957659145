import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const LocationsIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginInlineEnd: "0" }}
  >
    <g clipPath="url(#clip0_1100_15992)">
      <path
        d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
        fill="#646965"
      />
    </g>
    <defs>
      <clipPath id="clip0_1100_15992">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
