import { ChartSummaryButton } from "components/Chart/ChartButton/types";
import dayjs from "dayjs";

import {
  AnalyticsDateFilter,
  AnalyticsPeriodFilterLabels,
  AnalyticsPeriodFilterValues,
  AnalyticsPeriodGroupByValues,
  AnalyticsStateDateFilterValue,
  AnalyticsStatsSummaryKey,
  AnalyticsStatsSummaryLabels,
  AnalyticsStatsSummaryValues,
  ChartData,
  MergedChartSlotData,
} from "types/analytics";

type StartEndTime = "day" | "week" | "month" | "quarter" | "year";

const startOf = (value: StartEndTime) => {
  return dayjs().startOf(value);
};

const endOf = (value: StartEndTime) => {
  return dayjs().endOf(value);
};

export const getFormattedDate = (date: dayjs.Dayjs | string) => {
  return dayjs(date).format("DD MMM");
};

export const generateLabel = (value: AnalyticsPeriodFilterValues) => {
  const label = AnalyticsPeriodFilterLabels[value];

  switch (value) {
    case AnalyticsPeriodFilterValues.Custom:
      return label;

    case AnalyticsPeriodFilterValues.Today:
      return `${label} (${getFormattedDate(dayjs())})`;

    case AnalyticsPeriodFilterValues.Yesterday:
      return `${label} (${getFormattedDate(dayjs().subtract(1, "day"))})`;

    case AnalyticsPeriodFilterValues.ThisWeek:
      return `${label} (${getFormattedDate(
        startOf("week"),
      )} - ${getFormattedDate(endOf("week"))})`;

    case AnalyticsPeriodFilterValues.LastSevenDays:
      return `${label} (${getFormattedDate(
        dayjs().subtract(7, "day"),
      )} - ${getFormattedDate(dayjs().subtract(1, "day"))})`;

    case AnalyticsPeriodFilterValues.ThisMonth:
      return `${label} (${getFormattedDate(
        startOf("month"),
      )} - ${getFormattedDate(endOf("month"))})`;

    case AnalyticsPeriodFilterValues.LastFourWeeks:
      return `${label} (${getFormattedDate(
        dayjs().subtract(4, "week"),
      )} - ${getFormattedDate(dayjs().subtract(1, "day"))})`;

    case AnalyticsPeriodFilterValues.CurrentQuarter:
      return `${label} (${getFormattedDate(
        startOf("quarter"),
      )} - ${getFormattedDate(endOf("quarter"))})`;

    case AnalyticsPeriodFilterValues.CurrentYear:
      return `${label} (${getFormattedDate(
        startOf("year"),
      )} - ${getFormattedDate(endOf("year"))})`;

    default:
      return label;
  }
};

export const generateDates = (
  filter: AnalyticsStateDateFilterValue,
): AnalyticsDateFilter => {
  if (filter.value === AnalyticsPeriodFilterValues.Today) {
    const start = startOf("day");
    const end = endOf("day");

    const current = {
      startTime: start.toISOString(),
      endTime: end.toISOString(),
    };

    const prev = {
      startTime: start.subtract(1, "day").toISOString(),
      endTime: end.subtract(1, "day").toISOString(),
    };

    return {
      current,
      prev,
      groupBy: AnalyticsPeriodGroupByValues.Hour,
    };
  }

  if (filter.value === AnalyticsPeriodFilterValues.Yesterday) {
    const start = startOf("day").subtract(1, "day");
    const end = endOf("day").subtract(1, "day");

    const current = {
      startTime: start.toISOString(),
      endTime: end.toISOString(),
    };

    const prev = {
      startTime: start.subtract(1, "day").toISOString(),
      endTime: end.subtract(1, "day").toISOString(),
    };

    return {
      current,
      prev,
      groupBy: AnalyticsPeriodGroupByValues.Hour,
    };
  }

  if (filter.value === AnalyticsPeriodFilterValues.ThisWeek) {
    const start = startOf("week");
    const end = endOf("week");

    const current = {
      startTime: start.toISOString(),
      endTime: end.toISOString(),
    };

    const prev = {
      startTime: start.subtract(1, "week").toISOString(),
      endTime: end.subtract(1, "week").toISOString(),
    };

    return {
      current,
      prev,
      groupBy: AnalyticsPeriodGroupByValues.Day,
    };
  }

  if (filter.value === AnalyticsPeriodFilterValues.LastSevenDays) {
    const start = startOf("day").subtract(7, "day");
    const end = endOf("day").subtract(1, "day");

    const current = {
      startTime: start.toISOString(),
      endTime: end.toISOString(),
    };

    const prev = {
      startTime: start.subtract(7, "day").toISOString(),
      endTime: end.subtract(7, "day").toISOString(),
    };

    return {
      current,
      prev,
      groupBy: AnalyticsPeriodGroupByValues.Day,
    };
  }

  if (filter.value === AnalyticsPeriodFilterValues.ThisMonth) {
    const start = startOf("month");
    const end = endOf("month");

    const current = {
      startTime: start.toISOString(),
      endTime: end.toISOString(),
    };

    const prev = {
      startTime: start.subtract(1, "month").toISOString(),
      endTime: end.subtract(1, "month").toISOString(),
    };

    return {
      current,
      prev,
      groupBy: AnalyticsPeriodGroupByValues.Day,
    };
  }

  if (filter.value === AnalyticsPeriodFilterValues.LastFourWeeks) {
    const start = startOf("day").subtract(4, "week");
    const end = endOf("day").subtract(1, "day");

    const current = {
      startTime: start.toISOString(),
      endTime: end.toISOString(),
    };

    const prev = {
      startTime: start.subtract(4, "week").toISOString(),
      endTime: end.subtract(4, "week").toISOString(),
    };

    return {
      current,
      prev,
      groupBy: AnalyticsPeriodGroupByValues.Day,
    };
  }

  if (filter.value === AnalyticsPeriodFilterValues.CurrentQuarter) {
    const start = startOf("quarter");
    const end = endOf("quarter");

    const current = {
      startTime: start.toISOString(),
      endTime: end.toISOString(),
    };

    const prev = {
      startTime: start.subtract(1, "quarter").toISOString(),
      endTime: end.subtract(1, "quarter").toISOString(),
    };

    return {
      current,
      prev,
      groupBy: AnalyticsPeriodGroupByValues.Week,
    };
  }

  if (filter.value === AnalyticsPeriodFilterValues.CurrentYear) {
    const start = startOf("year");
    const end = endOf("year");

    const current = {
      startTime: start.toISOString(),
      endTime: end.toISOString(),
    };

    const prev = {
      startTime: start.subtract(1, "year").toISOString(),
      endTime: end.subtract(1, "year").toISOString(),
    };

    return {
      current,
      prev,
      groupBy: AnalyticsPeriodGroupByValues.Month,
    };
  }

  if (filter.value === AnalyticsPeriodFilterValues.Custom) {
    const current = {
      startTime: dayjs(filter.customTimePeriod.currentStartTime)
        .startOf("day")
        .toISOString(),
      endTime: dayjs(filter.customTimePeriod.currentEndTime)
        .endOf("day")
        .toISOString(),
    };

    const prev = {
      startTime: dayjs(filter.customTimePeriod.prevStartTime)
        .startOf("day")
        .toISOString(),
      endTime: dayjs(filter.customTimePeriod.prevEndTime)
        .endOf("day")
        .toISOString(),
    };

    return {
      current,
      prev,
      groupBy: filter.customTimePeriod.groupBy,
    };
  }
};

export const getRandomColor = (): string => {
  const getRandomValue = (): number => Math.floor(Math.random() * 256);

  const r = getRandomValue();
  const g = getRandomValue();
  const b = getRandomValue();

  return `rgb(${r}, ${g}, ${b})`;
};

export const getTooltipTitle = (
  values: dayjs.Dayjs[],
  groupBy: AnalyticsPeriodGroupByValues,
): string => {
  switch (groupBy) {
    case AnalyticsPeriodGroupByValues.Hour:
      return `${dayjs(values[0]).format("DD MMM HH:mm")} - ${dayjs(
        values[1],
      ).format("HH:mm")}`;

    case AnalyticsPeriodGroupByValues.Day:
      return `${dayjs(values[0]).format("DD MMM")}`;

    case AnalyticsPeriodGroupByValues.Week:
      return `${dayjs(values[0]).format("DD MMM")} - ${dayjs(values[1]).format(
        "DD MMM",
      )}`;

    case AnalyticsPeriodGroupByValues.Month:
      return `${dayjs(values[0]).format("MMM YYYY")}`;

    default:
      break;
  }
  return "";
};

const getStatsValuePrefix = (key: AnalyticsStatsSummaryKey) => {
  switch (key) {
    case AnalyticsStatsSummaryKey.Revenue:
    case AnalyticsStatsSummaryKey.AverageOrderValue:
      return "KWD";

    default:
      break;
  }
};

const getIsInvertedProfit = (key: AnalyticsStatsSummaryKey) => {
  switch (key) {
    case AnalyticsStatsSummaryKey.OrderIssueOvertime:
      return true;

    default:
      return false;
  }
};

const getProfitDisplay = (key: AnalyticsStatsSummaryKey) => {
  switch (key) {
    case AnalyticsStatsSummaryKey.OrderIssueOvertime:
      return "value";

    default:
      return "percents";
  }
};

export const createButtons = (
  summaryStats: AnalyticsStatsSummaryValues[],
): ChartSummaryButton[] => {
  return summaryStats.map(({ dataKey, value, prevValue }) => ({
    statsKey: dataKey,
    title: AnalyticsStatsSummaryLabels[dataKey],
    statsValue: value,
    statsPrevValue: prevValue,
    statsValuePrefix: getStatsValuePrefix(dataKey),
    profitDisplay: getProfitDisplay(dataKey),
    isInvertedProfit: getIsInvertedProfit(dataKey),
  }));
};

export const createSlotCurrentLocationValues = (
  locationIds: string[],
  isFuture: boolean,
) => {
  return locationIds.reduce((acc, id) => {
    acc[id] = isFuture ? null : 0;
    return acc;
  }, {});
};

export const createChartData = (
  slotsData: MergedChartSlotData[],
  locations: {
    label: string;
    value: string;
  }[],
  selected: AnalyticsStatsSummaryKey,
): ChartData[] => {
  if (!locations?.length || !selected) {
    return [];
  }

  return slotsData?.map(data => {
    const values = data[selected];

    const isPrevTooltipTitles =
      data?.prevSlotStartTime !== undefined &&
      data?.prevSlotEndTime !== undefined;

    const tooltipTitles = isPrevTooltipTitles
      ? {
          current: getTooltipTitle(
            [data.slotStartTime, data.slotEndTime],
            data.groupBy,
          ),
          prev: getTooltipTitle(
            [data.prevSlotStartTime, data.prevSlotEndTime],
            data.groupBy,
          ),
        }
      : locations.reduce((acc, item) => {
          acc[item.value] = `${item.label || item.value}`;

          return acc;
        }, {} as Record<string, string | number>);

    return {
      name: data.name,
      ...values,
      groupBy: data.groupBy,
      tooltipTitles,
    };
  });
};
