import { orderStatusColors } from "consts";
import { storage } from "helpers/dataHelpers";
import {
  GetKitchenOrdersResponse,
  KitchenOrder,
  KitchenOrderTab,
} from "types/kitchen";
import { EOrderTypeLabel, EOrderTypeValue, OrderStatus } from "types/orders";

export const handleIncomingOrders = (newOrders: KitchenOrder[]) => {
  if (!newOrders.length) {
    storage.set(KitchenOrderTab.NEW, []);
    return;
  }
  const alreadyReadedIds: string[] = storage.get(KitchenOrderTab.NEW);

  const incoming = newOrders.map(el => el);

  if (!alreadyReadedIds || !alreadyReadedIds?.length) {
    storage.set(
      KitchenOrderTab.NEW,
      incoming.map(o => o.id),
    );
    return incoming;
  }

  if (alreadyReadedIds?.length) {
    const notReadedOrders = incoming.filter(
      o => !alreadyReadedIds.includes(o.id),
    );

    storage.set(
      KitchenOrderTab.NEW,
      alreadyReadedIds.concat(notReadedOrders.map(o => o.id)),
    );

    return notReadedOrders;
  }
};

export const removeReadedIncomingOrder = (
  id: string,
  incomingOrders: KitchenOrder[],
) => {
  const filteredOrders = incomingOrders.filter(el => el.id !== id);

  const storageReadedIds = storage
    .get(KitchenOrderTab.NEW)
    .filter(el => el !== id);

  storage.set(KitchenOrderTab.NEW, storageReadedIds);

  return filteredOrders;
};

export const getOrderType = (type: string | number) => {
  switch (type?.toString()) {
    case EOrderTypeValue.PICK_UP:
      return EOrderTypeLabel.PICK_UP;
    case EOrderTypeValue.DELIVERY:
      return EOrderTypeLabel.DELIVERY;
    case EOrderTypeValue.EAT_IN:
      return EOrderTypeLabel.EAT_IN;

    default:
      return "Unknown";
  }
};

export const getOrderButtonColor = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.ACCEPTED:
      return orderStatusColors[OrderStatus.PREPARING];
    case OrderStatus.PREPARING:
      return orderStatusColors[OrderStatus.ACCEPTED];
    default:
      orderStatusColors[OrderStatus.DELIVERED];
  }
};

export const getNextStatus = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.ACCEPTED:
      return OrderStatus.PREPARING;
    case OrderStatus.PREPARING:
      return OrderStatus.READY_FOR_PICKUP;
    case OrderStatus.READY_FOR_PICKUP:
      return OrderStatus.IN_DELIVERY;
    default:
      return OrderStatus.DELIVERED;
  }
};

export const getOrderCountText = (tab: KitchenOrderTab) => {
  switch (tab) {
    case KitchenOrderTab.INPROGRESS:
      return " order(s) in Progress";
    case KitchenOrderTab.SCHEDULED:
      return " order(s) scheduled";
    case KitchenOrderTab.FINISHED:
      return " finished order(s)";
    default:
      return " new order(s)";
  }
};

export const checkSelectedOrderStatus = (
  selectedOrder: KitchenOrder,
  ordersData: GetKitchenOrdersResponse,
): OrderStatus | undefined => {
  const currentOrder = ordersData.data.find(o => o.id === selectedOrder?.id);

  if (!currentOrder) {
    return;
  }

  if (currentOrder.status === selectedOrder.status) {
    return;
  }

  return currentOrder.status;
};
