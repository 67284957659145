import {
  AnalyticsChartKey,
  AnalyticsPeriodGroupByValues,
  AnalyticsState,
  AnalyticsStateDateFilterValue,
  AnalyticsStatsSummaryKey,
  AnalyticsStatsSummaryLabels,
  AnalyticsStatsSummaryValues,
  ChartCompareValues,
  ChartSlotData,
} from "types/analytics";

import { ChartSummaryButton } from "./components/ChartButton/types";
import {
  ChartLegendConfig,
  ChartLinesConfig,
  ChartLinesData,
  ChartProps,
  CreateChartDataOutput,
  MergedChartSlotData,
} from "./types";
import { OrderResponseDto } from "../../mockData";
import { dateTransform, isTimeBetween } from "helpers/dataHelpers";
import dayjs from "dayjs";
import { generateDates, getFormattedDate } from "../../helpers";
import {
  analyticsChartLocationsColors,
  mainChartLineConfig,
  mainLegendConfig,
  prevChartLineConfig,
  prevLegendConfig,
} from "./config";

const getRandomColor = (): string => {
  const getRandomValue = (): number => Math.floor(Math.random() * 256);

  const r = getRandomValue();
  const g = getRandomValue();
  const b = getRandomValue();

  return `rgb(${r}, ${g}, ${b})`;
};

export const getTooltipTitle = (
  values: dayjs.Dayjs[],
  groupBy: AnalyticsPeriodGroupByValues,
): string => {
  switch (groupBy) {
    case AnalyticsPeriodGroupByValues.Hour:
      return `${dayjs(values[0]).format("DD MMM HH:mm")} - ${dayjs(
        values[1],
      ).format("HH:mm")}`;

    case AnalyticsPeriodGroupByValues.Day:
      return `${dayjs(values[0]).format("DD MMM")}`;

    case AnalyticsPeriodGroupByValues.Week:
      return `${dayjs(values[0]).format("DD MMM")} - ${dayjs(values[1]).format(
        "DD MMM",
      )}`;

    case AnalyticsPeriodGroupByValues.Month:
      return `${dayjs(values[0]).format("MMM YYYY")}`;

    default:
      break;
  }
  return "";
};

const getIsInvertedProfit = (key: AnalyticsStatsSummaryKey) => {
  switch (key) {
    case AnalyticsStatsSummaryKey.OrderIssueOvertime:
      return true;

    default:
      return false;
  }
};

const getProfitDisplay = (key: AnalyticsStatsSummaryKey) => {
  switch (key) {
    case AnalyticsStatsSummaryKey.OrderIssueOvertime:
      return "value";

    default:
      return "percents";
  }
};

const getStatsValuePrefix = (key: AnalyticsStatsSummaryKey) => {
  switch (key) {
    case AnalyticsStatsSummaryKey.Revenue:
    case AnalyticsStatsSummaryKey.AverageOrderValue:
      return "KWD";

    default:
      break;
  }
};

export const createButtons = (
  summaryStats: AnalyticsStatsSummaryValues[],
): ChartSummaryButton[] => {
  return summaryStats.map(({ dataKey, value, prevValue }) => ({
    statsKey: dataKey,
    title: AnalyticsStatsSummaryLabels[dataKey],
    statsValue: value,
    statsPrevValue: prevValue,
    statsValuePrefix: getStatsValuePrefix(dataKey),
    profitDisplay: getProfitDisplay(dataKey),
    isInvertedProfit: getIsInvertedProfit(dataKey),
  }));
};

const getMenuItemsOrdersFilteredByItemName = (
  data: OrderResponseDto[],
  itemId: string,
) => {
  let itemName = "";

  for (let i = 0; i < data.length; i++) {
    const element = data[i];

    const name = element.items.find(i => i.productId === itemId)?.productName;

    if (name) {
      itemName = name;
      break;
    }
  }

  const filteredData = [];

  data.forEach(order => {
    const filteredItems = order.items.filter(
      item => item.productName === itemName,
    );

    if (!filteredItems.length) {
      return;
    }

    filteredData.push({ ...order, items: filteredItems });
  });

  return filteredData;
};

const getMenuItemsOrdersFilteredByDates = (
  data: OrderResponseDto[],
  dateFilter: AnalyticsStateDateFilterValue,
): {
  current: OrderResponseDto[];
  prev: OrderResponseDto[];
  groupBy: AnalyticsPeriodGroupByValues;
} => {
  const result = {
    current: [],
    prev: [],
  };

  const { current, prev, groupBy } = generateDates(dateFilter);

  data.forEach(element => {
    if (isTimeBetween(current.startTime, current.endTime, element.updatedAt)) {
      result.current.push(element);
    }
    if (isTimeBetween(prev.startTime, prev.endTime, element.updatedAt)) {
      result.prev.push(element);
    }
  });

  return { ...result, groupBy };
};

const getMenuItemsChartData = (
  data: {
    current: OrderResponseDto[];
    prev: OrderResponseDto[];
    groupBy: AnalyticsPeriodGroupByValues;
  },
  location: string,
): { tabs: AnalyticsStatsSummaryValues[] } => {
  let tabs = [];

  let currentRevenue = 0;
  let prevRevenue = 0;
  let currentItemsSold = 0;
  let prevItemsSold = 0;

  data.current.forEach(order => {
    if (location === "all" || location === order.locationId) {
      order.items.forEach(
        item => (
          (currentItemsSold = currentItemsSold + item.quantity),
          (currentRevenue =
            currentRevenue + item.fiatCentsPrice * item.quantity)
        ),
      );
    }
  });

  data.prev.forEach(order => {
    if (location === "all" || location === order.locationId) {
      order.items.forEach(
        item => (
          (prevItemsSold = prevItemsSold + item.quantity),
          (prevRevenue = prevRevenue + item.fiatCentsPrice * item.quantity)
        ),
      );
    }
  });

  tabs = [
    {
      dataKey: AnalyticsStatsSummaryKey.Revenue,
      value: currentRevenue,
      prevValue: prevRevenue,
    },
    {
      dataKey: AnalyticsStatsSummaryKey.ItemSold,
      value: currentItemsSold,
      prevValue: prevItemsSold,
    },
  ];

  return { tabs };
};

const getOrdersChartData = (
  data: {
    current: OrderResponseDto[];
    prev: OrderResponseDto[];
    groupBy: AnalyticsPeriodGroupByValues;
  },
  location: string,
): { tabs: AnalyticsStatsSummaryValues[] } => {
  let tabs = [];

  let currentTotalOrder = 0;
  let prevTotalOrder = 0;
  let currentRevenue = 0;
  let prevRevenue = 0;
  let currentAverageOrderValue = 0;
  let prevAverageOrderValue = 0;
  let currentOrderIssueOvertime = 0;
  let prevOrderIssueOvertime = 0;

  data.current.forEach(order => {
    if (location === "all" || location === order.locationId) {
      currentTotalOrder++;
      currentRevenue = currentRevenue + order.totalFiatCentsPrice;

      currentAverageOrderValue = Number(
        (currentRevenue / currentTotalOrder).toFixed(3),
      );

      currentOrderIssueOvertime = 1;
    }
  });

  data.prev.forEach(order => {
    if (location === "all" || location === order.locationId) {
      prevTotalOrder++;
      prevRevenue = prevRevenue + order.totalFiatCentsPrice;

      prevAverageOrderValue = Number((prevRevenue / prevTotalOrder).toFixed(3));

      prevOrderIssueOvertime = 0;
    }
  });

  tabs = [
    {
      dataKey: AnalyticsStatsSummaryKey.TotalOrders,
      value: currentTotalOrder,
      prevValue: prevTotalOrder,
    },
    {
      dataKey: AnalyticsStatsSummaryKey.Revenue,
      value: currentRevenue,
      prevValue: prevRevenue,
    },
    {
      dataKey: AnalyticsStatsSummaryKey.AverageOrderValue,
      value: currentAverageOrderValue,
      prevValue: prevAverageOrderValue,
    },
    {
      dataKey: AnalyticsStatsSummaryKey.OrderIssueOvertime,
      value: currentOrderIssueOvertime,
      prevValue: prevOrderIssueOvertime,
    },
  ];

  return { tabs };
};

const getLocationsChartData = (
  data: {
    current: OrderResponseDto[];
    prev: OrderResponseDto[];
    groupBy: AnalyticsPeriodGroupByValues;
  },
  location: string,
): { tabs: AnalyticsStatsSummaryValues[] } => {
  let tabs = [];

  let currentTotalOrder = 0;
  let prevTotalOrder = 0;
  let currentRevenue = 0;
  let prevRevenue = 0;
  let currentAverageOrderValue = 0;
  let prevAverageOrderValue = 0;

  data.current.forEach(order => {
    if (location === "all" || location === order.locationId) {
      currentTotalOrder++;
      currentRevenue = currentRevenue + order.totalFiatCentsPrice;

      currentAverageOrderValue = Number(
        (currentRevenue / currentTotalOrder).toFixed(3),
      );
    }
  });

  data.prev.forEach(order => {
    if (location === "all" || location === order.locationId) {
      prevTotalOrder++;
      prevRevenue = prevRevenue + order.totalFiatCentsPrice;

      prevAverageOrderValue = Number((prevRevenue / prevTotalOrder).toFixed(3));
    }
  });

  tabs = [
    {
      dataKey: AnalyticsStatsSummaryKey.Revenue,
      value: currentRevenue,
      prevValue: prevRevenue,
    },
    {
      dataKey: AnalyticsStatsSummaryKey.Orders,
      value: currentTotalOrder,
      prevValue: prevTotalOrder,
    },
    {
      dataKey: AnalyticsStatsSummaryKey.AverageOrderValue,
      value: currentAverageOrderValue,
      prevValue: prevAverageOrderValue,
    },
  ];

  return { tabs };
};

const getChartComparedWithPrevLinesData = (
  dataKey: AnalyticsChartKey,
  data: {
    current: OrderResponseDto[];
    prev: OrderResponseDto[];
    groupBy: AnalyticsPeriodGroupByValues;
  },
  dateFilter: AnalyticsStateDateFilterValue,
): ChartLinesData => {
  const { groupBy, current, prev } = generateDates(dateFilter);

  const currentSlots: ChartSlotData[] = [];
  const prevSlots: ChartSlotData[] = [];

  if (groupBy === AnalyticsPeriodGroupByValues.Hour) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("hour");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "HH:mm",
        })} - ${dateTransform({ date: endDate, format: "HH:mm" })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Hour,
        data: {
          ...(dataKey === AnalyticsChartKey.MenuItemsChart && {
            [AnalyticsStatsSummaryKey.ItemSold]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
          }),
          ...(dataKey === AnalyticsChartKey.OrdersChart && {
            [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.OrderIssueOvertime]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.TotalOrders]: isFuture ? null : 0,
          }),
          ...(dataKey === AnalyticsChartKey.LocationsChart && {
            [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.Orders]: isFuture ? null : 0,
          }),
        },
      };

      currentSlots.push(slot);

      currentSlot = currentSlot.add(1, "hour");
    }

    let prevSlot = dayjs(prev.startTime);

    while (dayjs(prevSlot).isBefore(dayjs(prev.endTime))) {
      const endDate = dayjs(prevSlot).endOf("hour");

      const slot = {
        name: `${dateTransform({
          date: prevSlot,
          format: "HH:mm",
        })} - ${dateTransform({ date: endDate, format: "HH:mm" })}`,
        slotStartTime: prevSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Hour,
        data: {
          ...(dataKey === AnalyticsChartKey.MenuItemsChart && {
            [AnalyticsStatsSummaryKey.ItemSold]: 0,
            [AnalyticsStatsSummaryKey.Revenue]: 0,
          }),
          ...(dataKey === AnalyticsChartKey.OrdersChart && {
            [AnalyticsStatsSummaryKey.Revenue]: 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: 0,
            [AnalyticsStatsSummaryKey.OrderIssueOvertime]: 0,
            [AnalyticsStatsSummaryKey.TotalOrders]: 0,
          }),
          ...(dataKey === AnalyticsChartKey.LocationsChart && {
            [AnalyticsStatsSummaryKey.Revenue]: 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: 0,
            [AnalyticsStatsSummaryKey.Orders]: 0,
          }),
        },
      };

      prevSlots.push(slot);

      prevSlot = prevSlot.add(1, "hour");
    }
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Day) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("day");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "DD MMM",
        })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Day,
        data: {
          ...(dataKey === AnalyticsChartKey.MenuItemsChart && {
            [AnalyticsStatsSummaryKey.ItemSold]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
          }),
          ...(dataKey === AnalyticsChartKey.OrdersChart && {
            [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.OrderIssueOvertime]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.TotalOrders]: isFuture ? null : 0,
          }),
          ...(dataKey === AnalyticsChartKey.LocationsChart && {
            [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.Orders]: isFuture ? null : 0,
          }),
        },
      };

      currentSlots.push(slot);

      currentSlot = currentSlot.add(1, "day");
    }

    let prevSlot = dayjs(prev.startTime);

    while (dayjs(prevSlot).isBefore(dayjs(prev.endTime))) {
      const endDate = dayjs(prevSlot).endOf("day");

      const slot = {
        name: `${dateTransform({
          date: prevSlot,
          format: "DD MMM",
        })}`,
        slotStartTime: prevSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Day,
        data: {
          ...(dataKey === AnalyticsChartKey.MenuItemsChart && {
            [AnalyticsStatsSummaryKey.ItemSold]: 0,
            [AnalyticsStatsSummaryKey.Revenue]: 0,
          }),
          ...(dataKey === AnalyticsChartKey.OrdersChart && {
            [AnalyticsStatsSummaryKey.Revenue]: 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: 0,
            [AnalyticsStatsSummaryKey.OrderIssueOvertime]: 0,
            [AnalyticsStatsSummaryKey.TotalOrders]: 0,
          }),
          ...(dataKey === AnalyticsChartKey.LocationsChart && {
            [AnalyticsStatsSummaryKey.Revenue]: 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: 0,
            [AnalyticsStatsSummaryKey.Orders]: 0,
          }),
        },
      };

      prevSlots.push(slot);

      prevSlot = prevSlot.add(1, "day");
    }
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Week) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("week");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "DD MMM",
        })} - ${dateTransform({
          date: endDate,
          format: "DD MMM",
        })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Week,
        data: {
          ...(dataKey === AnalyticsChartKey.MenuItemsChart && {
            [AnalyticsStatsSummaryKey.ItemSold]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
          }),
          ...(dataKey === AnalyticsChartKey.OrdersChart && {
            [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.OrderIssueOvertime]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.TotalOrders]: isFuture ? null : 0,
          }),
          ...(dataKey === AnalyticsChartKey.LocationsChart && {
            [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.Orders]: isFuture ? null : 0,
          }),
        },
      };

      currentSlots.push(slot);

      currentSlot = currentSlot.add(1, "week").startOf("week");
    }

    let prevSlot = dayjs(prev.startTime);

    while (dayjs(prevSlot).isBefore(dayjs(prev.endTime))) {
      const endDate = dayjs(prevSlot).endOf("week");

      const slot = {
        name: `${dateTransform({
          date: prevSlot,
          format: "DD MMM",
        })} - ${dateTransform({
          date: endDate,
          format: "DD MMM",
        })}`,
        slotStartTime: prevSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Week,
        data: {
          ...(dataKey === AnalyticsChartKey.MenuItemsChart && {
            [AnalyticsStatsSummaryKey.ItemSold]: 0,
            [AnalyticsStatsSummaryKey.Revenue]: 0,
          }),
          ...(dataKey === AnalyticsChartKey.OrdersChart && {
            [AnalyticsStatsSummaryKey.Revenue]: 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: 0,
            [AnalyticsStatsSummaryKey.OrderIssueOvertime]: 0,
            [AnalyticsStatsSummaryKey.TotalOrders]: 0,
          }),
          ...(dataKey === AnalyticsChartKey.LocationsChart && {
            [AnalyticsStatsSummaryKey.Revenue]: 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: 0,
            [AnalyticsStatsSummaryKey.Orders]: 0,
          }),
        },
      };

      prevSlots.push(slot);

      prevSlot = prevSlot.add(1, "week").startOf("week");
    }
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Month) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("month");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "MMM",
        })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Month,
        data: {
          ...(dataKey === AnalyticsChartKey.MenuItemsChart && {
            [AnalyticsStatsSummaryKey.ItemSold]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
          }),
          ...(dataKey === AnalyticsChartKey.OrdersChart && {
            [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.OrderIssueOvertime]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.TotalOrders]: isFuture ? null : 0,
          }),
          ...(dataKey === AnalyticsChartKey.LocationsChart && {
            [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: isFuture ? null : 0,
            [AnalyticsStatsSummaryKey.Orders]: isFuture ? null : 0,
          }),
        },
      };

      currentSlots.push(slot);

      currentSlot = currentSlot.add(1, "month").startOf("month");
    }

    let prevSlot = dayjs(prev.startTime);

    while (dayjs(prevSlot).isBefore(dayjs(prev.endTime))) {
      const endDate = dayjs(prevSlot).endOf("month");

      const slot = {
        name: `${dateTransform({
          date: prevSlot,
          format: "MMM",
        })}`,
        slotStartTime: prevSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Month,
        data: {
          ...(dataKey === AnalyticsChartKey.MenuItemsChart && {
            [AnalyticsStatsSummaryKey.ItemSold]: 0,
            [AnalyticsStatsSummaryKey.Revenue]: 0,
          }),
          ...(dataKey === AnalyticsChartKey.OrdersChart && {
            [AnalyticsStatsSummaryKey.Revenue]: 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: 0,
            [AnalyticsStatsSummaryKey.OrderIssueOvertime]: 0,
            [AnalyticsStatsSummaryKey.TotalOrders]: 0,
          }),
          ...(dataKey === AnalyticsChartKey.LocationsChart && {
            [AnalyticsStatsSummaryKey.Revenue]: 0,
            [AnalyticsStatsSummaryKey.AverageOrderValue]: 0,
            [AnalyticsStatsSummaryKey.Orders]: 0,
          }),
        },
      };

      prevSlots.push(slot);

      prevSlot = prevSlot.add(1, "month").startOf("month");
    }
  }

  data.current.forEach(order => {
    const slotIndex = currentSlots.findIndex(slot =>
      isTimeBetween(slot.slotStartTime, slot.slotEndTime, order.updatedAt),
    );

    if (dataKey === AnalyticsChartKey.MenuItemsChart) {
      if (slotIndex !== undefined && !!currentSlots[slotIndex]) {
        currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.ItemSold] =
          (currentSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.ItemSold] ||
            0) + order.items.reduce((acc, item) => acc + item.quantity, 0);

        currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.Revenue] =
          (currentSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.Revenue] ||
            0) +
          order.items.reduce(
            (acc, item) => acc + item.fiatCentsPrice * item.quantity,
            0,
          );
      }
    }

    if (dataKey === AnalyticsChartKey.OrdersChart) {
      if (slotIndex !== undefined && !!currentSlots[slotIndex]) {
        currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.TotalOrders] =
          (currentSlots[slotIndex].data?.[
            AnalyticsStatsSummaryKey.TotalOrders
          ] || 0) + 1;

        currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.Revenue] =
          (currentSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.Revenue] ||
            0) + order.totalFiatCentsPrice;

        currentSlots[slotIndex].data[
          AnalyticsStatsSummaryKey.OrderIssueOvertime
        ] = 0;

        currentSlots[slotIndex].data[
          AnalyticsStatsSummaryKey.AverageOrderValue
        ] = Number(
          (
            currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.Revenue] /
            currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.TotalOrders]
          ).toFixed(3),
        );
      }
    }

    if (dataKey === AnalyticsChartKey.LocationsChart) {
      if (slotIndex !== undefined && !!currentSlots[slotIndex]) {
        currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.Orders] =
          (currentSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.Orders] ||
            0) + 1;

        currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.Revenue] =
          (currentSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.Revenue] ||
            0) + order.totalFiatCentsPrice;

        currentSlots[slotIndex].data[
          AnalyticsStatsSummaryKey.AverageOrderValue
        ] = Number(
          (
            currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.Revenue] /
            currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.Orders]
          ).toFixed(3),
        );
      }
    }
  });

  data.prev.forEach(order => {
    const slotIndex = prevSlots.findIndex(slot =>
      isTimeBetween(slot.slotStartTime, slot.slotEndTime, order.updatedAt),
    );

    if (dataKey === AnalyticsChartKey.MenuItemsChart) {
      if (slotIndex !== undefined && !!prevSlots[slotIndex]) {
        prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.ItemSold] =
          (prevSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.ItemSold] ||
            0) + order.items.reduce((acc, item) => acc + item.quantity, 0);

        prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.Revenue] =
          (prevSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.Revenue] || 0) +
          order.items.reduce(
            (acc, item) => acc + item.fiatCentsPrice * item.quantity,
            0,
          );
      }
    }

    if (dataKey === AnalyticsChartKey.OrdersChart) {
      if (slotIndex !== undefined && !!prevSlots[slotIndex]) {
        prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.TotalOrders] =
          (prevSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.TotalOrders] ||
            0) + 1;

        prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.Revenue] =
          (prevSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.Revenue] || 0) +
          order.totalFiatCentsPrice;

        prevSlots[slotIndex].data[
          AnalyticsStatsSummaryKey.OrderIssueOvertime
        ] = 0;

        prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.AverageOrderValue] =
          Number(
            (
              prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.Revenue] /
              prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.TotalOrders]
            ).toFixed(3),
          );
      }
    }

    if (dataKey === AnalyticsChartKey.LocationsChart) {
      if (slotIndex !== undefined && !!prevSlots[slotIndex]) {
        prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.Orders] =
          (prevSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.Orders] || 0) +
          1;

        prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.Revenue] =
          (prevSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.Revenue] || 0) +
          order.totalFiatCentsPrice;

        prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.AverageOrderValue] =
          Number(
            (
              prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.Revenue] /
              prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.Orders]
            ).toFixed(3),
          );
      }
    }
  });

  const isNeedAdditinalSlots = prevSlots?.length > currentSlots?.length;

  let slotsData: MergedChartSlotData[] = [];

  if (!isNeedAdditinalSlots) {
    const slots: MergedChartSlotData[] = currentSlots.map((el, i) => {
      const { data, ...rest } = el;

      if (dataKey === AnalyticsChartKey.MenuItemsChart) {
        return {
          ...rest,
          [AnalyticsStatsSummaryKey.ItemSold]: {
            current: data?.[AnalyticsStatsSummaryKey.ItemSold],
            prev:
              prevSlots[i]?.data?.[AnalyticsStatsSummaryKey.ItemSold] !==
              undefined
                ? prevSlots[i].data[AnalyticsStatsSummaryKey.ItemSold]
                : null,
          },
          [AnalyticsStatsSummaryKey.Revenue]: {
            current: data[AnalyticsStatsSummaryKey.Revenue],
            prev:
              prevSlots[i]?.data?.[AnalyticsStatsSummaryKey.Revenue] !==
              undefined
                ? prevSlots[i].data[AnalyticsStatsSummaryKey.Revenue]
                : null,
          },
          prevSlotStartTime: prevSlots[i]?.slotStartTime || null,
          prevSlotEndTime: prevSlots[i]?.slotEndTime || null,
        };
      }

      if (dataKey === AnalyticsChartKey.OrdersChart) {
        return {
          ...rest,
          [AnalyticsStatsSummaryKey.TotalOrders]: {
            current: data?.[AnalyticsStatsSummaryKey.TotalOrders],
            prev:
              prevSlots[i]?.data?.[AnalyticsStatsSummaryKey.TotalOrders] !==
              undefined
                ? prevSlots[i].data[AnalyticsStatsSummaryKey.TotalOrders]
                : null,
          },
          [AnalyticsStatsSummaryKey.Revenue]: {
            current: data[AnalyticsStatsSummaryKey.Revenue],
            prev:
              prevSlots[i]?.data?.[AnalyticsStatsSummaryKey.Revenue] !==
              undefined
                ? prevSlots[i].data[AnalyticsStatsSummaryKey.Revenue]
                : null,
          },
          [AnalyticsStatsSummaryKey.AverageOrderValue]: {
            current: data[AnalyticsStatsSummaryKey.AverageOrderValue],
            prev:
              prevSlots[i]?.data?.[
                AnalyticsStatsSummaryKey.AverageOrderValue
              ] !== undefined
                ? prevSlots[i].data[AnalyticsStatsSummaryKey.AverageOrderValue]
                : null,
          },
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]: {
            current: data[AnalyticsStatsSummaryKey.OrderIssueOvertime],
            prev:
              prevSlots[i]?.data?.[
                AnalyticsStatsSummaryKey.OrderIssueOvertime
              ] !== undefined
                ? prevSlots[i].data[AnalyticsStatsSummaryKey.OrderIssueOvertime]
                : null,
          },
          prevSlotStartTime: prevSlots[i]?.slotStartTime || null,
          prevSlotEndTime: prevSlots[i]?.slotEndTime || null,
        };
      }

      if (dataKey === AnalyticsChartKey.LocationsChart) {
        return {
          ...rest,
          [AnalyticsStatsSummaryKey.Orders]: {
            current: data?.[AnalyticsStatsSummaryKey.Orders],
            prev:
              prevSlots[i]?.data?.[AnalyticsStatsSummaryKey.Orders] !==
              undefined
                ? prevSlots[i].data[AnalyticsStatsSummaryKey.Orders]
                : null,
          },
          [AnalyticsStatsSummaryKey.Revenue]: {
            current: data[AnalyticsStatsSummaryKey.Revenue],
            prev:
              prevSlots[i]?.data?.[AnalyticsStatsSummaryKey.Revenue] !==
              undefined
                ? prevSlots[i].data[AnalyticsStatsSummaryKey.Revenue]
                : null,
          },
          [AnalyticsStatsSummaryKey.AverageOrderValue]: {
            current: data[AnalyticsStatsSummaryKey.AverageOrderValue],
            prev:
              prevSlots[i]?.data?.[
                AnalyticsStatsSummaryKey.AverageOrderValue
              ] !== undefined
                ? prevSlots[i].data[AnalyticsStatsSummaryKey.AverageOrderValue]
                : null,
          },
          prevSlotStartTime: prevSlots[i]?.slotStartTime || null,
          prevSlotEndTime: prevSlots[i]?.slotEndTime || null,
        };
      }
    });

    slotsData = slotsData.concat(slots);
  }

  if (isNeedAdditinalSlots) {
    const slots: MergedChartSlotData[] = prevSlots.map((el, i) => {
      const { data, name, slotStartTime, slotEndTime, ...rest } = el;

      if (dataKey === AnalyticsChartKey.MenuItemsChart) {
        return {
          ...rest,
          name: currentSlots[i]?.name || `(${name})`,
          [AnalyticsStatsSummaryKey.ItemSold]: {
            prev: data?.[AnalyticsStatsSummaryKey.ItemSold],
            current:
              currentSlots[i]?.data?.[AnalyticsStatsSummaryKey.ItemSold] !==
              undefined
                ? currentSlots[i]?.data[AnalyticsStatsSummaryKey.ItemSold]
                : null,
          },
          [AnalyticsStatsSummaryKey.Revenue]: {
            prev: data[AnalyticsStatsSummaryKey.Revenue],
            current:
              currentSlots[i]?.data?.[AnalyticsStatsSummaryKey.Revenue] !==
              undefined
                ? currentSlots[i]?.data[AnalyticsStatsSummaryKey.Revenue]
                : null,
          },
          slotStartTime: currentSlots[i]?.slotStartTime || null,
          slotEndTime: currentSlots[i]?.slotEndTime || null,
          prevSlotStartTime: slotStartTime,
          prevSlotEndTime: slotEndTime,
        };
      }

      if (dataKey === AnalyticsChartKey.OrdersChart) {
        return {
          ...rest,
          name: currentSlots[i]?.name || `(${name})`,
          [AnalyticsStatsSummaryKey.TotalOrders]: {
            prev: data?.[AnalyticsStatsSummaryKey.TotalOrders],
            current:
              currentSlots[i]?.data?.[AnalyticsStatsSummaryKey.TotalOrders] !==
              undefined
                ? currentSlots[i]?.data[AnalyticsStatsSummaryKey.TotalOrders]
                : null,
          },
          [AnalyticsStatsSummaryKey.Revenue]: {
            prev: data[AnalyticsStatsSummaryKey.Revenue],
            current:
              currentSlots[i]?.data?.[AnalyticsStatsSummaryKey.Revenue] !==
              undefined
                ? currentSlots[i]?.data[AnalyticsStatsSummaryKey.Revenue]
                : null,
          },
          [AnalyticsStatsSummaryKey.AverageOrderValue]: {
            prev: data[AnalyticsStatsSummaryKey.AverageOrderValue],
            current:
              currentSlots[i]?.data?.[
                AnalyticsStatsSummaryKey.AverageOrderValue
              ] !== undefined
                ? currentSlots[i]?.data[
                    AnalyticsStatsSummaryKey.AverageOrderValue
                  ]
                : null,
          },
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]: {
            prev: data[AnalyticsStatsSummaryKey.OrderIssueOvertime],
            current:
              currentSlots[i]?.data?.[
                AnalyticsStatsSummaryKey.OrderIssueOvertime
              ] !== undefined
                ? currentSlots[i]?.data[
                    AnalyticsStatsSummaryKey.OrderIssueOvertime
                  ]
                : null,
          },
          slotStartTime: currentSlots[i]?.slotStartTime || null,
          slotEndTime: currentSlots[i]?.slotEndTime || null,
          prevSlotStartTime: slotStartTime,
          prevSlotEndTime: slotEndTime,
        };
      }

      if (dataKey === AnalyticsChartKey.LocationsChart) {
        return {
          ...rest,
          name: currentSlots[i]?.name || `(${name})`,
          [AnalyticsStatsSummaryKey.Orders]: {
            prev: data?.[AnalyticsStatsSummaryKey.Orders],
            current:
              currentSlots[i]?.data?.[AnalyticsStatsSummaryKey.Orders] !==
              undefined
                ? currentSlots[i]?.data[AnalyticsStatsSummaryKey.Orders]
                : null,
          },
          [AnalyticsStatsSummaryKey.Revenue]: {
            prev: data[AnalyticsStatsSummaryKey.Revenue],
            current:
              currentSlots[i]?.data?.[AnalyticsStatsSummaryKey.Revenue] !==
              undefined
                ? currentSlots[i]?.data[AnalyticsStatsSummaryKey.Revenue]
                : null,
          },
          [AnalyticsStatsSummaryKey.AverageOrderValue]: {
            prev: data[AnalyticsStatsSummaryKey.AverageOrderValue],
            current:
              currentSlots[i]?.data?.[
                AnalyticsStatsSummaryKey.AverageOrderValue
              ] !== undefined
                ? currentSlots[i]?.data[
                    AnalyticsStatsSummaryKey.AverageOrderValue
                  ]
                : null,
          },

          slotStartTime: currentSlots[i]?.slotStartTime || null,
          slotEndTime: currentSlots[i]?.slotEndTime || null,
          prevSlotStartTime: slotStartTime,
          prevSlotEndTime: slotEndTime,
        };
      }
    });

    slotsData = slotsData.concat(slots);
  }

  const legendConfig = [
    {
      ...mainLegendConfig,
      title: `Current period (${getFormattedDate(
        current.startTime,
      )} - ${getFormattedDate(current.endTime)})`,
    },
    {
      ...prevLegendConfig,
      title: `Previous period (${getFormattedDate(
        prev.startTime,
      )} - ${getFormattedDate(prev.endTime)})`,
    },
  ];

  return {
    slotsData,
    legendConfig,
    linesConfig: [mainChartLineConfig, prevChartLineConfig],
  };
};

const createSlotCurrentLocationValues = (
  locationIds: string[],
  isFuture: boolean,
) => {
  return locationIds.reduce((acc, id) => {
    acc[id] = isFuture ? null : 0;
    return acc;
  }, {});
};

const getChartComparedWithLocationsLinesData = (
  dataKey: AnalyticsChartKey,
  data: {
    current: OrderResponseDto[];
    prev: OrderResponseDto[];
    groupBy: AnalyticsPeriodGroupByValues;
  },
  dateFilter: AnalyticsStateDateFilterValue,
  locations: {
    label: string;
    value: string;
  }[],
  activeLocation: string,
): ChartLinesData => {
  const { groupBy, current } = generateDates(dateFilter);

  const locationsIds = locations.map(location => location.value);

  const slotsData: MergedChartSlotData[] = [];

  if (groupBy === AnalyticsPeriodGroupByValues.Hour) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("hour");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot: MergedChartSlotData = {
        name: `${dateTransform({
          date: currentSlot,
          format: "HH:mm",
        })} - ${dateTransform({ date: endDate, format: "HH:mm" })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Hour,
        ...(dataKey === AnalyticsChartKey.MenuItemsChart && {
          [AnalyticsStatsSummaryKey.ItemSold]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
          [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
        }),
        ...(dataKey === AnalyticsChartKey.OrdersChart && {
          [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
          [AnalyticsStatsSummaryKey.AverageOrderValue]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
          [AnalyticsStatsSummaryKey.TotalOrders]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
        }),
        ...(dataKey === AnalyticsChartKey.LocationsChart && {
          [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
          [AnalyticsStatsSummaryKey.AverageOrderValue]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
          [AnalyticsStatsSummaryKey.Orders]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
        }),
      };

      slotsData.push(slot);

      currentSlot = currentSlot.add(1, "hour");
    }
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Day) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("day");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "DD MMM",
        })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Day,
        ...(dataKey === AnalyticsChartKey.MenuItemsChart && {
          [AnalyticsStatsSummaryKey.ItemSold]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
          [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
        }),
        ...(dataKey === AnalyticsChartKey.OrdersChart && {
          [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
          [AnalyticsStatsSummaryKey.AverageOrderValue]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
          [AnalyticsStatsSummaryKey.TotalOrders]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
        }),
        ...(dataKey === AnalyticsChartKey.LocationsChart && {
          [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
          [AnalyticsStatsSummaryKey.AverageOrderValue]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
          [AnalyticsStatsSummaryKey.Orders]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
        }),
      };

      slotsData.push(slot);

      currentSlot = currentSlot.add(1, "day");
    }
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Week) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("week");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "DD MMM",
        })} - ${dateTransform({
          date: endDate,
          format: "DD MMM",
        })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Week,
        ...(dataKey === AnalyticsChartKey.MenuItemsChart && {
          [AnalyticsStatsSummaryKey.ItemSold]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
          [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
        }),
        ...(dataKey === AnalyticsChartKey.OrdersChart && {
          [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
          [AnalyticsStatsSummaryKey.AverageOrderValue]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
          [AnalyticsStatsSummaryKey.TotalOrders]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
        }),
        ...(dataKey === AnalyticsChartKey.LocationsChart && {
          [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
          [AnalyticsStatsSummaryKey.AverageOrderValue]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
          [AnalyticsStatsSummaryKey.Orders]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
        }),
      };

      slotsData.push(slot);

      currentSlot = currentSlot.add(1, "week").startOf("week");
    }
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Month) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("month");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "MMM",
        })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Month,
        ...(dataKey === AnalyticsChartKey.MenuItemsChart && {
          [AnalyticsStatsSummaryKey.ItemSold]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
          [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
        }),
        ...(dataKey === AnalyticsChartKey.OrdersChart && {
          [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
          [AnalyticsStatsSummaryKey.AverageOrderValue]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
          [AnalyticsStatsSummaryKey.TotalOrders]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
        }),
        ...(dataKey === AnalyticsChartKey.LocationsChart && {
          [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
          [AnalyticsStatsSummaryKey.AverageOrderValue]:
            createSlotCurrentLocationValues(locationsIds, isFuture),
          [AnalyticsStatsSummaryKey.Orders]: createSlotCurrentLocationValues(
            locationsIds,
            isFuture,
          ),
        }),
      };

      slotsData.push(slot);

      currentSlot = currentSlot.add(1, "month").startOf("month");
    }
  }

  data.current.forEach(order => {
    const slotIndex = slotsData.findIndex(slot =>
      isTimeBetween(slot.slotStartTime, slot.slotEndTime, order.updatedAt),
    );

    if (dataKey === AnalyticsChartKey.MenuItemsChart) {
      if (slotIndex !== undefined && !!slotsData[slotIndex]) {
        slotsData[slotIndex][AnalyticsStatsSummaryKey.ItemSold][
          order.locationId
        ] =
          (slotsData[slotIndex][AnalyticsStatsSummaryKey.ItemSold][
            order.locationId
          ] || 0) + order.items.reduce((acc, item) => acc + item.quantity, 0);

        slotsData[slotIndex][AnalyticsStatsSummaryKey.Revenue][
          order.locationId
        ] =
          (slotsData[slotIndex][AnalyticsStatsSummaryKey.ItemSold][
            order.locationId
          ] || 0) +
          order.items.reduce(
            (acc, item) => acc + item.fiatCentsPrice * item.quantity,
            0,
          );
      }
    }

    if (dataKey === AnalyticsChartKey.OrdersChart) {
      if (slotIndex !== undefined && !!slotsData[slotIndex]) {
        slotsData[slotIndex][AnalyticsStatsSummaryKey.TotalOrders][
          order.locationId
        ] =
          (slotsData[slotIndex][AnalyticsStatsSummaryKey.TotalOrders][
            order?.locationId
          ] || 0) + 1;

        slotsData[slotIndex][AnalyticsStatsSummaryKey.Revenue][
          order?.locationId
        ] =
          (slotsData[slotIndex][AnalyticsStatsSummaryKey.Revenue][
            order?.locationId
          ] || 0) + order.totalFiatCentsPrice;

        slotsData[slotIndex][AnalyticsStatsSummaryKey.AverageOrderValue][
          order?.locationId
        ] =
          slotsData[slotIndex][AnalyticsStatsSummaryKey.Revenue][
            order?.locationId
          ] /
          slotsData[slotIndex][AnalyticsStatsSummaryKey.TotalOrders][
            order?.locationId
          ];

        slotsData[slotIndex][AnalyticsStatsSummaryKey.OrderIssueOvertime][
          order?.locationId
        ] = 0;
      }
    }

    if (dataKey === AnalyticsChartKey.LocationsChart) {
      if (slotIndex !== undefined && !!slotsData[slotIndex]) {
        slotsData[slotIndex][AnalyticsStatsSummaryKey.Orders][
          order.locationId
        ] =
          (slotsData[slotIndex][AnalyticsStatsSummaryKey.Orders][
            order?.locationId
          ] || 0) + 1;

        slotsData[slotIndex][AnalyticsStatsSummaryKey.Revenue][
          order?.locationId
        ] =
          (slotsData[slotIndex][AnalyticsStatsSummaryKey.Revenue][
            order?.locationId
          ] || 0) + order.totalFiatCentsPrice;

        slotsData[slotIndex][AnalyticsStatsSummaryKey.AverageOrderValue][
          order?.locationId
        ] =
          slotsData[slotIndex][AnalyticsStatsSummaryKey.Revenue][
            order?.locationId
          ] /
          slotsData[slotIndex][AnalyticsStatsSummaryKey.Orders][
            order?.locationId
          ];
      }
    }
  });

  const locationsChartData = locations.reduce((acc, el, i) => {
    const color = analyticsChartLocationsColors[i] || getRandomColor();
    const isActiveLocation = activeLocation === el.value;

    acc[el.value] = {
      title: el.label || el.value,
      color,
      dataKey: el.value,
      stroke: color,
      activeDot: { r: isActiveLocation ? 6 : 0, stroke: color, fill: "#fff" },
    };

    return acc;
  }, {});

  const legendConfig: ChartLegendConfig[] = locations.map((location, i) => ({
    title: locationsChartData[location.value].title,
    color: locationsChartData[location.value].color,
  }));

  const linesConfig: ChartLinesConfig[] = locations.map((location, i) => ({
    dataKey: locationsChartData[location.value].dataKey,
    stroke: locationsChartData[location.value].stroke,
    activeDot: locationsChartData[location.value].activeDot,
  }));

  return {
    slotsData,
    legendConfig,
    linesConfig,
  };
};

export const createChartData = (
  props: ChartProps,
  analyticsFilters: AnalyticsState,
  locations: { label: string; value: string }[],
): CreateChartDataOutput => {
  const isCompareBetweenLocations =
    analyticsFilters.comparing === ChartCompareValues.SelectedLocation;

  if (props.dataKey === AnalyticsChartKey.MenuItemsChart) {
    //TODO: pay attention! temporary function for mocked data
    // find all orders which contain this item by item name
    const filteredBySelectedItem = getMenuItemsOrdersFilteredByItemName(
      props.data,
      props.itemId,
    );

    const filteredByDatesItems = getMenuItemsOrdersFilteredByDates(
      filteredBySelectedItem,
      analyticsFilters.dateFilter,
    );

    const { tabs } = getMenuItemsChartData(
      filteredByDatesItems,
      analyticsFilters.location,
    );

    const chartLinesData = isCompareBetweenLocations
      ? getChartComparedWithLocationsLinesData(
          props.dataKey,
          filteredByDatesItems,
          analyticsFilters.dateFilter,
          locations,
          analyticsFilters.location,
        )
      : getChartComparedWithPrevLinesData(
          props.dataKey,
          filteredByDatesItems,
          analyticsFilters.dateFilter,
        );

    return { tabs, chartLinesData };
  }

  if (props.dataKey === AnalyticsChartKey.OrdersChart) {
    const filteredByDatesItems = getMenuItemsOrdersFilteredByDates(
      props.data,
      analyticsFilters.dateFilter,
    );

    const { tabs } = getOrdersChartData(
      filteredByDatesItems,
      analyticsFilters.location,
    );

    const chartLinesData = isCompareBetweenLocations
      ? getChartComparedWithLocationsLinesData(
          props.dataKey,
          filteredByDatesItems,
          analyticsFilters.dateFilter,
          locations,
          analyticsFilters.location,
        )
      : getChartComparedWithPrevLinesData(
          props.dataKey,
          filteredByDatesItems,
          analyticsFilters.dateFilter,
        );

    return { tabs, chartLinesData };
  }

  if (props.dataKey === AnalyticsChartKey.LocationsChart) {
    const filteredByDatesItems = getMenuItemsOrdersFilteredByDates(
      props.data,
      analyticsFilters.dateFilter,
    );

    const { tabs } = getLocationsChartData(
      filteredByDatesItems,
      props.selectedLocation,
    );

    const chartLinesData = isCompareBetweenLocations
      ? getChartComparedWithLocationsLinesData(
          props.dataKey,
          filteredByDatesItems,
          analyticsFilters.dateFilter,
          locations,
          props.selectedLocation,
        )
      : getChartComparedWithPrevLinesData(
          props.dataKey,
          filteredByDatesItems,
          analyticsFilters.dateFilter,
        );

    return { tabs, chartLinesData };
  }

  return {
    tabs: [],
    chartLinesData: { slotsData: [], linesConfig: [], legendConfig: [] },
  };
};
