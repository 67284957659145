import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const BulletDisabled: FC<SvgIconConstituentValues> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      id="Bullet"
      cx="10"
      cy="10"
      r="8"
      fill="#C6C5C5"
      stroke="#ECECEC"
      strokeWidth="4"
    />
  </svg>
);
