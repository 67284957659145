import { endpoints } from "consts";

export const locationsUrlBuilder = {
  getLocations: (companyId: string): string => {
    return `${endpoints.companies}${companyId}/sites/`;
  },
  deleteLocation: (id: string): string => {
    return `${endpoints.sites}${id}`;
  },
  getLocationAddress: (id: string): string => {
    return `${endpoints.sites}${id}/address/`;
  },
  createLocation: (companyId: string): string => {
    return `${endpoints.companies}${companyId}/sites/`;
  },
  updateAddress: (id: string): string => {
    return `${endpoints.sites}${id}/address/`;
  },
  getChannelLink: ({ siteId, id }: { siteId: string; id: string }): string => {
    return `${endpoints.sites}${siteId}/channels/${id}/`;
  },
  createChannelLink: (siteId: string): string => {
    return `${endpoints.sites}${siteId}/channels/`;
  },
  updateChannelLink: ({
    siteId,
    channelId,
  }: {
    siteId: string;
    channelId: string;
  }): string => {
    return `${endpoints.sites}${siteId}/channels/${channelId}/`;
  },
  updateChannelLinks: (): string => {
    return `${endpoints.channelLinks}`;
  },
  getOpeningHours: (id: string): string => {
    return `${endpoints.sites}${id}/working-hours/`;
  },
  updateOpeningHours: (id: string): string => {
    return `${endpoints.sites}${id}/working-hours/`;
  },
};
