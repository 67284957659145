import styled from "styled-components";
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { theme } from "theme";

export const DeleteButton = styled(Button.Heading)`
  color: ${theme.color.red};
`;

export const InputsContainer = styled(FlexContainer)<{ $withImage?: boolean }>`
  max-width: ${({ $withImage }) => ($withImage ? "384px" : "none")};
  width: 100%;

  @media (max-width: 679px) {
    max-width: none;
  }
`;

export const ModifierContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  margin-bottom: 12px;

  div {
    border-bottom: 1px solid ${theme.color.borderMedium};
  }

  div:last-child {
    border-bottom: none;
  }
`;

export const ModifierItem = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 4px 0 10px;
`;
