import styled from "styled-components";
import { FlexContainer } from "styled/Box";
import { theme } from "theme";

export const ChannelLinkContainer = styled(FlexContainer)<{ $main?: boolean }>`
  padding: 16px 0;

  ${({ $main }) =>
    $main && `border-bottom: 1px solid ${theme.color.borderLight};`};
`;

export const ActionsContainer = styled(FlexContainer)`
  padding: 12px 0;
`;
