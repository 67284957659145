// Lib
import { FC } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Hooks
import { useViewport } from "hooks";
// Types
import { DayType } from "types/common";
import { LocationsSelectItem, OpeningHoursFormTypes } from "types/locations";
// Icons
import { RightBurgerMenuIcon } from "icons";
// Components
import { DropDown } from "components";
import { TagsSelect } from "components/Form";
import { DaySchedule } from "../DaySchedule";
// Styled
import { ContentBox, FlexContainer, InputsGridContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { ButtonsContainer } from "./styled";

import { daysConfig } from "./config";
import { defaultValues } from "./defaultValues";
import { resolver } from "./validation";

interface OpeningHoursFormProps {
  disabled?: boolean;
  openingHoursData?: OpeningHoursFormTypes;
  selectOptions: LocationsSelectItem[];
  handleSendRequest?: (args: OpeningHoursFormTypes) => void;
}

export const OpeningHoursForm: FC<OpeningHoursFormProps> = ({
  disabled,
  openingHoursData,
  selectOptions,
  handleSendRequest,
}) => {
  const { isDesktop } = useViewport();

  const { control, handleSubmit, watch, getValues, setValue } =
    useForm<OpeningHoursFormTypes>({
      defaultValues: openingHoursData
        ? {
            ...openingHoursData,
            locations: openingHoursData.locations?.map(
              el => selectOptions?.find(o => o.id === el).name,
            ),
          }
        : defaultValues,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolver,
    });

  const handleDuplicateValues = (day: DayType) => {
    const currentFieldIndex = daysConfig.findIndex(el => el.dayValue === day);
    const currentValues = getValues(`days.${day}.time`);

    setValue(
      `days.${daysConfig[currentFieldIndex + 1].dayValue}.available`,
      true,
    );
    setValue(
      `days.${daysConfig[currentFieldIndex + 1].dayValue}.time`,
      currentValues,
    );
  };

  const onSubmit: SubmitHandler<OpeningHoursFormTypes> = data => {
    handleSendRequest(data);
  };

  const options =
    selectOptions?.length &&
    selectOptions?.map(o => ({ value: o.name, label: o.name }));

  const dropDownMenuItems = [
    {
      key: "3",
      label: "Save",
      disabled: disabled,
      onClick: () => handleSubmit(onSubmit)(),
    },
  ];

  return (
    <>
      <FlexContainer
        $column
        $gap={12}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ContentBox>
          <InputsGridContainer>
            <Controller
              name="locations"
              control={control}
              render={({ field, fieldState }) => (
                <TagsSelect
                  required
                  {...field}
                  label="Locations"
                  mode="multiple"
                  options={options}
                  fieldState={fieldState}
                  disabled={disabled}
                />
              )}
            />
          </InputsGridContainer>
        </ContentBox>

        {daysConfig.map(({ dayValue, title }) => (
          <DaySchedule
            key={title}
            title={title}
            disabled={disabled}
            dayValue={dayValue}
            control={control}
            watch={watch}
            setValue={setValue}
            handleDuplicateValues={handleDuplicateValues}
          />
        ))}

        <ButtonsContainer>
          {isDesktop ? (
            <Button.Heading
              type="primary"
              htmlType="submit"
              disabled={disabled}
            >
              Save
            </Button.Heading>
          ) : (
            <DropDown trigger={["click"]} items={dropDownMenuItems}>
              <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
            </DropDown>
          )}
        </ButtonsContainer>
      </FlexContainer>
    </>
  );
};
