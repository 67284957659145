// Lib
import { FC, useEffect, useState } from "react";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
// Hooks
import { useViewport } from "hooks";
// Types
import { ProductTag } from "types/products";
// Components
import { Modal } from "components";
import { Input, TagUploadImage } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";

import { resolver } from "./validation";

const defaultValues = { imageUrl: null, name: "" };

export interface TagCreateEditForm {
  imageUrl: string | null;
  name: string;
}

interface TagCreateEditModalProps {
  data: boolean | ProductTag;
  isLoading: boolean;
  onSave: ({
    id,
    formData,
  }: {
    id: string | null;
    formData: TagCreateEditForm;
  }) => void;
  onClose: () => void;
}

export const TagCreateEditModal: FC<TagCreateEditModalProps> = ({
  data,
  isLoading,
  onSave,
  onClose,
}) => {
  const { isMobile } = useViewport();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const { handleSubmit, reset, control, watch, setValue } =
    useForm<TagCreateEditForm>({
      defaultValues,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolver,
    });

  const isEdit = data && typeof data !== "boolean";

  useEffect(() => {
    if (typeof data !== "boolean") {
      const { name, imageUrl } = data;

      reset({
        name,
        imageUrl,
      });
    } else {
      reset(defaultValues);
      setErrorMessage("");
    }
  }, [data]);

  const setImage = (image: string) => {
    setValue("imageUrl", image);
  };

  const image = watch("imageUrl");

  const onSubmit: SubmitHandler<TagCreateEditForm> = formData => {
    const id = typeof data !== "boolean" ? data.id : null;

    onSave({ id, formData });
  };

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    reset(defaultValues);
    setErrorMessage("");
    onClose();
  };

  return (
    <Modal
      open={!!data}
      title={`${isEdit ? "Edit" : "Add"} Tag`}
      onClose={handleClose}
    >
      <TagUploadImage
        open={!!data}
        value={image}
        onChange={setImage}
        disabled={isLoading}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={"name"}
          control={control}
          render={({ field, fieldState }) => (
            <Input
              required
              label="Name"
              placeholder="Enter tag name"
              {...field}
              fieldState={fieldState}
              disabled={isLoading}
            />
          )}
        />

        <FlexContainer
          $fullwidth
          $column={isMobile}
          $justify="flex-end"
          $gap={8}
          $padding="24px 0 0"
        >
          <Button.Heading
            $fullWidth={isMobile}
            disabled={isLoading}
            onClick={handleClose}
          >
            Cancel
          </Button.Heading>

          <Button.Heading
            $fullWidth={isMobile}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Save
          </Button.Heading>
        </FlexContainer>
      </form>
    </Modal>
  );
};
