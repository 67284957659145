import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const StoreIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Placeholder" clipPath="url(#clip0_5446_492)">
      <path
        id="Vector"
        d="M20 4H4V6H20V4ZM21 14V12L20 7H4L3 12V14H4V20H14V14H18V20H20V14H21ZM12 18H6V14H12V18Z"
        fill="#646965"
      />
    </g>
    <defs>
      <clipPath id="clip0_5446_492">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
