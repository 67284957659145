// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { UrlQueryParams } from "types/common";
import {
  CustomerProfileResponseDto,
  GetCustomersResponseDto,
} from "types/customers";
// Helpers

import { customersUrlBuilder } from "./urlBuilder/customers";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";
import { customersMapper } from "utils/mappers";

export const customersAPI = createApi({
  reducerPath: "customersAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["customers", "customer"],
  endpoints: build => ({
    getCustomers: build.query<
      GetCustomersResponseDto,
      { query: UrlQueryParams }
    >({
      query: ({ query }) => ({
        url: customersUrlBuilder.getCustomers({ query }),
        method: "GET",
      }),
      transformResponse: (response: GetCustomersResponseDto) =>
        customersMapper(response),
      providesTags: ["customers"],
    }),
    getCustomer: build.query<CustomerProfileResponseDto, { id: string }>({
      query: ({ id }) => ({
        url: customersUrlBuilder.getCustomer({ id }),
        method: "GET",
      }),
      providesTags: ["customer"],
    }),
  }),
});

export const { useGetCustomersQuery, useGetCustomerQuery } = customersAPI;
