import styled from "styled-components";
import { theme } from "theme";

export const PageWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${theme.breakpoints.m}) {
    min-height: auto;
    padding-top: 60px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;
