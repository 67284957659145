import { InventoryItem } from "types/inventory";

export const changeTableData = (
  prev: InventoryItem[],
  recordId: string,
  value: number,
): InventoryItem[] => {
  const newArray = [...prev];

  const index = newArray.findIndex(el => el.id === recordId);

  newArray[index] = {
    ...newArray[index],
    stockQuantity: value,
  };

  return newArray;
};
