// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  GetExportSnoozeReportData,
  GetExportSnoozeReportRequest,
  GetOperationReportDetailsResponse,
  GetOperationReportsRequest,
  GetOperationReportsResponse,
} from "types/operationReport";
// Helpers
import { operationReportsUrlBuilder } from "./urlBuilder/operationReports";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";
import { operationReportDetailsMapper } from "utils/mappers";

export const operationReportsAPI = createApi({
  reducerPath: "operationsReportsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["operation-reports"],
  endpoints: build => ({
    getOperationReports: build.query<
      GetOperationReportsResponse,
      GetOperationReportsRequest
    >({
      query: ({ query }) => {
        return {
          url: operationReportsUrlBuilder.getOperationReports({
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["operation-reports"],
    }),
    getOperationReport: build.query<
      GetOperationReportDetailsResponse,
      { id: string }
    >({
      query: ({ id }) => {
        return {
          url: operationReportsUrlBuilder.getOperationReport(id),
          method: "GET",
        };
      },
      transformResponse: (response: GetOperationReportDetailsResponse) =>
        operationReportDetailsMapper(response),
      providesTags: ["operation-reports"],
    }),
    getExportSnoozeReport: build.query<
      GetExportSnoozeReportData[],
      GetExportSnoozeReportRequest
    >({
      query: ({ query }) => {
        return {
          url: operationReportsUrlBuilder.getExportSnoozeReport({ query }),
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetOperationReportsQuery,
  useGetOperationReportQuery,
  useLazyGetExportSnoozeReportQuery,
} = operationReportsAPI;
