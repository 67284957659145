import styled from "styled-components";
import { ContentBox } from "styled/Box";

export const ToolTipBody = styled(ContentBox)`
  padding: 8px;
  flex-direction: column;
  gap: 0px;
  min-width: 150px;
`;

export const ToolTipDataContainer = styled.div<{ $small?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 8px;

  ${({ $small }) => $small && `padding: 0px 8px`}
`;
