// Lib
import { FC, ReactNode, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
// Hooks
import { usePermissions, useViewport } from "hooks";
// Types
import { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";
import { Modifier } from "types/modifiers";
import { ProductTag } from "types/products";
// Theme
import { theme } from "theme";
// Helpers
import { createDropDownOptions } from "helpers/createDropDownOptions";
// Icons
import {
  DiningIcon,
  DragIndicatorIcon,
  EyeInvisibleIcon,
  PencilIcon,
  // PencilIcon,
  TrashIcon,
} from "icons";
// Components
import { Badge, Modal, Tooltip } from "components";
import { DropDown } from "components";
import { Checkbox } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button, ButtonType } from "styled/Buttons";
import { Typography } from "styled/Typography";
import {
  BadgeContainer,
  BadgesWrapper,
  Description,
  DisableBadgeWrapper,
  EditItemToolsContainer,
  Image,
  ImagePlaceholder,
  ImageWrapper,
  ModifierItem,
  More,
  Param,
  StyledLabelBadgeWrapper,
  Title,
  Wrapper,
} from "./styled";

type SnoozedButton = {
  Icon?: ReactNode;
  Button: ButtonType;
  isSnoozed: boolean;
  title?: string;
  disabled?: boolean;
  onClick: () => void;
};

interface MenuItemCardProps {
  isPreview?: boolean;
  draggable?: boolean;
  editable?: boolean;
  itemId: string;
  image: string;
  modifiers?: Modifier[];
  labels?: ProductTag[];
  title: string;
  description: string;
  kcal: string;
  kwd: string;
  tags?: string[];
  checked: boolean;
  disabled?: boolean;
  buttonCTA?: SnoozedButton;
  onChange?: (e: CheckboxChangeEvent, id: string) => void;
  handleDeleteItem?: (id: string) => void;
  handleEditItem?: (id: string) => void;
  handleDisableItem?: (id: string) => void;
}

export const MenuItemCard: FC<MenuItemCardProps> = ({
  isPreview,
  draggable,
  editable,
  itemId,
  image,
  modifiers,
  title,
  labels,
  kcal,
  kwd,
  tags,
  description,
  checked,
  disabled,
  buttonCTA,
  onChange,
  handleDeleteItem,
  handleEditItem,
  handleDisableItem,
}) => {
  const { isMobile } = useViewport();

  // temporary hiddden
  const { canUpdateProduct } = usePermissions();

  const [isModifiersShow, setIsModifiersShow] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: itemId,
  });

  const menuItemOptions = createDropDownOptions([
    {
      id: "1",
      label: "Edit product",
      Icon: PencilIcon,
      disabled: !canUpdateProduct,
      onClick: () => handleEditItem(itemId),
    },

    {
      id: "2",
      label: disabled ? "Enable product" : "Disable product",
      Icon: EyeInvisibleIcon,
      onClick: () => handleDisableItem(itemId),
    },
    {
      id: "3",
      label: "Delete product",
      Icon: TrashIcon,
      onClick: () => handleDeleteItem(itemId),
    },
  ]);

  const style = {
    transition,
    background: "white",
    borderRadius: "8px",
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? "100" : "auto",
  };

  return (
    <>
      <div ref={setNodeRef} style={style}>
        <Wrapper
          id={itemId}
          $fullwidth
          $column={isMobile}
          $align={isMobile ? "center" : "flex-start"}
          $gap={16}
          $padding="16px"
          checked={checked}
          disabled={disabled}
        >
          <ImageWrapper>
            <BadgesWrapper>
              {!!disabled && (
                <DisableBadgeWrapper>
                  <Badge title="Disabled" />
                </DisableBadgeWrapper>
              )}

              {!!labels?.length && (
                <StyledLabelBadgeWrapper>
                  {labels?.map(({ id, name }) => (
                    <Badge
                      key={id}
                      title={name}
                      bgColor={theme.color.lightBlue}
                      textColor={theme.color.white}
                    />
                  ))}
                </StyledLabelBadgeWrapper>
              )}

              {!!modifiers?.length && (
                <Button.Native onClick={() => setIsModifiersShow(true)}>
                  <Badge
                    title="Modifiers"
                    bgColor={theme.color.yellow}
                    textColor={theme.color.white}
                  />
                </Button.Native>
              )}
            </BadgesWrapper>

            {image ? (
              <Image src={image} />
            ) : (
              <ImagePlaceholder>
                <DiningIcon height={"120px"} width={"120px"} />
              </ImagePlaceholder>
            )}
          </ImageWrapper>

          <FlexContainer $fullwidth $column $gap={8}>
            <FlexContainer
              $fullwidth
              $align="flex-start"
              $justify="space-between"
              $gap={8}
            >
              <Title>{title}</Title>

              {!isPreview && (
                <EditItemToolsContainer>
                  {!!onChange && (
                    <Checkbox
                      checked={checked}
                      onChange={e => onChange(e, itemId)}
                    />
                  )}

                  {!!editable && (
                    <DropDown trigger={["click"]} items={menuItemOptions}>
                      <div>
                        <More fill={theme.color.text.tertiary} />
                      </div>
                    </DropDown>
                  )}
                </EditItemToolsContainer>
              )}
            </FlexContainer>

            <FlexContainer $align="center" $justify="space-between">
              {kcal ? <Param>{kcal} Kcal</Param> : <div />}
              {kwd ? <Param>{kwd} KWD</Param> : null}
            </FlexContainer>

            <Description>{!!description && description}</Description>

            <BadgeContainer $gap={4}>
              {!tags?.length ? null : tags?.length < 4 ? (
                <>
                  {tags.map(tag => (
                    <Badge key={tag} title={tag} />
                  ))}
                </>
              ) : (
                <>
                  {tags?.slice(0, 2)?.map(tag => (
                    <Badge key={tag} title={tag} />
                  ))}

                  <Tooltip title={tags?.slice(2).join(", ")}>
                    <div>
                      <Badge title={`+${tags.slice(2)?.length} more`} />
                    </div>
                  </Tooltip>
                </>
              )}
            </BadgeContainer>

            {!!draggable && (
              <FlexContainer $justify="flex-end">
                <div
                  style={{ cursor: isDragging ? "grabbing" : "grab" }}
                  {...attributes}
                  {...listeners}
                >
                  <DragIndicatorIcon />
                </div>
              </FlexContainer>
            )}

            {!!buttonCTA && (
              <FlexContainer $justify="flex-end">
                <buttonCTA.Button
                  icon={buttonCTA.Icon}
                  disabled={buttonCTA?.disabled}
                  status={buttonCTA?.isSnoozed ? "danger" : null}
                  onClick={buttonCTA.onClick}
                >
                  {buttonCTA.title}
                </buttonCTA.Button>
              </FlexContainer>
            )}
          </FlexContainer>
        </Wrapper>
      </div>

      <Modal
        title={title}
        open={isModifiersShow}
        width={500}
        onClose={() => setIsModifiersShow(false)}
      >
        <FlexContainer $fullwidth $column $gap={4}>
          <Typography.Title>Modifiers</Typography.Title>

          {modifiers?.map(m => (
            <ModifierItem key={m.id}>{m.name}</ModifierItem>
          ))}
        </FlexContainer>
      </Modal>
    </>
  );
};
