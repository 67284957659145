// Lib
import { FC } from "react";
import dayjs from "dayjs";
// Types
import { EventContentArg } from "@fullcalendar/core";
// Helpers
import { dateTransform } from "helpers/dataHelpers";
// Components
import { Tooltip } from "components";
// Styled
import { FlexContainer } from "styled/Box";
import { Label, Wrapper, BodyWrapper } from "./styled";

export const EventContent: FC<EventContentArg> = ({ event }) => {
  const isTooltipNeeded = dayjs(event.end).diff(event.start, "hours") < 2;

  const startDate = dateTransform({ date: event.start, format: "HH:mm" });

  const endDate = dateTransform({ date: event.end, format: "HH:mm" });

  if (isTooltipNeeded) {
    return (
      <Tooltip
        title={
          <FlexContainer $justify="center" $column>
            <p>{`${startDate} - ${endDate}`}</p>
            <p>{event.title}</p>
          </FlexContainer>
        }
      >
        <div>
          <Wrapper>
            <Label>{`${startDate} - ${endDate}`}</Label>
          </Wrapper>
          <BodyWrapper>
            <Label>{event.title}</Label>
          </BodyWrapper>
        </div>
      </Tooltip>
    );
  }

  return (
    <div>
      <Wrapper>
        <Label>{`${startDate} - ${endDate}`}</Label>
      </Wrapper>
      <BodyWrapper>
        <Label>{event.title}</Label>
      </BodyWrapper>
    </div>
  );
};
