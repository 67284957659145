import styled from "styled-components";
import { FlexContainer } from "styled/Box";

export const FieldsContainer = styled(FlexContainer)<{ $withmargin?: boolean }>`
  ${({ $withmargin }) =>
    $withmargin &&
    `margin: 0 136.5px 0 0;
    
  @media (max-width: 679px) {
    margin: 0 48px 0 0;
  }

  @media (max-width: 480px) {
    margin: 0;
  }
    `}
`;
