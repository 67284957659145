import { TabsProps } from "antd";
import { TagType } from "types/products";

export const items: TabsProps["items"] = [
  {
    key: TagType.Highlights,
    label: "Highlights",
  },
  {
    key: TagType.Alergents,
    label: "Alergents",
  },
];

export const columns = [
  {
    title: "",
    dataIndex: "imageUrl",
    key: "imageUrl",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
];
