// Lib
import { FC } from "react";
// Hooks
import { useViewport } from "hooks";
// Types
import { MenuCategoryType } from "types/menus";
// Theme
import { theme } from "theme";
// Helpers
import { generateUniqueId } from "helpers/dataHelpers";
import { createDropDownOptions } from "helpers/createDropDownOptions";
// Icons
import { ImportExportIcon, MoreVertIcon, PlusIcon } from "icons";
// Components
import { DropDown, SegmentedButtons } from "components";
// Styled
import { ContentBox, FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";

interface Categories {
  isPreview?: boolean;
  activeCategory: string | number;
  categoriesData: MenuCategoryType[];
  handleActiveCategory: (arg: string | number) => void;
  handleCategoriesData?: (arg: MenuCategoryType[]) => void;
  handleOpenCategoryModal?: (args: boolean) => void;
}

export const Categories: FC<Categories> = ({
  isPreview,
  activeCategory,
  categoriesData,
  handleActiveCategory,
  handleCategoriesData,
  handleOpenCategoryModal,
}) => {
  const { isTabletPortrait } = useViewport();

  const handleAddCategory = () => {
    handleOpenCategoryModal(true);
  };

  const handleSort = () => {
    const sortedData = [...categoriesData];

    const isSortedAscending = sortedData[0].order === 0;

    sortedData.sort((a, b) => {
      if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
        return isSortedAscending ? 1 : -1;
      }
      if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
        return isSortedAscending ? -1 : 1;
      }
      return 0;
    });

    if (sortedData[0].order === categoriesData[0].order) {
      handleCategoriesData(sortedData.reverse());

      return;
    }

    handleCategoriesData(sortedData);
  };

  const categoryHandlersOptions = createDropDownOptions([
    {
      id: generateUniqueId(),
      label: "Add Category",
      Icon: PlusIcon,
      onClick: handleAddCategory,
    },
    {
      id: generateUniqueId(),
      label: "Sort",
      Icon: ImportExportIcon,
      onClick: handleSort,
    },
  ]);

  const categoriesOptions = categoriesData.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  return (
    <FlexContainer $fullwidth>
      <ContentBox $column $gap={24}>
        <FlexContainer $align="center" $justify="space-between">
          <Typography.H2>Categories</Typography.H2>

          {!isPreview && (
            <>
              {isTabletPortrait ? (
                <DropDown items={categoryHandlersOptions} trigger={["click"]}>
                  <div>
                    <Button.SquaredIcon
                      icon={<MoreVertIcon fill={theme.color.text.tertiary} />}
                    />
                  </div>
                </DropDown>
              ) : (
                <Button.Form
                  type="primary"
                  icon={<PlusIcon fill="white" />}
                  onClick={handleAddCategory}
                >
                  Add Category
                </Button.Form>
              )}
            </>
          )}
        </FlexContainer>

        <FlexContainer $align="center" $justify="space-between" $gap={24}>
          <SegmentedButtons
            value={activeCategory}
            setValue={handleActiveCategory}
            options={categoriesOptions}
          />

          {!isPreview && !isTabletPortrait && !!categoriesData?.length && (
            <Button.Base
              icon={<ImportExportIcon fill={theme.color.text.tertiary} />}
              onClick={handleSort}
            >
              Sort
            </Button.Base>
          )}
        </FlexContainer>
      </ContentBox>
    </FlexContainer>
  );
};
