import { MenuCategoryItemType } from "types/menus";

export const createDefaultValues = (
  data: { categoryId: string; item: MenuCategoryItemType } | boolean,
) => {
  if (typeof data === "boolean") {
    return undefined;
  }

  return {
    name: data.item.product.name,
    description: data.item.product.description,
    image: data.item.product.imageUrl,
    price: data.item.product.price,
    plu: data.item.product.plu,
    tax: data.item.product.tax,
    modifiers: data.item.modifiers,
  };
};
