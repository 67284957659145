// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  GetOrderRevenueBreakdownAnalyticsRequest,
  GetOrdersAnalyticsRequest,
  GetOrdersAnalyticsResponse,
  GetOrderRevenueBreakdownAnalyticsResponse,
  GetOrderHourlyDistributionAnalyticsRequest,
  GetOrderHourlyDistributionAnalyticsResponse,
} from "types/analytics";
// Helpers
import { analyticsUrlBuilder } from "./urlBuilder/analytics";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";

export const analyticsAPI = createApi({
  reducerPath: "analyticsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),

  endpoints: build => ({
    getOrdersAnalytics: build.query<
      GetOrdersAnalyticsResponse,
      GetOrdersAnalyticsRequest
    >({
      query: ({ companyId, query }) => {
        return {
          url: analyticsUrlBuilder.getOrderAnalytics({ companyId, query }),
          method: "GET",
        };
      },
    }),
    getOrderRevenueBreakdownAnalytics: build.query<
      GetOrderRevenueBreakdownAnalyticsResponse,
      GetOrderRevenueBreakdownAnalyticsRequest
    >({
      query: ({ companyId, query }) => {
        return {
          url: analyticsUrlBuilder.getOrderRevenueBreakdownAnalytics({
            companyId,
            query,
          }),
          method: "GET",
        };
      },
    }),
    getOrderHourlyDistributionAnalytics: build.query<
      GetOrderHourlyDistributionAnalyticsResponse,
      GetOrderHourlyDistributionAnalyticsRequest
    >({
      query: ({ companyId, query }) => {
        return {
          url: analyticsUrlBuilder.getOrderHourlyDistributionAnalytics({
            companyId,
            query,
          }),
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetOrdersAnalyticsQuery,
  useGetOrderRevenueBreakdownAnalyticsQuery,
  useGetOrderHourlyDistributionAnalyticsQuery,
} = analyticsAPI;
