// Lib
import { PropsWithChildren, createContext, useEffect, useState } from "react";
// Api
import {
  useGetFinishedOrdersQuery,
  useGetInprogressOrdersQuery,
  useGetNewOrdersQuery,
  useGetScheduledOrdersQuery,
  useUpdateNewOrderMutation,
  useUpdateOrderMutation,
} from "rtkQuery/query/kitchenOrdersAPI";
import { useGetKitchenSettingsQuery } from "rtkQuery/query/kitchenSettingsAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import {
  useAudio,
  useKitchenSettings,
  useNotification,
  useSocket,
} from "hooks";
// Selectors
import { getActiveKitchen, getKitchenOrderFilters } from "rtkQuery/selectors";
// Types
import {
  ChangeOrderStatus,
  GetKitchenOrdersResponse,
  KitchenOrder,
  KitchenOrderTab,
  kitchenTabsStatuses,
} from "types/kitchen";
import { OrderStatus, OrderStatusLabel } from "types/orders";
// Constants
import { queryParams } from "consts";
// Helpers
import {
  storage,
  checkSelectedOrderStatus,
  handleIncomingOrders,
  removeReadedIncomingOrder,
} from "helpers/dataHelpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { ChangeKitchenModal, OrderNewItemModal } from "components";

import { sounds } from "sounds";

export interface KitchenOrdersContextType {
  isKitchenModal: boolean;
  isUpdateOrderLoading: boolean;
  isUpdateNewOrderLoading: boolean;
  selectedOrder: KitchenOrder | null;
  selectedOrderModal: KitchenOrder | null;
  tab: KitchenOrderTab;
  isOpenModal: boolean;
  incomingOrders: KitchenOrder[];
  newOrders: GetKitchenOrdersResponse;
  inProgressOrders: GetKitchenOrdersResponse;
  finishedOrders: GetKitchenOrdersResponse;
  scheduledOrders: GetKitchenOrdersResponse;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsKitchenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTab: React.Dispatch<React.SetStateAction<KitchenOrderTab>>;
  handleRefresh: () => void;
  handleChangeOrderStatus: (args: ChangeOrderStatus) => void;
  handleOrderSubmit: (orderId: string) => void;
  handleCancelOrder: (orderId: string) => void;
  onOrderSelect: (order: KitchenOrder) => void;
  setSelectedOrder: React.Dispatch<React.SetStateAction<KitchenOrder | null>>;
  handleCloseNewOrderModal: (id: string) => void;
}

export const KitchenOrdersContext = createContext(null);

export const KitchenOrdersProvider = ({ children }: PropsWithChildren) => {
  const kitchen = useAppSelector(getActiveKitchen);
  const { orderBy, orderDirection } = useAppSelector(getKitchenOrderFilters);

  const {
    loadOrdersManually,
    notificationPlaySound,
    notificationSoundDuration,
    notificationSound,
    setAllConfigs,
  } = useKitchenSettings();

  const socket = useSocket({ active: !!kitchen?.id });

  const { play, stop } = useAudio({
    url: sounds[notificationSound],
    duration: notificationSoundDuration,
  });

  const { data: kitchenSettingsData } = useGetKitchenSettingsQuery(
    {
      siteId: kitchen?.id,
    },
    { pollingInterval: 60000, skip: !kitchen?.id },
  );

  useEffect(() => {
    if (!kitchenSettingsData) {
      return;
    }
    setAllConfigs(kitchenSettingsData);
  }, [kitchenSettingsData]);

  const { openNotification } = useNotification();

  const [isKitchenModal, setIsKitchenModal] = useState(false);

  useEffect(() => {
    if (kitchen?.id) return;

    setIsKitchenModal(true);
  }, []);

  const [tab, setTab] = useState(KitchenOrderTab.NEW);
  const [selectedOrder, setSelectedOrder] = useState<KitchenOrder | null>(null);

  const [selectedOrderModal, setSelectedOrderModal] =
    useState<KitchenOrder | null>(null);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [incomingOrders, setIncomingOrders] = useState<KitchenOrder[]>([]);

  const requestParams = {
    siteId: kitchen?.id,
    query: {
      orderBy,
      orderDirection,
    },
  };

  const { data: newOrders, refetch: refetchNew } = useGetNewOrdersQuery(
    requestParams,
    { ...queryParams, skip: !kitchen?.id },
  );

  const { data: inProgressOrders, refetch: refetchInprogress } =
    useGetInprogressOrdersQuery(requestParams, {
      ...queryParams,
      skip: !kitchen?.id,
    });

  const { data: finishedOrders, refetch: refetchFinished } =
    useGetFinishedOrdersQuery(requestParams, {
      ...queryParams,
      skip: !kitchen?.id,
    });

  const { data: scheduledOrders, refetch: refetchScheduled } =
    useGetScheduledOrdersQuery(requestParams, {
      ...queryParams,
      skip: !kitchen?.id,
    });

  const [updateOrder, { isLoading: isUpdateOrderLoading }] =
    useUpdateOrderMutation();

  const [updateNewOrder, { isLoading: isUpdateNewOrderLoading }] =
    useUpdateNewOrderMutation();

  const handleRefetchTabs = (message: OrderStatus[]) => {
    const tabs = Object.keys(kitchenTabsStatuses);

    const resultTabs = tabs.filter(tab => {
      const allowedStatuses: OrderStatus[] = kitchenTabsStatuses[tab];
      return message.some(status => allowedStatuses.includes(status));
    });

    resultTabs.forEach(el => {
      switch (el) {
        case KitchenOrderTab.NEW:
          refetchNew();
          break;
        case KitchenOrderTab.INPROGRESS:
          refetchInprogress();
          break;
        case KitchenOrderTab.FINISHED:
          refetchFinished();
          break;
        case KitchenOrderTab.SCHEDULED:
          refetchScheduled();
          break;

        default:
          break;
      }
    });
  };

  useEffect(() => {
    if (loadOrdersManually) return;

    if (!socket) return;
    if (!kitchen?.id) return;

    socket?.on(
      `notification:${kitchen?.id}`,

      (message: OrderStatus[]) => {
        console.log("message recieved");
        handleRefetchTabs(message);
      },
    );
    return () => {
      stop();
    };
  }, [socket, kitchen?.id, loadOrdersManually]);

  useEffect(() => {
    if (!newOrders) return;

    if (!newOrders.data.length) {
      setIncomingOrders([]);
      storage.set(KitchenOrderTab.NEW, []);
      return;
    }

    const incoming = handleIncomingOrders(newOrders.data);

    if (incoming) {
      setIncomingOrders(incoming);

      if (incoming?.length) {
        notificationPlaySound && play();
      } else {
        stop();
      }
    }
  }, [newOrders]);

  useEffect(() => {
    if (!inProgressOrders) return;
    const status = checkSelectedOrderStatus(selectedOrder, inProgressOrders);

    if (status) {
      setSelectedOrder(prev => ({ ...prev, status }));
    }
  }, [inProgressOrders]);

  useEffect(() => {
    if (!finishedOrders) return;
    const status = checkSelectedOrderStatus(selectedOrder, finishedOrders);

    if (status) {
      setSelectedOrder(prev => ({ ...prev, status }));
    }
  }, [finishedOrders]);

  useEffect(() => {
    if (!scheduledOrders) return;
    const status = checkSelectedOrderStatus(selectedOrder, scheduledOrders);

    if (status) {
      setSelectedOrder(prev => ({ ...prev, status }));
    }
  }, [scheduledOrders]);

  const onUndo = async (orderId: string, status: OrderStatus) => {
    try {
      await updateOrder({
        siteId: kitchen.id,
        orderId,
        data: {
          status,
        },
      }).unwrap();

      openNotification({
        message: `Order Marked as ${OrderStatusLabel[status]}`,
        duration: 10,
      });
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleChangeOrderStatus = async ({
    id,
    next,
    prev,
  }: ChangeOrderStatus) => {
    try {
      await updateOrder({
        siteId: kitchen.id,
        orderId: id,
        data: {
          status: next,
        },
      }).unwrap();

      openNotification({
        message: `Order Marked as ${OrderStatusLabel[next]}`,
        duration: 10,
        notificationButton: {
          label: "Undo",
          onClick: () => onUndo(id, prev),
        },
      });
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleOrderSubmit = async (orderId: string) => {
    try {
      await updateNewOrder({
        siteId: kitchen.id,
        orderId,
        data: {
          status: OrderStatus.ACCEPTED,
        },
      }).unwrap();

      const filteredOrders = removeReadedIncomingOrder(orderId, incomingOrders);

      setIncomingOrders(filteredOrders || []);

      if (orderId === selectedOrderModal?.id) {
        setIsOpenModal(false);
        setSelectedOrderModal(null);
      }

      openNotification({
        message: `Order Accepted`,
        duration: 10,
        notificationButton: {
          label: "Undo",
          onClick: () => onUndo(orderId, OrderStatus.PENDING),
        },
      });
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleCancelOrder = async (orderId: string) => {
    try {
      await updateNewOrder({
        siteId: kitchen.id,
        orderId,
        data: {
          status: OrderStatus.CANCELED,
        },
      }).unwrap();

      const filteredOrders = removeReadedIncomingOrder(orderId, incomingOrders);

      setIncomingOrders(filteredOrders || []);

      if (orderId === selectedOrderModal?.id) {
        setIsOpenModal(false);
        setSelectedOrderModal(null);
      }

      openNotification({
        message: `Order Canceled`,
        duration: 10,
        notificationButton: {
          label: "Undo",
          onClick: () => onUndo(orderId, OrderStatus.PENDING),
        },
      });
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleRefresh = () => {
    switch (tab) {
      case KitchenOrderTab.INPROGRESS:
        return refetchInprogress();
      case KitchenOrderTab.SCHEDULED:
        return refetchScheduled();
      case KitchenOrderTab.FINISHED:
        return refetchFinished();
      default:
        return refetchNew();
    }
  };

  const onOrderSelect = (order: KitchenOrder) => {
    if (kitchenTabsStatuses[KitchenOrderTab.NEW].includes(order?.status)) {
      setSelectedOrderModal(order);
      setIsOpenModal(true);
      return;
    }

    setSelectedOrder(order);
  };

  const handleCloseNewOrderModal = (id: string) => {
    setIncomingOrders(prev => prev.filter(o => o.id !== id));
    stop();
  };

  return (
    <KitchenOrdersContext.Provider
      value={{
        newOrders,
        inProgressOrders,
        finishedOrders,
        scheduledOrders,
        isKitchenModal,
        isUpdateOrderLoading,
        isUpdateNewOrderLoading,
        tab,
        selectedOrder,
        selectedOrderModal,
        isOpenModal,
        incomingOrders,
        setIsOpenModal,
        setTab,
        setIsKitchenModal,
        handleChangeOrderStatus,
        handleOrderSubmit,
        handleCancelOrder,
        handleRefresh,
        onOrderSelect,
        handleCloseNewOrderModal,
        setSelectedOrder,
      }}
    >
      {children}

      {!!incomingOrders?.length &&
        incomingOrders.map(order => (
          <OrderNewItemModal
            isLoading={isUpdateNewOrderLoading}
            key={order.id}
            open
            order={order}
            handleSubmit={handleOrderSubmit}
            handleCloseNewOrder={handleCloseNewOrderModal}
            handleCancelOrder={handleCancelOrder}
          />
        ))}

      <OrderNewItemModal
        isLoading={isUpdateNewOrderLoading}
        order={selectedOrderModal}
        open={isOpenModal}
        handleSubmit={handleOrderSubmit}
        handleClose={() => setIsOpenModal(false)}
        handleCancelOrder={handleCancelOrder}
        handleCloseNewOrder={() => setIsOpenModal(false)}
      />

      <ChangeKitchenModal
        open={isKitchenModal}
        onClose={() => setIsKitchenModal(false)}
      />
    </KitchenOrdersContext.Provider>
  );
};
