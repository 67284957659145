// Lib
import { FC, useEffect, useState } from "react";
// Api
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
import {
  useGetPosEmployeesQuery,
  useUpdatePosEmployeeMutation,
} from "rtkQuery/query/usersAPI";
// Hooks
import { useNotification, usePermissions } from "hooks";
import { useAppSelector } from "hooks/redux";
// Actions
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
// Theme
import { theme } from "theme";
// Constants
// Helpers
import { getFullName } from "helpers/dataHelpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { CloseBlackIcon, DoneBlackIcon, PencilIcon } from "icons";
// Layouts
// Components
import { Avatar, Loader } from "components";
import { Select } from "components/Form";
// Styled
import { ContentBox, FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import {
  PageTitleContainer,
  SelectContainer,
  StyledSearchSelect,
} from "./styled";
import { Spin } from "antd";

export const PosEmployees: FC = () => {
  const { openNotification } = useNotification();

  const { canUpdateSimphonyActive } = usePermissions();

  const companyId = useAppSelector(getUserCompanyId);

  const [isEdit, setIsEdit] = useState(false);
  const [locationIdValue, setLocationIdValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<string>("");

  const [update, { isLoading: isUpdateEmployeeLoading }] =
    useUpdatePosEmployeeMutation();

  const {
    data: locationsData,
    isFetching: isLocationsLoading,
    error: locationsDataError,
  } = useGetLocationsQuery({ companyId });

  const {
    data: posEmployeesData,
    isFetching: isPosEmployeesDataLoading,
    error: posEmployeesDataError,
  } = useGetPosEmployeesQuery(
    { companyId, siteId: locationIdValue },
    { refetchOnMountOrArgChange: true, skip: !companyId || !locationIdValue },
  );

  useEffect(() => {
    if (locationsDataError) {
      errorHandler({ error: locationsDataError, openNotification });
    }
  }, [locationsDataError]);

  useEffect(() => {
    if (posEmployeesDataError) {
      errorHandler({ error: posEmployeesDataError, openNotification });
    }
  }, [posEmployeesDataError]);

  useEffect(() => {
    if (!posEmployeesData?.active) {
      setSelectedUser("");
    }

    setSelectedUser(posEmployeesData?.active?.employeeID);
  }, [posEmployeesData]);

  const onCancel = () => {
    setSelectedUser(posEmployeesData?.active?.employeeID || "");

    setIsEdit(false);
  };

  const onSave = async () => {
    const objectNumber = posEmployeesData.employees.find(
      user => user.employeeID === selectedUser,
    )?.objectNumber;

    try {
      await update({
        employeeID: selectedUser,
        objectNumber,
        companyId,
        siteId: locationIdValue,
      }).unwrap();

      setIsEdit(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const onLocationChange = (value: string) => {
    setLocationIdValue(value);
    setIsEdit(false);
  };

  const locations =
    locationsData?.length &&
    locationsData.map(el => ({ label: el.name, value: el.id }));

  const usersOptions =
    posEmployeesData?.employees.map(
      ({ firstName, lastName, employeeID, objectNumber }) => {
        const name = getFullName({
          firstName,
          lastName,
        });

        const label = `${name} (${objectNumber})`;

        return {
          label: (
            <FlexContainer
              style={{ overflow: "hidden" }}
              key={label}
              $align="center"
              $justify="flex-start"
              $gap={8}
            >
              <Avatar name={label} />
              <div>{label}</div>
            </FlexContainer>
          ),
          value: employeeID,
          searchData: {
            name,
            objectNumber,
          },
        };
      },
    ) || null;

  const filteredOptions = !searchValue
    ? usersOptions
    : usersOptions?.filter(user => {
        const { name, objectNumber } = user.searchData;

        const isNameMatch = name
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase());

        const isObjectIDMatch = objectNumber
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase());

        if (isNameMatch || isObjectIDMatch) {
          return true;
        }
        return false;
      }) || [];

  return (
    <>
      <PageTitleContainer>
        <Typography.H1>Pos Employees</Typography.H1>

        <SelectContainer>
          <Select
            label="Location"
            loading={isLocationsLoading || isUpdateEmployeeLoading}
            value={locationIdValue}
            options={locations}
            disabled={isUpdateEmployeeLoading}
            onChange={value => onLocationChange(value)}
          />
        </SelectContainer>
      </PageTitleContainer>

      <Spin spinning={isPosEmployeesDataLoading} indicator={<Loader />}>
        <ContentBox $column $fullwidth>
          <FlexContainer $fullwidth $justify="space-between">
            <Typography.H2>Active Employee</Typography.H2>
            <FlexContainer
              $gap={8}
              $align="flex-end"
              $justify="flex-end"
              $width="88px"
              style={{ minWidth: 88 }}
            >
              {isEdit ? (
                <Button.SquaredIcon
                  type="primary"
                  icon={<DoneBlackIcon fill={theme.color.white} />}
                  disabled={isUpdateEmployeeLoading}
                  onClick={onSave}
                />
              ) : (
                <div />
              )}
              {canUpdateSimphonyActive && (
                <>
                  {!isEdit ? (
                    <Button.SquaredIcon
                      icon={<PencilIcon />}
                      onClick={() => setIsEdit(true)}
                    />
                  ) : (
                    <Button.CloseSquaredIcon
                      icon={<CloseBlackIcon />}
                      disabled={isUpdateEmployeeLoading}
                      onClick={onCancel}
                    />
                  )}
                </>
              )}
            </FlexContainer>
          </FlexContainer>
          <FlexContainer $gap={8} $align="flex-end">
            <FlexContainer $fullwidth $column>
              <StyledSearchSelect
                label="Select Employee"
                options={filteredOptions}
                searchValue={searchValue}
                onSearchChange={value => setSearchValue(value)}
                value={selectedUser}
                onChange={value => setSelectedUser(value)}
                loading={isUpdateEmployeeLoading || isPosEmployeesDataLoading}
                disabled={!isEdit || isPosEmployeesDataLoading}
              />
            </FlexContainer>
          </FlexContainer>
        </ContentBox>
      </Spin>
    </>
  );
};
