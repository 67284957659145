// Lib
import { FC } from "react";
// Hooks
import { useKitchenOrders, useViewport } from "hooks";
// Types
import { TabsProps } from "antd/es/tabs";
import { KitchenOrderTab } from "types/kitchen";
// Helpers
import { getOrderCountText } from "./helpers";
// Icons
import { DescriptionBlackIcon } from "icons";
// Components
import { NoItemsContent } from "components";
import {
  SelectedOrder,
  SortFilter,
  TabLabel,
  KitchenOrderItem,
  SelectedOrderDrawer,
} from "./components";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import {
  ButtonsContainer,
  ItemsContainer,
  OrdersSidebar,
  PageTitleContainer,
  PageWrapper,
  Refresh,
  SidebarOrderCountInfo,
  SidebarTabs,
} from "./styled";

export const KitchenOrders: FC = () => {
  const { isMiddleDesktop, isTabletPortrait } = useViewport();

  const {
    newOrders,
    inProgressOrders,
    finishedOrders,
    scheduledOrders,
    tab,
    selectedOrder,
    setTab,
    onOrderSelect,
    handleRefresh,
  } = useKitchenOrders() || {};

  const isNewTab = tab === KitchenOrderTab.NEW;
  const isInProgressTab = tab === KitchenOrderTab.INPROGRESS;
  const isFinishedTab = tab === KitchenOrderTab.FINISHED;

  const items: TabsProps["items"] = [
    {
      key: KitchenOrderTab.NEW,
      label: (
        <TabLabel
          labelText={KitchenOrderTab.NEW}
          count={newOrders?.data?.length}
        />
      ),
    },
    {
      key: KitchenOrderTab.INPROGRESS,
      label: (
        <TabLabel
          labelText={KitchenOrderTab.INPROGRESS}
          count={inProgressOrders?.data?.length}
        />
      ),
    },
    {
      key: KitchenOrderTab.FINISHED,
      label: <TabLabel labelText={KitchenOrderTab.FINISHED} />,
    },
    // temporary hidden
    // {
    //   key: KitchenOrderTab.SCHEDULED,
    //   label: (
    //     <TabLabel
    //       labelText={KitchenOrderTab.SCHEDULED}
    //       count={scheduledOrders?.data?.length}
    //     />
    //   ),
    // },
  ];

  const orderCountText = getOrderCountText(tab);

  const orderListData = isNewTab
    ? newOrders?.data
    : isInProgressTab
    ? inProgressOrders?.data
    : isFinishedTab
    ? finishedOrders?.data
    : scheduledOrders?.data;

  return (
    <PageWrapper $fullwidth $column>
      <OrdersSidebar
        width={isTabletPortrait ? "100%" : isMiddleDesktop ? 480 : 280}
      >
        <PageTitleContainer
          $width={
            isTabletPortrait ? "100%" : isMiddleDesktop ? "480px" : "280px"
          }
        >
          <Typography.H1>Orders</Typography.H1>

          <ButtonsContainer>
            <SortFilter />

            <Button.SquaredIcon icon={<Refresh />} onClick={handleRefresh} />
          </ButtonsContainer>
        </PageTitleContainer>

        <SidebarTabs
          $width={
            isTabletPortrait ? "100%" : isMiddleDesktop ? "480px" : "280px"
          }
          items={items}
          onChange={(value: KitchenOrderTab) => setTab(value)}
        />

        {!!orderListData?.length && (
          <SidebarOrderCountInfo
            $width={
              isTabletPortrait ? "100%" : isMiddleDesktop ? "480px" : "280px"
            }
          >{`${orderListData?.length} ${orderCountText}`}</SidebarOrderCountInfo>
        )}

        <ItemsContainer>
          {!!orderListData?.length &&
            orderListData?.map(order => (
              <KitchenOrderItem
                key={order.id}
                order={order}
                selected={order?.id === selectedOrder?.id}
                onSelect={() => onOrderSelect(order)}
              />
            ))}
        </ItemsContainer>

        {!orderListData?.length && (
          <FlexContainer $margin={isMiddleDesktop && "64px 0 0 0"}>
            <NoItemsContent
              Icon={DescriptionBlackIcon}
              message="No order to show"
              description="Try changing sections or change the filters"
            />
          </FlexContainer>
        )}
      </OrdersSidebar>

      {isTabletPortrait ? <SelectedOrderDrawer /> : <SelectedOrder />}
    </PageWrapper>
  );
};
