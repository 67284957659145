// Lib
import { FC, memo } from "react";
import { Select } from "antd";
// Hooks
import { useViewport } from "hooks";
// Icons
import { DownArrowIcon } from "icons";
// Styled
import { PaginationContainer, StyledPagination, Wrapper } from "./styled";

const defaultOptions = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
];

interface PaginationProps {
  showSizeChanger?: boolean;
  pageSize: number;
  setPageSize: (arg: number) => void;
  currentPage: number;
  setCurrentPage: (arg: number) => void;
  shadow?: boolean;
  padding?: string;
  onPage?: boolean;
  pageSizeOptions?: {
    value: number;
    label: string;
  }[];
  totalItems?: number;
}

export const Pagination: FC<PaginationProps> = memo(
  ({
    showSizeChanger,
    padding,
    shadow,
    onPage,
    pageSize,
    currentPage,
    setPageSize,
    setCurrentPage,
    pageSizeOptions,
    totalItems,
  }) => {
    const { isDesktop } = useViewport();

    const handleChange = (value: number) => {
      setPageSize(value);
    };

    const onPageChange = (page: number, size: number) => {
      setCurrentPage(page);

      if (!isDesktop && pageSize !== size) {
        setPageSize(size);
      }
    };

    const getCurrentOrder = () => {
      const startItem = (currentPage - 1) * pageSize + 1;
      const endItem = Math.min(currentPage * pageSize, totalItems);
      return `${startItem}-${endItem} of ${totalItems}`;
    };

    const currentItemsOrder = totalItems && getCurrentOrder();

    return (
      <Wrapper
        $align="center"
        $justify="space-between"
        $shadow={shadow}
        $onPage={onPage}
        $desktop={isDesktop}
        $padding={padding}
      >
        {isDesktop && (
          <div>
            <Select
              suffixIcon={<DownArrowIcon />}
              defaultValue={10}
              value={pageSize}
              style={{ width: 85, marginRight: 10 }}
              onChange={handleChange}
              options={pageSizeOptions || defaultOptions}
            />
            Rows per page
          </div>
        )}

        <PaginationContainer $responsive={!isDesktop}>
          <StyledPagination
            current={currentPage}
            pageSize={pageSize}
            onChange={onPageChange}
            showSizeChanger={showSizeChanger}
            responsive={!isDesktop}
            total={totalItems}
          />
        </PaginationContainer>

        {isDesktop && totalItems ? <div>{currentItemsOrder}</div> : <div />}
      </Wrapper>
    );
  },
);

Pagination.displayName = "Pagination";
