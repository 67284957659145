// Lib
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Hooks
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useViewport } from "hooks";
// Actions
import { setTableFilters } from "rtkQuery/slices/tableFilters";
// Selectors
import { getTableFilters } from "rtkQuery/selectors/tableFilters";
// Constants
import { ADMIN_ROUTES } from "consts";
// Types
import { FilterValue } from "antd/es/table/interface";
import { TablePaginationConfig } from "antd";
import { ETable, FilterStateItem } from "types/tableFilters";
import { GetOrdersResponse, OrderFilterTypes } from "types/orders";
import { Location } from "types/locations";
// Icons
import { DescriptionBlackIcon } from "icons";
// Components
import { Table } from "components";
// Styled

import { columns, filtersList } from "./config";
import { TabKey } from "../../CustomerDetails/config";

interface OrdersTabProps {
  isLoading: boolean;
  locationsData: Location[];
  ordersData: GetOrdersResponse;
  handleSort: (field: string, direction: string) => void;
}

export const OrdersTab: FC<OrdersTabProps> = ({
  isLoading,
  locationsData,
  ordersData,
  handleSort,
}) => {
  const { id } = useParams();
  const { isDesktop } = useViewport();

  const navigate = useNavigate();

  const filters = useAppSelector(getTableFilters);
  const dispatch = useAppDispatch();

  const setTableFilterValues = (filters: FilterStateItem[]) => {
    dispatch(setTableFilters({ name: ETable.CustomerOrders, filters }));
  };

  const filtersData = locationsData
    ? filtersList.map(el =>
        el.key === OrderFilterTypes.location
          ? {
              ...el,
              options: locationsData.map(l => ({ label: l.name, type: l.id })),
            }
          : el,
      )
    : filtersList;

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: any,
  ) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const onRow = record => {
    return {
      onClick: () => {
        navigate(`${ADMIN_ROUTES.ORDERS.path}/${record.id}`, {
          state: {
            backPath: `${ADMIN_ROUTES.CUSTOMERS.path}/${id}?tab=${
              TabKey.Orders || ""
            }`,
          },
        });
      },
    };
  };

  return (
    <Table
      isLoading={isLoading}
      withPagination={!isDesktop}
      dataSource={ordersData?.data || []}
      columns={columns}
      empty={{
        icon: DescriptionBlackIcon,
        title: "No order to show.",
        description: "Try changing sections or change the filters",
      }}
      header={{
        totalCount: ordersData?.total,
        filter: {
          filters: filtersData,
          selected: filters[ETable.CustomerOrders] || [],
          setValue: setTableFilterValues,
        },
      }}
      onChange={handleTableChange}
      onRow={onRow}
    />
  );
};
