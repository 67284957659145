// Lib
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import {
  AnalyticsPeriodFilterValues,
  AnalyticsState,
  AnalyticsStateDateFilterValue,
  ChartCompareValues,
} from "types/analytics";
import { OrderStatus } from "types/orders";

const initialState: AnalyticsState = {
  location: "",
  orderStatus: "",
  dateFilter: {
    value: AnalyticsPeriodFilterValues.Today,
  },
  comparing: ChartCompareValues.PreviousPeriod,
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setAnalyticsDateFilter: (
      state,
      action: PayloadAction<AnalyticsStateDateFilterValue>,
    ) => {
      state.dateFilter = action.payload;
    },
    setAnalyticsLocationFilter: (state, action: PayloadAction<string>) => {
      state.location = action.payload;
    },
    setAnalyticsOrderStatusFilter: (
      state,
      action: PayloadAction<OrderStatus | "">,
    ) => {
      state.orderStatus = action.payload;
    },
    setAnalyticsComparingFilter: (
      state,
      action: PayloadAction<ChartCompareValues>,
    ) => {
      state.comparing = action.payload;
    },
  },
});

export const {
  setAnalyticsDateFilter,
  setAnalyticsLocationFilter,
  setAnalyticsComparingFilter,
  setAnalyticsOrderStatusFilter,
} = analyticsSlice.actions;
export const analyticsSliceReducer = analyticsSlice.reducer;
