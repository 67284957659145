// Lib
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Api
import {
  useGetChannelLinkQuery,
  useUpdateChannelLinkMutation,
} from "rtkQuery/query/locationsAPI";
// Hooks
import { useNotification, usePermissions } from "hooks";
// Types
import { StatusType } from "types/common";
import { ChannelLinkForm } from "types/locations";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { ArrowBackLink, Loader } from "components";
import { EditChannelLinkForm } from "../components";
// Styled
import { Typography } from "styled/Typography";
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";

export const EditChannelLink: FC = () => {
  const { id, siteId } = useParams();

  const navigate = useNavigate();

  const { openNotification } = useNotification();

  const { canUpdateSite } = usePermissions();

  if (!canUpdateSite) {
    navigate(ADMIN_ROUTES.LOCATIONS.path);
  }

  const {
    data,
    isFetching: isGetChannelLinksLoading,
    isError,
    error,
  } = useGetChannelLinkQuery(
    { siteId, id },
    {
      skip: !siteId || !id,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (isError) {
      errorHandler({ error, openNotification });
    }
  }, [isError, error]);

  const [updateChannelLink, { isLoading: isUpdateChannelLinksLoading }] =
    useUpdateChannelLinkMutation();

  const handleSendRequest = async (data: ChannelLinkForm) => {
    try {
      await updateChannelLink({
        channelId: id,
        siteId,
        data: {
          name: data.name,
          status: data.active ? StatusType.Active : StatusType.Suspended,
          simphonyRevenuCenterId: data.simphonyRevenuCenterId,
        },
      }).unwrap();

      openNotification({ message: NOTIFICATIONS.CHANNEL_LINK_ADDED });

      navigate(-1);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const isLoading = isGetChannelLinksLoading || isUpdateChannelLinksLoading;

  return (
    <>
      {isLoading && <Loader />}

      <FlexContainer $column $gap={24}>
        <PageHeadingContainer>
          <PageTitleContainer $column $gap={16}>
            <ArrowBackLink
              title="Back to Location"
              path={ADMIN_ROUTES.LOCATIONS.path}
            />

            <Typography.H1>Edit channel link</Typography.H1>
          </PageTitleContainer>
        </PageHeadingContainer>

        {!!data && (
          <EditChannelLinkForm
            channelLinkData={{
              name: data.name,
              active: data.status === StatusType.Active ? true : false,
              simphonyRevenuCenterId: data.simphonyRevenuCenterId,
            }}
            handleSendRequest={handleSendRequest}
          />
        )}
      </FlexContainer>
    </>
  );
};
