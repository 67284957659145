import styled from "styled-components";
import { FlexContainer } from "styled/Box";

export const DatePickerContainer = styled.div<{ $open: boolean }>`
  width: ${({ $open }) => ($open ? "200px" : 0)};
  transition: width 0.25s;
  overflow: hidden;
`;

export const MobileDatePickerContainer = styled.div<{ $open: boolean }>`
  height: ${({ $open }) => ($open ? "40px" : 0)};
  transition: height 0.25s;
  width: 100%;
  overflow: hidden;
`;

export const SnoozeToolsContainer = styled(FlexContainer)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  @media (max-width: 1023px) {
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 760px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;
