// Lib
import { FC } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// Api
import {
  useGetProductQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useGetProductHighlightsQuery,
  useGetProductsLabelsQuery,
} from "rtkQuery/query/productsAPI";
// Hooks
import { useNotification } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { StatusType } from "types/common";
import { CreateProductRequest, UpdateProductRequest } from "types/products";
import { ProductFormTypes } from "../components/ProductForm/types";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Helpers
import { createInitialData, getRequestTagsData } from "./helpers";
// Components
import { ArrowBackLink, Loader } from "components";
import { ProductForm } from "../components";
// Styled
import { Typography } from "styled/Typography";
import { FlexContainer, PageTitleContainer } from "styled/Box";

export const CreateEditModifier: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const companyId = useAppSelector(getUserCompanyId);

  const { id } = useParams();
  const isEditMode = !!id;

  const { openNotification } = useNotification();

  const [createProduct, { isLoading: isCreateProductLoading }] =
    useCreateProductMutation();

  const [updateProduct, { isLoading: isUpdateProductLoading }] =
    useUpdateProductMutation();

  const { data: productData, isFetching: isProductLoading } =
    useGetProductQuery(id, {
      skip: !id,
      refetchOnMountOrArgChange: true,
    });

  const { data: highlightsData, isLoading: isHighlightsData } =
    useGetProductHighlightsQuery(null);
  const { data: alergentsData, isLoading: isAlergentsData } =
    useGetProductHighlightsQuery(null);
  const { data: labelsData, isLoading: isLabelsData } =
    useGetProductsLabelsQuery(null, { refetchOnMountOrArgChange: true });

  const pageTitle = `${isEditMode ? "Edit" : "Add"} Modifier`;

  const handleCreateProduct = async (data: CreateProductRequest) => {
    try {
      await createProduct(data).unwrap();

      openNotification({ message: NOTIFICATIONS.MODIFIER_ADDED });

      navigate(ADMIN_ROUTES.MODIFIERS.path, { state: location.state });
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleUpdateProduct = async (data: UpdateProductRequest) => {
    try {
      await updateProduct(data).unwrap();

      openNotification({ message: NOTIFICATIONS.MODIFIER_UPDATED });

      navigate(ADMIN_ROUTES.MODIFIERS.path, { state: location.state });
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleSendRequest = (formValues: ProductFormTypes) => {
    const labels = formValues?.labels !== "" ? [formValues?.labels] : [];

    const data = {
      ...formValues,
      highlights: getRequestTagsData(formValues.highlights),
      alergents: getRequestTagsData(formValues.alergents),
      labels,
      status:
        formValues?.status === "active"
          ? StatusType.Active
          : StatusType.Suspended,
    };
    if (isEditMode) {
      handleUpdateProduct({ id, data });
    } else {
      handleCreateProduct({ companyId, data });
    }
  };

  const initialData = productData && createInitialData(productData);

  const isLoading =
    isProductLoading ||
    isCreateProductLoading ||
    isUpdateProductLoading ||
    isHighlightsData ||
    isAlergentsData ||
    isLabelsData;

  return (
    <>
      {isLoading && <Loader />}

      <FlexContainer
        $gap={24}
        $align="center"
        $justify="space-between"
        $margin="0 0 24px"
      >
        <PageTitleContainer $column $gap={16}>
          <ArrowBackLink
            title="Back to Modifiers"
            path={ADMIN_ROUTES.MODIFIERS.path}
          />
          <Typography.H1>{pageTitle}</Typography.H1>
        </PageTitleContainer>
      </FlexContainer>

      <ProductForm
        alergentsData={alergentsData}
        highlightsData={highlightsData}
        labelsData={labelsData}
        initialData={initialData}
        handleSendRequest={handleSendRequest}
      />
    </>
  );
};
