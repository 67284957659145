import styled from "styled-components";
import { ContentBox } from "styled/Box";

export const Wrapper = styled(ContentBox)`
  min-height: 120px;
  width: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;
