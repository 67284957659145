import { OpenNotificationType } from "context/NotificationsContext";

// import { isFieldsResponseError, isGenericResponseError } from "helpers";
import { UseFormSetError } from "react-hook-form";
import {
  ErrorItems,
  ErrorValue,
  SectionErrors,
  SelectionItemErrors,
  isArrayOfString,
  isErrorWithMessage,
  isErrorWithMessageStringField,
  isFieldsResponseError,
  isGenericResponseError,
} from "helpers/services";
import { convertToTitleCase } from "helpers/dataHelpers";
import { permissionLabel } from "consts";

interface errorHandlerArgs<T> {
  error: unknown;
  setError?: UseFormSetError<T>;
  openNotification?: (args: OpenNotificationType) => void;
}

type ErrorsList = string[];
type ErrorDescriptor = { [key: string]: ErrorsList | ErrorDescriptor };

const getPermissionsError = (value: string): string => {
  const splittedString = value.split(":");

  if (!splittedString[1]) {
    return "Don`t have enough permissions to perfom this action. Please contact with administrator";
  }

  const permissionNames: string = splittedString[1]
    .split(",")
    .map(
      permission =>
        `"${permissionLabel[permission.trim()]}"` || `"${permission}"`,
    )
    .join(", ");
  return `Don\`t have folowing permissions: ${permissionNames}. Please contact with administrator`;
};

const combineKeys = (prevKey: string, key: string) => {
  if (isNaN(parseInt(key))) {
    return `${prevKey}.${key}`;
  } else {
    return `${prevKey}[${key}]`;
  }
};

export const errorValueToText = (errorValue: ErrorValue): string => {
  try {
    if (Array.isArray(errorValue)) {
      return errorValue.map(convertToTitleCase).join(" ");
    } else {
      return convertToTitleCase(errorValue);
    }
  } catch (error) {
    return `Something went wrong. The error is the following: ${JSON.stringify(
      errorValue,
      null,
      2,
    )}`;
  }
};

export const analyzeErrorDescriptor = (
  descriptor: ErrorDescriptor,
): string[] => {
  const result: string[] = [];

  const analyzer = (descriptor: ErrorDescriptor, prevKey?: string) => {
    Object.entries(descriptor).forEach(([key, value]) => {
      const currentKey = prevKey ? combineKeys(prevKey, key) : key;

      if (Array.isArray(value)) {
        result.push(`${currentKey}: ${value.join("; ")}`);
      } else if (typeof value === "object" && value != null) {
        analyzer(value, currentKey);
      } else {
        result.push(
          `${currentKey}: [UNEXPECTED TYPE: ${typeof value}]] => ${JSON.stringify(
            value,
          )}`,
        );
      }
    });
  };

  analyzer(descriptor);

  return result;
};

export const errorDescriptorToText = (descriptor: ErrorDescriptor): string => {
  try {
    const analyzedError = analyzeErrorDescriptor(descriptor);
    return analyzedError.join("\n");
  } catch (err) {
    return `Failed to analyze errors. Raw error response: ${JSON.stringify(
      descriptor,
      null,
      2,
    )}`;
  }
};

const toErrorsText = (value: SectionErrors): string[] => {
  const errorsSet = Object.values(value).reduce(
    (acc: Set<string>, error: SelectionItemErrors) => {
      Object.values(error).forEach((errors: string[] | any) => {
        if (Array.isArray(errors)) errors.forEach(error => acc.add(error));
      });
      return acc;
    },
    new Set(),
  ) as Set<string>;
  return Array.from(errorsSet);
};

const formatErrors = (errors: Record<string, string[] | SectionErrors>) => {
  let result = [];

  Object.keys(errors).forEach(field => {
    const value = errors[field];
    const errorTexts = Array.isArray(value) ? value : toErrorsText(value);

    const message = errorTexts.join(". ");

    result = [...result, { field, message }];
  });

  return result;
};

export const errorMessageHandler = (
  errors: ErrorItems,
  openNotification: (args: OpenNotificationType) => void,
) => {
  const showToastErrorMessage = (
    errorItems: ErrorItems,
    allowNestedErrors = true,
  ) => {
    Object.entries(errorItems).forEach(([key, value]) => {
      if (typeof value === "string" || isArrayOfString(value)) {
        const fieldName =
          key !== "non_field_errors" ? `${convertToTitleCase(key)}: ` : "";

        if (
          typeof value === "string" &&
          value.includes("Insufficient permissions")
        ) {
          const permissionsError = getPermissionsError(value);

          openNotification({
            type: "error",
            message: permissionsError,
            duration: 10,
          });
          return;
        }

        openNotification({
          type: "error",
          message: `${fieldName}${errorValueToText(value)}`,
        });
        return;
      } else if (Array.isArray(value) && allowNestedErrors) {
        value.forEach(item => {
          showToastErrorMessage(item, false);
        });
        return;
      } else {
        openNotification({
          type: "error",
          message: `Something wrong in the field "${convertToTitleCase(
            key,
          )}". The error is the following: ${JSON.stringify(value)}`,
        });
        return;
      }
    });
  };

  showToastErrorMessage(errors);
};

export const errorHandler = <T>({
  error,
  setError,
  openNotification,
}: errorHandlerArgs<T>) => {
  if (isFieldsResponseError(error) && setError) {
    const fieldErrors = formatErrors(error.data.errors);
    fieldErrors.forEach(({ field, message }) =>
      setError(field, { type: "custom", message }),
    );
    return;
  } else if (isGenericResponseError(error)) {
    errorMessageHandler(error.data, openNotification);
    return;
  } else if (isErrorWithMessage(error)) {
    openNotification({
      type: "error",
      message: convertToTitleCase(error.error),
    });
    return;
  } else if (isErrorWithMessageStringField(error)) {
    openNotification({
      type: "error",
      message: convertToTitleCase(error.message),
    });
    return;
  } else {
    openNotification({ type: "error", message: "Something went wrong" });
    return;
  }
};
