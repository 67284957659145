import { array, boolean, date, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const dayValidation = object().shape({
  time: array().of(
    object().shape({
      from: date()
        .nullable()
        .required("Time is required")
        .test(
          "from-to",
          "The start time cannot be later than the end time",
          function (value) {
            const { to } = this.parent;
            return (
              !to || (value !== null && value && value.getTime() < to.getTime())
            );
          },
        ),
      to: date()
        .nullable()
        .required("Time is required")
        .test(
          "from-to",
          "The end time cannot be earlier than the start time",
          function (value) {
            const { from } = this.parent;
            return (
              !from ||
              (value !== null && value && value.getTime() > from.getTime())
            );
          },
        ),
    }),
  ),
  available: boolean(),
});

const schema = object().shape({
  locations: array()
    .of(string())
    .min(1, "You must choose at least one location"),
  days: object().shape({
    monday: dayValidation,
    tuesday: dayValidation,
    wednesday: dayValidation,
    thursday: dayValidation,
    friday: dayValidation,
    saturday: dayValidation,
    sunday: dayValidation,
  }),
});

export const resolver = yupResolver(schema);
