import { endpoints } from "consts";

export const kitchenSettingsUrlBuilder = {
  getSettings: (siteId: string): string => {
    return `${endpoints.kitchen}sites/${siteId}/settings/`;
  },

  updateSettings: (siteId: string): string => {
    return `${endpoints.kitchen}sites/${siteId}/settings/`;
  },
  getLoopKitchenSettings: ({
    siteId,
    companyId,
  }: {
    siteId: string;
    companyId: string;
  }): string => {
    return `${endpoints.companies}${companyId}/sites/${siteId}/settings/`;
  },
  updateLoopKitchenSettings: ({
    siteId,
    companyId,
  }: {
    siteId: string;
    companyId: string;
  }): string => {
    return `${endpoints.companies}${companyId}/sites/${siteId}/settings/`;
  },
};
