import styled from "styled-components";

export const List = styled.ul`
  list-style: disc;
  padding-left: 24px;

  li {
    margin-bottom: 4px;
  }
`;
