import { useContext } from "react";
import {
  KitchenOrdersContext,
  KitchenOrdersContextType,
} from "context/KitchenOrdersContext";

export const useKitchenOrders = () => {
  const context = useContext<KitchenOrdersContextType>(KitchenOrdersContext);

  if (context === undefined) {
    throw new Error("NotificationsContext: context undefined");
  }
  return context;
};
