import styled from "styled-components";
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { theme } from "theme";

export const HeadingTitleContainer = styled(FlexContainer)`
  gap: 16px;
  align-items: center;
`;

export const TitleContainer = styled(FlexContainer)`
  gap: 4px;
`;

export const TitleCopy = styled.div`
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.color.text.primary2};
`;

export const DescriptionCopy = styled(Typography.Description)`
  color: ${theme.color.text.tertiary};
`;

export const IconContainer = styled.div`
  border-radius: 50%;
  background: ${theme.color.background.gray};
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
