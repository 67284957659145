// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  Location,
  GetLocationAddressRequest,
  UpdateChannelLinks,
  UpdateLocationAddressRequest,
  CreateLocationAddressRequest,
  GetOpeningHoursRequest,
  ChannelLink,
  ChannelLinkRequestData,
  UpdateOpeningHoursRequest,
} from "types/locations";
// Helpers
import { locationsUrlBuilder } from "./urlBuilder/locations";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";

export const locationsAPI = createApi({
  reducerPath: "locationsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["locations", "locationAddress", "channelLink", "openingHours"],
  endpoints: build => ({
    getLocations: build.query<Location[], { companyId: string }>({
      query: ({ companyId }) => ({
        url: locationsUrlBuilder.getLocations(companyId),
        method: "GET",
      }),
      providesTags: ["locations", "channelLink"],
    }),
    getLocationAddress: build.query<GetLocationAddressRequest, string>({
      query: id => ({
        url: locationsUrlBuilder.getLocationAddress(id),
        method: "GET",
      }),
      providesTags: ["locationAddress"],
    }),
    createLocationAddress: build.mutation<void, CreateLocationAddressRequest>({
      query: ({ companyId, data }) => ({
        url: locationsUrlBuilder.createLocation(companyId),
        method: "POST",
        data,
      }),
      invalidatesTags: ["locations"],
    }),
    updateLocationAddress: build.mutation<void, UpdateLocationAddressRequest>({
      query: ({ id, data }) => ({
        url: locationsUrlBuilder.updateAddress(id),
        method: "PUT",
        data,
      }),
      invalidatesTags: ["locations", "locationAddress"],
    }),
    deleteLocation: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: locationsUrlBuilder.deleteLocation(id),
        method: "DELETE",
      }),
      invalidatesTags: ["locations", "channelLink"],
    }),
    getChannelLink: build.query<ChannelLink, { siteId: string; id: string }>({
      query: data => ({
        url: locationsUrlBuilder.getChannelLink(data),
        method: "GET",
      }),
      providesTags: ["locations", "channelLink"],
    }),
    createChannelLink: build.mutation<void, ChannelLinkRequestData>({
      query: ({ siteId, data }) => ({
        url: locationsUrlBuilder.createChannelLink(siteId),
        method: "POST",
        data,
      }),
      invalidatesTags: ["locations", "channelLink"],
    }),
    updateChannelLink: build.mutation<void, ChannelLinkRequestData>({
      query: ({ siteId, channelId, data }) => ({
        url: locationsUrlBuilder.updateChannelLink({ siteId, channelId }),
        method: "PUT",
        data,
      }),
      invalidatesTags: ["locations", "channelLink"],
    }),
    updateChannelLinks: build.mutation<void, UpdateChannelLinks>({
      query: ({ body }) => ({
        url: locationsUrlBuilder.updateChannelLinks(),
        method: "PUT",
        body,
      }),
      invalidatesTags: ["locations", "channelLink"],
    }),
    getOpeningHours: build.query<GetOpeningHoursRequest, string>({
      query: id => ({
        url: locationsUrlBuilder.getOpeningHours(id),
        method: "GET",
      }),
      providesTags: ["openingHours"],
    }),
    updateOpeningHours: build.mutation<void, UpdateOpeningHoursRequest>({
      query: ({ id, data }) => ({
        url: locationsUrlBuilder.updateOpeningHours(id),
        method: "PUT",
        data,
      }),
      invalidatesTags: ["openingHours"],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useLazyGetLocationsQuery,
  useGetLocationAddressQuery,
  useCreateLocationAddressMutation,
  useUpdateLocationAddressMutation,
  useGetChannelLinkQuery,
  useCreateChannelLinkMutation,
  useUpdateChannelLinkMutation,
  useUpdateChannelLinksMutation,
  useGetOpeningHoursQuery,
  useUpdateOpeningHoursMutation,
  useDeleteLocationMutation,
} = locationsAPI;
