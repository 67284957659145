import styled from "styled-components";
import { FlexContainer } from "styled/Box";
import { theme } from "theme";

export const LocationItem = styled(FlexContainer)`
  border-radius: ${theme.borderRadius.base};
  border: 1px solid ${theme.color.borderLight};
  padding: 16px;
  cursor: pointer;
`;
