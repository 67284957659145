import { OpeningHoursFormTypes } from "types/locations";

export const defaultValues: OpeningHoursFormTypes = {
  locations: [],
  days: {
    monday: {
      available: false,
      time: [],
    },
    tuesday: {
      available: false,
      time: [],
    },
    wednesday: {
      available: false,
      time: [],
    },
    thursday: {
      available: false,
      time: [],
    },
    friday: {
      available: false,
      time: [],
    },
  },
};
