import { yupResolver } from "@hookform/resolvers/yup";
import { bool, object, string } from "yup";

const schema = object({
  active: bool(),
  name: string().required("Name is required"),
  simphonyRevenuCenterId: string().required(
    "Simphony Revenu Center Id is required",
  ),
}).required();

export const resolver = yupResolver(schema);
