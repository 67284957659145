import { Avatar as AntDAvatar } from "antd";
import styled from "styled-components";
import { theme } from "theme";
import { Typography } from "styled/Typography";
import { FlexContainer } from "styled/Box";

export const Wrapper = styled(FlexContainer)<{ $selected: boolean }>`
  padding: 16px 24px;
  border-bottom: 1px solid ${theme.color.borderLight};
  &:hover {
    cursor: pointer;
    background-color: #eff5f0;
  }

  ${({ $selected }) => $selected && `background-color: #eff5f0;`}
`;

export const Id = styled(Typography.Description)`
  color: ${theme.color.text.secondary};
`;

export const Customer = styled(Typography.Description)`
  margin-left: 8px;
  color: ${theme.color.text.tertiary};
`;

export const Avatar = styled(AntDAvatar)`
  background: none;
`;
