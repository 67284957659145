export enum ButtonTitles {
  accepted = "Start Preparing",
  preparing = "Ready for Pickup",
  prepared = "Ready for Pickup",
  ready_for_pickup = "In Delivery",
  pending = "",
  in_delivery = "",
  delivered = "",
  auto_finalized = "",
  canceled = "",
}
