import styled from "styled-components";
import { Collapse as AntDCollapse } from "antd";
import { theme } from "theme";

export const Collapse = styled(AntDCollapse)<{ $nested?: boolean }>`
  border-radius: 8px;
  box-shadow: ${theme.shadow.table};
  filter: none;

  background: ${theme.color.white};
  border: 0;

  .ant-collapse-header {
    padding: 20px 16px !important;
    align-items: center !important;
  }

  .ant-collapse-expand-icon {
    svg {
      transform: rotate(-90deg);
      cursor: pointer;
    }
  }

  ${({ $nested }) =>
    $nested &&
    `
  box-shadow: none;
  border: 1px solid #E0E0E0;

  .ant-collapse-header {
    padding: 8px 16px !important;
    align-items: center !important;
  }

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-content-box {
    padding: 7px 12px 12px !important;
  }
  `}
`;
