// Lib
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuProps } from "antd";
// Hooks
import { useUserRole } from "hooks";
import { useAppDispatch, useAppSelector } from "hooks/redux";
// Actions
import { userLogout } from "rtkQuery/slices";
// Selectors
import { getUserName } from "rtkQuery/selectors";
// Icons
import { ArrowDownIcon, AvatarIcon } from "icons";
// Styled
import {
  ControlsContainer,
  HeaderContainer,
  UserTitle,
  Dropdown,
  Logo,
  Avatar,
} from "./styled";

export const Header: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userName = useAppSelector(getUserName);
  const { isKitchenUser } = useUserRole();

  const { pathname } = useLocation();

  const isAdminRoute = pathname.includes("loop");

  const items: MenuProps["items"] = [
    !isKitchenUser && {
      key: "1",
      label: isAdminRoute ? "Switch to Kitchen" : "Switch to Admin Panel",
      onClick: () => navigate(isAdminRoute ? "/orders" : "/loop/orders"),
    },
    {
      key: "2",
      label: "Logout",
      onClick: () => dispatch(userLogout()),
    },
  ].filter(Boolean);

  return (
    <HeaderContainer>
      <Logo />

      <ControlsContainer>
        <Dropdown items={items} trigger={["click"]}>
          <a onClick={e => e.preventDefault()}>
            <Avatar size={42} icon={<AvatarIcon width="40" height="40" />} />{" "}
            <UserTitle>{userName}</UserTitle> <ArrowDownIcon />
          </a>
        </Dropdown>
      </ControlsContainer>
    </HeaderContainer>
  );
};
