import axios, { AxiosResponse } from "axios";
import { jwtDecode } from "jwt-decode";

import { endpoints } from "consts";

import { token } from "./handleToken";

import { store } from "store";

import { setUser, userLogout } from "rtkQuery/slices";
import { userState } from "types/user";

const API_URL = process.env.REACT_APP_BASE_URL;

const INVALID_TOKEN = 400;

export const refreshAccessToken = async (): Promise<string> => {
  const access = token.access.get();
  const refresh = token.refresh.get();

  return axios
    .post(`${API_URL}/${endpoints.refreshToken}`, {
      accessToken: access.token,
      refreshToken: refresh.token,
    })
    .then((response: AxiosResponse) => {
      const newAccessToken: string = response.data.accessToken;
      const newRefreshToken: string = response.data.refreshToken;

      const decoded: userState = jwtDecode(newAccessToken);

      store.dispatch(setUser(decoded));

      token.access.set(newAccessToken, access.storage);
      token.refresh.set(newRefreshToken, refresh.storage);
      return newAccessToken;
    })
    .catch(error => {
      if (error.response?.status === INVALID_TOKEN) {
        store.dispatch(userLogout());
      }

      throw error;
    });
};
