import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const PencilIcon: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9_88)">
      <path
        d="M3 17.2501V21.0001H6.75L17.81 9.94006L14.06 6.19006L3 17.2501ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006Z"
        fill={fill || "#646965"}
      />
    </g>
    <defs>
      <clipPath id="clip0_9_88">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
