// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { UploadMediaRequest, UploadMediaResponse } from "types/uploadMedia";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";
import { endpoints } from "consts";

export const uploadMediaAPI = createApi({
  reducerPath: "uploadMediaAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),

  endpoints: build => ({
    uploadMediaData: build.mutation<UploadMediaResponse, UploadMediaRequest>({
      query: ({ data }) => ({
        url: endpoints.media,
        method: "POST",
        data,
      }),
    }),
  }),
});

export const { useUploadMediaDataMutation } = uploadMediaAPI;
