import { EPaymentMethod, OrderDetailsResponse } from "types/orders";
import { OrdersTableRowData } from "./types";
import { fixedDigitsValue } from "helpers/dataHelpers";
import { ModifierType } from "types/common";

export const createTableData = (
  data: OrderDetailsResponse,
): OrdersTableRowData[] => {
  const isFmcCentsPaid = data?.paymentMethod === EPaymentMethod.FMC;

  const currency = isFmcCentsPaid ? "FM Coins" : "KWD";

  const tableData: OrdersTableRowData[] = data?.orderItems?.reduce(
    (acc: OrdersTableRowData[], el) => {
      const {
        id,
        productName,
        quantity,
        fmcCentsPrice,
        fiatCentsPrice,
        modifiers,
        remark,
      } = el || {};

      const productPrice = isFmcCentsPaid ? fmcCentsPrice : fiatCentsPrice;

      const totalProductPrice =
        (Number(productPrice) * 1000 * el.quantity) / 1000;

      acc.push({
        id,
        quantity,
        remark,
        type: "product",
        name: productName,
        price: `${fixedDigitsValue(productPrice, 2)} ${currency}`,
        total: `${fixedDigitsValue(totalProductPrice, 2)} ${currency}`,
      });

      if (modifiers?.length) {
        const sortedModifiers = [...modifiers].sort((a, b) => {
          if (
            a.product.modifierType === ModifierType.Addon &&
            b.product.modifierType === ModifierType.Modifier
          ) {
            return -1;
          }
          if (
            a.product.modifierType === ModifierType.Modifier &&
            b.product.modifierType === ModifierType.Addon
          ) {
            return 1;
          }
          return 0;
        });

        sortedModifiers.forEach(subItem => {
          const { id, product, fcmCentsPrice, fiatCentsPrice, quantity } =
            subItem || {};

          const modifiersQuantity = quantity || 1;

          const subItemPrice = isFmcCentsPaid ? fcmCentsPrice : fiatCentsPrice;

          const totalsubItemPrice =
            (Number(subItemPrice) * 1000 * el.quantity * modifiersQuantity) /
            1000;

          acc.push({
            id,
            quantity: modifiersQuantity,
            remark: "",
            type: product.modifierType,
            name: product.name,
            price: !+subItemPrice ? null : `${subItemPrice} ${currency}`,
            total: !+totalsubItemPrice
              ? null
              : `${fixedDigitsValue(totalsubItemPrice, 2)} ${currency}`,
          });
        });
      }

      return acc;
    },
    [],
  );
  return tableData;
};
