import { Switch as AtnDSwitch, SwitchProps as AntDSwitchProps } from "antd";
import { forwardRef } from "react";
import { Label, SwitchContainer } from "./styled";

export interface SwitchProps extends AntDSwitchProps {
  label?: string;
  labelPosition?: "left" | "right";
}

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ label, labelPosition = "right", ...props }, ref) => {
    return (
      <SwitchContainer $labelPosition={labelPosition}>
        <Label>{label}</Label>
        <AtnDSwitch ref={ref} {...props} className="custom-switch" />
      </SwitchContainer>
    );
  },
);

Switch.displayName = "Switch";
