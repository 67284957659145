// Lib
import { FC } from "react";
// Styled
import { Typography } from "styled/Typography";
import { Wrapper } from "./styled";

interface StatisticsSummaryProps {
  title: string;
  text: string | number;
}

export const StatisticsSummary: FC<StatisticsSummaryProps> = ({
  title,
  text,
}) => {
  return (
    <Wrapper>
      <Typography.TitleThin>{title}</Typography.TitleThin>
      <Typography.H2>{text}</Typography.H2>
    </Wrapper>
  );
};
