// Lib
import { FC, memo } from "react";
import { Dayjs } from "dayjs";
// Types
import { EFilterDateValue } from "types/common";
import { FilterCustomDate, FilterStateItem } from "types/tableFilters";
// Helpers
import { dateCreate } from "helpers/dataHelpers";
// Icons
import { CalendarTodayIcon } from "icons";
// Components
import { RangePicker } from "components/Form";
// Styled
import { CloseButton, LabelText } from "../styled";

interface TableDateRangePickerProps {
  name: string;
  selected: FilterStateItem[];
  subValue?: FilterCustomDate;
  setIsOpen: (value: Record<string, boolean>) => void;
  setDateFilterValue: (
    value: string,
    name: string,
    subValue?: FilterCustomDate,
  ) => void;
}

export const TableDateRangePicker: FC<TableDateRangePickerProps> = memo(
  ({ selected, name, subValue, setIsOpen, setDateFilterValue }) => {
    const selectedFilter = selected.find(
      (f: FilterStateItem) => f?.name === name,
    );

    const isCustom = selectedFilter.value === EFilterDateValue.CUSTOM;

    if (!isCustom) {
      return null;
    }

    const dateChangeHandler = (range: [Dayjs, Dayjs], isDate?: boolean) => {
      if (!range?.length) {
        setDateFilterValue(selectedFilter?.value as string, name, {
          [isDate ? "date" : "time"]: { start: null, end: null },
        });

        return;
      }

      if (!range[0] || !range[1]) {
        return;
      }

      const start = dateCreate(range[0]?.second(0))?.toISOString() || null;
      const end = dateCreate(range[1]?.second(0))?.toISOString() || null;

      setDateFilterValue(selectedFilter?.value as string, name, {
        [isDate ? "date" : "time"]: { start, end },
      });
    };

    return (
      <div style={{ padding: "0 10px 15px" }}>
        <div>
          <LabelText>Date</LabelText>

          <RangePicker
            allowEmpty={[true, true]}
            format={"DD/MM/YYYY"}
            allowClear={true}
            suffixIcon={<CalendarTodayIcon />}
            value={[
              dateCreate(subValue?.date?.start || null, true),
              dateCreate(subValue?.date?.end || null, true),
            ]}
            onChange={range => dateChangeHandler(range, true)}
          />
          <LabelText>Time</LabelText>

          <RangePicker
            allowEmpty={[true, true]}
            picker="time"
            format={"HH:mm"}
            allowClear={true}
            suffixIcon={<CalendarTodayIcon />}
            value={[
              dateCreate(subValue?.time?.start || null, true),
              dateCreate(subValue?.time?.end || null, true),
            ]}
            onChange={range => dateChangeHandler(range)}
          />

          <CloseButton onClick={() => setIsOpen({ [name]: false })}>
            Close
          </CloseButton>
        </div>
      </div>
    );
  },
);

TableDateRangePicker.displayName = "TableDateRangePicker";
