import styled from "styled-components";
import { FlexContainer } from "styled/Box";

export const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px;
`;

export const PageWrapper = styled(FlexContainer)`
  min-height: 100%;
`;
