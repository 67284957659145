import styled from "styled-components";
import { theme } from "theme";
import { Table } from "components";
import { Typography } from "styled/Typography";

export const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px;
`;

export const ItemId = styled(Typography.Description)`
  color: ${theme.color.text.tertiary};
`;

export const DescriptionGray = styled(ItemId)``;

export const OrderInfoTable = styled(Table)`
  box-shadow: none;
  .ant-table-thead {
    & .ant-table-cell {
      background: ${theme.color.white};
      border-bottom: 1px solid ${theme.color.borderMedium};
    }
  }

  .ant-table-thead .ant-table-cell,
  .ant-table-tbody .ant-table-cell {
    padding-left: 0px;
    padding-right: 0px;
    padding: 0;
  }

  &.ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: 0px;
  }

  .ant-table-content {
    border-bottom: 1px dashed ${theme.color.borderMedium};
  }

  .ant-table-thead .ant-table-cell {
    background: ${theme.color.white};
  }
`;
