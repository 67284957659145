import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const inventoryUrlBuilder = {
  getInventory: ({
    menuId,
    query,
  }: {
    menuId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(`${endpoints.menus}${menuId}/inventory`, query);
  },
  getKitchenInventory: ({
    kitchenId,
    menuId,
    query,
  }: {
    kitchenId: string;
    menuId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.kitchen}${kitchenId}/menus/${menuId}/inventory`,
      query,
    );
  },
  getInventoryDetails: ({
    id,
    menuId,
    query,
  }: {
    id: string;
    menuId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.menus}${menuId}/inventory/${id}/history`,
      query,
    );
  },
  getKitchenInventoryDetails: ({
    id,
    menuId,
    kitchenId,
    query,
  }: {
    id: string;
    menuId: string;
    kitchenId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.kitchen}${kitchenId}/menus/${menuId}/inventory/${id}/history`,
      query,
    );
  },
  updateInventory: (menuId: string, menuItemId: string): string => {
    return `${endpoints.menus}${menuId}/inventory/${menuItemId}`;
  },
  updateKitchenInventory: ({
    kitchenId,
    menuId,
    menuItemId,
  }: {
    kitchenId: string;
    menuId: string;
    menuItemId: string;
  }): string => {
    return `${endpoints.kitchen}${kitchenId}/menus/${menuId}/inventory/${menuItemId}`;
  },
};
