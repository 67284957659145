import styled from "styled-components";
import { theme } from "theme";

import { Tabs } from "components/Tabs";

import { FlexContainer } from "styled/Box";

export const Wrapper = styled(FlexContainer)<{
  $ordersCalendarTable?: boolean;
  $isSelectedFilter?: boolean;
}>`
  background-color: ${theme.color.white};
  padding: ${props => (props.$isSelectedFilter ? "12px 16px 0" : "12px 16px")};
  border-bottom: 1px solid ${theme.color.borderLight};
  min-height: 64px;

  ${({ $ordersCalendarTable }) => $ordersCalendarTable && `border: 0;`};
`;

export const HeaderTabs = styled(Tabs)<{ $headerItemsCount: number }>`
  max-width: ${({ $headerItemsCount }) =>
    $headerItemsCount > 0 && `calc(100% - ${$headerItemsCount * 50}px)`};

  &.ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 -16px 0;
  }
`;

export const RedDot = styled.div`
  position: absolute;
  top: 7px;
  right: 4px;
  border-radius: 5px;
  width: 8px;
  height: 8px;
  background-color: ${theme.color.red};
`;

export const MobileSearchWrapper = styled.div<{ $isTabs?: boolean }>`
  transition: height 0.2s;
  box-sizing: border-box;
  padding: 0;
  background: white;
  overflow: hidden;

  .ant-input-affix-wrapper {
    margin: ${({ $isTabs }) =>
      $isTabs ? `20px 0 !important` : `10px 0 !important`};
  }
`;

export const MobileFilterButtonWrapper = styled.div`
  position: relative;
`;
