import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const CopyIcon: FC<SvgIconConstituentValues> = ({ fill, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_517_83569)">
      <path
        d="M15 20H5V7C5 6.45 4.55 6 4 6C3.45 6 3 6.45 3 7V20C3 21.1 3.9 22 5 22H15C15.55 22 16 21.55 16 21C16 20.45 15.55 20 15 20ZM20 16V4C20 2.9 19.1 2 18 2H9C7.9 2 7 2.9 7 4V16C7 17.1 7.9 18 9 18H18C19.1 18 20 17.1 20 16ZM18 16H9V4H18V16Z"
        fill={fill || "black"}
      />
    </g>
    <defs>
      <clipPath id="clip0_517_83569">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
