// Lib
import { FC, memo, useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
// Hooks
import { useTable } from "hooks";
// Types
import { RangePickerProps } from "antd/es/date-picker";
import { ETable, FilterOption } from "types/tableFilters";
import { MenusScheduleCalendarEvent } from "types/menuScheduling";
// Components
import { Calendar } from "components";
import { DatePicker } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";

import { createEvents } from "./helpers";

interface OverviewProps {
  isActive: boolean;
  isLoading: boolean;
  filtersData: FilterOption[];
  data: MenusScheduleCalendarEvent[];
  onEventClick: (eventId: string) => void;
}

export const Overview: FC<OverviewProps> = memo(
  ({ isLoading, isActive, filtersData, data, onEventClick }) => {
    const { selectedFilters, handleSetTableFilterValues } = useTable({
      name: ETable.MenuScheduling,
      removeQueryParams: true,
    });

    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(() =>
      dayjs(),
    );

    const events = useMemo(
      () => createEvents(data, selectedDate),
      [selectedDate, data],
    );

    const setNext = useCallback(() => {
      setSelectedDate(prev => prev.add(1, "week"));
    }, []);

    const setPrev = useCallback(() => {
      setSelectedDate(prev => prev.subtract(1, "week"));
    }, []);

    const setToday = useCallback(() => {
      setSelectedDate(dayjs());
    }, []);

    const disabledDate: RangePickerProps["disabledDate"] = current => {
      // Can not select days before this week
      return current < dayjs().startOf("week");
    };

    return (
      <>
        <FlexContainer $fullwidth $margin="0 0 24px">
          <FlexContainer $width="200px">
            <DatePicker
              label="Select date"
              picker="week"
              allowClear={false}
              value={selectedDate}
              disabledDate={disabledDate}
              onChange={value => setSelectedDate(value)}
            />
          </FlexContainer>
        </FlexContainer>

        {isActive && (
          <Calendar
            isActive={isActive}
            isLoading={isLoading}
            selectedDate={selectedDate}
            dataSource={events}
            filter={{
              filters: filtersData,
              selected: selectedFilters,
              setValue: handleSetTableFilterValues,
            }}
            onEventClick={onEventClick}
            setNext={setNext}
            setPrev={setPrev}
            setToday={setToday}
          />
        )}
      </>
    );
  },
);

Overview.displayName = "Overview";
