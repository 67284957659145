// Lib
import styled from "styled-components";
import {
  Button as AntDButton,
  Avatar as AntDAvatar,
  Menu as AntDMenu,
  Drawer as AntDDrawer,
} from "antd";
// Theme
import { theme } from "theme";
import { LogoIcon } from "icons";

export const Logo = styled(LogoIcon)`
  path {
    fill: ${theme.color.white};
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${theme.color.brand};
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

export const Button = styled(AntDButton)`
  display: flex;
  align-items: center;
`;

export const UserTitle = styled.span`
  padding: 0 7px 0 10px;
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.fontSize.base};
  line-height: ${theme.fontSize.md};
  color: ${theme.color.text.primary2};
`;

export const Avatar = styled(AntDAvatar)`
  background: none;
`;

export const BurgerButton = styled.button<{
  $collapsed: boolean;
  $color: string;
}>`
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  i {
    background: ${({ $color }) => $color && $color};
    width: 25px;
    height: 2px;
    display: block;
    position: absolute;
    transition: 0.35s;
  }

  i:nth-child(1) {
    transform: translateY(-7px);
  }

  i:nth-child(3) {
    transform: translateY(7px);
  }

  ${({ $collapsed }) =>
    $collapsed &&
    `
    i:nth-child(1) {
      transform: translateY(0px) rotate(45deg)
      
    }

    i:nth-child(2) {
      width: 0px;
    }

    i:nth-child(3) {

      transform: translateY(0px) rotate(135deg)
    }
  `}
`;

export const Drawer = styled(AntDDrawer)`
  .ant-drawer-body {
    padding: 14px;
    min-height: 100vh;
  }
`;

export const Menu = styled(AntDMenu)`
  border: 0 !important;

  color: ${theme.color.text.primary2};
  line-height: ${theme.lineHeight.md} !important;
  font-size: ${theme.fontSize.md} !important;
  font-weight: ${theme.fontWeight.regular} !important;

  .ant-menu-item {
    padding: 0 16px !important;
    margin: 0;
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;

    cursor: pointer;

    a {
      color: ${theme.color.text.primary2};
      cursor: pointer;
    }
  }

  .ant-menu-submenu {
    margin: 0;

    .ant-menu-sub {
      background: ${theme.color.white} !important;

      .ant-menu-item {
        padding: 0px 16px 0px 46px !important;
      }
    }

    .ant-menu-submenu-title {
      padding: 0px 16px !important;
      margin: 0;
      height: 48px;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .ant-menu-item-selected {
    background: ${theme.color.borderLight};

    a {
      color: ${theme.color.text.primary2};
    }
  }

  &.ant-menu-inline-collapsed {
    .ant-menu-item {
      padding: 6px 14px !important;
    }

    .ant-menu-submenu-title {
      padding: 6px 14px !important;
    }
  }

  svg {
    height: 20px !important;
    width: 20px !important;
    min-width: 20px !important;
    line-height: ${theme.lineHeight.md} !important;
    font-size: ${theme.fontSize.md} !important;
  }
`;

export const SideBarButton = styled.button`
  padding: 0 16px;
  background-color: white;
  border: 0;
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0.06);
  }

  span {
    margin-left: 8px;
    color: #031306;
    color: ${theme.color.text.primary2};
    line-height: ${theme.lineHeight.md};
    font-size: ${theme.fontSize.md};
    font-weight: ${theme.fontWeight.regular};
  }
`;
