// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  KitchenChannelLink,
  KitchenChannelLinkRequestData,
} from "types/kitchen";
// Helpers
import { kitchenLocationsUrlBuilder } from "./urlBuilder/kitchenLocations";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";

export const kitchenLocationsAPI = createApi({
  reducerPath: "kitchenLocationsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),

  endpoints: build => ({
    getKitchenChannelLinks: build.query<
      KitchenChannelLink[],
      { siteId: string }
    >({
      query: ({ siteId }) => ({
        url: kitchenLocationsUrlBuilder.getLocationChannelLinks(siteId),
        method: "GET",
      }),
    }),
    updateKitchenChannelLink: build.mutation<
      void,
      KitchenChannelLinkRequestData
    >({
      query: ({ id, siteId, data }) => ({
        url: kitchenLocationsUrlBuilder.updateChannelLink(id, siteId),
        method: "PUT",
        data,
      }),
    }),
  }),
});

export const {
  useGetKitchenChannelLinksQuery,
  useUpdateKitchenChannelLinkMutation,
} = kitchenLocationsAPI;
