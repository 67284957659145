import styled from "styled-components";
import { theme } from "theme";
import { Typography } from "styled/Typography";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(247, 250, 248);
  cursor: pointer;
`;

export const Label = styled(Typography.Label)`
  margin: 0;
  padding: 4px;
  cursor: pointer;
`;

export const BodyWrapper = styled(Wrapper)`
  background: ${theme.color.white};
  height: auto;
  padding: 4px;
`;
