import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const ProductIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1120_64640)">
      <path
        d="M20 2H4C3 2 2 2.9 2 4V7.01C2 7.73 2.43 8.35 3 8.7V20C3 21.1 4.1 22 5 22H19C19.9 22 21 21.1 21 20V8.7C21.57 8.35 22 7.73 22 7.01V4C22 2.9 21 2 20 2ZM14 14H10C9.45 14 9 13.55 9 13C9 12.45 9.45 12 10 12H14C14.55 12 15 12.45 15 13C15 13.55 14.55 14 14 14ZM20 7H4V4H20V7Z"
        fill="#646965"
      />
    </g>
    <defs>
      <clipPath id="clip0_1120_64640">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
