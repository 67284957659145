export const columns = [
  {
    title: "Locations",
    dataIndex: "sites",
    key: "sites",
  },
  {
    title: "Channel links",
    dataIndex: "channels",
    key: "channels",
  },
  {
    title: "Menus",
    dataIndex: "menus",
    key: "menus",
  },
  {
    title: "Publish times",
    dataIndex: "publishTimes",
    key: "publishTimes",
  },
];
