// Lib
import { FC, memo, useCallback, useEffect, useMemo, useRef } from "react";
import dayjs from "dayjs";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  DayHeaderContentArg,
  EventClickArg,
  EventContentArg,
  SlotLabelContentArg,
} from "@fullcalendar/core";
// Hooks
import { useAppSelector } from "hooks/redux";
// Selectors
import { isUserSideBarOpenSelector } from "rtkQuery/selectors/ui";
// Types
import { ScheduledEvent } from "types/common";
import { TableFilters } from "types/tableFilters";
// Helpers
import { dateTransform } from "helpers/dataHelpers";
// Components
import { Spin } from "antd";
import { Loader } from "components";
import { CalendarHeader, EventContent } from "./components";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import {
  CalendarWrapper,
  HeaderWrapper,
  CellTitle,
  NextIcon,
  PrevIcon,
} from "./styled";

interface CalendarProps {
  isLoading: boolean;
  isActive?: boolean;
  selectedDate: dayjs.Dayjs;
  dataSource: ScheduledEvent[];
  filter?: TableFilters;
  onEventClick: (eventId: string) => void;
  setNext: () => void;
  setPrev: () => void;
  setToday: () => void;
}

export const Calendar: FC<CalendarProps> = memo(
  ({
    isActive,
    isLoading,
    dataSource,
    selectedDate,
    filter,
    setNext,
    setPrev,
    setToday,
    onEventClick,
  }) => {
    const sideBar = useAppSelector(isUserSideBarOpenSelector);

    const calendarRef = useRef(null);

    useEffect(() => {
      queueMicrotask(() =>
        calendarRef.current?.getApi()?.gotoDate(selectedDate.toISOString()),
      );
    }, [calendarRef, selectedDate]);

    useEffect(() => {
      setTimeout(() => calendarRef.current?.getApi()?.updateSize(), 300);
    }, [sideBar, isActive]);

    const renderHeaderContent = useCallback(
      (info: DayHeaderContentArg) => (
        <CellTitle>
          {dateTransform({ date: info.date, format: "ddd, DD MMM" })}
        </CellTitle>
      ),
      [],
    );

    const renderSlotContent = useCallback(
      (info: SlotLabelContentArg) => <CellTitle>{info.text}</CellTitle>,
      [],
    );

    const renderEventContent = (eventInfo: EventContentArg) => (
      <EventContent {...eventInfo} />
    );

    const slotLabelFormat = useCallback(
      ({ date }) => date.hour.toString().padStart(2, "0") + ":00",
      [],
    );

    const handleEventClick = (arg: EventClickArg) => {
      onEventClick(arg.event.extendedProps?.dataId);
    };

    const generateTitle = () => {
      const start = selectedDate.startOf("week");
      const end = selectedDate.endOf("week");

      const isTheSameMonth = start.get("month") === end.get("month");

      if (isTheSameMonth) {
        return `${start.format("DD")} - ${end.format("DD MMMM YYYY")}`;
      }

      return `${start.format("DD MMMM")} - ${end.format("DD MMMM YYYY")}`;
    };

    const validRange = useMemo(
      () => ({
        start: dayjs().toISOString(),
        end: null,
      }),
      [],
    );

    const isPrevButtonDisabled = selectedDate
      .add(-1, "week")
      .isBefore(dayjs().startOf("week"));

    const title = generateTitle();

    return (
      <CalendarWrapper>
        <HeaderWrapper>
          <CalendarHeader
            isLoading={false}
            filter={filter}
            leftTitle={title}
            suffix={
              <FlexContainer $fullwidth $gap={4} $justify="flex-end">
                <Button.Base
                  type="primary"
                  disabled={isPrevButtonDisabled}
                  onClick={setPrev}
                >
                  <PrevIcon />
                </Button.Base>
                <Button.Base type="primary" onClick={setToday}>
                  today
                </Button.Base>
                <Button.Base type="primary" onClick={setNext}>
                  <NextIcon />
                </Button.Base>
              </FlexContainer>
            }
          />
        </HeaderWrapper>

        <Spin spinning={isLoading} indicator={<Loader />}>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={false}
            initialView="timeGridWeek"
            firstDay={1}
            slotDuration={"01:00"}
            allDaySlot={false}
            validRange={validRange}
            events={dataSource}
            slotLabelFormat={slotLabelFormat}
            dayHeaderContent={renderHeaderContent}
            slotLabelContent={renderSlotContent}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
          />
        </Spin>
      </CalendarWrapper>
    );
  },
);

Calendar.displayName = "Calendar";
