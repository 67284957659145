// Lib
import { FC, useEffect, useState } from "react";
// Hooks
import { usePermissions, useViewport } from "hooks";
// Types
import { BusyModeChannelLinksType } from "types/locations";
// Components
import { Modal } from "components";
import { Channel } from "../Channel";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import { ActionsContainer, ChannelLinkContainer } from "./styled";

interface BusyModeFormProps {
  locationName: string;
  isFormOpen?: boolean;
  channelLinks: BusyModeChannelLinksType[];
  hideForm?: (agrs: BusyModeChannelLinksType[]) => void;
}

export const BusyModeForm: FC<BusyModeFormProps> = ({
  locationName,
  isFormOpen,
  channelLinks,
  hideForm,
}) => {
  const { isMobile } = useViewport();

  const { canUpdateSite, canDeactivateSite } = usePermissions();

  const [channels, setChannels] = useState<
    BusyModeChannelLinksType[] | null | never
  >(channelLinks);

  useEffect(() => {
    if (channelLinks) {
      setChannels(channelLinks);
    }
  }, [channelLinks]);

  const handleChangeStatusStore = (id: string) => {
    setChannels(prev => {
      const channels: BusyModeChannelLinksType[] = prev.reduce((acc, el) => {
        if (el.id !== id) {
          return [...acc, el];
        } else {
          return [...acc, { ...el, active: !el.active }];
        }
      }, []);

      return channels;
    });
  };

  const handleOpenAll = () => {
    setChannels(prev => prev.map(link => ({ ...link, active: true })));
  };
  const handleCloseAll = () => {
    setChannels(prev => prev.map(link => ({ ...link, active: false })));
  };

  const onCloseModal = () => {
    hideForm(channels);
  };

  const LinksStatus = channels.map(el => (el.active ? "opened" : "closed"));

  const canEdit = canDeactivateSite && canUpdateSite;

  return (
    <Modal
      open={isFormOpen}
      title={`Busy mode for ${locationName}`}
      onClose={onCloseModal}
    >
      <ActionsContainer
        $fullwidth
        $column={isMobile}
        $gap={8}
        $align="center"
        $justify="space-between"
      >
        <Typography.Title>All your store are opened</Typography.Title>

        {canEdit ? (
          <FlexContainer $fullwidth={isMobile} $gap={8} $column={isMobile}>
            {LinksStatus.includes("closed") && (
              <Button.Base
                $fullWidth={isMobile}
                status="success"
                onClick={handleOpenAll}
              >
                Open all store
              </Button.Base>
            )}

            {LinksStatus.includes("opened") && (
              <Button.Base
                $fullWidth={isMobile}
                status="danger"
                onClick={handleCloseAll}
              >
                Close all store
              </Button.Base>
            )}
          </FlexContainer>
        ) : null}
      </ActionsContainer>

      {channels.map(({ id, active, name }, idx) => (
        <ChannelLinkContainer
          key={id}
          $fullwidth
          $column={isMobile}
          $gap={16}
          $align={isMobile ? "flex-start" : "center"}
          $justify="space-between"
        >
          <Channel
            title={name}
            statusText={channelLinks[idx]?.active ? "Open" : "Disabled"}
            statusType="outlined"
            status={channelLinks[idx]?.active ? "success" : "danger"}
          />

          {canEdit ? (
            <Button.Base
              $fullWidth={isMobile}
              status={active ? "danger" : "success"}
              onClick={() => handleChangeStatusStore(id)}
            >
              {active ? "Close store" : "Open store"}
            </Button.Base>
          ) : null}
        </ChannelLinkContainer>
      ))}
    </Modal>
  );
};
