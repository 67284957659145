import styled from "styled-components";
import { FlexContainer } from "styled/Box";

export const SelectContainer = styled.div`
  min-width: 200px;
`;

export const PageStikyHeading = styled(FlexContainer)<{ $stiky: boolean }>`
  ${({ $stiky }) =>
    $stiky &&
    `
position: sticky;
  top: 96px;
  background: rgb(245, 245, 245);
  z-index: 1;
  margin: 0 -5px;
  padding: 0 5px;

  @media (max-width: 1023px) {
    top: 80px;
  }
`}
`;

export const PageHeadingContainer = styled(FlexContainer)`
  margin-top: -32px;
  padding-top: 32px;
  background: rgb(245, 245, 245);
  margin: -32px -5px 0;
  padding: 32px 5px 0;
`;
