import { getFullName } from "helpers/dataHelpers";
import { GetCustomersResponseDto } from "types/customers";
import { GetOperationReportDetailsResponse } from "types/operationReport";

export const operationReportDetailsMapper = (
  data: GetOperationReportDetailsResponse,
): GetOperationReportDetailsResponse => {
  const { user, ...responseData } = data;
  return {
    ...responseData,
    user: user
      ? {
          ...user,
          fullName: getFullName({
            firstName: user?.firstName || null,
            lastName: user?.lastName || null,
          }),
        }
      : null,
  };
};

export const customersMapper = (
  response: GetCustomersResponseDto,
): GetCustomersResponseDto => {
  const { data, ...restResponse } = response;

  return {
    ...restResponse,
    data: data.map(profile => {
      const { firstName, lastName } = profile;

      const fullName = getFullName({ firstName, lastName });

      return {
        ...profile,
        fullName,
      };
    }),
  };
};
