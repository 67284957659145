// Lib
import { FC, useEffect, useState } from "react";
// Api
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
// Hooks
import { useKitchenSettings, useNotification, useViewport } from "hooks";
import { useAppDispatch, useAppSelector } from "hooks/redux";
// Actions
import { setActiveKitchen } from "rtkQuery/slices";
// Selectors
import {
  getActiveKitchen,
  getUserCompanyId,
  getUserSites,
} from "rtkQuery/selectors";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { CheckCircleIcon } from "icons";
// Components
import { Modal } from "components/Modal";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import { LocationItem } from "./styled";
import { useLazyGetKitchenSettingsQuery } from "rtkQuery/query/kitchenSettingsAPI";

interface ChangeKitchenModalProps {
  open: boolean;
  onClose: () => void;
}

export const ChangeKitchenModal: FC<ChangeKitchenModalProps> = ({
  open,
  onClose,
}) => {
  const { isMobile } = useViewport();

  const dispatch = useAppDispatch();

  const companyId = useAppSelector(getUserCompanyId);
  const sites = useAppSelector(getUserSites);
  const activeKitchen = useAppSelector(getActiveKitchen);

  const { openNotification } = useNotification();
  const { setAllConfigs } = useKitchenSettings();

  const [active, setActive] = useState(activeKitchen?.id);

  useEffect(() => {
    setActive(activeKitchen?.id);
  }, [open]);

  const [getSettings, { isFetching }] = useLazyGetKitchenSettingsQuery();

  const {
    data,
    isFetching: isGetLocationsLoading,
    error,
  } = useGetLocationsQuery(
    {
      companyId,
    },
    {
      skip: !companyId,
      refetchOnMountOrArgChange: true,
    },
  );

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const onSubmit = async () => {
    try {
      const settings = await getSettings({
        siteId: active,
      }).unwrap();

      setAllConfigs(settings);

      dispatch(
        setActiveKitchen({
          id: active,
          name: data?.find(el => el.id === active)?.name,
        }),
      );

      onClose();
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handlePickLocation = (id: string) => {
    setActive(id);
  };

  const userLocations = data?.filter(l => sites.includes(l.id));

  return (
    <Modal
      title="Change kitchen"
      open={open}
      isLoading={isGetLocationsLoading}
      onClose={handleClose}
    >
      <FlexContainer $column $align="center" $justify="flex-end" $gap={16}>
        <FlexContainer $fullwidth $column $gap={12}>
          {!!userLocations?.length &&
            userLocations?.map(l => (
              <LocationItem
                key={l.id}
                $fullwidth
                $align="center"
                $justify="space-between"
                $gap={8}
                onClick={() => handlePickLocation(l.id)}
              >
                <Typography.Title>{l.name}</Typography.Title>

                {l.id === active && <CheckCircleIcon />}
              </LocationItem>
            ))}
        </FlexContainer>

        <FlexContainer
          $fullwidth
          $column={isMobile}
          $align="center"
          $justify="flex-end"
          $gap={8}
        >
          <Button.Base
            $fullWidth={isMobile}
            disabled={isGetLocationsLoading || isFetching || !activeKitchen?.id}
            onClick={handleClose}
          >
            Cancel
          </Button.Base>

          <Button.Base
            $fullWidth={isMobile}
            type="primary"
            disabled={!active || !data}
            loading={isFetching}
            onClick={onSubmit}
          >
            Confirm
          </Button.Base>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
