// Lib
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Api
import { useCreateChannelLinkMutation } from "rtkQuery/query/locationsAPI";
// Hooks
import { useNotification } from "hooks";
// Types
import { StatusType } from "types/common";
import { ChannelLinkForm } from "types/locations";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { ArrowBackLink, Loader } from "components";
import { EditChannelLinkForm } from "../components";
// Styled
import { Typography } from "styled/Typography";
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";

export const AddChannelLink: FC = () => {
  const { siteId } = useParams();
  const navigate = useNavigate();

  const { openNotification } = useNotification();

  const [createChannelLink, { isLoading }] = useCreateChannelLinkMutation();

  const handleSendRequest = async (data: ChannelLinkForm) => {
    try {
      await createChannelLink({
        siteId,
        data: {
          name: data.name,
          status: data.active ? StatusType.Active : StatusType.Suspended,
          simphonyRevenuCenterId: data.simphonyRevenuCenterId,
        },
      }).unwrap();

      openNotification({ message: NOTIFICATIONS.CHANNEL_LINK_ADDED });

      navigate(-1);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <FlexContainer $column $gap={24}>
        <PageHeadingContainer>
          <PageTitleContainer $column $gap={16}>
            <ArrowBackLink
              title="Back to Location"
              path={ADMIN_ROUTES.LOCATIONS.path}
            />

            <Typography.H1>Add channel link</Typography.H1>
          </PageTitleContainer>
        </PageHeadingContainer>

        <EditChannelLinkForm handleSendRequest={handleSendRequest} />
      </FlexContainer>
    </>
  );
};
