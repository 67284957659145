// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { UrlQueryParams } from "types/common";
import {
  UserProfileResponseDto,
  GetUsersResponseDto,
  GetPosEmployeesResponseDto,
  GetPosEmployeesRequestDto,
  UpdatePosEmployeesRequestDto,
  CreateUserRequestDto,
  UpdateUserRequestDto,
  UserRoleData,
} from "types/users";
// Helpers
import { usersMapper } from "helpers/dataHelpers";
import { usersUrlBuilder } from "./urlBuilder/users";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";
import { userState } from "types/user";

export const usersAPI = createApi({
  reducerPath: "usersAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["users", "user", "pos-employees", "roles"],

  endpoints: build => ({
    getMe: build.query<userState, void>({
      query: () => ({
        url: usersUrlBuilder.getMe(),
        method: "GET",
      }),
    }),
    getRoles: build.query<UserRoleData[], void>({
      query: () => ({
        url: usersUrlBuilder.getUserRoles(),
        method: "GET",
      }),

      providesTags: ["roles"],
    }),
    getUsers: build.query<GetUsersResponseDto, { query?: UrlQueryParams }>({
      query: ({ query }) => ({
        url: usersUrlBuilder.getUsers({ query }),
        method: "GET",
      }),
      transformResponse: (response: GetUsersResponseDto) =>
        usersMapper(response),
      providesTags: ["users"],
    }),
    getUser: build.query<UserProfileResponseDto, { id: string }>({
      query: ({ id }) => ({
        url: usersUrlBuilder.getUser({ id }),
        method: "GET",
      }),
      providesTags: ["user"],
    }),
    createUser: build.mutation<void, CreateUserRequestDto>({
      query: data => ({
        url: usersUrlBuilder.createUser(),
        method: "POST",
        data,
      }),
      invalidatesTags: ["users"],
    }),
    updateUser: build.mutation<void, UpdateUserRequestDto>({
      query: ({ id, ...data }) => ({
        url: usersUrlBuilder.getUser({ id }),
        method: "PUT",
        data,
      }),
      invalidatesTags: ["users", "user"],
    }),
    deleteUser: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: usersUrlBuilder.getUser({ id }),
        method: "DELETE",
      }),
      invalidatesTags: ["users"],
    }),
    getPosEmployees: build.query<
      GetPosEmployeesResponseDto,
      GetPosEmployeesRequestDto
    >({
      query: data => ({
        url: usersUrlBuilder.simphonyPosUsers(data),
        method: "GET",
      }),
      providesTags: ["pos-employees"],
    }),
    updatePosEmployee: build.mutation<void, UpdatePosEmployeesRequestDto>({
      query: ({ companyId, siteId, ...data }) => ({
        url: usersUrlBuilder.simphonyPosUsers({ companyId, siteId }),
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["pos-employees"],
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetRolesQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useLazyGetUserQuery,
  useDeleteUserMutation,
  useGetPosEmployeesQuery,
  useUpdatePosEmployeeMutation,
} = usersAPI;
