import { DayType } from "types/common";

export interface daysConfigTypes {
  dayValue: DayType;
  title: string;
}

export const daysConfig: daysConfigTypes[] = [
  { dayValue: "monday", title: "Monday" },
  { dayValue: "tuesday", title: "Tuesday" },
  { dayValue: "wednesday", title: "Wednesday" },
  { dayValue: "thursday", title: "Thursday" },
  { dayValue: "friday", title: "Friday" },
  { dayValue: "saturday", title: "Saturday" },
  { dayValue: "sunday", title: "Sunday" },
];
