// Lib
import { FC } from "react";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useKitchenOrders } from "hooks";
// Selectors
import { getActiveKitchen } from "rtkQuery/selectors";
// Components
import { SettingsItem } from "./components";
// Styled
import { ContentBox, FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { ArrowDown } from "./styled";

export const KitchenSettings: FC = () => {
  const kitchen = useAppSelector(getActiveKitchen);
  const { setIsKitchenModal } = useKitchenOrders();

  return (
    <>
      <FlexContainer
        $fullwidth
        $align="center"
        $justify="space-between"
        $gap={16}
        $margin="0 0 24px 0"
      >
        <FlexContainer $column $align="flex-start" $gap={4}>
          <Typography.H1>Settings</Typography.H1>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer $fullwidth $column $gap={16}>
        <ContentBox $fullwidth $column $gap={24}>
          <Typography.H2>General</Typography.H2>

          <SettingsItem title="Change kitchen">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setIsKitchenModal(true)}
            >
              {kitchen?.name || "Set Kitchen"} <ArrowDown />
            </div>
          </SettingsItem>
        </ContentBox>
      </FlexContainer>
    </>
  );
};
