import styled from "styled-components";
import { theme } from "theme";
import { SegmentedButtons } from "components";
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";

export const Wrapper = styled(FlexContainer)`
  padding: 20px 20px 16px;
  border-radius: 6px;
  background-color: ${theme.color.white};
  color: ${theme.color.white};
  box-shadow: ${theme.shadow.table};
`;

export const Title = styled(Typography.Title)`
  color: ${theme.color.text.tertiary};
  margin-bottom: 12px;
`;

export const OrderSegmentedButton = styled(SegmentedButtons)`
  & .ant-segmented-item {
    width: 150px;
  }
`;
