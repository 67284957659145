// Lib
import { FC } from "react";
// Types
import { CheckboxProps as AntCheckboxProps } from "antd";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { SvgIconConstituentValues } from "types/common";
// Components
import { Checkbox } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";

interface CheckboxWithTheIconProps extends AntCheckboxProps {
  label: string;
  Icon: FC<SvgIconConstituentValues>;
  field: ControllerRenderProps<any, any>;
  disabled: boolean;
}

export const CheckboxWithTheIcon: FC<CheckboxWithTheIconProps> = ({
  label,
  Icon,
  field,
  disabled,
}) => {
  return (
    <FlexContainer $align="center" $justify="space-between">
      <FlexContainer $align="center">
        <Icon />
        <div style={{ marginLeft: 10 }}>{label}</div>
      </FlexContainer>
      <Checkbox
        {...field}
        checked={field.value}
        disabled={disabled}
        onChange={e => field.onChange(e.target.checked)}
      />
    </FlexContainer>
  );
};
