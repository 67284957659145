import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const usersUrlBuilder = {
  getMe: (): string => {
    return `${endpoints.users}me`;
  },
  getUserRoles: (): string => {
    return endpoints.roles;
  },
  getUsers: ({ query }: { query: UrlQueryParams }): string => {
    return generateRequestUrl(endpoints.users, query);
  },
  getUser: ({ id }: { id: string }): string => {
    return `${endpoints.users}${id}`;
  },
  createUser: (): string => {
    return `${endpoints.users}`;
  },
  simphonyPosUsers: ({
    companyId,
    siteId,
  }: {
    companyId: string;
    siteId: string;
  }): string => {
    return `${endpoints.companies}${companyId}/sites/${siteId}/simphony-employees`;
  },
};
