import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const SwitchIcon: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_495_56755)">
      <path
        d="M9 3L5 6.99H8V14H10V6.99H13L9 3ZM16 17.01V10H14V17.01H11L15 21L19 17.01H16Z"
        fill={fill || "#646965"}
      />
    </g>
    <defs>
      <clipPath id="clip0_495_56755">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
