// Lib
import { FC, ReactNode } from "react";
// Hooks
import { useViewport } from "hooks";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";

interface SettingsItemProps {
  title: string;
  description?: string;
  width?: string;
  children: ReactNode;
}

export const SettingsItem: FC<SettingsItemProps> = ({
  title,
  description,
  width,
  children,
}) => {
  const { isMobile } = useViewport();
  return (
    <FlexContainer
      $fullwidth
      $column={isMobile}
      $gap={16}
      $align={isMobile ? "flex-start" : "center"}
      $justify="space-between"
    >
      <FlexContainer $column $align="flex-start" $gap={4}>
        <Typography.Title>{title}</Typography.Title>

        {!!description && (
          <Typography.DescriptionThin>{description}</Typography.DescriptionThin>
        )}
      </FlexContainer>

      <div style={{ width }}>{children}</div>
    </FlexContainer>
  );
};

export default SettingsItem;
