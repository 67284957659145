// Lib
import { FC } from "react";
// Constants
import { FULL_MONTH_DAY_YEAR_HOURS_MINUTES_DATE } from "consts";
// Helpers
import { convertStatusToText, dateTransform } from "helpers/dataHelpers";
// Icons
import { BulletDisabled, BulletSuccess } from "icons";
// Styled
import { FlexContainer } from "styled/Box";
import {
  BulletContainer,
  BulletDivider,
  StatusTime,
  StatusTitle,
} from "./styled";

interface OrderStatusItemProps {
  active: boolean;
  isLast: boolean;
  status: string;
  date: string;
}

export const OrderStatusItem: FC<OrderStatusItemProps> = ({
  status,
  date,
  active,
  isLast,
}) => {
  return (
    <FlexContainer
      $align="center"
      $wrap
      $justify="space-between"
      style={{ height: 20 }}
    >
      <FlexContainer $align="center">
        <BulletContainer>
          {active ? <BulletSuccess /> : <BulletDisabled />}
          {!isLast && <BulletDivider />}
        </BulletContainer>

        <StatusTitle>{convertStatusToText(status)}</StatusTitle>
      </FlexContainer>

      <StatusTime>
        {dateTransform({
          date,
          format: FULL_MONTH_DAY_YEAR_HOURS_MINUTES_DATE,
        })}
      </StatusTime>
    </FlexContainer>
  );
};
