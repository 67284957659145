import { SearchSelect } from "components/Form";
import styled from "styled-components";
import { Button } from "styled/Buttons";

export const SubmitButton = styled(Button.Heading)`
  position: absolute;
  right: 32px;
  top: 36px;
`;

export const StyledSearchSelect = styled(SearchSelect)`
  .ant-select-selection-item {
    padding-top: 5px !important;
  }
`;
