import styled from "styled-components";
import { theme } from "theme";
import { DownloadIcon, RefreshIcon } from "icons";
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";

export const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const Refresh = styled(RefreshIcon)`
  path {
    fill: ${theme.color.text.tertiary};
  }
`;
export const Download = styled(DownloadIcon)`
  path {
    fill: ${theme.color.text.tertiary};
  }
`;

export const PageWrapper = styled(FlexContainer)`
  min-height: 100%;
`;

export const TableRowButton = styled(Button.Notification)`
  padding: 0;
`;
