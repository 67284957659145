// Lib
import { FC } from "react";
// Styled
import { FlexContainer } from "styled/Box";
import { RoundedSnoozedNumber } from "../../../../../Loop/Snoozing/Main/styled";

interface KitchenOrderItemProps {
  labelText: string;
  count?: number;
}

export const TabLabel: FC<KitchenOrderItemProps> = ({ labelText, count }) => (
  <FlexContainer $align="center">
    {labelText}
    {!!count && (
      <RoundedSnoozedNumber style={{ marginLeft: 2 }}>
        {count}
      </RoundedSnoozedNumber>
    )}
  </FlexContainer>
);
