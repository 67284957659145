import { UrlQueryParams } from "./common";

export enum InventoryFilterTypes {
  date = "date",
  outOfStock = "availability",
}

export enum InventoryFilterNames {
  date = "Date",
  availability = "Out of Stock",
}

export type InventoryPropduct = {
  name: string;
};

export type InventoryItem = {
  id: string;
  product: InventoryPropduct;
  stockQuantity: number;
  updatedAt: string;
};

export interface GetInventoryResponse {
  data: InventoryItem[];
  page: number;
  size: number;
  total: number;
  totalPages: number;
}

export interface InventoryUserDetails {
  id: string;
  firstName: string;
  lastName: string;
}

export type InventoryStockHistory = {
  id: string;
  quantityChange: number;
  remainingQuantity: number;
  createdAt: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  order: string;
};

export interface GetInventoryDetailsResponse {
  item: {
    id: string;
    product: {
      name: string;
    };
  };
  data: InventoryStockHistory[];
  page: number;
  size: number;
  total: number;
  totalPages: number;
}

export interface GetInventoryRequest {
  menuId: string;
  query: UrlQueryParams;
}
export interface GetKitchenInventoryRequest extends GetInventoryRequest {
  kitchenId: string;
}

export interface UpdateInventoryRequest {
  menuId: string;
  menuItemId: string;
  data: {
    quantity: number;
  };
}

export interface UpdateKitchenInventoryRequest extends UpdateInventoryRequest {
  kitchenId: string;
}

export interface GetInventoryDetailsRequest {
  id: string;
  menuId: string;
  query: UrlQueryParams;
}

export interface GetKitchenInventoryDetailsRequest
  extends GetInventoryDetailsRequest {
  kitchenId: string;
}
