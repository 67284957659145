// Lib
import { FC, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
// Api
import { useLazyGetLocationsQuery } from "rtkQuery/query/locationsAPI";
import {
  useSnoozeItemsMutation,
  useUnsnoozeItemsMutation,
} from "rtkQuery/query/snoozeAPI";
import {
  useLazyGetMenuQuery,
  useLazyGetMenusQuery,
} from "rtkQuery/query/menusAPI";
// Hooks
import { useNotification, usePermissions, useViewport } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { SnoozingCategoryType } from "types/menus";
import { SnoozeValuesTypes } from "types/snooze";
import {
  CheckedItems,
  ESnoozeType,
  SnoozeBulkItems,
  SnoozeCategory,
  SnoozeItem,
  SnoozeTableItems,
} from "./types";
// Theme
import { theme } from "theme";
// Constants
import { MONTH_DAY_YEAR_DATE, NOTIFICATIONS } from "consts";
// Helpers
import { dateTransform, generateUniqueId } from "helpers/dataHelpers";
import {
  getAllSnoozedItems,
  snoozeUntilDateHandler,
  transformToSnoozeRequestData,
  transformToUnsnoozeRequestData,
  searchSnoozedItems,
  transformSnoozeMenuData,
  getTotalBulkItems,
} from "./helpers";
// Utils
import { scrollToAnchor } from "utils/scrollToAnchor";
import { errorHandler } from "utils/errorHandler";
// Icons
import {
  AlarmIcon,
  MenuIcon,
  RightBurgerMenuIcon,
  ScheduleIcon,
  SearchBlackIcon,
} from "icons";
// Components
import {
  Badge,
  DropDown,
  Loader,
  MenuItemCard,
  NoItemsContent,
  SegmentedButtons,
} from "components";
import { Input, Select } from "components/Form";
import { SnoozeModal, SnoozedItemsModal, UnsnoozeModal } from "./components";
// Styled
import { ContentBox, FlexContainer, PageTitleContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import { More } from "components/MenuItemCard/styled";
import {
  PageStikyHeading,
  SelectContainer,
  PageHeadingContainer,
  StyledGrid,
  NoContentBox,
  PageWrapper,
  RoundedSnoozedNumber,
  CategoriesBox,
} from "./styled";

dayjs.extend(utc);
dayjs.extend(timezone);

export const Snoozing: FC = () => {
  const { isDesktop, isMobile } = useViewport();

  const companyId = useAppSelector(getUserCompanyId);

  const { canSnoozeMenuItems } = usePermissions();

  const { openNotification } = useNotification();

  const [handleSnoozeRequest, { isLoading: isSnoozeLoading }] =
    useSnoozeItemsMutation();
  const [handleUnsnoozeRequest, { isLoading: isUnsnoozeLoading }] =
    useUnsnoozeItemsMutation();

  const [getLocations, { data: locationsData, isLoading: isLocationsLoading }] =
    useLazyGetLocationsQuery();

  const [getMenusList, { data: menusListData, isLoading: isGetMenusLoading }] =
    useLazyGetMenusQuery();

  const [getMenu, { isLoading: isGetMenuLoading }] = useLazyGetMenuQuery();

  const snoozeItemsRef = useRef<
    SnoozeCategory | SnoozeItem | SnoozeBulkItems | null
  >(null);
  const isScrolling = useRef(true);
  const timeout = useRef(null);

  const [categoriesData, setCategoriesData] = useState<SnoozingCategoryType[]>(
    [],
  );

  const [locationIdValue, setLocationIdValue] = useState<string>("");
  const [menuIdValue, setMenuIdValue] = useState<string>("");
  const [activeCategory, setActiveCagory] = useState("");

  const [search, setSearch] = useState("");
  const [checkedItems, setCheckedItems] = useState<CheckedItems[]>([]);

  const [snoozeModal, setSnoozeModal] = useState(false);
  const [unsnoozeModal, setUnsnoozeModal] = useState(false);
  const [snoozedItemsModal, setSnoozedItemsModal] = useState(false);

  const itemsToShow = search
    ? searchSnoozedItems(search, categoriesData)
    : categoriesData;

  const snoozedItems = getAllSnoozedItems(categoriesData);

  useEffect(() => {
    getLocations({ companyId });
  }, []);

  useEffect(() => {
    if (!locationsData) return;

    setLocationIdValue(locationsData[0]?.id);
  }, [locationsData]);

  useEffect(() => {
    if (!locationIdValue) return;

    window.scrollTo(0, 0);

    setCategoriesData([]);
    setMenuIdValue(null);

    const handleFetchMenusList = async () => {
      try {
        await getMenusList({
          companyId,
          query: {
            sites: locationIdValue,
          },
        }).unwrap();
      } catch (error) {
        errorHandler({ error, openNotification });
      }
    };

    handleFetchMenusList();
  }, [locationIdValue]);

  const handleFetchMenu = async () => {
    try {
      const data = await getMenu({ menuId: menuIdValue }).unwrap();

      window.scrollTo(0, 0);
      setCategoriesData(transformSnoozeMenuData(data));
      setActiveCagory(data.categories[0]?.id);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  useEffect(() => {
    if (!menuIdValue) return;

    window.scrollTo(0, 0);

    handleFetchMenu();
  }, [menuIdValue]);

  const handleActiveCategory = (value: string) => {
    clearTimeout(timeout.current);
    isScrolling.current = false;
    setActiveCagory(value);
    scrollToAnchor(value, 290);

    timeout.current = setTimeout(() => {
      isScrolling.current = true;
    }, 1000);
  };

  const handleScroll = () => {
    if (!isScrolling.current) return;

    const targets = itemsToShow.map(({ id }) => document.getElementById(id));

    targets.forEach((el, index) => {
      if (!targets[index]) return;
      const targetRect = targets[index]?.getBoundingClientRect();

      const isTargetVisible =
        (targetRect.top >= 350 && targetRect.top <= 550) ||
        (targetRect.bottom >= 350 && targetRect.bottom <= 550);

      if (isTargetVisible) {
        setActiveCagory(el.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeout.current);
    };
  }, [categoriesData, itemsToShow]);

  const handleLocationChange = (id: string) => {
    snoozeItemsRef.current = null;
    setCheckedItems([]);
    setLocationIdValue(id);
  };

  const handleMenuChange = (id: string) => {
    snoozeItemsRef.current = null;
    setCheckedItems([]);
    setMenuIdValue(id);
  };

  const handleCheckItem = (
    categoryId: string,
    itemId: string,
    value: boolean,
    isSnoozed: boolean,
  ) => {
    if (value) {
      setCheckedItems(prev => {
        if (!prev || !prev.length) {
          return [{ categoryId, items: [{ itemId, isSnoozed }] }];
        }

        const catIndex = prev?.findIndex(el => el?.categoryId === categoryId);

        if (catIndex < 0) {
          const newArr = [...prev];

          return [...newArr, { categoryId, items: [{ itemId, isSnoozed }] }];
        }

        const newArr = [...prev];
        newArr[catIndex].items = newArr[catIndex].items.concat({
          itemId,
          isSnoozed,
        });
        return newArr;
      });
    } else {
      setCheckedItems(prev => {
        const catIndex = prev.findIndex(el => el.categoryId === categoryId);

        const newArr = [...prev];
        if (newArr[catIndex].items.length > 1) {
          newArr[catIndex].items = newArr[catIndex].items.filter(
            item => item.itemId !== itemId,
          );
          return newArr;
        } else {
          return newArr.filter(el => el.categoryId !== categoryId);
        }
      });
    }
  };

  const handleOpenSnoozedItemsModal = () => {
    setSnoozedItemsModal(true);
  };

  const handleCloseSnoozedItemsModal = () => {
    setSnoozedItemsModal(false);
  };

  const handleOpenSnoozeModal = () => {
    setSnoozeModal(true);
  };

  const handleCloseSnoozeModal = () => {
    setSnoozeModal(false);
    snoozeItemsRef.current = null;
  };

  const handleOpenUnsnoozeModal = () => {
    setUnsnoozeModal(true);
  };

  const handleCloseUnsnoozeModal = () => {
    if (isUnsnoozeLoading) {
      return;
    }
    setUnsnoozeModal(false);
    snoozeItemsRef.current = null;
  };

  const handleSnooze = async (args: {
    value: SnoozeValuesTypes;
    until: string;
  }) => {
    try {
      await handleSnoozeRequest(
        transformToSnoozeRequestData(
          snoozeItemsRef.current,
          args.value,
          args.until,
          locationIdValue,
        ),
      ).unwrap();

      if (snoozeItemsRef.current.type === ESnoozeType.ITEM) {
        const snoozeItems = snoozeItemsRef.current;
        const snoozeItemCategory = categoriesData.find(cat =>
          cat.items.find(
            i =>
              snoozeItems.type === ESnoozeType.ITEM &&
              i.id === snoozeItems.item.id,
          ),
        );

        const snoozedItemId = snoozeItems.item.id;
        setCategoriesData(prev => {
          if (!prev.length) return;

          const currentCategoryIndx = prev.findIndex(
            cat => cat.id === snoozeItemCategory.id,
          );

          const newArray = [...prev];
          const categoryItems = newArray[currentCategoryIndx].items;

          const currentItem = categoryItems?.findIndex(
            el => el.id === snoozedItemId,
          );

          newArray[currentCategoryIndx].items[currentItem] = {
            ...newArray[currentCategoryIndx].items[currentItem],
            isSnoozed: true,
            snoozedAt: dayjs(),
            snoozedUntil: snoozeUntilDateHandler(args.value, args.until),
          };

          const isSnoozedAll = newArray[currentCategoryIndx].items.every(
            i => i.isSnoozed,
          );

          if (isSnoozedAll) {
            const isSnoozedUntilSame = newArray[
              currentCategoryIndx
            ].items.every((snoozedUntil, i, arr) => snoozedUntil === arr[0]);

            newArray[currentCategoryIndx].isSnoozed = true;

            if (isSnoozedUntilSame) {
              newArray[currentCategoryIndx].snoozedUntil =
                snoozeUntilDateHandler(args.value, args.until);
            }
          }

          return newArray;
        });

        openNotification({
          message: `1 ${NOTIFICATIONS.MENU_ITEMS_SNOOZED}`,
        });
      }

      if (snoozeItemsRef.current.type === ESnoozeType.CATEGORY) {
        const snoozeItems = snoozeItemsRef.current;
        setCategoriesData(prev => {
          const currentCategoryIndx = prev.findIndex(
            cat => cat.id === snoozeItems.category.id,
          );

          const newArray = [...prev];

          newArray[currentCategoryIndx].isSnoozed = true;
          newArray[currentCategoryIndx].snoozedUntil = snoozeUntilDateHandler(
            args.value,
            args.until,
          );

          newArray[currentCategoryIndx].items = newArray[
            currentCategoryIndx
          ].items.map(item => ({
            ...item,
            isSnoozed: true,
            snoozedAt: dayjs(),
            snoozedUntil: snoozeUntilDateHandler(args.value, args.until),
          }));

          return newArray;
        });

        openNotification({ message: NOTIFICATIONS.MENU_CATEGORY_SNOOZED });
      }

      if (snoozeItemsRef.current.type === ESnoozeType.BULK_ITEMS) {
        const snoozeItems = snoozeItemsRef.current;

        const totalItemsCount = getTotalBulkItems(snoozeItems);

        setCategoriesData(prev => {
          const newArray = [...prev];

          const itemsData = snoozeItems?.items?.map(checkedCategories => {
            const categoryIndx = newArray.findIndex(
              cat => cat.id === checkedCategories.categoryId,
            );

            return {
              ...checkedCategories,
              categoryIndx,
            };
          });

          itemsData.forEach(data => {
            newArray[data.categoryIndx].items = [
              ...newArray[data.categoryIndx].items,
            ].map(catItem => {
              if (data.items.find(({ itemId }) => itemId === catItem.id)) {
                return {
                  ...catItem,
                  isSnoozed: true,
                  snoozedAt: dayjs(),
                  snoozedUntil: snoozeUntilDateHandler(args.value, args.until),
                };
              }

              return catItem;
            });

            const isSnoozedAll = newArray[data.categoryIndx].items.every(
              i => i.isSnoozed,
            );

            if (isSnoozedAll) {
              const isSnoozedUntilSame = newArray[
                data.categoryIndx
              ].items.every((snoozedUntil, i, arr) => snoozedUntil === arr[0]);

              newArray[data.categoryIndx].isSnoozed = true;

              if (isSnoozedUntilSame) {
                newArray[data.categoryIndx].snoozedUntil =
                  snoozeUntilDateHandler(args.value, args.until);
              }
            }
          });

          return newArray;
        });

        openNotification({
          message: `${totalItemsCount} ${NOTIFICATIONS.MENU_ITEMS_SNOOZED}`,
        });

        setCheckedItems([]);
      }
    } catch (error) {
      errorHandler({ openNotification, error });
      handleFetchMenu();
    } finally {
      handleCloseSnoozeModal();
      snoozeItemsRef.current = null;
    }
  };

  const handleUnsnooze = async () => {
    try {
      await handleUnsnoozeRequest(
        transformToUnsnoozeRequestData(snoozeItemsRef.current, locationIdValue),
      ).unwrap();

      if (snoozeItemsRef.current.type === ESnoozeType.ITEM) {
        const snoozeItem = snoozeItemsRef.current;
        const snoozeItemCategory = categoriesData.find(cat =>
          cat.items.find(
            i =>
              snoozeItemsRef.current.type === ESnoozeType.ITEM &&
              i.id === snoozeItem.item.id,
          ),
        );
        setCategoriesData(prev => {
          if (!prev.length) return;

          const currentCategoryIndx = prev.findIndex(
            cat => cat.id === snoozeItemCategory.id,
          );

          const newArray = [...prev];
          const categoryItems = newArray[currentCategoryIndx].items;
          const currentItem = categoryItems.findIndex(
            el => el.id === snoozeItem.item.id,
          );

          newArray[currentCategoryIndx].items[currentItem] = {
            ...newArray[currentCategoryIndx].items[currentItem],
            isSnoozed: false,
            snoozedAt: null,
            snoozedUntil: null,
          };

          newArray[currentCategoryIndx].isSnoozed = false;
          newArray[currentCategoryIndx].snoozedUntil = null;

          return newArray;
        });

        openNotification({
          message: `1 ${NOTIFICATIONS.MENU_ITEMS_UNSNOOZED}`,
        });
      }

      if (snoozeItemsRef.current.type === ESnoozeType.CATEGORY) {
        const snoozeItems = snoozeItemsRef.current;
        setCategoriesData(prev => {
          const currentCategoryIndx = prev.findIndex(
            cat => cat.id === snoozeItems.category.id,
          );

          const newArray = [...prev];

          newArray[currentCategoryIndx].isSnoozed = false;
          newArray[currentCategoryIndx].snoozedUntil = null;

          newArray[currentCategoryIndx].items = newArray[
            currentCategoryIndx
          ].items.map(item => ({
            ...item,
            isSnoozed: false,
            snoozedUntil: null,
          }));

          return newArray;
        });

        openNotification({ message: NOTIFICATIONS.MENU_ITEMS_UNSNOOZED });
      }

      if (snoozeItemsRef.current.type === ESnoozeType.BULK_ITEMS) {
        const snoozeItems = snoozeItemsRef.current;

        const totalItemsCount = getTotalBulkItems(snoozeItems);

        setCategoriesData(prev => {
          const newArray = [...prev];

          const itemsData = snoozeItems.items?.map(checkedCategories => {
            const categoryIndx = newArray.findIndex(
              cat => cat.id === checkedCategories.categoryId,
            );

            return {
              ...checkedCategories,
              categoryIndx,
            };
          });

          itemsData.forEach(data => {
            newArray[data.categoryIndx].items = [
              ...newArray[data.categoryIndx].items,
            ].map(catItem => {
              if (data.items.find(({ itemId }) => itemId === catItem.id)) {
                return {
                  ...catItem,
                  isSnoozed: false,
                  snoozedStart: null,
                  snoozedUntil: null,
                };
              }

              return catItem;
            });

            newArray[data.categoryIndx].isSnoozed = false;
            newArray[data.categoryIndx].snoozedUntil = null;
          });

          return newArray;
        });

        openNotification({
          message: `${totalItemsCount} ${NOTIFICATIONS.MENU_ITEMS_UNSNOOZED}`,
        });

        setCheckedItems([]);
      }
    } catch (error) {
      errorHandler({ openNotification, error });
      handleFetchMenu();
    } finally {
      handleCloseUnsnoozeModal();
      snoozeItemsRef.current = null;
    }
  };

  const handleItemButtonClick = (
    catId: string,
    itemId: string,
    snoozed: boolean,
  ) => {
    const item = categoriesData
      .find(({ id }) => id === catId)
      .items.find(({ id }) => id === itemId);

    snoozeItemsRef.current = { type: ESnoozeType.ITEM, item };

    if (!snoozed) {
      handleOpenSnoozeModal();
    } else {
      handleOpenUnsnoozeModal();
    }
  };

  const handleCategoryButtonClick = (catId: string, snoozed: boolean) => {
    const category = categoriesData.find(({ id }) => id === catId);

    snoozeItemsRef.current = { type: ESnoozeType.CATEGORY, category };

    if (!snoozed) {
      handleOpenSnoozeModal();
    } else {
      handleOpenUnsnoozeModal();
    }
  };

  const handleSnoozeSelected = () => {
    snoozeItemsRef.current = {
      type: ESnoozeType.BULK_ITEMS,
      items: checkedItems,
    };

    handleOpenSnoozeModal();
  };

  const handleUnsnoozeSelected = () => {
    snoozeItemsRef.current = {
      type: ESnoozeType.BULK_ITEMS,
      items: checkedItems,
    };

    handleOpenUnsnoozeModal();
  };

  const handleUnsnoozeTableItems = (selected: CheckedItems[]) => {
    snoozeItemsRef.current = {
      type: ESnoozeType.BULK_ITEMS,
      items: selected,
    };

    handleUnsnooze();
  };

  const handleSnoozeTableItems = (args: SnoozeTableItems) => {
    const { selected, value, until } = args;

    snoozeItemsRef.current = {
      type: ESnoozeType.BULK_ITEMS,
      items: selected,
    };

    handleSnooze({ value: value as SnoozeValuesTypes, until });
  };

  const handleUnsnoozeAll = () => {
    snoozeItemsRef.current = {
      type: ESnoozeType.BULK_ITEMS,
      items: snoozedItems.filteredData.map(c => ({
        categoryId: c.id,
        items: c.items.map(i => ({ itemId: i.id, isSnoozed: i.isSnoozed })),
      })),
    };

    handleOpenUnsnoozeModal();
  };

  const isSnoozedChecked = checkedItems?.some(category =>
    category.items.some(item => item.isSnoozed),
  );

  const isUnsnoozedChecked = checkedItems?.some(category =>
    category.items.some(item => item.isSnoozed === false),
  );

  const locations =
    locationsData?.length &&
    locationsData.map(el => ({ label: el.name, value: el.id }));

  const menus =
    menusListData?.data?.length &&
    menusListData?.data?.map(el => ({ label: el.name, value: el.id }));

  const categoriesOptions = itemsToShow.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const snoozeToolsDropdownItems = [
    !!snoozedItems.totalCount &&
      !isUnsnoozedChecked &&
      !isSnoozedChecked &&
      canSnoozeMenuItems && {
        key: "1",
        label: "Unsnooze all",
        disabled: !canSnoozeMenuItems,
        onClick: handleUnsnoozeAll,
      },
    !!snoozedItems.totalCount && {
      key: "2",
      label: `Snoozed items (${snoozedItems.totalCount})`,
      onClick: handleOpenSnoozedItemsModal,
    },
    !!isSnoozedChecked && {
      key: "3",
      label: "Unsnooze selected item",
      disabled: !canSnoozeMenuItems,
      onClick: handleUnsnoozeSelected,
    },
    !!isUnsnoozedChecked && {
      key: "3",
      label: "Snooze selected item",
      disabled: !canSnoozeMenuItems,
      onClick: handleSnoozeSelected,
    },
  ].filter(Boolean);

  const isLoading = isGetMenuLoading;

  return (
    <>
      {isLoading && <Loader />}
      <PageWrapper $fullwidth $column $gap={16}>
        <PageStikyHeading $column $gap={24} $stiky={isDesktop}>
          <PageHeadingContainer $align="center" $justify="space-between">
            <PageTitleContainer $column $gap={16}>
              <Typography.H1>Snoozing Menu</Typography.H1>
            </PageTitleContainer>
          </PageHeadingContainer>

          <FlexContainer
            $column={!isDesktop}
            $align={isDesktop ? "flex-end" : "flex-start"}
            $justify="space-between"
            $gap={8}
          >
            <FlexContainer
              $gap={8}
              $fullwidth={isMobile}
              $column={isMobile}
              $wrap
              $align={isMobile ? "stretch" : "flex-start"}
            >
              <SelectContainer>
                <Select
                  label="Location"
                  loading={isLocationsLoading}
                  value={locationIdValue}
                  options={locations}
                  onChange={handleLocationChange}
                />
              </SelectContainer>

              <SelectContainer>
                <Select
                  label="Menu"
                  loading={isGetMenusLoading || isLocationsLoading}
                  disabled={isGetMenusLoading || isLocationsLoading}
                  value={menuIdValue}
                  options={menus}
                  onChange={handleMenuChange}
                />
              </SelectContainer>
            </FlexContainer>

            {isDesktop ? (
              <FlexContainer $gap={8} $wrap>
                {!!snoozedItems.totalCount &&
                  !isUnsnoozedChecked &&
                  !isSnoozedChecked &&
                  canSnoozeMenuItems && (
                    <Button.Heading
                      disabled={!canSnoozeMenuItems}
                      onClick={handleUnsnoozeAll}
                    >
                      Unsnooze all
                    </Button.Heading>
                  )}

                {!!snoozedItems.totalCount && (
                  <Button.Heading onClick={handleOpenSnoozedItemsModal}>
                    <FlexContainer $align="center" $justify="center" $gap={8}>
                      Snoozed items
                      <RoundedSnoozedNumber>
                        {snoozedItems.totalCount}
                      </RoundedSnoozedNumber>
                    </FlexContainer>
                  </Button.Heading>
                )}

                {!!isSnoozedChecked && (
                  <Button.Heading
                    status="success"
                    disabled={!canSnoozeMenuItems}
                    onClick={handleUnsnoozeSelected}
                  >
                    Unsnooze selected item
                  </Button.Heading>
                )}

                {!!isUnsnoozedChecked && (
                  <Button.Heading
                    type="primary"
                    disabled={!canSnoozeMenuItems}
                    onClick={handleSnoozeSelected}
                  >
                    Snooze selected item
                  </Button.Heading>
                )}
              </FlexContainer>
            ) : (
              <FlexContainer $fullwidth $justify="flex-end" $height="40px">
                {!!snoozeToolsDropdownItems?.length && (
                  <DropDown items={snoozeToolsDropdownItems}>
                    <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
                  </DropDown>
                )}
              </FlexContainer>
            )}
          </FlexContainer>

          {!!menuIdValue && (
            <CategoriesBox
              $column={!isDesktop}
              $align={!isDesktop ? "flex-start" : "center"}
              $justify="space-between"
            >
              <SegmentedButtons
                value={activeCategory}
                setValue={handleActiveCategory}
                options={categoriesOptions}
              />

              <FlexContainer
                $fullwidth={!isDesktop}
                $column={isMobile}
                $align={isMobile ? "stretch" : "center"}
                $justify="flex-end"
              >
                <Input
                  prefix={<SearchBlackIcon />}
                  placeholder="Search menu"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
              </FlexContainer>
            </CategoriesBox>
          )}
        </PageStikyHeading>

        {!!itemsToShow?.length &&
          itemsToShow?.map(category => (
            <ContentBox $column id={category.id} key={category.id}>
              <FlexContainer $align="center" $justify="space-between">
                <FlexContainer $align="center" $gap={8}>
                  <Typography.H2>{category.name}</Typography.H2>

                  {!!category?.disabled && <Badge title="Disabled" />}
                </FlexContainer>

                {!category.isSnoozed ? (
                  canSnoozeMenuItems && (
                    <>
                      {isDesktop ? (
                        <Button.Form
                          icon={<ScheduleIcon />}
                          disabled={!canSnoozeMenuItems}
                          onClick={() =>
                            handleCategoryButtonClick(
                              category.id,
                              category.isSnoozed,
                            )
                          }
                        >
                          Snoozed category
                        </Button.Form>
                      ) : (
                        <DropDown
                          trigger={["click"]}
                          items={[
                            {
                              key: generateUniqueId(),
                              label: "Snoozed category",
                              disabled: !canSnoozeMenuItems,
                              onClick: () =>
                                handleCategoryButtonClick(
                                  category.id,
                                  category.isSnoozed,
                                ),
                            },
                          ]}
                        >
                          <Button.SquaredIcon
                            icon={<More fill={theme.color.text.tertiary} />}
                          />
                        </DropDown>
                      )}
                    </>
                  )
                ) : category.snoozedUntil ? (
                  <>
                    {isDesktop ? (
                      <Button.Form
                        status="danger"
                        icon={<ScheduleIcon fill={theme.color.white} />}
                        disabled={!canSnoozeMenuItems}
                        onClick={() =>
                          handleCategoryButtonClick(
                            category.id,
                            category.isSnoozed,
                          )
                        }
                      >
                        Snoozed until{" "}
                        {category.snoozedUntil
                          ? dateTransform({
                              date: category.snoozedUntil,
                              format: MONTH_DAY_YEAR_DATE,
                            })
                          : null}
                      </Button.Form>
                    ) : (
                      <Button.SquaredIcon
                        status="danger"
                        icon={<ScheduleIcon fill={theme.color.white} />}
                        disabled={!canSnoozeMenuItems}
                        onClick={() =>
                          handleCategoryButtonClick(
                            category.id,
                            category.isSnoozed,
                          )
                        }
                      />
                    )}
                  </>
                ) : (
                  <Button.SquaredIcon
                    status="danger"
                    disabled={!canSnoozeMenuItems}
                    icon={<ScheduleIcon fill={theme.color.white} />}
                    onClick={() =>
                      handleCategoryButtonClick(category.id, category.isSnoozed)
                    }
                  />
                )}
              </FlexContainer>

              <StyledGrid>
                {category.items.map(
                  ({
                    id,
                    image,
                    name,
                    modifiers,
                    description,
                    kcal,
                    labels,
                    price,
                    tags,
                    disabled,
                    isSnoozed,
                    snoozedUntil,
                  }) => {
                    const buttonCTA = {
                      Icon: (
                        <AlarmIcon
                          fill={
                            isSnoozed
                              ? theme.color.white
                              : theme.color.text.tertiary
                          }
                        />
                      ),
                      Button: snoozedUntil
                        ? isDesktop
                          ? Button.Form
                          : Button.SquaredIcon
                        : Button.SquaredIcon,
                      isSnoozed,
                      title:
                        isDesktop &&
                        snoozedUntil &&
                        `Snoozed until ${dateTransform({
                          date: snoozedUntil,
                          format: MONTH_DAY_YEAR_DATE,
                        })}`,
                      disabled: !canSnoozeMenuItems,
                      onClick: () =>
                        handleItemButtonClick(category.id, id, isSnoozed),
                    };

                    const isChecked = !!checkedItems
                      ?.find(el => el.categoryId === category.id)
                      ?.items.find(el => el.itemId === id);

                    return (
                      <MenuItemCard
                        key={id}
                        itemId={id}
                        image={image}
                        modifiers={modifiers}
                        title={name}
                        description={description}
                        kcal={kcal}
                        kwd={price}
                        tags={tags}
                        labels={labels}
                        disabled={disabled}
                        checked={isChecked}
                        buttonCTA={buttonCTA}
                        onChange={
                          canSnoozeMenuItems
                            ? (e, itemId) =>
                                handleCheckItem(
                                  category.id,
                                  itemId,
                                  e.target.checked,
                                  isSnoozed,
                                )
                            : null
                        }
                      />
                    );
                  },
                )}
              </StyledGrid>
            </ContentBox>
          ))}

        {!menuIdValue && (
          <NoContentBox>
            <NoItemsContent
              Icon={MenuIcon}
              message="No location and menu selected"
              description="Please select a location and a menu to snooze items."
            />
          </NoContentBox>
        )}
      </PageWrapper>

      <SnoozeModal
        isLoading={isSnoozeLoading}
        open={snoozeModal}
        data={snoozeItemsRef?.current}
        handleSave={handleSnooze}
        handleClose={handleCloseSnoozeModal}
      />

      <UnsnoozeModal
        isLoading={isUnsnoozeLoading}
        open={unsnoozeModal}
        data={snoozeItemsRef?.current}
        onConfirm={handleUnsnooze}
        handleClose={handleCloseUnsnoozeModal}
      />

      <SnoozedItemsModal
        isLoading={isUnsnoozeLoading || isSnoozeLoading}
        open={snoozedItemsModal}
        data={snoozedItems}
        handleSnoozeTableItems={handleSnoozeTableItems}
        handleUnsnoozeTableItems={handleUnsnoozeTableItems}
        handleClose={handleCloseSnoozedItemsModal}
      />
    </>
  );
};
