// Lib
import { FC } from "react";
// Hooks
import { useKitchenSettings, useViewport } from "hooks";
// Types
import { KitchenOrder } from "types/kitchen";
// Constants
import { HOURS_MINUTES_DATE } from "consts";
// Helpers
import { dateTransform } from "helpers/dataHelpers";
import { getOrderType } from "../../pages/KitchenPwa/KitchenOrders/Main/helpers";
// Icons
import { AvatarIcon, LocationsIcon, StoreIcon } from "icons";
// Components
import { InfoItem, Modal } from "components";
import { OrderItemsList } from "./components";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import { Avatar, Divider, ListTitle, NewItemContentBox } from "./styled";

interface OrderNewItemModalProps {
  isLoading: boolean;
  order: KitchenOrder;
  open: boolean;
  handleClose?: () => void;
  handleSubmit: (id: string) => void;
  handleCloseNewOrder?: (id: string) => void;
  handleCancelOrder: (id: string) => void;
}

export const OrderNewItemModal: FC<OrderNewItemModalProps> = ({
  isLoading,
  order,
  open,
  handleSubmit,
  handleCloseNewOrder,
  handleCancelOrder,
}) => {
  const { isMobile } = useViewport();
  const { displayItemPrices, allowCancelingOrders } = useKitchenSettings();

  const onSubmit = () => {
    handleSubmit(order.id);
  };

  const onClose = () => {
    if (isLoading) return;
    handleCloseNewOrder(order.id);
  };

  const onCancel = () => {
    handleCancelOrder(order.id);
  };

  return (
    <Modal
      isLoading={isLoading}
      title={`#${order?.sid}`}
      subTitle={`${order?.orderItems?.length} item(s)${
        displayItemPrices ? ` (KWD ${order?.totalFmcCentsPrice})` : ""
      }`}
      width={640}
      open={open}
      onClose={onClose}
      closeIcon={false}
      suffix={
        <InfoItem
          type="schedule"
          label="Pickup Time"
          info={dateTransform({
            date: order?.pickupTime,
            format: HOURS_MINUTES_DATE,
          })}
        />
      }
    >
      <FlexContainer $fullwidth $column $gap={20}>
        <FlexContainer
          $fullwidth
          $align="center"
          $justify="flex-start"
          $gap={4}
        >
          <StoreIcon />
          <Typography.Description>
            Forevermore · {getOrderType(order?.type)}
          </Typography.Description>
        </FlexContainer>
        <Divider />

        <FlexContainer
          $fullwidth
          $align="center"
          $justify="flex-start"
          $gap={4}
        >
          <Avatar size={24} icon={<AvatarIcon />} />

          <Typography.Description>
            {order?.customerName} ({order?.phoneNumber})
          </Typography.Description>
        </FlexContainer>

        <FlexContainer
          $fullwidth
          $align="flex-start"
          $justify="flex-start"
          $gap={4}
        >
          <div>
            <LocationsIcon width="24" height="24" />
          </div>

          <Typography.Description>
            {order?.deliveryAddress}
          </Typography.Description>
        </FlexContainer>

        <NewItemContentBox>
          <FlexContainer $column $gap={12}>
            <ListTitle>Order list</ListTitle>

            <OrderItemsList orderItems={order?.orderItems || []} />
          </FlexContainer>
        </NewItemContentBox>

        <FlexContainer
          $fullwidth
          $column={isMobile}
          $align="center"
          $justify="space-between"
          $gap={8}
        >
          <Button.Base
            $fullWidth={isMobile}
            disabled={isLoading}
            onClick={onClose}
          >
            Close
          </Button.Base>

          <FlexContainer
            $fullwidth={isMobile}
            $column={isMobile}
            $align="center"
            $justify="center"
            $gap={8}
          >
            {allowCancelingOrders && (
              <Button.Base
                $fullWidth={isMobile}
                status="danger"
                disabled={isLoading}
                onClick={onCancel}
              >
                Cancel Order
              </Button.Base>
            )}

            <Button.Base
              $fullWidth={isMobile}
              type="primary"
              disabled={isLoading}
              onClick={onSubmit}
            >
              Accept
            </Button.Base>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
