import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const operationReportsUrlBuilder = {
  getOperationReports: ({ query }: { query: UrlQueryParams }): string => {
    return generateRequestUrl(`${endpoints.operationReports}`, query);
  },
  getOperationReport: (id: string): string => {
    return `${endpoints.operationReports}${id}`;
  },
  getExportSnoozeReport: ({ query }: { query: UrlQueryParams }): string => {
    return generateRequestUrl(
      `${endpoints.operationReports}export/snooze`,
      query,
    );
  },
};
