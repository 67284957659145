// Lib
import { FC } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Hooks
import { usePermissions, useViewport } from "hooks";
// Types
import { ChannelLinkForm } from "types/locations";
// Icons
import { RightBurgerMenuIcon } from "icons";
// Components
import { DropDown } from "components";
import { Input, Switch } from "components/Form";
// Styled
import { ContentBox, FlexContainer, InputsGridContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import { ButtonsContainer, TitleContainer } from "./styled";

import { resolver } from "./validation";
import { defaultValues } from "./defaultValues";

interface EditChannelLinkFormProps {
  channelLinkData?: ChannelLinkForm;
  handleSendRequest?: (args: ChannelLinkForm) => void;
}

export const EditChannelLinkForm: FC<EditChannelLinkFormProps> = ({
  channelLinkData,
  handleSendRequest,
}) => {
  const { isDesktop } = useViewport();

  const { canUpdateSite } = usePermissions();

  const { handleSubmit, control, watch } = useForm<ChannelLinkForm>({
    defaultValues: channelLinkData ? channelLinkData : defaultValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver,
  });

  const isActive = watch("active");

  const onSubmit: SubmitHandler<ChannelLinkForm> = data => {
    handleSendRequest(data);
  };

  const dropDownMenuItems = [
    {
      key: "3",
      label: "Save",
      disabled: !canUpdateSite,
      onClick: () => handleSubmit(onSubmit)(),
    },
  ];

  return (
    <>
      <FlexContainer
        as="form"
        $column
        $gap={16}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ContentBox $column>
          <TitleContainer>
            <Typography.H2>Basic setting</Typography.H2>

            <Controller
              name={"active"}
              control={control}
              render={({ field }) => (
                <Switch
                  label={isActive ? "Active" : "Disabled"}
                  labelPosition="left"
                  {...field}
                  checked={field.value}
                />
              )}
            />
          </TitleContainer>

          <InputsGridContainer>
            <Controller
              name={"name"}
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="Name"
                  required
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
            <Controller
              name={"simphonyRevenuCenterId"}
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="Simphony Revenu Center Id"
                  required
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
          </InputsGridContainer>
        </ContentBox>

        <ButtonsContainer $gap={10}>
          {isDesktop ? (
            <Button.Heading
              type="primary"
              htmlType="submit"
              disabled={!canUpdateSite}
            >
              Save
            </Button.Heading>
          ) : (
            <DropDown trigger={["click"]} items={dropDownMenuItems}>
              <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
            </DropDown>
          )}
        </ButtonsContainer>
      </FlexContainer>
    </>
  );
};
