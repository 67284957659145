// Lib
import { FC, Fragment, memo, useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
// Api
import { useGetOrdersAnalyticsQuery } from "rtkQuery/query/analyticsAPI";
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
// Selectors
import { getAnalyticsFilters, getUserCompanyId } from "rtkQuery/selectors";
// Types
import { AnalyticsStatsSummaryKey } from "types/analytics";
// Consts
import { queryParams } from "consts";
// Helpers
import {
  createButtons,
  createChartData,
  generateDates,
} from "../../../helpers";
import { createOrdersChartLines, createOrderTabs } from "./helpers";
// Components
import { Loader } from "components";
import { ChartButton, LineChart } from "components/Chart";
// Styled
import { ButtonsWrapper, Devider, Wrapper } from "./styled";

export const OrdersChart: FC = memo(() => {
  const companyId = useAppSelector(getUserCompanyId);
  const analyticsFilters = useAppSelector(getAnalyticsFilters);

  const [selected, setSelected] = useState<AnalyticsStatsSummaryKey | null>(
    null,
  );

  const { current, prev } = generateDates(analyticsFilters.dateFilter);

  const { data: locationsData } = useGetLocationsQuery({ companyId });

  const { data: ordersData, isFetching } = useGetOrdersAnalyticsQuery(
    {
      companyId,
      query: {
        status: analyticsFilters.orderStatus,
        siteId: analyticsFilters.location,
        dateBefore: current.startTime,
        dateAfter: current.endTime,
        dateBeforePrev: prev.startTime,
        dateAfterPrev: prev.endTime,
      },
    },
    queryParams,
  );

  const tabs = useMemo(() => createOrderTabs(ordersData), [ordersData]);

  const statsButtons = useMemo(() => createButtons(tabs), [tabs]);

  useEffect(() => {
    if (!!tabs?.length && !selected) {
      setSelected(tabs[0]?.dataKey);
    }
  }, [tabs]);

  const locations = useMemo(
    () =>
      locationsData?.map(({ name, id }) => ({ label: name, value: id })) || [],
    [locationsData],
  );

  const { chartLinesData } = useMemo(
    () =>
      createOrdersChartLines({
        orders: ordersData?.items,
        analyticsFilters,
        locations,
      }),
    [ordersData?.items, analyticsFilters, locations],
  );

  console.log("chartLinesData", chartLinesData);

  const chartData = useMemo(
    () => createChartData(chartLinesData?.slotsData, locations, selected),
    [chartLinesData?.slotsData, locations, selected],
  );

  return (
    <Spin
      spinning={isFetching}
      indicator={
        <>
          <Loader />
        </>
      }
    >
      <Wrapper>
        <ButtonsWrapper>
          {statsButtons?.map((button, i, array) => (
            <Fragment key={button.statsKey}>
              <ChartButton
                statsKey={button.statsKey}
                title={button.title}
                profitDisplay={button.profitDisplay}
                statsValue={button.statsValue}
                statsPrevValue={button.statsPrevValue}
                statsValuePrefix={button.statsValuePrefix}
                isSelected={button.statsKey === selected}
                isInvertedProfit={button.isInvertedProfit}
                onClick={setSelected}
              />
              {i !== array?.length - 1 && <Devider className="devider" />}
            </Fragment>
          ))}
        </ButtonsWrapper>

        <LineChart
          legendConfig={chartLinesData.legendConfig}
          linesConfig={chartLinesData.linesConfig}
          chartData={chartData}
        />
      </Wrapper>
    </Spin>
  );
});

OrdersChart.displayName = "OrdersChart";
