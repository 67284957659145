import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const MinusIcon: FC<SvgIconConstituentValues> = ({ fill, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_9871_9413)">
      <mask
        id="mask0_9871_9413"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <g clipPath="url(#clip1_9871_9413)">
          <g clipPath="url(#clip2_9871_9413)">
            <path
              d="M18 13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
              fill="black"
            />
          </g>
        </g>
      </mask>
      <g mask="url(#mask0_9871_9413)">
        <rect width="24" height="24" fill={fill || "#646965"} />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_9871_9413">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_9871_9413">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip2_9871_9413">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
