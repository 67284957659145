import { getOperationTypeBadge } from "../../helpers";
import { dateTransform, getSnoozeEndDate } from "helpers/dataHelpers";
import { MONTH_DAY_YEAR_HOURS_MINUTES_DATE } from "consts";
import { OperationLogType } from "types/operationReport";

export const menuItemsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "PLU",
    dataIndex: "plu",
    key: "plu",
  },
  {
    title: "Operation type",
    dataIndex: "snoozedAt",
    key: "type",
    render: (date: string) =>
      getOperationTypeBadge(
        date
          ? OperationLogType.PRODUCT_SNOOZE
          : OperationLogType.PRODUCT_UNSNOOZE,
      ),
  },
  {
    title: "Snooze start",
    dataIndex: "snoozedAt",
    key: "snoozedAt",
    render: (date: string) =>
      dateTransform({ date, format: MONTH_DAY_YEAR_HOURS_MINUTES_DATE }),
  },
  {
    title: "Snooze end",
    dataIndex: "snoozedUntil",
    key: "snoozedUntil",
    render: (date: string) => getSnoozeEndDate(date),
  },
];

export const insertedItemsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "PLU",
    dataIndex: "plu",
    key: "plu",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
];
