// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { AuthUserRequest, AuthUserResponse } from "types/auth";
// Helpers
import { authUrlBuilder } from "./urlBuilder/auth";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";

export const authAPI = createApi({
  reducerPath: "authAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: build => ({
    userAuth: build.query<AuthUserResponse, AuthUserRequest>({
      query: data => ({
        url: authUrlBuilder.authRequest(),
        method: "POST",
        data,
      }),
    }),
  }),
});

export const { useLazyUserAuthQuery } = authAPI;
