import { Avatar as AntDAvatar } from "antd";
import styled from "styled-components";
import { theme } from "theme";
import { ContentBox } from "styled/Box";
import { Typography } from "styled/Typography";

export const Divider = styled.div`
  border-bottom: 1px solid ${theme.color.borderLight};
`;

export const NewItemContentBox = styled(ContentBox)`
  background-color: ${theme.color.background.lightgray};
  box-shadow: none;
  border: 1px solid ${theme.color.borderLight};
  padding: 16px;
`;

export const ListTitle = styled(Typography.Title)`
  font-weight: ${theme.fontWeight.semiBold};
`;

export const Avatar = styled(AntDAvatar)`
  background: none;
`;

export const Note = styled(Typography.Description)`
  margin-left: 20px;
`;

export const ModifierTitle = styled(Typography.Description)`
  margin-left: 28px;
`;
