// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { SnoozeItemsRequest, UnsnoozeItemsRequest } from "types/snooze";
// Helpers
import { snoozeUrlBuilder } from "./urlBuilder/snooze";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";

export const snoozeAPI = createApi({
  reducerPath: "snoozeAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),

  endpoints: build => ({
    snoozeItems: build.mutation<void, SnoozeItemsRequest>({
      query: ({ data }) => ({
        url: snoozeUrlBuilder.snoozeItems(),
        method: "PATCH",
        data,
      }),
    }),
    unsnoozeItems: build.mutation<void, UnsnoozeItemsRequest>({
      query: ({ data }) => ({
        url: snoozeUrlBuilder.unsnoozeItems(),
        method: "PATCH",
        data,
      }),
    }),
  }),
});

export const { useSnoozeItemsMutation, useUnsnoozeItemsMutation } = snoozeAPI;
