import styled from "styled-components";
import { theme } from "theme";
import { FlexContainer, IconRoundedContainer } from "styled/Box";
import { Typography } from "styled/Typography";

export const Label = styled(FlexContainer)`
  color: ${theme.color.text.tertiary};
  font-size: 16px;
`;

export const InfoItemRounded = styled(IconRoundedContainer)`
  width: 40px;
  height: 40px;
  justify-content: center;
  color: ${theme.color.text.tertiary};
`;

export const IconText = styled(Typography.Title)`
  color: ${theme.color.text.primary2};
`;

export const DescriptionText = styled(Typography.Description)`
  color: ${theme.color.text.tertiary};
`;
