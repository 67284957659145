import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const analyticsUrlBuilder = {
  getOrderAnalytics: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/orders/general`,
      query,
    );
  },
  getOrderRevenueBreakdownAnalytics: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/orders/revenue-breakdown`,
      query,
    );
  },
  getOrderHourlyDistributionAnalytics: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/orders/hourly-distribution`,
      query,
    );
  },
};
