// Lib
import { FC } from "react";
// Hooks
import { useKitchenSettings } from "hooks";
// Constants
import { HOURS_MINUTES_DATE } from "consts";
// Helpers
import { dateTransform } from "helpers/dataHelpers";
// Icons
import { AvatarIcon } from "icons";
// Types
import { EPaymentMethod, OrderStatusLabel } from "types/orders";
import { KitchenOrder } from "types/kitchen";
// Components
import { Badge } from "components";
// Styled
import { Customer, Id, Wrapper } from "./styled";
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Avatar } from "./styled";

interface KitchenOrderItemProps {
  selected: boolean;
  order: KitchenOrder;
  onSelect: () => void;
}

export const KitchenOrderItem: FC<KitchenOrderItemProps> = ({
  selected,
  order,
  onSelect,
}) => {
  const { displayItemPrices } = useKitchenSettings();

  const price =
    order.paymentMethod === EPaymentMethod.FIAT
      ? `(${order.totalFiatCentsPrice} KWD)`
      : `(${order.totalFmcCentsPrice} FM Coins)`;

  return (
    <Wrapper $column $selected={selected} onClick={onSelect}>
      <FlexContainer $align="center" $justify="space-between">
        <Id>{order?.sid}</Id>

        <Badge status={order?.status} title={OrderStatusLabel[order?.status]} />
      </FlexContainer>

      <FlexContainer
        $align="center"
        $justify="space-between"
        $margin="16px 0 0"
      >
        <FlexContainer $gap={4} $wrap>
          <Typography.Title>
            {`${order?.orderItems?.length} item(s)`}
          </Typography.Title>

          {displayItemPrices && <Typography.Title>{price}</Typography.Title>}
        </FlexContainer>

        <Typography.Title>
          {dateTransform({
            date: order.pickupTime,
            format: HOURS_MINUTES_DATE,
          })}
        </Typography.Title>
      </FlexContainer>

      <FlexContainer
        $align="center"
        $justify="space-between"
        $margin="12px 0 0"
      >
        <FlexContainer $align="center" $justify="space-between">
          <Avatar size={22} icon={<AvatarIcon />} />

          <Customer>{order.customerName}</Customer>
        </FlexContainer>

        <div>
          {dateTransform({
            date: order.createdAt,
            format: HOURS_MINUTES_DATE,
          })}
        </div>
      </FlexContainer>
    </Wrapper>
  );
};
