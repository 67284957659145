import { Badge, Status } from "components";
import { convertStatusToText } from "helpers/dataHelpers";
import { theme } from "theme";
import {
  OperationLogStatus,
  OperationLogStatusLabel,
  OperationLogType,
  OperationLogTypeLabel,
} from "types/operationReport";

const dotColor = {
  // bulk_user_import: "#5BAA5C",
  // busy_mode: "#525CEC",
  // busy_mode_sync: "#313792",
  [OperationLogType.ACTIVATE_CHANNEL]: "#ABC455",
  [OperationLogType.DEACTIVATE_CHANNEL]: "#ABC455",
  // courier_update: "#2C5062",
  [OperationLogType.EXPORT]: "#1D2C7B",
  // health_check: "#7AAB57",
  // insert_order: "#DA9054",
  [OperationLogType.CREATE_MENU]: "#70B776",
  [OperationLogType.PUBLISH_MENU]: "#6DA4E1",
  [OperationLogType.UPDATE_MENU]: "#58927F",
  [OperationLogType.UPDATE_ORDER]: "#27373A",
  [OperationLogType.PRODUCT_SYNC]: "#73BD8B",
  // product_sync_preview: "#426762",
  [OperationLogType.PRODUCT_SNOOZE]: "#89D8A5",
  [OperationLogType.PRODUCT_UNSNOOZE]: "#73BDA3",
  [OperationLogType.SNOOZE_REPORT]: "#89D8A5",
  [OperationLogType.UN_SNOOZE_REPORT]: "#73BDA3",
  [OperationLogType.POS_EMPLOYEE]: "#dac154",
  [OperationLogType.CREATE_SITE]: "#648d46",
  [OperationLogType.UPDATE_SITE]: "#648d46",
  [OperationLogType.UPDATE_PRODUCT]: "#46628d",
  // update_store_opening_hours: "#6CA7DF",
};

const badgeColor = {
  [OperationLogStatus.SUCCESS]: theme.color.status.success,
  [OperationLogStatus.ABORTED]: theme.color.failed,
  [OperationLogStatus.AWAIT]: theme.color.yellow,
  [OperationLogStatus.FAILED]: theme.color.red,
  [OperationLogStatus.RUNNING]: theme.color.lightBlue,
  [OperationLogStatus.UNKNOWN]: theme.color.gray,
};

export const getOperationTypeBadge = (type: string) => {
  if (!type) {
    return null;
  }

  return (
    <Status
      capitalize
      text={OperationLogTypeLabel[type] || convertStatusToText(type)}
      type="outlined"
      markerColor={dotColor[type] || "white"}
    />
  );
};

export const getOperationStatusBadge = (status: OperationLogStatus) => {
  if (!status) {
    return null;
  }

  return (
    <Badge
      bgColor={badgeColor[status]}
      textColor="rgba(255, 255, 255, 1)"
      title={OperationLogStatusLabel[status]}
    />
  );
};
