// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  GetOrdersRequest,
  GetOrdersResponse,
  OrderDetailsResponse,
} from "types/orders";
// Helpers
import { ordersUrlBuilder } from "./urlBuilder/orders";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";

export const ordersAPI = createApi({
  reducerPath: "ordersAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["orders"],
  endpoints: build => ({
    getOrders: build.query<GetOrdersResponse, GetOrdersRequest>({
      query: ({ companyId, query }) => {
        return {
          url: ordersUrlBuilder.getOrders({ companyId, query }),
          method: "GET",
        };
      },
      providesTags: ["orders"],
    }),
    getOrder: build.query<OrderDetailsResponse, { id: string }>({
      query: ({ id }) => {
        return {
          url: ordersUrlBuilder.getOrder(id),
          method: "GET",
        };
      },
      providesTags: ["orders"],
    }),
  }),
});

export const { useGetOrdersQuery, useGetOrderQuery } = ordersAPI;
