// Lib
import { FC } from "react";
//Styled
import { Marker, Wrapper } from "./styled";

interface StatusProps {
  text: string;
  type?: "primary" | "outlined" | "borderless";
  status?: "success" | "warning" | "neutral" | "danger";
  markerColor?: string;
  capitalize?: boolean;
}

export const Status: FC<StatusProps> = ({
  text,
  type = "primary",
  status = "success",
  markerColor,
  capitalize,
}) => {
  return (
    <Wrapper $type={type} $status={status} $capitalize={capitalize}>
      {type !== "primary" && (
        <Marker $type={type} $status={status} $markerColor={markerColor} />
      )}
      <div>{text}</div>
    </Wrapper>
  );
};
