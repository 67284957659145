// Lib
import { FC, memo, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Api
import { useGetRolesQuery, useGetUsersQuery } from "rtkQuery/query/usersAPI";
// Hooks
import { useNotification } from "hooks";
// Actions
// Selectors
// Types
import { UserFormTypes } from "../../types";
// Theme
// Constants
import { permissionLabel, Role, roleLabel } from "consts";
// Helpers
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
// Layouts
// Components
import { Avatar } from "components";
import { Input, Select, TagsSelect } from "components/Form";
// Styled
import { ContentBox, FlexContainer, InputsGridContainer } from "styled/Box";
import { StyledSearchSelect, SubmitButton } from "./styled";

import { editResolver, resolver } from "./validation";
import { defaultValues } from "../../config";

interface UserFormProps {
  isLoading?: boolean;
  isSubmitLoading?: boolean;
  isEdit?: boolean;
  initialData?: UserFormTypes;
  locationsOptions: { label: string; value: string; renderValue: string }[];
  additionalLocationsOptions?: {
    label: string;
    value: string;
    renderValue: string;
  }[];
  handleSendRequest: (data: UserFormTypes) => void;
}

export const UserForm: FC<UserFormProps> = memo(
  ({
    isSubmitLoading,
    isEdit,
    initialData,
    locationsOptions,
    additionalLocationsOptions = [],
    handleSendRequest,
  }) => {
    const { openNotification } = useNotification();

    const [searchValue, setSearchValue] = useState<string>("");

    const { data: usersData, error: usersDataError } = useGetUsersQuery(
      {
        query: {},
      },
      { refetchOnMountOrArgChange: true },
    );
    const { data: rolesData, error: rolesDataError } = useGetRolesQuery(null, {
      refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
      if (rolesDataError) {
        errorHandler({ error: rolesDataError, openNotification });
      }
    }, [rolesDataError]);

    useEffect(() => {
      if (usersDataError) {
        errorHandler({ error: usersDataError, openNotification });
      }
    }, [usersDataError]);

    const { handleSubmit, control, setValue, watch, reset } =
      useForm<UserFormTypes>({
        defaultValues: initialData || defaultValues,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resolver: isEdit ? editResolver : resolver,
      });

    const selectedRole = watch("role");

    useEffect(() => {
      if (initialData) {
        reset(initialData);
      }
    }, [initialData]);

    const onUserRoleSelect = (roleId: string) => {
      if (roleId === Role.LoopAdmin || roleId === Role.Admin) {
        const permissions =
          rolesData
            ?.find(role => role.id === roleId)
            ?.permissions.map(permission => permission.id) || [];

        const locations = locationsOptions.map(location => location.value);

        setValue("permissions", permissions);
        setValue("locations", locations);

        return;
      }

      setValue("permissions", []);
    };

    const onSubmit: SubmitHandler<UserFormTypes> = data => {
      handleSendRequest(data);
    };

    const usersOptions =
      usersData?.map(({ fullName, email, id }) => {
        return {
          label: (
            <FlexContainer
              style={{ overflow: "hidden" }}
              key={fullName}
              $align="center"
              $justify="flex-start"
              $gap={8}
            >
              <Avatar name={fullName} />
              <div>{fullName}</div>
            </FlexContainer>
          ),
          value: id,
          searchData: {
            fullName,
            email,
          },
        };
      }) || null;

    const filteredOptions = !searchValue
      ? usersOptions
      : usersOptions.filter(user => {
          const { fullName, email } = user.searchData;

          const isNameMatch = fullName
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase());

          const isEmailMatch = email
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase());

          if (isNameMatch || isEmailMatch) {
            return true;
          }
          return false;
        });

    const permissionOptions =
      rolesData
        ?.find(role => role.id === selectedRole)
        ?.permissions.map(permission => ({
          value: permission.id,
          label: permissionLabel[permission.id],
          renderValue: permissionLabel[permission.id],
        })) || [];

    const rolesOptions =
      rolesData?.map(role => ({
        label: roleLabel[role.id],
        value: role.id,
      })) || [];

    const setSettingsFrom = (id: string) => {
      const user = usersData.find(user => user.id === id);

      const { role, sites, permissions } = user.roleAssignment;

      const locations =
        sites
          ?.map(location => location.id)
          .filter(el => locationsOptions?.find(l => l.value === el)) || [];

      const permission = permissions?.map(permission => permission.id) || [];

      setValue("role", role.id);
      setValue("locations", locations);
      setValue("permissions", permission);
    };

    const isInputSelectDisabled =
      selectedRole === Role.Admin || selectedRole === Role.LoopAdmin;

    return (
      <FlexContainer
        as="form"
        $fullwidth
        $column
        $gap={16}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ContentBox>
          <InputsGridContainer>
            <StyledSearchSelect
              label="Copy settings from"
              options={filteredOptions}
              searchValue={searchValue}
              onSearchChange={value => setSearchValue(value)}
              onSelect={id => setSettingsFrom(id)}
            />
          </InputsGridContainer>
        </ContentBox>

        <ContentBox>
          <InputsGridContainer>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  required
                  label="First name"
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  required
                  label="Last name"
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  required
                  label="E-mail"
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />

            {!isEdit && (
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    required
                    label="Password"
                    {...field}
                    fieldState={fieldState}
                  />
                )}
              />
            )}

            <Controller
              name="locations"
              control={control}
              render={({ field, fieldState }) => (
                <TagsSelect
                  required
                  showSearch={false}
                  aria-autocomplete="none"
                  label="Locations"
                  mode="tags"
                  {...field}
                  fieldState={fieldState}
                  options={[...locationsOptions, ...additionalLocationsOptions]}
                  disabled={isInputSelectDisabled}
                />
              )}
            />

            <Controller
              name="role"
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  required
                  label="User Role"
                  {...field}
                  fieldState={fieldState}
                  onSelect={onUserRoleSelect}
                  options={rolesOptions}
                />
              )}
            />

            <Controller
              name="permissions"
              control={control}
              render={({ field, fieldState }) => (
                <TagsSelect
                  required
                  showSearch={false}
                  label="Permissions"
                  {...field}
                  fieldState={fieldState}
                  options={permissionOptions}
                  disabled={isInputSelectDisabled}
                />
              )}
            />
          </InputsGridContainer>
        </ContentBox>

        <SubmitButton
          type="primary"
          htmlType="submit"
          loading={isSubmitLoading}
        >
          Save
        </SubmitButton>
      </FlexContainer>
    );
  },
);

UserForm.displayName = "UserForm";
