import { KitchenNotitficationSoundOptions } from "types/kitchen";

import cashPurchase from "./cashPurchase.mp3";
import cityAlertSiren from "./cityAlertSiren.wav";
import kittyMeow from "./kittyMeow.wav";

export const sounds = {
  [KitchenNotitficationSoundOptions.ALERT]: cashPurchase,
  [KitchenNotitficationSoundOptions.DEFINITE]: cityAlertSiren,
  [KitchenNotitficationSoundOptions.NOTIFICATION]: kittyMeow,
  [KitchenNotitficationSoundOptions.MUSIC_BOX]: kittyMeow,
  [KitchenNotitficationSoundOptions.SIREN]: kittyMeow,
  [KitchenNotitficationSoundOptions.RUSH]: kittyMeow,
};
