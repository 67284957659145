import { AccentText } from "components/Table/styled";

export const columns = [
  {
    title: "Location",
    dataIndex: "name",
    key: "name",
    render: text => <AccentText>{text}</AccentText>,
  },
  {
    title: "Order",
    dataIndex: "order",
    key: "order",
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
    key: "revenue",
  },
  {
    title: "Gross Sales",
    dataIndex: "grossSales",
    key: "grossSales",
  },
  {
    title: "AVG Order value",
    dataIndex: "avgOrderValue",
    key: "avgOrderValue",
  },
];
