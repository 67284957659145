// Lib
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Api
import { useCreateUserMutation } from "rtkQuery/query/usersAPI";
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useNotification } from "hooks";
// Actions
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { errorHandler } from "utils/errorHandler";
import { UserFormTypes } from "../types";
// Theme
// Constants
import { ADMIN_ROUTES } from "consts";
// Helpers
// Utils
// Icons
// Layouts
// Components
import { ArrowBackLink } from "components";
import { UserForm } from "../components";
// Styled
import { Typography } from "styled/Typography";
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";

export const AddUser: FC = () => {
  const { openNotification } = useNotification();
  const navigate = useNavigate();

  const companyId = useAppSelector(getUserCompanyId);

  const [create, { isLoading: isCreateLoading }] = useCreateUserMutation();
  const { data: locationsData, error } = useGetLocationsQuery(
    {
      companyId,
    },
    {
      skip: !companyId,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    },
  );

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const handleSendRequest = async (formValues: UserFormTypes) => {
    const { permissions, role, locations, ...data } = formValues;
    try {
      await create({
        ...data,
        companyId,
        permissionIds: permissions,
        siteIds: locations,
        roleId: role,
      }).unwrap();

      navigate(-1);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const locationsOptions =
    locationsData?.map(location => ({
      label: location.name,
      value: location.id,
      renderValue: location.name,
    })) || [];

  return (
    <FlexContainer $column $gap={24}>
      <PageHeadingContainer>
        <PageTitleContainer $column $gap={16}>
          <ArrowBackLink title="Back to Users" path={ADMIN_ROUTES.USERS.path} />

          <Typography.H1>Add User</Typography.H1>
        </PageTitleContainer>
      </PageHeadingContainer>

      <UserForm
        isSubmitLoading={isCreateLoading}
        locationsOptions={locationsOptions}
        handleSendRequest={handleSendRequest}
      />
    </FlexContainer>
  );
};
