import styled from "styled-components";
import { ContentBox, HorizontalDevider } from "styled/Box";
import { theme } from "theme";

export const Wrapper = styled(ContentBox)`
  width: 100%;
  padding: 0;
  gap: 0;
  overflow: hidden;

  @media (max-width: 1240px) {
    flex-direction: column;
  }
`;

export const ButtonsWrapper = styled.div`
  min-width: 340px;
  max-width: 420px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-right: 1px solid ${theme.color.borderLight};

  @media (max-width: 1240px) {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .devider {
      display: none;
    }
  }

  @media (max-width: 998px) {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr;

    .devider {
      display: block;
    }
  }
`;

export const Devider = styled(HorizontalDevider)`
  margin: 0 7px;
`;
