import { SearchSelect } from "components/Form";
import styled from "styled-components";

export const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px;

  @media (max-width: 800px) {
    height: 100px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const SelectContainer = styled.div`
  width: 200px;
`;

export const StyledSearchSelect = styled(SearchSelect)`
  .ant-select-selection-item {
    padding-top: 5px !important;
  }
`;
