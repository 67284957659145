// Lib
import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
// Hooks
import { usePermissions, useViewport } from "hooks";
// Types
import { RangePickerProps } from "antd/es/date-picker";
import {
  ESnoozeType,
  SnoozeBulkItems,
  SnoozeCategory,
  SnoozeItem,
} from "../../types";
// Helpers
import { getTotal } from "../../helpers";
// Components
import { Modal, SegmentedButtons } from "components";
import { DatePicker, Select } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";

import { snoozeOptions } from "../config";

interface SnoozeModalProps {
  isLoading: boolean;
  open: boolean;
  data: SnoozeCategory | SnoozeItem | SnoozeBulkItems | null;
  handleSave: (args: { value: string; until: string | dayjs.Dayjs }) => void;
  handleClose: () => void;
}

export const SnoozeModal: FC<SnoozeModalProps> = ({
  isLoading,
  open,
  data,
  handleSave,
  handleClose,
}) => {
  const { isMobile, isLargeMobile } = useViewport();
  const { canSnoozeMenuItems } = usePermissions();

  const [tabsValue, setTabsValue] = useState<string | number>(
    snoozeOptions[0].value,
  );

  const [untilDate, setUntilDate] = useState<string | dayjs.Dayjs>("");

  useEffect(() => {
    if (!!untilDate && tabsValue !== "snooze_until") {
      setUntilDate("");
    }
  }, [tabsValue]);

  useEffect(() => {
    if (!open) {
      setTabsValue(snoozeOptions[0].value);
    }
  }, [open]);

  const onChangeDate = (e: dayjs.Dayjs) => {
    setUntilDate(e);
  };

  const onSubmit = () => {
    handleSave({ value: tabsValue.toString(), until: untilDate });
  };

  const onClose = () => {
    if (isLoading) {
      return;
    }
    handleClose();
  };

  const total = getTotal(data);

  const disabledDate: RangePickerProps["disabledDate"] = current => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  const title =
    !!data && data?.type === ESnoozeType.CATEGORY
      ? `Disable ${data.category.name}`
      : "Disable for";

  const description =
    !!data && data?.type === ESnoozeType.ITEM
      ? null
      : data?.type === ESnoozeType.CATEGORY
      ? `${total} items in this category will be disabled`
      : data?.type === ESnoozeType.BULK_ITEMS
      ? `${total} items will be disabled`
      : null;

  const submitButtonIsDisabled =
    !canSnoozeMenuItems || (tabsValue === "snooze_until" && !untilDate);

  return (
    <Modal title={title} width={568} open={!!open} onClose={onClose}>
      <FlexContainer
        $fullwidth
        $column
        $align={isLargeMobile ? "stretch" : "flex-start"}
        $gap={16}
      >
        {!!description && (
          <Typography.DescriptionThin>{description}</Typography.DescriptionThin>
        )}

        {isLargeMobile ? (
          <Select
            value={tabsValue}
            onChange={setTabsValue}
            options={snoozeOptions}
          />
        ) : (
          <SegmentedButtons
            value={tabsValue}
            setValue={setTabsValue}
            options={snoozeOptions}
          />
        )}

        {tabsValue === "snooze_until" && (
          <DatePicker
            label="Date"
            placeholder="DD/MM/YYYY"
            format={"DD/MM/YYYY"}
            disabledDate={disabledDate}
            onChange={onChangeDate}
          />
        )}
      </FlexContainer>

      <FlexContainer
        $column={isMobile}
        $gap={8}
        $justify="flex-end"
        $padding="24px 0 0"
      >
        <Button.Heading
          $fullWidth={isMobile}
          disabled={isLoading}
          onClick={onClose}
        >
          Cancel
        </Button.Heading>

        <Button.Heading
          $fullWidth={isMobile}
          type="primary"
          disabled={submitButtonIsDisabled}
          loading={isLoading}
          onClick={onSubmit}
        >
          Snooze
        </Button.Heading>
      </FlexContainer>
    </Modal>
  );
};
