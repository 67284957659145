// Lib
import { FC } from "react";
import { TooltipProps } from "recharts";
// Styled
import { Typography } from "styled/Typography";
import { ToolTipBody, ToolTipDataContainer } from "./styled";

export const ChartTooltip: FC<TooltipProps<number, string>> = ({
  active,
  payload,
}) => {
  if (!active || !payload) {
    return null;
  }

  const isComparedWithPrev = !!payload[0]?.payload?.tooltipTitles?.prev;

  if (isComparedWithPrev) {
    return (
      <ToolTipBody>
        {payload.map(el => (
          <ToolTipDataContainer key={`${el.value}-${el.name}`}>
            <Typography.TitleThin>
              {el.payload.tooltipTitles?.[el.name] || el.name}
            </Typography.TitleThin>

            <Typography.Title>{el.value}</Typography.Title>
          </ToolTipDataContainer>
        ))}
      </ToolTipBody>
    );
  }

  return (
    <ToolTipBody>
      <ToolTipDataContainer>
        <Typography.TitleThin>{payload[0]?.payload?.name}</Typography.TitleThin>
      </ToolTipDataContainer>

      {payload.map(el => (
        <ToolTipDataContainer key={`${el.value}-${el.name}`} $small>
          <Typography.TitleThin>
            {el.payload.tooltipTitles?.[el.dataKey]}
          </Typography.TitleThin>

          <Typography.Title>
            {Math.round(el.value * 1000) / 1000}
          </Typography.Title>
        </ToolTipDataContainer>
      ))}
    </ToolTipBody>
  );
};
