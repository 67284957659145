// Lib
import { createSlice } from "@reduxjs/toolkit";
import { storage } from "helpers/dataHelpers";
// Types
import { AuthState } from "types/auth";
// Utils
import { token } from "utils/handleToken";

const initialState: AuthState = {
  isAuth: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLogin: state => {
      state.isAuth = true;
    },
    userLogout: state => {
      storage.remove("New");
      token.clear();
      state.isAuth = false;
    },
  },
});

export const { userLogin, userLogout } = authSlice.actions;
export const authSliceReducer = authSlice.reducer;
