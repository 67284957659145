import { FC } from "react";

import {
  KitchenBusyMode,
  KitchenInventory,
  KitchenInventoryDetails,
  KitchenOrders,
  KitchenSettings,
} from "pages";

import {
  Location,
  Menus,
  Orders,
  Snoozing,
  Products,
  AnalyticsLocations,
  AnalyticsMenuItems,
  AnalyticsOrders,
  OperationsReport,
  Schedule,
  Settings,
} from "pages/Loop";

import { Inventory } from "pages/Loop/Inventory/Main";
import {
  AddLocation,
  EditChannelLink,
  EditLocation,
  OpeningHours,
  AddChannelLink,
} from "pages/Loop/Location/View";
import { OrdersView } from "pages/Loop/Orders/View";
import { EditMenu, PreviewMenu } from "pages/Loop/Menus/View";
import { AddUser, EditUser } from "pages/Loop/Users/View";
import {
  CreateEditModifier,
  CreateEditProduct,
  Modifiers,
  Tags,
} from "pages/Loop/Products/View";
import { OperationReportDetails } from "pages/Loop/OperationsReport/View/OperationReportDetails";
import { Permission } from "./permissions";
import { InventoryDetails } from "pages/Loop/Inventory/View";
import { Labels } from "pages/Loop/Products/View/Labels";
import { MenuItemAnalyticsDetails } from "pages/Loop/Analytics/AnalyticsMenuItems/View";
import { PosEmployees } from "pages/Loop/PosEmployees";
import { LocationAnalyticsDetails } from "pages/Loop/Analytics/AnalyticsLocations/View/LocationAnalyticsDetails";
import { Users } from "pages/Loop/Users";
import { CustomerDetails } from "pages/Loop/Сustomers/View/CustomerDetails";
import { Customers } from "pages/Loop/Сustomers";

// import { CreateEditProfile } from "pages/Loop/Settings/Tablets/View/CreateEditProfile";

export interface Route {
  path: string;
  Component: FC;
  access?: boolean | Permission[];
  side: "kitchen" | "admin";
}

export const ADMIN_ROUTE_PATH = "/loop/";
export const LOGIN = "/login";
export const ADMIN_HOME_ROUTE = "/loop/orders";
export const KITCHEN_PWA_HOME_ROUTE = "/orders";

export const ADMIN_ROUTES: Record<string, Route> = {
  ORDERS: {
    path: "/loop/orders",
    Component: Orders,
    access: true,
    side: "admin",
  },
  ORDERS_VIEW: {
    path: "/loop/orders/:id",
    Component: OrdersView,
    access: true,
    side: "admin",
  },
  LOCATIONS: {
    path: "/loop/location",
    Component: Location,
    access: [Permission.ListSites],
    side: "admin",
  },
  LOCATION_EDIT: {
    path: "/loop/location/edit/:id",
    Component: EditLocation,
    access: [Permission.ReadSite],
    side: "admin",
  },
  LOCATION_CREATE: {
    path: "/loop/location/create",
    Component: AddLocation,
    access: [Permission.CreateSite],
    side: "admin",
  },
  OPENING_HOURS: {
    path: "/loop/location/opening_hours/:id",
    Component: OpeningHours,
    access: [Permission.ReadSite],
    side: "admin",
  },
  EDIT_CHANNEL_LINK: {
    path: "/loop/location/channel_link/:id/:siteId",
    Component: EditChannelLink,
    access: [Permission.ReadSite],
    side: "admin",
  },
  ADD_CHANNEL_LINK: {
    path: "/loop/location/channel_link/create/:siteId",
    Component: AddChannelLink,
    access: [Permission.UpdateSite],
    side: "admin",
  },
  MENUS: {
    path: "/loop/menus/all-menus",
    Component: Menus,
    access: [Permission.ListMenus],
    side: "admin",
  },
  SCHEDULE_MENUS: {
    path: "/loop/menus/schedule",
    Component: Schedule,
    access: [Permission.ListMenus],
    side: "admin",
  },
  ADD_MENUS: {
    path: "/loop/menus/create",
    Component: EditMenu,
    access: [Permission.CreateMenu],
    side: "admin",
  },
  EDIT_MENUS: {
    path: "/loop/menus/edit/:id",
    Component: EditMenu,
    access: [Permission.UpdateMenu],
    side: "admin",
  },
  PREVIEW_MENUS: {
    path: "/loop/menus/preview/:id",
    Component: PreviewMenu,
    access: [Permission.ReadMenu],
    side: "admin",
  },
  PRODUCTS: {
    path: "/loop/products/all-products",
    Component: Products,
    access: [Permission.ListProducts],
    side: "admin",
  },
  ADD_PRODUCTS: {
    path: "/loop/products/create",
    Component: CreateEditProduct,
    access: [Permission.CreateProduct],
    side: "admin",
  },
  EDIT_PRODUCTS: {
    path: "/loop/products/edit/:id",
    Component: CreateEditProduct,
    access: [Permission.ReadProduct],
    side: "admin",
  },
  MODIFIERS: {
    path: "/loop/products/addons-modifiers",
    Component: Modifiers,
    access: [Permission.ListProducts],
    side: "admin",
  },
  EDIT_MODIFIERS: {
    path: "/loop/addons-modifiers/edit/:id",
    Component: CreateEditModifier,
    access: [Permission.ReadProduct],
    side: "admin",
  },
  TAGS: {
    path: "/loop/products/tags",
    Component: Tags,
    access: [Permission.ListTags],
    side: "admin",
  },
  LABELS: {
    path: "/loop/products/labels",
    Component: Labels,
    access: [Permission.ListTags],
    side: "admin",
  },
  SNOOZING: {
    path: "/loop/operations/snoozing",
    Component: Snoozing,
    access: [Permission.SnoozeMenuItems],
    side: "admin",
  },
  OPERATIONS_REPORT: {
    path: "/loop/operations/operations_report",
    Component: OperationsReport,
    access: [Permission.ListOperationLogs],
    side: "admin",
  },
  OPERATIONS_REPORT_DETAILS: {
    path: "/loop/operations/operations_report/:id",
    Component: OperationReportDetails,
    access: [Permission.ReadOperationLog],
    side: "admin",
  },
  ANALYTICS_ORDERS: {
    path: "/loop/analytics/orders-analytics",
    Component: AnalyticsOrders,
    access: true,
    side: "admin",
  },
  ANALYTICS_MENUS: {
    path: "/loop/analytics/menus-analytics",
    Component: AnalyticsMenuItems,
    access: true,
    side: "admin",
  },
  ANALYTICS_MENUS_DETAILS: {
    path: "/loop/analytics/menus-analytics/:id",
    Component: MenuItemAnalyticsDetails,
    access: true,
    side: "admin",
  },
  ANALYTICS_LOCATIONS: {
    path: "/loop/analytics/locations-analytics",
    Component: AnalyticsLocations,
    access: true,
    side: "admin",
  },
  ANALYTICS_LOCATIONS_DETAILS: {
    path: "/loop/analytics/locations-analytics/:id",
    Component: LocationAnalyticsDetails,
    access: true,
    side: "admin",
  },
  POS_EMPLOYEES: {
    path: "/loop/pos-employees",
    Component: PosEmployees,
    access: [Permission.ListSimphonyEmployee],
    side: "admin",
  },
  USERS: {
    path: "/loop/users",
    Component: Users,
    access: [Permission.ListUsers],
    side: "admin",
  },
  USERS_CREATE: {
    path: "/loop/users/create",
    Component: AddUser,
    access: [Permission.CreateUser],
    side: "admin",
  },
  USERS_EDIT: {
    path: "/loop/users/:id",
    Component: EditUser,
    access: [Permission.UpdateUser],
    side: "admin",
  },
  SETTINGS_TABLETS: {
    path: "/loop/settings",
    Component: Settings,
    access: true,
    side: "admin",
  },
  INVENTORY: {
    path: "/loop/inventory",
    Component: Inventory,
    access: [Permission.ReadInventory],
    side: "admin",
  },
  INVENTORY_DETAILS: {
    path: "/loop/inventory/:locationId/:menuId/:id",
    Component: InventoryDetails,
    access: [Permission.ReadInventory],
    side: "admin",
  },
  CUSTOMERS: {
    path: "/loop/customers",
    Component: Customers,
    access: true,
    side: "admin",
  },
  CUSTOMERS_DETAILS: {
    path: "/loop/customers/:id",
    Component: CustomerDetails,
    access: true,
    side: "admin",
  },
};

export const KITCHEN_PWA_ROUTES: Record<string, Route> = {
  KITCHEN_ORDERS: {
    path: "/orders",
    Component: KitchenOrders,
    access: true,
    side: "kitchen",
  },
  KITCHEN_BUSY_MODE: {
    path: "/busy-mode",
    Component: KitchenBusyMode,
    access: true,
    side: "kitchen",
  },
  KITCHEN_INVENTORY: {
    path: "/inventory",
    Component: KitchenInventory,
    access: [Permission.ReadInventory],
    side: "kitchen",
  },
  KITCHEN_INVENTORY_DETAILS: {
    path: "/inventory/:locationId/:menuId/:id",
    Component: KitchenInventoryDetails,
    access: [Permission.ReadInventory],
    side: "kitchen",
  },
  KITCHEN_SETTINGS: {
    path: "/settings",
    Component: KitchenSettings,
    access: true,
    side: "kitchen",
  },
};
