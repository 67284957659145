import dayjs from "dayjs";
import {
  AnalyticsMenuItemsTableData,
  CreateTableDataHelperInputType,
} from "./types";

import { generateDates } from "../../helpers";

export const createTableData = ({
  dateFilter,
  location,
  data,
}: CreateTableDataHelperInputType): AnalyticsMenuItemsTableData[] => {
  const tableData = data.reduce((acc, el) => {
    if (location !== "all" && el.locationId !== location) {
      return acc;
    }

    if (el.orderStatus !== "delivered" && el.orderStatus !== "auto_finalized") {
      return acc;
    }

    const { current } = generateDates(dateFilter);

    if (
      !dayjs(el.updatedAt).isBetween(
        dayjs(current.startTime),
        dayjs(current.endTime),
      )
    ) {
      return acc;
    }

    el.items.forEach(product => {
      const isSoldIndividualy = el.items?.length === 1;

      if (acc?.[product.productName]) {
        acc[product.productName] = {
          ...acc[product.productName],
          totalSold:
            acc[product.productName].totalSold +
            product.fiatCentsPrice * product.quantity,
          soldIndividualy: isSoldIndividualy
            ? acc[product.productName].soldIndividualy +
              product.fiatCentsPrice * product.quantity
            : acc[product.productName].soldIndividualy,
        };

        return acc;
      }

      acc[product.productName] = {
        id: product.productId,
        name: product.productName,
        totalSold: product.fiatCentsPrice * product.quantity,
        soldIndividualy: product.fiatCentsPrice * product.quantity,
      };

      return acc;
    });

    return acc;
  }, {});

  return Object.keys(tableData).map(name => tableData[name]);
};
