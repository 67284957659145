import { endpoints } from "consts";

export const snoozeUrlBuilder = {
  snoozeItems: (): string => {
    return `${endpoints.snooze}`;
  },

  unsnoozeItems: (): string => {
    return `${endpoints.snooze}un-snooze/`;
  },
};
