export interface SnoozeItemsRequest {
  data: {
    siteId: string;
    items: {
      snoozedUntil: string | null;
      menuItemId: string;
    }[];
  };
}

export interface UnsnoozeItemsRequest {
  data: {
    siteId: string;
    items: string[];
  };
}

export enum SnoozeOptionsTypes {
  Snooze1H = "1H",
  Snooze2H = "2H",
  Snooze4H = "4H",
  Snooze6H = "6H",
  Snooze12H = "12H",
  SnoozeIndefinitely = "Indefinitely",
  SnoozeUntil = "Snooze until",
}
export enum SnoozeValuesTypes {
  Snooze1H = "1h",
  Snooze2H = "2h",
  Snooze4H = "4h",
  Snooze6H = "6h",
  Snooze12H = "12h",
  SnoozeIndefinitely = "indefinitely",
  SnoozeUntil = "snooze_until",
}
