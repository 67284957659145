import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, bool } from "yup";
import { LoginFormTypes } from ".";

const schema = object({
  email: string().trim().required("Email is required"),
  password: string().trim().required("Password is required"),
  rememberMe: bool(),
}).required();

export const resolver = yupResolver<LoginFormTypes>(schema);
