import { FC } from "react";

import { StyledButton } from "./styled";
import { ButtonProps } from "antd";

interface ButtonLinkProps extends ButtonProps {
  danger?: boolean;
  grey?: boolean;
  title: string;
  onClick?: () => void;
}

export const ButtonLink: FC<ButtonLinkProps> = ({
  danger,
  title,
  grey,
  onClick,
  ...props
}) => {
  return (
    <StyledButton
      danger={danger}
      type="link"
      {...props}
      $grey={grey}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
    >
      {title}
    </StyledButton>
  );
};
