import { styled } from "styled-components";
import { Button } from "styled/Buttons";
import { theme } from "theme";

export const CallButton = styled(Button.Form)`
  margin-top: 8px;
  margin-left: 52px;
`;

export const HeaderButton = styled(Button.Form)<{ $bgColor?: string }>`
  background-color: ${props => props.$bgColor || theme.color.status.success};
  color: ${theme.color.white};
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.color.borderLight};
`;

export const GridDataContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @media (max-width: 840px) {
    grid-template-columns: 1fr;
  }
`;
