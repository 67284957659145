import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { MONTH_DAY_YEAR_HOURS_MINUTES_DATE, TABLE_DATE } from "consts";

import { EFilterDateValue, UrlQueryParams } from "types/common";
import { Product, ProductTag } from "types/products";
import {
  GetKitchenOrdersResponse,
  KitchenOrder,
  KitchenOrderTab,
} from "types/kitchen";
import { OrderStatus } from "types/orders";
import { GetUsersResponseDto } from "types/users";
import { SettingOptionType } from "pages/Loop/Settings/types";

dayjs.extend(utc);
dayjs.extend(timezone);

export const generateTags = (product: Product) => {
  const alergents = product?.alergents?.map(a => a.name) || [];
  const highlights = product?.highlights?.map(a => a.name) || [];

  return alergents.concat(highlights);
};

export const getLabel = (product: Product): ProductTag[] => {
  return product.labels || [];
};

const set = (key: string, data: any): void => {
  const jsonData = JSON.stringify(data);
  localStorage.setItem(key, jsonData);
};

const get = (key: string): any | null => {
  const jsonData = localStorage.getItem(key);
  if (jsonData) {
    return JSON.parse(jsonData);
  }
  return null;
};

const remove = (key: string): void => {
  localStorage.removeItem(key);
};

export const storage = {
  set,
  get,
  remove,
};

export const convertToTitleCase = (str: string): string => {
  const valueUpperCase = `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  return valueUpperCase.replace(/_/g, " ");
};

export const isMatch = (arg1: unknown, arg2: unknown) =>
  JSON.stringify(arg1) === JSON.stringify(arg2);

export const convertStatusToText = (status: string) =>
  status.replaceAll("_", " ");

export const generateRequestUrl = (
  url: string,
  queryParams?: UrlQueryParams,
): string => {
  if (queryParams) {
    const queryString = Object.entries(queryParams)
      .map(([key, value]) =>
        value ? `${key}=${encodeURIComponent(value.toString())}` : undefined,
      )
      .filter(Boolean)
      .join("&");

    return `${url}?${queryString}`;
  }

  return url;
};

export const generateUniqueId = (): string => {
  const timestamp = new Date().getTime().toString(16);
  const randomNum = (Math.random() * Number.MAX_SAFE_INTEGER).toString(16);
  return `${timestamp}-${randomNum}`;
};

export const dateTransform = ({
  date,
  format = TABLE_DATE,
}: {
  date: string | Date | dayjs.Dayjs;
  format?: string;
}) => {
  if (!date) {
    return null;
  }

  return dayjs(date).format(format);
};

export const dateCreate = (
  date?: string | Date | dayjs.Dayjs,
  isUTCDisabled?: boolean,
): dayjs.Dayjs => {
  if (date === null) {
    return;
  }

  if (!date) {
    return isUTCDisabled
      ? dayjs(Date.now()).local()
      : dayjs.utc(Date.now()).local();
  }
  return isUTCDisabled ? dayjs(date).local() : dayjs.utc(date).local();
};

export const dateTransformToRequestFormat = (
  date: dayjs.Dayjs,
  utc?: boolean,
) => {
  return utc ? dayjs.utc(date).toISOString() : date.toISOString();
};

export const getRageDate = (value: EFilterDateValue | string, name: string) => {
  switch (value) {
    case EFilterDateValue.TODAY:
      return {
        [`${name}Before`]: dayjs().startOf("day").toISOString(),
        [`${name}After`]: dayjs().endOf("day").toISOString(),
      };
    case EFilterDateValue.YESTERDAY:
      return {
        [`${name}Before`]: dateCreate()
          .subtract(1, "day")
          .startOf("day")
          .toISOString(),
        [`${name}After`]: dateCreate()
          .subtract(1, "day")
          .endOf("day")
          .toISOString(),
      };
    case EFilterDateValue.IN_THE_LAST_7_DAYS:
      return {
        [`${name}Before`]: dayjs()
          .subtract(8, "day")
          .startOf("day")
          .toISOString(),
        [`${name}After`]: dayjs().subtract(1, "day").endOf("day").toISOString(),
      };
    case EFilterDateValue.IN_THE_LAST_30_DAYS:
      return {
        [`${name}Before`]: dayjs()
          .subtract(31, "day")
          .startOf("day")
          .toISOString(),
        [`${name}After`]: dayjs().subtract(1, "day").endOf("day").toISOString(),
      };
    case EFilterDateValue.IN_THE_LAST_90_DAYS:
      return {
        [`${name}Before`]: dayjs()
          .subtract(91, "day")
          .startOf("day")
          .toISOString(),
        [`${name}After`]: dayjs().subtract(1, "day").endOf("day").toISOString(),
      };
    case EFilterDateValue.IN_THE_12_MONTHS:
      return {
        [`${name}Before`]: dayjs()
          .subtract(12, "month")
          .subtract(1, "day")
          .startOf("day")
          .toISOString(),
        [`${name}After`]: dayjs().subtract(1, "day").endOf("day").toISOString(),
      };
    default:
      return {
        [`${name}Before`]: dayjs().startOf("day").toISOString(),
        [`${name}After`]: dayjs().endOf("day").toISOString(),
      };
  }
};

export const checkSelectedOrderStatus = (
  selectedOrder: KitchenOrder,
  ordersData: GetKitchenOrdersResponse,
): OrderStatus | undefined => {
  const currentOrder = ordersData.data.find(o => o.id === selectedOrder?.id);

  if (!currentOrder) {
    return;
  }

  if (currentOrder.status === selectedOrder.status) {
    return;
  }

  return currentOrder.status;
};

export const handleIncomingOrders = (newOrders: KitchenOrder[]) => {
  if (!newOrders.length) {
    storage.set(KitchenOrderTab.NEW, []);
    return;
  }
  const alreadyReadedIds: string[] = storage.get(KitchenOrderTab.NEW);

  const incoming = newOrders.map(el => el);

  if (!alreadyReadedIds || !alreadyReadedIds?.length) {
    storage.set(
      KitchenOrderTab.NEW,
      incoming.map(o => o.id),
    );
    return incoming;
  }

  if (alreadyReadedIds?.length) {
    const notReadedOrders = incoming.filter(
      o => !alreadyReadedIds.includes(o.id),
    );

    storage.set(
      KitchenOrderTab.NEW,
      alreadyReadedIds.concat(notReadedOrders.map(o => o.id)),
    );

    return notReadedOrders;
  }
};

export const removeReadedIncomingOrder = (
  id: string,
  incomingOrders: KitchenOrder[],
) => {
  const filteredOrders = incomingOrders.filter(el => el.id !== id);

  const storageReadedIds = storage
    .get(KitchenOrderTab.NEW)
    .filter(el => el !== id);

  storage.set(KitchenOrderTab.NEW, storageReadedIds);

  return filteredOrders;
};

export const getFullName = ({
  firstName,
  lastName,
}: {
  firstName: string | null;
  lastName: string | null;
}): string | null => [firstName, lastName].filter(Boolean).join(" ") || null;

export const usersMapper = (
  response: GetUsersResponseDto,
): GetUsersResponseDto => {
  return response.map(user => ({
    ...user,
    fullName: getFullName({
      firstName: user?.firstName,
      lastName: user?.lastName,
    }),
  }));
};

export const isTimeBetween = (
  startTime: string | Dayjs,
  endTime: string | Dayjs,
  time: string | Dayjs,
) => {
  return dayjs(time).isBetween(dayjs(startTime), dayjs(endTime));
};

//transform utc time 'HH:mm:ss' to local time 'HH:mm:ss'
export const transformScheduleHoursTimeToLocalValues = (time: string) => {
  const data = time.split(":");
  const hours = +data[0];
  const minutes = +data[1];
  const seconds = +data[2];

  const date = dayjs
    .utc()
    .hour(hours)
    .minute(minutes)
    .second(seconds)
    .local()
    .format("HH:mm:ss");

  return date;
};

//transform utc time 'HH:mm:ss' to local Date
export const transformScheduleHoursTimeToLocalDate = (time: string) => {
  const data = time.split(":");
  const hours = +data[0];
  const minutes = +data[1];
  const seconds = +data[2];

  const date = dayjs.utc().hour(hours).minute(minutes).second(seconds).local();

  return date;
};

export const createTimeString = (time: Dayjs): string => {
  if (!time) {
    return "";
  }
  return time.format("HH:mm");
};

export const createDateStringFromTime = (time: string): Dayjs => {
  if (!time) {
    return null;
  }

  const data = time.split(":");
  const hours = +data[0];
  const minutes = +data[1];

  return dayjs().hour(hours).minute(minutes);
};

export const getSnoozeEndDate = (date: string, format?: string) => {
  if (!date) {
    return null;
  }

  if (dayjs(date).diff(dayjs(), "year") > 2) {
    return "Indefinite";
  }

  return dateTransform({
    date,
    format: format || MONTH_DAY_YEAR_HOURS_MINUTES_DATE,
  });
};

export const createDefaultValues = <T>(value: T): SettingOptionType<T> => ({
  value,
  isLoading: false,
});

export const fixedDigitsValue = (
  data: number | string | undefined,
  digits?: number,
): string | number => {
  if (typeof data === "number") {
    if (digits) {
      return data?.toFixed(digits);
    }

    return data;
  }
  if (typeof data === "string") {
    if (digits) {
      return Number(data)?.toFixed(digits);
    }

    return data;
  }

  return data;
};
