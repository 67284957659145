import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const PosIcon: FC<SvgIconConstituentValues> = ({ fill, ...props }) => (
  <svg
    fill={fill || "#646965"}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 612 612"
    {...props}
  >
    <g>
      <path
        d="M459.887,56.471h-39.291V19.645C420.596,8.801,411.795,0,400.951,0H211.052c-10.845,0-19.646,8.801-19.646,19.645v36.828
		h-39.29c-25.329,0-45.839,20.522-45.839,45.839v463.849c0,25.316,20.508,45.839,45.839,45.839h307.769
		c25.328,0,45.838-20.522,45.838-45.839V102.31C505.725,77.008,485.215,56.471,459.887,56.471z M358.389,102.31V56.471V39.291h22.92
		v17.183v45.839v43.376h-22.92V102.31z M230.699,102.31V56.471V39.291h72.03v17.183v45.839v43.376h-72.03V102.31L230.699,102.31z
		 M242.708,558.226c0,10.856-8.801,19.646-19.646,19.646h-31.654c-10.845,0-19.646-8.789-19.646-19.646V524.41
		c0-10.856,8.801-19.645,19.646-19.645h31.654c10.845,0,19.646,8.788,19.646,19.645V558.226L242.708,558.226z M242.708,459.451
		c0,10.856-8.801,19.645-19.646,19.645h-31.654c-10.845,0-19.646-8.788-19.646-19.645v-33.815c0-10.856,8.801-19.646,19.646-19.646
		h31.654c10.845,0,19.646,8.789,19.646,19.646V459.451L242.708,459.451z M242.708,360.69c0,10.856-8.801,19.646-19.646,19.646
		h-31.654c-10.845,0-19.646-8.789-19.646-19.646v-33.827c0-10.857,8.801-19.646,19.646-19.646h31.654
		c10.845,0,19.646,8.788,19.646,19.646V360.69L242.708,360.69z M341.48,558.226c0,10.856-8.801,19.646-19.645,19.646h-31.667
		c-10.845,0-19.645-8.789-19.645-19.646V524.41c0-10.856,8.8-19.645,19.645-19.645h31.667c10.844,0,19.645,8.788,19.645,19.645
		V558.226z M341.48,459.451c0,10.856-8.801,19.645-19.645,19.645h-31.667c-10.845,0-19.645-8.788-19.645-19.645v-33.815
		c0-10.856,8.8-19.646,19.645-19.646h31.667c10.844,0,19.645,8.789,19.645,19.646V459.451z M341.48,360.69
		c0,10.856-8.801,19.646-19.645,19.646h-31.667c-10.845,0-19.645-8.789-19.645-19.646v-33.827c0-10.857,8.8-19.646,19.645-19.646
		h31.667c10.844,0,19.645,8.788,19.645,19.646V360.69z M440.244,558.226c0,10.856-8.801,19.646-19.646,19.646h-31.654
		c-10.844,0-19.645-8.789-19.645-19.646V524.41c0-10.856,8.801-19.645,19.645-19.645h31.654c10.846,0,19.646,8.788,19.646,19.645
		V558.226z M440.244,459.451c0,10.856-8.801,19.645-19.646,19.645h-31.654c-10.844,0-19.645-8.788-19.645-19.645v-33.815
		c0-10.856,8.801-19.646,19.645-19.646h31.654c10.846,0,19.646,8.789,19.646,19.646V459.451z M440.244,360.69
		c0,10.856-8.801,19.646-19.646,19.646h-31.654c-10.844,0-19.645-8.789-19.645-19.646v-33.827c0-10.857,8.801-19.646,19.645-19.646
		h31.654c10.846,0,19.646,8.788,19.646,19.646V360.69z M440.244,245.979c0,18.531-15.035,33.554-33.566,33.554H205.319
		c-18.531,0-33.554-15.023-33.554-33.554v-34.666c0-18.546,15.023-33.566,33.554-33.566h201.358
		c18.531,0,33.566,15.023,33.566,33.566V245.979z"
      />
    </g>
  </svg>
);
