import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const menuScheduleUrlBuilder = {
  getMenusScheduleCalendar: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.menuSchedule}calendar/companies/${companyId}`,
      query,
    );
  },
  getMenusScheduleList: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.menuSchedule}companies/${companyId}`,
      query,
    );
  },
  getMenuSchedule: ({ id }: { id: string }): string => {
    return `${endpoints.menuSchedule}${id}`;
  },
  createMenuSchedule: ({ companyId }: { companyId: string }): string => {
    return `${endpoints.menuSchedule}companies/${companyId}`;
  },
  updateMenuSchedule: ({ id }: { id: string }): string => {
    return `${endpoints.menuSchedule}${id}`;
  },
  deleteMenuSchedule: ({ id }: { id: string }): string => {
    return `${endpoints.menuSchedule}${id}`;
  },
};
