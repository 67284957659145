// Lib
import styled from "styled-components";
// Theme
import { theme } from "theme";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";

export const StyledImage = styled.img`
  border: 1px solid ${theme.color.borderLight};
  border-radius: ${theme.borderRadius.base};
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 351px;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const IconContainer = styled(FlexContainer)`
  border-radius: 50%;
  background: ${theme.color.background.secondary};
  padding: 8px;
`;

export const Copy = styled(Typography.DescriptionThin)`
  color: ${theme.color.text.secondary};
`;

export const ActionsContainer = styled(FlexContainer)`
  position: absolute;
  top: 16px;
  right: 16px;
  gap: 8px;
`;
