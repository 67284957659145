import { Avatar } from "components";
import { TABLE_DATE } from "consts";
import { dateTransform } from "helpers/dataHelpers";

import { FlexContainer } from "styled/Box";

export const columns = [
  {
    title: "Customer name",
    dataIndex: "fullName",
    key: "firstName",
    sorter: true,
    width: 355,
    render: (name: string | null) =>
      name ? (
        <FlexContainer $align="center" $gap={8}>
          <Avatar name={name} /> {name}
        </FlexContainer>
      ) : null,
  },
  {
    title: "Phone number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    sorter: true,
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 180,
    sorter: true,
    render: (date: string) =>
      dateTransform({
        date,
        format: TABLE_DATE,
      }),
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: 180,
    sorter: true,
    render: (date: string) =>
      dateTransform({
        date,
        format: TABLE_DATE,
      }),
  },
];
