// Lib
import { FC, useState } from "react";
import { Image } from "antd";
// Api
import { useUpdateKitchenChannelLinkMutation } from "rtkQuery/query/kitchenLocationsAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getActiveKitchen } from "rtkQuery/selectors";
// Types
import { StatusType } from "types/common";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Status } from "components";
// Styled
import { ContentBox, FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";

import fallback from "images/channelPlaceholder.png";

interface LocationItemProps {
  id: string;
  name: string;
  status: StatusType;
  simphonyRevenuCenterId: string;
}

export const LocationItem: FC<LocationItemProps> = ({
  id,
  name,
  status,
  simphonyRevenuCenterId,
}) => {
  const { isMobile } = useViewport();
  const kitchen = useAppSelector(getActiveKitchen);

  const [channelStatus, setChannelStatus] = useState<StatusType>(status);

  const [update, { isLoading }] = useUpdateKitchenChannelLinkMutation();

  const { openNotification } = useNotification();

  const tagStyle = channelStatus === StatusType.Active ? "success" : "danger";
  const tagText = channelStatus === StatusType.Active ? "Open" : "Disabled";

  const buttonStatus =
    channelStatus === StatusType.Active ? "success" : "danger";
  const buttonText =
    channelStatus === StatusType.Active ? "Close store" : "Open store";

  const onStatusChange = async () => {
    const newStatus =
      channelStatus === StatusType.Active
        ? StatusType.Suspended
        : StatusType.Active;
    try {
      await update({
        id,
        siteId: kitchen.id,
        data: {
          name,
          simphonyRevenuCenterId,
          status: newStatus,
        },
      }).unwrap();
      setChannelStatus(newStatus);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  return (
    <ContentBox
      $fullwidth
      $column={isMobile}
      $align={isMobile ? "flex-start" : "center"}
      $justify="space-between"
      $gap={16}
    >
      <FlexContainer $align="center" $gap={16}>
        <Image src={fallback} preview={false} width={56} height={56} />

        <FlexContainer $column $gap={4}>
          <Typography.Title>{name}</Typography.Title>

          <Status type="outlined" text={tagText} status={tagStyle} />
        </FlexContainer>
      </FlexContainer>

      <Button.Base
        $fullWidth={isMobile}
        status={buttonStatus}
        loading={isLoading}
        onClick={onStatusChange}
      >
        {buttonText}
      </Button.Base>
    </ContentBox>
  );
};
