// Lib
import { FC } from "react";
import { Avatar, Skeleton } from "antd";
// Icons
import {
  AvatarIcon,
  CallBlackIcon,
  DeliveryDining,
  DescriptionBlackIcon,
  EmailBlackIcon,
  GridviewIcon,
  LabelBlackIcon,
  LocationsIcon,
  MenuIcon,
  ScheduleIcon,
  StoreIcon,
  TagIcon,
} from "icons";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { DescriptionText, IconText, InfoItemRounded, Label } from "./styled";

const iconCollection = {
  note: () => <DescriptionBlackIcon />,
  schedule: () => <ScheduleIcon />,
  label: () => <LabelBlackIcon />,
  description: () => <DescriptionBlackIcon />,
  call: () => <CallBlackIcon />,
  email: () => <EmailBlackIcon />,
  tag: () => <TagIcon />,
  location: () => <LocationsIcon />,
  menu: () => <MenuIcon />,
  store: () => <StoreIcon />,
  delivery: () => <DeliveryDining />,
  grid: () => <GridviewIcon />,
  profile: null,
  asText: null,
  descriptionText: null,
  addon: null,
  modifier: null,
};

export interface InfoItemProps {
  isLoading?: boolean;
  type: keyof typeof iconCollection;
  label?: string;
  info: string;
  profileUrl?: string;
}

export const InfoItem: FC<InfoItemProps> = ({
  isLoading,
  type,
  label,
  info,
  profileUrl,
}) => {
  const Icon = iconCollection[type];

  const content = () => {
    switch (type) {
      case "asText":
        return (
          <FlexContainer $align="center">
            {label && (
              <InfoItemRounded $bgColor="#F0F2F1">
                <IconText>{label}</IconText>
              </InfoItemRounded>
            )}

            <FlexContainer $margin="0 0 0 12px">
              {isLoading ? (
                <Skeleton.Input />
              ) : (
                <Typography.Title>{info}</Typography.Title>
              )}
            </FlexContainer>
          </FlexContainer>
        );

      case "descriptionText":
      case "addon":
      case "modifier":
        return (
          <FlexContainer $align="center">
            <FlexContainer $margin="0 0 0 52px">
              {isLoading ? (
                <Skeleton.Input />
              ) : (
                <DescriptionText>{info}</DescriptionText>
              )}
            </FlexContainer>
          </FlexContainer>
        );

      case "profile":
        return (
          <>
            <FlexContainer $height="40px" $width="40px">
              <Avatar size={40} src={profileUrl || <AvatarIcon />} />
            </FlexContainer>

            <FlexContainer $column $margin="0 0 0 12px">
              {label && <Label>{label}</Label>}

              {isLoading ? (
                <Skeleton.Input />
              ) : (
                <Typography.Title>{info}</Typography.Title>
              )}
            </FlexContainer>
          </>
        );

      default:
        return (
          <FlexContainer $column $margin="0 0 0 12px">
            {label && <Label>{label}</Label>}

            {isLoading ? (
              <Skeleton.Input />
            ) : (
              <Typography.Title>{info}</Typography.Title>
            )}
          </FlexContainer>
        );
    }
  };

  return (
    <FlexContainer $align="flex-start">
      {Icon && (
        <InfoItemRounded $bgColor="#F0F2F1">
          <Icon />
        </InfoItemRounded>
      )}

      {content()}
    </FlexContainer>
  );
};
