import { UserProfileResponseDto } from "types/users";
import { UserFormTypes } from "../types";
import { defaultValues } from "../config";

export const createInitialValues = ({
  userData,
}: {
  userData: UserProfileResponseDto;
}): UserFormTypes => {
  if (!userData) {
    return defaultValues;
  }
  return {
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    email: userData?.email || "",
    password: "",
    locations: userData.roleAssignment?.sites?.map(site => site.id),
    role: userData.roleAssignment?.role?.id,
    permissions:
      userData.roleAssignment?.permissions?.map(permission => permission.id) ||
      [],
  };
};
