// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { GetModifiersRequest, GetModifiersResponse } from "types/modifiers";
// Helpers
import { modifiersUrlBuilder } from "./urlBuilder/modifiers";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";

export const modifiersAPI = createApi({
  reducerPath: "modifiersAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["modifiers"],
  endpoints: build => ({
    getModifiers: build.query<GetModifiersResponse, GetModifiersRequest>({
      query: ({ companyId, siteId, query }) => ({
        url: modifiersUrlBuilder.getModifiers({ companyId, siteId, query }),
        method: "GET",
      }),
      providesTags: ["modifiers"],
    }),
  }),
});

export const { useGetModifiersQuery } = modifiersAPI;
