import { SnoozingCategoryItemType, SnoozingCategoryType } from "types/menus";

export enum ESnoozeType {
  CATEGORY = "category",
  ITEM = "item",
  BULK_ITEMS = "bulk_items",
}

export type CheckedItems = {
  categoryId: string;
  items: { itemId: string; isSnoozed: boolean }[];
};

export type SnoozeCategory = {
  type: ESnoozeType.CATEGORY;
  category: SnoozingCategoryType;
};

export type SnoozeItem = {
  type: ESnoozeType.ITEM;
  item: SnoozingCategoryItemType;
};

export type SnoozeBulkItems = {
  type: ESnoozeType.BULK_ITEMS;
  items: CheckedItems[];
};

export type SnoozeTableItems = {
  selected: CheckedItems[];
  value: string | number;
  until: string;
};
