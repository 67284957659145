import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

const schema = object({
  name: string()
    .trim()
    .min(3, "Label name must be more than 3 characters")
    .max(50, "Label name must be less than 50 characters")
    .required("Label name is required"),
}).required();

export const resolver = yupResolver(schema);
