import { PlusIcon, RefreshIcon } from "icons";
import styled from "styled-components";
import { theme } from "theme";

export const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 24px;
`;

export const Refresh = styled(RefreshIcon)`
  path {
    fill: ${theme.color.text.tertiary};
  }
`;
export const Plus = styled(PlusIcon)`
  path {
    fill: ${theme.color.white};
  }
`;
