import dayjs from "dayjs";
import { MenuScheduleRequestData, MenusSchedule } from "types/menuScheduling";
import { ScheduleFormTypes } from "./types";
import { transformScheduleHoursTimeToLocalDate } from "helpers/dataHelpers";

export const createScheduleFormRequesData = (
  formData: ScheduleFormTypes,
): MenuScheduleRequestData => {
  const { menuIds, publishTimes, ...data } = formData;

  return {
    ...data,
    menuIds: [menuIds],

    publishTimes: publishTimes.map(({ days, time }) => ({
      days,
      time: dayjs(time).utc().format("HH:mm:ss"),
    })),
  };
};

export const createScheduleFormData = (
  data: MenusSchedule,
): ScheduleFormTypes => {
  const { timezone, menus, sites, channels, publishTimes } = data;

  return {
    timezone,
    menuIds: menus.map(menu => menu.id)[0],
    sitesIds: sites.map(site => site.id),
    channelIds: channels.map(channel => channel.id),
    publishTimes: publishTimes.map(publishTime => ({
      days: publishTime.days,
      time: transformScheduleHoursTimeToLocalDate(publishTime.time),
    })),
  };
};
