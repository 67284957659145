// Lib
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
// Styled

import { FC } from "react";
import { PageTitleContainer, SummaryGrid } from "./styled";
import { FlexContainer } from "styled/Box";
import { ArrowBackLink } from "components";
import { ADMIN_ROUTES } from "consts";
import { Typography } from "styled/Typography";
import { Chart, Filters, StatisticsSummary } from "../../../components";
import { useParams } from "react-router-dom";
import { AnalyticsChartKey } from "types/analytics";
import { mokedOrdersData } from "pages/Loop/Analytics/mockData";

export const LocationAnalyticsDetails: FC = () => {
  const { id } = useParams();

  const chartOrders = mokedOrdersData.filter(order => order.locationId === id);

  return (
    <>
      <PageTitleContainer>
        <FlexContainer $column $gap={16}>
          <ArrowBackLink
            title="Back to Locations Analytics"
            path={ADMIN_ROUTES.ANALYTICS_LOCATIONS.path}
          />
          <Typography.H1>Loc name</Typography.H1>
        </FlexContainer>

        <Filters skipLocation />
      </PageTitleContainer>

      <SummaryGrid>
        <StatisticsSummary title="Total Order" text={591} />

        <StatisticsSummary title="Total revenue" text={"KWD 4,865.800"} />

        <StatisticsSummary title="Average order value" text={"KWD 8.233"} />

        <StatisticsSummary
          title="Best selling product"
          text={"Short Ribs & Potatoes"}
        />
      </SummaryGrid>

      <Chart
        dataKey={AnalyticsChartKey.LocationsChart}
        data={mokedOrdersData}
        selectedLocation={id}
      />
    </>
  );
};
