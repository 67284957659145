export const NOTIFICATIONS = {
  SOMETHING_WENT_WRONG: "Something went wrong",
  LOCATION_ADDRESS_ADDED: "Location successfully added",
  LOCATION_ADDRESS_UPDATED: "Location successfully updated",
  CHANNEL_LINK_ADDED: "Channel link successfully added",
  CHANNEL_LINK_UPDATED: "Channel link successfully updated",
  OPENING_HOURS_UPDATED: "Opening hours successfully updated",
  DELETE_CATEGORY_MESSAGE: "This category will be deleted",
  APROVE_ACTION_MESSAGE: "Are you sure to continue this action?",
  DELETE_ITEMS_MESSAGE: "items will be deleted in this category",
  DELETE_DIFFERENT_ITEMS_MESSAGE: "items will be deleted",
  PRODUCT_ADDED: "Product added successfully",
  PRODUCT_UPDATED: "Product successfully updated",
  MODIFIER_ADDED: "Modifier added successfully",
  MODIFIER_UPDATED: "Modifier successfully updated",
  MENU_SAVED: "Menu successfully saved",
  MENU_PUBLISHED: "Menu successfully published",
  MENU_ITEMS_SNOOZED: "items snoozed",
  MENU_ITEMS_UNSNOOZED: "items activated",
  MENU_CATEGORY_SNOOZED: "Category snoozed",
  MENU_CATEGORY_UNSNOOZED: "Category activated",
  STOCK_UPDATED: "Stock updated",
  SCHEDULE_CREATED: "Schedule successfully added",
  SCHEDULE_UPDATED: "Schedule successfully updated",
  SCHEDULE_DELETED: "Schedule successfully deleted",
  TABLET_POFILE_CREATED: "Profile successfully added",
  TABLET_POFILE_UPDATED: "Profile successfully updated",
  TABLET_POFILE_DELETED: "Profile successfully deleted",
  ORDERS_SETTINGS_UPDATED: "Orders settings updated",
  NOTIFICATIONS_SETTINGS_UPDATED: "Notifications settings updated",
};
