import styled from "styled-components";
import { ContentBox, FlexContainer } from "styled/Box";
import { theme } from "theme";

export const SelectContainer = styled.div`
  min-width: 200px;
`;

export const PageStikyHeading = styled(FlexContainer)<{ $stiky: boolean }>`
  ${({ $stiky }) =>
    $stiky &&
    `
  position: sticky;
  top: 96px;
  background: rgb(245, 245, 245);
  z-index: 1;
  margin: 0 -5px;
  padding: 0 5px;

  @media (max-width: 1023px) {
    top: 80px;
  }
  `}
`;

export const PageHeadingContainer = styled(FlexContainer)`
  margin-top: -32px;
  padding-top: 32px;
  background: rgb(245, 245, 245);
  margin: -32px -5px 0;
  padding: 32px 5px 0;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  @media (max-width: ${theme.breakpoints.ml}) {
    grid-template-columns: 1fr;
  }
`;

export const NoContentBox = styled(ContentBox)`
  height: 100%;
`;

export const PageWrapper = styled(FlexContainer)`
  height: 100%;
`;

export const RoundedSnoozedNumber = styled.div`
  background-color: ${theme.color.red};
  color: ${theme.color.white};
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 50%;
  padding: 4px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

export const CategoriesBox = styled(ContentBox)`
  min-height: 100px;
`;
