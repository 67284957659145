import { yupResolver } from "@hookform/resolvers/yup";
import { array, object, string } from "yup";

const schema = object({
  firstName: string().trim().required("First name is required"),
  lastName: string().trim().required("First name is required"),
  email: string().email("E-mail is required").required("E-mail is required"),
  role: string().required("User role is required"),
  password: string()
    .trim()
    .min(6, "Password must be longer than or equal to 6 characters")
    .required("Password is required"),
  locations: array()
    .min(1, "Locations is required")
    .required("Locations is required")
    .nullable(),
  permissions: array()
    .min(1, "Permissions is required")
    .required("Permissions is required")
    .nullable(),
}).required();

const editSchema = object({
  firstName: string().trim().required("First name is required"),
  lastName: string().trim().required("First name is required"),
  email: string().email("E-mail is required").required("E-mail is required"),
  role: string().required("User role is required"),
  password: string().nullable(),
  locations: array()
    .min(1, "Locations is required")
    .required("Locations is required")
    .nullable(),
  permissions: array()
    .min(1, "Permissions is required")
    .required("Permissions is required")
    .nullable(),
}).required();

export const resolver = yupResolver(schema);

export const editResolver = yupResolver(editSchema);
