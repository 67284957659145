// Lib
import { FC, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// Hooks
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useUserRole } from "hooks";
// Actions
import { setIsUserSideBarOpen, userLogout } from "rtkQuery/slices";
// Selectors
import { getUserName } from "rtkQuery/selectors";
// Types
import { MenuProps } from "antd";
import { SidebarMenuItemType } from "types/common";
// Icons
import { AvatarIcon, LogoutIcon, SwitchIcon } from "icons";
// Components

// Styled
import { FlexContainer } from "styled/Box";
import {
  BurgerButton,
  HeaderContainer,
  UserTitle,
  Avatar,
  Menu,
  Drawer,
  SideBarButton,
  Logo,
} from "./styled";

type TMenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: TMenuItem[],
  type?: "group",
): TMenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as TMenuItem;
}

interface MobileHeaderProps {
  collapsed: boolean;
  sidebarMenuItems: SidebarMenuItemType[];
}

export const MobileHeader: FC<MobileHeaderProps> = ({
  collapsed,
  sidebarMenuItems,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userName = useAppSelector(getUserName);

  const { isKitchenUser } = useUserRole();

  const { pathname } = useLocation();

  const isAdminRoute = pathname.includes("loop");

  //scroll to top after change route
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setIsUserSideBarOpen(true));
  }, [pathname]);

  const handleLogOut = () => {
    dispatch(userLogout());
  };

  const handleCollapse = () => {
    dispatch(setIsUserSideBarOpen(!collapsed));
  };

  const items: TMenuItem[] = sidebarMenuItems.map(
    ({ Icon, label, path, type, list, key }) => {
      if (type === "link") {
        return getItem(<NavLink to={path}>{label}</NavLink>, key, <Icon />);
      }
      if (type === "list") {
        return getItem(
          label,
          key,
          <Icon />,
          list.map(({ label, path, key }) =>
            getItem(<NavLink to={path}>{label}</NavLink>, key),
          ),
        );
      }
    },
  );

  const onSwitchClick = () => {
    navigate(isAdminRoute ? "/orders" : "/loop/orders");
  };

  const selected = pathname.split("/");

  return (
    <HeaderContainer>
      <BurgerButton
        $collapsed={false}
        $color={"white"}
        onClick={handleCollapse}
      >
        <i />
        <i />
        <i />
      </BurgerButton>

      <Logo />

      <div style={{ width: 40 }}></div>

      <Drawer
        open={!collapsed}
        placement="left"
        closable={false}
        onClose={handleCollapse}
      >
        <div>
          <FlexContainer $fullwidth $justify="flex-end">
            <BurgerButton
              $collapsed={true}
              $color={"black"}
              onClick={handleCollapse}
            >
              <i />
              <i />
              <i />
            </BurgerButton>
          </FlexContainer>

          <FlexContainer $align="center" $margin="0 0 12px 16px">
            <Avatar size={42} icon={<AvatarIcon width="40" height="40" />} />

            <UserTitle>{userName || "user"}</UserTitle>
          </FlexContainer>

          {!isKitchenUser && (
            <SideBarButton onClick={onSwitchClick}>
              <SwitchIcon />

              <span>Switch to {isAdminRoute ? "Kitchen" : "Admin Panel"}</span>
            </SideBarButton>
          )}

          <Menu
            selectedKeys={selected}
            mode="inline"
            theme="light"
            inlineCollapsed={collapsed}
            items={items}
          />

          <SideBarButton onClick={handleLogOut}>
            <LogoutIcon />

            <span>Logout</span>
          </SideBarButton>
        </div>
      </Drawer>
    </HeaderContainer>
  );
};
