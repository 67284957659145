import { theme } from "theme";
import { Role, roleLabel } from "consts";
import { Avatar, Badge } from "components";
import { FlexContainer } from "styled/Box";
import { UserRoleAssignment } from "types/users";
import { dateTransform } from "helpers/dataHelpers";

const getBadgeBgColor = (role: string) => {
  switch (role) {
    case Role.AccountManager:
      return theme.color.blue;
    case Role.Admin:
      return theme.color.yellow;

    default:
      return undefined;
  }
};

const getBadgeTextColor = (role: string) => {
  switch (role) {
    case Role.AccountManager:
      return theme.color.white;
    case Role.Admin:
      return theme.color.white;

    default:
      return "";
  }
};

export const tableColumns = [
  {
    title: "Username",
    dataIndex: "fullName",
    key: "fullName",
    render: (name: string) => (
      <FlexContainer $gap={8}>
        <Avatar name={name} />
        <div>{name}</div>
      </FlexContainer>
    ),
  },
  {
    title: "Role",
    dataIndex: "roleAssignment",
    key: "roleAssignment",
    render: (data: UserRoleAssignment) =>
      data?.role?.id && (
        <Badge
          title={roleLabel[data?.role?.id]}
          bgColor={getBadgeBgColor(data?.role?.id)}
          textColor={getBadgeTextColor(data?.role?.id)}
        />
      ),
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date: string) => dateTransform({ date }),
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (date: string) => dateTransform({ date }),
  },
];
