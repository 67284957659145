import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const AnalyticIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM8 17C7.45 17 7 16.55 7 16V13C7 12.45 7.45 12 8 12C8.55 12 9 12.45 9 13V16C9 16.55 8.55 17 8 17ZM12 17C11.45 17 11 16.55 11 16V15C11 14.45 11.45 14 12 14C12.55 14 13 14.45 13 15V16C13 16.55 12.55 17 12 17ZM12 12C11.45 12 11 11.55 11 11C11 10.45 11.45 10 12 10C12.55 10 13 10.45 13 11C13 11.55 12.55 12 12 12ZM16 17C15.45 17 15 16.55 15 16V8C15 7.45 15.45 7 16 7C16.55 7 17 7.45 17 8V16C17 16.55 16.55 17 16 17Z"
      fill="#646965"
    />
  </svg>
);
