import styled from "styled-components";
import { theme } from "theme";

const ImageStyles = `
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 4px;
  border: 1px solid ${theme.color.borderLight};
  background-color: ${theme.color.white};
`;

export const TagImage = styled.img`
  ${ImageStyles}
  display: block;
  object-fit: cover;
`;

export const MockedImage = styled.div`
  ${ImageStyles}
  display: flex;
  align-items: center;
  justify-content: center;
`;
