// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  CreateMenuRequest,
  CreateUpdateMenuResponse,
  DeleteMenusRequest,
  GetKitchenMenuResponse,
  GetMenuRequest,
  GetMenuResponse,
  GetMenusRequest,
  GetMenusResponse,
  PublishMenuRequest,
  PublishMenuResponse,
  UpdateMenuRequest,
} from "types/menus";
// Helpers
import { menusUrlBuilder } from "./urlBuilder/menus";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";

export const menusAPI = createApi({
  reducerPath: "menusAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["menus"],
  endpoints: build => ({
    getMenus: build.query<GetMenusResponse, GetMenusRequest>({
      query: ({ companyId, query }) => ({
        url: menusUrlBuilder.getMenus({ companyId, query }),
        method: "GET",
      }),
      providesTags: ["menus"],
    }),
    getKitchenMenus: build.query<GetKitchenMenuResponse[], { id: string }>({
      query: ({ id }) => ({
        url: menusUrlBuilder.getKitchenMenus(id),
        method: "GET",
      }),
      providesTags: ["menus"],
    }),
    deleteMenus: build.mutation<void, DeleteMenusRequest>({
      query: ({ data }) => ({
        url: menusUrlBuilder.deleteMenus(),
        method: "DELETE",
        data,
      }),
      invalidatesTags: ["menus"],
    }),
    getMenu: build.query<GetMenuResponse, GetMenuRequest>({
      query: ({ menuId }) => ({
        url: menusUrlBuilder.getMenu(menuId),
        method: "GET",
      }),
      providesTags: ["menus"],
    }),
    updateMenu: build.mutation<CreateUpdateMenuResponse, UpdateMenuRequest>({
      query: ({ menuId, data }) => ({
        url: menusUrlBuilder.updateMenu(menuId),
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["menus"],
    }),
    createMenu: build.mutation<CreateUpdateMenuResponse, CreateMenuRequest>({
      query: ({ companyId, data }) => ({
        url: menusUrlBuilder.createMenu(companyId),
        method: "POST",
        data,
      }),
      invalidatesTags: ["menus"],
    }),
    publishMenu: build.mutation<PublishMenuResponse, PublishMenuRequest>({
      query: ({ id, data }) => ({
        url: menusUrlBuilder.publishMenu(id),
        method: "POST",
        data,
      }),
      invalidatesTags: ["menus"],
    }),
  }),
});

export const {
  useGetMenusQuery,
  useGetKitchenMenusQuery,
  useLazyGetMenusQuery,
  useLazyGetMenuQuery,
  useDeleteMenusMutation,
  useUpdateMenuMutation,
  useCreateMenuMutation,
  usePublishMenuMutation,
} = menusAPI;
