// Lib
import { FC } from "react";
import { Upload as AntDUpload } from "antd";
// Api
import { useUploadMediaDataMutation } from "rtkQuery/query/uploadMediaAPI";
// Hooks
import { useNotification } from "hooks";
// Theme
import { theme } from "theme";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { FileUploadIcon, TrashIcon } from "icons";
// Components
import { Loader } from "components/Loader";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import {
  ActionsContainer,
  Copy,
  IconContainer,
  StyledImage,
  Wrapper,
} from "./styles";

const { Dragger } = AntDUpload;

interface ImageUploadProps {
  disabled?: boolean;
  value?: string | null;
  onChange?: (args: string) => void;
}

export const ImageUpload: FC<ImageUploadProps> = ({
  disabled,
  value,
  onChange,
}) => {
  const { openNotification } = useNotification();

  const [saveImage, { isLoading }] = useUploadMediaDataMutation();

  const handleBeforeUpload = async (file: Blob) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await saveImage({ data: formData }).unwrap();

      onChange(response.url);
    } catch (error) {
      errorHandler({ error, openNotification });
    }

    return false;
    // It is important to return false so that the download is not sent to the server
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}

      {value ? (
        <StyledImage src={value} />
      ) : (
        <Dragger
          accept="image/*"
          multiple={false}
          disabled={disabled}
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
        >
          <FlexContainer $column $align="center" $justify="center" $gap={16}>
            <IconContainer $align="center" $justify="center">
              <FileUploadIcon fill={theme.color.text.tertiary} />
            </IconContainer>

            <Copy>Click here to upload, or drag and drop</Copy>
          </FlexContainer>
        </Dragger>
      )}

      {!!value && (
        <ActionsContainer>
          <AntDUpload
            accept="image/*"
            multiple={false}
            showUploadList={false}
            beforeUpload={handleBeforeUpload}
          >
            {!disabled && (
              <Button.SquaredIcon
                icon={<FileUploadIcon fill={theme.color.text.tertiary} />}
                disabled={disabled}
              />
            )}
          </AntDUpload>

          {!disabled && (
            <Button.SquaredIcon
              icon={<TrashIcon fill={theme.color.status.danger} />}
              disabled={disabled}
              onClick={() => onChange(null)}
            />
          )}
        </ActionsContainer>
      )}
    </Wrapper>
  );
};
