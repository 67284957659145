import styled from "styled-components";
import { Typography } from "styled/Typography";
import { theme } from "theme";

export const BulletContainer = styled.div`
  display: flex;
  position: relative;
`;

export const BulletDivider = styled.div`
  width: 2px;
  height: 24px;
  background-color: #ececec;
  position: absolute;
  left: 9px;
  top: 20px;
`;

export const StatusTitle = styled(Typography.Title)`
  margin-left: 12px;
  text-transform: capitalize;
`;

export const StatusTime = styled(Typography.Description)`
  color: ${theme.color.text.tertiary};
  padding-left: 32px;
`;
