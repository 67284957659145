import { UrlQueryParams } from "./common";
import { PublishOutOfStock, PublishResponseStatus } from "./menus";

export enum ScheduleDayLabels {
  monday = "Mon",
  tuesday = "Tue",
  wednesday = "Wed",
  thursday = "Thu",
  friday = "Fri",
  saturday = "Sat",
  sunday = "Sun",
}

export enum ScheduleDay {
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday",
}

export enum MenuSchedulingFilterTypes {
  sites = "sitesIds",
  channels = "channelsIds",
  menus = "menuIds",
}

export enum MenuSchedulingNames {
  sitesIds = "Location",
  channelsIds = "Channels",
  menuIds = "Menus",
}

export type MenusSchedule = {
  id: string;
  timezone: string;
  startTime: string;
  publishTimes: {
    createdAt: string;
    createdBy: string;
    days: ScheduleDay[];
    deletedAt: string | null;
    deletedBy: string | null;
    id: string;
    time: string;
    updatedAt: string;
    updatedBy: string;
  }[];
  endTime: string;
  sites: {
    id: string;
    name: string;
    status: string;
    isBusy: boolean;
  }[];
  channels: {
    id: string;
    name: string;
    status: string;
    siteId: string;
    simphonyRevenuCenterId: string;
    menuUpdateURL: string;
    snoozeUnsnoozeURL: string;
    busyModeURL: string;
    statusUpdateURL: string;
    courierUpdateURL: string;
  }[];
  menus: [
    {
      id: string;
      name: string;
      internalName: string;
      internalType: string;
      companyId: string;
      siteId: string;
      active: boolean;
    },
  ];
};

export interface GetMenusScheduleListRequest {
  companyId: string;
  query: UrlQueryParams;
}

export type DaySchedules = {
  scheduleId: string;
  menu: {
    id: string;
    name: string;
  }[];
  sites: {
    id: string;
    name: string;
  }[];
  startTime: string;
  endTime: string;
};

export type MenusScheduleCalendarEvent = {
  day: ScheduleDay;
  schedules: DaySchedules[];
};

export interface GetMenusScheduleCalendarRequest {
  companyId: string;
  query: UrlQueryParams;
}

export type MenuPublishTimes = {
  days: ScheduleDay[];
  time: string;
};

export type MenuScheduleRequestData = {
  timezone: string;
  sitesIds: string[];
  channelIds: string[];
  menuIds: string[];
  publishTimes: MenuPublishTimes[];
};

export type CreateMenuScheduleRequest = MenuScheduleRequestData & {
  companyId: string;
  outOfStock: PublishOutOfStock;
};

export type UpdateMenuScheduleRequest = MenuScheduleRequestData & {
  id: string;
};

export type CreateMenuScheduleResponse = {
  outOfStockItems?: {
    id: string;
    categoryId: string;
    stockQuantity: number;
    product: {
      id: string;
      name: string;
    };
  }[];
  status: PublishResponseStatus;
  message: string;
};
