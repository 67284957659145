// Lib
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { jwtDecode } from "jwt-decode";
// Api
import { useLazyUserAuthQuery } from "rtkQuery/query/authAPI";
// Hooks
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useNotification } from "hooks";
// Actions
import { setUser, userLogin } from "rtkQuery/slices";
// Selectors
import { isAuth } from "rtkQuery/selectors";
// Constants
import {
  ADMIN_HOME_ROUTE,
  ADMIN_ROUTE_PATH,
  KITCHEN_PWA_HOME_ROUTE,
  Role,
} from "consts";
// Helpers
import { resolver } from "./validation";
// Utils
import { token } from "utils/handleToken";
import { errorHandler } from "utils/errorHandler";
// Components
import { Checkbox, Input, PasswordInput } from "components/Form";
// Styles
import { Button } from "styled/Buttons";
import {
  Form,
  Heading,
  Title,
  TitlesContainer,
  InputsContainer,
} from "./styled";
import { userState } from "types/user";

interface LocationState {
  from?: {
    pathname?: string;
  };
}

export interface LoginFormTypes {
  email: string;
  password: string;
  rememberMe?: boolean;
}

export const LoginForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { openNotification } = useNotification();

  const isAuthorized = useAppSelector(isAuth);

  //react-router-dom don`t have this interface
  const from =
    (location.state as LocationState)?.from?.pathname || ADMIN_HOME_ROUTE;

  useEffect(() => {
    if (!isAuthorized) return;

    const access = token.access.get();

    if (!access) return;

    navigate(from, { replace: true });
  }, []);

  const [auth, { isLoading }] = useLazyUserAuthQuery();

  const { handleSubmit, control, setError } = useForm<LoginFormTypes>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver,
  });

  const onSubmit: SubmitHandler<LoginFormTypes> = async ({
    email,
    password,
    rememberMe,
  }) => {
    try {
      const response = await auth({ email, password }).unwrap();

      token.access.set(
        response.accessToken,
        rememberMe ? "localStorage" : "sessionStorage",
      );
      token.refresh.set(
        response.refreshToken,
        rememberMe ? "localStorage" : "sessionStorage",
      );

      const decoded: userState = jwtDecode(response.accessToken);

      dispatch(setUser(decoded));
      dispatch(userLogin());

      const getNavigatePath = () => {
        const isAdminPanelUser =
          decoded.roleId === Role.Admin ||
          decoded.roleId === Role.AccountManager;
        const isAdminPanelRoute = from.includes(ADMIN_ROUTE_PATH);

        if (isAdminPanelUser && isAdminPanelRoute) return from;
        if (!isAdminPanelUser && !isAdminPanelRoute) return from;
        if (isAdminPanelUser && !isAdminPanelRoute) return ADMIN_HOME_ROUTE;
        if (!isAdminPanelUser && isAdminPanelRoute)
          return KITCHEN_PWA_HOME_ROUTE;
      };

      navigate(getNavigatePath(), { replace: true });
    } catch (error) {
      errorHandler({ error, setError, openNotification });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TitlesContainer>
        <Heading>Login</Heading>

        <Title>Please login to your account</Title>
      </TitlesContainer>

      <InputsContainer>
        <Controller
          name={"email"}
          control={control}
          render={({ field, fieldState }) => (
            <Input
              label="Username or email"
              disabled={isLoading}
              {...field}
              fieldState={fieldState}
            />
          )}
        />

        <Controller
          name={"password"}
          control={control}
          render={({ field, fieldState }) => (
            <PasswordInput
              label="Password"
              disabled={isLoading}
              {...field}
              fieldState={fieldState}
            />
          )}
        />
      </InputsContainer>

      <Controller
        name={"rememberMe"}
        control={control}
        render={({ field }) => (
          <Checkbox
            label="Keep me logged in"
            {...field}
            disabled={isLoading}
            checked={field.value}
            onChange={e => field.onChange(e.target.checked)}
          />
        )}
      />

      <Button.Login type="primary" htmlType="submit" loading={isLoading}>
        Log in
      </Button.Login>
    </Form>
  );
};
