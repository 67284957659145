import { OperationReportDetailsData } from "types/operationReport";

export const getAddress = (data: OperationReportDetailsData): string => {
  return [data?.street, data?.number, data?.city, data?.postcode, data?.country]
    .filter(Boolean)
    .join(", ");
};

export const getContacts = (data: OperationReportDetailsData): string => {
  return [data?.email, `${data?.phoneCode || ""}${data?.phoneNumber || ""}`]
    .filter(Boolean)
    .join(", ");
};
