import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const PauseCircleIcon: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group">
      <g id="Group_2">
        <path
          id="Vector"
          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 14C7.45 14 7 13.55 7 13V7C7 6.45 7.45 6 8 6C8.55 6 9 6.45 9 7V13C9 13.55 8.55 14 8 14ZM12 14C11.45 14 11 13.55 11 13V7C11 6.45 11.45 6 12 6C12.55 6 13 6.45 13 7V13C13 13.55 12.55 14 12 14Z"
          fill={fill || "#646965"}
        />
      </g>
    </g>
  </svg>
);
