import styled from "styled-components";
import { theme } from "theme";
import { ArrowDownIcon } from "icons";
import { ContentBox } from "styled/Box";
import { Typography } from "styled/Typography";

export const CellTitle = styled(Typography.Title)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgb(100, 105, 101);
`;

export const HeaderWrapper = styled.div`
  min-width: 650px;
`;

export const CalendarWrapper = styled(ContentBox)`
  display: block;
  padding: 0;
  overflow: auto;

  .fc {
    min-width: 650px;

    table {
      border-left: 0;
      border-top-width: 0px;
    }

    thead {
      background: rgb(247, 250, 248);
      color: rgb(100, 105, 101);

      th {
        height: 40px;
        vertical-align: middle;
        border-left: 0;

        a {
          cursor: auto;
        }
      }

      th:not(.fc-timegrid-axis) {
        border-right: 0;
      }

      th:first-child {
        padding-left: 0;
      }

      th:last-child {
        padding-right: 0;
      }
    }

    tbody {
      td {
        height: 40px;
        vertical-align: middle;
        border-left: 0;
      }

      td:not(.fc-timegrid-slot-label) {
        border-right: 0;
      }

      .fc-timegrid-slot-label {
        width: 40px;
      }
    }

    .fc-view-harness {
      height: 1003px !important;
    }

    .fc-scroller {
      overflow: hidden auto !important;
    }

    .fc-v-event {
      background-color: transparent;
    }

    .fc-event {
      box-shadow: ${theme.shadow.table};
      border: 0;
      border-color: transparent;
      border-radius: ${theme.borderRadius.base};
      overflow: hidden;
      background-color: white;
    }
    .fc-event-main {
      padding: 0;
      border-radius: ${theme.borderRadius.base};
    }
  }
`;

export const PrevIcon = styled(ArrowDownIcon)`
  rotate: 90deg;
`;
export const NextIcon = styled(ArrowDownIcon)`
  rotate: -90deg;
`;
