// Lib
import { FC } from "react";
// Api
// Hooks
// Types
// Constants
import { MONTH_DAY_YEAR_DATE } from "consts";
// Helpers
import { dateTransform } from "helpers/dataHelpers";
// Utils
// Icons
// Components
import { SummaryField } from "components";
import { History } from "../History";
// Styled
import { ContentBox, SummaryItemsGrid } from "styled/Box";
import { Typography } from "styled/Typography";
import { Wrapper, MainInfoWrapper } from "../styled";

interface SummaryTabProps {
  isCustomerDataLoading: boolean;
  customerData: any;
}

export const SummaryTab: FC<SummaryTabProps> = ({
  isCustomerDataLoading,
  customerData,
}) => {
  return (
    <>
      <Wrapper>
        <MainInfoWrapper $column $gap={16}>
          <ContentBox $column $gap={20}>
            <Typography.H2>Customer Informations</Typography.H2>

            <SummaryItemsGrid>
              <SummaryField
                isLoading={isCustomerDataLoading}
                label="First name"
                value={customerData?.firstName}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Last name"
                value={customerData?.lastName}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Gender"
                value={customerData?.gender}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Birth date"
                value={dateTransform({
                  date: customerData?.birthDate,
                  format: MONTH_DAY_YEAR_DATE,
                })}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Phone number"
                value={customerData?.phoneNumber}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="E-mail"
                value={customerData?.email}
              />
            </SummaryItemsGrid>
          </ContentBox>
        </MainInfoWrapper>

        <History
          isCustomerDataLoading={isCustomerDataLoading}
          customerData={customerData}
        />
      </Wrapper>
    </>
  );
};
