import styled from "styled-components";

export const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px;

  @media (max-width: 1120px) {
    height: 136px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const SummaryGrid = styled.div`
  display: grid;
  gap: 16px;
  margin-bottom: 16px;

  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 715px) {
    grid-template-columns: 1fr;
  }
`;
