import styled from "styled-components";
import { Table as AntTable } from "antd";
import { theme } from "theme";

export const AntTableStyled = styled(AntTable)<{
  $accentColumn?: number;
  $accentColumnWidth?: string;
  $withPagination?: boolean;
  $ordersCalendarTable?: boolean;
  $wrap: boolean;
  $clicable?: boolean;
  $grayRowsIds?: string[];
}>`
  border-bottom-left-radius: ${props => (props?.$withPagination ? 0 : "8px")};
  border-bottom-right-radius: ${props => (props?.$withPagination ? 0 : "8px")};
  overflow: auto;
  background-color: white;
  ${({ $ordersCalendarTable }) => $ordersCalendarTable && `padding: 16px;`};

  .ant-table-thead {
    & .ant-table-cell {
      background: ${theme.color.lightGreen};
      color: ${theme.color.text.tertiary};
      font-size: ${theme.fontSize.base};
      font-weight: ${theme.fontWeight.medium};
      line-height: ${theme.lineHeight.base};

      ${({ $ordersCalendarTable }) =>
        $ordersCalendarTable && `background: ${theme.color.white}`};
    }
  }

  .ant-table-placeholder {
    color: black !important;
  }

  .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0px;
  }

  ${({ $accentColumn, $accentColumnWidth }) =>
    $accentColumn &&
    `
  & .ant-table-container table > thead > tr:first-child > *:nth-child(${$accentColumn}) {
    width: ${$accentColumnWidth || "100%"};
  }`}

  .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 0px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    width: 0px;
  }

  .ant-table-thead
    > tr
    > td:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    width: 0px;
  }
  .ant-spin-nested-loading {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .ant-spin-nested-loading .ant-spin-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .ant-table-wrapper .ant-table {
    border-radius: 8px;
  }
  .ant-table-wrapper .ant-table-container {
    border-radius: 8px;
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-tbody > tr > th,
  .ant-table-wrapper .ant-table-tbody > tr > td,
  .ant-table-wrapper tfoot > tr > th,
  .ant-table-wrapper tfoot > tr > td {
    padding: 10px 16px;
  }

  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > th {
    padding: 8px 12px;

    ${({ $ordersCalendarTable }) =>
      $ordersCalendarTable && `padding: 10px; border: 0; `}
  }

  & .ant-table-thead > tr > td {
    ${({ $ordersCalendarTable }) =>
      $ordersCalendarTable && `padding: 10px; border: 0; `}
  }

  & .ant-table-tbody > tr > td {
    padding: 8px 12px;
    vertical-align: middle;
    white-space: ${({ $wrap }) => !$wrap && "nowrap"};
    ${({ $clicable }) => $clicable && `cursor: pointer`};

    ${({ $ordersCalendarTable }) =>
      $ordersCalendarTable && `padding: 2px; border: 0;`}
  }

  ${({ $accentColumn }) =>
    $accentColumn &&
    `
  & .ant-table-tbody > tr > *:nth-child(${$accentColumn}) {
    font-weight: ${theme.fontWeight.medium};
  }`}

  .ant-table-tbody {
    & .ant-table-row {
      & .ant-table-cell {
        color: ${theme.color.text.primary2};
        font-size: ${theme.fontSize.md};
        line-height: ${theme.lineHeight.md};
        background: ${theme.color.white};
      }
    }

    ${({ $grayRowsIds }) =>
      $grayRowsIds?.length &&
      $grayRowsIds.map(
        rowId => `

    & .ant-table-row[data-row-key="${rowId}"] {
      & .ant-table-cell {
        background: ${theme.color.background.gray};
      }
    }
      `,
      )}
  }

  .ant-checkbox-wrapper {
    padding: 0px 4px;
  }

  &.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover,
  &.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td,
  &.ant-table-wrapper
    .ant-table-tbody
    > tr.ant-table-row-selected
    > td.ant-table-cell-row-hover {
    background-color: ${({ $clicable }) => ($clicable ? "#e9ede9" : "white")};

    ${({ $ordersCalendarTable }) =>
      $ordersCalendarTable && `background-color: white;`}
  }

  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: ${({ $clicable }) =>
      $clicable ? theme.color.lightGreen : "white"};

    ${({ $ordersCalendarTable }) =>
      $ordersCalendarTable && `background-color: white;`}
  }
`;

export const AccentText = styled.div`
  font-weight: ${theme.fontWeight.medium};
`;

export const TableShadowContainer = styled.div<{
  $withPagination?: boolean;
  $shadow: boolean;
}>`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;

  box-shadow: ${({ $shadow }) => $shadow && theme.shadow.table};

  border-bottom-left-radius: ${({ $withPagination }) =>
    $withPagination ? 0 : "8px"};

  border-bottom-right-radius: ${({ $withPagination }) =>
    $withPagination ? 0 : "8px"};
`;

const tableImageStyles = `
  height: 40px;
  margin-top: -4px;
  margin-bottom: -4px;
  width: 40px;
  border-radius: 4px;
  border: 1px solid ${theme.color.borderLight};
  background-color: ${theme.color.white};
`;

export const TableImage = styled.img`
  ${tableImageStyles}
  display: block;
  object-fit: cover;
`;

export const MockedImage = styled.div`
  ${tableImageStyles}
  display: flex;
  align-items: center;
  justify-content: center;
`;
