// Lib
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Api
import {
  useGetLocationAddressQuery,
  useUpdateLocationAddressMutation,
} from "rtkQuery/query/locationsAPI";
// Actions
import { setUser } from "rtkQuery/slices";
// Hooks
import { useAppDispatch } from "hooks/redux";
import { useNotification, usePermissions } from "hooks";
// Types
import { userState } from "types/user";
import { LocationAddressFormSubmitData } from "types/locations";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS } from "consts";
// Utils
import { refreshAccessToken } from "utils/refreshAccessToken";
import { errorHandler } from "utils/errorHandler";
// Components
import { ArrowBackLink, Loader } from "components";
import { EditLocationForm } from "../components";
// Styled
import { Typography } from "styled/Typography";
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";

export const EditLocation: FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { openNotification } = useNotification();

  const {
    data,
    isFetching: isGetAddressLoading,
    isError,
    error,
  } = useGetLocationAddressQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isError) {
      errorHandler({ error, openNotification });
    }
  }, [isError, error]);

  const [updateLocation, { isLoading: isUpdateAddressLoading }] =
    useUpdateLocationAddressMutation();

  const handleSendRequest = async (data: LocationAddressFormSubmitData) => {
    try {
      await updateLocation({ id, data }).unwrap();

      await refreshAccessToken();

      openNotification({ message: NOTIFICATIONS.LOCATION_ADDRESS_UPDATED });

      navigate(-1);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const isLoading = isUpdateAddressLoading || isGetAddressLoading;

  return (
    <>
      {isLoading && <Loader />}

      <FlexContainer $column $gap={24}>
        <PageHeadingContainer>
          <PageTitleContainer $column $gap={16}>
            <ArrowBackLink
              title="Back to Location"
              path={ADMIN_ROUTES.LOCATIONS.path}
            />

            <Typography.H1>Edit Location</Typography.H1>
          </PageTitleContainer>
        </PageHeadingContainer>

        {data && (
          <EditLocationForm
            addressData={{
              isHomeSite: data?.isHomeSite || false,
              name: data?.name || null,
              country: data?.address?.country || null,
              street: data?.address?.street || null,
              number: data?.address?.number || null,
              city: data?.address?.city || null,
              postcode: data?.address?.postcode || null,
              phoneCode: data?.address?.phoneCode || null,
              phoneNumber: data?.address?.phoneNumber || null,
              email: data?.address?.email || null,
              simphonyNodeId: data?.simphonyNodeId || null,
            }}
            handleSendRequest={handleSendRequest}
          />
        )}
      </FlexContainer>
    </>
  );
};
