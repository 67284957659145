// Lib
import React, { FC, memo, useEffect, useMemo, useState } from "react";
// Api
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
// Actions
// Selectors
import { getAnalyticsFilters, getUserCompanyId } from "rtkQuery/selectors";
// Types
import { AnalyticsStatsSummaryKey, ChartData } from "types/analytics";
import { ChartProps } from "./types";
// Theme
// Constants
// Helpers
import { createButtons, createChartData, getTooltipTitle } from "./helpers";
// Utils
// Icons
// Layouts
// Components
import { ChartButton, LineChart } from "./components";
// Styled
import { Wrapper, ButtonsWrapper, Devider } from "./styled";

import { mockedLocationsData } from "../../mockData";

export const Chart: FC<ChartProps> = memo(props => {
  const companyId = useAppSelector(getUserCompanyId);
  const analyticsFilters = useAppSelector(getAnalyticsFilters);

  const [selected, setSelected] = useState<AnalyticsStatsSummaryKey | null>(
    null,
  );

  const { data } = useGetLocationsQuery({ companyId });

  // TODO: change to locations from response
  // const locations =
  //   data?.map(({ name, id }) => ({ label: name, value: id })) || [];

  const locations =
    mockedLocationsData?.map(({ name, id }) => ({ label: name, value: id })) ||
    [];

  const { tabs, chartLinesData } = useMemo(
    () => createChartData(props, analyticsFilters, locations),
    [props, analyticsFilters],
  );

  useEffect(() => {
    if (tabs) {
      setSelected(tabs[0]?.dataKey || null);
    }
  }, []);

  const statsButtons = createButtons(tabs);

  const chartData: ChartData[] = chartLinesData.slotsData.map(data => {
    const values = data[selected];

    const isPrevTooltipTitles =
      data?.prevSlotStartTime !== undefined &&
      data?.prevSlotEndTime !== undefined;

    const tooltipTitles = isPrevTooltipTitles
      ? {
          current: getTooltipTitle(
            [data.slotStartTime, data.slotEndTime],
            data.groupBy,
          ),
          prev: getTooltipTitle(
            [data.prevSlotStartTime, data.prevSlotEndTime],
            data.groupBy,
          ),
        }
      : locations.reduce((acc, item) => {
          acc[item.value] = `${item.label || item.value}`;

          return acc;
        }, {} as Record<string, string | number>);

    return {
      name: data.name,
      ...values,
      groupBy: data.groupBy,
      tooltipTitles,
    };
  });

  console.log(chartData);

  return (
    <Wrapper>
      <ButtonsWrapper>
        {statsButtons?.map((button, i, array) => (
          <React.Fragment key={button.statsKey}>
            <ChartButton
              statsKey={button.statsKey}
              title={button.title}
              profitDisplay={button.profitDisplay}
              statsValue={button.statsValue}
              statsPrevValue={button.statsPrevValue}
              statsValuePrefix={button.statsValuePrefix}
              isSelected={button.statsKey === selected}
              isInvertedProfit={button.isInvertedProfit}
              onClick={setSelected}
            />
            {i !== array?.length - 1 && <Devider className="devider" />}
          </React.Fragment>
        ))}
      </ButtonsWrapper>

      <LineChart
        legendConfig={chartLinesData.legendConfig}
        linesConfig={chartLinesData.linesConfig}
        chartData={chartData}
      />
    </Wrapper>
  );
});

Chart.displayName = "Chart";
