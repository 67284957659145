// Lib
import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Hooks
import { useViewport } from "hooks";
// Types
import { MenuCategoryType, EditCategoryFormTypes } from "types/menus";
// Helpers
import { createDefaultValues } from "./helpers";
import { resolver } from "./validation";
// Components
import { Modal } from "components";
import { ImageUpload, Input, TextArea } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { UploadContainer } from "./styled";

interface CategoryFormProps {
  open: boolean;
  categoryData?: MenuCategoryType | boolean;
  handleAddCategory: (data: EditCategoryFormTypes) => void;
  handleEditCategoryData: (id: string, data: EditCategoryFormTypes) => void;
  handleClose: () => void;
}

export const CategoryForm: FC<CategoryFormProps> = ({
  open,
  categoryData,
  handleAddCategory,
  handleEditCategoryData,
  handleClose,
}) => {
  const { isMobile } = useViewport();

  const defaultValues = createDefaultValues(categoryData);

  const { handleSubmit, control, watch, setValue, reset } =
    useForm<EditCategoryFormTypes>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolver,
    });

  useEffect(() => {
    if (!categoryData) {
      return;
    }

    if (defaultValues) {
      setValue("name", defaultValues.name);
      setValue("description", defaultValues.description);
      setValue("image", defaultValues.image || null);
    }
  }, [categoryData]);

  const imageValue = watch("image");
  const nameValue = watch("name");

  const onSubmit: SubmitHandler<EditCategoryFormTypes> = data => {
    if (defaultValues) {
      handleEditCategoryData(defaultValues.id, data);
    } else {
      handleAddCategory(data);
    }

    handleClose();
    reset();
  };

  const setImage = (image: string) => {
    setValue("image", image);
  };

  const handleCloseForm = () => {
    handleClose();
    reset();
  };

  return (
    <Modal
      title="Add category"
      width={480}
      open={!!open}
      onClose={handleCloseForm}
    >
      <FlexContainer
        as="form"
        $column
        $gap={16}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name={"name"}
          control={control}
          render={({ field, fieldState }) => (
            <Input required label="Name" {...field} fieldState={fieldState} />
          )}
        />

        <Controller
          name={"description"}
          control={control}
          render={({ field, fieldState }) => (
            <TextArea label="Descriptions" {...field} fieldState={fieldState} />
          )}
        />

        <UploadContainer>
          <ImageUpload value={imageValue} onChange={setImage} />
        </UploadContainer>

        <FlexContainer
          $column={isMobile}
          $align="center"
          $justify="flex-end"
          $gap={8}
        >
          <Button.Heading $fullWidth={isMobile} onClick={handleCloseForm}>
            Cancel
          </Button.Heading>

          <Button.Heading
            $fullWidth={isMobile}
            type="primary"
            htmlType="submit"
            disabled={!nameValue}
          >
            Save
          </Button.Heading>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
