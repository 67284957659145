import { Route } from "consts";
import { SidebarMenuItemType } from "types/common";

interface CheckUserAdminPermissions {
  permissions: string[];
  routes: Record<string, Route>;
}

interface CheckUserAdminMenuPermissions {
  permissions: string[];
  menuItems: SidebarMenuItemType[];
}

export const checkAdminRoutesPermissions = ({
  permissions,
  routes,
}: CheckUserAdminPermissions): Record<string, Route> => {
  const filteredRoutes = {};

  for (const routeKey in routes) {
    const route = routes[routeKey];

    if (typeof route.access === "boolean" && route.access === true) {
      filteredRoutes[routeKey] = route;
    } else if (
      Array.isArray(route.access) &&
      route.access.some(permission => permissions.includes(permission))
    ) {
      filteredRoutes[routeKey] = route;
    }
  }

  return filteredRoutes;
};

export const checkAdminMenuPermissions = ({
  menuItems,
  permissions,
}: CheckUserAdminMenuPermissions) => {
  const filteredMenuItems = menuItems
    .map(item => {
      if (
        item.access === true ||
        (Array.isArray(item.access) &&
          item.access.some(permission => permissions.includes(permission)))
      ) {
        return item;
      }

      if (item.access === false) {
        return null;
      }

      if (item?.list) {
        const list = item?.list.filter(
          el =>
            el?.access === true ||
            (Array.isArray(el?.access) &&
              el.access.some(permission => permissions.includes(permission))),
        );
        return { ...item, list };
      }
      return null;
    })
    .filter(Boolean);

  return filteredMenuItems;
};
