import { dateTransform } from "helpers/dataHelpers";
import { CancelIcon, CheckCircleIcon } from "icons";
import { FlexContainer } from "styled/Box";
import { theme } from "theme";

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "Active",
    dataIndex: "active",
    key: "active",
    render: (active: boolean) => (
      <FlexContainer $justify="center">
        {active ? <CheckCircleIcon /> : <CancelIcon fill={theme.color.error} />}
      </FlexContainer>
    ),
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    render: (date: string) => dateTransform({ date }),
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    sorter: true,
    render: (date: string) => dateTransform({ date }),
  },
];
