// Lib
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Api
import {
  useLazyGetInventoryQuery,
  useUpdateKitchenInventoryMutation,
} from "rtkQuery/query/inventoryAPI";
import { useGetKitchenMenusQuery } from "rtkQuery/query/menusAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useNotification, usePermissions, useTable, useViewport } from "hooks";
// Actions
// Selectors
import { getActiveKitchen } from "rtkQuery/selectors";
// Types
import { ETable } from "types/tableFilters";
import { InventoryItem, InventoryPropduct } from "types/inventory";
// Theme
// Constants
import { NOTIFICATIONS } from "consts";
// Helpers
import { changeTableData } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { DescriptionBlackIcon } from "icons";
// Layouts
// Components
import { Pagination, Status, Table } from "components";
import { Select } from "components/Form";
import { EditableCell } from "./components";
// Styled
import { FlexContainer, PageButtonsContainer, PageWrapper } from "styled/Box";
import { Typography } from "styled/Typography";
import { SelectContainer } from "./styled";

import { columns, items } from "./config";

export const KitchenInventory: FC = () => {
  const { isMobile, isDesktop } = useViewport();

  const navigate = useNavigate();

  const location = useLocation();

  const kitchen = useAppSelector(getActiveKitchen);

  const { openNotification } = useNotification();

  const { canUpdateInventory } = usePermissions();

  const {
    tab,
    orderBy,
    orderDirection,
    currentPage,
    pageSize,
    search,
    debouncedSearch,
    setCurrentPage,
    setPageSize,
    setSearch,
    handleSort,
    setTab,
  } = useTable({ name: ETable.KitchenInventory });

  const [menuIdValue, setMenuIdValue] = useState<string>(
    () => location?.state?.menuIdValue || "",
  );

  const [tableData, setTableData] = useState<InventoryItem[]>([]);

  const {
    data: menusListData,
    isLoading: isGetMenusLoading,
    error: menusError,
  } = useGetKitchenMenusQuery(
    {
      id: kitchen?.id,
    },
    {
      skip: !kitchen?.id,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (menusError) {
      errorHandler({ error: menusError, openNotification });
    }
  }, [menusError]);

  const [updateStock] = useUpdateKitchenInventoryMutation();

  const [
    getIventoryData,
    { isFetching: isInventoryLoading, data: inventoryData },
  ] = useLazyGetInventoryQuery();

  const fetchInventory = async () => {
    try {
      const data = await getIventoryData({
        menuId: menuIdValue,
        query: {
          orderBy,
          orderDirection,
          page: currentPage,
          size: pageSize,
          search: debouncedSearch,
          availability: tab,
        },
      }).unwrap();

      setTableData(data.data);
    } catch (error) {
      errorHandler({ error, openNotification });
      setTableData([]);
      setCurrentPage(1);
      setTab("");
    }
  };

  useEffect(() => {
    if (!menuIdValue || !kitchen?.id) {
      return;
    }

    fetchInventory();
  }, [
    menuIdValue,
    orderBy,
    orderDirection,
    currentPage,
    pageSize,
    debouncedSearch,
    tab,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [tab]);

  useEffect(() => {
    if (!inventoryData?.data) return;

    setTableData(inventoryData.data);
  }, [inventoryData]);

  const handleSetTab = (value: string) => {
    setTab(value);
  };

  const handleStockChange = async (record: InventoryItem, value: number) => {
    try {
      await updateStock({
        kitchenId: kitchen?.id,
        menuId: menuIdValue,
        menuItemId: record.id,
        data: { quantity: value },
      }).unwrap();

      setTableData(prev => changeTableData(prev, record?.id, value));

      openNotification({ message: NOTIFICATIONS.STOCK_UPDATED });
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const mappedColumns = columns.map(c => {
    if (c.key === "productName") {
      return {
        ...c,
        render: (product: InventoryPropduct, record: InventoryItem) => (
          <FlexContainer
            $gap={8}
            $align="center"
            $cursor="pointer"
            onClick={() =>
              navigate(`/inventory/${kitchen?.id}/${menuIdValue}/${record.id}`)
            }
          >
            <Typography.Title>{product.name}</Typography.Title>

            {record.stockQuantity < 1 && (
              <Status text="Out of stock" status="danger" />
            )}
          </FlexContainer>
        ),
      };
    }

    if (c.key === "stock") {
      return {
        ...c,
        render: (quantity: number, record: InventoryItem) =>
          canUpdateInventory ? (
            <EditableCell
              initialValue={quantity}
              onCellValueChange={value => handleStockChange(record, value)}
            />
          ) : (
            quantity
          ),
      };
    }

    return c;
  });

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    handleSort(sorter?.columnKey, sorter?.order);
  };

  const menus =
    menusListData?.length &&
    menusListData?.map(el => ({ label: el.name, value: el.id }));

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer
        $fullwidth
        $column
        $gap={20}
        $grow={1}
        $padding={isDesktop && "0 0 32px"}
      >
        <FlexContainer
          $fullwidth
          $gap={16}
          $column={isMobile}
          $align={isMobile ? "flex-start" : "center"}
          $justify="space-between"
        >
          <Typography.H1>Inventory</Typography.H1>

          <PageButtonsContainer>
            <SelectContainer>
              <Select
                label="Menu"
                loading={isGetMenusLoading}
                disabled={isGetMenusLoading}
                value={!isGetMenusLoading && menuIdValue}
                options={menus}
                onChange={value => setMenuIdValue(value)}
              />
            </SelectContainer>
          </PageButtonsContainer>
        </FlexContainer>

        <Table
          isLoading={isInventoryLoading}
          withPagination={!isDesktop}
          rowKey={record => record.id}
          header={{
            tabs: { tab, setTab: handleSetTab, items },
            search: { value: search, setValue: setSearch },
          }}
          empty={{
            icon: DescriptionBlackIcon,
            title: "No items to show.",
            description: "Try changing Menu, or change the filters",
          }}
          columns={mappedColumns}
          dataSource={tableData || []}
          onChange={handleTableChange}
        />
      </FlexContainer>

      <Pagination
        onPage
        padding="12px 16px"
        showSizeChanger={!isDesktop}
        pageSize={pageSize}
        currentPage={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        totalItems={inventoryData?.total}
      />
    </PageWrapper>
  );
};
