import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const productsUrlBuilder = {
  getProducts: ({
    locationId,
    companyId,
    query,
  }: {
    locationId: string;
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/products/sites/${locationId}`,
      query,
    );
  },
  getProduct: (id: string): string => {
    return `${endpoints.products}${id}/`;
  },

  createProduct: (companyId: string): string => {
    return `${endpoints.companies}${companyId}/products/`;
  },
  updateProduct: (id: string): string => {
    return `${endpoints.products}${id}/`;
  },
  deleteProducts: (): string => {
    return `${endpoints.products}`;
  },
  getProductHightlights: (): string => {
    return `${endpoints.products}tags/highlights`;
  },
  updateProductHightlight: (id: string): string => {
    return `${endpoints.products}tags/highlights/${id}/`;
  },
  deleteProductHightlight: (id: string): string => {
    return `${endpoints.products}tags/highlights/${id}`;
  },
  getProductAlergents: (): string => {
    return `${endpoints.products}tags/alergents`;
  },
  updateProductAlergent: (id: string): string => {
    return `${endpoints.products}tags/alergents/${id}/`;
  },
  deleteProductAlergent: (id: string): string => {
    return `${endpoints.products}tags/alergents/${id}`;
  },
  getProductsLabels: (): string => {
    return `${endpoints.products}labels/list`;
  },
  createProductLabel: (): string => {
    return `${endpoints.products}labels`;
  },
  updateProductLabel: (id: string): string => {
    return `${endpoints.products}labels/${id}/`;
  },
  deleteProductLabel: (id: string): string => {
    return `${endpoints.products}labels/${id}`;
  },
  syncProducts: (id: string): string => {
    return `sites/company/${id}/pullProducts`;
  },
  syncLocationProducts: (id: string): string => {
    return `${endpoints.sites}${id}/pullProducts`;
  },
};
