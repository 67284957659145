import { useCallback, useEffect, useMemo, useState } from "react";

import { useDebounce } from "hooks";

import { Order } from "types/common";
import { ETable, FilterStateItem } from "types/tableFilters";
import { useAppDispatch, useAppSelector } from "./redux";
import { getTableFilters } from "rtkQuery/selectors/tableFilters";
import { useSearchParams } from "react-router-dom";
import { getFiltersQuery } from "helpers/tableFiltersHelpers";
import { isMatch } from "helpers/dataHelpers";
import { setTableFilters } from "rtkQuery/slices";

interface Props {
  name: ETable;
  removeQueryParams?: boolean;
}

export const useTable = ({ name, removeQueryParams }: Props) => {
  const tableFilters = useAppSelector(getTableFilters);
  const dispatch = useAppDispatch();

  const [debouncedFiltersQuery, setDebouncedFiltersQuery] = useState<
    Record<string, string | string[]>
  >({});

  const [searchParams, setSearchParams] = useSearchParams();

  const getParams = <T>(name: string, initial: T): T | string =>
    searchParams.get(name) || initial;

  const [orderDirection, setOrderDirection] = useState<Order>(
    getParams("orderDirection", "") as Order,
  );

  const [orderBy, setOrderBy] = useState<string>(() =>
    getParams("orderBy", ""),
  );

  const [currentPage, setCurrentPage] = useState<number>(() =>
    Number(searchParams.get("page") || 1),
  );

  const [pageSize, setPageSize] = useState<number>(() =>
    Number(searchParams.get("size") || 10),
  );

  const [search, setSearch] = useState<string>(() => getParams("search", ""));
  const [searchRequest, setSearchRequest] = useState<string>("");

  const [tab, setTab] = useState<string>(() => getParams("tab", ""));

  const debouncedSearch = useDebounce<string>(search, 1000);

  useEffect(() => {
    setSearchRequest(debouncedSearch);
    setCurrentPage(1);
  }, [debouncedSearch]);

  const filtersQuery = useMemo(
    () => getFiltersQuery(tableFilters[name]),
    [tableFilters[name]],
  );

  const debouncedFiltersQueryValues = useDebounce<
    Record<string, string | string[]>
  >(filtersQuery, 1000);

  useEffect(() => {
    if (!debouncedFiltersQueryValues && !debouncedFiltersQuery) {
      return;
    }

    if (!isMatch(debouncedFiltersQuery, debouncedFiltersQueryValues)) {
      setCurrentPage(1);
      setDebouncedFiltersQuery(debouncedFiltersQueryValues || {});
    }
  }, [debouncedFiltersQueryValues]);

  const checkParams = (filtersParams: string) => {
    if (isMatch(filtersParams, tableFilters[name])) {
      return;
    }

    if (filtersParams) {
      dispatch(setTableFilters({ name, filters: JSON.parse(filtersParams) }));
    }
  };

  useEffect(() => {
    const filtersParams = searchParams.get("filters");

    if (!filtersParams) {
      return;
    }

    checkParams(filtersParams);
  }, []);

  useEffect(() => {
    if (removeQueryParams) {
      return;
    }

    setSearchParams(
      {
        ...(tab && { tab }),
        ...(currentPage && { page: currentPage.toString() }),
        ...(pageSize && { size: pageSize.toString() }),
        ...(orderDirection && { orderDirection }),
        ...(orderBy && { orderBy }),
        ...(search && { search: debouncedSearch }),
        ...(tableFilters[name]?.length && {
          filters: JSON.stringify(tableFilters[name]),
        }),
      },
      { replace: true },
    );
  }, [
    orderDirection,
    orderBy,
    currentPage,
    pageSize,
    tab,
    debouncedSearch,
    tableFilters[name],
  ]);

  const handleSort = useCallback(
    (field: string, direction: string) => {
      if (!direction) {
        setOrderBy("");
        setOrderDirection("");
        return;
      }

      const orderBy =
        direction === "ascend" ? "ASC" : direction === "descend" ? "DESC" : "";

      setOrderBy(field);
      setOrderDirection(orderBy);
      setCurrentPage(1);
    },
    [orderBy],
  );

  const handleSetPageSize = useCallback((limit: number) => {
    setCurrentPage(1);
    setPageSize(limit);
  }, []);

  const handleSetTableFilterValues = useCallback(
    (filters: FilterStateItem[]) => {
      dispatch(setTableFilters({ name, filters }));
    },
    [],
  );

  const handleReset = useCallback(() => {
    setSearch("");
    setSearchRequest("");
    setPageSize(10);
    setCurrentPage(1);
    setOrderBy("");
    setOrderDirection("");
  }, []);

  return {
    tab,
    orderDirection,
    orderBy,
    currentPage,
    pageSize,
    search,
    debouncedSearch: searchRequest,
    selectedFilters: tableFilters[name] || [],
    debouncedFiltersQuery,
    setTab,
    setOrderDirection,
    setOrderBy,
    setCurrentPage,
    setPageSize: handleSetPageSize,
    setSearch,
    handleSort,
    handleReset,
    handleSetTableFilterValues,
  };
};
