import { Typography } from "styled/Typography";

import styled from "styled-components";
import { theme } from "theme";

export const InputContainer = styled.div<{ $fullWidth?: boolean }>`
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "240px")};
`;

export const Description = styled(Typography.TitleThin)`
  color: ${theme.color.text.primary2};
`;
