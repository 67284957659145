import { ArrowUpwardIcon } from "icons";
import styled from "styled-components";
import { Typography } from "styled/Typography";
import { theme } from "theme";

export const Button = styled.button<{ $active: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  min-height: 120px;
  border: none;
  padding: 16px 24px;
  border-radius: 8px;
  cursor: pointer;

  background: ${({ $active }) =>
    $active ? "rgba(238, 243, 241, 1)" : "transparent"};

  &:hover {
    background: rgba(244, 244, 244, 1);
  }
`;

export const CurrentValue = styled(Typography.Title)`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

export const PrevValue = styled(Typography.TitleThin)``;

export const PrevTitle = styled(PrevValue)`
  color: ${theme.color.text.tertiary};
  margin-right: 4px;
`;

export const CompareSummary = styled.div<{ $isPositiveResult: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px 2px 4px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  transition: all 0.25s;

  background: ${({ $isPositiveResult }) =>
    $isPositiveResult ? "rgba(91, 170, 92, 1)" : "rgba(250, 80, 69, 1)"};
`;

export const Arrow = styled(ArrowUpwardIcon)<{ $isPositiveResult: boolean }>`
  ${({ $isPositiveResult }) => !$isPositiveResult && `rotate: 180deg`}
`;
