// Lib
import { FC, useEffect, useState } from "react";
// Api
import {
  useCreateProductLabelMutation,
  useDeleteProductLabelMutation,
  useGetProductsLabelsQuery,
  useUpdateProductLabelMutation,
} from "rtkQuery/query/productsAPI";
// Hooks
import { useNotification, usePermissions } from "hooks";
// Actions
// Selectors
// Types
import { TableAction } from "types/common";
import { ProductTag } from "types/products";
// Theme
import { theme } from "theme";
// Constants
import { NOTIFICATIONS } from "consts";
// Helpers
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PencilIcon, PlusIcon, TrashIcon } from "icons";
// Layouts
// Components
import { ConfirmDialog, Table } from "components";
import { LabelCreateEditForm, LabelCreateEditModal } from "../components";
// Styled
import { FlexContainer, PageButtonsContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import { PageWrapper } from "./styled";

import { columns } from "./config";

export const Labels: FC = () => {
  const { canCreateTag, canUpdateTag, canDeleteTag } = usePermissions();
  const { openNotification } = useNotification();

  const [deleteModal, setDeleteModal] = useState<ProductTag | false>(false);
  const [editModal, setEditModal] = useState<ProductTag | boolean>(false);

  const [remove, { isLoading: isDeleteLoading }] =
    useDeleteProductLabelMutation();
  const [create, { isLoading: isCreateLoading }] =
    useCreateProductLabelMutation();
  const [update, { isLoading: isUpdateLoading }] =
    useUpdateProductLabelMutation();

  const {
    data: tableData,
    isFetching: isTableDataFetching,
    error,
  } = useGetProductsLabelsQuery(null, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const onConfirmDelete = async () => {
    if (!deleteModal) return;
    try {
      await remove({ id: deleteModal?.id });

      setDeleteModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleSave = async ({
    id,
    formData,
  }: {
    id: string | null;
    formData: LabelCreateEditForm;
  }) => {
    try {
      if (id) {
        await update({ id, ...formData }).unwrap();
      } else {
        await create({ ...formData }).unwrap();
      }
      setEditModal(false);
    } catch (error) {
      errorHandler({ error: error.data, openNotification });
    }
  };

  const actions: TableAction[] = [
    {
      title: "",
      Icon: PencilIcon,
      disabled: !canUpdateTag,
      onClick: (tag: ProductTag) => setEditModal(tag),
    },
    {
      title: "",
      type: "Delete",
      Icon: TrashIcon,
      disabled: !canDeleteTag,
      onClick: (tag: ProductTag) => setDeleteModal(tag),
    },
  ];

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $gap={20}
          $grow={1}
          $padding="0 0 32px"
        >
          <FlexContainer $align="center" $justify="space-between">
            <Typography.H1>Labels</Typography.H1>

            <PageButtonsContainer>
              {canCreateTag && (
                <Button.Heading
                  type="primary"
                  icon={<PlusIcon fill={theme.color.white} />}
                  onClick={() => setEditModal(true)}
                >
                  Add Label
                </Button.Heading>
              )}
            </PageButtonsContainer>
          </FlexContainer>

          <Table
            isLoading={isTableDataFetching}
            rowKey={record => record.id}
            header={{}}
            accentColumn={2}
            accentColumnWidth="100%"
            columns={columns}
            dataSource={tableData || []}
            actions={actions}
          />
        </FlexContainer>
      </PageWrapper>

      <LabelCreateEditModal
        data={editModal}
        isLoading={isCreateLoading || isUpdateLoading}
        onClose={() => setEditModal(false)}
        onSave={handleSave}
      />

      <ConfirmDialog
        open={!!deleteModal}
        Icon={TrashIcon}
        message={deleteModal && `${deleteModal.name} will be deleted`}
        description={NOTIFICATIONS.APROVE_ACTION_MESSAGE}
        firstCTAButton={{
          title: "Delete Label",
          status: "danger",
          loading: isDeleteLoading,
          onClick: () => onConfirmDelete(),
        }}
        onCancel={handleCloseDeleteModal}
      />
    </>
  );
};
