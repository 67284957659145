// Lib
import { FC } from "react";
import { useLocation, useParams } from "react-router-dom";
// Api
import { useGetOrderQuery } from "rtkQuery/query/ordersAPI";
// Types
import { TabsProps } from "antd/es/tabs";
// Constants
import { ADMIN_ROUTES } from "consts";
// Helpers
import { convertStatusToText } from "helpers/dataHelpers";
// Icons
import { LabelBlackIcon } from "icons";
// Components
import { ArrowBackLink, Badge, Loader, Tabs } from "components";
import { StatusTab, SummaryTab } from "../components";
// Styled
import {
  FlexContainer,
  IconRoundedContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";
import { Typography } from "styled/Typography";

export const OrdersView: FC = () => {
  const { id } = useParams();

  const location = useLocation();

  const { data: orderData, isFetching: isLoading } = useGetOrderQuery(
    { id },
    {
      skip: !id,
      refetchOnMountOrArgChange: true,
    },
  );

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Summary`,
      children: <SummaryTab isLoading={isLoading} orderData={orderData} />,
    },
    {
      key: "2",
      label: `Status`,
      children: <StatusTab orderHistories={orderData?.orderHistories} />,
    },
  ];

  return (
    <>
      {isLoading && <Loader />}

      <FlexContainer $column $gap={24}>
        <PageHeadingContainer>
          <PageTitleContainer $column>
            <ArrowBackLink
              title="Back to Orders"
              path={location?.state?.backPath || ADMIN_ROUTES.ORDERS.path}
            />
            <FlexContainer
              $gap={12}
              $wrap
              $align="center"
              $justify="flex-start"
              $margin="20px 0 0 0"
            >
              <IconRoundedContainer $bgColor="#E0E2E1">
                <LabelBlackIcon />
              </IconRoundedContainer>

              <Typography.H1>{orderData?.sid}</Typography.H1>

              {!!orderData?.status && (
                <Badge
                  status={orderData?.status}
                  title={convertStatusToText(orderData?.status)}
                />
              )}
            </FlexContainer>
          </PageTitleContainer>
        </PageHeadingContainer>

        <Tabs items={items} />
      </FlexContainer>
    </>
  );
};
