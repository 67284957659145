import dayjs from "dayjs";
import { OrderStatus } from "./orders";

export enum AnalyticsPeriodFilterValues {
  Custom = "custom",
  Today = "today",
  Yesterday = "yesterday",
  ThisWeek = "thisWeek",
  LastSevenDays = "lastSevenDays",
  ThisMonth = "thisMonth",
  LastFourWeeks = "lastFourWeeks",
  CurrentQuarter = "currentQuarter",
  CurrentYear = "currentYear",
}

export enum AnalyticsPeriodFilterLabels {
  custom = "Custom",
  today = "Today",
  yesterday = "Yesterday",
  thisWeek = "This week",
  lastSevenDays = "Last 7 days",
  thisMonth = "This month",
  lastFourWeeks = "Last 4 weeks",
  currentQuarter = "Current quarter",
  currentYear = "Current year",
}

export enum AnalyticsPeriodGroupByValues {
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
}

export enum AnalyticsPeriodGroupByLabels {
  hour = "Hour",
  day = "Day",
  week = "Week",
  month = "Month",
}

export enum ChartCompareValues {
  PreviousPeriod = "previousPeriod",
  SelectedLocation = "selectedLocation",
}

export enum ChartCompareLabels {
  previousPeriod = "Previous period",
  selectedLocation = "Selected location",
}

export type ChartLinesConfig = {
  dataKey: string;
  stroke: string;
  activeDot: { r: number; stroke?: string; fill?: string };
  strokeDasharray?: string;
};

export type ChartLegendConfig = {
  title: string;
  color: string;
  textColor?: string;
};

export type MergedChartSlotData = {
  name: string | number;
  [AnalyticsStatsSummaryKey.ItemSold]?: Record<string, number | null>;
  [AnalyticsStatsSummaryKey.Revenue]?: Record<string, number | null>;
  [AnalyticsStatsSummaryKey.AverageOrderValue]?: Record<string, number | null>;
  [AnalyticsStatsSummaryKey.OrderIssueOvertime]?: Record<string, number | null>;
  [AnalyticsStatsSummaryKey.TotalOrders]?: Record<string, number | null>;
  [AnalyticsStatsSummaryKey.TotalRevenue]?: Record<string, number | null>;
  slotStartTime: dayjs.Dayjs;
  slotEndTime: dayjs.Dayjs;
  prevSlotStartTime?: dayjs.Dayjs;
  prevSlotEndTime?: dayjs.Dayjs;
  groupBy: AnalyticsPeriodGroupByValues;
};

export type ChartLinesData = {
  slotsData: MergedChartSlotData[];
  linesConfig: ChartLinesConfig[];
  legendConfig: ChartLegendConfig[];
};

export type CreateChartDataOutput = {
  chartLinesData: ChartLinesData;
};

export type ChartDataCoordinates = Record<string, string | number | object>;

export type ChartData = {
  name: string | number;
  groupBy: AnalyticsPeriodGroupByValues;
  tooltipTitles?: Record<string, string | number>;
} & ChartDataCoordinates;

export type ChartSlotData = {
  name: string | number;
  slotStartTime: dayjs.Dayjs;
  slotEndTime: dayjs.Dayjs;
  data: {
    [AnalyticsStatsSummaryKey.ItemSold]?: number;
    [AnalyticsStatsSummaryKey.Revenue]?: number;
    [AnalyticsStatsSummaryKey.AverageOrderValue]?: number;
    [AnalyticsStatsSummaryKey.OrderIssueOvertime]?: number;
    [AnalyticsStatsSummaryKey.TotalOrders]?: number;
  };
  groupBy: AnalyticsPeriodGroupByValues;
};

export type AnalyticsDateFilter = {
  current: {
    startTime: string;
    endTime: string;
  };
  prev: {
    startTime: string;
    endTime: string;
  };
  groupBy: AnalyticsPeriodGroupByValues;
};

export type AnalyticsStateDateFilterValue = {
  value: AnalyticsPeriodFilterValues;
  //use for AnalyticsPeriodFilterValues.Custom
  customTimePeriod?: {
    currentStartTime: string;
    currentEndTime: string;
    prevStartTime: string;
    prevEndTime: string;
    groupBy: AnalyticsPeriodGroupByValues;
  };
};

export interface AnalyticsState {
  location: string;
  orderStatus: OrderStatus | "";
  dateFilter: AnalyticsStateDateFilterValue;
  comparing: ChartCompareValues;
}

export enum AnalyticsChartKey {
  OrdersChart = "ordersChart",
  MenuItemsChart = "menuItemsChart",
  LocationsChart = "locationsChart",
}

export enum AnalyticsStatsSummaryKey {
  ItemSold = "itemSold",
  Revenue = "revenue",
  TotalRevenue = "totalRevenue",
  OrderIssueOvertime = "orderIssueOvertime",
  AverageOrderValue = "averageOrderValue",
  TotalOrders = "totalOrders",
  Orders = "orders",
}

export enum AnalyticsStatsSummaryLabels {
  itemSold = "Item Sold",
  revenue = "Revenue",
  averageOrderValue = "Average order value",
  totalOrders = "Total Order",
  orderIssueOvertime = "Order issue overtime",
  orders = "Orders",
}

export type AnalyticsStatsSummaryValues = {
  dataKey: AnalyticsStatsSummaryKey;
  value: number;
  prevValue: number;
};

export type OrdersTableCalendarRow = {
  time: string;
  mon: number;
  tue: number;
  wed: number;
  thu: number;
  fri: number;
  sat: number;
  sun: number;
};

export interface GetOrdersAnalyticsRequest {
  companyId: string;
  query: {
    siteId?: string;
    status?: string | OrderStatus;
    dateBefore: string;
    dateAfter: string;
    dateBeforePrev: string;
    dateAfterPrev: string;
  };
}

export type AnalitycsOrderItem = {
  id: string;
  status: OrderStatus;
  totalFiatCentsPrice: string;
  totalFmcCentsPrice: string;
  site: {
    id: string;
    name: string;
  };
  updatedAt: string;
};

export interface GetOrdersAnalyticsResponse {
  items: AnalitycsOrderItem[];
  current: {
    totalOrders: number;
    totalFailedOrders: number;
    totalRevenue: string;
    averageOrderValue: string;
  };
  prev: {
    totalOrders: number;
    totalFailedOrders: number;
    totalRevenue: string;
    averageOrderValue: string;
  };
}

export interface GetOrderRevenueBreakdownAnalyticsRequest {
  companyId: string;
  query: {
    siteId?: string;
    period: "day" | "month";
    dateBefore: string;
    dateAfter: string;
  };
}

export interface GetOrderRevenueBreakdownAnalyticsResponse {
  items: {
    period: string;
    siteId: string | null;
    totalDeliveryCost: string;
    totalDiscount: string;
    totalRevenue: string;
  }[];
}

export interface GetOrderHourlyDistributionAnalyticsRequest {
  companyId: string;
  query: {
    dateBefore: string;
    dateAfter: string;
  };
}

export interface GetOrderHourlyDistributionAnalyticsResponse {
  items: OrdersTableCalendarRow[];
}
