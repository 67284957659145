// Lib
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Reset } from "styled-reset";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import updateLocale from "dayjs/plugin/updateLocale";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
// Context
import { NotificationProvider } from "context/NotificationsContext";
import { KitchenOrdersProvider } from "context/KitchenOrdersContext";
import { ViewportProvider } from "context/ViewportContext";
// Hooks
import { useUserRole } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getUserPermissions, isAuth } from "rtkQuery/selectors";
// Theme
import { config } from "theme";
// Constants
import {
  LOGIN,
  ADMIN_HOME_ROUTE,
  ADMIN_ROUTES,
  sidebarMenuItems,
  KITCHEN_PWA_ROUTES,
  KITCHEN_PWA_HOME_ROUTE,
} from "consts";
// Utils
import { token } from "utils/handleToken";
import {
  checkAdminMenuPermissions,
  checkAdminRoutesPermissions,
} from "utils/checkAdminPermissions";
// Components
import { AuthRequired, Layout, Me } from "components";
import { Login } from "pages";
// Styles
import { AppContainer, GlobalStyles } from "styles";

dayjs.extend(updateLocale);
dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);
dayjs.updateLocale("en", {
  weekStart: 1,
});

export const App = () => {
  const isAuthorized = useAppSelector(isAuth);
  const userPermissions = useAppSelector(getUserPermissions);
  const { isAdminUser, isAccountManagerUser, isKitchenUser } = useUserRole();

  const userAuthorized = isAuthorized && !!token.access.get();

  const hasAccessToLoop = isAdminUser || isAccountManagerUser;

  const HOME_ROUTE = hasAccessToLoop
    ? ADMIN_HOME_ROUTE
    : isKitchenUser && KITCHEN_PWA_HOME_ROUTE;

  const redirectPath = userAuthorized ? HOME_ROUTE : LOGIN;

  const ROUTES = hasAccessToLoop
    ? checkAdminRoutesPermissions({
        permissions: userPermissions,
        routes: { ...ADMIN_ROUTES, ...KITCHEN_PWA_ROUTES },
      })
    : isKitchenUser && KITCHEN_PWA_ROUTES;

  const sideBarMenu = hasAccessToLoop
    ? checkAdminMenuPermissions({
        menuItems: sidebarMenuItems.loop,
        permissions: userPermissions,
      })
    : [];

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="*" element={<Navigate replace to={redirectPath} />} />

        <Route
          path="/loop/*"
          element={<Navigate replace to={redirectPath} />}
        />

        <Route path={LOGIN} element={<Login />} />

        <>
          {Object.keys(ROUTES).map(key => {
            const { Component, path, side } = ROUTES[key];
            if (side === "kitchen") {
              return (
                <Route
                  key={key}
                  path={path}
                  element={
                    <AuthRequired>
                      <KitchenOrdersProvider>
                        <Layout sidebarMenuItems={sidebarMenuItems.kitchenPwa}>
                          <Component />
                        </Layout>
                      </KitchenOrdersProvider>
                    </AuthRequired>
                  }
                />
              );
            }
            return (
              <Route
                key={key}
                path={path}
                element={
                  <AuthRequired>
                    <Layout sidebarMenuItems={sideBarMenu}>
                      <Component />
                    </Layout>
                  </AuthRequired>
                }
              />
            );
          })}
        </>
      </Route>,
    ),
  );

  return (
    <>
      <Reset />
      <GlobalStyles />

      <Me />

      <NotificationProvider>
        <ConfigProvider theme={config}>
          <ViewportProvider>
            <AppContainer>
              <RouterProvider router={router} />
            </AppContainer>
          </ViewportProvider>
        </ConfigProvider>
      </NotificationProvider>
    </>
  );
};
