/* eslint-disable */

export const mockedLocationsData = [
  {
    id: "667026ab0ce21ecb25de8ba1",
    name: null,
  },
  {
    id: "66227a732df90efca4fe5e71",
    name: "Loop Khaitan",
  },
  {
    id: "64e768ede2f9b0c9236120eb",
    name: "Cold Kitchen",
  },
  {
    id: "64c21023966460755c5339cf",
    name: "FM  Yard",
  },
  {
    id: "64b66609f9b191f3652edc8b",
    name: "FM Khaitan",
  },
  {
    id: "64634f2fcdf25f405d574f88",
    name: "FM Jabriya",
  },
  {
    id: "645a3d26d812e8a27b06d717",
    name: "FM City",
  },
];

export type OrderStatusDto =
  | "pending"
  | "accepted"
  | "preparing"
  | "prepared"
  | "ready_for_pickup"
  | "in_delivery"
  | "delivered"
  | "auto_finalized"
  | "canceled";

export type ChargeStatusDto =
  | "pending"
  | "succeeded"
  | "failed"
  | "refund_requested"
  | "refund_succeeded"
  | "refund_failed"
  | "unpaid";

export type ChargeFailureReasonDto =
  | "insufficient_funds"
  | "expired_balance"
  | "refund_failed"
  | "internal_error"
  | null;

export enum AllPaymentTypes {
  All = "all",
}

export type Coupon = {
  id: string;
  createdAt: string;
  updatedAt: string;
  discountOff: number;
  discountType: any;
  startDate: string;
  expiryDate: string;
  numberOfUses: number;
  couponCode: string;
  isActive: boolean;
  allowMultipleUsage: boolean;
  allowedUsers: any[];
  minimumOrderFiatCentsPrice: number;
  minimumOrderFmcCentsPrice: number;
  orderPaymentType: OrderPaymentType | AllPaymentTypes;
  couponType: any;
};
export type AddonsModifiers = {
  productId: string;
  productPLU: string;
  productName: string;
  productDescription: string;
  productCalories: number;
  quantity: number;
  fmcCentsPrice: number;
  fiatCentsPrice: number;
  externalFiatCentsPrice?: any;
  nutritionInfo?: any;
};

export type OrderItemResponseDto = {
  productId: string;
  productPLU: string;
  productName: string;
  productDescription?: string;
  productCalories?: number;
  quantity: number;
  imageURL?: string;
  fmcCentsPrice: number;
  fiatCentsPrice: number;
  nutritionInfo?: any;
  externalFiatCentsPrice?: any;
  remark: string;
  subItems: AddonsModifiers[];
};

export type CustomerAddressResponseDto = {
  id: string;
  name: string;
  lng: number;
  lat: number;
  city: string;
  area: string;
  block: string;
  street: string;
  building: string;
  floor: string | null;
  flat: string | null;
  phone: string;
  additionalInfo: string | null;
  isSelected: boolean;
  isInAnyDeliveryZone?: boolean;
  createdAt: string;
  updatedAt: string;
};

export enum OrderPaymentType {
  FiatCents = "fiatCents",
  FmcCents = "fmcCents",
}

export type DeliveryResponseDto = {
  id: string;
  customerId: string;
  locationId: string;
  deliveryFeeFiatCents?: number;
  deliveryFeeFmcCents?: number;
  deliveryAddress: CustomerAddressResponseDto;
  deliverectValidationId: string | null;
  deliveryProvider: "deliverect" | "own";
  expiresAt: string;
  estimatedDeliveryTime: string | null;
  estimatedDeliveryTimeEnd: string | null;
  orderId: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OrderStatusHistoryItemResponseDto = {
  previousStatus: OrderStatusDto | string | null;
  newStatus: OrderStatusDto | string;
  timestamp: string;
};

export type OrderResponseDto = {
  paymentType: OrderPaymentType | string;
  id: string;
  sid: string;
  menuId: string;
  locationId: string;
  locationName: string | null;
  deliveryId: string;
  customerId: string;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;

  phoneNumber?: string | null;
  delivery: DeliveryResponseDto;
  items: OrderItemResponseDto[];
  totalFiatCentsPrice: number;
  totalFmcCentsPrice: number;
  totalExternalFiatCentsPrice?: any;
  totalFiatCentsPriceOriginal?: number;
  totalFmcCentsPriceOriginal?: number;
  orderStatus: OrderStatusDto;
  chargeStatus: ChargeStatusDto;
  coupons: Coupon[];
  chargeFailureReason: ChargeFailureReasonDto;
  orderStatusHistory: OrderStatusHistoryItemResponseDto[];
  chargeStatusHistory: OrderStatusHistoryItemResponseDto[];
  timeslot: string;
  fleetInfo?: any;
  createdAt: string;
  updatedAt: string;
  cutlery?: boolean;
  hasRating?: boolean;
  type?: string;
};

export const mokedOrdersData: OrderResponseDto[] = [
  {
    id: "6685128709647a7c33cad1f1",
    sid: "240703-182117",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6685128709647a7c33cad190",
    firstName: "Hassan asiri",
    lastName: null,
    phoneNumber: "+96596600302",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65a7abd373472f758c802504",
    delivery: {
      id: "6685128709647a7c33cad190",
      customerId: "65a7abd373472f758c802504",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6633467788e72dd2ffc56f00",
        name: "الدوام",
        lng: 47.95462924987078,
        lat: 29.31869565693983,
        city: "Shuwaikh Industrial",
        area: "Shuwaikh Industrial",
        block: "0",
        street: "Street 29",
        building: "Environmental public authority ",
        floor: "Ground floor ",
        flat: "",
        phone: "+96596600302",
        additionalInfo: "Go in thru gate number 2",
        isSelected: true,
        createdAt: "2024-05-02T07:53:27.462Z",
        updatedAt: "2024-07-03T08:56:44.226Z",
      },
      deliverectValidationId: "66851287258eb377f7f1c817",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T09:07:43.014Z",
      estimatedDeliveryTime: "2024-07-03T09:44:41.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6685128709647a7c33cad1f1",
      createdAt: "2024-07-03T08:57:43.132Z",
      updatedAt: "2024-07-03T08:57:43.911Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f481",
        productPLU: "2161013-1",
        productDescription:
          "C 28g| P 22g| F 21g\n2 fried eggs with avocado and beef sausage served with sour dough toast.\n\nGood source of Protein & healthy fat",
        productName: "Egg Avo Platter",
        imageURL:
          "https://resizer.deliverect.com/r_ObTbJI3AoU9w6PFaC93-52m67nkc-am15KRZoSW6I/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRWdnJTIwYXZvY2FkbyUyMGJyZWFra2llJTIwKDEpLTY2MzYwMTA4Y2JiMDVhYTc5N2MyNjg4MS5qcGc=",
        productCalories: 389,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f4a7",
        productPLU: "2166009-1",
        productDescription:
          "C 22g| P 11g| F 13g\nMango greek yogurt with chia pudding topped with mangoes.\n\nGood source oF Vitamin A",
        productName: "Mango Greek Yogurt",
        imageURL:
          "https://resizer.deliverect.com/xGontUyo2rWspjDzY7LiTodlo5Gzmv5FuI0NOV_lXRI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNS02NTExNmVjYTM5NGU3ODMxZTFiZDM3MWUuanBn",
        productCalories: 249,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:57:43.884Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:58:34.525Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:58:46.851Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T09:06:52.847Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T09:06:52.847Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T09:08:58.460Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:41:44.366Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:57:43.884Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:57:55.422Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:57:43.884Z",
    updatedAt: "2024-07-03T09:41:44.705Z",
  },
  {
    id: "668511b909647a7c33cac250",
    sid: "240703-811767",
    coupons: [],
    menuId: "6684c96309647a7c33c4f840",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "668511b809647a7c33cac244",
    firstName: "Rawan khaled",
    lastName: null,
    phoneNumber: "+96555388883",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6640503b66978e88ed0d945f",
    delivery: {
      id: "668511b809647a7c33cac244",
      customerId: "6640503b66978e88ed0d945f",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66409a2a5ca983b14ac67f44",
        name: "Home",
        lng: 47.89909511804581,
        lat: 29.273439843139435,
        city: "Sabah Al Nasser",
        area: "Sabah Al Nasser",
        block: "7",
        street: "49",
        building: "2",
        floor: "",
        flat: "",
        phone: "+96555388883",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-12T10:30:02.590Z",
        updatedAt: "2024-06-07T08:49:16.005Z",
      },
      deliverectValidationId: "668511b885b4e122ced50612",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T09:04:16.569Z",
      estimatedDeliveryTime: "2024-07-03T09:41:15.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668511b909647a7c33cac250",
      createdAt: "2024-07-03T08:54:16.656Z",
      updatedAt: "2024-07-03T08:54:17.252Z",
    },
    items: [
      {
        productId: "6684c96309647a7c33c4f86c",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96309647a7c33c4f8c4",
        productPLU: "2202035-1",
        productDescription:
          "C 24g | P 20.7g | F 3.7g\nVanilla, Milk, Stevia & WHEY Protein",
        productName: "Vanilla Protein shake",
        imageURL:
          "https://resizer.deliverect.com/3bjzKi97cmL3LLTEUU_lvjy3JLsp7QvOF9KbvO9bJaY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVmFuaWxsYSUyMHByb3RlaW4lMjBzaGFrZS02NjQxZGMwZmNjNWEzYTExMDc5YzVlYzgucG5n",
        productCalories: 212,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:54:17.234Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:55:03.041Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T09:05:31.982Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T09:05:31.982Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T09:05:31.982Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T09:12:09.812Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:33:32.580Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:54:17.234Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:54:28.758Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:54:17.234Z",
    updatedAt: "2024-07-03T09:33:32.941Z",
  },
  {
    id: "6685113409647a7c33cab8ec",
    sid: "240703-693092",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6685113409647a7c33cab8c3",
    firstName: " Bader",
    lastName: null,
    phoneNumber: "+96555551014",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c958ff75a6aa442d12a1b3",
    delivery: {
      id: "6685113409647a7c33cab8c3",
      customerId: "64c958ff75a6aa442d12a1b3",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6501b1b634491db2e903696a",
        name: "Work",
        lng: 47.98337113112211,
        lat: 29.38579584391136,
        city: "Kuwait City",
        area: "Sharq",
        block: "2",
        street: "Ibrahem almudhaf st",
        building: "Jase tower",
        floor: "14",
        flat: "",
        phone: "+96555551014",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-09-13T12:57:26.191Z",
        updatedAt: "2024-07-03T08:51:03.588Z",
      },
      deliverectValidationId: "66851133258eb377f7f14ece",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T09:02:03.894Z",
      estimatedDeliveryTime: "2024-07-03T09:39:02.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6685113409647a7c33cab8ec",
      createdAt: "2024-07-03T08:52:04.079Z",
      updatedAt: "2024-07-03T08:52:04.940Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f47a",
        productPLU: "2166004-1",
        productDescription:
          "C 60g | P 9g | F 4g\n2 Pcs vanilla protein pancake with maple syrup\n\nGood Source of protein & Vitamins.",
        productName: "Vanilla Pancake",
        imageURL:
          "https://resizer.deliverect.com/3F1kHUeH6dH4pskh42lWXLmxolWCndanYZSf8qGeUeI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGFuY2FrZS02NWY2ZWE5YWE2OGE3NjU2YWUwNjgyOGYucG5n",
        productCalories: 312,
        quantity: 1,
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f4dc",
        productPLU: "2202045-1",
        productDescription:
          "C 24g | P 20.8g | F 3.8g\nVanilla, Milk, Stevia, Coffee & WHEY Protein",
        productName: "Vanilla Protein Latte",
        imageURL:
          "https://resizer.deliverect.com/IToGNZncc7jFRfdY-Nz1qPS1_kaXAd3rj5Qhiy7-fPk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvdmFuaWxsYSUyMGxhdHRlLTY2NDFkYzIyNzQ4ZjM2N2MxMzE2Y2U5MS5wbmc=",
        productCalories: 212,
        quantity: 1,
        fmcCentsPrice: 160,
        fiatCentsPrice: 160,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:52:04.921Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:52:36.485Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:52:48.516Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T09:00:20.659Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T09:00:20.659Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T09:10:24.852Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:31:11.842Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:52:04.921Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:52:16.446Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:52:04.921Z",
    updatedAt: "2024-07-03T09:31:12.299Z",
  },
  {
    id: "6685111209647a7c33cab71b",
    sid: "240703-382607",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6685111109647a7c33cab712",
    firstName: "Rawya",
    lastName: null,
    phoneNumber: "+96595560443",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "665b051c0eba0d08a56c79b0",
    delivery: {
      id: "6685111109647a7c33cab712",
      customerId: "665b051c0eba0d08a56c79b0",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6677e711bed556ad8f74e1ad",
        name: "Work",
        lng: 47.93485298752785,
        lat: 29.34703929630097,
        city: "Kuwait",
        area: "ميناء الشويخ",
        block: "..",
        street: "موسسة المواني الكويتية ",
        building: "Kuwait ports authority ",
        floor: " Floor 7 - الدور السابع",
        flat: " Office 714 - مكتب رقم ٧١٤",
        phone: "+96595560443",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-23T09:12:49.654Z",
        updatedAt: "2024-07-03T08:50:22.018Z",
      },
      deliverectValidationId: "6685111190cd3eacd5f182e2",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T09:01:29.620Z",
      estimatedDeliveryTime: "2024-07-03T09:38:28.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6685111209647a7c33cab71b",
      createdAt: "2024-07-03T08:51:29.714Z",
      updatedAt: "2024-07-03T08:51:30.362Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f47c",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        remark: "Only eggs and cheese ",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:51:30.342Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:51:55.055Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:52:42.559Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:58:33.254Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:58:33.258Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:58:55.122Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:28:10.351Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:51:30.342Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:51:41.996Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:51:30.342Z",
    updatedAt: "2024-07-03T09:28:10.955Z",
  },
  {
    id: "6685103b09647a7c33caa957",
    sid: "240703-678562",
    coupons: [],
    menuId: "6684c96209647a7c33c4f647",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6685103b09647a7c33caa944",
    firstName: "jassm aldabbous",
    lastName: null,
    phoneNumber: "+96599301163",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6683427d8c15153ac89fec2f",
    delivery: {
      id: "6685103b09647a7c33caa944",
      customerId: "6683427d8c15153ac89fec2f",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6683436b09647a7c338a0739",
        name: "home",
        lng: 48.1369698420167,
        lat: 29.089392816595616,
        city: "Kuwait",
        area: "Fahaheel",
        block: "10",
        street: "Homoud Hamdan Alkhadda St",
        building: "7",
        floor: "",
        flat: "",
        phone: "+96599301163",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-02T00:01:47.009Z",
        updatedAt: "2024-07-02T00:01:47.009Z",
      },
      deliverectValidationId: "6685103b06a601cc357d813e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:57:55.395Z",
      estimatedDeliveryTime: "2024-07-03T09:34:53.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6685103b09647a7c33caa957",
      createdAt: "2024-07-03T08:47:55.483Z",
      updatedAt: "2024-07-03T08:47:56.018Z",
    },
    items: [
      {
        productId: "6684c96209647a7c33c4f670",
        productPLU: "2161021-1",
        productDescription:
          "C 16g| P 15g| F 16g\nEggs, turkish soudjouk, muhammara & labneh\n\nGOOD SOURCE OF PROTEIN",
        productName: "Turkish Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/-B_481BG16xkyYtWQaVbIvD-9tkBN12twZodyhjhhEA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2lzaCUyMGJyZWFrZmFzdCUyMHRhY29zKDEpLTY1NTQ4ZjU3YWI3MmIwMDAyY2I5ZTMyNi5wbmc=",
        productCalories: 268,
        quantity: 1,
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96209647a7c33c4f68f",
        productPLU: "2161023-1",
        productDescription:
          "C 37g| P 17g| F 13g\nFries topped with caramelized onions, ground beef, pickled jalapeños, american cheese & our special sauce. \n\n*GOOD SOURCE OF PROTEIN,VITAMINB12*",
        productName: "Messy Fries",
        imageURL:
          "https://resizer.deliverect.com/uhTtheRt0RZXomxI_1n5O4G2cP8KUacFKd3MVV-dhio/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTUVTU1klMjBGUklFUy02NTc1OGZhM2QyZWUyNWQ5YmZkZDE4YWUucG5n",
        productCalories: 333,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [
          {
            productId: "6684c96209647a7c33c4f6ee",
            productPLU: "1907046-1",
            productName: "No Pickled Jalapeno",
            productDescription: "C -0.12g| P -0.05g| F 0g| cal -3| coins 0",
            productCalories: -3,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
          },
          {
            productId: "6684c96209647a7c33c4f6f0",
            productPLU: "1907066-1",
            productName: "No Caramelized Onion",
            productDescription: "C -9g| P -1g| F 0g| cal -38| coins 0",
            productCalories: -38,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
          },
        ],
      },
    ],
    totalFiatCentsPrice: 450,
    totalFmcCentsPrice: 450,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:47:55.998Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:48:43.708Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:54:22.113Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:58:30.105Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:58:30.105Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T09:03:19.306Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:30:03.288Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:47:55.998Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:48:07.670Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:47:55.998Z",
    updatedAt: "2024-07-03T09:30:03.729Z",
  },
  {
    id: "66850ef509647a7c33ca9820",
    sid: "240703-305239",
    coupons: [],
    menuId: "6684c96209647a7c33c4f647",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "66850ef409647a7c33ca9814",
    firstName: "Ibrahim alhulail ",
    lastName: null,
    phoneNumber: "+96599320178",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d9a299dfecd0088b52c705",
    delivery: {
      id: "66850ef409647a7c33ca9814",
      customerId: "64d9a299dfecd0088b52c705",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66748bc84305a800258429a9",
        name: "Work ",
        lng: 48.053540736436844,
        lat: 29.09817497956321,
        city: "Ahmadi",
        area: "North Ahmadi",
        block: "13",
        street: "طريق المقوع 51 ",
        building: "Kipic head office VIP ",
        floor: "0",
        flat: "",
        phone: "+96599320178",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-20T20:06:32.633Z",
        updatedAt: "2024-07-03T08:02:59.360Z",
      },
      deliverectValidationId: "66850ef4d47b5bc49bc934e2",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:52:28.242Z",
      estimatedDeliveryTime: "2024-07-03T09:29:27.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66850ef509647a7c33ca9820",
      createdAt: "2024-07-03T08:42:28.325Z",
      updatedAt: "2024-07-03T08:42:29.129Z",
    },
    items: [
      {
        productId: "6684c96209647a7c33c4f6a2",
        productPLU: "2202034-1",
        productDescription: "C 17.1g | P 1.9g | F 0.6g\nPomelo & Pomegranate",
        productName: "Seasonal Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/XTwC1dVyhg5--YDaNmhMWBtNNZpa3e6lzIaYdRmZhDA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2Vhc29uYWwlMjBmcnVpdCUyMHNhbGFkLTY2NDFkYmY5YWIxMzE0ZDg1Njc3OGRhNS5wbmc=",
        productCalories: 82,
        quantity: 1,
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96209647a7c33c4f690",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96209647a7c33c4f685",
        productPLU: "2167004-1",
        productDescription:
          "C 43g| P 16g| F 19g\nLight halloumi & black olives with zaatar pesto & ranch in multigrain bread. \n\nGood source of calcium & protein",
        productName: "Halloumi Sandwich",
        imageURL:
          "https://resizer.deliverect.com/kxZW-LHRBXZhOao_mcBsk7OjD7py5CcDqaceAnZntvQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaGFsbG91bWktNjRiN2I2NDZlNGQ1YzMwMDE4YjY1YjZkLnBuZw==",
        productCalories: 407,
        quantity: 1,
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 320,
    totalFmcCentsPrice: 320,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:42:29.111Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:42:47.834Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:43:51.572Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:55:35.122Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:55:35.123Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:57:57.187Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:30:19.450Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:42:29.111Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:42:40.581Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:42:29.111Z",
    updatedAt: "2024-07-03T09:30:19.792Z",
  },
  {
    id: "66850ec709647a7c33ca966f",
    sid: "240703-167622",
    coupons: [],
    menuId: "6684c96509647a7c33c4fa3c",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "66850ec709647a7c33ca95fe",
    firstName: "munera alnajdi",
    lastName: null,
    phoneNumber: "+96550707469",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6656de988ceacb066c54afb3",
    delivery: {
      id: "66850ec709647a7c33ca95fe",
      customerId: "6656de988ceacb066c54afb3",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6656e10fecc6fd38a64f6c1c",
        name: "surra",
        lng: 48.018031641840935,
        lat: 29.31376947456156,
        city: "Jabriya",
        area: "Surra",
        block: "2",
        street: "8 St",
        building: "13",
        floor: "",
        flat: "",
        phone: "+96550707469",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-29T08:02:23.313Z",
        updatedAt: "2024-06-30T13:55:34.654Z",
      },
      deliverectValidationId: "66850ec722fbd612118e0cfc",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:51:43.043Z",
      estimatedDeliveryTime: "2024-07-03T09:28:41.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66850ec709647a7c33ca966f",
      createdAt: "2024-07-03T08:41:43.142Z",
      updatedAt: "2024-07-03T08:41:44.005Z",
    },
    items: [
      {
        productId: "6684c96509647a7c33c4fa7b",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/1uWrRlXfTE6RYwQoa2vZDD0miTwao6DizisuqC3ZQlY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZWFiOGVjNDM1MDAxYTRjMGJkYy5qcGc=",
        productCalories: 497,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96509647a7c33c4faae",
        productPLU: "2202005-1",
        productDescription: "C 0g| P 3g| F 0g\nSugar Free",
        productName: "Amino go BCAA Mango",
        imageURL:
          "https://resizer.deliverect.com/82M6g2SZkIhL3Q6WqOQPx8MhMICHb3avU8paSKMiFiE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRm9yZXZlcm1vcmUtMTIzMjItNjRhYWIyYWEyZWNhMDcwMDIwYTRlNWQzLmpwZw==",
        productCalories: 13,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:41:43.974Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:42:30.900Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:42:30.901Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:50:32.463Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:50:32.463Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:50:54.853Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:09:25.806Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:41:43.974Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:41:55.688Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:41:43.975Z",
    updatedAt: "2024-07-03T09:09:26.161Z",
  },
  {
    id: "66850e1a09647a7c33ca8e58",
    sid: "240703-188702",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "66850e1a09647a7c33ca8e4d",
    firstName: " Haya Alhammad",
    lastName: null,
    phoneNumber: "+96599832900",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c504aa75a6aa442d0f7733",
    delivery: {
      id: "66850e1a09647a7c33ca8e4d",
      customerId: "64c504aa75a6aa442d0f7733",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65c9bfe0106ad03db14f8628",
        name: "Faleh Office ",
        lng: 47.983408,
        lat: 29.3628581,
        city: "Al Kuwayt",
        area: "Mirqab",
        block: "Block 1",
        street: "Al-Soor St",
        building: "PIFSS main building",
        floor: "Murqaab Block 1 Alsoor street PIFSS main building",
        flat: "",
        phone: "+96597947744",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-02-12T06:51:12.953Z",
        updatedAt: "2024-07-03T08:38:28.267Z",
      },
      deliverectValidationId: "66850e19bbebd362e92927ef",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:48:49.989Z",
      estimatedDeliveryTime: "2024-07-03T09:25:48.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66850e1a09647a7c33ca8e58",
      createdAt: "2024-07-03T08:38:50.070Z",
      updatedAt: "2024-07-03T08:38:50.741Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f4ae",
        productPLU: "2166021-1",
        productDescription:
          "C 14g| P 2g| F 1g\nFresh pomegranate seeds.\n\nGOOD SOURCE OF VITAMIN  C",
        productName: "Fresh Pomegranate",
        imageURL:
          "https://resizer.deliverect.com/WJf31EG_Y5Z5W3uzQMXw3WY0SLJWIrFJ8gidaXjWYVQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NTE3YzBlMjZlYjUwNTAwMTg3ZGUwNzIuanBn",
        productCalories: 70,
        quantity: 1,
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f48d",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g\nChicken with our special sauce and pickled cucumber wrapped in tortilla.\n\nGood source of Protein",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 2,
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:38:50.722Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:39:35.509Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:41:02.138Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:57:12.383Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:57:12.384Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:58:21.372Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:27:47.910Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:38:50.722Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:39:02.191Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:38:50.722Z",
    updatedAt: "2024-07-03T09:27:48.257Z",
  },
  {
    id: "66850da109647a7c33ca835c",
    sid: "240703-655921",
    coupons: [],
    menuId: "6684c96209647a7c33c4f647",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "66850da109647a7c33ca8352",
    firstName: "Fahad Aljadaan",
    lastName: null,
    phoneNumber: "+96566024905",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "655b9ea55548ef369331203f",
    delivery: {
      id: "66850da109647a7c33ca8352",
      customerId: "655b9ea55548ef369331203f",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "655f04a402b2e21f1fa06e23",
        name: "Home",
        lng: 48.06345585733652,
        lat: 29.263078957573107,
        city: "Sabah Al Salem",
        area: "Sabah Al Salem",
        block: "5",
        street: "St 1, Ave 15",
        building: "61",
        floor: "",
        flat: "",
        phone: "+96566024905",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-23T07:52:04.821Z",
        updatedAt: "2024-07-02T06:17:18.313Z",
      },
      deliverectValidationId: "66850da035273be22cc31947",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:46:48.892Z",
      estimatedDeliveryTime: "2024-07-03T09:23:47.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66850da109647a7c33ca835c",
      createdAt: "2024-07-03T08:36:49.038Z",
      updatedAt: "2024-07-03T08:36:49.580Z",
    },
    items: [
      {
        productId: "6684c96209647a7c33c4f676",
        productPLU: "2161013-1",
        productDescription:
          "C 28g| P 22g| F 21g\n2 fried eggs with avocado and beef sausage served with sour dough toast.\n\nGood source of Protein & healthy fat",
        productName: "Egg Avo Platter",
        imageURL:
          "https://resizer.deliverect.com/r_ObTbJI3AoU9w6PFaC93-52m67nkc-am15KRZoSW6I/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRWdnJTIwYXZvY2FkbyUyMGJyZWFra2llJTIwKDEpLTY2MzYwMTA4Y2JiMDVhYTc5N2MyNjg4MS5qcGc=",
        productCalories: 389,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96209647a7c33c4f69c",
        productPLU: "2166009-1",
        productDescription:
          "C 22g| P 11g| F 13g\nMango greek yogurt with chia pudding topped with mangoes.\n\nGood source oF Vitamin A",
        productName: "Mango Greek Yogurt",
        imageURL:
          "https://resizer.deliverect.com/xGontUyo2rWspjDzY7LiTodlo5Gzmv5FuI0NOV_lXRI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNS02NTExNmVjYTM5NGU3ODMxZTFiZDM3MWUuanBn",
        productCalories: 249,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:36:49.561Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:37:18.302Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:37:18.303Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:51:59.372Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:51:59.373Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:52:21.276Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:18:13.223Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:36:49.561Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:36:58.550Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:36:49.561Z",
    updatedAt: "2024-07-03T09:18:13.577Z",
  },
  {
    id: "66850d9009647a7c33ca81c6",
    sid: "240703-410655",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "66850d8e09647a7c33ca81a8",
    firstName: "Mohammed AlSafran",
    lastName: null,
    phoneNumber: "+96599918057",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "655359236fe598ae84f44e35",
    delivery: {
      id: "66850d8e09647a7c33ca81a8",
      customerId: "655359236fe598ae84f44e35",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65e440c2733cd68442c43706",
        name: "Work",
        lng: 47.97457180917263,
        lat: 29.371245800586635,
        city: "Al Kuwayt",
        area: "Jibla",
        block: "10",
        street: "Ahmad Al Jaber St",
        building: "Burj Baitak",
        floor: "32",
        flat: "",
        phone: "+96599918057",
        additionalInfo: "Burj Baitak",
        isSelected: true,
        createdAt: "2024-03-03T09:20:02.768Z",
        updatedAt: "2024-07-03T08:34:30.595Z",
      },
      deliverectValidationId: "66850d8ed4cdc4432a487c07",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:46:30.390Z",
      estimatedDeliveryTime: "2024-07-03T09:23:29.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66850d9009647a7c33ca81c6",
      createdAt: "2024-07-03T08:36:30.493Z",
      updatedAt: "2024-07-03T08:36:32.485Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f493",
        productPLU: "2167016-1",
        productDescription:
          "C 30g| P 10g| F 11g\nSourdough bread with low fat halloumi cheee, cucumber slices, black olives, guacamole and our special sauce with coriander.\n\nGood source of protein & calcium, healthy fat",
        productName: "Mini Halloumi Sandwich",
        imageURL:
          "https://resizer.deliverect.com/m-55NsCVcGO4zSYvIr1NjPvniHdSTA8w7DLuZLWTUeM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTWluaSUyMGhhbGxvdW1pJTIwc2FuZHdpY2gtNjRmNDhmYWYxOGIxNGMwMDJjZjg0ZDY2LmpwZw==",
        productCalories: 259,
        quantity: 2,
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        remark: "No cucmber ",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f4d7",
        productPLU: "2202036-1",
        productDescription:
          "C 20g | P 19.2g | F 4.7g\nCocoa powder, Milk, Stevia & WHEY Protein",
        productName: "Chocolate Protein Shake",
        imageURL:
          "https://resizer.deliverect.com/TjoR9Z9GyFIBSfx0VgVYXmRJcSGxnQwDMGEZg2k_4G4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hvY29sYXRlJTIwcHJvdGVpbiUyMHNoYWtlLTY2NDFkYWJmMGMyNGQ5MjVmY2QzZmE4Yi5wbmc=",
        productCalories: 200,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:36:32.467Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:36:51.454Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:41:02.242Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:45:16.368Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:45:16.368Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:58:33.049Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:12:40.608Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:36:32.467Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:36:36.774Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:36:32.467Z",
    updatedAt: "2024-07-03T09:12:40.979Z",
  },
  {
    id: "66850af409647a7c33ca4e23",
    sid: "240703-541354",
    coupons: [],
    menuId: "6684c96209647a7c33c4f647",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "66850af409647a7c33ca4e16",
    firstName: "Lulwa albelali",
    lastName: null,
    phoneNumber: "+96566637667",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65226682c6da5aa4aeed26b1",
    delivery: {
      id: "66850af409647a7c33ca4e16",
      customerId: "65226682c6da5aa4aeed26b1",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6669f851d8ab4a2ab7e83923",
        name: "Home",
        lng: 48.050515204668045,
        lat: 29.24918330138528,
        city: "Sabah Al Salem",
        area: "Sabah Al Salem",
        block: "10",
        street: "2",
        building: "1",
        floor: "",
        flat: "",
        phone: "+96566637667",
        additionalInfo: "Avenue 6",
        isSelected: true,
        createdAt: "2024-06-12T19:34:41.893Z",
        updatedAt: "2024-06-12T19:34:41.893Z",
      },
      deliverectValidationId: "66850af306a601cc357afe9f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:35:23.995Z",
      estimatedDeliveryTime: "2024-07-03T09:12:22.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66850af409647a7c33ca4e23",
      createdAt: "2024-07-03T08:25:24.109Z",
      updatedAt: "2024-07-03T08:25:24.622Z",
    },
    items: [
      {
        productId: "6684c96209647a7c33c4f67b",
        productPLU: "2167022-1",
        productDescription:
          "C 29g | P 21g | F 16g\nSpicy chicken shawarma with spring onions, pomegranates, parsley, pickled cucumbers & spicy sauce wrapped in tortilla. \n\nGood source of Protein",
        productName: "Spicy Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/7iczQVW8ZJbNN179Yvo32X8zQsGcTbj5NwVYyyacwCk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU0hBV0FSTUlBKDEpLTY2NDRiYzU4M2NiZjdhMzY3ZmI0YTI1ZC5qcGc=",
        productCalories: 344,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96209647a7c33c4f67f",
        productPLU: "2162075-1",
        productDescription:
          "C 53g| P 20g| F 9g\nGround beef burrito with seasoned egyptian rice, corn, black beans, lettuce & sour cream wrapped in a tortilla wrap.\n\nGOOD SOURCE OF PROTEIN & FIBER",
        productName: "Beef Burrito",
        imageURL:
          "https://resizer.deliverect.com/85QohnB6E-ysOf_DICo7nPH2RjHXSEy1Qm9NHYJHOgk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGJ1cnJpdG8oMSktNjU1NDhmYTA2MmFiMGUwMDFmM2E3MzczLnBuZw==",
        productCalories: 373,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96209647a7c33c4f693",
        productPLU: "2166037-1",
        productDescription:
          "C 31g | P 3g | F 8g [%New%]\nTortilla chips, cheddar cheese, & pickled jalapeños. ",
        productName: "Jalapeño Cheddar Nachos ",
        imageURL:
          "https://resizer.deliverect.com/Igtv_X3IIKdvVYw3drGOS6DlDQf47bmEkgol0b9fBZ0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFsYXBlbm8lMjBjaGVlc2UlMjBuYWNob3MtNjY2MWNkZWQ5YWNlYTFmMWZlZWVkOTU5LnBuZw==",
        productCalories: 208,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 530,
    totalFmcCentsPrice: 530,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:25:24.602Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:26:13.093Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:29:32.165Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:34:47.201Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:34:47.202Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:35:55.292Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:05:58.838Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:25:24.602Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:25:36.110Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:25:24.602Z",
    updatedAt: "2024-07-03T09:05:59.307Z",
  },
  {
    id: "66850acb09647a7c33ca4a76",
    sid: "240703-533212",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "66850aca09647a7c33ca4a6a",
    firstName: "Hamad jamal alammar",
    lastName: null,
    phoneNumber: "+96551429994",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "665ec8200eba0d08a5753529",
    delivery: {
      id: "66850aca09647a7c33ca4a6a",
      customerId: "665ec8200eba0d08a5753529",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "666169ab3d5f2b755d4d0c7f",
        name: "Work",
        lng: 47.981521748006344,
        lat: 29.378370060316225,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "6",
        street: "Ahmad Al Jaber St",
        building: "Department for Legal Advice and Legislation",
        floor: "",
        flat: "",
        phone: "+96551429994",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-06T07:47:55.969Z",
        updatedAt: "2024-07-03T08:24:12.346Z",
      },
      deliverectValidationId: "66850aca3e682bb537f03504",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:34:42.646Z",
      estimatedDeliveryTime: "2024-07-03T09:11:41.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66850acb09647a7c33ca4a76",
      createdAt: "2024-07-03T08:24:42.735Z",
      updatedAt: "2024-07-03T08:24:43.660Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f489",
        productPLU: "2162076-1",
        productDescription:
          "C 57g| P 32g| F 12g\nGrilled chicken, rice, corn, mixed beans sauce, iceburg lettuce, chedder cheese, mozarella cheese & ranch all wrapped in a tortilla wrap.\n\nGOOD SOURCE OF PROTEIN, VITAMINS AND MINERALS",
        productName: "Chicken Burrito",
        imageURL:
          "https://resizer.deliverect.com/6YIIS1bTpbzMr3WFtmPqqbcL9yd4ZmTrgUo1MJkaRzE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cnJpdG8lMjB3cmFwLTY1NzU4ZmY2MTk4NDQxOWFmYWNhOTI3MS5qcGc=",
        productCalories: 464,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f48d",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g\nChicken with our special sauce and pickled cucumber wrapped in tortilla.\n\nGood source of Protein",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:24:43.641Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:25:20.673Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:26:51.021Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:38:23.615Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:38:23.615Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:38:40.481Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:07:33.303Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:24:43.641Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:24:55.247Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:24:43.641Z",
    updatedAt: "2024-07-03T09:07:33.821Z",
  },
  {
    id: "66850a8409647a7c33ca451a",
    sid: "240703-798006",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "66850a8309647a7c33ca450e",
    firstName: "Sulaiman Alkandari",
    lastName: null,
    phoneNumber: "+96555571115",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c53c5975a6aa442d0f858b",
    delivery: {
      id: "66850a8309647a7c33ca450e",
      customerId: "64c53c5975a6aa442d0f858b",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64e453aae4583466bfcb27d6",
        name: "Work",
        lng: 47.974817398935556,
        lat: 29.371376528639587,
        city: "Al Kuwayt",
        area: "Jibla",
        block: "10",
        street: "Ahmad Al Jaber St",
        building: "Baitak Tower",
        floor: "4",
        flat: "",
        phone: "+96555571115",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-22T06:20:26.752Z",
        updatedAt: "2024-04-30T09:06:29.073Z",
      },
      deliverectValidationId: "66850a835972f622b1c9994c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:33:31.593Z",
      estimatedDeliveryTime: "2024-07-03T09:10:30.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66850a8409647a7c33ca451a",
      createdAt: "2024-07-03T08:23:31.684Z",
      updatedAt: "2024-07-03T08:23:32.461Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f47b",
        productPLU: "2161021-1",
        productDescription:
          "C 16g| P 15g| F 16g\nEggs, turkish soudjouk, muhammara & labneh\n\nGOOD SOURCE OF PROTEIN",
        productName: "Turkish Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/-B_481BG16xkyYtWQaVbIvD-9tkBN12twZodyhjhhEA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2lzaCUyMGJyZWFrZmFzdCUyMHRhY29zKDEpLTY1NTQ4ZjU3YWI3MmIwMDAyY2I5ZTMyNi5wbmc=",
        productCalories: 268,
        quantity: 1,
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f4df",
        productPLU: "2202040-1",
        productDescription: "C 4.8g | P 0g | F 0g\nOrange, Grapefruit & Lemon",
        productName: "Immunity Shot",
        imageURL:
          "https://resizer.deliverect.com/WkO7ZBCzCZBVTRau0FCMoAizLyoqJmikHQl5iJRWhJs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaW1tdW5pdHklMjBzaG90LTY2NDFkYjEwYWIxMzE0ZDg1Njc3OGNkZS5wbmc=",
        productCalories: 19,
        quantity: 1,
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f4a9",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 490,
    totalFmcCentsPrice: 490,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:23:32.441Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:24:24.637Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:26:47.079Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:38:41.569Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:38:41.570Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:41:02.377Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T08:58:55.323Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:23:32.441Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:23:44.024Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:23:32.441Z",
    updatedAt: "2024-07-03T08:58:55.662Z",
  },
  {
    id: "66850a4309647a7c33ca3e8d",
    sid: "240703-010064",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "66850a4209647a7c33ca3e84",
    firstName: "Noura Alaqeeli",
    lastName: null,
    phoneNumber: "+96599746615",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "667817ce8c15153ac8835c77",
    delivery: {
      id: "66850a4209647a7c33ca3e84",
      customerId: "667817ce8c15153ac8835c77",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "667929bcbed556ad8fa9eb51",
        name: "Work",
        lng: 47.95464064925909,
        lat: 29.318671115803266,
        city: "Shuwaikh Industrial",
        area: "Shuwaikh Industrial",
        block: "2",
        street: "Street 29",
        building: "2",
        floor: "",
        flat: "",
        phone: "+96599746615",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-24T08:09:32.716Z",
        updatedAt: "2024-07-02T07:29:00.575Z",
      },
      deliverectValidationId: "66850a42d4cdc4432a468aeb",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:32:26.739Z",
      estimatedDeliveryTime: "2024-07-03T09:09:25.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66850a4309647a7c33ca3e8d",
      createdAt: "2024-07-03T08:22:26.822Z",
      updatedAt: "2024-07-03T08:22:27.406Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f47c",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:22:27.382Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:23:16.194Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:23:40.256Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:39:03.355Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:39:03.357Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:40:27.573Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:02:05.408Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:22:27.382Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:22:38.932Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:22:27.382Z",
    updatedAt: "2024-07-03T09:02:05.750Z",
  },
  {
    id: "668509a909647a7c33ca2fc4",
    sid: "240703-830106",
    coupons: [],
    menuId: "6684c96209647a7c33c4f647",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "668509a909647a7c33ca2fba",
    firstName: "Noura Almutalaqem",
    lastName: null,
    phoneNumber: "+96555992121",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64f798c8ec522879c1af7ace",
    delivery: {
      id: "668509a909647a7c33ca2fba",
      customerId: "64f798c8ec522879c1af7ace",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6599324cafe41535d394e367",
        name: "Home",
        lng: 48.090975657105446,
        lat: 29.144054905829485,
        city: "Hadiya",
        area: "Hadiya",
        block: "4",
        street: "7",
        building: "26",
        floor: "",
        flat: "",
        phone: "+96555992121",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-06T10:58:20.179Z",
        updatedAt: "2024-07-01T19:11:15.726Z",
      },
      deliverectValidationId: "668509a990cd3eacd5ee0962",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:29:53.054Z",
      estimatedDeliveryTime: "2024-07-03T09:06:52.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668509a909647a7c33ca2fc4",
      createdAt: "2024-07-03T08:19:53.145Z",
      updatedAt: "2024-07-03T08:19:53.831Z",
    },
    items: [
      {
        productId: "6684c96209647a7c33c4f675",
        productPLU: "2161012-1",
        productDescription:
          "C 37g| P 22g| F 22g\nLabneh with 2 fried eggs and spicy oil served with sour dough toast.\n\nGood source of Protein",
        productName: "Chilli Labneh Eggs",
        imageURL:
          "https://resizer.deliverect.com/Tvq3YPeZcza5ZviOlAoE1mwErVO51WvoA54fcBDtuks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZWdnJTIwbGFibmVoJTIwYnJlYWtraWUlMjAoMSktNjYzNjAwZWI0MjIxOWQ1ZWY5MjViZjRlLmpwZw==",
        productCalories: 434,
        quantity: 1,
        fmcCentsPrice: 180,
        fiatCentsPrice: 180,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96209647a7c33c4f6a7",
        productPLU: "2165003-1",
        productDescription: "C 12g | P 2g | F 9g\n",
        productName: "Peanut Bites",
        imageURL:
          "https://resizer.deliverect.com/S2e9SlQ_6ewwX1Z-Pg_RtXB9e4OxquLa6-xOxnwoCJM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTMtNjRiN2JjNmFkMGE4N2MwMDFmZTYwZjBhLnBuZw==",
        productCalories: 139,
        quantity: 1,
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:19:53.812Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:20:42.050Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:21:41.535Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:28:59.635Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:28:59.635Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:40:38.905Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T09:03:47.315Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:19:53.812Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:20:05.481Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:19:53.812Z",
    updatedAt: "2024-07-03T09:03:47.700Z",
  },
  {
    id: "6685095c09647a7c33ca2731",
    sid: "240703-141184",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6685095c09647a7c33ca2725",
    firstName: "Yousef almulla",
    lastName: null,
    phoneNumber: "+96597773307",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "666195cc0eba0d08a57bf960",
    delivery: {
      id: "6685095c09647a7c33ca2725",
      customerId: "666195cc0eba0d08a57bf960",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6683b70c09647a7c339118e4",
        name: "Kuwait Investment Authority",
        lng: 47.97936909999999,
        lat: 29.3821443,
        city: "مدينة",
        area: "Sharq",
        block: "1",
        street: "Street 201",
        building: "900028",
        floor: "",
        flat: "",
        phone: "+96597773307",
        additionalInfo: "Gate Number 2 ",
        isSelected: true,
        createdAt: "2024-07-02T08:15:08.062Z",
        updatedAt: "2024-07-03T08:17:25.633Z",
      },
      deliverectValidationId: "6685095c16a4c9b8a5fecaab",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:28:36.146Z",
      estimatedDeliveryTime: "2024-07-03T09:05:34.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6685095c09647a7c33ca2731",
      createdAt: "2024-07-03T08:18:36.228Z",
      updatedAt: "2024-07-03T08:18:37.010Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f49f",
        productPLU: "2166029-1",
        productDescription:
          "C 16g| P 3g| F 1g\nGrilled corn\n\nGOOD SOURCE OF VITAMIN B6",
        productName: "Grilled Corn",
        imageURL:
          "https://resizer.deliverect.com/bzZU2HyEukivHUCvK9HB6Fq74sQULhtryjUuDiuVkPc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDQtNjUyOWY0MDFkZDMxNzAwMGJhZmIwOWE4LmpwZw==",
        productCalories: 83,
        quantity: 1,
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f4a2",
        productPLU: "2166017-1",
        productDescription:
          "C 16g| P 8g| F 11g\nCarrots, cucumber, red capsicum , celery with a hummus dip\n\nGood source of Vitamins & Dietary fibre",
        productName: "Veggie Crudites",
        imageURL:
          "https://resizer.deliverect.com/I3FIyxNKPcOIzVfjKga2oVRpt99xbhQflVKJw27qrWM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjA5Xy02NTA4MzM1ZjM5NGU3ODMxZTFiZDMyMDYucG5n",
        productCalories: 195,
        quantity: 1,
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f47b",
        productPLU: "2161021-1",
        productDescription:
          "C 16g| P 15g| F 16g\nEggs, turkish soudjouk, muhammara & labneh\n\nGOOD SOURCE OF PROTEIN",
        productName: "Turkish Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/-B_481BG16xkyYtWQaVbIvD-9tkBN12twZodyhjhhEA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2lzaCUyMGJyZWFrZmFzdCUyMHRhY29zKDEpLTY1NTQ4ZjU3YWI3MmIwMDAyY2I5ZTMyNi5wbmc=",
        productCalories: 268,
        quantity: 1,
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 450,
    totalFmcCentsPrice: 450,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:18:36.989Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:19:27.517Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:23:35.003Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:30:08.479Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:30:08.480Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:30:30.576Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T08:57:13.617Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:18:36.989Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:18:48.567Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:18:36.989Z",
    updatedAt: "2024-07-03T08:57:14.025Z",
  },
  {
    id: "6685090209647a7c33ca2300",
    sid: "240703-420804",
    coupons: [],
    menuId: "6684c96309647a7c33c4f840",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6685090109647a7c33ca22f6",
    firstName: "Khaled Alkhaled",
    lastName: null,
    phoneNumber: "+96550555566",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66373cfc89ba1a51b12be40e",
    delivery: {
      id: "6685090109647a7c33ca22f6",
      customerId: "66373cfc89ba1a51b12be40e",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66373df58b0d42e9ca4d4e4d",
        name: "Home",
        lng: 47.97099441289902,
        lat: 29.31639240168588,
        city: "Kuwait City",
        area: "Yarmouk",
        block: "1",
        street: "1",
        building: "21",
        floor: "",
        flat: "",
        phone: "+96550555566",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-05T08:06:13.233Z",
        updatedAt: "2024-07-02T12:50:17.650Z",
      },
      deliverectValidationId: "66850901b7f37d5af29ea300",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:27:05.666Z",
      estimatedDeliveryTime: "2024-07-03T09:04:04.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6685090209647a7c33ca2300",
      createdAt: "2024-07-03T08:17:05.749Z",
      updatedAt: "2024-07-03T08:17:06.203Z",
    },
    items: [
      {
        productId: "6684c96309647a7c33c4f86c",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 2,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:17:06.182Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:17:50.399Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:28:36.343Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:31:45.586Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:31:45.586Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:32:11.001Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T08:47:20.214Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:17:06.182Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:17:17.627Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:17:06.182Z",
    updatedAt: "2024-07-03T08:47:20.560Z",
  },
  {
    id: "668508f309647a7c33ca21e3",
    sid: "240703-582392",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "668508f209647a7c33ca21d9",
    firstName: "Sabeeka ALMUBARAK ",
    lastName: null,
    phoneNumber: "+96567671144",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6683eaa88c15153ac8a1a554",
    delivery: {
      id: "668508f209647a7c33ca21d9",
      customerId: "6683eaa88c15153ac8a1a554",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6683ec7509647a7c33a01d7f",
        name: "Home",
        lng: 47.99009224399924,
        lat: 29.345057867658007,
        city: "Kuwait",
        area: "Nuzha",
        block: "1",
        street: "15",
        building: "3",
        floor: "",
        flat: "",
        phone: "+96567671144",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-02T12:03:01.507Z",
        updatedAt: "2024-07-02T12:03:01.507Z",
      },
      deliverectValidationId: "668508f216a4c9b8a5feb460",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:26:50.470Z",
      estimatedDeliveryTime: "2024-07-03T09:03:49.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668508f309647a7c33ca21e3",
      createdAt: "2024-07-03T08:16:50.557Z",
      updatedAt: "2024-07-03T08:16:51.102Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f47a",
        productPLU: "2166004-1",
        productDescription:
          "C 60g | P 9g | F 4g\n2 Pcs vanilla protein pancake with maple syrup\n\nGood Source of protein & Vitamins.",
        productName: "Vanilla Pancake",
        imageURL:
          "https://resizer.deliverect.com/3F1kHUeH6dH4pskh42lWXLmxolWCndanYZSf8qGeUeI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGFuY2FrZS02NWY2ZWE5YWE2OGE3NjU2YWUwNjgyOGYucG5n",
        productCalories: 312,
        quantity: 1,
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f490",
        productPLU: "2167004-1",
        productDescription:
          "C 43g| P 16g| F 19g\nLight halloumi & black olives with zaatar pesto & ranch in multigrain bread. \n\nGood source of calcium & protein",
        productName: "Halloumi Sandwich",
        imageURL:
          "https://resizer.deliverect.com/kxZW-LHRBXZhOao_mcBsk7OjD7py5CcDqaceAnZntvQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaGFsbG91bWktNjRiN2I2NDZlNGQ1YzMwMDE4YjY1YjZkLnBuZw==",
        productCalories: 407,
        quantity: 1,
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:16:51.082Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:17:40.234Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:18:40.485Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:29:21.297Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:29:21.297Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:37:34.662Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T08:48:46.464Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:16:51.082Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:17:02.578Z",
      },
    ],
    cutlery: true,
    hasRating: true,
    timeslot: null,
    createdAt: "2024-07-03T08:16:51.082Z",
    updatedAt: "2024-07-03T09:16:48.589Z",
  },
  {
    id: "668508cf09647a7c33ca1eb8",
    sid: "240703-330837",
    coupons: [],
    menuId: "6684c96309647a7c33c4f840",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "668508ce09647a7c33ca1ea4",
    firstName: "Jenan alsultan",
    lastName: null,
    phoneNumber: "+96595539362",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "652672441bfa57c5dbf498e2",
    delivery: {
      id: "668508ce09647a7c33ca1ea4",
      customerId: "652672441bfa57c5dbf498e2",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "654607adf91376d3946a4674",
        name: "Jenan",
        lng: 47.998186647892,
        lat: 29.280475297975286,
        city: "Zahra",
        area: "Zahra",
        block: "5",
        street: "42 mijren alhamad St",
        building: "62",
        floor: "",
        flat: "",
        phone: "+96595539362",
        additionalInfo: "Main street ",
        isSelected: true,
        createdAt: "2023-11-04T08:58:21.456Z",
        updatedAt: "2024-06-28T07:07:37.522Z",
      },
      deliverectValidationId: "668508ce258eb377f7ed7616",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:26:14.124Z",
      estimatedDeliveryTime: "2024-07-03T09:03:12.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668508cf09647a7c33ca1eb8",
      createdAt: "2024-07-03T08:16:14.214Z",
      updatedAt: "2024-07-03T08:16:15.091Z",
    },
    items: [
      {
        productId: "6684c96309647a7c33c4f8c5",
        productPLU: "2202036-1",
        productDescription:
          "C 20g | P 19.2g | F 4.7g\nCocoa powder, Milk, Stevia & WHEY Protein",
        productName: "Chocolate Protein Shake",
        imageURL:
          "https://resizer.deliverect.com/TjoR9Z9GyFIBSfx0VgVYXmRJcSGxnQwDMGEZg2k_4G4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hvY29sYXRlJTIwcHJvdGVpbiUyMHNoYWtlLTY2NDFkYWJmMGMyNGQ5MjVmY2QzZmE4Yi5wbmc=",
        productCalories: 200,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96309647a7c33c4f869",
        productPLU: "2161021-1",
        productDescription:
          "C 16g| P 15g| F 16g\nEggs, turkish soudjouk, muhammara & labneh\n\nGOOD SOURCE OF PROTEIN",
        productName: "Turkish Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/-B_481BG16xkyYtWQaVbIvD-9tkBN12twZodyhjhhEA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2lzaCUyMGJyZWFrZmFzdCUyMHRhY29zKDEpLTY1NTQ4ZjU3YWI3MmIwMDAyY2I5ZTMyNi5wbmc=",
        productCalories: 268,
        quantity: 1,
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 410,
    totalFmcCentsPrice: 410,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:16:15.072Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:17:05.520Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:29:21.044Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:33:37.536Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:33:37.536Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:34:42.018Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T08:48:25.679Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:16:15.072Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:16:26.647Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:16:15.072Z",
    updatedAt: "2024-07-03T08:48:26.024Z",
  },
  {
    id: "6685088509647a7c33ca1949",
    sid: "240703-470503",
    coupons: [],
    menuId: "6684c96209647a7c33c4f647",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6685088509647a7c33ca193f",
    firstName: "Maha Alghanim ",
    lastName: null,
    phoneNumber: "+96596661235",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65781b227a1e4e4900f0e41e",
    delivery: {
      id: "6685088509647a7c33ca193f",
      customerId: "65781b227a1e4e4900f0e41e",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65781df0f093f32458139a45",
        name: "Al hajeri house",
        lng: 48.07243086397648,
        lat: 29.261847621544884,
        city: "Sabah Al Salem",
        area: "Sabah Al Salem",
        block: "4",
        street: "31 St",
        building: "House  9",
        floor: "",
        flat: "",
        phone: "+96596661235",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-12T08:46:40.501Z",
        updatedAt: "2023-12-12T08:46:40.501Z",
      },
      deliverectValidationId: "66850885e0ae9e25feb080ea",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T08:25:01.330Z",
      estimatedDeliveryTime: "2024-07-03T09:01:58.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6685088509647a7c33ca1949",
      createdAt: "2024-07-03T08:15:01.438Z",
      updatedAt: "2024-07-03T08:15:01.962Z",
    },
    items: [
      {
        productId: "6684c96209647a7c33c4f69d",
        productPLU: "2166008-1",
        productDescription:
          "C 30g| P 13g| F 16g\nGreek yogurt with peanut butter, raspberry, blueberry, blackberry topped with honey and granola.\n\nGood source of Vitamins",
        productName: "Berry Parfait",
        imageURL:
          "https://resizer.deliverect.com/0p2ShlK1ih7A2WUCAl2RWW19NH0UKIi5XafIY_FXOQc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NTExNmVkYTVkMWVjODc5MjQ0NDE3N2UuanBn",
        productCalories: 316,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96209647a7c33c4f6b5",
        productPLU: "2202052-1",
        productDescription:
          "C 13g | P 0g | F 0g\nPomegranate, lemon\n\nGood source of Vitamin C",
        productName: "Pomade",
        imageURL:
          "https://resizer.deliverect.com/JpImLqr5JfNLPk8gTnl6NoZd9i6mdIhqPB4QA7H5lw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEtNjY1OTFhNzgxNmEzMzQyNTY5OGVhM2QxLnBuZw==",
        productCalories: 54,
        quantity: 1,
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 360,
    totalFmcCentsPrice: 360,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:15:01.941Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T08:15:51.891Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T08:21:47.515Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T08:22:25.211Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T08:22:25.211Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T08:35:44.112Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T08:53:50.327Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T08:15:01.941Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T08:15:13.437Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T08:15:01.941Z",
    updatedAt: "2024-07-03T08:53:50.672Z",
  },
  {
    id: "6684fbaa09647a7c33c92399",
    sid: "240703-872433",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6684fba909647a7c33c9238e",
    firstName: "Ahmed AlSaleh ",
    lastName: null,
    phoneNumber: "+96597790981",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "652835561bfa57c5dbf6b1f1",
    delivery: {
      id: "6684fba909647a7c33c9238e",
      customerId: "652835561bfa57c5dbf6b1f1",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6656d615ecc6fd38a64e5a11",
        name: "Work",
        lng: 47.94843167066574,
        lat: 29.34862651975422,
        city: "Kuwait",
        area: "Shuwaikh Administrative",
        block: "6",
        street: "Abdulrahman AlBader St",
        building: "Second gate",
        floor: "",
        flat: "",
        phone: "+96597790981",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-29T07:15:33.215Z",
        updatedAt: "2024-06-30T08:08:12.031Z",
      },
      deliverectValidationId: "6684fba9258eb377f7e940fc",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T07:30:09.500Z",
      estimatedDeliveryTime: "2024-07-03T08:07:08.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684fbaa09647a7c33c92399",
      createdAt: "2024-07-03T07:20:09.591Z",
      updatedAt: "2024-07-03T07:20:10.297Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f49c",
        productPLU: "2166031-1",
        productDescription:
          "C 32g| P 12g| F 12g\nBaked sweet potato fries with mayo barbeque sauce.",
        productName: "Baked Sweet Potato Fries",
        imageURL:
          "https://resizer.deliverect.com/EexbDtzbKG0sVOjjNXiIlT_MIN2doyc2NlpJu2_T6nc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3dlZXQlMjBwb3RhdG8lMjBmcmllcygxKS02NTU0OGY3NzYyYWIwZTAwMWYzYTczNzEucG5n",
        productCalories: 244,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f48e",
        productPLU: "2167009-1",
        productDescription:
          "C 40g| P 34g| F 20g\nRoast beef, arugula, caramelized onions, american cheese & mozarella cheese.\n\n*GOOD SOURCE OF PROTEIN,VITAMINB12 *",
        productName: "Steak Beef Sandwich",
        imageURL:
          "https://resizer.deliverect.com/DtNUTtvxCtTpYduIHX1EtM3gopyjoTP3-HuOderSW-c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU1RFQUslMjBTQU5EV0hJQ0gtNjU3NTkwMGE0OGU3YWFhNzJiYzFjNDY5LnBuZw==",
        productCalories: 476,
        quantity: 1,
        fmcCentsPrice: 330,
        fiatCentsPrice: 330,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 480,
    totalFmcCentsPrice: 480,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:20:10.272Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T07:20:33.860Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T07:25:35.282Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T07:31:17.088Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T07:31:17.089Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T07:31:39.051Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T07:42:19.976Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:20:10.272Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T07:20:21.782Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T07:20:10.272Z",
    updatedAt: "2024-07-03T07:42:20.319Z",
  },
  {
    id: "6684fb4f09647a7c33c91eff",
    sid: "240703-828781",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6684fb4e09647a7c33c91eae",
    firstName: " Aisha ahmed",
    lastName: null,
    phoneNumber: "+96599800893",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65119113e1d91353418ef120",
    delivery: {
      id: "6684fb4e09647a7c33c91eae",
      customerId: "65119113e1d91353418ef120",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65e8553f5511d0a569490d62",
        name: "Mansouriya",
        lng: 47.98957910388708,
        lat: 29.35898461200002,
        city: "Al Kuwayt",
        area: "Mansouriya",
        block: "1",
        street: "14 St",
        building: "12",
        floor: "",
        flat: "",
        phone: "+96599800893",
        additionalInfo: "White and grey house at the corner ",
        isSelected: true,
        createdAt: "2024-03-06T11:36:31.491Z",
        updatedAt: "2024-06-06T11:47:31.286Z",
      },
      deliverectValidationId: "6684fb4edcfdab957350a8a6",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T07:28:38.687Z",
      estimatedDeliveryTime: "2024-07-03T08:05:36.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684fb4f09647a7c33c91eff",
      createdAt: "2024-07-03T07:18:38.978Z",
      updatedAt: "2024-07-03T07:18:39.895Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f485",
        productPLU: "2167023-1",
        productDescription:
          "C 29g | P 22g | F 16g\nScrambled eggs with mushroom, green olives and feta cheese in a tortilla wrap\n\nGOOD SOURCE OF PROTEIN, MINERALS",
        productName: "Mushroom egg wrap",
        imageURL:
          "https://resizer.deliverect.com/icCk6VyiEI1LioDbIHa0TjQikuisfIyU0FZUoDvsxdY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAyLTY2M2ZmM2Q0ZGI0ZjMyZjZhNTZmMGY4MC5wbmc=",
        productCalories: 348,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f4dd",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:18:39.859Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T07:19:42.316Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T07:20:03.828Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T07:26:44.565Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T07:26:44.565Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T07:27:06.413Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T07:43:56.497Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:18:39.859Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T07:19:00.917Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T07:18:39.859Z",
    updatedAt: "2024-07-03T07:43:56.849Z",
  },
  {
    id: "6684fb4d09647a7c33c91deb",
    sid: "240703-913153",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6684fb4c09647a7c33c91cb2",
    firstName: "Aishah Burabba ",
    lastName: null,
    phoneNumber: "+96597949995",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66763cd08c15153ac87f7718",
    delivery: {
      id: "6684fb4c09647a7c33c91cb2",
      customerId: "66763cd08c15153ac87f7718",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "668104ba86fe4b6ad1aed51b",
        name: "Work ",
        lng: 47.98115418677363,
        lat: 29.378860473632812,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "6",
        street: "Ahmad Al Jaber St",
        building: "Department for Legal Advice and Legislation",
        floor: "",
        flat: "",
        phone: "+96597949995",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-30T07:09:46.538Z",
        updatedAt: "2024-07-03T07:02:37.724Z",
      },
      deliverectValidationId: "6684fb4b85b4e122cec9f070",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T07:28:35.652Z",
      estimatedDeliveryTime: "2024-07-03T08:05:33.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684fb4d09647a7c33c91deb",
      createdAt: "2024-07-03T07:18:36.059Z",
      updatedAt: "2024-07-03T07:18:37.994Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f47c",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f4b2",
        productPLU: "2165003-1",
        productDescription: "C 12g | P 2g | F 9g\n",
        productName: "Peanut Bites",
        imageURL:
          "https://resizer.deliverect.com/S2e9SlQ_6ewwX1Z-Pg_RtXB9e4OxquLa6-xOxnwoCJM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTMtNjRiN2JjNmFkMGE4N2MwMDFmZTYwZjBhLnBuZw==",
        productCalories: 139,
        quantity: 1,
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 320,
    totalFmcCentsPrice: 320,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:18:37.974Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T07:19:07.781Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T07:19:07.795Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T07:30:54.659Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T07:30:54.659Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T07:38:05.361Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T07:52:34.383Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:18:37.974Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T07:18:49.456Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T07:18:37.974Z",
    updatedAt: "2024-07-03T07:52:34.875Z",
  },
  {
    id: "6684faf409647a7c33c91415",
    sid: "240703-985331",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6684faf409647a7c33c913a5",
    firstName: "jana alkhulaifi",
    lastName: null,
    phoneNumber: "+96569981122",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65195f06c6da5aa4aee41552",
    delivery: {
      id: "6684faf409647a7c33c913a5",
      customerId: "65195f06c6da5aa4aee41552",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65ad3faf1c5f840f7cd5e083",
        name: "MOF",
        lng: 47.9765302,
        lat: 29.3652884,
        city: "Al Kuwayt",
        area: "Mirqab",
        block: "1",
        street: "Alwazzan",
        building: "Gate 6",
        floor: "G",
        flat: "",
        phone: "+96569981122",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-21T16:00:47.248Z",
        updatedAt: "2024-07-02T06:06:21.501Z",
      },
      deliverectValidationId: "6684faf3ec2cf6c6a409e7a0",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T07:27:07.971Z",
      estimatedDeliveryTime: "2024-07-03T08:04:06.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684faf409647a7c33c91415",
      createdAt: "2024-07-03T07:17:08.077Z",
      updatedAt: "2024-07-03T07:17:08.840Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f488",
        productPLU: "2162079-1",
        productDescription:
          "C 63g| P 24g| F 9g\nBaked cauliflower with  eggplant, potato, cucumber and falafel drizzled with tahini sauce in saj bread.\n\nGood Source Of Protein And Fiber",
        productName: "Mushakal Wrap",
        imageURL:
          "https://resizer.deliverect.com/GAAYXxN6zgWfrJVNB3YeUO6Fr9gMhYKrP5SEQdiGD-s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaGFrZWwlMjB3cmFwLTY1NzU4ZmRlYjAxYTgyMzljNzk1ZmE2My5qcGc=",
        productCalories: 429,
        quantity: 1,
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        remark: "",
        subItems: [
          {
            productId: "6684c96009647a7c33c4f4e8",
            productPLU: "1907038-1",
            productName: "No Cauliflower",
            productDescription: "C -2.6g| P -1g| F -1g| cal -17| coins 0",
            productCalories: -17,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
          },
          {
            productId: "6684c96009647a7c33c4f4e9",
            productPLU: "1907039-1",
            productName: "No Eggplant",
            productDescription: "C -2.6g| P -0.4g| F -0.6g| cal -16| coins 0",
            productCalories: -16,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
          },
        ],
      },
      {
        productId: "6684c96009647a7c33c4f49e",
        productPLU: "2166037-1",
        productDescription:
          "C 31g | P 3g | F 8g [%New%]\nTortilla chips, cheddar cheese, & pickled jalapeños. ",
        productName: "Jalapeño Cheddar Nachos ",
        imageURL:
          "https://resizer.deliverect.com/Igtv_X3IIKdvVYw3drGOS6DlDQf47bmEkgol0b9fBZ0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFsYXBlbm8lMjBjaGVlc2UlMjBuYWNob3MtNjY2MWNkZWQ5YWNlYTFmMWZlZWVkOTU5LnBuZw==",
        productCalories: 208,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:17:08.820Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T07:17:38.595Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T07:17:38.595Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T07:28:48.588Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T07:28:48.589Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T07:29:10.463Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T07:42:08.708Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:17:08.820Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T07:17:20.290Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T07:17:08.821Z",
    updatedAt: "2024-07-03T07:42:09.110Z",
  },
  {
    id: "6684facb09647a7c33c90f83",
    sid: "240703-567841",
    coupons: [],
    menuId: "6684c96309647a7c33c4f840",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6684facb09647a7c33c90f78",
    firstName: "Samaa alzamel",
    lastName: null,
    phoneNumber: "+96597427970",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d9f1ccdfecd0088b530979",
    delivery: {
      id: "6684facb09647a7c33c90f78",
      customerId: "64d9f1ccdfecd0088b530979",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66042f3cbb94678303edf46e",
        name: "Home 🤍",
        lng: 47.966631799936295,
        lat: 29.31091706710166,
        city: "Kuwait",
        area: "Yarmouk",
        block: "4",
        street: "Ahmad althaqeb",
        building: "436",
        floor: "1",
        flat: "1",
        phone: "+96597427970",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-03-27T14:37:48.809Z",
        updatedAt: "2024-06-06T06:15:38.638Z",
      },
      deliverectValidationId: "6684facbe0ae9e25feab46b2",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T07:26:27.059Z",
      estimatedDeliveryTime: "2024-07-03T08:03:25.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684facb09647a7c33c90f83",
      createdAt: "2024-07-03T07:16:27.141Z",
      updatedAt: "2024-07-03T07:16:27.879Z",
    },
    items: [
      {
        productId: "6684c96309647a7c33c4f870",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96309647a7c33c4f87b",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g\nChicken with our special sauce and pickled cucumber wrapped in tortilla.\n\nGood source of Protein",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 2,
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:16:27.774Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T07:16:47.557Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T07:18:14.759Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T07:28:26.094Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T07:28:26.094Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T07:29:21.726Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T07:39:27.934Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:16:27.774Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T07:16:39.324Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-07-03T07:16:27.774Z",
    updatedAt: "2024-07-03T07:39:28.285Z",
  },
  {
    id: "6684faa109647a7c33c90920",
    sid: "240703-743747",
    coupons: [],
    menuId: "6684c96509647a7c33c4fa3c",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6684faa009647a7c33c90918",
    firstName: "Hind Al Gharaballi",
    lastName: null,
    phoneNumber: "+96594466880",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65d8977aa56b1a242b9a96cd",
    delivery: {
      id: "6684faa009647a7c33c90918",
      customerId: "65d8977aa56b1a242b9a96cd",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65d8e1de9ca941ee826e156b",
        name: "Home",
        lng: 48.080747620378396,
        lat: 29.3194580078125,
        city: "Rumaithiya",
        area: "Rumaithiya",
        block: "7",
        street: "70",
        building: "9",
        floor: "",
        flat: "",
        phone: "+96594466880",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-02-23T18:20:14.777Z",
        updatedAt: "2024-06-30T06:08:43.021Z",
      },
      deliverectValidationId: "6684faa090cd3eacd5e5f070",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T07:25:44.568Z",
      estimatedDeliveryTime: "2024-07-03T08:02:43.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684faa109647a7c33c90920",
      createdAt: "2024-07-03T07:15:44.661Z",
      updatedAt: "2024-07-03T07:15:45.102Z",
    },
    items: [
      {
        productId: "6684c96509647a7c33c4fa6c",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 2,
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 260,
    totalFmcCentsPrice: 260,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:15:45.078Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T07:16:30.075Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T07:16:43.032Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T07:32:48.718Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T07:32:48.718Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T07:36:35.113Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T07:58:27.448Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:15:45.078Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T07:15:56.797Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T07:15:45.078Z",
    updatedAt: "2024-07-03T07:58:27.790Z",
  },
  {
    id: "6684fa9309647a7c33c907b4",
    sid: "240703-422604",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6684fa9209647a7c33c907a6",
    firstName: "Abdulrahman alkhamees ",
    lastName: null,
    phoneNumber: "+96551331969",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "659d7a7f2d5e4e99268ed76d",
    delivery: {
      id: "6684fa9209647a7c33c907a6",
      customerId: "659d7a7f2d5e4e99268ed76d",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65c990fd106ad03db14eb945",
        name: "Benid algar dental speciality center",
        lng: 48.00333531573415,
        lat: 29.373301014993405,
        city: "Kuwait",
        area: "Bnied Al-Gar",
        block: "1",
        street: "81 st",
        building: "Floor 1",
        floor: "",
        flat: "",
        phone: "+96551331969",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-02-12T03:31:09.755Z",
        updatedAt: "2024-07-03T07:14:54.717Z",
      },
      deliverectValidationId: "6684fa92fcba48c1e607e134",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T07:25:30.535Z",
      estimatedDeliveryTime: "2024-07-03T08:02:29.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684fa9309647a7c33c907b4",
      createdAt: "2024-07-03T07:15:30.621Z",
      updatedAt: "2024-07-03T07:15:31.368Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f47e",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f481",
        productPLU: "2161013-1",
        productDescription:
          "C 28g| P 22g| F 21g\n2 fried eggs with avocado and beef sausage served with sour dough toast.\n\nGood source of Protein & healthy fat",
        productName: "Egg Avo Platter",
        imageURL:
          "https://resizer.deliverect.com/r_ObTbJI3AoU9w6PFaC93-52m67nkc-am15KRZoSW6I/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRWdnJTIwYXZvY2FkbyUyMGJyZWFra2llJTIwKDEpLTY2MzYwMTA4Y2JiMDVhYTc5N2MyNjg4MS5qcGc=",
        productCalories: 389,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 390,
    totalFmcCentsPrice: 390,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:15:31.307Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T07:16:18.606Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T07:16:18.606Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T07:30:20.563Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T07:30:20.564Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T07:32:26.248Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T07:41:02.447Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:15:31.307Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T07:15:42.807Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T07:15:31.307Z",
    updatedAt: "2024-07-03T07:41:02.845Z",
  },
  {
    id: "6684fa2009647a7c33c8fe3d",
    sid: "240703-566712",
    coupons: [],
    menuId: "6684c96009647a7c33c4f450",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6684fa2009647a7c33c8fe33",
    firstName: "Aisha Alnashmi",
    lastName: null,
    phoneNumber: "+96599102266",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "655313dd6fe598ae84f3f327",
    delivery: {
      id: "6684fa2009647a7c33c8fe33",
      customerId: "655313dd6fe598ae84f3f327",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "665d610f93dc7e98b4c3db6a",
        name: "Kuwait supply company ",
        lng: 47.93665140867233,
        lat: 29.34594451923504,
        city: "Shuwaikh Industrial",
        area: "Shuwaikh Industrial 1",
        block: "1",
        street: "Jamal abdulnasser streer",
        building: "Kuwaot supply company ",
        floor: "",
        flat: "",
        phone: "+96599102266",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-03T06:22:07.635Z",
        updatedAt: "2024-07-03T04:26:41.731Z",
      },
      deliverectValidationId: "6684fa20d95f84b0b2a0deed",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T07:23:36.197Z",
      estimatedDeliveryTime: "2024-07-03T08:00:34.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684fa2009647a7c33c8fe3d",
      createdAt: "2024-07-03T07:13:36.286Z",
      updatedAt: "2024-07-03T07:13:36.863Z",
    },
    items: [
      {
        productId: "6684c96009647a7c33c4f4ad",
        productPLU: "2202034-1",
        productDescription: "C 17.1g | P 1.9g | F 0.6g\nPomelo & Pomegranate",
        productName: "Seasonal Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/XTwC1dVyhg5--YDaNmhMWBtNNZpa3e6lzIaYdRmZhDA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2Vhc29uYWwlMjBmcnVpdCUyMHNhbGFkLTY2NDFkYmY5YWIxMzE0ZDg1Njc3OGRhNS5wbmc=",
        productCalories: 82,
        quantity: 1,
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96009647a7c33c4f481",
        productPLU: "2161013-1",
        productDescription:
          "C 28g| P 22g| F 21g\n2 fried eggs with avocado and beef sausage served with sour dough toast.\n\nGood source of Protein & healthy fat",
        productName: "Egg Avo Platter",
        imageURL:
          "https://resizer.deliverect.com/r_ObTbJI3AoU9w6PFaC93-52m67nkc-am15KRZoSW6I/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRWdnJTIwYXZvY2FkbyUyMGJyZWFra2llJTIwKDEpLTY2MzYwMTA4Y2JiMDVhYTc5N2MyNjg4MS5qcGc=",
        productCalories: 389,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "Don’t put oil ",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:13:36.842Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T07:13:54.909Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T07:14:02.443Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T07:30:09.707Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T07:30:09.708Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T07:31:05.763Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T07:51:07.166Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:13:36.842Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T07:13:48.412Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T07:13:36.842Z",
    updatedAt: "2024-07-03T07:51:07.504Z",
  },
  {
    id: "6684f9f209647a7c33c8f8d5",
    sid: "240703-341639",
    coupons: [],
    menuId: "6684c96509647a7c33c4fa3c",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6684f9f209647a7c33c8f815",
    firstName: "ghalia alghanim",
    lastName: null,
    phoneNumber: "+96596900991",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65a647522d5e4e992696032c",
    delivery: {
      id: "6684f9f209647a7c33c8f815",
      customerId: "65a647522d5e4e992696032c",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66786bc9bed556ad8f9c220c",
        name: "mishrf",
        lng: 48.047022968530655,
        lat: 29.281426702940678,
        city: "Mubarak Al-Abdullah",
        area: "Mubarak Al-Abdullah",
        block: "q",
        street: "115 St",
        building: "66",
        floor: "",
        flat: "",
        phone: "+96596900991",
        additionalInfo: "west mishref",
        isSelected: true,
        createdAt: "2024-06-23T18:39:05.263Z",
        updatedAt: "2024-06-23T18:39:05.263Z",
      },
      deliverectValidationId: "6684f9f185b4e122cec97821",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T07:22:49.727Z",
      estimatedDeliveryTime: "2024-07-03T07:59:48.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684f9f209647a7c33c8f8d5",
      createdAt: "2024-07-03T07:12:50.003Z",
      updatedAt: "2024-07-03T07:12:50.887Z",
    },
    items: [
      {
        productId: "6684c96509647a7c33c4fa8f",
        productPLU: "2166006-1",
        productDescription:
          "C 67g| P 8g| F 9g\nCinnamon chia whole oats with candied peanuts, peanut biscuit and peanut butter & lotus spread.\n\nGood source of Vitamins & Dietary fibre",
        productName: "Cinnamon Oats",
        imageURL:
          "https://resizer.deliverect.com/mJ-Xb-5qUQiDDKJOr4MGqGbDWxCn3gIddYpHwTQNiZo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NTExNmU5NTNmNWMzYTdmNDZlNGM2NDUuanBn",
        productCalories: 381,
        quantity: 1,
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96509647a7c33c4faa8",
        productPLU: "2202050-1",
        productDescription:
          "C 27g | P 1.5g | F 0g\nOrange \n\nGood source of Vitamin C",
        productName: "Orange Citrus",
        imageURL:
          "https://resizer.deliverect.com/92Cgq5w07iSWvqOzcojq0Egx0BDIxsVNTfIhlYhtUmU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTMtNjY1OTE5ODQ0MjllZTgwN2Q2OWQxYmZkLnBuZw==",
        productCalories: 116,
        quantity: 1,
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96509647a7c33c4fa9f",
        productPLU: "2165006-1",
        productDescription: "C 16g| P 6g| F 9g",
        productName: "Salted Peanut Bar",
        imageURL:
          "https://resizer.deliverect.com/Qu7qBz5pYpj9PPgLW8qkiYW3Mxp7m7H_vgxL4onEh8c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTEtNjRiN2JjOWU0NThjYTYzOGY4MmMzMDgxLnBuZw==",
        productCalories: 174,
        quantity: 1,
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:12:50.829Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T07:13:36.964Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T07:16:43.135Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T07:19:31.450Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T07:19:31.451Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T07:25:01.596Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T07:41:16.312Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:12:50.829Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T07:13:02.382Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T07:12:50.829Z",
    updatedAt: "2024-07-03T07:41:16.655Z",
  },
  {
    id: "6684f9db09647a7c33c8f6ff",
    sid: "240703-248286",
    coupons: [],
    menuId: "6684c96309647a7c33c4f840",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6684f9da09647a7c33c8f6f4",
    firstName: "Nouf Alqassar",
    lastName: null,
    phoneNumber: "+96599446680",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "651b9463c6da5aa4aee6fe06",
    delivery: {
      id: "6684f9da09647a7c33c8f6f4",
      customerId: "651b9463c6da5aa4aee6fe06",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65794ae6f093f32458194160",
        name: "Work",
        lng: 48.02118860185146,
        lat: 29.270559091971396,
        city: "Kuwait",
        area: "Ministries Zone",
        block: "0",
        street: "430",
        building: "Public institution for socail security",
        floor: "Second floor",
        flat: "",
        phone: "+96599446680",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-13T06:10:46.810Z",
        updatedAt: "2024-07-03T07:10:21.314Z",
      },
      deliverectValidationId: "6684f9da06a601cc357369a7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-03T07:22:26.454Z",
      estimatedDeliveryTime: "2024-07-03T07:59:25.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684f9db09647a7c33c8f6ff",
      createdAt: "2024-07-03T07:12:26.537Z",
      updatedAt: "2024-07-03T07:12:27.260Z",
    },
    items: [
      {
        productId: "6684c96309647a7c33c4f87f",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/1uWrRlXfTE6RYwQoa2vZDD0miTwao6DizisuqC3ZQlY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZWFiOGVjNDM1MDAxYTRjMGJkYy5qcGc=",
        productCalories: 497,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684c96309647a7c33c4f88e",
        productPLU: "2166020-1",
        productDescription:
          "C 13g| P 2g| F 1g\nBeetroots tossed in olive oil, lemon juice and fresh coriander.\n\nGood source of dietary Fibre, Iron, Potassium and Vitamin C\nContains garlic.",
        productName: "Beetroot Pot",
        imageURL:
          "https://resizer.deliverect.com/4QQmAm_YErxn11xFj_N8IRMBlHt61yeQWJR1VR9vwug/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNy02NTEwMzhmNDI2N2RiNTQwNTI2ZTM4MTguanBn",
        productCalories: 70,
        quantity: 1,
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:12:27.241Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-03T07:13:11.709Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-03T07:15:33.325Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-03T07:25:53.136Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-03T07:25:53.137Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-03T07:26:44.489Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-03T07:43:30.205Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-03T07:12:27.241Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-03T07:12:38.889Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-03T07:12:27.241Z",
    updatedAt: "2024-07-03T07:43:30.600Z",
  },
  {
    id: "668459c609647a7c33c1e821",
    sid: "240702-926600",
    coupons: [],
    menuId: "6684404c09647a7c33b69777",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "668459c609647a7c33c1e815",
    firstName: " Shaikhah almulla",
    lastName: null,
    phoneNumber: "+96560602314",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "652821061bfa57c5dbf69f50",
    delivery: {
      id: "668459c609647a7c33c1e815",
      customerId: "652821061bfa57c5dbf69f50",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65464a9df91376d3946c365b",
        name: "Home",
        lng: 47.9953321069479,
        lat: 29.287260350445298,
        city: "Kuwait",
        area: "Al-Siddeeq",
        block: "6",
        street: "609",
        building: "74B",
        floor: "",
        flat: "",
        phone: "+96560602314",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-04T13:43:57.464Z",
        updatedAt: "2024-01-12T08:41:07.813Z",
      },
      deliverectValidationId: "668459c6c77f4ba41d4b9ecb",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T19:59:26.361Z",
      estimatedDeliveryTime: "2024-07-02T20:36:25.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668459c609647a7c33c1e821",
      createdAt: "2024-07-02T19:49:26.450Z",
      updatedAt: "2024-07-02T19:49:26.977Z",
    },
    items: [
      {
        productId: "6684404c09647a7c33b69819",
        productPLU: "2162066-1",
        productDescription:
          "C 68g| P 39g| F 20g\nBeef shawarma served with egyptian rice, baked tomato drizzled with our shawarma sauce, cucumber pickle and sumac onion.\n\nGood source oF Vitamin B12",
        productName: "Beef Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/LMPlnG_aus0s_JuAVcljRBmuvv3t6907i0cmIgWlcb0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjYtNjRiN2I4MjMwZTYwOTUwMDE4YzUwNTA4LnBuZw==",
        productCalories: 608,
        quantity: 1,
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404c09647a7c33b69859",
        productPLU: "2202021-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Coca Cola Zero",
        imageURL:
          "https://resizer.deliverect.com/NvHctUF0z3us3Lhe9x5u3_mQIOOXnn8K3FvSZO6MkK4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCgxKS02NGM5MTk1MjZlY2Q3NzAwMjZjNGU1MDUuanBn",
        productCalories: 0,
        quantity: 1,
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:49:26.952Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T19:50:12.117Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T19:51:36.093Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T20:03:59.380Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T20:03:59.380Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T20:20:10.971Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T20:41:48.699Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:49:26.952Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T19:49:38.561Z",
      },
    ],
    cutlery: true,
    hasRating: true,
    timeslot: null,
    createdAt: "2024-07-02T19:49:26.952Z",
    updatedAt: "2024-07-02T21:21:59.708Z",
  },
  {
    id: "6684599d09647a7c33c1d716",
    sid: "240702-544200",
    coupons: [],
    menuId: "6684404c09647a7c33b69777",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6684599c09647a7c33c1d5dd",
    firstName: "Hamad Alibrahem ",
    lastName: null,
    phoneNumber: "+96596640495",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6667b3b47b408cf4f5d6c30d",
    delivery: {
      id: "6684599c09647a7c33c1d5dd",
      customerId: "6667b3b47b408cf4f5d6c30d",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6669f04bd8ab4a2ab7e70198",
        name: "Diwaniya",
        lng: 47.976862750947475,
        lat: 29.317856706658823,
        city: "Shuwaikh Industrial",
        area: "Qortuba",
        block: "4",
        street: "1 jada 5",
        building: "14",
        floor: "",
        flat: "",
        phone: "+96596640495",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-12T19:00:27.787Z",
        updatedAt: "2024-07-02T19:48:18.580Z",
      },
      deliverectValidationId: "6684599b22b56ec7d57484da",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T19:58:43.161Z",
      estimatedDeliveryTime: "2024-07-02T20:35:41.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684599d09647a7c33c1d716",
      createdAt: "2024-07-02T19:48:44.331Z",
      updatedAt: "2024-07-02T19:48:45.459Z",
    },
    items: [
      {
        productId: "6684404c09647a7c33b697d1",
        productPLU: "2162076-1",
        productDescription:
          "C 57g| P 32g| F 12g\nGrilled chicken, rice, corn, mixed beans sauce, iceburg lettuce, chedder cheese, mozarella cheese & ranch all wrapped in a tortilla wrap.\n\nGOOD SOURCE OF PROTEIN, VITAMINS AND MINERALS",
        productName: "Chicken Burrito",
        imageURL:
          "https://resizer.deliverect.com/6YIIS1bTpbzMr3WFtmPqqbcL9yd4ZmTrgUo1MJkaRzE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cnJpdG8lMjB3cmFwLTY1NzU4ZmY2MTk4NDQxOWFmYWNhOTI3MS5qcGc=",
        productCalories: 464,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404c09647a7c33b69822",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:48:45.428Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T19:49:54.223Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T19:50:32.547Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T20:04:17.390Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T20:04:17.390Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T20:05:47.439Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T20:29:07.245Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:48:45.428Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T19:48:57.664Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T19:48:45.428Z",
    updatedAt: "2024-07-02T20:29:07.593Z",
  },
  {
    id: "6684598f09647a7c33c1ce3b",
    sid: "240702-084806",
    coupons: [],
    menuId: "6684405509647a7c33b6a5a4",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6684598f09647a7c33c1ce2a",
    firstName: "Eman ibrahim ameen",
    lastName: null,
    phoneNumber: "+96550035101",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "667958088c15153ac8866ccf",
    delivery: {
      id: "6684598f09647a7c33c1ce2a",
      customerId: "667958088c15153ac8866ccf",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "667958a1bed556ad8fb5e4b2",
        name: "Egaila ",
        lng: 48.09420067816973,
        lat: 29.17759798426205,
        city: "Kuwait",
        area: "Eqaila",
        block: "4",
        street: "420",
        building: "20",
        floor: "",
        flat: "",
        phone: "+96550035101",
        additionalInfo: "Yellow house. No bell only call",
        isSelected: true,
        createdAt: "2024-06-24T11:29:37.285Z",
        updatedAt: "2024-07-02T19:47:51.930Z",
      },
      deliverectValidationId: "6684598f725db551226aa312",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T19:58:31.222Z",
      estimatedDeliveryTime: "2024-07-02T20:35:29.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684598f09647a7c33c1ce3b",
      createdAt: "2024-07-02T19:48:31.301Z",
      updatedAt: "2024-07-02T19:48:31.844Z",
    },
    items: [
      {
        productId: "6684405509647a7c33b6a623",
        productPLU: "2162113-1",
        productDescription:
          "C 66g | P 31g | F 23g[%Popular%]\nCheeseburger with baked sweet potato fries and bbq sauce",
        productName: "Cheeseburger & fries",
        imageURL:
          "https://resizer.deliverect.com/ieC2-A-hVgDcV7K8o8Xs_r4BY3iPAcNrHZDyA3fQ42o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjA1Xy02NjQ0YjA1NTc0NWEzOTM5ZTIxNjY5MTQucG5n",
        productCalories: 595,
        quantity: 1,
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:48:31.823Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T19:48:51.527Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T19:49:05.227Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T19:56:36.221Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T19:56:36.221Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T20:06:55.495Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T20:23:17.601Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:48:31.823Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T19:48:44.778Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T19:48:31.823Z",
    updatedAt: "2024-07-02T20:23:17.952Z",
  },
  {
    id: "6684595809647a7c33c1bfe7",
    sid: "240702-820836",
    coupons: [],
    menuId: "6684404c09647a7c33b69777",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6684595709647a7c33c1bfda",
    firstName: "Fatmah Alfailakawi ",
    lastName: null,
    phoneNumber: "+96599910582",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66565e4f8ceacb066c542309",
    delivery: {
      id: "6684595709647a7c33c1bfda",
      customerId: "66565e4f8ceacb066c542309",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66565eb8ecc6fd38a64a4804",
        name: "Alfailakawi House",
        lng: 47.98606909811497,
        lat: 29.271420428876645,
        city: "خيطان،",
        area: "Khaitan",
        block: "1",
        street: "144 ",
        building: "13",
        floor: "",
        flat: "",
        phone: "+96599910582",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-28T22:46:16.527Z",
        updatedAt: "2024-05-28T22:46:16.527Z",
      },
      deliverectValidationId: "6684595780e3ed2de2a2ff0f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T19:57:35.069Z",
      estimatedDeliveryTime: "2024-07-02T20:34:34.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684595809647a7c33c1bfe7",
      createdAt: "2024-07-02T19:47:35.371Z",
      updatedAt: "2024-07-02T19:47:36.072Z",
    },
    items: [
      {
        productId: "6684404c09647a7c33b697e5",
        productPLU: "2163010-1",
        productDescription:
          "C 25g| P 34g| F 15g\nGrilled chicken with baby gem lettuce, caesar dressing topped with sourdough croutons & shaved parmesan cheese.\n\nGood source of Vitamin A &Potein",
        productName: "Chicken Ceasar Salad",
        imageURL:
          "https://resizer.deliverect.com/aOyM36xC7gBB5GwjyrYYA3YsTJ8-9RRXq22EPXeQlkM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NGI3YjVjOWU0ZDVjMzAwMThiNjViNmIucG5n",
        productCalories: 371,
        quantity: 1,
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:47:36.056Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T19:48:01.141Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T19:50:05.264Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T20:04:06.215Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T20:04:06.216Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T20:07:18.014Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T20:15:08.705Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:47:36.056Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T19:47:47.523Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T19:47:36.056Z",
    updatedAt: "2024-07-02T20:15:09.045Z",
  },
  {
    id: "6684591509647a7c33c1a070",
    sid: "240702-941408",
    coupons: [],
    menuId: "6684405209647a7c33b6a0fb",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6684591409647a7c33c1a060",
    firstName: "Hassan Dashti",
    lastName: null,
    phoneNumber: "+96592223336",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "662f789c7ef16a176ee68c65",
    delivery: {
      id: "6684591409647a7c33c1a060",
      customerId: "662f789c7ef16a176ee68c65",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "662f7938c37a2a2c26786c65",
        name: "Mansoriya",
        lng: 47.99056448042393,
        lat: 29.358762152545136,
        city: "Kuwait",
        area: "Mansouriya",
        block: "1",
        street: "15 St",
        building: "16 B",
        floor: "",
        flat: "",
        phone: "+96592223336",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-29T10:40:56.907Z",
        updatedAt: "2024-04-29T10:40:56.907Z",
      },
      deliverectValidationId: "668459141483dc433d549a3e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T19:56:28.535Z",
      estimatedDeliveryTime: "2024-07-02T20:33:26.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684591509647a7c33c1a070",
      createdAt: "2024-07-02T19:46:28.659Z",
      updatedAt: "2024-07-02T19:46:29.215Z",
    },
    items: [
      {
        productId: "6684405209647a7c33b6a158",
        productPLU: "2167013-1",
        productDescription:
          "C 42g| P 28g| F 12g [%Popular%]\nBeef with our special sauce and sumac onion wrapped in tortilla.\n\nGood source of Vitamin B6 & Vitamin B12",
        productName: "Beef Shawarma",
        imageURL:
          "https://resizer.deliverect.com/DQl32OjdVbaHyJyht46g35g33tk0bsgQNbyyp4jWAdc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQmVlZiUyMHNoYXdhcm1hLTY1ZjZlOThhMzRiNTQ3YTg5NDYyNmEwZS5qcGc=",
        productCalories: 388,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684405209647a7c33b6a170",
        productPLU: "2162120-1",
        productDescription:
          "C 63g | P 29g | F 12g [%New%]\nTomato sauce, cheese, chicken topped with BBQ sauce\n\nGood source of Protein ",
        productName: "BBQ Chicken Pizza",
        imageURL:
          "https://resizer.deliverect.com/oLi5aqw9dJEyegq-8KQAZCtGuNR2P65Gdii-y51sbns/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmJxJTIwcGl6emEtNjY3NDM0MDZhZjdmODYxNjFhMjNhOWYyLnBuZw==",
        productCalories: 476,
        quantity: 1,
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684405209647a7c33b6a1a6",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684405209647a7c33b6a14b",
        productPLU: "2161006-1",
        productDescription:
          "C 27g | P 28g | F 28g\nScrambled eggs, sourdough toast, guacamole,grilled bacon, blanched green beans, grilled cherry tomato with coriander.\n\nGood source of protien & healthy fat",
        productName: "FM  Breakfast",
        imageURL:
          "https://resizer.deliverect.com/Q1wfz8V9k1eV52eKoDg2053U4MKQ3ahaibZUrGgKe1U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRk0lMjBicmVha2Zhc3QlMjAoMSktNjYzNjAwYTI4NGMwMDk0NWNmMTJiOTYxLmpwZw==",
        productCalories: 472,
        quantity: 1,
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 790,
    totalFmcCentsPrice: 790,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "pending",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:46:29.195Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T19:46:52.453Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T19:47:12.164Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T20:01:28.448Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T20:01:28.448Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T20:01:49.466Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T20:15:20.696Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:46:29.195Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T19:46:29.195Z",
    updatedAt: "2024-07-02T20:15:21.039Z",
  },
  {
    id: "6684590d09647a7c33c19e88",
    sid: "240702-462720",
    coupons: [],
    menuId: "6684404f09647a7c33b69b60",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6684590c09647a7c33c19e3c",
    firstName: "Hussain Albehbehani ",
    lastName: null,
    phoneNumber: "+96565861116",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "667fc58e8c15153ac8950d1a",
    delivery: {
      id: "6684590c09647a7c33c19e3c",
      customerId: "667fc58e8c15153ac8950d1a",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "667fc5f886fe4b6ad186bde3",
        name: "Home",
        lng: 48.03947204723954,
        lat: 29.27859259928584,
        city: "Mubarak Al-Abdullah",
        area: "Mubarak Al-Abdullah",
        block: "3",
        street: "305 St",
        building: "3",
        floor: "",
        flat: "",
        phone: "+96565861116",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-29T08:29:44.418Z",
        updatedAt: "2024-06-29T08:29:44.418Z",
      },
      deliverectValidationId: "6684590c80e3ed2de2a2eac7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T19:56:20.321Z",
      estimatedDeliveryTime: "2024-07-02T20:33:19.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684590d09647a7c33c19e88",
      createdAt: "2024-07-02T19:46:20.416Z",
      updatedAt: "2024-07-02T19:46:21.258Z",
    },
    items: [
      {
        productId: "6684404f09647a7c33b69be1",
        productPLU: "2162109-1",
        productDescription:
          "C 23g | P 29g | F 16g \n Beef Lasagna with zucchini & basil\n\nGood source of protein and vitamin A",
        productName: "Zucchini Beef Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SGhwFjSpMczUdKMaEIYf4gYXABWfE33sF_WueZYOSfI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGxhc2FnbmEtNjY0NGFmZDU3NDhmMzY3YzEzMTZlNWZhLnBuZw==",
        productCalories: 352,
        quantity: 1,
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404f09647a7c33b69bc2",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 490,
    totalFmcCentsPrice: 490,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:46:21.043Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T19:46:41.986Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T20:00:42.210Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T20:00:42.210Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T20:00:42.211Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T20:01:04.178Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T20:20:11.056Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:46:21.043Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T19:46:32.921Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T19:46:21.043Z",
    updatedAt: "2024-07-02T20:20:11.399Z",
  },
  {
    id: "668458a609647a7c33c182e7",
    sid: "240702-253145",
    coupons: [],
    menuId: "6684404f09647a7c33b69b60",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "668458a609647a7c33c182d7",
    firstName: "Talal Albaghli",
    lastName: null,
    phoneNumber: "+96566392244",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64eb89a2b4954e5ceb62d3d4",
    delivery: {
      id: "668458a609647a7c33c182d7",
      customerId: "64eb89a2b4954e5ceb62d3d4",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64ec8d023b8f1d06c4d689de",
        name: "Home",
        lng: 48.02555322647095,
        lat: 29.314335499084002,
        city: "Jabriya",
        area: "Jabriya",
        block: "5",
        street: "6",
        building: "541",
        floor: "",
        flat: "",
        phone: "+96566392244",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-28T12:03:14.099Z",
        updatedAt: "2024-06-07T09:33:18.728Z",
      },
      deliverectValidationId: "668458a679c4a067001c4914",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T19:54:38.187Z",
      estimatedDeliveryTime: "2024-07-02T20:31:37.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668458a609647a7c33c182e7",
      createdAt: "2024-07-02T19:44:38.275Z",
      updatedAt: "2024-07-02T19:44:38.815Z",
    },
    items: [
      {
        productId: "6684404f09647a7c33b69bca",
        productPLU: "2163017-1",
        productDescription:
          "C 14g| P 5g| F 14g\nIceberg lettuce, purple cabbage, celery, cucumbers, red bell peppers, carrots, spring onions & feta cheese drizzled in a apple cider dressing.\n\nGOOD SOURCE OF VITAMINS AND MINERALS",
        productName: "Crisp Garden Salad",
        imageURL:
          "https://resizer.deliverect.com/j1CgCeX_u4Ri_dXIPf7B0tN79FQj6Y8oZKfuCfwP-TM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAxMi02NTE3ZWUxNDY4ZDZhNTAxZDEzMzZhMjQuanBn",
        productCalories: 202,
        quantity: 1,
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404f09647a7c33b69bd5",
        productPLU: "2162120-1",
        productDescription:
          "C 63g | P 29g | F 12g [%New%]\nTomato sauce, cheese, chicken topped with BBQ sauce\n\nGood source of Protein ",
        productName: "BBQ Chicken Pizza",
        imageURL:
          "https://resizer.deliverect.com/oLi5aqw9dJEyegq-8KQAZCtGuNR2P65Gdii-y51sbns/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmJxJTIwcGl6emEtNjY3NDM0MDZhZjdmODYxNjFhMjNhOWYyLnBuZw==",
        productCalories: 476,
        quantity: 1,
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404f09647a7c33b69c08",
        productPLU: "2150024-1",
        productDescription:
          "C 10g | P 16g | F 7g\nJareesh with beef tenderloin, topped with diced onions. \n\nGood sourceof Protein& Vitamin B12 and Fiber ",
        productName: "Jareesh",
        imageURL:
          "https://resizer.deliverect.com/QzmTeX0Uu4rVADzlheZmP78oV_-yy2Dy_YHrovH5kCA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFyZWVzaC02NjQxZGIyZTliZTJlY2UxM2IyZGNkZWQuanBn",
        productCalories: 167,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404f09647a7c33b69c2e",
        productPLU: "2202049-1",
        productDescription:
          "C 37g | P 0.75g | F 0g\nMango, lemon\n\nGood source of Vitamin C",
        productName: "Mango Zest",
        imageURL:
          "https://resizer.deliverect.com/Z5A3Et0Kx8-jsClnBJortO-cfJp_NB50ODoTnmv5LqI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTItNjY1OTE4ZTJlYjE5ZTYwZTlkMGZkYjZiLnBuZw==",
        productCalories: 156,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 920,
    totalFmcCentsPrice: 920,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:44:38.794Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T19:45:01.040Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T19:58:48.319Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T19:58:48.319Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T19:58:48.320Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T19:59:10.188Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T20:06:21.644Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:44:38.794Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T19:44:50.703Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T19:44:38.794Z",
    updatedAt: "2024-07-02T20:06:21.990Z",
  },
  {
    id: "6684588209647a7c33c172e6",
    sid: "240702-528779",
    coupons: [],
    menuId: "6684404c09647a7c33b69777",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6684588109647a7c33c172dd",
    firstName: "arub aldhaferi",
    lastName: null,
    phoneNumber: "+96567774303",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "665eda570eba0d08a5756bdb",
    delivery: {
      id: "6684588109647a7c33c172dd",
      customerId: "665eda570eba0d08a5756bdb",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "665edb113d5f2b755df65f2c",
        name: "home ",
        lng: 47.89604511111975,
        lat: 29.279050882602014,
        city: "Ardiya",
        area: "Ardiya",
        block: "9",
        street: "5",
        building: "9",
        floor: "1",
        flat: "1",
        phone: "+96567774303",
        additionalInfo: "red house",
        isSelected: true,
        createdAt: "2024-06-04T09:14:57.574Z",
        updatedAt: "2024-07-02T05:42:42.954Z",
      },
      deliverectValidationId: "668458811013fe1e6d71ceb5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T19:54:01.899Z",
      estimatedDeliveryTime: "2024-07-02T20:31:00.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684588209647a7c33c172e6",
      createdAt: "2024-07-02T19:44:01.992Z",
      updatedAt: "2024-07-02T19:44:02.566Z",
    },
    items: [
      {
        productId: "6684404c09647a7c33b697cf",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:44:02.546Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T19:44:34.029Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T19:46:02.490Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T20:01:38.489Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T20:01:38.490Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T20:05:35.761Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T20:54:32.741Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:44:02.546Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T19:44:11.505Z",
      },
    ],
    cutlery: true,
    hasRating: true,
    timeslot: null,
    createdAt: "2024-07-02T19:44:02.546Z",
    updatedAt: "2024-07-02T21:04:11.815Z",
  },
  {
    id: "6684586709647a7c33c16e32",
    sid: "240702-596095",
    coupons: [],
    menuId: "6684404c09647a7c33b69777",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6684586609647a7c33c16e14",
    firstName: "Danah Alali",
    lastName: null,
    phoneNumber: "+96541003664",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6682f4668c15153ac89f1c1b",
    delivery: {
      id: "6684586609647a7c33c16e14",
      customerId: "6682f4668c15153ac89f1c1b",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6682f60d09647a7c337fa239",
        name: "Home",
        lng: 47.900547198951244,
        lat: 29.240170396090335,
        city: "عبدالله المبارك الصباح",
        area: "عبدالله المبارك الصباح",
        block: "6",
        street: "603",
        building: "House 12",
        floor: "",
        flat: "",
        phone: "+96541003664",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-01T18:31:41.309Z",
        updatedAt: "2024-07-01T18:31:41.309Z",
      },
      deliverectValidationId: "66845866e0ae9e25fe5c733d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T19:53:34.652Z",
      estimatedDeliveryTime: "2024-07-02T20:30:33.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684586709647a7c33c16e32",
      createdAt: "2024-07-02T19:43:34.739Z",
      updatedAt: "2024-07-02T19:43:35.318Z",
    },
    items: [
      {
        productId: "6684404c09647a7c33b697f5",
        productPLU: "2162114-1",
        productDescription:
          "C 72g | P 23g | F 37g[%Popular%]\nSpicy chicken with baked sweet potato fries and bbq sauce",
        productName: "Spicy chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/hz32F8HhZsCkfGOHXJa1A_84fVa6XlqxSUPRpF4NkUA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjA0Xy02NjQ0YjA4NjNjYmY3YTM2N2ZiNGEyMTEucG5n",
        productCalories: 713,
        quantity: 1,
        fmcCentsPrice: 340,
        fiatCentsPrice: 340,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404c09647a7c33b69829",
        productPLU: "2166016-1",
        productDescription:
          "C 23g| P 7g| F 11g\nEdamame with spicy aoili sauce.\n\nGood source of Dietary fibre",
        productName: "Edamame",
        imageURL:
          "https://resizer.deliverect.com/M_MarA40YziTUu2xEfXvbnV7HlmJJ3vSOFpeOcKWcuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNy02NTExNmUyZDRmNWNlOTI2ZTU5MTQxMjguanBn",
        productCalories: 220,
        quantity: 1,
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 460,
    totalFmcCentsPrice: 460,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:43:35.297Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T19:44:35.606Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T19:46:02.702Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T20:01:27.608Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T20:01:27.608Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T20:05:13.327Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T20:35:58.830Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:43:35.297Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T19:43:55.216Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T19:43:35.297Z",
    updatedAt: "2024-07-02T20:35:59.236Z",
  },
  {
    id: "6684585e09647a7c33c16c52",
    sid: "240702-627376",
    coupons: [],
    menuId: "6684404c09647a7c33b69777",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6684585d09647a7c33c16c48",
    firstName: "Khaled ahamd",
    lastName: null,
    phoneNumber: "+96597141410",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6682ec098c15153ac89ee0ee",
    delivery: {
      id: "6684585d09647a7c33c16c48",
      customerId: "6682ec098c15153ac89ee0ee",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6682ef0809647a7c337c3c3a",
        name: "Home",
        lng: 48.03042948246002,
        lat: 29.266059728125146,
        city: "Kuwait",
        area: "Shuhada",
        block: "3",
        street: "313 ",
        building: "51",
        floor: "",
        flat: "",
        phone: "97141410",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-01T18:01:44.462Z",
        updatedAt: "2024-07-02T19:29:25.517Z",
      },
      deliverectValidationId: "6684585d22b56ec7d573c1af",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T19:53:25.749Z",
      estimatedDeliveryTime: "2024-07-02T20:30:24.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684585e09647a7c33c16c52",
      createdAt: "2024-07-02T19:43:25.832Z",
      updatedAt: "2024-07-02T19:43:26.371Z",
    },
    items: [
      {
        productId: "6684404c09647a7c33b697f5",
        productPLU: "2162114-1",
        productDescription:
          "C 72g | P 23g | F 37g[%Popular%]\nSpicy chicken with baked sweet potato fries and bbq sauce",
        productName: "Spicy chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/hz32F8HhZsCkfGOHXJa1A_84fVa6XlqxSUPRpF4NkUA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjA0Xy02NjQ0YjA4NjNjYmY3YTM2N2ZiNGEyMTEucG5n",
        productCalories: 713,
        quantity: 1,
        fmcCentsPrice: 340,
        fiatCentsPrice: 340,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404c09647a7c33b69829",
        productPLU: "2166016-1",
        productDescription:
          "C 23g| P 7g| F 11g\nEdamame with spicy aoili sauce.\n\nGood source of Dietary fibre",
        productName: "Edamame",
        imageURL:
          "https://resizer.deliverect.com/M_MarA40YziTUu2xEfXvbnV7HlmJJ3vSOFpeOcKWcuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNy02NTExNmUyZDRmNWNlOTI2ZTU5MTQxMjguanBn",
        productCalories: 220,
        quantity: 1,
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 460,
    totalFmcCentsPrice: 460,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:43:26.352Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T19:44:33.933Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T19:46:01.986Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T20:01:49.322Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T20:01:49.322Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T20:05:02.024Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T20:24:06.473Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T19:43:26.352Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T19:43:43.527Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-07-02T19:43:26.352Z",
    updatedAt: "2024-07-02T20:24:06.835Z",
  },
  {
    id: "6684447009647a7c33b8ed49",
    sid: "240702-021860",
    coupons: [],
    menuId: "6684404f09647a7c33b69b60",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6684446f09647a7c33b8ec94",
    firstName: "Eman alshareedah",
    lastName: null,
    phoneNumber: "+96599330069",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "651177ffe1d91353418ebbab",
    delivery: {
      id: "6684446f09647a7c33b8ec94",
      customerId: "651177ffe1d91353418ebbab",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65648cb702b2e21f1fb7fd0e",
        name: "Home",
        lng: 48.00158450289803,
        lat: 29.310745239257812,
        city: "Kuwait",
        area: "Surra",
        block: "4",
        street: "15 St",
        building: "25",
        floor: "",
        flat: "",
        phone: "+96599330069",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-27T12:33:59.529Z",
        updatedAt: "2023-11-27T12:33:59.529Z",
      },
      deliverectValidationId: "6684446fe43469c912e750d8",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T18:28:23.240Z",
      estimatedDeliveryTime: "2024-07-02T19:05:20.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684447009647a7c33b8ed49",
      createdAt: "2024-07-02T18:18:23.378Z",
      updatedAt: "2024-07-02T18:18:24.288Z",
    },
    items: [
      {
        productId: "6684404f09647a7c33b69c09",
        productPLU: "2166033-1",
        productDescription:
          "C 28g| P 15g| F 12g\nSpicy crispy shrimp bites served with ranch sauce.\n\nGood source of Protein, Vitamin B12 and Iron",
        productName: "Buffalo Shrimp Bites",
        imageURL:
          "https://resizer.deliverect.com/rvAuXhjdUl1okUgfHDTv8pSQt2Rjhn13XhdwXCPIEyw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnVmZmFsbyUyMHNocmltcCUyMGJpdGVzLTY1Y2IxNGU4Zjc3ODBhZDhlOTQyYWZlMy5wbmc=",
        productCalories: 280,
        quantity: 1,
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404f09647a7c33b69bec",
        productPLU: "2162007-1",
        productDescription:
          "C 33g | P 30g | F 11g\nBeef patty, melted cheddar cheese, white onion, dill pickles with ketchup and mustard.\n\nGood Source of  Niacin & Vitamin B12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 351,
        quantity: 1,
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        remark: "",
        subItems: [
          {
            productId: "6684404f09647a7c33b69c81",
            productPLU: "1907008-1",
            productName: "No Dill Pickles",
            productDescription: "C 0g| P 0g| F 0g| cal -0.4| coins 0",
            productCalories: -0.4,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
          },
        ],
      },
      {
        productId: "6684404f09647a7c33b69c31",
        productPLU: "2202052-1",
        productDescription:
          "C 13g | P 0g | F 0g\nPomegranate, lemon\n\nGood source of Vitamin C",
        productName: "Pomade",
        imageURL:
          "https://resizer.deliverect.com/JpImLqr5JfNLPk8gTnl6NoZd9i6mdIhqPB4QA7H5lw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEtNjY1OTFhNzgxNmEzMzQyNTY5OGVhM2QxLnBuZw==",
        productCalories: 54,
        quantity: 1,
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 640,
    totalFmcCentsPrice: 640,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:18:24.191Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T18:19:46.620Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T18:58:24.940Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T18:58:24.940Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T18:58:24.940Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T18:58:47.600Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T19:10:38.730Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:18:24.191Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T18:19:37.099Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T18:18:24.191Z",
    updatedAt: "2024-07-02T20:18:01.038Z",
  },
  {
    id: "6684446d09647a7c33b8eaaf",
    sid: "240702-885185",
    coupons: [],
    menuId: "6684404f09647a7c33b69b60",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6684446a09647a7c33b8e893",
    firstName: "محمد مصبح عقيل ",
    lastName: null,
    phoneNumber: "+96550023999",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66536a2d47e7155f92720a4b",
    delivery: {
      id: "6684446a09647a7c33b8e893",
      customerId: "66536a2d47e7155f92720a4b",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66536b2cde3f2e06c66e7f63",
        name: "25",
        lng: 48.05510114884647,
        lat: 29.296768188476562,
        city: "Kuwait",
        area: "بيان",
        block: "8",
        street: "الاول ",
        building: "25",
        floor: "",
        flat: "",
        phone: "+96550023999",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-26T17:02:36.764Z",
        updatedAt: "2024-07-02T18:12:06.380Z",
      },
      deliverectValidationId: "6684446aba47b39c3795cb5f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T18:28:18.489Z",
      estimatedDeliveryTime: "2024-07-02T19:05:16.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684446d09647a7c33b8eaaf",
      createdAt: "2024-07-02T18:18:18.893Z",
      updatedAt: "2024-07-02T18:18:21.265Z",
    },
    items: [
      {
        productId: "6684404f09647a7c33b69bee",
        productPLU: "2162083-1",
        productDescription:
          "C 17g| P 50g| F 23g\nGrilled steak topped with a mushroom sauce, served with mashed potatoes, blanched broccoli, cauliflower, carrots & baby corn.\n\nGOOD SOURCE OF PROTEIN,VITAMINS B12",
        productName: "Steak with Mushroom Sauce",
        imageURL:
          "https://resizer.deliverect.com/MhHmajc6fZKUcOfQ81P3g_G9D6wrDIOY-TbE2YJyj3o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3RlYWslMjB3aXRoJTIwbXVzaHJvb20tNjU1NGNjMTdlMWViNzQwMDFlNmYyZDdhLnBuZw==",
        productCalories: 475,
        quantity: 1,
        fmcCentsPrice: 540,
        fiatCentsPrice: 540,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404f09647a7c33b69c1f",
        productPLU: "2166021-1",
        productDescription:
          "C 14g| P 2g| F 1g\nFresh pomegranate seeds.\n\nGOOD SOURCE OF VITAMIN  C",
        productName: "Fresh Pomegranate",
        imageURL:
          "https://resizer.deliverect.com/0QanPE0fkKGZvx0QoWjnfvfTbh0UdPExZ1vpzwL3kU0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNjYtNjUyOWY0MGY4NDhjZjcwMDE4YzBmYmIxLmpwZw==",
        productCalories: 70,
        quantity: 1,
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 630,
    totalFmcCentsPrice: 630,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:18:21.177Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T18:19:32.171Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T18:51:56.489Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T18:51:56.489Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T18:51:56.489Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T18:52:18.798Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T19:13:04.238Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:18:21.177Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T18:18:56.064Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T18:18:21.177Z",
    updatedAt: "2024-07-02T20:18:00.848Z",
  },
  {
    id: "6684446c09647a7c33b8ea0b",
    sid: "240702-755560",
    coupons: [],
    menuId: "6684404f09647a7c33b69b60",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6684446a09647a7c33b8e89d",
    firstName: "munera alnajdi",
    lastName: null,
    phoneNumber: "+96550707469",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6656de988ceacb066c54afb3",
    delivery: {
      id: "6684446a09647a7c33b8e89d",
      customerId: "6656de988ceacb066c54afb3",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6656e10fecc6fd38a64f6c1c",
        name: "surra",
        lng: 48.018031641840935,
        lat: 29.31376947456156,
        city: "Jabriya",
        area: "Surra",
        block: "2",
        street: "8 St",
        building: "13",
        floor: "",
        flat: "",
        phone: "+96550707469",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-29T08:02:23.313Z",
        updatedAt: "2024-06-30T13:55:34.654Z",
      },
      deliverectValidationId: "6684446a963fc85d73eff6e5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T18:28:18.748Z",
      estimatedDeliveryTime: "2024-07-02T19:05:17.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684446c09647a7c33b8ea0b",
      createdAt: "2024-07-02T18:18:18.951Z",
      updatedAt: "2024-07-02T18:18:20.463Z",
    },
    items: [
      {
        productId: "6684404f09647a7c33b69bbc",
        productPLU: "2162073-1",
        productDescription:
          "C 55g| P 22g| F 20g [%Popular%]\nCrispy buffalo chicken tenders, iceberg lettuce, cheese with black pepper ranch sauce in a tortilla\n\nGOOD SOURCE OF PROTEIN, IRON, VITAMINS AND MINERALS ",
        productName: "Zinger Wrap",
        imageURL:
          "https://resizer.deliverect.com/HxB6Jk4SYuzyKeoB5PLI0G8vmLwmz39dUm-FWsICaAg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvWmluZ2VyJTIwd3JhcC02NWY2ZTkxOGMxMjg2NmZjODg2NjczZTIuanBn",
        productCalories: 491,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404f09647a7c33b69bc7",
        productPLU: "2163018-1",
        productDescription:
          "C 33g| P 7g| F 9g\nRed quinoa mixed with green bell peppers, cucumbers, red radishes, red onions & pomegranate seeds topped with feta cheese, golden raisens & dried cranberries all drizzled in a citrusy vinegratte.",
        productName: "Quinoa Salad",
        imageURL:
          "https://resizer.deliverect.com/e3ZflZAd7M2zwKScDw0f_qLPJ1BkRuzHHLJoW1Jtpfk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcXVpbm9hJTIwc2FsYWQoMSktNjU1NGNjMmNhYjcyYjAwMDJjYjllMzVhLnBuZw==",
        productCalories: 241,
        quantity: 1,
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 360,
    totalFmcCentsPrice: 360,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:18:20.268Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T18:18:43.183Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T18:58:00.734Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T18:58:00.734Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T18:58:00.734Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T18:58:23.766Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T19:24:52.390Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:18:20.268Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T18:18:34.399Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T18:18:20.268Z",
    updatedAt: "2024-07-02T20:18:01.152Z",
  },
  {
    id: "6684443409647a7c33b8c5e0",
    sid: "240702-487539",
    coupons: [],
    menuId: "6684405509647a7c33b6a5a4",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6684443209647a7c33b8c403",
    firstName: "Abdulaziz jowhar ",
    lastName: null,
    phoneNumber: "+96566218888",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "655117c26fe598ae84f24b53",
    delivery: {
      id: "6684443209647a7c33b8c403",
      customerId: "655117c26fe598ae84f24b53",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "655257c35ea5b27ca4a00633",
        name: "My home ",
        lng: 48.09267919510603,
        lat: 29.210576514150876,
        city: "Fnaitees",
        area: "Fnaitees",
        block: "8",
        street: "805 St",
        building: "338",
        floor: "Non",
        flat: "Non",
        phone: "+96566218888",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-13T17:07:15.304Z",
        updatedAt: "2024-05-14T10:18:01.848Z",
      },
      deliverectValidationId: "66844432725db551225f4dd1",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T18:27:22.495Z",
      estimatedDeliveryTime: "2024-07-02T19:04:21.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684443409647a7c33b8c5e0",
      createdAt: "2024-07-02T18:17:22.587Z",
      updatedAt: "2024-07-02T18:17:24.684Z",
    },
    items: [
      {
        productId: "6684405509647a7c33b6a60d",
        productPLU: "2163018-1",
        productDescription:
          "C 33g| P 7g| F 9g\nRed quinoa mixed with green bell peppers, cucumbers, red radishes, red onions & pomegranate seeds topped with feta cheese, golden raisens & dried cranberries all drizzled in a citrusy vinegratte.",
        productName: "Quinoa Salad",
        imageURL:
          "https://resizer.deliverect.com/e3ZflZAd7M2zwKScDw0f_qLPJ1BkRuzHHLJoW1Jtpfk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcXVpbm9hJTIwc2FsYWQoMSktNjU1NGNjMmNhYjcyYjAwMDJjYjllMzVhLnBuZw==",
        productCalories: 241,
        quantity: 1,
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684405509647a7c33b6a621",
        productPLU: "2162111-1",
        productDescription:
          "C 72g | P 23g | F 34g[%Popular%]\nChicken burger with baked sweet potato fries and bbq sauce",
        productName: "Chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/W-Q0KOEEFconPveULpPYJ1-BKoKF0NFlrsCg_Bf1OTQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAxXy02NjQ0YjA2NGUxYWUwZmJmYWQ2MmM3NjcucG5n",
        productCalories: 686,
        quantity: 1,
        fmcCentsPrice: 340,
        fiatCentsPrice: 340,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 510,
    totalFmcCentsPrice: 510,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:17:24.668Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T18:18:06.047Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T18:18:06.047Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T18:42:02.820Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T18:42:02.820Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T18:42:24.691Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T19:01:22.001Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:17:24.668Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T18:17:43.320Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T18:17:24.668Z",
    updatedAt: "2024-07-02T19:01:22.488Z",
  },
  {
    id: "6684442f09647a7c33b8c1f7",
    sid: "240702-739006",
    coupons: [],
    menuId: "6684404c09647a7c33b69777",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6684442d09647a7c33b8bfc5",
    firstName: "Husam yaseen",
    lastName: null,
    phoneNumber: "+96566530120",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "667c4fe48c15153ac88f1ffd",
    delivery: {
      id: "6684442d09647a7c33b8bfc5",
      customerId: "667c4fe48c15153ac88f1ffd",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "667c507e86fe4b6ad1331415",
        name: "Home",
        lng: 47.96836718666696,
        lat: 29.285354614257812,
        city: "Al Farwaniyah",
        area: "Khaitan",
        block: "8",
        street: "57",
        building: "3776",
        floor: "2",
        flat: "3",
        phone: "+96566530120",
        additionalInfo: "Same building there is fouz market (paqala) ",
        isSelected: true,
        createdAt: "2024-06-26T17:31:42.475Z",
        updatedAt: "2024-06-26T17:31:42.475Z",
      },
      deliverectValidationId: "6684442db6dddee0a0f9290f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T18:27:17.803Z",
      estimatedDeliveryTime: "2024-07-02T19:04:16.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684442f09647a7c33b8c1f7",
      createdAt: "2024-07-02T18:17:17.930Z",
      updatedAt: "2024-07-02T18:17:19.692Z",
    },
    items: [
      {
        productId: "6684404c09647a7c33b697d3",
        productPLU: "2162073-1",
        productDescription:
          "C 55g| P 22g| F 20g [%Popular%]\nCrispy buffalo chicken tenders, iceberg lettuce, cheese with black pepper ranch sauce in a tortilla\n\nGOOD SOURCE OF PROTEIN, IRON, VITAMINS AND MINERALS ",
        productName: "Zinger Wrap",
        imageURL:
          "https://resizer.deliverect.com/HxB6Jk4SYuzyKeoB5PLI0G8vmLwmz39dUm-FWsICaAg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvWmluZ2VyJTIwd3JhcC02NWY2ZTkxOGMxMjg2NmZjODg2NjczZTIuanBn",
        productCalories: 491,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404c09647a7c33b697d5",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404c09647a7c33b697f3",
        productPLU: "2162112-1",
        productDescription:
          "C 64g | P 31g | F 26g[%Popular%]\nFM burger with baked sweet potato fries and bbq sauce",
        productName: "FM burger & fries",
        imageURL:
          "https://resizer.deliverect.com/x99vUapgX87SAwe62HZOV0adVNKcDxeysqBvzjp9D1c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjA2LTY2NDRiMDc0NWM2ZGVmMDFjMzNjM2MyMi5wbmc=",
        productCalories: 614,
        quantity: 1,
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 660,
    totalFmcCentsPrice: 660,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:17:19.653Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T18:18:08.818Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T18:18:53.841Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T18:40:18.270Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T18:40:18.270Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T18:40:40.584Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T18:59:37.203Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:17:19.653Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T18:17:31.530Z",
      },
    ],
    cutlery: true,
    hasRating: true,
    timeslot: null,
    createdAt: "2024-07-02T18:17:19.653Z",
    updatedAt: "2024-07-02T20:04:02.072Z",
  },
  {
    id: "668443ef09647a7c33b89ee6",
    sid: "240702-080266",
    coupons: [],
    menuId: "6684404c09647a7c33b69777",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "668443ee09647a7c33b89dca",
    firstName: "shatha almutawa",
    lastName: null,
    phoneNumber: "+96597433636",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d4080622ca634ce639bdf9",
    delivery: {
      id: "668443ee09647a7c33b89dca",
      customerId: "64d4080622ca634ce639bdf9",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6627d8a1d99caaf52158c247",
        name: "home",
        lng: 47.97892768627067,
        lat: 29.314697265625,
        city: "Kuwait",
        area: "Qortuba",
        block: "4",
        street: "1 St. 4 Ln",
        building: "9",
        floor: "",
        flat: "",
        phone: "+96597433636",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-23T15:49:53.579Z",
        updatedAt: "2024-04-23T15:49:53.579Z",
      },
      deliverectValidationId: "668443edb47e1f13544ac532",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T18:26:13.867Z",
      estimatedDeliveryTime: "2024-07-02T19:03:12.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668443ef09647a7c33b89ee6",
      createdAt: "2024-07-02T18:16:14.013Z",
      updatedAt: "2024-07-02T18:16:15.192Z",
    },
    items: [
      {
        productId: "6684404c09647a7c33b697e5",
        productPLU: "2163010-1",
        productDescription:
          "C 25g| P 34g| F 15g\nGrilled chicken with baby gem lettuce, caesar dressing topped with sourdough croutons & shaved parmesan cheese.\n\nGood source of Vitamin A &Potein",
        productName: "Chicken Ceasar Salad",
        imageURL:
          "https://resizer.deliverect.com/aOyM36xC7gBB5GwjyrYYA3YsTJ8-9RRXq22EPXeQlkM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NGI3YjVjOWU0ZDVjMzAwMThiNjViNmIucG5n",
        productCalories: 371,
        quantity: 1,
        fmcCentsPrice: 340,
        fiatCentsPrice: 270,
        remark: "",
        subItems: [
          {
            productId: "6684404c09647a7c33b6987f",
            productPLU: "1906025-1",
            productName: "Extra  Grilled Chicken",
            productDescription: "C 0g| P 22g| F 2g| cal 101| coins 0.7",
            productCalories: 101,
            quantity: 1,
            fmcCentsPrice: 70,
            fiatCentsPrice: 70,
          },
          {
            productId: "6684404c09647a7c33b69882",
            productPLU: "1907023-1",
            productName: "No Crouton",
            productDescription: "C -13g| P -3g| F -1g| cal -68| coins 0",
            productCalories: -68,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
          },
        ],
      },
      {
        productId: "6684404c09647a7c33b697d5",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 440,
    totalFmcCentsPrice: 440,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:16:15.172Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T18:16:37.788Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T18:18:54.235Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T18:33:56.851Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T18:33:56.851Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T18:34:13.955Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T18:50:58.708Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:16:15.172Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T18:16:26.814Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T18:16:15.172Z",
    updatedAt: "2024-07-02T18:50:59.334Z",
  },
  {
    id: "668443cf09647a7c33b88aaa",
    sid: "240702-036253",
    coupons: [],
    menuId: "6684404f09647a7c33b69b60",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "668443cd09647a7c33b889d8",
    firstName: "Abdulwahab alzaidi",
    lastName: null,
    phoneNumber: "+96595599292",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c000d275a6aa442d0dee5e",
    delivery: {
      id: "668443cd09647a7c33b889d8",
      customerId: "64c000d275a6aa442d0dee5e",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64ce4f275e8f4fe1fb17ba7a",
        name: "Duwaniya",
        lng: 47.994922734797,
        lat: 29.318510066285043,
        city: "Kuwait",
        area: "Surra",
        block: "5",
        street: "3 St",
        building: "House 51",
        floor: "",
        flat: "",
        phone: "+96595554455",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-05T13:31:19.426Z",
        updatedAt: "2024-07-02T18:12:13.515Z",
      },
      deliverectValidationId: "668443cd79c4a0670010a1f4",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T18:25:41.877Z",
      estimatedDeliveryTime: "2024-07-02T19:02:40.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668443cf09647a7c33b88aaa",
      createdAt: "2024-07-02T18:15:41.973Z",
      updatedAt: "2024-07-02T18:15:43.100Z",
    },
    items: [
      {
        productId: "6684404f09647a7c33b69bbc",
        productPLU: "2162073-1",
        productDescription:
          "C 55g| P 22g| F 20g [%Popular%]\nCrispy buffalo chicken tenders, iceberg lettuce, cheese with black pepper ranch sauce in a tortilla\n\nGOOD SOURCE OF PROTEIN, IRON, VITAMINS AND MINERALS ",
        productName: "Zinger Wrap",
        imageURL:
          "https://resizer.deliverect.com/HxB6Jk4SYuzyKeoB5PLI0G8vmLwmz39dUm-FWsICaAg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvWmluZ2VyJTIwd3JhcC02NWY2ZTkxOGMxMjg2NmZjODg2NjczZTIuanBn",
        productCalories: 491,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404f09647a7c33b69bcd",
        productPLU: "2163003-1",
        productDescription:
          "C 36g | P 22g | F 11g\nShredded chicken, mixed slaw, edamame, & crunchy toasted peanut, iceberg lettuce, mandarin, all drizzled with a peanut dressing and topped with sesame seeds with coriander.\n\nGood source of Vitamins A, K & C",
        productName: "Asian Peanut Salad",
        imageURL:
          "https://resizer.deliverect.com/humW3Ra_sNPdK2LTBHq_jLtgrnRK92UjDwqvK9r2I2o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvdGhhaSUyMHBlYW51dCUyMHNhbGFkLTY0YjdiNWI2N2U5MmVhMTFiYjcwZGExMy5wbmc=",
        productCalories: 333,
        quantity: 1,
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 420,
    totalFmcCentsPrice: 420,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:15:43.078Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T18:16:47.834Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T18:41:03.770Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T18:41:03.770Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T18:41:03.770Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T18:41:27.107Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T18:54:18.236Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:15:43.078Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T18:16:11.302Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T18:15:43.078Z",
    updatedAt: "2024-07-02T20:18:01.297Z",
  },
  {
    id: "668443c609647a7c33b885cc",
    sid: "240702-949098",
    coupons: [],
    menuId: "6684405509647a7c33b6a5a4",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "668443c509647a7c33b884a7",
    firstName: "Mutlaq alkhuzam",
    lastName: null,
    phoneNumber: "+96597522231",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "667b18238c15153ac88c095e",
    delivery: {
      id: "668443c509647a7c33b884a7",
      customerId: "667b18238c15153ac88c095e",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "667b18b1ac113a1f6d14699f",
        name: "Home",
        lng: 48.13656013458967,
        lat: 29.085845016820876,
        city: "Kuwait",
        area: "الفحيحيل",
        block: "10",
        street: "4",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96597522231",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-25T19:21:21.784Z",
        updatedAt: "2024-06-25T19:21:21.784Z",
      },
      deliverectValidationId: "668443c5d3ae55e4d213c813",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T18:25:33.181Z",
      estimatedDeliveryTime: "2024-07-02T19:02:31.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668443c609647a7c33b885cc",
      createdAt: "2024-07-02T18:15:33.424Z",
      updatedAt: "2024-07-02T18:15:34.558Z",
    },
    items: [
      {
        productId: "6684405509647a7c33b6a633",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684405509647a7c33b6a631",
        productPLU: "2162006-1",
        productDescription:
          "C 31g| P 29g| F 14g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 366,
        quantity: 1,
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 430,
    totalFmcCentsPrice: 430,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:15:34.542Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T18:16:08.117Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T18:16:37.662Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T18:42:52.718Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T18:42:52.718Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T18:44:38.325Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T19:19:53.794Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:15:34.542Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T18:15:54.423Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T18:15:34.542Z",
    updatedAt: "2024-07-02T19:19:54.140Z",
  },
  {
    id: "668443c109647a7c33b88263",
    sid: "240702-446201",
    coupons: [],
    menuId: "6684405509647a7c33b6a5a4",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "668443c109647a7c33b88251",
    firstName: "Mohammad",
    lastName: null,
    phoneNumber: "+96566688860",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6650ce9d47e7155f926ced24",
    delivery: {
      id: "668443c109647a7c33b88251",
      customerId: "6650ce9d47e7155f926ced24",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "665b4e6793dc7e98b4835e50",
        name: "Mermaid House",
        lng: 48.05726699531078,
        lat: 29.251177497847564,
        city: "Sabah Al Salem",
        area: "Sabah Al Salem",
        block: "9",
        street: "3 St 11 Ln",
        building: "2",
        floor: "",
        flat: "",
        phone: "+96555844848",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-01T16:37:59.323Z",
        updatedAt: "2024-07-02T18:11:21.497Z",
      },
      deliverectValidationId: "668443c1bf9e75b0348b446d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T18:25:29.035Z",
      estimatedDeliveryTime: "2024-07-02T19:02:28.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668443c109647a7c33b88263",
      createdAt: "2024-07-02T18:15:29.120Z",
      updatedAt: "2024-07-02T18:15:29.827Z",
    },
    items: [
      {
        productId: "6684405509647a7c33b6a611",
        productPLU: "2163011-1",
        productDescription:
          "C 8g| P 5g| F 20g\nMixed greens, eggplant feta, carrots & red & white cabbage drizzed with a zesty vinaigrette dressing.\n\nGOOD SOURCE OF VITAMIN E & VITAMIN K",
        productName: "Eggplant Feta Salad",
        imageURL:
          "https://resizer.deliverect.com/pERu_JbxdO1wsoe98smib3-rtsS87wXQnqud_Pox7cY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2lkZSUyMDQtNjUxMDM4NjM2OTU5NzA3ZDUwMWUwMzY2LmpwZw==",
        productCalories: 232,
        quantity: 1,
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684405509647a7c33b6a62e",
        productPLU: "2162082-1",
        productDescription:
          "C 44g| P 39g| F 6g\nChicken breast with baked eggplant, cauliflower, onions, carrots, red bell peppers in rice with CORIANDER\n\nGOOD SOURCE OF PROTEIN,VITAMIN A &C",
        productName: "Maqlouba",
        imageURL:
          "https://resizer.deliverect.com/ImlBCJvVJcyYJBWth7S7FEgLNUj1i0B0vhTLvEmLoDY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NjQ0YmNjMzc0OGYzNjdjMTMxNmU2ODgucG5n",
        productCalories: 386,
        quantity: 1,
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684405509647a7c33b6a659",
        productPLU: "2166017-1",
        productDescription:
          "C 16g| P 8g| F 11g\nCarrots, cucumber, red & yellow capsicum, celery, purple & green endives with a hummus dip",
        productName: "Veggie Crudites",
        imageURL:
          "https://resizer.deliverect.com/I3FIyxNKPcOIzVfjKga2oVRpt99xbhQflVKJw27qrWM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjA5Xy02NTA4MzM1ZjM5NGU3ODMxZTFiZDMyMDYucG5n",
        productCalories: 195,
        quantity: 1,
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684405509647a7c33b6a667",
        productPLU: "2165020-1",
        productDescription:
          "C 10g | P 1g | F 6g [%New%]\nPecan, caramel, milk chocolate",
        productName: "Pecan Turtle",
        imageURL:
          "https://resizer.deliverect.com/M_C4q-ZLQuwipzQEg6XeH4Pvq602HMW3vxgoWZ8KB3k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVjYW4lMjB0dXJ0bGUtNjY2MWNlNDc1MDRlYzgwNTk0NmU0YTkzLnBuZw==",
        productCalories: 100,
        quantity: 1,
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684405509647a7c33b6a664",
        productPLU: "2202034-1",
        productDescription: "C 17.1g | P 1.9g | F 0.6g\nPomelo & Pomegranate",
        productName: "Seasonal Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/XTwC1dVyhg5--YDaNmhMWBtNNZpa3e6lzIaYdRmZhDA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2Vhc29uYWwlMjBmcnVpdCUyMHNhbGFkLTY2NDFkYmY5YWIxMzE0ZDg1Njc3OGRhNS5wbmc=",
        productCalories: 82,
        quantity: 1,
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 760,
    totalFmcCentsPrice: 760,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:15:29.809Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T18:16:09.239Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T18:16:39.278Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T18:36:39.463Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T18:36:39.463Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T18:37:01.539Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T19:06:31.467Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:15:29.809Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T18:15:41.925Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T18:15:29.809Z",
    updatedAt: "2024-07-02T19:06:31.810Z",
  },
  {
    id: "668443a009647a7c33b8793a",
    sid: "240702-301631",
    coupons: [],
    menuId: "6684404f09647a7c33b69b60",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6684439f09647a7c33b8792d",
    firstName: "Fatma alsaegh",
    lastName: null,
    phoneNumber: "+96598990084",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "668146098c15153ac898b01d",
    delivery: {
      id: "6684439f09647a7c33b8792d",
      customerId: "668146098c15153ac898b01d",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "668146b286fe4b6ad1be88cd",
        name: "Home",
        lng: 48.06282169137959,
        lat: 29.284957885742188,
        city: "Kuwait",
        area: "Mishref",
        block: "2",
        street: "2",
        building: "16",
        floor: "1",
        flat: "2",
        phone: "+96598990084",
        additionalInfo: "Right hand side small door  ",
        isSelected: true,
        createdAt: "2024-06-30T11:51:14.034Z",
        updatedAt: "2024-06-30T15:47:54.707Z",
      },
      deliverectValidationId: "6684439fd8c4b7b2911507f7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T18:24:55.808Z",
      estimatedDeliveryTime: "2024-07-02T19:01:54.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668443a009647a7c33b8793a",
      createdAt: "2024-07-02T18:14:55.896Z",
      updatedAt: "2024-07-02T18:14:56.619Z",
    },
    items: [
      {
        productId: "6684404f09647a7c33b69bf7",
        productPLU: "2162019-1",
        productDescription:
          "C 45g| P 24g| F 16g\nShrimp & rice topped with hashu with a side of dakkus.\n\nGluten free, Good source of Vitamin B 12, A & C",
        productName: "Murabyan",
        imageURL:
          "https://resizer.deliverect.com/TJVdjvdLawOHnrvUIIFGmtSne5lvu_vKoyHBIoWMnFg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkVaSVpFRCUyME1VUllCQU4tNjRjOGJlYzQ3MGY0OGUwMDFmNjdjMmVjLmpwZw==",
        productCalories: 420,
        quantity: 1,
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404f09647a7c33b69c4a",
        productPLU: "2202042-1",
        productDescription:
          "C 20.5g | P 10.7g | F 6g\nColumbia coffee, Low fat Milk & Stevia",
        productName: "Spanish Latte",
        imageURL:
          "https://resizer.deliverect.com/9TlQsSxyHUFBMkzJXw4eSVQXrHLxw3ft31tZUhc9Y5M/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BhbmlzaCUyMGxhdHRlLTY2NDM3YWEwM2ZjYjljY2I2NjJkN2QyNS5wbmc=",
        productCalories: 180,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
      {
        productId: "6684404f09647a7c33b69bb6",
        productPLU: "2167023-1",
        productDescription:
          "C 29g | P 22g | F 16g\nScrambled eggs with mushroom, green olives and feta cheese in a tortilla wrap\n\nGOOD SOURCE OF PROTEIN, MINERALS",
        productName: "Mushroom egg wrap",
        imageURL:
          "https://resizer.deliverect.com/icCk6VyiEI1LioDbIHa0TjQikuisfIyU0FZUoDvsxdY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAyLTY2M2ZmM2Q0ZGI0ZjMyZjZhNTZmMGY4MC5wbmc=",
        productCalories: 348,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 640,
    totalFmcCentsPrice: 640,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:14:56.601Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T18:15:38.986Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T19:05:15.080Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T19:05:15.080Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T19:05:15.080Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T19:05:37.042Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T19:23:19.226Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T18:14:56.601Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T18:15:27.045Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T18:14:56.601Z",
    updatedAt: "2024-07-02T20:16:21.726Z",
  },
  {
    id: "668426ea09647a7c33ae6a59",
    sid: "240702-245598",
    coupons: [],
    menuId: "6683c20909647a7c339286de",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "668426e909647a7c33ae6a4f",
    firstName: "Munera ",
    lastName: null,
    phoneNumber: "+96597566332",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66584fe20eba0d08a5682a69",
    delivery: {
      id: "668426e909647a7c33ae6a4f",
      customerId: "66584fe20eba0d08a5682a69",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6659c5af93dc7e98b460df2d",
        name: "Albanwan",
        lng: 47.98369150000001,
        lat: 29.328058,
        city: "Kuwait",
        area: "Adailiya",
        block: "3",
        street: "37",
        building: "House 12",
        floor: "",
        flat: "",
        phone: "+96597566332",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-31T12:42:23.982Z",
        updatedAt: "2024-07-02T16:11:09.695Z",
      },
      deliverectValidationId: "668426e9ed1376f6e4803e76",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T16:22:25.729Z",
      estimatedDeliveryTime: "2024-07-02T16:59:24.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668426ea09647a7c33ae6a59",
      createdAt: "2024-07-02T16:12:25.809Z",
      updatedAt: "2024-07-02T16:12:26.541Z",
    },
    items: [
      {
        productId: "6683c20a09647a7c33928784",
        productPLU: "2150024-1",
        productDescription:
          "C 10g | P 16g | F 7g\nJareesh with beef tenderloin, topped with diced onions. \n\nGood sourceof Protein& Vitamin B12 and Fiber ",
        productName: "Jareesh",
        imageURL:
          "https://resizer.deliverect.com/QzmTeX0Uu4rVADzlheZmP78oV_-yy2Dy_YHrovH5kCA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFyZWVzaC02NjQxZGIyZTliZTJlY2UxM2IyZGNkZWQuanBn",
        productCalories: 167,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20a09647a7c3392878b",
        productPLU: "2166037-1",
        productDescription:
          "C 31g | P 3g | F 8g [%New%]\nTortilla chips, cheddar cheese, & pickled jalapeños. ",
        productName: "Jalapeño Cheddar Nachos ",
        imageURL:
          "https://resizer.deliverect.com/Igtv_X3IIKdvVYw3drGOS6DlDQf47bmEkgol0b9fBZ0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFsYXBlbm8lMjBjaGVlc2UlMjBuYWNob3MtNjY2MWNkZWQ5YWNlYTFmMWZlZWVkOTU5LnBuZw==",
        productCalories: 208,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:12:26.522Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T16:13:09.672Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T16:13:09.672Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T16:19:52.318Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T16:19:52.318Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T16:20:47.775Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T16:33:40.399Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:12:26.522Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T16:12:38.006Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T16:12:26.522Z",
    updatedAt: "2024-07-02T16:33:40.817Z",
  },
  {
    id: "668426dd09647a7c33ae68d3",
    sid: "240702-025690",
    coupons: [],
    menuId: "6683c20409647a7c33927e62",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "668426dc09647a7c33ae68c8",
    firstName: "Masha’el AlSendi",
    lastName: null,
    phoneNumber: "+96597794494",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65ae5b2b97e5f13760b7a8c0",
    delivery: {
      id: "668426dc09647a7c33ae68c8",
      customerId: "65ae5b2b97e5f13760b7a8c0",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65ae5c781c5f840f7cdb0b08",
        name: "Home",
        lng: 48.0806909,
        lat: 29.1886698,
        city: "Kuwait",
        area: "Mubarak Al-Kabeer",
        block: "2",
        street: "46",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96597794494",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-22T12:15:52.636Z",
        updatedAt: "2024-07-02T15:43:42.107Z",
      },
      deliverectValidationId: "668426dcd47b5bc49b532b69",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T16:22:12.365Z",
      estimatedDeliveryTime: "2024-07-02T16:59:11.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668426dd09647a7c33ae68d3",
      createdAt: "2024-07-02T16:12:12.449Z",
      updatedAt: "2024-07-02T16:12:13.228Z",
    },
    items: [
      {
        productId: "6683c20409647a7c33927ee3",
        productPLU: "2162087-1",
        productDescription:
          "C 46g| P 25g| F 12g\nSpaghetti pasta with shrimp & mushrooms, topped with parmesan cheese & parsley.\n\n*GOOD SOURCE OF PROTEIN,VITAMINS & MINERALS*",
        productName: "Shrimp Spaghetti",
        imageURL:
          "https://resizer.deliverect.com/e50u0BHoIPbgB_0McTOoN_3zV5uGhjvZSnQvuYCNeBM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2hyaW1wJTIwcGFzdGEtNjVmNmU5ZTNmZmQ4YjY5NGNiZjRmOTQ2LnBuZw==",
        productCalories: 392,
        quantity: 1,
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20409647a7c33927ed4",
        productPLU: "2162117-1",
        productDescription:
          "C 60g | P 22g | F 15g [%New%]\nTomato sauce, cheese & oregano.",
        productName: "Classic Margherita Pizza",
        imageURL:
          "https://resizer.deliverect.com/gXmec37h0lqeQkrMSIQRiRdHLwG9kVK9Ukz9m_Kw930/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWFyZ2hhcml0YS02NjZiMzAxMWQ2NmMzMDFhNDE0NmYxNTMucG5n",
        productCalories: 463,
        quantity: 1,
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 610,
    totalFmcCentsPrice: 610,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:12:13.205Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T16:12:41.348Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T16:12:41.349Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T16:23:47.488Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T16:23:47.488Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T16:26:39.204Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T16:59:44.262Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:12:13.205Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T16:12:24.789Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T16:12:13.205Z",
    updatedAt: "2024-07-02T16:59:44.610Z",
  },
  {
    id: "668426b709647a7c33ae6138",
    sid: "240702-082599",
    coupons: [],
    menuId: "6683c20709647a7c339282f1",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "668426b609647a7c33ae608a",
    firstName: "ghada",
    lastName: null,
    phoneNumber: "+96590966836",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d921fedfecd0088b524919",
    delivery: {
      id: "668426b609647a7c33ae608a",
      customerId: "64d921fedfecd0088b524919",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "664a1715e7d7ea1c4c6eb663",
        name: "Nuzha ",
        lng: 47.989186495542526,
        lat: 29.344481144756603,
        city: "Kuwait",
        area: "Nuzha",
        block: "2",
        street: "28",
        building: "2",
        floor: "",
        flat: "",
        phone: "+96590966836",
        additionalInfo: "yacoub almutawa ",
        isSelected: true,
        createdAt: "2024-05-19T15:13:25.596Z",
        updatedAt: "2024-05-27T13:00:29.367Z",
      },
      deliverectValidationId: "668426b685b4e122ce61252a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T16:21:34.332Z",
      estimatedDeliveryTime: "2024-07-02T16:58:33.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668426b709647a7c33ae6138",
      createdAt: "2024-07-02T16:11:34.648Z",
      updatedAt: "2024-07-02T16:11:35.634Z",
    },
    items: [
      {
        productId: "6683c20709647a7c33928342",
        productPLU: "2167024-1",
        productDescription:
          "C 48g | P 19g | F 9g [%New%]\nGrilled chicken, baby rocca, diced pineapples, avocado, lemon dressing & mornay sauce on a sourdough bun. \n\nGOOD SOURCE OF PROTEIN",
        productName: "Mornay Chicken Sandwich",
        imageURL:
          "https://resizer.deliverect.com/DAo-X9NwIq3ldfjY3TyiAYKEktaQIPQ3dVvIy5NfGGc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbW9ybmF5JTIwY2hpY2tlbiUyMHNhbmR3aWNoLTY2NjFjYzk3NTE3YjBhMmY5YTNmNTAyZC5wbmc=",
        productCalories: 349,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "no pinapple ",
        subItems: [],
      },
      {
        productId: "6683c20709647a7c33928362",
        productPLU: "2162120-1",
        productDescription:
          "C 63g | P 29g | F 12g [%New%]\nTomato sauce, cheese, chicken topped with BBQ sauce\n\nGood source of Protein ",
        productName: "BBQ Chicken Pizza",
        imageURL:
          "https://resizer.deliverect.com/oLi5aqw9dJEyegq-8KQAZCtGuNR2P65Gdii-y51sbns/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmJxJTIwcGl6emEtNjY3NDM0MDZhZjdmODYxNjFhMjNhOWYyLnBuZw==",
        productCalories: 476,
        quantity: 1,
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20709647a7c3392838e",
        productPLU: "2162021-1",
        productDescription:
          "C 60g| P 50g| F 16g\nChicken shawarma served with calrose rice & drizzled with our shawarma sauce and sumac white onions.\n\nHigh in protein, ",
        productName: "Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/wpaWs0_xzYbkz_6vxHW2469_71kHXwgrjXuSA4epEwY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2hpY2tlbiUyMFNoYXdhcm1hJTIwYm93bC02NGY0OTRlOTFhNzliZjNkNzNkYjUzNjcuanBn",
        productCalories: 584,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 220,
        remark: "",
        subItems: [
          {
            productId: "6683c20709647a7c3392843d",
            productPLU: "1906036-1",
            productName: "1/2 Portion",
            productDescription: "C -30g| P -25g| F -8g| cal -292| coins -0.7",
            productCalories: -292,
            quantity: 1,
            fmcCentsPrice: -70,
            fiatCentsPrice: -70,
          },
        ],
      },
    ],
    totalFiatCentsPrice: 650,
    totalFmcCentsPrice: 650,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:11:35.614Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T16:12:14.888Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T16:13:55.516Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T16:20:21.600Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T16:20:21.601Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T16:20:36.554Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T16:20:54.671Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:11:35.614Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T16:11:47.723Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T16:11:35.614Z",
    updatedAt: "2024-07-02T16:36:54.970Z",
  },
  {
    id: "6684268c09647a7c33ae5539",
    sid: "240702-180509",
    coupons: [],
    menuId: "6683c20409647a7c33927e62",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6684268a09647a7c33ae5381",
    firstName: "Mohammed AlSafran",
    lastName: null,
    phoneNumber: "+96599918057",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "655359236fe598ae84f44e35",
    delivery: {
      id: "6684268a09647a7c33ae5381",
      customerId: "655359236fe598ae84f44e35",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "655373b55ea5b27ca4a5c7c6",
        name: "Home",
        lng: 48.089728688128204,
        lat: 29.234024047851562,
        city: "Al-Masayel",
        area: "Al-Masayel",
        block: "4",
        street: "413",
        building: "263",
        floor: "",
        flat: "",
        phone: "+96599918057",
        additionalInfo: "white gray house has garage",
        isSelected: true,
        createdAt: "2023-11-14T13:18:45.416Z",
        updatedAt: "2024-06-30T14:42:12.848Z",
      },
      deliverectValidationId: "6684268a35273be22c5475f2",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T16:20:50.265Z",
      estimatedDeliveryTime: "2024-07-02T16:57:49.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684268c09647a7c33ae5539",
      createdAt: "2024-07-02T16:10:50.354Z",
      updatedAt: "2024-07-02T16:10:52.331Z",
    },
    items: [
      {
        productId: "6683c20409647a7c33927ed1",
        productPLU: "2162118-1",
        productDescription:
          "C 60g | P 22g | F 17g [%New%]\nTomato sauce, cheese & pepperoni\n\nGood source of Protein ",
        productName: "Pepperoni Pizza",
        imageURL:
          "https://resizer.deliverect.com/QJO9SowT2ihHOIWJoe4NojUcvMSrdQhHrE3QE_iNeHs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVwcGVyb25pLTY2NzQzMzkzMjU0YjRkYjE0ZjE5OTk4MS5wbmc=",
        productCalories: 481,
        quantity: 1,
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20409647a7c33927ece",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20409647a7c33927f07",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 600,
    totalFmcCentsPrice: 600,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:10:52.229Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T16:11:19.378Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T16:12:35.173Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T16:23:35.993Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T16:23:35.994Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T16:26:57.349Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T16:50:15.244Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:10:52.229Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T16:11:04.136Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T16:10:52.229Z",
    updatedAt: "2024-07-02T16:50:15.598Z",
  },
  {
    id: "6684263f09647a7c33ae4d04",
    sid: "240702-317814",
    coupons: [],
    menuId: "6683c20709647a7c339282f1",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6684263f09647a7c33ae4cfa",
    firstName: "Shamlan alroumi",
    lastName: null,
    phoneNumber: "+96560090053",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d4464e22ca634ce639bf00",
    delivery: {
      id: "6684263f09647a7c33ae4cfa",
      customerId: "64d4464e22ca634ce639bf00",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64e9f284da8077b230ad6758",
        name: "Home",
        lng: 48.008822947740555,
        lat: 29.35639380047659,
        city: "Kuwait City",
        area: "Daiya",
        block: "1",
        street: "10",
        building: "10",
        floor: "",
        flat: "",
        phone: "+96560090053",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-26T12:39:32.866Z",
        updatedAt: "2024-07-02T12:09:40.800Z",
      },
      deliverectValidationId: "6684263ffcba48c1e69c39c9",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T16:19:35.363Z",
      estimatedDeliveryTime: "2024-07-02T16:56:34.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684263f09647a7c33ae4d04",
      createdAt: "2024-07-02T16:09:35.452Z",
      updatedAt: "2024-07-02T16:09:35.948Z",
    },
    items: [
      {
        productId: "6683c20709647a7c339283b0",
        productPLU: "2165003-1",
        productDescription: "C 12g | P 2g | F 9g\n",
        productName: "Peanut Bites",
        imageURL:
          "https://resizer.deliverect.com/S2e9SlQ_6ewwX1Z-Pg_RtXB9e4OxquLa6-xOxnwoCJM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTMtNjRiN2JjNmFkMGE4N2MwMDFmZTYwZjBhLnBuZw==",
        productCalories: 139,
        quantity: 2,
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20709647a7c33928362",
        productPLU: "2162120-1",
        productDescription:
          "C 63g | P 29g | F 12g [%New%]\nTomato sauce, cheese, chicken topped with BBQ sauce\n\nGood source of Protein ",
        productName: "BBQ Chicken Pizza",
        imageURL:
          "https://resizer.deliverect.com/oLi5aqw9dJEyegq-8KQAZCtGuNR2P65Gdii-y51sbns/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmJxJTIwcGl6emEtNjY3NDM0MDZhZjdmODYxNjFhMjNhOWYyLnBuZw==",
        productCalories: 476,
        quantity: 1,
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 450,
    totalFmcCentsPrice: 450,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:09:35.930Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T16:10:24.869Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T16:12:55.486Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "delivered",
        timestamp: "2024-07-02T16:20:59.534Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:09:35.930Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T16:09:47.621Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T16:09:35.930Z",
    updatedAt: "2024-07-02T16:38:28.120Z",
  },
  {
    id: "6684260109647a7c33ae44a7",
    sid: "240702-973944",
    coupons: [],
    menuId: "6683c20d09647a7c33928c1a",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "668425ff09647a7c33ae4388",
    firstName: "Shaima’a Almohanna ",
    lastName: null,
    phoneNumber: "+96595552220",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66458b4366978e88ed194879",
    delivery: {
      id: "668425ff09647a7c33ae4388",
      customerId: "66458b4366978e88ed194879",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66458c15fb0531469a6d8c0e",
        name: "Siddeeq home",
        lng: 47.9881466053506,
        lat: 29.291030883789062,
        city: "Kuwait",
        area: "Al-Siddeeq",
        block: "5",
        street: "515",
        building: "70",
        floor: "3",
        flat: "3",
        phone: "+96595552220",
        additionalInfo: "Left side door to elevator",
        isSelected: true,
        createdAt: "2024-05-16T04:31:17.912Z",
        updatedAt: "2024-07-01T09:12:58.600Z",
      },
      deliverectValidationId: "668425ffd3ae55e4d201eabe",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T16:18:31.224Z",
      estimatedDeliveryTime: "2024-07-02T16:55:29.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684260109647a7c33ae44a7",
      createdAt: "2024-07-02T16:08:31.562Z",
      updatedAt: "2024-07-02T16:08:33.444Z",
    },
    items: [
      {
        productId: "6683c20d09647a7c33928c86",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20d09647a7c33928c71",
        productPLU: "2162073-1",
        productDescription:
          "C 55g| P 22g| F 20g [%Popular%]\nCrispy buffalo chicken tenders, iceberg lettuce, cheese with black pepper ranch sauce in a tortilla\n\nGOOD SOURCE OF PROTEIN, IRON, VITAMINS AND MINERALS ",
        productName: "Zinger Wrap",
        imageURL:
          "https://resizer.deliverect.com/HxB6Jk4SYuzyKeoB5PLI0G8vmLwmz39dUm-FWsICaAg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvWmluZ2VyJTIwd3JhcC02NWY2ZTkxOGMxMjg2NmZjODg2NjczZTIuanBn",
        productCalories: 491,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:08:33.426Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T16:09:23.066Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T16:10:58.117Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T16:18:57.365Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T16:18:57.365Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T16:20:59.801Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T16:51:05.686Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:08:33.426Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T16:08:46.516Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T16:08:33.426Z",
    updatedAt: "2024-07-02T16:51:06.030Z",
  },
  {
    id: "6684250009647a7c33ae1c9b",
    sid: "240702-716503",
    coupons: [],
    menuId: "6683c20909647a7c339286de",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6684250009647a7c33ae1c8e",
    firstName: "noura almutairi",
    lastName: null,
    phoneNumber: "+96590000771",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65298a9a1bfa57c5dbf74412",
    delivery: {
      id: "6684250009647a7c33ae1c8e",
      customerId: "65298a9a1bfa57c5dbf74412",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65463a2cf91376d3946bbdc3",
        name: "surra block 6 street 11 house 42",
        lng: 48.00307597965002,
        lat: 29.317636259207354,
        city: "Kuwait",
        area: "السرة",
        block: "6",
        street: "11",
        building: "42",
        floor: "",
        flat: "",
        phone: "+96590000771",
        additionalInfo: "grey house on corner",
        isSelected: true,
        createdAt: "2023-11-04T12:33:48.181Z",
        updatedAt: "2024-06-30T06:25:50.626Z",
      },
      deliverectValidationId: "66842500d8c4b7b291fedf32",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T16:14:16.300Z",
      estimatedDeliveryTime: "2024-07-02T16:51:15.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684250009647a7c33ae1c9b",
      createdAt: "2024-07-02T16:04:16.378Z",
      updatedAt: "2024-07-02T16:04:16.820Z",
    },
    items: [
      {
        productId: "6683c20a09647a7c33928757",
        productPLU: "2162111-1",
        productDescription:
          "C 72g | P 23g | F 34g[%Popular%]\nChicken burger with baked sweet potato fries and bbq sauce",
        productName: "Chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/W-Q0KOEEFconPveULpPYJ1-BKoKF0NFlrsCg_Bf1OTQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAxXy02NjQ0YjA2NGUxYWUwZmJmYWQ2MmM3NjcucG5n",
        productCalories: 686,
        quantity: 1,
        fmcCentsPrice: 340,
        fiatCentsPrice: 340,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:04:16.801Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T16:04:54.918Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T16:09:35.085Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T16:19:59.492Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T16:19:59.492Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T16:26:01.978Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T16:44:27.911Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:04:16.801Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T16:04:39.080Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T16:04:16.801Z",
    updatedAt: "2024-07-02T16:44:28.261Z",
  },
  {
    id: "668424ff09647a7c33ae1c85",
    sid: "240702-254083",
    coupons: [],
    menuId: "6683c20409647a7c33927e62",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "668424fe09647a7c33ae1c71",
    firstName: "Yousef abdulaziz alshatti",
    lastName: null,
    phoneNumber: "+96599383533",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "666b4212eff64cfb0693a797",
    delivery: {
      id: "668424fe09647a7c33ae1c71",
      customerId: "666b4212eff64cfb0693a797",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "666b44010ce21ecb25a13884",
        name: "Home",
        lng: 48.08883826914975,
        lat: 29.199935913085938,
        city: "Kuwait",
        area: "Al-Qurain District",
        block: "1",
        street: "4 St",
        building: "23",
        floor: "",
        flat: "",
        phone: "+96599383533",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-13T19:09:53.762Z",
        updatedAt: "2024-06-13T19:09:53.762Z",
      },
      deliverectValidationId: "668424fe963fc85d73daa471",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T16:14:14.344Z",
      estimatedDeliveryTime: "2024-07-02T16:51:12.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668424ff09647a7c33ae1c85",
      createdAt: "2024-07-02T16:04:14.961Z",
      updatedAt: "2024-07-02T16:04:15.656Z",
    },
    items: [
      {
        productId: "6683c20409647a7c33927eb4",
        productPLU: "2167022-1",
        productDescription:
          "C 29g | P 21g | F 16g\nSpicy chicken shawarma with spring onions, pomegranates, parsley, pickled cucumbers & spicy sauce wrapped in tortilla. \n\nGood source of Protein",
        productName: "Spicy Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/7iczQVW8ZJbNN179Yvo32X8zQsGcTbj5NwVYyyacwCk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU0hBV0FSTUlBKDEpLTY2NDRiYzU4M2NiZjdhMzY3ZmI0YTI1ZC5qcGc=",
        productCalories: 344,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20409647a7c33927ece",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20409647a7c33927eda",
        productPLU: "2162113-1",
        productDescription:
          "C 66g | P 31g | F 23g[%Popular%]\nCheeseburger with baked sweet potato fries and bbq sauce",
        productName: "Cheeseburger & fries",
        imageURL:
          "https://resizer.deliverect.com/ieC2-A-hVgDcV7K8o8Xs_r4BY3iPAcNrHZDyA3fQ42o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjA1Xy02NjQ0YjA1NTc0NWEzOTM5ZTIxNjY5MTQucG5n",
        productCalories: 595,
        quantity: 1,
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20409647a7c33927f0d",
        productPLU: "2166029-1",
        productDescription:
          "C 16g| P 3g| F 1g\nGrilled corn\n\nGOOD SOURCE OF VITAMIN B6",
        productName: "Grilled Corn",
        imageURL:
          "https://resizer.deliverect.com/bzZU2HyEukivHUCvK9HB6Fq74sQULhtryjUuDiuVkPc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDQtNjUyOWY0MDFkZDMxNzAwMGJhZmIwOWE4LmpwZw==",
        productCalories: 83,
        quantity: 1,
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 690,
    totalFmcCentsPrice: 690,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:04:15.637Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T16:04:42.154Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T16:05:06.891Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T16:13:30.671Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T16:13:30.672Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T16:27:10.406Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T16:46:29.215Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:04:15.637Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T16:04:27.139Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T16:04:15.637Z",
    updatedAt: "2024-07-02T16:46:29.664Z",
  },
  {
    id: "6684249209647a7c33adfee2",
    sid: "240702-591354",
    coupons: [],
    menuId: "6683c20d09647a7c33928c1a",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6684249109647a7c33adfed7",
    firstName: "Nour albloushi",
    lastName: null,
    phoneNumber: "+96594180044",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65a3ff332d5e4e992693f4a7",
    delivery: {
      id: "6684249109647a7c33adfed7",
      customerId: "65a3ff332d5e4e992693f4a7",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "667c56fd86fe4b6ad1352f85",
        name: "Home",
        lng: 48.03139814514417,
        lat: 29.267181396484375,
        city: "Kuwait",
        area: "Shuhada",
        block: "3",
        street: "315 ",
        building: "3",
        floor: "",
        flat: "",
        phone: "+96594180044",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-26T17:59:25.583Z",
        updatedAt: "2024-07-02T16:01:10.205Z",
      },
      deliverectValidationId: "66842491019fabc76e3ddcda",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T16:12:25.347Z",
      estimatedDeliveryTime: "2024-07-02T16:49:24.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684249209647a7c33adfee2",
      createdAt: "2024-07-02T16:02:25.441Z",
      updatedAt: "2024-07-02T16:02:26.029Z",
    },
    items: [
      {
        productId: "6683c20d09647a7c33928cf7",
        productPLU: "2202021-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Coca Cola Zero",
        imageURL:
          "https://resizer.deliverect.com/NvHctUF0z3us3Lhe9x5u3_mQIOOXnn8K3FvSZO6MkK4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCgxKS02NGM5MTk1MjZlY2Q3NzAwMjZjNGU1MDUuanBn",
        productCalories: 0,
        quantity: 1,
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20d09647a7c33928c88",
        productPLU: "2162124-1",
        productDescription:
          "C 50g | P 22g | F 10g [%New%]\nChicken, Orzo pasta with tomato sauce topped with parmesan cheese",
        productName: "Chicken Orzo Parmesan",
        imageURL:
          "https://resizer.deliverect.com/zbX-3JmfsnTTty6PcgLrHH6DLyYIdE8OalCp4CsIb18/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvT3J6byUyMHBhc3RhLTY2N2Q1ZjFjNzcwNGVkMjViNjliN2Q2My5wbmc=",
        productCalories: 378,
        quantity: 1,
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:02:26.002Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T16:02:56.131Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T16:05:31.433Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T16:07:42.649Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T16:07:42.650Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T16:21:11.326Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T16:37:10.335Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:02:26.002Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T16:02:41.685Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T16:02:26.002Z",
    updatedAt: "2024-07-02T16:37:10.695Z",
  },
  {
    id: "6684248609647a7c33adfd4c",
    sid: "240702-116265",
    coupons: [],
    menuId: "6683c20d09647a7c33928c1a",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6684248509647a7c33adfd41",
    firstName: " latifa ben essa",
    lastName: null,
    phoneNumber: "+96594969591",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64ce98e8f119a7788c9886f5",
    delivery: {
      id: "6684248509647a7c33adfd41",
      customerId: "64ce98e8f119a7788c9886f5",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64fdb7c534491db2e9f17ffa",
        name: "home",
        lng: 47.992407493293285,
        lat: 29.318175006112117,
        city: "Kuwait",
        area: "Qortuba",
        block: "1",
        street: "1",
        building: "22",
        floor: "",
        flat: "",
        phone: "+96594969591",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-09-10T12:34:13.996Z",
        updatedAt: "2024-05-18T21:24:02.604Z",
      },
      deliverectValidationId: "6684248585b4e122ce5e701b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T16:12:13.856Z",
      estimatedDeliveryTime: "2024-07-02T16:49:12.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6684248609647a7c33adfd4c",
      createdAt: "2024-07-02T16:02:13.975Z",
      updatedAt: "2024-07-02T16:02:14.622Z",
    },
    items: [
      {
        productId: "6683c20d09647a7c33928c73",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20d09647a7c33928cc4",
        productPLU: "2166037-1",
        productDescription:
          "C 31g | P 3g | F 8g [%New%]\nTortilla chips, cheddar cheese, & pickled jalapeños. ",
        productName: "Jalapeño Cheddar Nachos ",
        imageURL:
          "https://resizer.deliverect.com/Igtv_X3IIKdvVYw3drGOS6DlDQf47bmEkgol0b9fBZ0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFsYXBlbm8lMjBjaGVlc2UlMjBuYWNob3MtNjY2MWNkZWQ5YWNlYTFmMWZlZWVkOTU5LnBuZw==",
        productCalories: 208,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:02:14.603Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T16:02:41.902Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T16:05:31.323Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T16:11:53.074Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T16:11:53.076Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T16:13:21.336Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T16:31:00.538Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T16:02:14.603Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T16:02:30.244Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T16:02:14.603Z",
    updatedAt: "2024-07-02T16:31:00.906Z",
  },
  {
    id: "6683cfb009647a7c33968ff5",
    sid: "240702-509345",
    coupons: [],
    menuId: "6683c20709647a7c339282f1",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6683cfb009647a7c33968feb",
    firstName: "Khalid ",
    lastName: null,
    phoneNumber: "+96599400374",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "655efac95548ef36933475a4",
    delivery: {
      id: "6683cfb009647a7c33968feb",
      customerId: "655efac95548ef36933475a4",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "655efb3202b2e21f1fa03d5f",
        name: "Office ",
        lng: 47.9932879,
        lat: 29.3790539,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "1",
        street: "Al-Shuhada St",
        building: "Al hamra tower",
        floor: "25",
        flat: "",
        phone: "+96599400374",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-23T07:11:46.446Z",
        updatedAt: "2024-07-02T09:46:48.030Z",
      },
      deliverectValidationId: "6683cfaf0a925aa7828cf7e5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T10:10:15.985Z",
      estimatedDeliveryTime: "2024-07-02T10:47:14.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683cfb009647a7c33968ff5",
      createdAt: "2024-07-02T10:00:16.069Z",
      updatedAt: "2024-07-02T10:00:16.555Z",
    },
    items: [
      {
        productId: "6683c20709647a7c33928397",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20709647a7c33928369",
        productPLU: "2162112-1",
        productDescription:
          "C 64g | P 31g | F 26g[%Popular%]\nFM burger with baked sweet potato fries and bbq sauce",
        productName: "FM burger & fries",
        imageURL:
          "https://resizer.deliverect.com/x99vUapgX87SAwe62HZOV0adVNKcDxeysqBvzjp9D1c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjA2LTY2NDRiMDc0NWM2ZGVmMDFjMzNjM2MyMi5wbmc=",
        productCalories: 614,
        quantity: 1,
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 580,
    totalFmcCentsPrice: 580,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T10:00:16.537Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T10:01:10.960Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T10:02:12.431Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T10:12:24.035Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T10:12:24.036Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T10:12:45.946Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T10:47:50.318Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T10:00:16.537Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T10:00:33.576Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T10:00:16.537Z",
    updatedAt: "2024-07-02T10:47:50.660Z",
  },
  {
    id: "6683cfa909647a7c33968ab8",
    sid: "240702-530262",
    coupons: [],
    menuId: "6683c20709647a7c339282f1",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6683cfa809647a7c33968949",
    firstName: " Yousif albairami",
    lastName: null,
    phoneNumber: "+96593337888",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64b98f7bb38e44d254c2f865",
    delivery: {
      id: "6683cfa809647a7c33968949",
      customerId: "64b98f7bb38e44d254c2f865",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6537fec2289c08cbdbab6427",
        name: "Work",
        lng: 47.97771133482456,
        lat: 29.374332043385714,
        city: "Al Kuwayt",
        area: "Jibla",
        block: "Industrial bank of kuwait",
        street: "51 St",
        building: "Industrial bank of kuwait",
        floor: "",
        flat: "",
        phone: "+96593337888",
        additionalInfo: "Behind macdonalds the building in the middle ",
        isSelected: true,
        createdAt: "2023-10-24T17:28:34.241Z",
        updatedAt: "2024-07-02T06:00:55.451Z",
      },
      deliverectValidationId: "6683cfa85f714554219d1726",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T10:10:08.415Z",
      estimatedDeliveryTime: "2024-07-02T10:47:05.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683cfa909647a7c33968ab8",
      createdAt: "2024-07-02T10:00:08.790Z",
      updatedAt: "2024-07-02T10:00:10.106Z",
    },
    items: [
      {
        productId: "6683c20709647a7c33928361",
        productPLU: "2162118-1",
        productDescription:
          "C 60g | P 22g | F 17g [%New%]\nTomato sauce, cheese & pepperoni\n\nGood source of Protein ",
        productName: "Pepperoni Pizza",
        imageURL:
          "https://resizer.deliverect.com/QJO9SowT2ihHOIWJoe4NojUcvMSrdQhHrE3QE_iNeHs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVwcGVyb25pLTY2NzQzMzkzMjU0YjRkYjE0ZjE5OTk4MS5wbmc=",
        productCalories: 481,
        quantity: 1,
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20709647a7c3392839c",
        productPLU: "2166037-1",
        productDescription:
          "C 31g | P 3g | F 8g [%New%]\nTortilla chips, cheddar cheese, & pickled jalapeños. ",
        productName: "Jalapeño Cheddar Nachos ",
        imageURL:
          "https://resizer.deliverect.com/Igtv_X3IIKdvVYw3drGOS6DlDQf47bmEkgol0b9fBZ0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFsYXBlbm8lMjBjaGVlc2UlMjBuYWNob3MtNjY2MWNkZWQ5YWNlYTFmMWZlZWVkOTU5LnBuZw==",
        productCalories: 208,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20709647a7c339283b1",
        productPLU: "2165004-1",
        productDescription: "C 14g| P 2g| F 7g",
        productName: "Cereal Pistachio Bites",
        imageURL:
          "https://resizer.deliverect.com/IUQyIdvpRwNFUq2ZxWw-qjg3cbiMMp0GETm81Dti0uA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjRiN2JjODRhYzgyOTIwMDJkMzk2MTNkLnBuZw==",
        productCalories: 129,
        quantity: 1,
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20709647a7c339283ce",
        productPLU: "2202020-1",
        productDescription: "C 0g| P 0g| F 0g\nLight",
        productName: "Coca Cola light",
        imageURL:
          "https://resizer.deliverect.com/ohMqXjy5JVCdObHNif9r9s_l7TLtUsT8RYOrg55pTug/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMyUyMCgxKS02NGM5MTk2MGE3NTU1ZjAwMWYzOWNlNjQuanBn",
        productCalories: 2,
        quantity: 1,
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 630,
    totalFmcCentsPrice: 630,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T10:00:09.972Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T10:00:34.045Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T10:02:02.572Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T10:14:59.044Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T10:14:59.044Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T10:15:20.917Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T10:39:10.561Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T10:00:09.972Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T10:00:22.106Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T10:00:09.972Z",
    updatedAt: "2024-07-02T10:39:10.918Z",
  },
  {
    id: "6683cf7d09647a7c339672c2",
    sid: "240702-368957",
    coupons: [],
    menuId: "6683c20709647a7c339282f1",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6683cf7d09647a7c339672b7",
    firstName: "Fatima Khaled AlMudahka",
    lastName: null,
    phoneNumber: "+96599996160",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "650f2f03e1d91353418be244",
    delivery: {
      id: "6683cf7d09647a7c339672b7",
      customerId: "650f2f03e1d91353418be244",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6664113bf4d227595032c153",
        name: "Home",
        lng: 47.99036365002394,
        lat: 29.351098265566222,
        city: "Shuwaikh Industrial",
        area: "Abdullah al-Salem",
        block: "2",
        street: "Homoud Yousif Al-Nusif St",
        building: "27",
        floor: "",
        flat: "",
        phone: "+96599996160",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-08T08:07:23.771Z",
        updatedAt: "2024-06-08T08:07:23.771Z",
      },
      deliverectValidationId: "6683cf7d06a601cc35cfdb9d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T10:09:25.069Z",
      estimatedDeliveryTime: "2024-07-02T10:46:23.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683cf7d09647a7c339672c2",
      createdAt: "2024-07-02T09:59:25.153Z",
      updatedAt: "2024-07-02T09:59:25.839Z",
    },
    items: [
      {
        productId: "6683c20709647a7c33928380",
        productPLU: "2162044-1",
        productDescription:
          "C 46g| P 32g| F 8g\nButter chicken served with white basmati rice and a brocolli garnish with coriander.\n\nGluten free, Good source of Vitamins & Iron.",
        productName: "Butter Chicken",
        imageURL:
          "https://resizer.deliverect.com/kNZO7hSeSAa7aTJWFU1oyK8hGOHaTbLbvvvMD_J1ft0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnV0dGVyJTIwY2hpY2tlbi02NWY2ZWEzZTUxOTBiNjZmYzkxYmQ2MzMuanBn",
        productCalories: 384,
        quantity: 1,
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20709647a7c3392839e",
        productPLU: "2166020-1",
        productDescription:
          "C 13g| P 2g| F 1g\nBeetroots tossed in olive oil, lemon juice and fresh coriander.\n\nGood source of dietary Fibre, Iron, Potassium and Vitamin C\nContains garlic.",
        productName: "Beetroot Pot",
        imageURL:
          "https://resizer.deliverect.com/4QQmAm_YErxn11xFj_N8IRMBlHt61yeQWJR1VR9vwug/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNy02NTEwMzhmNDI2N2RiNTQwNTI2ZTM4MTguanBn",
        productCalories: 70,
        quantity: 1,
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:59:25.819Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T10:02:02.717Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T10:02:02.718Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T10:11:59.947Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T10:11:59.947Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T10:12:23.758Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T10:23:59.335Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:59:25.819Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T09:59:37.379Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T09:59:25.820Z",
    updatedAt: "2024-07-02T10:23:59.676Z",
  },
  {
    id: "6683cf1f09647a7c33966037",
    sid: "240702-088940",
    coupons: [],
    menuId: "6683c20909647a7c339286de",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6683cf1f09647a7c33966029",
    firstName: "Sulaiman Kh",
    lastName: null,
    phoneNumber: "+96599754995",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6523aaadc6da5aa4aeeea2bb",
    delivery: {
      id: "6683cf1f09647a7c33966029",
      customerId: "6523aaadc6da5aa4aeeea2bb",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65fc4a62c7445468ab5b5599",
        name: "Home",
        lng: 48.06690350174904,
        lat: 29.294951025555243,
        city: "Kuwait",
        area: "Bayan",
        block: "10",
        street: "1",
        building: "23",
        floor: "",
        flat: "",
        phone: "+96599754995",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-03-21T14:55:30.677Z",
        updatedAt: "2024-03-21T14:55:30.677Z",
      },
      deliverectValidationId: "6683cf1fbe2b61a501825701",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T10:07:51.046Z",
      estimatedDeliveryTime: "2024-07-02T10:44:49.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683cf1f09647a7c33966037",
      createdAt: "2024-07-02T09:57:51.132Z",
      updatedAt: "2024-07-02T09:57:51.623Z",
    },
    items: [
      {
        productId: "6683c20a09647a7c33928777",
        productPLU: "2162043-1",
        productDescription:
          "C 45g| P 28g| F 16g\nPink pasta with grilled chicken breast, topped with parmesan cheese.\n\nGood source of B-Complex Vitamins",
        productName: "Pink Pasta",
        imageURL:
          "https://resizer.deliverect.com/h10UGlGO3vP_ayu1YBOqijxvB6J8MuhGdH52j7lOn8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGluayUyMHBhc3RhLTY0YjdhZmJlZWUwY2I5MDAyMWRjNWRlOC5wbmc=",
        productCalories: 436,
        quantity: 1,
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20a09647a7c33928796",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 420,
    totalFmcCentsPrice: 420,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:57:51.600Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T09:58:23.039Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T09:58:23.040Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T10:15:22.518Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T10:15:22.518Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T10:15:57.233Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T10:36:29.213Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:57:51.600Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T09:58:07.331Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T09:57:51.600Z",
    updatedAt: "2024-07-02T10:36:29.552Z",
  },
  {
    id: "6683cf1609647a7c33965e44",
    sid: "240702-327619",
    coupons: [],
    menuId: "6683c20d09647a7c33928c1a",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6683cf1509647a7c33965ccb",
    firstName: "Afnan Al Ibrahim",
    lastName: null,
    phoneNumber: "+96591110550",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6683cb6d8c15153ac8a10744",
    delivery: {
      id: "6683cf1509647a7c33965ccb",
      customerId: "6683cb6d8c15153ac8a10744",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6683cc5b09647a7c3395627d",
        name: "Afnan",
        lng: 47.968704141676426,
        lat: 29.304710190802414,
        city: "Kuwait",
        area: "Yarmouk",
        block: "3",
        street: "3",
        building: "17",
        floor: "",
        flat: "",
        phone: "+96591110550",
        additionalInfo: "Door bell on left side of the house ",
        isSelected: true,
        createdAt: "2024-07-02T09:46:03.068Z",
        updatedAt: "2024-07-02T09:46:03.068Z",
      },
      deliverectValidationId: "6683cf14ba89275d9e081501",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T10:07:40.925Z",
      estimatedDeliveryTime: "2024-07-02T10:44:39.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683cf1609647a7c33965e44",
      createdAt: "2024-07-02T09:57:41.029Z",
      updatedAt: "2024-07-02T09:57:42.711Z",
    },
    items: [
      {
        productId: "6683c20d09647a7c33928c86",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20d09647a7c33928c7e",
        productPLU: "2163016-1",
        productDescription:
          "C 35g| P 6g| F 10g\nRocca mixed with grilled zucchini, grilled red bell peppers & grilled corn drizzled in a vinaigrette dressing with coriander.\n\nGOOD SOURCE OF FIBER",
        productName: "Rocca Feta Salad",
        imageURL:
          "https://resizer.deliverect.com/hVjV_VJZKJD7LeDzKwVSVGem1rTZivC1K9bQFd7KnCs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjBTQUxBRC02NTE3ZWUwNjM5NGU3ODMxZTFiZDNiNWEuanBn",
        productCalories: 254,
        quantity: 1,
        fmcCentsPrice: 350,
        fiatCentsPrice: 350,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20d09647a7c33928c94",
        productPLU: "2162110-1",
        productDescription:
          "C 16g | P 39g | F 12g\nChicken Lasagna with zucchini & basil\n\nGood source of Protein, Vitamin A & B12",
        productName: "Zucchini Chicken Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SF1f4yzXFAmy7O5H5pNNhkbFAWle_8S5j0MSAJ6QIzY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGxhc2FnbmEtNjY0MzdhMWQ3YjdlNGViN2M1MjcwN2IwLmpwZw==",
        productCalories: 328,
        quantity: 1,
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 700,
    totalFmcCentsPrice: 700,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:57:42.530Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T09:58:33.599Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T09:58:48.434Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T10:06:08.873Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T10:06:08.873Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "delivered",
        timestamp: "2024-07-02T10:07:48.233Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:57:42.530Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T09:57:55.497Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T09:57:42.530Z",
    updatedAt: "2024-07-02T10:08:42.948Z",
  },
  {
    id: "6683cf0b09647a7c3396564f",
    sid: "240702-918358",
    coupons: [],
    menuId: "6683c20909647a7c339286de",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6683cf0b09647a7c33965646",
    firstName: "Sulaiman Kh",
    lastName: null,
    phoneNumber: "+96599754995",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6523aaadc6da5aa4aeeea2bb",
    delivery: {
      id: "6683cf0b09647a7c33965646",
      customerId: "6523aaadc6da5aa4aeeea2bb",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65fc4a62c7445468ab5b5599",
        name: "Home",
        lng: 48.06690350174904,
        lat: 29.294951025555243,
        city: "Kuwait",
        area: "Bayan",
        block: "10",
        street: "1",
        building: "23",
        floor: "",
        flat: "",
        phone: "+96599754995",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-03-21T14:55:30.677Z",
        updatedAt: "2024-03-21T14:55:30.677Z",
      },
      deliverectValidationId: "6683cf0bb7f37d5af2f30343",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T10:07:31.106Z",
      estimatedDeliveryTime: "2024-07-02T10:44:29.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683cf0b09647a7c3396564f",
      createdAt: "2024-07-02T09:57:31.202Z",
      updatedAt: "2024-07-02T09:57:31.726Z",
    },
    items: [
      {
        productId: "6683c20a09647a7c33928777",
        productPLU: "2162043-1",
        productDescription:
          "C 45g| P 28g| F 16g\nPink pasta with grilled chicken breast, topped with parmesan cheese.\n\nGood source of B-Complex Vitamins",
        productName: "Pink Pasta",
        imageURL:
          "https://resizer.deliverect.com/h10UGlGO3vP_ayu1YBOqijxvB6J8MuhGdH52j7lOn8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGluayUyMHBhc3RhLTY0YjdhZmJlZWUwY2I5MDAyMWRjNWRlOC5wbmc=",
        productCalories: 436,
        quantity: 1,
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:57:31.708Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T09:58:01.550Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T09:58:01.550Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "delivered",
        timestamp: "2024-07-02T10:03:12.057Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:57:31.708Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T09:57:43.168Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T09:57:31.708Z",
    updatedAt: "2024-07-02T10:09:44.365Z",
  },
  {
    id: "6683cedd09647a7c3396423d",
    sid: "240702-753288",
    coupons: [],
    menuId: "6683c20909647a7c339286de",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6683cedd09647a7c3396422f",
    firstName: "Mohammad alabduljalil",
    lastName: null,
    phoneNumber: "+96594932323",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6523b085c6da5aa4aeeeb3e5",
    delivery: {
      id: "6683cedd09647a7c3396422f",
      customerId: "6523b085c6da5aa4aeeeb3e5",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "663132847eeff096a028d52d",
        name: "Home",
        lng: 47.99098391085863,
        lat: 29.324694428686374,
        city: "Kuwait",
        area: "Adailiya",
        block: "3",
        street: "Essa Abdulrahman Al Asousi St",
        building: "9b",
        floor: "1",
        flat: "2",
        phone: "+96594932323",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-30T18:03:48.999Z",
        updatedAt: "2024-06-08T17:17:24.569Z",
      },
      deliverectValidationId: "6683cedcd3ae55e4d2c9be41",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T10:06:44.918Z",
      estimatedDeliveryTime: "2024-07-02T10:43:43.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683cedd09647a7c3396423d",
      createdAt: "2024-07-02T09:56:45.012Z",
      updatedAt: "2024-07-02T09:56:45.576Z",
    },
    items: [
      {
        productId: "6683c20a09647a7c33928783",
        productPLU: "2168002-1",
        productDescription:
          "C 4g | P 2g | F 2g|\nCreamy mushroom soup topped with parsley. ",
        productName: "Mushroom Soup",
        imageURL:
          "https://resizer.deliverect.com/VvtgR2CAH02ajyt1L7X3df8XtKjNQ2Kx-d7hCJY0Q1w/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBzb3VwLTY2NDM3YTg5ZTFhZTBmYmZhZDYyYmVhZC5qcGc=",
        productCalories: 42,
        quantity: 1,
        fmcCentsPrice: 140,
        fiatCentsPrice: 140,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20a09647a7c33928764",
        productPLU: "2162082-1",
        productDescription:
          "C 44g| P 39g| F 6g\nChicken breast with baked eggplant, cauliflower, onions, carrots, red bell peppers in rice with CORIANDER\n\nGOOD SOURCE OF PROTEIN,VITAMIN A &C",
        productName: "Maqlouba",
        imageURL:
          "https://resizer.deliverect.com/ImlBCJvVJcyYJBWth7S7FEgLNUj1i0B0vhTLvEmLoDY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NjQ0YmNjMzc0OGYzNjdjMTMxNmU2ODgucG5n",
        productCalories: 386,
        quantity: 1,
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:56:45.547Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T09:57:02.988Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T09:57:12.401Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T10:07:47.891Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T10:07:47.891Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T10:13:42.443Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T10:29:42.976Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:56:45.547Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T09:56:57.019Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T09:56:45.548Z",
    updatedAt: "2024-07-02T10:29:43.379Z",
  },
  {
    id: "6683cec009647a7c33963bac",
    sid: "240702-966477",
    coupons: [],
    menuId: "6683c20709647a7c339282f1",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6683cebe09647a7c339639d2",
    firstName: "Ebrahim Alfawaz",
    lastName: null,
    phoneNumber: "+96599951865",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65abc53597e5f13760b58894",
    delivery: {
      id: "6683cebe09647a7c339639d2",
      customerId: "65abc53597e5f13760b58894",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65abc64f1c5f840f7ccfbe69",
        name: "Dawam",
        lng: 47.961226142942905,
        lat: 29.362261753619777,
        city: "Kuwait City",
        area: "Jibla",
        block: "14",
        street: "Abu bakir ",
        building: "Alhamad Tower C",
        floor: "1st floor",
        flat: "",
        phone: "+96599951865",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-20T13:10:39.649Z",
        updatedAt: "2024-07-02T09:54:18.446Z",
      },
      deliverectValidationId: "6683cebe06a601cc35cf83d1",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T10:06:14.680Z",
      estimatedDeliveryTime: "2024-07-02T10:43:13.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683cec009647a7c33963bac",
      createdAt: "2024-07-02T09:56:14.785Z",
      updatedAt: "2024-07-02T09:56:16.792Z",
    },
    items: [
      {
        productId: "6683c20709647a7c3392837e",
        productPLU: "2162085-1",
        productDescription:
          "C 16g| P 46g| F 14g\nGrilled chicken served with mashed potatoes, blanched broccoli, cauliflower & carrots, served with a lemon sauce garnished with a slice of lemon and parsley.\n\nGood source of Protein",
        productName: "Grilled Lemon Chicken",
        imageURL:
          "https://resizer.deliverect.com/n9S1sQV2m-69QdAwmCpIpZWcf6LGGUpyB0bFdTHRC1U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3JpbGxlZCUyMGxlbW9uJTIwY2hpY2tlbigxKS02NTU0OTA2MWZhNTNkZDAwMWVlOTQ2N2IucG5n",
        productCalories: 375,
        quantity: 1,
        fmcCentsPrice: 400,
        fiatCentsPrice: 270,
        remark: "",
        subItems: [
          {
            productId: "6683c20709647a7c33928421",
            productPLU: "1907063-1",
            productName: "No Mashed Potato",
            productDescription: "C -9g| P -1g| F -1g| cal -51| coins 0",
            productCalories: -51,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
          },
          {
            productId: "6683c20709647a7c33928420",
            productPLU: "1906046-1",
            productName: "Extra  Grilled Chicken",
            productDescription: "C 0.4g| P 42g| F 4g| cal 208| coins 1.3",
            productCalories: 208,
            quantity: 1,
            fmcCentsPrice: 130,
            fiatCentsPrice: 130,
          },
        ],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:56:16.777Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T10:00:30.137Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T10:01:59.731Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T10:13:09.096Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T10:13:09.096Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T10:13:31.249Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T10:20:52.556Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:56:16.777Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T09:56:40.125Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T09:56:16.777Z",
    updatedAt: "2024-07-02T10:20:52.911Z",
  },
  {
    id: "6683cebb09647a7c3396390e",
    sid: "240702-236513",
    coupons: [],
    menuId: "6683c20d09647a7c33928c1a",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6683ceba09647a7c33963901",
    firstName: "Afnan Al Ibrahim",
    lastName: null,
    phoneNumber: "+96591110550",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6683cb6d8c15153ac8a10744",
    delivery: {
      id: "6683ceba09647a7c33963901",
      customerId: "6683cb6d8c15153ac8a10744",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6683cc5b09647a7c3395627d",
        name: "Afnan",
        lng: 47.968704141676426,
        lat: 29.304710190802414,
        city: "Kuwait",
        area: "Yarmouk",
        block: "3",
        street: "3",
        building: "17",
        floor: "",
        flat: "",
        phone: "+96591110550",
        additionalInfo: "Door bell on left side of the house ",
        isSelected: true,
        createdAt: "2024-07-02T09:46:03.068Z",
        updatedAt: "2024-07-02T09:46:03.068Z",
      },
      deliverectValidationId: "6683ceba963fc85d73a09890",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T10:06:10.875Z",
      estimatedDeliveryTime: "2024-07-02T10:43:09.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683cebb09647a7c3396390e",
      createdAt: "2024-07-02T09:56:10.971Z",
      updatedAt: "2024-07-02T09:56:11.636Z",
    },
    items: [
      {
        productId: "6683c20d09647a7c33928c94",
        productPLU: "2162110-1",
        productDescription:
          "C 16g | P 39g | F 12g\nChicken Lasagna with zucchini & basil\n\nGood source of Protein, Vitamin A & B12",
        productName: "Zucchini Chicken Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SF1f4yzXFAmy7O5H5pNNhkbFAWle_8S5j0MSAJ6QIzY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGxhc2FnbmEtNjY0MzdhMWQ3YjdlNGViN2M1MjcwN2IwLmpwZw==",
        productCalories: 328,
        quantity: 1,
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20d09647a7c33928c7e",
        productPLU: "2163016-1",
        productDescription:
          "C 35g| P 6g| F 10g\nRocca mixed with grilled zucchini, grilled red bell peppers & grilled corn drizzled in a vinaigrette dressing with coriander.\n\nGOOD SOURCE OF FIBER",
        productName: "Rocca Feta Salad",
        imageURL:
          "https://resizer.deliverect.com/hVjV_VJZKJD7LeDzKwVSVGem1rTZivC1K9bQFd7KnCs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjBTQUxBRC02NTE3ZWUwNjM5NGU3ODMxZTFiZDNiNWEuanBn",
        productCalories: 254,
        quantity: 1,
        fmcCentsPrice: 350,
        fiatCentsPrice: 350,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20d09647a7c33928c86",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 700,
    totalFmcCentsPrice: 700,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:56:11.617Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T09:57:02.548Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T09:58:43.116Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T10:04:07.754Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T10:04:07.754Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T10:16:22.331Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T10:35:22.691Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:56:11.617Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T09:56:28.743Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T09:56:11.617Z",
    updatedAt: "2024-07-02T10:35:23.144Z",
  },
  {
    id: "6683ceb609647a7c33963744",
    sid: "240702-520346",
    coupons: [],
    menuId: "6683c20709647a7c339282f1",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6683ceb509647a7c33963606",
    firstName: "Mona alfahad ",
    lastName: null,
    phoneNumber: "+96599994798",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "662f560c7ef16a176ee63c44",
    delivery: {
      id: "6683ceb509647a7c33963606",
      customerId: "662f560c7ef16a176ee63c44",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "662f5912c37a2a2c266eb20c",
        name: "Work",
        lng: 47.97509031370282,
        lat: 29.372351203698372,
        city: "Al Kuwayt",
        area: "Jibla",
        block: "10",
        street: "Ahmad Al Jaber St",
        building: "Abk",
        floor: "",
        flat: "",
        phone: "+96599994798",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-29T08:23:46.271Z",
        updatedAt: "2024-07-01T09:42:09.067Z",
      },
      deliverectValidationId: "6683ceb462979c0b736cec59",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T10:06:04.879Z",
      estimatedDeliveryTime: "2024-07-02T10:43:03.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683ceb609647a7c33963744",
      createdAt: "2024-07-02T09:56:05.085Z",
      updatedAt: "2024-07-02T09:56:06.338Z",
    },
    items: [
      {
        productId: "6683c20709647a7c339283a5",
        productPLU: "2166009-1",
        productDescription:
          "C 22g| P 11g| F 13g\nMango greek yogurt with chia pudding topped with mangoes.\n\nGood source oF Vitamin A",
        productName: "Mango Greek Yogurt",
        imageURL:
          "https://resizer.deliverect.com/xGontUyo2rWspjDzY7LiTodlo5Gzmv5FuI0NOV_lXRI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNS02NTExNmVjYTM5NGU3ODMxZTFiZDM3MWUuanBn",
        productCalories: 249,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683c20709647a7c33928363",
        productPLU: "2162119-1",
        productDescription:
          "C 61g | P 30g | F 15g [%New%]\nWhite sauce, cheese, chicken & ranch.\n\nGood source of Protein ",
        productName: "Chicken Ranch Pizza",
        imageURL:
          "https://resizer.deliverect.com/pOgi343_UbyaTdgs3CsFQ3-UsGlbLpc3aSBCJo-QO7g/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmFuY2gtNjY2YjMwODA1MGM0ODZkNGMzYTJkOGMzLnBuZw==",
        productCalories: 499,
        quantity: 1,
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 460,
    totalFmcCentsPrice: 460,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:56:06.318Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T09:56:52.869Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T10:00:54.316Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T10:13:55.722Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T10:13:55.722Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T10:14:58.953Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T10:33:08.947Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T09:56:06.318Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T09:56:19.010Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T09:56:06.318Z",
    updatedAt: "2024-07-02T10:33:09.500Z",
  },
  {
    id: "6683ae6909647a7c3390327d",
    sid: "240702-512688",
    coupons: [],
    menuId: "6683747909647a7c338a63ec",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6683ae6809647a7c33903273",
    firstName: "Dalal bouresli",
    lastName: null,
    phoneNumber: "+96594901415",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65069276e1d9135341849130",
    delivery: {
      id: "6683ae6809647a7c33903273",
      customerId: "65069276e1d9135341849130",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "650b20380a91a2efcd3fd6f8",
        name: "Home",
        lng: 48.04159618914127,
        lat: 29.302738472590093,
        city: "822R+MFM،",
        area: "Bayan",
        block: "3",
        street: "2",
        building: "16",
        floor: "",
        flat: "",
        phone: "+96594901415",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-09-20T16:39:20.425Z",
        updatedAt: "2024-06-30T11:56:50.272Z",
      },
      deliverectValidationId: "6683ae68d3ae55e4d2bac7a4",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T07:48:16.333Z",
      estimatedDeliveryTime: "2024-07-02T08:25:14.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683ae6909647a7c3390327d",
      createdAt: "2024-07-02T07:38:16.421Z",
      updatedAt: "2024-07-02T07:38:17.107Z",
    },
    items: [
      {
        productId: "6683747909647a7c338a6421",
        productPLU: "2167023-1",
        productDescription:
          "C 29g | P 22g | F 16g\nScrambled eggs with mushroom, green olives and feta cheese in a tortilla wrap\n\nGOOD SOURCE OF PROTEIN, MINERALS",
        productName: "Mushroom egg wrap",
        imageURL:
          "https://resizer.deliverect.com/icCk6VyiEI1LioDbIHa0TjQikuisfIyU0FZUoDvsxdY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAyLTY2M2ZmM2Q0ZGI0ZjMyZjZhNTZmMGY4MC5wbmc=",
        productCalories: 348,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683747909647a7c338a6479",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:38:17.075Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T07:39:04.842Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T07:46:36.845Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T07:46:36.845Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T07:46:36.845Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T07:49:00.842Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T08:10:43.731Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:38:17.075Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T07:38:28.641Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T07:38:17.075Z",
    updatedAt: "2024-07-02T08:10:44.133Z",
  },
  {
    id: "6683ae2d09647a7c33902b5d",
    sid: "240702-438687",
    coupons: [],
    menuId: "6683747e09647a7c338a69d7",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6683ae2c09647a7c33902b4f",
    firstName: "Muhammad Jallad",
    lastName: null,
    phoneNumber: "+96596699040",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65057eaee1d9135341837fb7",
    delivery: {
      id: "6683ae2c09647a7c33902b4f",
      customerId: "65057eaee1d9135341837fb7",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6533f3b6289c08cbdb935295",
        name: "Work",
        lng: 47.93527811765671,
        lat: 29.30785054032826,
        city: "Kuwait",
        area: "Rai",
        block: "4",
        street: "Hamad Al Alban",
        building: "Gulf Aluminum - near shaker shawrma",
        floor: "Mizzanine",
        flat: "",
        phone: "+96596699040",
        additionalInfo: "Shawrma shaker building ",
        isSelected: true,
        createdAt: "2023-10-21T15:52:22.279Z",
        updatedAt: "2024-07-02T07:26:33.025Z",
      },
      deliverectValidationId: "6683ae2c35273be22c0c2ff7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T07:47:16.438Z",
      estimatedDeliveryTime: "2024-07-02T08:24:15.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683ae2d09647a7c33902b5d",
      createdAt: "2024-07-02T07:37:16.532Z",
      updatedAt: "2024-07-02T07:37:17.098Z",
    },
    items: [
      {
        productId: "6683747e09647a7c338a6a01",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683747e09647a7c338a6a14",
        productPLU: "2167019-1",
        productDescription:
          "C 38g| P 13g| F 23g\nSmoked turkey with mozzarella and beetroot cranberry sauce in croissant\n\nGood source of B-complex Vitamins",
        productName: "Turkey Cranberry Croissant",
        imageURL:
          "https://resizer.deliverect.com/SiaHO94ArFIj3uWBLTgIfGyblLzwSygKG5jgjGg5Fco/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvdHVya2V5JTIwY3JhbmJlcnJ5JTIwc2FuZHdpY2gtNjU1NDhmMDhhMzhiZTYwMDI1M2RiNTlhLnBuZw==",
        productCalories: 411,
        quantity: 2,
        fmcCentsPrice: 180,
        fiatCentsPrice: 180,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683747e09647a7c338a6a08",
        productPLU: "2167014-1",
        productDescription:
          "C 32g| P 14g| F 8g\nEnglish muffin with egg whites, blanched spinach, mozzarella cheese and our breakfast sauce.",
        productName: "FM Egg Muffin",
        imageURL:
          "https://resizer.deliverect.com/UDWwpJ2NagrTgNTXcD-YQp6Hbon4KgO_xVwUVvV2eKE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NGJmYTJlMzE0ODFjMTAwMjZjYTEwNTkucG5n",
        productCalories: 256,
        quantity: 1,
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 730,
    totalFmcCentsPrice: 730,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:37:17.077Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T07:38:42.857Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T07:46:04.255Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T07:53:30.809Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T07:53:30.810Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T07:58:57.592Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T08:29:47.138Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:37:17.077Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T07:37:28.521Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T07:37:17.077Z",
    updatedAt: "2024-07-02T08:29:47.478Z",
  },
  {
    id: "6683ae1e09647a7c33902a49",
    sid: "240702-865856",
    coupons: [],
    menuId: "6683747d09647a7c338a67e2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6683ae1e09647a7c33902a3c",
    firstName: "Ali m",
    lastName: null,
    phoneNumber: "+96555118899",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65ae681d97e5f13760b7c90e",
    delivery: {
      id: "6683ae1e09647a7c33902a3c",
      customerId: "65ae681d97e5f13760b7c90e",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65e570da733cd68442d36eb3",
        name: "Touristic enterprise company ",
        lng: 47.94981703162193,
        lat: 29.3452778443621,
        city: "Kuwait",
        area: "Shuwaikh Administrative",
        block: "0",
        street: "0",
        building: "0",
        floor: "0",
        flat: "0",
        phone: "+96555118899",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-03-04T06:57:30.141Z",
        updatedAt: "2024-07-02T07:36:22.844Z",
      },
      deliverectValidationId: "6683ae1ed95f84b0b2e3e4bd",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T07:47:02.418Z",
      estimatedDeliveryTime: "2024-07-02T08:24:01.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683ae1e09647a7c33902a49",
      createdAt: "2024-07-02T07:37:02.507Z",
      updatedAt: "2024-07-02T07:37:03.013Z",
    },
    items: [
      {
        productId: "6683747d09647a7c338a6828",
        productPLU: "2166033-1",
        productDescription:
          "C 28g| P 15g| F 12g\nSpicy crispy shrimp bites served with ranch sauce.\n\nGood source of Protein, Vitamin B12 and Iron",
        productName: "Buffalo Shrimp Bites",
        imageURL:
          "https://resizer.deliverect.com/rvAuXhjdUl1okUgfHDTv8pSQt2Rjhn13XhdwXCPIEyw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnVmZmFsbyUyMHNocmltcCUyMGJpdGVzLTY1Y2IxNGU4Zjc3ODBhZDhlOTQyYWZlMy5wbmc=",
        productCalories: 280,
        quantity: 1,
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683747d09647a7c338a6829",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683747d09647a7c338a682b",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 510,
    totalFmcCentsPrice: 510,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:37:02.994Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T07:37:24.572Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T07:37:57.325Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T07:56:57.457Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T07:56:57.458Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T07:57:19.491Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T08:19:57.476Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:37:02.994Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T07:37:14.600Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T07:37:02.994Z",
    updatedAt: "2024-07-02T08:19:57.818Z",
  },
  {
    id: "6683adcc09647a7c33902062",
    sid: "240702-009159",
    coupons: [],
    menuId: "6683747e09647a7c338a69d7",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6683adcb09647a7c3390205a",
    firstName: "Abdulaziz Alhashel",
    lastName: null,
    phoneNumber: "+96555297947",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66747875da6a8fa4306e129e",
    delivery: {
      id: "6683adcb09647a7c3390205a",
      customerId: "66747875da6a8fa4306e129e",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "667a808513bc9a8f172d94b3",
        name: "Aziz home",
        lng: 47.964231222867966,
        lat: 29.312379410078268,
        city: "Kuwait",
        area: "Yarmouk",
        block: "4",
        street: "5 St",
        building: "House No. 29B",
        floor: "",
        flat: "",
        phone: "+96555297947",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-25T08:32:05.371Z",
        updatedAt: "2024-07-02T07:34:27.848Z",
      },
      deliverectValidationId: "6683adcbba47b39c373a1edf",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T07:45:39.537Z",
      estimatedDeliveryTime: "2024-07-02T08:22:37.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683adcc09647a7c33902062",
      createdAt: "2024-07-02T07:35:39.820Z",
      updatedAt: "2024-07-02T07:35:40.358Z",
    },
    items: [
      {
        productId: "6683747e09647a7c338a6a07",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 2,
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 260,
    totalFmcCentsPrice: 260,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:35:40.341Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T07:36:28.547Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T07:45:58.114Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T07:46:06.448Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T07:46:06.448Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T07:59:20.564Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T08:10:17.702Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:35:40.341Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T07:35:51.744Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T07:35:40.341Z",
    updatedAt: "2024-07-02T08:10:18.052Z",
  },
  {
    id: "6683adb909647a7c33901df0",
    sid: "240702-894530",
    coupons: [],
    menuId: "6683747d09647a7c338a67e2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6683adb809647a7c33901de8",
    firstName: "Noura Alaqeeli",
    lastName: null,
    phoneNumber: "+96599746615",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "667817ce8c15153ac8835c77",
    delivery: {
      id: "6683adb809647a7c33901de8",
      customerId: "667817ce8c15153ac8835c77",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "667929bcbed556ad8fa9eb51",
        name: "Work",
        lng: 47.95464064925909,
        lat: 29.318671115803266,
        city: "Shuwaikh Industrial",
        area: "Shuwaikh Industrial",
        block: "2",
        street: "Street 29",
        building: "2",
        floor: "",
        flat: "",
        phone: "+96599746615",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-24T08:09:32.716Z",
        updatedAt: "2024-07-02T07:29:00.575Z",
      },
      deliverectValidationId: "6683adb85972f622b10e37b7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T07:45:20.424Z",
      estimatedDeliveryTime: "2024-07-02T08:22:19.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683adb909647a7c33901df0",
      createdAt: "2024-07-02T07:35:20.519Z",
      updatedAt: "2024-07-02T07:35:21.269Z",
    },
    items: [
      {
        productId: "6683747d09647a7c338a680b",
        productPLU: "2161021-1",
        productDescription:
          "C 16g| P 15g| F 16g\nEggs, turkish soudjouk, muhammara & labneh\n\nGOOD SOURCE OF PROTEIN",
        productName: "Turkish Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/-B_481BG16xkyYtWQaVbIvD-9tkBN12twZodyhjhhEA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2lzaCUyMGJyZWFrZmFzdCUyMHRhY29zKDEpLTY1NTQ4ZjU3YWI3MmIwMDAyY2I5ZTMyNi5wbmc=",
        productCalories: 268,
        quantity: 1,
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 260,
    totalFmcCentsPrice: 260,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:35:21.250Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T07:36:08.560Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T07:37:51.941Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T07:51:25.008Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T07:51:25.008Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T07:51:46.893Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T08:02:25.193Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:35:21.250Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T07:35:32.797Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T07:35:21.250Z",
    updatedAt: "2024-07-02T08:02:25.545Z",
  },
  {
    id: "6683ad4a09647a7c33900550",
    sid: "240702-159596",
    coupons: [],
    menuId: "6683747d09647a7c338a67e2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6683ad4a09647a7c33900542",
    firstName: "Hana B",
    lastName: null,
    phoneNumber: "+96597881188",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64a827de54365c63a997c60d",
    delivery: {
      id: "6683ad4a09647a7c33900542",
      customerId: "64a827de54365c63a997c60d",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64a970427c8d5486a78195ca",
        name: "Boodai",
        lng: 47.97058564281289,
        lat: 29.3447265625,
        city: "Al Kuwayt",
        area: "Shamiya",
        block: "9",
        street: "94",
        building: "8",
        floor: "",
        flat: "",
        phone: "+96597881188",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-07-08T14:18:42.211Z",
        updatedAt: "2023-10-03T07:11:22.514Z",
      },
      deliverectValidationId: "6683ad4a3eb4cd061906db9a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T07:43:30.149Z",
      estimatedDeliveryTime: "2024-07-02T08:20:29.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683ad4a09647a7c33900550",
      createdAt: "2024-07-02T07:33:30.237Z",
      updatedAt: "2024-07-02T07:33:30.653Z",
    },
    items: [
      {
        productId: "6683747d09647a7c338a680c",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683747d09647a7c338a680e",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683747d09647a7c338a6838",
        productPLU: "2166008-1",
        productDescription:
          "C 30g| P 13g| F 16g\nGreek yogurt with peanut butter, raspberry, blueberry, blackberry topped with honey and granola.\n\nGood source of Vitamins",
        productName: "Berry Parfait",
        imageURL:
          "https://resizer.deliverect.com/0p2ShlK1ih7A2WUCAl2RWW19NH0UKIi5XafIY_FXOQc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NTExNmVkYTVkMWVjODc5MjQ0NDE3N2UuanBn",
        productCalories: 316,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 630,
    totalFmcCentsPrice: 630,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:33:30.633Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T07:35:16.775Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T07:37:40.965Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T07:56:36.343Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T07:56:36.344Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T07:56:57.231Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T08:04:55.746Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:33:30.633Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T07:33:42.150Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T07:33:30.633Z",
    updatedAt: "2024-07-02T08:04:56.081Z",
  },
  {
    id: "6683ad2b09647a7c339000bf",
    sid: "240702-103039",
    coupons: [],
    menuId: "6683747d09647a7c338a67e2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6683ad2b09647a7c33900049",
    firstName: "Jassem alwazzan",
    lastName: null,
    phoneNumber: "+96597523434",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "661f4a8ab0d178e63207ddc9",
    delivery: {
      id: "6683ad2b09647a7c33900049",
      customerId: "661f4a8ab0d178e63207ddc9",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "662c8f92c37a2a2c26ef7f81",
        name: "Al tujjar tower",
        lng: 47.98062002286315,
        lat: 29.37030472802529,
        city: "Kuwait City",
        area: "Mirqab",
        block: "2",
        street: "Abdulaziz Hamad Al Saqer St",
        building: "49",
        floor: "2",
        flat: "22",
        phone: "+96597523434",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-27T05:39:30.101Z",
        updatedAt: "2024-07-02T07:30:47.990Z",
      },
      deliverectValidationId: "6683ad2a06a601cc35c09bd6",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T07:42:58.733Z",
      estimatedDeliveryTime: "2024-07-02T08:19:57.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683ad2b09647a7c339000bf",
      createdAt: "2024-07-02T07:32:59.010Z",
      updatedAt: "2024-07-02T07:32:59.863Z",
    },
    items: [
      {
        productId: "6683747d09647a7c338a680c",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683747d09647a7c338a6837",
        productPLU: "2166009-1",
        productDescription:
          "C 22g| P 11g| F 13g\nMango greek yogurt with chia pudding topped with mangoes.\n\nGood source oF Vitamin A",
        productName: "Mango Greek Yogurt",
        imageURL:
          "https://resizer.deliverect.com/xGontUyo2rWspjDzY7LiTodlo5Gzmv5FuI0NOV_lXRI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNS02NTExNmVjYTM5NGU3ODMxZTFiZDM3MWUuanBn",
        productCalories: 249,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:32:59.843Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T07:33:49.985Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T07:37:35.945Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T07:45:23.793Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T07:45:23.796Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T07:45:45.663Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T07:58:35.482Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:32:59.843Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T07:33:11.396Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T07:32:59.843Z",
    updatedAt: "2024-07-02T07:58:35.827Z",
  },
  {
    id: "6683aca609647a7c338ff07e",
    sid: "240702-409837",
    coupons: [],
    menuId: "6683747d09647a7c338a67e2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6683aca509647a7c338ff073",
    firstName: "alyaa al alawi",
    lastName: null,
    phoneNumber: "+96599936676",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d98f62dfecd0088b52c218",
    delivery: {
      id: "6683aca509647a7c338ff073",
      customerId: "64d98f62dfecd0088b52c218",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65c9d780106ad03db14fdcf4",
        name: "work",
        lng: 47.9895037,
        lat: 29.374429,
        city: "Al Kuwayt",
        area: "Mirqab",
        block: "3",
        street: "Khalid Ibn Al Waleed St",
        building: "mazaya tower 2",
        floor: "6",
        flat: "6",
        phone: "+96599936676",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-02-12T08:32:00.188Z",
        updatedAt: "2024-06-03T08:48:57.901Z",
      },
      deliverectValidationId: "6683aca5b34a794491510e72",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T07:40:45.771Z",
      estimatedDeliveryTime: "2024-07-02T08:17:44.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683aca609647a7c338ff07e",
      createdAt: "2024-07-02T07:30:45.853Z",
      updatedAt: "2024-07-02T07:30:46.347Z",
    },
    items: [
      {
        productId: "6683747d09647a7c338a683f",
        productPLU: "2165018-1",
        productDescription:
          "C 11g | P 6g | F 10g [%New%]\nOats, walnuts, peanut butter, dark chocolate",
        productName: "Dark choco energy ball",
        imageURL:
          "https://resizer.deliverect.com/69TNdx88od-x4FEUhcpgmN6UNz91dtK9gL-M2fzELt4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZGFyayUyMGNob2NvJTIwZW5lcmd5JTIwYmFsbHMtNjY1YzhmOGNkMmQ3Y2NmYjNhMWQyNmUxLnBuZw==",
        productCalories: 160,
        quantity: 1,
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683747d09647a7c338a6815",
        productPLU: "2167023-1",
        productDescription:
          "C 29g | P 22g | F 16g\nScrambled eggs with mushroom, green olives and feta cheese in a tortilla wrap\n\nGOOD SOURCE OF PROTEIN, MINERALS",
        productName: "Mushroom egg wrap",
        imageURL:
          "https://resizer.deliverect.com/icCk6VyiEI1LioDbIHa0TjQikuisfIyU0FZUoDvsxdY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAyLTY2M2ZmM2Q0ZGI0ZjMyZjZhNTZmMGY4MC5wbmc=",
        productCalories: 348,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:30:46.329Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T07:31:18.182Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T07:31:35.653Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T07:45:01.443Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T07:45:01.443Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T07:45:23.450Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T08:03:42.983Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:30:46.329Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T07:31:00.643Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T07:30:46.329Z",
    updatedAt: "2024-07-02T08:03:43.330Z",
  },
  {
    id: "6683ac9d09647a7c338fee7c",
    sid: "240702-274577",
    coupons: [],
    menuId: "6683747b09647a7c338a65e4",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6683ac9c09647a7c338fee0a",
    firstName: "Ahmad Alhusaini ",
    lastName: null,
    phoneNumber: "+96566596194",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c958ff75a6aa442d12a1b8",
    delivery: {
      id: "6683ac9c09647a7c338fee0a",
      customerId: "64c958ff75a6aa442d12a1b8",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66790d0cbed556ad8fa6747a",
        name: "Work",
        lng: 48.058380112051964,
        lat: 29.088355367183084,
        city: "Ahmadi",
        area: "South Al Ahmadi",
        block: "9",
        street: "W 6th avenue",
        building: "Ex-Data managment building",
        floor: "",
        flat: "",
        phone: "+96566596194",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-24T06:07:08.098Z",
        updatedAt: "2024-07-02T07:27:52.991Z",
      },
      deliverectValidationId: "6683ac9cfcba48c1e65229ae",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T07:40:36.195Z",
      estimatedDeliveryTime: "2024-07-02T08:17:34.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683ac9d09647a7c338fee7c",
      createdAt: "2024-07-02T07:30:36.275Z",
      updatedAt: "2024-07-02T07:30:37.046Z",
    },
    items: [
      {
        productId: "6683747b09647a7c338a6610",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683747b09647a7c338a6639",
        productPLU: "2166009-1",
        productDescription:
          "C 22g| P 11g| F 13g\nMango greek yogurt with chia pudding topped with mangoes.\n\nGood source oF Vitamin A",
        productName: "Mango Greek Yogurt",
        imageURL:
          "https://resizer.deliverect.com/xGontUyo2rWspjDzY7LiTodlo5Gzmv5FuI0NOV_lXRI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNS02NTExNmVjYTM5NGU3ODMxZTFiZDM3MWUuanBn",
        productCalories: 249,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:30:37.027Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T07:31:22.541Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T07:34:26.180Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T07:43:28.169Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T07:43:28.170Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T08:01:18.166Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T08:31:24.058Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:30:37.027Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T07:30:48.599Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T07:30:37.027Z",
    updatedAt: "2024-07-02T08:31:24.470Z",
  },
  {
    id: "6683ac8209647a7c338feb91",
    sid: "240702-834462",
    coupons: [],
    menuId: "6683747e09647a7c338a69d7",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6683ac8109647a7c338feaf9",
    firstName: "Jasem Alnajadah",
    lastName: null,
    phoneNumber: "+96569679655",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "665df9730eba0d08a573d616",
    delivery: {
      id: "6683ac8109647a7c338feaf9",
      customerId: "665df9730eba0d08a573d616",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "665dfa313d5f2b755de1a3c2",
        name: "Home",
        lng: 47.988187260062034,
        lat: 29.290847778320312,
        city: "Kuwait",
        area: "Al-Siddeeq",
        block: "5",
        street: "515",
        building: "70",
        floor: "3",
        flat: "3",
        phone: "+96569679655",
        additionalInfo: "Left sude door",
        isSelected: true,
        createdAt: "2024-06-03T17:15:29.679Z",
        updatedAt: "2024-07-01T13:03:49.374Z",
      },
      deliverectValidationId: "6683ac8190759e807964e475",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-02T07:40:09.289Z",
      estimatedDeliveryTime: "2024-07-02T08:17:08.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6683ac8209647a7c338feb91",
      createdAt: "2024-07-02T07:30:09.395Z",
      updatedAt: "2024-07-02T07:30:10.163Z",
    },
    items: [
      {
        productId: "6683747e09647a7c338a6a19",
        productPLU: "2167020-1",
        productDescription:
          "C 44g| P 18g| F 16g\nMultigrain bread with pesto sauce, mozzarella and roasted cherry tomato.\n\nGood source of Protein & B-complex Vitamins.",
        productName: "Mozzarella Pesto Sandwich",
        imageURL:
          "https://resizer.deliverect.com/MTQ1UyiHq3wn_f2uWaa75yYeZS3COeKkS1PRcwo5UP0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvOC02NGJmYTRlZmI4MzczNjAwMTg5MGY0NDEucG5n",
        productCalories: 392,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "",
        subItems: [],
      },
      {
        productId: "6683747e09647a7c338a6a26",
        productPLU: "2166016-1",
        productDescription:
          "C 23g| P 7g| F 11g\nEdamame with spicy aoili sauce.\n\nGood source of Dietary fibre",
        productName: "Edamame",
        imageURL:
          "https://resizer.deliverect.com/M_MarA40YziTUu2xEfXvbnV7HlmJJ3vSOFpeOcKWcuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNy02NTExNmUyZDRmNWNlOTI2ZTU5MTQxMjguanBn",
        productCalories: 220,
        quantity: 1,
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 320,
    totalFmcCentsPrice: 320,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:30:10.141Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-02T07:30:56.028Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-02T07:33:41.174Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-02T07:38:21.056Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-02T07:38:21.057Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-02T07:39:52.590Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-02T07:51:58.440Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-02T07:30:10.141Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-02T07:30:21.769Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-02T07:30:10.141Z",
    updatedAt: "2024-07-02T07:51:58.804Z",
  },
  {
    id: "66827d9f09647a7c3355903e",
    sid: "240701-038870",
    coupons: [],
    menuId: "668270a809647a7c33514a87",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "66827d9d09647a7c33558f15",
    firstName: "Dalal Sulaiman",
    lastName: null,
    phoneNumber: "+96599133854",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "654e267126da1b2cf2095f7f",
    delivery: {
      id: "66827d9d09647a7c33558f15",
      customerId: "654e267126da1b2cf2095f7f",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "656b086a521b44fb94a4b132",
        name: "Home",
        lng: 47.994733757118155,
        lat: 29.289031982421875,
        city: "Kuwait",
        area: "Al-Siddeeq",
        block: "6",
        street: "602",
        building: "11 B",
        floor: "2",
        flat: "3",
        phone: "+96599133854",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-02T10:35:22.414Z",
        updatedAt: "2023-12-02T10:35:22.414Z",
      },
      deliverectValidationId: "66827d9d5c222d8a3ed55e2d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T10:07:49.812Z",
      estimatedDeliveryTime: "2024-07-01T10:44:48.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66827d9f09647a7c3355903e",
      createdAt: "2024-07-01T09:57:49.894Z",
      updatedAt: "2024-07-01T09:57:51.511Z",
    },
    items: [
      {
        productId: "668270a809647a7c33514afd",
        productPLU: "2162111-1",
        productDescription:
          "C 72g | P 23g | F 34g[%Popular%]\nChicken burger with baked sweet potato fries and bbq sauce",
        productName: "Chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/W-Q0KOEEFconPveULpPYJ1-BKoKF0NFlrsCg_Bf1OTQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAxXy02NjQ0YjA2NGUxYWUwZmJmYWQ2MmM3NjcucG5n",
        productCalories: 686,
        quantity: 1,
        fmcCentsPrice: 340,
        fiatCentsPrice: 340,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:57:51.491Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:58:38.199Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T09:58:38.437Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T10:04:55.085Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T10:04:55.085Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:48:12.970Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:20:01.292Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:57:51.491Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:58:03.616Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:57:51.491Z",
    updatedAt: "2024-07-01T16:20:01.677Z",
  },
  {
    id: "66827d6a09647a7c335582fb",
    sid: "240701-778814",
    coupons: [],
    menuId: "668270aa09647a7c33514e76",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "66827d6909647a7c335582ef",
    firstName: "Anwar alhawas",
    lastName: null,
    phoneNumber: "+96599668029",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "665a28dc0eba0d08a56b9a66",
    delivery: {
      id: "66827d6909647a7c335582ef",
      customerId: "665a28dc0eba0d08a56b9a66",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "666ec3800ce21ecb25ccff5b",
        name: "Anwar",
        lng: 47.987548,
        lat: 29.3877529,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "3",
        street: "25 ebn msbah ",
        building: "‘",
        floor: ".",
        flat: ".",
        phone: "+96599668029",
        additionalInfo: "Call me when u receive ",
        isSelected: true,
        createdAt: "2024-06-16T10:50:40.066Z",
        updatedAt: "2024-06-23T10:41:41.916Z",
      },
      deliverectValidationId: "66827d69067315c2af35aa18",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T10:06:57.165Z",
      estimatedDeliveryTime: "2024-07-01T10:43:56.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66827d6a09647a7c335582fb",
      createdAt: "2024-07-01T09:56:57.318Z",
      updatedAt: "2024-07-01T09:56:58.110Z",
    },
    items: [
      {
        productId: "668270aa09647a7c33514eec",
        productPLU: "2162111-1",
        productDescription:
          "C 72g | P 23g | F 34g[%Popular%]\nChicken burger with baked sweet potato fries and bbq sauce",
        productName: "Chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/W-Q0KOEEFconPveULpPYJ1-BKoKF0NFlrsCg_Bf1OTQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAxXy02NjQ0YjA2NGUxYWUwZmJmYWQ2MmM3NjcucG5n",
        productCalories: 686,
        quantity: 1,
        fmcCentsPrice: 340,
        fiatCentsPrice: 340,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270aa09647a7c33514f42",
        productPLU: "2202052-1",
        productDescription:
          "C 13g | P 0g | F 0g\nPomegranate, lemon\n\nGood source of Vitamin C",
        productName: "Pomade",
        imageURL:
          "https://resizer.deliverect.com/JpImLqr5JfNLPk8gTnl6NoZd9i6mdIhqPB4QA7H5lw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEtNjY1OTFhNzgxNmEzMzQyNTY5OGVhM2QxLnBuZw==",
        productCalories: 54,
        quantity: 4,
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270aa09647a7c33514f0a",
        productPLU: "2162030-1",
        productDescription:
          "C 42g | P 26g | F 21g\nLo mein noodles, beef, carrots, cabbage & zucchini.\n\n*Good source of Protein & vitamins*",
        productName: "Lo Mein Noodles",
        imageURL:
          "https://resizer.deliverect.com/gtToqQp69qSy57Ivr2G-IxNvZrR3UxiMiFxzdOFokw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTE8lMjBNRUlOJTIwTk9PRExFUy02NTc1OTAxZDRmNzg3N2JlMWJhMWIyN2MucG5n",
        productCalories: 461,
        quantity: 1,
        fmcCentsPrice: 390,
        fiatCentsPrice: 390,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 1410,
    totalFmcCentsPrice: 1410,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:56:58.088Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T10:06:34.906Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T10:06:34.907Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T10:18:49.029Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T10:18:49.030Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:47:39.245Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:48:25.546Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:56:58.088Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:57:09.987Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:56:58.088Z",
    updatedAt: "2024-07-01T16:48:25.896Z",
  },
  {
    id: "66827d5d09647a7c33557f36",
    sid: "240701-654995",
    coupons: [],
    menuId: "668270a809647a7c33514a87",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "66827d5d09647a7c33557f2e",
    firstName: "Sulaiman Al Thafiri",
    lastName: null,
    phoneNumber: "+96598525238",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "650af443e1d913534188d863",
    delivery: {
      id: "66827d5d09647a7c33557f2e",
      customerId: "650af443e1d913534188d863",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "661cd48d7e47a34cffb0c543",
        name: "Home",
        lng: 47.982945330441,
        lat: 29.31519847249172,
        city: "Kuwait",
        area: "Qortuba",
        block: "4",
        street: "3 St",
        building: "House 45",
        floor: "2",
        flat: "",
        phone: "+96598525238",
        additionalInfo: "Side door",
        isSelected: true,
        createdAt: "2024-04-15T07:17:33.872Z",
        updatedAt: "2024-06-25T13:45:13.701Z",
      },
      deliverectValidationId: "66827d5d067315c2af35a41d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T10:06:45.267Z",
      estimatedDeliveryTime: "2024-07-01T10:43:43.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66827d5d09647a7c33557f36",
      createdAt: "2024-07-01T09:56:45.360Z",
      updatedAt: "2024-07-01T09:56:45.895Z",
    },
    items: [
      {
        productId: "668270a809647a7c33514af7",
        productPLU: "2162120-1",
        productDescription:
          "C 63g | P 29g | F 12g [%New%]\nTomato sauce, cheese, chicken topped with BBQ sauce\n\nGood source of Protein ",
        productName: "BBQ Chicken Pizza",
        imageURL:
          "https://resizer.deliverect.com/oLi5aqw9dJEyegq-8KQAZCtGuNR2P65Gdii-y51sbns/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmJxJTIwcGl6emEtNjY3NDM0MDZhZjdmODYxNjFhMjNhOWYyLnBuZw==",
        productCalories: 476,
        quantity: 1,
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 310,
    totalFmcCentsPrice: 310,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:56:45.875Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:57:02.089Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T09:58:41.478Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T10:04:43.799Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T10:04:43.799Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:50:06.664Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:05:19.699Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:56:45.875Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:56:58.506Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:56:45.875Z",
    updatedAt: "2024-07-01T16:15:03.391Z",
  },
  {
    id: "66827d5309647a7c33557a25",
    sid: "240701-355314",
    coupons: [],
    menuId: "668270aa09647a7c33514e76",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "66827d5309647a7c335579f9",
    firstName: "Noura Khaled",
    lastName: null,
    phoneNumber: "+96597134260",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6559f7bb6fe598ae84f9e693",
    delivery: {
      id: "66827d5309647a7c335579f9",
      customerId: "6559f7bb6fe598ae84f9e693",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6559f8bb5ea5b27ca4c1fa36",
        name: "Work",
        lng: 47.9786764,
        lat: 29.37685639999999,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "6",
        street: "Ahmad aljaber",
        building: "City Tower",
        floor: "10",
        flat: "Ulearn",
        phone: "+96597134260",
        additionalInfo:
          "Opposites Crustal Tower and Doha Bankمقابل برج كريستال وبنك الدوحة",
        isSelected: true,
        createdAt: "2023-11-19T11:59:55.300Z",
        updatedAt: "2024-06-30T09:37:38.350Z",
      },
      deliverectValidationId: "66827d52c25b7bb131dd335c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T10:06:34.925Z",
      estimatedDeliveryTime: "2024-07-01T10:43:33.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66827d5309647a7c33557a25",
      createdAt: "2024-07-01T09:56:35.196Z",
      updatedAt: "2024-07-01T09:56:35.911Z",
    },
    items: [
      {
        productId: "668270aa09647a7c33514ef1",
        productPLU: "2162107-1",
        productDescription:
          "C 30g | P 46g | F 9g\nGrilled zaatar chicken with saj bread and zaatar yogurt sauce. Contains coriander \n\nGood source of Protein ",
        productName: "Zaatar Chicken",
        imageURL:
          "https://resizer.deliverect.com/HLEGxWozw0ZDVfWyAN_TK_oChgkvffkzz-_bxUrGMJI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemFhdGFyJTIwY2hpY2tlbi02NjQ0YjBjMTc0NWEzOTM5ZTIxNjY5MjAucG5n",
        productCalories: 385,
        quantity: 1,
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270aa09647a7c33514f1c",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        remark: "Make sure it’s fresh and not burned please ",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:56:35.886Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:57:01.973Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T10:06:32.004Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T10:18:01.186Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T10:18:01.186Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:04:10.140Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:56:35.886Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:56:47.572Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:56:35.886Z",
    updatedAt: "2024-07-01T16:22:32.816Z",
  },
  {
    id: "66827cf009647a7c335559ed",
    sid: "240701-374041",
    coupons: [],
    menuId: "668270a409647a7c335144db",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "66827cef09647a7c335559d5",
    firstName: "Dalal Khoursheed ",
    lastName: null,
    phoneNumber: "+96562223315",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "664b31a50e2115864268f6bb",
    delivery: {
      id: "66827cef09647a7c335559d5",
      customerId: "664b31a50e2115864268f6bb",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "664b506930566b5d295525cd",
        name: "Office",
        lng: 48.138425357511274,
        lat: 29.079483032226562,
        city: "Kuwait",
        area: "الفحيحيل",
        block: "-",
        street: "-",
        building: "-",
        floor: "3",
        flat: "",
        phone: "+96562223315",
        additionalInfo: "Xcite building infront of al kout mall 3rd floor",
        isSelected: true,
        createdAt: "2024-05-20T13:30:17.491Z",
        updatedAt: "2024-07-01T09:47:28.060Z",
      },
      deliverectValidationId: "66827cef132a4a9c90179b4f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T10:04:55.729Z",
      estimatedDeliveryTime: "2024-07-01T10:41:54.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66827cf009647a7c335559ed",
      createdAt: "2024-07-01T09:54:55.808Z",
      updatedAt: "2024-07-01T09:54:56.605Z",
    },
    items: [
      {
        productId: "668270a509647a7c33514605",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        fmcCentsPrice: 300,
        fiatCentsPrice: 230,
        remark: "",
        subItems: [
          {
            productId: "668270a509647a7c335146ae",
            productPLU: "1906019-1",
            productName: "Extra  Chicken",
            productDescription: "C 0g| P 18g| F 1.5g| cal 87| coins 0.7",
            productCalories: 87,
            quantity: 1,
            fmcCentsPrice: 70,
            fiatCentsPrice: 70,
          },
        ],
      },
      {
        productId: "668270a509647a7c335145dc",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:54:56.585Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:55:16.581Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T10:00:29.692Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T10:09:57.719Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T10:09:57.719Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:49:20.245Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:03:01.732Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:54:56.585Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:55:08.052Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:54:56.585Z",
    updatedAt: "2024-07-01T16:03:02.086Z",
  },
  {
    id: "66827cde09647a7c33555793",
    sid: "240701-266714",
    coupons: [],
    menuId: "668270aa09647a7c33514e76",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "66827cdd09647a7c33555781",
    firstName: "Abdullah Alqallaf",
    lastName: null,
    phoneNumber: "+96560685852",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "665dc8d80eba0d08a5733344",
    delivery: {
      id: "66827cdd09647a7c33555781",
      customerId: "665dc8d80eba0d08a5733344",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6667daac0f9532784a6b9a90",
        name: "BBK",
        lng: 47.9841171,
        lat: 29.3803933,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "5",
        street: "Ahmad Al Jaber St",
        building: "N/A",
        floor: "",
        flat: "",
        phone: "+96560685852",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-11T05:03:40.965Z",
        updatedAt: "2024-06-24T05:56:44.845Z",
      },
      deliverectValidationId: "66827cdd064b6695822adb98",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T10:04:37.585Z",
      estimatedDeliveryTime: "2024-07-01T10:41:36.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66827cde09647a7c33555793",
      createdAt: "2024-07-01T09:54:37.672Z",
      updatedAt: "2024-07-01T09:54:38.388Z",
    },
    items: [
      {
        productId: "668270aa09647a7c33514f06",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:54:38.369Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:55:16.684Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T10:06:29.556Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T10:17:50.367Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T10:17:50.367Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:46:32.431Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:34:06.956Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:54:38.369Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:54:49.849Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:54:38.369Z",
    updatedAt: "2024-07-01T16:34:07.510Z",
  },
  {
    id: "66827cc909647a7c33554f7e",
    sid: "240701-694076",
    coupons: [],
    menuId: "668270ad09647a7c335152f9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "66827cc809647a7c33554ed7",
    firstName: "Shahad AlMershed",
    lastName: null,
    phoneNumber: "+96597887211",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "665d80f00eba0d08a572240e",
    delivery: {
      id: "66827cc809647a7c33554ed7",
      customerId: "665d80f00eba0d08a572240e",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "665eee303d5f2b755dfafbad",
        name: "Home mishref",
        lng: 48.0726645514369,
        lat: 29.285098770752967,
        city: "مدينة الكويت",
        area: "Mishref",
        block: "1",
        street: "8 ",
        building: "9",
        floor: "",
        flat: "",
        phone: "+96597887211",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-04T10:36:32.890Z",
        updatedAt: "2024-06-23T18:00:02.411Z",
      },
      deliverectValidationId: "66827cc8c290370fd8e1be06",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T10:04:16.532Z",
      estimatedDeliveryTime: "2024-07-01T10:41:15.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66827cc909647a7c33554f7e",
      createdAt: "2024-07-01T09:54:16.622Z",
      updatedAt: "2024-07-01T09:54:17.552Z",
    },
    items: [
      {
        productId: "668270ad09647a7c3351538e",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:54:17.532Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:55:16.803Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T10:01:02.579Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T10:09:46.868Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T10:09:46.869Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:57:57.818Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:21:00.206Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:54:17.532Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:54:36.317Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:54:17.532Z",
    updatedAt: "2024-07-01T16:21:00.551Z",
  },
  {
    id: "66827cc109647a7c33554d13",
    sid: "240701-631461",
    coupons: [],
    menuId: "668270ad09647a7c335152f9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "66827cbf09647a7c33554b37",
    firstName: "Fatmah aljumah",
    lastName: null,
    phoneNumber: "+96597930331",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65a175442d5e4e9926920793",
    delivery: {
      id: "66827cbf09647a7c33554b37",
      customerId: "65a175442d5e4e9926920793",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65a1760bafe41535d3c3e84c",
        name: "Fatma s home",
        lng: 48.0702468752861,
        lat: 29.281896356657835,
        city: "Kuwait",
        area: "Mishref",
        block: "1",
        street: "1st St",
        building: "43A",
        floor: "",
        flat: "",
        phone: "+96597930331",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-12T17:25:31.974Z",
        updatedAt: "2024-06-30T16:37:50.458Z",
      },
      deliverectValidationId: "66827cbf907c6a3aa53cff1e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T10:04:07.421Z",
      estimatedDeliveryTime: "2024-07-01T10:41:05.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66827cc109647a7c33554d13",
      createdAt: "2024-07-01T09:54:07.605Z",
      updatedAt: "2024-07-01T09:54:09.075Z",
    },
    items: [
      {
        productId: "668270ad09647a7c33515398",
        productPLU: "2162091-1",
        productDescription:
          " C 38g | P 25g | F 17g\nEgyptian rice, seasoned ground beef, corn, black beans & lettuce with a side of sour cream.\n\nGood source of Protein  ",
        productName: "Tex-mex beef bowl",
        imageURL:
          "https://resizer.deliverect.com/Md584f-_J9sS4MGl1CkKV4Rc3qATfoJ0ZZ1r5Vz8VaA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvdGV4JTIwbWV4JTIwYm93bC02NjQzN2FiMDBjMjRkOTI1ZmNkNDBhN2YucG5n",
        productCalories: 405,
        quantity: 1,
        fmcCentsPrice: 330,
        fiatCentsPrice: 330,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270ad09647a7c3351539a",
        productPLU: "2162021-1",
        productDescription:
          "C 60g| P 50g| F 16g\nChicken shawarma served with calrose rice & drizzled with our shawarma sauce and sumac white onions.\n\nHigh in protein, ",
        productName: "Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/wpaWs0_xzYbkz_6vxHW2469_71kHXwgrjXuSA4epEwY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2hpY2tlbiUyMFNoYXdhcm1hJTIwYm93bC02NGY0OTRlOTFhNzliZjNkNzNkYjUzNjcuanBn",
        productCalories: 584,
        quantity: 1,
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        remark: "",
        subItems: [
          {
            productId: "668270ad09647a7c335153f5",
            productPLU: "1907030-1",
            productName: "No Sumac Onion",
            productDescription: "C -2.3g| P -0.3g| F 0g| cal -10| coins 0",
            productCalories: -10,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
          },
        ],
      },
      {
        productId: "668270ad09647a7c3351536b",
        productPLU: "2162122-1",
        productDescription:
          "C 25g | P 5g | F 6g [%New%]\nTortilla, mashed beans, guacamole, salsa, grilled pineapple, jalapeno salsa and kale. Contains Coriander.\n\nGood source of Vitamin C & K",
        productName: "Beans & Kale Tacos",
        imageURL:
          "https://resizer.deliverect.com/I6P9SEwtrysk4ivqKWtSQjFAgt0t-zShenQXMT9i74U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvdmVnJTIwdGFjb3MtNjY3ZDVlYWU3NzA0ZWQyNWI2OWI3ZDU2LnBuZw==",
        productCalories: 174,
        quantity: 1,
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 770,
    totalFmcCentsPrice: 770,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:54:09.052Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:55:16.927Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T10:01:02.475Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T10:08:50.825Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T10:08:50.826Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:46:09.589Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:39:07.813Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:54:09.052Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:54:25.191Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:54:09.052Z",
    updatedAt: "2024-07-01T16:39:08.156Z",
  },
  {
    id: "66827cba09647a7c33554889",
    sid: "240701-151330",
    coupons: [],
    menuId: "668270a409647a7c335144db",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "66827cb909647a7c335547cf",
    firstName: "Reem",
    lastName: null,
    phoneNumber: "+96597969607",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "662f764d7ef16a176ee6856b",
    delivery: {
      id: "66827cb909647a7c335547cf",
      customerId: "662f764d7ef16a176ee6856b",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "665c073793dc7e98b493f5fa",
        name: "Work",
        lng: 48.087232299149036,
        lat: 29.09189434352738,
        city: "Ahmadi",
        area: "Mid Ahmadi",
        block: "12",
        street: "Hamad Al Zuwayer St",
        building: "KNPC Building",
        floor: "",
        flat: "",
        phone: "+96597969607",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-02T05:46:31.045Z",
        updatedAt: "2024-06-30T06:38:54.854Z",
      },
      deliverectValidationId: "66827cb9b1f78b2b6bdc1a52",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T10:04:01.024Z",
      estimatedDeliveryTime: "2024-07-01T10:41:00.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66827cba09647a7c33554889",
      createdAt: "2024-07-01T09:54:01.553Z",
      updatedAt: "2024-07-01T09:54:02.679Z",
    },
    items: [
      {
        productId: "668270a509647a7c335145ce",
        productPLU: "2167007-1",
        productDescription:
          "C 60g| P 24g| F 17g [%Best seller%]\nSmoked turkey with grilled turkey bacon, avocado puree, rocca, pesto & emmental cheese all in sourdough toast. ",
        productName: "Turkey Pesto",
        imageURL:
          "https://resizer.deliverect.com/d4E--e7KeFg5pz7n8OJh2vgevZuUdJEXur9hGCWc7sY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2V5JTIwc2FuZHdpY2gtNjRmNDkwMzljNDA4OTczMWUzMzk4OTc0LmpwZw==",
        productCalories: 489,
        quantity: 1,
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270a509647a7c33514649",
        productPLU: "2202013-1",
        productDescription: "C 2g| P 0g| F 0g\nStevia Sugar",
        productName: "Green ColaOrange",
        imageURL:
          "https://resizer.deliverect.com/NjdIUyF7kBzJB7hyNpXuuRP6lWLZZJzCSVc7OIATpKA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNCUyMCgxKS02NGM5MTk3ODMxODE2MjAwMWU4NzU3ZGIuanBn",
        productCalories: 13,
        quantity: 1,
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:54:02.660Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:54:20.288Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T10:00:29.985Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T10:09:42.842Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T10:09:42.842Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:58:43.263Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:44:38.728Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:54:02.660Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:54:14.135Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:54:02.660Z",
    updatedAt: "2024-07-01T16:44:39.091Z",
  },
  {
    id: "66827ca709647a7c335540f0",
    sid: "240701-992509",
    coupons: [],
    menuId: "668270aa09647a7c33514e76",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "66827ca709647a7c335540e2",
    firstName: "Mohammad Alomar",
    lastName: null,
    phoneNumber: "+96597330700",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65cba89e637513912a7a2342",
    delivery: {
      id: "66827ca709647a7c335540e2",
      customerId: "65cba89e637513912a7a2342",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "668008bf86fe4b6ad1929110",
        name: "Work",
        lng: 47.974781692028046,
        lat: 29.37124782894262,
        city: "Al Kuwayt",
        area: "Jibla",
        block: "1",
        street: "Ahmad Al Jaber St",
        building: "Baitik Tower",
        floor: "11",
        flat: "",
        phone: "+96597330700",
        additionalInfo: "Baitik Tower",
        isSelected: true,
        createdAt: "2024-06-29T13:14:39.164Z",
        updatedAt: "2024-06-30T07:04:51.360Z",
      },
      deliverectValidationId: "66827ca6064b6695822a88f4",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T10:03:42.980Z",
      estimatedDeliveryTime: "2024-07-01T10:40:41.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "66827ca709647a7c335540f0",
      createdAt: "2024-07-01T09:53:43.100Z",
      updatedAt: "2024-07-01T09:53:43.806Z",
    },
    items: [
      {
        productId: "668270aa09647a7c33514f22",
        productPLU: "2166020-1",
        productDescription:
          "C 13g| P 2g| F 1g\nBeetroots tossed in olive oil, lemon juice and fresh coriander.\n\nGood source of dietary Fibre, Iron, Potassium and Vitamin C\nContains garlic.",
        productName: "Beetroot Pot",
        imageURL:
          "https://resizer.deliverect.com/4QQmAm_YErxn11xFj_N8IRMBlHt61yeQWJR1VR9vwug/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNy02NTEwMzhmNDI2N2RiNTQwNTI2ZTM4MTguanBn",
        productCalories: 70,
        quantity: 1,
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270aa09647a7c33514efc",
        productPLU: "2162006-1",
        productDescription:
          "C 31g| P 29g| F 14g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 366,
        quantity: 1,
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        remark: "Without cheese",
        subItems: [
          {
            productId: "668270aa09647a7c33514f96",
            productPLU: "1907003-1",
            productName: "No Cheddar Cheese",
            productDescription: "C 0g| P -2g| F -3g| cal -40| coins 0\n\n",
            productCalories: -40,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
          },
        ],
      },
      {
        productId: "668270aa09647a7c33514f1c",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 360,
    totalFmcCentsPrice: 360,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:53:43.787Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:54:32.146Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T10:06:27.234Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T10:17:39.497Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T10:17:39.497Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:00:08.526Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:53:43.787Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:53:55.369Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:53:43.787Z",
    updatedAt: "2024-07-01T16:30:49.803Z",
  },
  {
    id: "668274d109647a7c3352ef07",
    sid: "240701-464037",
    coupons: [],
    menuId: "668270aa09647a7c33514e76",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "668274d109647a7c3352eee7",
    firstName: "Rakan Alfadalah",
    lastName: null,
    phoneNumber: "+96597887686",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d4ab8822ca634ce639df03",
    delivery: {
      id: "668274d109647a7c3352eee7",
      customerId: "64d4ab8822ca634ce639df03",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6645fafffb0531469a7af2b2",
        name: "Albahar Tower 13",
        lng: 47.9798383954106,
        lat: 29.377166748046875,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "1",
        street: "Ahmad Al Jaber St",
        building: "Albahar Tower",
        floor: "13",
        flat: "Rakan",
        phone: "+96597887686",
        additionalInfo: "Same building of First Abu Dhabi Bank",
        isSelected: true,
        createdAt: "2024-05-16T12:24:31.248Z",
        updatedAt: "2024-07-01T08:35:24.877Z",
      },
      deliverectValidationId: "668274d0d315a6ac58499553",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T09:30:16.969Z",
      estimatedDeliveryTime: "2024-07-01T10:07:15.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668274d109647a7c3352ef07",
      createdAt: "2024-07-01T09:20:17.061Z",
      updatedAt: "2024-07-01T09:20:17.688Z",
    },
    items: [
      {
        productId: "668270aa09647a7c33514ec7",
        productPLU: "2167023-1",
        productDescription:
          "C 29g | P 22g | F 16g\nScrambled eggs with mushroom, green olives and feta cheese in a tortilla wrap\n\nGOOD SOURCE OF PROTEIN, MINERALS",
        productName: "Mushroom egg wrap",
        imageURL:
          "https://resizer.deliverect.com/icCk6VyiEI1LioDbIHa0TjQikuisfIyU0FZUoDvsxdY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAyLTY2M2ZmM2Q0ZGI0ZjMyZjZhNTZmMGY4MC5wbmc=",
        productCalories: 348,
        quantity: 1,
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270aa09647a7c33514edf",
        productPLU: "2163010-1",
        productDescription:
          "C 25g| P 34g| F 15g\nGrilled chicken with baby gem lettuce, caesar dressing topped with sourdough croutons & shaved parmesan cheese.\n\nGood source of Vitamin A &Potein",
        productName: "Chicken Ceasar Salad",
        imageURL:
          "https://resizer.deliverect.com/aOyM36xC7gBB5GwjyrYYA3YsTJ8-9RRXq22EPXeQlkM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NGI3YjVjOWU0ZDVjMzAwMThiNjViNmIucG5n",
        productCalories: 371,
        quantity: 1,
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 470,
    totalFmcCentsPrice: 470,
    totalFiatCentsPriceOriginal: 470,
    totalFmcCentsPriceOriginal: 470,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:20:17.667Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:21:04.747Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T09:24:21.370Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T09:48:32.713Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T09:48:32.713Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:39:46.154Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T15:55:50.182Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:20:17.667Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:20:29.344Z",
      },
    ],
    cutlery: true,
    hasRating: true,
    timeslot: null,
    createdAt: "2024-07-01T09:20:17.667Z",
    updatedAt: "2024-07-01T18:09:07.823Z",
  },
  {
    id: "668274c209647a7c3352ec4a",
    sid: "240701-055394",
    coupons: [],
    menuId: "668270aa09647a7c33514e76",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "668274c109647a7c3352ec3d",
    firstName: "Bader AlJassar",
    lastName: null,
    phoneNumber: "+96598000728",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65a59f022d5e4e992695b0d3",
    delivery: {
      id: "668274c109647a7c3352ec3d",
      customerId: "65a59f022d5e4e992695b0d3",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6681191186fe4b6ad1b0af71",
        name: "CMA - ALHamra Tower",
        lng: 47.99328491091728,
        lat: 29.379096949843273,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "1",
        street: "Al-Shuhada St",
        building: "AL HAMRA",
        floor: "20",
        flat: "",
        phone: "+96598000728",
        additionalInfo: "Give to security in Floor 20",
        isSelected: true,
        createdAt: "2024-06-30T08:36:33.330Z",
        updatedAt: "2024-07-01T09:17:16.683Z",
      },
      deliverectValidationId: "668274c101a08e40690b75fb",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T09:30:01.673Z",
      estimatedDeliveryTime: "2024-07-01T10:07:00.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668274c209647a7c3352ec4a",
      createdAt: "2024-07-01T09:20:01.754Z",
      updatedAt: "2024-07-01T09:20:02.694Z",
    },
    items: [
      {
        productId: "668270aa09647a7c33514ed4",
        productPLU: "2167007-1",
        productDescription:
          "C 60g| P 24g| F 17g [%Best seller%]\nSmoked turkey with grilled turkey bacon, avocado puree, rocca, pesto & emmental cheese all in sourdough toast. ",
        productName: "Turkey Pesto",
        imageURL:
          "https://resizer.deliverect.com/d4E--e7KeFg5pz7n8OJh2vgevZuUdJEXur9hGCWc7sY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2V5JTIwc2FuZHdpY2gtNjRmNDkwMzljNDA4OTczMWUzMzk4OTc0LmpwZw==",
        productCalories: 489,
        quantity: 1,
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270aa09647a7c33514f3a",
        productPLU: "2165011-1",
        productDescription: "C 11g| P 2g| F 9g",
        productName: "Pretzel Bar",
        imageURL:
          "https://resizer.deliverect.com/kPvDmjANIIvikAU1B5EKJqEYgXoEga1zIgvbiH7qkl0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTAtNjRiN2JkMDNhMDAxOGMwYmY0YTY2NTZkLnBuZw==",
        productCalories: 137,
        quantity: 1,
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270aa09647a7c33514f62",
        productPLU: "2202041-1",
        productDescription:
          "C 5.3g | P 0.8g | F 0g\nBeetroot, Grapefruit & Himalayan Salt",
        productName: "Heart Beet Shot",
        imageURL:
          "https://resizer.deliverect.com/hr_xtMDdUpGtwUgywZHAGVPEYM_cOE7XRFgrZu-SPkU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaGVhcnRiZWV0JTIwc2hvdC02NjQxZGFmZjc0YTNlMTU5MTYzYWJhNjkucG5n",
        productCalories: 25,
        quantity: 1,
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:20:02.669Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:20:31.808Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T09:24:17.266Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T09:48:21.479Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T09:48:21.479Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:33:08.762Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:55:59.994Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:20:02.669Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:20:17.912Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:20:02.669Z",
    updatedAt: "2024-07-01T16:56:00.374Z",
  },
  {
    id: "668274be09647a7c3352eaba",
    sid: "240701-218263",
    coupons: [],
    menuId: "668270ad09647a7c335152f9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "668274be09647a7c3352eaaf",
    firstName: "Masha’el AlSendi",
    lastName: null,
    phoneNumber: "+96597794494",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65ae5b2b97e5f13760b7a8c0",
    delivery: {
      id: "668274be09647a7c3352eaaf",
      customerId: "65ae5b2b97e5f13760b7a8c0",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6677b2eaaaef22e7a1a6287c",
        name: "Office ",
        lng: 48.068608,
        lat: 29.2808226,
        city: "Kuwait",
        area: "Mishref",
        block: "4",
        street: "Road 59",
        building: "Gulf bank ",
        floor: "Ground floor",
        flat: "",
        phone: "+96597794494",
        additionalInfo: "Mishref coop. ",
        isSelected: true,
        createdAt: "2024-06-23T05:30:18.253Z",
        updatedAt: "2024-07-01T07:12:27.516Z",
      },
      deliverectValidationId: "668274bec3bacd04fa392f56",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T09:29:58.160Z",
      estimatedDeliveryTime: "2024-07-01T10:06:57.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668274be09647a7c3352eaba",
      createdAt: "2024-07-01T09:19:58.245Z",
      updatedAt: "2024-07-01T09:19:58.808Z",
    },
    items: [
      {
        productId: "668270ad09647a7c33515399",
        productPLU: "2162056-1",
        productDescription:
          "C 52g| P 40g| F 18g\nGrilled tenderloin steak served with egyptian rice, charred corn, mushroom sauce, grilled onion topped with chives.\n\nGood source of iron & B-Complex vitamins",
        productName: "Mushroom Steak Bowl",
        imageURL:
          "https://resizer.deliverect.com/mI46_G8ohOUlax6lzY869gIqShTLi01aFmd1aeKxxuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGJvd2wlMjAtNjRmNDhmODQxNDI5ODQwMDFlOGVjOGVkLmpwZw==",
        productCalories: 530,
        quantity: 1,
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:19:58.785Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:20:17.856Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T09:21:39.805Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T09:28:56.013Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T09:28:56.013Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:28:12.192Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:10:57.645Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:19:58.785Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:20:05.569Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:19:58.785Z",
    updatedAt: "2024-07-01T16:10:57.989Z",
  },
  {
    id: "668274ad09647a7c3352e565",
    sid: "240701-948519",
    coupons: [],
    menuId: "668270a809647a7c33514a87",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "668274ac09647a7c3352e4d2",
    firstName: "Rawan khaled",
    lastName: null,
    phoneNumber: "+96555388883",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6640503b66978e88ed0d945f",
    delivery: {
      id: "668274ac09647a7c3352e4d2",
      customerId: "6640503b66978e88ed0d945f",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66409a2a5ca983b14ac67f44",
        name: "Home",
        lng: 47.89909511804581,
        lat: 29.273439843139435,
        city: "Sabah Al Nasser",
        area: "Sabah Al Nasser",
        block: "7",
        street: "49",
        building: "2",
        floor: "",
        flat: "",
        phone: "+96555388883",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-12T10:30:02.590Z",
        updatedAt: "2024-06-07T08:49:16.005Z",
      },
      deliverectValidationId: "668274ac907c6a3aa5380de8",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T09:29:40.698Z",
      estimatedDeliveryTime: "2024-07-01T10:06:39.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "668274ad09647a7c3352e565",
      createdAt: "2024-07-01T09:19:40.779Z",
      updatedAt: "2024-07-01T09:19:41.607Z",
    },
    items: [
      {
        productId: "668270a809647a7c33514b16",
        productPLU: "2162035-1",
        productDescription:
          "C 50g| P 32g| F 15g\nBlack pepper beef with veggies served with egyptian rice.\n\nGood source of Iron & B-12 Vitamins",
        productName: "Black Pepper Beef",
        imageURL:
          "https://resizer.deliverect.com/FBy7BxqQqLr64Ae34YvyRhL0jHWvib_v5F9riYKNFsc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNzYtNjRiN2IwNWM5Mjc2OTYwMDE5NmViYjFjLnBuZw==",
        productCalories: 474,
        quantity: 1,
        fmcCentsPrice: 330,
        fiatCentsPrice: 330,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270a809647a7c33514b52",
        productPLU: "2202051-1",
        productDescription:
          "C 51g | P 0.25g | F 0g\nWatermelon, lemon, mint\n\nGood source of Vitamin C",
        productName: "Waterlemon",
        imageURL:
          "https://resizer.deliverect.com/Z6SJv4l04sxDRKAG7bkaZpBT06F5O-JLEkDJxwTujFY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTQtNjY1OTE5Y2UxNmEzMzQyNTY5OGVhM2NiLnBuZw==",
        productCalories: 194,
        quantity: 1,
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270a809647a7c33514b44",
        productPLU: "2165023-1",
        productDescription:
          "C 16g | P 2g | F 6g [%New%]\nPeanut butter, coconut sugar, dark chocolate",
        productName: "PB Truffle",
        imageURL:
          "https://resizer.deliverect.com/X6vlA6kt817IOZLqSq57RvbtT2FtBrlTqxmfCbmz75o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGIlMjB0cnVmZmxlLTY2NjFjZTU4YTkxZGNiNzk4NzA4YzFiZC5wbmc=",
        productCalories: 134,
        quantity: 1,
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270a809647a7c33514b3b",
        productPLU: "2166008-1",
        productDescription:
          "C 30g| P 13g| F 16g\nGreek yogurt with peanut butter, raspberry, blueberry, blackberry topped with honey and granola.\n\nGood source of Vitamins",
        productName: "Berry Parfait",
        imageURL:
          "https://resizer.deliverect.com/0p2ShlK1ih7A2WUCAl2RWW19NH0UKIi5XafIY_FXOQc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NTExNmVkYTVkMWVjODc5MjQ0NDE3N2UuanBn",
        productCalories: 316,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 740,
    totalFmcCentsPrice: 740,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:19:41.587Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:20:17.642Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T09:20:17.752Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T09:31:16.097Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T09:31:16.097Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:38:37.350Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:15:03.682Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:19:41.587Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:19:43.955Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:19:41.587Z",
    updatedAt: "2024-07-01T16:15:04.032Z",
  },
  {
    id: "6682747d09647a7c3352d870",
    sid: "240701-552320",
    coupons: [],
    menuId: "668270aa09647a7c33514e76",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6682747d09647a7c3352d84d",
    firstName: "Abdulwahab boodai",
    lastName: null,
    phoneNumber: "+96599877723",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "649f052d54365c63a9973fbe",
    delivery: {
      id: "6682747d09647a7c3352d84d",
      customerId: "649f052d54365c63a9973fbe",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64a969947c8d5486a781943d",
        name: "Home",
        lng: 47.97042402123355,
        lat: 29.344970703125,
        city: "Al Kuwayt",
        area: "Shamiya",
        block: "9",
        street: "94 St",
        building: "House 8",
        floor: "",
        flat: "",
        phone: "+96599877723",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-07-08T13:50:12.645Z",
        updatedAt: "2024-05-27T21:53:19.462Z",
      },
      deliverectValidationId: "6682747d207c98da56fdb088",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T09:28:53.098Z",
      estimatedDeliveryTime: "2024-07-01T10:05:52.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6682747d09647a7c3352d870",
      createdAt: "2024-07-01T09:18:53.184Z",
      updatedAt: "2024-07-01T09:18:53.742Z",
    },
    items: [
      {
        productId: "668270aa09647a7c33514eda",
        productPLU: "2163016-1",
        productDescription:
          "C 35g| P 6g| F 10g\nRocca mixed with grilled zucchini, grilled red bell peppers & grilled corn drizzled in a vinaigrette dressing with coriander.\n\nGOOD SOURCE OF FIBER",
        productName: "Rocca Feta Salad",
        imageURL:
          "https://resizer.deliverect.com/hVjV_VJZKJD7LeDzKwVSVGem1rTZivC1K9bQFd7KnCs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjBTQUxBRC02NTE3ZWUwNjM5NGU3ODMxZTFiZDNiNWEuanBn",
        productCalories: 254,
        quantity: 1,
        fmcCentsPrice: 350,
        fiatCentsPrice: 350,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270aa09647a7c33514ef3",
        productPLU: "2162004-1",
        productDescription:
          "C 57g | P 42g | F 11g [%Popular%]\nFlavorful chicken machboos made with marinated chicken breast, aromatic basmati rice, topped with hashu & served with dakkus.",
        productName: "Chicken Machboos",
        imageURL:
          "https://resizer.deliverect.com/wylOEkBBpXVNBS2HAi9pQ55oF2RJyK2HNyEERWjweQ4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWFjaGJvdXMtNjY0NGIwMjllMWFlMGZiZmFkNjJjNzYxLnBuZw==",
        productCalories: 495,
        quantity: 1,
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270aa09647a7c33514f4f",
        productPLU: "2202013-1",
        productDescription: "C 2g| P 0g| F 0g\nStevia Sugar",
        productName: "Green ColaOrange",
        imageURL:
          "https://resizer.deliverect.com/NjdIUyF7kBzJB7hyNpXuuRP6lWLZZJzCSVc7OIATpKA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNCUyMCgxKS02NGM5MTk3ODMxODE2MjAwMWU4NzU3ZGIuanBn",
        productCalories: 13,
        quantity: 1,
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 670,
    totalFmcCentsPrice: 670,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:18:53.721Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:19:21.343Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T09:24:16.072Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T09:46:49.803Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T09:46:49.803Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:38:48.535Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:12:55.323Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:18:53.721Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:19:05.270Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:18:53.721Z",
    updatedAt: "2024-07-01T16:12:55.662Z",
  },
  {
    id: "6682745f09647a7c3352d125",
    sid: "240701-166585",
    coupons: [],
    menuId: "668270aa09647a7c33514e76",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6682745e09647a7c3352d119",
    firstName: "ABDULRAHMAN ALRABIAH",
    lastName: null,
    phoneNumber: "+96599160190",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6628d9cd11936febbb1c4eee",
    delivery: {
      id: "6682745e09647a7c3352d119",
      customerId: "6628d9cd11936febbb1c4eee",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6628dab7675418b886e659df",
        name: "Work",
        lng: 47.945659440010786,
        lat: 29.343463310437926,
        city: "Kuwait",
        area: "Shuwaikh Administrative",
        block: "6",
        street: "Barrak Khalid Dawood Al Marzouq St",
        building: "Zain HQ3",
        floor: "",
        flat: "",
        phone: "+96599160190",
        additionalInfo: "Next to nbk",
        isSelected: true,
        createdAt: "2024-04-24T10:11:03.333Z",
        updatedAt: "2024-07-01T09:14:21.281Z",
      },
      deliverectValidationId: "6682745ec290370fd8dd675e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T09:28:22.891Z",
      estimatedDeliveryTime: "2024-07-01T10:05:21.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6682745f09647a7c3352d125",
      createdAt: "2024-07-01T09:18:22.983Z",
      updatedAt: "2024-07-01T09:18:23.656Z",
    },
    items: [
      {
        productId: "668270aa09647a7c33514ecf",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 2,
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270aa09647a7c33514ef1",
        productPLU: "2162107-1",
        productDescription:
          "C 30g | P 46g | F 9g\nGrilled zaatar chicken with saj bread and zaatar yogurt sauce. Contains coriander \n\nGood source of Protein ",
        productName: "Zaatar Chicken",
        imageURL:
          "https://resizer.deliverect.com/HLEGxWozw0ZDVfWyAN_TK_oChgkvffkzz-_bxUrGMJI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemFhdGFyJTIwY2hpY2tlbi02NjQ0YjBjMTc0NWEzOTM5ZTIxNjY5MjAucG5n",
        productCalories: 385,
        quantity: 1,
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 490,
    totalFmcCentsPrice: 490,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:18:23.636Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:19:19.950Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T09:24:11.840Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T09:42:33.388Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T09:42:33.388Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:31:02.536Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T15:43:05.330Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:18:23.636Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:18:35.161Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:18:23.636Z",
    updatedAt: "2024-07-01T15:49:55.264Z",
  },
  {
    id: "6682743b09647a7c3352c878",
    sid: "240701-624152",
    coupons: [],
    menuId: "668270aa09647a7c33514e76",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6682743b09647a7c3352c84a",
    firstName: "Dhai alnemas",
    lastName: null,
    phoneNumber: "+96565112300",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "653810e21bfa57c5db05cfcc",
    delivery: {
      id: "6682743b09647a7c3352c84a",
      customerId: "653810e21bfa57c5db05cfcc",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6550afac5ea5b27ca49384ce",
        name: "Home",
        lng: 47.9741346091032,
        lat: 29.338314964918148,
        city: "Kuwait City",
        area: "Faiha",
        block: "4",
        street: "49",
        building: "9",
        floor: "",
        flat: "",
        phone: "+96565112300",
        additionalInfo: "Door near the garden ",
        isSelected: true,
        createdAt: "2023-11-12T10:57:48.813Z",
        updatedAt: "2023-11-12T10:57:48.813Z",
      },
      deliverectValidationId: "6682743b97a42a249be8de33",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T09:27:47.008Z",
      estimatedDeliveryTime: "2024-07-01T10:04:45.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6682743b09647a7c3352c878",
      createdAt: "2024-07-01T09:17:47.095Z",
      updatedAt: "2024-07-01T09:17:47.932Z",
    },
    items: [
      {
        productId: "668270aa09647a7c33514efc",
        productPLU: "2162006-1",
        productDescription:
          "C 31g| P 29g| F 14g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 366,
        quantity: 1,
        fmcCentsPrice: 280,
        fiatCentsPrice: 230,
        remark: "No pickles ",
        subItems: [
          {
            productId: "668270aa09647a7c33514f95",
            productPLU: "1906004-1",
            productName: "Extra  Beef Pattie",
            productDescription: "C 1g| P 11g| F 2g| cal 71| coins 0.5",
            productCalories: 71,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
          },
        ],
      },
      {
        productId: "668270aa09647a7c33514f12",
        productPLU: "2162021-1",
        productDescription:
          "C 60g| P 50g| F 16g\nChicken shawarma served with calrose rice & drizzled with our shawarma sauce and sumac white onions.\n\nHigh in protein, ",
        productName: "Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/wpaWs0_xzYbkz_6vxHW2469_71kHXwgrjXuSA4epEwY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2hpY2tlbiUyMFNoYXdhcm1hJTIwYm93bC02NGY0OTRlOTFhNzliZjNkNzNkYjUzNjcuanBn",
        productCalories: 584,
        quantity: 1,
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 500,
    totalFmcCentsPrice: 500,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:17:47.912Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:24:09.274Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T09:24:09.275Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T09:42:11.566Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T09:42:11.567Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:26:14.923Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:07:16.630Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:17:47.912Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:17:59.402Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:17:47.912Z",
    updatedAt: "2024-07-01T16:07:16.991Z",
  },
  {
    id: "6682742309647a7c3352be5a",
    sid: "240701-442986",
    coupons: [],
    menuId: "668270aa09647a7c33514e76",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6682742209647a7c3352be50",
    firstName: "Duaij AlFarhan",
    lastName: null,
    phoneNumber: "+96555932442",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65412c099746b5cbf5799085",
    delivery: {
      id: "6682742209647a7c3352be50",
      customerId: "65412c099746b5cbf5799085",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65519f795ea5b27ca49954ae",
        name: "Work",
        lng: 47.975194081664085,
        lat: 29.377524255528037,
        city: "Al Kuwayt",
        area: "Jibla",
        block: "5",
        street: "Mubarak Al Kabeer St",
        building: "Commercial Bank of Kuwait",
        floor: "",
        flat: "",
        phone: "+96555932442",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-13T04:00:57.454Z",
        updatedAt: "2024-06-30T04:57:42.442Z",
      },
      deliverectValidationId: "66827422207c98da56fd8370",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T09:27:22.447Z",
      estimatedDeliveryTime: "2024-07-01T10:04:21.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6682742309647a7c3352be5a",
      createdAt: "2024-07-01T09:17:22.582Z",
      updatedAt: "2024-07-01T09:17:23.348Z",
    },
    items: [
      {
        productId: "668270aa09647a7c33514f27",
        productPLU: "2166006-1",
        productDescription:
          "C 67g| P 8g| F 9g\nCinnamon chia whole oats with candied peanuts, peanut biscuit and peanut butter & lotus spread.\n\nGood source of Vitamins & Dietary fibre",
        productName: "Cinnamon Oats",
        imageURL:
          "https://resizer.deliverect.com/mJ-Xb-5qUQiDDKJOr4MGqGbDWxCn3gIddYpHwTQNiZo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NTExNmU5NTNmNWMzYTdmNDZlNGM2NDUuanBn",
        productCalories: 381,
        quantity: 1,
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270aa09647a7c33514f2a",
        productPLU: "2166008-1",
        productDescription:
          "C 30g| P 13g| F 16g\nGreek yogurt with peanut butter, raspberry, blueberry, blackberry topped with honey and granola.\n\nGood source of Vitamins",
        productName: "Berry Parfait",
        imageURL:
          "https://resizer.deliverect.com/0p2ShlK1ih7A2WUCAl2RWW19NH0UKIi5XafIY_FXOQc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NTExNmVkYTVkMWVjODc5MjQ0NDE3N2UuanBn",
        productCalories: 316,
        quantity: 1,
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 260,
    totalFmcCentsPrice: 260,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:17:23.327Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:24:07.313Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T09:24:07.313Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T09:42:00.587Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T09:42:00.587Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-07-01T15:32:12.886Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-07-01T16:10:23.698Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:17:23.327Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:17:35.295Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-07-01T09:17:23.327Z",
    updatedAt: "2024-07-01T16:10:24.066Z",
  },
  {
    id: "6682741509647a7c3352b57f",
    sid: "240701-465281",
    coupons: [],
    menuId: "668270aa09647a7c33514e76",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6682741509647a7c3352b55d",
    firstName: "Khaled AlRoudan",
    lastName: null,
    phoneNumber: "+96555555333",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "662f58d07ef16a176ee6411c",
    delivery: {
      id: "6682741509647a7c3352b55d",
      customerId: "662f58d07ef16a176ee6411c",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "662f5b7bc37a2a2c266f1a5d",
        name: "Kuwait Investment Authority",
        lng: 47.97950510087795,
        lat: 29.382217407226562,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "1",
        street: "201",
        building: "900028",
        floor: "",
        flat: "",
        phone: "+96555555333",
        additionalInfo: "Gate 2",
        isSelected: true,
        createdAt: "2024-04-29T08:34:03.833Z",
        updatedAt: "2024-06-27T09:28:21.157Z",
      },
      deliverectValidationId: "66827415d315a6ac58495c58",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T09:27:09.202Z",
      estimatedDeliveryTime: "2024-07-01T10:04:07.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6682741509647a7c3352b57f",
      createdAt: "2024-07-01T09:17:09.345Z",
      updatedAt: "2024-07-01T09:17:09.929Z",
    },
    items: [
      {
        productId: "668270aa09647a7c33514f06",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270aa09647a7c33514f54",
        productPLU: "2202022-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Sprite Zero",
        imageURL:
          "https://resizer.deliverect.com/ZEZdNlPffzmGS3rg7Tvd5hMcV02zZNG7y9fZavC83oI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMSUyMCgxKS02NGM5MTk0M2E0NTBlNjAwMjY4M2QzODEuanBn",
        productCalories: 0,
        quantity: 1,
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:17:09.909Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:20:47.066Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T09:20:47.067Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T09:42:44.879Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T09:42:44.879Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "delivered",
        timestamp: "2024-07-01T15:50:18.323Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:17:09.909Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:17:21.578Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:17:09.909Z",
    updatedAt: "2024-07-01T16:01:41.475Z",
  },
  {
    id: "6682740509647a7c3352ac52",
    sid: "240701-381700",
    coupons: [],
    menuId: "668270aa09647a7c33514e76",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6682740409647a7c3352ab84",
    firstName: "Khaled Alwattar",
    lastName: null,
    phoneNumber: "+96594040050",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64de9a3f70771121d14a8a4f",
    delivery: {
      id: "6682740409647a7c3352ab84",
      customerId: "64de9a3f70771121d14a8a4f",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64e1ed3be4583466bfc68fa4",
        name: "Arzan",
        lng: 47.98641990000001,
        lat: 29.38245879999999,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "2",
        street: "Ahmad Al Jaber St",
        building: "Arzan Financial Group Tower",
        floor: "",
        flat: "",
        phone: "+96594040050",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-20T10:38:51.146Z",
        updatedAt: "2024-07-01T09:15:28.835Z",
      },
      deliverectValidationId: "66827404d9b445dec8939ee4",
      deliveryProvider: "deliverect",
      expiresAt: "2024-07-01T09:26:52.388Z",
      estimatedDeliveryTime: "2024-07-01T10:03:50.000Z",
      estimatedDeliveryTimeEnd: null,
      orderId: "6682740509647a7c3352ac52",
      createdAt: "2024-07-01T09:16:52.748Z",
      updatedAt: "2024-07-01T09:16:53.804Z",
    },
    items: [
      {
        productId: "668270aa09647a7c33514edf",
        productPLU: "2163010-1",
        productDescription:
          "C 25g| P 34g| F 15g\nGrilled chicken with baby gem lettuce, caesar dressing topped with sourdough croutons & shaved parmesan cheese.\n\nGood source of Vitamin A &Potein",
        productName: "Chicken Ceasar Salad",
        imageURL:
          "https://resizer.deliverect.com/aOyM36xC7gBB5GwjyrYYA3YsTJ8-9RRXq22EPXeQlkM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NGI3YjVjOWU0ZDVjMzAwMThiNjViNmIucG5n",
        productCalories: 371,
        quantity: 1,
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        remark: "",
        subItems: [],
      },
      {
        productId: "668270aa09647a7c33514f11",
        productPLU: "2162056-1",
        productDescription:
          "C 52g| P 40g| F 18g\nGrilled tenderloin steak served with egyptian rice, charred corn, mushroom sauce, grilled onion topped with chives.\n\nGood source of iron & B-Complex vitamins",
        productName: "Mushroom Steak Bowl",
        imageURL:
          "https://resizer.deliverect.com/mI46_G8ohOUlax6lzY869gIqShTLi01aFmd1aeKxxuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGJvd2wlMjAtNjRmNDhmODQxNDI5ODQwMDFlOGVjOGVkLmpwZw==",
        productCalories: 530,
        quantity: 1,
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 670,
    totalFmcCentsPrice: 670,
    totalFiatCentsPriceOriginal: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:16:53.783Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-07-01T09:20:41.785Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-07-01T09:20:41.785Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-07-01T09:42:56.358Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-07-01T09:42:56.358Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "delivered",
        timestamp: "2024-07-01T15:46:32.508Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-07-01T09:16:53.783Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-07-01T09:17:05.727Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-07-01T09:16:53.783Z",
    updatedAt: "2024-07-01T15:59:32.635Z",
  },
  {
    id: "671a33c2a378154b5481aa2e",
    sid: "241024-424287",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a33c1a378154b5481aa10",
    firstName: "Ebrahem",
    lastName: null,
    phoneNumber: "+96596655718",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66114be8d17e71b5c7ea2863",
    delivery: {
      id: "671a33c1a378154b5481aa10",
      customerId: "66114be8d17e71b5c7ea2863",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66114f6b0f6299f287b530de",
        name: "39",
        lng: 48.00091210752726,
        lat: 29.327929411879452,
        city: "Kuwait",
        area: "Rawda",
        block: "2",
        street: "24 St",
        building: "39",
        floor: "",
        flat: "",
        phone: "+96596655718",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-06T13:34:35.353Z",
        updatedAt: "2024-10-23T15:52:27.320Z",
      },
      deliverectValidationId: "671a33c155a63cc4b3d0078a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:57:13.094Z",
      estimatedDeliveryTime: "2024-10-24T12:12:11.887Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:42:11.887Z",
      orderId: "671a33c2a378154b5481aa2e",
      createdAt: "2024-10-24T11:47:13.496Z",
      updatedAt: "2024-10-24T11:47:14.782Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824675",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "pending",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:47:14.757Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:47:14.757Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:47:34.955Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:47:14.757Z",
    updatedAt: "2024-10-24T11:47:34.955Z",
    fleetInfo: null,
  },
  {
    id: "671a33c0a378154b5481a9f8",
    sid: "241024-453984",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a33bfa378154b5481a9ed",
    firstName: "Abrar Alnuwaif",
    lastName: null,
    phoneNumber: "+96590099997",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "666b48e3eff64cfb0693b41e",
    delivery: {
      id: "671a33bfa378154b5481a9ed",
      customerId: "666b48e3eff64cfb0693b41e",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "666c0f430ce21ecb25a5c9e1",
        name: "Home",
        lng: 48.06983917951584,
        lat: 29.27605389897181,
        city: "Kuwait",
        area: "Mishref",
        block: "4",
        street: "8",
        building: "3",
        floor: "",
        flat: "",
        phone: "+96590099997",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-14T09:37:07.240Z",
        updatedAt: "2024-06-24T13:34:04.319Z",
      },
      deliverectValidationId: "671a33bf2e12b68950a9dfa2",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:57:11.738Z",
      estimatedDeliveryTime: "2024-10-24T12:12:10.339Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:42:10.339Z",
      orderId: "671a33c0a378154b5481a9f8",
      createdAt: "2024-10-24T11:47:11.884Z",
      updatedAt: "2024-10-24T11:47:12.426Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824661",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba90824691",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.2,
          protein: 2.8,
          fat: 8.6,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "pending",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:47:12.400Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:47:12.400Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:47:24.275Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:47:12.400Z",
    updatedAt: "2024-10-24T11:47:24.276Z",
    fleetInfo: null,
  },
  {
    id: "671a33a0a378154b548197e4",
    sid: "241024-160175",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a339fa378154b548197db",
    firstName: "Shikha bin sabt",
    lastName: null,
    phoneNumber: "+96597568552",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64ff49e3ec522879c1b4f693",
    delivery: {
      id: "671a339fa378154b548197db",
      customerId: "64ff49e3ec522879c1b4f693",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65363826289c08cbdb9fa996",
        name: "Home",
        lng: 47.9861678,
        lat: 29.3123481,
        city: "Kuwait",
        area: "Qortuba",
        block: "2",
        street: "4",
        building: "53",
        floor: "",
        flat: "",
        phone: "+96597568552",
        additionalInfo: "Yellow house corner ",
        isSelected: true,
        createdAt: "2023-10-23T09:08:54.847Z",
        updatedAt: "2024-10-24T11:42:34.023Z",
      },
      deliverectValidationId: "671a339fc581fc4bf1234d19",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:56:39.741Z",
      estimatedDeliveryTime: "2024-10-24T12:11:38.707Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:41:38.707Z",
      orderId: "671a33a0a378154b548197e4",
      createdAt: "2024-10-24T11:46:39.847Z",
      updatedAt: "2024-10-24T11:46:40.471Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d11",
        productPLU: "2162043-1",
        productDescription:
          "C 45g| P 28g| F 16g\nPink pasta with grilled chicken breast, topped with parmesan cheese.\n\nGood source of B-Complex Vitamins",
        productName: "Pink Pasta",
        imageURL:
          "https://resizer.deliverect.com/h10UGlGO3vP_ayu1YBOqijxvB6J8MuhGdH52j7lOn8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGluayUyMHBhc3RhLTY0YjdhZmJlZWUwY2I5MDAyMWRjNWRlOC5wbmc=",
        productCalories: 436,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 28,
          fat: 16,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 0,
    totalExternalFiatCentsPrice: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "pending",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:46:40.456Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:46:40.456Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:46:51.883Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:46:40.456Z",
    updatedAt: "2024-10-24T11:46:51.884Z",
    fleetInfo: null,
  },
  {
    id: "671a3378a378154b54817fb5",
    sid: "241024-432849",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a3376a378154b54817ed7",
    firstName: "Muneera jassim",
    lastName: null,
    phoneNumber: "+96599766282",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6511c987e1d91353418f4148",
    delivery: {
      id: "671a3376a378154b54817ed7",
      customerId: "6511c987e1d91353418f4148",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6538efb8289c08cbdbafd7ae",
        name: "Muneera",
        lng: 48.028583619743586,
        lat: 29.27796672931579,
        city: "Mubarak Al-Abdullah",
        area: "Hateen",
        block: "3",
        street: "311",
        building: "52",
        floor: "",
        flat: "",
        phone: "+96599766282",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-25T10:36:40.132Z",
        updatedAt: "2024-10-22T17:36:06.000Z",
      },
      deliverectValidationId: "671a3376c581fc4bf1233e17",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:55:58.009Z",
      estimatedDeliveryTime: "2024-10-24T12:10:56.929Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:42:00.000Z",
      orderId: "671a3378a378154b54817fb5",
      createdAt: "2024-10-24T11:45:58.631Z",
      updatedAt: "2024-10-24T11:47:08.278Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825cf0",
        productPLU: "2162116-1",
        productDescription:
          "C 61g | P 36g | F 12g\nVeal with biryani rice & sauce, lime wedges, salad, lime yogurt, air fried onion, rose petals, coriander, roasted cashew nuts, golden raisins.\n\nGOOD SOURCE OF PROTEIN,VITAMIN & MINERALS",
        productName: "Veal Biryani",
        imageURL:
          "https://resizer.deliverect.com/4XXRKqwztfEBnvDx9RJ6qOfBLstTwbE3mNEBSrckFJ8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVkVBTCUyMEJJUllBTkktNjY1MDNhOWRlMWFlMGZiZmFkNjMwNTFhLnBuZw==",
        productCalories: 496,
        quantity: 1,
        nutritionInfo: {
          carbs: 61,
          protein: 36,
          fat: 12,
        },
        fmcCentsPrice: 350,
        fiatCentsPrice: 350,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d27",
        productPLU: "2163020-1",
        productDescription:
          "C 10g | P 2g | F 3g [%New%]\n\nRomaine lettuce, cucumber, green capsicum, spring onion, parsley, dill leaves, sumac, croutons with Italian dressing.",
        productName: "Mini Italian salad",
        imageURL:
          "https://resizer.deliverect.com/XkxzmQRK5xU2IA0XJqjnN8x_RpgC6KxTMoxtFe2tjkE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGl0YWxpYW4lMjBzYWxhZC02NzEzNGUwMjQ4OGM3MjkyNjAyMTliNDcucG5n",
        productCalories: 75,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 2,
          fat: 3,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 480,
    totalFmcCentsPrice: 480,
    totalFiatCentsPriceOriginal: 480,
    totalExternalFiatCentsPrice: 480,
    totalFmcCentsPriceOriginal: 480,
    orderStatus: "accepted",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:46:00.118Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:47:24.028Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:46:00.118Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:46:15.789Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:46:00.118Z",
    updatedAt: "2024-10-24T11:47:24.030Z",
    fleetInfo: null,
  },
  {
    id: "671a336fa378154b54817add",
    sid: "241024-315544",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a336ea378154b54817a0f",
    firstName: "فوزية سعود الزايد",
    lastName: null,
    phoneNumber: "+96599070616",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "656b2580a3b189708bcc723e",
    delivery: {
      id: "671a336ea378154b54817a0f",
      customerId: "656b2580a3b189708bcc723e",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "656b2649521b44fb94a55514",
        name: "المنزل",
        lng: 48.013454116880894,
        lat: 29.318413015615118,
        city: "Kuwait",
        area: "السرة",
        block: "١",
        street: "شارع 11",
        building: "١٧",
        floor: "",
        flat: "",
        phone: "+96599070616",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-02T12:42:49.248Z",
        updatedAt: "2023-12-02T12:42:49.248Z",
      },
      deliverectValidationId: "671a336e8da25298e78a996d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:55:50.303Z",
      estimatedDeliveryTime: "2024-10-24T12:10:49.303Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:42:00.000Z",
      orderId: "671a336fa378154b54817add",
      createdAt: "2024-10-24T11:45:50.415Z",
      updatedAt: "2024-10-24T11:47:08.279Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba9082465c",
        productPLU: "2162077-1",
        productDescription:
          "C 41g | P 26g | F 16g\nShort ribs, mixed cabbage, sour cream, spring onion, parsely with tortilla wrap.\n\nGood Source Of Protein, Vitamin B12, Minerals",
        productName: "Short Rib Tacos",
        imageURL:
          "https://resizer.deliverect.com/bwNBblwmBg7NZVLL0zxwD_1h0r1mAgb-zXh607JTGn4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVEFDTy02NTI5ZjMxMWQyNmQ1ZGYyNzhkZDZiYjYucG5n",
        productCalories: 420,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 26,
          fat: 16,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba908246a3",
        productPLU: "2166031-1",
        productDescription:
          "C 32g| P 12g| F 12g\nBaked sweet potato fries with mayo barbeque sauce.",
        productName: "Baked Sweet Potato Fries",
        imageURL:
          "https://resizer.deliverect.com/EexbDtzbKG0sVOjjNXiIlT_MIN2doyc2NlpJu2_T6nc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3dlZXQlMjBwb3RhdG8lMjBmcmllcygxKS02NTU0OGY3NzYyYWIwZTAwMWYzYTczNzEucG5n",
        productCalories: 244,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 12,
          fat: 12,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 550,
    totalFmcCentsPrice: 550,
    totalFiatCentsPriceOriginal: 550,
    totalExternalFiatCentsPrice: 550,
    totalFmcCentsPriceOriginal: 550,
    orderStatus: "accepted",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:45:51.492Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:47:07.280Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:45:51.492Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:46:04.224Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:45:51.492Z",
    updatedAt: "2024-10-24T11:47:07.281Z",
    fleetInfo: null,
  },
  {
    id: "671a335aa378154b54816b43",
    sid: "241024-583902",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a3358a378154b54816aa6",
    firstName: "Hamad Nabil Alawadhi",
    lastName: null,
    phoneNumber: "+96599227252",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65b267bee1ffdaa1364e62e0",
    delivery: {
      id: "671a3358a378154b54816aa6",
      customerId: "65b267bee1ffdaa1364e62e0",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65b26849f140bf59260856ab",
        name: "Home",
        lng: 47.992640510201454,
        lat: 29.281646865328845,
        city: "Zahra",
        area: "Zahra",
        block: "3",
        street: "304",
        building: "11",
        floor: "",
        flat: "",
        phone: "+96599227252",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-25T13:55:21.907Z",
        updatedAt: "2024-10-12T13:11:02.187Z",
      },
      deliverectValidationId: "671a33584f3524be78f9724b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:55:28.839Z",
      estimatedDeliveryTime: "2024-10-24T12:10:27.273Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:42:00.000Z",
      orderId: "671a335aa378154b54816b43",
      createdAt: "2024-10-24T11:45:28.948Z",
      updatedAt: "2024-10-24T11:47:07.964Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825ceb",
        productPLU: "2162118-1",
        productDescription:
          "C 60g | P 22g | F 17g \nTomato sauce, cheese & pepperoni\n\nGood source of Protein ",
        productName: "Pepperoni Pizza",
        imageURL:
          "https://resizer.deliverect.com/QJO9SowT2ihHOIWJoe4NojUcvMSrdQhHrE3QE_iNeHs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVwcGVyb25pLTY2NzQzMzkzMjU0YjRkYjE0ZjE5OTk4MS5wbmc=",
        productCalories: 481,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 22,
          fat: 17,
        },
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825cdf",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d1e",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 24,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 690,
    totalFmcCentsPrice: 690,
    totalFiatCentsPriceOriginal: 690,
    totalExternalFiatCentsPrice: 690,
    totalFmcCentsPriceOriginal: 690,
    orderStatus: "accepted",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:45:30.081Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:47:21.940Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:45:30.081Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:45:52.655Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:45:30.081Z",
    updatedAt: "2024-10-24T11:47:21.943Z",
    fleetInfo: null,
  },
  {
    id: "671a3359a378154b54816b3d",
    sid: "241024-861025",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a3357a378154b548169cf",
    firstName: "Rushaid albader",
    lastName: null,
    phoneNumber: "+96597511117",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "652603501bfa57c5dbf42752",
    delivery: {
      id: "671a3357a378154b548169cf",
      customerId: "652603501bfa57c5dbf42752",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66baebf70846fbb356d7dc56",
        name: "Shamiya house",
        lng: 47.97025915235281,
        lat: 29.354657679108662,
        city: "Kuwait",
        area: "Shamiya",
        block: "5",
        street: "50",
        building: "5",
        floor: "",
        flat: "",
        phone: "+96597511117",
        additionalInfo: "White house",
        isSelected: true,
        createdAt: "2024-08-13T05:15:35.004Z",
        updatedAt: "2024-10-22T05:55:01.906Z",
      },
      deliverectValidationId: "671a3357621b7746ab7d4c5a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:55:27.260Z",
      estimatedDeliveryTime: "2024-10-24T12:10:25.981Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:42:00.000Z",
      orderId: "671a3359a378154b54816b3d",
      createdAt: "2024-10-24T11:45:27.733Z",
      updatedAt: "2024-10-24T11:47:07.438Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823e8f",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ef5",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 340,
    totalExternalFiatCentsPrice: 340,
    totalFmcCentsPriceOriginal: 340,
    orderStatus: "accepted",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:45:29.877Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:47:07.443Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:45:29.877Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:45:41.806Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:45:29.877Z",
    updatedAt: "2024-10-24T11:47:07.444Z",
    fleetInfo: null,
  },
  {
    id: "671a3343a378154b54815dbb",
    sid: "241024-718379",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a3343a378154b54815db3",
    firstName: "Nourah Alshammari ",
    lastName: null,
    phoneNumber: "+96555335036",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6594f7352d5e4e992686f4b8",
    delivery: {
      id: "671a3343a378154b54815db3",
      customerId: "6594f7352d5e4e992686f4b8",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "668ae8de09647a7c33815b49",
        name: "المنزل",
        lng: 47.8729007737903,
        lat: 29.300155639648438,
        city: "الأندلس",
        area: "الأندلس",
        block: "قطعة 6",
        street: "5",
        building: "286",
        floor: "",
        flat: "",
        phone: "+96555335036",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-07T19:13:34.507Z",
        updatedAt: "2024-10-21T19:06:57.627Z",
      },
      deliverectValidationId: "671a33426a3e6800dd3ced80",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:55:06.985Z",
      estimatedDeliveryTime: "2024-10-24T12:10:05.876Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:42:00.000Z",
      orderId: "671a3343a378154b54815dbb",
      createdAt: "2024-10-24T11:45:07.122Z",
      updatedAt: "2024-10-24T11:47:06.743Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d12",
        productPLU: "2162037-1",
        productDescription:
          "C 62g| P 27g| F 12.4g [%Popular%]\n\nA classic favorite made with perfectly cooked spaghetti & a hearty bolognese sauce. Topped with a sprinkle of parmesan cheese & chopped parsley & garnished with a fresh basil sprig.",
        productName: "Spaghetti Bolognese",
        imageURL:
          "https://resizer.deliverect.com/8ryC24TcKatYjKjvTqCxeYugRnVz3p47fJ-PTIB67ek/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhZ2hldHRpLTY3MTM1NWVlY2NiZDAxOGZkYzRlNGNjYy5wbmc=",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 62,
          protein: 27,
          fat: 12.4,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "accepted",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:45:07.848Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:47:20.558Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:45:07.848Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:45:19.350Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:45:07.848Z",
    updatedAt: "2024-10-24T11:47:20.559Z",
    fleetInfo: null,
  },
  {
    id: "671a3304a378154b54813ef3",
    sid: "241024-304465",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a3304a378154b54813ee7",
    firstName: "Dana alnesef",
    lastName: null,
    phoneNumber: "+96596967777",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "650e00a7e1d91353418b188c",
    delivery: {
      id: "671a3304a378154b54813ee7",
      customerId: "650e00a7e1d91353418b188c",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66cb5727a48a4cf50fa40585",
        name: "Home",
        lng: 47.98222448676824,
        lat: 29.35539877770801,
        city: "Al Kuwayt",
        area: "Abdullah al-Salem",
        block: "1",
        street: "Rashed Boresli St",
        building: "13",
        floor: "",
        flat: "",
        phone: "+96596967777",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-25T16:09:11.306Z",
        updatedAt: "2024-10-23T16:29:02.133Z",
      },
      deliverectValidationId: "671a3303b63f266a7db46fd9",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:54:03.964Z",
      estimatedDeliveryTime: "2024-10-24T12:09:02.928Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:41:00.000Z",
      orderId: "671a3304a378154b54813ef3",
      createdAt: "2024-10-24T11:44:04.105Z",
      updatedAt: "2024-10-24T11:46:05.412Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823ec8",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671a1ab4e79c0dba90823f86",
            productPLU: "1907056-1",
            productName: "No Onion",
            productDescription: "C -0.5g| P 0g| F 0g| cal -2.2| coins 0",
            productCalories: -2.2,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.5,
              protein: null,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "671a1ab4e79c0dba90823eee",
        productPLU: "2150014-1",
        productDescription: "C 2g| P 30g| F 6g\n",
        productName: "Chicken Shawarma(100GM)",
        imageURL:
          "https://resizer.deliverect.com/5s0dpyz1hk8m1gvg8YGrM7u6yPuJzcNpjKxdUNjSi6g/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMHNoYXdhcm1hJTIwKDEpLTY0ZDc1YjZhNjg1NDg1MDAyNzQwZTNmNy5qcGc=",
        productCalories: 182,
        quantity: 1,
        nutritionInfo: {
          carbs: 2,
          protein: 30,
          fat: 6,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 420,
    totalFmcCentsPrice: 420,
    totalFiatCentsPriceOriginal: 420,
    totalExternalFiatCentsPrice: 420,
    totalFmcCentsPriceOriginal: 420,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:44:04.853Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:46:05.091Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:46:26.450Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:44:04.853Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:44:17.046Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:44:04.853Z",
    updatedAt: "2024-10-24T11:46:26.453Z",
    fleetInfo: null,
  },
  {
    id: "671a32c7a378154b54812922",
    sid: "241024-388400",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a32c6a378154b54812916",
    firstName: "Manar Jaber",
    lastName: null,
    phoneNumber: "+96566400450",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "661f61acb0d178e63207ee98",
    delivery: {
      id: "671a32c6a378154b54812916",
      customerId: "661f61acb0d178e63207ee98",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6660a00f3d5f2b755d3d9db0",
        name: "Home Bayan",
        lng: 48.0486843,
        lat: 29.2874887,
        city: "Kuwait",
        area: "بيان",
        block: "13",
        street: "12",
        building: "33",
        floor: "",
        flat: "",
        phone: "+96566400450",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-05T17:27:43.471Z",
        updatedAt: "2024-09-06T09:44:18.119Z",
      },
      deliverectValidationId: "671a32c6c581fc4bf122d19d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:53:02.123Z",
      estimatedDeliveryTime: "2024-10-24T12:08:00.522Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:40:00.000Z",
      orderId: "671a32c7a378154b54812922",
      createdAt: "2024-10-24T11:43:02.367Z",
      updatedAt: "2024-10-24T11:45:08.256Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba908246d6",
        productPLU: "2202022-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Sprite Zero",
        imageURL:
          "https://resizer.deliverect.com/ZEZdNlPffzmGS3rg7Tvd5hMcV02zZNG7y9fZavC83oI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMSUyMCgxKS02NGM5MTk0M2E0NTBlNjAwMjY4M2QzODEuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba9082463d",
        productPLU: "2162134-1",
        productDescription:
          "C 33g | P 22g | F 19g [%New%]\n\nSesame bun, chicken patty, mayo sauce mix, spicy coleslaw, and cheddar cheese.",
        productName: "Spicy slaw chicken burger",
        imageURL:
          "https://resizer.deliverect.com/ocLKlrRu1U60c57669Upgx0hkVW30a4Pd8p0JRqT0as/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BpY3klMjBzbGF3JTIwY2hpY2tlbiUyMGJ1cmdlci02NzEzNTA3YmJhNmM3ZTllODMyZWQzYmQucG5n",
        productCalories: 391,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 22,
          fat: 19,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba908246ca",
        productPLU: "2165005-1",
        productDescription: "C 13g| P 1g| F 10g",
        productName: "Salted Pecan Bites",
        imageURL:
          "https://resizer.deliverect.com/p3z6wZFsuIaNIkw99YRPwzlHvwGLg970lPG5QDvZQrc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDgtNjRiN2JjOTE2ZjhhYmY0M2UzMWQxMjBjLnBuZw==",
        productCalories: 151,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 1,
          fat: 10,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 360,
    totalFmcCentsPrice: 360,
    totalFiatCentsPriceOriginal: 360,
    totalExternalFiatCentsPrice: 360,
    totalFmcCentsPriceOriginal: 360,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:43:03.197Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:45:22.537Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:45:22.537Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:43:03.197Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:43:19.800Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:43:03.197Z",
    updatedAt: "2024-10-24T11:45:25.968Z",
    fleetInfo: null,
  },
  {
    id: "671a32baa378154b54812227",
    sid: "241024-338239",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a32b9a378154b54812103",
    firstName: "Arwa AlYaqout",
    lastName: null,
    phoneNumber: "+96596069191",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65352e661bfa57c5db027b2b",
    delivery: {
      id: "671a32b9a378154b54812103",
      customerId: "65352e661bfa57c5db027b2b",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6715404ab7a34cd092745964",
        name: "My home",
        lng: 48.028524475436384,
        lat: 29.268661499023438,
        city: "Kuwait",
        area: "Shuhada",
        block: "4",
        street: "409",
        building: "21",
        floor: "",
        flat: "",
        phone: "+96596069191",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-20T17:39:22.597Z",
        updatedAt: "2024-10-23T12:16:22.885Z",
      },
      deliverectValidationId: "671a32b9b63263b7f63effca",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:52:49.134Z",
      estimatedDeliveryTime: "2024-10-24T12:07:47.728Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:39:00.000Z",
      orderId: "671a32baa378154b54812227",
      createdAt: "2024-10-24T11:42:49.372Z",
      updatedAt: "2024-10-24T11:44:08.551Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d25",
        productPLU: "2163022-1",
        productDescription:
          "C 15g | P 2g | F 14g [%New%]\n\nVeggies with fattoush dressing & croutons.\n\nGood source of Vitamins",
        productName: "Mini Fattoush",
        imageURL:
          "https://resizer.deliverect.com/QUcv0CxdaaAXW0GlMNB1GAQ5fahshLaeuOoMIRAFR5c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGZhdHRvdXNoJTIwc2FsYWQtNjcxMzUxNmViYTZjN2U5ZTgzMmVkM2MyLnBuZw==",
        productCalories: 194,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 2,
          fat: 14,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d06",
        productPLU: "2162022-1",
        productDescription:
          "C 32g | P 40g | F 21g\nShish tawook with batata harra, sumac white onions and pickled cucumber served with tahini sauce.\n\nGluten free,High in protien,Good source of Vitamin C",
        productName: "Shish Tawook with Batata Harra",
        imageURL:
          "https://resizer.deliverect.com/t8iFrO7JgasiTM_3BgLdmFPccJzHYDm3brRNicOgS5E/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjktNjRjOGJlYWZhNDUwZTYwMDI2ODNkMjhhLnBuZw==",
        productCalories: 477,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 40,
          fat: 21,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "accepted",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:42:50.758Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:44:09.376Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:42:50.758Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:43:06.328Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:42:50.758Z",
    updatedAt: "2024-10-24T11:44:09.376Z",
    fleetInfo: null,
  },
  {
    id: "671a32b4a378154b54811dd3",
    sid: "241024-132559",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a32b3a378154b54811dc8",
    firstName: "Atyab al wasmi",
    lastName: null,
    phoneNumber: "+96566738280",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6717d433b58bc4730843892c",
    delivery: {
      id: "671a32b3a378154b54811dc8",
      customerId: "6717d433b58bc4730843892c",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "671a3249a378154b5480e381",
        name: "Hayas",
        lng: 47.964039109647274,
        lat: 29.342974652376085,
        city: "Kuwait",
        area: "Kaifan",
        block: "2",
        street: "Abo alasseya ",
        building: "8",
        floor: "",
        flat: "",
        phone: "+96566738280",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-24T11:40:57.325Z",
        updatedAt: "2024-10-24T11:40:57.325Z",
      },
      deliverectValidationId: "671a32b355a63cc4b3cf84eb",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:52:43.312Z",
      estimatedDeliveryTime: "2024-10-24T12:07:42.244Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:39:00.000Z",
      orderId: "671a32b4a378154b54811dd3",
      createdAt: "2024-10-24T11:42:43.421Z",
      updatedAt: "2024-10-24T11:44:06.968Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823ec0",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823eef",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 360,
    totalFmcCentsPrice: 360,
    totalFiatCentsPriceOriginal: 360,
    totalExternalFiatCentsPrice: 360,
    totalFmcCentsPriceOriginal: 360,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:42:44.040Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:44:06.616Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:46:26.543Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:42:44.040Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:42:55.727Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:42:44.041Z",
    updatedAt: "2024-10-24T11:46:26.543Z",
    fleetInfo: null,
  },
  {
    id: "671a32a1a378154b548111f1",
    sid: "241024-009751",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a329ea378154b54810f77",
    firstName: "Shoug",
    lastName: null,
    phoneNumber: "+96555110550",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6629753611936febbb1d477e",
    delivery: {
      id: "671a329ea378154b54810f77",
      customerId: "6629753611936febbb1d477e",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6629763d8f1be751057b5431",
        name: "Home",
        lng: 48.09980066314558,
        lat: 29.188400268554688,
        city: "Kuwait",
        area: "Abu 'Fteira",
        block: "7",
        street: "165",
        building: "167",
        floor: "0",
        flat: "",
        phone: "+96555110550",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-24T21:14:37.741Z",
        updatedAt: "2024-10-21T14:18:27.631Z",
      },
      deliverectValidationId: "671a329eb4faa71444c36881",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:52:22.750Z",
      estimatedDeliveryTime: "2024-10-24T12:07:21.769Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:39:00.000Z",
      orderId: "671a32a1a378154b548111f1",
      createdAt: "2024-10-24T11:42:22.867Z",
      updatedAt: "2024-10-24T11:44:07.400Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e54",
        productPLU: "2162037-1",
        productDescription:
          "C 62g| P 27g| F 12.4g [%Popular%]\n\nA classic favorite made with perfectly cooked spaghetti & a hearty bolognese sauce. Topped with a sprinkle of parmesan cheese & chopped parsley & garnished with a fresh basil sprig.",
        productName: "Spaghetti Bolognese",
        imageURL:
          "https://resizer.deliverect.com/8ryC24TcKatYjKjvTqCxeYugRnVz3p47fJ-PTIB67ek/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhZ2hldHRpLTY3MTM1NWVlY2NiZDAxOGZkYzRlNGNjYy5wbmc=",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 62,
          protein: 27,
          fat: 12.4,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824e5a",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824e76",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.2,
          protein: 2.8,
          fat: 8.6,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 630,
    totalFmcCentsPrice: 630,
    totalFiatCentsPriceOriginal: 630,
    totalExternalFiatCentsPrice: 630,
    totalFmcCentsPriceOriginal: 630,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:42:25.686Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:44:05.864Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:45:10.578Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:42:25.686Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:42:38.012Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:42:25.686Z",
    updatedAt: "2024-10-24T11:45:10.579Z",
    fleetInfo: null,
  },
  {
    id: "671a322fa378154b5480dc39",
    sid: "241024-759876",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a322fa378154b5480dc29",
    firstName: "Wadha Alshuwaib ",
    lastName: null,
    phoneNumber: "+96599604480",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67149fe6b58bc473083a8ea7",
    delivery: {
      id: "671a322fa378154b5480dc29",
      customerId: "67149fe6b58bc473083a8ea7",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6714a5d8b7a34cd09241217b",
        name: "H 70",
        lng: 47.9861678,
        lat: 29.3123481,
        city: "Kuwait",
        area: "Qortuba",
        block: "BLK 4",
        street: "ST 4",
        building: "H 70",
        floor: "",
        flat: "",
        phone: "+96599604480",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-20T06:40:24.908Z",
        updatedAt: "2024-10-21T10:25:55.112Z",
      },
      deliverectValidationId: "671a322e88e23371a92ba94f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:50:30.921Z",
      estimatedDeliveryTime: "2024-10-24T12:05:29.836Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:37:00.000Z",
      orderId: "671a322fa378154b5480dc39",
      createdAt: "2024-10-24T11:40:31.015Z",
      updatedAt: "2024-10-24T11:42:06.118Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d0c",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d10",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 480,
    totalFmcCentsPrice: 480,
    totalFiatCentsPriceOriginal: 480,
    totalExternalFiatCentsPrice: 480,
    totalFmcCentsPriceOriginal: 480,
    orderStatus: "accepted",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:40:31.660Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:42:05.679Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:40:31.660Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:40:43.077Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T11:40:31.660Z",
    updatedAt: "2024-10-24T11:42:05.680Z",
    fleetInfo: null,
  },
  {
    id: "671a320ca378154b5480d192",
    sid: "241024-639971",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a320ba378154b5480d0bf",
    firstName: "Deema AlFalah",
    lastName: null,
    phoneNumber: "+96566363117",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64cff176f119a7788c994656",
    delivery: {
      id: "671a320ba378154b5480d0bf",
      customerId: "64cff176f119a7788c994656",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64ff127d34491db2e9f819f1",
        name: "home",
        lng: 48.10359968633959,
        lat: 29.18011474609375,
        city: "Kuwait",
        area: "egaila ",
        block: "1",
        street: "110",
        building: "27",
        floor: "",
        flat: "",
        phone: "+96566363117",
        additionalInfo: "ring bell pls",
        isSelected: true,
        createdAt: "2023-09-11T13:13:33.707Z",
        updatedAt: "2024-10-22T11:38:46.025Z",
      },
      deliverectValidationId: "671a320b4cb7e660cd25d19f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:49:55.220Z",
      estimatedDeliveryTime: "2024-10-24T12:04:53.499Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:36:00.000Z",
      orderId: "671a320ca378154b5480d192",
      createdAt: "2024-10-24T11:39:55.383Z",
      updatedAt: "2024-10-24T11:41:06.601Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e22",
        productPLU: "2162134-1",
        productDescription:
          "C 33g | P 22g | F 19g [%New%]\n\nSesame bun, chicken patty, mayo sauce mix, spicy coleslaw, and cheddar cheese.",
        productName: "Spicy slaw chicken burger",
        imageURL:
          "https://resizer.deliverect.com/ocLKlrRu1U60c57669Upgx0hkVW30a4Pd8p0JRqT0as/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BpY3klMjBzbGF3JTIwY2hpY2tlbiUyMGJ1cmdlci02NzEzNTA3YmJhNmM3ZTllODMyZWQzYmQucG5n",
        productCalories: 391,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 22,
          fat: 19,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "without coleslaw ",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824e87",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:39:56.453Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:41:06.523Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:45:27.114Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:39:56.453Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:40:09.949Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:39:56.453Z",
    updatedAt: "2024-10-24T11:45:27.128Z",
    fleetInfo: null,
  },
  {
    id: "671a31faa378154b5480c2fc",
    sid: "241024-818872",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a31f8a378154b5480c1f8",
    firstName: "Lulu Boodai",
    lastName: null,
    phoneNumber: "+96599440006",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66dd781aaa1645e3a508d082",
    delivery: {
      id: "671a31f8a378154b5480c1f8",
      customerId: "66dd781aaa1645e3a508d082",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66dd78c3ced6c5247e0a69cd",
        name: "home",
        lng: 47.9854951892495,
        lat: 29.359375,
        city: "Kuwait City",
        area: "Abdullah al-Salem",
        block: "1",
        street: "12 St",
        building: "10 A",
        floor: "2",
        flat: "4",
        phone: "+96599440006",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-08T10:13:23.917Z",
        updatedAt: "2024-09-08T10:13:23.917Z",
      },
      deliverectValidationId: "671a31f86ef78a7622048ef0",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:49:36.437Z",
      estimatedDeliveryTime: "2024-10-24T12:04:34.728Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:34:34.728Z",
      orderId: "671a31faa378154b5480c2fc",
      createdAt: "2024-10-24T11:39:36.825Z",
      updatedAt: "2024-10-24T11:39:38.487Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823eb8",
        productPLU: "2162085-1",
        productDescription:
          "C 15g| P 45g| F 15g\nGrilled chicken served with mashed potatoes, blanched broccoli, cauliflower & carrots, served with a lemon sauce garnished with a slice of lemon and parsley.\n\nGood source of Protein",
        productName: "Grilled Lemon Chicken",
        imageURL:
          "https://resizer.deliverect.com/n9S1sQV2m-69QdAwmCpIpZWcf6LGGUpyB0bFdTHRC1U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3JpbGxlZCUyMGxlbW9uJTIwY2hpY2tlbigxKS02NTU0OTA2MWZhNTNkZDAwMWVlOTQ2N2IucG5n",
        productCalories: 375,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 45,
          fat: 15,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823eb9",
        productPLU: "2162074-1",
        productDescription:
          "C 59g| P 29g| F 13g\nKung pao chicken topped with toasted peanuts.\n\nGOOD SOURCE OF  PROTEIN",
        productName: "Kung Pao Chicken",
        imageURL:
          "https://resizer.deliverect.com/5-Abvm0e_bQAk3bZkwUrzX_ONYVykRTm2X4SywUk0vQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjAwLTY1MTdlZTc1ZmVkZWU0YTU4MDc0NGQ5Yy5qcGc=",
        productCalories: 469,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 29,
          fat: 13,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ebc",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ec0",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ef4",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ee1",
        productPLU: "2163010-1",
        productDescription:
          "C 25g| P 34g| F 15g\nGrilled chicken with baby gem lettuce, caesar dressing topped with sourdough croutons & shaved parmesan cheese.\n\nGood source of Vitamin A &Potein",
        productName: "Chicken Ceasar Salad",
        imageURL:
          "https://resizer.deliverect.com/aOyM36xC7gBB5GwjyrYYA3YsTJ8-9RRXq22EPXeQlkM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NGI3YjVjOWU0ZDVjMzAwMThiNjViNmIucG5n",
        productCalories: 371,
        quantity: 1,
        nutritionInfo: {
          carbs: 25,
          protein: 34,
          fat: 15,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823eea",
        productPLU: "2162021-1",
        productDescription:
          "C 60g| P 50g| F 16g\nChicken shawarma served with calrose rice & drizzled with our shawarma sauce and sumac white onions.\n\nHigh in protein, ",
        productName: "Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/wpaWs0_xzYbkz_6vxHW2469_71kHXwgrjXuSA4epEwY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2hpY2tlbiUyMFNoYXdhcm1hJTIwYm93bC02NGY0OTRlOTFhNzliZjNkNzNkYjUzNjcuanBn",
        productCalories: 584,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 50,
          fat: 16,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 1650,
    totalFmcCentsPrice: 1650,
    totalFiatCentsPriceOriginal: 1650,
    totalExternalFiatCentsPrice: 1650,
    totalFmcCentsPriceOriginal: 1650,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:39:38.471Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:41:06.378Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:41:28.560Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:39:38.471Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:39:49.827Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:39:38.471Z",
    updatedAt: "2024-10-24T11:41:28.560Z",
    fleetInfo: null,
  },
  {
    id: "671a31c6a378154b5480ab3e",
    sid: "241024-550005",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a31c6a378154b5480ab22",
    firstName: "Ali Bin Hussain ",
    lastName: null,
    phoneNumber: "+96599990511",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6703c4a1906276458c861219",
    delivery: {
      id: "671a31c6a378154b5480ab22",
      customerId: "6703c4a1906276458c861219",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6703c7d3218a8c4af28dba62",
        name: "Home",
        lng: 48.06322468444705,
        lat: 29.106104666925788,
        city: "Ahmadi",
        area: "North Ahmadi",
        block: "16",
        street: "15avenue",
        building: "11",
        floor: "",
        flat: "",
        phone: "+96599990511",
        additionalInfo: "Between house 9 & 13",
        isSelected: true,
        createdAt: "2024-10-07T11:36:51.296Z",
        updatedAt: "2024-10-22T08:42:32.107Z",
      },
      deliverectValidationId: "671a31c64cb7e660cd25b438",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:48:46.088Z",
      estimatedDeliveryTime: "2024-10-24T12:03:44.956Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:35:00.000Z",
      orderId: "671a31c6a378154b5480ab3e",
      createdAt: "2024-10-24T11:38:46.196Z",
      updatedAt: "2024-10-24T11:40:07.554Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e21",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824e73",
        productPLU: "2163010-1",
        productDescription:
          "C 25g| P 34g| F 15g\nGrilled chicken with baby gem lettuce, caesar dressing topped with sourdough croutons & shaved parmesan cheese.\n\nGood source of Vitamin A &Potein",
        productName: "Chicken Ceasar Salad",
        imageURL:
          "https://resizer.deliverect.com/aOyM36xC7gBB5GwjyrYYA3YsTJ8-9RRXq22EPXeQlkM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NGI3YjVjOWU0ZDVjMzAwMThiNjViNmIucG5n",
        productCalories: 371,
        quantity: 1,
        nutritionInfo: {
          carbs: 25,
          protein: 34,
          fat: 15,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 550,
    totalFmcCentsPrice: 550,
    totalFiatCentsPriceOriginal: 550,
    totalExternalFiatCentsPrice: 550,
    totalFmcCentsPriceOriginal: 550,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:38:46.873Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:40:06.886Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:40:17.373Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:38:46.873Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:39:07.646Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:38:46.873Z",
    updatedAt: "2024-10-24T11:40:17.374Z",
    fleetInfo: null,
  },
  {
    id: "671a31c3a378154b5480aa4d",
    sid: "241024-879651",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a31c1a378154b5480a8ed",
    firstName: "Mubarak alshamroukh",
    lastName: null,
    phoneNumber: "+96566677717",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6649d92869f370da27dbdb8a",
    delivery: {
      id: "671a31c1a378154b5480a8ed",
      customerId: "6649d92869f370da27dbdb8a",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6649db23e7d7ea1c4c63143f",
        name: "Home ",
        lng: 47.94193370472455,
        lat: 29.2823486328125,
        city: "Kuwait",
        area: "Rehab",
        block: "1 ",
        street: "5 ",
        building: "28",
        floor: "Ground",
        flat: "",
        phone: "+96566677717",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-19T10:57:39.482Z",
        updatedAt: "2024-05-19T10:57:39.482Z",
      },
      deliverectValidationId: "671a31c1827135858bc9f390",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:48:41.653Z",
      estimatedDeliveryTime: "2024-10-24T12:03:40.313Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:35:00.000Z",
      orderId: "671a31c3a378154b5480aa4d",
      createdAt: "2024-10-24T11:38:41.886Z",
      updatedAt: "2024-10-24T11:40:05.730Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d18",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d45",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 360,
    totalFmcCentsPrice: 360,
    totalFiatCentsPriceOriginal: 360,
    totalExternalFiatCentsPrice: 360,
    totalFmcCentsPriceOriginal: 360,
    orderStatus: "accepted",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:38:43.473Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:40:06.644Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:38:43.473Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:38:56.615Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:38:43.473Z",
    updatedAt: "2024-10-24T11:40:06.645Z",
    fleetInfo: null,
  },
  {
    id: "671a317ea378154b54809a04",
    sid: "241024-527695",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a317ba378154b5480968a",
    firstName: "Asmaa Almutairi",
    lastName: null,
    phoneNumber: "+96565938832",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65bf68afd3514bca6686850c",
    delivery: {
      id: "671a317ba378154b5480968a",
      customerId: "65bf68afd3514bca6686850c",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "662432db5fbe613a604f68aa",
        name: "Home",
        lng: 47.934812754392624,
        lat: 29.26961243521573,
        city: "Kuwait",
        area: "Ishbiliya",
        block: "Block 4",
        street: "Street 408",
        building: "House 4",
        floor: "",
        flat: "",
        phone: "+96565938832",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-20T21:25:47.501Z",
        updatedAt: "2024-10-21T14:39:56.370Z",
      },
      deliverectValidationId: "671a317b3bbe5b0ef24e79b7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:47:31.148Z",
      estimatedDeliveryTime: "2024-10-24T12:02:30.015Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:34:00.000Z",
      orderId: "671a317ea378154b54809a04",
      createdAt: "2024-10-24T11:37:31.466Z",
      updatedAt: "2024-10-24T11:39:06.983Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d35",
        productPLU: "2162136-1",
        productDescription:
          "C 55g | P 28g | F 17g [%New%]\n\nGrilled chicken served with brown basmati rice, red and white quinoa, roasted sweet potato, mixed salad slaw, cucumber, and drizzled with coleslaw & sesame dressing\n\nGood source of Protein & Vitamin A",
        productName: "Asian chicken bowl",
        imageURL:
          "https://resizer.deliverect.com/ulfP2vI0enUjJIX9PtpeKqCqXdujjxBmnnP22nA6Mhw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYXNpYW4lMjBjaGlja2VuJTIwYm93bCUyMC02NzEzNGYyNjFjNmQ3NGExODRiZjU2NWQucG5n",
        productCalories: 485,
        quantity: 1,
        nutritionInfo: {
          carbs: 55,
          protein: 28,
          fat: 17,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825cdf",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 580,
    totalFmcCentsPrice: 580,
    totalFiatCentsPriceOriginal: 580,
    totalExternalFiatCentsPrice: 580,
    totalFmcCentsPriceOriginal: 580,
    orderStatus: "accepted",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:37:34.970Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:39:08.708Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:37:34.970Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:37:53.797Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:37:34.970Z",
    updatedAt: "2024-10-24T11:39:08.709Z",
    fleetInfo: null,
  },
  {
    id: "671a317aa378154b548094dc",
    sid: "241024-640074",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a3175a378154b548090f9",
    firstName: "Abdulaziz ",
    lastName: null,
    phoneNumber: "+96599630454",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64e62e4570771121d14e64ff",
    delivery: {
      id: "671a3175a378154b548090f9",
      customerId: "64e62e4570771121d14e64ff",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64ed970498e65fe43e53fffa",
        name: "Hose",
        lng: 48.01026954871494,
        lat: 29.27734375,
        city: "Zahra",
        area: "Zahra",
        block: "1",
        street: "122 St",
        building: "14",
        floor: "1",
        flat: "",
        phone: "+96599630454",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-29T06:58:12.714Z",
        updatedAt: "2024-10-24T11:34:21.968Z",
      },
      deliverectValidationId: "671a31743946960ae1552f21",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:47:24.696Z",
      estimatedDeliveryTime: "2024-10-24T12:02:22.624Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:34:00.000Z",
      orderId: "671a317aa378154b548094dc",
      createdAt: "2024-10-24T11:37:25.670Z",
      updatedAt: "2024-10-24T11:39:06.951Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825cfa",
        productPLU: "2162096-1",
        productDescription:
          "C 48g| P 17g| F 17g\nCrispy fish tenders served with baked fries & tartar sauce.\n\nGood source of Protein, Vitamins and Minerals",
        productName: "Fish N' Chips",
        imageURL:
          "https://resizer.deliverect.com/nIuQ090dCYLyovaNm9H_VJzVg3JpgGl0gzEm4NvBR6s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRmlzaCUyME4nJTIwQ2hpcHMtNjVmNmVjMDViOWNjMGMzODFjY2YwOTQyLnBuZw==",
        productCalories: 413,
        quantity: 1,
        nutritionInfo: {
          carbs: 48,
          protein: 17,
          fat: 17,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 400,
    totalExternalFiatCentsPrice: 400,
    totalFmcCentsPriceOriginal: 400,
    orderStatus: "accepted",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:37:30.257Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:39:07.901Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:37:30.257Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:37:42.256Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:37:30.257Z",
    updatedAt: "2024-10-24T11:39:07.903Z",
    fleetInfo: null,
  },
  {
    id: "671a3150a378154b548070db",
    sid: "241024-385228",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a314fa378154b548070ce",
    firstName: "Sara burhama",
    lastName: null,
    phoneNumber: "+96597736693",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "651a56ddc6da5aa4aee549e8",
    delivery: {
      id: "671a314fa378154b548070ce",
      customerId: "651a56ddc6da5aa4aee549e8",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "665d724a93dc7e98b4c535d5",
        name: "Surra",
        lng: 48.00181299448013,
        lat: 29.31570891674392,
        city: "Kuwait",
        area: "Surra",
        block: "5",
        street: "10",
        building: "25",
        floor: "",
        flat: "",
        phone: "+96597736693",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-03T07:35:38.097Z",
        updatedAt: "2024-10-20T18:45:18.357Z",
      },
      deliverectValidationId: "671a314f19256adb1627c76b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:46:47.866Z",
      estimatedDeliveryTime: "2024-10-24T12:01:46.803Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:33:00.000Z",
      orderId: "671a3150a378154b548070db",
      createdAt: "2024-10-24T11:36:47.960Z",
      updatedAt: "2024-10-24T11:38:06.108Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba9082463c",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba9082463e",
        productPLU: "2162132-1",
        productDescription:
          "C 25g | P 26g | F 21g [%New%]\n\nBeef with red & green capsicum, onions, cauliflower, tomato sauce mix with cauliflower dill rice . \n\nGood source of protein & vitamins",
        productName: "Beef Sajiyeh",
        imageURL:
          "https://resizer.deliverect.com/fA4Fn4615a1ZQrXrniLgeB7arSPTloEzAMzGmcaJlMY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQmVlZiUyMHNhaml5ZWgtNjcwNzc0MGVlNjQyNjQ5MzFhZTY2ZWU0LnBuZw==",
        productCalories: 393,
        quantity: 1,
        nutritionInfo: {
          carbs: 25,
          protein: 26,
          fat: 21,
        },
        fmcCentsPrice: 375,
        fiatCentsPrice: 375,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba908246a3",
        productPLU: "2166031-1",
        productDescription:
          "C 32g| P 12g| F 12g\nBaked sweet potato fries with mayo barbeque sauce.",
        productName: "Baked Sweet Potato Fries",
        imageURL:
          "https://resizer.deliverect.com/EexbDtzbKG0sVOjjNXiIlT_MIN2doyc2NlpJu2_T6nc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3dlZXQlMjBwb3RhdG8lMjBmcmllcygxKS02NTU0OGY3NzYyYWIwZTAwMWYzYTczNzEucG5n",
        productCalories: 244,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 12,
          fat: 12,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 805,
    totalFmcCentsPrice: 805,
    totalFiatCentsPriceOriginal: 805,
    totalExternalFiatCentsPrice: 805,
    totalFmcCentsPriceOriginal: 805,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:36:48.575Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:38:06.352Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:38:20.684Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:36:48.575Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:37:12.448Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:36:48.575Z",
    updatedAt: "2024-10-24T11:38:20.685Z",
    fleetInfo: null,
  },
  {
    id: "671a3146a378154b54806ed7",
    sid: "241024-632962",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a3146a378154b54806ec9",
    firstName: "Nawaf Almodaian",
    lastName: null,
    phoneNumber: "+96597266886",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65719441ca5e1d575c060b4a",
    delivery: {
      id: "671a3146a378154b54806ec9",
      customerId: "65719441ca5e1d575c060b4a",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6571b07cbc46886165034615",
        name: "Home ",
        lng: 48.08731444180012,
        lat: 29.27498995476558,
        city: "Kuwait",
        area: "Salwa",
        block: "12",
        street: "10 St",
        building: "68",
        floor: "",
        flat: "3",
        phone: "+96597266886",
        additionalInfo:
          "House at corner of street 10 & street 8, entrance on street 8",
        isSelected: true,
        createdAt: "2023-12-07T11:46:04.329Z",
        updatedAt: "2024-10-24T11:34:56.487Z",
      },
      deliverectValidationId: "671a31466a3e6800dd3b6ce3",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:46:38.287Z",
      estimatedDeliveryTime: "2024-10-24T12:01:37.243Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:33:00.000Z",
      orderId: "671a3146a378154b54806ed7",
      createdAt: "2024-10-24T11:36:38.377Z",
      updatedAt: "2024-10-24T11:38:07.886Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824691",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.2,
          protein: 2.8,
          fat: 8.6,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba9082466d",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 310,
    totalFmcCentsPrice: 310,
    totalFiatCentsPriceOriginal: 310,
    totalExternalFiatCentsPrice: 310,
    totalFmcCentsPriceOriginal: 310,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:36:38.978Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:38:05.676Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:38:22.255Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:36:38.978Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:37:01.214Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:36:38.978Z",
    updatedAt: "2024-10-24T11:38:22.259Z",
    fleetInfo: null,
  },
  {
    id: "671a3145a378154b54806eba",
    sid: "241024-803680",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a3143a378154b54806dd1",
    firstName: "G h alajmi",
    lastName: null,
    phoneNumber: "+96565654588",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66421a7866978e88ed11f924",
    delivery: {
      id: "671a3143a378154b54806dd1",
      customerId: "66421a7866978e88ed11f924",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66421b2a5ca983b14af8a288",
        name: "بيت",
        lng: 48.06416731327772,
        lat: 29.20778761638359,
        city: "Kuwait",
        area: "القصور",
        block: "7",
        street: "شارع 34",
        building: "47",
        floor: "",
        flat: "",
        phone: "+96565654588",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-13T13:52:42.413Z",
        updatedAt: "2024-05-13T13:52:42.413Z",
      },
      deliverectValidationId: "671a314372410ebb0822756e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:46:35.595Z",
      estimatedDeliveryTime: "2024-10-24T12:01:34.463Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:33:00.000Z",
      orderId: "671a3145a378154b54806eba",
      createdAt: "2024-10-24T11:36:35.712Z",
      updatedAt: "2024-10-24T11:38:09.025Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e22",
        productPLU: "2162134-1",
        productDescription:
          "C 33g | P 22g | F 19g [%New%]\n\nSesame bun, chicken patty, mayo sauce mix, spicy coleslaw, and cheddar cheese.",
        productName: "Spicy slaw chicken burger",
        imageURL:
          "https://resizer.deliverect.com/ocLKlrRu1U60c57669Upgx0hkVW30a4Pd8p0JRqT0as/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BpY3klMjBzbGF3JTIwY2hpY2tlbiUyMGJ1cmdlci02NzEzNTA3YmJhNmM3ZTllODMyZWQzYmQucG5n",
        productCalories: 391,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 22,
          fat: 19,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824e21",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 510,
    totalFmcCentsPrice: 510,
    totalFiatCentsPriceOriginal: 510,
    totalExternalFiatCentsPrice: 510,
    totalFmcCentsPriceOriginal: 510,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:36:37.338Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:38:07.134Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:39:07.818Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:36:37.338Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:36:48.753Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:36:37.338Z",
    updatedAt: "2024-10-24T11:39:07.818Z",
    fleetInfo: null,
  },
  {
    id: "671a3125a378154b54805dbd",
    sid: "241024-248885",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a3125a378154b54805db3",
    firstName: "Muneera ahmad",
    lastName: null,
    phoneNumber: "+96551500852",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66fd96c4ced7b08f00528d51",
    delivery: {
      id: "671a3125a378154b54805db3",
      customerId: "66fd96c4ced7b08f00528d51",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66fd975a02796fcd9b8807ba",
        name: "Home",
        lng: 48.063724078238,
        lat: 29.25165197077469,
        city: "صباح السالم",
        area: "صباح السالم",
        block: "9",
        street: "1 lane 2",
        building: "173 elevator 108",
        floor: "1",
        flat: "2",
        phone: "+96551500852",
        additionalInfo: "Black door",
        isSelected: true,
        createdAt: "2024-10-02T18:56:26.929Z",
        updatedAt: "2024-10-13T12:11:03.328Z",
      },
      deliverectValidationId: "671a3125621b7746ab7c7d6e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:46:05.074Z",
      estimatedDeliveryTime: "2024-10-24T12:01:04.028Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:33:00.000Z",
      orderId: "671a3125a378154b54805dbd",
      createdAt: "2024-10-24T11:36:05.189Z",
      updatedAt: "2024-10-24T11:38:06.196Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e5a",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:36:05.800Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:38:05.925Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:39:07.721Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:36:05.800Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:36:17.736Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:36:05.800Z",
    updatedAt: "2024-10-24T11:39:07.727Z",
    fleetInfo: null,
  },
  {
    id: "671a3115a378154b5480589a",
    sid: "241024-756393",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a3113a378154b54805786",
    firstName: "Hala AlZuwawi ",
    lastName: null,
    phoneNumber: "+96597668696",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d94c3bdfecd0088b52ad39",
    delivery: {
      id: "671a3113a378154b54805786",
      customerId: "64d94c3bdfecd0088b52ad39",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65379c01289c08cbdba831ee",
        name: "Home",
        lng: 47.97684062272311,
        lat: 29.319447562416237,
        city: "Shuwaikh Industrial",
        area: "Adailiya",
        block: "2",
        street: "Abdulla AlNouri ",
        building: "16",
        floor: "",
        flat: "",
        phone: "+96597668696",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-24T10:27:13.485Z",
        updatedAt: "2024-09-29T11:30:45.916Z",
      },
      deliverectValidationId: "671a31138da25298e789e5dd",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:45:47.103Z",
      estimatedDeliveryTime: "2024-10-24T12:00:44.552Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:32:00.000Z",
      orderId: "671a3115a378154b5480589a",
      createdAt: "2024-10-24T11:35:47.843Z",
      updatedAt: "2024-10-24T11:37:12.895Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824660",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671a1ab9e79c0dba908246fb",
            productPLU: "1906004-1",
            productName: "Extra  Beef Pattie",
            productDescription: "C 2g| P 6g| F 3g| cal 59| coins 0.5",
            productCalories: 59,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 2,
              protein: 6,
              fat: 3,
            },
          },
          {
            productId: "671a1ab9e79c0dba908246fc",
            productPLU: "1907004-1",
            productName: "No Onion",
            productDescription: "C -1g| P 0g| F 0g| cal -3| coins 0\n",
            productCalories: -3,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: null,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "671a1ab9e79c0dba908246cf",
        productPLU: "2202050-1",
        productDescription:
          "C 27g | P 1.5g | F 0g\nOrange \n\nGood source of Vitamin C",
        productName: "Orange Citrus",
        imageURL:
          "https://resizer.deliverect.com/92Cgq5w07iSWvqOzcojq0Egx0BDIxsVNTfIhlYhtUmU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTMtNjY1OTE5ODQ0MjllZTgwN2Q2OWQxYmZkLnBuZw==",
        productCalories: 116,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 1.5,
          fat: null,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 390,
    totalFmcCentsPrice: 390,
    totalFiatCentsPriceOriginal: 390,
    totalExternalFiatCentsPrice: 390,
    totalFmcCentsPriceOriginal: 390,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:35:49.554Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:37:12.304Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:38:25.823Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:35:49.554Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:35:55.975Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:35:49.554Z",
    updatedAt: "2024-10-24T11:38:25.824Z",
    fleetInfo: null,
  },
  {
    id: "671a30faa378154b548047d9",
    sid: "241024-836723",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a30f9a378154b548047c2",
    firstName: "Hassan alghaith ",
    lastName: null,
    phoneNumber: "+96566032272",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65188d4ac6da5aa4aee380b5",
    delivery: {
      id: "671a30f9a378154b548047c2",
      customerId: "65188d4ac6da5aa4aee380b5",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "652620d5289c08cbdb55b3b8",
        name: "Hassan alghaith ",
        lng: 48.01132410764694,
        lat: 29.320529472031208,
        city: "Kuwait",
        area: "Surra",
        block: "1",
        street: "13 St",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96566032272",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-11T04:13:09.588Z",
        updatedAt: "2023-10-11T04:13:09.588Z",
      },
      deliverectValidationId: "671a30f94cb7e660cd254c78",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:45:21.346Z",
      estimatedDeliveryTime: "2024-10-24T12:00:20.297Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:32:00.000Z",
      orderId: "671a30faa378154b548047d9",
      createdAt: "2024-10-24T11:35:21.452Z",
      updatedAt: "2024-10-24T11:37:09.960Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba9082463d",
        productPLU: "2162134-1",
        productDescription:
          "C 33g | P 22g | F 19g [%New%]\n\nSesame bun, chicken patty, mayo sauce mix, spicy coleslaw, and cheddar cheese.",
        productName: "Spicy slaw chicken burger",
        imageURL:
          "https://resizer.deliverect.com/ocLKlrRu1U60c57669Upgx0hkVW30a4Pd8p0JRqT0as/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BpY3klMjBzbGF3JTIwY2hpY2tlbiUyMGJ1cmdlci02NzEzNTA3YmJhNmM3ZTllODMyZWQzYmQucG5n",
        productCalories: 391,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 22,
          fat: 19,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba9082465d",
        productPLU: "2162123-1",
        productDescription:
          "C 32g | P 23g | F 14g \nGlazed bun, beef pattie, mozzarella cheese slice, mushroom sauce with light mayo ",
        productName: "Mushroom Burger",
        imageURL:
          "https://resizer.deliverect.com/ha2uMGKS1nYV_BDUXrhrnd4juPdkh4UN_AL73RV3EbM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBidXJnZXItNjY5MDY2ODYxMGNhZmJmNDkzMTIzNGQ5LnBuZw==",
        productCalories: 346,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 23,
          fat: 14,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba9082467b",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 24,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba908246a3",
        productPLU: "2166031-1",
        productDescription:
          "C 32g| P 12g| F 12g\nBaked sweet potato fries with mayo barbeque sauce.",
        productName: "Baked Sweet Potato Fries",
        imageURL:
          "https://resizer.deliverect.com/EexbDtzbKG0sVOjjNXiIlT_MIN2doyc2NlpJu2_T6nc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3dlZXQlMjBwb3RhdG8lMjBmcmllcygxKS02NTU0OGY3NzYyYWIwZTAwMWYzYTczNzEucG5n",
        productCalories: 244,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 12,
          fat: 12,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 730,
    totalFmcCentsPrice: 730,
    totalFiatCentsPriceOriginal: 730,
    totalExternalFiatCentsPrice: 730,
    totalFmcCentsPriceOriginal: 730,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:35:22.227Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:37:09.651Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:38:27.162Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:35:22.227Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:35:35.318Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:35:22.227Z",
    updatedAt: "2024-10-24T11:38:27.163Z",
    fleetInfo: null,
  },
  {
    id: "671a30b7a378154b54802362",
    sid: "241024-875043",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a30b6a378154b54802334",
    firstName: "Naser B",
    lastName: null,
    phoneNumber: "+96590013656",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6717807bb58bc47308426651",
    delivery: {
      id: "671a30b6a378154b54802334",
      customerId: "6717807bb58bc47308426651",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67178178b7a34cd092d455f6",
        name: "Home",
        lng: 47.98781789839268,
        lat: 29.307545315698636,
        city: "Kuwait",
        area: "Qortuba",
        block: "3",
        street: "5 St J2",
        building: "25",
        floor: "",
        flat: "",
        phone: "+96590013656",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-22T10:42:00.375Z",
        updatedAt: "2024-10-24T08:44:36.232Z",
      },
      deliverectValidationId: "671a30b66a3e6800dd3b3ae4",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:44:14.788Z",
      estimatedDeliveryTime: "2024-10-24T11:59:13.231Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:31:00.000Z",
      orderId: "671a30b7a378154b54802362",
      createdAt: "2024-10-24T11:34:14.893Z",
      updatedAt: "2024-10-24T11:36:06.391Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d25",
        productPLU: "2163022-1",
        productDescription:
          "C 15g | P 2g | F 14g [%New%]\n\nVeggies with fattoush dressing & croutons.\n\nGood source of Vitamins",
        productName: "Mini Fattoush",
        imageURL:
          "https://resizer.deliverect.com/QUcv0CxdaaAXW0GlMNB1GAQ5fahshLaeuOoMIRAFR5c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGZhdHRvdXNoJTIwc2FsYWQtNjcxMzUxNmViYTZjN2U5ZTgzMmVkM2MyLnBuZw==",
        productCalories: 194,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 2,
          fat: 14,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d06",
        productPLU: "2162022-1",
        productDescription:
          "C 32g | P 40g | F 21g\nShish tawook with batata harra, sumac white onions and pickled cucumber served with tahini sauce.\n\nGluten free,High in protien,Good source of Vitamin C",
        productName: "Shish Tawook with Batata Harra",
        imageURL:
          "https://resizer.deliverect.com/t8iFrO7JgasiTM_3BgLdmFPccJzHYDm3brRNicOgS5E/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjktNjRjOGJlYWZhNDUwZTYwMDI2ODNkMjhhLnBuZw==",
        productCalories: 477,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 40,
          fat: 21,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "accepted",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:34:15.725Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:36:07.516Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:34:15.725Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:34:27.274Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T11:34:15.725Z",
    updatedAt: "2024-10-24T11:36:07.517Z",
    fleetInfo: null,
  },
  {
    id: "671a3098a378154b548019e8",
    sid: "241024-623966",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a3097a378154b548019bb",
    firstName: "Fay",
    lastName: null,
    phoneNumber: "+96599976202",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66d879c9aa1645e3a5ff0a60",
    delivery: {
      id: "671a3097a378154b548019bb",
      customerId: "66d879c9aa1645e3a5ff0a60",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67025d6f218a8c4af240d97a",
        name: "Rawda",
        lng: 47.99869257956743,
        lat: 29.326863859737642,
        city: "Kuwait",
        area: "Rawda",
        block: "2",
        street: "23 St",
        building: "37",
        floor: "",
        flat: "",
        phone: "+96599976202",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-06T09:50:39.071Z",
        updatedAt: "2024-10-10T13:07:21.689Z",
      },
      deliverectValidationId: "671a3097a46a7e087dd3db54",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:43:43.448Z",
      estimatedDeliveryTime: "2024-10-24T11:58:42.301Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:30:00.000Z",
      orderId: "671a3098a378154b548019e8",
      createdAt: "2024-10-24T11:33:43.566Z",
      updatedAt: "2024-10-24T11:35:08.237Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824663",
        productPLU: "2162022-1",
        productDescription:
          "C 32g | P 40g | F 21g\nShish tawook with batata harra, sumac white onions and pickled cucumber served with tahini sauce.\n\nGluten free,High in protien,Good source of Vitamin C",
        productName: "Shish Tawook with Batata Harra",
        imageURL:
          "https://resizer.deliverect.com/t8iFrO7JgasiTM_3BgLdmFPccJzHYDm3brRNicOgS5E/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjktNjRjOGJlYWZhNDUwZTYwMDI2ODNkMjhhLnBuZw==",
        productCalories: 477,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 40,
          fat: 21,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba90824691",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.2,
          protein: 2.8,
          fat: 8.6,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba908246ca",
        productPLU: "2165005-1",
        productDescription: "C 13g| P 1g| F 10g",
        productName: "Salted Pecan Bites",
        imageURL:
          "https://resizer.deliverect.com/p3z6wZFsuIaNIkw99YRPwzlHvwGLg970lPG5QDvZQrc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDgtNjRiN2JjOTE2ZjhhYmY0M2UzMWQxMjBjLnBuZw==",
        productCalories: 151,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 1,
          fat: 10,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 410,
    totalFmcCentsPrice: 410,
    totalFiatCentsPriceOriginal: 410,
    totalExternalFiatCentsPrice: 410,
    totalFmcCentsPriceOriginal: 410,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:33:44.197Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:35:08.342Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:38:25.731Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:33:44.197Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:34:06.134Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:33:44.197Z",
    updatedAt: "2024-10-24T11:38:25.732Z",
    fleetInfo: null,
  },
  {
    id: "671a3097a378154b548019be",
    sid: "241024-925865",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a3096a378154b548019b2",
    firstName: "Maha Al-Fahad",
    lastName: null,
    phoneNumber: "+96599023994",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d92d68dfecd0088b526eb5",
    delivery: {
      id: "671a3096a378154b548019b2",
      customerId: "64d92d68dfecd0088b526eb5",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65375712289c08cbdba5b3c1",
        name: "Home",
        lng: 47.9638097807765,
        lat: 29.34604743254509,
        city: "Kuwait",
        area: "Shamiya",
        block: "8",
        street: "85 ",
        building: "13",
        floor: "",
        flat: "",
        phone: "+96599023994",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-24T05:33:06.618Z",
        updatedAt: "2023-10-24T05:33:06.618Z",
      },
      deliverectValidationId: "671a3096621b7746ab7c485c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:43:42.771Z",
      estimatedDeliveryTime: "2024-10-24T11:58:40.916Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:30:00.000Z",
      orderId: "671a3097a378154b548019be",
      createdAt: "2024-10-24T11:33:42.877Z",
      updatedAt: "2024-10-24T11:35:08.210Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823e8f",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "Without turkey bacon",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823f16",
        productPLU: "2166050-1",
        productDescription:
          "C 6g | P 2g | F 8g [%New%]\n\nPecan nuts, digestive, stevia, unsalted butter, cream cheese, heavy cream, egg, sour cream, all purpose flour, vanilla extract, syrup.",
        productName: "Pecan cheesecake",
        imageURL:
          "https://resizer.deliverect.com/V5P-zIm2i9d9zMnIsCSZ3RcaQiBGbqJ8glcvZNImsFM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hlZXNjYWtlJTIwYml0ZS02NmY5MGRhZmE0OGIxYjQxMWRmZTBiZTAucG5n",
        productCalories: 104,
        quantity: 2,
        nutritionInfo: {
          carbs: 6,
          protein: 2,
          fat: 8,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 480,
    totalFmcCentsPrice: 480,
    totalFiatCentsPriceOriginal: 480,
    totalExternalFiatCentsPrice: 480,
    totalFmcCentsPriceOriginal: 480,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:33:43.611Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:35:07.933Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:41:28.815Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:33:43.611Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:33:55.208Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T11:33:43.611Z",
    updatedAt: "2024-10-24T11:41:28.816Z",
    fleetInfo: null,
  },
  {
    id: "671a3049a378154b54800428",
    sid: "241024-184937",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a3048a378154b54800415",
    firstName: "Haya khalifa alhajeri",
    lastName: null,
    phoneNumber: "+96566552269",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65632d00e0d78832aa8f2dea",
    delivery: {
      id: "671a3048a378154b54800415",
      customerId: "65632d00e0d78832aa8f2dea",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65632e3102b2e21f1faf4d57",
        name: "Mansoriya, B2 st29 H20",
        lng: 47.99491403552668,
        lat: 29.352981567382812,
        city: "Kuwait",
        area: "Mansouriya",
        block: "2",
        street: "29",
        building: "20B",
        floor: "2",
        flat: "",
        phone: "+96566552269",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-26T11:38:25.139Z",
        updatedAt: "2024-09-12T19:14:43.389Z",
      },
      deliverectValidationId: "671a30488ee7d1c2565cbb46",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:42:24.674Z",
      estimatedDeliveryTime: "2024-10-24T11:57:23.628Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:29:00.000Z",
      orderId: "671a3049a378154b54800428",
      createdAt: "2024-10-24T11:32:24.775Z",
      updatedAt: "2024-10-24T11:34:06.417Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823eb8",
        productPLU: "2162085-1",
        productDescription:
          "C 15g| P 45g| F 15g\nGrilled chicken served with mashed potatoes, blanched broccoli, cauliflower & carrots, served with a lemon sauce garnished with a slice of lemon and parsley.\n\nGood source of Protein",
        productName: "Grilled Lemon Chicken",
        imageURL:
          "https://resizer.deliverect.com/n9S1sQV2m-69QdAwmCpIpZWcf6LGGUpyB0bFdTHRC1U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3JpbGxlZCUyMGxlbW9uJTIwY2hpY2tlbigxKS02NTU0OTA2MWZhNTNkZDAwMWVlOTQ2N2IucG5n",
        productCalories: 375,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 45,
          fat: 15,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ed7",
        productPLU: "2163020-1",
        productDescription:
          "C 10g | P 2g | F 3g [%New%]\n\nRomaine lettuce, cucumber, green capsicum, spring onion, parsley, dill leaves, sumac, croutons with Italian dressing.",
        productName: "Mini Italian salad",
        imageURL:
          "https://resizer.deliverect.com/XkxzmQRK5xU2IA0XJqjnN8x_RpgC6KxTMoxtFe2tjkE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGl0YWxpYW4lMjBzYWxhZC02NzEzNGUwMjQ4OGM3MjkyNjAyMTliNDcucG5n",
        productCalories: 75,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 2,
          fat: 3,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 400,
    totalExternalFiatCentsPrice: 400,
    totalFmcCentsPriceOriginal: 400,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:32:25.725Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:34:06.659Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:34:29.188Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:32:25.725Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:32:46.566Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:32:25.725Z",
    updatedAt: "2024-10-24T11:34:29.188Z",
    fleetInfo: null,
  },
  {
    id: "671a3048a378154b5480040b",
    sid: "241024-296108",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a3047a378154b548003fc",
    firstName: "Dana Dana Dana",
    lastName: null,
    phoneNumber: "+96599245571",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66912079e02b51659fe3037e",
    delivery: {
      id: "671a3047a378154b548003fc",
      customerId: "66912079e02b51659fe3037e",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66e43bc8ced6c5247e02b6ae",
        name: "جابر العلي ",
        lng: 48.07286873459816,
        lat: 29.17561737442898,
        city: "Kuwait",
        area: "جابر العلي",
        block: "2",
        street: "شارع 25",
        building: "5",
        floor: "",
        flat: "",
        phone: "+96599245571",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-13T13:19:04.054Z",
        updatedAt: "2024-10-22T12:14:34.673Z",
      },
      deliverectValidationId: "671a3047982ae1ad6d5d853a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:42:23.245Z",
      estimatedDeliveryTime: "2024-10-24T11:57:22.227Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:29:00.000Z",
      orderId: "671a3048a378154b5480040b",
      createdAt: "2024-10-24T11:32:23.421Z",
      updatedAt: "2024-10-24T11:34:06.128Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e52",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824e77",
        productPLU: "2162136-1",
        productDescription:
          "C 55g | P 28g | F 17g [%New%]\n\nGrilled chicken served with brown basmati rice, red and white quinoa, roasted sweet potato, mixed salad slaw, cucumber, and drizzled with coleslaw & sesame dressing\n\nGood source of Protein & Vitamin A",
        productName: "Asian chicken bowl",
        imageURL:
          "https://resizer.deliverect.com/ulfP2vI0enUjJIX9PtpeKqCqXdujjxBmnnP22nA6Mhw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYXNpYW4lMjBjaGlja2VuJTIwYm93bCUyMC02NzEzNGYyNjFjNmQ3NGExODRiZjU2NWQucG5n",
        productCalories: 485,
        quantity: 1,
        nutritionInfo: {
          carbs: 55,
          protein: 28,
          fat: 17,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 530,
    totalFmcCentsPrice: 530,
    totalFiatCentsPriceOriginal: 530,
    totalExternalFiatCentsPrice: 530,
    totalFmcCentsPriceOriginal: 530,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:32:24.153Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:34:13.110Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:34:23.965Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:32:24.153Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:32:35.517Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:32:24.153Z",
    updatedAt: "2024-10-24T11:34:23.966Z",
    fleetInfo: null,
  },
  {
    id: "671a3037a378154b547fff93",
    sid: "241024-846816",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a3035a378154b547ffe7b",
    firstName: "Mohammad Alhouli",
    lastName: null,
    phoneNumber: "+96599397343",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "651041d0e1d91353418d1b5f",
    delivery: {
      id: "671a3035a378154b547ffe7b",
      customerId: "651041d0e1d91353418d1b5f",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6512ad750a91a2efcd5b7920",
        name: "Home",
        lng: 48.08090731501579,
        lat: 29.177814649042446,
        city: "Kuwait",
        area: "Jaber Al Ali",
        block: "2",
        street: "10",
        building: "11",
        floor: "",
        flat: "",
        phone: "+96599397343",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-09-26T10:07:49.047Z",
        updatedAt: "2024-10-24T11:31:25.392Z",
      },
      deliverectValidationId: "671a30340348c4b0eab2e4ad",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:42:04.860Z",
      estimatedDeliveryTime: "2024-10-24T11:57:02.523Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:29:00.000Z",
      orderId: "671a3037a378154b547fff93",
      createdAt: "2024-10-24T11:32:05.599Z",
      updatedAt: "2024-10-24T11:34:07.188Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e21",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 2,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824e87",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 620,
    totalFmcCentsPrice: 620,
    totalFiatCentsPriceOriginal: 620,
    totalExternalFiatCentsPrice: 620,
    totalFmcCentsPriceOriginal: 620,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:32:07.474Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:34:12.936Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:34:23.392Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:32:07.474Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:32:18.906Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:32:07.474Z",
    updatedAt: "2024-10-24T11:34:23.393Z",
    fleetInfo: null,
  },
  {
    id: "671a2ff9a378154b547fe408",
    sid: "241024-826590",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2ff8a378154b547fe328",
    firstName: "Shadan alnusif ",
    lastName: null,
    phoneNumber: "+96599092305",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64ec6f0bb4954e5ceb6352a1",
    delivery: {
      id: "671a2ff8a378154b547fe328",
      customerId: "64ec6f0bb4954e5ceb6352a1",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f1cb631294dc02ca61496c",
        name: "Home",
        lng: 47.986573688685894,
        lat: 29.35673219641467,
        city: "Kuwait",
        area: "Abdullah al-Salem",
        block: "1",
        street: "Nusif alyousef aven 9 h 1",
        building: "1",
        floor: "",
        flat: "",
        phone: "+96599092305",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-23T20:11:15.210Z",
        updatedAt: "2024-10-11T07:05:01.398Z",
      },
      deliverectValidationId: "671a2ff74f3524be78f82993",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:41:03.958Z",
      estimatedDeliveryTime: "2024-10-24T11:56:01.850Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:28:00.000Z",
      orderId: "671a2ff9a378154b547fe408",
      createdAt: "2024-10-24T11:31:04.087Z",
      updatedAt: "2024-10-24T11:33:05.498Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823e9e",
        productPLU: "2162117-1",
        productDescription:
          "C 60g | P 22g | F 15g\nTomato sauce, cheese & oregano.",
        productName: "Classic Margherita Pizza",
        imageURL:
          "https://resizer.deliverect.com/gXmec37h0lqeQkrMSIQRiRdHLwG9kVK9Ukz9m_Kw930/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWFyZ2hhcml0YS02NjZiMzAxMWQ2NmMzMDFhNDE0NmYxNTMucG5n",
        productCalories: 463,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 22,
          fat: 15,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:31:05.781Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:33:33.747Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:34:29.688Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:31:05.781Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:31:17.202Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:31:05.781Z",
    updatedAt: "2024-10-24T11:34:29.690Z",
    fleetInfo: null,
  },
  {
    id: "671a2fe8a378154b547fd8f9",
    sid: "241024-016986",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2fe8a378154b547fd8e8",
    firstName: "Sarah Almutairi ",
    lastName: null,
    phoneNumber: "+96565600572",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "670a0b88aa779ec4a77edc01",
    delivery: {
      id: "671a2fe8a378154b547fd8e8",
      customerId: "670a0b88aa779ec4a77edc01",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670a0bf2f495b693cdfe3c68",
        name: "Home",
        lng: 47.962909871748536,
        lat: 29.336334228515625,
        city: "Kuwait",
        area: "Kaifan",
        block: "4",
        street: "Al Quds",
        building: "56",
        floor: "Ground ",
        flat: "",
        phone: "+96565600572",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-12T05:41:06.833Z",
        updatedAt: "2024-10-12T05:41:06.833Z",
      },
      deliverectValidationId: "671a2fe760ec4531f4642e03",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:40:47.993Z",
      estimatedDeliveryTime: "2024-10-24T11:55:46.648Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:27:00.000Z",
      orderId: "671a2fe8a378154b547fd8f9",
      createdAt: "2024-10-24T11:30:48.113Z",
      updatedAt: "2024-10-24T11:32:07.795Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823eb2",
        productPLU: "2162006-1",
        productDescription:
          "C 33g| P 19g| F 16g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ef5",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:30:48.858Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:33:33.204Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:34:29.576Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:30:48.858Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:31:01.205Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:30:48.859Z",
    updatedAt: "2024-10-24T11:34:29.577Z",
    fleetInfo: null,
  },
  {
    id: "671a2fd9a378154b547fd2fb",
    sid: "241024-326918",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2fd9a378154b547fd2e6",
    firstName: "Ali edrees",
    lastName: null,
    phoneNumber: "+96597745227",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66076f678af55dd3671654bb",
    delivery: {
      id: "671a2fd9a378154b547fd2e6",
      customerId: "66076f678af55dd3671654bb",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f8f3ffee3a746f7f847f6a",
        name: "Office",
        lng: 47.9824924,
        lat: 29.3801101,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "Sharq police station ",
        street: "Ahmad Al Jaber St",
        building: "Sharq police station ",
        floor: "0",
        flat: "0",
        phone: "+96597745227",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-29T06:30:23.858Z",
        updatedAt: "2024-10-24T03:44:46.276Z",
      },
      deliverectValidationId: "671a2fd8c97ba45e1ccc23be",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:40:32.998Z",
      estimatedDeliveryTime: "2024-10-24T11:55:31.853Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:27:00.000Z",
      orderId: "671a2fd9a378154b547fd2fb",
      createdAt: "2024-10-24T11:30:33.110Z",
      updatedAt: "2024-10-24T11:32:07.467Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823eb4",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671a1ab4e79c0dba90823f49",
            productPLU: "1906003-1",
            productName: "Extra Chedder Cheese",
            productDescription: "C 0g| P 2g| F 3g| cal 40| coins 0.2",
            productCalories: 40,
            quantity: 1,
            fmcCentsPrice: 20,
            fiatCentsPrice: 20,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: 2,
              fat: 3,
            },
          },
          {
            productId: "671a1ab4e79c0dba90823f4c",
            productPLU: "1907010-1",
            productName: "No Iceberg Lettuce",
            productDescription: "C -0.3g| P 0g| F 0g| cal -1.4| coins 0",
            productCalories: -1.4,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.3,
              protein: null,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "671a1ab4e79c0dba90823eef",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ef6",
        productPLU: "2166031-1",
        productDescription:
          "C 32g| P 12g| F 12g\nBaked sweet potato fries with mayo barbeque sauce.",
        productName: "Baked Sweet Potato Fries",
        imageURL:
          "https://resizer.deliverect.com/EexbDtzbKG0sVOjjNXiIlT_MIN2doyc2NlpJu2_T6nc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3dlZXQlMjBwb3RhdG8lMjBmcmllcygxKS02NTU0OGY3NzYyYWIwZTAwMWYzYTczNzEucG5n",
        productCalories: 244,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 12,
          fat: 12,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 500,
    totalFmcCentsPrice: 500,
    totalFiatCentsPriceOriginal: 500,
    totalExternalFiatCentsPrice: 500,
    totalFmcCentsPriceOriginal: 500,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:30:33.762Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:34:29.028Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:34:29.028Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:30:33.762Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:30:45.299Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:30:33.762Z",
    updatedAt: "2024-10-24T11:34:29.029Z",
    fleetInfo: null,
  },
  {
    id: "671a2f74a378154b547fa88b",
    sid: "241024-284923",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2f74a378154b547fa882",
    firstName: "Danah Ahmad",
    lastName: null,
    phoneNumber: "+96594444763",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65183b46c6da5aa4aee2da61",
    delivery: {
      id: "671a2f74a378154b547fa882",
      customerId: "65183b46c6da5aa4aee2da61",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "653d18a5289c08cbdbc2c986",
        name: "HOME",
        lng: 47.8886233991902,
        lat: 29.302444458007812,
        city: "Al Kuwayt",
        area: "Andalous",
        block: "10",
        street: "106 St",
        building: "35",
        floor: "",
        flat: "",
        phone: "+96594444763",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-28T14:20:21.117Z",
        updatedAt: "2024-10-24T11:22:47.449Z",
      },
      deliverectValidationId: "671a2f743bbe5b0ef24d996b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:38:52.147Z",
      estimatedDeliveryTime: "2024-10-24T11:53:51.109Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:25:00.000Z",
      orderId: "671a2f74a378154b547fa88b",
      createdAt: "2024-10-24T11:28:52.255Z",
      updatedAt: "2024-10-24T11:30:13.106Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d0c",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:28:52.884Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:30:12.184Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:47:00.959Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:47:00.959Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:47:00.959Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:47:13.791Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:28:52.884Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:29:16.928Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:28:52.885Z",
    updatedAt: "2024-10-24T11:47:14.121Z",
    fleetInfo: null,
  },
  {
    id: "671a2f6ea378154b547fa464",
    sid: "241024-504929",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2f6ca378154b547fa366",
    firstName: "Faisal",
    lastName: null,
    phoneNumber: "+96550540008",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6512fa9ee1d91353419054e3",
    delivery: {
      id: "671a2f6ca378154b547fa366",
      customerId: "6512fa9ee1d91353419054e3",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66449805fb0531469a540d53",
        name: "OPG garage",
        lng: 47.93886775150895,
        lat: 29.317384340265058,
        city: "Shuwaikh Industrial",
        area: "Shuwaikh Industrial 3",
        block: "Block C ",
        street: "St 33",
        building: "149",
        floor: "الأرضي ",
        flat: "",
        phone: "+96550540008",
        additionalInfo: "مقابل الوزان للتجهيزات الغذائية ",
        isSelected: true,
        createdAt: "2024-05-15T11:09:57.577Z",
        updatedAt: "2024-10-19T08:01:01.372Z",
      },
      deliverectValidationId: "671a2f6b6ef78a762202f8e4",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:38:43.897Z",
      estimatedDeliveryTime: "2024-10-24T11:53:42.887Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:25:00.000Z",
      orderId: "671a2f6ea378154b547fa464",
      createdAt: "2024-10-24T11:28:44.930Z",
      updatedAt: "2024-10-24T11:30:12.956Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823eb2",
        productPLU: "2162006-1",
        productDescription:
          "C 33g| P 19g| F 16g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823eb8",
        productPLU: "2162085-1",
        productDescription:
          "C 15g| P 45g| F 15g\nGrilled chicken served with mashed potatoes, blanched broccoli, cauliflower & carrots, served with a lemon sauce garnished with a slice of lemon and parsley.\n\nGood source of Protein",
        productName: "Grilled Lemon Chicken",
        imageURL:
          "https://resizer.deliverect.com/n9S1sQV2m-69QdAwmCpIpZWcf6LGGUpyB0bFdTHRC1U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3JpbGxlZCUyMGxlbW9uJTIwY2hpY2tlbigxKS02NTU0OTA2MWZhNTNkZDAwMWVlOTQ2N2IucG5n",
        productCalories: 375,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 45,
          fat: 15,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 500,
    totalFmcCentsPrice: 500,
    totalFiatCentsPriceOriginal: 500,
    totalExternalFiatCentsPrice: 500,
    totalFmcCentsPriceOriginal: 500,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:28:46.437Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:34:23.564Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:34:23.567Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:28:46.437Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:29:06.420Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:28:46.437Z",
    updatedAt: "2024-10-24T11:34:23.573Z",
    fleetInfo: null,
  },
  {
    id: "671a2f6ba378154b547fa211",
    sid: "241024-735147",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2f6aa378154b547fa1eb",
    firstName: "Omar alhasawi",
    lastName: null,
    phoneNumber: "+96599333896",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65266db41bfa57c5dbf49284",
    delivery: {
      id: "671a2f6aa378154b547fa1eb",
      customerId: "65266db41bfa57c5dbf49284",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65475a54f91376d39471136f",
        name: "H - H",
        lng: 47.99914184957742,
        lat: 29.277680222750515,
        city: "Zahra",
        area: "Zahra",
        block: "5",
        street: "507",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96599333896",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-05T09:03:16.478Z",
        updatedAt: "2024-10-24T11:27:22.212Z",
      },
      deliverectValidationId: "671a2f6aa7e4fe3721949e51",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:38:42.711Z",
      estimatedDeliveryTime: "2024-10-24T11:53:41.236Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:25:00.000Z",
      orderId: "671a2f6ba378154b547fa211",
      createdAt: "2024-10-24T11:28:42.882Z",
      updatedAt: "2024-10-24T11:30:11.237Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d35",
        productPLU: "2162136-1",
        productDescription:
          "C 55g | P 28g | F 17g [%New%]\n\nGrilled chicken served with brown basmati rice, red and white quinoa, roasted sweet potato, mixed salad slaw, cucumber, and drizzled with coleslaw & sesame dressing\n\nGood source of Protein & Vitamin A",
        productName: "Asian chicken bowl",
        imageURL:
          "https://resizer.deliverect.com/ulfP2vI0enUjJIX9PtpeKqCqXdujjxBmnnP22nA6Mhw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYXNpYW4lMjBjaGlja2VuJTIwYm93bCUyMC02NzEzNGYyNjFjNmQ3NGExODRiZjU2NWQucG5n",
        productCalories: 485,
        quantity: 1,
        nutritionInfo: {
          carbs: 55,
          protein: 28,
          fat: 17,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d6e",
        productPLU: "2165008-1",
        productDescription: "C 13g| P 3g| F 10g",
        productName: "Snickers Bar",
        imageURL:
          "https://resizer.deliverect.com/qlfM-kU78NJZFB6H6YZxYvpH8UfR3_jKIa0YwqE7w20/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTItNjRiN2JjZGJhMDAxOGMwYmY0YTY2NTZiLnBuZw==",
        productCalories: 157,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 3,
          fat: 10,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 360,
    totalFmcCentsPrice: 360,
    totalFiatCentsPriceOriginal: 360,
    totalExternalFiatCentsPrice: 360,
    totalFmcCentsPriceOriginal: 360,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:28:43.721Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:30:10.977Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:47:28.379Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:28:43.721Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:28:55.143Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:28:43.721Z",
    updatedAt: "2024-10-24T11:47:28.380Z",
    fleetInfo: null,
  },
  {
    id: "671a2f4fa378154b547f9650",
    sid: "241024-694007",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a2f4ea378154b547f9646",
    firstName: "Noria albanai",
    lastName: null,
    phoneNumber: "+96590007746",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66a12d51ac4eb4f3eef3c18d",
    delivery: {
      id: "671a2f4ea378154b547f9646",
      customerId: "66a12d51ac4eb4f3eef3c18d",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66a12e911683a2e7260703d9",
        name: "Noriya",
        lng: 48.06778537147837,
        lat: 29.206466674804688,
        city: "Kuwait",
        area: "Al-Qurain District",
        block: "3",
        street: "4 St",
        building: "96",
        floor: "",
        flat: "",
        phone: "+96590007746",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-24T16:40:49.392Z",
        updatedAt: "2024-10-22T11:33:43.372Z",
      },
      deliverectValidationId: "671a2f4e72410ebb0821a68e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:38:14.583Z",
      estimatedDeliveryTime: "2024-10-24T11:53:13.233Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:25:00.000Z",
      orderId: "671a2f4fa378154b547f9650",
      createdAt: "2024-10-24T11:28:14.844Z",
      updatedAt: "2024-10-24T11:30:11.597Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e22",
        productPLU: "2162134-1",
        productDescription:
          "C 33g | P 22g | F 19g [%New%]\n\nSesame bun, chicken patty, mayo sauce mix, spicy coleslaw, and cheddar cheese.",
        productName: "Spicy slaw chicken burger",
        imageURL:
          "https://resizer.deliverect.com/ocLKlrRu1U60c57669Upgx0hkVW30a4Pd8p0JRqT0as/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BpY3klMjBzbGF3JTIwY2hpY2tlbiUyMGJ1cmdlci02NzEzNTA3YmJhNmM3ZTllODMyZWQzYmQucG5n",
        productCalories: 391,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 22,
          fat: 19,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824e94",
        productPLU: "2166029-1",
        productDescription:
          "C 20g| P 3g| F 3g[%New%]\n\nCharred corn with tangy mayo sauce\n\nGOOD SOURCE OF VITAMIN B6",
        productName: "Mini Grilled Corn",
        imageURL:
          "https://resizer.deliverect.com/55C0BPdGu8KBIs3g2oHJe2DixhQfs99DVTEllZD_Nzg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNvcm4tNjcxMzg1YTQxYzZkNzRhMTg0YmY1NmFlLnBuZw==",
        productCalories: 119,
        quantity: 1,
        nutritionInfo: {
          carbs: 20,
          protein: 3,
          fat: 3,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 320,
    totalFmcCentsPrice: 320,
    totalFiatCentsPriceOriginal: 320,
    totalExternalFiatCentsPrice: 320,
    totalFmcCentsPriceOriginal: 320,
    orderStatus: "ready_for_pickup",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:28:15.655Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:30:08.899Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:31:18.201Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:45:01.972Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:45:01.972Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:28:15.655Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:28:27.162Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:28:15.655Z",
    updatedAt: "2024-10-24T11:45:10.443Z",
    fleetInfo: null,
  },
  {
    id: "671a2f22a378154b547f7edd",
    sid: "241024-035199",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a2f22a378154b547f7ed1",
    firstName: "Ali Alnaser",
    lastName: null,
    phoneNumber: "+96599533145",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "660fe9ded17e71b5c7e960a0",
    delivery: {
      id: "671a2f22a378154b547f7ed1",
      customerId: "660fe9ded17e71b5c7e960a0",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "660feaa536f403f24cd6b454",
        name: "Home",
        lng: 48.080619165464036,
        lat: 29.320022583007812,
        city: "Rumaithiya",
        area: "Rumaithiya",
        block: "7",
        street: "Street 71",
        building: "11",
        floor: "",
        flat: "",
        phone: "+96599533145",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-05T12:12:21.439Z",
        updatedAt: "2024-10-24T11:26:32.052Z",
      },
      deliverectValidationId: "671a2f213946960ae154208a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:37:29.968Z",
      estimatedDeliveryTime: "2024-10-24T11:52:28.937Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:24:00.000Z",
      orderId: "671a2f22a378154b547f7edd",
      createdAt: "2024-10-24T11:27:30.098Z",
      updatedAt: "2024-10-24T11:29:05.314Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824670",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba90824667",
        productPLU: "2162044-1",
        productDescription:
          "C 46g| P 32g| F 8g\nButter chicken served with white basmati rice and a brocolli garnish with coriander.\n\nGluten free, Good source of Vitamins & Iron.",
        productName: "Butter Chicken",
        imageURL:
          "https://resizer.deliverect.com/kNZO7hSeSAa7aTJWFU1oyK8hGOHaTbLbvvvMD_J1ft0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnV0dGVyJTIwY2hpY2tlbi02NWY2ZWEzZTUxOTBiNjZmYzkxYmQ2MzMuanBn",
        productCalories: 384,
        quantity: 1,
        nutritionInfo: {
          carbs: 46,
          protein: 32,
          fat: 8,
        },
        fmcCentsPrice: 360,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671a1ab9e79c0dba9082471f",
            productPLU: "1906042-1",
            productName: "Extra  Chicken",
            productDescription: "C 5g| P 25g| F 2g| cal 133| coins 0.9",
            productCalories: 133,
            quantity: 1,
            fmcCentsPrice: 90,
            fiatCentsPrice: 90,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 5,
              protein: 25,
              fat: 2,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 490,
    totalFmcCentsPrice: 490,
    totalFiatCentsPriceOriginal: 490,
    totalExternalFiatCentsPrice: 490,
    totalFmcCentsPriceOriginal: 490,
    orderStatus: "ready_for_pickup",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:27:30.741Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:29:07.534Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:38:26.668Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:45:28.864Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:45:28.864Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:27:30.741Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:27:42.394Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:27:30.741Z",
    updatedAt: "2024-10-24T11:45:40.050Z",
    fleetInfo: null,
  },
  {
    id: "671a2edaa378154b547f5967",
    sid: "241024-366925",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a2ed9a378154b547f57cd",
    firstName: "Feras Mohammed alfarsi",
    lastName: null,
    phoneNumber: "+96596666939",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6716a8b1b58bc47308411830",
    delivery: {
      id: "671a2ed9a378154b547f57cd",
      customerId: "6716a8b1b58bc47308411830",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67175759b7a34cd092cb142d",
        name: "My home ",
        lng: 48.011992312967784,
        lat: 29.315045009471955,
        city: "Kuwait",
        area: "Surra",
        block: "2",
        street: "6 St",
        building: "26",
        floor: "0",
        flat: "0",
        phone: "+96596666939",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-22T07:42:17.247Z",
        updatedAt: "2024-10-24T05:56:36.675Z",
      },
      deliverectValidationId: "671a2ed8621b7746ab7b5141",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:36:16.947Z",
      estimatedDeliveryTime: "2024-10-24T11:51:15.767Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:23:00.000Z",
      orderId: "671a2edaa378154b547f5967",
      createdAt: "2024-10-24T11:26:17.288Z",
      updatedAt: "2024-10-24T11:28:09.090Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824669",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba9082468e",
        productPLU: "2163010-1",
        productDescription:
          "C 25g| P 34g| F 15g\nGrilled chicken with baby gem lettuce, caesar dressing topped with sourdough croutons & shaved parmesan cheese.\n\nGood source of Vitamin A &Potein",
        productName: "Chicken Ceasar Salad",
        imageURL:
          "https://resizer.deliverect.com/aOyM36xC7gBB5GwjyrYYA3YsTJ8-9RRXq22EPXeQlkM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NGI3YjVjOWU0ZDVjMzAwMThiNjViNmIucG5n",
        productCalories: 371,
        quantity: 1,
        nutritionInfo: {
          carbs: 25,
          protein: 34,
          fat: 15,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba9082466d",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba90824682",
        productPLU: "2163022-1",
        productDescription:
          "C 15g | P 2g | F 14g [%New%]\n\nVeggies with fattoush dressing & croutons.\n\nGood source of Vitamins",
        productName: "Mini Fattoush",
        imageURL:
          "https://resizer.deliverect.com/QUcv0CxdaaAXW0GlMNB1GAQ5fahshLaeuOoMIRAFR5c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGZhdHRvdXNoJTIwc2FsYWQtNjcxMzUxNmViYTZjN2U5ZTgzMmVkM2MyLnBuZw==",
        productCalories: 194,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 2,
          fat: 14,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba908246bc",
        productPLU: "2202033-1",
        productDescription:
          "C 16.8g | P 1.1g | F 0.9g\nKiwi, Lychee, Blueberry, Raspberry, Strawberry , Mango Juice",
        productName: "Exotic Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/FaUQbHO5bVt45oTlIa5crQvzqJWE25HgI0k6ZJia_Ls/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZXhvdGljJTIwZnJ1aXQtNjY0MzdhNDcwYzI0ZDkyNWZjZDQwYTZkLnBuZw==",
        productCalories: 80,
        quantity: 1,
        nutritionInfo: {
          carbs: 16.8,
          protein: 1.1,
          fat: 0.9,
        },
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 1050,
    totalFmcCentsPrice: 1050,
    totalFiatCentsPriceOriginal: 1050,
    totalExternalFiatCentsPrice: 1050,
    totalFmcCentsPriceOriginal: 1050,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:26:18.777Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:28:07.405Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:38:39.739Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:44:12.106Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:44:12.106Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:44:36.472Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:26:18.777Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:26:30.209Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:26:18.777Z",
    updatedAt: "2024-10-24T11:45:22.460Z",
    fleetInfo: null,
  },
  {
    id: "671a2ebca378154b547f439d",
    sid: "241024-671981",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a2ebba378154b547f4392",
    firstName: "Omar alkhlaifi",
    lastName: null,
    phoneNumber: "+96555504111",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6656e4cb8ceacb066c54c01e",
    delivery: {
      id: "671a2ebba378154b547f4392",
      customerId: "6656e4cb8ceacb066c54c01e",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6656e52decc6fd38a64fc015",
        name: "Home",
        lng: 48.068395145237446,
        lat: 29.192882573772778,
        city: "Kuwait",
        area: "Mubarak Al-Kabeer",
        block: "3",
        street: "2 St",
        building: "43",
        floor: "",
        flat: "",
        phone: "+96555504111",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-29T08:19:57.755Z",
        updatedAt: "2024-10-23T16:10:47.651Z",
      },
      deliverectValidationId: "671a2ebb3bee773669a983ce",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:35:47.366Z",
      estimatedDeliveryTime: "2024-10-24T11:50:46.097Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:22:00.000Z",
      orderId: "671a2ebca378154b547f439d",
      createdAt: "2024-10-24T11:25:47.487Z",
      updatedAt: "2024-10-24T11:27:10.995Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e28",
        productPLU: "2162126-1",
        productDescription:
          "C 47g | P 30g | F 19g\nRice, Grilled Salmon, Coconut Curry, Cherry Tomato, Coriander Leaves\n\nGood source of Protein & Vitamin B12",
        productName: "Coconut Salmon Curry",
        imageURL:
          "https://resizer.deliverect.com/RGhcodNiC9p5cDsaZeSeiR0MrQFl28w5nWBhlmGSMsI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY29jb251dCUyMHNhbG1vbiUyMGN1cnJ5LTY2YjUzNjA5NDAxOTZkZGUyZjg5MjliNC5wbmc=",
        productCalories: 479,
        quantity: 1,
        nutritionInfo: {
          carbs: 47,
          protein: 30,
          fat: 19,
        },
        fmcCentsPrice: 490,
        fiatCentsPrice: 490,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824e55",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 620,
    totalFmcCentsPrice: 620,
    totalFiatCentsPriceOriginal: 620,
    totalExternalFiatCentsPrice: 620,
    totalFmcCentsPriceOriginal: 620,
    orderStatus: "ready_for_pickup",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:25:48.064Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:27:10.883Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:31:11.177Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:45:10.861Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:45:10.861Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:25:48.064Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:26:09.682Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:25:48.064Z",
    updatedAt: "2024-10-24T11:45:22.026Z",
    fleetInfo: null,
  },
  {
    id: "671a2eaea378154b547f4152",
    sid: "241024-624013",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2eada378154b547f4144",
    firstName: "Fahad essam alansari",
    lastName: null,
    phoneNumber: "+96597808332",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6551ffd66fe598ae84f2f79d",
    delivery: {
      id: "671a2eada378154b547f4144",
      customerId: "6551ffd66fe598ae84f2f79d",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65685b9cb17b87d5d2d665fd",
        name: "Fahad",
        lng: 47.975257028297975,
        lat: 29.298065185546875,
        city: "Kuwait",
        area: "Khaitan",
        block: "10",
        street: "6",
        building: "2",
        floor: "",
        flat: "",
        phone: "+96597808332",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-30T09:53:32.810Z",
        updatedAt: "2024-10-24T11:22:57.966Z",
      },
      deliverectValidationId: "671a2ead6a3e6800dd3a087d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:35:33.574Z",
      estimatedDeliveryTime: "2024-10-24T11:50:32.429Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:22:00.000Z",
      orderId: "671a2eaea378154b547f4152",
      createdAt: "2024-10-24T11:25:33.695Z",
      updatedAt: "2024-10-24T11:27:10.180Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825ce0",
        productPLU: "2162134-1",
        productDescription:
          "C 33g | P 22g | F 19g [%New%]\n\nSesame bun, chicken patty, mayo sauce mix, spicy coleslaw, and cheddar cheese.",
        productName: "Spicy slaw chicken burger",
        imageURL:
          "https://resizer.deliverect.com/ocLKlrRu1U60c57669Upgx0hkVW30a4Pd8p0JRqT0as/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BpY3klMjBzbGF3JTIwY2hpY2tlbiUyMGJ1cmdlci02NzEzNTA3YmJhNmM3ZTllODMyZWQzYmQucG5n",
        productCalories: 391,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 22,
          fat: 19,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d4b",
        productPLU: "2166042-1",
        productDescription: "C 26.6g | P 13.8g | F 7.1g",
        productName: "PROPUFFS Cheese",
        imageURL:
          "https://resizer.deliverect.com/e0URr0ml6_6wBDHu6cUrypxzvNRY1xVo6FoQ9SwxXqE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NmJlMDUzNDA4YmY5MDY0ZWFmYTVkYjgucG5n",
        productCalories: 194,
        quantity: 1,
        nutritionInfo: {
          carbs: 26.6,
          protein: 13.8,
          fat: 7.1,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d40",
        productPLU: "2168001-1",
        productDescription:
          "C 14g | P 5g | F 1g\nRed lentil soup topped with coriander & served with a lemon wedge. ",
        productName: "Lentil Soup",
        imageURL:
          "https://resizer.deliverect.com/5EbDdbzPc4MFBEpLJbi04Urr0_H4N9CuGy35JtE6XBM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbGVudGlsJTIwc291cC02NjQxZGJjOTNjYmY3YTM2N2ZiNDhiN2IuanBn",
        productCalories: 85,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 5,
          fat: 1,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d3f",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 570,
    totalFmcCentsPrice: 570,
    totalFiatCentsPriceOriginal: 570,
    totalExternalFiatCentsPrice: 570,
    totalFmcCentsPriceOriginal: 570,
    orderStatus: "preparing",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:25:34.323Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:27:08.700Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:47:28.267Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:25:34.323Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:25:46.783Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:25:34.323Z",
    updatedAt: "2024-10-24T11:47:28.268Z",
    fleetInfo: null,
  },
  {
    id: "671a2ea7a378154b547f404f",
    sid: "241024-082846",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a2ea6a378154b547f4042",
    firstName: "Nourah AlHamdan",
    lastName: null,
    phoneNumber: "+96594011509",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f2482b7f0597f029e2fbf4",
    delivery: {
      id: "671a2ea6a378154b547f4042",
      customerId: "66f2482b7f0597f029e2fbf4",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f289d01294dc02ca6bbe01",
        name: "Nourah",
        lng: 48.11781384050846,
        lat: 29.166621678001768,
        city: "Kuwait",
        area: "Fintas",
        block: "4",
        street: "20",
        building: "433",
        floor: "",
        flat: "",
        phone: "+96594011509",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-24T09:43:44.891Z",
        updatedAt: "2024-09-24T09:43:44.891Z",
      },
      deliverectValidationId: "671a2ea6eb2dd773021c2e20",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:35:26.179Z",
      estimatedDeliveryTime: "2024-10-24T11:50:24.525Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:22:00.000Z",
      orderId: "671a2ea7a378154b547f404f",
      createdAt: "2024-10-24T11:25:26.448Z",
      updatedAt: "2024-10-24T11:27:06.981Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e22",
        productPLU: "2162134-1",
        productDescription:
          "C 33g | P 22g | F 19g [%New%]\n\nSesame bun, chicken patty, mayo sauce mix, spicy coleslaw, and cheddar cheese.",
        productName: "Spicy slaw chicken burger",
        imageURL:
          "https://resizer.deliverect.com/ocLKlrRu1U60c57669Upgx0hkVW30a4Pd8p0JRqT0as/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BpY3klMjBzbGF3JTIwY2hpY2tlbiUyMGJ1cmdlci02NzEzNTA3YmJhNmM3ZTllODMyZWQzYmQucG5n",
        productCalories: 391,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 22,
          fat: 19,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824e52",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824ec7",
        productPLU: "2202041-1",
        productDescription:
          "C 5.3g | P 0.8g | F 0g\nBeetroot, Grapefruit & Himalayan Salt",
        productName: "Heart Beet Shot",
        imageURL:
          "https://resizer.deliverect.com/hr_xtMDdUpGtwUgywZHAGVPEYM_cOE7XRFgrZu-SPkU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaGVhcnRiZWV0JTIwc2hvdC02NjQxZGFmZjc0YTNlMTU5MTYzYWJhNjkucG5n",
        productCalories: 25,
        quantity: 1,
        nutritionInfo: {
          carbs: 5.3,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 540,
    totalFmcCentsPrice: 540,
    totalFiatCentsPriceOriginal: 540,
    totalExternalFiatCentsPrice: 540,
    totalFmcCentsPriceOriginal: 540,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:25:27.032Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:27:08.843Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:31:12.932Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:43:14.381Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:43:14.381Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:43:36.370Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:25:27.032Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:25:57.667Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T11:25:27.032Z",
    updatedAt: "2024-10-24T11:45:23.768Z",
    fleetInfo: null,
  },
  {
    id: "671a2ea3a378154b547f3fe9",
    sid: "241024-136440",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a2ea2a378154b547f3e56",
    firstName: "dalal abdulhmed moh",
    lastName: null,
    phoneNumber: "+96594425482",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "671a26b41434069564e0a45d",
    delivery: {
      id: "671a2ea2a378154b547f3e56",
      customerId: "671a26b41434069564e0a45d",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "671a272de79c0dba9087f1ec",
        name: "المسايل ",
        lng: 48.084161495540926,
        lat: 29.241134643554688,
        city: "المسايل",
        area: "المسايل",
        block: "5",
        street: "شارع 506",
        building: "12A",
        floor: "",
        flat: "",
        phone: "+96594425482",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-24T10:53:33.263Z",
        updatedAt: "2024-10-24T10:53:33.263Z",
      },
      deliverectValidationId: "671a2ea26ef78a762202b140",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:35:22.093Z",
      estimatedDeliveryTime: "2024-10-24T11:50:20.853Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:22:00.000Z",
      orderId: "671a2ea3a378154b547f3fe9",
      createdAt: "2024-10-24T11:25:22.287Z",
      updatedAt: "2024-10-24T11:27:06.983Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e3f",
        productPLU: "2162047-1",
        productDescription:
          "C 51g| P 31g| F 11g \nPenne pasta in a mushroom & parmesan sauce, topped with grilled dijon chicken. \n\n*Good source of protein, Vitamin  D, & minerals*",
        productName: "Dijon Chicken Pasta",
        imageURL:
          "https://resizer.deliverect.com/LI0VRiKQ75jObW5px0dylKLXpRerpSCN0-9_VLTemyk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGFuZCUyMG11c2hyb29tJTIwcGFzdGEtNjY0NGJjYjAyNTFjMmQ2ZTEyNjQ3NTUwLnBuZw==",
        productCalories: 427,
        quantity: 1,
        nutritionInfo: {
          carbs: 51,
          protein: 31,
          fat: 11,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824eb6",
        productPLU: "2202052-1",
        productDescription:
          "C 13g | P 0g | F 0g\nPomegranate, lemon\n\nGood source of Vitamin C",
        productName: "Pomade",
        imageURL:
          "https://resizer.deliverect.com/JpImLqr5JfNLPk8gTnl6NoZd9i6mdIhqPB4QA7H5lw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEtNjY1OTFhNzgxNmEzMzQyNTY5OGVhM2QxLnBuZw==",
        productCalories: 54,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824eb0",
        productPLU: "2165008-1",
        productDescription: "C 13g| P 3g| F 10g",
        productName: "Snickers Bar",
        imageURL:
          "https://resizer.deliverect.com/qlfM-kU78NJZFB6H6YZxYvpH8UfR3_jKIa0YwqE7w20/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTItNjRiN2JjZGJhMDAxOGMwYmY0YTY2NTZiLnBuZw==",
        productCalories: 157,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 3,
          fat: 10,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 510,
    totalFmcCentsPrice: 510,
    totalFiatCentsPriceOriginal: 510,
    totalExternalFiatCentsPrice: 510,
    totalFmcCentsPriceOriginal: 510,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:25:23.885Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:27:07.272Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:31:14.174Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:38:59.156Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:38:59.156Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:43:01.651Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:25:23.885Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:25:35.836Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:25:23.885Z",
    updatedAt: "2024-10-24T11:44:47.884Z",
    fleetInfo: null,
  },
  {
    id: "671a2e2aa378154b547f15ef",
    sid: "241024-793704",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2e28a378154b547f13ba",
    firstName: "Taiba AlKanderi ",
    lastName: null,
    phoneNumber: "+96555986697",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c3cc0375a6aa442d0f1423",
    delivery: {
      id: "671a2e28a378154b547f13ba",
      customerId: "64c3cc0375a6aa442d0f1423",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65476084f91376d39471598c",
        name: "City tower ",
        lng: 47.97875352730834,
        lat: 29.376876831054688,
        city: "Kuwait City",
        area: "Sharq",
        block: "6",
        street: "Ahmad AlJaber street",
        building: "City tower in front of crystal tower",
        floor: "5",
        flat: "",
        phone: "+96555986697",
        additionalInfo: "In front of crystal tower, black glass building",
        isSelected: true,
        createdAt: "2023-11-05T09:29:40.339Z",
        updatedAt: "2024-10-20T10:32:58.901Z",
      },
      deliverectValidationId: "671a2e27eb2dd773021bfb1c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:33:19.785Z",
      estimatedDeliveryTime: "2024-10-24T11:48:18.593Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:20:00.000Z",
      orderId: "671a2e2aa378154b547f15ef",
      createdAt: "2024-10-24T11:23:20.432Z",
      updatedAt: "2024-10-24T11:25:05.464Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823e90",
        productPLU: "2162134-1",
        productDescription:
          "C 33g | P 22g | F 19g [%New%]\n\nSesame bun, chicken patty, mayo sauce mix, spicy coleslaw, and cheddar cheese.",
        productName: "Spicy slaw chicken burger",
        imageURL:
          "https://resizer.deliverect.com/ocLKlrRu1U60c57669Upgx0hkVW30a4Pd8p0JRqT0as/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BpY3klMjBzbGF3JTIwY2hpY2tlbiUyMGJ1cmdlci02NzEzNTA3YmJhNmM3ZTllODMyZWQzYmQucG5n",
        productCalories: 391,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 22,
          fat: 19,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ef6",
        productPLU: "2166031-1",
        productDescription:
          "C 32g| P 12g| F 12g\nBaked sweet potato fries with mayo barbeque sauce.",
        productName: "Baked Sweet Potato Fries",
        imageURL:
          "https://resizer.deliverect.com/EexbDtzbKG0sVOjjNXiIlT_MIN2doyc2NlpJu2_T6nc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3dlZXQlMjBwb3RhdG8lMjBmcmllcygxKS02NTU0OGY3NzYyYWIwZTAwMWYzYTczNzEucG5n",
        productCalories: 244,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 12,
          fat: 12,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:23:22.751Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:25:12.799Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:25:27.800Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:34:58.486Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:34:58.486Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:38:31.329Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:23:22.751Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:23:34.657Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:23:22.751Z",
    updatedAt: "2024-10-24T11:38:31.422Z",
    fleetInfo: null,
  },
  {
    id: "671a2e18a378154b547f0bdb",
    sid: "241024-022949",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2e18a378154b547f0bc7",
    firstName: "Ali AlNassar",
    lastName: null,
    phoneNumber: "+96599779311",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65154e62c6da5aa4aee0b53e",
    delivery: {
      id: "671a2e18a378154b547f0bc7",
      customerId: "65154e62c6da5aa4aee0b53e",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6526b553289c08cbdb59e270",
        name: "Ali AlNassar",
        lng: 47.959536015987396,
        lat: 29.35559077326849,
        city: "Kuwait",
        area: "Shuwaikh Residential",
        block: "2",
        street: "20 St",
        building: "8",
        floor: "",
        flat: "",
        phone: "+96599779311",
        additionalInfo: "Grey house ",
        isSelected: true,
        createdAt: "2023-10-11T14:46:43.429Z",
        updatedAt: "2024-05-07T17:35:44.209Z",
      },
      deliverectValidationId: "671a2e17c97ba45e1ccb882f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:33:03.985Z",
      estimatedDeliveryTime: "2024-10-24T11:48:02.919Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:20:00.000Z",
      orderId: "671a2e18a378154b547f0bdb",
      createdAt: "2024-10-24T11:23:04.084Z",
      updatedAt: "2024-10-24T11:25:05.451Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823ea5",
        productPLU: "2162110-1",
        productDescription:
          "C 16g | P 39g | F 12g\nChicken Lasagna with zucchini & basil\n\nGood source of Protein, Vitamin A & B12",
        productName: "Zucchini Chicken Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SF1f4yzXFAmy7O5H5pNNhkbFAWle_8S5j0MSAJ6QIzY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGxhc2FnbmEtNjY0MzdhMWQ3YjdlNGViN2M1MjcwN2IwLmpwZw==",
        productCalories: 328,
        quantity: 1,
        nutritionInfo: {
          carbs: 16,
          protein: 39,
          fat: 12,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823edf",
        productPLU: "2163013-1",
        productDescription:
          "C 24g| P 12g| F 18g\nMixed greens, green bell peppers, cucumbers, tomatoes, spring onions, radishes, fresh mint, zaatar, parsley & grilled halloumi drizzled with a fatoush vinagrette & topped with zaatar croutons. \n\nGOOD SOURCE OF PROTIEN , VITAMIN K , A & CALCIUM",
        productName: "Fattoush",
        imageURL:
          "https://resizer.deliverect.com/5hPWwFSPNGl8nDgfpHyx8xc7_Gg5ln9BhI5g5gG9uoM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2lkZSUyMDItNjUxMDM3NjU4YmNmMGU3YzQ1YzVkMDA4LmpwZw==",
        productCalories: 306,
        quantity: 1,
        nutritionInfo: {
          carbs: 24,
          protein: 12,
          fat: 18,
        },
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 530,
    totalFmcCentsPrice: 530,
    totalFiatCentsPriceOriginal: 530,
    totalExternalFiatCentsPrice: 530,
    totalFmcCentsPriceOriginal: 530,
    orderStatus: "ready_for_pickup",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:23:04.863Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:25:07.094Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:25:27.707Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:46:17.323Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:46:17.324Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:23:04.863Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:23:23.325Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:23:04.863Z",
    updatedAt: "2024-10-24T11:46:26.350Z",
    fleetInfo: null,
  },
  {
    id: "671a2e12a378154b547f08b9",
    sid: "241024-872991",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a2e11a378154b547f0871",
    firstName: "Manar Jaber",
    lastName: null,
    phoneNumber: "+96566400450",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "661f61acb0d178e63207ee98",
    delivery: {
      id: "671a2e11a378154b547f0871",
      customerId: "661f61acb0d178e63207ee98",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6660a00f3d5f2b755d3d9db0",
        name: "Home Bayan",
        lng: 48.0486843,
        lat: 29.2874887,
        city: "Kuwait",
        area: "بيان",
        block: "13",
        street: "12",
        building: "33",
        floor: "",
        flat: "",
        phone: "+96566400450",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-05T17:27:43.471Z",
        updatedAt: "2024-09-06T09:44:18.119Z",
      },
      deliverectValidationId: "671a2e11c6ffa8c03b4bd77e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:32:57.654Z",
      estimatedDeliveryTime: "2024-10-24T11:47:56.621Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:19:00.000Z",
      orderId: "671a2e12a378154b547f08b9",
      createdAt: "2024-10-24T11:22:57.743Z",
      updatedAt: "2024-10-24T11:24:09.753Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba9082465f",
        productPLU: "2162006-1",
        productDescription:
          "C 33g| P 19g| F 16g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671a1ab9e79c0dba908246fb",
            productPLU: "1906004-1",
            productName: "Extra  Beef Pattie",
            productDescription: "C 2g| P 6g| F 3g| cal 59| coins 0.5",
            productCalories: 59,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 2,
              protein: 6,
              fat: 3,
            },
          },
          {
            productId: "671a1ab9e79c0dba908246fe",
            productPLU: "1907006-1",
            productName: "No Iceberg Lettuce",
            productDescription: "C 0g| P 0g| F 0g| cal -1| coins 0\n",
            productCalories: -1,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: null,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "671a1ab9e79c0dba908246d6",
        productPLU: "2202022-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Sprite Zero",
        imageURL:
          "https://resizer.deliverect.com/ZEZdNlPffzmGS3rg7Tvd5hMcV02zZNG7y9fZavC83oI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMSUyMCgxKS02NGM5MTk0M2E0NTBlNjAwMjY4M2QzODEuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:22:58.330Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:24:07.857Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:36:15.810Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:36:15.810Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:36:15.810Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:36:38.443Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:22:58.330Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:23:10.801Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:22:58.330Z",
    updatedAt: "2024-10-24T11:40:38.524Z",
    fleetInfo: null,
  },
  {
    id: "671a2e07a378154b547f0012",
    sid: "241024-838836",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2e06a378154b547effde",
    firstName: "مبارك عصام الأنصاري ",
    lastName: null,
    phoneNumber: "+96566235381",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "670a2e05aa779ec4a77f143a",
    delivery: {
      id: "671a2e06a378154b547effde",
      customerId: "670a2e05aa779ec4a77f143a",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670a2e99f495b693cdfff42b",
        name: "البيت",
        lng: 47.975182346999645,
        lat: 29.298034029674138,
        city: "Kuwait",
        area: "خيطان",
        block: "10",
        street: "6",
        building: "2",
        floor: "",
        flat: "",
        phone: "+96566235381",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-12T08:08:57.674Z",
        updatedAt: "2024-10-14T11:35:22.385Z",
      },
      deliverectValidationId: "671a2e055211809fa890b48a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:32:45.938Z",
      estimatedDeliveryTime: "2024-10-24T11:47:44.751Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:19:00.000Z",
      orderId: "671a2e07a378154b547f0012",
      createdAt: "2024-10-24T11:22:46.461Z",
      updatedAt: "2024-10-24T11:24:07.613Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825cdf",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d4b",
        productPLU: "2166042-1",
        productDescription: "C 26.6g | P 13.8g | F 7.1g",
        productName: "PROPUFFS Cheese",
        imageURL:
          "https://resizer.deliverect.com/e0URr0ml6_6wBDHu6cUrypxzvNRY1xVo6FoQ9SwxXqE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NmJlMDUzNDA4YmY5MDY0ZWFmYTVkYjgucG5n",
        productCalories: 194,
        quantity: 1,
        nutritionInfo: {
          carbs: 26.6,
          protein: 13.8,
          fat: 7.1,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d57",
        productPLU: "2166011-1",
        productDescription:
          "C 58g| P 11g| F 13g\nChocolate chia & flaxseed oats with peanut butter biscuit, orange zest, hazelnut chocolate and chocolate coverture.\n\nGood source of Vitamins & Dietary fibre",
        productName: "Choco Chia Oats",
        imageURL:
          "https://resizer.deliverect.com/Isi8w_ThjYiyjTMxCBPpzL50QvwOgBOSXLSqnE3bNhM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMi02NTExNmVmYzBhODZkZTcwYTAzMjg0NzkuanBn",
        productCalories: 393,
        quantity: 1,
        nutritionInfo: {
          carbs: 58,
          protein: 11,
          fat: 13,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 450,
    totalFmcCentsPrice: 450,
    totalFiatCentsPriceOriginal: 450,
    totalExternalFiatCentsPrice: 450,
    totalFmcCentsPriceOriginal: 450,
    orderStatus: "ready_for_pickup",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:22:47.333Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:24:12.206Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:47:01.135Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:47:25.414Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:47:25.414Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:22:47.333Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:22:59.760Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:22:47.334Z",
    updatedAt: "2024-10-24T11:47:27.878Z",
    fleetInfo: null,
  },
  {
    id: "671a2dfda378154b547efbad",
    sid: "241024-566258",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2dfca378154b547efb86",
    firstName: "Samaa alzamel",
    lastName: null,
    phoneNumber: "+96597427970",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d9f1ccdfecd0088b530979",
    delivery: {
      id: "671a2dfca378154b547efb86",
      customerId: "64d9f1ccdfecd0088b530979",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66042f3cbb94678303edf46e",
        name: "Home 🤍",
        lng: 47.966631799936295,
        lat: 29.31091706710166,
        city: "Kuwait",
        area: "Yarmouk",
        block: "4",
        street: "Ahmad althaqeb",
        building: "436",
        floor: "1",
        flat: "1",
        phone: "+96597427970",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-03-27T14:37:48.809Z",
        updatedAt: "2024-10-10T17:23:56.869Z",
      },
      deliverectValidationId: "671a2dfcc97ba45e1ccb7d24",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:32:36.229Z",
      estimatedDeliveryTime: "2024-10-24T11:47:34.706Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:19:00.000Z",
      orderId: "671a2dfda378154b547efbad",
      createdAt: "2024-10-24T11:22:36.664Z",
      updatedAt: "2024-10-24T11:24:05.678Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825ce0",
        productPLU: "2162134-1",
        productDescription:
          "C 33g | P 22g | F 19g [%New%]\n\nSesame bun, chicken patty, mayo sauce mix, spicy coleslaw, and cheddar cheese.",
        productName: "Spicy slaw chicken burger",
        imageURL:
          "https://resizer.deliverect.com/ocLKlrRu1U60c57669Upgx0hkVW30a4Pd8p0JRqT0as/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BpY3klMjBzbGF3JTIwY2hpY2tlbiUyMGJ1cmdlci02NzEzNTA3YmJhNmM3ZTllODMyZWQzYmQucG5n",
        productCalories: 391,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 22,
          fat: 19,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d45",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:22:37.404Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:24:06.790Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:46:27.363Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:46:27.363Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:46:27.364Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:46:49.992Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:22:37.404Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:22:49.033Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T11:22:37.404Z",
    updatedAt: "2024-10-24T11:47:24.223Z",
    fleetInfo: null,
  },
  {
    id: "671a2db5a378154b547ed888",
    sid: "241024-893118",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2db5a378154b547ed880",
    firstName: "Mohammed Burehamah ",
    lastName: null,
    phoneNumber: "+96599948428",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66d5927ed7546254fad7bbea",
    delivery: {
      id: "671a2db5a378154b547ed880",
      customerId: "66d5927ed7546254fad7bbea",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66d5934aa42e9ac814ae60ba",
        name: "Homse",
        lng: 48.03681816905736,
        lat: 29.267015083748753,
        city: "Kuwait",
        area: "Shuhada",
        block: "2",
        street: "201",
        building: "59",
        floor: "",
        flat: "",
        phone: "+96599948428",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-02T10:28:26.925Z",
        updatedAt: "2024-10-23T10:58:06.732Z",
      },
      deliverectValidationId: "671a2db4c3d18b0678c936b6",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:31:24.975Z",
      estimatedDeliveryTime: "2024-10-24T11:46:23.170Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:18:00.000Z",
      orderId: "671a2db5a378154b547ed888",
      createdAt: "2024-10-24T11:21:25.065Z",
      updatedAt: "2024-10-24T11:23:06.846Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825cdf",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:21:25.755Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:23:06.250Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:43:36.550Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:43:36.550Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:43:36.551Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:43:58.644Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:21:25.755Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:21:37.296Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:21:25.756Z",
    updatedAt: "2024-10-24T11:47:09.459Z",
    fleetInfo: null,
  },
  {
    id: "671a2d96a378154b547ec9b7",
    sid: "241024-264599",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a2d95a378154b547ec9ac",
    firstName: "Dawood Behbehani",
    lastName: null,
    phoneNumber: "+96599943360",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "661ac148b0d178e632041441",
    delivery: {
      id: "671a2d95a378154b547ec9ac",
      customerId: "661ac148b0d178e632041441",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "661ac189df9c25a445490bff",
        name: "Home",
        lng: 48.00298332857946,
        lat: 29.316085815429688,
        city: "Kuwait",
        area: "Surra",
        block: "6",
        street: "13",
        building: "26",
        floor: "",
        flat: "",
        phone: "+96599943360",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-13T17:31:53.405Z",
        updatedAt: "2024-10-24T11:14:33.182Z",
      },
      deliverectValidationId: "671a2d958da25298e7880a90",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:30:53.553Z",
      estimatedDeliveryTime: "2024-10-24T11:45:52.533Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:17:00.000Z",
      orderId: "671a2d96a378154b547ec9b7",
      createdAt: "2024-10-24T11:20:53.700Z",
      updatedAt: "2024-10-24T11:22:07.683Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824697",
        productPLU: "2162021-1",
        productDescription:
          "C 60g| P 50g| F 16g\nChicken shawarma served with calrose rice & drizzled with our shawarma sauce and sumac white onions.\n\nHigh in protein, ",
        productName: "Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/wpaWs0_xzYbkz_6vxHW2469_71kHXwgrjXuSA4epEwY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2hpY2tlbiUyMFNoYXdhcm1hJTIwYm93bC02NGY0OTRlOTFhNzliZjNkNzNkYjUzNjcuanBn",
        productCalories: 584,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 50,
          fat: 16,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba908246d5",
        productPLU: "2202021-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Coca Cola Zero",
        imageURL:
          "https://resizer.deliverect.com/NvHctUF0z3us3Lhe9x5u3_mQIOOXnn8K3FvSZO6MkK4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCgxKS02NGM5MTk1MjZlY2Q3NzAwMjZjNGU1MDUuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 270,
    totalExternalFiatCentsPrice: 270,
    totalFmcCentsPriceOriginal: 270,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:20:54.231Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:22:10.320Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:37:09.795Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:37:09.795Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:37:09.795Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:37:35.423Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:20:54.231Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:21:16.962Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:20:54.231Z",
    updatedAt: "2024-10-24T11:40:38.610Z",
    fleetInfo: null,
  },
  {
    id: "671a2d8ca378154b547ec52d",
    sid: "241024-230014",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2d8ba378154b547ec509",
    firstName: "Saud AlBader",
    lastName: null,
    phoneNumber: "+96597377463",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65003395ec522879c1b5a25b",
    delivery: {
      id: "671a2d8ba378154b547ec509",
      customerId: "65003395ec522879c1b5a25b",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6620059d4c5ea8a87fbe7439",
        name: "Apartment",
        lng: 47.96151213549842,
        lat: 29.331924438476562,
        city: "Kuwait",
        area: "Khaldiya",
        block: "1",
        street: "Abdulla Al Khalaf Al Saeed St",
        building: "3",
        floor: "",
        flat: "",
        phone: "+96597377463",
        additionalInfo: "Main street side third ring road",
        isSelected: true,
        createdAt: "2024-04-17T17:23:41.136Z",
        updatedAt: "2024-10-24T11:13:11.709Z",
      },
      deliverectValidationId: "671a2d8b621b7746ab7ae2b4",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:30:43.746Z",
      estimatedDeliveryTime: "2024-10-24T11:45:42.710Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:17:00.000Z",
      orderId: "671a2d8ca378154b547ec52d",
      createdAt: "2024-10-24T11:20:43.854Z",
      updatedAt: "2024-10-24T11:22:06.653Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d3f",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d28",
        productPLU: "2163019-1",
        productDescription:
          "C 21g | P 6g | F 17g \nQuinoa, Cucumber, beetroot, parsley, spring onion, mint, kale,  pomegranate seeds, feta cheese and spring dressing. \n\nGood source of Vitamin C & K",
        productName: "Spring Salad",
        imageURL:
          "https://resizer.deliverect.com/Ie4UluZ6Zpy11uE4mml8eAE-iGHGSk3zhVI2_OjBREU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3ByaW5nJTIwc2FsYWQtNjY5MDY1ZTAwMjQyYTUwMWQ5ZTc4YTg2LnBuZw==",
        productCalories: 261,
        quantity: 1,
        nutritionInfo: {
          carbs: 21,
          protein: 6,
          fat: 17,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d45",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 410,
    totalFmcCentsPrice: 410,
    totalFiatCentsPriceOriginal: 410,
    totalExternalFiatCentsPrice: 410,
    totalFmcCentsPriceOriginal: 410,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:20:44.760Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:22:09.928Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:29:33.856Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:29:35.490Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:29:35.491Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:42:29.171Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:20:44.760Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:21:05.049Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:20:44.760Z",
    updatedAt: "2024-10-24T11:42:29.299Z",
    fleetInfo: null,
  },
  {
    id: "671a2d88a378154b547ec3dc",
    sid: "241024-069610",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a2d87a378154b547ec2fc",
    firstName: "Hessa almehri",
    lastName: null,
    phoneNumber: "+96567799931",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "669d3bd4ea7d0049ce6ec407",
    delivery: {
      id: "671a2d87a378154b547ec2fc",
      customerId: "669d3bd4ea7d0049ce6ec407",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "669d3c8edbd8df4f135df36e",
        name: "Home",
        lng: 47.99783337237301,
        lat: 29.336181640625,
        city: "Kuwait",
        area: "Rawda",
        block: "4",
        street: "46",
        building: "19",
        floor: "",
        flat: "",
        phone: "+96567799931",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-21T16:51:26.208Z",
        updatedAt: "2024-10-23T11:03:00.724Z",
      },
      deliverectValidationId: "671a2d879d15dbbf9fb3afbd",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:30:39.484Z",
      estimatedDeliveryTime: "2024-10-24T11:45:38.371Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:17:00.000Z",
      orderId: "671a2d88a378154b547ec3dc",
      createdAt: "2024-10-24T11:20:39.579Z",
      updatedAt: "2024-10-24T11:22:07.681Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba9082463d",
        productPLU: "2162134-1",
        productDescription:
          "C 33g | P 22g | F 19g [%New%]\n\nSesame bun, chicken patty, mayo sauce mix, spicy coleslaw, and cheddar cheese.",
        productName: "Spicy slaw chicken burger",
        imageURL:
          "https://resizer.deliverect.com/ocLKlrRu1U60c57669Upgx0hkVW30a4Pd8p0JRqT0as/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BpY3klMjBzbGF3JTIwY2hpY2tlbiUyMGJ1cmdlci02NzEzNTA3YmJhNmM3ZTllODMyZWQzYmQucG5n",
        productCalories: 391,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 22,
          fat: 19,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba908246a2",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "ready_for_pickup",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:20:40.806Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:22:05.721Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:41:12.412Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:41:50.972Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:41:50.973Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:20:40.806Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:20:52.494Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:20:40.806Z",
    updatedAt: "2024-10-24T11:42:01.740Z",
    fleetInfo: null,
  },
  {
    id: "671a2d6ea378154b547eb9f6",
    sid: "241024-167530",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a2d6ea378154b547eb9e5",
    firstName: "Abdullah AR",
    lastName: null,
    phoneNumber: "+96566847262",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67041aec906276458c877166",
    delivery: {
      id: "671a2d6ea378154b547eb9e5",
      customerId: "67041aec906276458c877166",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "671a2c2fa378154b547e376b",
        name: "Al Marshood House",
        lng: 48.01084902137518,
        lat: 29.31177044075296,
        city: "Kuwait",
        area: "Surra",
        block: "3",
        street: "4",
        building: "House : 15",
        floor: "",
        flat: "",
        phone: "+96597116637",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-24T11:14:55.036Z",
        updatedAt: "2024-10-24T11:14:55.036Z",
      },
      deliverectValidationId: "671a2d6e9d15dbbf9fb3a113",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:30:14.132Z",
      estimatedDeliveryTime: "2024-10-24T11:45:12.904Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:17:00.000Z",
      orderId: "671a2d6ea378154b547eb9f6",
      createdAt: "2024-10-24T11:20:14.251Z",
      updatedAt: "2024-10-24T11:22:05.718Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824661",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba908246bc",
        productPLU: "2202033-1",
        productDescription:
          "C 16.8g | P 1.1g | F 0.9g\nKiwi, Lychee, Blueberry, Raspberry, Strawberry , Mango Juice",
        productName: "Exotic Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/FaUQbHO5bVt45oTlIa5crQvzqJWE25HgI0k6ZJia_Ls/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZXhvdGljJTIwZnJ1aXQtNjY0MzdhNDcwYzI0ZDkyNWZjZDQwYTZkLnBuZw==",
        productCalories: 80,
        quantity: 1,
        nutritionInfo: {
          carbs: 16.8,
          protein: 1.1,
          fat: 0.9,
        },
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 370,
    totalExternalFiatCentsPrice: 370,
    totalFmcCentsPriceOriginal: 370,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:20:14.927Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:22:05.568Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:37:58.070Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:37:58.070Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:37:58.071Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:38:19.928Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:20:14.927Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:20:32.842Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:20:14.927Z",
    updatedAt: "2024-10-24T11:42:01.915Z",
    fleetInfo: null,
  },
  {
    id: "671a2d69a378154b547eb686",
    sid: "241024-426584",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2d69a378154b547eb667",
    firstName: "Humoud AlSanousi",
    lastName: null,
    phoneNumber: "+96599212442",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "664d834a2f93385a5e410386",
    delivery: {
      id: "671a2d69a378154b547eb667",
      customerId: "664d834a2f93385a5e410386",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "664dc60f894471f3436c9351",
        name: "Salam B2",
        lng: 48.024257384240634,
        lat: 29.296192249909197,
        city: "Kuwait City",
        area: "Salam",
        block: "2",
        street: "204",
        building: "48",
        floor: "Ground floor",
        flat: "2",
        phone: "+96599212442",
        additionalInfo: "Next to electricity box - middle stairs",
        isSelected: true,
        createdAt: "2024-05-22T10:16:47.764Z",
        updatedAt: "2024-10-24T11:19:22.372Z",
      },
      deliverectValidationId: "671a2d6872410ebb0820be8b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:30:08.893Z",
      estimatedDeliveryTime: "2024-10-24T11:45:07.083Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:17:00.000Z",
      orderId: "671a2d69a378154b547eb686",
      createdAt: "2024-10-24T11:20:09.177Z",
      updatedAt: "2024-10-24T11:22:05.678Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d34",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.2,
          protein: 2.8,
          fat: 8.6,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d36",
        productPLU: "2162137-1",
        productDescription:
          "C 58g | P 26g | F 15g [%New%]\n\nEgyptian rice topped with chopped steak and mixed veggies, served with roasted sweet potato, sauce, air-fried onion, and garnished with chives, sesame seeds and sumac. ( Includes Coriander )\n\nGood source of Protein & Vitamins",
        productName: "Chopped steak rice bowl",
        imageURL:
          "https://resizer.deliverect.com/_zrZW_wKv-53wc25P7NkxtvjUm6QJfutpW1Mat8BQ0U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hvcHBlZCUyMHN0ZWFrLTY3MTM0ZjkwYmJlZWUwMmFhMDk5ZWI3Zi5wbmc=",
        productCalories: 471,
        quantity: 1,
        nutritionInfo: {
          carbs: 58,
          protein: 26,
          fat: 15,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 480,
    totalFmcCentsPrice: 480,
    totalFiatCentsPriceOriginal: 480,
    totalExternalFiatCentsPrice: 480,
    totalFmcCentsPriceOriginal: 480,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:20:09.891Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:22:05.392Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:29:30.639Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:37:35.875Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:37:35.875Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:37:58.016Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:20:09.891Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:20:21.924Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:20:09.891Z",
    updatedAt: "2024-10-24T11:47:03.131Z",
    fleetInfo: null,
  },
  {
    id: "671a2d5ba378154b547eaf6a",
    sid: "241024-954243",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a2d5aa378154b547eaf22",
    firstName: "Shouq",
    lastName: null,
    phoneNumber: "+96590077914",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66d723ffaa1645e3a5fb9a43",
    delivery: {
      id: "671a2d5aa378154b547eaf22",
      customerId: "66d723ffaa1645e3a5fb9a43",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66d72510ced6c5247e473430",
        name: "51",
        lng: 48.07901273451121,
        lat: 29.248733520507812,
        city: "Sabah Al Salem",
        area: "Sabah Al Salem",
        block: "11",
        street: "2",
        building: "51",
        floor: "",
        flat: "",
        phone: "+96590077914",
        additionalInfo: "Jada 13",
        isSelected: true,
        createdAt: "2024-09-03T15:02:40.509Z",
        updatedAt: "2024-10-24T10:03:56.341Z",
      },
      deliverectValidationId: "671a2d5a4f3524be78f71101",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:29:54.260Z",
      estimatedDeliveryTime: "2024-10-24T11:44:53.193Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:16:00.000Z",
      orderId: "671a2d5ba378154b547eaf6a",
      createdAt: "2024-10-24T11:19:54.364Z",
      updatedAt: "2024-10-24T11:21:06.286Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e78",
        productPLU: "2162137-1",
        productDescription:
          "C 58g | P 26g | F 15g [%New%]\n\nEgyptian rice topped with chopped steak and mixed veggies, served with roasted sweet potato, sauce, air-fried onion, and garnished with chives, sesame seeds and sumac. ( Includes Coriander )\n\nGood source of Protein & Vitamins",
        productName: "Chopped steak rice bowl",
        imageURL:
          "https://resizer.deliverect.com/_zrZW_wKv-53wc25P7NkxtvjUm6QJfutpW1Mat8BQ0U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hvcHBlZCUyMHN0ZWFrLTY3MTM0ZjkwYmJlZWUwMmFhMDk5ZWI3Zi5wbmc=",
        productCalories: 471,
        quantity: 1,
        nutritionInfo: {
          carbs: 58,
          protein: 26,
          fat: 15,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 400,
    totalExternalFiatCentsPrice: 400,
    totalFmcCentsPriceOriginal: 400,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:19:55.203Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:21:12.343Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:21:28.359Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:33:07.867Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:33:07.867Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:38:50.827Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:19:55.203Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:20:07.048Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:19:55.203Z",
    updatedAt: "2024-10-24T11:38:50.899Z",
    fleetInfo: null,
  },
  {
    id: "671a2d1ea378154b547e999b",
    sid: "241024-772224",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2d1da378154b547e998b",
    firstName: "yousef sami alshayji",
    lastName: null,
    phoneNumber: "+96596090906",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6649e83a69f370da27dc1b89",
    delivery: {
      id: "671a2d1da378154b547e998b",
      customerId: "6649e83a69f370da27dc1b89",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6649e901e7d7ea1c4c66f542",
        name: "home",
        lng: 47.96621781311908,
        lat: 29.334396362304688,
        city: "م٣٧",
        area: "Kaifan",
        block: "4",
        street: "Arafat St",
        building: "15",
        floor: "2",
        flat: "1",
        phone: "+96596090906",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-19T11:56:49.406Z",
        updatedAt: "2024-10-24T11:17:03.724Z",
      },
      deliverectValidationId: "671a2d1d3bee773669a8979b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:28:53.062Z",
      estimatedDeliveryTime: "2024-10-24T11:43:52.009Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:15:00.000Z",
      orderId: "671a2d1ea378154b547e999b",
      createdAt: "2024-10-24T11:18:53.201Z",
      updatedAt: "2024-10-24T11:20:07.249Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823ebe",
        productPLU: "2162019-1",
        productDescription:
          "C 45g| P 24g| F 16g\nShrimp & rice topped with hashu with a side of dakkus.\n\nGluten free, Good source of Vitamin B 12, A & C",
        productName: "Murabyan",
        imageURL:
          "https://resizer.deliverect.com/TJVdjvdLawOHnrvUIIFGmtSne5lvu_vKoyHBIoWMnFg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkVaSVpFRCUyME1VUllCQU4tNjRjOGJlYzQ3MGY0OGUwMDFmNjdjMmVjLmpwZw==",
        productCalories: 420,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 24,
          fat: 16,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ee1",
        productPLU: "2163010-1",
        productDescription:
          "C 25g| P 34g| F 15g\nGrilled chicken with baby gem lettuce, caesar dressing topped with sourdough croutons & shaved parmesan cheese.\n\nGood source of Vitamin A &Potein",
        productName: "Chicken Ceasar Salad",
        imageURL:
          "https://resizer.deliverect.com/aOyM36xC7gBB5GwjyrYYA3YsTJ8-9RRXq22EPXeQlkM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NGI3YjVjOWU0ZDVjMzAwMThiNjViNmIucG5n",
        productCalories: 371,
        quantity: 1,
        nutritionInfo: {
          carbs: 25,
          protein: 34,
          fat: 15,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ee6",
        productPLU: "2162137-1",
        productDescription:
          "C 58g | P 26g | F 15g [%New%]\n\nEgyptian rice topped with chopped steak and mixed veggies, served with roasted sweet potato, sauce, air-fried onion, and garnished with chives, sesame seeds and sumac. ( Includes Coriander )\n\nGood source of Protein & Vitamins",
        productName: "Chopped steak rice bowl",
        imageURL:
          "https://resizer.deliverect.com/_zrZW_wKv-53wc25P7NkxtvjUm6QJfutpW1Mat8BQ0U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hvcHBlZCUyMHN0ZWFrLTY3MTM0ZjkwYmJlZWUwMmFhMDk5ZWI3Zi5wbmc=",
        productCalories: 471,
        quantity: 1,
        nutritionInfo: {
          carbs: 58,
          protein: 26,
          fat: 15,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823f0a",
        productPLU: "2166009-1",
        productDescription:
          "C 22g| P 11g| F 13g\nMango greek yogurt with chia pudding topped with mangoes.\n\nGood source oF Vitamin A",
        productName: "Mango Greek Yogurt",
        imageURL:
          "https://resizer.deliverect.com/xGontUyo2rWspjDzY7LiTodlo5Gzmv5FuI0NOV_lXRI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNS02NTExNmVjYTM5NGU3ODMxZTFiZDM3MWUuanBn",
        productCalories: 249,
        quantity: 1,
        nutritionInfo: {
          carbs: 22,
          protein: 11,
          fat: 13,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 1110,
    totalFmcCentsPrice: 1110,
    totalFiatCentsPriceOriginal: 1110,
    totalExternalFiatCentsPrice: 1110,
    totalFmcCentsPriceOriginal: 1110,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:18:54.082Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:20:13.779Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:20:30.281Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:33:53.534Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:33:53.534Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:34:49.662Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:18:54.082Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:19:05.946Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:18:54.082Z",
    updatedAt: "2024-10-24T11:35:03.272Z",
    fleetInfo: null,
  },
  {
    id: "671a2cf6a378154b547e84d7",
    sid: "241024-460461",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2cf6a378154b547e84c8",
    firstName: "Abdulmohsen Alrashed",
    lastName: null,
    phoneNumber: "+96569929664",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "657ab3d1fcc803b6c2c0a065",
    delivery: {
      id: "671a2cf6a378154b547e84c8",
      customerId: "657ab3d1fcc803b6c2c0a065",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "657ab519db039d9402e3de83",
        name: "Kfh",
        lng: 47.975520975887775,
        lat: 29.371849442118194,
        city: "Al Kuwayt",
        area: "Jibla",
        block: "10",
        street: "Abdulla Al Mubarak St",
        building: "Kuwait financial house",
        floor: "",
        flat: "",
        phone: "+96569929664",
        additionalInfo: "Service door",
        isSelected: true,
        createdAt: "2023-12-14T07:56:09.936Z",
        updatedAt: "2024-10-24T10:25:12.703Z",
      },
      deliverectValidationId: "671a2cf59d15dbbf9fb3724d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:28:13.902Z",
      estimatedDeliveryTime: "2024-10-24T11:43:12.710Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:15:00.000Z",
      orderId: "671a2cf6a378154b547e84d7",
      createdAt: "2024-10-24T11:18:14.093Z",
      updatedAt: "2024-10-24T11:20:07.413Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823ea7",
        productPLU: "2162109-1",
        productDescription:
          "C 23g | P 29g | F 16g \n Beef Lasagna with zucchini & basil\n\nGood source of protein and vitamin A",
        productName: "Zucchini Beef Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SGhwFjSpMczUdKMaEIYf4gYXABWfE33sF_WueZYOSfI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGxhc2FnbmEtNjY0NGFmZDU3NDhmMzY3YzEzMTZlNWZhLnBuZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 23,
          protein: 29,
          fat: 16,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:18:14.744Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:20:08.911Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:20:28.766Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:28:19.845Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:28:19.845Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:28:42.696Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:33:08.970Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:18:14.744Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:18:26.207Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:18:14.745Z",
    updatedAt: "2024-10-24T11:33:53.437Z",
    fleetInfo: null,
  },
  {
    id: "671a2cbda378154b547e73ed",
    sid: "241024-590585",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2cbda378154b547e739c",
    firstName: "Hisham AlEssa",
    lastName: null,
    phoneNumber: "+96566820057",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "660de93ed17e71b5c7e85d9b",
    delivery: {
      id: "671a2cbda378154b547e739c",
      customerId: "660de93ed17e71b5c7e85d9b",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "661fa46d4041cf06589f2e3c",
        name: "MAC",
        lng: 47.93966621160507,
        lat: 29.31379349021777,
        city: "Kuwait",
        area: "Rai",
        block: "3",
        street: "29 St",
        building: "GAC MOTOR",
        floor: "",
        flat: "",
        phone: "+96566820057",
        additionalInfo:
          "Please enter from the main door. GAC MOTOR is next to KIA",
        isSelected: true,
        createdAt: "2024-04-17T10:29:01.484Z",
        updatedAt: "2024-10-23T09:23:43.028Z",
      },
      deliverectValidationId: "671a2cbc1821405a3860890a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:27:16.651Z",
      estimatedDeliveryTime: "2024-10-24T11:42:15.522Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:14:00.000Z",
      orderId: "671a2cbda378154b547e73ed",
      createdAt: "2024-10-24T11:17:17.261Z",
      updatedAt: "2024-10-24T11:19:08.819Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825cdf",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d45",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 340,
    totalExternalFiatCentsPrice: 340,
    totalFmcCentsPriceOriginal: 340,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:17:17.990Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:19:16.923Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:19:24.025Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:41:11.771Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:41:11.771Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:41:34.370Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:17:17.990Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:17:37.893Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:17:17.990Z",
    updatedAt: "2024-10-24T11:47:01.798Z",
    fleetInfo: null,
  },
  {
    id: "671a2cbba378154b547e7229",
    sid: "241024-769941",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2cb8a378154b547e6fe4",
    firstName: "Mariam Al Haroun",
    lastName: null,
    phoneNumber: "+96590008595",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "665368fe47e7155f92720468",
    delivery: {
      id: "671a2cb8a378154b547e6fe4",
      customerId: "665368fe47e7155f92720468",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6653697cde3f2e06c66e0873",
        name: "home",
        lng: 47.98369150000001,
        lat: 29.3518587,
        city: "Kuwait",
        area: "Abdullah al-Salem",
        block: "3",
        street: "bader al sayer ",
        building: "32",
        floor: "",
        flat: "",
        phone: "+96590008595",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-26T16:55:24.134Z",
        updatedAt: "2024-10-18T14:57:11.090Z",
      },
      deliverectValidationId: "671a2cb7a46a7e087dd22b23",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:27:11.774Z",
      estimatedDeliveryTime: "2024-10-24T11:42:10.750Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:14:00.000Z",
      orderId: "671a2cbba378154b547e7229",
      createdAt: "2024-10-24T11:17:12.520Z",
      updatedAt: "2024-10-24T11:19:07.699Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823ea7",
        productPLU: "2162109-1",
        productDescription:
          "C 23g | P 29g | F 16g \n Beef Lasagna with zucchini & basil\n\nGood source of protein and vitamin A",
        productName: "Zucchini Beef Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SGhwFjSpMczUdKMaEIYf4gYXABWfE33sF_WueZYOSfI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGxhc2FnbmEtNjY0NGFmZDU3NDhmMzY3YzEzMTZlNWZhLnBuZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 23,
          protein: 29,
          fat: 16,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:17:15.369Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:19:16.817Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:19:22.708Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:33:42.340Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:33:42.340Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:34:04.472Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:17:15.369Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:17:27.448Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:17:15.369Z",
    updatedAt: "2024-10-24T11:34:16.741Z",
    fleetInfo: null,
  },
  {
    id: "671a2ba8e79c0dba9089f46a",
    sid: "241024-258874",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a2ba7e79c0dba9089f23a",
    firstName: "Lulu alsanea",
    lastName: null,
    phoneNumber: "+96599577777",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6703ffd2906276458c86ee9a",
    delivery: {
      id: "671a2ba7e79c0dba9089f23a",
      customerId: "6703ffd2906276458c86ee9a",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67040095218a8c4af29f10ea",
        name: "Home",
        lng: 48.0125455185771,
        lat: 29.31032826996189,
        city: "Jabriya",
        area: "Surra",
        block: "3",
        street: "8 St",
        building: "13A",
        floor: "Ground floor ",
        flat: "",
        phone: "+96599577777",
        additionalInfo: "Entrance near electricity building side",
        isSelected: true,
        createdAt: "2024-10-07T15:39:01.709Z",
        updatedAt: "2024-10-07T15:39:01.709Z",
      },
      deliverectValidationId: "671a2ba63946960ae1528c2b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:22:38.990Z",
      estimatedDeliveryTime: "2024-10-24T11:37:37.819Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:09:00.000Z",
      orderId: "671a2ba8e79c0dba9089f46a",
      createdAt: "2024-10-24T11:12:39.149Z",
      updatedAt: "2024-10-24T11:14:07.439Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824696",
        productPLU: "2162056-1",
        productDescription:
          "C 52g| P 40g| F 18g\nGrilled tenderloin steak served with egyptian rice, charred corn, mushroom sauce, grilled onion topped with chives.\n\nGood source of iron & B-Complex vitamins",
        productName: "Mushroom Steak Bowl",
        imageURL:
          "https://resizer.deliverect.com/mI46_G8ohOUlax6lzY869gIqShTLi01aFmd1aeKxxuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGJvd2wlMjAtNjRmNDhmODQxNDI5ODQwMDFlOGVjOGVkLmpwZw==",
        productCalories: 530,
        quantity: 1,
        nutritionInfo: {
          carbs: 52,
          protein: 40,
          fat: 18,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "No onion",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba90824690",
        productPLU: "2163012-1",
        productDescription:
          "C 6g| P 12g| F 5g\nChicken caesar salad with grilled chicken topped with sourdough croutons & shaved parmesan cheese.  \n\nGOOD SOURCE OF PROTIEN , VITAMIN K & A",
        productName: "MINI CAESAR SALAD",
        imageURL:
          "https://resizer.deliverect.com/pn19DI6nKfoNFDytlww7s2gKrhsydFXuV2FgKSAnmjs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNhZXNhciUyMHNhbGFkLTY1MDQ4YTQ3YzQwODk3MzFlMzM5OTFkZi5qcGc=",
        productCalories: 117,
        quantity: 1,
        nutritionInfo: {
          carbs: 6,
          protein: 12,
          fat: 5,
        },
        fmcCentsPrice: 140,
        fiatCentsPrice: 140,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 540,
    totalFmcCentsPrice: 540,
    totalFiatCentsPriceOriginal: 540,
    totalExternalFiatCentsPrice: 540,
    totalFmcCentsPriceOriginal: 540,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:12:40.984Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:14:06.462Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:16:54.954Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:32:49.688Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:32:49.688Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:33:08.774Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:44:36.557Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:12:40.984Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:12:52.878Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:12:40.984Z",
    updatedAt: "2024-10-24T11:44:36.903Z",
    fleetInfo: null,
  },
  {
    id: "671a2b8de79c0dba9089dcc0",
    sid: "241024-433156",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2b8ce79c0dba9089dbea",
    firstName: "Abdullah Alkhonaini",
    lastName: null,
    phoneNumber: "+96599678000",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "657f47f979c7230609b2a454",
    delivery: {
      id: "671a2b8ce79c0dba9089dbea",
      customerId: "657f47f979c7230609b2a454",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65b5cfe0b1aa073e20dec3f6",
        name: "Home",
        lng: 47.97358240932226,
        lat: 29.30472777889939,
        city: "Kuwait",
        area: "Yarmouk",
        block: "2",
        street: "1 st 1 Ln",
        building: "16",
        floor: "",
        flat: "",
        phone: "+96599678000",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-28T03:54:08.017Z",
        updatedAt: "2024-10-21T18:21:55.575Z",
      },
      deliverectValidationId: "671a2b8cdda2cbde338f9ea8",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:22:12.326Z",
      estimatedDeliveryTime: "2024-10-24T11:37:11.195Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:09:00.000Z",
      orderId: "671a2b8de79c0dba9089dcc0",
      createdAt: "2024-10-24T11:12:12.427Z",
      updatedAt: "2024-10-24T11:14:08.224Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825cdf",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d5d",
        productPLU: "2166035-1",
        productDescription: "C 32g | P 1g | F 0g\nRed Grapes",
        productName: "Red Grapes",
        imageURL:
          "https://resizer.deliverect.com/AtcTHpynl67VC2iPbOU8NdD4B36i27YMor_K0a-pcRA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAzXy02NjNmZWU0YWUwMDMyNDNiNWVhOTRjZWUucG5n",
        productCalories: 132,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 1,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 430,
    totalFmcCentsPrice: 430,
    totalFiatCentsPriceOriginal: 430,
    totalExternalFiatCentsPrice: 430,
    totalFmcCentsPriceOriginal: 430,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:12:13.234Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:14:12.491Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:16:28.487Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:34:16.906Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:34:16.906Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:34:38.428Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:12:13.234Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:12:32.525Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:12:13.234Z",
    updatedAt: "2024-10-24T11:46:53.992Z",
    fleetInfo: null,
  },
  {
    id: "671a2b80e79c0dba9089d639",
    sid: "241024-302596",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2b7fe79c0dba9089d5e4",
    firstName: "هيا اظبيه",
    lastName: null,
    phoneNumber: "+96599561944",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66eaba06aa1645e3a52322be",
    delivery: {
      id: "671a2b7fe79c0dba9089d5e4",
      customerId: "66eaba06aa1645e3a52322be",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6702b816218a8c4af25d82ab",
        name: "Home ",
        lng: 47.98048507422209,
        lat: 29.310683773254027,
        city: "Kuwait",
        area: "Qortuba",
        block: "4",
        street: "5th ",
        building: "2",
        floor: "",
        flat: "",
        phone: "+96599561944",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-06T16:17:26.838Z",
        updatedAt: "2024-10-22T11:50:26.135Z",
      },
      deliverectValidationId: "671a2b7f6ae6b41d0f95fbe7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:21:59.417Z",
      estimatedDeliveryTime: "2024-10-24T11:36:58.238Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:09:00.000Z",
      orderId: "671a2b80e79c0dba9089d639",
      createdAt: "2024-10-24T11:11:59.513Z",
      updatedAt: "2024-10-24T11:14:08.248Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d11",
        productPLU: "2162043-1",
        productDescription:
          "C 45g| P 28g| F 16g\nPink pasta with grilled chicken breast, topped with parmesan cheese.\n\nGood source of B-Complex Vitamins",
        productName: "Pink Pasta",
        imageURL:
          "https://resizer.deliverect.com/h10UGlGO3vP_ayu1YBOqijxvB6J8MuhGdH52j7lOn8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGluayUyMHBhc3RhLTY0YjdhZmJlZWUwY2I5MDAyMWRjNWRlOC5wbmc=",
        productCalories: 436,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 28,
          fat: 16,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d6d",
        productPLU: "2165005-1",
        productDescription: "C 13g| P 1g| F 10g",
        productName: "Salted Pecan Bites",
        imageURL:
          "https://resizer.deliverect.com/p3z6wZFsuIaNIkw99YRPwzlHvwGLg970lPG5QDvZQrc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDgtNjRiN2JjOTE2ZjhhYmY0M2UzMWQxMjBjLnBuZw==",
        productCalories: 151,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 1,
          fat: 10,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 350,
    totalExternalFiatCentsPrice: 350,
    totalFmcCentsPriceOriginal: 350,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:12:00.374Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:14:09.283Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:16:32.961Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:30:23.491Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:30:23.492Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:30:45.368Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:12:00.374Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:12:16.247Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:12:00.374Z",
    updatedAt: "2024-10-24T11:46:48.318Z",
    fleetInfo: null,
  },
  {
    id: "671a2b78e79c0dba9089d158",
    sid: "241024-285820",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2b78e79c0dba9089d14d",
    firstName: "Nimsha",
    lastName: null,
    phoneNumber: "+96597552255",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c3f0e975a6aa442d0f3611",
    delivery: {
      id: "671a2b78e79c0dba9089d14d",
      customerId: "64c3f0e975a6aa442d0f3611",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64ce56305e8f4fe1fb17c464",
        name: "Home",
        lng: 47.9952248185873,
        lat: 29.307112565697146,
        city: "Kuwait",
        area: "Qortuba",
        block: "2",
        street: "1 St",
        building: "56",
        floor: "",
        flat: "",
        phone: "+96597552255",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-05T14:01:20.526Z",
        updatedAt: "2024-10-08T10:07:26.531Z",
      },
      deliverectValidationId: "671a2b789b0c83c35d5ab2a9",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:21:52.138Z",
      estimatedDeliveryTime: "2024-10-24T11:36:50.925Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:08:00.000Z",
      orderId: "671a2b78e79c0dba9089d158",
      createdAt: "2024-10-24T11:11:52.238Z",
      updatedAt: "2024-10-24T11:13:05.675Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d18",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "No corn please ",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d44",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 510,
    totalFmcCentsPrice: 510,
    totalFiatCentsPriceOriginal: 510,
    totalExternalFiatCentsPrice: 510,
    totalFmcCentsPriceOriginal: 510,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:11:52.850Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:13:05.034Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:13:20.414Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:28:19.903Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:28:19.903Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:31:10.020Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:11:52.850Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:12:04.548Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:11:52.850Z",
    updatedAt: "2024-10-24T11:31:10.138Z",
    fleetInfo: null,
  },
  {
    id: "671a2b56e79c0dba9089c0d1",
    sid: "241024-111348",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2b55e79c0dba9089c0b2",
    firstName: "Seham Sultan ALMubarak",
    lastName: null,
    phoneNumber: "+96590007400",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "662d0ef97ef16a176ee32974",
    delivery: {
      id: "671a2b55e79c0dba9089c0b2",
      customerId: "662d0ef97ef16a176ee32974",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "662d0fefc37a2a2c26044b96",
        name: "Sultan almubarak ",
        lng: 47.97554478049278,
        lat: 29.34068359488353,
        city: "Kuwait City",
        area: "Faiha",
        block: "1",
        street: "Abdullah alfadel",
        building: "1",
        floor: "",
        flat: "",
        phone: "50630031",
        additionalInfo: "Corner house near roundabout ",
        isSelected: true,
        createdAt: "2024-04-27T14:47:11.499Z",
        updatedAt: "2024-08-31T10:29:12.768Z",
      },
      deliverectValidationId: "671a2b54c6ffa8c03b4a6ee0",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:21:16.898Z",
      estimatedDeliveryTime: "2024-10-24T11:36:14.845Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:08:00.000Z",
      orderId: "671a2b56e79c0dba9089c0d1",
      createdAt: "2024-10-24T11:11:17.521Z",
      updatedAt: "2024-10-24T11:13:05.677Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823ecf",
        productPLU: "2167009-1",
        productDescription:
          "C 40g| P 34g| F 20g\nRoast beef, arugula, caramelized onions, american cheese & mozarella cheese.\n\n*GOOD SOURCE OF PROTEIN,VITAMINB12 *",
        productName: "Steak Beef Sandwich",
        imageURL:
          "https://resizer.deliverect.com/DtNUTtvxCtTpYduIHX1EtM3gopyjoTP3-HuOderSW-c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU1RFQUslMjBTQU5EV0hJQ0gtNjU3NTkwMGE0OGU3YWFhNzJiYzFjNDY5LnBuZw==",
        productCalories: 476,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 34,
          fat: 20,
        },
        fmcCentsPrice: 330,
        fiatCentsPrice: 330,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671a1ab4e79c0dba90823f90",
            productPLU: "1907040-1",
            productName: "No Caramelized Onion",
            productDescription: "C -6g| P -0.6g| F 0g| cal -25| coins 0",
            productCalories: -25,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -6,
              protein: -0.6,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "671a1ab4e79c0dba90823f1d",
        productPLU: "2165005-1",
        productDescription: "C 13g| P 1g| F 10g",
        productName: "Salted Pecan Bites",
        imageURL:
          "https://resizer.deliverect.com/p3z6wZFsuIaNIkw99YRPwzlHvwGLg970lPG5QDvZQrc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDgtNjRiN2JjOTE2ZjhhYmY0M2UzMWQxMjBjLnBuZw==",
        productCalories: 151,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 1,
          fat: 10,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 410,
    totalFmcCentsPrice: 410,
    totalFiatCentsPriceOriginal: 410,
    totalExternalFiatCentsPrice: 410,
    totalFmcCentsPriceOriginal: 410,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:11:18.438Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:13:05.330Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:14:11.235Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:26:11.551Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:26:11.551Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:26:34.592Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:44:59.273Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:11:18.438Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:11:30.426Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:11:18.438Z",
    updatedAt: "2024-10-24T11:44:59.619Z",
    fleetInfo: null,
  },
  {
    id: "671a2b1fe79c0dba90899e77",
    sid: "241024-387531",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2b1de79c0dba90899d76",
    firstName: "Zahra Almousa",
    lastName: null,
    phoneNumber: "+96566464888",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64db325670771121d148cffe",
    delivery: {
      id: "671a2b1de79c0dba90899d76",
      customerId: "64db325670771121d148cffe",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65b8dfd1b1aa073e20f6cef3",
        name: "Hamra Office",
        lng: 47.99318154816811,
        lat: 29.379287719726562,
        city: "مدينة الكويت",
        area: "شرق",
        block: "-",
        street: "شارع الشهداء",
        building: "AlHamra Tower",
        floor: "23",
        flat: "",
        phone: "+96566464888",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-30T11:38:57.689Z",
        updatedAt: "2024-10-23T11:02:39.347Z",
      },
      deliverectValidationId: "671a2b1d0374fafa8853b5d9",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:20:21.762Z",
      estimatedDeliveryTime: "2024-10-24T11:35:20.675Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:07:00.000Z",
      orderId: "671a2b1fe79c0dba90899e77",
      createdAt: "2024-10-24T11:10:21.860Z",
      updatedAt: "2024-10-24T11:12:11.267Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823ec4",
        productPLU: "2167026-1",
        productDescription:
          "C 31g | P 19g | F 12g [%New%]\n\nTortilla, sun-dried tomato paste, turkey breast, light mayo, iceberg lettuce, fresh tomato, cheddar cheese, avocado.",
        productName: "Sundried Tomato & Turkey Wrap",
        imageURL:
          "https://resizer.deliverect.com/lkBFjz6fT3ypjhfEPm4jFgiCjqU4b3hZdgbi5fTcJJo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3VuJTIwZHJpZWQlMjB0dXJrZXklMjB3cmFwLTY2ZjkwZDU2MzNmN2I4MjhjMTYwY2U3ZC5wbmc=",
        productCalories: 308,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:10:23.115Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:12:07.536Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:14:13.731Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:19:42.728Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:19:42.729Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:23:57.135Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:10:23.115Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:10:34.561Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T11:10:23.115Z",
    updatedAt: "2024-10-24T11:24:22.202Z",
    fleetInfo: null,
  },
  {
    id: "671a2b0ee79c0dba908994df",
    sid: "241024-419135",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2b0ce79c0dba908993c3",
    firstName: "Ahmad Mo",
    lastName: null,
    phoneNumber: "+96599966916",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "652af1ee1bfa57c5dbf8438f",
    delivery: {
      id: "671a2b0ce79c0dba908993c3",
      customerId: "652af1ee1bfa57c5dbf8438f",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65463b60f91376d3946bc4f7",
        name: "Yarmouk ",
        lng: 47.9633367061615,
        lat: 29.313151205819025,
        city: "Kuwait",
        area: "Yarmouk",
        block: "4",
        street: "1 St",
        building: "26",
        floor: "2",
        flat: "3",
        phone: "+96599966916",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-04T12:38:56.320Z",
        updatedAt: "2024-09-24T11:06:04.238Z",
      },
      deliverectValidationId: "671a2b0c0348c4b0eab017e6",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:20:04.359Z",
      estimatedDeliveryTime: "2024-10-24T11:35:03.282Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:07:00.000Z",
      orderId: "671a2b0ee79c0dba908994df",
      createdAt: "2024-10-24T11:10:04.481Z",
      updatedAt: "2024-10-24T11:12:05.786Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825ce7",
        productPLU: "2162125-1",
        productDescription:
          "C 51g | P 30g | F 8g\nGrilled Salmon with dill rice served with dakkus, lemon wedge & fresh pomegranate.\n\nGood source of Protein & Vitamins",
        productName: "Salmon & Dill Rice",
        imageURL:
          "https://resizer.deliverect.com/p93TVzm_6AWG3-UvpUjucOs9ophRezuPgMNQYVzvWXA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2FsbW9uJTIwJTI2JTIwZGlsbC02NmFiZjQ0Y2JiMGY0NmM4YzFkMDRjMzcucG5n",
        productCalories: 396,
        quantity: 1,
        nutritionInfo: {
          carbs: 51,
          protein: 30,
          fat: 8,
        },
        fmcCentsPrice: 840,
        fiatCentsPrice: 480,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671a1acae79c0dba90825d8c",
            productPLU: "1906082-1",
            productName: "Add Salmon",
            productDescription: "C 2g| P 25g| F 5g| cal 162| coins 3.6",
            productCalories: 162,
            quantity: 1,
            fmcCentsPrice: 360,
            fiatCentsPrice: 360,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 2,
              protein: 25,
              fat: 5,
            },
          },
        ],
      },
      {
        productId: "671a1acae79c0dba90825d0e",
        productPLU: "2162019-1",
        productDescription:
          "C 45g| P 24g| F 16g\nShrimp & rice topped with hashu with a side of dakkus.\n\nGluten free, Good source of Vitamin B 12, A & C",
        productName: "Murabyan",
        imageURL:
          "https://resizer.deliverect.com/TJVdjvdLawOHnrvUIIFGmtSne5lvu_vKoyHBIoWMnFg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkVaSVpFRCUyME1VUllCQU4tNjRjOGJlYzQ3MGY0OGUwMDFmNjdjMmVjLmpwZw==",
        productCalories: 420,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 24,
          fat: 16,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 1130,
    totalFmcCentsPrice: 1130,
    totalFiatCentsPriceOriginal: 1130,
    totalExternalFiatCentsPrice: 1130,
    totalFmcCentsPriceOriginal: 1130,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:10:06.092Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:12:05.820Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:12:28.070Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:23:23.158Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:23:23.158Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:35:03.070Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:10:06.092Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:10:18.815Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:10:06.092Z",
    updatedAt: "2024-10-24T11:35:03.157Z",
    fleetInfo: null,
  },
  {
    id: "671a2af5e79c0dba90898d26",
    sid: "241024-401054",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a2af4e79c0dba90898d04",
    firstName: "حصه فهد ",
    lastName: null,
    phoneNumber: "+96555325330",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66c8b414dd3e60cf2e538fdc",
    delivery: {
      id: "671a2af4e79c0dba90898d04",
      customerId: "66c8b414dd3e60cf2e538fdc",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66c8b4928bb86bc9eb7d9c45",
        name: "هديه",
        lng: 48.089723736047745,
        lat: 29.13811698829471,
        city: "هدية",
        area: "هدية",
        block: "5",
        street: "18",
        building: "15",
        floor: "-",
        flat: "-",
        phone: "+96555325330",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-23T16:10:58.732Z",
        updatedAt: "2024-08-23T16:10:58.732Z",
      },
      deliverectValidationId: "671a2af4eb2dd773021aa61d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:19:40.637Z",
      estimatedDeliveryTime: "2024-10-24T11:34:39.488Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:06:00.000Z",
      orderId: "671a2af5e79c0dba90898d26",
      createdAt: "2024-10-24T11:09:40.742Z",
      updatedAt: "2024-10-24T11:11:05.288Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e40",
        productPLU: "2162082-1",
        productDescription:
          "C 44g| P 39g| F 6g\nChicken breast with baked eggplant, cauliflower, onions, carrots, red bell peppers in rice with CORIANDER\n\nGOOD SOURCE OF PROTEIN,VITAMIN A &C",
        productName: "Maqlouba",
        imageURL:
          "https://resizer.deliverect.com/ImlBCJvVJcyYJBWth7S7FEgLNUj1i0B0vhTLvEmLoDY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NjQ0YmNjMzc0OGYzNjdjMTMxNmU2ODgucG5n",
        productCalories: 386,
        quantity: 1,
        nutritionInfo: {
          carbs: 44,
          protein: 39,
          fat: 6,
        },
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        externalFiatCentsPrice: 0,
        remark:
          " with out baked eggplant, cauliflower, onions, carrots,  CORIANDER",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824e8d",
        productPLU: "2166042-1",
        productDescription: "C 26.6g | P 13.8g | F 7.1g",
        productName: "PROPUFFS Cheese",
        imageURL:
          "https://resizer.deliverect.com/e0URr0ml6_6wBDHu6cUrypxzvNRY1xVo6FoQ9SwxXqE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NmJlMDUzNDA4YmY5MDY0ZWFmYTVkYjgucG5n",
        productCalories: 194,
        quantity: 1,
        nutritionInfo: {
          carbs: 26.6,
          protein: 13.8,
          fat: 7.1,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 340,
    totalExternalFiatCentsPrice: 340,
    totalFmcCentsPriceOriginal: 340,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:09:41.376Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:11:04.501Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:12:08.118Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:21:51.224Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:21:51.225Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:30:57.570Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:09:41.376Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:09:52.810Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:09:41.376Z",
    updatedAt: "2024-10-24T11:31:21.879Z",
    fleetInfo: null,
  },
  {
    id: "671a2ac0e79c0dba90897a42",
    sid: "241024-336621",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2abee79c0dba90897829",
    firstName: "806394",
    lastName: null,
    phoneNumber: "+96555550730",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66265391aa408215b396560f",
    delivery: {
      id: "671a2abee79c0dba90897829",
      customerId: "66265391aa408215b396560f",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6626543e892823a48b41b93a",
        name: "My home",
        lng: 47.98504907264788,
        lat: 29.336166381835938,
        city: "Kuwait",
        area: "Faiha",
        block: "9",
        street: "93 St",
        building: "3",
        floor: "2",
        flat: "2",
        phone: "+96555550730",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-22T12:12:46.606Z",
        updatedAt: "2024-04-22T12:12:46.606Z",
      },
      deliverectValidationId: "671a2abd0374fafa88539267",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:18:45.800Z",
      estimatedDeliveryTime: "2024-10-24T11:33:44.774Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:05:00.000Z",
      orderId: "671a2ac0e79c0dba90897a42",
      createdAt: "2024-10-24T11:08:46.106Z",
      updatedAt: "2024-10-24T11:10:07.807Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823ec0",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ebc",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ef5",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ef6",
        productPLU: "2166031-1",
        productDescription:
          "C 32g| P 12g| F 12g\nBaked sweet potato fries with mayo barbeque sauce.",
        productName: "Baked Sweet Potato Fries",
        imageURL:
          "https://resizer.deliverect.com/EexbDtzbKG0sVOjjNXiIlT_MIN2doyc2NlpJu2_T6nc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3dlZXQlMjBwb3RhdG8lMjBmcmllcygxKS02NTU0OGY3NzYyYWIwZTAwMWYzYTczNzEucG5n",
        productCalories: 244,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 12,
          fat: 12,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 690,
    totalFmcCentsPrice: 690,
    totalFiatCentsPriceOriginal: 690,
    totalExternalFiatCentsPrice: 690,
    totalFmcCentsPriceOriginal: 690,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:08:48.715Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:10:07.351Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:10:28.434Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:19:35.972Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:19:35.973Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:26:08.813Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:08:48.715Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:09:00.688Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:08:48.715Z",
    updatedAt: "2024-10-24T11:26:09.287Z",
    fleetInfo: null,
  },
  {
    id: "671a2a5ce79c0dba90894088",
    sid: "241024-964319",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a2a5be79c0dba90894077",
    firstName: "Thamer Alebrahim",
    lastName: null,
    phoneNumber: "+96597903366",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64f34c121324bbcd6480a95a",
    delivery: {
      id: "671a2a5be79c0dba90894077",
      customerId: "64f34c121324bbcd6480a95a",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65214159f3f5a7e1da0d1fbd",
        name: "Sweet Home",
        lng: 48.07625820860267,
        lat: 29.275318909870755,
        city: "Kuwait",
        area: "Mishref",
        block: "3",
        street: "1",
        building: "8",
        floor: "",
        flat: "",
        phone: "+96597903366",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-07T11:30:33.417Z",
        updatedAt: "2024-09-04T09:41:06.912Z",
      },
      deliverectValidationId: "671a2a5b4f3524be78f61150",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:17:07.186Z",
      estimatedDeliveryTime: "2024-10-24T11:32:05.630Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:04:00.000Z",
      orderId: "671a2a5ce79c0dba90894088",
      createdAt: "2024-10-24T11:07:07.394Z",
      updatedAt: "2024-10-24T11:09:10.763Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba9082463d",
        productPLU: "2162134-1",
        productDescription:
          "C 33g | P 22g | F 19g [%New%]\n\nSesame bun, chicken patty, mayo sauce mix, spicy coleslaw, and cheddar cheese.",
        productName: "Spicy slaw chicken burger",
        imageURL:
          "https://resizer.deliverect.com/ocLKlrRu1U60c57669Upgx0hkVW30a4Pd8p0JRqT0as/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BpY3klMjBzbGF3JTIwY2hpY2tlbiUyMGJ1cmdlci02NzEzNTA3YmJhNmM3ZTllODMyZWQzYmQucG5n",
        productCalories: 391,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 22,
          fat: 19,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba908246b9",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:07:08.009Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:09:08.445Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:16:54.780Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:28:54.954Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:28:54.954Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:29:17.190Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:07:08.009Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:07:24.755Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:07:08.009Z",
    updatedAt: "2024-10-24T11:42:27.839Z",
    fleetInfo: null,
  },
  {
    id: "671a2a58e79c0dba90893f72",
    sid: "241024-642481",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a2a57e79c0dba90893f69",
    firstName: "Aisha hs",
    lastName: null,
    phoneNumber: "+96599977748",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "669a372cea7d0049ce6910c2",
    delivery: {
      id: "671a2a57e79c0dba90893f69",
      customerId: "669a372cea7d0049ce6910c2",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6717faf2b7a34cd092fcdce2",
        name: "9A",
        lng: 48.000054471194744,
        lat: 29.310461004461516,
        city: "Kuwait",
        area: "Surra",
        block: "4",
        street: "16 St",
        building: "9A",
        floor: "",
        flat: "",
        phone: "+96599977748",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-22T19:20:18.449Z",
        updatedAt: "2024-10-22T19:20:18.449Z",
      },
      deliverectValidationId: "671a2a574f3524be78f610f1",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:17:03.326Z",
      estimatedDeliveryTime: "2024-10-24T11:32:01.677Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:04:00.000Z",
      orderId: "671a2a58e79c0dba90893f72",
      createdAt: "2024-10-24T11:07:03.421Z",
      updatedAt: "2024-10-24T11:09:10.764Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824667",
        productPLU: "2162044-1",
        productDescription:
          "C 46g| P 32g| F 8g\nButter chicken served with white basmati rice and a brocolli garnish with coriander.\n\nGluten free, Good source of Vitamins & Iron.",
        productName: "Butter Chicken",
        imageURL:
          "https://resizer.deliverect.com/kNZO7hSeSAa7aTJWFU1oyK8hGOHaTbLbvvvMD_J1ft0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnV0dGVyJTIwY2hpY2tlbi02NWY2ZWEzZTUxOTBiNjZmYzkxYmQ2MzMuanBn",
        productCalories: 384,
        quantity: 1,
        nutritionInfo: {
          carbs: 46,
          protein: 32,
          fat: 8,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 270,
    totalExternalFiatCentsPrice: 270,
    totalFmcCentsPriceOriginal: 270,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:07:04.146Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:09:10.120Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:16:33.070Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:27:32.121Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:27:32.122Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:27:54.375Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:41:50.916Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:07:04.146Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:07:13.454Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:07:04.146Z",
    updatedAt: "2024-10-24T11:45:26.217Z",
    fleetInfo: null,
  },
  {
    id: "671a2a51e79c0dba90893d59",
    sid: "241024-589394",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2a50e79c0dba90893d47",
    firstName: "Khaled Al Ammari",
    lastName: null,
    phoneNumber: "+96599308500",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64e8eba28073f39665e5af54",
    delivery: {
      id: "671a2a50e79c0dba90893d47",
      customerId: "64e8eba28073f39665e5af54",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6603e558bb94678303e29200",
        name: "Office ",
        lng: 47.96633306890726,
        lat: 29.361510482599172,
        city: "Al Kuwayt",
        area: "Jibla",
        block: "13",
        street: "23 St",
        building: "Juibla squire tower",
        floor: "10",
        flat: "",
        phone: "+96599308500",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-03-27T09:22:32.624Z",
        updatedAt: "2024-10-15T07:06:33.749Z",
      },
      deliverectValidationId: "671a2a50ff147771ad95c74a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:16:56.730Z",
      estimatedDeliveryTime: "2024-10-24T11:31:55.593Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:03:00.000Z",
      orderId: "671a2a51e79c0dba90893d59",
      createdAt: "2024-10-24T11:06:56.858Z",
      updatedAt: "2024-10-24T11:08:07.712Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823ee3",
        productPLU: "2163012-1",
        productDescription:
          "C 6g| P 12g| F 5g\nChicken caesar salad with grilled chicken topped with sourdough croutons & shaved parmesan cheese.  \n\nGOOD SOURCE OF PROTIEN , VITAMIN K & A",
        productName: "MINI CAESAR SALAD",
        imageURL:
          "https://resizer.deliverect.com/pn19DI6nKfoNFDytlww7s2gKrhsydFXuV2FgKSAnmjs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNhZXNhciUyMHNhbGFkLTY1MDQ4YTQ3YzQwODk3MzFlMzM5OTFkZi5qcGc=",
        productCalories: 117,
        quantity: 1,
        nutritionInfo: {
          carbs: 6,
          protein: 12,
          fat: 5,
        },
        fmcCentsPrice: 140,
        fiatCentsPrice: 140,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823eb8",
        productPLU: "2162085-1",
        productDescription:
          "C 15g| P 45g| F 15g\nGrilled chicken served with mashed potatoes, blanched broccoli, cauliflower & carrots, served with a lemon sauce garnished with a slice of lemon and parsley.\n\nGood source of Protein",
        productName: "Grilled Lemon Chicken",
        imageURL:
          "https://resizer.deliverect.com/n9S1sQV2m-69QdAwmCpIpZWcf6LGGUpyB0bFdTHRC1U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3JpbGxlZCUyMGxlbW9uJTIwY2hpY2tlbigxKS02NTU0OTA2MWZhNTNkZDAwMWVlOTQ2N2IucG5n",
        productCalories: 375,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 45,
          fat: 15,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 410,
    totalFmcCentsPrice: 410,
    totalFiatCentsPriceOriginal: 410,
    totalExternalFiatCentsPrice: 410,
    totalFmcCentsPriceOriginal: 410,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:06:57.413Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:08:06.152Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:10:28.586Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:19:53.617Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:19:53.617Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:24:33.351Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:29:17.585Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:06:57.413Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:07:02.174Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T11:06:57.413Z",
    updatedAt: "2024-10-24T11:29:17.926Z",
    fleetInfo: null,
  },
  {
    id: "671a2a3ce79c0dba908937cb",
    sid: "241024-063418",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2a3ce79c0dba908937c0",
    firstName: "Noor Alsaleh",
    lastName: null,
    phoneNumber: "+96560722882",
    paymentType: "fmcCents",
    type: "scheduleOrder",
    customerId: "64de572770771121d14a5bb9",
    delivery: {
      id: "671a2a3ce79c0dba908937c0",
      customerId: "64de572770771121d14a5bb9",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "651c027bf3f5a7e1daf60806",
        name: "Home",
        lng: 47.96622443944216,
        lat: 29.313787936320946,
        city: "Kuwait",
        area: "Yarmouk",
        block: "4",
        street: "3 avenue 1",
        building: "13",
        floor: "G",
        flat: "1",
        phone: "+96560722882",
        additionalInfo: "Backside of the house",
        isSelected: true,
        createdAt: "2023-10-03T12:00:59.954Z",
        updatedAt: "2024-10-24T11:02:43.398Z",
      },
      deliverectValidationId: "671a2a3b9d15dbbf9fb2446e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:16:35.971Z",
      estimatedDeliveryTime: "2024-10-24T12:00:00.000Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:06:00.000Z",
      orderId: "671a2a3ce79c0dba908937cb",
      createdAt: "2024-10-24T11:06:36.079Z",
      updatedAt: "2024-10-24T11:25:05.785Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d84",
        productPLU: "2202040-1",
        productDescription: "C 4.8g | P 0g | F 0g\nOrange, Grapefruit & Lemon",
        productName: "Immunity Shot",
        imageURL:
          "https://resizer.deliverect.com/WkO7ZBCzCZBVTRau0FCMoAizLyoqJmikHQl5iJRWhJs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaW1tdW5pdHklMjBzaG90LTY2NDFkYjEwYWIxMzE0ZDg1Njc3OGNkZS5wbmc=",
        productCalories: 19,
        quantity: 1,
        nutritionInfo: {
          carbs: 4.8,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d3a",
        productPLU: "2162021-1",
        productDescription:
          "C 60g| P 50g| F 16g\nChicken shawarma served with calrose rice & drizzled with our shawarma sauce and sumac white onions.\n\nHigh in protein, ",
        productName: "Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/wpaWs0_xzYbkz_6vxHW2469_71kHXwgrjXuSA4epEwY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2hpY2tlbiUyMFNoYXdhcm1hJTIwYm93bC02NGY0OTRlOTFhNzliZjNkNzNkYjUzNjcuanBn",
        productCalories: 584,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 50,
          fat: 16,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:06:36.812Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:25:06.769Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:28:14.743Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:46:10.930Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:46:10.931Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:46:26.795Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:06:36.812Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:06:40.538Z",
      },
    ],
    cutlery: false,
    timeslot: "15:00 - 15:29",
    createdAt: "2024-10-24T11:06:36.813Z",
    updatedAt: "2024-10-24T11:46:55.050Z",
    fleetInfo: null,
  },
  {
    id: "671a2a07e79c0dba908920ff",
    sid: "241024-092764",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a2a06e79c0dba908920ec",
    firstName: "Yousef",
    lastName: null,
    phoneNumber: "+96599374868",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "655ceb875548ef3693329335",
    delivery: {
      id: "671a2a06e79c0dba908920ec",
      customerId: "655ceb875548ef3693329335",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670fc616f495b693cd0ac4a8",
        name: "Home",
        lng: 48.09519561007619,
        lat: 29.214018048441005,
        city: "Fnaitees",
        area: "Fnaitees",
        block: "7",
        street: "702",
        building: "230",
        floor: "2",
        flat: "5",
        phone: "+96599374868",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-16T13:56:38.632Z",
        updatedAt: "2024-10-16T13:56:38.632Z",
      },
      deliverectValidationId: "671a2a066ae6b41d0f94a293",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:15:42.272Z",
      estimatedDeliveryTime: "2024-10-24T11:30:41.241Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:02:00.000Z",
      orderId: "671a2a07e79c0dba908920ff",
      createdAt: "2024-10-24T11:05:42.413Z",
      updatedAt: "2024-10-24T11:07:07.858Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e47",
        productPLU: "2162083-1",
        productDescription:
          "C 17g| P 50g| F 23g\nGrilled steak topped with a mushroom sauce, served with mashed potatoes, blanched broccoli, cauliflower, carrots & baby corn.\n\nGOOD SOURCE OF PROTEIN,VITAMINS B12",
        productName: "Steak with Mushroom Sauce",
        imageURL:
          "https://resizer.deliverect.com/MhHmajc6fZKUcOfQ81P3g_G9D6wrDIOY-TbE2YJyj3o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3RlYWslMjB3aXRoJTIwbXVzaHJvb20tNjU1NGNjMTdlMWViNzQwMDFlNmYyZDdhLnBuZw==",
        productCalories: 475,
        quantity: 1,
        nutritionInfo: {
          carbs: 17,
          protein: 50,
          fat: 23,
        },
        fmcCentsPrice: 540,
        fiatCentsPrice: 540,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 540,
    totalFmcCentsPrice: 540,
    totalFiatCentsPriceOriginal: 540,
    totalExternalFiatCentsPrice: 540,
    totalFmcCentsPriceOriginal: 540,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:05:43.087Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:07:07.298Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:07:23.253Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:21:43.631Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:21:43.632Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:22:37.514Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:41:34.938Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:05:43.087Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:05:55.990Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T11:05:43.087Z",
    updatedAt: "2024-10-24T11:41:35.269Z",
    fleetInfo: null,
  },
  {
    id: "671a29e7e79c0dba90890ed6",
    sid: "241024-612501",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a29e6e79c0dba90890ecb",
    firstName: "Duna Alkandari",
    lastName: null,
    phoneNumber: "+96560343446",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6713cdeab58bc473083931e4",
    delivery: {
      id: "671a29e6e79c0dba90890ecb",
      customerId: "6713cdeab58bc473083931e4",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6714be18b7a34cd09243dc9c",
        name: "Work",
        lng: 47.96986545879249,
        lat: 29.246841430664062,
        city: "Al-Dajeej",
        area: "Al-Dajeej",
        block: "Airport ",
        street: "0",
        building: "Project department",
        floor: "1",
        flat: "",
        phone: "+96560343446",
        additionalInfo: "Call when arrive",
        isSelected: true,
        createdAt: "2024-10-20T08:23:52.594Z",
        updatedAt: "2024-10-24T11:02:47.904Z",
      },
      deliverectValidationId: "671a29e6e8f9aa549af905c2",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:15:10.625Z",
      estimatedDeliveryTime: "2024-10-24T11:30:09.434Z",
      estimatedDeliveryTimeEnd: "2024-10-24T12:02:00.000Z",
      orderId: "671a29e7e79c0dba90890ed6",
      createdAt: "2024-10-24T11:05:10.747Z",
      updatedAt: "2024-10-24T11:07:06.381Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d10",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d72",
        productPLU: "2202050-1",
        productDescription:
          "C 27g | P 1.5g | F 0g\nOrange \n\nGood source of Vitamin C",
        productName: "Orange Citrus",
        imageURL:
          "https://resizer.deliverect.com/92Cgq5w07iSWvqOzcojq0Egx0BDIxsVNTfIhlYhtUmU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTMtNjY1OTE5ODQ0MjllZTgwN2Q2OWQxYmZkLnBuZw==",
        productCalories: 116,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 1.5,
          fat: null,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 340,
    totalExternalFiatCentsPrice: 340,
    totalFmcCentsPriceOriginal: 340,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:05:11.405Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:07:09.086Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:09:38.547Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:19:05.907Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:19:05.908Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:19:31.815Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:32:40.344Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:05:11.405Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:05:22.906Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:05:11.405Z",
    updatedAt: "2024-10-24T11:32:40.683Z",
    fleetInfo: null,
  },
  {
    id: "671a290de79c0dba9088b400",
    sid: "241024-760563",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a290ce79c0dba9088b3d6",
    firstName: "Mariam alwazzan",
    lastName: null,
    phoneNumber: "+96599677712",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "656f52648504d7b50d803d37",
    delivery: {
      id: "671a290ce79c0dba9088b3d6",
      customerId: "656f52648504d7b50d803d37",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "656f52f2937c260d0fbfb427",
        name: "Home",
        lng: 47.99466896724027,
        lat: 29.362335205078125,
        city: "Kuwait",
        area: "Mansouriya",
        block: "1",
        street: "11",
        building: "7",
        floor: "",
        flat: "",
        phone: "+96599677712",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-05T16:42:26.504Z",
        updatedAt: "2024-10-22T10:42:35.345Z",
      },
      deliverectValidationId: "671a290ceb2dd7730219be37",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:11:32.638Z",
      estimatedDeliveryTime: "2024-10-24T11:26:31.389Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:58:00.000Z",
      orderId: "671a290de79c0dba9088b400",
      createdAt: "2024-10-24T11:01:32.740Z",
      updatedAt: "2024-10-24T11:03:12.586Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823e90",
        productPLU: "2162134-1",
        productDescription:
          "C 33g | P 22g | F 19g [%New%]\n\nSesame bun, chicken patty, mayo sauce mix, spicy coleslaw, and cheddar cheese.",
        productName: "Spicy slaw chicken burger",
        imageURL:
          "https://resizer.deliverect.com/ocLKlrRu1U60c57669Upgx0hkVW30a4Pd8p0JRqT0as/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BpY3klMjBzbGF3JTIwY2hpY2tlbiUyMGJ1cmdlci02NzEzNTA3YmJhNmM3ZTllODMyZWQzYmQucG5n",
        productCalories: 391,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 22,
          fat: 19,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823f03",
        productPLU: "2166020-1",
        productDescription:
          "C 13g| P 2g| F 1g\nBeetroots tossed in olive oil, lemon juice and fresh coriander.\n\nGood source of dietary Fibre, Iron, Potassium and Vitamin C\nContains garlic.",
        productName: "Beetroot Pot",
        imageURL:
          "https://resizer.deliverect.com/4QQmAm_YErxn11xFj_N8IRMBlHt61yeQWJR1VR9vwug/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNy02NTEwMzhmNDI2N2RiNTQwNTI2ZTM4MTguanBn",
        productCalories: 70,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 2,
          fat: 1,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:01:33.448Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:03:15.149Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:03:47.625Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:14:16.743Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:14:16.743Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:14:44.865Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:31:53.773Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:01:33.448Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:01:46.139Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:01:33.448Z",
    updatedAt: "2024-10-24T11:31:54.222Z",
    fleetInfo: null,
  },
  {
    id: "671a28fae79c0dba9088ac72",
    sid: "241024-107188",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a28fae79c0dba9088ac66",
    firstName: "Yousef alarrouj",
    lastName: null,
    phoneNumber: "+96569977622",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66eaa3acaa1645e3a522c8f1",
    delivery: {
      id: "671a28fae79c0dba9088ac66",
      customerId: "66eaa3acaa1645e3a522c8f1",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66eaa462ced6c5247ebc59f1",
        name: "Home",
        lng: 47.89025153964758,
        lat: 29.306392793353556,
        city: "Andalous",
        area: "Andalous",
        block: "Block 12 ",
        street: "15 St",
        building: "24",
        floor: "0",
        flat: "",
        phone: "+96569977622",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-18T09:58:58.840Z",
        updatedAt: "2024-10-24T11:00:02.844Z",
      },
      deliverectValidationId: "671a28f9c47d7273b3c14aac",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:11:13.926Z",
      estimatedDeliveryTime: "2024-10-24T11:26:12.572Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:58:00.000Z",
      orderId: "671a28fae79c0dba9088ac72",
      createdAt: "2024-10-24T11:01:14.061Z",
      updatedAt: "2024-10-24T11:03:06.051Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825cf3",
        productPLU: "2162112-1",
        productDescription:
          "C 65g | P 21g | F 28g[%Popular%]\nFM burger with baked sweet potato fries and bbq sauce",
        productName: "FM burger & fries",
        imageURL:
          "https://resizer.deliverect.com/RmA60bYBZskDxWGZ3uPWH1NZAH_rxnDkyiAlnyV_l1o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBidXJnZXIlMjBjb21iby02NzEzNTc5NTQ4OGM3MjkyNjAyMTliNWYucG5n",
        productCalories: 596,
        quantity: 1,
        nutritionInfo: {
          carbs: 65,
          protein: 21,
          fat: 28,
        },
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 370,
    totalExternalFiatCentsPrice: 370,
    totalFmcCentsPriceOriginal: 370,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:01:14.716Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:03:15.006Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:03:20.848Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:16:55.043Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:16:55.043Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:19:53.852Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:45:43.904Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:01:14.716Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:01:26.287Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T11:01:14.716Z",
    updatedAt: "2024-10-24T11:45:44.538Z",
    fleetInfo: null,
  },
  {
    id: "671a28b9e79c0dba90888b29",
    sid: "241024-444418",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a28b6e79c0dba90888a09",
    firstName: "Taibah Ahmad",
    lastName: null,
    phoneNumber: "+96566356656",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "652670b61bfa57c5dbf49637",
    delivery: {
      id: "671a28b6e79c0dba90888a09",
      customerId: "652670b61bfa57c5dbf49637",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66bc78d63e27c431ef46d82d",
        name: "Home",
        lng: 47.88862980902195,
        lat: 29.30264724904058,
        city: "Andalous",
        area: "Andalous",
        block: "10",
        street: "106",
        building: "35",
        floor: "Ground floor",
        flat: "",
        phone: "+96566356656",
        additionalInfo: "2nd house near roundabout gray house ",
        isSelected: true,
        createdAt: "2024-08-14T09:28:54.629Z",
        updatedAt: "2024-10-24T10:54:03.575Z",
      },
      deliverectValidationId: "671a28b46ef78a7622ffc459",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:10:04.835Z",
      estimatedDeliveryTime: "2024-10-24T11:25:02.878Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:57:00.000Z",
      orderId: "671a28b9e79c0dba90888b29",
      createdAt: "2024-10-24T11:00:06.265Z",
      updatedAt: "2024-10-24T11:02:05.058Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d0c",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:00:09.324Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:02:05.224Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:03:03.802Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:16:43.903Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:16:43.903Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:19:42.991Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:40:43.082Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T11:00:09.324Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:00:22.860Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T11:00:09.324Z",
    updatedAt: "2024-10-24T11:40:43.424Z",
    fleetInfo: null,
  },
  {
    id: "671a28aee79c0dba908886d5",
    sid: "241024-051244",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a28aee79c0dba908886cd",
    firstName: "Khaled alhussain",
    lastName: null,
    phoneNumber: "+96598888967",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c7b4d675a6aa442d10c5b0",
    delivery: {
      id: "671a28aee79c0dba908886cd",
      customerId: "64c7b4d675a6aa442d10c5b0",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "651b8f09f3f5a7e1daf3721f",
        name: "Home",
        lng: 47.957915626466274,
        lat: 29.326831517256963,
        city: "Kuwait City",
        area: "Khaldiya",
        block: "1",
        street: "13",
        building: "33",
        floor: "1",
        flat: "",
        phone: "+96598888967",
        additionalInfo: "Corner house",
        isSelected: true,
        createdAt: "2023-10-03T03:48:25.305Z",
        updatedAt: "2024-10-21T15:04:59.189Z",
      },
      deliverectValidationId: "671a28adb63263b7f63a332f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:09:57.964Z",
      estimatedDeliveryTime: "2024-10-24T11:24:56.931Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:56:00.000Z",
      orderId: "671a28aee79c0dba908886d5",
      createdAt: "2024-10-24T10:59:58.055Z",
      updatedAt: "2024-10-24T11:01:13.338Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825cdf",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:59:58.817Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:01:10.814Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:03:14.816Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:16:33.660Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:16:33.660Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:20:26.947Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:59:58.817Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T11:00:10.632Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:59:58.817Z",
    updatedAt: "2024-10-24T11:20:27.098Z",
    fleetInfo: null,
  },
  {
    id: "671a289ee79c0dba90887f5e",
    sid: "241024-770433",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a289de79c0dba90887f45",
    firstName: "Maryam alawadhi",
    lastName: null,
    phoneNumber: "+96567757570",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65192a4bc6da5aa4aee3aeb6",
    delivery: {
      id: "671a289de79c0dba90887f45",
      customerId: "65192a4bc6da5aa4aee3aeb6",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "653e3823289c08cbdbc929de",
        name: "My home",
        lng: 48.009105679999976,
        lat: 29.287750244140625,
        city: "Kuwait",
        area: "Hateen",
        block: "2",
        street: "230 abdulmohsen alzabin",
        building: "554",
        floor: "Basement ",
        flat: "None",
        phone: "+96567757570",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-29T10:46:59.563Z",
        updatedAt: "2024-10-23T14:30:46.672Z",
      },
      deliverectValidationId: "671a289dc581fc4bf11d38df",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:09:41.799Z",
      estimatedDeliveryTime: "2024-10-24T11:24:40.615Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:56:00.000Z",
      orderId: "671a289ee79c0dba90887f5e",
      createdAt: "2024-10-24T10:59:41.906Z",
      updatedAt: "2024-10-24T11:01:12.736Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d29",
        productPLU: "2162106-1",
        productDescription:
          "C 31g | P 7g | F 18g\nCorn, shishito pepper, jalapeno, coriander, feta cheese, red onion, shishito salad dressing\n\nGood source of Vitamins ",
        productName: "Shishito Corn Salad",
        imageURL:
          "https://resizer.deliverect.com/6rdXnPFXcSncZOuJkUg1u6lTeZ62zbWaCh8VKxZvZJw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCUyQiUyMHJlc2l6ZWQtNjY0NGFmYWQ1YzZkZWYwMWMzM2MzYzE1LnBuZw==",
        productCalories: 314,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 7,
          fat: 18,
        },
        fmcCentsPrice: 350,
        fiatCentsPrice: 350,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825ce6",
        productPLU: "2162126-1",
        productDescription:
          "C 47g | P 30g | F 19g\nRice, Grilled Salmon, Coconut Curry, Cherry Tomato, Coriander Leaves\n\nGood source of Protein & Vitamin B12",
        productName: "Coconut Salmon Curry",
        imageURL:
          "https://resizer.deliverect.com/RGhcodNiC9p5cDsaZeSeiR0MrQFl28w5nWBhlmGSMsI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY29jb251dCUyMHNhbG1vbiUyMGN1cnJ5LTY2YjUzNjA5NDAxOTZkZGUyZjg5MjliNC5wbmc=",
        productCalories: 479,
        quantity: 1,
        nutritionInfo: {
          carbs: 47,
          protein: 30,
          fat: 19,
        },
        fmcCentsPrice: 490,
        fiatCentsPrice: 490,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 840,
    totalFmcCentsPrice: 840,
    totalFiatCentsPriceOriginal: 840,
    totalExternalFiatCentsPrice: 840,
    totalFmcCentsPriceOriginal: 840,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:59:42.866Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:01:44.283Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:02:41.339Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:02:52.954Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:02:52.954Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:15:08.329Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:47:16.742Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:59:42.866Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:59:55.069Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:59:42.867Z",
    updatedAt: "2024-10-24T11:47:17.085Z",
    fleetInfo: null,
  },
  {
    id: "671a287ee79c0dba90887107",
    sid: "241024-991717",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a287ce79c0dba9088700c",
    firstName: "Ali ibrahem reda",
    lastName: null,
    phoneNumber: "+96590999412",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "665323ea47e7155f92711e76",
    delivery: {
      id: "671a287ce79c0dba9088700c",
      customerId: "665323ea47e7155f92711e76",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "665325aede3f2e06c6617450",
        name: "House",
        lng: 48.081612065434456,
        lat: 29.207499937280176,
        city: "Kuwait",
        area: "Al-Qurain District",
        block: "1",
        street: "39",
        building: "House 3",
        floor: "-",
        flat: "-",
        phone: "+96590999412",
        additionalInfo: "Secound house on the right",
        isSelected: true,
        createdAt: "2024-05-26T12:06:06.847Z",
        updatedAt: "2024-05-26T12:06:06.847Z",
      },
      deliverectValidationId: "671a287c9d15dbbf9fb152b9",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:09:08.284Z",
      estimatedDeliveryTime: "2024-10-24T11:24:06.974Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:56:00.000Z",
      orderId: "671a287ee79c0dba90887107",
      createdAt: "2024-10-24T10:59:08.660Z",
      updatedAt: "2024-10-24T11:01:06.512Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e46",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824e87",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 260,
    totalFmcCentsPrice: 260,
    totalFiatCentsPriceOriginal: 260,
    totalExternalFiatCentsPrice: 260,
    totalFmcCentsPriceOriginal: 260,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:59:10.393Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T11:01:05.954Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:01:44.203Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:17:30.270Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:17:30.270Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:17:52.133Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:40:11.403Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:59:10.393Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:59:13.655Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:59:10.393Z",
    updatedAt: "2024-10-24T11:40:11.749Z",
    fleetInfo: null,
  },
  {
    id: "671a27dce79c0dba90883687",
    sid: "241024-992323",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a27dbe79c0dba9088361a",
    firstName: "Bader Alsumait",
    lastName: null,
    phoneNumber: "+96597333122",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c816b575a6aa442d117494",
    delivery: {
      id: "671a27dbe79c0dba9088361a",
      customerId: "64c816b575a6aa442d117494",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "64cf5c3b5e8f4fe1fb18c50b",
        name: "International Islamic Charitable Organization",
        lng: 48.01837932318449,
        lat: 29.27226832807635,
        city: "Kuwait",
        area: "Ministries Zone",
        block: "0",
        street: "Ali Abdulwahab Almutawa",
        building: "International Islamic Charitable Organization Head Office",
        floor: "Third Floor",
        flat: "General Manager Office",
        phone: "+96597333122",
        additionalInfo:
          "Please deliver to security officer at the gate, and tell him to deliver to General Manager.",
        isSelected: true,
        createdAt: "2023-08-06T08:39:23.401Z",
        updatedAt: "2024-10-22T08:49:47.475Z",
      },
      deliverectValidationId: "671a27db7b41664b99a55fa4",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:06:27.438Z",
      estimatedDeliveryTime: "2024-10-24T11:21:26.097Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:53:00.000Z",
      orderId: "671a27dce79c0dba90883687",
      createdAt: "2024-10-24T10:56:27.723Z",
      updatedAt: "2024-10-24T10:58:05.658Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825ce3",
        productPLU: "2162130-1",
        productDescription:
          "C 40g | P 19g | F 16g\n\nSlider bun, patty, cheddar cheese, iceberg lettuce, white onion, dill pickle chips, mayo ketchup sauce.",
        productName: "FM Sliders",
        imageURL:
          "https://resizer.deliverect.com/gYXzCSrBPdFooHt3S19xzbSh9205wL6ziwlFQHMhLTI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBzbGlkZXIoMSktNjZmNTZmM2ZlNjQyNjQ5MzFhZTYwMDMwLnBuZw==",
        productCalories: 380,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d45",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d70",
        productPLU: "2202055-1",
        productDescription: "C 0g | P 0g | F 0g ",
        productName: "Celsius Cola",
        imageURL:
          "https://resizer.deliverect.com/BY78vNMNBCBYUUYrwhumRcBaZ__owWkEHv-C0kvAh5w/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2Vsc2l1cyUyMGNvbGEtNjY2YjJlZDVlYTA2OTU5ODUyYzNiYTYxLnBuZw==",
        productCalories: 10,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 460,
    totalFmcCentsPrice: 460,
    totalFiatCentsPriceOriginal: 460,
    totalExternalFiatCentsPrice: 460,
    totalFmcCentsPriceOriginal: 460,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:56:28.483Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:58:05.542Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:02:41.516Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:10:23.289Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:10:23.289Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:10:45.793Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:39:00.343Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:56:28.483Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:56:41.655Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:56:28.483Z",
    updatedAt: "2024-10-24T11:39:00.683Z",
    fleetInfo: null,
  },
  {
    id: "671a2743e79c0dba9087f517",
    sid: "241024-024435",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2742e79c0dba9087f4e1",
    firstName: "Dalal Alhulail",
    lastName: null,
    phoneNumber: "+96599997226",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "658450e4e8af429bc67ef0b4",
    delivery: {
      id: "671a2742e79c0dba9087f4e1",
      customerId: "658450e4e8af429bc67ef0b4",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65899eb983e8b6e331dd60c7",
        name: "Faiha home",
        lng: 47.97363739460707,
        lat: 29.341102997842874,
        city: "Kuwait City",
        area: "Faiha",
        block: "1",
        street: "16",
        building: "10",
        floor: "",
        flat: "",
        phone: "+96599997226",
        additionalInfo: "Side door",
        isSelected: true,
        createdAt: "2023-12-25T15:24:41.309Z",
        updatedAt: "2024-01-30T11:35:17.691Z",
      },
      deliverectValidationId: "671a27429b0c83c35d5908ac",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:03:54.434Z",
      estimatedDeliveryTime: "2024-10-24T11:18:53.343Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:50:00.000Z",
      orderId: "671a2743e79c0dba9087f517",
      createdAt: "2024-10-24T10:53:54.539Z",
      updatedAt: "2024-10-24T10:55:09.609Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823ea1",
        productPLU: "2162108-1",
        productDescription:
          "C 20g | P 39g | F 28g\nSesame chicken with snow peas topped with roasted white sesame served with veggie bulgur rice.\n\nGood source of Protein & Vitamin C",
        productName: "Sesame chicken & snow peas",
        imageURL:
          "https://resizer.deliverect.com/yyleY01jv8RuFpYLVQ0J9ADHIJK5V3bCte9f4aRy4cE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU2VzYW1lJTIwY2hpY2tlbiUyMCUyNiUyMHBlYXMtNjY0NGIwOWUzZmNiOWNjYjY2MmQ4NTVlLnBuZw==",
        productCalories: 488,
        quantity: 1,
        nutritionInfo: {
          carbs: 20,
          protein: 39,
          fat: 28,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ee2",
        productPLU: "2166026-1",
        productDescription:
          "C 13g| P 6g| F 9g\nKale, cabbage, carrots, onions, orange slices & cashews topped with sesame seeds & drizzled with garlic ginger peanut dressing.\n\nGOOD SOURCE OF VITAMIN & VITAMIN C",
        productName: "Mini Thai Slaw",
        imageURL:
          "https://resizer.deliverect.com/89E9zE4JuS-ammKNFUesPBA8SiE1FYMyZUmczLAr-Gg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMHRoYWklMjBzbGF3LTY1MDQ5NDYxOGVjNDM1MDAxYTRjMTQ2My5qcGc=",
        productCalories: 157,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 6,
          fat: 9,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:53:55.480Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:55:14.079Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:55:27.582Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:03:50.016Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:03:50.016Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:23:10.909Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:43:01.770Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:53:55.480Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:54:09.160Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:53:55.480Z",
    updatedAt: "2024-10-24T11:43:02.109Z",
    fleetInfo: null,
  },
  {
    id: "671a2722e79c0dba9087f0f3",
    sid: "241024-401158",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2720e79c0dba9087ef01",
    firstName: "Lulwa Alajeel",
    lastName: null,
    phoneNumber: "+96560017070",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66d780eeaa1645e3a5fcedf2",
    delivery: {
      id: "671a2720e79c0dba9087ef01",
      customerId: "66d780eeaa1645e3a5fcedf2",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670e4662f495b693cdb2cfe2",
        name: "Lulwa Alajeel",
        lng: 47.97772709280252,
        lat: 29.350386703859265,
        city: "Kuwait",
        area: "Abdullah al-Salem",
        block: "4",
        street: "45",
        building: "9",
        floor: "",
        flat: "",
        phone: "+96560017070",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-15T10:39:30.178Z",
        updatedAt: "2024-10-16T11:03:00.892Z",
      },
      deliverectValidationId: "671a271f1cb32e3bceb570a7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:03:19.902Z",
      estimatedDeliveryTime: "2024-10-24T11:18:18.880Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:50:00.000Z",
      orderId: "671a2722e79c0dba9087f0f3",
      createdAt: "2024-10-24T10:53:20.130Z",
      updatedAt: "2024-10-24T10:55:05.517Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823ea9",
        productPLU: "2162100-1",
        productDescription:
          "C 21g | P 43g | F 14g\nGrilled peri peri chicken served with carrots, broccoli, mashed cauliflower, mashed potatoes & peri peri sauce.\n\nGood source of Protein, Vitamin A & C",
        productName: "Peri Peri Chicken",
        imageURL:
          "https://resizer.deliverect.com/AknH0sQlCRFCVD-wgAZApwhopxSIbFs4YPWXQoH9G88/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUEVSSSUyMFBFUkklMjBDSElDS0VOJTIwKDEpLTY2NDRiYzhhM2NiZjdhMzY3ZmI0YTI2MS5qcGc=",
        productCalories: 382,
        quantity: 1,
        nutritionInfo: {
          carbs: 21,
          protein: 43,
          fat: 14,
        },
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 310,
    totalFmcCentsPrice: 310,
    totalFiatCentsPriceOriginal: 310,
    totalExternalFiatCentsPrice: 310,
    totalFmcCentsPriceOriginal: 310,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:53:22.133Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:55:13.230Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:55:32.438Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:03:59.234Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:03:59.235Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:22:52.305Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:35:52.961Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:53:22.133Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:53:35.829Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T10:53:22.133Z",
    updatedAt: "2024-10-24T11:35:53.294Z",
    fleetInfo: null,
  },
  {
    id: "671a2718e79c0dba9087e94c",
    sid: "241024-701106",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2716e79c0dba9087e86c",
    firstName: "Hania AlThekair",
    lastName: null,
    phoneNumber: "+96594021116",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65104175e1d91353418d1a81",
    delivery: {
      id: "671a2716e79c0dba9087e86c",
      customerId: "65104175e1d91353418d1a81",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6658575793dc7e98b43f9d9f",
        name: "dhahiyah ",
        lng: 47.97484740614891,
        lat: 29.345008081919268,
        city: "Kuwait",
        area: "Abdullah al-Salem",
        block: "4",
        street: "43",
        building: "9",
        floor: "",
        flat: "",
        phone: "+96594021116",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-30T10:39:19.732Z",
        updatedAt: "2024-10-24T10:51:55.787Z",
      },
      deliverectValidationId: "671a27168ee7d1c25657d231",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:03:10.236Z",
      estimatedDeliveryTime: "2024-10-24T11:18:09.228Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:50:00.000Z",
      orderId: "671a2718e79c0dba9087e94c",
      createdAt: "2024-10-24T10:53:10.428Z",
      updatedAt: "2024-10-24T10:55:05.398Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823eb3",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671a1ab4e79c0dba90823f4b",
            productPLU: "1907003-1",
            productName: "No Cheddar Cheese",
            productDescription: "C 0g| P -2g| F -3g| cal -40| coins 0\n\n",
            productCalories: -40,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: -2,
              fat: -3,
            },
          },
        ],
      },
      {
        productId: "671a1ab4e79c0dba90823ef5",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:53:12.330Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:55:07.774Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:55:30.877Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:03:45.007Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:03:45.007Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:04:57.860Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:21:57.814Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:53:12.330Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:53:24.919Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:53:12.330Z",
    updatedAt: "2024-10-24T11:21:58.659Z",
    fleetInfo: null,
  },
  {
    id: "671a26ace79c0dba9087c0c9",
    sid: "241024-330622",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a26aae79c0dba9087bf63",
    firstName: "Jana Abdullah ",
    lastName: null,
    phoneNumber: "+96598741100",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66ef06597f0597f029dbe01f",
    delivery: {
      id: "671a26aae79c0dba9087bf63",
      customerId: "66ef06597f0597f029dbe01f",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66ef06d21294dc02caf2d0f7",
        name: "Home",
        lng: 48.04337019798266,
        lat: 29.2762451171875,
        city: "Mubarak Al-Abdullah",
        area: "Mubarak Al-Abdullah",
        block: "3",
        street: "312",
        building: "4",
        floor: "",
        flat: "",
        phone: "+96598741100",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-21T17:48:02.784Z",
        updatedAt: "2024-09-21T17:48:02.784Z",
      },
      deliverectValidationId: "671a26aa7b41664b99a46e32",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:01:22.750Z",
      estimatedDeliveryTime: "2024-10-24T11:16:21.098Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:48:00.000Z",
      orderId: "671a26ace79c0dba9087c0c9",
      createdAt: "2024-10-24T10:51:22.860Z",
      updatedAt: "2024-10-24T10:53:04.968Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824673",
        productPLU: "2167023-1",
        productDescription:
          "C 29g | P 22g | F 16g\nScrambled eggs with mushroom, green olives and feta cheese in a tortilla wrap\n\nGOOD SOURCE OF PROTEIN, MINERALS",
        productName: "Mushroom egg wrap",
        imageURL:
          "https://resizer.deliverect.com/icCk6VyiEI1LioDbIHa0TjQikuisfIyU0FZUoDvsxdY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAyLTY2M2ZmM2Q0ZGI0ZjMyZjZhNTZmMGY4MC5wbmc=",
        productCalories: 348,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 22,
          fat: 16,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba908246e1",
        productPLU: "2202040-1",
        productDescription: "C 4.8g | P 0g | F 0g\nOrange, Grapefruit & Lemon",
        productName: "Immunity Shot",
        imageURL:
          "https://resizer.deliverect.com/WkO7ZBCzCZBVTRau0FCMoAizLyoqJmikHQl5iJRWhJs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaW1tdW5pdHklMjBzaG90LTY2NDFkYjEwYWIxMzE0ZDg1Njc3OGNkZS5wbmc=",
        productCalories: 19,
        quantity: 1,
        nutritionInfo: {
          carbs: 4.8,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:51:24.798Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:53:03.761Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:53:48.262Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:07:35.843Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:07:35.844Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:07:59.736Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:36:56.145Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:51:24.798Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:51:36.373Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:51:24.798Z",
    updatedAt: "2024-10-24T11:36:56.484Z",
    fleetInfo: null,
  },
  {
    id: "671a266ce79c0dba9087a4b6",
    sid: "241024-003165",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a266ce79c0dba9087a4a9",
    firstName: "Talal Bukhamseen",
    lastName: null,
    phoneNumber: "+96566668660",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "657567947a1e4e4900edaf9b",
    delivery: {
      id: "671a266ce79c0dba9087a4a9",
      customerId: "657567947a1e4e4900edaf9b",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65756aaff093f32458049243",
        name: "Home Salwa",
        lng: 48.085029534995556,
        lat: 29.292997203272318,
        city: "Kuwait",
        area: "Salwa",
        block: "9",
        street: "1 St",
        building: "82B",
        floor: "",
        flat: "",
        phone: "+96566668660",
        additionalInfo:
          "Front side (sea side) enter from sand area or btween houses.",
        isSelected: true,
        createdAt: "2023-12-10T07:37:19.962Z",
        updatedAt: "2024-10-24T10:49:06.494Z",
      },
      deliverectValidationId: "671a266bcc78686e3f468df5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T11:00:19.972Z",
      estimatedDeliveryTime: "2024-10-24T11:15:18.628Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:47:00.000Z",
      orderId: "671a266ce79c0dba9087a4b6",
      createdAt: "2024-10-24T10:50:20.070Z",
      updatedAt: "2024-10-24T10:52:06.442Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba9082463c",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab9e79c0dba90824689",
        productPLU: "2163016-1",
        productDescription:
          "C 35g| P 6g| F 10g\nRocca mixed with grilled zucchini, grilled red bell peppers & grilled corn drizzled in a vinaigrette dressing with coriander.\n\nGOOD SOURCE OF FIBER",
        productName: "Rocca Feta Salad",
        imageURL:
          "https://resizer.deliverect.com/hVjV_VJZKJD7LeDzKwVSVGem1rTZivC1K9bQFd7KnCs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjBTQUxBRC02NTE3ZWUwNjM5NGU3ODMxZTFiZDNiNWEuanBn",
        productCalories: 254,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 6,
          fat: 10,
        },
        fmcCentsPrice: 350,
        fiatCentsPrice: 350,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 630,
    totalFmcCentsPrice: 630,
    totalFiatCentsPriceOriginal: 630,
    totalExternalFiatCentsPrice: 630,
    totalFmcCentsPriceOriginal: 630,
    orderStatus: "in_delivery",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:50:20.763Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:52:05.873Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:53:44.171Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:15:44.302Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:15:44.302Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:16:07.562Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:50:20.763Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:50:32.565Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:50:20.764Z",
    updatedAt: "2024-10-24T11:16:43.814Z",
    fleetInfo: null,
  },
  {
    id: "671a262ae79c0dba90878c4e",
    sid: "241024-180883",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2629e79c0dba90878c17",
    firstName: "Salma alkhulaifi",
    lastName: null,
    phoneNumber: "+96566657540",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64ca094175a6aa442d12ca5c",
    delivery: {
      id: "671a2629e79c0dba90878c17",
      customerId: "64ca094175a6aa442d12ca5c",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64f74ccf2b0eec3968b1ba45",
        name: "Salma khalid",
        lng: 48.002224043011665,
        lat: 29.27313541992589,
        city: "Zahra",
        area: "Zahra",
        block: "4",
        street: "Sulaiman Ibraheem Al Musallam St/ st 421",
        building: "72",
        floor: "",
        flat: "",
        phone: "+96566657540",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-09-05T15:44:15.201Z",
        updatedAt: "2024-10-12T16:35:53.305Z",
      },
      deliverectValidationId: "671a26294f3524be78f3f206",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T10:59:13.012Z",
      estimatedDeliveryTime: "2024-10-24T11:14:11.771Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:46:00.000Z",
      orderId: "671a262ae79c0dba90878c4e",
      createdAt: "2024-10-24T10:49:13.107Z",
      updatedAt: "2024-10-24T10:51:08.317Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825cdf",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "No turkey bacon",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d1e",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 24,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "No pickles",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d45",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 440,
    totalFmcCentsPrice: 440,
    totalFiatCentsPriceOriginal: 440,
    totalExternalFiatCentsPrice: 440,
    totalFmcCentsPriceOriginal: 440,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:49:14.451Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:51:05.087Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:03:14.925Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:09:27.277Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:09:27.278Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:11:11.781Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:26:59.886Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:49:14.451Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:49:26.034Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T10:49:14.451Z",
    updatedAt: "2024-10-24T11:27:00.285Z",
    fleetInfo: null,
  },
  {
    id: "671a25b3e79c0dba90877ea5",
    sid: "241024-906535",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a25b2e79c0dba90877e98",
    firstName: "Fatma",
    lastName: null,
    phoneNumber: "+96590986496",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "656856c646956c2566bafd3a",
    delivery: {
      id: "671a25b2e79c0dba90877e98",
      customerId: "656856c646956c2566bafd3a",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66557dcbecc6fd38a6208436",
        name: "Home",
        lng: 48.0952394,
        lat: 29.2207571,
        city: "Fnaitees",
        area: "Fnaitees",
        block: "7",
        street: "706",
        building: "House 78",
        floor: "",
        flat: "",
        phone: "+96590986496",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-28T06:46:35.325Z",
        updatedAt: "2024-08-03T15:28:04.261Z",
      },
      deliverectValidationId: "671a25b27aed98372747c194",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T10:57:14.895Z",
      estimatedDeliveryTime: "2024-10-24T11:12:13.632Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:44:00.000Z",
      orderId: "671a25b3e79c0dba90877ea5",
      createdAt: "2024-10-24T10:47:14.987Z",
      updatedAt: "2024-10-24T10:49:06.612Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e21",
        productPLU: "2162133-1",
        productDescription:
          "C 31g | P 26g | F 23g [%New%]\n\nSesame bun, chicken patty with mayo sauce mix, turkey bacon, cheddar cheese, iceberg lettuce.",
        productName: "Chicken & turkey bacon burger",
        imageURL:
          "https://resizer.deliverect.com/Fs_oiyZbqbAhfUYuat4OFEG6YWhsDJmSb25iwPY9iXQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMCUyNiUyMHR1a2V5JTIwYmFjb24lMjBidXJnZXItNjcxMzUwMTA0ODhjNzI5MjYwMjE5YjRjLnBuZw==",
        productCalories: 435,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:47:15.597Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:49:06.719Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:01:09.035Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:01:12.094Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:01:12.094Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:05:47.820Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:26:47.354Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:47:15.597Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:47:27.095Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:47:15.597Z",
    updatedAt: "2024-10-24T11:26:47.691Z",
    fleetInfo: null,
  },
  {
    id: "671a25a6e79c0dba90877b70",
    sid: "241024-033652",
    coupons: [],
    menuId: "671a1abfe79c0dba90824dc1",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671a25a5e79c0dba90877b65",
    firstName: "Weaam Alabdullah",
    lastName: null,
    phoneNumber: "+96597377384",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64b95043b38e44d254c2f2d4",
    delivery: {
      id: "671a25a5e79c0dba90877b65",
      customerId: "64b95043b38e44d254c2f2d4",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6682ac6b09647a7c33664195",
        name: "Alabdullah Home",
        lng: 48.05889945477247,
        lat: 29.25969333739186,
        city: "Sabah Al Salem",
        area: "Sabah Al Salem",
        block: "5",
        street: "1 St. 9 Ln",
        building: "31",
        floor: "",
        flat: "",
        phone: "+96597377384",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-01T13:17:31.871Z",
        updatedAt: "2024-07-01T13:17:31.871Z",
      },
      deliverectValidationId: "671a25a53bbe5b0ef2474925",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T10:57:01.577Z",
      estimatedDeliveryTime: "2024-10-24T11:11:59.919Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:44:00.000Z",
      orderId: "671a25a6e79c0dba90877b70",
      createdAt: "2024-10-24T10:47:01.752Z",
      updatedAt: "2024-10-24T10:49:06.610Z",
    },
    items: [
      {
        productId: "671a1abfe79c0dba90824e37",
        productPLU: "2162110-1",
        productDescription:
          "C 16g | P 39g | F 12g\nChicken Lasagna with zucchini & basil\n\nGood source of Protein, Vitamin A & B12",
        productName: "Zucchini Chicken Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SF1f4yzXFAmy7O5H5pNNhkbFAWle_8S5j0MSAJ6QIzY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGxhc2FnbmEtNjY0MzdhMWQ3YjdlNGViN2M1MjcwN2IwLmpwZw==",
        productCalories: 328,
        quantity: 1,
        nutritionInfo: {
          carbs: 16,
          protein: 39,
          fat: 12,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1abfe79c0dba90824e80",
        productPLU: "2150014-1",
        productDescription: "C 2g| P 30g| F 6g\n",
        productName: "Chicken Shawarma(100GM)",
        imageURL:
          "https://resizer.deliverect.com/5s0dpyz1hk8m1gvg8YGrM7u6yPuJzcNpjKxdUNjSi6g/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMHNoYXdhcm1hJTIwKDEpLTY0ZDc1YjZhNjg1NDg1MDAyNzQwZTNmNy5qcGc=",
        productCalories: 182,
        quantity: 1,
        nutritionInfo: {
          carbs: 2,
          protein: 30,
          fat: 6,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 390,
    totalFmcCentsPrice: 390,
    totalFiatCentsPriceOriginal: 390,
    totalExternalFiatCentsPrice: 390,
    totalFmcCentsPriceOriginal: 390,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:47:02.387Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:49:05.840Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T11:01:08.942Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:01:22.430Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:01:22.430Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:02:18.750Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:26:35.498Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:47:02.387Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:47:13.957Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:47:02.387Z",
    updatedAt: "2024-10-24T11:26:35.832Z",
    fleetInfo: null,
  },
  {
    id: "671a2561e79c0dba9087593a",
    sid: "241024-122537",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2561e79c0dba90875931",
    firstName: "haya alhamlan",
    lastName: null,
    phoneNumber: "+96565547771",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64da0a2cc5253fb1749cbb3e",
    delivery: {
      id: "671a2561e79c0dba90875931",
      customerId: "64da0a2cc5253fb1749cbb3e",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6514332b0a91a2efcd6507dd",
        name: "alhumaimeedi ",
        lng: 47.96750610775073,
        lat: 29.315536499023438,
        city: "Kuwait",
        area: "Yarmouk",
        block: "4",
        street: "3",
        building: "5",
        floor: "",
        flat: "",
        phone: "+96565547771",
        additionalInfo: "avenue 3",
        isSelected: true,
        createdAt: "2023-09-27T13:50:35.404Z",
        updatedAt: "2024-10-23T11:43:12.741Z",
      },
      deliverectValidationId: "671a25609b0c83c35d58212b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T10:55:52.923Z",
      estimatedDeliveryTime: "2024-10-24T11:10:51.854Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:42:00.000Z",
      orderId: "671a2561e79c0dba9087593a",
      createdAt: "2024-10-24T10:45:53.043Z",
      updatedAt: "2024-10-24T10:47:06.358Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d12",
        productPLU: "2162037-1",
        productDescription:
          "C 62g| P 27g| F 12.4g [%Popular%]\n\nA classic favorite made with perfectly cooked spaghetti & a hearty bolognese sauce. Topped with a sprinkle of parmesan cheese & chopped parsley & garnished with a fresh basil sprig.",
        productName: "Spaghetti Bolognese",
        imageURL:
          "https://resizer.deliverect.com/8ryC24TcKatYjKjvTqCxeYugRnVz3p47fJ-PTIB67ek/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhZ2hldHRpLTY3MTM1NWVlY2NiZDAxOGZkYzRlNGNjYy5wbmc=",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 62,
          protein: 27,
          fat: 12.4,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:45:53.596Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:47:06.755Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:48:42.611Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:01:34.797Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:01:34.797Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:01:55.298Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:24:09.469Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:45:53.596Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:46:06.212Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:45:53.597Z",
    updatedAt: "2024-10-24T11:24:09.867Z",
    fleetInfo: null,
  },
  {
    id: "671a254be79c0dba90875011",
    sid: "241024-372606",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a254ae79c0dba90875009",
    firstName: "Ali Al Sabah",
    lastName: null,
    phoneNumber: "+96599992444",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6702ac66906276458c833fa5",
    delivery: {
      id: "671a254ae79c0dba90875009",
      customerId: "6702ac66906276458c833fa5",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6702b240218a8c4af25b7dbb",
        name: "Home",
        lng: 48.00035052001476,
        lat: 29.299112547392514,
        city: "Kuwait",
        area: "Al-Siddeeq",
        block: "1",
        street: "103 St",
        building: "1",
        floor: "",
        flat: "",
        phone: "+96599992444",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-06T15:52:32.495Z",
        updatedAt: "2024-10-06T15:52:32.495Z",
      },
      deliverectValidationId: "671a254ac47d7273b3bf0456",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T10:55:30.265Z",
      estimatedDeliveryTime: "2024-10-24T11:10:28.696Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:42:00.000Z",
      orderId: "671a254be79c0dba90875011",
      createdAt: "2024-10-24T10:45:30.606Z",
      updatedAt: "2024-10-24T10:47:04.933Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825cfb",
        productPLU: "2162089-1",
        productDescription:
          "C 36g | P 43g | F 18g\nMongolian beef with green bell peppers & green onions served with bulgur veggie mix.\n\nGOOD SOURCE OF PROTEIN & VITAMINA B12& VITAMIN C",
        productName: "Mongolian Beef",
        imageURL:
          "https://resizer.deliverect.com/ZXcmtUYh2Cc-oEj8YoLx8mWIX35FnYCLywx_hPNI4_I/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNSUyMCgxKS02NjUxZGY2Yzc0OGYzNjdjMTMxNzJhZGQucG5n",
        productCalories: 478,
        quantity: 1,
        nutritionInfo: {
          carbs: 36,
          protein: 43,
          fat: 18,
        },
        fmcCentsPrice: 460,
        fiatCentsPrice: 460,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 460,
    totalFmcCentsPrice: 460,
    totalFiatCentsPriceOriginal: 460,
    totalExternalFiatCentsPrice: 460,
    totalFmcCentsPriceOriginal: 460,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:45:31.280Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:47:05.426Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:48:49.680Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:02:29.952Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:02:29.952Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:07:13.378Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:40:31.710Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:45:31.280Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:45:42.698Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:45:31.280Z",
    updatedAt: "2024-10-24T11:40:32.059Z",
    fleetInfo: null,
  },
  {
    id: "671a250ae79c0dba90873923",
    sid: "241024-764395",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a2509e79c0dba90873810",
    firstName: "Eman Aljrayed ",
    lastName: null,
    phoneNumber: "+96566693584",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66ab26c6f6ed0b2948a1d95a",
    delivery: {
      id: "671a2509e79c0dba90873810",
      customerId: "66ab26c6f6ed0b2948a1d95a",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66ac957a1d6702aca3bfea9f",
        name: "Home ",
        lng: 48.00499768941302,
        lat: 29.29217529296875,
        city: "Kuwait",
        area: "السلام",
        block: "7",
        street: "720",
        building: "26",
        floor: "2",
        flat: "1",
        phone: "+96566693584",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-02T08:14:50.384Z",
        updatedAt: "2024-08-02T08:14:50.384Z",
      },
      deliverectValidationId: "671a25098ee7d1c25656e71e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T10:54:25.144Z",
      estimatedDeliveryTime: "2024-10-24T11:09:23.461Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:41:00.000Z",
      orderId: "671a250ae79c0dba90873923",
      createdAt: "2024-10-24T10:44:25.273Z",
      updatedAt: "2024-10-24T10:46:10.760Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825d12",
        productPLU: "2162037-1",
        productDescription:
          "C 62g| P 27g| F 12.4g [%Popular%]\n\nA classic favorite made with perfectly cooked spaghetti & a hearty bolognese sauce. Topped with a sprinkle of parmesan cheese & chopped parsley & garnished with a fresh basil sprig.",
        productName: "Spaghetti Bolognese",
        imageURL:
          "https://resizer.deliverect.com/8ryC24TcKatYjKjvTqCxeYugRnVz3p47fJ-PTIB67ek/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhZ2hldHRpLTY3MTM1NWVlY2NiZDAxOGZkYzRlNGNjYy5wbmc=",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 62,
          protein: 27,
          fat: 12.4,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d28",
        productPLU: "2163019-1",
        productDescription:
          "C 21g | P 6g | F 17g \nQuinoa, Cucumber, beetroot, parsley, spring onion, mint, kale,  pomegranate seeds, feta cheese and spring dressing. \n\nGood source of Vitamin C & K",
        productName: "Spring Salad",
        imageURL:
          "https://resizer.deliverect.com/Ie4UluZ6Zpy11uE4mml8eAE-iGHGSk3zhVI2_OjBREU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3ByaW5nJTIwc2FsYWQtNjY5MDY1ZTAwMjQyYTUwMWQ5ZTc4YTg2LnBuZw==",
        productCalories: 261,
        quantity: 1,
        nutritionInfo: {
          carbs: 21,
          protein: 6,
          fat: 17,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d40",
        productPLU: "2168001-1",
        productDescription:
          "C 14g | P 5g | F 1g\nRed lentil soup topped with coriander & served with a lemon wedge. ",
        productName: "Lentil Soup",
        imageURL:
          "https://resizer.deliverect.com/5EbDdbzPc4MFBEpLJbi04Urr0_H4N9CuGy35JtE6XBM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbGVudGlsJTIwc291cC02NjQxZGJjOTNjYmY3YTM2N2ZiNDhiN2IuanBn",
        productCalories: 85,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 5,
          fat: 1,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 580,
    totalFmcCentsPrice: 580,
    totalFiatCentsPriceOriginal: 580,
    totalExternalFiatCentsPrice: 580,
    totalFmcCentsPriceOriginal: 580,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:44:26.543Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:46:11.229Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:46:24.844Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:03:03.912Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:03:03.912Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:07:24.730Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:31:10.226Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:44:26.543Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:44:49.064Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:44:26.543Z",
    updatedAt: "2024-10-24T11:31:10.558Z",
    fleetInfo: null,
  },
  {
    id: "671a2509e79c0dba90873841",
    sid: "241024-942676",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a2508e79c0dba90873805",
    firstName: " Abdullah Al Dikheel",
    lastName: null,
    phoneNumber: "+96594954454",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65181c1ac6da5aa4aee2ba64",
    delivery: {
      id: "671a2508e79c0dba90873805",
      customerId: "65181c1ac6da5aa4aee2ba64",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65427331f91376d3945b1265",
        name: "Abdullah Al Dikheel house",
        lng: 47.9807545939066,
        lat: 29.357147216796875,
        city: "Kuwait",
        area: "Abdullah al-Salem",
        block: "1",
        street: "Nisf Al-Yousif St",
        building: "45",
        floor: "",
        flat: "",
        phone: "+96594954454",
        additionalInfo: "Whiter corner house",
        isSelected: true,
        createdAt: "2023-11-01T15:48:01.619Z",
        updatedAt: "2023-11-01T15:48:01.619Z",
      },
      deliverectValidationId: "671a2508b4faa71444bc73eb",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T10:54:24.572Z",
      estimatedDeliveryTime: "2024-10-24T11:09:23.465Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:41:00.000Z",
      orderId: "671a2509e79c0dba90873841",
      createdAt: "2024-10-24T10:44:24.677Z",
      updatedAt: "2024-10-24T10:46:09.350Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823ea6",
        productPLU: "2162107-1",
        productDescription:
          "C 30g | P 46g | F 9g\nGrilled zaatar chicken with saj bread and zaatar yogurt sauce. Contains coriander \n\nGood source of Protein ",
        productName: "Zaatar Chicken",
        imageURL:
          "https://resizer.deliverect.com/HLEGxWozw0ZDVfWyAN_TK_oChgkvffkzz-_bxUrGMJI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemFhdGFyJTIwY2hpY2tlbi02NjQ0YjBjMTc0NWEzOTM5ZTIxNjY5MjAucG5n",
        productCalories: 385,
        quantity: 1,
        nutritionInfo: {
          carbs: 30,
          protein: 46,
          fat: 9,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823eb1",
        productPLU: "2162008-1",
        productDescription:
          "C 40g | P 21g | F 24g [%Popular%]\nCrispy chicken with pepper jack cheese, coleslaw mix, dill pickles & a spicy mayo sauce.",
        productName: "Spicy Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/V3b1iOeX1UmC1nng8xEmFG8Y02arGnZCgSM1cET5hoo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BpY3klMjBjaGlja2VuJTIwYnVyZ2VyKDEpLTY1NTQ4ZjI5ODEyNDJlMDAxOGYwYWIyZi5wbmc=",
        productCalories: 460,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 24,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ee0",
        productPLU: "2163003-1",
        productDescription:
          "C 36g | P 22g | F 11g\nShredded chicken, mixed slaw, edamame, & crunchy toasted peanut, iceberg lettuce, mandarin, all drizzled with a peanut dressing and topped with sesame seeds with coriander.\n\nGood source of Vitamins A, K & C",
        productName: "Asian Peanut Salad",
        imageURL:
          "https://resizer.deliverect.com/humW3Ra_sNPdK2LTBHq_jLtgrnRK92UjDwqvK9r2I2o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvdGhhaSUyMHBlYW51dCUyMHNhbGFkLTY0YjdiNWI2N2U5MmVhMTFiYjcwZGExMy5wbmc=",
        productCalories: 333,
        quantity: 1,
        nutritionInfo: {
          carbs: 36,
          protein: 22,
          fat: 11,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823f0c",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 870,
    totalFmcCentsPrice: 870,
    totalFiatCentsPriceOriginal: 870,
    totalExternalFiatCentsPrice: 870,
    totalFmcCentsPriceOriginal: 870,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:44:25.566Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:46:08.324Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:50:01.158Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:02:13.698Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:02:13.698Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:04:10.309Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:16:43.804Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:44:25.566Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:44:38.085Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:44:25.566Z",
    updatedAt: "2024-10-24T11:16:44.146Z",
    fleetInfo: null,
  },
  {
    id: "671a24e9e79c0dba908720a5",
    sid: "241024-910731",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a24e8e79c0dba9087209d",
    firstName: "Sara Al Zawawi",
    lastName: null,
    phoneNumber: "+96562226226",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6580720d79c7230609b3821b",
    delivery: {
      id: "671a24e8e79c0dba9087209d",
      customerId: "6580720d79c7230609b3821b",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "659e8bc8afe41535d3b4400b",
        name: "Home",
        lng: 47.99409977819362,
        lat: 29.33197021484375,
        city: "Kuwait",
        area: "Rawda",
        block: "5",
        street: "albarudi 65 St",
        building: "16",
        floor: "-",
        flat: "-",
        phone: "+96562226226",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-10T12:21:28.046Z",
        updatedAt: "2024-09-14T09:05:05.883Z",
      },
      deliverectValidationId: "671a24e8a2eef67674fbff53",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T10:53:52.434Z",
      estimatedDeliveryTime: "2024-10-24T11:08:51.277Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:40:00.000Z",
      orderId: "671a24e9e79c0dba908720a5",
      createdAt: "2024-10-24T10:43:52.547Z",
      updatedAt: "2024-10-24T10:45:09.850Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824670",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 3,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 390,
    totalFmcCentsPrice: 390,
    totalFiatCentsPriceOriginal: 390,
    totalExternalFiatCentsPrice: 390,
    totalFmcCentsPriceOriginal: 390,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:43:53.211Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:45:06.450Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:53:50.658Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:03:25.996Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:03:25.997Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:03:47.868Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:32:20.363Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:43:53.211Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:44:05.110Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:43:53.211Z",
    updatedAt: "2024-10-24T11:32:20.704Z",
    fleetInfo: null,
  },
  {
    id: "671a24c7e79c0dba90871884",
    sid: "241024-717992",
    coupons: [],
    menuId: "671a1ab4e79c0dba90823e2b",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671a24c6e79c0dba9087186e",
    firstName: "Lama alnafisi",
    lastName: null,
    phoneNumber: "+96599765444",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "665de1a00eba0d08a5737da8",
    delivery: {
      id: "671a24c6e79c0dba9087186e",
      customerId: "665de1a00eba0d08a5737da8",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "665de2663d5f2b755ddbab69",
        name: "Home",
        lng: 47.96024311333895,
        lat: 29.334068779310662,
        city: "Kuwait",
        area: "Kaifan",
        block: "7",
        street: "73 ",
        building: "7",
        floor: "",
        flat: "",
        phone: "+96599765444",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-03T15:33:58.636Z",
        updatedAt: "2024-06-03T15:33:58.636Z",
      },
      deliverectValidationId: "671a24c66a3e6800dd3477e0",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T10:53:18.553Z",
      estimatedDeliveryTime: "2024-10-24T11:08:17.165Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:40:00.000Z",
      orderId: "671a24c7e79c0dba90871884",
      createdAt: "2024-10-24T10:43:18.654Z",
      updatedAt: "2024-10-24T10:45:05.999Z",
    },
    items: [
      {
        productId: "671a1ab4e79c0dba90823eb3",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671a1ab4e79c0dba90823f4e",
            productPLU: "1906004-1",
            productName: "Extra  Beef Pattie",
            productDescription: "C 2g| P 6g| F 3g| cal 59| coins 0.5",
            productCalories: 59,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 2,
              protein: 6,
              fat: 3,
            },
          },
        ],
      },
      {
        productId: "671a1ab4e79c0dba90823f1d",
        productPLU: "2165005-1",
        productDescription: "C 13g| P 1g| F 10g",
        productName: "Salted Pecan Bites",
        imageURL:
          "https://resizer.deliverect.com/p3z6wZFsuIaNIkw99YRPwzlHvwGLg970lPG5QDvZQrc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDgtNjRiN2JjOTE2ZjhhYmY0M2UzMWQxMjBjLnBuZw==",
        productCalories: 151,
        quantity: 2,
        nutritionInfo: {
          carbs: 13,
          protein: 1,
          fat: 10,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1ab4e79c0dba90823ed5",
        productPLU: "2163022-1",
        productDescription:
          "C 15g | P 2g | F 14g [%New%]\n\nVeggies with fattoush dressing & croutons.\n\nGood source of Vitamins",
        productName: "Mini Fattoush",
        imageURL:
          "https://resizer.deliverect.com/QUcv0CxdaaAXW0GlMNB1GAQ5fahshLaeuOoMIRAFR5c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGZhdHRvdXNoJTIwc2FsYWQtNjcxMzUxNmViYTZjN2U5ZTgzMmVkM2MyLnBuZw==",
        productCalories: 194,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 2,
          fat: 14,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 570,
    totalFmcCentsPrice: 570,
    totalFiatCentsPriceOriginal: 570,
    totalExternalFiatCentsPrice: 570,
    totalFmcCentsPriceOriginal: 570,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:43:19.412Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:45:06.252Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:45:21.562Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T10:54:54.443Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T10:54:54.443Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:01:34.362Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:18:14.827Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:43:19.412Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:43:30.972Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:43:19.412Z",
    updatedAt: "2024-10-24T11:18:15.170Z",
    fleetInfo: null,
  },
  {
    id: "671a248ae79c0dba90870b57",
    sid: "241024-052309",
    coupons: [],
    menuId: "671a1ab9e79c0dba908245df",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671a2489e79c0dba90870b4b",
    firstName: "Faisal Alqaood",
    lastName: null,
    phoneNumber: "+96599858811",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65c2f8c1914bef1d8303ab66",
    delivery: {
      id: "671a2489e79c0dba90870b4b",
      customerId: "65c2f8c1914bef1d8303ab66",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65c2f9cc4a64488bb64b0780",
        name: "Home",
        lng: 48.00963297486305,
        lat: 29.30764091492096,
        city: "Kuwait",
        area: "Surra",
        block: "3",
        street: "12 St",
        building: "24",
        floor: "2",
        flat: "",
        phone: "99858811",
        additionalInfo: "Second floor, leave by the door please ",
        isSelected: true,
        createdAt: "2024-02-07T03:32:28.884Z",
        updatedAt: "2024-10-17T08:30:20.353Z",
      },
      deliverectValidationId: "671a2489621b7746ab759c67",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T10:52:17.161Z",
      estimatedDeliveryTime: "2024-10-24T11:07:15.797Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:39:00.000Z",
      orderId: "671a248ae79c0dba90870b57",
      createdAt: "2024-10-24T10:42:17.350Z",
      updatedAt: "2024-10-24T10:44:05.894Z",
    },
    items: [
      {
        productId: "671a1ab9e79c0dba90824697",
        productPLU: "2162021-1",
        productDescription:
          "C 60g| P 50g| F 16g\nChicken shawarma served with calrose rice & drizzled with our shawarma sauce and sumac white onions.\n\nHigh in protein, ",
        productName: "Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/wpaWs0_xzYbkz_6vxHW2469_71kHXwgrjXuSA4epEwY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2hpY2tlbiUyMFNoYXdhcm1hJTIwYm93bC02NGY0OTRlOTFhNzliZjNkNzNkYjUzNjcuanBn",
        productCalories: 584,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 50,
          fat: 16,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671a1ab9e79c0dba9082473a",
            productPLU: "1907030-1",
            productName: "No Sumac Onion",
            productDescription: "C -2.3g| P -0.3g| F 0g| cal -10| coins 0",
            productCalories: -10,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -2.3,
              protein: -0.3,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "671a1ab9e79c0dba90824699",
        productPLU: "2150008-1",
        productDescription: "C 34.8g| P 2.8g| F 0.4g",
        productName: "Egyptian Rice (100GM)",
        imageURL:
          "https://resizer.deliverect.com/OsU4MCiJQSiA7ILcLO_ell-Y5YG2etx9_auPPSzHy_k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNy02NGMxMWI0MWE0NTBlNjAwMjY4M2NhYzQucG5n",
        productCalories: 164,
        quantity: 1,
        nutritionInfo: {
          carbs: 34.8,
          protein: 2.8,
          fat: 0.4,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 270,
    totalExternalFiatCentsPrice: 270,
    totalFmcCentsPriceOriginal: 270,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:42:18.024Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:44:05.040Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:44:16.841Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T10:57:10.758Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T10:57:10.758Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T10:57:32.625Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:23:22.908Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:42:18.024Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:42:26.074Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:42:18.024Z",
    updatedAt: "2024-10-24T11:23:23.250Z",
    fleetInfo: null,
  },
  {
    id: "671a245fe79c0dba9086f46a",
    sid: "241024-891516",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a245ee79c0dba9086f461",
    firstName: "fatima albedaiwi",
    lastName: null,
    phoneNumber: "+96599951564",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "668195338c15153ac89a1524",
    delivery: {
      id: "671a245ee79c0dba9086f461",
      customerId: "668195338c15153ac89a1524",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6681959886fe4b6ad1d562ed",
        name: "hitten ",
        lng: 48.0253140218235,
        lat: 29.282562255859375,
        city: "Kuwait",
        area: "Hateen",
        block: "3 ",
        street: "شارع 308",
        building: "27",
        floor: "",
        flat: "",
        phone: "+96599951564",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-30T17:27:52.858Z",
        updatedAt: "2024-10-24T10:40:59.782Z",
      },
      deliverectValidationId: "671a245eb77df2bdcbf808bf",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T10:51:34.524Z",
      estimatedDeliveryTime: "2024-10-24T11:06:33.264Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:38:00.000Z",
      orderId: "671a245fe79c0dba9086f46a",
      createdAt: "2024-10-24T10:41:34.613Z",
      updatedAt: "2024-10-24T10:43:04.684Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825cf3",
        productPLU: "2162112-1",
        productDescription:
          "C 65g | P 21g | F 28g[%Popular%]\nFM burger with baked sweet potato fries and bbq sauce",
        productName: "FM burger & fries",
        imageURL:
          "https://resizer.deliverect.com/RmA60bYBZskDxWGZ3uPWH1NZAH_rxnDkyiAlnyV_l1o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBidXJnZXIlMjBjb21iby02NzEzNTc5NTQ4OGM3MjkyNjAyMTliNWYucG5n",
        productCalories: 596,
        quantity: 1,
        nutritionInfo: {
          carbs: 65,
          protein: 21,
          fat: 28,
        },
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 370,
    totalExternalFiatCentsPrice: 370,
    totalFmcCentsPriceOriginal: 370,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:41:35.312Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:43:04.582Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:46:30.050Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:02:41.618Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:02:41.618Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:06:50.138Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:44:48.001Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:41:35.312Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:41:46.826Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:41:35.312Z",
    updatedAt: "2024-10-24T11:44:48.339Z",
    fleetInfo: null,
  },
  {
    id: "671a23f7e79c0dba9086d1b3",
    sid: "241024-138621",
    coupons: [],
    menuId: "671a1acae79c0dba90825c82",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671a23f7e79c0dba9086d1a8",
    firstName: "Mohammad alabdulrazzaq ",
    lastName: null,
    phoneNumber: "+96599977747",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66e20d0aaa1645e3a5143c4c",
    delivery: {
      id: "671a23f7e79c0dba9086d1a8",
      customerId: "66e20d0aaa1645e3a5143c4c",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66e6c7edced6c5247e377ec2",
        name: "Home",
        lng: 47.98292638733983,
        lat: 29.31639760286176,
        city: "Kuwait",
        area: "Qortuba",
        block: "4",
        street: "2 St",
        building: "35",
        floor: "",
        flat: "",
        phone: "+96599977747",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-15T11:41:33.363Z",
        updatedAt: "2024-10-03T08:36:05.202Z",
      },
      deliverectValidationId: "671a23f63bbe5b0ef246aa06",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T10:49:50.926Z",
      estimatedDeliveryTime: "2024-10-24T11:04:49.930Z",
      estimatedDeliveryTimeEnd: "2024-10-24T11:36:00.000Z",
      orderId: "671a23f7e79c0dba9086d1b3",
      createdAt: "2024-10-24T10:39:51.026Z",
      updatedAt: "2024-10-24T10:41:05.830Z",
    },
    items: [
      {
        productId: "671a1acae79c0dba90825cfd",
        productPLU: "2162047-1",
        productDescription:
          "C 51g| P 31g| F 11g \nPenne pasta in a mushroom & parmesan sauce, topped with grilled dijon chicken. \n\n*Good source of protein, Vitamin  D, & minerals*",
        productName: "Dijon Chicken Pasta",
        imageURL:
          "https://resizer.deliverect.com/LI0VRiKQ75jObW5px0dylKLXpRerpSCN0-9_VLTemyk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGFuZCUyMG11c2hyb29tJTIwcGFzdGEtNjY0NGJjYjAyNTFjMmQ2ZTEyNjQ3NTUwLnBuZw==",
        productCalories: 427,
        quantity: 1,
        nutritionInfo: {
          carbs: 51,
          protein: 31,
          fat: 11,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671a1acae79c0dba90825d2d",
        productPLU: "2163017-1",
        productDescription:
          "C 14g| P 5g| F 14g\nIceberg lettuce, purple cabbage, celery, cucumbers, red bell peppers, carrots, spring onions & feta cheese drizzled in a apple cider dressing.\n\nGOOD SOURCE OF VITAMINS AND MINERALS",
        productName: "Crisp Garden Salad",
        imageURL:
          "https://resizer.deliverect.com/j1CgCeX_u4Ri_dXIPf7B0tN79FQj6Y8oZKfuCfwP-TM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAxMi02NTE3ZWUxNDY4ZDZhNTAxZDEzMzZhMjQuanBn",
        productCalories: 202,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 5,
          fat: 14,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 550,
    totalFmcCentsPrice: 550,
    totalFiatCentsPriceOriginal: 550,
    totalExternalFiatCentsPrice: 550,
    totalFmcCentsPriceOriginal: 550,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:39:51.699Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T10:41:05.475Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T10:41:17.146Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T11:02:19.019Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T11:02:19.020Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T11:02:41.405Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T11:34:04.548Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T10:39:51.699Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T10:40:03.147Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T10:39:51.699Z",
    updatedAt: "2024-10-24T11:34:05.066Z",
    fleetInfo: null,
  },
  {
    id: "6719d90db7a34cd0923e0775",
    sid: "241024-282043",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719d90cb7a34cd0923e0761",
    firstName: "Yousef Alghanim",
    lastName: null,
    phoneNumber: "+96566551172",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66471615ad6da8cfc42fda67",
    delivery: {
      id: "6719d90cb7a34cd0923e0761",
      customerId: "66471615ad6da8cfc42fda67",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "664c3045894471f343408988",
        name: "Work",
        lng: 47.9903082,
        lat: 29.3764565,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "7",
        street: "Omar Ben Al Khattab St",
        building: "1",
        floor: "4",
        flat: "",
        phone: "+96566551172",
        additionalInfo: "Alrayya tower, 4th floor",
        isSelected: true,
        createdAt: "2024-05-21T05:25:25.200Z",
        updatedAt: "2024-10-20T05:40:53.396Z",
      },
      deliverectValidationId: "6719d90cb63263b7f60f1209",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:30:12.063Z",
      estimatedDeliveryTime: "2024-10-24T05:45:10.838Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:17:00.000Z",
      orderId: "6719d90db7a34cd0923e0775",
      createdAt: "2024-10-24T05:20:12.167Z",
      updatedAt: "2024-10-24T05:22:05.455Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7010",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 2,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7013",
        productPLU: "2161021-1",
        productDescription:
          "C 16g| P 15g| F 16g\nEggs, turkish soudjouk, muhammara & labneh\n\nGOOD SOURCE OF PROTEIN",
        productName: "Turkish Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/-B_481BG16xkyYtWQaVbIvD-9tkBN12twZodyhjhhEA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2lzaCUyMGJyZWFrZmFzdCUyMHRhY29zKDEpLTY1NTQ4ZjU3YWI3MmIwMDAyY2I5ZTMyNi5wbmc=",
        productCalories: 268,
        quantity: 2,
        nutritionInfo: {
          carbs: 16,
          protein: 15,
          fat: 16,
        },
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 1020,
    totalFmcCentsPrice: 1020,
    totalFiatCentsPriceOriginal: 1020,
    totalExternalFiatCentsPrice: 1020,
    totalFmcCentsPriceOriginal: 1020,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:20:13.070Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:22:03.563Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:22:07.596Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:31:29.176Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:31:29.176Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:38:45.614Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:52:27.975Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:20:13.070Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:20:24.477Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:20:13.070Z",
    updatedAt: "2024-10-24T05:52:28.313Z",
    fleetInfo: null,
  },
  {
    id: "6719d8d4b7a34cd0923dfe15",
    sid: "241024-580950",
    coupons: [],
    menuId: "67196444b7a34cd0923c7414",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6719d8d3b7a34cd0923dfe0a",
    firstName: "Faisal",
    lastName: null,
    phoneNumber: "+96599100552",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c4f12475a6aa442d0f7194",
    delivery: {
      id: "6719d8d3b7a34cd0923dfe0a",
      customerId: "64c4f12475a6aa442d0f7194",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64e5b816e4583466bfce2114",
        name: "Work",
        lng: 48.097856529057026,
        lat: 29.343807004672804,
        city: "Salmiya",
        area: "Al-Bidea",
        block: "1",
        street: "Blajat",
        building: "Marine fire recue",
        floor: "",
        flat: "",
        phone: "+96599100552",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-23T07:41:10.695Z",
        updatedAt: "2024-10-23T05:26:14.254Z",
      },
      deliverectValidationId: "6719d8d388e23371a9fb9e4f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:29:15.732Z",
      estimatedDeliveryTime: "2024-10-24T05:44:14.419Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:16:00.000Z",
      orderId: "6719d8d4b7a34cd0923dfe15",
      createdAt: "2024-10-24T05:19:15.828Z",
      updatedAt: "2024-10-24T05:21:06.583Z",
    },
    items: [
      {
        productId: "67196444b7a34cd0923c7435",
        productPLU: "2161021-1",
        productDescription:
          "C 16g| P 15g| F 16g\nEggs, turkish soudjouk, muhammara & labneh\n\nGOOD SOURCE OF PROTEIN",
        productName: "Turkish Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/-B_481BG16xkyYtWQaVbIvD-9tkBN12twZodyhjhhEA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2lzaCUyMGJyZWFrZmFzdCUyMHRhY29zKDEpLTY1NTQ4ZjU3YWI3MmIwMDAyY2I5ZTMyNi5wbmc=",
        productCalories: 268,
        quantity: 1,
        nutritionInfo: {
          carbs: 16,
          protein: 15,
          fat: 16,
        },
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196444b7a34cd0923c7432",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 510,
    totalFmcCentsPrice: 510,
    totalFiatCentsPriceOriginal: 510,
    totalExternalFiatCentsPrice: 510,
    totalFmcCentsPriceOriginal: 510,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:19:16.559Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:21:04.425Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:25:03.738Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:33:51.544Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:33:51.545Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:44:19.305Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T06:05:02.831Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:19:16.559Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:19:36.776Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:19:16.559Z",
    updatedAt: "2024-10-24T06:05:03.178Z",
    fleetInfo: null,
  },
  {
    id: "6719d8d2b7a34cd0923dfd9f",
    sid: "241024-502177",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719d8d1b7a34cd0923dfd6b",
    firstName: "Sarah AlArfaj",
    lastName: null,
    phoneNumber: "+96594446253",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67178d09b58bc47308429c90",
    delivery: {
      id: "6719d8d1b7a34cd0923dfd6b",
      customerId: "67178d09b58bc47308429c90",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6718b578b7a34cd092074e34",
        name: "Work",
        lng: 48.05359337478876,
        lat: 29.098311203886276,
        city: "Ahmadi",
        area: "North Ahmadi",
        block: "8",
        street: "South Main St.",
        building: "KOC Complex - B6 ",
        floor: "1st floor",
        flat: "611133",
        phone: "+96594446253",
        additionalInfo: "Come through gate No.4",
        isSelected: true,
        createdAt: "2024-10-23T08:36:08.986Z",
        updatedAt: "2024-10-24T05:13:27.646Z",
      },
      deliverectValidationId: "6719d8d18ee7d1c25629fd9a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:29:13.426Z",
      estimatedDeliveryTime: "2024-10-24T05:44:12.405Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:16:00.000Z",
      orderId: "6719d8d2b7a34cd0923dfd9f",
      createdAt: "2024-10-24T05:19:13.536Z",
      updatedAt: "2024-10-24T05:21:04.957Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c7143",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c7155",
        productPLU: "2166015-1",
        productDescription:
          "C 28g| P 12g| F 20g\nHummus served with pita chips.\n\nGood source of Vitamin E",
        productName: "Hummus",
        imageURL:
          "https://resizer.deliverect.com/72PxglK3MSw736Em3OxAtr0nEkCtbdmogjJvF3gGaJo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjAyLTY1MDgzMzQ1ZGViYWM1MDAxZjY1N2Q4OC5qcGc=",
        productCalories: 340,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 12,
          fat: 20,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c715b",
        productPLU: "2165008-1",
        productDescription: "C 13g| P 3g| F 10g",
        productName: "Snickers Bar",
        imageURL:
          "https://resizer.deliverect.com/qlfM-kU78NJZFB6H6YZxYvpH8UfR3_jKIa0YwqE7w20/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTItNjRiN2JjZGJhMDAxOGMwYmY0YTY2NTZiLnBuZw==",
        productCalories: 157,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 3,
          fat: 10,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 360,
    totalFmcCentsPrice: 360,
    totalFiatCentsPriceOriginal: 360,
    totalExternalFiatCentsPrice: 360,
    totalFmcCentsPriceOriginal: 360,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:19:14.175Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:21:04.546Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:21:48.439Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:39:51.356Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:39:51.356Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:50:20.668Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T06:24:09.343Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:19:14.175Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:19:25.792Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:19:14.175Z",
    updatedAt: "2024-10-24T06:24:09.710Z",
    fleetInfo: null,
  },
  {
    id: "6719d8b8b7a34cd0923dfab6",
    sid: "241024-545914",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719d8b7b7a34cd0923dfaa9",
    firstName: "Faleh",
    lastName: null,
    phoneNumber: "+96597947744",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64ca556c75a6aa442d132be5",
    delivery: {
      id: "6719d8b7b7a34cd0923dfaa9",
      customerId: "64ca556c75a6aa442d132be5",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6698beb8b92d296a2cb6a980",
        name: "Public Institution for Social Security",
        lng: 47.983802789999984,
        lat: 29.363113403320312,
        city: "Kuwait City",
        area: "Mirqab",
        block: "1",
        street: "Alsoor Street",
        building: "PIFSS main building",
        floor: "Ground floor",
        flat: "",
        phone: "+96597947744",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-18T07:05:28.756Z",
        updatedAt: "2024-10-20T05:10:55.761Z",
      },
      deliverectValidationId: "6719d8b73946960ae123b270",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:28:47.747Z",
      estimatedDeliveryTime: "2024-10-24T05:43:46.474Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:15:00.000Z",
      orderId: "6719d8b8b7a34cd0923dfab6",
      createdAt: "2024-10-24T05:18:47.846Z",
      updatedAt: "2024-10-24T05:20:04.603Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c703d",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        nutritionInfo: {
          carbs: 5,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7023",
        productPLU: "2166021-1",
        productDescription:
          "C 14g| P 2g| F 1g\nFresh pomegranate seeds.\n\nGOOD SOURCE OF VITAMIN  C",
        productName: "Fresh Pomegranate",
        imageURL:
          "https://resizer.deliverect.com/0QanPE0fkKGZvx0QoWjnfvfTbh0UdPExZ1vpzwL3kU0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNjYtNjUyOWY0MGY4NDhjZjcwMDE4YzBmYmIxLmpwZw==",
        productCalories: 70,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 2,
          fat: 1,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7017",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 2,
        nutritionInfo: {
          carbs: 27,
          protein: 24,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 370,
    totalExternalFiatCentsPrice: 370,
    totalFmcCentsPriceOriginal: 370,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:18:48.629Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:20:09.634Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:20:13.213Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:28:13.277Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:28:13.277Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:31:16.840Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:48:26.524Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:18:48.629Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:19:00.179Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:18:48.629Z",
    updatedAt: "2024-10-24T05:48:26.865Z",
    fleetInfo: null,
  },
  {
    id: "6719d883b7a34cd0923df27c",
    sid: "241024-108598",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719d882b7a34cd0923df1f9",
    firstName: "Fawaz Jamal AlAkroka",
    lastName: null,
    phoneNumber: "+96590950366",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66faed828951a675da10bce2",
    delivery: {
      id: "6719d882b7a34cd0923df1f9",
      customerId: "66faed828951a675da10bce2",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66faf32eee3a746f7ff1504b",
        name: "Work",
        lng: 47.9704375192523,
        lat: 29.374971901517505,
        city: "Kuwait City",
        area: "Jibla",
        block: "3",
        street: "Ali Al Salem Street",
        building: "Boubyan Bank (Grey Building)",
        floor: "2",
        flat: "",
        phone: "+96590950366",
        additionalInfo: "Deliver to security ",
        isSelected: true,
        createdAt: "2024-09-30T18:51:26.949Z",
        updatedAt: "2024-10-20T05:14:03.126Z",
      },
      deliverectValidationId: "6719d882827135858b95c14e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:27:54.267Z",
      estimatedDeliveryTime: "2024-10-24T05:42:53.226Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:14:00.000Z",
      orderId: "6719d883b7a34cd0923df27c",
      createdAt: "2024-10-24T05:17:54.359Z",
      updatedAt: "2024-10-24T05:19:05.011Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7010",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7027",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 400,
    totalExternalFiatCentsPrice: 400,
    totalFmcCentsPriceOriginal: 400,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:17:55.490Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:19:03.823Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:19:47.790Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:26:10.858Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:26:10.859Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:26:32.913Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:37:06.521Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:17:55.490Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:18:06.922Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:17:55.490Z",
    updatedAt: "2024-10-24T05:37:06.861Z",
    fleetInfo: null,
  },
  {
    id: "6719d7f5b7a34cd0923dde2a",
    sid: "241024-405175",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719d7f4b7a34cd0923dde1b",
    firstName: "Sherifa D",
    lastName: null,
    phoneNumber: "+96560644550",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6716b9c6b58bc47308413edd",
    delivery: {
      id: "6719d7f4b7a34cd0923dde1b",
      customerId: "6716b9c6b58bc47308413edd",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6716ba62b7a34cd092c498df",
        name: "Sideeq",
        lng: 47.99382068216801,
        lat: 29.290570467085054,
        city: "Kuwait",
        area: "Al-Siddeeq",
        block: "4",
        street: "411",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96560644550",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-21T20:32:34.109Z",
        updatedAt: "2024-10-21T20:32:34.109Z",
      },
      deliverectValidationId: "6719d7f43bee7736697a97be",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:25:32.674Z",
      estimatedDeliveryTime: "2024-10-24T05:40:31.353Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:12:00.000Z",
      orderId: "6719d7f5b7a34cd0923dde2a",
      createdAt: "2024-10-24T05:15:32.772Z",
      updatedAt: "2024-10-24T05:17:05.406Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c7286",
        productPLU: "2167014-1",
        productDescription:
          "C 32g| P 14g| F 8g\nEnglish muffin with egg whites, blanched spinach, mozzarella cheese and our breakfast sauce.",
        productName: "FM Egg Muffin",
        imageURL:
          "https://resizer.deliverect.com/UDWwpJ2NagrTgNTXcD-YQp6Hbon4KgO_xVwUVvV2eKE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NGJmYTJlMzE0ODFjMTAwMjZjYTEwNTkucG5n",
        productCalories: 256,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 14,
          fat: 8,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72ae",
        productPLU: "2202043-1",
        productDescription: "C 3.8g | P 1.5g | F 0.5g\nColumbia coffee, Water",
        productName: "Cold Brew",
        imageURL:
          "https://resizer.deliverect.com/vJfaq3WgcPkWREtbcv57BpeV4cJZHp0DfxbTLKoOq48/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY29sZCUyMGJyZXctNjY0MWRhZTA2YTdhYTY5NGFiMjI3ZjkzLnBuZw==",
        productCalories: 25,
        quantity: 1,
        nutritionInfo: {
          carbs: 3.8,
          protein: 1.5,
          fat: 0.5,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72a9",
        productPLU: "2202024-1",
        productDescription: "C 0g| P 0g| F 0g",
        productName: "Voda Voda water 330 ml",
        imageURL:
          "https://resizer.deliverect.com/rREX_if98r0ZPCForFZZOMM-QG7U7usZElGULr0shy4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NTZlZjljOTE5ODQ0MTlhZmFjYTY4YjIuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:15:33.453Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:17:04.184Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:17:26.622Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:35:14.430Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:35:14.430Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:35:36.294Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:47:06.570Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:15:33.453Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:15:44.960Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:15:33.453Z",
    updatedAt: "2024-10-24T05:47:06.909Z",
    fleetInfo: null,
  },
  {
    id: "6719d7dbb7a34cd0923ddbaf",
    sid: "241024-904177",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719d7dbb7a34cd0923ddba4",
    firstName: "Hamad Al Refaei ",
    lastName: null,
    phoneNumber: "+96599115004",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65841b21e8af429bc67ea147",
    delivery: {
      id: "6719d7dbb7a34cd0923ddba4",
      customerId: "65841b21e8af429bc67ea147",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "661bc0cb00177f948b47486f",
        name: "Home",
        lng: 48.081764951348305,
        lat: 29.24326530172693,
        city: "Kuwait",
        area: "Al-Adan District",
        block: "8",
        street: "4",
        building: "11",
        floor: "",
        flat: "",
        phone: "+96599115004",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-14T11:40:59.087Z",
        updatedAt: "2024-04-14T11:40:59.087Z",
      },
      deliverectValidationId: "6719d7da0348c4b0ea8186ba",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:25:06.929Z",
      estimatedDeliveryTime: "2024-10-24T05:40:05.799Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:12:00.000Z",
      orderId: "6719d7dbb7a34cd0923ddbaf",
      createdAt: "2024-10-24T05:15:07.025Z",
      updatedAt: "2024-10-24T05:17:05.080Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c714d",
        productPLU: "2167007-1",
        productDescription:
          "C 60g| P 24g| F 17g [%Best seller%]\nSmoked turkey with grilled turkey bacon, avocado puree, rocca, pesto & emmental cheese all in sourdough toast. ",
        productName: "Turkey Pesto",
        imageURL:
          "https://resizer.deliverect.com/d4E--e7KeFg5pz7n8OJh2vgevZuUdJEXur9hGCWc7sY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2V5JTIwc2FuZHdpY2gtNjRmNDkwMzljNDA4OTczMWUzMzk4OTc0LmpwZw==",
        productCalories: 489,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 24,
          fat: 17,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c7163",
        productPLU: "2202052-1",
        productDescription:
          "C 13g | P 0g | F 0g\nPomegranate, lemon\n\nGood source of Vitamin C",
        productName: "Pomade",
        imageURL:
          "https://resizer.deliverect.com/JpImLqr5JfNLPk8gTnl6NoZd9i6mdIhqPB4QA7H5lw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEtNjY1OTFhNzgxNmEzMzQyNTY5OGVhM2QxLnBuZw==",
        productCalories: 54,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 400,
    totalExternalFiatCentsPrice: 400,
    totalFmcCentsPriceOriginal: 400,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:15:07.740Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:17:05.083Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:18:12.131Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:39:41.511Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:39:41.511Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:42:11.706Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T06:02:55.904Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:15:07.740Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:15:19.359Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:15:07.740Z",
    updatedAt: "2024-10-24T06:02:56.263Z",
    fleetInfo: null,
  },
  {
    id: "6719d776b7a34cd0923dd2bd",
    sid: "241024-582764",
    coupons: [],
    menuId: "67196444b7a34cd0923c7414",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6719d776b7a34cd0923dd2b1",
    firstName: "Sarah Naqi",
    lastName: null,
    phoneNumber: "+96599074780",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "650869e9e1d913534186eac9",
    delivery: {
      id: "6719d776b7a34cd0923dd2b1",
      customerId: "650869e9e1d913534186eac9",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "650bd2d00a91a2efcd417621",
        name: "WORK",
        lng: 48.032773062586784,
        lat: 29.32730554871526,
        city: "Jabriya",
        area: "Jabriya",
        block: "4",
        street: "Hussain Bin Ali Al Roumi",
        building: "Medicine Faculty Gate 1",
        floor: "",
        flat: "",
        phone: "+96599074780",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-09-21T05:21:20.887Z",
        updatedAt: "2024-10-24T05:02:18.426Z",
      },
      deliverectValidationId: "6719d776dda2cbde3362c918",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:23:26.021Z",
      estimatedDeliveryTime: "2024-10-24T05:38:24.621Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:10:00.000Z",
      orderId: "6719d776b7a34cd0923dd2bd",
      createdAt: "2024-10-24T05:13:26.114Z",
      updatedAt: "2024-10-24T05:15:06.215Z",
    },
    items: [
      {
        productId: "67196444b7a34cd0923c743b",
        productPLU: "2167023-1",
        productDescription:
          "C 29g | P 22g | F 16g\nScrambled eggs with mushroom, green olives and feta cheese in a tortilla wrap\n\nGOOD SOURCE OF PROTEIN, MINERALS",
        productName: "Mushroom egg wrap",
        imageURL:
          "https://resizer.deliverect.com/icCk6VyiEI1LioDbIHa0TjQikuisfIyU0FZUoDvsxdY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAyLTY2M2ZmM2Q0ZGI0ZjMyZjZhNTZmMGY4MC5wbmc=",
        productCalories: 348,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 22,
          fat: 16,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196444b7a34cd0923c744a",
        productPLU: "2165008-1",
        productDescription: "C 13g| P 3g| F 10g",
        productName: "Snickers Bar",
        imageURL:
          "https://resizer.deliverect.com/qlfM-kU78NJZFB6H6YZxYvpH8UfR3_jKIa0YwqE7w20/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTItNjRiN2JjZGJhMDAxOGMwYmY0YTY2NTZiLnBuZw==",
        productCalories: 157,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 3,
          fat: 10,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196444b7a34cd0923c7450",
        productPLU: "2202050-1",
        productDescription:
          "C 27g | P 1.5g | F 0g\nOrange \n\nGood source of Vitamin C",
        productName: "Orange Citrus",
        imageURL:
          "https://resizer.deliverect.com/92Cgq5w07iSWvqOzcojq0Egx0BDIxsVNTfIhlYhtUmU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTMtNjY1OTE5ODQ0MjllZTgwN2Q2OWQxYmZkLnBuZw==",
        productCalories: 116,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 1.5,
          fat: null,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 370,
    totalExternalFiatCentsPrice: 370,
    totalFmcCentsPriceOriginal: 370,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:13:26.928Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:15:06.009Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:25:02.934Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:25:13.397Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:25:13.397Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:39:08.131Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:46:55.268Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:13:26.928Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:13:38.397Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T05:13:26.928Z",
    updatedAt: "2024-10-24T05:46:55.602Z",
    fleetInfo: null,
  },
  {
    id: "6719d766b7a34cd0923dd128",
    sid: "241024-392378",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719d765b7a34cd0923dd103",
    firstName: "Shaika alobaid",
    lastName: null,
    phoneNumber: "+96594445268",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "671051b3aa779ec4a78fe81f",
    delivery: {
      id: "6719d765b7a34cd0923dd103",
      customerId: "671051b3aa779ec4a78fe81f",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "671052a2f495b693cd274c88",
        name: "Home",
        lng: 48.070174183037324,
        lat: 29.25898249351624,
        city: "Sabah Al Salem",
        area: "Sabah Al Salem",
        block: "4",
        street: "3",
        building: "9",
        floor: "",
        flat: "",
        phone: "+96594445268",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-16T23:56:18.361Z",
        updatedAt: "2024-10-16T23:56:18.361Z",
      },
      deliverectValidationId: "6719d7656a3e6800dd08358a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:23:09.410Z",
      estimatedDeliveryTime: "2024-10-24T05:38:07.983Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:10:00.000Z",
      orderId: "6719d766b7a34cd0923dd128",
      createdAt: "2024-10-24T05:13:09.529Z",
      updatedAt: "2024-10-24T05:15:05.858Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c7147",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 26,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c716a",
        productPLU: "2202036-1",
        productDescription:
          "C 20g | P 19.2g | F 4.7g\nCocoa powder, Milk, Stevia & WHEY Protein",
        productName: "Chocolate Protein Shake",
        imageURL:
          "https://resizer.deliverect.com/TjoR9Z9GyFIBSfx0VgVYXmRJcSGxnQwDMGEZg2k_4G4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hvY29sYXRlJTIwcHJvdGVpbiUyMHNoYWtlLTY2NDFkYWJmMGMyNGQ5MjVmY2QzZmE4Yi5wbmc=",
        productCalories: 200,
        quantity: 1,
        nutritionInfo: {
          carbs: 20,
          protein: 19.2,
          fat: 4.7,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 340,
    totalExternalFiatCentsPrice: 340,
    totalFmcCentsPriceOriginal: 340,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:13:10.201Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:15:04.097Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:15:14.147Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:27:13.346Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:27:13.346Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:32:06.906Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:48:37.069Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:13:10.201Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:13:16.875Z",
      },
    ],
    cutlery: false,
    hasRating: true,
    timeslot: null,
    createdAt: "2024-10-24T05:13:10.201Z",
    updatedAt: "2024-10-24T06:38:03.369Z",
    fleetInfo: null,
  },
  {
    id: "6719d69cb7a34cd0923dc42e",
    sid: "241024-986601",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719d69cb7a34cd0923dc423",
    firstName: "Tarik alghanim",
    lastName: null,
    phoneNumber: "+96566668512",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65c08460d3514bca6687dbda",
    delivery: {
      id: "6719d69cb7a34cd0923dc423",
      customerId: "65c08460d3514bca6687dbda",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65c086fee693073428b714b6",
        name: "Flat",
        lng: 47.992368433624506,
        lat: 29.283483144306544,
        city: "Zahra",
        area: "Zahra",
        block: "3",
        street: "301",
        building: "8",
        floor: "2",
        flat: "2",
        phone: "+96566668512",
        additionalInfo: "Use right gate on the right side of the building",
        isSelected: true,
        createdAt: "2024-02-05T06:58:06.965Z",
        updatedAt: "2024-10-23T16:14:52.711Z",
      },
      deliverectValidationId: "6719d69bc47d7273b39365ea",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:19:47.924Z",
      estimatedDeliveryTime: "2024-10-24T05:34:46.629Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:06:00.000Z",
      orderId: "6719d69cb7a34cd0923dc42e",
      createdAt: "2024-10-24T05:09:48.039Z",
      updatedAt: "2024-10-24T05:11:06.625Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c7285",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c7288",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 26,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "No salad",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 320,
    totalFmcCentsPrice: 320,
    totalFiatCentsPriceOriginal: 320,
    totalExternalFiatCentsPrice: 320,
    totalFmcCentsPriceOriginal: 320,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:09:48.554Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:11:04.329Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:11:13.109Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:24:00.317Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:24:00.317Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:39:19.488Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:51:11.275Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:09:48.554Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:09:59.982Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:09:48.554Z",
    updatedAt: "2024-10-24T05:51:11.630Z",
    fleetInfo: null,
  },
  {
    id: "6719d68cb7a34cd0923dc24c",
    sid: "241024-550969",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719d68cb7a34cd0923dc230",
    firstName: "Qutaiba barrak albarjas",
    lastName: null,
    phoneNumber: "+96597776060",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6709834aaa779ec4a77ea891",
    delivery: {
      id: "6719d68cb7a34cd0923dc230",
      customerId: "6709834aaa779ec4a77ea891",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670b5cdbf495b693cd2db0b3",
        name: "Shweek",
        lng: 47.95451468279147,
        lat: 29.32135009765625,
        city: "Shuwaikh Industrial",
        area: "Shuwaikh Industrial",
        block: "2",
        street: "28",
        building: "الاداره العامه للجنسيه ووثائق السفر",
        floor: "0",
        flat: "",
        phone: "+96597776060",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-13T05:38:35.242Z",
        updatedAt: "2024-10-20T05:04:13.550Z",
      },
      deliverectValidationId: "6719d68c827135858b952acb",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:19:32.158Z",
      estimatedDeliveryTime: "2024-10-24T05:34:30.850Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:06:00.000Z",
      orderId: "6719d68cb7a34cd0923dc24c",
      createdAt: "2024-10-24T05:09:32.266Z",
      updatedAt: "2024-10-24T05:11:06.650Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7016",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c703d",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        nutritionInfo: {
          carbs: 5,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c703e",
        productPLU: "2202039-1",
        productDescription: "C 8.6g | P 0.6g | F 0g\nTurmeric, Orange & Honey",
        productName: "Energy Shot",
        imageURL:
          "https://resizer.deliverect.com/ebvnxevyu9pax93CwcUbxaXHU29E4Bx-a8lOaqc9oPI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZW5lcmd5JTIwc2hvdC02NjQxZGFlZjVjNmRlZjAxYzMzYzIzMzMucG5n",
        productCalories: 37,
        quantity: 1,
        nutritionInfo: {
          carbs: 8.6,
          protein: 0.6,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:09:32.922Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:11:04.495Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:11:18.223Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:24:44.265Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:24:44.265Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:25:06.220Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:41:16.067Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:09:32.922Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:09:44.495Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:09:32.922Z",
    updatedAt: "2024-10-24T05:41:16.512Z",
    fleetInfo: null,
  },
  {
    id: "6719d66cb7a34cd0923dbef8",
    sid: "241024-694853",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719d66bb7a34cd0923dbee6",
    firstName: "Abdullah Alawadhi",
    lastName: null,
    phoneNumber: "+96597699399",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67129734b58bc4730837438d",
    delivery: {
      id: "6719d66bb7a34cd0923dbee6",
      customerId: "67129734b58bc4730837438d",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6714dbcbb7a34cd0924be1c6",
        name: "Office",
        lng: 47.97484505921602,
        lat: 29.371811437955323,
        city: "Kuwait City",
        area: "Jibla",
        block: "1",
        street: "1",
        building: "1",
        floor: "",
        flat: "",
        phone: "+96597699399",
        additionalInfo: "ABK (Ahli Bank of Kuwait) head office, service door",
        isSelected: true,
        createdAt: "2024-10-20T10:30:35.997Z",
        updatedAt: "2024-10-22T09:50:19.761Z",
      },
      deliverectValidationId: "6719d66bc6ffa8c03b1dfef0",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:18:59.277Z",
      estimatedDeliveryTime: "2024-10-24T05:33:57.996Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:06:00.000Z",
      orderId: "6719d66cb7a34cd0923dbef8",
      createdAt: "2024-10-24T05:08:59.374Z",
      updatedAt: "2024-10-24T05:11:05.151Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7011",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 2,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 260,
    totalFmcCentsPrice: 260,
    totalFiatCentsPriceOriginal: 260,
    totalExternalFiatCentsPrice: 260,
    totalFmcCentsPriceOriginal: 260,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:09:00.233Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:11:04.724Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:11:18.103Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:25:29.320Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:25:29.320Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:25:48.705Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:46:40.045Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:09:00.233Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:09:20.823Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:09:00.233Z",
    updatedAt: "2024-10-24T05:46:40.383Z",
    fleetInfo: null,
  },
  {
    id: "6719d66ab7a34cd0923dbedb",
    sid: "241024-607970",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719d669b7a34cd0923dbea8",
    firstName: "Abdulrahman alayoub",
    lastName: null,
    phoneNumber: "+96569911999",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "670cf240aa779ec4a786a10e",
    delivery: {
      id: "6719d669b7a34cd0923dbea8",
      customerId: "670cf240aa779ec4a786a10e",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6717456bb7a34cd092c90e65",
        name: "Qibla",
        lng: 47.975076583115595,
        lat: 29.371464053238107,
        city: "Al Kuwayt",
        area: "Jibla",
        block: "10",
        street: "Ahmad Al Jaber St",
        building: "Baitek towers ",
        floor: "27",
        flat: "",
        phone: "+96569911999",
        additionalInfo: "Baitek tower, it’s better to deliver with motorcycle ",
        isSelected: true,
        createdAt: "2024-10-22T06:25:47.489Z",
        updatedAt: "2024-10-23T04:38:53.778Z",
      },
      deliverectValidationId: "6719d6693bee7736697a1a6c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:18:57.260Z",
      estimatedDeliveryTime: "2024-10-24T05:33:55.963Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:05:00.000Z",
      orderId: "6719d66ab7a34cd0923dbedb",
      createdAt: "2024-10-24T05:08:57.382Z",
      updatedAt: "2024-10-24T05:10:05.788Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7010",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:08:58.396Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:10:04.232Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:10:49.935Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:25:48.817Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:25:48.817Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:26:10.779Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:48:48.036Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:08:58.396Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:09:09.863Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:08:58.396Z",
    updatedAt: "2024-10-24T05:48:48.501Z",
    fleetInfo: null,
  },
  {
    id: "6719d641b7a34cd0923db8da",
    sid: "241024-857022",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719d641b7a34cd0923db8c9",
    firstName: "Dana khalifa",
    lastName: null,
    phoneNumber: "+96555699006",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "656b1fbba3b189708bcc593b",
    delivery: {
      id: "6719d641b7a34cd0923db8c9",
      customerId: "656b1fbba3b189708bcc593b",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66a484491683a2e7265b37c6",
        name: "Andalous ",
        lng: 47.88192944709875,
        lat: 29.3048095703125,
        city: "Andalous",
        area: "Andalous",
        block: "9",
        street: "5 St",
        building: "194",
        floor: "",
        flat: "",
        phone: "+96555699006",
        additionalInfo: "Orange house ",
        isSelected: true,
        createdAt: "2024-07-27T05:23:21.821Z",
        updatedAt: "2024-07-27T05:23:21.821Z",
      },
      deliverectValidationId: "6719d6403bee7736697a0de7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:18:16.984Z",
      estimatedDeliveryTime: "2024-10-24T05:33:15.685Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:05:00.000Z",
      orderId: "6719d641b7a34cd0923db8da",
      createdAt: "2024-10-24T05:08:17.117Z",
      updatedAt: "2024-10-24T05:10:06.341Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c7285",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72b1",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        nutritionInfo: {
          carbs: 5,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72b3",
        productPLU: "2202040-1",
        productDescription: "C 4.8g | P 0g | F 0g\nOrange, Grapefruit & Lemon",
        productName: "Immunity Shot",
        imageURL:
          "https://resizer.deliverect.com/WkO7ZBCzCZBVTRau0FCMoAizLyoqJmikHQl5iJRWhJs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaW1tdW5pdHklMjBzaG90LTY2NDFkYjEwYWIxMzE0ZDg1Njc3OGNkZS5wbmc=",
        productCalories: 19,
        quantity: 1,
        nutritionInfo: {
          carbs: 4.8,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c7292",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 420,
    totalFmcCentsPrice: 420,
    totalFiatCentsPriceOriginal: 420,
    totalExternalFiatCentsPrice: 420,
    totalFmcCentsPriceOriginal: 420,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:08:17.856Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:10:04.767Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:10:13.641Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:22:01.187Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:22:01.188Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:22:23.044Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:54:12.117Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:08:17.856Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:08:29.934Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T05:08:17.856Z",
    updatedAt: "2024-10-24T05:54:12.450Z",
    fleetInfo: null,
  },
  {
    id: "6719d640b7a34cd0923db8c0",
    sid: "241024-639681",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719d63fb7a34cd0923db8b5",
    firstName: "Zeid Alkhaled",
    lastName: null,
    phoneNumber: "+96599170777",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c4b46d75a6aa442d0f6155",
    delivery: {
      id: "6719d63fb7a34cd0923db8b5",
      customerId: "64c4b46d75a6aa442d0f6155",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64e06f1ee4583466bfc3ed69",
        name: "Work",
        lng: 47.98021115362644,
        lat: 29.36353692924005,
        city: "Al Kuwayt",
        area: "Mirqab",
        block: "1",
        street: "67 St",
        building: "tower A floor 12",
        floor: "",
        flat: "",
        phone: "+96599170777",
        additionalInfo: "Twin Tower",
        isSelected: true,
        createdAt: "2023-08-19T07:28:30.908Z",
        updatedAt: "2024-10-08T05:25:01.990Z",
      },
      deliverectValidationId: "6719d63fff147771ad690836",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:18:15.642Z",
      estimatedDeliveryTime: "2024-10-24T05:33:14.050Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:05:00.000Z",
      orderId: "6719d640b7a34cd0923db8c0",
      createdAt: "2024-10-24T05:08:15.759Z",
      updatedAt: "2024-10-24T05:10:04.988Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7010",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 2,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7023",
        productPLU: "2166021-1",
        productDescription:
          "C 14g| P 2g| F 1g\nFresh pomegranate seeds.\n\nGOOD SOURCE OF VITAMIN  C",
        productName: "Fresh Pomegranate",
        imageURL:
          "https://resizer.deliverect.com/0QanPE0fkKGZvx0QoWjnfvfTbh0UdPExZ1vpzwL3kU0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNjYtNjUyOWY0MGY4NDhjZjcwMDE4YzBmYmIxLmpwZw==",
        productCalories: 70,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 2,
          fat: 1,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 590,
    totalFmcCentsPrice: 590,
    totalFiatCentsPriceOriginal: 590,
    totalExternalFiatCentsPrice: 590,
    totalFmcCentsPriceOriginal: 590,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:08:16.389Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:10:03.737Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:10:44.772Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:19:58.764Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:19:58.764Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:31:28.032Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:41:49.323Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:08:16.389Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:08:18.927Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:08:16.389Z",
    updatedAt: "2024-10-24T05:41:49.666Z",
    fleetInfo: null,
  },
  {
    id: "6719d627b7a34cd0923db5f1",
    sid: "241024-452910",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719d626b7a34cd0923db5e0",
    firstName: "Dekheel Aldekheel",
    lastName: null,
    phoneNumber: "+96599222215",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d92a1bdfecd0088b526864",
    delivery: {
      id: "6719d626b7a34cd0923db5e0",
      customerId: "64d92a1bdfecd0088b526864",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64d9eaed3902308f22ead4ca",
        name: "Work",
        lng: 47.966885075497004,
        lat: 29.363662719726562,
        city: "Al Kuwayt",
        area: "Jibla",
        block: "13",
        street: "Mohammad Thunayyan Al-Ghanem St",
        building: "Salhiya Complex",
        floor: "4",
        flat: "Gate 6",
        phone: "+96599222215",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-14T08:50:53.599Z",
        updatedAt: "2024-10-22T04:25:27.699Z",
      },
      deliverectValidationId: "6719d6263946960ae12297eb",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:17:50.173Z",
      estimatedDeliveryTime: "2024-10-24T05:32:49.014Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:04:00.000Z",
      orderId: "6719d627b7a34cd0923db5f1",
      createdAt: "2024-10-24T05:07:50.434Z",
      updatedAt: "2024-10-24T05:09:08.890Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7011",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c703d",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        nutritionInfo: {
          carbs: 5,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7027",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7022",
        productPLU: "2166015-1",
        productDescription:
          "C 28g| P 12g| F 20g\nHummus served with pita chips.\n\nGood source of Vitamin E",
        productName: "Hummus",
        imageURL:
          "https://resizer.deliverect.com/72PxglK3MSw736Em3OxAtr0nEkCtbdmogjJvF3gGaJo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjAyLTY1MDgzMzQ1ZGViYWM1MDAxZjY1N2Q4OC5qcGc=",
        productCalories: 340,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 12,
          fat: 20,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 410,
    totalFmcCentsPrice: 410,
    totalFiatCentsPriceOriginal: 410,
    totalExternalFiatCentsPrice: 410,
    totalFmcCentsPriceOriginal: 410,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:07:51.168Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:09:03.920Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:09:14.256Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:19:47.897Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:19:47.897Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:26:44.314Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:31:28.259Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:07:51.168Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:07:57.400Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:07:51.168Z",
    updatedAt: "2024-10-24T05:31:28.601Z",
    fleetInfo: null,
  },
  {
    id: "6719d58ab7a34cd0923da981",
    sid: "241024-650619",
    coupons: [],
    menuId: "67196444b7a34cd0923c7414",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6719d589b7a34cd0923da976",
    firstName: "Maryam alibrahim",
    lastName: null,
    phoneNumber: "+96596691681",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6533ed8b1bfa57c5db00b536",
    delivery: {
      id: "6719d589b7a34cd0923da976",
      customerId: "6533ed8b1bfa57c5db00b536",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f51ecbee3a746f7f1b9999",
        name: "Work",
        lng: 48.05632587522268,
        lat: 29.29656125116268,
        city: "Kuwait",
        area: "Bayan",
        block: "8",
        street: "1",
        building: "School alsharouq",
        floor: "",
        flat: "",
        phone: "+96596691681",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-26T08:43:55.209Z",
        updatedAt: "2024-10-24T05:00:04.336Z",
      },
      deliverectValidationId: "6719d589a7e4fe37215fa949",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:15:13.315Z",
      estimatedDeliveryTime: "2024-10-24T05:30:11.549Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:02:00.000Z",
      orderId: "6719d58ab7a34cd0923da981",
      createdAt: "2024-10-24T05:05:13.428Z",
      updatedAt: "2024-10-24T05:07:07.819Z",
    },
    items: [
      {
        productId: "67196444b7a34cd0923c7436",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 26,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196444b7a34cd0923c7450",
        productPLU: "2202050-1",
        productDescription:
          "C 27g | P 1.5g | F 0g\nOrange \n\nGood source of Vitamin C",
        productName: "Orange Citrus",
        imageURL:
          "https://resizer.deliverect.com/92Cgq5w07iSWvqOzcojq0Egx0BDIxsVNTfIhlYhtUmU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTMtNjY1OTE5ODQ0MjllZTgwN2Q2OWQxYmZkLnBuZw==",
        productCalories: 116,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 1.5,
          fat: null,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:05:14.197Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:07:05.483Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:19:47.693Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:21:37.880Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:21:37.880Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:21:59.761Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:40:22.319Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:05:14.197Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:05:25.875Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:05:14.197Z",
    updatedAt: "2024-10-24T05:40:22.653Z",
    fleetInfo: null,
  },
  {
    id: "6719d4e6b7a34cd0923da2a6",
    sid: "241024-117755",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719d4e5b7a34cd0923da295",
    firstName: "Huda Khalid",
    lastName: null,
    phoneNumber: "+96566813510",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c6743f75a6aa442d0fd77d",
    delivery: {
      id: "6719d4e5b7a34cd0923da295",
      customerId: "64c6743f75a6aa442d0fd77d",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "64eb27933b8f1d06c4d226b4",
        name: "Home",
        lng: 47.966901026666164,
        lat: 29.277391865322976,
        city: "Al Farwaniyah",
        area: "Al Farwaniyah",
        block: "3 ",
        street: "108 St",
        building: "11",
        floor: "7",
        flat: "14",
        phone: "+96566813510",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-27T10:38:11.644Z",
        updatedAt: "2024-05-29T16:17:56.867Z",
      },
      deliverectValidationId: "6719d4e5c6ffa8c03b1d57a7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:12:29.284Z",
      estimatedDeliveryTime: "2024-10-24T05:27:28.041Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:59:00.000Z",
      orderId: "6719d4e6b7a34cd0923da2a6",
      createdAt: "2024-10-24T05:02:29.399Z",
      updatedAt: "2024-10-24T05:04:07.923Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c72b1",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        nutritionInfo: {
          carbs: 5,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72b2",
        productPLU: "2202039-1",
        productDescription: "C 8.6g | P 0.6g | F 0g\nTurmeric, Orange & Honey",
        productName: "Energy Shot",
        imageURL:
          "https://resizer.deliverect.com/ebvnxevyu9pax93CwcUbxaXHU29E4Bx-a8lOaqc9oPI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZW5lcmd5JTIwc2hvdC02NjQxZGFlZjVjNmRlZjAxYzMzYzIzMzMucG5n",
        productCalories: 37,
        quantity: 1,
        nutritionInfo: {
          carbs: 8.6,
          protein: 0.6,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72b4",
        productPLU: "2202041-1",
        productDescription:
          "C 5.3g | P 0.8g | F 0g\nBeetroot, Grapefruit & Himalayan Salt",
        productName: "Heart Beet Shot",
        imageURL:
          "https://resizer.deliverect.com/hr_xtMDdUpGtwUgywZHAGVPEYM_cOE7XRFgrZu-SPkU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaGVhcnRiZWV0JTIwc2hvdC02NjQxZGFmZjc0YTNlMTU5MTYzYWJhNjkucG5n",
        productCalories: 25,
        quantity: 1,
        nutritionInfo: {
          carbs: 5.3,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72a1",
        productPLU: "2202049-1",
        productDescription:
          "C 37g | P 0.75g | F 0g\nMango, lemon\n\nGood source of Vitamin C",
        productName: "Mango Zest",
        imageURL:
          "https://resizer.deliverect.com/Z5A3Et0Kx8-jsClnBJortO-cfJp_NB50ODoTnmv5LqI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTItNjY1OTE4ZTJlYjE5ZTYwZTlkMGZkYjZiLnBuZw==",
        productCalories: 156,
        quantity: 1,
        nutritionInfo: {
          carbs: 37,
          protein: 0.75,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 390,
    totalFmcCentsPrice: 390,
    totalFiatCentsPriceOriginal: 390,
    totalExternalFiatCentsPrice: 390,
    totalFmcCentsPriceOriginal: 390,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:02:30.115Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:04:05.485Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:05:24.652Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:06:04.546Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:06:04.546Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:10:50.783Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:20:39.402Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:02:30.115Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:02:36.202Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:02:30.115Z",
    updatedAt: "2024-10-24T05:20:39.734Z",
    fleetInfo: null,
  },
  {
    id: "6719d48fb7a34cd0923d992f",
    sid: "241024-957467",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719d48eb7a34cd0923d9925",
    firstName: "Rawan AlMajed",
    lastName: null,
    phoneNumber: "+96569967989",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65c111d3914bef1d8301b37b",
    delivery: {
      id: "6719d48eb7a34cd0923d9925",
      customerId: "65c111d3914bef1d8301b37b",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65c268414a64488bb6499e1c",
        name: "Work",
        lng: 47.99198459999999,
        lat: 29.3778691,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "shuhada st",
        street: "Al-Shuhada St",
        building: "NBK building",
        floor: "",
        flat: "",
        phone: "+96569967989",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-02-06T17:11:29.745Z",
        updatedAt: "2024-10-24T04:03:59.531Z",
      },
      deliverectValidationId: "6719d48e1821405a382d6242",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:11:02.167Z",
      estimatedDeliveryTime: "2024-10-24T05:26:00.900Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:58:00.000Z",
      orderId: "6719d48fb7a34cd0923d992f",
      createdAt: "2024-10-24T05:01:02.294Z",
      updatedAt: "2024-10-24T05:03:05.555Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c703a",
        productPLU: "2202043-1",
        productDescription: "C 3.8g | P 1.5g | F 0.5g\nColumbia coffee, Water",
        productName: "Cold Brew",
        imageURL:
          "https://resizer.deliverect.com/vJfaq3WgcPkWREtbcv57BpeV4cJZHp0DfxbTLKoOq48/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY29sZCUyMGJyZXctNjY0MWRhZTA2YTdhYTY5NGFiMjI3ZjkzLnBuZw==",
        productCalories: 25,
        quantity: 1,
        nutritionInfo: {
          carbs: 3.8,
          protein: 1.5,
          fat: 0.5,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c701d",
        productPLU: "2167004-1",
        productDescription:
          "C 43g| P 16g| F 19g\nLight halloumi & black olives with zaatar pesto & ranch in multigrain bread. \n\nGood source of calcium & protein",
        productName: "Halloumi Sandwich",
        imageURL:
          "https://resizer.deliverect.com/kxZW-LHRBXZhOao_mcBsk7OjD7py5CcDqaceAnZntvQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaGFsbG91bWktNjRiN2I2NDZlNGQ1YzMwMDE4YjY1YjZkLnBuZw==",
        productCalories: 407,
        quantity: 1,
        nutritionInfo: {
          carbs: 43,
          protein: 16,
          fat: 19,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:01:03.030Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:03:04.003Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:06:15.731Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:13:19.833Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:13:19.833Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:13:49.693Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:36:16.599Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:01:03.030Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:01:14.425Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T05:01:03.030Z",
    updatedAt: "2024-10-24T05:36:17.042Z",
    fleetInfo: null,
  },
  {
    id: "6719d451b7a34cd0923d9172",
    sid: "241024-471045",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719d450b7a34cd0923d90fd",
    firstName: "ABDULLAH ALKHARS",
    lastName: null,
    phoneNumber: "+96599636973",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c3cb1b75a6aa442d0f12c2",
    delivery: {
      id: "6719d450b7a34cd0923d90fd",
      customerId: "64c3cb1b75a6aa442d0f12c2",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64f015b798e65fe43e5d95bb",
        name: "kuwait flour mills & bakeries company",
        lng: 47.936997413635254,
        lat: 29.34722666953339,
        city: "Shuwaikh Industrial",
        area: "Shuwaikh Industrial 1",
        block: "No block",
        street: "Jamal Abdul Nasser Street",
        building: "800004",
        floor: "7",
        flat: "",
        phone: "+96599636973",
        additionalInfo: "Main security ",
        isSelected: true,
        createdAt: "2023-08-31T04:23:19.007Z",
        updatedAt: "2024-10-24T04:59:22.104Z",
      },
      deliverectValidationId: "6719d450dda2cbde33619668",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:10:00.215Z",
      estimatedDeliveryTime: "2024-10-24T05:24:58.867Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:57:00.000Z",
      orderId: "6719d451b7a34cd0923d9172",
      createdAt: "2024-10-24T05:00:00.308Z",
      updatedAt: "2024-10-24T05:02:04.767Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7010",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:00:01.116Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:02:03.109Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:06:15.607Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:12:58.087Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:12:58.088Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:20:09.974Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:37:13.628Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T05:00:01.116Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:00:26.724Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T05:00:01.116Z",
    updatedAt: "2024-10-24T05:37:13.969Z",
    fleetInfo: null,
  },
  {
    id: "6719d44ab7a34cd0923d8f5f",
    sid: "241024-391134",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719d449b7a34cd0923d8f43",
    firstName: "موضي سعد الحريبي",
    lastName: null,
    phoneNumber: "+96551220032",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66a20ebdac4eb4f3eef53c06",
    delivery: {
      id: "6719d449b7a34cd0923d8f43",
      customerId: "66a20ebdac4eb4f3eef53c06",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66e91755ced6c5247e875c24",
        name: "المدرسه",
        lng: 47.87381167992845,
        lat: 29.303497314453125,
        city: "الأندلس",
        area: "الأندلس",
        block: "٩",
        street: "١٠١",
        building: "مدرسه حواء بنت يزيد ثانويه",
        floor: "١",
        flat: "الاقتصاد المنزلي",
        phone: "+96551220032",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-17T05:44:53.001Z",
        updatedAt: "2024-10-24T04:56:27.396Z",
      },
      deliverectValidationId: "6719d449cc78686e3f1ac094",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:09:53.610Z",
      estimatedDeliveryTime: "2024-10-24T05:24:52.553Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:56:00.000Z",
      orderId: "6719d44ab7a34cd0923d8f5f",
      createdAt: "2024-10-24T04:59:53.703Z",
      updatedAt: "2024-10-24T05:01:10.383Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c7288",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 26,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c729b",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 340,
    totalExternalFiatCentsPrice: 340,
    totalFmcCentsPriceOriginal: 340,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:59:54.462Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:01:09.252Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:01:26.790Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:10:08.477Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:10:08.478Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:22:34.317Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:47:43.377Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:59:54.462Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:00:15.275Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:59:54.462Z",
    updatedAt: "2024-10-24T05:47:43.919Z",
    fleetInfo: null,
  },
  {
    id: "6719d448b7a34cd0923d8f27",
    sid: "241024-881861",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719d447b7a34cd0923d8ed5",
    firstName: "tareq almutawa ",
    lastName: null,
    phoneNumber: "+96594473130",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c77ac975a6aa442d1086a9",
    delivery: {
      id: "6719d447b7a34cd0923d8ed5",
      customerId: "64c77ac975a6aa442d1086a9",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65448a3af91376d39465865e",
        name: "CBK",
        lng: 47.9751026,
        lat: 29.3774787,
        city: "Al Kuwayt",
        area: "Jibla",
        block: "0",
        street: "Mubarak Al Kabeer St",
        building: "0",
        floor: "Mezzanine ",
        flat: "",
        phone: "+96594473130",
        additionalInfo:
          "commercial bank of kuwait, head office, stop at back door please",
        isSelected: true,
        createdAt: "2023-11-03T05:50:50.096Z",
        updatedAt: "2024-10-23T04:21:21.471Z",
      },
      deliverectValidationId: "6719d4476a3e6800dd06f18e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:09:51.557Z",
      estimatedDeliveryTime: "2024-10-24T05:24:49.975Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:56:00.000Z",
      orderId: "6719d448b7a34cd0923d8f27",
      createdAt: "2024-10-24T04:59:51.737Z",
      updatedAt: "2024-10-24T05:01:08.520Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7019",
        productPLU: "2167023-1",
        productDescription:
          "C 29g | P 22g | F 16g\nScrambled eggs with mushroom, green olives and feta cheese in a tortilla wrap\n\nGOOD SOURCE OF PROTEIN, MINERALS",
        productName: "Mushroom egg wrap",
        imageURL:
          "https://resizer.deliverect.com/icCk6VyiEI1LioDbIHa0TjQikuisfIyU0FZUoDvsxdY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAyLTY2M2ZmM2Q0ZGI0ZjMyZjZhNTZmMGY4MC5wbmc=",
        productCalories: 348,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 22,
          fat: 16,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "no mushroom",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7022",
        productPLU: "2166015-1",
        productDescription:
          "C 28g| P 12g| F 20g\nHummus served with pita chips.\n\nGood source of Vitamin E",
        productName: "Hummus",
        imageURL:
          "https://resizer.deliverect.com/72PxglK3MSw736Em3OxAtr0nEkCtbdmogjJvF3gGaJo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjAyLTY1MDgzMzQ1ZGViYWM1MDAxZjY1N2Q4OC5qcGc=",
        productCalories: 340,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 12,
          fat: 20,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c703f",
        productPLU: "2202040-1",
        productDescription: "C 4.8g | P 0g | F 0g\nOrange, Grapefruit & Lemon",
        productName: "Immunity Shot",
        imageURL:
          "https://resizer.deliverect.com/WkO7ZBCzCZBVTRau0FCMoAizLyoqJmikHQl5iJRWhJs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaW1tdW5pdHklMjBzaG90LTY2NDFkYjEwYWIxMzE0ZDg1Njc3OGNkZS5wbmc=",
        productCalories: 19,
        quantity: 1,
        nutritionInfo: {
          carbs: 4.8,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:59:52.779Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:01:07.343Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:06:15.504Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:19:43.500Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:19:43.500Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:26:55.496Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:42:38.633Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:59:52.779Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T05:00:04.240Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:59:52.779Z",
    updatedAt: "2024-10-24T05:42:38.974Z",
    fleetInfo: null,
  },
  {
    id: "6719d421b7a34cd0923d8bec",
    sid: "241024-265060",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719d421b7a34cd0923d8bdb",
    firstName: "Fahad Al Mutawa",
    lastName: null,
    phoneNumber: "+96550171467",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f3963b7f0597f029e5e767",
    delivery: {
      id: "6719d421b7a34cd0923d8bdb",
      customerId: "66f3963b7f0597f029e5e767",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f3d4b61294dc02ca98c1f5",
        name: "Work",
        lng: 47.94370796531439,
        lat: 29.35457728891954,
        city: "Al Kuwayt",
        area: "Shuwaikh Residential",
        block: "7",
        street: "Jamal abdulnasser st",
        building: "Kuwait petroleum corporation",
        floor: "Gate 1",
        flat: "Gate 1",
        phone: "+96550171467",
        additionalInfo: "Stay at gate 1 and call me",
        isSelected: true,
        createdAt: "2024-09-25T09:15:34.426Z",
        updatedAt: "2024-09-25T09:15:34.426Z",
      },
      deliverectValidationId: "6719d421c6ffa8c03b1d1aa9",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T05:09:13.079Z",
      estimatedDeliveryTime: "2024-10-24T05:24:11.642Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:56:00.000Z",
      orderId: "6719d421b7a34cd0923d8bec",
      createdAt: "2024-10-24T04:59:13.181Z",
      updatedAt: "2024-10-24T05:01:07.737Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c701d",
        productPLU: "2167004-1",
        productDescription:
          "C 43g| P 16g| F 19g\nLight halloumi & black olives with zaatar pesto & ranch in multigrain bread. \n\nGood source of calcium & protein",
        productName: "Halloumi Sandwich",
        imageURL:
          "https://resizer.deliverect.com/kxZW-LHRBXZhOao_mcBsk7OjD7py5CcDqaceAnZntvQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaGFsbG91bWktNjRiN2I2NDZlNGQ1YzMwMDE4YjY1YjZkLnBuZw==",
        productCalories: 407,
        quantity: 1,
        nutritionInfo: {
          carbs: 43,
          protein: 16,
          fat: 19,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7037",
        productPLU: "2202036-1",
        productDescription:
          "C 20g | P 19.2g | F 4.7g\nCocoa powder, Milk, Stevia & WHEY Protein",
        productName: "Chocolate Protein Shake",
        imageURL:
          "https://resizer.deliverect.com/TjoR9Z9GyFIBSfx0VgVYXmRJcSGxnQwDMGEZg2k_4G4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hvY29sYXRlJTIwcHJvdGVpbiUyMHNoYWtlLTY2NDFkYWJmMGMyNGQ5MjVmY2QzZmE4Yi5wbmc=",
        productCalories: 200,
        quantity: 1,
        nutritionInfo: {
          carbs: 20,
          protein: 19.2,
          fat: 4.7,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:59:13.962Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:01:04.634Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T05:06:15.404Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:13:08.970Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:13:08.970Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:20:39.244Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:31:39.524Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:59:13.962Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:59:25.597Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:59:13.962Z",
    updatedAt: "2024-10-24T05:31:39.857Z",
    fleetInfo: null,
  },
  {
    id: "6719d1d4b7a34cd0923d69cc",
    sid: "241024-954794",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719d1d4b7a34cd0923d69c3",
    firstName: "Yousef",
    lastName: null,
    phoneNumber: "+96566612130",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6680a6f08c15153ac8974cbc",
    delivery: {
      id: "6719d1d4b7a34cd0923d69c3",
      customerId: "6680a6f08c15153ac8974cbc",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6680a81486fe4b6ad1aa7b28",
        name: "القرين",
        lng: 48.07027906179428,
        lat: 29.1951854348033,
        city: "Kuwait",
        area: "القرين",
        block: "4",
        street: "5",
        building: "28",
        floor: "",
        flat: "",
        phone: "+96566612130",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-30T00:34:28.592Z",
        updatedAt: "2024-06-30T00:34:28.592Z",
      },
      deliverectValidationId: "6719d1d4c6ffa8c03b1c4ba8",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:59:24.089Z",
      estimatedDeliveryTime: "2024-10-24T05:14:22.836Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:46:00.000Z",
      orderId: "6719d1d4b7a34cd0923d69cc",
      createdAt: "2024-10-24T04:49:24.181Z",
      updatedAt: "2024-10-24T04:51:04.866Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c7143",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:49:24.868Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:51:03.392Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:53:51.259Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:07:39.699Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:07:39.699Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:08:07.283Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:44:26.796Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:49:24.868Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:49:36.530Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:49:24.868Z",
    updatedAt: "2024-10-24T05:44:27.133Z",
    fleetInfo: null,
  },
  {
    id: "6719d194b7a34cd0923d67a2",
    sid: "241024-350687",
    coupons: [],
    menuId: "67196444b7a34cd0923c7414",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6719d193b7a34cd0923d6793",
    firstName: "Alaa Naqi",
    lastName: null,
    phoneNumber: "+96599890165",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "654762c29746b5cbf57fbc41",
    delivery: {
      id: "6719d193b7a34cd0923d6793",
      customerId: "654762c29746b5cbf57fbc41",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "658276d6bdf4fa90df542eea",
        name: "Work",
        lng: 48.033166006207466,
        lat: 29.32833360998727,
        city: "Jabriya",
        area: "Jabriya",
        block: "4",
        street: "Hussein Bin Ali Alroumi Road",
        building: "Abdulmohsen Alabdulrazzag Health Science Sentre",
        floor: "Kuwait University ",
        flat: "faculty of medicine ",
        phone: "+96599890165",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-20T05:08:38.462Z",
        updatedAt: "2024-10-23T04:53:10.175Z",
      },
      deliverectValidationId: "6719d193a7e4fe37215da600",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:58:19.796Z",
      estimatedDeliveryTime: "2024-10-24T05:13:18.511Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:45:00.000Z",
      orderId: "6719d194b7a34cd0923d67a2",
      createdAt: "2024-10-24T04:48:19.893Z",
      updatedAt: "2024-10-24T04:50:05.900Z",
    },
    items: [
      {
        productId: "67196444b7a34cd0923c7436",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 26,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196444b7a34cd0923c7451",
        productPLU: "2202051-1",
        productDescription:
          "C 51g | P 0.25g | F 0g\nWatermelon, lemon, mint\n\nGood source of Vitamin C",
        productName: "Waterlemon",
        imageURL:
          "https://resizer.deliverect.com/Z6SJv4l04sxDRKAG7bkaZpBT06F5O-JLEkDJxwTujFY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTQtNjY1OTE5Y2UxNmEzMzQyNTY5OGVhM2NiLnBuZw==",
        productCalories: 194,
        quantity: 1,
        nutritionInfo: {
          carbs: 51,
          protein: 0.25,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 340,
    totalExternalFiatCentsPrice: 340,
    totalFmcCentsPriceOriginal: 340,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:48:20.724Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:50:04.359Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:51:20.200Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:01:12.773Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:01:12.774Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:01:52.893Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:15:21.476Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:48:20.724Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:48:32.311Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:48:20.724Z",
    updatedAt: "2024-10-24T05:15:21.812Z",
    fleetInfo: null,
  },
  {
    id: "6719d185b7a34cd0923d6652",
    sid: "241024-538128",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719d185b7a34cd0923d6647",
    firstName: "Wadhha ",
    lastName: null,
    phoneNumber: "+96566009623",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66dd6345aa1645e3a5088466",
    delivery: {
      id: "6719d185b7a34cd0923d6647",
      customerId: "66dd6345aa1645e3a5088466",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6710a7aaf495b693cd2a41a8",
        name: "مدرسه رابعه العدويه",
        lng: 48.07436725124717,
        lat: 29.173694885179874,
        city: "Kuwait",
        area: "Jaber Al Ali",
        block: "2",
        street: "2",
        building: "2",
        floor: "2",
        flat: "2",
        phone: "+96566009623",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-17T05:59:06.023Z",
        updatedAt: "2024-10-24T04:42:52.684Z",
      },
      deliverectValidationId: "6719d1858ee7d1c25626d12b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:58:05.134Z",
      estimatedDeliveryTime: "2024-10-24T05:13:03.919Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:45:00.000Z",
      orderId: "6719d185b7a34cd0923d6652",
      createdAt: "2024-10-24T04:48:05.224Z",
      updatedAt: "2024-10-24T04:50:06.069Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c714e",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c714c",
        productPLU: "2167023-1",
        productDescription:
          "C 29g | P 22g | F 16g\nScrambled eggs with mushroom, green olives and feta cheese in a tortilla wrap\n\nGOOD SOURCE OF PROTEIN, MINERALS",
        productName: "Mushroom egg wrap",
        imageURL:
          "https://resizer.deliverect.com/icCk6VyiEI1LioDbIHa0TjQikuisfIyU0FZUoDvsxdY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAyLTY2M2ZmM2Q0ZGI0ZjMyZjZhNTZmMGY4MC5wbmc=",
        productCalories: 348,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 22,
          fat: 16,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 400,
    totalExternalFiatCentsPrice: 400,
    totalFmcCentsPriceOriginal: 400,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:48:05.941Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:50:05.567Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:50:22.791Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:03:04.132Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:03:04.132Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:07:56.076Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:28:27.282Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:48:05.941Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:48:17.487Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:48:05.941Z",
    updatedAt: "2024-10-24T05:28:27.624Z",
    fleetInfo: null,
  },
  {
    id: "6719d14bb7a34cd0923d6185",
    sid: "241024-208242",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719d14ab7a34cd0923d6156",
    firstName: "Badreyah",
    lastName: null,
    phoneNumber: "+96551292262",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66fe4157ced7b08f00535c72",
    delivery: {
      id: "6719d14ab7a34cd0923d6156",
      customerId: "66fe4157ced7b08f00535c72",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6705fcef218a8c4af2f71ef1",
        name: "School",
        lng: 47.96414203941822,
        lat: 29.310140583165314,
        city: "Kuwait City",
        area: "Yarmouk",
        block: "3",
        street: "Ahmad Yousif Althaqeb St",
        building: "Bader alrifai school",
        floor: "",
        flat: "",
        phone: "+96599849424",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-09T03:47:59.826Z",
        updatedAt: "2024-10-24T04:46:40.396Z",
      },
      deliverectValidationId: "6719d14a9d15dbbf9f805bf7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:57:06.801Z",
      estimatedDeliveryTime: "2024-10-24T05:12:05.323Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:44:00.000Z",
      orderId: "6719d14bb7a34cd0923d6185",
      createdAt: "2024-10-24T04:47:06.893Z",
      updatedAt: "2024-10-24T04:49:05.285Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c729b",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c7291",
        productPLU: "2167004-1",
        productDescription:
          "C 43g| P 16g| F 19g\nLight halloumi & black olives with zaatar pesto & ranch in multigrain bread. \n\nGood source of calcium & protein",
        productName: "Halloumi Sandwich",
        imageURL:
          "https://resizer.deliverect.com/kxZW-LHRBXZhOao_mcBsk7OjD7py5CcDqaceAnZntvQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaGFsbG91bWktNjRiN2I2NDZlNGQ1YzMwMDE4YjY1YjZkLnBuZw==",
        productCalories: 407,
        quantity: 1,
        nutritionInfo: {
          carbs: 43,
          protein: 16,
          fat: 19,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:47:07.674Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:49:03.698Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:50:05.424Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:56:54.784Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:56:54.785Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:00:48.498Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:13:17.265Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:47:07.674Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:47:19.327Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:47:07.674Z",
    updatedAt: "2024-10-24T05:13:17.600Z",
    fleetInfo: null,
  },
  {
    id: "6719d047b7a34cd0923d553b",
    sid: "241024-857497",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719d046b7a34cd0923d5524",
    firstName: "Sleiman Haji",
    lastName: null,
    phoneNumber: "+96599471117",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c52ed775a6aa442d0f830f",
    delivery: {
      id: "6719d046b7a34cd0923d5524",
      customerId: "64c52ed775a6aa442d0f830f",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "65b20ecdf140bf59260650df",
        name: "PTC WORK",
        lng: 48.079758659005165,
        lat: 29.089007831204356,
        city: "Ahmadi",
        area: "Mid Ahmadi",
        block: "9",
        street: "16",
        building: "Petroleum training center ",
        floor: "Gate 2 ",
        flat: "",
        phone: "+96599471117",
        additionalInfo: "Call once at gate number 2 ",
        isSelected: true,
        createdAt: "2024-01-25T07:33:33.636Z",
        updatedAt: "2024-10-22T04:47:53.890Z",
      },
      deliverectValidationId: "6719d0466a3e6800dd0547ff",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:52:46.192Z",
      estimatedDeliveryTime: "2024-10-24T05:07:45.172Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:39:00.000Z",
      orderId: "6719d047b7a34cd0923d553b",
      createdAt: "2024-10-24T04:42:46.311Z",
      updatedAt: "2024-10-24T04:44:05.910Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c7144",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c7157",
        productPLU: "2202032-1",
        productDescription:
          "C 15.6g | P 0.9g | F 0.7g\nGreen apple, pineapple,watermelon & orange",
        productName: "Classic Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/2sV5-NAlUviox1mImQPHbEENE5_b1KyoHVL0t_T2CVE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2xhc3NpYyUyMGZydWl0LTY2NDM3YTM1ZTdlZDJhNDdlMjFhMzFmZC5wbmc=",
        productCalories: 73,
        quantity: 1,
        nutritionInfo: {
          carbs: 15.6,
          protein: 0.9,
          fat: 0.7,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c7170",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        nutritionInfo: {
          carbs: 5,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:42:47.023Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:44:04.258Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:44:44.618Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:53:40.235Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:53:40.236Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:57:39.753Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:38:00.852Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:42:47.023Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:42:58.452Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:42:47.023Z",
    updatedAt: "2024-10-24T05:38:01.382Z",
    fleetInfo: null,
  },
  {
    id: "6719d027b7a34cd0923d51e7",
    sid: "241024-887743",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719d026b7a34cd0923d51d6",
    firstName: "Hussain Alkazemi",
    lastName: null,
    phoneNumber: "+96598984242",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66d85151aa1645e3a5fe82cc",
    delivery: {
      id: "6719d026b7a34cd0923d51d6",
      customerId: "66d85151aa1645e3a5fe82cc",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67100627f495b693cd204432",
        name: "Home",
        lng: 47.99517687410116,
        lat: 29.274531679992553,
        city: "Zahra",
        area: "Zahra",
        block: "8",
        street: "822",
        building: "House 39",
        floor: "",
        flat: "",
        phone: "+96598984242",
        additionalInfo: "Please call when order arrives.",
        isSelected: true,
        createdAt: "2024-10-16T18:29:59.437Z",
        updatedAt: "2024-10-16T18:29:59.437Z",
      },
      deliverectValidationId: "6719d026cc78686e3f18ca38",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:52:14.532Z",
      estimatedDeliveryTime: "2024-10-24T05:07:13.188Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:39:00.000Z",
      orderId: "6719d027b7a34cd0923d51e7",
      createdAt: "2024-10-24T04:42:14.633Z",
      updatedAt: "2024-10-24T04:44:05.773Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c7292",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c7285",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c729d",
        productPLU: "2165020-1",
        productDescription:
          "C 10g | P 1g | F 6g\nPecan, caramel, milk chocolate",
        productName: "Pecan Turtle",
        imageURL:
          "https://resizer.deliverect.com/M_C4q-ZLQuwipzQEg6XeH4Pvq602HMW3vxgoWZ8KB3k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVjYW4lMjB0dXJ0bGUtNjY2MWNlNDc1MDRlYzgwNTk0NmU0YTkzLnBuZw==",
        productCalories: 100,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 1,
          fat: 6,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72a0",
        productPLU: "2165029-1",
        productDescription:
          "C 7g | P 1.4g | F 6g\nRoasted Pistachio, caramel, chocolate sea salt",
        productName: "Pistachio Choco Bite",
        imageURL:
          "https://resizer.deliverect.com/rc4xgt7ScqZ-uLdWjRdijowgfyk5KpqjTo2XDqyEBSo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGlzdGFjaGlvJTIwY2hvY28lMjBiaXRlLTY2OTkxOWY4ZTYyZTU5NWI3ZDIzYmZiMy5wbmc=",
        productCalories: 91,
        quantity: 1,
        nutritionInfo: {
          carbs: 7,
          protein: 1.4,
          fat: 6,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c728c",
        productPLU: "2167013-1",
        productDescription:
          "C 42g| P 28g| F 12g [%Popular%]\nBeef with our special sauce and sumac onion wrapped in tortilla.\n\nGood source of Vitamin B6 & Vitamin B12",
        productName: "Beef Shawarma",
        imageURL:
          "https://resizer.deliverect.com/DQl32OjdVbaHyJyht46g35g33tk0bsgQNbyyp4jWAdc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQmVlZiUyMHNoYXdhcm1hLTY1ZjZlOThhMzRiNTQ3YTg5NDYyNmEwZS5qcGc=",
        productCalories: 388,
        quantity: 1,
        nutritionInfo: {
          carbs: 42,
          protein: 28,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 570,
    totalFmcCentsPrice: 570,
    totalFiatCentsPriceOriginal: 570,
    totalExternalFiatCentsPrice: 570,
    totalFmcCentsPriceOriginal: 570,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:42:15.492Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:44:04.072Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:44:12.279Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:57:05.689Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:57:05.689Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:00:15.530Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:15:47.816Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:42:15.492Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:42:26.954Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:42:15.492Z",
    updatedAt: "2024-10-24T05:15:48.166Z",
    fleetInfo: null,
  },
  {
    id: "6719cfdfb7a34cd0923d4b46",
    sid: "241024-355252",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719cfdeb7a34cd0923d4b32",
    firstName: "Ahmad aleissa",
    lastName: null,
    phoneNumber: "+96599457900",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "652d14f01bfa57c5dbfa9778",
    delivery: {
      id: "6719cfdeb7a34cd0923d4b32",
      customerId: "652d14f01bfa57c5dbfa9778",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67187796b7a34cd0920120e2",
        name: "Work",
        lng: 47.9747679,
        lat: 29.3714489,
        city: "Al Kuwayt",
        area: "Jibla",
        block: "Baitek tower",
        street: "Ahmad Al Jaber St",
        building: "Baitak tower ",
        floor: "9",
        flat: "",
        phone: "+96599457900",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-23T04:12:06.707Z",
        updatedAt: "2024-10-24T04:39:05.745Z",
      },
      deliverectValidationId: "6719cfde7aed983727179441",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:51:02.480Z",
      estimatedDeliveryTime: "2024-10-24T05:06:01.113Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:38:00.000Z",
      orderId: "6719cfdfb7a34cd0923d4b46",
      createdAt: "2024-10-24T04:41:02.718Z",
      updatedAt: "2024-10-24T04:43:05.288Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7011",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7023",
        productPLU: "2166021-1",
        productDescription:
          "C 14g| P 2g| F 1g\nFresh pomegranate seeds.\n\nGOOD SOURCE OF VITAMIN  C",
        productName: "Fresh Pomegranate",
        imageURL:
          "https://resizer.deliverect.com/0QanPE0fkKGZvx0QoWjnfvfTbh0UdPExZ1vpzwL3kU0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNjYtNjUyOWY0MGY4NDhjZjcwMDE4YzBmYmIxLmpwZw==",
        productCalories: 70,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 2,
          fat: 1,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c703f",
        productPLU: "2202040-1",
        productDescription: "C 4.8g | P 0g | F 0g\nOrange, Grapefruit & Lemon",
        productName: "Immunity Shot",
        imageURL:
          "https://resizer.deliverect.com/WkO7ZBCzCZBVTRau0FCMoAizLyoqJmikHQl5iJRWhJs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaW1tdW5pdHklMjBzaG90LTY2NDFkYjEwYWIxMzE0ZDg1Njc3OGNkZS5wbmc=",
        productCalories: 19,
        quantity: 1,
        nutritionInfo: {
          carbs: 4.8,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:41:03.475Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:43:03.433Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:51:57.340Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:52:02.255Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:52:02.255Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:58:53.302Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:17:57.229Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:41:03.475Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:41:14.923Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:41:03.475Z",
    updatedAt: "2024-10-24T05:17:57.566Z",
    fleetInfo: null,
  },
  {
    id: "6719cfd3b7a34cd0923d4a58",
    sid: "241024-654543",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719cfd3b7a34cd0923d4a4f",
    firstName: "Maryam",
    lastName: null,
    phoneNumber: "+96566100331",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65311c701bfa57c5dbff032c",
    delivery: {
      id: "6719cfd3b7a34cd0923d4a4f",
      customerId: "65311c701bfa57c5dbff032c",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6641fbd25ca983b14af24bca",
        name: "Home",
        lng: 47.975005149495914,
        lat: 29.304595947265625,
        city: "Kuwait",
        area: "Yarmouk",
        block: "2",
        street: "1 jada 1",
        building: "27",
        floor: "",
        flat: "",
        phone: "+96566100331",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-13T11:38:58.150Z",
        updatedAt: "2024-10-18T07:46:04.006Z",
      },
      deliverectValidationId: "6719cfd28da25298e7548687",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:50:50.966Z",
      estimatedDeliveryTime: "2024-10-24T05:05:49.926Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:37:00.000Z",
      orderId: "6719cfd3b7a34cd0923d4a58",
      createdAt: "2024-10-24T04:40:51.062Z",
      updatedAt: "2024-10-24T04:42:07.766Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c7290",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:40:51.597Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:42:05.206Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:42:11.483Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T05:00:15.738Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T05:00:15.738Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:00:37.236Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:20:16.839Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:40:51.597Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:41:03.116Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:40:51.597Z",
    updatedAt: "2024-10-24T05:20:17.200Z",
    fleetInfo: null,
  },
  {
    id: "6719cfa3b7a34cd0923d4487",
    sid: "241024-936412",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719cfa2b7a34cd0923d4478",
    firstName: "Anfal",
    lastName: null,
    phoneNumber: "+96598732509",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "664073b366978e88ed0def93",
    delivery: {
      id: "6719cfa2b7a34cd0923d4478",
      customerId: "664073b366978e88ed0def93",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "664074285ca983b14ac04f6b",
        name: "Work",
        lng: 47.97259019226132,
        lat: 29.2000732421875,
        city: "Abdullah Al Mubarak Al Sabah",
        area: "Abdullah Al Mubarak Al Sabah",
        block: "0",
        street: "1",
        building: "Kuwait civil aviation ",
        floor: "",
        flat: "",
        phone: "+96598732509",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-12T07:47:52.701Z",
        updatedAt: "2024-10-23T07:33:42.050Z",
      },
      deliverectValidationId: "6719cfa272410ebb08ebd42c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:50:02.271Z",
      estimatedDeliveryTime: "2024-10-24T05:05:00.998Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:37:00.000Z",
      orderId: "6719cfa3b7a34cd0923d4487",
      createdAt: "2024-10-24T04:40:02.365Z",
      updatedAt: "2024-10-24T04:42:06.643Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c7284",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "Without guacamole ",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c729e",
        productPLU: "2165028-1",
        productDescription:
          "C 8.2g | P 1.4g | F 7g\nRoasted coconut, coconut powder, whipping cream, cocoa butter, white & milk chocolate",
        productName: "Roasted Coconut Truffle",
        imageURL:
          "https://resizer.deliverect.com/Fsqppf723HLU1qfvol46WqRv2KlRhKmHhGjrowxj0o8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY29jb251dCUyMHRydWZmbGUlMjAtNjY5OTE5OTI0NWU1NDA4NDgxNDQ5MDIwLnBuZw==",
        productCalories: 106,
        quantity: 2,
        nutritionInfo: {
          carbs: 8.2,
          protein: 1.4,
          fat: 7,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c729f",
        productPLU: "2165003-1",
        productDescription: "C 12g | P 2g | F 9g\n",
        productName: "Peanut Bites",
        imageURL:
          "https://resizer.deliverect.com/S2e9SlQ_6ewwX1Z-Pg_RtXB9e4OxquLa6-xOxnwoCJM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTMtNjRiN2JjNmFkMGE4N2MwMDFmZTYwZjBhLnBuZw==",
        productCalories: 139,
        quantity: 1,
        nutritionInfo: {
          carbs: 12,
          protein: 2,
          fat: 9,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 460,
    totalFmcCentsPrice: 460,
    totalFiatCentsPriceOriginal: 460,
    totalExternalFiatCentsPrice: 460,
    totalFmcCentsPriceOriginal: 460,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:40:03.102Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:42:03.973Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:42:16.656Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:57:16.764Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:57:16.764Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:58:07.808Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:17:19.235Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:40:03.102Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:40:14.588Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:40:03.102Z",
    updatedAt: "2024-10-24T05:17:19.573Z",
    fleetInfo: null,
  },
  {
    id: "6719cf3db7a34cd0923d3ee9",
    sid: "241024-724552",
    coupons: [],
    menuId: "67196444b7a34cd0923c7414",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6719cf3db7a34cd0923d3edb",
    firstName: "Mashael AlSabah ",
    lastName: null,
    phoneNumber: "+96599933391",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65818999357446f075c4c1b9",
    delivery: {
      id: "6719cf3db7a34cd0923d3edb",
      customerId: "65818999357446f075c4c1b9",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65819a3abdf4fa90df513859",
        name: "My house ",
        lng: 48.01413372159004,
        lat: 29.31381103384959,
        city: "Kuwait",
        area: "Surra",
        block: "2",
        street: "5 St",
        building: "25",
        floor: "",
        flat: "",
        phone: "+96599933391",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-19T13:27:22.859Z",
        updatedAt: "2023-12-19T13:27:22.859Z",
      },
      deliverectValidationId: "6719cf3d4cb7e660cdeeeb77",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:48:21.102Z",
      estimatedDeliveryTime: "2024-10-24T05:03:19.858Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:35:00.000Z",
      orderId: "6719cf3db7a34cd0923d3ee9",
      createdAt: "2024-10-24T04:38:21.202Z",
      updatedAt: "2024-10-24T04:40:05.089Z",
    },
    items: [
      {
        productId: "67196444b7a34cd0923c7432",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "67196444b7a34cd0923c7463",
            productPLU: "1907036-1",
            productName: "No Chilli Flakes",
            productDescription: "C 0g| P 0g| F 0g| cal 0| coins 0\n",
            productCalories: 0,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: null,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:38:21.870Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:40:03.452Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:40:16.621Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:56:43.937Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:56:43.937Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T05:03:12.285Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:12:09.647Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:38:21.870Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:38:33.356Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:38:21.870Z",
    updatedAt: "2024-10-24T05:12:10.007Z",
    fleetInfo: null,
  },
  {
    id: "6719ce4cb7a34cd0923d3925",
    sid: "241024-519655",
    coupons: [],
    menuId: "67196444b7a34cd0923c7414",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6719ce4ab7a34cd0923d38a9",
    firstName: "Sherifa Alzeer",
    lastName: null,
    phoneNumber: "+96596665726",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66d31ceed7546254fad1e3c7",
    delivery: {
      id: "6719ce4ab7a34cd0923d38a9",
      customerId: "66d31ceed7546254fad1e3c7",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66e08050ced6c5247e937921",
        name: "Home ",
        lng: 47.98927060401771,
        lat: 29.334732055664062,
        city: "Kuwait",
        area: "Rawda",
        block: "5",
        street: "57 St",
        building: "32",
        floor: "",
        flat: "",
        phone: "+96596665726",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-10T17:22:24.700Z",
        updatedAt: "2024-10-22T16:41:03.680Z",
      },
      deliverectValidationId: "6719ce4aff147771ad64bb7b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:44:18.701Z",
      estimatedDeliveryTime: "2024-10-24T04:59:17.505Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:31:00.000Z",
      orderId: "6719ce4cb7a34cd0923d3925",
      createdAt: "2024-10-24T04:34:18.993Z",
      updatedAt: "2024-10-24T04:36:13.892Z",
    },
    items: [
      {
        productId: "67196444b7a34cd0923c7436",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 26,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196444b7a34cd0923c7449",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196444b7a34cd0923c744a",
        productPLU: "2165008-1",
        productDescription: "C 13g| P 3g| F 10g",
        productName: "Snickers Bar",
        imageURL:
          "https://resizer.deliverect.com/qlfM-kU78NJZFB6H6YZxYvpH8UfR3_jKIa0YwqE7w20/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTItNjRiN2JjZGJhMDAxOGMwYmY0YTY2NTZiLnBuZw==",
        productCalories: 157,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 3,
          fat: 10,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 400,
    totalExternalFiatCentsPrice: 400,
    totalFmcCentsPriceOriginal: 400,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:34:20.178Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:36:12.335Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:36:21.445Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:55:22.012Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:55:22.013Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:58:22.876Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:11:57.236Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:34:20.178Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:34:31.680Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:34:20.178Z",
    updatedAt: "2024-10-24T05:11:57.584Z",
    fleetInfo: null,
  },
  {
    id: "6719cd7eb7a34cd0923d33c0",
    sid: "241024-539755",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719cd7eb7a34cd0923d33b3",
    firstName: "Bodour ",
    lastName: null,
    phoneNumber: "+96598533319",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66e87754aa1645e3a51eb1c9",
    delivery: {
      id: "6719cd7eb7a34cd0923d33b3",
      customerId: "66e87754aa1645e3a51eb1c9",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66e87803ced6c5247e7e42bb",
        name: "Home ",
        lng: 48.0985597364305,
        lat: 29.182815551757812,
        city: "Kuwait",
        area: "Mubarak Al-Kabeer",
        block: "7",
        street: "21",
        building: "16",
        floor: "",
        flat: "",
        phone: "+96598533319",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-16T18:25:07.867Z",
        updatedAt: "2024-09-16T18:25:07.867Z",
      },
      deliverectValidationId: "6719cd7d982ae1ad6d26a785",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:40:53.724Z",
      estimatedDeliveryTime: "2024-10-24T04:55:52.518Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:27:00.000Z",
      orderId: "6719cd7eb7a34cd0923d33c0",
      createdAt: "2024-10-24T04:30:54.016Z",
      updatedAt: "2024-10-24T04:32:04.991Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c7143",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c715d",
        productPLU: "2165028-1",
        productDescription:
          "C 8.2g | P 1.4g | F 7g\nRoasted coconut, coconut powder, whipping cream, cocoa butter, white & milk chocolate",
        productName: "Roasted Coconut Truffle",
        imageURL:
          "https://resizer.deliverect.com/Fsqppf723HLU1qfvol46WqRv2KlRhKmHhGjrowxj0o8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY29jb251dCUyMHRydWZmbGUlMjAtNjY5OTE5OTI0NWU1NDA4NDgxNDQ5MDIwLnBuZw==",
        productCalories: 106,
        quantity: 1,
        nutritionInfo: {
          carbs: 8.2,
          protein: 1.4,
          fat: 7,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c7163",
        productPLU: "2202052-1",
        productDescription:
          "C 13g | P 0g | F 0g\nPomegranate, lemon\n\nGood source of Vitamin C",
        productName: "Pomade",
        imageURL:
          "https://resizer.deliverect.com/JpImLqr5JfNLPk8gTnl6NoZd9i6mdIhqPB4QA7H5lw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEtNjY1OTFhNzgxNmEzMzQyNTY5OGVhM2QxLnBuZw==",
        productCalories: 54,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 490,
    totalFmcCentsPrice: 490,
    totalFiatCentsPriceOriginal: 490,
    totalExternalFiatCentsPrice: 490,
    totalFmcCentsPriceOriginal: 490,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:30:54.937Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:32:03.619Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:33:00.408Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:44:33.761Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:44:33.762Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:52:49.700Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:10:28.478Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:30:54.937Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:31:06.617Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:30:54.937Z",
    updatedAt: "2024-10-24T05:10:28.809Z",
    fleetInfo: null,
  },
  {
    id: "6719cd2bb7a34cd0923d2fe9",
    sid: "241024-501460",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719cd2bb7a34cd0923d2fe1",
    firstName: "Durrah Alshammeri",
    lastName: null,
    phoneNumber: "+96550225556",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "662255b3b0d178e6320a31a0",
    delivery: {
      id: "6719cd2bb7a34cd0923d2fe1",
      customerId: "662255b3b0d178e6320a31a0",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66fe8438218a8c4af2dc30d7",
        name: "Suaad Alsayeq School",
        lng: 48.08616243302822,
        lat: 29.175782275485503,
        city: "Kuwait",
        area: "جابر العلي",
        block: "1",
        street: "1",
        building: "School",
        floor: "",
        flat: "",
        phone: "+96550225556",
        additionalInfo: "English department ",
        isSelected: true,
        createdAt: "2024-10-03T11:47:04.286Z",
        updatedAt: "2024-10-22T03:47:50.133Z",
      },
      deliverectValidationId: "6719cd2bc6ffa8c03b19aad8",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:39:31.149Z",
      estimatedDeliveryTime: "2024-10-24T04:54:29.985Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:26:00.000Z",
      orderId: "6719cd2bb7a34cd0923d2fe9",
      createdAt: "2024-10-24T04:29:31.248Z",
      updatedAt: "2024-10-24T04:31:06.944Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c7149",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 2,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "Please add katchup",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 260,
    totalFmcCentsPrice: 260,
    totalFiatCentsPriceOriginal: 260,
    totalExternalFiatCentsPrice: 260,
    totalFmcCentsPriceOriginal: 260,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:29:31.879Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:31:05.201Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:32:55.047Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:52:49.794Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:52:49.795Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:53:11.734Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:19:10.285Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:29:31.879Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:29:43.333Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:29:31.879Z",
    updatedAt: "2024-10-24T05:19:10.624Z",
    fleetInfo: null,
  },
  {
    id: "6719cd01b7a34cd0923d2da2",
    sid: "241024-950031",
    coupons: [],
    menuId: "67196444b7a34cd0923c7414",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6719cd01b7a34cd0923d2d99",
    firstName: "Ali alnaser ",
    lastName: null,
    phoneNumber: "+96599543556",
    paymentType: "fmcCents",
    type: "scheduleOrder",
    customerId: "64d93dabdfecd0088b52962a",
    delivery: {
      id: "6719cd01b7a34cd0923d2d99",
      customerId: "64d93dabdfecd0088b52962a",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "650ffd280a91a2efcd4d1040",
        name: "Ali Alnaser ",
        lng: 48.06163996171589,
        lat: 29.294723510742188,
        city: "Kuwait",
        area: "Bayan",
        block: "10 ",
        street: "1 avn 15",
        building: "17",
        floor: "",
        flat: "",
        phone: "+96599543556",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-09-24T09:11:04.625Z",
        updatedAt: "2024-10-19T09:03:58.044Z",
      },
      deliverectValidationId: "6719cd01827135858b910e02",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:38:49.282Z",
      estimatedDeliveryTime: "2024-10-24T06:30:00.000Z",
      estimatedDeliveryTimeEnd: "2024-10-24T06:36:00.000Z",
      orderId: "6719cd01b7a34cd0923d2da2",
      createdAt: "2024-10-24T04:28:49.385Z",
      updatedAt: "2024-10-24T05:55:05.058Z",
    },
    items: [
      {
        productId: "67196444b7a34cd0923c7432",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:28:49.881Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T05:55:08.501Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T06:02:38.388Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T06:02:43.146Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T06:02:43.146Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T06:10:13.816Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T06:33:07.011Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:28:49.881Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:29:01.471Z",
      },
    ],
    cutlery: true,
    timeslot: "09:30 - 09:59",
    createdAt: "2024-10-24T04:28:49.881Z",
    updatedAt: "2024-10-24T06:33:07.354Z",
    fleetInfo: null,
  },
  {
    id: "6719cc9cb7a34cd0923d289b",
    sid: "241024-240572",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719cc9bb7a34cd0923d288a",
    firstName: "Fajer jassim",
    lastName: null,
    phoneNumber: "+96567011076",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "664b63730e2115864269a63d",
    delivery: {
      id: "6719cc9bb7a34cd0923d288a",
      customerId: "664b63730e2115864269a63d",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "664b640a30566b5d295771de",
        name: "Office",
        lng: 48.05354408919811,
        lat: 29.098675027918322,
        city: "Ahmadi",
        area: "North Ahmadi",
        block: "13",
        street: "Mugwaa",
        building: "Kipic new head office building",
        floor: "G",
        flat: "",
        phone: "+96567011076",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-20T14:54:02.726Z",
        updatedAt: "2024-05-20T14:54:02.726Z",
      },
      deliverectValidationId: "6719cc9bb4faa714448d1847",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:37:07.007Z",
      estimatedDeliveryTime: "2024-10-24T04:52:05.928Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:24:00.000Z",
      orderId: "6719cc9cb7a34cd0923d289b",
      createdAt: "2024-10-24T04:27:07.120Z",
      updatedAt: "2024-10-24T04:29:06.639Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c714e",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c7155",
        productPLU: "2166015-1",
        productDescription:
          "C 28g| P 12g| F 20g\nHummus served with pita chips.\n\nGood source of Vitamin E",
        productName: "Hummus",
        imageURL:
          "https://resizer.deliverect.com/72PxglK3MSw736Em3OxAtr0nEkCtbdmogjJvF3gGaJo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjAyLTY1MDgzMzQ1ZGViYWM1MDAxZjY1N2Q4OC5qcGc=",
        productCalories: 340,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 12,
          fat: 20,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:27:08.138Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:29:04.677Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:29:21.795Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:53:29.365Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:53:29.365Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:57:50.927Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:26:55.781Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:27:08.138Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:27:28.707Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:27:08.138Z",
    updatedAt: "2024-10-24T05:26:56.115Z",
    fleetInfo: null,
  },
  {
    id: "6719cc9ab7a34cd0923d287e",
    sid: "241024-234820",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719cc99b7a34cd0923d2853",
    firstName: "dhuha alkandry",
    lastName: null,
    phoneNumber: "+96598977759",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64ede0141324bbcd647dd314",
    delivery: {
      id: "6719cc99b7a34cd0923d2853",
      customerId: "64ede0141324bbcd647dd314",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "665d61dd93dc7e98b4c3fa50",
        name: "school",
        lng: 47.95906561149688,
        lat: 29.335708618164062,
        city: "Kuwait",
        area: "Kaifan",
        block: "7",
        street: "7",
        building: "school",
        floor: "",
        flat: "",
        phone: "+96598977759",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-03T06:25:33.309Z",
        updatedAt: "2024-10-02T04:46:01.248Z",
      },
      deliverectValidationId: "6719cc99ff147771ad63f2f8",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:37:05.405Z",
      estimatedDeliveryTime: "2024-10-24T04:52:03.806Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:24:00.000Z",
      orderId: "6719cc9ab7a34cd0923d287e",
      createdAt: "2024-10-24T04:27:05.506Z",
      updatedAt: "2024-10-24T04:29:05.395Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7010",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:27:06.286Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:29:03.714Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:29:19.798Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:38:16.508Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:38:16.509Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:38:38.343Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:07:13.237Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:27:06.286Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:27:17.722Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:27:06.286Z",
    updatedAt: "2024-10-24T05:07:13.571Z",
    fleetInfo: null,
  },
  {
    id: "6719cc66b7a34cd0923d23a0",
    sid: "241024-218740",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719cc65b7a34cd0923d2358",
    firstName: " saad alsubaie",
    lastName: null,
    phoneNumber: "+96566242216",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64fcbe8cec522879c1b2b4f0",
    delivery: {
      id: "6719cc65b7a34cd0923d2358",
      customerId: "64fcbe8cec522879c1b2b4f0",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "652ab40a289c08cbdb69f9db",
        name: "مدرسة الرؤية بوابة 1",
        lng: 48.082305416464806,
        lat: 29.248284915874645,
        city: "Sabah Al Salem",
        area: "Sabah Al Salem",
        block: "2",
        street: "3",
        building: "مدرسة الرؤية بوابة 1",
        floor: "",
        flat: "",
        phone: "+96566242216",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-14T15:30:18.719Z",
        updatedAt: "2024-10-24T04:25:12.359Z",
      },
      deliverectValidationId: "6719cc654cb7e660cded937d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:36:13.681Z",
      estimatedDeliveryTime: "2024-10-24T04:51:12.407Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:23:00.000Z",
      orderId: "6719cc66b7a34cd0923d23a0",
      createdAt: "2024-10-24T04:26:13.805Z",
      updatedAt: "2024-10-24T04:28:04.463Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c7143",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c714d",
        productPLU: "2167007-1",
        productDescription:
          "C 60g| P 24g| F 17g [%Best seller%]\nSmoked turkey with grilled turkey bacon, avocado puree, rocca, pesto & emmental cheese all in sourdough toast. ",
        productName: "Turkey Pesto",
        imageURL:
          "https://resizer.deliverect.com/d4E--e7KeFg5pz7n8OJh2vgevZuUdJEXur9hGCWc7sY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2V5JTIwc2FuZHdpY2gtNjRmNDkwMzljNDA4OTczMWUzMzk4OTc0LmpwZw==",
        productCalories: 489,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 24,
          fat: 17,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 480,
    totalFmcCentsPrice: 480,
    totalFiatCentsPriceOriginal: 480,
    totalExternalFiatCentsPrice: 480,
    totalFmcCentsPriceOriginal: 480,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:26:14.608Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:28:03.187Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:28:17.208Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:44:22.904Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:44:22.904Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:51:55.042Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:03:45.928Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:26:14.608Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:26:26.294Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:26:14.608Z",
    updatedAt: "2024-10-24T05:03:46.384Z",
    fleetInfo: null,
  },
  {
    id: "6719cc1cb7a34cd0923d1b4d",
    sid: "241024-054863",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719cc1cb7a34cd0923d1b07",
    firstName: "Ebrahem Alhajeri",
    lastName: null,
    phoneNumber: "+96597211058",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6680022a8c15153ac895a854",
    delivery: {
      id: "6719cc1cb7a34cd0923d1b07",
      customerId: "6680022a8c15153ac895a854",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6680fcc686fe4b6ad1adf4d7",
        name: "Office",
        lng: 48.087201453745365,
        lat: 29.092953127324417,
        city: "Ahmadi",
        area: "Mid Ahmadi",
        block: "12",
        street: "Hamad Al Zuwayer St",
        building: "KNPC",
        floor: "",
        flat: "",
        phone: "+96597211058",
        additionalInfo: "KNPC Head Office - Ahmadi",
        isSelected: true,
        createdAt: "2024-06-30T06:35:50.020Z",
        updatedAt: "2024-10-23T04:13:29.596Z",
      },
      deliverectValidationId: "6719cc1c827135858b9030fd",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:35:00.064Z",
      estimatedDeliveryTime: "2024-10-24T04:49:59.034Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:22:00.000Z",
      orderId: "6719cc1cb7a34cd0923d1b4d",
      createdAt: "2024-10-24T04:25:00.205Z",
      updatedAt: "2024-10-24T04:27:04.936Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c714e",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "67196442b7a34cd0923c717d",
            productPLU: "1907026-1",
            productName: "No Turkey",
            productDescription: "C 0g| P -4.2g| F -3.2g| cal -53| coins 0",
            productCalories: -53,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: -4.2,
              fat: -3.2,
            },
          },
          {
            productId: "67196442b7a34cd0923c717e",
            productPLU: "1907027-1",
            productName: "No Egg",
            productDescription: "C -0.4g| P -6.2g| F -4.7g| cal -71| coins 0",
            productCalories: -71,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.4,
              protein: -6.2,
              fat: -4.7,
            },
          },
          {
            productId: "67196442b7a34cd0923c7178",
            productPLU: "1907028-1",
            productName: "No Pickles",
            productDescription: "C -0.2g| P 0g| F 0g| cal -1| coins 0",
            productCalories: -1,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.2,
              protein: null,
              fat: null,
            },
          },
          {
            productId: "67196442b7a34cd0923c717f",
            productPLU: "1907029-1",
            productName: "No Tomatoes",
            productDescription: "C -1g| P 0g| F 0g| cal -4.5| coins 0",
            productCalories: -4.5,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: null,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "67196442b7a34cd0923c7158",
        productPLU: "2202033-1",
        productDescription:
          "C 16.8g | P 1.1g | F 0.9g\nKiwi, Lychee, Blueberry, Raspberry, Strawberry , Mango Juice",
        productName: "Exotic Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/FaUQbHO5bVt45oTlIa5crQvzqJWE25HgI0k6ZJia_Ls/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZXhvdGljJTIwZnJ1aXQtNjY0MzdhNDcwYzI0ZDkyNWZjZDQwYTZkLnBuZw==",
        productCalories: 80,
        quantity: 1,
        nutritionInfo: {
          carbs: 16.8,
          protein: 1.1,
          fat: 0.9,
        },
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 370,
    totalExternalFiatCentsPrice: 370,
    totalFmcCentsPriceOriginal: 370,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:25:00.996Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:27:09.876Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:27:19.347Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:37:00.600Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:37:00.600Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:42:34.786Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:12:46.279Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:25:00.996Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:25:12.913Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:25:00.996Z",
    updatedAt: "2024-10-24T05:12:46.623Z",
    fleetInfo: null,
  },
  {
    id: "6719cc12b7a34cd0923d19e3",
    sid: "241024-754362",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719cc11b7a34cd0923d19cc",
    firstName: "Ghaneemah ",
    lastName: null,
    phoneNumber: "+96590083303",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6701730b906276458c803175",
    delivery: {
      id: "6719cc11b7a34cd0923d19cc",
      customerId: "6701730b906276458c803175",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67038b34218a8c4af27c868f",
        name: "Ghaneema",
        lng: 47.99198459999999,
        lat: 29.3778691,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "7",
        street: "Al-Shuhada St",
        building: "NBK headquarter Tower",
        floor: "17",
        flat: "",
        phone: "+96590083303",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-07T07:18:12.383Z",
        updatedAt: "2024-10-22T04:48:22.284Z",
      },
      deliverectValidationId: "6719cc11c47d7273b38e88ce",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:34:49.061Z",
      estimatedDeliveryTime: "2024-10-24T04:49:47.787Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:21:00.000Z",
      orderId: "6719cc12b7a34cd0923d19e3",
      createdAt: "2024-10-24T04:24:49.159Z",
      updatedAt: "2024-10-24T04:26:06.159Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7016",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c702e",
        productPLU: "2202050-1",
        productDescription:
          "C 27g | P 1.5g | F 0g\nOrange \n\nGood source of Vitamin C",
        productName: "Orange Citrus",
        imageURL:
          "https://resizer.deliverect.com/92Cgq5w07iSWvqOzcojq0Egx0BDIxsVNTfIhlYhtUmU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTMtNjY1OTE5ODQ0MjllZTgwN2Q2OWQxYmZkLnBuZw==",
        productCalories: 116,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 1.5,
          fat: null,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7023",
        productPLU: "2166021-1",
        productDescription:
          "C 14g| P 2g| F 1g\nFresh pomegranate seeds.\n\nGOOD SOURCE OF VITAMIN  C",
        productName: "Fresh Pomegranate",
        imageURL:
          "https://resizer.deliverect.com/0QanPE0fkKGZvx0QoWjnfvfTbh0UdPExZ1vpzwL3kU0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNjYtNjUyOWY0MGY4NDhjZjcwMDE4YzBmYmIxLmpwZw==",
        productCalories: 70,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 2,
          fat: 1,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:24:50.114Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:26:04.383Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:41:12.398Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:41:15.528Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:41:15.528Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:42:23.480Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:55:05.967Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:24:50.114Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:25:01.542Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:24:50.114Z",
    updatedAt: "2024-10-24T04:55:06.405Z",
    fleetInfo: null,
  },
  {
    id: "6719cbf6b7a34cd0923d14b1",
    sid: "241024-332300",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719cbf5b7a34cd0923d14a8",
    firstName: "Khaled Alkhaled",
    lastName: null,
    phoneNumber: "+96550555566",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66373cfc89ba1a51b12be40e",
    delivery: {
      id: "6719cbf5b7a34cd0923d14a8",
      customerId: "66373cfc89ba1a51b12be40e",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66373df58b0d42e9ca4d4e4d",
        name: "Home",
        lng: 47.97099441289902,
        lat: 29.31639240168588,
        city: "Kuwait City",
        area: "Yarmouk",
        block: "1",
        street: "1",
        building: "21",
        floor: "",
        flat: "",
        phone: "+96550555566",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-05T08:06:13.233Z",
        updatedAt: "2024-08-25T13:27:50.588Z",
      },
      deliverectValidationId: "6719cbf5b63263b7f608afcf",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:34:21.296Z",
      estimatedDeliveryTime: "2024-10-24T04:49:20.306Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:21:00.000Z",
      orderId: "6719cbf6b7a34cd0923d14b1",
      createdAt: "2024-10-24T04:24:21.394Z",
      updatedAt: "2024-10-24T04:26:05.449Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c7288",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 2,
        nutritionInfo: {
          carbs: 4,
          protein: 26,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:24:22.078Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:26:04.897Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:26:36.919Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:41:41.884Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:41:41.884Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:47:21.064Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:04:32.779Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:24:22.078Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:24:33.531Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:24:22.078Z",
    updatedAt: "2024-10-24T05:04:33.118Z",
    fleetInfo: null,
  },
  {
    id: "6719cbdbb7a34cd0923d11c3",
    sid: "241024-655368",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719cbdab7a34cd0923d11ad",
    firstName: "Talal alazemi",
    lastName: null,
    phoneNumber: "+96566669828",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6565b5ede0d78832aa91f369",
    delivery: {
      id: "6719cbdab7a34cd0923d11ad",
      customerId: "6565b5ede0d78832aa91f369",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "659e1f51afe41535d3b113cb",
        name: "Work",
        lng: 48.08752968907356,
        lat: 29.093684875614933,
        city: "Ahmadi",
        area: "Mid Ahmadi",
        block: "11",
        street: "Hamaed zwayer street",
        building: "KNPC building",
        floor: "Security gate no 3",
        flat: "",
        phone: "+96566669828",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-10T04:38:41.992Z",
        updatedAt: "2024-10-24T04:22:19.465Z",
      },
      deliverectValidationId: "6719cbdac6ffa8c03b18a96b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:33:54.447Z",
      estimatedDeliveryTime: "2024-10-24T04:48:53.250Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:20:00.000Z",
      orderId: "6719cbdbb7a34cd0923d11c3",
      createdAt: "2024-10-24T04:23:54.535Z",
      updatedAt: "2024-10-24T04:25:06.413Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c7147",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 26,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c7149",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c716d",
        productPLU: "2202043-1",
        productDescription: "C 3.8g | P 1.5g | F 0.5g\nColumbia coffee, Water",
        productName: "Cold Brew",
        imageURL:
          "https://resizer.deliverect.com/vJfaq3WgcPkWREtbcv57BpeV4cJZHp0DfxbTLKoOq48/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY29sZCUyMGJyZXctNjY0MWRhZTA2YTdhYTY5NGFiMjI3ZjkzLnBuZw==",
        productCalories: 25,
        quantity: 1,
        nutritionInfo: {
          carbs: 3.8,
          protein: 1.5,
          fat: 0.5,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c715c",
        productPLU: "2165020-1",
        productDescription:
          "C 10g | P 1g | F 6g\nPecan, caramel, milk chocolate",
        productName: "Pecan Turtle",
        imageURL:
          "https://resizer.deliverect.com/M_C4q-ZLQuwipzQEg6XeH4Pvq602HMW3vxgoWZ8KB3k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVjYW4lMjB0dXJ0bGUtNjY2MWNlNDc1MDRlYzgwNTk0NmU0YTkzLnBuZw==",
        productCalories: 100,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 1,
          fat: 6,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 500,
    totalFmcCentsPrice: 500,
    totalFiatCentsPriceOriginal: 500,
    totalExternalFiatCentsPrice: 500,
    totalFmcCentsPriceOriginal: 500,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:23:55.145Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:25:07.240Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:25:18.439Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:36:49.695Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:36:49.695Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:42:46.079Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:10:17.175Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:23:55.145Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:24:14.334Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:23:55.145Z",
    updatedAt: "2024-10-24T05:10:17.616Z",
    fleetInfo: null,
  },
  {
    id: "6719cbd7b7a34cd0923d111a",
    sid: "241024-105868",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719cbd7b7a34cd0923d110a",
    firstName: "Eman Aljrayed ",
    lastName: null,
    phoneNumber: "+96566693584",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66ab26c6f6ed0b2948a1d95a",
    delivery: {
      id: "6719cbd7b7a34cd0923d110a",
      customerId: "66ab26c6f6ed0b2948a1d95a",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66ac957a1d6702aca3bfea9f",
        name: "Home ",
        lng: 48.00499768941302,
        lat: 29.29217529296875,
        city: "Kuwait",
        area: "السلام",
        block: "7",
        street: "720",
        building: "26",
        floor: "2",
        flat: "1",
        phone: "+96566693584",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-02T08:14:50.384Z",
        updatedAt: "2024-08-02T08:14:50.384Z",
      },
      deliverectValidationId: "6719cbd78ee7d1c256236466",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:33:51.074Z",
      estimatedDeliveryTime: "2024-10-24T04:48:49.436Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:20:00.000Z",
      orderId: "6719cbd7b7a34cd0923d111a",
      createdAt: "2024-10-24T04:23:51.175Z",
      updatedAt: "2024-10-24T04:25:06.256Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c728e",
        productPLU: "2167007-1",
        productDescription:
          "C 60g| P 24g| F 17g [%Best seller%]\nSmoked turkey with grilled turkey bacon, avocado puree, rocca, pesto & emmental cheese all in sourdough toast. ",
        productName: "Turkey Pesto",
        imageURL:
          "https://resizer.deliverect.com/d4E--e7KeFg5pz7n8OJh2vgevZuUdJEXur9hGCWc7sY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2V5JTIwc2FuZHdpY2gtNjRmNDkwMzljNDA4OTczMWUzMzk4OTc0LmpwZw==",
        productCalories: 489,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 24,
          fat: 17,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c729a",
        productPLU: "2202034-1",
        productDescription: "C 17.1g | P 1.9g | F 0.6g\nPomelo & Pomegranate",
        productName: "Seasonal Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/XTwC1dVyhg5--YDaNmhMWBtNNZpa3e6lzIaYdRmZhDA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2Vhc29uYWwlMjBmcnVpdCUyMHNhbGFkLTY2NDFkYmY5YWIxMzE0ZDg1Njc3OGRhNS5wbmc=",
        productCalories: 82,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.1,
          protein: 1.9,
          fat: 0.6,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c729e",
        productPLU: "2165028-1",
        productDescription:
          "C 8.2g | P 1.4g | F 7g\nRoasted coconut, coconut powder, whipping cream, cocoa butter, white & milk chocolate",
        productName: "Roasted Coconut Truffle",
        imageURL:
          "https://resizer.deliverect.com/Fsqppf723HLU1qfvol46WqRv2KlRhKmHhGjrowxj0o8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY29jb251dCUyMHRydWZmbGUlMjAtNjY5OTE5OTI0NWU1NDA4NDgxNDQ5MDIwLnBuZw==",
        productCalories: 106,
        quantity: 1,
        nutritionInfo: {
          carbs: 8.2,
          protein: 1.4,
          fat: 7,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 430,
    totalFmcCentsPrice: 430,
    totalFiatCentsPriceOriginal: 430,
    totalExternalFiatCentsPrice: 430,
    totalFmcCentsPriceOriginal: 430,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:23:51.919Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:25:04.779Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:25:51.923Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:33:23.542Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:33:23.543Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:38:16.436Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:58:30.999Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:23:51.919Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:24:03.453Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:23:51.919Z",
    updatedAt: "2024-10-24T04:58:31.338Z",
    fleetInfo: null,
  },
  {
    id: "6719cb4fb7a34cd0923d0c49",
    sid: "241024-657966",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719cb4fb7a34cd0923d0c3e",
    firstName: "Fahad Alwazzan",
    lastName: null,
    phoneNumber: "+96560005576",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d148bb65d114f018b967b9",
    delivery: {
      id: "6719cb4fb7a34cd0923d0c3e",
      customerId: "64d148bb65d114f018b967b9",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64d9752a3902308f22ea5309",
        name: "home",
        lng: 47.95989146951223,
        lat: 29.327896118164062,
        city: "Kuwait City",
        area: "Khaldiya",
        block: "1",
        street: "16",
        building: "2",
        floor: "2",
        flat: "2",
        phone: "+96560005576",
        additionalInfo:
          "On the left of the house there is a door, continue stra",
        isSelected: true,
        createdAt: "2023-08-14T00:28:26.498Z",
        updatedAt: "2024-10-16T04:53:53.120Z",
      },
      deliverectValidationId: "6719cb4fc581fc4bf1e7d9dd",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:31:35.179Z",
      estimatedDeliveryTime: "2024-10-24T04:46:33.768Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:18:00.000Z",
      orderId: "6719cb4fb7a34cd0923d0c49",
      createdAt: "2024-10-24T04:21:35.287Z",
      updatedAt: "2024-10-24T04:23:05.291Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c7288",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 2,
        nutritionInfo: {
          carbs: 4,
          protein: 26,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark:
          "extra onions please, no cottage chesse , no Rocca and cherry tomatoes",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72b3",
        productPLU: "2202040-1",
        productDescription: "C 4.8g | P 0g | F 0g\nOrange, Grapefruit & Lemon",
        productName: "Immunity Shot",
        imageURL:
          "https://resizer.deliverect.com/WkO7ZBCzCZBVTRau0FCMoAizLyoqJmikHQl5iJRWhJs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaW1tdW5pdHklMjBzaG90LTY2NDFkYjEwYWIxMzE0ZDg1Njc3OGNkZS5wbmc=",
        productCalories: 19,
        quantity: 1,
        nutritionInfo: {
          carbs: 4.8,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 460,
    totalFmcCentsPrice: 460,
    totalFiatCentsPriceOriginal: 460,
    totalExternalFiatCentsPrice: 460,
    totalFmcCentsPriceOriginal: 460,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:21:35.905Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:23:11.019Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:23:11.019Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:34:34.832Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:34:34.832Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:47:32.242Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:17:07.935Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:21:35.905Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:21:47.620Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:21:35.906Z",
    updatedAt: "2024-10-24T05:17:08.391Z",
    fleetInfo: null,
  },
  {
    id: "6719cb0db7a34cd0923d0a50",
    sid: "241024-436678",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719cb0cb7a34cd0923d0a46",
    firstName: "Rawan D",
    lastName: null,
    phoneNumber: "+96599499748",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64f82a3eec522879c1af9e0b",
    delivery: {
      id: "6719cb0cb7a34cd0923d0a46",
      customerId: "64f82a3eec522879c1af9e0b",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670eaa09f495b693cdd6b424",
        name: "Kuwait flour mills and bakeries company",
        lng: 47.936477065086365,
        lat: 29.347335838145916,
        city: "Shuwaikh Industrial",
        area: "Shuwaikh Port",
        block: "4th street",
        street: "Jamal Abdul Nasser St",
        building: "KFMB (quality control department)",
        floor: "",
        flat: "",
        phone: "+96599499748",
        additionalInfo: "Call for more diriction",
        isSelected: true,
        createdAt: "2024-10-15T17:44:41.817Z",
        updatedAt: "2024-10-19T04:06:48.199Z",
      },
      deliverectValidationId: "6719cb0c88e23371a9f53700",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:30:28.900Z",
      estimatedDeliveryTime: "2024-10-24T04:45:27.107Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:17:00.000Z",
      orderId: "6719cb0db7a34cd0923d0a50",
      createdAt: "2024-10-24T04:20:28.992Z",
      updatedAt: "2024-10-24T04:22:05.302Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7011",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7027",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:20:29.730Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:22:04.410Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:37:41.335Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:37:41.335Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:37:41.335Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:38:03.292Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:48:12.292Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:20:29.730Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:20:41.449Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:20:29.730Z",
    updatedAt: "2024-10-24T04:48:12.643Z",
    fleetInfo: null,
  },
  {
    id: "6719c95ab7a34cd0923cfcaf",
    sid: "241024-029486",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719c959b7a34cd0923cfca3",
    firstName: "Doaa shehab",
    lastName: null,
    phoneNumber: "+96551151848",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66af66443bc888d224da24cd",
    delivery: {
      id: "6719c959b7a34cd0923cfca3",
      customerId: "66af66443bc888d224da24cd",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66cd5666a48a4cf50feb2771",
        name: "Ahli bank of kuwait",
        lng: 47.873834026046524,
        lat: 29.28350830078125,
        city: "Firdous",
        area: "Firdous",
        block: "Ahli bank of kuwait ",
        street: "0",
        building: "0",
        floor: "0",
        flat: "0",
        phone: "+96551151848",
        additionalInfo: "Bank (ahli bank of kuwait ) ferdos ",
        isSelected: true,
        createdAt: "2024-08-27T04:30:30.947Z",
        updatedAt: "2024-10-21T05:12:06.255Z",
      },
      deliverectValidationId: "6719c9598da25298e7500cad",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:23:13.640Z",
      estimatedDeliveryTime: "2024-10-24T04:38:12.467Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:10:00.000Z",
      orderId: "6719c95ab7a34cd0923cfcaf",
      createdAt: "2024-10-24T04:13:13.738Z",
      updatedAt: "2024-10-24T04:15:04.803Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c7285",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c729d",
        productPLU: "2165020-1",
        productDescription:
          "C 10g | P 1g | F 6g\nPecan, caramel, milk chocolate",
        productName: "Pecan Turtle",
        imageURL:
          "https://resizer.deliverect.com/M_C4q-ZLQuwipzQEg6XeH4Pvq602HMW3vxgoWZ8KB3k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVjYW4lMjB0dXJ0bGUtNjY2MWNlNDc1MDRlYzgwNTk0NmU0YTkzLnBuZw==",
        productCalories: 100,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 1,
          fat: 6,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72b1",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        nutritionInfo: {
          carbs: 5,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 270,
    totalExternalFiatCentsPrice: 270,
    totalFmcCentsPriceOriginal: 270,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:13:14.371Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:15:03.687Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:17:57.951Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:23:22.257Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:23:22.257Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:32:08.905Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:03:12.415Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:13:14.371Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:13:25.832Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:13:14.371Z",
    updatedAt: "2024-10-24T05:03:12.762Z",
    fleetInfo: null,
  },
  {
    id: "6719c945b7a34cd0923cfbbf",
    sid: "241024-274221",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719c945b7a34cd0923cfbb4",
    firstName: "athbi alsabah",
    lastName: null,
    phoneNumber: "+96569303069",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6504a1d6e1d913534182f75a",
    delivery: {
      id: "6719c945b7a34cd0923cfbb4",
      customerId: "6504a1d6e1d913534182f75a",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "665410eede3f2e06c67d8fc2",
        name: "Fiha police ",
        lng: 47.979114800691605,
        lat: 29.336795092540992,
        city: "Kuwait City",
        area: "Faiha",
        block: "5",
        street: "Police station",
        building: "0",
        floor: "1",
        flat: "",
        phone: "+96569303069",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-27T04:49:50.611Z",
        updatedAt: "2024-10-24T04:00:46.060Z",
      },
      deliverectValidationId: "6719c94560ec4531f42afe14",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:22:53.051Z",
      estimatedDeliveryTime: "2024-10-24T04:37:51.915Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:09:00.000Z",
      orderId: "6719c945b7a34cd0923cfbbf",
      createdAt: "2024-10-24T04:12:53.144Z",
      updatedAt: "2024-10-24T04:14:06.186Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7014",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 26,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7021",
        productPLU: "2166029-1",
        productDescription:
          "C 20g| P 3g| F 3g[%New%]\n\nCharred corn with tangy mayo sauce\n\nGOOD SOURCE OF VITAMIN B6",
        productName: "Mini Grilled Corn",
        imageURL:
          "https://resizer.deliverect.com/55C0BPdGu8KBIs3g2oHJe2DixhQfs99DVTEllZD_Nzg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNvcm4tNjcxMzg1YTQxYzZkNzRhMTg0YmY1NmFlLnBuZw==",
        productCalories: 119,
        quantity: 1,
        nutritionInfo: {
          carbs: 20,
          protein: 3,
          fat: 3,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:12:53.816Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:14:05.951Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:35:32.536Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:35:37.863Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:35:37.863Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:38:49.520Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:53:40.082Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:12:53.816Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:13:05.395Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:12:53.816Z",
    updatedAt: "2024-10-24T04:53:40.430Z",
    fleetInfo: null,
  },
  {
    id: "6719c8e9b7a34cd0923cfaa3",
    sid: "241024-914171",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719c8e9b7a34cd0923cfa96",
    firstName: "Noora Albarjas",
    lastName: null,
    phoneNumber: "+96567036222",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "667a50678c15153ac8890c29",
    delivery: {
      id: "6719c8e9b7a34cd0923cfa96",
      customerId: "667a50678c15153ac8890c29",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "668cb296bd8f0d8945f68a8c",
        name: "My work ",
        lng: 48.087593391537666,
        lat: 29.093054482049027,
        city: "Ahmadi",
        area: "Mid Ahmadi",
        block: "12",
        street: "Hamad Al Zuwayer St",
        building: "Work knpc head office",
        floor: "",
        flat: "",
        phone: "+96567036222",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-09T03:46:30.085Z",
        updatedAt: "2024-10-24T04:10:02.962Z",
      },
      deliverectValidationId: "6719c8e91cb32e3bce819814",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:21:21.034Z",
      estimatedDeliveryTime: "2024-10-24T04:36:19.569Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:08:00.000Z",
      orderId: "6719c8e9b7a34cd0923cfaa3",
      createdAt: "2024-10-24T04:11:21.149Z",
      updatedAt: "2024-10-24T04:13:04.955Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c7145",
        productPLU: "2167014-1",
        productDescription:
          "C 32g| P 14g| F 8g\nEnglish muffin with egg whites, blanched spinach, mozzarella cheese and our breakfast sauce.",
        productName: "FM Egg Muffin",
        imageURL:
          "https://resizer.deliverect.com/UDWwpJ2NagrTgNTXcD-YQp6Hbon4KgO_xVwUVvV2eKE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NGJmYTJlMzE0ODFjMTAwMjZjYTEwNTkucG5n",
        productCalories: 256,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 14,
          fat: 8,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c715c",
        productPLU: "2165020-1",
        productDescription:
          "C 10g | P 1g | F 6g\nPecan, caramel, milk chocolate",
        productName: "Pecan Turtle",
        imageURL:
          "https://resizer.deliverect.com/M_C4q-ZLQuwipzQEg6XeH4Pvq602HMW3vxgoWZ8KB3k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVjYW4lMjB0dXJ0bGUtNjY2MWNlNDc1MDRlYzgwNTk0NmU0YTkzLnBuZw==",
        productCalories: 100,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 1,
          fat: 6,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c7158",
        productPLU: "2202033-1",
        productDescription:
          "C 16.8g | P 1.1g | F 0.9g\nKiwi, Lychee, Blueberry, Raspberry, Strawberry , Mango Juice",
        productName: "Exotic Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/FaUQbHO5bVt45oTlIa5crQvzqJWE25HgI0k6ZJia_Ls/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZXhvdGljJTIwZnJ1aXQtNjY0MzdhNDcwYzI0ZDkyNWZjZDQwYTZkLnBuZw==",
        productCalories: 80,
        quantity: 1,
        nutritionInfo: {
          carbs: 16.8,
          protein: 1.1,
          fat: 0.9,
        },
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 350,
    totalExternalFiatCentsPrice: 350,
    totalFmcCentsPriceOriginal: 350,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:11:21.807Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:13:06.974Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:14:09.597Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:23:11.312Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:23:11.313Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:42:12.050Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:08:19.112Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:11:21.807Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:11:33.348Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:11:21.807Z",
    updatedAt: "2024-10-24T05:08:19.455Z",
    fleetInfo: null,
  },
  {
    id: "6719c8bbb7a34cd0923cf72d",
    sid: "241024-689564",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719c8bab7a34cd0923cf721",
    firstName: "Qout Alomar",
    lastName: null,
    phoneNumber: "+96599693333",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64f424a61324bbcd64812afa",
    delivery: {
      id: "6719c8bab7a34cd0923cf721",
      customerId: "64f424a61324bbcd64812afa",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "663730468b0d42e9ca4bee20",
        name: "Work",
        lng: 48.0868652,
        lat: 29.0920472,
        city: "Ahmadi",
        area: "Mid Ahmadi",
        block: "12",
        street: "Hamad Al Zuwayer St",
        building: "KNPC Head Office Building",
        floor: "Gate 2",
        flat: "",
        phone: "+96599693333",
        additionalInfo:
          "Please park opposite the building in the guest parking area and go on bus and go to gate 2",
        isSelected: true,
        createdAt: "2024-05-05T07:07:50.967Z",
        updatedAt: "2024-10-13T04:05:44.927Z",
      },
      deliverectValidationId: "6719c8ba7aed98372713b261",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:20:34.246Z",
      estimatedDeliveryTime: "2024-10-24T04:35:33.001Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:07:00.000Z",
      orderId: "6719c8bbb7a34cd0923cf72d",
      createdAt: "2024-10-24T04:10:34.400Z",
      updatedAt: "2024-10-24T04:12:04.616Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c7144",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c715e",
        productPLU: "2165003-1",
        productDescription: "C 12g | P 2g | F 9g\n",
        productName: "Peanut Bites",
        imageURL:
          "https://resizer.deliverect.com/S2e9SlQ_6ewwX1Z-Pg_RtXB9e4OxquLa6-xOxnwoCJM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTMtNjRiN2JjNmFkMGE4N2MwMDFmZTYwZjBhLnBuZw==",
        productCalories: 139,
        quantity: 1,
        nutritionInfo: {
          carbs: 12,
          protein: 2,
          fat: 9,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c715a",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 350,
    totalExternalFiatCentsPrice: 350,
    totalFmcCentsPriceOriginal: 350,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:10:35.147Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:12:03.586Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:14:06.067Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:23:00.088Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:23:00.088Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:42:57.317Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:07:33.683Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:10:35.147Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:10:46.784Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:10:35.147Z",
    updatedAt: "2024-10-24T05:07:34.134Z",
    fleetInfo: null,
  },
  {
    id: "6719c861b7a34cd0923cf23b",
    sid: "241024-330483",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719c860b7a34cd0923cf233",
    firstName: "Michael Mullett",
    lastName: null,
    phoneNumber: "+96599922458",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c5fa9175a6aa442d0f9c45",
    delivery: {
      id: "6719c860b7a34cd0923cf233",
      customerId: "64c5fa9175a6aa442d0f9c45",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6549c59191bb38a0b80f33e6",
        name: "Massalah west tower ",
        lng: 48.00154159688406,
        lat: 29.37725830078125,
        city: "Al Kuwayt",
        area: "Bnied Al-Gar",
        block: "2",
        street: "Mohamad Abdulmohsin Al Kharafi St",
        building: "Tower 9",
        floor: "Car park",
        flat: "0",
        phone: "+96599922458",
        additionalInfo: "Massaleh west tower ",
        isSelected: true,
        createdAt: "2023-11-07T05:05:21.132Z",
        updatedAt: "2024-09-09T04:31:21.064Z",
      },
      deliverectValidationId: "6719c8601821405a3827d2cb",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:19:04.587Z",
      estimatedDeliveryTime: "2024-10-24T04:34:02.242Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:06:00.000Z",
      orderId: "6719c861b7a34cd0923cf23b",
      createdAt: "2024-10-24T04:09:04.686Z",
      updatedAt: "2024-10-24T04:11:04.294Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7011",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 4,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 520,
    totalFmcCentsPrice: 520,
    totalFiatCentsPriceOriginal: 520,
    totalExternalFiatCentsPrice: 520,
    totalFmcCentsPriceOriginal: 520,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:09:05.375Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:11:03.030Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:26:41.409Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:26:41.409Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:26:41.409Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:27:03.278Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:41:38.439Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:09:05.375Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:09:16.916Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:09:05.375Z",
    updatedAt: "2024-10-24T04:41:38.783Z",
    fleetInfo: null,
  },
  {
    id: "6719c852b7a34cd0923cf0c9",
    sid: "241024-221234",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719c851b7a34cd0923cf0bd",
    firstName: "Sayed Almohri",
    lastName: null,
    phoneNumber: "+96566407700",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6617f775b0d178e632028efb",
    delivery: {
      id: "6719c851b7a34cd0923cf0bd",
      customerId: "6617f775b0d178e632028efb",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "669f2def1683a2e726b4c01d",
        name: "Work",
        lng: 48.08756919054913,
        lat: 29.093353271484375,
        city: "Ahmadi",
        area: "Mid Ahmadi",
        block: "10",
        street: "Hamad Al Zuwayer St",
        building: "KNPC HO",
        floor: "",
        flat: "",
        phone: "+96566407700",
        additionalInfo: "KNPC Head Office",
        isSelected: true,
        createdAt: "2024-07-23T04:13:35.454Z",
        updatedAt: "2024-10-22T04:07:40.579Z",
      },
      deliverectValidationId: "6719c85160ec4531f42aa0b5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:18:49.542Z",
      estimatedDeliveryTime: "2024-10-24T04:33:48.135Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:05:00.000Z",
      orderId: "6719c852b7a34cd0923cf0c9",
      createdAt: "2024-10-24T04:08:49.665Z",
      updatedAt: "2024-10-24T04:10:05.561Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c7143",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c7145",
        productPLU: "2167014-1",
        productDescription:
          "C 32g| P 14g| F 8g\nEnglish muffin with egg whites, blanched spinach, mozzarella cheese and our breakfast sauce.",
        productName: "FM Egg Muffin",
        imageURL:
          "https://resizer.deliverect.com/UDWwpJ2NagrTgNTXcD-YQp6Hbon4KgO_xVwUVvV2eKE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NGJmYTJlMzE0ODFjMTAwMjZjYTEwNTkucG5n",
        productCalories: 256,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 14,
          fat: 8,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 370,
    totalExternalFiatCentsPrice: 370,
    totalFmcCentsPriceOriginal: 370,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:08:50.533Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:10:04.305Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:10:26.097Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:22:49.195Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:22:49.195Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:43:08.919Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:10:08.566Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:08:50.533Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:09:02.011Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:08:50.533Z",
    updatedAt: "2024-10-24T05:10:09.115Z",
    fleetInfo: null,
  },
  {
    id: "6719c82fb7a34cd0923cef4b",
    sid: "241024-941414",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719c82eb7a34cd0923cef22",
    firstName: "yousef sami alshayji",
    lastName: null,
    phoneNumber: "+96596090906",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6649e83a69f370da27dc1b89",
    delivery: {
      id: "6719c82eb7a34cd0923cef22",
      customerId: "6649e83a69f370da27dc1b89",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6719c805b7a34cd0923ced1b",
        name: "القصر",
        lng: 47.9671218,
        lat: 29.36965700000001,
        city: "Kuwait City",
        area: "Jibla",
        block: "1",
        street: "1",
        building: "Justice palace",
        floor: "",
        flat: "",
        phone: "+96596090906",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-24T04:07:33.080Z",
        updatedAt: "2024-10-24T04:07:33.080Z",
      },
      deliverectValidationId: "6719c82edda2cbde335af745",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:18:14.449Z",
      estimatedDeliveryTime: "2024-10-24T04:33:13.113Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:05:00.000Z",
      orderId: "6719c82fb7a34cd0923cef4b",
      createdAt: "2024-10-24T04:08:14.544Z",
      updatedAt: "2024-10-24T04:10:04.940Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7011",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c7026",
        productPLU: "2202034-1",
        productDescription: "C 17.1g | P 1.9g | F 0.6g\nPomelo & Pomegranate",
        productName: "Seasonal Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/XTwC1dVyhg5--YDaNmhMWBtNNZpa3e6lzIaYdRmZhDA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2Vhc29uYWwlMjBmcnVpdCUyMHNhbGFkLTY2NDFkYmY5YWIxMzE0ZDg1Njc3OGRhNS5wbmc=",
        productCalories: 82,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.1,
          protein: 1.9,
          fat: 0.6,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 260,
    totalFmcCentsPrice: 260,
    totalFiatCentsPriceOriginal: 260,
    totalExternalFiatCentsPrice: 260,
    totalFmcCentsPriceOriginal: 260,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:08:15.237Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:10:03.518Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:24:24.412Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:24:24.413Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:24:24.413Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:24:46.274Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:44:22.888Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:08:15.237Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:08:27.487Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:08:15.237Z",
    updatedAt: "2024-10-24T04:44:23.340Z",
    fleetInfo: null,
  },
  {
    id: "6719c75db7a34cd0923ce931",
    sid: "241024-868376",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719c75cb7a34cd0923ce924",
    firstName: "Monia alobaid",
    lastName: null,
    phoneNumber: "+96590976016",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66408d6766978e88ed0e34ac",
    delivery: {
      id: "6719c75cb7a34cd0923ce924",
      customerId: "66408d6766978e88ed0e34ac",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66408e455ca983b14ac3584c",
        name: "Work",
        lng: 48.087331219351945,
        lat: 29.093063354492188,
        city: "Ahmadi",
        area: "Mid Ahmadi",
        block: "12",
        street: "Hamad Al Zuwayer St",
        building: "KNPC HEAD OFFICE",
        floor: "",
        flat: "",
        phone: "+96590976016",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-12T09:39:17.929Z",
        updatedAt: "2024-10-24T04:03:09.509Z",
      },
      deliverectValidationId: "6719c75c3bbe5b0ef215dab8",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:14:44.885Z",
      estimatedDeliveryTime: "2024-10-24T04:29:43.616Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:01:00.000Z",
      orderId: "6719c75db7a34cd0923ce931",
      createdAt: "2024-10-24T04:04:44.991Z",
      updatedAt: "2024-10-24T04:06:05.637Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c7143",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "Extra chilli flakes",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c715d",
        productPLU: "2165028-1",
        productDescription:
          "C 8.2g | P 1.4g | F 7g\nRoasted coconut, coconut powder, whipping cream, cocoa butter, white & milk chocolate",
        productName: "Roasted Coconut Truffle",
        imageURL:
          "https://resizer.deliverect.com/Fsqppf723HLU1qfvol46WqRv2KlRhKmHhGjrowxj0o8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY29jb251dCUyMHRydWZmbGUlMjAtNjY5OTE5OTI0NWU1NDA4NDgxNDQ5MDIwLnBuZw==",
        productCalories: 106,
        quantity: 1,
        nutritionInfo: {
          carbs: 8.2,
          protein: 1.4,
          fat: 7,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c7163",
        productPLU: "2202052-1",
        productDescription:
          "C 13g | P 0g | F 0g\nPomegranate, lemon\n\nGood source of Vitamin C",
        productName: "Pomade",
        imageURL:
          "https://resizer.deliverect.com/JpImLqr5JfNLPk8gTnl6NoZd9i6mdIhqPB4QA7H5lw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEtNjY1OTFhNzgxNmEzMzQyNTY5OGVhM2QxLnBuZw==",
        productCalories: 54,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 490,
    totalFmcCentsPrice: 490,
    totalFiatCentsPriceOriginal: 490,
    totalExternalFiatCentsPrice: 490,
    totalFmcCentsPriceOriginal: 490,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:04:45.690Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:06:04.748Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:06:43.906Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:14:03.152Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:14:03.152Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:42:00.864Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T05:06:28.170Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:04:45.690Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:04:57.259Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T04:04:45.690Z",
    updatedAt: "2024-10-24T05:06:28.621Z",
    fleetInfo: null,
  },
  {
    id: "6719c728b7a34cd0923ce768",
    sid: "241024-787079",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719c727b7a34cd0923ce75d",
    firstName: "Danah Ahmad",
    lastName: null,
    phoneNumber: "+96594444763",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65183b46c6da5aa4aee2da61",
    delivery: {
      id: "6719c727b7a34cd0923ce75d",
      customerId: "65183b46c6da5aa4aee2da61",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "668b8ed909647a7c338b0676",
        name: "Work",
        lng: 48.00657938994449,
        lat: 29.222091674804688,
        city: "Kuwait",
        area: "Sabhan Industrial Area",
        block: "8",
        street: "101",
        building: "The government centre for testing & quality control",
        floor: "",
        flat: "",
        phone: "+96594444763",
        additionalInfo:
          "المركز الحكومي لضبط الجودة المبنى الرئيسي Main Building ",
        isSelected: true,
        createdAt: "2024-07-08T07:01:45.289Z",
        updatedAt: "2024-10-24T03:44:53.521Z",
      },
      deliverectValidationId: "6719c72760ec4531f429b98b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:13:51.679Z",
      estimatedDeliveryTime: "2024-10-24T04:28:50.474Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:00:00.000Z",
      orderId: "6719c728b7a34cd0923ce768",
      createdAt: "2024-10-24T04:03:51.806Z",
      updatedAt: "2024-10-24T04:05:05.125Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c7286",
        productPLU: "2167014-1",
        productDescription:
          "C 32g| P 14g| F 8g\nEnglish muffin with egg whites, blanched spinach, mozzarella cheese and our breakfast sauce.",
        productName: "FM Egg Muffin",
        imageURL:
          "https://resizer.deliverect.com/UDWwpJ2NagrTgNTXcD-YQp6Hbon4KgO_xVwUVvV2eKE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NGJmYTJlMzE0ODFjMTAwMjZjYTEwNTkucG5n",
        productCalories: 256,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 14,
          fat: 8,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72a4",
        productPLU: "2202052-1",
        productDescription:
          "C 13g | P 0g | F 0g\nPomegranate, lemon\n\nGood source of Vitamin C",
        productName: "Pomade",
        imageURL:
          "https://resizer.deliverect.com/JpImLqr5JfNLPk8gTnl6NoZd9i6mdIhqPB4QA7H5lw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEtNjY1OTFhNzgxNmEzMzQyNTY5OGVhM2QxLnBuZw==",
        productCalories: 54,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:03:52.556Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:05:03.416Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:07:30.539Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:10:48.097Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:10:48.097Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:12:47.006Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:38:49.784Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T04:03:52.556Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T04:04:04.051Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T04:03:52.556Z",
    updatedAt: "2024-10-24T04:38:50.137Z",
    fleetInfo: null,
  },
  {
    id: "6719c62eb7a34cd0923cdf94",
    sid: "241024-221217",
    coupons: [],
    menuId: "67196444b7a34cd0923c7414",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6719c62db7a34cd0923cdf89",
    firstName: "Hamza",
    lastName: null,
    phoneNumber: "+96595582009",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66fc52fa8951a675da14164a",
    delivery: {
      id: "6719c62db7a34cd0923cdf89",
      customerId: "66fc52fa8951a675da14164a",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67003068218a8c4af2090211",
        name: "Hin2",
        lng: 48.026299718767405,
        lat: 29.322808850177488,
        city: "الجابرية",
        area: "الجابرية",
        block: "B3",
        street: "1",
        building: "Wafra living",
        floor: "10",
        flat: "21",
        phone: "+96599955897",
        additionalInfo: "Building e",
        isSelected: true,
        createdAt: "2024-10-04T18:14:00.170Z",
        updatedAt: "2024-10-20T16:15:31.677Z",
      },
      deliverectValidationId: "6719c62d7b41664b996e117a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:09:41.284Z",
      estimatedDeliveryTime: "2024-10-24T04:24:40.021Z",
      estimatedDeliveryTimeEnd: "2024-10-24T05:00:00.000Z",
      orderId: "6719c62eb7a34cd0923cdf94",
      createdAt: "2024-10-24T03:59:41.385Z",
      updatedAt: "2024-10-24T04:26:00.372Z",
    },
    items: [
      {
        productId: "67196444b7a34cd0923c7433",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 2,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196444b7a34cd0923c7434",
        productPLU: "2167014-1",
        productDescription:
          "C 32g| P 14g| F 8g\nEnglish muffin with egg whites, blanched spinach, mozzarella cheese and our breakfast sauce.",
        productName: "FM Egg Muffin",
        imageURL:
          "https://resizer.deliverect.com/UDWwpJ2NagrTgNTXcD-YQp6Hbon4KgO_xVwUVvV2eKE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NGJmYTJlMzE0ODFjMTAwMjZjYTEwNTkucG5n",
        productCalories: 256,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 14,
          fat: 8,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "canceled",
    chargeStatus: "refund_succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:59:42.014Z",
      },
      {
        previousStatus: "pending",
        newStatus: "canceled",
        timestamp: "2024-10-24T04:06:49.523Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:59:42.014Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T03:59:53.774Z",
      },
      {
        previousStatus: "succeeded",
        newStatus: "refund_requested",
        timestamp: "2024-10-24T04:06:49.523Z",
      },
      {
        previousStatus: "refund_requested",
        newStatus: "refund_succeeded",
        timestamp: "2024-10-24T04:06:49.890Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T03:59:42.014Z",
    updatedAt: "2024-10-24T04:06:49.891Z",
    fleetInfo: null,
  },
  {
    id: "6719c5c3b7a34cd0923cdc41",
    sid: "241024-973376",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719c5c2b7a34cd0923cdc2f",
    firstName: "fatma al hajji",
    lastName: null,
    phoneNumber: "+96567677647",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66d6cdf2aa1645e3a5fa52ad",
    delivery: {
      id: "6719c5c2b7a34cd0923cdc2f",
      customerId: "66d6cdf2aa1645e3a5fa52ad",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67191364b7a34cd092252fd3",
        name: "shuhada",
        lng: 48.035393979568724,
        lat: 29.267333984375,
        city: "Kuwait",
        area: "Shuhada",
        block: "2",
        street: "201",
        building: "46",
        floor: "",
        flat: "",
        phone: "+96567677647",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-23T15:16:52.951Z",
        updatedAt: "2024-10-23T15:16:52.951Z",
      },
      deliverectValidationId: "6719c5c2c6ffa8c03b1586c7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:07:54.561Z",
      estimatedDeliveryTime: "2024-10-24T04:22:52.999Z",
      estimatedDeliveryTimeEnd: "2024-10-24T04:54:00.000Z",
      orderId: "6719c5c3b7a34cd0923cdc41",
      createdAt: "2024-10-24T03:57:54.651Z",
      updatedAt: "2024-10-24T03:59:05.713Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c7284",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:57:55.293Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T03:59:03.769Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:07:29.816Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:07:35.633Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:07:35.634Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:14:06.193Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:46:47.268Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:57:55.293Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T03:58:06.906Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T03:57:55.293Z",
    updatedAt: "2024-10-24T04:46:47.899Z",
    fleetInfo: null,
  },
  {
    id: "6719c55bb7a34cd0923cd7a8",
    sid: "241024-117968",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719c55bb7a34cd0923cd79b",
    firstName: "Sara",
    lastName: null,
    phoneNumber: "+96566321770",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "651a6028c6da5aa4aee5516f",
    delivery: {
      id: "6719c55bb7a34cd0923cd79b",
      customerId: "651a6028c6da5aa4aee5516f",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "653e3e9f289c08cbdbc9927f",
        name: "Sara Althuwaini ",
        lng: 48.06000578746142,
        lat: 29.260345458984375,
        city: "Sabah Al Salem",
        area: "Sabah Al Salem",
        block: "5",
        street: "1 avenue 10",
        building: "2",
        floor: "",
        flat: "",
        phone: "+96566321770",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-29T11:14:39.549Z",
        updatedAt: "2023-10-29T11:14:39.549Z",
      },
      deliverectValidationId: "6719c55b7aed983727117d42",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:06:11.075Z",
      estimatedDeliveryTime: "2024-10-24T04:21:09.629Z",
      estimatedDeliveryTimeEnd: "2024-10-24T04:53:00.000Z",
      orderId: "6719c55bb7a34cd0923cd7a8",
      createdAt: "2024-10-24T03:56:11.165Z",
      updatedAt: "2024-10-24T03:58:05.167Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c714e",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c716d",
        productPLU: "2202043-1",
        productDescription: "C 3.8g | P 1.5g | F 0.5g\nColumbia coffee, Water",
        productName: "Cold Brew",
        imageURL:
          "https://resizer.deliverect.com/vJfaq3WgcPkWREtbcv57BpeV4cJZHp0DfxbTLKoOq48/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY29sZCUyMGJyZXctNjY0MWRhZTA2YTdhYTY5NGFiMjI3ZjkzLnBuZw==",
        productCalories: 25,
        quantity: 1,
        nutritionInfo: {
          carbs: 3.8,
          protein: 1.5,
          fat: 0.5,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c716f",
        productPLU: "2202045-1",
        productDescription:
          "C 24g | P 20.8g | F 3.8g\nVanilla, Milk, Stevia, Coffee & WHEY Protein",
        productName: "Vanilla Protein Latte",
        imageURL:
          "https://resizer.deliverect.com/IToGNZncc7jFRfdY-Nz1qPS1_kaXAd3rj5Qhiy7-fPk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvdmFuaWxsYSUyMGxhdHRlLTY2NDFkYzIyNzQ4ZjM2N2MxMzE2Y2U5MS5wbmc=",
        productCalories: 212,
        quantity: 1,
        nutritionInfo: {
          carbs: 24,
          protein: 20.8,
          fat: 3.8,
        },
        fmcCentsPrice: 160,
        fiatCentsPrice: 160,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 480,
    totalFmcCentsPrice: 480,
    totalFiatCentsPriceOriginal: 480,
    totalExternalFiatCentsPrice: 480,
    totalFmcCentsPriceOriginal: 480,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:56:11.808Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T03:58:02.663Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T03:59:04.071Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:10:15.211Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:10:15.212Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:13:36.972Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:33:33.268Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:56:11.808Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T03:56:23.319Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T03:56:11.808Z",
    updatedAt: "2024-10-24T04:33:33.719Z",
    fleetInfo: null,
  },
  {
    id: "6719c549b7a34cd0923cd718",
    sid: "241024-819685",
    coupons: [],
    menuId: "67196442b7a34cd0923c7125",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6719c548b7a34cd0923cd704",
    firstName: "هنوف مرزوق الصواغ",
    lastName: null,
    phoneNumber: "+96598090633",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "670b5132aa779ec4a7817bc3",
    delivery: {
      id: "6719c548b7a34cd0923cd704",
      customerId: "670b5132aa779ec4a7817bc3",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670b52bef495b693cd2cb479",
        name: "هنوف",
        lng: 48.0919826,
        lat: 29.19402839999999,
        city: "فنيطيس",
        area: "مبارك الكبير",
        block: "8",
        street: "15",
        building: "مدرسة عيسى حسين اليوسفي ",
        floor: "",
        flat: "",
        phone: "+96598090633",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-13T04:55:26.064Z",
        updatedAt: "2024-10-20T04:13:34.313Z",
      },
      deliverectValidationId: "6719c5481821405a38262f8c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:05:52.454Z",
      estimatedDeliveryTime: "2024-10-24T04:20:51.066Z",
      estimatedDeliveryTimeEnd: "2024-10-24T04:52:00.000Z",
      orderId: "6719c549b7a34cd0923cd718",
      createdAt: "2024-10-24T03:55:52.558Z",
      updatedAt: "2024-10-24T03:57:05.519Z",
    },
    items: [
      {
        productId: "67196442b7a34cd0923c7145",
        productPLU: "2167014-1",
        productDescription:
          "C 32g| P 14g| F 8g\nEnglish muffin with egg whites, blanched spinach, mozzarella cheese and our breakfast sauce.",
        productName: "FM Egg Muffin",
        imageURL:
          "https://resizer.deliverect.com/UDWwpJ2NagrTgNTXcD-YQp6Hbon4KgO_xVwUVvV2eKE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NGJmYTJlMzE0ODFjMTAwMjZjYTEwNTkucG5n",
        productCalories: 256,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 14,
          fat: 8,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c7156",
        productPLU: "2166021-1",
        productDescription:
          "C 14g| P 2g| F 1g\nFresh pomegranate seeds.\n\nGOOD SOURCE OF VITAMIN  C",
        productName: "Fresh Pomegranate",
        imageURL:
          "https://resizer.deliverect.com/0QanPE0fkKGZvx0QoWjnfvfTbh0UdPExZ1vpzwL3kU0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNjYtNjUyOWY0MGY4NDhjZjcwMDE4YzBmYmIxLmpwZw==",
        productCalories: 70,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 2,
          fat: 1,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196442b7a34cd0923c7154",
        productPLU: "2166029-1",
        productDescription:
          "C 20g| P 3g| F 3g[%New%]\n\nCharred corn with tangy mayo sauce\n\nGOOD SOURCE OF VITAMIN B6",
        productName: "Mini Grilled Corn",
        imageURL:
          "https://resizer.deliverect.com/55C0BPdGu8KBIs3g2oHJe2DixhQfs99DVTEllZD_Nzg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNvcm4tNjcxMzg1YTQxYzZkNzRhMTg0YmY1NmFlLnBuZw==",
        productCalories: 119,
        quantity: 1,
        nutritionInfo: {
          carbs: 20,
          protein: 3,
          fat: 3,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:55:53.313Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T03:57:04.704Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T03:57:20.000Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:08:21.182Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:08:21.183Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:15:56.616Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:27:43.497Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:55:53.313Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T03:56:05.000Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T03:55:53.313Z",
    updatedAt: "2024-10-24T04:27:43.840Z",
    fleetInfo: null,
  },
  {
    id: "6719c3fdb7a34cd0923cd094",
    sid: "241024-533479",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719c3fcb7a34cd0923cd085",
    firstName: "Eman Faisal alsafi",
    lastName: null,
    phoneNumber: "+96597132228",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6714d569b58bc473083b3801",
    delivery: {
      id: "6719c3fcb7a34cd0923cd085",
      customerId: "6714d569b58bc473083b3801",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6714d7a7b7a34cd0924a2cf1",
        name: "المنزل",
        lng: 47.999500755777966,
        lat: 29.30035400390625,
        city: "Kuwait",
        area: "الصديق",
        block: "1",
        street: "104",
        building: "836",
        floor: "",
        flat: "",
        phone: "+96597132228",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-20T10:12:55.270Z",
        updatedAt: "2024-10-20T10:12:55.270Z",
      },
      deliverectValidationId: "6719c3fc72410ebb08e62f03",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T04:00:20.362Z",
      estimatedDeliveryTime: "2024-10-24T04:15:19.279Z",
      estimatedDeliveryTimeEnd: "2024-10-24T04:47:00.000Z",
      orderId: "6719c3fdb7a34cd0923cd094",
      createdAt: "2024-10-24T03:50:20.479Z",
      updatedAt: "2024-10-24T03:52:05.133Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c728d",
        productPLU: "2167023-1",
        productDescription:
          "C 29g | P 22g | F 16g\nScrambled eggs with mushroom, green olives and feta cheese in a tortilla wrap\n\nGOOD SOURCE OF PROTEIN, MINERALS",
        productName: "Mushroom egg wrap",
        imageURL:
          "https://resizer.deliverect.com/icCk6VyiEI1LioDbIHa0TjQikuisfIyU0FZUoDvsxdY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAyLTY2M2ZmM2Q0ZGI0ZjMyZjZhNTZmMGY4MC5wbmc=",
        productCalories: 348,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 22,
          fat: 16,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c7284",
        productPLU: "2161018-1",
        productDescription:
          "C 14g| P 16g| F 16g [%Best seller%]\nEggs, guacamole, cheddar cheese, mozeralla cheese & labneh in wrapped in tortilla & seasoned lightly with chili flakes.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Guacamole Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/bW-KU7WecLcJgwnGTbacJ6z0sioqb4_4IzD-O3bBrPM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3VhYyUyMGVnZyUyMHRhY29zLTY1NTQ4ZmNhODEyNDJlMDAxOGYwYWIzMS5wbmc=",
        productCalories: 264,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72a2",
        productPLU: "2202050-1",
        productDescription:
          "C 27g | P 1.5g | F 0g\nOrange \n\nGood source of Vitamin C",
        productName: "Orange Citrus",
        imageURL:
          "https://resizer.deliverect.com/92Cgq5w07iSWvqOzcojq0Egx0BDIxsVNTfIhlYhtUmU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTMtNjY1OTE5ODQ0MjllZTgwN2Q2OWQxYmZkLnBuZw==",
        productCalories: 116,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 1.5,
          fat: null,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72b0",
        productPLU: "2202045-1",
        productDescription:
          "C 24g | P 20.8g | F 3.8g\nVanilla, Milk, Stevia, Coffee & WHEY Protein",
        productName: "Vanilla Protein Latte",
        imageURL:
          "https://resizer.deliverect.com/IToGNZncc7jFRfdY-Nz1qPS1_kaXAd3rj5Qhiy7-fPk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvdmFuaWxsYSUyMGxhdHRlLTY2NDFkYzIyNzQ4ZjM2N2MxMzE2Y2U5MS5wbmc=",
        productCalories: 212,
        quantity: 1,
        nutritionInfo: {
          carbs: 24,
          protein: 20.8,
          fat: 3.8,
        },
        fmcCentsPrice: 160,
        fiatCentsPrice: 160,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 720,
    totalFmcCentsPrice: 720,
    totalFiatCentsPriceOriginal: 720,
    totalExternalFiatCentsPrice: 720,
    totalFmcCentsPriceOriginal: 720,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:50:21.312Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T03:52:03.585Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T03:52:51.780Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T03:52:53.765Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T03:52:53.765Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:13:48.350Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:27:25.333Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:50:21.312Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T03:50:33.288Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T03:50:21.312Z",
    updatedAt: "2024-10-24T04:27:25.692Z",
    fleetInfo: null,
  },
  {
    id: "6719c35db7a34cd0923ccf15",
    sid: "241024-474294",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719c35db7a34cd0923ccf0a",
    firstName: "Fatimah ",
    lastName: null,
    phoneNumber: "+96599078000",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66c69c017cfb5b3acb168905",
    delivery: {
      id: "6719c35db7a34cd0923ccf0a",
      customerId: "66c69c017cfb5b3acb168905",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66c6b3719bb4637074e90151",
        name: "House",
        lng: 47.98507584258914,
        lat: 29.357683907897936,
        city: "Al Kuwayt",
        area: "Abdullah al-Salem",
        block: "1",
        street: "Nusf alyousef",
        building: "21",
        floor: "",
        flat: "",
        phone: "+96599078000",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-22T03:41:37.316Z",
        updatedAt: "2024-08-22T03:41:37.316Z",
      },
      deliverectValidationId: "6719c35d4cb7e660cde966ae",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T03:57:41.003Z",
      estimatedDeliveryTime: "2024-10-24T04:12:39.791Z",
      estimatedDeliveryTimeEnd: "2024-10-24T04:44:00.000Z",
      orderId: "6719c35db7a34cd0923ccf15",
      createdAt: "2024-10-24T03:47:41.228Z",
      updatedAt: "2024-10-24T03:49:08.414Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c7011",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196440b7a34cd0923c701b",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:47:41.934Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:39:06.693Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:39:06.693Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:39:06.694Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:39:06.694Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:39:28.646Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:53:15.873Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:47:41.934Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T03:47:53.390Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-24T03:47:41.934Z",
    updatedAt: "2024-10-24T04:53:16.212Z",
    fleetInfo: null,
  },
  {
    id: "6719c34eb7a34cd0923cce43",
    sid: "241024-197686",
    coupons: [],
    menuId: "67196440b7a34cd0923c6ff2",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719c34db7a34cd0923cce36",
    firstName: "Ali edrees",
    lastName: null,
    phoneNumber: "+96597745227",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66076f678af55dd3671654bb",
    delivery: {
      id: "6719c34db7a34cd0923cce36",
      customerId: "66076f678af55dd3671654bb",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f8f3ffee3a746f7f847f6a",
        name: "Office",
        lng: 47.9824924,
        lat: 29.3801101,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "Sharq police station ",
        street: "Ahmad Al Jaber St",
        building: "Sharq police station ",
        floor: "0",
        flat: "0",
        phone: "+96597745227",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-29T06:30:23.858Z",
        updatedAt: "2024-10-24T03:44:46.276Z",
      },
      deliverectValidationId: "6719c34d8ee7d1c2561ea8c0",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T03:57:25.561Z",
      estimatedDeliveryTime: "2024-10-24T04:12:24.182Z",
      estimatedDeliveryTimeEnd: "2024-10-24T04:44:00.000Z",
      orderId: "6719c34eb7a34cd0923cce43",
      createdAt: "2024-10-24T03:47:25.676Z",
      updatedAt: "2024-10-24T03:49:08.412Z",
    },
    items: [
      {
        productId: "67196440b7a34cd0923c701d",
        productPLU: "2167004-1",
        productDescription:
          "C 43g| P 16g| F 19g\nLight halloumi & black olives with zaatar pesto & ranch in multigrain bread. \n\nGood source of calcium & protein",
        productName: "Halloumi Sandwich",
        imageURL:
          "https://resizer.deliverect.com/kxZW-LHRBXZhOao_mcBsk7OjD7py5CcDqaceAnZntvQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaGFsbG91bWktNjRiN2I2NDZlNGQ1YzMwMDE4YjY1YjZkLnBuZw==",
        productCalories: 407,
        quantity: 2,
        nutritionInfo: {
          carbs: 43,
          protein: 16,
          fat: 19,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 260,
    totalFmcCentsPrice: 260,
    totalFiatCentsPriceOriginal: 260,
    totalExternalFiatCentsPrice: 260,
    totalFmcCentsPriceOriginal: 260,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:47:26.737Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T04:11:20.102Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T04:11:20.103Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T04:11:25.157Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T04:11:25.157Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T04:11:47.480Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:25:00.933Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:47:26.737Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T03:47:38.533Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T03:47:26.737Z",
    updatedAt: "2024-10-24T04:25:01.391Z",
    fleetInfo: null,
  },
  {
    id: "6719c009b7a34cd0923cbfc0",
    sid: "241024-448841",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719c009b7a34cd0923cbfb3",
    firstName: "مشاري مشعل العتيبي ",
    lastName: null,
    phoneNumber: "+96599279725",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65c50dd8914bef1d8305d09a",
    delivery: {
      id: "6719c009b7a34cd0923cbfb3",
      customerId: "65c50dd8914bef1d8305d09a",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66dd2004ced6c5247eff6fab",
        name: "مدرسه معاذ بن جبل ",
        lng: 47.978831494784814,
        lat: 29.29486083984375,
        city: "Kuwait",
        area: "خيطان",
        block: "5",
        street: "ناصر الجبري ",
        building: "مدرسه معاذ بن جبل ",
        floor: "",
        flat: "",
        phone: "+96599279725",
        additionalInfo: "مدرسه ",
        isSelected: true,
        createdAt: "2024-09-08T03:54:44.397Z",
        updatedAt: "2024-10-24T03:32:49.954Z",
      },
      deliverectValidationId: "6719c009cc78686e3f101619",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T03:43:29.031Z",
      estimatedDeliveryTime: "2024-10-24T03:58:27.818Z",
      estimatedDeliveryTimeEnd: "2024-10-24T04:30:00.000Z",
      orderId: "6719c009b7a34cd0923cbfc0",
      createdAt: "2024-10-24T03:33:29.140Z",
      updatedAt: "2024-10-24T03:35:05.291Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c728f",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "67196443b7a34cd0923c72c0",
            productPLU: "1907029-1",
            productName: "No Tomatoes",
            productDescription: "C -1g| P 0g| F 0g| cal -4.5| coins 0",
            productCalories: -4.5,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: null,
              fat: null,
            },
          },
          {
            productId: "67196443b7a34cd0923c72b9",
            productPLU: "1907028-1",
            productName: "No Pickles",
            productDescription: "C -0.2g| P 0g| F 0g| cal -1| coins 0",
            productCalories: -1,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.2,
              protein: null,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "67196443b7a34cd0923c7292",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:33:29.900Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T03:35:03.184Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T03:52:20.752Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T03:52:20.752Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T03:52:20.752Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T03:52:42.634Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:02:59.967Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:33:29.900Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T03:33:41.392Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T03:33:29.900Z",
    updatedAt: "2024-10-24T04:03:00.421Z",
    fleetInfo: null,
  },
  {
    id: "6719bfd5b7a34cd0923cbdb3",
    sid: "241024-499768",
    coupons: [],
    menuId: "67196443b7a34cd0923c7264",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719bfd4b7a34cd0923cbda8",
    firstName: "bedour alwazzan",
    lastName: null,
    phoneNumber: "+96596682799",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66dee624aa1645e3a50cb05c",
    delivery: {
      id: "6719bfd4b7a34cd0923cbda8",
      customerId: "66dee624aa1645e3a50cb05c",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66e34920ced6c5247ef70de0",
        name: "house",
        lng: 47.96838998794556,
        lat: 29.306947093633937,
        city: "Kuwait",
        area: "Yarmouk",
        block: "3",
        street: "2 St. 12 Ln",
        building: "13",
        floor: "",
        flat: "",
        phone: "+96596682799",
        additionalInfo: "alyarmouk block3 street2 lane12 house13",
        isSelected: true,
        createdAt: "2024-09-12T20:03:44.691Z",
        updatedAt: "2024-09-24T20:38:37.808Z",
      },
      deliverectValidationId: "6719bfd3b4faa7144482cc3f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-24T03:42:35.931Z",
      estimatedDeliveryTime: "2024-10-24T03:57:34.555Z",
      estimatedDeliveryTimeEnd: "2024-10-24T04:29:00.000Z",
      orderId: "6719bfd5b7a34cd0923cbdb3",
      createdAt: "2024-10-24T03:32:36.060Z",
      updatedAt: "2024-10-24T03:34:05.597Z",
    },
    items: [
      {
        productId: "67196443b7a34cd0923c728e",
        productPLU: "2167007-1",
        productDescription:
          "C 60g| P 24g| F 17g [%Best seller%]\nSmoked turkey with grilled turkey bacon, avocado puree, rocca, pesto & emmental cheese all in sourdough toast. ",
        productName: "Turkey Pesto",
        imageURL:
          "https://resizer.deliverect.com/d4E--e7KeFg5pz7n8OJh2vgevZuUdJEXur9hGCWc7sY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2V5JTIwc2FuZHdpY2gtNjRmNDkwMzljNDA4OTczMWUzMzk4OTc0LmpwZw==",
        productCalories: 489,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 24,
          fat: 17,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67196443b7a34cd0923c72b2",
        productPLU: "2202039-1",
        productDescription: "C 8.6g | P 0.6g | F 0g\nTurmeric, Orange & Honey",
        productName: "Energy Shot",
        imageURL:
          "https://resizer.deliverect.com/ebvnxevyu9pax93CwcUbxaXHU29E4Bx-a8lOaqc9oPI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZW5lcmd5JTIwc2hvdC02NjQxZGFlZjVjNmRlZjAxYzMzYzIzMzMucG5n",
        productCalories: 37,
        quantity: 1,
        nutritionInfo: {
          carbs: 8.6,
          protein: 0.6,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 310,
    totalFmcCentsPrice: 310,
    totalFiatCentsPriceOriginal: 310,
    totalExternalFiatCentsPrice: 310,
    totalFmcCentsPriceOriginal: 310,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:32:37.072Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T03:34:03.641Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T03:43:18.228Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T03:43:18.229Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T03:43:18.229Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T03:43:40.179Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:11:01.348Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-24T03:32:37.072Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-24T03:32:48.889Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-24T03:32:37.072Z",
    updatedAt: "2024-10-24T04:11:01.686Z",
    fleetInfo: null,
  },
  {
    id: "67195ccfb7a34cd0923bfdf0",
    sid: "241023-880898",
    coupons: [],
    menuId: "67193aebb7a34cd092343f4a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67195ccdb7a34cd0923bfb2f",
    firstName: "Ghazail Alghassab",
    lastName: null,
    phoneNumber: "+96565989890",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65061ee3e1d91353418477ee",
    delivery: {
      id: "67195ccdb7a34cd0923bfb2f",
      customerId: "65061ee3e1d91353418477ee",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "663efa3b5ca983b14aa236a5",
        name: "H",
        lng: 48.122636787593365,
        lat: 29.105348014650488,
        city: "Mangaf",
        area: "Mangaf",
        block: "2",
        street: "14",
        building: "1",
        floor: "",
        flat: "",
        phone: "+96565989890",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-11T04:55:23.826Z",
        updatedAt: "2024-05-11T04:55:23.826Z",
      },
      deliverectValidationId: "67195ccd4871f32c0949ff22",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:40:05.418Z",
      estimatedDeliveryTime: "2024-10-23T20:55:04.164Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:27:00.000Z",
      orderId: "67195ccfb7a34cd0923bfdf0",
      createdAt: "2024-10-23T20:30:05.509Z",
      updatedAt: "2024-10-23T20:32:08.978Z",
    },
    items: [
      {
        productId: "67193aebb7a34cd092343fcf",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 2,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 400,
    totalExternalFiatCentsPrice: 400,
    totalFmcCentsPriceOriginal: 400,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:30:07.698Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:32:09.904Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:45:27.056Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:45:37.773Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:45:37.774Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:46:56.634Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T21:00:02.053Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:30:07.698Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:30:19.338Z",
      },
    ],
    cutlery: true,
    hasRating: true,
    timeslot: null,
    createdAt: "2024-10-23T20:30:07.698Z",
    updatedAt: "2024-10-24T03:14:25.450Z",
    fleetInfo: null,
  },
  {
    id: "67195ca1b7a34cd0923bf2c6",
    sid: "241023-390868",
    coupons: [],
    menuId: "671939c4b7a34cd09233a4da",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67195ca0b7a34cd0923bf2ba",
    firstName: "Muneerah",
    lastName: null,
    phoneNumber: "+96595000447",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66600c180eba0d08a57841da",
    delivery: {
      id: "67195ca0b7a34cd0923bf2ba",
      customerId: "66600c180eba0d08a57841da",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66600cad3d5f2b755d21a854",
        name: "Home",
        lng: 48.01737315952778,
        lat: 29.29866427457813,
        city: "Kuwait",
        area: "Salam",
        block: "3",
        street: "324 St",
        building: "27",
        floor: "",
        flat: "",
        phone: "+96595000447",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-05T06:58:53.099Z",
        updatedAt: "2024-10-06T22:41:25.949Z",
      },
      deliverectValidationId: "67195ca03bbe5b0ef2dcc2bb",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:39:20.395Z",
      estimatedDeliveryTime: "2024-10-23T20:54:19.258Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:26:00.000Z",
      orderId: "67195ca1b7a34cd0923bf2c6",
      createdAt: "2024-10-23T20:29:20.505Z",
      updatedAt: "2024-10-23T20:31:06.671Z",
    },
    items: [
      {
        productId: "671939c4b7a34cd09233a55c",
        productPLU: "2162077-1",
        productDescription:
          "C 43g | P 26g | F 16g\nShort ribs, mixed cabbage, sour cream, spring onion, parsely with tortilla wrap.\n\nGood Source Of Protein, Vitamin B12, Minerals",
        productName: "Short Rib Tacos",
        imageURL:
          "https://resizer.deliverect.com/bwNBblwmBg7NZVLL0zxwD_1h0r1mAgb-zXh607JTGn4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVEFDTy02NTI5ZjMxMWQyNmQ1ZGYyNzhkZDZiYjYucG5n",
        productCalories: 420,
        quantity: 1,
        nutritionInfo: {
          carbs: 43,
          protein: 26,
          fat: 16,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671939c4b7a34cd09233a610",
            productPLU: "1907043-1",
            productName: "No Spring onion",
            productDescription: "C -0.2g| P 0g| F 0g| cal -1| coins 0",
            productCalories: -1,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.2,
              protein: null,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "671939c4b7a34cd09233a5b2",
        productPLU: "2166015-1",
        productDescription:
          "C 28g| P 12g| F 20g\nHummus served with pita chips.\n\nGood source of Vitamin E",
        productName: "Hummus",
        imageURL:
          "https://resizer.deliverect.com/72PxglK3MSw736Em3OxAtr0nEkCtbdmogjJvF3gGaJo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjAyLTY1MDgzMzQ1ZGViYWM1MDAxZjY1N2Q4OC5qcGc=",
        productCalories: 340,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 12,
          fat: 20,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 450,
    totalFmcCentsPrice: 450,
    totalFiatCentsPriceOriginal: 450,
    totalExternalFiatCentsPrice: 450,
    totalFmcCentsPriceOriginal: 450,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:29:21.031Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:31:22.228Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:32:05.920Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:37:34.336Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:37:34.336Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:37:56.244Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:53:03.864Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:29:21.031Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:29:23.748Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:29:21.031Z",
    updatedAt: "2024-10-23T21:16:05.829Z",
    fleetInfo: null,
  },
  {
    id: "67195bfeb7a34cd0923be670",
    sid: "241023-386017",
    coupons: [],
    menuId: "67193aebb7a34cd092343f4a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67195bfdb7a34cd0923be667",
    firstName: "Noura althaqeb ",
    lastName: null,
    phoneNumber: "+96594477151",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6526a4aa1bfa57c5dbf4effc",
    delivery: {
      id: "67195bfdb7a34cd0923be667",
      customerId: "6526a4aa1bfa57c5dbf4effc",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "658701096b671b8d675778a4",
        name: "Home ",
        lng: 48.06909905746579,
        lat: 29.084988925231475,
        city: "Ahmadi",
        area: "South Al Ahmadi",
        block: "6",
        street: "9 south",
        building: "19",
        floor: "",
        flat: "",
        phone: "+96594477151",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-23T15:47:21.201Z",
        updatedAt: "2024-10-23T12:08:20.622Z",
      },
      deliverectValidationId: "67195bfdaa30bafc46827cf6",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:36:37.858Z",
      estimatedDeliveryTime: "2024-10-23T20:51:36.826Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:23:00.000Z",
      orderId: "67195bfeb7a34cd0923be670",
      createdAt: "2024-10-23T20:26:37.954Z",
      updatedAt: "2024-10-23T20:28:07.067Z",
    },
    items: [
      {
        productId: "67193aebb7a34cd092343fe3",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:26:38.573Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:28:07.759Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:44:09.376Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:44:09.376Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:44:09.376Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:44:31.334Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T21:05:35.101Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:26:38.573Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:26:50.211Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:26:38.573Z",
    updatedAt: "2024-10-23T21:05:35.550Z",
    fleetInfo: null,
  },
  {
    id: "67195b9bb7a34cd0923be19b",
    sid: "241023-955337",
    coupons: [],
    menuId: "671939c4b7a34cd09233a4da",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67195b9ab7a34cd0923be18e",
    firstName: "Maryam alnajar",
    lastName: null,
    phoneNumber: "+96569960090",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66c6e2b37cfb5b3acb16dfbb",
    delivery: {
      id: "67195b9ab7a34cd0923be18e",
      customerId: "66c6e2b37cfb5b3acb16dfbb",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66c6e3679bb4637074ebb441",
        name: "My home",
        lng: 47.978249453008175,
        lat: 29.313233939186915,
        city: "Kuwait",
        area: "Qortuba",
        block: "4",
        street: "1",
        building: "80",
        floor: "",
        flat: "",
        phone: "+96569960090",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-22T07:06:15.585Z",
        updatedAt: "2024-10-10T12:51:12.157Z",
      },
      deliverectValidationId: "67195b9a9d15dbbf9f41ce3b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:34:58.868Z",
      estimatedDeliveryTime: "2024-10-23T20:49:57.730Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:21:00.000Z",
      orderId: "67195b9bb7a34cd0923be19b",
      createdAt: "2024-10-23T20:24:58.967Z",
      updatedAt: "2024-10-23T20:26:05.648Z",
    },
    items: [
      {
        productId: "671939c4b7a34cd09233a560",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671939c4b7a34cd09233a5f8",
            productPLU: "1906003-1",
            productName: "Extra Chedder Cheese",
            productDescription: "C 0g| P 2g| F 3g| cal 40| coins 0.2",
            productCalories: 40,
            quantity: 1,
            fmcCentsPrice: 20,
            fiatCentsPrice: 20,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: 2,
              fat: 3,
            },
          },
          {
            productId: "671939c4b7a34cd09233a5f9",
            productPLU: "1906005-1",
            productName: "Extra  Chicken Pattie",
            productDescription: "C 13g| P 15g| F 2g| cal 136| coins 0.5",
            productCalories: 136,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 13,
              protein: 15,
              fat: 2,
            },
          },
        ],
      },
      {
        productId: "671939c4b7a34cd09233a5a1",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:24:59.597Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:26:18.654Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:26:55.389Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:38:35.052Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:38:35.052Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:38:57.128Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:52:51.389Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:24:59.597Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:25:13.748Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:24:59.597Z",
    updatedAt: "2024-10-23T21:15:58.068Z",
    fleetInfo: null,
  },
  {
    id: "67195b93b7a34cd0923bde7b",
    sid: "241023-794048",
    coupons: [],
    menuId: "67193aebb7a34cd092343f4a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67195b92b7a34cd0923bde53",
    firstName: "Abdulaziz Alrshaid ",
    lastName: null,
    phoneNumber: "+96596644132",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65524c326fe598ae84f38fdd",
    delivery: {
      id: "67195b92b7a34cd0923bde53",
      customerId: "65524c326fe598ae84f38fdd",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6705759e218a8c4af2edb548",
        name: "Dewaniya ben hussain",
        lng: 48.09458959847689,
        lat: 29.18144887124147,
        city: "Hadiya",
        area: "Mubarak Al-Kabeer",
        block: "7",
        street: "14",
        building: "35",
        floor: "",
        flat: "",
        phone: "+96596644132",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-08T18:10:38.058Z",
        updatedAt: "2024-10-23T19:44:34.064Z",
      },
      deliverectValidationId: "67195b927aed983727d65cfb",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:34:50.286Z",
      estimatedDeliveryTime: "2024-10-23T20:49:48.613Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:21:00.000Z",
      orderId: "67195b93b7a34cd0923bde7b",
      createdAt: "2024-10-23T20:24:50.383Z",
      updatedAt: "2024-10-23T20:26:05.947Z",
    },
    items: [
      {
        productId: "67193aebb7a34cd092343fcd",
        productPLU: "2162006-1",
        productDescription:
          "C 33g| P 19g| F 16g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67193aebb7a34cd092343fce",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 460,
    totalFmcCentsPrice: 460,
    totalFiatCentsPriceOriginal: 460,
    totalExternalFiatCentsPrice: 460,
    totalFmcCentsPriceOriginal: 460,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:24:51.153Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:26:07.345Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:43:00.390Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:43:00.390Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:43:00.390Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:43:22.251Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T21:01:18.929Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:24:51.153Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:25:02.791Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:24:51.154Z",
    updatedAt: "2024-10-23T21:01:19.375Z",
    fleetInfo: null,
  },
  {
    id: "67195a63b7a34cd0923bbe3e",
    sid: "241023-732346",
    coupons: [],
    menuId: "671939c9b7a34cd09233ac69",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67195a62b7a34cd0923bbe2a",
    firstName: "Talal AlKhalifah",
    lastName: null,
    phoneNumber: "+96566434638",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "651021e0e1d91353418c7b9e",
    delivery: {
      id: "67195a62b7a34cd0923bbe2a",
      customerId: "651021e0e1d91353418c7b9e",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6560c59802b2e21f1fa6f49e",
        name: "Faiha Duwaniya ",
        lng: 47.98257149755955,
        lat: 29.342855394865126,
        city: "Al Kuwayt",
        area: "Faiha",
        block: "3",
        street: "37",
        building: "7",
        floor: "",
        flat: "",
        phone: "+96566434638",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-24T15:47:36.166Z",
        updatedAt: "2024-10-23T20:13:13.438Z",
      },
      deliverectValidationId: "67195a6205f0aa7bf6ad2c6b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:29:46.261Z",
      estimatedDeliveryTime: "2024-10-23T20:44:45.011Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:16:00.000Z",
      orderId: "67195a63b7a34cd0923bbe3e",
      createdAt: "2024-10-23T20:19:46.537Z",
      updatedAt: "2024-10-23T20:21:06.598Z",
    },
    items: [
      {
        productId: "671939c9b7a34cd09233aceb",
        productPLU: "2162047-1",
        productDescription:
          "C 51g| P 31g| F 11g \nPenne pasta in a mushroom & parmesan sauce, topped with grilled dijon chicken. \n\n*Good source of protein, Vitamin  D, & minerals*",
        productName: "Dijon Chicken Pasta",
        imageURL:
          "https://resizer.deliverect.com/LI0VRiKQ75jObW5px0dylKLXpRerpSCN0-9_VLTemyk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGFuZCUyMG11c2hyb29tJTIwcGFzdGEtNjY0NGJjYjAyNTFjMmQ2ZTEyNjQ3NTUwLnBuZw==",
        productCalories: 427,
        quantity: 1,
        nutritionInfo: {
          carbs: 51,
          protein: 31,
          fat: 11,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c9b7a34cd09233ad21",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.2,
          protein: 2.8,
          fat: 8.6,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c9b7a34cd09233ad09",
        productPLU: "2162073-1",
        productDescription:
          "C 55g| P 22g| F 20g [%Popular%]\nCrispy buffalo chicken tenders, iceberg lettuce, cheese with black pepper ranch sauce in a tortilla\n\nGOOD SOURCE OF PROTEIN, IRON, VITAMINS AND MINERALS ",
        productName: "Zinger Wrap",
        imageURL:
          "https://resizer.deliverect.com/HxB6Jk4SYuzyKeoB5PLI0G8vmLwmz39dUm-FWsICaAg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvWmluZ2VyJTIwd3JhcC02NWY2ZTkxOGMxMjg2NmZjODg2NjczZTIuanBn",
        productCalories: 491,
        quantity: 1,
        nutritionInfo: {
          carbs: 55,
          protein: 22,
          fat: 20,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c9b7a34cd09233ad27",
        productPLU: "2162021-1",
        productDescription:
          "C 60g| P 50g| F 16g\nChicken shawarma served with calrose rice & drizzled with our shawarma sauce and sumac white onions.\n\nHigh in protein, ",
        productName: "Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/wpaWs0_xzYbkz_6vxHW2469_71kHXwgrjXuSA4epEwY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2hpY2tlbiUyMFNoYXdhcm1hJTIwYm93bC02NGY0OTRlOTFhNzliZjNkNzNkYjUzNjcuanBn",
        productCalories: 584,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 50,
          fat: 16,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 770,
    totalFmcCentsPrice: 770,
    totalFiatCentsPriceOriginal: 770,
    totalExternalFiatCentsPrice: 770,
    totalFmcCentsPriceOriginal: 770,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:19:47.173Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:21:09.019Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:22:25.205Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:37:39.168Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:37:39.168Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:39:11.420Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:52:05.197Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:19:47.173Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:19:58.605Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:19:47.173Z",
    updatedAt: "2024-10-23T20:52:05.554Z",
    fleetInfo: null,
  },
  {
    id: "671959b4b7a34cd0923bb271",
    sid: "241023-795246",
    coupons: [],
    menuId: "671939c4b7a34cd09233a4da",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671959b4b7a34cd0923bb234",
    firstName: "dalal alhassoun",
    lastName: null,
    phoneNumber: "+96599930800",
    paymentType: "fmcCents",
    type: "scheduleOrder",
    customerId: "669b7da8ea7d0049ce6ad505",
    delivery: {
      id: "671959b4b7a34cd0923bb234",
      customerId: "669b7da8ea7d0049ce6ad505",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "669b7e31dbd8df4f1317b871",
        name: "home",
        lng: 47.96333394190282,
        lat: 29.320159912109375,
        city: "Kuwait",
        area: "Khaldiya",
        block: "4",
        street: "41",
        building: "9",
        floor: "",
        flat: "",
        phone: "+96599930800",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-20T09:06:57.837Z",
        updatedAt: "2024-10-16T12:03:44.234Z",
      },
      deliverectValidationId: "671959b4052354339430ab0c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:26:52.080Z",
      estimatedDeliveryTime: "2024-10-24T04:00:00.000Z",
      estimatedDeliveryTimeEnd: "2024-10-24T04:06:00.000Z",
      orderId: "671959b4b7a34cd0923bb271",
      createdAt: "2024-10-23T20:16:52.188Z",
      updatedAt: "2024-10-24T03:25:06.456Z",
    },
    items: [
      {
        productId: "671939c4b7a34cd09233a57e",
        productPLU: "2167007-1",
        productDescription:
          "C 60g| P 24g| F 17g [%Best seller%]\nSmoked turkey with grilled turkey bacon, avocado puree, rocca, pesto & emmental cheese all in sourdough toast. ",
        productName: "Turkey Pesto",
        imageURL:
          "https://resizer.deliverect.com/d4E--e7KeFg5pz7n8OJh2vgevZuUdJEXur9hGCWc7sY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2V5JTIwc2FuZHdpY2gtNjRmNDkwMzljNDA4OTczMWUzMzk4OTc0LmpwZw==",
        productCalories: 489,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 24,
          fat: 17,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671939c4b7a34cd09233a641",
            productPLU: "1907025-1",
            productName: "No Bacon",
            productDescription: "C 0g| P -3g| F -2g| cal -36| coins 0",
            productCalories: -36,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: -3,
              fat: -2,
            },
          },
        ],
      },
      {
        productId: "671939c4b7a34cd09233a5a0",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 440,
    totalFmcCentsPrice: 440,
    totalFiatCentsPriceOriginal: 440,
    totalExternalFiatCentsPrice: 440,
    totalFmcCentsPriceOriginal: 440,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:16:52.803Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-24T03:25:04.411Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-24T03:42:56.274Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-24T03:42:56.275Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-24T03:42:56.275Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-24T03:43:18.146Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-24T04:22:23.826Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:16:52.803Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:17:04.206Z",
      },
    ],
    cutlery: true,
    timeslot: "07:00 - 07:29",
    createdAt: "2024-10-23T20:16:52.803Z",
    updatedAt: "2024-10-24T04:22:24.169Z",
    fleetInfo: null,
  },
  {
    id: "67195994b7a34cd0923baa6e",
    sid: "241023-183132",
    coupons: [],
    menuId: "671939c4b7a34cd09233a4da",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67195994b7a34cd0923baa5e",
    firstName: "jasim algharabally",
    lastName: null,
    phoneNumber: "+96596902226",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64cb716275a6aa442d13c587",
    delivery: {
      id: "67195994b7a34cd0923baa5e",
      customerId: "64cb716275a6aa442d13c587",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6644d641fb0531469a5f08ab",
        name: "home",
        lng: 47.98019874840975,
        lat: 29.308457453689705,
        city: "Kuwait",
        area: "Qortuba",
        block: "3",
        street: "2",
        building: "57",
        floor: "",
        flat: "",
        phone: "+96596902226",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-15T15:35:29.114Z",
        updatedAt: "2024-10-23T15:10:05.937Z",
      },
      deliverectValidationId: "6719599388e23371a9b326a2",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:26:19.991Z",
      estimatedDeliveryTime: "2024-10-23T20:41:18.855Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:13:00.000Z",
      orderId: "67195994b7a34cd0923baa6e",
      createdAt: "2024-10-23T20:16:20.087Z",
      updatedAt: "2024-10-23T20:18:04.819Z",
    },
    items: [
      {
        productId: "671939c4b7a34cd09233a53b",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c4b7a34cd09233a5b7",
        productPLU: "2166008-1",
        productDescription:
          "C 30g| P 13g| F 16g\nGreek yogurt with peanut butter, raspberry, blueberry, blackberry topped with honey and granola.\n\nGood source of Vitamins",
        productName: "Berry Parfait",
        imageURL:
          "https://resizer.deliverect.com/0p2ShlK1ih7A2WUCAl2RWW19NH0UKIi5XafIY_FXOQc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NTExNmVkYTVkMWVjODc5MjQ0NDE3N2UuanBn",
        productCalories: 316,
        quantity: 1,
        nutritionInfo: {
          carbs: 30,
          protein: 13,
          fat: 16,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 320,
    totalFmcCentsPrice: 320,
    totalFiatCentsPriceOriginal: 320,
    totalExternalFiatCentsPrice: 320,
    totalFmcCentsPriceOriginal: 320,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:16:20.612Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:18:17.080Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:18:41.720Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:27:13.643Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:27:13.643Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:31:18.463Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:45:17.632Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:16:20.612Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:16:32.266Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:16:20.612Z",
    updatedAt: "2024-10-23T20:45:17.977Z",
    fleetInfo: null,
  },
  {
    id: "6719593ab7a34cd0923b9e5b",
    sid: "241023-135826",
    coupons: [],
    menuId: "671939c4b7a34cd09233a4da",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719593ab7a34cd0923b9e4f",
    firstName: "Abdullah alwazzan",
    lastName: null,
    phoneNumber: "+96598888388",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6572e8c17a1e4e4900ebd3d0",
    delivery: {
      id: "6719593ab7a34cd0923b9e4f",
      customerId: "6572e8c17a1e4e4900ebd3d0",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6573717ef093f32458ff4f43",
        name: "Home11",
        lng: 47.96953495591879,
        lat: 29.316883528109095,
        city: "Al Kuwayt",
        area: "Yarmouk",
        block: "1",
        street: "1 St, jaddah 3",
        building: "11",
        floor: "Ground",
        flat: "",
        phone: "+96598888388",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-08T19:41:50.123Z",
        updatedAt: "2024-10-23T20:13:55.320Z",
      },
      deliverectValidationId: "671959393bee77366933a87d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:24:49.661Z",
      estimatedDeliveryTime: "2024-10-23T20:39:48.604Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:11:00.000Z",
      orderId: "6719593ab7a34cd0923b9e5b",
      createdAt: "2024-10-23T20:14:50.071Z",
      updatedAt: "2024-10-23T20:16:05.169Z",
    },
    items: [
      {
        productId: "671939c4b7a34cd09233a53b",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "No sauces ",
        subItems: [],
      },
      {
        productId: "671939c4b7a34cd09233a56f",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 2,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "No cheese, less mayonnaise ",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 390,
    totalFmcCentsPrice: 390,
    totalFiatCentsPriceOriginal: 390,
    totalExternalFiatCentsPrice: 390,
    totalFmcCentsPriceOriginal: 390,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:14:50.833Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:16:17.457Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:16:54.522Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:30:10.674Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:30:10.674Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:30:32.650Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:41:27.103Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:14:50.833Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:15:02.589Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:14:50.833Z",
    updatedAt: "2024-10-23T20:41:27.453Z",
    fleetInfo: null,
  },
  {
    id: "671958cbb7a34cd0923b89db",
    sid: "241023-963045",
    coupons: [],
    menuId: "671939c9b7a34cd09233ac69",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671958cab7a34cd0923b89d1",
    firstName: "Abdulrahman",
    lastName: null,
    phoneNumber: "+96597477752",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f58b9a8951a675da05743f",
    delivery: {
      id: "671958cab7a34cd0923b89d1",
      customerId: "66f58b9a8951a675da05743f",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f81253ee3a746f7f6b15d3",
        name: "Shamya",
        lng: 47.96682737121052,
        lat: 29.349868774414062,
        city: "Al Kuwayt",
        area: "Shamiya",
        block: "6",
        street: "شارع 64",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96597477752",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-28T14:27:31.703Z",
        updatedAt: "2024-10-23T20:11:02.378Z",
      },
      deliverectValidationId: "671958cac581fc4bf1a3a976",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:22:58.774Z",
      estimatedDeliveryTime: "2024-10-23T20:37:57.668Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:09:00.000Z",
      orderId: "671958cbb7a34cd0923b89db",
      createdAt: "2024-10-23T20:12:58.871Z",
      updatedAt: "2024-10-23T20:14:05.406Z",
    },
    items: [
      {
        productId: "671939c9b7a34cd09233acf9",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 330,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671939c9b7a34cd09233adba",
            productPLU: "1906021-1",
            productName: "Extra  Crispy Chicken",
            productDescription: "C 24g| P 24g| F 11g| cal 296| coins 0.8",
            productCalories: 296,
            quantity: 1,
            fmcCentsPrice: 80,
            fiatCentsPrice: 80,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 24,
              protein: 24,
              fat: 11,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:12:59.463Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:14:14.410Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:14:44.942Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:25:00.489Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:25:00.489Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:25:22.346Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:31:44.928Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:12:59.463Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:13:11.122Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:12:59.463Z",
    updatedAt: "2024-10-23T20:31:45.269Z",
    fleetInfo: null,
  },
  {
    id: "67195830b7a34cd0923b7d56",
    sid: "241023-373070",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67195830b7a34cd0923b7d49",
    firstName: "Hamad AlSaif",
    lastName: null,
    phoneNumber: "+96594912492",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "662fd6147ef16a176ee760bd",
    delivery: {
      id: "67195830b7a34cd0923b7d49",
      customerId: "662fd6147ef16a176ee760bd",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "662fd68ec37a2a2c26953516",
        name: "Home",
        lng: 47.99663197249174,
        lat: 29.337359583521774,
        city: "Kuwait",
        area: "Rawda",
        block: "4",
        street: "44 St",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96594912492",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-29T17:19:10.521Z",
        updatedAt: "2024-10-05T04:05:06.707Z",
      },
      deliverectValidationId: "67195830cc78686e3fda0a28",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:20:24.183Z",
      estimatedDeliveryTime: "2024-10-23T20:35:23.009Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:07:00.000Z",
      orderId: "67195830b7a34cd0923b7d56",
      createdAt: "2024-10-23T20:10:24.274Z",
      updatedAt: "2024-10-23T20:12:05.521Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339beb",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339bdf",
        productPLU: "2162054-1",
        productDescription:
          "C 30g| P 26g| F 23g\nRoasted short ribs with mashed potatoes, roasted baby carrots, shallots & broccoli, topped with scallions.\n\nGluten free, High in protien, Good source of Vitamin A, B12 & B6.",
        productName: "Short Ribs & Mash",
        imageURL:
          "https://resizer.deliverect.com/O89_GaQW6TO8q2JdVmgNCZe7eyPxS91cns3qGWT8_oo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvOS02NGI3YjFkYjJlY2EwNzAwMjBhNGVkZTMucG5n",
        productCalories: 431,
        quantity: 1,
        nutritionInfo: {
          carbs: 30,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 440,
        fiatCentsPrice: 440,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339c20",
        productPLU: "2166046-1",
        productDescription: "C 31g | P 13g | F 6.2g\n",
        productName: "PRO CHIPS See Salt & Vinegar",
        imageURL:
          "https://resizer.deliverect.com/Is_on5fI5_atevigbJyEv4KviOO5_bhXdCODMbLIWMY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2VhJTIwc2FsdCUyMCUyNiUyMHZpbmVnYXItNjZkMDNjMWMwODIzMGQyNzBhMDliYTQ0LnBuZw==",
        productCalories: 230,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 13,
          fat: 6.2,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 670,
    totalFmcCentsPrice: 670,
    totalFiatCentsPriceOriginal: 670,
    totalExternalFiatCentsPrice: 670,
    totalFmcCentsPriceOriginal: 670,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:10:24.889Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:12:10.986Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:25:45.765Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:29:19.099Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:29:19.099Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:32:08.754Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:56:47.791Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:10:24.889Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:10:36.334Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:10:24.889Z",
    updatedAt: "2024-10-23T20:56:48.238Z",
    fleetInfo: null,
  },
  {
    id: "67195821b7a34cd0923b793f",
    sid: "241023-924564",
    coupons: [],
    menuId: "671939c9b7a34cd09233ac69",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67195820b7a34cd0923b792c",
    firstName: "badriya boodai",
    lastName: null,
    phoneNumber: "+96594779959",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64a0366c54365c63a99746e0",
    delivery: {
      id: "67195820b7a34cd0923b792c",
      customerId: "64a0366c54365c63a99746e0",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64a2b6fc7c8d5486a7803a76",
        name: "home",
        lng: 47.97045877463654,
        lat: 29.34490966796875,
        city: "Al Kuwayt",
        area: "Shamiya",
        block: "9",
        street: "94 St",
        building: "8",
        floor: "",
        flat: "",
        phone: "+96594779959",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-07-03T11:54:36.274Z",
        updatedAt: "2024-08-24T09:12:19.389Z",
      },
      deliverectValidationId: "67195820621b7746ab042354",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:20:08.847Z",
      estimatedDeliveryTime: "2024-10-23T20:35:07.829Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:07:00.000Z",
      orderId: "67195821b7a34cd0923b793f",
      createdAt: "2024-10-23T20:10:08.950Z",
      updatedAt: "2024-10-23T20:12:05.138Z",
    },
    items: [
      {
        productId: "671939c9b7a34cd09233accc",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c9b7a34cd09233ad2d",
        productPLU: "2168001-1",
        productDescription:
          "C 14g | P 5g | F 1g\nRed lentil soup topped with coriander & served with a lemon wedge. ",
        productName: "Lentil Soup",
        imageURL:
          "https://resizer.deliverect.com/5EbDdbzPc4MFBEpLJbi04Urr0_H4N9CuGy35JtE6XBM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbGVudGlsJTIwc291cC02NjQxZGJjOTNjYmY3YTM2N2ZiNDhiN2IuanBn",
        productCalories: 85,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 5,
          fat: 1,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c9b7a34cd09233ad39",
        productPLU: "2166043-1",
        productDescription: "C 20g | P 14g | F 16g",
        productName: "PROPUFFS Spicy Pizza",
        imageURL:
          "https://resizer.deliverect.com/o5snKddI3KecA9uec_06KzSRebEEtxrkG7Rkk3jxSYY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMi02NmJlMDUzNDA4YmY5MDY0ZWFmYTVkYjkucG5n",
        productCalories: 280,
        quantity: 1,
        nutritionInfo: {
          carbs: 20,
          protein: 14,
          fat: 16,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c9b7a34cd09233ad44",
        productPLU: "2166011-1",
        productDescription:
          "C 58g| P 11g| F 13g\nChocolate chia & flaxseed oats with peanut butter biscuit, orange zest, hazelnut chocolate and chocolate coverture.\n\nGood source of Vitamins & Dietary fibre",
        productName: "Choco Chia Oats",
        imageURL:
          "https://resizer.deliverect.com/Isi8w_ThjYiyjTMxCBPpzL50QvwOgBOSXLSqnE3bNhM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMi02NTExNmVmYzBhODZkZTcwYTAzMjg0NzkuanBn",
        productCalories: 393,
        quantity: 1,
        nutritionInfo: {
          carbs: 58,
          protein: 11,
          fat: 13,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c9b7a34cd09233ad61",
        productPLU: "2202052-1",
        productDescription:
          "C 13g | P 0g | F 0g\nPomegranate, lemon\n\nGood source of Vitamin C",
        productName: "Pomade",
        imageURL:
          "https://resizer.deliverect.com/JpImLqr5JfNLPk8gTnl6NoZd9i6mdIhqPB4QA7H5lw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEtNjY1OTFhNzgxNmEzMzQyNTY5OGVhM2QxLnBuZw==",
        productCalories: 54,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c9b7a34cd09233ad6f",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        nutritionInfo: {
          carbs: 5,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 660,
    totalFmcCentsPrice: 660,
    totalFiatCentsPriceOriginal: 660,
    totalExternalFiatCentsPrice: 660,
    totalFmcCentsPriceOriginal: 660,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:10:09.550Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:12:08.881Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:13:49.275Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:17:31.758Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:17:31.759Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:25:34.693Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:36:03.405Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:10:09.550Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:10:11.963Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:10:09.550Z",
    updatedAt: "2024-10-23T20:36:03.849Z",
    fleetInfo: null,
  },
  {
    id: "6719580cb7a34cd0923b73e9",
    sid: "241023-407177",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6719580bb7a34cd0923b73e0",
    firstName: "Mishari Al Dhulaei",
    lastName: null,
    phoneNumber: "+96566615671",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "668c0d6fe02b51659fd7a9e8",
    delivery: {
      id: "6719580bb7a34cd0923b73e0",
      customerId: "668c0d6fe02b51659fd7a9e8",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "668c0e4cbd8f0d8945e01dda",
        name: "Home",
        lng: 48.07689070701599,
        lat: 29.28431652689073,
        city: "Kuwait",
        area: "Salwa",
        block: "10",
        street: "1 St",
        building: "478",
        floor: "Ground",
        flat: "2",
        phone: "+96566615671",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-08T16:05:32.530Z",
        updatedAt: "2024-10-12T20:22:23.994Z",
      },
      deliverectValidationId: "6719580b8da25298e71329fd",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:19:47.582Z",
      estimatedDeliveryTime: "2024-10-23T20:34:46.528Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:06:00.000Z",
      orderId: "6719580cb7a34cd0923b73e9",
      createdAt: "2024-10-23T20:09:47.672Z",
      updatedAt: "2024-10-23T20:11:07.413Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339bd0",
        productPLU: "2162109-1",
        productDescription:
          "C 23g | P 29g | F 16g \n Beef Lasagna with zucchini & basil\n\nGood source of protein and vitamin A",
        productName: "Zucchini Beef Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SGhwFjSpMczUdKMaEIYf4gYXABWfE33sF_WueZYOSfI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGxhc2FnbmEtNjY0NGFmZDU3NDhmMzY3YzEzMTZlNWZhLnBuZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 23,
          protein: 29,
          fat: 16,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:09:48.386Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:11:06.879Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:19:44.703Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:19:44.703Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:19:44.703Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:20:06.669Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:36:46.691Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:09:48.386Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:09:50.588Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:09:48.386Z",
    updatedAt: "2024-10-23T20:36:47.033Z",
    fleetInfo: null,
  },
  {
    id: "671957b5b7a34cd0923b661c",
    sid: "241023-535781",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671957b3b7a34cd0923b6588",
    firstName: "Mohammed AlNasrallah",
    lastName: null,
    phoneNumber: "+96598999717",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "669a5068ea7d0049ce69444b",
    delivery: {
      id: "671957b3b7a34cd0923b6588",
      customerId: "669a5068ea7d0049ce69444b",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66a0a8501683a2e726e98a55",
        name: "Home",
        lng: 47.97444976866245,
        lat: 29.327096048813893,
        city: "Shuwaikh Industrial",
        area: "Adailiya",
        block: "1",
        street: "16",
        building: "4",
        floor: "",
        flat: "",
        phone: "+96598999717",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-24T07:08:00.730Z",
        updatedAt: "2024-10-23T20:06:38.444Z",
      },
      deliverectValidationId: "671957b24871f32c0947e767",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:18:18.961Z",
      estimatedDeliveryTime: "2024-10-23T20:33:17.537Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:05:00.000Z",
      orderId: "671957b5b7a34cd0923b661c",
      createdAt: "2024-10-23T20:08:19.816Z",
      updatedAt: "2024-10-23T20:10:08.693Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339c1d",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339bf9",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 260,
    totalFmcCentsPrice: 260,
    totalFiatCentsPriceOriginal: 260,
    totalExternalFiatCentsPrice: 260,
    totalFmcCentsPriceOriginal: 260,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:08:21.318Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:10:13.460Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:25:45.556Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:31:22.914Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:31:22.914Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:31:44.860Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:48:57.220Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:08:21.318Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:08:37.190Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:08:21.318Z",
    updatedAt: "2024-10-23T20:48:57.669Z",
    fleetInfo: null,
  },
  {
    id: "671957aeb7a34cd0923b60b6",
    sid: "241023-955750",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671957adb7a34cd0923b60ab",
    firstName: "Omar Al Matar",
    lastName: null,
    phoneNumber: "+96593333391",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "670c0ab8aa779ec4a784b467",
    delivery: {
      id: "671957adb7a34cd0923b60ab",
      customerId: "670c0ab8aa779ec4a784b467",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670c0b6af495b693cd5e58d0",
        name: "Surra",
        lng: 47.994924289841876,
        lat: 29.31842041015625,
        city: "Kuwait",
        area: "Surra",
        block: "5",
        street: "3",
        building: "51",
        floor: "",
        flat: "",
        phone: "+96593333391",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-13T18:03:22.866Z",
        updatedAt: "2024-10-13T18:03:22.866Z",
      },
      deliverectValidationId: "671957ad7b41664b99317726",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:18:13.696Z",
      estimatedDeliveryTime: "2024-10-23T20:33:11.657Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:05:00.000Z",
      orderId: "671957aeb7a34cd0923b60b6",
      createdAt: "2024-10-23T20:08:13.915Z",
      updatedAt: "2024-10-23T20:10:05.860Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339bdc",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339c1d",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 260,
    totalFmcCentsPrice: 260,
    totalFiatCentsPriceOriginal: 260,
    totalExternalFiatCentsPrice: 260,
    totalFmcCentsPriceOriginal: 260,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:08:14.635Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:10:08.963Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:25:45.676Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:27:06.569Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:27:06.569Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:27:28.421Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:39:25.186Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:08:14.635Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:08:26.318Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:08:14.635Z",
    updatedAt: "2024-10-23T20:39:25.519Z",
    fleetInfo: null,
  },
  {
    id: "671956a8b7a34cd0923b4694",
    sid: "241023-710995",
    coupons: [],
    menuId: "671939c4b7a34cd09233a4da",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671956a7b7a34cd0923b4689",
    firstName: "Abdullrahman Alrubaiaan",
    lastName: null,
    phoneNumber: "+96541116261",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67156793b58bc473083db756",
    delivery: {
      id: "671956a7b7a34cd0923b4689",
      customerId: "67156793b58bc473083db756",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "671568aeb7a34cd09282302f",
        name: "Home",
        lng: 47.96943940222263,
        lat: 29.300590608291643,
        city: "Kuwait",
        area: "Khaitan",
        block: "10",
        street: "Naser aljabri",
        building: "142",
        floor: "",
        flat: "",
        phone: "+96541116261",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-20T20:31:42.828Z",
        updatedAt: "2024-10-23T19:58:31.888Z",
      },
      deliverectValidationId: "671956a7621b7746ab038463",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:13:51.760Z",
      estimatedDeliveryTime: "2024-10-23T20:28:50.686Z",
      estimatedDeliveryTimeEnd: "2024-10-23T21:00:00.000Z",
      orderId: "671956a8b7a34cd0923b4694",
      createdAt: "2024-10-23T20:03:51.979Z",
      updatedAt: "2024-10-23T20:05:05.083Z",
    },
    items: [
      {
        productId: "671939c4b7a34cd09233a574",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c4b7a34cd09233a577",
        productPLU: "2162075-1",
        productDescription:
          "C 53g| P 20g| F 9g\nGround beef burrito with seasoned egyptian rice, corn, black beans, lettuce & sour cream wrapped in a tortilla wrap.\n\nGOOD SOURCE OF PROTEIN & FIBER",
        productName: "Beef Burrito",
        imageURL:
          "https://resizer.deliverect.com/85QohnB6E-ysOf_DICo7nPH2RjHXSEy1Qm9NHYJHOgk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGJ1cnJpdG8oMSktNjU1NDhmYTA2MmFiMGUwMDFmM2E3MzczLnBuZw==",
        productCalories: 373,
        quantity: 1,
        nutritionInfo: {
          carbs: 53,
          protein: 20,
          fat: 9,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 490,
    totalFmcCentsPrice: 490,
    totalFiatCentsPriceOriginal: 490,
    totalExternalFiatCentsPrice: 490,
    totalFmcCentsPriceOriginal: 490,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:03:52.685Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:05:17.735Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:05:55.593Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:14:03.426Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:14:03.426Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:24:30.121Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:37:09.514Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:03:52.685Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:04:04.254Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:03:52.685Z",
    updatedAt: "2024-10-23T20:37:09.956Z",
    fleetInfo: null,
  },
  {
    id: "67195666b7a34cd0923b3d9c",
    sid: "241023-067165",
    coupons: [],
    menuId: "671939c4b7a34cd09233a4da",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67195666b7a34cd0923b3d90",
    firstName: "Yousuf",
    lastName: null,
    phoneNumber: "+96599203060",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6706a944aa779ec4a7797a39",
    delivery: {
      id: "67195666b7a34cd0923b3d90",
      customerId: "6706a944aa779ec4a7797a39",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6706ae4df495b693cda06b7e",
        name: "Home",
        lng: 47.986112013459206,
        lat: 29.27145206200531,
        city: "خيطان،",
        area: "Khaitan",
        block: "1",
        street: "144 St",
        building: "15",
        floor: "",
        flat: "",
        phone: "+96599203060",
        additionalInfo: "White house",
        isSelected: true,
        createdAt: "2024-10-09T16:24:45.943Z",
        updatedAt: "2024-10-22T11:56:16.604Z",
      },
      deliverectValidationId: "671956668da25298e71258d9",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:12:46.011Z",
      estimatedDeliveryTime: "2024-10-23T20:27:44.724Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:59:00.000Z",
      orderId: "67195666b7a34cd0923b3d9c",
      createdAt: "2024-10-23T20:02:46.119Z",
      updatedAt: "2024-10-23T20:04:09.889Z",
    },
    items: [
      {
        productId: "671939c4b7a34cd09233a5c7",
        productPLU: "2165020-1",
        productDescription:
          "C 10g | P 1g | F 6g\nPecan, caramel, milk chocolate",
        productName: "Pecan Turtle",
        imageURL:
          "https://resizer.deliverect.com/M_C4q-ZLQuwipzQEg6XeH4Pvq602HMW3vxgoWZ8KB3k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVjYW4lMjB0dXJ0bGUtNjY2MWNlNDc1MDRlYzgwNTk0NmU0YTkzLnBuZw==",
        productCalories: 100,
        quantity: 4,
        nutritionInfo: {
          carbs: 10,
          protein: 1,
          fat: 6,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c4b7a34cd09233a5c6",
        productPLU: "2165029-1",
        productDescription:
          "C 7g | P 1.4g | F 6g\nRoasted Pistachio, caramel, chocolate sea salt",
        productName: "Pistachio Choco Bite",
        imageURL:
          "https://resizer.deliverect.com/rc4xgt7ScqZ-uLdWjRdijowgfyk5KpqjTo2XDqyEBSo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGlzdGFjaGlvJTIwY2hvY28lMjBiaXRlLTY2OTkxOWY4ZTYyZTU5NWI3ZDIzYmZiMy5wbmc=",
        productCalories: 91,
        quantity: 2,
        nutritionInfo: {
          carbs: 7,
          protein: 1.4,
          fat: 6,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c4b7a34cd09233a5c9",
        productPLU: "2165005-1",
        productDescription: "C 13g| P 1g| F 10g",
        productName: "Salted Pecan Bites",
        imageURL:
          "https://resizer.deliverect.com/p3z6wZFsuIaNIkw99YRPwzlHvwGLg970lPG5QDvZQrc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDgtNjRiN2JjOTE2ZjhhYmY0M2UzMWQxMjBjLnBuZw==",
        productCalories: 151,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 1,
          fat: 10,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 440,
    totalFmcCentsPrice: 440,
    totalFiatCentsPriceOriginal: 440,
    totalExternalFiatCentsPrice: 440,
    totalFmcCentsPriceOriginal: 440,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:02:46.751Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T20:04:21.519Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:05:39.163Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:07:33.410Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:07:33.410Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:15:24.605Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:21:10.575Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T20:02:46.751Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T20:02:58.283Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T20:02:46.751Z",
    updatedAt: "2024-10-23T20:21:10.927Z",
    fleetInfo: null,
  },
  {
    id: "67195539b7a34cd0923b170d",
    sid: "241023-249738",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67195539b7a34cd0923b1701",
    firstName: "f",
    lastName: null,
    phoneNumber: "+96560990866",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65c07066d3514bca6687c401",
    delivery: {
      id: "67195539b7a34cd0923b1701",
      customerId: "65c07066d3514bca6687c401",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6632e2d5e5f71dc6c5fd5ee5",
        name: "home",
        lng: 48.053883388638496,
        lat: 29.282586153805333,
        city: "Kuwait",
        area: "Mishref",
        block: "6",
        street: "st 3, ln 1",
        building: "356",
        floor: "",
        flat: "",
        phone: "+96560990866",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-02T00:48:21.990Z",
        updatedAt: "2024-09-29T11:07:52.270Z",
      },
      deliverectValidationId: "67195539dda2cbde3319fe27",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:07:45.082Z",
      estimatedDeliveryTime: "2024-10-23T20:22:43.854Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:54:00.000Z",
      orderId: "67195539b7a34cd0923b170d",
      createdAt: "2024-10-23T19:57:45.343Z",
      updatedAt: "2024-10-23T19:59:06.855Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339c12",
        productPLU: "2162021-1",
        productDescription:
          "C 60g| P 50g| F 16g\nChicken shawarma served with calrose rice & drizzled with our shawarma sauce and sumac white onions.\n\nHigh in protein, ",
        productName: "Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/wpaWs0_xzYbkz_6vxHW2469_71kHXwgrjXuSA4epEwY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2hpY2tlbiUyMFNoYXdhcm1hJTIwYm93bC02NGY0OTRlOTFhNzliZjNkNzNkYjUzNjcuanBn",
        productCalories: 584,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 50,
          fat: 16,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339bdb",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 450,
    totalFmcCentsPrice: 450,
    totalFiatCentsPriceOriginal: 450,
    totalExternalFiatCentsPrice: 450,
    totalFmcCentsPriceOriginal: 450,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:57:45.859Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:59:06.300Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T20:03:36.118Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:09:57.662Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:09:57.662Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:10:13.778Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:27:38.499Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:57:45.859Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:57:57.284Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:57:45.859Z",
    updatedAt: "2024-10-23T20:27:38.944Z",
    fleetInfo: null,
  },
  {
    id: "67195497b7a34cd0923b04f0",
    sid: "241023-097989",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67195496b7a34cd0923b04dd",
    firstName: "abdullah al osaimi ",
    lastName: null,
    phoneNumber: "+96555966636",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6682fc138c15153ac89f4a13",
    delivery: {
      id: "67195496b7a34cd0923b04dd",
      customerId: "6682fc138c15153ac89f4a13",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f595eeee3a746f7f334b96",
        name: "3dil",
        lng: 47.98466295003891,
        lat: 29.323251757611494,
        city: "Kuwait",
        area: "Adailiya",
        block: "3",
        street: "39",
        building: "17",
        floor: "",
        flat: "",
        phone: "+96555966636",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-26T17:12:14.934Z",
        updatedAt: "2024-10-11T18:18:24.150Z",
      },
      deliverectValidationId: "671954952e12b6895030b0d5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T20:05:01.885Z",
      estimatedDeliveryTime: "2024-10-23T20:20:00.644Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:52:00.000Z",
      orderId: "67195497b7a34cd0923b04f0",
      createdAt: "2024-10-23T19:55:02.339Z",
      updatedAt: "2024-10-23T19:57:06.191Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339bc1",
        productPLU: "2162123-1",
        productDescription:
          "C 32g | P 23g | F 14g \nGlazed bun, beef pattie, mozzarella cheese slice, mushroom sauce with light mayo ",
        productName: "Mushroom Burger",
        imageURL:
          "https://resizer.deliverect.com/ha2uMGKS1nYV_BDUXrhrnd4juPdkh4UN_AL73RV3EbM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBidXJnZXItNjY5MDY2ODYxMGNhZmJmNDkzMTIzNGQ5LnBuZw==",
        productCalories: 346,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 23,
          fat: 14,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339c1f",
        productPLU: "2161023-1",
        productDescription:
          "C 37g| P 17g| F 13g\nFries topped with caramelized onions, ground beef, pickled jalapeños, american cheese & our special sauce. \n\n*GOOD SOURCE OF PROTEIN,VITAMINB12*",
        productName: "Messy Fries",
        imageURL:
          "https://resizer.deliverect.com/uhTtheRt0RZXomxI_1n5O4G2cP8KUacFKd3MVV-dhio/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTUVTU1klMjBGUklFUy02NTc1OGZhM2QyZWUyNWQ5YmZkZDE4YWUucG5n",
        productCalories: 333,
        quantity: 1,
        nutritionInfo: {
          carbs: 37,
          protein: 17,
          fat: 13,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339c1c",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 650,
    totalFmcCentsPrice: 650,
    totalFiatCentsPriceOriginal: 650,
    totalExternalFiatCentsPrice: 650,
    totalFmcCentsPriceOriginal: 650,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:55:03.298Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:57:10.954Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:57:43.659Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:10:36.103Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:10:36.104Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:12:29.906Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:25:56.171Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:55:03.298Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:55:08.649Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:55:03.298Z",
    updatedAt: "2024-10-23T20:25:56.506Z",
    fleetInfo: null,
  },
  {
    id: "67195277b7a34cd0923ac7bc",
    sid: "241023-810204",
    coupons: [],
    menuId: "671939c9b7a34cd09233ac69",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67195276b7a34cd0923ac7ab",
    firstName: "bashar marafie",
    lastName: null,
    phoneNumber: "+96599350000",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65df758e3d2abafc7e6ec21a",
    delivery: {
      id: "67195276b7a34cd0923ac7ab",
      customerId: "65df758e3d2abafc7e6ec21a",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "65e35a7baa43a979a8ad921e",
        name: "duwanya sabooh",
        lng: 47.98845861107111,
        lat: 29.356930568470325,
        city: "Kuwait",
        area: "Abdullah al-Salem",
        block: "1",
        street: "10 St",
        building: "house 3b",
        floor: "",
        flat: "",
        phone: "+96599350000",
        additionalInfo: "white house",
        isSelected: true,
        createdAt: "2024-03-02T16:57:31.130Z",
        updatedAt: "2024-10-22T18:11:33.053Z",
      },
      deliverectValidationId: "67195276b63263b7f6cb8679",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:55:58.526Z",
      estimatedDeliveryTime: "2024-10-23T20:10:57.544Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:42:00.000Z",
      orderId: "67195277b7a34cd0923ac7bc",
      createdAt: "2024-10-23T19:45:58.616Z",
      updatedAt: "2024-10-23T19:47:05.888Z",
    },
    items: [
      {
        productId: "671939c9b7a34cd09233ad2c",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c9b7a34cd09233acf9",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c9b7a34cd09233ad09",
        productPLU: "2162073-1",
        productDescription:
          "C 55g| P 22g| F 20g [%Popular%]\nCrispy buffalo chicken tenders, iceberg lettuce, cheese with black pepper ranch sauce in a tortilla\n\nGOOD SOURCE OF PROTEIN, IRON, VITAMINS AND MINERALS ",
        productName: "Zinger Wrap",
        imageURL:
          "https://resizer.deliverect.com/HxB6Jk4SYuzyKeoB5PLI0G8vmLwmz39dUm-FWsICaAg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvWmluZ2VyJTIwd3JhcC02NWY2ZTkxOGMxMjg2NmZjODg2NjczZTIuanBn",
        productCalories: 491,
        quantity: 1,
        nutritionInfo: {
          carbs: 55,
          protein: 22,
          fat: 20,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c9b7a34cd09233acd6",
        productPLU: "2162123-1",
        productDescription:
          "C 32g | P 23g | F 14g \nGlazed bun, beef pattie, mozzarella cheese slice, mushroom sauce with light mayo ",
        productName: "Mushroom Burger",
        imageURL:
          "https://resizer.deliverect.com/ha2uMGKS1nYV_BDUXrhrnd4juPdkh4UN_AL73RV3EbM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBidXJnZXItNjY5MDY2ODYxMGNhZmJmNDkzMTIzNGQ5LnBuZw==",
        productCalories: 346,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 23,
          fat: 14,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 820,
    totalFmcCentsPrice: 820,
    totalFiatCentsPriceOriginal: 820,
    totalExternalFiatCentsPrice: 820,
    totalFmcCentsPriceOriginal: 820,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:45:59.333Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:47:08.766Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:47:47.070Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:06:03.158Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:06:03.158Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:07:11.879Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:15:50.392Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:45:59.333Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:46:10.710Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:45:59.333Z",
    updatedAt: "2024-10-23T20:15:50.733Z",
    fleetInfo: null,
  },
  {
    id: "67195258b7a34cd0923ac56f",
    sid: "241023-518274",
    coupons: [],
    menuId: "67193aebb7a34cd092343f4a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67195257b7a34cd0923ac564",
    firstName: "Abdulaziz Alrshaid ",
    lastName: null,
    phoneNumber: "+96596644132",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65524c326fe598ae84f38fdd",
    delivery: {
      id: "67195257b7a34cd0923ac564",
      customerId: "65524c326fe598ae84f38fdd",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6705759e218a8c4af2edb548",
        name: "Dewaniya ben hussain",
        lng: 48.09458959847689,
        lat: 29.18144887124147,
        city: "Hadiya",
        area: "Mubarak Al-Kabeer",
        block: "7",
        street: "14",
        building: "35",
        floor: "",
        flat: "",
        phone: "+96596644132",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-08T18:10:38.058Z",
        updatedAt: "2024-10-23T19:44:34.064Z",
      },
      deliverectValidationId: "67195257ce0f62dcdfdfb9c3",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:55:27.783Z",
      estimatedDeliveryTime: "2024-10-23T20:10:26.738Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:42:00.000Z",
      orderId: "67195258b7a34cd0923ac56f",
      createdAt: "2024-10-23T19:45:27.908Z",
      updatedAt: "2024-10-23T19:47:04.517Z",
    },
    items: [
      {
        productId: "67193aebb7a34cd092343fde",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67193aebb7a34cd092344012",
        productPLU: "2161023-1",
        productDescription:
          "C 37g| P 17g| F 13g\nFries topped with caramelized onions, ground beef, pickled jalapeños, american cheese & our special sauce. \n\n*GOOD SOURCE OF PROTEIN,VITAMINB12*",
        productName: "Messy Fries",
        imageURL:
          "https://resizer.deliverect.com/uhTtheRt0RZXomxI_1n5O4G2cP8KUacFKd3MVV-dhio/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTUVTU1klMjBGUklFUy02NTc1OGZhM2QyZWUyNWQ5YmZkZDE4YWUucG5n",
        productCalories: 333,
        quantity: 1,
        nutritionInfo: {
          carbs: 37,
          protein: 17,
          fat: 13,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 320,
    totalFmcCentsPrice: 320,
    totalFiatCentsPriceOriginal: 320,
    totalExternalFiatCentsPrice: 320,
    totalFmcCentsPriceOriginal: 320,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:45:28.530Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:47:05.730Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:54:08.592Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:04:27.803Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:04:27.803Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:06:14.263Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:23:02.199Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:45:28.530Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:45:40.078Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:45:28.530Z",
    updatedAt: "2024-10-23T20:23:02.654Z",
    fleetInfo: null,
  },
  {
    id: "67195226b7a34cd0923abd80",
    sid: "241023-320797",
    coupons: [],
    menuId: "67193aebb7a34cd092343f4a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67195226b7a34cd0923abd73",
    firstName: "محمد الناصر",
    lastName: null,
    phoneNumber: "+96568889197",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66eef9517f0597f029dbacdc",
    delivery: {
      id: "67195226b7a34cd0923abd73",
      customerId: "66eef9517f0597f029dbacdc",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66eef9e81294dc02caef881b",
        name: "Alnasser house",
        lng: 48.07951591908932,
        lat: 29.222845821600192,
        city: "Kuwait",
        area: "Al-Qusour District",
        block: "1",
        street: "51",
        building: "12",
        floor: "",
        flat: "",
        phone: "+96568889197",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-21T16:52:56.141Z",
        updatedAt: "2024-09-21T16:52:56.141Z",
      },
      deliverectValidationId: "67195226982ae1ad6de79654",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:54:38.212Z",
      estimatedDeliveryTime: "2024-10-23T20:09:37.108Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:41:00.000Z",
      orderId: "67195226b7a34cd0923abd80",
      createdAt: "2024-10-23T19:44:38.301Z",
      updatedAt: "2024-10-23T19:46:06.045Z",
    },
    items: [
      {
        productId: "67193aebb7a34cd09234400a",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67193aebb7a34cd092343fce",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67193aebb7a34cd092343ff4",
        productPLU: "2162106-1",
        productDescription:
          "C 31g | P 7g | F 18g\nCorn, shishito pepper, jalapeno, coriander, feta cheese, red onion, shishito salad dressing\n\nGood source of Vitamins ",
        productName: "Shishito Corn Salad",
        imageURL:
          "https://resizer.deliverect.com/6rdXnPFXcSncZOuJkUg1u6lTeZ62zbWaCh8VKxZvZJw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCUyQiUyMHJlc2l6ZWQtNjY0NGFmYWQ1YzZkZWYwMWMzM2MzYzE1LnBuZw==",
        productCalories: 314,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 7,
          fat: 18,
        },
        fmcCentsPrice: 350,
        fiatCentsPrice: 350,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 710,
    totalFmcCentsPrice: 710,
    totalFiatCentsPriceOriginal: 710,
    totalExternalFiatCentsPrice: 710,
    totalFmcCentsPriceOriginal: 710,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:44:38.937Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:46:07.919Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:54:11.511Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:01:15.213Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:01:15.214Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:01:37.177Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:19:00.100Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:44:38.937Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:44:57.233Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:44:38.937Z",
    updatedAt: "2024-10-23T20:19:00.550Z",
    fleetInfo: null,
  },
  {
    id: "67195222b7a34cd0923abb7e",
    sid: "241023-358406",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67195222b7a34cd0923abb4b",
    firstName: "Nasser Alomran",
    lastName: null,
    phoneNumber: "+96597799933",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66b5f044eb3fe809b1bb45e0",
    delivery: {
      id: "67195222b7a34cd0923abb4b",
      customerId: "66b5f044eb3fe809b1bb45e0",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66b626fc0846fbb356433da1",
        name: "Home",
        lng: 48.045402746647596,
        lat: 29.336984060073505,
        city: "Salmiya",
        area: "Salmiya",
        block: "11",
        street: "Jabir Bin Hayyan St",
        building: "1",
        floor: "5",
        flat: "9",
        phone: "+96597799933",
        additionalInfo: "Sugar salon building",
        isSelected: true,
        createdAt: "2024-08-09T14:26:04.028Z",
        updatedAt: "2024-09-27T11:02:03.049Z",
      },
      deliverectValidationId: "671952219d15dbbf9f3d4b20",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:54:33.523Z",
      estimatedDeliveryTime: "2024-10-23T20:09:32.487Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:41:00.000Z",
      orderId: "67195222b7a34cd0923abb7e",
      createdAt: "2024-10-23T19:44:34.007Z",
      updatedAt: "2024-10-23T19:46:05.683Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339bf0",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339beb",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 430,
    totalFmcCentsPrice: 430,
    totalFiatCentsPriceOriginal: 430,
    totalExternalFiatCentsPrice: 430,
    totalFmcCentsPriceOriginal: 430,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:44:34.746Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:46:10.477Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:49:34.560Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:01:38.958Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:01:38.958Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:02:00.813Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:26:51.499Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:44:34.746Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:44:46.217Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:44:34.746Z",
    updatedAt: "2024-10-23T20:26:51.842Z",
    fleetInfo: null,
  },
  {
    id: "671951bfb7a34cd0923aa288",
    sid: "241023-264083",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671951bfb7a34cd0923aa27d",
    firstName: "Haya AlSharekh",
    lastName: null,
    phoneNumber: "+96597513459",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66e89dfdaa1645e3a51f0a97",
    delivery: {
      id: "671951bfb7a34cd0923aa27d",
      customerId: "66e89dfdaa1645e3a51f0a97",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66ea03c2ced6c5247eb41f20",
        name: "home",
        lng: 48.000373654067516,
        lat: 29.31603194721375,
        city: "Al Kuwayt",
        area: "Surra",
        block: "5",
        street: "9",
        building: "40",
        floor: "",
        flat: "",
        phone: "+96597513459",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-17T22:33:38.362Z",
        updatedAt: "2024-10-14T13:05:06.084Z",
      },
      deliverectValidationId: "671951bfadb0bdc77d8d4e91",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:52:55.091Z",
      estimatedDeliveryTime: "2024-10-23T20:07:54.039Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:39:00.000Z",
      orderId: "671951bfb7a34cd0923aa288",
      createdAt: "2024-10-23T19:42:55.194Z",
      updatedAt: "2024-10-23T19:44:05.960Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339c1b",
        productPLU: "2166033-1",
        productDescription:
          "C 28g| P 15g| F 12g\nSpicy crispy shrimp bites served with ranch sauce.\n\nGood source of Protein, Vitamin B12 and Iron",
        productName: "Buffalo Shrimp Bites",
        imageURL:
          "https://resizer.deliverect.com/rvAuXhjdUl1okUgfHDTv8pSQt2Rjhn13XhdwXCPIEyw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnVmZmFsbyUyMHNocmltcCUyMGJpdGVzLTY1Y2IxNGU4Zjc3ODBhZDhlOTQyYWZlMy5wbmc=",
        productCalories: 280,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 15,
          fat: 12,
        },
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339c1d",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:42:55.997Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:44:12.128Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:46:25.040Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T20:03:26.848Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T20:03:26.848Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:03:47.232Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:30:32.871Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:42:55.997Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:43:07.547Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:42:55.997Z",
    updatedAt: "2024-10-23T20:30:33.210Z",
    fleetInfo: null,
  },
  {
    id: "67195150b7a34cd0923a8d39",
    sid: "241023-522823",
    coupons: [],
    menuId: "671939c9b7a34cd09233ac69",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719514fb7a34cd0923a8c62",
    firstName: "Ghala Al Khaled",
    lastName: null,
    phoneNumber: "+96594432235",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64daff0270771121d148a597",
    delivery: {
      id: "6719514fb7a34cd0923a8c62",
      customerId: "64daff0270771121d148a597",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "653ccae6289c08cbdbc08413",
        name: "Home",
        lng: 48.00117819962803,
        lat: 29.35089111328125,
        city: "Kuwait",
        area: "Qadsiya",
        block: "2",
        street: "Hamad Al Khaled",
        building: "House 37",
        floor: "",
        flat: "",
        phone: "+96594432235",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-28T08:48:38.369Z",
        updatedAt: "2023-10-28T08:48:38.369Z",
      },
      deliverectValidationId: "6719514f09aba370f71bfd82",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:51:03.547Z",
      estimatedDeliveryTime: "2024-10-23T20:06:02.471Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:38:00.000Z",
      orderId: "67195150b7a34cd0923a8d39",
      createdAt: "2024-10-23T19:41:03.888Z",
      updatedAt: "2024-10-23T19:43:05.017Z",
    },
    items: [
      {
        productId: "671939c9b7a34cd09233acf7",
        productPLU: "2162044-1",
        productDescription:
          "C 46g| P 32g| F 8g\nButter chicken served with white basmati rice and a brocolli garnish with coriander.\n\nGluten free, Good source of Vitamins & Iron.",
        productName: "Butter Chicken",
        imageURL:
          "https://resizer.deliverect.com/kNZO7hSeSAa7aTJWFU1oyK8hGOHaTbLbvvvMD_J1ft0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnV0dGVyJTIwY2hpY2tlbi02NWY2ZWEzZTUxOTBiNjZmYzkxYmQ2MzMuanBn",
        productCalories: 384,
        quantity: 1,
        nutritionInfo: {
          carbs: 46,
          protein: 32,
          fat: 8,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c9b7a34cd09233ad32",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:41:04.997Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:43:10.142Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:44:32.639Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T19:59:37.725Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T19:59:37.725Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T20:02:29.604Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:11:37.386Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:41:04.997Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:41:17.394Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-23T19:41:04.997Z",
    updatedAt: "2024-10-23T20:11:37.742Z",
    fleetInfo: null,
  },
  {
    id: "67195101b7a34cd0923a78a1",
    sid: "241023-440842",
    coupons: [],
    menuId: "67193aebb7a34cd092343f4a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671950ffb7a34cd0923a7745",
    firstName: "Manal",
    lastName: null,
    phoneNumber: "+96599968008",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65aec711406c76b601e351f3",
    delivery: {
      id: "671950ffb7a34cd0923a7745",
      customerId: "65aec711406c76b601e351f3",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65e4ccc1733cd68442d0eb31",
        name: "Home",
        lng: 48.07347793132067,
        lat: 29.203858735627847,
        city: "Kuwait",
        area: "Al-Qurain District",
        block: "2",
        street: "14",
        building: "14",
        floor: "",
        flat: "",
        phone: "+96599968008",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-03-03T19:17:21.274Z",
        updatedAt: "2024-10-10T19:16:11.480Z",
      },
      deliverectValidationId: "671950ffc6ffa8c03bdc25be",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:49:43.899Z",
      estimatedDeliveryTime: "2024-10-23T20:04:42.715Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:36:00.000Z",
      orderId: "67195101b7a34cd0923a78a1",
      createdAt: "2024-10-23T19:39:43.996Z",
      updatedAt: "2024-10-23T19:41:09.132Z",
    },
    items: [
      {
        productId: "67193aebb7a34cd092343ffa",
        productPLU: "2163013-1",
        productDescription:
          "C 24g| P 12g| F 18g\nMixed greens, green bell peppers, cucumbers, tomatoes, spring onions, radishes, fresh mint, zaatar, parsley & grilled halloumi drizzled with a fatoush vinagrette & topped with zaatar croutons. \n\nGOOD SOURCE OF PROTIEN , VITAMIN K , A & CALCIUM",
        productName: "Fattoush",
        imageURL:
          "https://resizer.deliverect.com/5hPWwFSPNGl8nDgfpHyx8xc7_Gg5ln9BhI5g5gG9uoM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2lkZSUyMDItNjUxMDM3NjU4YmNmMGU3YzQ1YzVkMDA4LmpwZw==",
        productCalories: 306,
        quantity: 1,
        nutritionInfo: {
          carbs: 24,
          protein: 12,
          fat: 18,
        },
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67193aebb7a34cd092343fa9",
        productPLU: "2161013-1",
        productDescription:
          "C 28g| P 22g| F 21g\n2 fried eggs with avocado and beef sausage served with sour dough toast.\n\nGood source of Protein & healthy fat",
        productName: "Egg Avo Platter",
        imageURL:
          "https://resizer.deliverect.com/r_ObTbJI3AoU9w6PFaC93-52m67nkc-am15KRZoSW6I/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRWdnJTIwYXZvY2FkbyUyMGJyZWFra2llJTIwKDEpLTY2MzYwMTA4Y2JiMDVhYTc5N2MyNjg4MS5qcGc=",
        productCalories: 389,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 22,
          fat: 21,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67193aebb7a34cd09234403a",
        productPLU: "2202054-1",
        productDescription: "C 2g | P 0g | F 0g ",
        productName: "Celsius Raspberry Acai Green tea",
        imageURL:
          "https://resizer.deliverect.com/Plce5FTacWF00hnnPmi-Lxn6Pwpoltv9lTshlqaHQpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2Vsc2l1cy02Njc0MzQzNzdkNWM4YjdhZjVhOGVkZTcucG5n",
        productCalories: 10,
        quantity: 1,
        nutritionInfo: {
          carbs: 2,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 610,
    totalFmcCentsPrice: 610,
    totalFiatCentsPriceOriginal: 610,
    totalExternalFiatCentsPrice: 610,
    totalFmcCentsPriceOriginal: 610,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:39:45.302Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:41:14.420Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:54:09.591Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T19:56:43.536Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T19:56:43.536Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T19:57:05.377Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:15:10.978Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:39:45.302Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:39:56.753Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:39:45.302Z",
    updatedAt: "2024-10-23T20:15:11.320Z",
    fleetInfo: null,
  },
  {
    id: "671950e3b7a34cd0923a7293",
    sid: "241023-932606",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671950e2b7a34cd0923a7286",
    firstName: "dalal",
    lastName: null,
    phoneNumber: "+96599482229",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66d77f60aa1645e3a5fcecae",
    delivery: {
      id: "671950e2b7a34cd0923a7286",
      customerId: "66d77f60aa1645e3a5fcecae",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66d7800fced6c5247e5ecab4",
        name: "Home",
        lng: 48.07493604719639,
        lat: 29.300760216858627,
        city: "Kuwait",
        area: "Salwa",
        block: "5",
        street: "8 St",
        building: "7A",
        floor: "",
        flat: "",
        phone: "+96599482229",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-03T21:30:55.902Z",
        updatedAt: "2024-10-07T16:27:08.902Z",
      },
      deliverectValidationId: "671950e23bbe5b0ef2d6c3c2",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:49:14.274Z",
      estimatedDeliveryTime: "2024-10-23T20:04:13.059Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:36:00.000Z",
      orderId: "671950e3b7a34cd0923a7293",
      createdAt: "2024-10-23T19:39:14.379Z",
      updatedAt: "2024-10-23T19:41:06.395Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339bbc",
        productPLU: "2162129-1",
        productDescription:
          "C 47g | P 33g | F 14g\nPenne Pasta with chicken breast, broccoli based sauce topped with parmesan cheese & oregano",
        productName: "Broccoli Pasta",
        imageURL:
          "https://resizer.deliverect.com/q-jXVupL9907eYxO0rhsjsRQBexYeTKdjyCeZLvseEY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnJvY29sbGklMjBwYXN0YS02NmQwM2I5MTA4MjMwZDI3MGEwOWJhM2MucG5n",
        productCalories: 446,
        quantity: 1,
        nutritionInfo: {
          carbs: 47,
          protein: 33,
          fat: 14,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 270,
    totalExternalFiatCentsPrice: 270,
    totalFmcCentsPriceOriginal: 270,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:39:15.082Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:41:10.372Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:44:15.503Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T19:53:21.790Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T19:53:21.790Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T19:53:44.078Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:13:16.511Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:39:15.082Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:39:26.930Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:39:15.082Z",
    updatedAt: "2024-10-23T20:13:18.049Z",
    fleetInfo: null,
  },
  {
    id: "67194fc0b7a34cd0923a40fd",
    sid: "241023-189283",
    coupons: [],
    menuId: "671939c4b7a34cd09233a4da",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67194fc0b7a34cd0923a40ed",
    firstName: "Abdulrahman Alowisi",
    lastName: null,
    phoneNumber: "+96566169041",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66cb6a44c41ac94683a50e91",
    delivery: {
      id: "67194fc0b7a34cd0923a40ed",
      customerId: "66cb6a44c41ac94683a50e91",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66cb6ab1a48a4cf50faa0f37",
        name: "Home",
        lng: 48.00259452313185,
        lat: 29.303145997241792,
        city: "Kuwait",
        area: "Salam",
        block: "1",
        street: "116 St",
        building: "588",
        floor: "2",
        flat: "7",
        phone: "+96566169041",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-25T17:32:33.894Z",
        updatedAt: "2024-08-25T17:32:33.894Z",
      },
      deliverectValidationId: "67194fbf05f0aa7bf6a7974b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:44:23.931Z",
      estimatedDeliveryTime: "2024-10-23T19:59:22.823Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:31:00.000Z",
      orderId: "67194fc0b7a34cd0923a40fd",
      createdAt: "2024-10-23T19:34:24.034Z",
      updatedAt: "2024-10-23T19:36:07.976Z",
    },
    items: [
      {
        productId: "671939c4b7a34cd09233a560",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c4b7a34cd09233a569",
        productPLU: "2162031-1",
        productDescription:
          "C 63g| P 25g| F 12g\nSweet & sour chicken, green bell peppers, carrots & pineapples served with white rice.",
        productName: "Sweet & Sour Chicken",
        imageURL:
          "https://resizer.deliverect.com/DlUpN-omCGUFYG08dg-u9PiTfH2URZQt44nUONmWKto/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvOC02NGI3YjAxMzRiOTdiYTM4NWRjN2FlMmIucG5n",
        productCalories: 466,
        quantity: 1,
        nutritionInfo: {
          carbs: 63,
          protein: 25,
          fat: 12,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c4b7a34cd09233a59b",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 2,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c4b7a34cd09233a5a1",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 800,
    totalFmcCentsPrice: 800,
    totalFiatCentsPriceOriginal: 800,
    totalExternalFiatCentsPrice: 800,
    totalFmcCentsPriceOriginal: 800,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:34:24.826Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:36:19.615Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:36:41.319Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T19:51:22.588Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T19:51:22.588Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T19:51:58.957Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:08:35.289Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:34:24.826Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:34:36.802Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:34:24.826Z",
    updatedAt: "2024-10-23T20:08:35.625Z",
    fleetInfo: null,
  },
  {
    id: "67194fb6b7a34cd0923a3a62",
    sid: "241023-193675",
    coupons: [],
    menuId: "671939c9b7a34cd09233ac69",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67194fb5b7a34cd0923a3a55",
    firstName: "Haya alshaya",
    lastName: null,
    phoneNumber: "+96597665882",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65492f0d4b64138ba90d552f",
    delivery: {
      id: "67194fb5b7a34cd0923a3a55",
      customerId: "65492f0d4b64138ba90d552f",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6565e3cc02b2e21f1fbf952f",
        name: "Home",
        lng: 47.95430134981871,
        lat: 29.3352168229703,
        city: "Kuwait",
        area: "Kaifan",
        block: "6",
        street: "Amar Bin Yasir St",
        building: "22",
        floor: "",
        flat: "",
        phone: "+96597665882",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-28T12:57:48.647Z",
        updatedAt: "2024-10-23T19:31:24.865Z",
      },
      deliverectValidationId: "67194fb53946960ae1dafa8c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:44:13.151Z",
      estimatedDeliveryTime: "2024-10-23T19:59:11.837Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:31:00.000Z",
      orderId: "67194fb6b7a34cd0923a3a62",
      createdAt: "2024-10-23T19:34:13.340Z",
      updatedAt: "2024-10-23T19:36:05.127Z",
    },
    items: [
      {
        productId: "671939c9b7a34cd09233ad2c",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c9b7a34cd09233acf0",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "Extra ketchup please",
        subItems: [
          {
            productId: "671939c9b7a34cd09233ad7d",
            productPLU: "1906004-1",
            productName: "Extra  Beef Pattie",
            productDescription: "C 2g| P 6g| F 3g| cal 59| coins 0.5",
            productCalories: 59,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 2,
              protein: 6,
              fat: 3,
            },
          },
          {
            productId: "671939c9b7a34cd09233ad8e",
            productPLU: "1907004-1",
            productName: "No Onion",
            productDescription: "C -1g| P 0g| F 0g| cal -3| coins 0\n",
            productCalories: -3,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: null,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 410,
    totalFmcCentsPrice: 410,
    totalFiatCentsPriceOriginal: 410,
    totalExternalFiatCentsPrice: 410,
    totalFmcCentsPriceOriginal: 410,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:34:14.106Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:36:11.146Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:42:08.201Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T19:51:29.273Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T19:51:29.273Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T19:52:47.692Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:07:11.950Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:34:14.106Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:34:25.510Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:34:14.106Z",
    updatedAt: "2024-10-23T20:07:12.295Z",
    fleetInfo: null,
  },
  {
    id: "67194fa1b7a34cd0923a33a8",
    sid: "241023-433828",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67194fa0b7a34cd0923a339d",
    firstName: "Tariq alqahtany",
    lastName: null,
    phoneNumber: "+96569968699",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "670f5f67aa779ec4a78cef59",
    delivery: {
      id: "67194fa0b7a34cd0923a339d",
      customerId: "670f5f67aa779ec4a78cef59",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67194f1fb7a34cd0923a1b98",
        name: "Home",
        lng: 48.04310593754053,
        lat: 29.28120737430509,
        city: "Mubarak Al-Abdullah",
        area: "Mubarak Al-Abdullah",
        block: "2",
        street: "202",
        building: "76",
        floor: "",
        flat: "",
        phone: "+96569968699",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-23T19:31:43.600Z",
        updatedAt: "2024-10-23T19:31:43.600Z",
      },
      deliverectValidationId: "67194fa0b63263b7f6ca7395",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:43:52.187Z",
      estimatedDeliveryTime: "2024-10-23T19:58:50.937Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:30:00.000Z",
      orderId: "67194fa1b7a34cd0923a33a8",
      createdAt: "2024-10-23T19:33:52.315Z",
      updatedAt: "2024-10-23T19:35:09.154Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339bc3",
        productPLU: "2162124-1",
        productDescription:
          "C 50g | P 22g | F 10g \nChicken, Orzo pasta with tomato sauce topped with parmesan cheese",
        productName: "Chicken Orzo Parmesan",
        imageURL:
          "https://resizer.deliverect.com/zbX-3JmfsnTTty6PcgLrHH6DLyYIdE8OalCp4CsIb18/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvT3J6byUyMHBhc3RhLTY2N2Q1ZjFjNzcwNGVkMjViNjliN2Q2My5wbmc=",
        productCalories: 378,
        quantity: 1,
        nutritionInfo: {
          carbs: 50,
          protein: 22,
          fat: 10,
        },
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339bed",
        productPLU: "2167025-1",
        productDescription:
          "C 29g | P 31g | F 12g\nTortilla with chicken breast, Mexican sauce, cheddar cheese, red & green capsicum.\n\nGood source of Protein & Vitamin C",
        productName: "Mexican wrap",
        imageURL:
          "https://resizer.deliverect.com/rAzOkou3Hx2u334uLtcNGO0XSk3R-noyrtj8ts7K71I/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTWV4aWNhbiUyMHdyYXAtNjZjODU4YzEyZTkyNDE5N2YyNWEzYzc4LnBuZw==",
        productCalories: 348,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 31,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 470,
    totalFmcCentsPrice: 470,
    totalFiatCentsPriceOriginal: 470,
    totalExternalFiatCentsPrice: 470,
    totalFmcCentsPriceOriginal: 470,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:33:53.073Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:35:13.968Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:40:02.200Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T19:52:47.847Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T19:52:47.847Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T19:53:09.695Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:08:54.154Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:33:53.073Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:34:05.063Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:33:53.073Z",
    updatedAt: "2024-10-23T20:08:54.496Z",
    fleetInfo: null,
  },
  {
    id: "67194eaab7a34cd09239f6a7",
    sid: "241023-545316",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67194ea9b7a34cd09239f69d",
    firstName: "aishah ",
    lastName: null,
    phoneNumber: "+96599948868",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c3dcf175a6aa442d0f2841",
    delivery: {
      id: "67194ea9b7a34cd09239f69d",
      customerId: "64c3dcf175a6aa442d0f2841",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64cbe076787ffa718056e1c9",
        name: "home",
        lng: 48.00551377236843,
        lat: 29.30865131363749,
        city: "Kuwait",
        area: "Surra",
        block: "4",
        street: "9",
        building: "5",
        floor: "",
        flat: "",
        phone: "+96599948868",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-03T17:14:30.456Z",
        updatedAt: "2024-10-23T19:29:10.253Z",
      },
      deliverectValidationId: "67194ea972410ebb08a6cdec",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:39:45.797Z",
      estimatedDeliveryTime: "2024-10-23T19:54:44.747Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:26:00.000Z",
      orderId: "67194eaab7a34cd09239f6a7",
      createdAt: "2024-10-23T19:29:45.927Z",
      updatedAt: "2024-10-23T19:31:11.858Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339bb6",
        productPLU: "2161013-1",
        productDescription:
          "C 28g| P 22g| F 21g\n2 fried eggs with avocado and beef sausage served with sour dough toast.\n\nGood source of Protein & healthy fat",
        productName: "Egg Avo Platter",
        imageURL:
          "https://resizer.deliverect.com/r_ObTbJI3AoU9w6PFaC93-52m67nkc-am15KRZoSW6I/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRWdnJTIwYXZvY2FkbyUyMGJyZWFra2llJTIwKDEpLTY2MzYwMTA4Y2JiMDVhYTc5N2MyNjg4MS5qcGc=",
        productCalories: 389,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 22,
          fat: 21,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "no avocado\nextra sausage",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339c25",
        productPLU: "2166040-1",
        productDescription: "C 22g | P 13g | F 5.6g",
        productName: "PROPUFFS Chili Lemon",
        imageURL:
          "https://resizer.deliverect.com/tdSwmeq79dgfjvNGOk-dkicu6FKBe5l8mtdGTYVaDJo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjAzLTY2YjUzNjA5NDAxOTZkZGUyZjg5MjliNS5wbmc=",
        productCalories: 190,
        quantity: 5,
        nutritionInfo: {
          carbs: 22,
          protein: 13,
          fat: 5.6,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 700,
    totalFmcCentsPrice: 700,
    totalFiatCentsPriceOriginal: 700,
    totalExternalFiatCentsPrice: 700,
    totalFmcCentsPriceOriginal: 700,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:29:46.504Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:31:12.574Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:33:12.344Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T19:55:49.584Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T19:55:49.584Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T19:57:40.974Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:04:37.182Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:29:46.504Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:29:58.249Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:29:46.504Z",
    updatedAt: "2024-10-23T20:04:37.531Z",
    fleetInfo: null,
  },
  {
    id: "67194dbab7a34cd09239c7f2",
    sid: "241023-109495",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67194dbab7a34cd09239c7dd",
    firstName: "Fatima alajmi",
    lastName: null,
    phoneNumber: "+96599582866",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "667aa1f28c15153ac88a2816",
    delivery: {
      id: "67194dbab7a34cd09239c7dd",
      customerId: "667aa1f28c15153ac88a2816",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "667af3c4ac113a1f6d087e3c",
        name: "Home ",
        lng: 48.021631836891174,
        lat: 29.31799146699104,
        city: "Jabriya",
        area: "Jabriya",
        block: "5",
        street: "14",
        building: "54",
        floor: "",
        flat: "",
        phone: "+96599582866",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-25T16:43:48.745Z",
        updatedAt: "2024-09-13T08:21:29.434Z",
      },
      deliverectValidationId: "67194db9b63f266a7d31a369",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:35:45.906Z",
      estimatedDeliveryTime: "2024-10-23T19:50:44.751Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:22:00.000Z",
      orderId: "67194dbab7a34cd09239c7f2",
      createdAt: "2024-10-23T19:25:46.003Z",
      updatedAt: "2024-10-23T19:27:06.545Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339bdb",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "671939bdb7a34cd092339c7e",
            productPLU: "1907009-1",
            productName: "No Ketchup Mustard Sauce",
            productDescription: "C -5.7g| P -0.2g| F 0g| cal -21| coins 0",
            productCalories: -21,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -5.7,
              protein: -0.2,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "671939bdb7a34cd092339bdc",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339c1d",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 490,
    totalFmcCentsPrice: 490,
    totalFiatCentsPriceOriginal: 490,
    totalExternalFiatCentsPrice: 0,
    totalFmcCentsPriceOriginal: 490,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:25:46.758Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:27:11.532Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:28:14.588Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T19:43:11.904Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T19:43:11.905Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T19:56:19.576Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:03:07.407Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:25:46.758Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:25:58.199Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:25:46.758Z",
    updatedAt: "2024-10-23T20:03:07.741Z",
    fleetInfo: null,
  },
  {
    id: "67194c6ab7a34cd092398ec0",
    sid: "241023-906401",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67194c6ab7a34cd092398eb4",
    firstName: "Jumanah Ali Alduraia",
    lastName: null,
    phoneNumber: "+96594004107",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "666ca76ceff64cfb069569c7",
    delivery: {
      id: "67194c6ab7a34cd092398eb4",
      customerId: "666ca76ceff64cfb069569c7",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "666ca83f0ce21ecb25b6cc0c",
        name: "Home shaab",
        lng: 48.018374629318714,
        lat: 29.35339818112407,
        city: "Kuwait City",
        area: "Shaab",
        block: "6",
        street: "Alzubair bin alawam",
        building: "5",
        floor: "",
        flat: "",
        phone: "+96594004107",
        additionalInfo: "Avenue 67 corner house",
        isSelected: true,
        createdAt: "2024-06-14T20:29:51.576Z",
        updatedAt: "2024-10-23T19:16:13.438Z",
      },
      deliverectValidationId: "67194c6a0348c4b0ea361046",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:30:10.227Z",
      estimatedDeliveryTime: "2024-10-23T19:45:08.832Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:17:00.000Z",
      orderId: "67194c6ab7a34cd092398ec0",
      createdAt: "2024-10-23T19:20:10.317Z",
      updatedAt: "2024-10-23T19:22:08.776Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339bb7",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339c19",
        productPLU: "2168002-1",
        productDescription:
          "C 4g | P 2g | F 2g|\nCreamy mushroom soup topped with parsley. ",
        productName: "Mushroom Soup",
        imageURL:
          "https://resizer.deliverect.com/VvtgR2CAH02ajyt1L7X3df8XtKjNQ2Kx-d7hCJY0Q1w/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBzb3VwLTY2NDM3YTg5ZTFhZTBmYmZhZDYyYmVhZC5qcGc=",
        productCalories: 42,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 2,
          fat: 2,
        },
        fmcCentsPrice: 140,
        fiatCentsPrice: 140,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339c51",
        productPLU: "2202022-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Sprite Zero",
        imageURL:
          "https://resizer.deliverect.com/ZEZdNlPffzmGS3rg7Tvd5hMcV02zZNG7y9fZavC83oI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMSUyMCgxKS02NGM5MTk0M2E0NTBlNjAwMjY4M2QzODEuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 320,
    totalFmcCentsPrice: 320,
    totalFiatCentsPriceOriginal: 320,
    totalExternalFiatCentsPrice: 320,
    totalFmcCentsPriceOriginal: 320,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:20:10.974Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:22:07.086Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:22:53.087Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T19:40:06.845Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T19:40:06.845Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T19:41:27.118Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T20:05:39.173Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:20:10.974Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:20:23.106Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:20:10.974Z",
    updatedAt: "2024-10-23T20:05:39.613Z",
    fleetInfo: null,
  },
  {
    id: "67194b41b7a34cd09239638d",
    sid: "241023-255140",
    coupons: [],
    menuId: "671939bdb7a34cd092339b57",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67194b40b7a34cd092396383",
    firstName: "Nasser Alghatam",
    lastName: null,
    phoneNumber: "+96566614140",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d78b30dfecd0088b516a65",
    delivery: {
      id: "67194b40b7a34cd092396383",
      customerId: "64d78b30dfecd0088b516a65",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66ae10eb1d6702aca3e3146e",
        name: "Home",
        lng: 48.002001754939556,
        lat: 29.310710667932277,
        city: "Kuwait",
        area: "Surra",
        block: "4",
        street: "15",
        building: "29",
        floor: "",
        flat: "",
        phone: "+96566614140",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-03T11:13:47.771Z",
        updatedAt: "2024-10-23T12:25:18.481Z",
      },
      deliverectValidationId: "67194b403946960ae1d8c259",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:25:12.747Z",
      estimatedDeliveryTime: "2024-10-23T19:40:11.747Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:12:00.000Z",
      orderId: "67194b41b7a34cd09239638d",
      createdAt: "2024-10-23T19:15:12.909Z",
      updatedAt: "2024-10-23T19:17:11.053Z",
    },
    items: [
      {
        productId: "671939bdb7a34cd092339c1d",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939bdb7a34cd092339c1c",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 270,
    totalExternalFiatCentsPrice: 270,
    totalFmcCentsPriceOriginal: 270,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:15:13.490Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:17:12.199Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:22:53.204Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T19:29:52.377Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T19:29:52.377Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T19:30:14.207Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T19:37:35.907Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:15:13.490Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:15:25.086Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:15:13.490Z",
    updatedAt: "2024-10-23T19:37:36.523Z",
    fleetInfo: null,
  },
  {
    id: "67194ae8b7a34cd0923957af",
    sid: "241023-281766",
    coupons: [],
    menuId: "671939c4b7a34cd09233a4da",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67194ae7b7a34cd0923957a5",
    firstName: "Saleh khalid ",
    lastName: null,
    phoneNumber: "+96556666343",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67054999906276458c8a6d99",
    delivery: {
      id: "67194ae7b7a34cd0923957a5",
      customerId: "67054999906276458c8a6d99",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670965dff495b693cdf67390",
        name: "Dwaniya",
        lng: 47.991310469806194,
        lat: 29.30757014954666,
        city: "Kuwait",
        area: "Qortuba",
        block: "2",
        street: "2 St. 6 Ln",
        building: "4",
        floor: "",
        flat: "",
        phone: "+96556666343",
        additionalInfo: "Basment",
        isSelected: true,
        createdAt: "2024-10-11T17:52:31.088Z",
        updatedAt: "2024-10-23T19:10:37.402Z",
      },
      deliverectValidationId: "67194ae772410ebb08a4a52b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T19:23:43.743Z",
      estimatedDeliveryTime: "2024-10-23T19:38:42.605Z",
      estimatedDeliveryTimeEnd: "2024-10-23T20:10:00.000Z",
      orderId: "67194ae8b7a34cd0923957af",
      createdAt: "2024-10-23T19:13:43.836Z",
      updatedAt: "2024-10-23T19:15:06.961Z",
    },
    items: [
      {
        productId: "671939c4b7a34cd09233a558",
        productPLU: "2162089-1",
        productDescription:
          "C 36g | P 43g | F 18g\nMongolian beef with green bell peppers & green onions served with bulgur veggie mix.\n\nGOOD SOURCE OF PROTEIN & VITAMINA B12& VITAMIN C",
        productName: "Mongolian Beef",
        imageURL:
          "https://resizer.deliverect.com/ZXcmtUYh2Cc-oEj8YoLx8mWIX35FnYCLywx_hPNI4_I/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNSUyMCgxKS02NjUxZGY2Yzc0OGYzNjdjMTMxNzJhZGQucG5n",
        productCalories: 478,
        quantity: 1,
        nutritionInfo: {
          carbs: 36,
          protein: 43,
          fat: 18,
        },
        fmcCentsPrice: 460,
        fiatCentsPrice: 460,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "671939c4b7a34cd09233a577",
        productPLU: "2162075-1",
        productDescription:
          "C 53g| P 20g| F 9g\nGround beef burrito with seasoned egyptian rice, corn, black beans, lettuce & sour cream wrapped in a tortilla wrap.\n\nGOOD SOURCE OF PROTEIN & FIBER",
        productName: "Beef Burrito",
        imageURL:
          "https://resizer.deliverect.com/85QohnB6E-ysOf_DICo7nPH2RjHXSEy1Qm9NHYJHOgk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGJ1cnJpdG8oMSktNjU1NDhmYTA2MmFiMGUwMDFmM2E3MzczLnBuZw==",
        productCalories: 373,
        quantity: 1,
        nutritionInfo: {
          carbs: 53,
          protein: 20,
          fat: 9,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 650,
    totalFmcCentsPrice: 650,
    totalFiatCentsPriceOriginal: 650,
    totalExternalFiatCentsPrice: 650,
    totalFmcCentsPriceOriginal: 650,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:13:44.542Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T19:15:18.896Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T19:17:17.335Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T19:26:19.531Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T19:26:19.531Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T19:28:50.780Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T19:58:48.259Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T19:13:44.542Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T19:13:55.971Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T19:13:44.542Z",
    updatedAt: "2024-10-23T19:58:48.705Z",
    fleetInfo: null,
  },

  {
    id: "671918efb7a34cd09226aed0",
    sid: "241023-552886",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671918eeb7a34cd09226ad76",
    firstName: "Hass",
    lastName: null,
    phoneNumber: "+96569000075",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "662f78f17ef16a176ee68d29",
    delivery: {
      id: "671918eeb7a34cd09226ad76",
      customerId: "662f78f17ef16a176ee68d29",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6719066db7a34cd092219f06",
        name: "مستشفى العسكري ",
        lng: 48.0394034832716,
        lat: 29.249339812764255,
        city: "Sabah Al Salem",
        area: "Sabah Al Salem",
        block: "صبحان ",
        street: "مستشفى العكسري",
        building: "المستشفى الرئيسي",
        floor: "4",
        flat: "1",
        phone: "+96569000075",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-23T14:21:33.051Z",
        updatedAt: "2024-10-23T14:21:33.051Z",
      },
      deliverectValidationId: "671918ee7aed983727b0c3db",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:50:30.262Z",
      estimatedDeliveryTime: "2024-10-23T16:05:29.013Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:37:00.000Z",
      orderId: "671918efb7a34cd09226aed0",
      createdAt: "2024-10-23T15:40:30.538Z",
      updatedAt: "2024-10-23T15:42:07.280Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fda",
        productPLU: "2162116-1",
        productDescription:
          "C 61g | P 36g | F 12g\nVeal with biryani rice & sauce, lime wedges, salad, lime yogurt, air fried onion, rose petals, coriander, roasted cashew nuts, golden raisins.\n\nGOOD SOURCE OF PROTEIN,VITAMIN & MINERALS",
        productName: "Veal Biryani",
        imageURL:
          "https://resizer.deliverect.com/4XXRKqwztfEBnvDx9RJ6qOfBLstTwbE3mNEBSrckFJ8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVkVBTCUyMEJJUllBTkktNjY1MDNhOWRlMWFlMGZiZmFkNjMwNTFhLnBuZw==",
        productCalories: 496,
        quantity: 1,
        nutritionInfo: {
          carbs: 61,
          protein: 36,
          fat: 12,
        },
        fmcCentsPrice: 350,
        fiatCentsPrice: 350,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208601e",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.2,
          protein: 2.8,
          fat: 8.6,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 430,
    totalFmcCentsPrice: 430,
    totalFiatCentsPriceOriginal: 430,
    totalExternalFiatCentsPrice: 430,
    totalFmcCentsPriceOriginal: 430,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:40:31.584Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:42:09.826Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:42:29.096Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:55:47.845Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:55:47.845Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:56:09.782Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:30:49.813Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:40:31.584Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:40:44.903Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:40:31.584Z",
    updatedAt: "2024-10-23T16:30:50.170Z",
    fleetInfo: null,
  },
  {
    id: "671918cdb7a34cd0922698fc",
    sid: "241023-347494",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671918ccb7a34cd0922698bc",
    firstName: "Mohammad eisa",
    lastName: null,
    phoneNumber: "+96551637555",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6702596b906276458c81cd16",
    delivery: {
      id: "671918ccb7a34cd0922698bc",
      customerId: "6702596b906276458c81cd16",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67025c09218a8c4af24065b1",
        name: "Home",
        lng: 48.02583049982786,
        lat: 29.290462891990625,
        city: "Kuwait",
        area: "Salam",
        block: "5",
        street: "515 ",
        building: "55",
        floor: "",
        flat: "",
        phone: "+96551637555",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-06T09:44:41.655Z",
        updatedAt: "2024-10-23T15:38:26.432Z",
      },
      deliverectValidationId: "671918cc4871f32c09257b9e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:49:56.432Z",
      estimatedDeliveryTime: "2024-10-23T16:04:55.006Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:36:00.000Z",
      orderId: "671918cdb7a34cd0922698fc",
      createdAt: "2024-10-23T15:39:56.531Z",
      updatedAt: "2024-10-23T15:41:11.262Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fec",
        productPLU: "2162006-1",
        productDescription:
          "C 33g| P 19g| F 16g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 352,
        quantity: 2,
        nutritionInfo: {
          carbs: 33,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 460,
    totalFmcCentsPrice: 460,
    totalFiatCentsPriceOriginal: 460,
    totalExternalFiatCentsPrice: 460,
    totalFmcCentsPriceOriginal: 460,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:39:57.393Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:41:31.799Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:41:50.780Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:52:21.845Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:52:21.845Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T16:07:19.594Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:43:37.357Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:39:57.393Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:40:21.506Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:39:57.393Z",
    updatedAt: "2024-10-23T16:43:37.822Z",
    fleetInfo: null,
  },
  {
    id: "671918ccb7a34cd0922698cc",
    sid: "241023-391055",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671918ccb7a34cd0922698b9",
    firstName: "Marwa alruwaieh",
    lastName: null,
    phoneNumber: "+96597129999",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c3e80475a6aa442d0f2fd0",
    delivery: {
      id: "671918ccb7a34cd0922698b9",
      customerId: "64c3e80475a6aa442d0f2fd0",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64ea53be3b8f1d06c4d10862",
        name: "Home",
        lng: 47.972676677824566,
        lat: 29.315765380859375,
        city: "Kuwait",
        area: "Yarmouk",
        block: "1",
        street: "1 St 4 Ln",
        building: "8",
        floor: "",
        flat: "",
        phone: "+96597129999",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-26T19:34:22.029Z",
        updatedAt: "2024-08-22T17:26:58.132Z",
      },
      deliverectValidationId: "671918ccb63f266a7d15db3f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:49:56.273Z",
      estimatedDeliveryTime: "2024-10-23T16:04:55.215Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:36:00.000Z",
      orderId: "671918ccb7a34cd0922698cc",
      createdAt: "2024-10-23T15:39:56.425Z",
      updatedAt: "2024-10-23T15:41:11.259Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fdc",
        productPLU: "2162111-1",
        productDescription:
          "C 72g | P 23g | F 34g[%Popular%]\nChicken burger with baked sweet potato fries and bbq sauce",
        productName: "Chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/fMQuypdGrqkSRZhbEebRsjjcen1dY6rXtUgYEcTeE9s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cmdlciUyMGNvbWJvLTY3MTM1NzgzMWM2ZDc0YTE4NGJmNTY2ZC5wbmc=",
        productCalories: 686,
        quantity: 1,
        nutritionInfo: {
          carbs: 72,
          protein: 23,
          fat: 34,
        },
        fmcCentsPrice: 340,
        fiatCentsPrice: 340,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092086043",
        productPLU: "2166010-1",
        productDescription:
          "C 60g| P 11g| F 14g\nBerry oats with chia seeds, flax seeds, raspberry, blueberry, blackberry, berry jam and lotus biscuit.\n\nGood source of Vitamins & Dietary fibre",
        productName: "Triple Berry Oats",
        imageURL:
          "https://resizer.deliverect.com/I1vcxMkKmY29tfnBVwlJ4hYSD37vX8QkNOhoNFzCZWc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NTExNmVhNWIxNjEyMDgzZWJjNmNjNDMuanBn",
        productCalories: 410,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 11,
          fat: 14,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 430,
    totalFmcCentsPrice: 430,
    totalFiatCentsPriceOriginal: 430,
    totalExternalFiatCentsPrice: 430,
    totalFmcCentsPriceOriginal: 430,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:39:56.936Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:41:20.971Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:41:58.663Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:52:34.542Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:52:34.543Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T16:08:05.622Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:22:53.278Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:39:56.936Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:40:08.445Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:39:56.936Z",
    updatedAt: "2024-10-23T16:22:54.638Z",
    fleetInfo: null,
  },
  {
    id: "671918c1b7a34cd092269443",
    sid: "241023-167163",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671918c0b7a34cd092269431",
    firstName: "Taibah Ahmad",
    lastName: null,
    phoneNumber: "+96566356656",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "652670b61bfa57c5dbf49637",
    delivery: {
      id: "671918c0b7a34cd092269431",
      customerId: "652670b61bfa57c5dbf49637",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66bc78d63e27c431ef46d82d",
        name: "Home",
        lng: 47.88862980902195,
        lat: 29.30264724904058,
        city: "Andalous",
        area: "Andalous",
        block: "10",
        street: "106",
        building: "35",
        floor: "Ground floor",
        flat: "",
        phone: "+96566356656",
        additionalInfo: "2nd house near roundabout gray house ",
        isSelected: true,
        createdAt: "2024-08-14T09:28:54.629Z",
        updatedAt: "2024-10-23T11:04:31.459Z",
      },
      deliverectValidationId: "671918c00348c4b0ea1a19c3",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:49:44.799Z",
      estimatedDeliveryTime: "2024-10-23T16:04:43.769Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:36:00.000Z",
      orderId: "671918c1b7a34cd092269443",
      createdAt: "2024-10-23T15:39:44.897Z",
      updatedAt: "2024-10-23T15:41:06.765Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fd7",
        productPLU: "2162119-1",
        productDescription:
          "C 61g | P 30g | F 15g \nWhite sauce, cheese, chicken & ranch.\n\nGood source of Protein ",
        productName: "Chicken Ranch Pizza",
        imageURL:
          "https://resizer.deliverect.com/pOgi343_UbyaTdgs3CsFQ3-UsGlbLpc3aSBCJo-QO7g/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmFuY2gtNjY2YjMwODA1MGM0ODZkNGMzYTJkOGMzLnBuZw==",
        productCalories: 499,
        quantity: 2,
        nutritionInfo: {
          carbs: 61,
          protein: 30,
          fat: 15,
        },
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092086062",
        productPLU: "2202021-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Coca Cola Zero",
        imageURL:
          "https://resizer.deliverect.com/NvHctUF0z3us3Lhe9x5u3_mQIOOXnn8K3FvSZO6MkK4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCgxKS02NGM5MTk1MjZlY2Q3NzAwMjZjNGU1MDUuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 670,
    totalFmcCentsPrice: 670,
    totalFiatCentsPriceOriginal: 670,
    totalExternalFiatCentsPrice: 670,
    totalFmcCentsPriceOriginal: 670,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:39:45.460Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:41:08.673Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:41:55.984Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:52:00.094Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:52:00.095Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T16:02:12.764Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:19:37.595Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:39:45.460Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:39:56.952Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:39:45.460Z",
    updatedAt: "2024-10-23T16:19:37.948Z",
    fleetInfo: null,
  },
  {
    id: "6719189eb7a34cd092268940",
    sid: "241023-052734",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6719189eb7a34cd092268911",
    firstName: "Sherifa Alzeer",
    lastName: null,
    phoneNumber: "+96596665726",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66d31ceed7546254fad1e3c7",
    delivery: {
      id: "6719189eb7a34cd092268911",
      customerId: "66d31ceed7546254fad1e3c7",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66e08050ced6c5247e937921",
        name: "Home ",
        lng: 47.98927060401771,
        lat: 29.334732055664062,
        city: "Kuwait",
        area: "Rawda",
        block: "5",
        street: "57 St",
        building: "32",
        floor: "",
        flat: "",
        phone: "+96596665726",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-10T17:22:24.700Z",
        updatedAt: "2024-10-22T16:41:03.680Z",
      },
      deliverectValidationId: "6719189d7d3a1e48915d18cc",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:49:09.978Z",
      estimatedDeliveryTime: "2024-10-23T16:04:08.628Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:36:00.000Z",
      orderId: "6719189eb7a34cd092268940",
      createdAt: "2024-10-23T15:39:10.133Z",
      updatedAt: "2024-10-23T15:41:07.302Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208675b",
        productPLU: "2167025-1",
        productDescription:
          "C 29g | P 31g | F 12g\nTortilla with chicken breast, Mexican sauce, cheddar cheese, red & green capsicum.\n\nGood source of Protein & Vitamin C",
        productName: "Mexican wrap",
        imageURL:
          "https://resizer.deliverect.com/rAzOkou3Hx2u334uLtcNGO0XSk3R-noyrtj8ts7K71I/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTWV4aWNhbiUyMHdyYXAtNjZjODU4YzEyZTkyNDE5N2YyNWEzYzc4LnBuZw==",
        productCalories: 348,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 31,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd09208676d",
        productPLU: "2163020-1",
        productDescription:
          "C 10g | P 2g | F 3g [%New%]\n\nRomaine lettuce, cucumber, green capsicum, spring onion, parsley, dill leaves, sumac, croutons with Italian dressing.",
        productName: "Mini Italian salad",
        imageURL:
          "https://resizer.deliverect.com/XkxzmQRK5xU2IA0XJqjnN8x_RpgC6KxTMoxtFe2tjkE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGl0YWxpYW4lMjBzYWxhZC02NzEzNGUwMjQ4OGM3MjkyNjAyMTliNDcucG5n",
        productCalories: 75,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 2,
          fat: 3,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 360,
    totalFmcCentsPrice: 360,
    totalFiatCentsPriceOriginal: 360,
    totalExternalFiatCentsPrice: 360,
    totalFmcCentsPriceOriginal: 360,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:39:10.920Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:41:07.694Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:43:45.278Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:57:40.365Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:57:40.365Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T16:03:35.401Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:31:46.182Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:39:10.920Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:39:15.991Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:39:10.920Z",
    updatedAt: "2024-10-23T16:31:46.523Z",
    fleetInfo: null,
  },
  {
    id: "671917f6b7a34cd092265483",
    sid: "241023-043376",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671917f5b7a34cd092265476",
    firstName: "Mubarak hamad",
    lastName: null,
    phoneNumber: "+96550795955",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64dd245e70771121d149f2e2",
    delivery: {
      id: "671917f5b7a34cd092265476",
      customerId: "64dd245e70771121d149f2e2",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "656eba1658a9039e60444971",
        name: "Home",
        lng: 48.00054665654898,
        lat: 29.286075471654698,
        city: "Kuwait",
        area: "Al-Siddeeq",
        block: "6",
        street: "611",
        building: "30",
        floor: "",
        flat: "",
        phone: "+96550795955",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-05T05:50:14.113Z",
        updatedAt: "2023-12-05T05:50:14.113Z",
      },
      deliverectValidationId: "671917f5982ae1ad6dc655e5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:46:21.743Z",
      estimatedDeliveryTime: "2024-10-23T16:01:20.677Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:33:00.000Z",
      orderId: "671917f6b7a34cd092265483",
      createdAt: "2024-10-23T15:36:21.849Z",
      updatedAt: "2024-10-23T15:38:07.466Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085ffa",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092085ff6",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 480,
    totalFmcCentsPrice: 480,
    totalFiatCentsPriceOriginal: 480,
    totalExternalFiatCentsPrice: 480,
    totalFmcCentsPriceOriginal: 480,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:36:22.554Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:38:14.899Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:38:49.684Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:52:10.998Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:52:10.998Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T16:01:47.161Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:15:28.382Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:36:22.554Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:36:33.959Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:36:22.554Z",
    updatedAt: "2024-10-23T16:15:28.715Z",
    fleetInfo: null,
  },
  {
    id: "671917e9b7a34cd092264c1f",
    sid: "241023-066069",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671917e8b7a34cd092264c05",
    firstName: "Anan Alessa",
    lastName: null,
    phoneNumber: "+96599346660",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "653b8b2a1bfa57c5db096356",
    delivery: {
      id: "671917e8b7a34cd092264c05",
      customerId: "653b8b2a1bfa57c5db096356",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67129487b7a34cd092008181",
        name: "Surra",
        lng: 48.013836901022884,
        lat: 29.315444946289062,
        city: "Al Kuwayt",
        area: "Surra",
        block: "2",
        street: "3",
        building: "11",
        floor: "",
        flat: "",
        phone: "+96599346660",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-18T17:01:59.812Z",
        updatedAt: "2024-10-23T15:16:49.257Z",
      },
      deliverectValidationId: "671917e805f0aa7bf68a7830",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:46:08.665Z",
      estimatedDeliveryTime: "2024-10-23T16:01:07.474Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:33:00.000Z",
      orderId: "671917e9b7a34cd092264c1f",
      createdAt: "2024-10-23T15:36:08.764Z",
      updatedAt: "2024-10-23T15:38:09.101Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208675e",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 2,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd09208676b",
        productPLU: "2163022-1",
        productDescription:
          "C 15g | P 2g | F 14g [%New%]\n\nVeggies with fattoush dressing & croutons.\n\nGood source of Vitamins",
        productName: "Mini Fattoush",
        imageURL:
          "https://resizer.deliverect.com/QUcv0CxdaaAXW0GlMNB1GAQ5fahshLaeuOoMIRAFR5c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGZhdHRvdXNoJTIwc2FsYWQtNjcxMzUxNmViYTZjN2U5ZTgzMmVkM2MyLnBuZw==",
        productCalories: 194,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 2,
          fat: 14,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 730,
    totalFmcCentsPrice: 730,
    totalFiatCentsPriceOriginal: 730,
    totalExternalFiatCentsPrice: 730,
    totalFmcCentsPriceOriginal: 730,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:36:09.734Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:38:08.050Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:39:56.239Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:52:47.028Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:52:47.028Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:53:09.081Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:03:35.485Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:36:09.734Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:36:21.808Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:36:09.734Z",
    updatedAt: "2024-10-23T16:03:35.820Z",
    fleetInfo: null,
  },
  {
    id: "67191783b7a34cd092262341",
    sid: "241023-755829",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67191782b7a34cd092262336",
    firstName: "Aljoharah Abalkhail",
    lastName: null,
    phoneNumber: "+96599641989",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6701520b906276458c7fbd52",
    delivery: {
      id: "67191782b7a34cd092262336",
      customerId: "6701520b906276458c7fbd52",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670159e6218a8c4af223dae3",
        name: "Home",
        lng: 47.97214890547146,
        lat: 29.31463623046875,
        city: "Kuwait",
        area: "Yarmouk",
        block: "1",
        street: "1",
        building: "12",
        floor: "",
        flat: "",
        phone: "+96599641989",
        additionalInfo: "Avenue 6",
        isSelected: true,
        createdAt: "2024-10-05T15:23:18.076Z",
        updatedAt: "2024-10-05T15:23:18.076Z",
      },
      deliverectValidationId: "671917829d15dbbf9f1d8697",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:44:26.503Z",
      estimatedDeliveryTime: "2024-10-23T15:59:25.238Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:31:00.000Z",
      orderId: "67191783b7a34cd092262341",
      createdAt: "2024-10-23T15:34:26.636Z",
      updatedAt: "2024-10-23T15:36:13.606Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fdd",
        productPLU: "2162112-1",
        productDescription:
          "C 65g | P 21g | F 28g[%Popular%]\nFM burger with baked sweet potato fries and bbq sauce",
        productName: "FM burger & fries",
        imageURL:
          "https://resizer.deliverect.com/RmA60bYBZskDxWGZ3uPWH1NZAH_rxnDkyiAlnyV_l1o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBidXJnZXIlMjBjb21iby02NzEzNTc5NTQ4OGM3MjkyNjAyMTliNWYucG5n",
        productCalories: 596,
        quantity: 1,
        nutritionInfo: {
          carbs: 65,
          protein: 21,
          fat: 28,
        },
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208601c",
        productPLU: "2166026-1",
        productDescription:
          "C 13g| P 6g| F 9g\nKale, cabbage, carrots, onions, orange slices & cashews topped with sesame seeds & drizzled with garlic ginger peanut dressing.\n\nGOOD SOURCE OF VITAMIN & VITAMIN C",
        productName: "Mini Thai Slaw",
        imageURL:
          "https://resizer.deliverect.com/89E9zE4JuS-ammKNFUesPBA8SiE1FYMyZUmczLAr-Gg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMHRoYWklMjBzbGF3LTY1MDQ5NDYxOGVjNDM1MDAxYTRjMTQ2My5qcGc=",
        productCalories: 157,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 6,
          fat: 9,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 480,
    totalFmcCentsPrice: 480,
    totalFiatCentsPriceOriginal: 480,
    totalExternalFiatCentsPrice: 480,
    totalFmcCentsPriceOriginal: 480,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:34:27.519Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:36:14.683Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:36:40.272Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:47:27.588Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:47:27.588Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:49:51.018Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:06:11.064Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:34:27.519Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:34:38.941Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:34:27.519Z",
    updatedAt: "2024-10-23T16:06:11.401Z",
    fleetInfo: null,
  },
  {
    id: "67191760b7a34cd092261a74",
    sid: "241023-434460",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6719175fb7a34cd092261a65",
    firstName: "Rana Ragab",
    lastName: null,
    phoneNumber: "+96594031132",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65a24d8b2d5e4e9926925286",
    delivery: {
      id: "6719175fb7a34cd092261a65",
      customerId: "65a24d8b2d5e4e9926925286",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65a25520afe41535d3c5572a",
        name: "Home",
        lng: 48.02786879629162,
        lat: 29.31829833984375,
        city: "Jabriya",
        area: "Jabriya",
        block: "6",
        street: "7 St",
        building: "16",
        floor: "Basement ",
        flat: "1",
        phone: "+96594031132",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-13T09:17:20.026Z",
        updatedAt: "2024-10-16T12:36:51.591Z",
      },
      deliverectValidationId: "6719175f81b8d7611cc7e6a5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:43:51.349Z",
      estimatedDeliveryTime: "2024-10-23T15:58:50.211Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:30:00.000Z",
      orderId: "67191760b7a34cd092261a74",
      createdAt: "2024-10-23T15:33:51.656Z",
      updatedAt: "2024-10-23T15:35:05.503Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208675d",
        productPLU: "2167022-1",
        productDescription:
          "C 29g | P 21g | F 16g\nSpicy chicken shawarma with spring onions, pomegranates, parsley, pickled cucumbers & spicy sauce wrapped in tortilla. \n\nGood source of Protein",
        productName: "Spicy Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/7iczQVW8ZJbNN179Yvo32X8zQsGcTbj5NwVYyyacwCk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU0hBV0FSTUlBKDEpLTY2NDRiYzU4M2NiZjdhMzY3ZmI0YTI1ZC5qcGc=",
        productCalories: 344,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 21,
          fat: 16,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd09208678b",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd0920867ae",
        productPLU: "2166048-1",
        productDescription:
          "C 14.5g | P 1.1g | F 9.5g\n\nPecan, caramel, milk chocolate, oregano, salt.",
        productName: "Mini Pecan Bite",
        imageURL:
          "https://resizer.deliverect.com/V2nX4Za8sFq9MzknlziqUwHNWortONN-Rdp0v-bmPQY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVjYW4lMjBiaXRlLTY2ZWMyODA5NmU2M2ZkMzcyODAxMjA4MS5wbmc=",
        productCalories: 155,
        quantity: 1,
        nutritionInfo: {
          carbs: 14.5,
          protein: 1.1,
          fat: 9.5,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 310,
    totalFmcCentsPrice: 310,
    totalFiatCentsPriceOriginal: 310,
    totalExternalFiatCentsPrice: 310,
    totalFmcCentsPriceOriginal: 310,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:33:52.237Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:35:05.723Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:35:21.613Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:56:10.055Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:56:10.055Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:56:47.969Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:24:44.455Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:33:52.237Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:34:03.816Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:33:52.237Z",
    updatedAt: "2024-10-23T16:24:44.793Z",
    fleetInfo: null,
  },
  {
    id: "67191745b7a34cd092260f0c",
    sid: "241023-910888",
    coupons: [],
    menuId: "6718bc39b7a34cd09208791a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67191745b7a34cd092260ed3",
    firstName: "nour almejadi",
    lastName: null,
    phoneNumber: "+96599118336",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64e3838070771121d14ce675",
    delivery: {
      id: "67191745b7a34cd092260ed3",
      customerId: "64e3838070771121d14ce675",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64e3c3d9e4583466bfcaece8",
        name: "home",
        lng: 48.0568877980113,
        lat: 29.23952702255255,
        city: "Jabriya",
        area: "Al-Adan District",
        block: "2",
        street: "14 St",
        building: "9",
        floor: "",
        flat: "",
        phone: "+96599646122",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-21T20:06:49.576Z",
        updatedAt: "2024-07-14T16:29:50.549Z",
      },
      deliverectValidationId: "67191744cc78686e3fb778c5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:43:24.823Z",
      estimatedDeliveryTime: "2024-10-23T15:58:23.679Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:30:00.000Z",
      orderId: "67191745b7a34cd092260f0c",
      createdAt: "2024-10-23T15:33:25.027Z",
      updatedAt: "2024-10-23T15:35:07.543Z",
    },
    items: [
      {
        productId: "6718bc39b7a34cd09208798d",
        productPLU: "2162109-1",
        productDescription:
          "C 23g | P 29g | F 16g \n Beef Lasagna with zucchini & basil\n\nGood source of protein and vitamin A",
        productName: "Zucchini Beef Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SGhwFjSpMczUdKMaEIYf4gYXABWfE33sF_WueZYOSfI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGxhc2FnbmEtNjY0NGFmZDU3NDhmMzY3YzEzMTZlNWZhLnBuZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 23,
          protein: 29,
          fat: 16,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd09208798c",
        productPLU: "2162107-1",
        productDescription:
          "C 30g | P 46g | F 9g\nGrilled zaatar chicken with saj bread and zaatar yogurt sauce. Contains coriander \n\nGood source of Protein ",
        productName: "Zaatar Chicken",
        imageURL:
          "https://resizer.deliverect.com/HLEGxWozw0ZDVfWyAN_TK_oChgkvffkzz-_bxUrGMJI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemFhdGFyJTIwY2hpY2tlbi02NjQ0YjBjMTc0NWEzOTM5ZTIxNjY5MjAucG5n",
        productCalories: 385,
        quantity: 1,
        nutritionInfo: {
          carbs: 30,
          protein: 46,
          fat: 9,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd0920879c2",
        productPLU: "2163016-1",
        productDescription:
          "C 35g| P 6g| F 10g\nRocca mixed with grilled zucchini, grilled red bell peppers & grilled corn drizzled in a vinaigrette dressing with coriander.\n\nGOOD SOURCE OF FIBER",
        productName: "Rocca Feta Salad",
        imageURL:
          "https://resizer.deliverect.com/hVjV_VJZKJD7LeDzKwVSVGem1rTZivC1K9bQFd7KnCs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjBTQUxBRC02NTE3ZWUwNjM5NGU3ODMxZTFiZDNiNWEuanBn",
        productCalories: 254,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 6,
          fat: 10,
        },
        fmcCentsPrice: 350,
        fiatCentsPrice: 350,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 930,
    totalFmcCentsPrice: 930,
    totalFiatCentsPriceOriginal: 930,
    totalExternalFiatCentsPrice: 930,
    totalFmcCentsPriceOriginal: 930,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:33:25.840Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:35:08.773Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:35:39.905Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:52:58.052Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:52:58.054Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:56:36.210Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:15:40.665Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:33:25.840Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:33:37.384Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:33:25.840Z",
    updatedAt: "2024-10-23T16:15:41.322Z",
    fleetInfo: null,
  },
  {
    id: "67191719b7a34cd092260880",
    sid: "241023-426433",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67191718b7a34cd092260863",
    firstName: "Abdulaziz adel alsorayia",
    lastName: null,
    phoneNumber: "+96599002626",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64bd5538b38e44d254c3b057",
    delivery: {
      id: "67191718b7a34cd092260863",
      customerId: "64bd5538b38e44d254c3b057",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64beda6f966460755c501361",
        name: "My home",
        lng: 48.049190381128525,
        lat: 29.278732299804688,
        city: "Mubarak Al-Abdullah",
        area: "Mubarak Al-Abdullah",
        block: "1",
        street: "111",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96599002626",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-07-24T20:09:19.433Z",
        updatedAt: "2024-10-23T15:30:55.231Z",
      },
      deliverectValidationId: "671917184cb7e660cd8ed1fc",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:42:40.311Z",
      estimatedDeliveryTime: "2024-10-23T15:57:38.413Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:29:00.000Z",
      orderId: "67191719b7a34cd092260880",
      createdAt: "2024-10-23T15:32:40.946Z",
      updatedAt: "2024-10-23T15:34:09.726Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd092086759",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd09208678b",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd0920867b8",
        productPLU: "2202050-1",
        productDescription:
          "C 27g | P 1.5g | F 0g\nOrange \n\nGood source of Vitamin C",
        productName: "Orange Citrus",
        imageURL:
          "https://resizer.deliverect.com/92Cgq5w07iSWvqOzcojq0Egx0BDIxsVNTfIhlYhtUmU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTMtNjY1OTE5ODQ0MjllZTgwN2Q2OWQxYmZkLnBuZw==",
        productCalories: 116,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 1.5,
          fat: null,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:32:41.943Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:34:08.359Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:35:22.021Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:53:19.694Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:53:19.695Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:53:41.544Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:17:47.374Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:32:41.943Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:32:53.663Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:32:41.943Z",
    updatedAt: "2024-10-23T16:17:47.765Z",
    fleetInfo: null,
  },
  {
    id: "6719163fb7a34cd09225d4e3",
    sid: "241023-093530",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719163fb7a34cd09225d4d2",
    firstName: "Mohammad alosaimi",
    lastName: null,
    phoneNumber: "+96550778087",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f7c8508951a675da0834c0",
    delivery: {
      id: "6719163fb7a34cd09225d4d2",
      customerId: "66f7c8508951a675da0834c0",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "66f7c969ee3a746f7f5c2702",
        name: "Kaifan block 2 street Abu alasyeh house 8",
        lng: 47.96398848295212,
        lat: 29.343064671154174,
        city: "Kuwait",
        area: "Kaifan",
        block: "2",
        street: "Abu alasyeh",
        building: "House 8",
        floor: "",
        flat: "",
        phone: "+96550778087",
        additionalInfo: "Has 3 trees",
        isSelected: true,
        createdAt: "2024-09-28T09:16:25.252Z",
        updatedAt: "2024-09-28T09:16:25.252Z",
      },
      deliverectValidationId: "6719163e621b7746abe28502",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:39:02.936Z",
      estimatedDeliveryTime: "2024-10-23T15:54:01.066Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:26:00.000Z",
      orderId: "6719163fb7a34cd09225d4e3",
      createdAt: "2024-10-23T15:29:03.095Z",
      updatedAt: "2024-10-23T15:31:06.134Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd09208709d",
        productPLU: "2161023-1",
        productDescription:
          "C 37g| P 17g| F 13g\nFries topped with caramelized onions, ground beef, pickled jalapeños, american cheese & our special sauce. \n\n*GOOD SOURCE OF PROTEIN,VITAMINB12*",
        productName: "Messy Fries",
        imageURL:
          "https://resizer.deliverect.com/uhTtheRt0RZXomxI_1n5O4G2cP8KUacFKd3MVV-dhio/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTUVTU1klMjBGUklFUy02NTc1OGZhM2QyZWUyNWQ5YmZkZDE4YWUucG5n",
        productCalories: 333,
        quantity: 1,
        nutritionInfo: {
          carbs: 37,
          protein: 17,
          fat: 13,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc1fb7a34cd092087155",
            productPLU: "1907066-1",
            productName: "No Caramelized Onion",
            productDescription: "C -9g| P -1g| F 0g| cal -38| coins 0",
            productCalories: -38,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -9,
              protein: -1,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "6718bc1fb7a34cd0920870a7",
        productPLU: "2166037-1",
        productDescription:
          "C 31g | P 3g | F 8g \nTortilla chips, cheddar cheese, & pickled jalapeños. ",
        productName: "Jalapeño Cheddar Nachos ",
        imageURL:
          "https://resizer.deliverect.com/Igtv_X3IIKdvVYw3drGOS6DlDQf47bmEkgol0b9fBZ0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFsYXBlbm8lMjBjaGVlc2UlMjBuYWNob3MtNjY2MWNkZWQ5YWNlYTFmMWZlZWVkOTU5LnBuZw==",
        productCalories: 208,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 3,
          fat: 8,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd092087070",
        productPLU: "2162076-1",
        productDescription:
          "C 57g| P 32g| F 12g\nGrilled chicken, rice, corn, mixed beans sauce, iceburg lettuce, chedder cheese, mozarella cheese & ranch all wrapped in a tortilla wrap.\n\nGOOD SOURCE OF PROTEIN, VITAMINS AND MINERALS",
        productName: "Chicken Burrito",
        imageURL:
          "https://resizer.deliverect.com/6YIIS1bTpbzMr3WFtmPqqbcL9yd4ZmTrgUo1MJkaRzE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cnJpdG8lMjB3cmFwLTY1NzU4ZmY2MTk4NDQxOWFmYWNhOTI3MS5qcGc=",
        productCalories: 464,
        quantity: 1,
        nutritionInfo: {
          carbs: 57,
          protein: 32,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 570,
    totalFmcCentsPrice: 570,
    totalFiatCentsPriceOriginal: 570,
    totalExternalFiatCentsPrice: 570,
    totalFmcCentsPriceOriginal: 570,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:29:03.735Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:31:14.212Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:35:49.550Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:54:03.827Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:54:03.827Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:54:25.828Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:17:02.151Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:29:03.735Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:29:15.262Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:29:03.735Z",
    updatedAt: "2024-10-23T16:20:01.755Z",
    fleetInfo: null,
  },
  {
    id: "671915d2b7a34cd09225aa89",
    sid: "241023-679572",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671915d1b7a34cd09225aa7c",
    firstName: "Munirah Almershed",
    lastName: null,
    phoneNumber: "+96560006509",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "651eaf7cc6da5aa4aeea8774",
    delivery: {
      id: "671915d1b7a34cd09225aa7c",
      customerId: "651eaf7cc6da5aa4aeea8774",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66e98a20ced6c5247e9ceb46",
        name: "Alsomait apt",
        lng: 48.00124268978834,
        lat: 29.33405855532594,
        city: "Kuwait",
        area: "Rawda",
        block: "4",
        street: "47St",
        building: "8A",
        floor: "2",
        flat: "3",
        phone: "+96560006509",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-17T13:54:40.008Z",
        updatedAt: "2024-10-23T15:26:12.388Z",
      },
      deliverectValidationId: "671915d12e12b68950104ae5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:37:13.223Z",
      estimatedDeliveryTime: "2024-10-23T15:52:12.189Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:24:00.000Z",
      orderId: "671915d2b7a34cd09225aa89",
      createdAt: "2024-10-23T15:27:13.324Z",
      updatedAt: "2024-10-23T15:29:06.599Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd092086756",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd09208674e",
        productPLU: "2162085-1",
        productDescription:
          "C 15g| P 45g| F 15g\nGrilled chicken served with mashed potatoes, blanched broccoli, cauliflower & carrots, served with a lemon sauce garnished with a slice of lemon and parsley.\n\nGood source of Protein",
        productName: "Grilled Lemon Chicken",
        imageURL:
          "https://resizer.deliverect.com/n9S1sQV2m-69QdAwmCpIpZWcf6LGGUpyB0bFdTHRC1U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3JpbGxlZCUyMGxlbW9uJTIwY2hpY2tlbigxKS02NTU0OTA2MWZhNTNkZDAwMWVlOTQ2N2IucG5n",
        productCalories: 375,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 45,
          fat: 15,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 500,
    totalFmcCentsPrice: 500,
    totalFiatCentsPriceOriginal: 500,
    totalExternalFiatCentsPrice: 500,
    totalFmcCentsPriceOriginal: 500,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:27:14.024Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:29:09.527Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:29:20.657Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:54:48.729Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:54:48.729Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:55:10.588Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:19:25.864Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:27:14.024Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:27:25.459Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:27:14.024Z",
    updatedAt: "2024-10-23T16:19:26.523Z",
    fleetInfo: null,
  },
  {
    id: "67191582b7a34cd092259a83",
    sid: "241023-172465",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67191582b7a34cd092259a71",
    firstName: "نورة جاسم المقاطع ",
    lastName: null,
    phoneNumber: "+96594004008",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "658aed82f0a8195a7959e490",
    delivery: {
      id: "67191582b7a34cd092259a71",
      customerId: "658aed82f0a8195a7959e490",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "658aee96c5c3350af49e5e49",
        name: "السرة قطعة 4 شارع 9 منزل 8A",
        lng: 48.0055033788085,
        lat: 29.308467120795548,
        city: "Kuwait",
        area: "السرة",
        block: "4",
        street: "شارع 9",
        building: "8A",
        floor: "",
        flat: "",
        phone: "+96594004008",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-26T15:17:42.653Z",
        updatedAt: "2024-09-30T11:40:42.049Z",
      },
      deliverectValidationId: "67191581c99b8125b93be9f7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:35:53.984Z",
      estimatedDeliveryTime: "2024-10-23T15:50:52.696Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:22:00.000Z",
      orderId: "67191582b7a34cd092259a83",
      createdAt: "2024-10-23T15:25:54.338Z",
      updatedAt: "2024-10-23T15:27:06.078Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd092086751",
        productPLU: "2162035-1",
        productDescription:
          "C 50g| P 32g| F 15g\nBlack pepper beef with veggies served with egyptian rice.\n\nGood source of Iron & B-12 Vitamins",
        productName: "Black Pepper Beef",
        imageURL:
          "https://resizer.deliverect.com/FBy7BxqQqLr64Ae34YvyRhL0jHWvib_v5F9riYKNFsc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNzYtNjRiN2IwNWM5Mjc2OTYwMDE5NmViYjFjLnBuZw==",
        productCalories: 474,
        quantity: 1,
        nutritionInfo: {
          carbs: 50,
          protein: 32,
          fat: 15,
        },
        fmcCentsPrice: 330,
        fiatCentsPrice: 330,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:25:54.938Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:27:05.113Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:28:15.576Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:42:59.884Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:42:59.884Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:43:23.290Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:55:10.723Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:25:54.938Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:26:06.540Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:25:54.938Z",
    updatedAt: "2024-10-23T15:56:09.936Z",
    fleetInfo: null,
  },
  {
    id: "671914a3b7a34cd09225755a",
    sid: "241023-755827",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671914a3b7a34cd092257520",
    firstName: "Munira AlSabah",
    lastName: null,
    phoneNumber: "+96599013333",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65fff5a879ceb3bb17129b93",
    delivery: {
      id: "671914a3b7a34cd092257520",
      customerId: "65fff5a879ceb3bb17129b93",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65fff65836b598acdbbcd3bb",
        name: "Home",
        lng: 48.04194487631321,
        lat: 29.31221306630629,
        city: "Jabriya",
        area: "Jabriya",
        block: "11",
        street: "2",
        building: "288",
        floor: "",
        flat: "",
        phone: "+96599013333",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-03-24T09:46:00.015Z",
        updatedAt: "2024-03-24T09:46:00.015Z",
      },
      deliverectValidationId: "671914a3ff147771ad02ac05",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:32:11.163Z",
      estimatedDeliveryTime: "2024-10-23T15:47:09.707Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:19:00.000Z",
      orderId: "671914a3b7a34cd09225755a",
      createdAt: "2024-10-23T15:22:11.343Z",
      updatedAt: "2024-10-23T15:24:05.063Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd0920867a2",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086765",
        productPLU: "2167009-1",
        productDescription:
          "C 40g| P 34g| F 20g\nRoast beef, arugula, caramelized onions, american cheese & mozarella cheese.\n\n*GOOD SOURCE OF PROTEIN,VITAMINB12 *",
        productName: "Steak Beef Sandwich",
        imageURL:
          "https://resizer.deliverect.com/DtNUTtvxCtTpYduIHX1EtM3gopyjoTP3-HuOderSW-c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU1RFQUslMjBTQU5EV0hJQ0gtNjU3NTkwMGE0OGU3YWFhNzJiYzFjNDY5LnBuZw==",
        productCalories: 476,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 34,
          fat: 20,
        },
        fmcCentsPrice: 330,
        fiatCentsPrice: 330,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd0920867b0",
        productPLU: "2165029-1",
        productDescription:
          "C 7g | P 1.4g | F 6g\nRoasted Pistachio, caramel, chocolate sea salt",
        productName: "Pistachio Choco Bite",
        imageURL:
          "https://resizer.deliverect.com/rc4xgt7ScqZ-uLdWjRdijowgfyk5KpqjTo2XDqyEBSo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGlzdGFjaGlvJTIwY2hvY28lMjBiaXRlLTY2OTkxOWY4ZTYyZTU5NWI3ZDIzYmZiMy5wbmc=",
        productCalories: 91,
        quantity: 1,
        nutritionInfo: {
          carbs: 7,
          protein: 1.4,
          fat: 6,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 540,
    totalFmcCentsPrice: 540,
    totalFiatCentsPriceOriginal: 540,
    totalExternalFiatCentsPrice: 540,
    totalFmcCentsPriceOriginal: 540,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:22:11.973Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:24:04.762Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:24:36.853Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:37:07.387Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:37:07.388Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:38:17.426Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:51:28.312Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:22:11.973Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:22:23.418Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:22:11.973Z",
    updatedAt: "2024-10-23T15:51:28.648Z",
    fleetInfo: null,
  },
  {
    id: "67191432b7a34cd092256608",
    sid: "241023-813325",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67191431b7a34cd0922565ce",
    firstName: "Khaled almahboub",
    lastName: null,
    phoneNumber: "+96596053799",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "652bc50d1bfa57c5dbf90222",
    delivery: {
      id: "67191431b7a34cd0922565ce",
      customerId: "652bc50d1bfa57c5dbf90222",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66fe7adc218a8c4af2d92ba5",
        name: "Home",
        lng: 47.9596739821136,
        lat: 29.33520299672231,
        city: "Kuwait",
        area: "كيفان",
        block: "7",
        street: " 70",
        building: "10",
        floor: "",
        flat: "",
        phone: "+96596053799",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-03T11:07:08.698Z",
        updatedAt: "2024-10-23T15:17:52.456Z",
      },
      deliverectValidationId: "67191431c581fc4bf17fbef5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:30:17.076Z",
      estimatedDeliveryTime: "2024-10-23T15:45:16.044Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:17:00.000Z",
      orderId: "67191432b7a34cd092256608",
      createdAt: "2024-10-23T15:20:17.455Z",
      updatedAt: "2024-10-23T15:22:05.840Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd09208705c",
        productPLU: "2162022-1",
        productDescription:
          "C 32g | P 40g | F 21g\nShish tawook with batata harra, sumac white onions and pickled cucumber served with tahini sauce.\n\nGluten free,High in protien,Good source of Vitamin C",
        productName: "Shish Tawook with Batata Harra",
        imageURL:
          "https://resizer.deliverect.com/t8iFrO7JgasiTM_3BgLdmFPccJzHYDm3brRNicOgS5E/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjktNjRjOGJlYWZhNDUwZTYwMDI2ODNkMjhhLnBuZw==",
        productCalories: 477,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 40,
          fat: 21,
        },
        fmcCentsPrice: 380,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "Well done",
        subItems: [
          {
            productId: "6718bc1fb7a34cd092087110",
            productPLU: "1906015-1",
            productName: "Extra  Shish Tawook",
            productDescription: "C 1.7g| P 35g| F 10g| cal 240| coins 1.3",
            productCalories: 240,
            quantity: 1,
            fmcCentsPrice: 130,
            fiatCentsPrice: 130,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 1.7,
              protein: 35,
              fat: 10,
            },
          },
        ],
      },
      {
        productId: "6718bc1fb7a34cd092087093",
        productPLU: "2150004-1",
        productDescription: "C 28g| P 5g| F 11g\nContains coriander.",
        productName: "Batata Harra (100GM)",
        imageURL:
          "https://resizer.deliverect.com/u6QAZJk-DEsLbrspCI76OZkhV7dEOnByA2HrQZeDbD0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmF0YXRhJTIwaGFycmEtNjRkNzViMTc0YWIyYTcwMDE4MmY4NDNiLmpwZw==",
        productCalories: 231,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 5,
          fat: 11,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 470,
    totalFmcCentsPrice: 470,
    totalFiatCentsPriceOriginal: 470,
    totalExternalFiatCentsPrice: 470,
    totalFmcCentsPriceOriginal: 470,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:20:18.151Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:22:12.947Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:22:51.111Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:39:49.020Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:39:49.020Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:42:59.809Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:54:26.358Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:20:18.151Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:20:29.735Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:20:18.151Z",
    updatedAt: "2024-10-23T15:54:26.695Z",
    fleetInfo: null,
  },
  {
    id: "671913f4b7a34cd092255977",
    sid: "241023-305861",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671913f3b7a34cd09225594a",
    firstName: "Jamal Al-Qenaie",
    lastName: null,
    phoneNumber: "+96598817777",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66180614b0d178e6320299c6",
    delivery: {
      id: "671913f3b7a34cd09225594a",
      customerId: "66180614b0d178e6320299c6",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6669c35ed8ab4a2ab7deaf03",
        name: "Home",
        lng: 48.02904948592186,
        lat: 29.320545253594354,
        city: "Jabriya",
        area: "Jabriya",
        block: "6",
        street: "2",
        building: "22",
        floor: "",
        flat: "",
        phone: "+96598817777",
        additionalInfo: "In front of small generator house. ",
        isSelected: true,
        createdAt: "2024-06-12T15:48:46.729Z",
        updatedAt: "2024-08-30T08:18:43.506Z",
      },
      deliverectValidationId: "671913f33946960ae1bdb65e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:29:15.660Z",
      estimatedDeliveryTime: "2024-10-23T15:44:14.258Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:16:00.000Z",
      orderId: "671913f4b7a34cd092255977",
      createdAt: "2024-10-23T15:19:15.784Z",
      updatedAt: "2024-10-23T15:21:07.982Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208678b",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086785",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086749",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd0920867a9",
        productPLU: "2165027-1",
        productDescription: "C 14.8g | P 10.5g | F 8.9g",
        productName: "Cookie Dough Protein Bar",
        imageURL:
          "https://resizer.deliverect.com/InmIyMnUSs4bAPmQPAs1G2sv183VYh86dPPnyFkQc6k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY29va2llJTIwZG91Z2gtNjZhMmUzYmU0NWU1NDA4NDgxNDRiNjA4LnBuZw==",
        productCalories: 200,
        quantity: 1,
        nutritionInfo: {
          carbs: 14.8,
          protein: 10.5,
          fat: 8.9,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 570,
    totalFmcCentsPrice: 570,
    totalFiatCentsPriceOriginal: 570,
    totalExternalFiatCentsPrice: 570,
    totalFmcCentsPriceOriginal: 570,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:19:16.430Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:21:05.108Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:22:38.609Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:35:51.546Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:35:51.546Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:36:14.524Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:43:54.655Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:19:16.430Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:19:27.879Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:19:16.430Z",
    updatedAt: "2024-10-23T15:43:55.501Z",
    fleetInfo: null,
  },
  {
    id: "671913b7b7a34cd092254cec",
    sid: "241023-872827",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671913b7b7a34cd092254cdf",
    firstName: "fatma al hajji",
    lastName: null,
    phoneNumber: "+96567677647",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66d6cdf2aa1645e3a5fa52ad",
    delivery: {
      id: "671913b7b7a34cd092254cdf",
      customerId: "66d6cdf2aa1645e3a5fa52ad",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67191364b7a34cd092252fd3",
        name: "shuhada",
        lng: 48.035393979568724,
        lat: 29.267333984375,
        city: "Kuwait",
        area: "Shuhada",
        block: "2",
        street: "201",
        building: "46",
        floor: "",
        flat: "",
        phone: "+96567677647",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-23T15:16:52.951Z",
        updatedAt: "2024-10-23T15:16:52.951Z",
      },
      deliverectValidationId: "671913b7b4faa7144424cebe",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:28:15.198Z",
      estimatedDeliveryTime: "2024-10-23T15:43:14.127Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:15:00.000Z",
      orderId: "671913b7b7a34cd092254cec",
      createdAt: "2024-10-23T15:18:15.297Z",
      updatedAt: "2024-10-23T15:20:05.870Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd09208602f",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092085ffd",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092086003",
        productPLU: "2162079-1",
        productDescription:
          "C 49g| P 12g| F 17g\nBaked cauliflower with  eggplant, potato, cucumber and falafel drizzled with tahini sauce in saj bread. \n\nGOOD SOURCE OF PROTEIN VITAMINS AND FIBER",
        productName: "Mushakal Wrap",
        imageURL:
          "https://resizer.deliverect.com/GAAYXxN6zgWfrJVNB3YeUO6Fr9gMhYKrP5SEQdiGD-s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaGFrZWwlMjB3cmFwLTY1NzU4ZmRlYjAxYTgyMzljNzk1ZmE2My5qcGc=",
        productCalories: 397,
        quantity: 1,
        nutritionInfo: {
          carbs: 49,
          protein: 12,
          fat: 17,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:18:15.932Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:20:08.068Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:27:01.280Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:33:10.077Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:33:10.080Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:37:54.499Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:58:27.170Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:18:15.932Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:18:27.345Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:18:15.932Z",
    updatedAt: "2024-10-23T15:58:27.522Z",
    fleetInfo: null,
  },
  {
    id: "6719139ab7a34cd092254347",
    sid: "241023-888388",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67191399b7a34cd09225433b",
    firstName: "Moneera Bourisli",
    lastName: null,
    phoneNumber: "+96599923334",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6704c949906276458c88981a",
    delivery: {
      id: "67191399b7a34cd09225433b",
      customerId: "6704c949906276458c88981a",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6704cad6218a8c4af2bc8bf4",
        name: "Home",
        lng: 48.02252132445574,
        lat: 29.278277656408417,
        city: "Kuwait",
        area: "Hateen",
        block: "3",
        street: "301",
        building: "3",
        floor: "",
        flat: "",
        phone: "+96599923334",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-08T06:01:58.265Z",
        updatedAt: "2024-10-23T11:47:27.335Z",
      },
      deliverectValidationId: "6719139960ec4531f4c9414b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:27:45.439Z",
      estimatedDeliveryTime: "2024-10-23T15:42:44.390Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:14:00.000Z",
      orderId: "6719139ab7a34cd092254347",
      createdAt: "2024-10-23T15:17:45.538Z",
      updatedAt: "2024-10-23T15:19:08.293Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fea",
        productPLU: "2162123-1",
        productDescription:
          "C 32g | P 23g | F 14g \nGlazed bun, beef pattie, mozzarella cheese slice, mushroom sauce with light mayo ",
        productName: "Mushroom Burger",
        imageURL:
          "https://resizer.deliverect.com/ha2uMGKS1nYV_BDUXrhrnd4juPdkh4UN_AL73RV3EbM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBidXJnZXItNjY5MDY2ODYxMGNhZmJmNDkzMTIzNGQ5LnBuZw==",
        productCalories: 346,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 23,
          fat: 14,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208602f",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 310,
    totalFmcCentsPrice: 310,
    totalFiatCentsPriceOriginal: 310,
    totalExternalFiatCentsPrice: 310,
    totalFmcCentsPriceOriginal: 310,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:17:46.136Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:19:09.252Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:27:03.923Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:32:59.181Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:32:59.181Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:36:45.138Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:09:24.079Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:17:46.136Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:17:58.650Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:17:46.136Z",
    updatedAt: "2024-10-23T16:09:24.424Z",
    fleetInfo: null,
  },
  {
    id: "6719138ab7a34cd092253ce9",
    sid: "241023-382769",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67191389b7a34cd092253cb0",
    firstName: "Jamal Al-Qenaie",
    lastName: null,
    phoneNumber: "+96598817777",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66180614b0d178e6320299c6",
    delivery: {
      id: "67191389b7a34cd092253cb0",
      customerId: "66180614b0d178e6320299c6",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6669c35ed8ab4a2ab7deaf03",
        name: "Home",
        lng: 48.02904948592186,
        lat: 29.320545253594354,
        city: "Jabriya",
        area: "Jabriya",
        block: "6",
        street: "2",
        building: "22",
        floor: "",
        flat: "",
        phone: "+96598817777",
        additionalInfo: "In front of small generator house. ",
        isSelected: true,
        createdAt: "2024-06-12T15:48:46.729Z",
        updatedAt: "2024-08-30T08:18:43.506Z",
      },
      deliverectValidationId: "67191389982ae1ad6dc3b1fa",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:27:29.346Z",
      estimatedDeliveryTime: "2024-10-23T15:42:26.885Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:12:26.885Z",
      orderId: "6719138ab7a34cd092253ce9",
      createdAt: "2024-10-23T15:17:29.630Z",
      updatedAt: "2024-10-23T15:17:30.311Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208678b",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086785",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086761",
        productPLU: "2162075-1",
        productDescription:
          "C 53g| P 20g| F 9g\nGround beef burrito with seasoned egyptian rice, corn, black beans, lettuce & sour cream wrapped in a tortilla wrap.\n\nGOOD SOURCE OF PROTEIN & FIBER",
        productName: "Beef Burrito",
        imageURL:
          "https://resizer.deliverect.com/85QohnB6E-ysOf_DICo7nPH2RjHXSEy1Qm9NHYJHOgk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGJ1cnJpdG8oMSktNjU1NDhmYTA2MmFiMGUwMDFmM2E3MzczLnBuZw==",
        productCalories: 373,
        quantity: 1,
        nutritionInfo: {
          carbs: 53,
          protein: 20,
          fat: 9,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd0920867aa",
        productPLU: "2165024-1",
        productDescription: "C 13.3g | P 11g | F 12.4g",
        productName: "Hazelnut Protein Bar",
        imageURL:
          "https://resizer.deliverect.com/pg30abnLy-OmZXiD0pRcojSoQmouj-KnEcCUAbGui1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaGF6ZWxudXQtNjY5OTE5M2JlNjJlNTk1YjdkMjNiZmFmLnBuZw==",
        productCalories: 224,
        quantity: 1,
        nutritionInfo: {
          carbs: 13.3,
          protein: 11,
          fat: 12.4,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 530,
    totalFmcCentsPrice: 530,
    totalFiatCentsPriceOriginal: 530,
    totalExternalFiatCentsPrice: 530,
    totalFmcCentsPriceOriginal: 530,
    orderStatus: "canceled",
    chargeStatus: "refund_succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:17:30.292Z",
      },
      {
        previousStatus: "pending",
        newStatus: "canceled",
        timestamp: "2024-10-23T15:17:55.021Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:17:30.292Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:17:42.130Z",
      },
      {
        previousStatus: "succeeded",
        newStatus: "refund_requested",
        timestamp: "2024-10-23T15:17:55.021Z",
      },
      {
        previousStatus: "refund_requested",
        newStatus: "refund_failed",
        timestamp: "2024-10-23T15:18:09.592Z",
      },
      {
        previousStatus: "refund_failed",
        newStatus: "refund_succeeded",
        timestamp: "2024-10-23T15:18:38.311Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:17:30.292Z",
    updatedAt: "2024-10-23T15:18:38.312Z",
    fleetInfo: null,
  },
  {
    id: "67191360b7a34cd092252dae",
    sid: "241023-050539",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719135fb7a34cd092252da1",
    firstName: "Farah alghanim",
    lastName: null,
    phoneNumber: "+96599911561",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "664c61b52f93385a5e3e807c",
    delivery: {
      id: "6719135fb7a34cd092252da1",
      customerId: "664c61b52f93385a5e3e807c",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "664c626a894471f34344a15a",
        name: "Home",
        lng: 47.98531975597143,
        lat: 29.336117396197814,
        city: "Kuwait",
        area: "Faiha",
        block: "9",
        street: "93 St",
        building: "4",
        floor: "",
        flat: "",
        phone: "+96599911561",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-21T08:59:22.629Z",
        updatedAt: "2024-08-21T10:05:24.225Z",
      },
      deliverectValidationId: "6719135fe630c246d2369364",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:26:47.411Z",
      estimatedDeliveryTime: "2024-10-23T15:41:46.385Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:13:00.000Z",
      orderId: "67191360b7a34cd092252dae",
      createdAt: "2024-10-23T15:16:47.527Z",
      updatedAt: "2024-10-23T15:18:06.069Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd09208707d",
        productPLU: "2163020-1",
        productDescription:
          "C 10g | P 2g | F 3g [%New%]\n\nRomaine lettuce, cucumber, green capsicum, spring onion, parsley, dill leaves, sumac, croutons with Italian dressing.",
        productName: "Mini Italian salad",
        imageURL:
          "https://resizer.deliverect.com/XkxzmQRK5xU2IA0XJqjnN8x_RpgC6KxTMoxtFe2tjkE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGl0YWxpYW4lMjBzYWxhZC02NzEzNGUwMjQ4OGM3MjkyNjAyMTliNDcucG5n",
        productCalories: 75,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 2,
          fat: 3,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd092087074",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 24,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd0920870ce",
        productPLU: "2202021-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Coca Cola Zero",
        imageURL:
          "https://resizer.deliverect.com/NvHctUF0z3us3Lhe9x5u3_mQIOOXnn8K3FvSZO6MkK4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCgxKS02NGM5MTk1MjZlY2Q3NzAwMjZjNGU1MDUuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:16:48.274Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:18:12.620Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:22:18.151Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:24:26.008Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:24:26.008Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:34:43.961Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:53:09.209Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:16:48.274Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:17:00.220Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:16:48.274Z",
    updatedAt: "2024-10-23T15:53:09.763Z",
    fleetInfo: null,
  },
  {
    id: "671912eeb7a34cd09225110c",
    sid: "241023-168582",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671912edb7a34cd092251100",
    firstName: "Omar Al Matar",
    lastName: null,
    phoneNumber: "+96593333391",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "670c0ab8aa779ec4a784b467",
    delivery: {
      id: "671912edb7a34cd092251100",
      customerId: "670c0ab8aa779ec4a784b467",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670c0b6af495b693cd5e58d0",
        name: "Surra",
        lng: 47.994924289841876,
        lat: 29.31842041015625,
        city: "Kuwait",
        area: "Surra",
        block: "5",
        street: "3",
        building: "51",
        floor: "",
        flat: "",
        phone: "+96593333391",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-13T18:03:22.866Z",
        updatedAt: "2024-10-13T18:03:22.866Z",
      },
      deliverectValidationId: "671912ed1cb32e3bce25cc4d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:24:53.492Z",
      estimatedDeliveryTime: "2024-10-23T15:39:52.467Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:11:00.000Z",
      orderId: "671912eeb7a34cd09225110c",
      createdAt: "2024-10-23T15:14:53.602Z",
      updatedAt: "2024-10-23T15:16:06.813Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd092086752",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086759",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:14:54.944Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:16:05.163Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:18:29.331Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:35:25.472Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:35:25.472Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:42:18.510Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:56:48.245Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:14:54.944Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:15:06.374Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-23T15:14:54.944Z",
    updatedAt: "2024-10-23T15:56:48.580Z",
    fleetInfo: null,
  },
  {
    id: "671912c6b7a34cd09225064b",
    sid: "241023-453192",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671912c5b7a34cd09225057f",
    firstName: "Moneera Yousif ",
    lastName: null,
    phoneNumber: "+96550101310",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6622bfcab0d178e6320a9e48",
    delivery: {
      id: "671912c5b7a34cd09225057f",
      customerId: "6622bfcab0d178e6320a9e48",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670987cbf495b693cdfcb29e",
        name: "Home ",
        lng: 48.00414483046957,
        lat: 29.291061401367188,
        city: "مدينة الكويت",
        area: "السلام",
        block: "7",
        street: "711",
        building: "15",
        floor: "الباب الجانبي الدور الثاني",
        flat: "1",
        phone: "+96550101310",
        additionalInfo: "يرجى الاتصال عند الوصول",
        isSelected: true,
        createdAt: "2024-10-11T20:17:15.202Z",
        updatedAt: "2024-10-23T15:12:22.580Z",
      },
      deliverectValidationId: "671912c4dda2cbde33f8ec9d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:24:12.858Z",
      estimatedDeliveryTime: "2024-10-23T15:39:11.166Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:11:00.000Z",
      orderId: "671912c6b7a34cd09225064b",
      createdAt: "2024-10-23T15:14:13.280Z",
      updatedAt: "2024-10-23T15:16:06.348Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092086002",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:14:14.358Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:16:12.943Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:16:28.703Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:30:01.106Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:30:01.106Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:35:29.215Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:55:22.011Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:14:14.358Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:14:25.999Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:14:14.358Z",
    updatedAt: "2024-10-23T15:55:22.484Z",
    fleetInfo: null,
  },
  {
    id: "67191297b7a34cd09224f690",
    sid: "241023-068985",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67191296b7a34cd09224f643",
    firstName: "Mustafa Alshemali",
    lastName: null,
    phoneNumber: "+96567045359",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65dc1940a56b1a242b9c5255",
    delivery: {
      id: "67191296b7a34cd09224f643",
      customerId: "65dc1940a56b1a242b9c5255",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65dc1a148c65e13bb397dbec",
        name: "Dahia",
        lng: 47.99108885228634,
        lat: 29.350356865992932,
        city: "Shuwaikh Industrial",
        area: "Abdullah al-Salem",
        block: "2",
        street: "Homoud Yousif Al-Nusif St, Avenue 23",
        building: "16",
        floor: "",
        flat: "",
        phone: "+96567045359",
        additionalInfo: "Do not ring the bell. Call me when you’re outside.",
        isSelected: true,
        createdAt: "2024-02-26T04:56:52.777Z",
        updatedAt: "2024-10-23T15:09:53.146Z",
      },
      deliverectValidationId: "6719129660ec4531f4c8eab3",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:23:26.434Z",
      estimatedDeliveryTime: "2024-10-23T15:38:25.242Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:10:00.000Z",
      orderId: "67191297b7a34cd09224f690",
      createdAt: "2024-10-23T15:13:26.530Z",
      updatedAt: "2024-10-23T15:15:05.976Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd09208704c",
        productPLU: "2162107-1",
        productDescription:
          "C 30g | P 46g | F 9g\nGrilled zaatar chicken with saj bread and zaatar yogurt sauce. Contains coriander \n\nGood source of Protein ",
        productName: "Zaatar Chicken",
        imageURL:
          "https://resizer.deliverect.com/HLEGxWozw0ZDVfWyAN_TK_oChgkvffkzz-_bxUrGMJI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemFhdGFyJTIwY2hpY2tlbi02NjQ0YjBjMTc0NWEzOTM5ZTIxNjY5MjAucG5n",
        productCalories: 385,
        quantity: 1,
        nutritionInfo: {
          carbs: 30,
          protein: 46,
          fat: 9,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd092087050",
        productPLU: "2162096-1",
        productDescription:
          "C 48g| P 17g| F 17g\nCrispy fish tenders served with baked fries & tartar sauce.\n\nGood source of Protein, Vitamins and Minerals",
        productName: "Fish N' Chips",
        imageURL:
          "https://resizer.deliverect.com/nIuQ090dCYLyovaNm9H_VJzVg3JpgGl0gzEm4NvBR6s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRmlzaCUyME4nJTIwQ2hpcHMtNjVmNmVjMDViOWNjMGMzODFjY2YwOTQyLnBuZw==",
        productCalories: 413,
        quantity: 1,
        nutritionInfo: {
          carbs: 48,
          protein: 17,
          fat: 17,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "No tartar sauce",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd0920870c8",
        productPLU: "2202050-1",
        productDescription:
          "C 27g | P 1.5g | F 0g\nOrange \n\nGood source of Vitamin C",
        productName: "Orange Citrus",
        imageURL:
          "https://resizer.deliverect.com/92Cgq5w07iSWvqOzcojq0Egx0BDIxsVNTfIhlYhtUmU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTMtNjY1OTE5ODQ0MjllZTgwN2Q2OWQxYmZkLnBuZw==",
        productCalories: 116,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 1.5,
          fat: null,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 800,
    totalFmcCentsPrice: 800,
    totalFiatCentsPriceOriginal: 800,
    totalExternalFiatCentsPrice: 800,
    totalFmcCentsPriceOriginal: 800,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:13:27.740Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:15:08.803Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:15:48.962Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:36:02.560Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:36:02.560Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:38:05.794Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:49:26.695Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:13:27.740Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:13:48.396Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:13:27.740Z",
    updatedAt: "2024-10-23T15:49:28.307Z",
    fleetInfo: null,
  },
  {
    id: "67191295b7a34cd09224f63a",
    sid: "241023-293211",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67191295b7a34cd09224f62e",
    firstName: "Aisha AlMutairi",
    lastName: null,
    phoneNumber: "+96594477247",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64b00d41e9669a876a04038a",
    delivery: {
      id: "67191295b7a34cd09224f62e",
      customerId: "64b00d41e9669a876a04038a",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64b173e16d1da80f5a822149",
        name: "Nuzha Home",
        lng: 47.99218989908695,
        lat: 29.34637041964141,
        city: "Kuwait",
        area: "النزهة",
        block: "1",
        street: "14",
        building: "7A",
        floor: "",
        flat: "",
        phone: "+96594477247",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-07-14T16:12:17.178Z",
        updatedAt: "2024-10-23T15:10:35.258Z",
      },
      deliverectValidationId: "671912950348c4b0ea175077",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:23:25.140Z",
      estimatedDeliveryTime: "2024-10-23T15:38:23.615Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:10:00.000Z",
      orderId: "67191295b7a34cd09224f63a",
      createdAt: "2024-10-23T15:13:25.327Z",
      updatedAt: "2024-10-23T15:15:06.240Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd092087063",
        productPLU: "2162031-1",
        productDescription:
          "C 63g| P 25g| F 12g\nSweet & sour chicken, green bell peppers, carrots & pineapples served with white rice.",
        productName: "Sweet & Sour Chicken",
        imageURL:
          "https://resizer.deliverect.com/DlUpN-omCGUFYG08dg-u9PiTfH2URZQt44nUONmWKto/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvOC02NGI3YjAxMzRiOTdiYTM4NWRjN2FlMmIucG5n",
        productCalories: 466,
        quantity: 1,
        nutritionInfo: {
          carbs: 63,
          protein: 25,
          fat: 12,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:13:25.996Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:15:20.329Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:15:49.447Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:35:54.696Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:35:54.696Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:37:43.335Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:56:59.705Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:13:25.996Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:13:37.439Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:13:25.996Z",
    updatedAt: "2024-10-23T15:57:00.377Z",
    fleetInfo: null,
  },
  {
    id: "67191258b7a34cd09224e879",
    sid: "241023-676696",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67191257b7a34cd09224e77f",
    firstName: "Salehalkhannah",
    lastName: null,
    phoneNumber: "+96566222429",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66658bd97b408cf4f5d1abf1",
    delivery: {
      id: "67191257b7a34cd09224e77f",
      customerId: "66658bd97b408cf4f5d1abf1",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "66658c9b0f9532784a218c4b",
        name: "Faiha",
        lng: 47.97851594124498,
        lat: 29.341949462890625,
        city: "Al Kuwayt",
        area: "Faiha",
        block: "2",
        street: "24 St",
        building: "11",
        floor: "1",
        flat: "1",
        phone: "+96566222429",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-09T11:06:03.730Z",
        updatedAt: "2024-10-20T14:21:15.775Z",
      },
      deliverectValidationId: "67191257e7e718a7ef72603c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:22:23.060Z",
      estimatedDeliveryTime: "2024-10-23T15:37:21.722Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:09:00.000Z",
      orderId: "67191258b7a34cd09224e879",
      createdAt: "2024-10-23T15:12:23.168Z",
      updatedAt: "2024-10-23T15:14:07.137Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd09208709a",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd09208709c",
        productPLU: "2166031-1",
        productDescription:
          "C 32g| P 12g| F 12g\nBaked sweet potato fries with mayo barbeque sauce.",
        productName: "Baked Sweet Potato Fries",
        imageURL:
          "https://resizer.deliverect.com/EexbDtzbKG0sVOjjNXiIlT_MIN2doyc2NlpJu2_T6nc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3dlZXQlMjBwb3RhdG8lMjBmcmllcygxKS02NTU0OGY3NzYyYWIwZTAwMWYzYTczNzEucG5n",
        productCalories: 244,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 12,
          fat: 12,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd09208705a",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 2,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 760,
    totalFmcCentsPrice: 760,
    totalFiatCentsPriceOriginal: 760,
    totalExternalFiatCentsPrice: 760,
    totalFmcCentsPriceOriginal: 760,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:12:24.431Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:14:14.103Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:14:38.662Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:34:55.230Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:34:55.231Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:35:17.913Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:48:36.919Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:12:24.431Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:12:34.315Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:12:24.431Z",
    updatedAt: "2024-10-23T15:48:37.541Z",
    fleetInfo: null,
  },
  {
    id: "6719122db7a34cd09224dca3",
    sid: "241023-981734",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6719122cb7a34cd09224dc98",
    firstName: "shaikha ahmad",
    lastName: null,
    phoneNumber: "+96560009552",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "670fc6d5aa779ec4a78e98c2",
    delivery: {
      id: "6719122cb7a34cd09224dc98",
      customerId: "670fc6d5aa779ec4a78e98c2",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670fc72ff495b693cd0b08f7",
        name: "daiya",
        lng: 48.00942622752768,
        lat: 29.35489331085068,
        city: "Kuwait City",
        area: "Daiya",
        block: "1",
        street: "13",
        building: "6",
        floor: "g",
        flat: "2",
        phone: "+96560009552",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-16T14:01:19.676Z",
        updatedAt: "2024-10-23T15:10:33.085Z",
      },
      deliverectValidationId: "6719122cc47d7273b3269a0e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:21:40.344Z",
      estimatedDeliveryTime: "2024-10-23T15:36:39.345Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:08:00.000Z",
      orderId: "6719122db7a34cd09224dca3",
      createdAt: "2024-10-23T15:11:40.440Z",
      updatedAt: "2024-10-23T15:13:07.886Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd092087068",
        productPLU: "2162037-1",
        productDescription:
          "C 62g| P 27g| F 12.4g [%Popular%]\n\nA classic favorite made with perfectly cooked spaghetti & a hearty bolognese sauce. Topped with a sprinkle of parmesan cheese & chopped parsley & garnished with a fresh basil sprig.",
        productName: "Spaghetti Bolognese",
        imageURL:
          "https://resizer.deliverect.com/8ryC24TcKatYjKjvTqCxeYugRnVz3p47fJ-PTIB67ek/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhZ2hldHRpLTY3MTM1NWVlY2NiZDAxOGZkYzRlNGNjYy5wbmc=",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 62,
          protein: 27,
          fat: 12.4,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd092087067",
        productPLU: "2162043-1",
        productDescription:
          "C 45g| P 28g| F 16g\nPink pasta with grilled chicken breast, topped with parmesan cheese.\n\nGood source of B-Complex Vitamins",
        productName: "Pink Pasta",
        imageURL:
          "https://resizer.deliverect.com/h10UGlGO3vP_ayu1YBOqijxvB6J8MuhGdH52j7lOn8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGluayUyMHBhc3RhLTY0YjdhZmJlZWUwY2I5MDAyMWRjNWRlOC5wbmc=",
        productCalories: 436,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 28,
          fat: 16,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 520,
    totalFmcCentsPrice: 520,
    totalFiatCentsPriceOriginal: 520,
    totalExternalFiatCentsPrice: 520,
    totalFmcCentsPriceOriginal: 520,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:11:41.143Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:13:16.609Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:14:04.045Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:22:18.229Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:22:18.230Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:34:06.142Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:50:59.713Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:11:41.143Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:11:56.196Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:11:41.143Z",
    updatedAt: "2024-10-23T15:51:00.159Z",
    fleetInfo: null,
  },
  {
    id: "67191225b7a34cd09224db5e",
    sid: "241023-484266",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67191224b7a34cd09224db25",
    firstName: "Aseel Rajab",
    lastName: null,
    phoneNumber: "+96567612343",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "671648cbb58bc473083f8c69",
    delivery: {
      id: "67191224b7a34cd09224db25",
      customerId: "671648cbb58bc473083f8c69",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6716495cb7a34cd0929fa0e8",
        name: "Ministry Social affairs near united round about ",
        lng: 47.887864580977755,
        lat: 29.313873291015625,
        city: "Kuwait",
        area: "Shuwaikh Medical",
        block: "1",
        street: "1",
        building: "4",
        floor: "",
        flat: "",
        phone: "+96567612343",
        additionalInfo: "Please Call for location ",
        isSelected: true,
        createdAt: "2024-10-21T12:30:20.767Z",
        updatedAt: "2024-10-21T12:30:20.767Z",
      },
      deliverectValidationId: "671912248ee7d1c256c16292",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:21:32.737Z",
      estimatedDeliveryTime: "2024-10-23T15:36:31.626Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:08:00.000Z",
      orderId: "67191225b7a34cd09224db5e",
      createdAt: "2024-10-23T15:11:32.859Z",
      updatedAt: "2024-10-23T15:13:07.431Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd092087055",
        productPLU: "2162077-1",
        productDescription:
          "C 41g | P 26g | F 16g\nShort ribs, mixed cabbage, sour cream, spring onion, parsely with tortilla wrap.\n\nGood Source Of Protein, Vitamin B12, Minerals",
        productName: "Short Rib Tacos",
        imageURL:
          "https://resizer.deliverect.com/bwNBblwmBg7NZVLL0zxwD_1h0r1mAgb-zXh607JTGn4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVEFDTy02NTI5ZjMxMWQyNmQ1ZGYyNzhkZDZiYjYucG5n",
        productCalories: 420,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 26,
          fat: 16,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd092087064",
        productPLU: "2162019-1",
        productDescription:
          "C 45g| P 24g| F 16g\nShrimp & rice topped with hashu with a side of dakkus.\n\nGluten free, Good source of Vitamin B 12, A & C",
        productName: "Murabyan",
        imageURL:
          "https://resizer.deliverect.com/TJVdjvdLawOHnrvUIIFGmtSne5lvu_vKoyHBIoWMnFg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkVaSVpFRCUyME1VUllCQU4tNjRjOGJlYzQ3MGY0OGUwMDFmNjdjMmVjLmpwZw==",
        productCalories: 420,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 24,
          fat: 16,
        },
        fmcCentsPrice: 460,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc1fb7a34cd092087125",
            productPLU: "1906017-1",
            productName: "Extra  Shrimp",
            productDescription: "C 7g| P 19g| F 11g| cal 206| coins 1.7",
            productCalories: 206,
            quantity: 1,
            fmcCentsPrice: 170,
            fiatCentsPrice: 170,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 7,
              protein: 19,
              fat: 11,
            },
          },
        ],
      },
      {
        productId: "6718bc1fb7a34cd092087068",
        productPLU: "2162037-1",
        productDescription:
          "C 62g| P 27g| F 12.4g [%Popular%]\n\nA classic favorite made with perfectly cooked spaghetti & a hearty bolognese sauce. Topped with a sprinkle of parmesan cheese & chopped parsley & garnished with a fresh basil sprig.",
        productName: "Spaghetti Bolognese",
        imageURL:
          "https://resizer.deliverect.com/8ryC24TcKatYjKjvTqCxeYugRnVz3p47fJ-PTIB67ek/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhZ2hldHRpLTY3MTM1NWVlY2NiZDAxOGZkYzRlNGNjYy5wbmc=",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 62,
          protein: 27,
          fat: 12.4,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 1110,
    totalFmcCentsPrice: 1110,
    totalFiatCentsPriceOriginal: 1110,
    totalExternalFiatCentsPrice: 1110,
    totalFmcCentsPriceOriginal: 1110,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:11:33.578Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:13:22.626Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:14:17.045Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:22:16.056Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:22:16.056Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:22:51.951Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:35:29.569Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:11:33.578Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:11:45.283Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:11:33.578Z",
    updatedAt: "2024-10-23T15:35:29.910Z",
    fleetInfo: null,
  },
  {
    id: "67191216b7a34cd09224d395",
    sid: "241023-036489",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67191215b7a34cd09224d34a",
    firstName: "jasim algharabally",
    lastName: null,
    phoneNumber: "+96596902226",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64cb716275a6aa442d13c587",
    delivery: {
      id: "67191215b7a34cd09224d34a",
      customerId: "64cb716275a6aa442d13c587",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6644d641fb0531469a5f08ab",
        name: "home",
        lng: 47.98019874840975,
        lat: 29.308457453689705,
        city: "Kuwait",
        area: "Qortuba",
        block: "3",
        street: "2",
        building: "57",
        floor: "",
        flat: "",
        phone: "+96596902226",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-15T15:35:29.114Z",
        updatedAt: "2024-10-23T15:10:05.937Z",
      },
      deliverectValidationId: "6719121525bdf62c98e5cc4b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:21:17.848Z",
      estimatedDeliveryTime: "2024-10-23T15:36:16.528Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:08:00.000Z",
      orderId: "67191216b7a34cd09224d395",
      createdAt: "2024-10-23T15:11:17.941Z",
      updatedAt: "2024-10-23T15:13:07.184Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fcc",
        productPLU: "2162131-1",
        productDescription:
          "C 44g | P 19g | F 16g [%New%]\n\nSlider bun, beef patty, cheddar cheese, cranberry mayo sauce, grilled white onion, rocca (arugula), tomato slice",
        productName: "Rocca Slider",
        imageURL:
          "https://resizer.deliverect.com/RFgoMv_Ln3gENNMlWPtsbz7A-EydnJQdumAQGSYp2Ow/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjByb2NjYSUyMHNsaWRlci02NmY1NmYwMzVhMmUwYjZhZDQxZGNjZWQucG5n",
        productCalories: 396,
        quantity: 1,
        nutritionInfo: {
          carbs: 44,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092086057",
        productPLU: "2165005-1",
        productDescription: "C 13g| P 1g| F 10g",
        productName: "Salted Pecan Bites",
        imageURL:
          "https://resizer.deliverect.com/p3z6wZFsuIaNIkw99YRPwzlHvwGLg970lPG5QDvZQrc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDgtNjRiN2JjOTE2ZjhhYmY0M2UzMWQxMjBjLnBuZw==",
        productCalories: 151,
        quantity: 3,
        nutritionInfo: {
          carbs: 13,
          protein: 1,
          fat: 10,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 500,
    totalFmcCentsPrice: 500,
    totalFiatCentsPriceOriginal: 500,
    totalExternalFiatCentsPrice: 500,
    totalFmcCentsPriceOriginal: 500,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:11:18.562Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:13:08.775Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:15:04.814Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:27:23.848Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:27:23.848Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:27:45.709Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:48:16.455Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:11:18.563Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:11:29.995Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:11:18.563Z",
    updatedAt: "2024-10-23T15:48:17.254Z",
    fleetInfo: null,
  },
  {
    id: "6719120ab7a34cd09224d0ee",
    sid: "241023-683966",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67191208b7a34cd09224cf50",
    firstName: "Noura Khaled",
    lastName: null,
    phoneNumber: "+96597134260",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6559f7bb6fe598ae84f9e693",
    delivery: {
      id: "67191208b7a34cd09224cf50",
      customerId: "6559f7bb6fe598ae84f9e693",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6571d1a2bc468861650429d4",
        name: "Home",
        lng: 48.0480111,
        lat: 29.3375635,
        city: "Al Kuwayt",
        area: "Al Kuwayt",
        block: "11",
        street: "Baghdad St",
        building: "House of Q salon building",
        floor: "14",
        flat: "28",
        phone: "+96597134260",
        additionalInfo: "Salmiya ",
        isSelected: true,
        createdAt: "2023-12-07T14:07:30.578Z",
        updatedAt: "2024-10-23T15:03:11.062Z",
      },
      deliverectValidationId: "6719120705f0aa7bf6877dd0",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:21:03.390Z",
      estimatedDeliveryTime: "2024-10-23T15:36:02.230Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:08:00.000Z",
      orderId: "6719120ab7a34cd09224d0ee",
      createdAt: "2024-10-23T15:11:04.046Z",
      updatedAt: "2024-10-23T15:13:04.867Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208672d",
        productPLU: "2162125-1",
        productDescription:
          "C 51g | P 30g | F 8g\nGrilled Salmon with dill rice served with dakkus, lemon wedge & fresh pomegranate.\n\nGood source of Protein & Vitamins",
        productName: "Salmon & Dill Rice",
        imageURL:
          "https://resizer.deliverect.com/p93TVzm_6AWG3-UvpUjucOs9ophRezuPgMNQYVzvWXA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2FsbW9uJTIwJTI2JTIwZGlsbC02NmFiZjQ0Y2JiMGY0NmM4YzFkMDRjMzcucG5n",
        productCalories: 396,
        quantity: 1,
        nutritionInfo: {
          carbs: 51,
          protein: 30,
          fat: 8,
        },
        fmcCentsPrice: 480,
        fiatCentsPrice: 480,
        externalFiatCentsPrice: 0,
        remark: "No onions and not spicy ",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 480,
    totalFmcCentsPrice: 480,
    totalFiatCentsPriceOriginal: 480,
    totalExternalFiatCentsPrice: 480,
    totalFmcCentsPriceOriginal: 480,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:11:06.112Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:13:04.569Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:14:27.355Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:26:19.441Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:26:19.441Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:26:41.294Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:47:52.948Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:11:06.112Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:11:17.609Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:11:06.112Z",
    updatedAt: "2024-10-23T15:47:53.294Z",
    fleetInfo: null,
  },
  {
    id: "671911f4b7a34cd09224ca32",
    sid: "241023-647975",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671911f3b7a34cd09224ca26",
    firstName: "Yasmin",
    lastName: null,
    phoneNumber: "+96592229211",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67162d90b58bc473083f05ac",
    delivery: {
      id: "671911f3b7a34cd09224ca26",
      customerId: "67162d90b58bc473083f05ac",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67162e54b7a34cd092948e78",
        name: "Home",
        lng: 48.008832457769756,
        lat: 29.319000244140625,
        city: "Al Kuwayt",
        area: "Surra",
        block: "1",
        street: "7",
        building: "8",
        floor: "",
        flat: "",
        phone: "+96592229211",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-21T10:35:00.891Z",
        updatedAt: "2024-10-21T10:35:00.891Z",
      },
      deliverectValidationId: "671911f3b87b85dcf6b078a2",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:20:43.491Z",
      estimatedDeliveryTime: "2024-10-23T15:35:42.417Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:07:00.000Z",
      orderId: "671911f4b7a34cd09224ca32",
      createdAt: "2024-10-23T15:10:43.618Z",
      updatedAt: "2024-10-23T15:12:05.436Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208676f",
        productPLU: "2162106-1",
        productDescription:
          "C 31g | P 7g | F 18g\nCorn, shishito pepper, jalapeno, coriander, feta cheese, red onion, shishito salad dressing\n\nGood source of Vitamins ",
        productName: "Shishito Corn Salad",
        imageURL:
          "https://resizer.deliverect.com/6rdXnPFXcSncZOuJkUg1u6lTeZ62zbWaCh8VKxZvZJw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCUyQiUyMHJlc2l6ZWQtNjY0NGFmYWQ1YzZkZWYwMWMzM2MzYzE1LnBuZw==",
        productCalories: 314,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 7,
          fat: 18,
        },
        fmcCentsPrice: 350,
        fiatCentsPrice: 350,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086755",
        productPLU: "2162030-1",
        productDescription:
          "C 42g | P 26g | F 21g\nLo mein noodles, beef, carrots, cabbage & zucchini.\n\n*Good source of Protein & vitamins*",
        productName: "Lo Mein Noodles",
        imageURL:
          "https://resizer.deliverect.com/gtToqQp69qSy57Ivr2G-IxNvZrR3UxiMiFxzdOFokw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTE8lMjBNRUlOJTIwTk9PRExFUy02NTc1OTAxZDRmNzg3N2JlMWJhMWIyN2MucG5n",
        productCalories: 461,
        quantity: 1,
        nutritionInfo: {
          carbs: 42,
          protein: 26,
          fat: 21,
        },
        fmcCentsPrice: 390,
        fiatCentsPrice: 390,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086729",
        productPLU: "2162130-1",
        productDescription:
          "C 40g | P 19g | F 16g\n\nSlider bun, patty, cheddar cheese, iceberg lettuce, white onion, dill pickle chips, mayo ketchup sauce.",
        productName: "FM Sliders",
        imageURL:
          "https://resizer.deliverect.com/gYXzCSrBPdFooHt3S19xzbSh9205wL6ziwlFQHMhLTI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBzbGlkZXIoMSktNjZmNTZmM2ZlNjQyNjQ5MzFhZTYwMDMwLnBuZw==",
        productCalories: 380,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 990,
    totalFmcCentsPrice: 990,
    totalFiatCentsPriceOriginal: 990,
    totalExternalFiatCentsPrice: 990,
    totalFmcCentsPriceOriginal: 990,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:10:44.314Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:12:05.441Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:13:00.206Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:22:07.304Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:22:07.305Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:22:29.164Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:30:31.921Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:10:44.314Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:10:55.861Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:10:44.314Z",
    updatedAt: "2024-10-23T15:30:32.375Z",
    fleetInfo: null,
  },
  {
    id: "671911b6b7a34cd09224b1b9",
    sid: "241023-535700",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671911b6b7a34cd09224b19e",
    firstName: "Ali Al Sabah",
    lastName: null,
    phoneNumber: "+96599992444",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6702ac66906276458c833fa5",
    delivery: {
      id: "671911b6b7a34cd09224b19e",
      customerId: "6702ac66906276458c833fa5",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6702b240218a8c4af25b7dbb",
        name: "Home",
        lng: 48.00035052001476,
        lat: 29.299112547392514,
        city: "Kuwait",
        area: "Al-Siddeeq",
        block: "1",
        street: "103 St",
        building: "1",
        floor: "",
        flat: "",
        phone: "+96599992444",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-06T15:52:32.495Z",
        updatedAt: "2024-10-06T15:52:32.495Z",
      },
      deliverectValidationId: "671911b6621b7746abe010c6",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:19:42.125Z",
      estimatedDeliveryTime: "2024-10-23T15:34:40.733Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:06:00.000Z",
      orderId: "671911b6b7a34cd09224b1b9",
      createdAt: "2024-10-23T15:09:42.231Z",
      updatedAt: "2024-10-23T15:11:06.569Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fdd",
        productPLU: "2162112-1",
        productDescription:
          "C 65g | P 21g | F 28g[%Popular%]\nFM burger with baked sweet potato fries and bbq sauce",
        productName: "FM burger & fries",
        imageURL:
          "https://resizer.deliverect.com/RmA60bYBZskDxWGZ3uPWH1NZAH_rxnDkyiAlnyV_l1o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBidXJnZXIlMjBjb21iby02NzEzNTc5NTQ4OGM3MjkyNjAyMTliNWYucG5n",
        productCalories: 596,
        quantity: 1,
        nutritionInfo: {
          carbs: 65,
          protein: 21,
          fat: 28,
        },
        fmcCentsPrice: 420,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc14b7a34cd092086088",
            productPLU: "1906004-1",
            productName: "Extra  Beef Pattie",
            productDescription: "C 2g| P 6g| F 3g| cal 59| coins 0.5",
            productCalories: 59,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 2,
              protein: 6,
              fat: 3,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 420,
    totalFmcCentsPrice: 420,
    totalFiatCentsPriceOriginal: 420,
    totalExternalFiatCentsPrice: 420,
    totalFmcCentsPriceOriginal: 420,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:09:42.992Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:11:14.998Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:11:54.610Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:29:50.273Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:29:50.273Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:34:55.117Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:02:27.608Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:09:42.992Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:09:55.168Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:09:42.992Z",
    updatedAt: "2024-10-23T16:02:27.956Z",
    fleetInfo: null,
  },
  {
    id: "67191113b7a34cd092248adf",
    sid: "241023-938377",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67191112b7a34cd092248a1a",
    firstName: "Bedour alsubaiei",
    lastName: null,
    phoneNumber: "+96597717247",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f1d37a7f0597f029e2c22b",
    delivery: {
      id: "67191112b7a34cd092248a1a",
      customerId: "66f1d37a7f0597f029e2c22b",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f1d3f01294dc02ca62265d",
        name: "Hessa",
        lng: 47.9633582794302,
        lat: 29.3486328125,
        city: "Kuwait",
        area: "Shamiya",
        block: "8",
        street: "89 St",
        building: "16",
        floor: "",
        flat: "",
        phone: "+96597717247",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-23T20:47:44.621Z",
        updatedAt: "2024-10-23T15:04:52.745Z",
      },
      deliverectValidationId: "671911124cb7e660cd8bbc5c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:16:58.402Z",
      estimatedDeliveryTime: "2024-10-23T15:31:57.372Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:03:00.000Z",
      orderId: "67191113b7a34cd092248adf",
      createdAt: "2024-10-23T15:06:58.604Z",
      updatedAt: "2024-10-23T15:08:05.698Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd092087063",
        productPLU: "2162031-1",
        productDescription:
          "C 63g| P 25g| F 12g\nSweet & sour chicken, green bell peppers, carrots & pineapples served with white rice.",
        productName: "Sweet & Sour Chicken",
        imageURL:
          "https://resizer.deliverect.com/DlUpN-omCGUFYG08dg-u9PiTfH2URZQt44nUONmWKto/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvOC02NGI3YjAxMzRiOTdiYTM4NWRjN2FlMmIucG5n",
        productCalories: 466,
        quantity: 1,
        nutritionInfo: {
          carbs: 63,
          protein: 25,
          fat: 12,
        },
        fmcCentsPrice: 360,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc1fb7a34cd092087124",
            productPLU: "1906010-1",
            productName: "Extra  Breaded Chicken",
            productDescription: "C 21g| P 25g| F 9.3g| cal 279| coins 0.8",
            productCalories: 279,
            quantity: 1,
            fmcCentsPrice: 80,
            fiatCentsPrice: 80,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 21,
              protein: 25,
              fat: 9.3,
            },
          },
        ],
      },
      {
        productId: "6718bc1fb7a34cd09208707d",
        productPLU: "2163020-1",
        productDescription:
          "C 10g | P 2g | F 3g [%New%]\n\nRomaine lettuce, cucumber, green capsicum, spring onion, parsley, dill leaves, sumac, croutons with Italian dressing.",
        productName: "Mini Italian salad",
        imageURL:
          "https://resizer.deliverect.com/XkxzmQRK5xU2IA0XJqjnN8x_RpgC6KxTMoxtFe2tjkE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGl0YWxpYW4lMjBzYWxhZC02NzEzNGUwMjQ4OGM3MjkyNjAyMTliNDcucG5n",
        productCalories: 75,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 2,
          fat: 3,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 490,
    totalFmcCentsPrice: 490,
    totalFiatCentsPriceOriginal: 490,
    totalExternalFiatCentsPrice: 490,
    totalFmcCentsPriceOriginal: 490,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:06:59.770Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:08:12.270Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:08:28.090Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:20:40.641Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:20:40.641Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:21:02.500Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:31:39.744Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:06:59.770Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:07:12.009Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:06:59.770Z",
    updatedAt: "2024-10-23T15:31:40.559Z",
    fleetInfo: null,
  },
  {
    id: "67191092b7a34cd09224791c",
    sid: "241023-439665",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67191091b7a34cd092247910",
    firstName: "Danah AlHaroon",
    lastName: null,
    phoneNumber: "+96599891440",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66a9f749f6ed0b29489f50ab",
    delivery: {
      id: "67191091b7a34cd092247910",
      customerId: "66a9f749f6ed0b29489f50ab",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66a9f8691c3dd8f82b14d529",
        name: "Home",
        lng: 47.9772000387311,
        lat: 29.343614411838892,
        city: "Kuwait City",
        area: "Faiha",
        block: "2",
        street: "26",
        building: "4",
        floor: "",
        flat: "",
        phone: "+96599891440",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-31T08:40:09.716Z",
        updatedAt: "2024-07-31T08:40:09.716Z",
      },
      deliverectValidationId: "671910913946960ae1bbb6aa",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:14:49.065Z",
      estimatedDeliveryTime: "2024-10-23T15:29:47.738Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:01:00.000Z",
      orderId: "67191092b7a34cd09224791c",
      createdAt: "2024-10-23T15:04:49.361Z",
      updatedAt: "2024-10-23T15:06:07.534Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd092087059",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc1fb7a34cd0920870f4",
            productPLU: "1906004-1",
            productName: "Extra  Beef Pattie",
            productDescription: "C 2g| P 6g| F 3g| cal 59| coins 0.5",
            productCalories: 59,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 2,
              protein: 6,
              fat: 3,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:04:50.163Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:06:14.508Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:06:27.977Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:19:01.836Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:19:01.836Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:19:23.693Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:33:07.713Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:04:50.163Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:05:01.598Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:04:50.163Z",
    updatedAt: "2024-10-23T15:33:08.378Z",
    fleetInfo: null,
  },
  {
    id: "67191059b7a34cd092246d96",
    sid: "241023-616727",
    coupons: [],
    menuId: "6718bc39b7a34cd09208791a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67191058b7a34cd092246d88",
    firstName: "Khadijah Muthaffar",
    lastName: null,
    phoneNumber: "+96599992785",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "661c1b01b0d178e632055f9b",
    delivery: {
      id: "67191058b7a34cd092246d88",
      customerId: "661c1b01b0d178e632055f9b",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "661c1b8275d5aca9d88449da",
        name: "My flat ",
        lng: 48.08381097391248,
        lat: 29.26429281641854,
        city: "Sabah Al Salem",
        area: "Sabah Al Salem",
        block: "1",
        street: "117",
        building: "7th Avenue ",
        floor: "8",
        flat: "B55",
        phone: "+96599992785",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-14T18:08:02.653Z",
        updatedAt: "2024-07-16T16:48:53.942Z",
      },
      deliverectValidationId: "67191058e630c246d234c26c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:13:52.289Z",
      estimatedDeliveryTime: "2024-10-23T15:28:51.183Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:00:00.000Z",
      orderId: "67191059b7a34cd092246d96",
      createdAt: "2024-10-23T15:03:52.388Z",
      updatedAt: "2024-10-23T15:05:05.351Z",
    },
    items: [
      {
        productId: "6718bc39b7a34cd092087976",
        productPLU: "2162135-1",
        productDescription:
          "C 48g | P 30g | F 20g [%New%]\n\nFajita chicken, roasted green & red bell pepper, white onion, mozzarella & cheddar cheese, fajita pasta sauce, tagliatelle pasta.\n\nGood source of Protein & Vitamin C",
        productName: "Chicken Fajita pasta",
        imageURL:
          "https://resizer.deliverect.com/P6vv9AmRSy3fv-D6K24m2s6Cm2eCavfO009W0aR_n_s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2hpY2tlbiUyMGZhaml0YSUyMHBhc3RhLTY3MTM1NTM3ZjU4NTM0NDM4NzE1ZTRhOS5wbmc=",
        productCalories: 492,
        quantity: 1,
        nutritionInfo: {
          carbs: 48,
          protein: 30,
          fat: 20,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd0920879a9",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd0920879c8",
        productPLU: "2166026-1",
        productDescription:
          "C 13g| P 6g| F 9g\nKale, cabbage, carrots, onions, orange slices & cashews topped with sesame seeds & drizzled with garlic ginger peanut dressing.\n\nGOOD SOURCE OF VITAMIN & VITAMIN C",
        productName: "Mini Thai Slaw",
        imageURL:
          "https://resizer.deliverect.com/89E9zE4JuS-ammKNFUesPBA8SiE1FYMyZUmczLAr-Gg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMHRoYWklMjBzbGF3LTY1MDQ5NDYxOGVjNDM1MDAxYTRjMTQ2My5qcGc=",
        productCalories: 157,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 6,
          fat: 9,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd0920879d6",
        productPLU: "2168001-1",
        productDescription:
          "C 14g | P 5g | F 1g\nRed lentil soup topped with coriander & served with a lemon wedge. ",
        productName: "Lentil Soup",
        imageURL:
          "https://resizer.deliverect.com/5EbDdbzPc4MFBEpLJbi04Urr0_H4N9CuGy35JtE6XBM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbGVudGlsJTIwc291cC02NjQxZGJjOTNjYmY3YTM2N2ZiNDhiN2IuanBn",
        productCalories: 85,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 5,
          fat: 1,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 630,
    totalFmcCentsPrice: 630,
    totalFiatCentsPriceOriginal: 630,
    totalExternalFiatCentsPrice: 630,
    totalFmcCentsPriceOriginal: 630,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:03:53.223Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:05:04.757Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:09:53.090Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:20:13.344Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:20:13.345Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:23:14.751Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:56:13.757Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:03:53.223Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:04:05.406Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T15:03:53.223Z",
    updatedAt: "2024-10-23T15:56:14.094Z",
    fleetInfo: null,
  },
  {
    id: "6719101ab7a34cd092245ce7",
    sid: "241023-221808",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67191019b7a34cd092245cda",
    firstName: "Fatimah Al Arifi",
    lastName: null,
    phoneNumber: "+96597990555",
    paymentType: "fmcCents",
    type: "scheduleOrder",
    customerId: "6707af2baa779ec4a77b7977",
    delivery: {
      id: "67191019b7a34cd092245cda",
      customerId: "6707af2baa779ec4a77b7977",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6707b002f495b693cdc155c2",
        name: "Home",
        lng: 48.06882210604777,
        lat: 29.275273526769592,
        city: "Kuwait",
        area: "Mishref",
        block: "4",
        street: "8 St",
        building: "16",
        floor: "",
        flat: "",
        phone: "+96597990555",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-10T10:44:18.227Z",
        updatedAt: "2024-10-16T18:11:35.844Z",
      },
      deliverectValidationId: "67191019a7e4fe3721f69b05",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:12:49.337Z",
      estimatedDeliveryTime: "2024-10-23T16:00:00.000Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:06:00.000Z",
      orderId: "6719101ab7a34cd092245ce7",
      createdAt: "2024-10-23T15:02:49.443Z",
      updatedAt: "2024-10-23T15:25:10.443Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd092086734",
        productPLU: "2162117-1",
        productDescription:
          "C 60g | P 22g | F 15g\nTomato sauce, cheese & oregano.",
        productName: "Classic Margherita Pizza",
        imageURL:
          "https://resizer.deliverect.com/gXmec37h0lqeQkrMSIQRiRdHLwG9kVK9Ukz9m_Kw930/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWFyZ2hhcml0YS02NjZiMzAxMWQ2NmMzMDFhNDE0NmYxNTMucG5n",
        productCalories: 463,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 22,
          fat: 15,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086796",
        productPLU: "2166036-1",
        productDescription:
          "C 30g | P 4g | F 9g \nTortilla chips,  guacamole & salsa",
        productName: "Salsa & Guac Nachos",
        imageURL:
          "https://resizer.deliverect.com/0WMmSGxzjSPDoEdAbH-9LvVLhnBDISj1o9ayRRQBqWA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU2Fsc2ElMjAlMjYlMjBndWFjJTIwbmFjaG9zLTY2NjFjZGE0NDliZDAxOWI4YmRhYWNkNS5wbmc=",
        productCalories: 217,
        quantity: 1,
        nutritionInfo: {
          carbs: 30,
          protein: 4,
          fat: 9,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 510,
    totalFmcCentsPrice: 510,
    totalFiatCentsPriceOriginal: 510,
    totalExternalFiatCentsPrice: 510,
    totalFmcCentsPriceOriginal: 510,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:02:50.162Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:25:09.894Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:26:22.521Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:35:29.044Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:35:29.044Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:40:07.669Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:01:24.571Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T15:02:50.162Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T15:03:01.821Z",
      },
    ],
    cutlery: true,
    hasRating: true,
    timeslot: "19:00 - 19:29",
    createdAt: "2024-10-23T15:02:50.162Z",
    updatedAt: "2024-10-23T16:20:37.084Z",
    fleetInfo: null,
  },
  {
    id: "67190f50b7a34cd092243d8c",
    sid: "241023-653031",
    coupons: [],
    menuId: "6718bc39b7a34cd09208791a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67190f4fb7a34cd092243d81",
    firstName: "Salma faisal alrasih",
    lastName: null,
    phoneNumber: "+96560006282",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66e2a99eaa1645e3a514d1ce",
    delivery: {
      id: "67190f4fb7a34cd092243d81",
      customerId: "66e2a99eaa1645e3a514d1ce",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66fbe0b6ee3a746f7f0947d7",
        name: "My house ",
        lng: 48.08809161186218,
        lat: 29.243020725768325,
        city: "Al-Masayel",
        area: "Al-Masayel",
        block: "2",
        street: "210 St",
        building: "228A",
        floor: "House ",
        flat: "House ",
        phone: "+96560006282",
        additionalInfo:
          "Just knock the door or call the housemade number  98786354",
        isSelected: true,
        createdAt: "2024-10-01T11:44:54.535Z",
        updatedAt: "2024-10-17T20:07:49.957Z",
      },
      deliverectValidationId: "67190f4fb63263b7f6a6f79f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:09:27.779Z",
      estimatedDeliveryTime: "2024-10-23T15:24:26.793Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:56:00.000Z",
      orderId: "67190f50b7a34cd092243d8c",
      createdAt: "2024-10-23T14:59:27.878Z",
      updatedAt: "2024-10-23T15:01:09.072Z",
    },
    items: [
      {
        productId: "6718bc39b7a34cd092087990",
        productPLU: "2162096-1",
        productDescription:
          "C 48g| P 17g| F 17g\nCrispy fish tenders served with baked fries & tartar sauce.\n\nGood source of Protein, Vitamins and Minerals",
        productName: "Fish N' Chips",
        imageURL:
          "https://resizer.deliverect.com/nIuQ090dCYLyovaNm9H_VJzVg3JpgGl0gzEm4NvBR6s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRmlzaCUyME4nJTIwQ2hpcHMtNjVmNmVjMDViOWNjMGMzODFjY2YwOTQyLnBuZw==",
        productCalories: 413,
        quantity: 1,
        nutritionInfo: {
          carbs: 48,
          protein: 17,
          fat: 17,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd0920879d8",
        productPLU: "2150024-1",
        productDescription:
          "C 10g | P 16g | F 7g\nJareesh with beef tenderloin, topped with diced onions. \n\nGood sourceof Protein& Vitamin B12 and Fiber ",
        productName: "Jareesh",
        imageURL:
          "https://resizer.deliverect.com/wQlso2Vdw0WvZ4iujMAq1gJW3Y2itz5As0oEsHIlggU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFyZWVzaC02NmQwNzFmZDE5NGUzZDNkMDBmMzk0NjcucG5n",
        productCalories: 167,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 16,
          fat: 7,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 590,
    totalFmcCentsPrice: 590,
    totalFiatCentsPriceOriginal: 590,
    totalExternalFiatCentsPrice: 590,
    totalFmcCentsPriceOriginal: 590,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:59:28.428Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:01:08.789Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:01:20.369Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:16:18.087Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:16:18.087Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:16:41.268Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:31:25.769Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:59:28.428Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:59:49.014Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:59:28.428Z",
    updatedAt: "2024-10-23T15:31:26.108Z",
    fleetInfo: null,
  },
  {
    id: "67190f4eb7a34cd092243d70",
    sid: "241023-183761",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190f4db7a34cd092243c9d",
    firstName: "Ahmad AlSharhan",
    lastName: null,
    phoneNumber: "+96560330009",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d5e5015a344f927498a7d3",
    delivery: {
      id: "67190f4db7a34cd092243c9d",
      customerId: "64d5e5015a344f927498a7d3",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65056ef10a91a2efcd29c318",
        name: "Home",
        lng: 47.95817378908396,
        lat: 29.325587737051986,
        city: "Shuwaikh Industrial",
        area: "Khaldiya",
        block: "1",
        street: "Ghazza 7",
        building: "33",
        floor: "",
        flat: "",
        phone: "+96560330009",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-09-16T09:01:37.335Z",
        updatedAt: "2024-10-23T14:56:50.459Z",
      },
      deliverectValidationId: "67190f4dc47d7273b3252183",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:09:25.308Z",
      estimatedDeliveryTime: "2024-10-23T15:24:24.202Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:56:00.000Z",
      orderId: "67190f4eb7a34cd092243d70",
      createdAt: "2024-10-23T14:59:25.398Z",
      updatedAt: "2024-10-23T15:01:09.750Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085ff6",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092085fed",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092085ffa",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 710,
    totalFmcCentsPrice: 710,
    totalFiatCentsPriceOriginal: 710,
    totalExternalFiatCentsPrice: 710,
    totalFmcCentsPriceOriginal: 710,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:59:26.521Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:01:12.242Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:01:30.290Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:11:05.299Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:11:05.300Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:11:32.766Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:40:27.949Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:59:26.521Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:59:38.044Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:59:26.521Z",
    updatedAt: "2024-10-23T15:40:28.754Z",
    fleetInfo: null,
  },
  {
    id: "67190f16b7a34cd09224339f",
    sid: "241023-588309",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67190f16b7a34cd092243393",
    firstName: "Tasneem Saeed AlSennawi",
    lastName: null,
    phoneNumber: "+96599439394",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66936c25e02b51659fe64869",
    delivery: {
      id: "67190f16b7a34cd092243393",
      customerId: "66936c25e02b51659fe64869",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6693dee4034bd20f75917f7c",
        name: "My home",
        lng: 48.08593679219484,
        lat: 29.27399267713483,
        city: "Kuwait",
        area: "Salwa",
        block: "12",
        street: "7",
        building: "5",
        floor: "",
        flat: "",
        phone: "+96599439394",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-14T14:21:24.289Z",
        updatedAt: "2024-10-23T14:57:24.946Z",
      },
      deliverectValidationId: "67190f1660ec4531f4c72306",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:08:30.124Z",
      estimatedDeliveryTime: "2024-10-23T15:23:28.965Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:55:00.000Z",
      orderId: "67190f16b7a34cd09224339f",
      createdAt: "2024-10-23T14:58:30.217Z",
      updatedAt: "2024-10-23T15:00:05.792Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd092086745",
        productPLU: "2162077-1",
        productDescription:
          "C 41g | P 26g | F 16g\nShort ribs, mixed cabbage, sour cream, spring onion, parsely with tortilla wrap.\n\nGood Source Of Protein, Vitamin B12, Minerals",
        productName: "Short Rib Tacos",
        imageURL:
          "https://resizer.deliverect.com/bwNBblwmBg7NZVLL0zxwD_1h0r1mAgb-zXh607JTGn4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVEFDTy02NTI5ZjMxMWQyNmQ1ZGYyNzhkZDZiYjYucG5n",
        productCalories: 420,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 26,
          fat: 16,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086764",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 24,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 500,
    totalFmcCentsPrice: 500,
    totalFiatCentsPriceOriginal: 500,
    totalExternalFiatCentsPrice: 500,
    totalFmcCentsPriceOriginal: 500,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:58:30.986Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:00:05.368Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:00:22.707Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:10:06.048Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:10:06.048Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:10:28.419Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:35:39.892Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:58:30.986Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:58:42.402Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:58:30.987Z",
    updatedAt: "2024-10-23T15:35:40.237Z",
    fleetInfo: null,
  },
  {
    id: "67190ef3b7a34cd092242729",
    sid: "241023-221206",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190ef2b7a34cd09224265b",
    firstName: "Haifa Aljarallah",
    lastName: null,
    phoneNumber: "+96567072020",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66488eb169f370da27d978ca",
    delivery: {
      id: "67190ef2b7a34cd09224265b",
      customerId: "66488eb169f370da27d978ca",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "66488faae7d7ea1c4c44edb9",
        name: "Home",
        lng: 47.97519139945507,
        lat: 29.307272132197145,
        city: "Kuwait",
        area: "Yarmouk",
        block: "2",
        street: "St 1 Lane 2",
        building: "4",
        floor: "",
        flat: "",
        phone: "+96567072020",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-18T11:23:22.093Z",
        updatedAt: "2024-05-18T11:23:22.093Z",
      },
      deliverectValidationId: "67190ef21cb32e3bce23168e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:07:54.409Z",
      estimatedDeliveryTime: "2024-10-23T15:22:53.374Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:54:00.000Z",
      orderId: "67190ef3b7a34cd092242729",
      createdAt: "2024-10-23T14:57:54.632Z",
      updatedAt: "2024-10-23T14:59:04.417Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092086008",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 24,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208601c",
        productPLU: "2166026-1",
        productDescription:
          "C 13g| P 6g| F 9g\nKale, cabbage, carrots, onions, orange slices & cashews topped with sesame seeds & drizzled with garlic ginger peanut dressing.\n\nGOOD SOURCE OF VITAMIN & VITAMIN C",
        productName: "Mini Thai Slaw",
        imageURL:
          "https://resizer.deliverect.com/89E9zE4JuS-ammKNFUesPBA8SiE1FYMyZUmczLAr-Gg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMHRoYWklMjBzbGF3LTY1MDQ5NDYxOGVjNDM1MDAxYTRjMTQ2My5qcGc=",
        productCalories: 157,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 6,
          fat: 9,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208602a",
        productPLU: "2168001-1",
        productDescription:
          "C 14g | P 5g | F 1g\nRed lentil soup topped with coriander & served with a lemon wedge. ",
        productName: "Lentil Soup",
        imageURL:
          "https://resizer.deliverect.com/5EbDdbzPc4MFBEpLJbi04Urr0_H4N9CuGy35JtE6XBM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbGVudGlsJTIwc291cC02NjQxZGJjOTNjYmY3YTM2N2ZiNDhiN2IuanBn",
        productCalories: 85,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 5,
          fat: 1,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 320,
    totalFmcCentsPrice: 320,
    totalFiatCentsPriceOriginal: 320,
    totalExternalFiatCentsPrice: 320,
    totalFmcCentsPriceOriginal: 320,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:57:55.821Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:59:10.915Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:59:49.670Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:09:17.559Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:09:17.559Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:11:21.671Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:25:09.466Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:57:55.821Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:58:07.923Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:57:55.821Z",
    updatedAt: "2024-10-23T15:25:09.813Z",
    fleetInfo: null,
  },
  {
    id: "67190eb4b7a34cd0922414b8",
    sid: "241023-954840",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67190eb3b7a34cd092241479",
    firstName: "Yousef",
    lastName: null,
    phoneNumber: "+96599417777",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65085afce1d913534186db11",
    delivery: {
      id: "67190eb3b7a34cd092241479",
      customerId: "65085afce1d913534186db11",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "653505cd289c08cbdb98a5c4",
        name: "Shamiya",
        lng: 47.96226687192963,
        lat: 29.3551025390625,
        city: "Al Kuwayt",
        area: "Shamiya",
        block: "3",
        street: "شارع 32،",
        building: "5",
        floor: "",
        flat: "",
        phone: "+96599417777",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-22T11:21:49.937Z",
        updatedAt: "2024-10-21T15:11:11.415Z",
      },
      deliverectValidationId: "67190eb30348c4b0ea1571f4",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:06:51.631Z",
      estimatedDeliveryTime: "2024-10-23T15:21:50.604Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:53:00.000Z",
      orderId: "67190eb4b7a34cd0922414b8",
      createdAt: "2024-10-23T14:56:51.758Z",
      updatedAt: "2024-10-23T14:58:05.975Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd09208704d",
        productPLU: "2162109-1",
        productDescription:
          "C 23g | P 29g | F 16g \n Beef Lasagna with zucchini & basil\n\nGood source of protein and vitamin A",
        productName: "Zucchini Beef Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SGhwFjSpMczUdKMaEIYf4gYXABWfE33sF_WueZYOSfI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGxhc2FnbmEtNjY0NGFmZDU3NDhmMzY3YzEzMTZlNWZhLnBuZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 23,
          protein: 29,
          fat: 16,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd092087098",
        productPLU: "2150024-1",
        productDescription:
          "C 10g | P 16g | F 7g\nJareesh with beef tenderloin, topped with diced onions. \n\nGood sourceof Protein& Vitamin B12 and Fiber ",
        productName: "Jareesh",
        imageURL:
          "https://resizer.deliverect.com/wQlso2Vdw0WvZ4iujMAq1gJW3Y2itz5As0oEsHIlggU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFyZWVzaC02NmQwNzFmZDE5NGUzZDNkMDBmMzk0NjcucG5n",
        productCalories: 167,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 16,
          fat: 7,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 480,
    totalFmcCentsPrice: 480,
    totalFiatCentsPriceOriginal: 480,
    totalExternalFiatCentsPrice: 480,
    totalFmcCentsPriceOriginal: 480,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:56:52.442Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:58:12.091Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:58:49.838Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:03:47.691Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:03:47.692Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:05:27.673Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:22:29.233Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:56:52.442Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:57:04.482Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:56:52.442Z",
    updatedAt: "2024-10-23T15:22:29.571Z",
    fleetInfo: null,
  },
  {
    id: "67190e84b7a34cd092240578",
    sid: "241023-564439",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190e83b7a34cd092240553",
    firstName: "Abdullah AlEssa",
    lastName: null,
    phoneNumber: "+96550553359",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f41e6a8951a675da027cfb",
    delivery: {
      id: "67190e83b7a34cd092240553",
      customerId: "66f41e6a8951a675da027cfb",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f41f2cee3a746f7ffe52f3",
        name: "Home",
        lng: 48.00947807729244,
        lat: 29.288326815384103,
        city: "Kuwait",
        area: "حطين",
        block: "2",
        street: "شارع 214",
        building: "33",
        floor: "",
        flat: "",
        phone: "+96550553359",
        additionalInfo: "White House on corner ",
        isSelected: true,
        createdAt: "2024-09-25T14:33:16.884Z",
        updatedAt: "2024-10-23T14:54:13.159Z",
      },
      deliverectValidationId: "67190e83c47d7273b324cb46",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:06:03.293Z",
      estimatedDeliveryTime: "2024-10-23T15:21:01.862Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:53:00.000Z",
      orderId: "67190e84b7a34cd092240578",
      createdAt: "2024-10-23T14:56:03.495Z",
      updatedAt: "2024-10-23T14:58:05.806Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085ff5",
        productPLU: "2162035-1",
        productDescription:
          "C 50g| P 32g| F 15g\nBlack pepper beef with veggies served with egyptian rice.\n\nGood source of Iron & B-12 Vitamins",
        productName: "Black Pepper Beef",
        imageURL:
          "https://resizer.deliverect.com/FBy7BxqQqLr64Ae34YvyRhL0jHWvib_v5F9riYKNFsc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNzYtNjRiN2IwNWM5Mjc2OTYwMDE5NmViYjFjLnBuZw==",
        productCalories: 474,
        quantity: 1,
        nutritionInfo: {
          carbs: 50,
          protein: 32,
          fat: 15,
        },
        fmcCentsPrice: 330,
        fiatCentsPrice: 330,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:56:04.194Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:58:11.530Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:58:45.933Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:09:25.093Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:09:25.093Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:10:05.341Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:26:41.383Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:56:04.194Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:56:15.944Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:56:04.195Z",
    updatedAt: "2024-10-23T15:26:41.725Z",
    fleetInfo: null,
  },
  {
    id: "67190e50b7a34cd09223fa4e",
    sid: "241023-608694",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67190e4fb7a34cd09223fa3d",
    firstName: "Mohammad m dashti",
    lastName: null,
    phoneNumber: "+96594017560",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67002c26906276458c7e13d8",
    delivery: {
      id: "67190e4fb7a34cd09223fa3d",
      customerId: "67002c26906276458c7e13d8",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6700479d218a8c4af20cea53",
        name: "Mansourya ",
        lng: 47.990464947052665,
        lat: 29.358963012695312,
        city: "Kuwait",
        area: "Mansouriya",
        block: "1",
        street: "15 St",
        building: "16B",
        floor: "",
        flat: "",
        phone: "+96594017560",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-04T19:53:01.148Z",
        updatedAt: "2024-10-04T19:53:01.148Z",
      },
      deliverectValidationId: "67190e4f05f0aa7bf684d131",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:05:11.494Z",
      estimatedDeliveryTime: "2024-10-23T15:20:10.410Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:52:00.000Z",
      orderId: "67190e50b7a34cd09223fa4e",
      createdAt: "2024-10-23T14:55:11.630Z",
      updatedAt: "2024-10-23T14:57:05.673Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd092087049",
        productPLU: "2162112-1",
        productDescription:
          "C 65g | P 21g | F 28g[%Popular%]\nFM burger with baked sweet potato fries and bbq sauce",
        productName: "FM burger & fries",
        imageURL:
          "https://resizer.deliverect.com/RmA60bYBZskDxWGZ3uPWH1NZAH_rxnDkyiAlnyV_l1o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBidXJnZXIlMjBjb21iby02NzEzNTc5NTQ4OGM3MjkyNjAyMTliNWYucG5n",
        productCalories: 596,
        quantity: 1,
        nutritionInfo: {
          carbs: 65,
          protein: 21,
          fat: 28,
        },
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc1fb7a34cd0920870f6",
            productPLU: "1907005-1",
            productName: "No Mayo ketchup Sauce",
            productDescription: "C -4g| P 0g| F -3g| cal -44| coins 0",
            productCalories: -44,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -4,
              protein: null,
              fat: -3,
            },
          },
          {
            productId: "6718bc1fb7a34cd0920870f1",
            productPLU: "1907003-1",
            productName: "No Cheddar Cheese",
            productDescription: "C 0g| P -2g| F -3g| cal -40| coins 0\n\n",
            productCalories: -40,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: -2,
              fat: -3,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 370,
    totalExternalFiatCentsPrice: 370,
    totalFmcCentsPriceOriginal: 370,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:55:12.306Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:57:13.356Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:57:26.806Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:06:00.041Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:06:00.041Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:11:09.876Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:30:48.633Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:55:12.306Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:55:23.964Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:55:12.306Z",
    updatedAt: "2024-10-23T15:30:49.083Z",
    fleetInfo: null,
  },
  {
    id: "67190dddb7a34cd09223ce92",
    sid: "241023-617114",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67190ddcb7a34cd09223ce0f",
    firstName: "Jaber t",
    lastName: null,
    phoneNumber: "+96599898899",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66a64217ac4eb4f3eefc880c",
    delivery: {
      id: "67190ddcb7a34cd09223ce0f",
      customerId: "66a64217ac4eb4f3eefc880c",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66a642d71683a2e72698bae9",
        name: "Home",
        lng: 48.008207716047764,
        lat: 29.30680909995469,
        city: "Al Kuwayt",
        area: "Surra",
        block: "4",
        street: "7 St",
        building: "97",
        floor: "",
        flat: "",
        phone: "+96599898899",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-28T13:08:39.135Z",
        updatedAt: "2024-07-28T13:08:39.135Z",
      },
      deliverectValidationId: "67190ddbb87b85dcf6ae5d5b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:03:15.837Z",
      estimatedDeliveryTime: "2024-10-23T15:18:14.850Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:50:00.000Z",
      orderId: "67190dddb7a34cd09223ce92",
      createdAt: "2024-10-23T14:53:16.455Z",
      updatedAt: "2024-10-23T14:55:05.277Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd092086763",
        productPLU: "2167013-1",
        productDescription:
          "C 42g| P 28g| F 12g [%Popular%]\nBeef with our special sauce and sumac onion wrapped in tortilla.\n\nGood source of Vitamin B6 & Vitamin B12",
        productName: "Beef Shawarma",
        imageURL:
          "https://resizer.deliverect.com/DQl32OjdVbaHyJyht46g35g33tk0bsgQNbyyp4jWAdc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQmVlZiUyMHNoYXdhcm1hLTY1ZjZlOThhMzRiNTQ3YTg5NDYyNmEwZS5qcGc=",
        productCalories: 388,
        quantity: 1,
        nutritionInfo: {
          carbs: 42,
          protein: 28,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086786",
        productPLU: "2168001-1",
        productDescription:
          "C 14g | P 5g | F 1g\nRed lentil soup topped with coriander & served with a lemon wedge. ",
        productName: "Lentil Soup",
        imageURL:
          "https://resizer.deliverect.com/5EbDdbzPc4MFBEpLJbi04Urr0_H4N9CuGy35JtE6XBM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbGVudGlsJTIwc291cC02NjQxZGJjOTNjYmY3YTM2N2ZiNDhiN2IuanBn",
        productCalories: 85,
        quantity: 2,
        nutritionInfo: {
          carbs: 14,
          protein: 5,
          fat: 1,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086787",
        productPLU: "2168002-1",
        productDescription:
          "C 4g | P 2g | F 2g|\nCreamy mushroom soup topped with parsley. ",
        productName: "Mushroom Soup",
        imageURL:
          "https://resizer.deliverect.com/VvtgR2CAH02ajyt1L7X3df8XtKjNQ2Kx-d7hCJY0Q1w/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBzb3VwLTY2NDM3YTg5ZTFhZTBmYmZhZDYyYmVhZC5qcGc=",
        productCalories: 42,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 2,
          fat: 2,
        },
        fmcCentsPrice: 140,
        fiatCentsPrice: 140,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 550,
    totalFmcCentsPrice: 550,
    totalFiatCentsPriceOriginal: 550,
    totalExternalFiatCentsPrice: 550,
    totalFmcCentsPriceOriginal: 550,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:53:17.572Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:55:12.114Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:58:24.025Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:08:40.191Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:08:40.192Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:09:02.182Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:22:52.024Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:53:17.572Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:53:37.010Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:53:17.572Z",
    updatedAt: "2024-10-23T15:22:52.399Z",
    fleetInfo: null,
  },
  {
    id: "67190dd5b7a34cd09223c837",
    sid: "241023-767723",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67190dd4b7a34cd09223c72f",
    firstName: "Abdulwahab almuzairei ",
    lastName: null,
    phoneNumber: "+96599091399",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c8e1d875a6aa442d1237c7",
    delivery: {
      id: "67190dd4b7a34cd09223c72f",
      customerId: "64c8e1d875a6aa442d1237c7",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64d9e8693902308f22eacb5e",
        name: "Home",
        lng: 48.00537798553705,
        lat: 29.32627895165066,
        city: "Kuwait",
        area: "Rawda",
        block: "3",
        street: "34 St",
        building: "26",
        floor: "",
        flat: "",
        phone: "+96599091399",
        additionalInfo: "Corner house",
        isSelected: true,
        createdAt: "2023-08-14T08:40:09.210Z",
        updatedAt: "2023-10-10T13:29:09.237Z",
      },
      deliverectValidationId: "67190dd3b63f266a7d109d88",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:03:07.864Z",
      estimatedDeliveryTime: "2024-10-23T15:18:06.827Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:50:00.000Z",
      orderId: "67190dd5b7a34cd09223c837",
      createdAt: "2024-10-23T14:53:08.516Z",
      updatedAt: "2024-10-23T14:55:05.447Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208672d",
        productPLU: "2162125-1",
        productDescription:
          "C 51g | P 30g | F 8g\nGrilled Salmon with dill rice served with dakkus, lemon wedge & fresh pomegranate.\n\nGood source of Protein & Vitamins",
        productName: "Salmon & Dill Rice",
        imageURL:
          "https://resizer.deliverect.com/p93TVzm_6AWG3-UvpUjucOs9ophRezuPgMNQYVzvWXA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2FsbW9uJTIwJTI2JTIwZGlsbC02NmFiZjQ0Y2JiMGY0NmM4YzFkMDRjMzcucG5n",
        productCalories: 396,
        quantity: 1,
        nutritionInfo: {
          carbs: 51,
          protein: 30,
          fat: 8,
        },
        fmcCentsPrice: 480,
        fiatCentsPrice: 480,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086732",
        productPLU: "2162120-1",
        productDescription:
          "C 63g | P 29g | F 12g \nTomato sauce, cheese, chicken topped with BBQ sauce\n\nGood source of Protein ",
        productName: "BBQ Chicken Pizza",
        imageURL:
          "https://resizer.deliverect.com/oLi5aqw9dJEyegq-8KQAZCtGuNR2P65Gdii-y51sbns/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmJxJTIwcGl6emEtNjY3NDM0MDZhZjdmODYxNjFhMjNhOWYyLnBuZw==",
        productCalories: 476,
        quantity: 1,
        nutritionInfo: {
          carbs: 63,
          protein: 29,
          fat: 12,
        },
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 790,
    totalFmcCentsPrice: 790,
    totalFiatCentsPriceOriginal: 790,
    totalExternalFiatCentsPrice: 790,
    totalFmcCentsPriceOriginal: 790,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:53:09.837Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:55:12.198Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:58:23.937Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:11:20.809Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:11:20.809Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:12:40.690Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:26:10.762Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:53:09.837Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:53:26.615Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:53:09.837Z",
    updatedAt: "2024-10-23T15:26:11.202Z",
    fleetInfo: null,
  },
  {
    id: "67190dceb7a34cd09223c08c",
    sid: "241023-182627",
    coupons: [],
    menuId: "6718bc39b7a34cd09208791a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67190dcbb7a34cd09223bdcc",
    firstName: "Talal alazemi",
    lastName: null,
    phoneNumber: "+96566669828",
    paymentType: "fmcCents",
    type: "scheduleOrder",
    customerId: "6565b5ede0d78832aa91f369",
    delivery: {
      id: "67190dcbb7a34cd09223bdcc",
      customerId: "6565b5ede0d78832aa91f369",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "667bd8f7ac113a1f6d1f0273",
        name: "Home",
        lng: 48.099406845867634,
        lat: 29.103375417428943,
        city: "Kuwait",
        area: "الصباحية",
        block: "2",
        street: "2",
        building: "663",
        floor: "Diwanya",
        flat: "",
        phone: "+96566669828",
        additionalInfo:
          "3rd house on the right when facing the house the door on the right is diwanya to place the food ",
        isSelected: true,
        createdAt: "2024-06-26T09:01:43.905Z",
        updatedAt: "2024-10-23T14:46:03.453Z",
      },
      deliverectValidationId: "67190dcbe630c246d233d9fe",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:02:59.339Z",
      estimatedDeliveryTime: "2024-10-23T16:00:00.000Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:06:00.000Z",
      orderId: "67190dceb7a34cd09223c08c",
      createdAt: "2024-10-23T14:52:59.560Z",
      updatedAt: "2024-10-23T15:25:06.814Z",
    },
    items: [
      {
        productId: "6718bc39b7a34cd092087988",
        productPLU: "2162111-1",
        productDescription:
          "C 72g | P 23g | F 34g[%Popular%]\nChicken burger with baked sweet potato fries and bbq sauce",
        productName: "Chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/fMQuypdGrqkSRZhbEebRsjjcen1dY6rXtUgYEcTeE9s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cmdlciUyMGNvbWJvLTY3MTM1NzgzMWM2ZDc0YTE4NGJmNTY2ZC5wbmc=",
        productCalories: 686,
        quantity: 1,
        nutritionInfo: {
          carbs: 72,
          protein: 23,
          fat: 34,
        },
        fmcCentsPrice: 340,
        fiatCentsPrice: 340,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd0920879d5",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd092087a0f",
        productPLU: "2202022-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Sprite Zero",
        imageURL:
          "https://resizer.deliverect.com/ZEZdNlPffzmGS3rg7Tvd5hMcV02zZNG7y9fZavC83oI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMSUyMCgxKS02NGM5MTk0M2E0NTBlNjAwMjY4M2QzODEuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd0920879bd",
        productPLU: "2163020-1",
        productDescription:
          "C 10g | P 2g | F 3g [%New%]\n\nRomaine lettuce, cucumber, green capsicum, spring onion, parsley, dill leaves, sumac, croutons with Italian dressing.",
        productName: "Mini Italian salad",
        imageURL:
          "https://resizer.deliverect.com/XkxzmQRK5xU2IA0XJqjnN8x_RpgC6KxTMoxtFe2tjkE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGl0YWxpYW4lMjBzYWxhZC02NzEzNGUwMjQ4OGM3MjkyNjAyMTliNDcucG5n",
        productCalories: 75,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 2,
          fat: 3,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 650,
    totalFmcCentsPrice: 650,
    totalFiatCentsPriceOriginal: 650,
    totalExternalFiatCentsPrice: 650,
    totalFmcCentsPriceOriginal: 650,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:53:02.425Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:25:08.473Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:30:11.980Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:52:47.029Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:52:47.029Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T16:09:13.118Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T17:00:39.969Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:53:02.425Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:53:15.749Z",
      },
    ],
    cutlery: true,
    timeslot: "19:00 - 19:29",
    createdAt: "2024-10-23T14:53:02.425Z",
    updatedAt: "2024-10-23T17:00:40.312Z",
    fleetInfo: null,
  },
  {
    id: "67190d57b7a34cd092239344",
    sid: "241023-728302",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190d56b7a34cd09223932f",
    firstName: "Danah Alansari",
    lastName: null,
    phoneNumber: "+96594436640",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c3d80f75a6aa442d0f220c",
    delivery: {
      id: "67190d56b7a34cd09223932f",
      customerId: "64c3d80f75a6aa442d0f220c",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "64ca8f21966460755c5b02c0",
        name: "Home",
        lng: 48.00401374697685,
        lat: 29.27246398847958,
        city: "Zahra",
        area: "Zahra",
        block: "4",
        street: "423",
        building: "25",
        floor: "",
        flat: "",
        phone: "+96594436640",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-02T17:15:13.211Z",
        updatedAt: "2024-10-23T14:50:14.681Z",
      },
      deliverectValidationId: "67190d56147ac98c5c5d8fbd",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:01:02.393Z",
      estimatedDeliveryTime: "2024-10-23T15:16:00.594Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:48:00.000Z",
      orderId: "67190d57b7a34cd092239344",
      createdAt: "2024-10-23T14:51:02.643Z",
      updatedAt: "2024-10-23T14:53:09.273Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092086002",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc14b7a34cd0920860c0",
            productPLU: "1907056-1",
            productName: "No Onion",
            productDescription: "C -0.5g| P 0g| F 0g| cal -2.2| coins 0",
            productCalories: -2.2,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.5,
              protein: null,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "6718bc14b7a34cd092086011",
        productPLU: "2163020-1",
        productDescription:
          "C 10g | P 2g | F 3g [%New%]\n\nRomaine lettuce, cucumber, green capsicum, spring onion, parsley, dill leaves, sumac, croutons with Italian dressing.",
        productName: "Mini Italian salad",
        imageURL:
          "https://resizer.deliverect.com/XkxzmQRK5xU2IA0XJqjnN8x_RpgC6KxTMoxtFe2tjkE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGl0YWxpYW4lMjBzYWxhZC02NzEzNGUwMjQ4OGM3MjkyNjAyMTliNDcucG5n",
        productCalories: 75,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 2,
          fat: 3,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092086028",
        productPLU: "2150014-1",
        productDescription: "C 2g| P 30g| F 6g\n",
        productName: "Chicken Shawarma(100GM)",
        imageURL:
          "https://resizer.deliverect.com/5s0dpyz1hk8m1gvg8YGrM7u6yPuJzcNpjKxdUNjSi6g/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMHNoYXdhcm1hJTIwKDEpLTY0ZDc1YjZhNjg1NDg1MDAyNzQwZTNmNy5qcGc=",
        productCalories: 182,
        quantity: 1,
        nutritionInfo: {
          carbs: 2,
          protein: 30,
          fat: 6,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 550,
    totalFmcCentsPrice: 550,
    totalFiatCentsPriceOriginal: 550,
    totalExternalFiatCentsPrice: 550,
    totalFmcCentsPriceOriginal: 550,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:51:03.293Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:53:16.835Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:54:42.823Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:07:44.062Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:07:44.062Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:09:13.502Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:23:58.882Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:51:03.293Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:51:14.738Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:51:03.293Z",
    updatedAt: "2024-10-23T15:23:59.227Z",
    fleetInfo: null,
  },
  {
    id: "67190d22b7a34cd09223868b",
    sid: "241023-722544",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67190d22b7a34cd09223867e",
    firstName: "Hessa AlHunaidi ",
    lastName: null,
    phoneNumber: "+96560004548",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c3e2f775a6aa442d0f2c3e",
    delivery: {
      id: "67190d22b7a34cd09223867e",
      customerId: "64c3e2f775a6aa442d0f2c3e",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "650c799f0a91a2efcd4431a6",
        name: "Mama ghanima",
        lng: 47.96117652207613,
        lat: 29.33487052198079,
        city: "Shuwaikh Industrial",
        area: "Kaifan",
        block: "7",
        street: "71",
        building: "4",
        floor: "",
        flat: "",
        phone: "+96560004548",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-09-21T17:13:03.177Z",
        updatedAt: "2024-10-23T14:09:32.890Z",
      },
      deliverectValidationId: "67190d22dda2cbde33f6360b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T15:00:10.127Z",
      estimatedDeliveryTime: "2024-10-23T15:15:09.095Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:47:00.000Z",
      orderId: "67190d22b7a34cd09223868b",
      createdAt: "2024-10-23T14:50:10.231Z",
      updatedAt: "2024-10-23T14:52:05.151Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd092087058",
        productPLU: "2162006-1",
        productDescription:
          "C 33g| P 19g| F 16g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 352,
        quantity: 2,
        nutritionInfo: {
          carbs: 33,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd09208709b",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd092087098",
        productPLU: "2150024-1",
        productDescription:
          "C 10g | P 16g | F 7g\nJareesh with beef tenderloin, topped with diced onions. \n\nGood sourceof Protein& Vitamin B12 and Fiber ",
        productName: "Jareesh",
        imageURL:
          "https://resizer.deliverect.com/wQlso2Vdw0WvZ4iujMAq1gJW3Y2itz5As0oEsHIlggU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFyZWVzaC02NmQwNzFmZDE5NGUzZDNkMDBmMzk0NjcucG5n",
        productCalories: 167,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 16,
          fat: 7,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 710,
    totalFmcCentsPrice: 710,
    totalFiatCentsPriceOriginal: 710,
    totalExternalFiatCentsPrice: 710,
    totalFmcCentsPriceOriginal: 710,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:50:10.807Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:52:07.190Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:56:23.367Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:02:29.704Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:02:29.704Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:05:40.929Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:15:35.034Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:50:10.807Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:50:22.686Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:50:10.808Z",
    updatedAt: "2024-10-23T15:15:35.479Z",
    fleetInfo: null,
  },
  {
    id: "67190d15b7a34cd092237ee0",
    sid: "241023-142857",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190d14b7a34cd092237ed2",
    firstName: "Abdulmohsen Al-Khaled",
    lastName: null,
    phoneNumber: "+96596927938",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65edaf5c3ecea306239e709d",
    delivery: {
      id: "67190d14b7a34cd092237ed2",
      customerId: "65edaf5c3ecea306239e709d",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66a9c20f1c3dd8f82b1085dc",
        name: "Maribel last address",
        lng: 47.99354757253206,
        lat: 29.306289672851562,
        city: "العارضية",
        area: "Qortuba",
        block: "2",
        street: "1",
        building: "11",
        floor: "Avenue 3",
        flat: "House 11",
        phone: "+9659734022",
        additionalInfo:
          "Ring doorbell. House maid will take the order (maribel)",
        isSelected: true,
        createdAt: "2024-07-31T04:48:15.271Z",
        updatedAt: "2024-10-23T14:47:57.942Z",
      },
      deliverectValidationId: "67190d14b63263b7f6a63487",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:59:56.315Z",
      estimatedDeliveryTime: "2024-10-23T15:14:55.028Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:46:00.000Z",
      orderId: "67190d15b7a34cd092237ee0",
      createdAt: "2024-10-23T14:49:56.405Z",
      updatedAt: "2024-10-23T14:51:05.891Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092086034",
        productPLU: "2166045-1",
        productDescription: "C 30.8g | P 13g | F 7g",
        productName: "PRO CHIPS Sweet Chili",
        imageURL:
          "https://resizer.deliverect.com/wkLcJJBZvXkUfpEPep3wpGWmec82OiaxVPO7017FpyU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3dlZXQlMjBjaGlsbGklMjBjaGlwLTY2Yzg1OGMxMmU5MjQxOTdmMjVhM2M3Ni5wbmc=",
        productCalories: 238,
        quantity: 1,
        nutritionInfo: {
          carbs: 30.8,
          protein: 13,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208604c",
        productPLU: "2165026-1",
        productDescription: "C 13.4g | P 11g | F 12.2g\n",
        productName: "Peanut Butter Protein Bar",
        imageURL:
          "https://resizer.deliverect.com/3ZwgGn9CBKj9EAZV281gDw-Z9vljTvZQNDf5O18NRFY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVhbnV0JTIwYnV0dGVyLTY2YTJlMzE4YmIwZjQ2YzhjMWQwMmI5Yy5wbmc=",
        productCalories: 222,
        quantity: 1,
        nutritionInfo: {
          carbs: 13.4,
          protein: 11,
          fat: 12.2,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092086056",
        productPLU: "2165003-1",
        productDescription: "C 12g | P 2g | F 9g\n",
        productName: "Peanut Bites",
        imageURL:
          "https://resizer.deliverect.com/S2e9SlQ_6ewwX1Z-Pg_RtXB9e4OxquLa6-xOxnwoCJM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTMtNjRiN2JjNmFkMGE4N2MwMDFmZTYwZjBhLnBuZw==",
        productCalories: 139,
        quantity: 2,
        nutritionInfo: {
          carbs: 12,
          protein: 2,
          fat: 9,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092086066",
        productPLU: "2202036-1",
        productDescription:
          "C 20g | P 19.2g | F 4.7g\nCocoa powder, Milk, Stevia & WHEY Protein",
        productName: "Chocolate Protein Shake",
        imageURL:
          "https://resizer.deliverect.com/TjoR9Z9GyFIBSfx0VgVYXmRJcSGxnQwDMGEZg2k_4G4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hvY29sYXRlJTIwcHJvdGVpbiUyMHNoYWtlLTY2NDFkYWJmMGMyNGQ5MjVmY2QzZmE4Yi5wbmc=",
        productCalories: 200,
        quantity: 1,
        nutritionInfo: {
          carbs: 20,
          protein: 19.2,
          fat: 4.7,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 540,
    totalFmcCentsPrice: 540,
    totalFiatCentsPriceOriginal: 540,
    totalExternalFiatCentsPrice: 540,
    totalFmcCentsPriceOriginal: 540,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:49:57.188Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:51:07.909Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:52:58.171Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:07:33.222Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:07:33.222Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:13:25.741Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:39:45.001Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:49:57.188Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:50:09.199Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:49:57.188Z",
    updatedAt: "2024-10-23T15:39:45.359Z",
    fleetInfo: null,
  },
  {
    id: "67190ccdb7a34cd092235bee",
    sid: "241023-611252",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190ccbb7a34cd092235a3b",
    firstName: "Nusaiba alasfoor",
    lastName: null,
    phoneNumber: "+96560685055",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66daea2eaa1645e3a503eb24",
    delivery: {
      id: "67190ccbb7a34cd092235a3b",
      customerId: "66daea2eaa1645e3a503eb24",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66dc2b86ced6c5247edf3044",
        name: "14",
        lng: 47.92486836548357,
        lat: 29.246383666992188,
        city: "Abdullah Al Mubarak Al Sabah",
        area: "Abdullah Al Mubarak Al Sabah",
        block: "1",
        street: "115 St",
        building: "14",
        floor: "",
        flat: "",
        phone: "+96560685055",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-07T10:31:34.260Z",
        updatedAt: "2024-10-06T13:52:15.012Z",
      },
      deliverectValidationId: "67190ccb827135858b29b73a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:58:43.043Z",
      estimatedDeliveryTime: "2024-10-23T15:13:41.530Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:45:00.000Z",
      orderId: "67190ccdb7a34cd092235bee",
      createdAt: "2024-10-23T14:48:43.263Z",
      updatedAt: "2024-10-23T14:50:08.318Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fe4",
        productPLU: "2162096-1",
        productDescription:
          "C 48g| P 17g| F 17g\nCrispy fish tenders served with baked fries & tartar sauce.\n\nGood source of Protein, Vitamins and Minerals",
        productName: "Fish N' Chips",
        imageURL:
          "https://resizer.deliverect.com/nIuQ090dCYLyovaNm9H_VJzVg3JpgGl0gzEm4NvBR6s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRmlzaCUyME4nJTIwQ2hpcHMtNjVmNmVjMDViOWNjMGMzODFjY2YwOTQyLnBuZw==",
        productCalories: 413,
        quantity: 1,
        nutritionInfo: {
          carbs: 48,
          protein: 17,
          fat: 17,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208600e",
        productPLU: "2167020-1",
        productDescription:
          "C 44g| P 18g| F 16g\nMultigrain bread with pesto sauce, mozzarella and roasted cherry tomato.\n\nGood source of Protein & B-complex Vitamins.",
        productName: "Mozzarella Pesto Sandwich",
        imageURL:
          "https://resizer.deliverect.com/MTQ1UyiHq3wn_f2uWaa75yYeZS3COeKkS1PRcwo5UP0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvOC02NGJmYTRlZmI4MzczNjAwMTg5MGY0NDEucG5n",
        productCalories: 392,
        quantity: 1,
        nutritionInfo: {
          carbs: 44,
          protein: 18,
          fat: 16,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 600,
    totalFmcCentsPrice: 600,
    totalFiatCentsPriceOriginal: 600,
    totalExternalFiatCentsPrice: 600,
    totalFmcCentsPriceOriginal: 600,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:48:45.363Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:50:21.937Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:52:56.502Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:01:38.372Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:01:38.372Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:12:18.005Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:34:06.309Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:48:45.363Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:48:56.993Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:48:45.363Z",
    updatedAt: "2024-10-23T15:34:06.660Z",
    fleetInfo: null,
  },
  {
    id: "67190cb8b7a34cd0922357fd",
    sid: "241023-384547",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190cb7b7a34cd0922357ed",
    firstName: "Dalal",
    lastName: null,
    phoneNumber: "+96599981190",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6662e484ab54cc8f31355f5d",
    delivery: {
      id: "67190cb7b7a34cd0922357ed",
      customerId: "6662e484ab54cc8f31355f5d",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6662e4f068bedb14f21296ec",
        name: "House",
        lng: 48.00269342958927,
        lat: 29.30072485625037,
        city: "Kuwait",
        area: "Salam",
        block: "1",
        street: "112",
        building: "23",
        floor: "",
        flat: "",
        phone: "+96599981190",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-07T10:46:08.032Z",
        updatedAt: "2024-10-18T08:39:28.225Z",
      },
      deliverectValidationId: "67190cb7cc78686e3fb1996b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:58:23.321Z",
      estimatedDeliveryTime: "2024-10-23T15:13:22.185Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:45:00.000Z",
      orderId: "67190cb8b7a34cd0922357fd",
      createdAt: "2024-10-23T14:48:23.412Z",
      updatedAt: "2024-10-23T14:50:08.320Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd09208600b",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208602e",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 2,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 620,
    totalFmcCentsPrice: 620,
    totalFiatCentsPriceOriginal: 620,
    totalExternalFiatCentsPrice: 620,
    totalFmcCentsPriceOriginal: 620,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:48:24.042Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:50:19.524Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:50:44.660Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:01:46.958Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:01:46.958Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:10:57.259Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:38:44.576Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:48:24.042Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:48:34.394Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:48:24.042Z",
    updatedAt: "2024-10-23T15:38:45.032Z",
    fleetInfo: null,
  },
  {
    id: "67190c13b7a34cd092232b97",
    sid: "241023-208002",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190c13b7a34cd092232b8a",
    firstName: "Rawan AlMajed",
    lastName: null,
    phoneNumber: "+96569967989",
    paymentType: "fmcCents",
    type: "scheduleOrder",
    customerId: "65c111d3914bef1d8301b37b",
    delivery: {
      id: "67190c13b7a34cd092232b8a",
      customerId: "65c111d3914bef1d8301b37b",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f255cd1294dc02ca652f0d",
        name: "Home Alsiddeq",
        lng: 47.98722915351391,
        lat: 29.294094881391906,
        city: "جابر الاحمد",
        area: "Al-Siddeeq",
        block: "2",
        street: "Ghassab Mohammad AlZamanan",
        building: "614",
        floor: "1",
        flat: "1",
        phone: "+96569967989",
        additionalInfo: "Door on the left, first floor",
        isSelected: true,
        createdAt: "2024-09-24T06:01:49.828Z",
        updatedAt: "2024-10-23T13:18:44.514Z",
      },
      deliverectValidationId: "67190c13c581fc4bf17c4724",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:55:39.135Z",
      estimatedDeliveryTime: "2024-10-23T16:00:00.000Z",
      estimatedDeliveryTimeEnd: "2024-10-23T16:06:00.000Z",
      orderId: "67190c13b7a34cd092232b97",
      createdAt: "2024-10-23T14:45:39.232Z",
      updatedAt: "2024-10-23T15:25:06.933Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fea",
        productPLU: "2162123-1",
        productDescription:
          "C 32g | P 23g | F 14g \nGlazed bun, beef pattie, mozzarella cheese slice, mushroom sauce with light mayo ",
        productName: "Mushroom Burger",
        imageURL:
          "https://resizer.deliverect.com/ha2uMGKS1nYV_BDUXrhrnd4juPdkh4UN_AL73RV3EbM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBidXJnZXItNjY5MDY2ODYxMGNhZmJmNDkzMTIzNGQ5LnBuZw==",
        productCalories: 346,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 23,
          fat: 14,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092086014",
        productPLU: "2163018-1",
        productDescription:
          "C 33g| P 7g| F 9g\nRed quinoa mixed with green bell peppers, cucumbers, red radishes, red onions & pomegranate seeds topped with feta cheese, golden raisens & dried cranberries all drizzled in a citrusy vinegratte.",
        productName: "Quinoa Salad",
        imageURL:
          "https://resizer.deliverect.com/e3ZflZAd7M2zwKScDw0f_qLPJ1BkRuzHHLJoW1Jtpfk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcXVpbm9hJTIwc2FsYWQoMSktNjU1NGNjMmNhYjcyYjAwMDJjYjllMzVhLnBuZw==",
        productCalories: 241,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 7,
          fat: 9,
        },
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208605a",
        productPLU: "2202055-1",
        productDescription: "C 0g | P 0g | F 0g ",
        productName: "Celsius Cola",
        imageURL:
          "https://resizer.deliverect.com/BY78vNMNBCBYUUYrwhumRcBaZ__owWkEHv-C0kvAh5w/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2Vsc2l1cyUyMGNvbGEtNjY2YjJlZDVlYTA2OTU5ODUyYzNiYTYxLnBuZw==",
        productCalories: 10,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 570,
    totalFmcCentsPrice: 570,
    totalFiatCentsPriceOriginal: 570,
    totalExternalFiatCentsPrice: 570,
    totalFmcCentsPriceOriginal: 570,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:45:39.876Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T15:25:22.582Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T15:32:06.872Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:32:48.352Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:32:48.352Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:37:20.214Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:07:19.870Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:45:39.876Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:45:51.392Z",
      },
    ],
    cutlery: true,
    timeslot: "19:00 - 19:29",
    createdAt: "2024-10-23T14:45:39.876Z",
    updatedAt: "2024-10-23T16:07:20.224Z",
    fleetInfo: null,
  },
  {
    id: "67190bd1b7a34cd0922324f7",
    sid: "241023-008385",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67190bd0b7a34cd0922324e8",
    firstName: "Zamelat dawam 2",
    lastName: null,
    phoneNumber: "+96590993833",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66cc53e5c41ac94683a6b20a",
    delivery: {
      id: "67190bd0b7a34cd0922324e8",
      customerId: "66cc53e5c41ac94683a6b20a",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66cc9204a48a4cf50fd346ec",
        name: "Home ",
        lng: 48.084585981340815,
        lat: 29.295623779296875,
        city: "Kuwait",
        area: "Salwa",
        block: "9 ",
        street: "4 ",
        building: "Buliding 15 ",
        floor: "",
        flat: "",
        phone: "+96590993833",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-26T14:32:36.850Z",
        updatedAt: "2024-10-19T13:25:51.223Z",
      },
      deliverectValidationId: "67190bd06a3e6800dd9dd159",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:54:32.533Z",
      estimatedDeliveryTime: "2024-10-23T15:09:31.536Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:41:00.000Z",
      orderId: "67190bd1b7a34cd0922324f7",
      createdAt: "2024-10-23T14:44:32.629Z",
      updatedAt: "2024-10-23T14:46:05.695Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd092086756",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "Without mushroom please!! ",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd09208677a",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.2,
          protein: 2.8,
          fat: 8.6,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd09208679f",
        productPLU: "2166010-1",
        productDescription:
          "C 60g| P 11g| F 14g\nBerry oats with chia seeds, flax seeds, raspberry, blueberry, blackberry, berry jam and lotus biscuit.\n\nGood source of Vitamins & Dietary fibre",
        productName: "Triple Berry Oats",
        imageURL:
          "https://resizer.deliverect.com/I1vcxMkKmY29tfnBVwlJ4hYSD37vX8QkNOhoNFzCZWc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NTExNmVhNWIxNjEyMDgzZWJjNmNjNDMuanBn",
        productCalories: 410,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 11,
          fat: 14,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd0920867a4",
        productPLU: "2202032-1",
        productDescription:
          "C 15.6g | P 0.9g | F 0.7g\nGreen apple, pineapple,watermelon & orange",
        productName: "Classic Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/2sV5-NAlUviox1mImQPHbEENE5_b1KyoHVL0t_T2CVE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2xhc3NpYyUyMGZydWl0LTY2NDM3YTM1ZTdlZDJhNDdlMjFhMzFmZC5wbmc=",
        productCalories: 73,
        quantity: 1,
        nutritionInfo: {
          carbs: 15.6,
          protein: 0.9,
          fat: 0.7,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 520,
    totalFmcCentsPrice: 520,
    totalFiatCentsPriceOriginal: 520,
    totalExternalFiatCentsPrice: 520,
    totalFmcCentsPriceOriginal: 520,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:44:33.230Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:46:05.153Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:58:23.410Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:58:34.731Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:58:34.731Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:04:16.543Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:38:17.643Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:44:33.230Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:44:44.621Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:44:33.230Z",
    updatedAt: "2024-10-23T15:38:17.989Z",
    fleetInfo: null,
  },
  {
    id: "67190bb4b7a34cd092232050",
    sid: "241023-601692",
    coupons: [],
    menuId: "6718bc39b7a34cd09208791a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67190bb3b7a34cd092231f8b",
    firstName: "Hala F O",
    lastName: null,
    phoneNumber: "+96550485967",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66a4f338ac4eb4f3eef9b5d7",
    delivery: {
      id: "67190bb3b7a34cd092231f8b",
      customerId: "66a4f338ac4eb4f3eef9b5d7",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: 100,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67190bb3b7a34cd092231f8a",
        name: "manually",
        lng: 0,
        lat: 0,
        city: "هدية",
        area: "هدية",
        block: "5",
        street: "18",
        building: "15",
        floor: null,
        flat: null,
        phone: "+96550485967",
        additionalInfo: null,
        isSelected: false,
        createdAt: "2024-10-23T14:44:03.302Z",
        updatedAt: "2024-10-23T14:44:03.302Z",
      },
      deliverectValidationId: "67190bb20348c4b0ea145fec",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:54:02.737Z",
      estimatedDeliveryTime: "2024-10-23T15:31:01.000Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:41:00.000Z",
      orderId: "67190bb4b7a34cd092232050",
      createdAt: "2024-10-23T14:44:03.302Z",
      updatedAt: "2024-10-23T14:46:06.563Z",
    },
    items: [
      {
        productId: "6718bc39b7a34cd09208799c",
        productPLU: "2162022-1",
        productDescription:
          "C 32g | P 40g | F 21g\nShish tawook with batata harra, sumac white onions and pickled cucumber served with tahini sauce.\n\nGluten free,High in protien,Good source of Vitamin C",
        productName: "Shish Tawook with Batata Harra",
        imageURL:
          "https://resizer.deliverect.com/t8iFrO7JgasiTM_3BgLdmFPccJzHYDm3brRNicOgS5E/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjktNjRjOGJlYWZhNDUwZTYwMDI2ODNkMjhhLnBuZw==",
        productCalories: 477,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 40,
          fat: 21,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: null,
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd092087a0f",
        productPLU: "2202022-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Sprite Zero",
        imageURL:
          "https://resizer.deliverect.com/ZEZdNlPffzmGS3rg7Tvd5hMcV02zZNG7y9fZavC83oI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMSUyMCgxKS02NGM5MTk0M2E0NTBlNjAwMjY4M2QzODEuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: null,
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 0,
    totalExternalFiatCentsPrice: 0,
    totalFmcCentsPriceOriginal: 0,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:44:04.606Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:46:06.737Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:46:21.848Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T15:04:16.623Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T15:04:16.624Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:04:38.470Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:24:10.154Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:44:04.606Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:44:16.123Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-23T14:44:04.606Z",
    updatedAt: "2024-10-23T15:24:10.511Z",
    fleetInfo: null,
  },
  {
    id: "67190b2ab7a34cd0922306d2",
    sid: "241023-539119",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67190b29b7a34cd0922306c0",
    firstName: "Yousef Al Roumi",
    lastName: null,
    phoneNumber: "+96555849696",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "656c400ca3b189708bcd75d1",
    delivery: {
      id: "67190b29b7a34cd0922306c0",
      customerId: "656c400ca3b189708bcd75d1",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "656c40fb521b44fb94a9ce5c",
        name: "Home",
        lng: 47.953327372670174,
        lat: 29.35320414553741,
        city: "Kuwait City",
        area: "Shuwaikh Residential",
        block: "5",
        street: "55",
        building: "15",
        floor: "",
        flat: "",
        phone: "+96555849696",
        additionalInfo: "White House",
        isSelected: true,
        createdAt: "2023-12-03T08:48:59.651Z",
        updatedAt: "2023-12-22T10:42:50.480Z",
      },
      deliverectValidationId: "67190b29e630c246d232da81",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:51:45.824Z",
      estimatedDeliveryTime: "2024-10-23T15:06:44.785Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:38:00.000Z",
      orderId: "67190b2ab7a34cd0922306d2",
      createdAt: "2024-10-23T14:41:45.923Z",
      updatedAt: "2024-10-23T14:43:05.391Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd092087077",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd0920870bf",
        productPLU: "2165028-1",
        productDescription:
          "C 8.2g | P 1.4g | F 7g\nRoasted coconut, coconut powder, whipping cream, cocoa butter, white & milk chocolate",
        productName: "Roasted Coconut Truffle",
        imageURL:
          "https://resizer.deliverect.com/Fsqppf723HLU1qfvol46WqRv2KlRhKmHhGjrowxj0o8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY29jb251dCUyMHRydWZmbGUlMjAtNjY5OTE5OTI0NWU1NDA4NDgxNDQ5MDIwLnBuZw==",
        productCalories: 106,
        quantity: 1,
        nutritionInfo: {
          carbs: 8.2,
          protein: 1.4,
          fat: 7,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 270,
    totalExternalFiatCentsPrice: 270,
    totalFmcCentsPriceOriginal: 270,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:41:46.795Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:43:06.672Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:43:47.542Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:54:16.032Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:54:16.032Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:01:47.289Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:12:18.201Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:41:46.795Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:41:58.240Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:41:46.795Z",
    updatedAt: "2024-10-23T15:12:18.537Z",
    fleetInfo: null,
  },
  {
    id: "67190afab7a34cd09222fe81",
    sid: "241023-413892",
    coupons: [],
    menuId: "6718bc39b7a34cd09208791a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67190afab7a34cd09222fe77",
    firstName: "Omar alhasawi",
    lastName: null,
    phoneNumber: "+96599333896",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65266db41bfa57c5dbf49284",
    delivery: {
      id: "67190afab7a34cd09222fe77",
      customerId: "65266db41bfa57c5dbf49284",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65461a7ef91376d3946ac40d",
        name: "Home",
        lng: 48.06079865759693,
        lat: 29.216293334960938,
        city: "Kuwait",
        area: "Al-Qusour District",
        block: "7",
        street: "9",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96599333896",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-04T10:18:38.876Z",
        updatedAt: "2024-10-21T17:27:45.411Z",
      },
      deliverectValidationId: "67190afa72410ebb08830c48",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:50:58.002Z",
      estimatedDeliveryTime: "2024-10-23T15:05:56.993Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:37:00.000Z",
      orderId: "67190afab7a34cd09222fe81",
      createdAt: "2024-10-23T14:40:58.105Z",
      updatedAt: "2024-10-23T14:42:05.183Z",
    },
    items: [
      {
        productId: "6718bc39b7a34cd0920879cc",
        productPLU: "2162137-1",
        productDescription:
          "C 58g | P 26g | F 15g [%New%]\n\nEgyptian rice topped with chopped steak and mixed veggies, served with roasted sweet potato, sauce, air-fried onion, and garnished with chives, sesame seeds and sumac. ( Includes Coriander )\n\nGood source of Protein & Vitamins",
        productName: "Chopped steak rice bowl",
        imageURL:
          "https://resizer.deliverect.com/_zrZW_wKv-53wc25P7NkxtvjUm6QJfutpW1Mat8BQ0U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hvcHBlZCUyMHN0ZWFrLTY3MTM0ZjkwYmJlZWUwMmFhMDk5ZWI3Zi5wbmc=",
        productCalories: 471,
        quantity: 1,
        nutritionInfo: {
          carbs: 58,
          protein: 26,
          fat: 15,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd0920879cb",
        productPLU: "2162136-1",
        productDescription:
          "C 55g | P 28g | F 17g [%New%]\n\nGrilled chicken served with brown basmati rice, red and white quinoa, roasted sweet potato, mixed salad slaw, cucumber, and drizzled with coleslaw & sesame dressing\n\nGood source of Protein & Vitamin A",
        productName: "Asian chicken bowl",
        imageURL:
          "https://resizer.deliverect.com/ulfP2vI0enUjJIX9PtpeKqCqXdujjxBmnnP22nA6Mhw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYXNpYW4lMjBjaGlja2VuJTIwYm93bCUyMC02NzEzNGYyNjFjNmQ3NGExODRiZjU2NWQucG5n",
        productCalories: 485,
        quantity: 1,
        nutritionInfo: {
          carbs: 55,
          protein: 28,
          fat: 17,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 700,
    totalFmcCentsPrice: 700,
    totalFiatCentsPriceOriginal: 700,
    totalExternalFiatCentsPrice: 700,
    totalFmcCentsPriceOriginal: 700,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:40:58.743Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:42:05.296Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:43:31.461Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:55:50.163Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:55:50.164Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:03:24.333Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:24:58.081Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:40:58.743Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:41:10.180Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:40:58.743Z",
    updatedAt: "2024-10-23T15:24:58.518Z",
    fleetInfo: null,
  },
  {
    id: "67190ad6b7a34cd09222f2ad",
    sid: "241023-862123",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190ad6b7a34cd09222f2a0",
    firstName: "Mohammad Alaiban",
    lastName: null,
    phoneNumber: "+96598886044",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f488518951a675da03ac98",
    delivery: {
      id: "67190ad6b7a34cd09222f2a0",
      customerId: "66f488518951a675da03ac98",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f4895cee3a746f7f15ddf8",
        name: "Home",
        lng: 47.97052318230271,
        lat: 29.312300325673057,
        city: "Kuwait",
        area: "اليرموك",
        block: "1",
        street: "2",
        building: "5",
        floor: "",
        flat: "",
        phone: "+96598886044",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-25T22:06:20.399Z",
        updatedAt: "2024-10-22T14:36:54.815Z",
      },
      deliverectValidationId: "67190ad58ee7d1c256be4fa1",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:50:21.914Z",
      estimatedDeliveryTime: "2024-10-23T15:05:20.885Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:37:00.000Z",
      orderId: "67190ad6b7a34cd09222f2ad",
      createdAt: "2024-10-23T14:40:22.038Z",
      updatedAt: "2024-10-23T14:42:05.158Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fcd",
        productPLU: "2162130-1",
        productDescription:
          "C 40g | P 19g | F 16g\n\nSlider bun, patty, cheddar cheese, iceberg lettuce, white onion, dill pickle chips, mayo ketchup sauce.",
        productName: "FM Sliders",
        imageURL:
          "https://resizer.deliverect.com/gYXzCSrBPdFooHt3S19xzbSh9205wL6ziwlFQHMhLTI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBzbGlkZXIoMSktNjZmNTZmM2ZlNjQyNjQ5MzFhZTYwMDMwLnBuZw==",
        productCalories: 380,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208600f",
        productPLU: "2163022-1",
        productDescription:
          "C 15g | P 2g | F 14g [%New%]\n\nVeggies with fattoush dressing & croutons.\n\nGood source of Vitamins",
        productName: "Mini Fattoush",
        imageURL:
          "https://resizer.deliverect.com/QUcv0CxdaaAXW0GlMNB1GAQ5fahshLaeuOoMIRAFR5c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGZhdHRvdXNoJTIwc2FsYWQtNjcxMzUxNmViYTZjN2U5ZTgzMmVkM2MyLnBuZw==",
        productCalories: 194,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 2,
          fat: 14,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:40:22.784Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:42:07.330Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:49:14.306Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:50:46.964Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:50:46.965Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:53:35.263Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:07:20.104Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:40:22.785Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:40:34.252Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:40:22.785Z",
    updatedAt: "2024-10-23T15:07:20.454Z",
    fleetInfo: null,
  },
  {
    id: "67190a68b7a34cd09222d9df",
    sid: "241023-242007",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190a67b7a34cd09222d9d4",
    firstName: "Sherifa D",
    lastName: null,
    phoneNumber: "+96560644550",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6716b9c6b58bc47308413edd",
    delivery: {
      id: "67190a67b7a34cd09222d9d4",
      customerId: "6716b9c6b58bc47308413edd",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6716ba62b7a34cd092c498df",
        name: "Sideeq",
        lng: 47.99382068216801,
        lat: 29.290570467085054,
        city: "Kuwait",
        area: "Al-Siddeeq",
        block: "4",
        street: "411",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96560644550",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-21T20:32:34.109Z",
        updatedAt: "2024-10-21T20:32:34.109Z",
      },
      deliverectValidationId: "67190a6755ce2b35f2da9bdc",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:48:31.320Z",
      estimatedDeliveryTime: "2024-10-23T15:03:30.069Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:35:00.000Z",
      orderId: "67190a68b7a34cd09222d9df",
      createdAt: "2024-10-23T14:38:31.432Z",
      updatedAt: "2024-10-23T14:40:09.698Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fdf",
        productPLU: "2162110-1",
        productDescription:
          "C 16g | P 39g | F 12g\nChicken Lasagna with zucchini & basil\n\nGood source of Protein, Vitamin A & B12",
        productName: "Zucchini Chicken Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SF1f4yzXFAmy7O5H5pNNhkbFAWle_8S5j0MSAJ6QIzY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGxhc2FnbmEtNjY0MzdhMWQ3YjdlNGViN2M1MjcwN2IwLmpwZw==",
        productCalories: 328,
        quantity: 1,
        nutritionInfo: {
          carbs: 16,
          protein: 39,
          fat: 12,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092086061",
        productPLU: "2202020-1",
        productDescription: "C 0g| P 0g| F 0g\nLight",
        productName: "Coca Cola light",
        imageURL:
          "https://resizer.deliverect.com/ohMqXjy5JVCdObHNif9r9s_l7TLtUsT8RYOrg55pTug/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMyUyMCgxKS02NGM5MTk2MGE3NTU1ZjAwMWYzOWNlNjQuanBn",
        productCalories: 2,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 320,
    totalFmcCentsPrice: 320,
    totalFiatCentsPriceOriginal: 320,
    totalExternalFiatCentsPrice: 320,
    totalFmcCentsPriceOriginal: 320,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:38:32.409Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:40:11.431Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:40:45.159Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:53:04.702Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:53:04.703Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:53:57.981Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:11:32.885Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:38:32.409Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:38:43.812Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:38:32.409Z",
    updatedAt: "2024-10-23T15:11:33.315Z",
    fleetInfo: null,
  },
  {
    id: "67190a57b7a34cd09222d5a4",
    sid: "241023-654699",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67190a56b7a34cd09222d554",
    firstName: "Abdullah AlSamhan",
    lastName: null,
    phoneNumber: "+96566814343",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66d95719aa1645e3a500cafa",
    delivery: {
      id: "67190a56b7a34cd09222d554",
      customerId: "66d95719aa1645e3a500cafa",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66dddeabced6c5247e2b1ee1",
        name: "Home",
        lng: 48.08579631149769,
        lat: 29.272682164167158,
        city: "Kuwait",
        area: "Salwa",
        block: "12",
        street: "2",
        building: "401 in Kuwait finder (29) outside building",
        floor: "Ground",
        flat: "1",
        phone: "+96566814343",
        additionalInfo: "Orange house opposite house 69",
        isSelected: true,
        createdAt: "2024-09-08T17:28:11.125Z",
        updatedAt: "2024-10-23T14:36:45.085Z",
      },
      deliverectValidationId: "67190a56e9cbdcf0e3b3d59d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:48:14.195Z",
      estimatedDeliveryTime: "2024-10-23T15:03:13.185Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:35:00.000Z",
      orderId: "67190a57b7a34cd09222d5a4",
      createdAt: "2024-10-23T14:38:14.284Z",
      updatedAt: "2024-10-23T14:40:07.674Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd092086776",
        productPLU: "2163003-1",
        productDescription:
          "C 36g | P 22g | F 11g\nShredded chicken, mixed slaw, edamame, & crunchy toasted peanut, iceberg lettuce, mandarin, all drizzled with a peanut dressing and topped with sesame seeds with coriander.\n\nGood source of Vitamins A, K & C",
        productName: "Asian Peanut Salad",
        imageURL:
          "https://resizer.deliverect.com/humW3Ra_sNPdK2LTBHq_jLtgrnRK92UjDwqvK9r2I2o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvdGhhaSUyMHBlYW51dCUyMHNhbGFkLTY0YjdiNWI2N2U5MmVhMTFiYjcwZGExMy5wbmc=",
        productCalories: 333,
        quantity: 1,
        nutritionInfo: {
          carbs: 36,
          protein: 22,
          fat: 11,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc19b7a34cd09208682d",
            productPLU: "1906023-1",
            productName: "Extra  Sesame Chicken",
            productDescription: "C 2g| P 11g| F 3g| cal 80| coins 0.5",
            productCalories: 80,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 2,
              protein: 11,
              fat: 3,
            },
          },
        ],
      },
      {
        productId: "6718bc19b7a34cd092086764",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 24,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:38:15.010Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:40:07.303Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:40:22.680Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:58:12.292Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:58:12.292Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:02:54.221Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:28:10.899Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:38:15.010Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:38:26.381Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:38:15.010Z",
    updatedAt: "2024-10-23T15:28:11.239Z",
    fleetInfo: null,
  },
  {
    id: "67190a3cb7a34cd09222cdf4",
    sid: "241023-901801",
    coupons: [],
    menuId: "6718bc39b7a34cd09208791a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67190a3ab7a34cd09222cc1e",
    firstName: "Hala F O",
    lastName: null,
    phoneNumber: "+96550485967",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66a4f338ac4eb4f3eef9b5d7",
    delivery: {
      id: "67190a3ab7a34cd09222cc1e",
      customerId: "66a4f338ac4eb4f3eef9b5d7",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66a546811683a2e7267c4e71",
        name: "هديه ق 5 ش 18 م 15 ",
        lng: 48.089406564831734,
        lat: 29.137855136309437,
        city: "هدية",
        area: "هدية",
        block: "5",
        street: "18",
        building: "15",
        floor: "",
        flat: "",
        phone: "+96550485967",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-27T19:12:01.135Z",
        updatedAt: "2024-10-22T13:27:37.732Z",
      },
      deliverectValidationId: "67190a393946960ae1b9008e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:47:45.855Z",
      estimatedDeliveryTime: "2024-10-23T15:02:42.979Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:34:00.000Z",
      orderId: "67190a3cb7a34cd09222cdf4",
      createdAt: "2024-10-23T14:37:46.228Z",
      updatedAt: "2024-10-23T14:39:06.875Z",
    },
    items: [
      {
        productId: "6718bc39b7a34cd0920879b4",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 24,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd092087a0f",
        productPLU: "2202022-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Sprite Zero",
        imageURL:
          "https://resizer.deliverect.com/ZEZdNlPffzmGS3rg7Tvd5hMcV02zZNG7y9fZavC83oI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMSUyMCgxKS02NGM5MTk0M2E0NTBlNjAwMjY4M2QzODEuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd0920879df",
        productPLU: "2166044-1",
        productDescription: "C 29.6g | P 13g | F 8.2g",
        productName: "PRO CHIPS Spicy Cheese",
        imageURL:
          "https://resizer.deliverect.com/DI3ZryM4jZ6wfY4bMCrM8iN_pJIJi0F2EnLURGnyrDA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNS02NmM4NWU0MmIzOTY1OGViZWUxZGE2MzYucG5n",
        productCalories: 246,
        quantity: 1,
        nutritionInfo: {
          carbs: 29.6,
          protein: 13,
          fat: 8.2,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:37:48.964Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:39:05.860Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:39:18.508Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:45:44.605Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:37:48.964Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:38:00.530Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:37:48.964Z",
    updatedAt: "2024-10-23T14:53:47.647Z",
    fleetInfo: null,
  },
  {
    id: "671909ebb7a34cd09222aef7",
    sid: "241023-428978",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671909ebb7a34cd09222aeb6",
    firstName: " Noha Al Refai",
    lastName: null,
    phoneNumber: "+96597470434",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64e110c570771121d14b7a06",
    delivery: {
      id: "671909ebb7a34cd09222aeb6",
      customerId: "64e110c570771121d14b7a06",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65d4d2ef393070be7ec2c2db",
        name: "Home main door",
        lng: 48.02894555032253,
        lat: 29.30928630217997,
        city: "الجابرية",
        area: "الجابرية",
        block: "Jabriya B9",
        street: "Street 1",
        building: "House 47",
        floor: "",
        flat: "",
        phone: "+96597470434",
        additionalInfo: "White House on the corner",
        isSelected: true,
        createdAt: "2024-02-20T16:27:27.606Z",
        updatedAt: "2024-10-23T14:19:41.247Z",
      },
      deliverectValidationId: "671909eb05f0aa7bf6831581",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:46:27.141Z",
      estimatedDeliveryTime: "2024-10-23T15:01:25.186Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:33:00.000Z",
      orderId: "671909ebb7a34cd09222aef7",
      createdAt: "2024-10-23T14:36:27.333Z",
      updatedAt: "2024-10-23T14:38:05.267Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd0920867b1",
        productPLU: "2165020-1",
        productDescription:
          "C 10g | P 1g | F 6g\nPecan, caramel, milk chocolate",
        productName: "Pecan Turtle",
        imageURL:
          "https://resizer.deliverect.com/M_C4q-ZLQuwipzQEg6XeH4Pvq602HMW3vxgoWZ8KB3k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVjYW4lMjB0dXJ0bGUtNjY2MWNlNDc1MDRlYzgwNTk0NmU0YTkzLnBuZw==",
        productCalories: 100,
        quantity: 2,
        nutritionInfo: {
          carbs: 10,
          protein: 1,
          fat: 6,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd09208678c",
        productPLU: "2166031-1",
        productDescription:
          "C 32g| P 12g| F 12g\nBaked sweet potato fries with mayo barbeque sauce.",
        productName: "Baked Sweet Potato Fries",
        imageURL:
          "https://resizer.deliverect.com/EexbDtzbKG0sVOjjNXiIlT_MIN2doyc2NlpJu2_T6nc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3dlZXQlMjBwb3RhdG8lMjBmcmllcygxKS02NTU0OGY3NzYyYWIwZTAwMWYzYTczNzEucG5n",
        productCalories: 244,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 12,
          fat: 12,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086778",
        productPLU: "2166026-1",
        productDescription:
          "C 13g| P 6g| F 9g\nKale, cabbage, carrots, onions, orange slices & cashews topped with sesame seeds & drizzled with garlic ginger peanut dressing.\n\nGOOD SOURCE OF VITAMIN & VITAMIN C",
        productName: "Mini Thai Slaw",
        imageURL:
          "https://resizer.deliverect.com/89E9zE4JuS-ammKNFUesPBA8SiE1FYMyZUmczLAr-Gg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMHRoYWklMjBzbGF3LTY1MDQ5NDYxOGVjNDM1MDAxYTRjMTQ2My5qcGc=",
        productCalories: 157,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 6,
          fat: 9,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086752",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd09208675b",
        productPLU: "2167025-1",
        productDescription:
          "C 29g | P 31g | F 12g\nTortilla with chicken breast, Mexican sauce, cheddar cheese, red & green capsicum.\n\nGood source of Protein & Vitamin C",
        productName: "Mexican wrap",
        imageURL:
          "https://resizer.deliverect.com/rAzOkou3Hx2u334uLtcNGO0XSk3R-noyrtj8ts7K71I/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTWV4aWNhbiUyMHdyYXAtNjZjODU4YzEyZTkyNDE5N2YyNWEzYzc4LnBuZw==",
        productCalories: 348,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 31,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 860,
    totalFmcCentsPrice: 860,
    totalFiatCentsPriceOriginal: 860,
    totalExternalFiatCentsPrice: 860,
    totalFmcCentsPriceOriginal: 860,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:36:27.984Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:38:04.991Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:40:23.930Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:53:00.622Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:53:00.625Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:53:24.075Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:01:47.373Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:36:27.984Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:36:39.537Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:36:27.984Z",
    updatedAt: "2024-10-23T15:01:47.706Z",
    fleetInfo: null,
  },
  {
    id: "671909cab7a34cd09222a636",
    sid: "241023-128361",
    coupons: [],
    menuId: "6718bc39b7a34cd09208791a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671909c9b7a34cd09222a62c",
    firstName: "فاطمة أحمد الراشد",
    lastName: null,
    phoneNumber: "+96594040963",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f3d8af7f0597f029e6a61e",
    delivery: {
      id: "671909c9b7a34cd09222a62c",
      customerId: "66f3d8af7f0597f029e6a61e",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f3da8e1294dc02ca9a786e",
        name: "المنزل",
        lng: 48.05839788168669,
        lat: 29.22290990282339,
        city: "Kuwait",
        area: "العدان",
        block: "2",
        street: "84",
        building: "9",
        floor: "",
        flat: "",
        phone: "+96594040963",
        additionalInfo: "الباب الابيض ",
        isSelected: true,
        createdAt: "2024-09-25T09:40:30.418Z",
        updatedAt: "2024-10-11T18:38:50.831Z",
      },
      deliverectValidationId: "671909c9dda2cbde33f4e695",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:45:53.768Z",
      estimatedDeliveryTime: "2024-10-23T15:00:52.708Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:32:00.000Z",
      orderId: "671909cab7a34cd09222a636",
      createdAt: "2024-10-23T14:35:53.886Z",
      updatedAt: "2024-10-23T14:37:05.316Z",
    },
    items: [
      {
        productId: "6718bc39b7a34cd0920879ee",
        productPLU: "2166006-1",
        productDescription:
          "C 67g| P 8g| F 9g\nCinnamon chia whole oats with candied peanuts, peanut biscuit and peanut butter & lotus spread.\n\nGood source of Vitamins & Dietary fibre",
        productName: "Cinnamon Oats",
        imageURL:
          "https://resizer.deliverect.com/mJ-Xb-5qUQiDDKJOr4MGqGbDWxCn3gIddYpHwTQNiZo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NTExNmU5NTNmNWMzYTdmNDZlNGM2NDUuanBn",
        productCalories: 381,
        quantity: 1,
        nutritionInfo: {
          carbs: 67,
          protein: 8,
          fat: 9,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd0920879e6",
        productPLU: "2166036-1",
        productDescription:
          "C 30g | P 4g | F 9g \nTortilla chips,  guacamole & salsa",
        productName: "Salsa & Guac Nachos",
        imageURL:
          "https://resizer.deliverect.com/0WMmSGxzjSPDoEdAbH-9LvVLhnBDISj1o9ayRRQBqWA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU2Fsc2ElMjAlMjYlMjBndWFjJTIwbmFjaG9zLTY2NjFjZGE0NDliZDAxOWI4YmRhYWNkNS5wbmc=",
        productCalories: 217,
        quantity: 1,
        nutritionInfo: {
          carbs: 30,
          protein: 4,
          fat: 9,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:35:54.676Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:37:05.755Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:37:25.395Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:43:36.661Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:43:36.661Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T15:03:13.116Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:31:00.185Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:35:54.676Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:36:06.306Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:35:54.676Z",
    updatedAt: "2024-10-23T15:31:00.517Z",
    fleetInfo: null,
  },
  {
    id: "6719098db7a34cd092228c21",
    sid: "241023-396854",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719098db7a34cd092228c17",
    firstName: "Ahmed alkhaled",
    lastName: null,
    phoneNumber: "+96599299922",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6649df6f69f370da27dbf68c",
    delivery: {
      id: "6719098db7a34cd092228c17",
      customerId: "6649df6f69f370da27dbf68c",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "664a1803e7d7ea1c4c6ee051",
        name: "Penthouse ",
        lng: 47.993767373263836,
        lat: 29.30633083307349,
        city: "العارضية",
        area: "Qortuba",
        block: "2",
        street: "1 St. 3 Ln",
        building: "10",
        floor: "3",
        flat: "7",
        phone: "+96599299922",
        additionalInfo: "3rd floor ",
        isSelected: true,
        createdAt: "2024-05-19T15:17:23.860Z",
        updatedAt: "2024-10-22T16:20:46.377Z",
      },
      deliverectValidationId: "6719098cb87b85dcf6ac8628",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:44:52.956Z",
      estimatedDeliveryTime: "2024-10-23T14:59:51.974Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:31:00.000Z",
      orderId: "6719098db7a34cd092228c21",
      createdAt: "2024-10-23T14:34:53.079Z",
      updatedAt: "2024-10-23T14:36:07.390Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fde",
        productPLU: "2162113-1",
        productDescription:
          "C 67g | P 21g | F 25g[%Popular%]\nCheeseburger with baked sweet potato fries and bbq sauce",
        productName: "Cheeseburger & fries",
        imageURL:
          "https://resizer.deliverect.com/7r3h13J-z3i_dUkPi81fBjeHfz7KOpOpG45voMIj2xA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hlZXNlYnVyZ2VyJTIwY29tYm8tNjcxMzU3NjhmNTg1MzQ0Mzg3MTVlNGIyLnBuZw==",
        productCalories: 577,
        quantity: 1,
        nutritionInfo: {
          carbs: 67,
          protein: 21,
          fat: 25,
        },
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 370,
    totalExternalFiatCentsPrice: 370,
    totalFmcCentsPriceOriginal: 370,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:34:53.808Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:36:35.661Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:37:38.395Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:52:20.741Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:52:20.741Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:56:48.500Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:34:17.688Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:34:53.808Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:35:05.215Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:34:53.808Z",
    updatedAt: "2024-10-23T15:34:18.718Z",
    fleetInfo: null,
  },
  {
    id: "6719097db7a34cd09222856e",
    sid: "241023-741363",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719097cb7a34cd09222855c",
    firstName: "Esam AlAdwani",
    lastName: null,
    phoneNumber: "+96567079977",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c3bdc875a6aa442d0effa5",
    delivery: {
      id: "6719097cb7a34cd09222855c",
      customerId: "64c3bdc875a6aa442d0effa5",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6637cbe8a2ffa46fcd94cb5b",
        name: "Home",
        lng: 47.9824236407876,
        lat: 29.313126924933357,
        city: "Kuwait",
        area: "Qortuba",
        block: "4",
        street: "4",
        building: "13",
        floor: "",
        flat: "",
        phone: "+96567079977",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-05T18:11:52.373Z",
        updatedAt: "2024-10-23T14:33:34.483Z",
      },
      deliverectValidationId: "6719097c1cb32e3bce2070e3",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:44:36.260Z",
      estimatedDeliveryTime: "2024-10-23T14:59:35.229Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:31:00.000Z",
      orderId: "6719097db7a34cd09222856e",
      createdAt: "2024-10-23T14:34:36.361Z",
      updatedAt: "2024-10-23T14:36:10.266Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd09208600b",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092085ffd",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:34:37.128Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:36:36.658Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:37:29.210Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:52:31.591Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:52:31.592Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:56:36.747Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:23:15.822Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:34:37.128Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:34:49.007Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:34:37.128Z",
    updatedAt: "2024-10-23T15:23:16.181Z",
    fleetInfo: null,
  },
  {
    id: "67190969b7a34cd092227c26",
    sid: "241023-778566",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190968b7a34cd092227c10",
    firstName: "Shahad A",
    lastName: null,
    phoneNumber: "+96555352900",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "659cfb1c2d5e4e99268dc6db",
    delivery: {
      id: "67190968b7a34cd092227c10",
      customerId: "659cfb1c2d5e4e99268dc6db",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66635ef5f4d22759502e00df",
        name: "Home",
        lng: 47.9971332103014,
        lat: 29.308697509660554,
        city: "Kuwait",
        area: "Qortuba",
        block: "2",
        street: "Street 1, Avenue 3",
        building: "76",
        floor: "",
        flat: "",
        phone: "+96555352900",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-07T19:26:45.219Z",
        updatedAt: "2024-06-07T19:26:45.219Z",
      },
      deliverectValidationId: "671909688da25298e7ec0531",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:44:16.743Z",
      estimatedDeliveryTime: "2024-10-23T14:59:15.734Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:31:00.000Z",
      orderId: "67190969b7a34cd092227c26",
      createdAt: "2024-10-23T14:34:16.844Z",
      updatedAt: "2024-10-23T14:36:05.938Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085ff5",
        productPLU: "2162035-1",
        productDescription:
          "C 50g| P 32g| F 15g\nBlack pepper beef with veggies served with egyptian rice.\n\nGood source of Iron & B-12 Vitamins",
        productName: "Black Pepper Beef",
        imageURL:
          "https://resizer.deliverect.com/FBy7BxqQqLr64Ae34YvyRhL0jHWvib_v5F9riYKNFsc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNzYtNjRiN2IwNWM5Mjc2OTYwMDE5NmViYjFjLnBuZw==",
        productCalories: 474,
        quantity: 1,
        nutritionInfo: {
          carbs: 50,
          protein: 32,
          fat: 15,
        },
        fmcCentsPrice: 330,
        fiatCentsPrice: 330,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092085ffa",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 560,
    totalFmcCentsPrice: 560,
    totalFiatCentsPriceOriginal: 560,
    totalExternalFiatCentsPrice: 560,
    totalFmcCentsPriceOriginal: 560,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:34:17.428Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:36:30.873Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:37:40.013Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:52:09.885Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:52:09.886Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:57:01.037Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:28:22.102Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:34:17.428Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:34:37.802Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:34:17.428Z",
    updatedAt: "2024-10-23T15:28:22.451Z",
    fleetInfo: null,
  },
  {
    id: "67190967b7a34cd092227bde",
    sid: "241023-697705",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190966b7a34cd092227b06",
    firstName: "Abdulaziz ",
    lastName: null,
    phoneNumber: "+96599630454",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64e62e4570771121d14e64ff",
    delivery: {
      id: "67190966b7a34cd092227b06",
      customerId: "64e62e4570771121d14e64ff",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64ed970498e65fe43e53fffa",
        name: "Hose",
        lng: 48.01026954871494,
        lat: 29.27734375,
        city: "Zahra",
        area: "Zahra",
        block: "1",
        street: "122 St",
        building: "14",
        floor: "1",
        flat: "",
        phone: "+96599630454",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-29T06:58:12.714Z",
        updatedAt: "2024-10-23T14:28:35.654Z",
      },
      deliverectValidationId: "67190965b87b85dcf6ac7f03",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:44:13.316Z",
      estimatedDeliveryTime: "2024-10-23T14:59:11.995Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:31:00.000Z",
      orderId: "67190967b7a34cd092227bde",
      createdAt: "2024-10-23T14:34:14.371Z",
      updatedAt: "2024-10-23T14:36:04.413Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085ff2",
        productPLU: "2162085-1",
        productDescription:
          "C 15g| P 45g| F 15g\nGrilled chicken served with mashed potatoes, blanched broccoli, cauliflower & carrots, served with a lemon sauce garnished with a slice of lemon and parsley.\n\nGood source of Protein",
        productName: "Grilled Lemon Chicken",
        imageURL:
          "https://resizer.deliverect.com/n9S1sQV2m-69QdAwmCpIpZWcf6LGGUpyB0bFdTHRC1U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvR3JpbGxlZCUyMGxlbW9uJTIwY2hpY2tlbigxKS02NTU0OTA2MWZhNTNkZDAwMWVlOTQ2N2IucG5n",
        productCalories: 375,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 45,
          fat: 15,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "cook the chicken well-done ",
        subItems: [
          {
            productId: "6718bc14b7a34cd0920860a9",
            productPLU: "1906046-1",
            productName: "Extra  Grilled Chicken",
            productDescription: "C 0.4g| P 42g| F 4g| cal 208| coins 1.3",
            productCalories: 208,
            quantity: 1,
            fmcCentsPrice: 130,
            fiatCentsPrice: 130,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 0.4,
              protein: 42,
              fat: 4,
            },
          },
        ],
      },
      {
        productId: "6718bc14b7a34cd092086028",
        productPLU: "2150014-1",
        productDescription: "C 2g| P 30g| F 6g\n",
        productName: "Chicken Shawarma(100GM)",
        imageURL:
          "https://resizer.deliverect.com/5s0dpyz1hk8m1gvg8YGrM7u6yPuJzcNpjKxdUNjSi6g/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMHNoYXdhcm1hJTIwKDEpLTY0ZDc1YjZhNjg1NDg1MDAyNzQwZTNmNy5qcGc=",
        productCalories: 182,
        quantity: 1,
        nutritionInfo: {
          carbs: 2,
          protein: 30,
          fat: 6,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 520,
    totalFmcCentsPrice: 520,
    totalFiatCentsPriceOriginal: 520,
    totalExternalFiatCentsPrice: 520,
    totalFmcCentsPriceOriginal: 520,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:34:15.484Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:36:13.266Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:37:31.106Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:51:18.150Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:51:18.150Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:51:41.199Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:03:56.007Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:34:15.484Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:34:26.912Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:34:15.484Z",
    updatedAt: "2024-10-23T15:03:56.349Z",
    fleetInfo: null,
  },
  {
    id: "6719093bb7a34cd092226263",
    sid: "241023-540784",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719093ab7a34cd09222616b",
    firstName: "Muneera AlJabri",
    lastName: null,
    phoneNumber: "+96596677788",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64cf829ef119a7788c98c5da",
    delivery: {
      id: "6719093ab7a34cd09222616b",
      customerId: "64cf829ef119a7788c98c5da",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65dd9588293bdc4801b1ada0",
        name: "home 2 ",
        lng: 47.89169657975435,
        lat: 29.304516979524983,
        city: "Andalous",
        area: "Andalous",
        block: "12",
        street: "19",
        building: "8",
        floor: "",
        flat: "",
        phone: "+96596677788",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-02-27T07:55:52.077Z",
        updatedAt: "2024-10-07T16:16:35.782Z",
      },
      deliverectValidationId: "6719093a7b41664b99093ebe",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:43:30.238Z",
      estimatedDeliveryTime: "2024-10-23T14:58:29.220Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:30:00.000Z",
      orderId: "6719093bb7a34cd092226263",
      createdAt: "2024-10-23T14:33:30.365Z",
      updatedAt: "2024-10-23T14:35:08.488Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fd8",
        productPLU: "2162117-1",
        productDescription:
          "C 60g | P 22g | F 15g\nTomato sauce, cheese & oregano.",
        productName: "Classic Margherita Pizza",
        imageURL:
          "https://resizer.deliverect.com/gXmec37h0lqeQkrMSIQRiRdHLwG9kVK9Ukz9m_Kw930/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWFyZ2hhcml0YS02NjZiMzAxMWQ2NmMzMDFhNDE0NmYxNTMucG5n",
        productCalories: 463,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 22,
          fat: 15,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:33:31.511Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:35:14.298Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:36:39.369Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:52:01.495Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:52:01.495Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:55:48.730Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:19:30.206Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:33:31.511Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:33:43.030Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:33:31.511Z",
    updatedAt: "2024-10-23T15:19:30.540Z",
    fleetInfo: null,
  },
  {
    id: "6719091cb7a34cd09222594d",
    sid: "241023-026250",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6719091ab7a34cd09222586d",
    firstName: "Thamer Alebrahim",
    lastName: null,
    phoneNumber: "+96597903366",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64f34c121324bbcd6480a95a",
    delivery: {
      id: "6719091ab7a34cd09222586d",
      customerId: "64f34c121324bbcd6480a95a",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65214159f3f5a7e1da0d1fbd",
        name: "Sweet Home",
        lng: 48.07625820860267,
        lat: 29.275318909870755,
        city: "Kuwait",
        area: "Mishref",
        block: "3",
        street: "1",
        building: "8",
        floor: "",
        flat: "",
        phone: "+96597903366",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-07T11:30:33.417Z",
        updatedAt: "2024-09-04T09:41:06.912Z",
      },
      deliverectValidationId: "6719091a0348c4b0ea135cb1",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:42:58.632Z",
      estimatedDeliveryTime: "2024-10-23T14:57:57.267Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:30:00.000Z",
      orderId: "6719091cb7a34cd09222594d",
      createdAt: "2024-10-23T14:32:58.821Z",
      updatedAt: "2024-10-23T14:35:06.569Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208675d",
        productPLU: "2167022-1",
        productDescription:
          "C 29g | P 21g | F 16g\nSpicy chicken shawarma with spring onions, pomegranates, parsley, pickled cucumbers & spicy sauce wrapped in tortilla. \n\nGood source of Protein",
        productName: "Spicy Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/7iczQVW8ZJbNN179Yvo32X8zQsGcTbj5NwVYyyacwCk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU0hBV0FSTUlBKDEpLTY2NDRiYzU4M2NiZjdhMzY3ZmI0YTI1ZC5qcGc=",
        productCalories: 344,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 21,
          fat: 16,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086793",
        productPLU: "2166040-1",
        productDescription: "C 22g | P 13g | F 5.6g",
        productName: "PROPUFFS Chili Lemon",
        imageURL:
          "https://resizer.deliverect.com/tdSwmeq79dgfjvNGOk-dkicu6FKBe5l8mtdGTYVaDJo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjAzLTY2YjUzNjA5NDAxOTZkZGUyZjg5MjliNS5wbmc=",
        productCalories: 190,
        quantity: 1,
        nutritionInfo: {
          carbs: 22,
          protein: 13,
          fat: 5.6,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd0920867a2",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 400,
    totalExternalFiatCentsPrice: 400,
    totalFmcCentsPriceOriginal: 400,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:33:00.502Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:35:06.341Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:36:59.199Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:50:30.167Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:50:30.167Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:50:44.707Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:11:55.709Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:33:00.502Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:33:11.954Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:33:00.502Z",
    updatedAt: "2024-10-23T15:11:56.051Z",
    fleetInfo: null,
  },
  {
    id: "671908eab7a34cd0922243ce",
    sid: "241023-147266",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671908e9b7a34cd0922243bb",
    firstName: "Aysha Alnafisi",
    lastName: null,
    phoneNumber: "+96597980003",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66cf54ebb3b61346bdc687a6",
    delivery: {
      id: "671908e9b7a34cd0922243bb",
      customerId: "66cf54ebb3b61346bdc687a6",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66d452b1a42e9ac814793385",
        name: "Kaifan home",
        lng: 47.95487182214856,
        lat: 29.339048491513896,
        city: "Kuwait",
        area: "Kaifan",
        block: "5",
        street: "Al-Nabigha St",
        building: "24",
        floor: "",
        flat: "",
        phone: "+96597980003",
        additionalInfo: "Corner house",
        isSelected: true,
        createdAt: "2024-09-01T11:40:33.911Z",
        updatedAt: "2024-10-23T14:28:52.981Z",
      },
      deliverectValidationId: "671908e93946960ae1b88f01",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:42:09.445Z",
      estimatedDeliveryTime: "2024-10-23T14:57:08.081Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:29:00.000Z",
      orderId: "671908eab7a34cd0922243ce",
      createdAt: "2024-10-23T14:32:09.639Z",
      updatedAt: "2024-10-23T14:34:05.529Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd0920870c6",
        productPLU: "2202055-1",
        productDescription: "C 0g | P 0g | F 0g ",
        productName: "Celsius Cola",
        imageURL:
          "https://resizer.deliverect.com/BY78vNMNBCBYUUYrwhumRcBaZ__owWkEHv-C0kvAh5w/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2Vsc2l1cyUyMGNvbGEtNjY2YjJlZDVlYTA2OTU5ODUyYzNiYTYxLnBuZw==",
        productCalories: 10,
        quantity: 3,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd0920870b6",
        productPLU: "2202034-1",
        productDescription: "C 17.1g | P 1.9g | F 0.6g\nPomelo & Pomegranate",
        productName: "Seasonal Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/XTwC1dVyhg5--YDaNmhMWBtNNZpa3e6lzIaYdRmZhDA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2Vhc29uYWwlMjBmcnVpdCUyMHNhbGFkLTY2NDFkYmY5YWIxMzE0ZDg1Njc3OGRhNS5wbmc=",
        productCalories: 82,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.1,
          protein: 1.9,
          fat: 0.6,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd0920870b1",
        productPLU: "2166008-1",
        productDescription:
          "C 30g| P 13g| F 16g\nGreek yogurt with peanut butter, raspberry, blueberry, blackberry topped with honey and granola.\n\nGood source of Vitamins",
        productName: "Berry Parfait",
        imageURL:
          "https://resizer.deliverect.com/0p2ShlK1ih7A2WUCAl2RWW19NH0UKIi5XafIY_FXOQc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NTExNmVkYTVkMWVjODc5MjQ0NDE3N2UuanBn",
        productCalories: 316,
        quantity: 1,
        nutritionInfo: {
          carbs: 30,
          protein: 13,
          fat: 16,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "Separate berry from yougurt separate yogurt and granola",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd0920870b0",
        productPLU: "2166009-1",
        productDescription:
          "C 22g| P 11g| F 13g\nMango greek yogurt with chia pudding topped with mangoes.\n\nGood source oF Vitamin A",
        productName: "Mango Greek Yogurt",
        imageURL:
          "https://resizer.deliverect.com/xGontUyo2rWspjDzY7LiTodlo5Gzmv5FuI0NOV_lXRI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNS02NTExNmVjYTM5NGU3ODMxZTFiZDM3MWUuanBn",
        productCalories: 249,
        quantity: 1,
        nutritionInfo: {
          carbs: 22,
          protein: 11,
          fat: 13,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd0920870ab",
        productPLU: "2166017-1",
        productDescription:
          "C 16g| P 8g| F 11g\nCarrots, cucumber, red & yellow capsicum, celery, purple & green endives with a hummus dip",
        productName: "Veggie Crudites",
        imageURL:
          "https://resizer.deliverect.com/I3FIyxNKPcOIzVfjKga2oVRpt99xbhQflVKJw27qrWM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjA5Xy02NTA4MzM1ZjM5NGU3ODMxZTFiZDMyMDYucG5n",
        productCalories: 195,
        quantity: 1,
        nutritionInfo: {
          carbs: 16,
          protein: 8,
          fat: 11,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd0920870d8",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        nutritionInfo: {
          carbs: 5,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 1100,
    totalFmcCentsPrice: 1100,
    totalFiatCentsPriceOriginal: 1100,
    totalExternalFiatCentsPrice: 1100,
    totalFmcCentsPriceOriginal: 1100,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:32:10.639Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:34:07.641Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:35:05.777Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:38:46.643Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:38:46.643Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:55:16.079Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:07:43.955Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:32:10.639Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:32:22.245Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-23T14:32:10.639Z",
    updatedAt: "2024-10-23T15:07:44.293Z",
    fleetInfo: null,
  },
  {
    id: "671907d1b7a34cd09221ee3f",
    sid: "241023-301142",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671907d0b7a34cd09221ec0a",
    firstName: "Ahmad",
    lastName: null,
    phoneNumber: "+96599449338",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64f57f481324bbcd6482560c",
    delivery: {
      id: "671907d0b7a34cd09221ec0a",
      customerId: "64f57f481324bbcd6482560c",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "652541d3289c08cbdb527f45",
        name: "Home ",
        lng: 47.99348305910826,
        lat: 29.30567299317968,
        city: "العارضية",
        area: "Qortuba",
        block: "2",
        street: "St 7",
        building: "House 11",
        floor: "",
        flat: "",
        phone: "+96599449338",
        additionalInfo: "White House black gate next to Italian embassy ",
        isSelected: true,
        createdAt: "2023-10-10T12:21:39.356Z",
        updatedAt: "2024-10-23T14:23:48.317Z",
      },
      deliverectValidationId: "671907d0e9cbdcf0e3b2db6f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:37:28.054Z",
      estimatedDeliveryTime: "2024-10-23T14:52:27.018Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:24:00.000Z",
      orderId: "671907d1b7a34cd09221ee3f",
      createdAt: "2024-10-23T14:27:28.163Z",
      updatedAt: "2024-10-23T14:29:05.099Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092086025",
        productPLU: "2162066-1",
        productDescription:
          "C 68g| P 39g| F 20g\nBeef shawarma served with egyptian rice, baked tomato drizzled with our shawarma sauce, cucumber pickle and sumac onion.\n\nGood source oF Vitamin B12",
        productName: "Beef Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/LMPlnG_aus0s_JuAVcljRBmuvv3t6907i0cmIgWlcb0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjYtNjRiN2I4MjMwZTYwOTUwMDE4YzUwNTA4LnBuZw==",
        productCalories: 608,
        quantity: 1,
        nutritionInfo: {
          carbs: 68,
          protein: 39,
          fat: 20,
        },
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208602e",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208602f",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 510,
    totalFmcCentsPrice: 510,
    totalFiatCentsPriceOriginal: 510,
    totalExternalFiatCentsPrice: 510,
    totalFmcCentsPriceOriginal: 510,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:27:29.869Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:29:08.063Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:31:49.394Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:41:28.812Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:41:28.813Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:56:25.572Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:20:30.228Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:27:29.869Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:27:42.324Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:27:29.869Z",
    updatedAt: "2024-10-23T15:20:30.573Z",
    fleetInfo: null,
  },
  {
    id: "671907a3b7a34cd09221e059",
    sid: "241023-669179",
    coupons: [],
    menuId: "6718bc39b7a34cd09208791a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671907a2b7a34cd09221e047",
    firstName: "Faisal",
    lastName: null,
    phoneNumber: "+96599560787",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66c47e702a6487838c0194ef",
    delivery: {
      id: "671907a2b7a34cd09221e047",
      customerId: "66c47e702a6487838c0194ef",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66c47f49a899ab7ba105a413",
        name: "Home",
        lng: 48.08369480073452,
        lat: 29.184782049530046,
        city: "Kuwait",
        area: "Mubarak Al-Kabeer",
        block: "6",
        street: "22",
        building: "19",
        floor: "",
        flat: "",
        phone: "+96599560787",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-20T11:34:33.763Z",
        updatedAt: "2024-10-23T14:25:39.763Z",
      },
      deliverectValidationId: "671907a1b87b85dcf6abf749",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:36:41.981Z",
      estimatedDeliveryTime: "2024-10-23T14:51:39.799Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:23:00.000Z",
      orderId: "671907a3b7a34cd09221e059",
      createdAt: "2024-10-23T14:26:42.241Z",
      updatedAt: "2024-10-23T14:28:06.555Z",
    },
    items: [
      {
        productId: "6718bc39b7a34cd0920879a9",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd092087996",
        productPLU: "2162123-1",
        productDescription:
          "C 32g | P 23g | F 14g \nGlazed bun, beef pattie, mozzarella cheese slice, mushroom sauce with light mayo ",
        productName: "Mushroom Burger",
        imageURL:
          "https://resizer.deliverect.com/ha2uMGKS1nYV_BDUXrhrnd4juPdkh4UN_AL73RV3EbM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBidXJnZXItNjY5MDY2ODYxMGNhZmJmNDkzMTIzNGQ5LnBuZw==",
        productCalories: 346,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 23,
          fat: 14,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd09208799a",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd0920879d5",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd0920879db",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 770,
    totalFmcCentsPrice: 770,
    totalFiatCentsPriceOriginal: 770,
    totalExternalFiatCentsPrice: 770,
    totalFmcCentsPriceOriginal: 770,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:26:43.146Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:28:06.037Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:28:23.577Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:43:47.655Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:43:47.655Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:44:47.528Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:04:53.539Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:26:43.146Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:26:54.689Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:26:43.146Z",
    updatedAt: "2024-10-23T15:04:54.440Z",
    fleetInfo: null,
  },
  {
    id: "67190749b7a34cd09221c8c5",
    sid: "241023-571016",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67190749b7a34cd09221c8b8",
    firstName: "Dalal alsaleh",
    lastName: null,
    phoneNumber: "+96598700090",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65155c55c6da5aa4aee0c5a2",
    delivery: {
      id: "67190749b7a34cd09221c8b8",
      customerId: "65155c55c6da5aa4aee0c5a2",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65240124f3f5a7e1da1c754e",
        name: "Home",
        lng: 47.98187278211117,
        lat: 29.335780639044046,
        city: "Kuwait",
        area: "Faiha",
        block: "9",
        street: "98 St",
        building: "19",
        floor: "",
        flat: "",
        phone: "+96598700090",
        additionalInfo: "Beige house on corner",
        isSelected: true,
        createdAt: "2023-10-09T13:33:24.744Z",
        updatedAt: "2024-10-23T14:21:36.275Z",
      },
      deliverectValidationId: "671907498ee7d1c256bc4c28",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:35:13.044Z",
      estimatedDeliveryTime: "2024-10-23T14:50:11.831Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:22:00.000Z",
      orderId: "67190749b7a34cd09221c8c5",
      createdAt: "2024-10-23T14:25:13.163Z",
      updatedAt: "2024-10-23T14:27:04.867Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd092087037",
        productPLU: "2162132-1",
        productDescription:
          "C 25g | P 26g | F 21g [%New%]\n\nBeef with red & green capsicum, onions, cauliflower, tomato sauce mix with cauliflower dill rice . \n\nGood source of protein & vitamins",
        productName: "Beef Sajiyeh",
        imageURL:
          "https://resizer.deliverect.com/fA4Fn4615a1ZQrXrniLgeB7arSPTloEzAMzGmcaJlMY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQmVlZiUyMHNhaml5ZWgtNjcwNzc0MGVlNjQyNjQ5MzFhZTY2ZWU0LnBuZw==",
        productCalories: 393,
        quantity: 1,
        nutritionInfo: {
          carbs: 25,
          protein: 26,
          fat: 21,
        },
        fmcCentsPrice: 375,
        fiatCentsPrice: 375,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd09208708a",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.2,
          protein: 2.8,
          fat: 8.6,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd0920870c2",
        productPLU: "2165003-1",
        productDescription: "C 12g | P 2g | F 9g\n",
        productName: "Peanut Bites",
        imageURL:
          "https://resizer.deliverect.com/S2e9SlQ_6ewwX1Z-Pg_RtXB9e4OxquLa6-xOxnwoCJM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTMtNjRiN2JjNmFkMGE4N2MwMDFmZTYwZjBhLnBuZw==",
        productCalories: 139,
        quantity: 1,
        nutritionInfo: {
          carbs: 12,
          protein: 2,
          fat: 9,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 525,
    totalFmcCentsPrice: 525,
    totalFiatCentsPriceOriginal: 525,
    totalExternalFiatCentsPrice: 525,
    totalFmcCentsPriceOriginal: 525,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:25:13.751Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:27:07.125Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:27:35.693Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:33:19.317Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:33:19.317Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:34:26.667Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:53:35.498Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:25:13.751Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:25:25.167Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:25:13.751Z",
    updatedAt: "2024-10-23T14:53:35.833Z",
    fleetInfo: null,
  },
  {
    id: "67190719b7a34cd09221bf62",
    sid: "241023-179853",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190719b7a34cd09221bf51",
    firstName: "Shirin Abdul Rehmann ",
    lastName: null,
    phoneNumber: "+96596058824",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6631f22481372496ff746fce",
    delivery: {
      id: "67190719b7a34cd09221bf51",
      customerId: "6631f22481372496ff746fce",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66c4f788a899ab7ba120b8e1",
        name: "Home",
        lng: 48.0263955,
        lat: 29.2671595,
        city: "Kuwait",
        area: "Shuhada",
        block: "3",
        street: "308 St",
        building: "House 24",
        floor: "",
        flat: "",
        phone: "+96596058824",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-20T20:07:36.771Z",
        updatedAt: "2024-10-23T14:23:19.313Z",
      },
      deliverectValidationId: "671907180348c4b0ea129057",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:34:24.921Z",
      estimatedDeliveryTime: "2024-10-23T14:49:23.814Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:21:00.000Z",
      orderId: "67190719b7a34cd09221bf62",
      createdAt: "2024-10-23T14:24:25.099Z",
      updatedAt: "2024-10-23T14:26:05.660Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085ff3",
        productPLU: "2162074-1",
        productDescription:
          "C 59g| P 29g| F 13g\nKung pao chicken topped with toasted peanuts.\n\nGOOD SOURCE OF  PROTEIN",
        productName: "Kung Pao Chicken",
        imageURL:
          "https://resizer.deliverect.com/5-Abvm0e_bQAk3bZkwUrzX_ONYVykRTm2X4SywUk0vQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjAwLTY1MTdlZTc1ZmVkZWU0YTU4MDc0NGQ5Yy5qcGc=",
        productCalories: 469,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 29,
          fat: 13,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208602e",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092086032",
        productPLU: "2166046-1",
        productDescription: "C 31g | P 13g | F 6.2g\n",
        productName: "PRO CHIPS See Salt & Vinegar",
        imageURL:
          "https://resizer.deliverect.com/Is_on5fI5_atevigbJyEv4KviOO5_bhXdCODMbLIWMY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2VhJTIwc2FsdCUyMCUyNiUyMHZpbmVnYXItNjZkMDNjMWMwODIzMGQyNzBhMDliYTQ0LnBuZw==",
        productCalories: 230,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 13,
          fat: 6.2,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 510,
    totalFmcCentsPrice: 510,
    totalFiatCentsPriceOriginal: 510,
    totalExternalFiatCentsPrice: 510,
    totalFmcCentsPriceOriginal: 510,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:24:25.632Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:26:17.476Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:26:45.917Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:40:54.218Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:40:54.218Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:41:16.202Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:05:05.312Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:24:25.632Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:24:42.993Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:24:25.632Z",
    updatedAt: "2024-10-23T15:05:05.657Z",
    fleetInfo: null,
  },
  {
    id: "67190714b7a34cd09221bd09",
    sid: "241023-843103",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190713b7a34cd09221bcf9",
    firstName: "Abdulaziz Alduaij ",
    lastName: null,
    phoneNumber: "+96597558966",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6643a92d66978e88ed15c402",
    delivery: {
      id: "67190713b7a34cd09221bcf9",
      customerId: "6643a92d66978e88ed15c402",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6643a9ef5ca983b14a29b759",
        name: "Alduaij Home",
        lng: 47.98676513135433,
        lat: 29.31663918626645,
        city: "Kuwait",
        area: "Qortuba",
        block: "1",
        street: "1, Avenue 10",
        building: "16",
        floor: "",
        flat: "",
        phone: "+96597558966",
        additionalInfo: "Apartment number 3 ",
        isSelected: true,
        createdAt: "2024-05-14T18:14:07.945Z",
        updatedAt: "2024-10-23T11:30:54.499Z",
      },
      deliverectValidationId: "6719071360ec4531f4c39aa4",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:34:19.563Z",
      estimatedDeliveryTime: "2024-10-23T14:49:17.770Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:21:00.000Z",
      orderId: "67190714b7a34cd09221bd09",
      createdAt: "2024-10-23T14:24:19.729Z",
      updatedAt: "2024-10-23T14:26:05.776Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fd0",
        productPLU: "2162126-1",
        productDescription:
          "C 47g | P 30g | F 19g\nRice, Grilled Salmon, Coconut Curry, Cherry Tomato, Coriander Leaves\n\nGood source of Protein & Vitamin B12",
        productName: "Coconut Salmon Curry",
        imageURL:
          "https://resizer.deliverect.com/RGhcodNiC9p5cDsaZeSeiR0MrQFl28w5nWBhlmGSMsI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY29jb251dCUyMHNhbG1vbiUyMGN1cnJ5LTY2YjUzNjA5NDAxOTZkZGUyZjg5MjliNC5wbmc=",
        productCalories: 479,
        quantity: 1,
        nutritionInfo: {
          carbs: 47,
          protein: 30,
          fat: 19,
        },
        fmcCentsPrice: 490,
        fiatCentsPrice: 490,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092086008",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 24,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd09208600b",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc14b7a34cd0920860cd",
            productPLU: "1907029-1",
            productName: "No Tomatoes",
            productDescription: "C -1g| P 0g| F 0g| cal -4.5| coins 0",
            productCalories: -4.5,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: null,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 790,
    totalFmcCentsPrice: 790,
    totalFiatCentsPriceOriginal: 790,
    totalExternalFiatCentsPrice: 790,
    totalFmcCentsPriceOriginal: 790,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:24:20.436Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:26:19.339Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:26:44.306Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:40:39.329Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:40:39.329Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:44:58.719Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:12:43.922Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:24:20.436Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:24:32.098Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-23T14:24:20.436Z",
    updatedAt: "2024-10-23T15:12:44.258Z",
    fleetInfo: null,
  },
  {
    id: "67190609b7a34cd092218711",
    sid: "241023-432267",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67190608b7a34cd092218701",
    firstName: "Saud Albaijan",
    lastName: null,
    phoneNumber: "+96599189055",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66c198526c59ceb40a735077",
    delivery: {
      id: "67190608b7a34cd092218701",
      customerId: "66c198526c59ceb40a735077",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66c1aec66a31f4fea73b0c2f",
        name: "Home",
        lng: 47.95860294252634,
        lat: 29.339705665219192,
        city: "Al Kuwayt",
        area: "Kaifan",
        block: "5",
        street: "Mohammed yousif AlAdsani",
        building: "1",
        floor: "",
        flat: "",
        phone: "+96599189055",
        additionalInfo: "Corner house",
        isSelected: true,
        createdAt: "2024-08-18T08:20:22.854Z",
        updatedAt: "2024-10-19T05:45:36.954Z",
      },
      deliverectValidationId: "671906080d4544b052d50cb2",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:29:52.395Z",
      estimatedDeliveryTime: "2024-10-23T14:44:51.256Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:16:00.000Z",
      orderId: "67190609b7a34cd092218711",
      createdAt: "2024-10-23T14:19:52.499Z",
      updatedAt: "2024-10-23T14:21:06.123Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd09208705f",
        productPLU: "2162074-1",
        productDescription:
          "C 59g| P 29g| F 13g\nKung pao chicken topped with toasted peanuts.\n\nGOOD SOURCE OF  PROTEIN",
        productName: "Kung Pao Chicken",
        imageURL:
          "https://resizer.deliverect.com/5-Abvm0e_bQAk3bZkwUrzX_ONYVykRTm2X4SywUk0vQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjAwLTY1MTdlZTc1ZmVkZWU0YTU4MDc0NGQ5Yy5qcGc=",
        productCalories: 469,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 29,
          fat: 13,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc1fb7a34cd092087117",
            productPLU: "1907041-1",
            productName: "No Toasted Peanuts",
            productDescription: "C -3.5g| P -0.3g| F -1g| cal -24| coins 0",
            productCalories: -24,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -3.5,
              protein: -0.3,
              fat: -1,
            },
          },
        ],
      },
      {
        productId: "6718bc1fb7a34cd092087096",
        productPLU: "2168001-1",
        productDescription:
          "C 14g | P 5g | F 1g\nRed lentil soup topped with coriander & served with a lemon wedge. ",
        productName: "Lentil Soup",
        imageURL:
          "https://resizer.deliverect.com/5EbDdbzPc4MFBEpLJbi04Urr0_H4N9CuGy35JtE6XBM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbGVudGlsJTIwc291cC02NjQxZGJjOTNjYmY3YTM2N2ZiNDhiN2IuanBn",
        productCalories: 85,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 5,
          fat: 1,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 310,
    totalFmcCentsPrice: 310,
    totalFiatCentsPriceOriginal: 310,
    totalExternalFiatCentsPrice: 310,
    totalFmcCentsPriceOriginal: 310,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:19:53.194Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:21:08.840Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:21:28.806Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:33:07.957Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:33:07.957Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:33:54.164Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:44:13.871Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:19:53.194Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:20:04.820Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:19:53.194Z",
    updatedAt: "2024-10-23T14:44:14.227Z",
    fleetInfo: null,
  },
  {
    id: "67190582b7a34cd0922169ed",
    sid: "241023-043740",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190581b7a34cd0922169e0",
    firstName: "Ali alfajji",
    lastName: null,
    phoneNumber: "+96591119990",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66d4ba07d7546254fad66c53",
    delivery: {
      id: "67190581b7a34cd0922169e0",
      customerId: "66d4ba07d7546254fad66c53",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670a9d08f495b693cd17c755",
        name: "Ali alfajji",
        lng: 47.971587516367435,
        lat: 29.30539290632629,
        city: "Kuwait",
        area: "Yarmouk",
        block: "2",
        street: "Street 1  house 2",
        building: "House 2",
        floor: "",
        flat: "",
        phone: "+96591119990",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-12T16:00:08.352Z",
        updatedAt: "2024-10-23T14:16:50.093Z",
      },
      deliverectValidationId: "671905816a3e6800dd9bd919",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:27:37.472Z",
      estimatedDeliveryTime: "2024-10-23T14:42:36.431Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:14:00.000Z",
      orderId: "67190582b7a34cd0922169ed",
      createdAt: "2024-10-23T14:17:37.565Z",
      updatedAt: "2024-10-23T14:19:05.578Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092086019",
        productPLU: "2163013-1",
        productDescription:
          "C 24g| P 12g| F 18g\nMixed greens, green bell peppers, cucumbers, tomatoes, spring onions, radishes, fresh mint, zaatar, parsley & grilled halloumi drizzled with a fatoush vinagrette & topped with zaatar croutons. \n\nGOOD SOURCE OF PROTIEN , VITAMIN K , A & CALCIUM",
        productName: "Fattoush",
        imageURL:
          "https://resizer.deliverect.com/5hPWwFSPNGl8nDgfpHyx8xc7_Gg5ln9BhI5g5gG9uoM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2lkZSUyMDItNjUxMDM3NjU4YmNmMGU3YzQ1YzVkMDA4LmpwZw==",
        productCalories: 306,
        quantity: 1,
        nutritionInfo: {
          carbs: 24,
          protein: 12,
          fat: 18,
        },
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092085fe2",
        productPLU: "2162004-1",
        productDescription:
          "C 57g | P 42g | F 11g [%Popular%]\nFlavorful chicken machboos made with marinated chicken breast, aromatic basmati rice, topped with hashu & served with dakkus.",
        productName: "Chicken Machboos",
        imageURL:
          "https://resizer.deliverect.com/wylOEkBBpXVNBS2HAi9pQ55oF2RJyK2HNyEERWjweQ4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWFjaGJvdXMtNjY0NGIwMjllMWFlMGZiZmFkNjJjNzYxLnBuZw==",
        productCalories: 495,
        quantity: 1,
        nutritionInfo: {
          carbs: 57,
          protein: 42,
          fat: 11,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc14b7a34cd092086093",
            productPLU: "1906068-1",
            productName: "Extra 1/2 chicken",
            productDescription: "C 0.5g| P 17.5g| F 1.5g| cal 87| coins 0.5\n",
            productCalories: 87,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 0.5,
              protein: 17.5,
              fat: 1.5,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 560,
    totalFmcCentsPrice: 560,
    totalFiatCentsPriceOriginal: 560,
    totalExternalFiatCentsPrice: 560,
    totalFmcCentsPriceOriginal: 560,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:17:38.312Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:19:07.655Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:20:46.091Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:32:45.352Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:32:45.352Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:34:49.150Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:50:48.525Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:17:38.312Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:17:50.108Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:17:38.312Z",
    updatedAt: "2024-10-23T14:50:48.867Z",
    fleetInfo: null,
  },
  {
    id: "671904e7b7a34cd0922154a0",
    sid: "241023-749407",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671904e6b7a34cd092215490",
    firstName: "Fatemah Salmeen",
    lastName: null,
    phoneNumber: "+96599794692",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66533c6247e7155f92717e20",
    delivery: {
      id: "671904e6b7a34cd092215490",
      customerId: "66533c6247e7155f92717e20",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66efb1161294dc02cafc44e6",
        name: "FATEMAH S",
        lng: 48.00941772758961,
        lat: 29.286473941743907,
        city: "Kuwait",
        area: "Hateen",
        block: "2",
        street: "216 St",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96599794692",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-22T05:54:30.613Z",
        updatedAt: "2024-10-23T11:46:13.374Z",
      },
      deliverectValidationId: "671904e63946960ae1b7269b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:25:02.299Z",
      estimatedDeliveryTime: "2024-10-23T14:40:01.199Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:12:00.000Z",
      orderId: "671904e7b7a34cd0922154a0",
      createdAt: "2024-10-23T14:15:02.494Z",
      updatedAt: "2024-10-23T14:17:07.804Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fd8",
        productPLU: "2162117-1",
        productDescription:
          "C 60g | P 22g | F 15g\nTomato sauce, cheese & oregano.",
        productName: "Classic Margherita Pizza",
        imageURL:
          "https://resizer.deliverect.com/gXmec37h0lqeQkrMSIQRiRdHLwG9kVK9Ukz9m_Kw930/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWFyZ2hhcml0YS02NjZiMzAxMWQ2NmMzMDFhNDE0NmYxNTMucG5n",
        productCalories: 463,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 22,
          fat: 15,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092085fd6",
        productPLU: "2162120-1",
        productDescription:
          "C 63g | P 29g | F 12g \nTomato sauce, cheese, chicken topped with BBQ sauce\n\nGood source of Protein ",
        productName: "BBQ Chicken Pizza",
        imageURL:
          "https://resizer.deliverect.com/oLi5aqw9dJEyegq-8KQAZCtGuNR2P65Gdii-y51sbns/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmJxJTIwcGl6emEtNjY3NDM0MDZhZjdmODYxNjFhMjNhOWYyLnBuZw==",
        productCalories: 476,
        quantity: 1,
        nutritionInfo: {
          carbs: 63,
          protein: 29,
          fat: 12,
        },
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 610,
    totalFmcCentsPrice: 610,
    totalFiatCentsPriceOriginal: 610,
    totalExternalFiatCentsPrice: 610,
    totalFmcCentsPriceOriginal: 610,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:15:03.001Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:17:08.370Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:17:46.993Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:31:16.220Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:31:16.220Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:32:33.114Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:04:38.663Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:15:03.001Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:15:15.087Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:15:03.001Z",
    updatedAt: "2024-10-23T15:04:39.030Z",
    fleetInfo: null,
  },
  {
    id: "671904e3b7a34cd092215332",
    sid: "241023-134849",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671904e2b7a34cd092215322",
    firstName: "Faisal AlDhubaib",
    lastName: null,
    phoneNumber: "+96598559999",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65d0c6c7637513912a7ebb46",
    delivery: {
      id: "671904e2b7a34cd092215322",
      customerId: "65d0c6c7637513912a7ebb46",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "65d0c773ccd522c5be430d88",
        name: "Home",
        lng: 47.969769440402445,
        lat: 29.321548461914062,
        city: "Kuwait",
        area: "Khaldiya",
        block: "3",
        street: "Firdous",
        building: "196",
        floor: "",
        flat: "",
        phone: "+96598559999",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-02-17T14:49:23.959Z",
        updatedAt: "2024-02-17T14:49:23.959Z",
      },
      deliverectValidationId: "671904e23946960ae1b72637",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:24:58.235Z",
      estimatedDeliveryTime: "2024-10-23T14:39:57.211Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:11:00.000Z",
      orderId: "671904e3b7a34cd092215332",
      createdAt: "2024-10-23T14:14:58.331Z",
      updatedAt: "2024-10-23T14:16:05.916Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fcd",
        productPLU: "2162130-1",
        productDescription:
          "C 40g | P 19g | F 16g\n\nSlider bun, patty, cheddar cheese, iceberg lettuce, white onion, dill pickle chips, mayo ketchup sauce.",
        productName: "FM Sliders",
        imageURL:
          "https://resizer.deliverect.com/gYXzCSrBPdFooHt3S19xzbSh9205wL6ziwlFQHMhLTI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBzbGlkZXIoMSktNjZmNTZmM2ZlNjQyNjQ5MzFhZTYwMDMwLnBuZw==",
        productCalories: 380,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092085fde",
        productPLU: "2162113-1",
        productDescription:
          "C 67g | P 21g | F 25g[%Popular%]\nCheeseburger with baked sweet potato fries and bbq sauce",
        productName: "Cheeseburger & fries",
        imageURL:
          "https://resizer.deliverect.com/7r3h13J-z3i_dUkPi81fBjeHfz7KOpOpG45voMIj2xA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hlZXNlYnVyZ2VyJTIwY29tYm8tNjcxMzU3NjhmNTg1MzQ0Mzg3MTVlNGIyLnBuZw==",
        productCalories: 577,
        quantity: 1,
        nutritionInfo: {
          carbs: 67,
          protein: 21,
          fat: 25,
        },
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc14b7a34cd09208608d",
            productPLU: "1907008-1",
            productName: "No Dill Pickles",
            productDescription: "C 0g| P 0g| F 0g| cal -0.4| coins 0",
            productCalories: -0.4,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: null,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 620,
    totalFmcCentsPrice: 620,
    totalFiatCentsPriceOriginal: 620,
    totalExternalFiatCentsPrice: 620,
    totalFmcCentsPriceOriginal: 620,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:14:59.352Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:16:18.434Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:17:38.588Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:31:04.706Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:31:04.706Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:34:37.883Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:00:31.496Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:14:59.352Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:15:26.043Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:14:59.352Z",
    updatedAt: "2024-10-23T15:00:31.947Z",
    fleetInfo: null,
  },
  {
    id: "671904dcb7a34cd092215146",
    sid: "241023-098599",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671904dbb7a34cd092215138",
    firstName: "Ali boland",
    lastName: null,
    phoneNumber: "+96566771315",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64e47aad70771121d14d5873",
    delivery: {
      id: "671904dbb7a34cd092215138",
      customerId: "64e47aad70771121d14d5873",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6655e6b8ecc6fd38a634599c",
        name: "Amb",
        lng: 47.90540319884358,
        lat: 29.23492431640625,
        city: "Abdullah Al Mubarak Al Sabah",
        area: "Abdullah Al Mubarak Al Sabah",
        block: "4",
        street: "416",
        building: "25",
        floor: "1",
        flat: "",
        phone: "+96566771315",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-28T14:14:16.029Z",
        updatedAt: "2024-10-23T14:09:39.789Z",
      },
      deliverectValidationId: "671904db1821405a38bf1977",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:24:51.715Z",
      estimatedDeliveryTime: "2024-10-23T14:39:50.485Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:11:00.000Z",
      orderId: "671904dcb7a34cd092215146",
      createdAt: "2024-10-23T14:14:51.810Z",
      updatedAt: "2024-10-23T14:16:05.016Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085feb",
        productPLU: "2162008-1",
        productDescription:
          "C 40g | P 21g | F 24g [%Popular%]\nCrispy chicken with pepper jack cheese, coleslaw mix, dill pickles & a spicy mayo sauce.",
        productName: "Spicy Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/V3b1iOeX1UmC1nng8xEmFG8Y02arGnZCgSM1cET5hoo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BpY3klMjBjaGlja2VuJTIwYnVyZ2VyKDEpLTY1NTQ4ZjI5ODEyNDJlMDAxOGYwYWIyZi5wbmc=",
        productCalories: 460,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 24,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc14b7a34cd09208609f",
            productPLU: "1907012-1",
            productName: "No Pepper Jack Cheese",
            productDescription: "C 0g| P -3g| F -4g| cal -51| coins 0",
            productCalories: -51,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: -3,
              fat: -4,
            },
          },
        ],
      },
      {
        productId: "6718bc14b7a34cd092086031",
        productPLU: "2161023-1",
        productDescription:
          "C 37g| P 17g| F 13g\nFries topped with caramelized onions, ground beef, pickled jalapeños, american cheese & our special sauce. \n\n*GOOD SOURCE OF PROTEIN,VITAMINB12*",
        productName: "Messy Fries",
        imageURL:
          "https://resizer.deliverect.com/uhTtheRt0RZXomxI_1n5O4G2cP8KUacFKd3MVV-dhio/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTUVTU1klMjBGUklFUy02NTc1OGZhM2QyZWUyNWQ5YmZkZDE4YWUucG5n",
        productCalories: 333,
        quantity: 1,
        nutritionInfo: {
          carbs: 37,
          protein: 17,
          fat: 13,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc14b7a34cd0920860e9",
            productPLU: "1907066-1",
            productName: "No Caramelized Onion",
            productDescription: "C -9g| P -1g| F 0g| cal -38| coins 0",
            productCalories: -38,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -9,
              protein: -1,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 390,
    totalFmcCentsPrice: 390,
    totalFiatCentsPriceOriginal: 390,
    totalExternalFiatCentsPrice: 390,
    totalFmcCentsPriceOriginal: 390,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:14:52.654Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:16:12.931Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:17:42.891Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:32:28.996Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:32:28.996Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:37:24.393Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:55:51.186Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:14:52.654Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:15:04.136Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:14:52.654Z",
    updatedAt: "2024-10-23T14:55:51.528Z",
    fleetInfo: null,
  },
  {
    id: "67190488b7a34cd09221400e",
    sid: "241023-410935",
    coupons: [],
    menuId: "6718bc39b7a34cd09208791a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67190486b7a34cd092213f19",
    firstName: "Dana AlYaseen",
    lastName: null,
    phoneNumber: "+96566366993",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64cf6468f119a7788c98b1b2",
    delivery: {
      id: "67190486b7a34cd092213f19",
      customerId: "64cf6468f119a7788c98b1b2",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "659ceb19afe41535d3a9b21d",
        name: "Home",
        lng: 48.089471608400345,
        lat: 29.23868755835887,
        city: "Al-Masayel",
        area: "Al-Masayel",
        block: "2",
        street: "210",
        building: "55",
        floor: "2",
        flat: "1",
        phone: "+96566366993",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-09T06:43:37.267Z",
        updatedAt: "2024-10-23T13:46:55.418Z",
      },
      deliverectValidationId: "67190486e9cbdcf0e3b19980",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:23:26.471Z",
      estimatedDeliveryTime: "2024-10-23T14:38:25.040Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:10:00.000Z",
      orderId: "67190488b7a34cd09221400e",
      createdAt: "2024-10-23T14:13:26.869Z",
      updatedAt: "2024-10-23T14:15:05.155Z",
    },
    items: [
      {
        productId: "6718bc39b7a34cd0920879da",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd092087981",
        productPLU: "2162118-1",
        productDescription:
          "C 60g | P 22g | F 17g \nTomato sauce, cheese & pepperoni\n\nGood source of Protein ",
        productName: "Pepperoni Pizza",
        imageURL:
          "https://resizer.deliverect.com/QJO9SowT2ihHOIWJoe4NojUcvMSrdQhHrE3QE_iNeHs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVwcGVyb25pLTY2NzQzMzkzMjU0YjRkYjE0ZjE5OTk4MS5wbmc=",
        productCalories: 481,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 22,
          fat: 17,
        },
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd092087997",
        productPLU: "2162008-1",
        productDescription:
          "C 40g | P 21g | F 24g [%Popular%]\nCrispy chicken with pepper jack cheese, coleslaw mix, dill pickles & a spicy mayo sauce.",
        productName: "Spicy Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/V3b1iOeX1UmC1nng8xEmFG8Y02arGnZCgSM1cET5hoo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BpY3klMjBjaGlja2VuJTIwYnVyZ2VyKDEpLTY1NTQ4ZjI5ODEyNDJlMDAxOGYwYWIyZi5wbmc=",
        productCalories: 460,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 24,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd0920879a5",
        productPLU: "2162030-1",
        productDescription:
          "C 42g | P 26g | F 21g\nLo mein noodles, beef, carrots, cabbage & zucchini.\n\n*Good source of Protein & vitamins*",
        productName: "Lo Mein Noodles",
        imageURL:
          "https://resizer.deliverect.com/gtToqQp69qSy57Ivr2G-IxNvZrR3UxiMiFxzdOFokw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTE8lMjBNRUlOJTIwTk9PRExFUy02NTc1OTAxZDRmNzg3N2JlMWJhMWIyN2MucG5n",
        productCalories: 461,
        quantity: 1,
        nutritionInfo: {
          carbs: 42,
          protein: 26,
          fat: 21,
        },
        fmcCentsPrice: 390,
        fiatCentsPrice: 390,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 1110,
    totalFmcCentsPrice: 1110,
    totalFiatCentsPriceOriginal: 1110,
    totalExternalFiatCentsPrice: 1110,
    totalFmcCentsPriceOriginal: 1110,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:13:28.552Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:15:08.594Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:16:02.088Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:28:24.400Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:28:24.400Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:29:19.792Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:44:02.576Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:13:28.552Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:13:40.255Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:13:28.553Z",
    updatedAt: "2024-10-23T14:44:03.028Z",
    fleetInfo: null,
  },
  {
    id: "67190414b7a34cd0922128e9",
    sid: "241023-381926",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67190413b7a34cd0922128e0",
    firstName: "Manar Jaber",
    lastName: null,
    phoneNumber: "+96566400450",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "661f61acb0d178e63207ee98",
    delivery: {
      id: "67190413b7a34cd0922128e0",
      customerId: "661f61acb0d178e63207ee98",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6660a00f3d5f2b755d3d9db0",
        name: "Home Bayan",
        lng: 48.0486843,
        lat: 29.2874887,
        city: "Kuwait",
        area: "بيان",
        block: "13",
        street: "12",
        building: "33",
        floor: "",
        flat: "",
        phone: "+96566400450",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-05T17:27:43.471Z",
        updatedAt: "2024-09-06T09:44:18.119Z",
      },
      deliverectValidationId: "671904133946960ae1b6e9a8",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:21:31.750Z",
      estimatedDeliveryTime: "2024-10-23T14:36:30.703Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:08:00.000Z",
      orderId: "67190414b7a34cd0922128e9",
      createdAt: "2024-10-23T14:11:31.856Z",
      updatedAt: "2024-10-23T14:13:06.386Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208675e",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:11:32.314Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:13:06.708Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:15:08.075Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:27:57.391Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:27:57.391Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:28:19.742Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:58:04.589Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:11:32.314Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:11:46.601Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:11:32.315Z",
    updatedAt: "2024-10-23T14:58:05.632Z",
    fleetInfo: null,
  },
  {
    id: "6719040cb7a34cd092212727",
    sid: "241023-474790",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6719040bb7a34cd09221271c",
    firstName: "Abdulrahman Al Muscati",
    lastName: null,
    phoneNumber: "+96569690790",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66bb4e5aeb3fe809b1c55140",
    delivery: {
      id: "6719040bb7a34cd09221271c",
      customerId: "66bb4e5aeb3fe809b1c55140",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66c6ec689bb4637074ec4df7",
        name: "Home",
        lng: 48.01603087252589,
        lat: 29.31536865234375,
        city: "Kuwait",
        area: "Surra",
        block: "Block 2",
        street: "Street 4",
        building: "House 14",
        floor: "",
        flat: "",
        phone: "+96569690790",
        additionalInfo: "Call when outside ",
        isSelected: true,
        createdAt: "2024-08-22T07:44:40.899Z",
        updatedAt: "2024-10-23T14:09:16.142Z",
      },
      deliverectValidationId: "6719040bb63f266a7d0ccd09",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:21:23.322Z",
      estimatedDeliveryTime: "2024-10-23T14:36:21.999Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:08:00.000Z",
      orderId: "6719040cb7a34cd092212727",
      createdAt: "2024-10-23T14:11:23.495Z",
      updatedAt: "2024-10-23T14:13:06.912Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd092086738",
        productPLU: "2162111-1",
        productDescription:
          "C 72g | P 23g | F 34g[%Popular%]\nChicken burger with baked sweet potato fries and bbq sauce",
        productName: "Chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/fMQuypdGrqkSRZhbEebRsjjcen1dY6rXtUgYEcTeE9s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cmdlciUyMGNvbWJvLTY3MTM1NzgzMWM2ZDc0YTE4NGJmNTY2ZC5wbmc=",
        productCalories: 686,
        quantity: 1,
        nutritionInfo: {
          carbs: 72,
          protein: 23,
          fat: 34,
        },
        fmcCentsPrice: 410,
        fiatCentsPrice: 340,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc19b7a34cd0920867e0",
            productPLU: "1906005-1",
            productName: "Extra  Chicken Pattie",
            productDescription: "C 13g| P 15g| F 2g| cal 136| coins 0.5",
            productCalories: 136,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 13,
              protein: 15,
              fat: 2,
            },
          },
          {
            productId: "6718bc19b7a34cd0920867df",
            productPLU: "1906003-1",
            productName: "Extra Chedder Cheese",
            productDescription: "C 0g| P 2g| F 3g| cal 40| coins 0.2",
            productCalories: 40,
            quantity: 1,
            fmcCentsPrice: 20,
            fiatCentsPrice: 20,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: 2,
              fat: 3,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 410,
    totalFmcCentsPrice: 410,
    totalFiatCentsPriceOriginal: 410,
    totalExternalFiatCentsPrice: 410,
    totalFmcCentsPriceOriginal: 410,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:11:24.083Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:13:06.607Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:15:02.159Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:29:16.791Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:29:16.791Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:29:40.250Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:38:24.675Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:11:24.083Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:11:35.677Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:11:24.083Z",
    updatedAt: "2024-10-23T14:38:25.134Z",
    fleetInfo: null,
  },
  {
    id: "67190406b7a34cd0922125e1",
    sid: "241023-952325",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67190406b7a34cd0922125d5",
    firstName: "Hessa AlHunaidi ",
    lastName: null,
    phoneNumber: "+96560004548",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c3e2f775a6aa442d0f2c3e",
    delivery: {
      id: "67190406b7a34cd0922125d5",
      customerId: "64c3e2f775a6aa442d0f2c3e",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "650c799f0a91a2efcd4431a6",
        name: "Mama ghanima",
        lng: 47.96117652207613,
        lat: 29.33487052198079,
        city: "Shuwaikh Industrial",
        area: "Kaifan",
        block: "7",
        street: "71",
        building: "4",
        floor: "",
        flat: "",
        phone: "+96560004548",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-09-21T17:13:03.177Z",
        updatedAt: "2024-10-23T14:09:32.890Z",
      },
      deliverectValidationId: "6719040609aba370f7f245ff",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:21:18.104Z",
      estimatedDeliveryTime: "2024-10-23T14:36:17.084Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:08:00.000Z",
      orderId: "67190406b7a34cd0922125e1",
      createdAt: "2024-10-23T14:11:18.217Z",
      updatedAt: "2024-10-23T14:13:04.450Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd09208704d",
        productPLU: "2162109-1",
        productDescription:
          "C 23g | P 29g | F 16g \n Beef Lasagna with zucchini & basil\n\nGood source of protein and vitamin A",
        productName: "Zucchini Beef Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SGhwFjSpMczUdKMaEIYf4gYXABWfE33sF_WueZYOSfI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGxhc2FnbmEtNjY0NGFmZDU3NDhmMzY3YzEzMTZlNWZhLnBuZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 23,
          protein: 29,
          fat: 16,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd092087059",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 520,
    totalFmcCentsPrice: 520,
    totalFiatCentsPriceOriginal: 520,
    totalExternalFiatCentsPrice: 520,
    totalFmcCentsPriceOriginal: 520,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:11:18.781Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:13:06.812Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:14:44.157Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:26:58.931Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:26:58.931Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:27:20.358Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:40:08.567Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:11:18.781Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:11:22.502Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:11:18.781Z",
    updatedAt: "2024-10-23T14:40:09.015Z",
    fleetInfo: null,
  },
  {
    id: "671903eeb7a34cd092211946",
    sid: "241023-353487",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671903edb7a34cd09221193a",
    firstName: "Noura AlOtaibi",
    lastName: null,
    phoneNumber: "+96594405096",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65ca06ce914bef1d8309b9f9",
    delivery: {
      id: "671903edb7a34cd09221193a",
      customerId: "65ca06ce914bef1d8309b9f9",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65ca0baf106ad03db1515997",
        name: "Work",
        lng: 47.99386623141055,
        lat: 29.38958740234375,
        city: "مدينة الكويت",
        area: "شرق",
        block: "1",
        street: "شارع ابن مسباح",
        building: "1",
        floor: "",
        flat: "",
        phone: "+96594405096",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-02-12T12:14:39.751Z",
        updatedAt: "2024-10-23T14:10:04.059Z",
      },
      deliverectValidationId: "671903edb63263b7f6a2c63c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:20:53.761Z",
      estimatedDeliveryTime: "2024-10-23T14:35:52.765Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:07:00.000Z",
      orderId: "671903eeb7a34cd092211946",
      createdAt: "2024-10-23T14:10:53.853Z",
      updatedAt: "2024-10-23T14:12:06.093Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd092087062",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:10:54.740Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:12:06.511Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:12:39.165Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:25:22.671Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:25:22.676Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:36:50.713Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:09:13.647Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:10:54.740Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:11:00.994Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:10:54.740Z",
    updatedAt: "2024-10-23T15:09:13.994Z",
    fleetInfo: null,
  },
  {
    id: "671903a4b7a34cd092210573",
    sid: "241023-182846",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671903a3b7a34cd09221050c",
    firstName: "Haya Alsharrah",
    lastName: null,
    phoneNumber: "+96597504443",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66b48f44eb3fe809b1b90657",
    delivery: {
      id: "671903a3b7a34cd09221050c",
      customerId: "66b48f44eb3fe809b1b90657",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66b490410846fbb3561975cf",
        name: "Home",
        lng: 48.028823509812355,
        lat: 29.26983997848903,
        city: "Kuwait",
        area: "Shuhada",
        block: "4",
        street: "415",
        building: "67",
        floor: "",
        flat: "",
        phone: "+96597504443",
        additionalInfo: "Corner house",
        isSelected: true,
        createdAt: "2024-08-08T09:30:41.478Z",
        updatedAt: "2024-10-15T10:55:18.138Z",
      },
      deliverectValidationId: "671903a30348c4b0ea117042",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:19:39.537Z",
      estimatedDeliveryTime: "2024-10-23T14:34:38.406Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:06:00.000Z",
      orderId: "671903a4b7a34cd092210573",
      createdAt: "2024-10-23T14:09:39.637Z",
      updatedAt: "2024-10-23T14:11:05.127Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092086002",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:09:40.572Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:11:11.742Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:11:30.543Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:27:09.268Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:27:09.268Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:32:56.999Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:51:42.887Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:09:40.572Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:09:52.233Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:09:40.572Z",
    updatedAt: "2024-10-23T14:51:43.223Z",
    fleetInfo: null,
  },
  {
    id: "67190378b7a34cd09220ece7",
    sid: "241023-449321",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67190376b7a34cd09220eab1",
    firstName: "Rayan Kazerooni",
    lastName: null,
    phoneNumber: "+96560038182",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "659d68232d5e4e99268ebe6a",
    delivery: {
      id: "67190376b7a34cd09220eab1",
      customerId: "659d68232d5e4e99268ebe6a",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "659e21f7afe41535d3b11c36",
        name: "Home",
        lng: 47.99078240990639,
        lat: 29.377436314796487,
        city: "Kuwait City",
        area: "Sharq",
        block: "Sharq",
        street: "Al Shuhada St",
        building: "Marriott Courtyard Hotel",
        floor: "Reception",
        flat: "Near Al Raya Mall",
        phone: "+96560038182",
        additionalInfo: "Deliver to concierge desk under my name",
        isSelected: true,
        createdAt: "2024-01-10T04:49:59.267Z",
        updatedAt: "2024-10-23T13:08:22.918Z",
      },
      deliverectValidationId: "67190376a7e4fe3721f13a88",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:18:54.143Z",
      estimatedDeliveryTime: "2024-10-23T14:33:52.859Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:05:00.000Z",
      orderId: "67190378b7a34cd09220ece7",
      createdAt: "2024-10-23T14:08:54.403Z",
      updatedAt: "2024-10-23T14:10:06.609Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd092087066",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd092087055",
        productPLU: "2162077-1",
        productDescription:
          "C 41g | P 26g | F 16g\nShort ribs, mixed cabbage, sour cream, spring onion, parsely with tortilla wrap.\n\nGood Source Of Protein, Vitamin B12, Minerals",
        productName: "Short Rib Tacos",
        imageURL:
          "https://resizer.deliverect.com/bwNBblwmBg7NZVLL0zxwD_1h0r1mAgb-zXh607JTGn4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVEFDTy02NTI5ZjMxMWQyNmQ1ZGYyNzhkZDZiYjYucG5n",
        productCalories: 420,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 26,
          fat: 16,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd09208709a",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd092087087",
        productPLU: "2163010-1",
        productDescription:
          "C 25g| P 34g| F 15g\nGrilled chicken with baby gem lettuce, caesar dressing topped with sourdough croutons & shaved parmesan cheese.\n\nGood source of Vitamin A &Potein",
        productName: "Chicken Ceasar Salad",
        imageURL:
          "https://resizer.deliverect.com/aOyM36xC7gBB5GwjyrYYA3YsTJ8-9RRXq22EPXeQlkM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NGI3YjVjOWU0ZDVjMzAwMThiNjViNmIucG5n",
        productCalories: 371,
        quantity: 1,
        nutritionInfo: {
          carbs: 25,
          protein: 34,
          fat: 15,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd0920870c2",
        productPLU: "2165003-1",
        productDescription: "C 12g | P 2g | F 9g\n",
        productName: "Peanut Bites",
        imageURL:
          "https://resizer.deliverect.com/S2e9SlQ_6ewwX1Z-Pg_RtXB9e4OxquLa6-xOxnwoCJM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTMtNjRiN2JjNmFkMGE4N2MwMDFmZTYwZjBhLnBuZw==",
        productCalories: 139,
        quantity: 1,
        nutritionInfo: {
          carbs: 12,
          protein: 2,
          fat: 9,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd0920870c4",
        productPLU: "2165008-1",
        productDescription: "C 13g| P 3g| F 10g",
        productName: "Snickers Bar",
        imageURL:
          "https://resizer.deliverect.com/qlfM-kU78NJZFB6H6YZxYvpH8UfR3_jKIa0YwqE7w20/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTItNjRiN2JjZGJhMDAxOGMwYmY0YTY2NTZiLnBuZw==",
        productCalories: 157,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 3,
          fat: 10,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 1240,
    totalFmcCentsPrice: 1240,
    totalFiatCentsPriceOriginal: 1240,
    totalExternalFiatCentsPrice: 1240,
    totalFmcCentsPriceOriginal: 1240,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:08:56.795Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:10:08.125Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:12:33.878Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:20:35.248Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:20:35.248Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:36:40.228Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:01:15.181Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:08:56.795Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:09:11.472Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:08:56.795Z",
    updatedAt: "2024-10-23T15:01:15.516Z",
    fleetInfo: null,
  },
  {
    id: "67190365b7a34cd09220e6cd",
    sid: "241023-880231",
    coupons: [],
    menuId: "6718bc39b7a34cd09208791a",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67190364b7a34cd09220e6ac",
    firstName: "Noura AlOtaibi",
    lastName: null,
    phoneNumber: "+96594405096",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65ca06ce914bef1d8309b9f9",
    delivery: {
      id: "67190364b7a34cd09220e6ac",
      customerId: "65ca06ce914bef1d8309b9f9",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6686a08b09647a7c330cf826",
        name: "Home",
        lng: 48.06329224258661,
        lat: 29.263051480234914,
        city: "Sabah Al Salem",
        area: "Sabah Al Salem",
        block: "5",
        street: "1",
        building: "60",
        floor: "avenue 15",
        flat: "",
        phone: "+96594405096",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-04T13:15:55.249Z",
        updatedAt: "2024-10-23T14:05:19.352Z",
      },
      deliverectValidationId: "671903634871f32c091b6e61",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:18:35.418Z",
      estimatedDeliveryTime: "2024-10-23T14:33:34.355Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:05:00.000Z",
      orderId: "67190365b7a34cd09220e6cd",
      createdAt: "2024-10-23T14:08:36.204Z",
      updatedAt: "2024-10-23T14:10:07.513Z",
    },
    items: [
      {
        productId: "6718bc39b7a34cd0920879db",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc39b7a34cd092087998",
        productPLU: "2162006-1",
        productDescription:
          "C 33g| P 19g| F 16g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:08:37.504Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:10:07.210Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:10:35.314Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:25:32.760Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:25:32.760Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:36:17.733Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:51:54.115Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:08:37.504Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:09:00.734Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:08:37.504Z",
    updatedAt: "2024-10-23T14:51:54.569Z",
    fleetInfo: null,
  },
  {
    id: "67190364b7a34cd09220e6bb",
    sid: "241023-935011",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67190364b7a34cd09220e6af",
    firstName: "Bader Alhashel",
    lastName: null,
    phoneNumber: "+96599448300",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c4919475a6aa442d0f5dc6",
    delivery: {
      id: "67190364b7a34cd09220e6af",
      customerId: "64c4919475a6aa442d0f5dc6",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64ccf612787ffa718057880e",
        name: "Home",
        lng: 48.00293684005737,
        lat: 29.324369961245562,
        city: "Kuwait",
        area: "Rawda",
        block: "3",
        street: "Yousif Alsabeeh St",
        building: "9",
        floor: "",
        flat: "",
        phone: "+96599448300",
        additionalInfo: "Come to the back side of the house",
        isSelected: true,
        createdAt: "2023-08-04T12:58:58.599Z",
        updatedAt: "2024-10-23T14:07:50.186Z",
      },
      deliverectValidationId: "6719036378623a895dc8c2e5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:18:35.770Z",
      estimatedDeliveryTime: "2024-10-23T14:33:34.639Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:05:00.000Z",
      orderId: "67190364b7a34cd09220e6bb",
      createdAt: "2024-10-23T14:08:36.306Z",
      updatedAt: "2024-10-23T14:10:06.076Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208677f",
        productPLU: "2162056-1",
        productDescription:
          "C 52g| P 40g| F 18g\nGrilled tenderloin steak served with egyptian rice, charred corn, mushroom sauce, grilled onion topped with chives.\n\nGood source of iron & B-Complex vitamins",
        productName: "Mushroom Steak Bowl",
        imageURL:
          "https://resizer.deliverect.com/mI46_G8ohOUlax6lzY869gIqShTLi01aFmd1aeKxxuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGJvd2wlMjAtNjRmNDhmODQxNDI5ODQwMDFlOGVjOGVkLmpwZw==",
        productCalories: 530,
        quantity: 1,
        nutritionInfo: {
          carbs: 52,
          protein: 40,
          fat: 18,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 400,
    totalExternalFiatCentsPrice: 400,
    totalFmcCentsPriceOriginal: 400,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:08:36.926Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:10:05.506Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:15:07.767Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:25:43.611Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:25:43.611Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:26:14.560Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:37:50.696Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:08:36.926Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:08:48.429Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:08:36.926Z",
    updatedAt: "2024-10-23T14:37:51.043Z",
    fleetInfo: null,
  },
  {
    id: "6719030bb7a34cd09220ca10",
    sid: "241023-000516",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6719030ab7a34cd09220c9fb",
    firstName: "Abdullah AlSabah",
    lastName: null,
    phoneNumber: "+96566666283",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6716b962b58bc47308413d64",
    delivery: {
      id: "6719030ab7a34cd09220c9fb",
      customerId: "6716b962b58bc47308413d64",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6716bb15b7a34cd092c4a4b3",
        name: "Abdullah",
        lng: 47.99380827695131,
        lat: 29.29079332835378,
        city: "Kuwait",
        area: "Al-Siddeeq",
        block: "4",
        street: "411",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96566666283",
        additionalInfo: "White House at the corner the street ",
        isSelected: true,
        createdAt: "2024-10-21T20:35:33.796Z",
        updatedAt: "2024-10-21T20:35:33.796Z",
      },
      deliverectValidationId: "6719030a60ec4531f4c13bf6",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:17:06.459Z",
      estimatedDeliveryTime: "2024-10-23T14:32:05.256Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:04:00.000Z",
      orderId: "6719030bb7a34cd09220ca10",
      createdAt: "2024-10-23T14:07:06.579Z",
      updatedAt: "2024-10-23T14:09:09.681Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd09208602c",
        productPLU: "2150024-1",
        productDescription:
          "C 10g | P 16g | F 7g\nJareesh with beef tenderloin, topped with diced onions. \n\nGood sourceof Protein& Vitamin B12 and Fiber ",
        productName: "Jareesh",
        imageURL:
          "https://resizer.deliverect.com/wQlso2Vdw0WvZ4iujMAq1gJW3Y2itz5As0oEsHIlggU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFyZWVzaC02NmQwNzFmZDE5NGUzZDNkMDBmMzk0NjcucG5n",
        productCalories: 167,
        quantity: 1,
        nutritionInfo: {
          carbs: 10,
          protein: 16,
          fat: 7,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092085ff9",
        productPLU: "2162030-1",
        productDescription:
          "C 42g | P 26g | F 21g\nLo mein noodles, beef, carrots, cabbage & zucchini.\n\n*Good source of Protein & vitamins*",
        productName: "Lo Mein Noodles",
        imageURL:
          "https://resizer.deliverect.com/gtToqQp69qSy57Ivr2G-IxNvZrR3UxiMiFxzdOFokw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTE8lMjBNRUlOJTIwTk9PRExFUy02NTc1OTAxZDRmNzg3N2JlMWJhMWIyN2MucG5n",
        productCalories: 461,
        quantity: 1,
        nutritionInfo: {
          carbs: 42,
          protein: 26,
          fat: 21,
        },
        fmcCentsPrice: 390,
        fiatCentsPrice: 390,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092086065",
        productPLU: "2202035-1",
        productDescription:
          "C 24g | P 20.7g | F 3.7g\nVanilla, Milk, Stevia & WHEY Protein",
        productName: "Vanilla Protein shake",
        imageURL:
          "https://resizer.deliverect.com/3bjzKi97cmL3LLTEUU_lvjy3JLsp7QvOF9KbvO9bJaY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVmFuaWxsYSUyMHByb3RlaW4lMjBzaGFrZS02NjQxZGMwZmNjNWEzYTExMDc5YzVlYzgucG5n",
        productCalories: 212,
        quantity: 1,
        nutritionInfo: {
          carbs: 24,
          protein: 20.7,
          fat: 3.7,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 730,
    totalFmcCentsPrice: 730,
    totalFiatCentsPriceOriginal: 730,
    totalExternalFiatCentsPrice: 730,
    totalFmcCentsPriceOriginal: 730,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:07:07.234Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:09:11.386Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:14:27.146Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:22:14.869Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:22:14.869Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:33:08.302Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:12:55.560Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:07:07.234Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:07:29.658Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:07:07.234Z",
    updatedAt: "2024-10-23T15:12:56.143Z",
    fleetInfo: null,
  },
  {
    id: "6719030ab7a34cd09220c9fd",
    sid: "241023-164818",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67190309b7a34cd09220c9e6",
    firstName: "Tarik alghanim",
    lastName: null,
    phoneNumber: "+96566668512",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65c08460d3514bca6687dbda",
    delivery: {
      id: "67190309b7a34cd09220c9e6",
      customerId: "65c08460d3514bca6687dbda",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6718cf18b7a34cd0920ee303",
        name: "Eden Salon & Spa",
        lng: 47.994905,
        lat: 29.3776208,
        city: "Al Kuwayt",
        area: "Sharq",
        block: "8",
        street: "156 St",
        building: "173",
        floor: "",
        flat: "",
        phone: "+96560481818",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-23T10:25:28.435Z",
        updatedAt: "2024-10-23T10:25:52.783Z",
      },
      deliverectValidationId: "67190309cc78686e3fab8519",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:17:05.872Z",
      estimatedDeliveryTime: "2024-10-23T14:32:04.414Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:04:00.000Z",
      orderId: "6719030ab7a34cd09220c9fd",
      createdAt: "2024-10-23T14:07:05.966Z",
      updatedAt: "2024-10-23T14:09:06.786Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd09208707b",
        productPLU: "2163022-1",
        productDescription:
          "C 15g | P 2g | F 14g [%New%]\n\nVeggies with fattoush dressing & croutons.\n\nGood source of Vitamins",
        productName: "Mini Fattoush",
        imageURL:
          "https://resizer.deliverect.com/QUcv0CxdaaAXW0GlMNB1GAQ5fahshLaeuOoMIRAFR5c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGZhdHRvdXNoJTIwc2FsYWQtNjcxMzUxNmViYTZjN2U5ZTgzMmVkM2MyLnBuZw==",
        productCalories: 194,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 2,
          fat: 14,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd09208709a",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 340,
    totalExternalFiatCentsPrice: 340,
    totalFmcCentsPriceOriginal: 340,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:07:06.590Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:09:08.059Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:09:50.873Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:20:24.266Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:20:24.266Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:36:28.967Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:52:44.379Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:07:06.590Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:07:17.994Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:07:06.590Z",
    updatedAt: "2024-10-23T14:52:45.411Z",
    fleetInfo: null,
  },
  {
    id: "671902feb7a34cd09220c7e3",
    sid: "241023-256843",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671902feb7a34cd09220c7d4",
    firstName: "Salma ",
    lastName: null,
    phoneNumber: "+96599118800",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64e37f5770771121d14ce303",
    delivery: {
      id: "671902feb7a34cd09220c7d4",
      customerId: "64e37f5770771121d14ce303",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "651bed35f3f5a7e1daf53d52",
        name: "Dar Salma",
        lng: 48.0035053,
        lat: 29.3499618,
        city: "Kuwait",
        area: "Qadsiya",
        block: "9",
        street: "94",
        building: "5A",
        floor: "",
        flat: "",
        phone: "+96599118800",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-03T10:30:13.327Z",
        updatedAt: "2024-10-17T10:27:02.687Z",
      },
      deliverectValidationId: "671902fd05f0aa7bf67ee289",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:16:53.982Z",
      estimatedDeliveryTime: "2024-10-23T14:31:52.999Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:03:00.000Z",
      orderId: "671902feb7a34cd09220c7e3",
      createdAt: "2024-10-23T14:06:54.084Z",
      updatedAt: "2024-10-23T14:08:07.290Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd092087095",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd09208705a",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd092087077",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc1fb7a34cd092087139",
            productPLU: "1907029-1",
            productName: "No Tomatoes",
            productDescription: "C -1g| P 0g| F 0g| cal -4.5| coins 0",
            productCalories: -4.5,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: null,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 530,
    totalFmcCentsPrice: 530,
    totalFiatCentsPriceOriginal: 530,
    totalExternalFiatCentsPrice: 530,
    totalFmcCentsPriceOriginal: 530,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:06:54.821Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:08:10.951Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:09:05.556Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:31:49.697Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:31:49.697Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:33:19.564Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:51:06.788Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:06:54.821Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:07:06.393Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:06:54.821Z",
    updatedAt: "2024-10-23T14:51:07.158Z",
    fleetInfo: null,
  },
  {
    id: "671902d6b7a34cd09220be76",
    sid: "241023-570824",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671902d6b7a34cd09220be6c",
    firstName: "Amna al muhanna",
    lastName: null,
    phoneNumber: "+96550889133",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "662f921d7ef16a176ee6cc81",
    delivery: {
      id: "671902d6b7a34cd09220be6c",
      customerId: "662f921d7ef16a176ee6cc81",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "662f9312c37a2a2c2682eea9",
        name: "Amn ",
        lng: 48.023118702739495,
        lat: 29.309738159179688,
        city: "Jabriya",
        area: "Jabriya",
        block: "9",
        street: "18",
        building: "26A",
        floor: "",
        flat: "",
        phone: "+96550889133",
        additionalInfo: "House on the corner ",
        isSelected: true,
        createdAt: "2024-04-29T12:31:14.160Z",
        updatedAt: "2024-10-22T12:58:48.143Z",
      },
      deliverectValidationId: "671902d6c6ffa8c03bb2d8d5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:16:14.316Z",
      estimatedDeliveryTime: "2024-10-23T14:31:13.313Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:03:00.000Z",
      orderId: "671902d6b7a34cd09220be76",
      createdAt: "2024-10-23T14:06:14.408Z",
      updatedAt: "2024-10-23T14:08:08.135Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208674d",
        productPLU: "2162054-1",
        productDescription:
          "C 30g| P 26g| F 23g\nRoasted short ribs with mashed potatoes, roasted baby carrots, shallots & broccoli, topped with scallions.\n\nGluten free, High in protien, Good source of Vitamin A, B12 & B6.",
        productName: "Short Ribs & Mash",
        imageURL:
          "https://resizer.deliverect.com/O89_GaQW6TO8q2JdVmgNCZe7eyPxS91cns3qGWT8_oo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvOS02NGI3YjFkYjJlY2EwNzAwMjBhNGVkZTMucG5n",
        productCalories: 431,
        quantity: 1,
        nutritionInfo: {
          carbs: 30,
          protein: 26,
          fat: 23,
        },
        fmcCentsPrice: 780,
        fiatCentsPrice: 440,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc19b7a34cd092086802",
            productPLU: "1906020-1",
            productName: "Extra  Roasted Short Ribs",
            productDescription: "C 0g| P 20g| F 20g| cal 263| coins 3.4",
            productCalories: 263,
            quantity: 1,
            fmcCentsPrice: 340,
            fiatCentsPrice: 340,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: 20,
              fat: 20,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 780,
    totalFmcCentsPrice: 780,
    totalFiatCentsPriceOriginal: 780,
    totalExternalFiatCentsPrice: 780,
    totalFmcCentsPriceOriginal: 780,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:06:14.927Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:08:07.599Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:15:02.507Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:28:32.409Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:28:32.410Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:28:46.539Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:38:36.202Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:06:14.927Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:06:26.476Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:06:14.927Z",
    updatedAt: "2024-10-23T14:38:36.550Z",
    fleetInfo: null,
  },
  {
    id: "67190285b7a34cd09220a245",
    sid: "241023-628842",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67190282b7a34cd092209fc4",
    firstName: " Khaled",
    lastName: null,
    phoneNumber: "+96599369996",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "651923a5c6da5aa4aee3a63e",
    delivery: {
      id: "67190282b7a34cd092209fc4",
      customerId: "651923a5c6da5aa4aee3a63e",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "653e494b289c08cbdbca11cd",
        name: "Home ",
        lng: 47.994017489254475,
        lat: 29.334477055285713,
        city: "Kuwait",
        area: "Rawda",
        block: "5",
        street: "65 St",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96599369996",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-29T12:00:11.152Z",
        updatedAt: "2023-10-29T12:00:11.152Z",
      },
      deliverectValidationId: "671902828da25298e7e90644",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:14:50.286Z",
      estimatedDeliveryTime: "2024-10-23T14:29:48.645Z",
      estimatedDeliveryTimeEnd: "2024-10-23T15:01:00.000Z",
      orderId: "67190285b7a34cd09220a245",
      createdAt: "2024-10-23T14:04:50.704Z",
      updatedAt: "2024-10-23T14:06:07.259Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208674c",
        productPLU: "2162022-1",
        productDescription:
          "C 32g | P 40g | F 21g\nShish tawook with batata harra, sumac white onions and pickled cucumber served with tahini sauce.\n\nGluten free,High in protien,Good source of Vitamin C",
        productName: "Shish Tawook with Batata Harra",
        imageURL:
          "https://resizer.deliverect.com/t8iFrO7JgasiTM_3BgLdmFPccJzHYDm3brRNicOgS5E/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjktNjRjOGJlYWZhNDUwZTYwMDI2ODNkMjhhLnBuZw==",
        productCalories: 477,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 40,
          fat: 21,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086780",
        productPLU: "2162021-1",
        productDescription:
          "C 60g| P 50g| F 16g\nChicken shawarma served with calrose rice & drizzled with our shawarma sauce and sumac white onions.\n\nHigh in protein, ",
        productName: "Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/wpaWs0_xzYbkz_6vxHW2469_71kHXwgrjXuSA4epEwY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2hpY2tlbiUyMFNoYXdhcm1hJTIwYm93bC02NGY0OTRlOTFhNzliZjNkNzNkYjUzNjcuanBn",
        productCalories: 584,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 50,
          fat: 16,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086799",
        productPLU: "2166020-1",
        productDescription:
          "C 13g| P 2g| F 1g\nBeetroots tossed in olive oil, lemon juice and fresh coriander.\n\nGood source of dietary Fibre, Iron, Potassium and Vitamin C\nContains garlic.",
        productName: "Beetroot Pot",
        imageURL:
          "https://resizer.deliverect.com/4QQmAm_YErxn11xFj_N8IRMBlHt61yeQWJR1VR9vwug/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNy02NTEwMzhmNDI2N2RiNTQwNTI2ZTM4MTguanBn",
        productCalories: 70,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 2,
          fat: 1,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd09208677a",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.2,
          protein: 2.8,
          fat: 8.6,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 620,
    totalFmcCentsPrice: 620,
    totalFiatCentsPriceOriginal: 620,
    totalExternalFiatCentsPrice: 620,
    totalFmcCentsPriceOriginal: 620,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:04:53.462Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:06:06.475Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:06:27.546Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:22:30.032Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:22:30.032Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:32:21.841Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:52:21.315Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:04:53.462Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:05:05.170Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-23T14:04:53.462Z",
    updatedAt: "2024-10-23T14:52:21.655Z",
    fleetInfo: null,
  },
  {
    id: "67190266b7a34cd092209479",
    sid: "241023-771840",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67190265b7a34cd09220946d",
    firstName: "Fajer Al Mazidi",
    lastName: null,
    phoneNumber: "+96566262662",
    paymentType: "fmcCents",
    type: "scheduleOrder",
    customerId: "6533eb7f1bfa57c5db00b38f",
    delivery: {
      id: "67190265b7a34cd09220946d",
      customerId: "6533eb7f1bfa57c5db00b38f",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6564ad0902b2e21f1fb8f16c",
        name: "Home",
        lng: 48.001735880970955,
        lat: 29.314516720049404,
        city: "Kuwait",
        area: "Surra",
        block: "5",
        street: "6",
        building: "14",
        floor: "",
        flat: "",
        phone: "+96566262662",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-27T14:51:53.086Z",
        updatedAt: "2024-10-12T10:39:13.664Z",
      },
      deliverectValidationId: "67190265b63263b7f6a151d5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:14:21.202Z",
      estimatedDeliveryTime: "2024-10-23T17:00:00.000Z",
      estimatedDeliveryTimeEnd: "2024-10-23T17:06:00.000Z",
      orderId: "67190266b7a34cd092209479",
      createdAt: "2024-10-23T14:04:21.321Z",
      updatedAt: "2024-10-23T16:25:06.872Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd09208672f",
        productPLU: "2162122-1",
        productDescription:
          "C 25g | P 5g | F 6g \nTortilla, mashed beans, guacamole, salsa, grilled pineapple, jalapeno salsa and kale. Contains Coriander.\n\nGood source of Vitamin C & K",
        productName: "Beans & Kale Tacos",
        imageURL:
          "https://resizer.deliverect.com/I6P9SEwtrysk4ivqKWtSQjFAgt0t-zShenQXMT9i74U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvdmVnJTIwdGFjb3MtNjY3ZDVlYWU3NzA0ZWQyNWI2OWI3ZDU2LnBuZw==",
        productCalories: 174,
        quantity: 1,
        nutritionInfo: {
          carbs: 25,
          protein: 5,
          fat: 6,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086759",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 2,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd09208678b",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 540,
    totalFmcCentsPrice: 540,
    totalFiatCentsPriceOriginal: 540,
    totalExternalFiatCentsPrice: 540,
    totalFmcCentsPriceOriginal: 540,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:04:22.128Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T16:25:06.886Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T16:26:20.739Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T16:37:11.171Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T16:37:11.171Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T16:42:21.160Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T16:55:57.059Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:04:22.128Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:04:33.650Z",
      },
    ],
    cutlery: false,
    timeslot: "20:00 - 20:29",
    createdAt: "2024-10-23T14:04:22.128Z",
    updatedAt: "2024-10-23T16:55:57.394Z",
    fleetInfo: null,
  },
  {
    id: "67190194b7a34cd092207370",
    sid: "241023-238991",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67190194b7a34cd092207365",
    firstName: "Mohammed Akbar",
    lastName: null,
    phoneNumber: "+96552550764",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67024756906276458c81854e",
    delivery: {
      id: "67190194b7a34cd092207365",
      customerId: "67024756906276458c81854e",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670247ee218a8c4af23bf943",
        name: "Home",
        lng: 48.018465489149094,
        lat: 29.286934901502665,
        city: "Kuwait",
        area: "Hateen",
        block: "2",
        street: "201 St",
        building: "12",
        floor: "",
        flat: "",
        phone: "+96552550764",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-06T08:18:54.904Z",
        updatedAt: "2024-10-06T08:18:54.904Z",
      },
      deliverectValidationId: "671901944cb7e660cd85381e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:10:52.086Z",
      estimatedDeliveryTime: "2024-10-23T14:25:50.549Z",
      estimatedDeliveryTimeEnd: "2024-10-23T14:57:00.000Z",
      orderId: "67190194b7a34cd092207370",
      createdAt: "2024-10-23T14:00:52.207Z",
      updatedAt: "2024-10-23T14:02:06.886Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd09208606c",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        nutritionInfo: {
          carbs: 5,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092085ff6",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:00:52.921Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:02:08.458Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:03:01.406Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:14:45.520Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:14:45.552Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:20:35.596Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:41:33.032Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:00:52.921Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:01:04.355Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:00:52.921Z",
    updatedAt: "2024-10-23T14:41:33.366Z",
    fleetInfo: null,
  },
  {
    id: "67190166b7a34cd092206e38",
    sid: "241023-465705",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67190166b7a34cd092206e28",
    firstName: "Reem Althekair ",
    lastName: null,
    phoneNumber: "+96599886625",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65780fb47a1e4e4900f0d632",
    delivery: {
      id: "67190166b7a34cd092206e28",
      customerId: "65780fb47a1e4e4900f0d632",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "65781014f093f324581354ec",
        name: "Home ",
        lng: 47.97688513985621,
        lat: 29.331039428710938,
        city: "Shuwaikh Industrial",
        area: "Adailiya",
        block: "1",
        street: "12",
        building: "3",
        floor: "",
        flat: "",
        phone: "+96599886625",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-12T07:47:32.199Z",
        updatedAt: "2023-12-12T07:47:32.199Z",
      },
      deliverectValidationId: "6719016572410ebb087e98ab",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:10:05.961Z",
      estimatedDeliveryTime: "2024-10-23T14:25:04.950Z",
      estimatedDeliveryTimeEnd: "2024-10-23T14:57:00.000Z",
      orderId: "67190166b7a34cd092206e38",
      createdAt: "2024-10-23T14:00:06.074Z",
      updatedAt: "2024-10-23T14:02:06.673Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd0920867ac",
        productPLU: "2166050-1",
        productDescription:
          "C 6g | P 2g | F 8g [%New%]\n\nPecan nuts, digestive, stevia, unsalted butter, cream cheese, heavy cream, egg, sour cream, all purpose flour, vanilla extract, syrup.",
        productName: "Pecan cheesecake",
        imageURL:
          "https://resizer.deliverect.com/V5P-zIm2i9d9zMnIsCSZ3RcaQiBGbqJ8glcvZNImsFM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hlZXNjYWtlJTIwYml0ZS02NmY5MGRhZmE0OGIxYjQxMWRmZTBiZTAucG5n",
        productCalories: 104,
        quantity: 1,
        nutritionInfo: {
          carbs: 6,
          protein: 2,
          fat: 8,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086760",
        productPLU: "2162076-1",
        productDescription:
          "C 57g| P 32g| F 12g\nGrilled chicken, rice, corn, mixed beans sauce, iceburg lettuce, chedder cheese, mozarella cheese & ranch all wrapped in a tortilla wrap.\n\nGOOD SOURCE OF PROTEIN, VITAMINS AND MINERALS",
        productName: "Chicken Burrito",
        imageURL:
          "https://resizer.deliverect.com/6YIIS1bTpbzMr3WFtmPqqbcL9yd4ZmTrgUo1MJkaRzE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cnJpdG8lMjB3cmFwLTY1NzU4ZmY2MTk4NDQxOWFmYWNhOTI3MS5qcGc=",
        productCalories: 464,
        quantity: 1,
        nutritionInfo: {
          carbs: 57,
          protein: 32,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc19b7a34cd092086729",
        productPLU: "2162130-1",
        productDescription:
          "C 40g | P 19g | F 16g\n\nSlider bun, patty, cheddar cheese, iceberg lettuce, white onion, dill pickle chips, mayo ketchup sauce.",
        productName: "FM Sliders",
        imageURL:
          "https://resizer.deliverect.com/gYXzCSrBPdFooHt3S19xzbSh9205wL6ziwlFQHMhLTI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBzbGlkZXIoMSktNjZmNTZmM2ZlNjQyNjQ5MzFhZTYwMDMwLnBuZw==",
        productCalories: 380,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 540,
    totalFmcCentsPrice: 540,
    totalFiatCentsPriceOriginal: 540,
    totalExternalFiatCentsPrice: 540,
    totalFmcCentsPriceOriginal: 540,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:00:06.810Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:02:06.806Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:02:26.755Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:19:10.982Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:19:10.982Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:19:32.935Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:33:54.524Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T14:00:06.810Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:00:18.215Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T14:00:06.810Z",
    updatedAt: "2024-10-23T14:33:54.866Z",
    fleetInfo: null,
  },
  {
    id: "67190159b7a34cd092206a39",
    sid: "241023-828415",
    coupons: [],
    menuId: "6718bc1fb7a34cd092086fda",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67190158b7a34cd092206a2b",
    firstName: "Lateefah Alabdalmughni",
    lastName: null,
    phoneNumber: "+96597890000",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "665b8f620eba0d08a56e30f3",
    delivery: {
      id: "67190158b7a34cd092206a2b",
      customerId: "665b8f620eba0d08a56e30f3",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "665b903093dc7e98b49204a2",
        name: "Home",
        lng: 48.01881138908168,
        lat: 29.358871459960938,
        city: "Kuwait",
        area: "Daiya",
        block: "4",
        street: "57",
        building: "8",
        floor: "",
        flat: "",
        phone: "+96597890000",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-01T21:18:40.860Z",
        updatedAt: "2024-10-20T09:11:42.634Z",
      },
      deliverectValidationId: "67190158147ac98c5c576ce2",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:09:52.718Z",
      estimatedDeliveryTime: "2024-10-23T14:24:51.725Z",
      estimatedDeliveryTimeEnd: "2024-10-23T14:56:00.000Z",
      orderId: "67190159b7a34cd092206a39",
      createdAt: "2024-10-23T13:59:52.809Z",
      updatedAt: "2024-10-23T14:01:05.776Z",
    },
    items: [
      {
        productId: "6718bc1fb7a34cd09208705a",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc1fb7a34cd09208709d",
        productPLU: "2161023-1",
        productDescription:
          "C 37g| P 17g| F 13g\nFries topped with caramelized onions, ground beef, pickled jalapeños, american cheese & our special sauce. \n\n*GOOD SOURCE OF PROTEIN,VITAMINB12*",
        productName: "Messy Fries",
        imageURL:
          "https://resizer.deliverect.com/uhTtheRt0RZXomxI_1n5O4G2cP8KUacFKd3MVV-dhio/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTUVTU1klMjBGUklFUy02NTc1OGZhM2QyZWUyNWQ5YmZkZDE4YWUucG5n",
        productCalories: 333,
        quantity: 1,
        nutritionInfo: {
          carbs: 37,
          protein: 17,
          fat: 13,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6718bc1fb7a34cd092087153",
            productPLU: "1907046-1",
            productName: "No Pickled Jalapeno",
            productDescription: "C -0.12g| P -0.05g| F 0g| cal -3| coins 0",
            productCalories: -3,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.12,
              protein: -0.05,
              fat: null,
            },
          },
          {
            productId: "6718bc1fb7a34cd092087155",
            productPLU: "1907066-1",
            productName: "No Caramelized Onion",
            productDescription: "C -9g| P -1g| F 0g| cal -38| coins 0",
            productCalories: -38,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -9,
              protein: -1,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 390,
    totalFmcCentsPrice: 390,
    totalFiatCentsPriceOriginal: 390,
    totalExternalFiatCentsPrice: 390,
    totalFmcCentsPriceOriginal: 390,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T13:59:53.631Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T14:01:08.684Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:03:11.745Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:15:51.239Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:15:51.239Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:33:30.538Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T15:02:07.741Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T13:59:53.631Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T14:00:05.082Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T13:59:53.631Z",
    updatedAt: "2024-10-23T15:02:08.076Z",
    fleetInfo: null,
  },
  {
    id: "671900e6b7a34cd0922051d4",
    sid: "241023-882434",
    coupons: [],
    menuId: "6718bc14b7a34cd092085f6e",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671900e5b7a34cd0922051c0",
    firstName: "Amani Alshuwaib",
    lastName: null,
    phoneNumber: "+96599712582",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "654b444526da1b2cf2060744",
    delivery: {
      id: "671900e5b7a34cd0922051c0",
      customerId: "654b444526da1b2cf2060744",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65674a3c02b2e21f1fc6a369",
        name: "Home",
        lng: 47.9858066,
        lat: 29.3174647,
        city: "Kuwait City",
        area: "Qortuba",
        block: "1",
        street: "1 Avenue 10",
        building: "40",
        floor: "",
        flat: "",
        phone: "+96599712582",
        additionalInfo: "Avenue 10",
        isSelected: true,
        createdAt: "2023-11-29T14:27:08.110Z",
        updatedAt: "2024-10-17T19:56:29.279Z",
      },
      deliverectValidationId: "671900e5982ae1ad6dbac243",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:07:57.338Z",
      estimatedDeliveryTime: "2024-10-23T14:22:56.383Z",
      estimatedDeliveryTimeEnd: "2024-10-23T14:54:00.000Z",
      orderId: "671900e6b7a34cd0922051d4",
      createdAt: "2024-10-23T13:57:57.443Z",
      updatedAt: "2024-10-23T13:59:06.870Z",
    },
    items: [
      {
        productId: "6718bc14b7a34cd092085fe8",
        productPLU: "2162082-1",
        productDescription:
          "C 44g| P 39g| F 6g\nChicken breast with baked eggplant, cauliflower, onions, carrots, red bell peppers in rice with CORIANDER\n\nGOOD SOURCE OF PROTEIN,VITAMIN A &C",
        productName: "Maqlouba",
        imageURL:
          "https://resizer.deliverect.com/ImlBCJvVJcyYJBWth7S7FEgLNUj1i0B0vhTLvEmLoDY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NjQ0YmNjMzc0OGYzNjdjMTMxNmU2ODgucG5n",
        productCalories: 386,
        quantity: 1,
        nutritionInfo: {
          carbs: 44,
          protein: 39,
          fat: 6,
        },
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6718bc14b7a34cd092086019",
        productPLU: "2163013-1",
        productDescription:
          "C 24g| P 12g| F 18g\nMixed greens, green bell peppers, cucumbers, tomatoes, spring onions, radishes, fresh mint, zaatar, parsley & grilled halloumi drizzled with a fatoush vinagrette & topped with zaatar croutons. \n\nGOOD SOURCE OF PROTIEN , VITAMIN K , A & CALCIUM",
        productName: "Fattoush",
        imageURL:
          "https://resizer.deliverect.com/5hPWwFSPNGl8nDgfpHyx8xc7_Gg5ln9BhI5g5gG9uoM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2lkZSUyMDItNjUxMDM3NjU4YmNmMGU3YzQ1YzVkMDA4LmpwZw==",
        productCalories: 306,
        quantity: 1,
        nutritionInfo: {
          carbs: 24,
          protein: 12,
          fat: 18,
        },
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 500,
    totalFmcCentsPrice: 500,
    totalFiatCentsPriceOriginal: 500,
    totalExternalFiatCentsPrice: 500,
    totalFmcCentsPriceOriginal: 500,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T13:57:58.084Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T13:59:09.103Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:03:02.299Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:11:34.859Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:11:34.859Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:11:57.845Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:31:59.447Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T13:57:58.084Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T13:58:20.016Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T13:57:58.084Z",
    updatedAt: "2024-10-23T14:31:59.794Z",
    fleetInfo: null,
  },
  {
    id: "671900e5b7a34cd0922051c3",
    sid: "241023-082982",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671900e4b7a34cd0922051b3",
    firstName: "Hessa almehri",
    lastName: null,
    phoneNumber: "+96567799931",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "669d3bd4ea7d0049ce6ec407",
    delivery: {
      id: "671900e4b7a34cd0922051b3",
      customerId: "669d3bd4ea7d0049ce6ec407",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "669d3c8edbd8df4f135df36e",
        name: "Home",
        lng: 47.99783337237301,
        lat: 29.336181640625,
        city: "Kuwait",
        area: "Rawda",
        block: "4",
        street: "46",
        building: "19",
        floor: "",
        flat: "",
        phone: "+96567799931",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-21T16:51:26.208Z",
        updatedAt: "2024-10-23T11:03:00.724Z",
      },
      deliverectValidationId: "671900e4c47d7273b31f296e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:07:56.568Z",
      estimatedDeliveryTime: "2024-10-23T14:22:55.009Z",
      estimatedDeliveryTimeEnd: "2024-10-23T14:54:00.000Z",
      orderId: "671900e5b7a34cd0922051c3",
      createdAt: "2024-10-23T13:57:56.681Z",
      updatedAt: "2024-10-23T13:59:07.115Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd092086787",
        productPLU: "2168002-1",
        productDescription:
          "C 4g | P 2g | F 2g|\nCreamy mushroom soup topped with parsley. ",
        productName: "Mushroom Soup",
        imageURL:
          "https://resizer.deliverect.com/VvtgR2CAH02ajyt1L7X3df8XtKjNQ2Kx-d7hCJY0Q1w/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBzb3VwLTY2NDM3YTg5ZTFhZTBmYmZhZDYyYmVhZC5qcGc=",
        productCalories: 42,
        quantity: 2,
        nutritionInfo: {
          carbs: 4,
          protein: 2,
          fat: 2,
        },
        fmcCentsPrice: 140,
        fiatCentsPrice: 140,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T13:57:57.490Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T13:59:06.536Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:00:46.943Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:20:46.728Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:20:46.728Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:32:46.373Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:49:25.743Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T13:57:57.490Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T13:58:09.118Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T13:57:57.490Z",
    updatedAt: "2024-10-23T14:49:26.881Z",
    fleetInfo: null,
  },
  {
    id: "67190089b7a34cd092203ca9",
    sid: "241023-065836",
    coupons: [],
    menuId: "6718bc19b7a34cd0920866c8",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67190088b7a34cd092203ca0",
    firstName: "Amel Alyahya ",
    lastName: null,
    phoneNumber: "+96599714554",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67093fe7aa779ec4a77e132f",
    delivery: {
      id: "67190088b7a34cd092203ca0",
      customerId: "67093fe7aa779ec4a77e132f",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67094175f495b693cdf07a17",
        name: "Mishrif",
        lng: 48.07017378509045,
        lat: 29.274966841875475,
        city: "Kuwait",
        area: "Mishref",
        block: "4",
        street: "8",
        building: "43",
        floor: "",
        flat: "",
        phone: "+96599714554",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-11T15:17:09.124Z",
        updatedAt: "2024-10-11T15:17:09.124Z",
      },
      deliverectValidationId: "671900884871f32c091a1002",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T14:06:24.547Z",
      estimatedDeliveryTime: "2024-10-23T14:21:22.816Z",
      estimatedDeliveryTimeEnd: "2024-10-23T14:53:00.000Z",
      orderId: "67190089b7a34cd092203ca9",
      createdAt: "2024-10-23T13:56:24.771Z",
      updatedAt: "2024-10-23T13:58:05.620Z",
    },
    items: [
      {
        productId: "6718bc19b7a34cd092086737",
        productPLU: "2162108-1",
        productDescription:
          "C 20g | P 39g | F 28g\nSesame chicken with snow peas topped with roasted white sesame served with veggie bulgur rice.\n\nGood source of Protein & Vitamin C",
        productName: "Sesame chicken & snow peas",
        imageURL:
          "https://resizer.deliverect.com/yyleY01jv8RuFpYLVQ0J9ADHIJK5V3bCte9f4aRy4cE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU2VzYW1lJTIwY2hpY2tlbiUyMCUyNiUyMHBlYXMtNjY0NGIwOWUzZmNiOWNjYjY2MmQ4NTVlLnBuZw==",
        productCalories: 488,
        quantity: 1,
        nutritionInfo: {
          carbs: 20,
          protein: 39,
          fat: 28,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 270,
    totalExternalFiatCentsPrice: 270,
    totalFmcCentsPriceOriginal: 270,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T13:56:25.520Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T13:58:07.849Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T14:00:51.391Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T14:13:08.368Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T14:13:08.369Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T14:13:30.314Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T14:38:01.940Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T13:56:25.520Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T13:56:37.187Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T13:56:25.521Z",
    updatedAt: "2024-10-23T14:38:02.278Z",
    fleetInfo: null,
  },
  {
    id: "67187688b7a34cd09201142c",
    sid: "241023-692822",
    coupons: [],
    menuId: "67186be3b7a34cd09200c58c",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67187687b7a34cd092011424",
    firstName: "Michael Mullett",
    lastName: null,
    phoneNumber: "+96599922458",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c5fa9175a6aa442d0f9c45",
    delivery: {
      id: "67187687b7a34cd092011424",
      customerId: "64c5fa9175a6aa442d0f9c45",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6549c59191bb38a0b80f33e6",
        name: "Massalah west tower ",
        lng: 48.00154159688406,
        lat: 29.37725830078125,
        city: "Al Kuwayt",
        area: "Bnied Al-Gar",
        block: "2",
        street: "Mohamad Abdulmohsin Al Kharafi St",
        building: "Tower 9",
        floor: "Car park",
        flat: "0",
        phone: "+96599922458",
        additionalInfo: "Massaleh west tower ",
        isSelected: true,
        createdAt: "2023-11-07T05:05:21.132Z",
        updatedAt: "2024-09-09T04:31:21.064Z",
      },
      deliverectValidationId: "671876873b9248833b403c9b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T04:17:35.533Z",
      estimatedDeliveryTime: "2024-10-23T04:32:34.278Z",
      estimatedDeliveryTimeEnd: "2024-10-23T05:04:00.000Z",
      orderId: "67187688b7a34cd09201142c",
      createdAt: "2024-10-23T04:07:35.759Z",
      updatedAt: "2024-10-23T04:09:05.177Z",
    },
    items: [
      {
        productId: "67186be3b7a34cd09200c5bd",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 5,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 650,
    totalFmcCentsPrice: 650,
    totalFiatCentsPriceOriginal: 650,
    totalExternalFiatCentsPrice: 650,
    totalFmcCentsPriceOriginal: 650,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T04:07:36.366Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T04:09:04.030Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T04:10:09.496Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T04:18:11.124Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T04:18:11.124Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T04:18:29.119Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T04:38:46.765Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T04:07:36.366Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T04:07:47.930Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T04:07:36.366Z",
    updatedAt: "2024-10-23T04:38:47.208Z",
    fleetInfo: null,
  },
  {
    id: "6718762ab7a34cd092010f5a",
    sid: "241023-634842",
    coupons: [],
    menuId: "67186beeb7a34cd09200d2ae",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67187629b7a34cd092010f4d",
    firstName: "شوق ",
    lastName: null,
    phoneNumber: "+96560461676",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66a65528ac4eb4f3eefcbbf8",
    delivery: {
      id: "67187629b7a34cd092010f4d",
      customerId: "66a65528ac4eb4f3eefcbbf8",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66a655e91683a2e7269c48bc",
        name: "عبدالله مبارك",
        lng: 47.89879437598638,
        lat: 29.235137939453125,
        city: "عبدالله المبارك الصباح",
        area: "عبدالله المبارك الصباح",
        block: "٦",
        street: "Street 620",
        building: "14",
        floor: "",
        flat: "",
        phone: "+96560461676",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-28T14:30:01.214Z",
        updatedAt: "2024-10-19T17:42:00.010Z",
      },
      deliverectValidationId: "671876293946960ae16d1c26",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T04:16:01.886Z",
      estimatedDeliveryTime: "2024-10-23T04:31:00.105Z",
      estimatedDeliveryTimeEnd: "2024-10-23T05:03:00.000Z",
      orderId: "6718762ab7a34cd092010f5a",
      createdAt: "2024-10-23T04:06:01.985Z",
      updatedAt: "2024-10-23T04:08:06.919Z",
    },
    items: [
      {
        productId: "67186beeb7a34cd09200d2db",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 26,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186beeb7a34cd09200d312",
        productPLU: "2202032-1",
        productDescription:
          "C 15.6g | P 0.9g | F 0.7g\nGreen apple, pineapple,watermelon & orange",
        productName: "Classic Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/2sV5-NAlUviox1mImQPHbEENE5_b1KyoHVL0t_T2CVE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2xhc3NpYyUyMGZydWl0LTY2NDM3YTM1ZTdlZDJhNDdlMjFhMzFmZC5wbmc=",
        productCalories: 73,
        quantity: 1,
        nutritionInfo: {
          carbs: 15.6,
          protein: 0.9,
          fat: 0.7,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186beeb7a34cd09200d332",
        productPLU: "2202042-1",
        productDescription:
          "C 20.5g | P 10.7g | F 6g\nColumbia coffee, Low fat Milk & Stevia",
        productName: "Spanish Latte",
        imageURL:
          "https://resizer.deliverect.com/9TlQsSxyHUFBMkzJXw4eSVQXrHLxw3ft31tZUhc9Y5M/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3BhbmlzaCUyMGxhdHRlLTY2NDM3YWEwM2ZjYjljY2I2NjJkN2QyNS5wbmc=",
        productCalories: 180,
        quantity: 1,
        nutritionInfo: {
          carbs: 20.5,
          protein: 10.7,
          fat: 6,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 460,
    totalFmcCentsPrice: 460,
    totalFiatCentsPriceOriginal: 460,
    totalExternalFiatCentsPrice: 460,
    totalFmcCentsPriceOriginal: 460,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T04:06:02.658Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T04:08:05.712Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T04:08:13.899Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T04:18:59.717Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T04:18:59.717Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T04:25:18.743Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T04:48:45.780Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T04:06:02.658Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T04:06:14.253Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T04:06:02.658Z",
    updatedAt: "2024-10-23T04:48:46.111Z",
    fleetInfo: null,
  },
  {
    id: "6718749eb7a34cd092010674",
    sid: "241023-290961",
    coupons: [],
    menuId: "67186bebb7a34cd09200ce4f",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6718749db7a34cd09201066a",
    firstName: "Hessa almehri",
    lastName: null,
    phoneNumber: "+96567799931",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "669d3bd4ea7d0049ce6ec407",
    delivery: {
      id: "6718749db7a34cd09201066a",
      customerId: "669d3bd4ea7d0049ce6ec407",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "669dd9f7dbd8df4f136fa49e",
        name: "Pada",
        lng: 48.01304306834936,
        lat: 29.33305040713408,
        city: "Hawally",
        area: "Hawally",
        block: "11",
        street: "Mousa Bin Nussair St",
        building: "Legal building",
        floor: "Ground",
        flat: "",
        phone: "+96567799931",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-22T04:03:03.328Z",
        updatedAt: "2024-10-23T03:58:58.082Z",
      },
      deliverectValidationId: "6718749d1cb32e3bced37cfc",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T04:09:25.227Z",
      estimatedDeliveryTime: "2024-10-23T04:24:24.015Z",
      estimatedDeliveryTimeEnd: "2024-10-23T04:56:00.000Z",
      orderId: "6718749eb7a34cd092010674",
      createdAt: "2024-10-23T03:59:25.316Z",
      updatedAt: "2024-10-23T04:01:14.157Z",
    },
    items: [
      {
        productId: "67186bebb7a34cd09200ceb1",
        productPLU: "2166034-1",
        productDescription:
          'C 15g | P 0.8g | F 0g\nMango chunks, strawberry, kiwi, pineapple & black grapes. Contains " Coriander"\n\nGOOD SOURCE OF  VITAMIN A & C',
        productName: "Tropical Fruits",
        imageURL:
          "https://resizer.deliverect.com/vsumDTgVlxB_i1FMSN31Gibj8VJsUCjPb86BH9kQLnQ/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRlJVSVQlMjBCT1dMLTY2NTAzOGM3M2QzMjMzOGZjZDA3YTdkMi5wbmc=",
        productCalories: 62,
        quantity: 1,
        nutritionInfo: {
          carbs: 15,
          protein: 0.8,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186bebb7a34cd09200ce80",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:59:26.086Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T04:01:13.390Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T04:19:12.383Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T04:19:12.384Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T04:19:12.384Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T04:19:34.484Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T04:36:57.038Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:59:26.086Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T03:59:37.591Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-23T03:59:26.086Z",
    updatedAt: "2024-10-23T05:27:18.248Z",
    fleetInfo: null,
  },
  {
    id: "67187446b7a34cd092010390",
    sid: "241023-122534",
    coupons: [],
    menuId: "67186beeb7a34cd09200d2ae",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67187446b7a34cd092010382",
    firstName: "Eman Aljrayed ",
    lastName: null,
    phoneNumber: "+96566693584",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66ab26c6f6ed0b2948a1d95a",
    delivery: {
      id: "67187446b7a34cd092010382",
      customerId: "66ab26c6f6ed0b2948a1d95a",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66ac957a1d6702aca3bfea9f",
        name: "Home ",
        lng: 48.00499768941302,
        lat: 29.29217529296875,
        city: "Kuwait",
        area: "السلام",
        block: "7",
        street: "720",
        building: "26",
        floor: "2",
        flat: "1",
        phone: "+96566693584",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-02T08:14:50.384Z",
        updatedAt: "2024-08-02T08:14:50.384Z",
      },
      deliverectValidationId: "671874464871f32c09d037f0",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T04:07:58.036Z",
      estimatedDeliveryTime: "2024-10-23T04:22:56.750Z",
      estimatedDeliveryTimeEnd: "2024-10-23T04:54:00.000Z",
      orderId: "67187446b7a34cd092010390",
      createdAt: "2024-10-23T03:57:58.129Z",
      updatedAt: "2024-10-23T03:59:05.626Z",
    },
    items: [
      {
        productId: "67186beeb7a34cd09200d2df",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186beeb7a34cd09200d309",
        productPLU: "2166017-1",
        productDescription:
          "C 16g| P 8g| F 11g\nCarrots, cucumber, red capsicum , celery with a hummus dip\n\nGood source of Vitamins & Dietary fibre",
        productName: "Veggie Crudites",
        imageURL:
          "https://resizer.deliverect.com/I3FIyxNKPcOIzVfjKga2oVRpt99xbhQflVKJw27qrWM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjA5Xy02NTA4MzM1ZjM5NGU3ODMxZTFiZDMyMDYucG5n",
        productCalories: 195,
        quantity: 1,
        nutritionInfo: {
          carbs: 16,
          protein: 8,
          fat: 11,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186beeb7a34cd09200d316",
        productPLU: "2166050-1",
        productDescription:
          "C 6g | P 2g | F 8g [%New%]\n\nPecan nuts, digestive, stevia, unsalted butter, cream cheese, heavy cream, egg, sour cream, all purpose flour, vanilla extract, syrup.",
        productName: "Pecan cheesecake",
        imageURL:
          "https://resizer.deliverect.com/V5P-zIm2i9d9zMnIsCSZ3RcaQiBGbqJ8glcvZNImsFM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hlZXNjYWtlJTIwYml0ZS02NmY5MGRhZmE0OGIxYjQxMWRmZTBiZTAucG5n",
        productCalories: 104,
        quantity: 1,
        nutritionInfo: {
          carbs: 6,
          protein: 2,
          fat: 8,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186beeb7a34cd09200d314",
        productPLU: "2202034-1",
        productDescription: "C 17.1g | P 1.9g | F 0.6g\nPomelo & Pomegranate",
        productName: "Seasonal Fruit Salad",
        imageURL:
          "https://resizer.deliverect.com/XTwC1dVyhg5--YDaNmhMWBtNNZpa3e6lzIaYdRmZhDA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2Vhc29uYWwlMjBmcnVpdCUyMHNhbGFkLTY2NDFkYmY5YWIxMzE0ZDg1Njc3OGRhNS5wbmc=",
        productCalories: 82,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.1,
          protein: 1.9,
          fat: 0.6,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 460,
    totalFmcCentsPrice: 460,
    totalFiatCentsPriceOriginal: 460,
    totalExternalFiatCentsPrice: 460,
    totalFmcCentsPriceOriginal: 460,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:57:58.786Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T03:59:03.621Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T04:05:38.806Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T04:05:57.095Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T04:05:57.095Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T04:13:52.949Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T04:37:51.270Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:57:58.786Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T03:58:10.362Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T03:57:58.786Z",
    updatedAt: "2024-10-23T04:37:51.891Z",
    fleetInfo: null,
  },
  {
    id: "671873afb7a34cd09200fdf7",
    sid: "241023-281949",
    coupons: [],
    menuId: "67186beeb7a34cd09200d2ae",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671873aeb7a34cd09200fdeb",
    firstName: "Khalid AlBader",
    lastName: null,
    phoneNumber: "+96597939924",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64f8b18fec522879c1b03c1c",
    delivery: {
      id: "671873aeb7a34cd09200fdeb",
      customerId: "64f8b18fec522879c1b03c1c",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65016e7834491db2e901e78d",
        name: "Home",
        lng: 47.97896660864353,
        lat: 29.30903255130506,
        city: "Kuwait",
        area: "Qortuba",
        block: "3",
        street: "Street 1 Avenue 1",
        building: "5",
        floor: "",
        flat: "",
        phone: "+96597939924",
        additionalInfo: "Opposite house 1",
        isSelected: true,
        createdAt: "2023-09-13T08:10:32.948Z",
        updatedAt: "2023-09-13T08:10:32.948Z",
      },
      deliverectValidationId: "671873ae3946960ae16c00b1",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T04:05:26.870Z",
      estimatedDeliveryTime: "2024-10-23T04:20:25.745Z",
      estimatedDeliveryTimeEnd: "2024-10-23T04:52:00.000Z",
      orderId: "671873afb7a34cd09200fdf7",
      createdAt: "2024-10-23T03:55:26.963Z",
      updatedAt: "2024-10-23T03:57:04.897Z",
    },
    items: [
      {
        productId: "67186beeb7a34cd09200d306",
        productPLU: "2166029-1",
        productDescription:
          "C 20g| P 3g| F 3g[%New%]\n\nCharred corn with tangy mayo sauce\n\nGOOD SOURCE OF VITAMIN B6",
        productName: "Mini Grilled Corn",
        imageURL:
          "https://resizer.deliverect.com/55C0BPdGu8KBIs3g2oHJe2DixhQfs99DVTEllZD_Nzg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNvcm4tNjcxMzg1YTQxYzZkNzRhMTg0YmY1NmFlLnBuZw==",
        productCalories: 119,
        quantity: 1,
        nutritionInfo: {
          carbs: 20,
          protein: 3,
          fat: 3,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186beeb7a34cd09200d30a",
        productPLU: "2166015-1",
        productDescription:
          "C 28g| P 12g| F 20g\nHummus served with pita chips.\n\nGood source of Vitamin E",
        productName: "Hummus",
        imageURL:
          "https://resizer.deliverect.com/72PxglK3MSw736Em3OxAtr0nEkCtbdmogjJvF3gGaJo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjAyLTY1MDgzMzQ1ZGViYWM1MDAxZjY1N2Q4OC5qcGc=",
        productCalories: 340,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 12,
          fat: 20,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186beeb7a34cd09200d2e1",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 270,
    totalExternalFiatCentsPrice: 270,
    totalFmcCentsPriceOriginal: 270,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:55:27.928Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T03:57:03.454Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T04:05:33.733Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T04:06:07.964Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T04:06:07.965Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T04:11:07.418Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T04:47:43.265Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:55:27.928Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T03:55:39.606Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-23T03:55:27.928Z",
    updatedAt: "2024-10-23T04:47:43.597Z",
    fleetInfo: null,
  },
  {
    id: "67187363b7a34cd09200f976",
    sid: "241023-887251",
    coupons: [],
    menuId: "67186bebb7a34cd09200ce4f",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67187362b7a34cd09200f969",
    firstName: "Israa Behbehani ",
    lastName: null,
    phoneNumber: "+96550050020",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66fb3e9c8951a675da111ebc",
    delivery: {
      id: "67187362b7a34cd09200f969",
      customerId: "66fb3e9c8951a675da111ebc",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66fb3f78ee3a746f7ff60e9b",
        name: "Home",
        lng: 48.008652459830046,
        lat: 29.310496906798694,
        city: "Kuwait City",
        area: "Surra",
        block: "3",
        street: "5",
        building: "10",
        floor: "",
        flat: "",
        phone: "+96550050020",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-01T00:16:56.174Z",
        updatedAt: "2024-10-22T15:04:33.078Z",
      },
      deliverectValidationId: "671873620d4544b052890efb",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T04:04:10.056Z",
      estimatedDeliveryTime: "2024-10-23T04:19:08.696Z",
      estimatedDeliveryTimeEnd: "2024-10-23T04:51:00.000Z",
      orderId: "67187363b7a34cd09200f976",
      createdAt: "2024-10-23T03:54:10.153Z",
      updatedAt: "2024-10-23T03:56:05.976Z",
    },
    items: [
      {
        productId: "67186bebb7a34cd09200ce79",
        productPLU: "2161021-1",
        productDescription:
          "C 16g| P 15g| F 16g\nEggs, turkish soudjouk, muhammara & labneh\n\nGOOD SOURCE OF PROTEIN",
        productName: "Turkish Egg Tacos",
        imageURL:
          "https://resizer.deliverect.com/-B_481BG16xkyYtWQaVbIvD-9tkBN12twZodyhjhhEA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2lzaCUyMGJyZWFrZmFzdCUyMHRhY29zKDEpLTY1NTQ4ZjU3YWI3MmIwMDAyY2I5ZTMyNi5wbmc=",
        productCalories: 268,
        quantity: 1,
        nutritionInfo: {
          carbs: 16,
          protein: 15,
          fat: 16,
        },
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186bebb7a34cd09200ce92",
        productPLU: "2167016-1",
        productDescription:
          "C 30g| P 10g| F 11g\nSourdough bread with low fat halloumi cheee, cucumber slices, black olives, guacamole and our special sauce with coriander.\n\nGood source of protein & calcium, healthy fat",
        productName: "Mini Halloumi Sandwich",
        imageURL:
          "https://resizer.deliverect.com/m-55NsCVcGO4zSYvIr1NjPvniHdSTA8w7DLuZLWTUeM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTWluaSUyMGhhbGxvdW1pJTIwc2FuZHdpY2gtNjRmNDhmYWYxOGIxNGMwMDJjZjg0ZDY2LmpwZw==",
        productCalories: 259,
        quantity: 1,
        nutritionInfo: {
          carbs: 30,
          protein: 10,
          fat: 11,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 350,
    totalExternalFiatCentsPrice: 350,
    totalFmcCentsPriceOriginal: 350,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:54:11.219Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T03:56:04.210Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T04:07:23.739Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T04:07:23.739Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T04:07:23.739Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T04:07:45.626Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T04:20:05.966Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:54:11.219Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T03:54:22.751Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-23T03:54:11.219Z",
    updatedAt: "2024-10-23T05:27:07.131Z",
    fleetInfo: null,
  },
  {
    id: "67187197b7a34cd09200f04c",
    sid: "241023-074115",
    coupons: [],
    menuId: "67186be3b7a34cd09200c58c",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67187196b7a34cd09200f041",
    firstName: "Rawan D",
    lastName: null,
    phoneNumber: "+96599499748",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64f82a3eec522879c1af9e0b",
    delivery: {
      id: "67187196b7a34cd09200f041",
      customerId: "64f82a3eec522879c1af9e0b",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670eaa09f495b693cdd6b424",
        name: "Kuwait flour mills and bakeries company",
        lng: 47.936477065086365,
        lat: 29.347335838145916,
        city: "Shuwaikh Industrial",
        area: "Shuwaikh Port",
        block: "4th street",
        street: "Jamal Abdul Nasser St",
        building: "KFMB (quality control department)",
        floor: "",
        flat: "",
        phone: "+96599499748",
        additionalInfo: "Call for more diriction",
        isSelected: true,
        createdAt: "2024-10-15T17:44:41.817Z",
        updatedAt: "2024-10-19T04:06:48.199Z",
      },
      deliverectValidationId: "67187196e9cbdcf0e3682958",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T03:56:30.673Z",
      estimatedDeliveryTime: "2024-10-23T04:11:29.460Z",
      estimatedDeliveryTimeEnd: "2024-10-23T04:43:00.000Z",
      orderId: "67187197b7a34cd09200f04c",
      createdAt: "2024-10-23T03:46:30.798Z",
      updatedAt: "2024-10-23T03:48:10.944Z",
    },
    items: [
      {
        productId: "67186be3b7a34cd09200c5ce",
        productPLU: "2167007-1",
        productDescription:
          "C 60g| P 24g| F 17g [%Best seller%]\nSmoked turkey with grilled turkey bacon, avocado puree, rocca, pesto & emmental cheese all in sourdough toast. ",
        productName: "Turkey Pesto",
        imageURL:
          "https://resizer.deliverect.com/d4E--e7KeFg5pz7n8OJh2vgevZuUdJEXur9hGCWc7sY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2V5JTIwc2FuZHdpY2gtNjRmNDkwMzljNDA4OTczMWUzMzk4OTc0LmpwZw==",
        productCalories: 489,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 24,
          fat: 17,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186be3b7a34cd09200c616",
        productPLU: "2202040-1",
        productDescription: "C 4.8g | P 0g | F 0g\nOrange, Grapefruit & Lemon",
        productName: "Immunity Shot",
        imageURL:
          "https://resizer.deliverect.com/WkO7ZBCzCZBVTRau0FCMoAizLyoqJmikHQl5iJRWhJs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaW1tdW5pdHklMjBzaG90LTY2NDFkYjEwYWIxMzE0ZDg1Njc3OGNkZS5wbmc=",
        productCalories: 19,
        quantity: 1,
        nutritionInfo: {
          carbs: 4.8,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 310,
    totalFmcCentsPrice: 310,
    totalFiatCentsPriceOriginal: 310,
    totalExternalFiatCentsPrice: 310,
    totalFmcCentsPriceOriginal: 310,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:46:31.469Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T03:48:07.308Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T03:52:41.744Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T03:54:03.055Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T03:54:03.056Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T04:03:30.575Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T04:13:13.220Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:46:31.469Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T03:46:43.073Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T03:46:31.469Z",
    updatedAt: "2024-10-23T04:13:13.680Z",
    fleetInfo: null,
  },
  {
    id: "6718708bb7a34cd09200edc5",
    sid: "241023-939755",
    coupons: [],
    menuId: "67186beeb7a34cd09200d2ae",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6718708ab7a34cd09200edba",
    firstName: "Eman Faisal alsafi",
    lastName: null,
    phoneNumber: "+96597132228",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6714d569b58bc473083b3801",
    delivery: {
      id: "6718708ab7a34cd09200edba",
      customerId: "6714d569b58bc473083b3801",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6714d7a7b7a34cd0924a2cf1",
        name: "المنزل",
        lng: 47.999500755777966,
        lat: 29.30035400390625,
        city: "Kuwait",
        area: "الصديق",
        block: "1",
        street: "104",
        building: "836",
        floor: "",
        flat: "",
        phone: "+96597132228",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-20T10:12:55.270Z",
        updatedAt: "2024-10-20T10:12:55.270Z",
      },
      deliverectValidationId: "6718708ae7e718a7ef1ddb3a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T03:52:02.589Z",
      estimatedDeliveryTime: "2024-10-23T04:07:01.060Z",
      estimatedDeliveryTimeEnd: "2024-10-23T04:39:00.000Z",
      orderId: "6718708bb7a34cd09200edc5",
      createdAt: "2024-10-23T03:42:02.917Z",
      updatedAt: "2024-10-23T03:44:05.971Z",
    },
    items: [
      {
        productId: "67186beeb7a34cd09200d2e7",
        productPLU: "2162079-1",
        productDescription:
          "C 49g| P 12g| F 17g\nBaked cauliflower with  eggplant, potato, cucumber and falafel drizzled with tahini sauce in saj bread. \n\nGOOD SOURCE OF PROTEIN VITAMINS AND FIBER",
        productName: "Mushakal Wrap",
        imageURL:
          "https://resizer.deliverect.com/GAAYXxN6zgWfrJVNB3YeUO6Fr9gMhYKrP5SEQdiGD-s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaGFrZWwlMjB3cmFwLTY1NzU4ZmRlYjAxYTgyMzljNzk1ZmE2My5qcGc=",
        productCalories: 397,
        quantity: 2,
        nutritionInfo: {
          carbs: 49,
          protein: 12,
          fat: 17,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186beeb7a34cd09200d317",
        productPLU: "2165026-1",
        productDescription: "C 13.4g | P 11g | F 12.2g\n",
        productName: "Peanut Butter Protein Bar",
        imageURL:
          "https://resizer.deliverect.com/3ZwgGn9CBKj9EAZV281gDw-Z9vljTvZQNDf5O18NRFY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVhbnV0JTIwYnV0dGVyLTY2YTJlMzE4YmIwZjQ2YzhjMWQwMmI5Yy5wbmc=",
        productCalories: 222,
        quantity: 1,
        nutritionInfo: {
          carbs: 13.4,
          protein: 11,
          fat: 12.2,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:42:03.779Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T03:44:04.253Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T03:47:05.216Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T03:53:10.405Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T03:53:10.405Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T03:54:28.612Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T04:12:07.658Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:42:03.779Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T03:42:15.434Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T03:42:03.779Z",
    updatedAt: "2024-10-23T04:12:07.993Z",
    fleetInfo: null,
  },
  {
    id: "67187000b7a34cd09200ec77",
    sid: "241023-527191",
    coupons: [],
    menuId: "67186be7b7a34cd09200c9eb",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67187000b7a34cd09200ec5d",
    firstName: "Fatem",
    lastName: null,
    phoneNumber: "+96599410809",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65a8bcd3cd9aef3291a9a2b2",
    delivery: {
      id: "67187000b7a34cd09200ec5d",
      customerId: "65a8bcd3cd9aef3291a9a2b2",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65dda950293bdc4801b1f4e2",
        name: "Knpc",
        lng: 48.0876249,
        lat: 29.09300709999999,
        city: "Ahmadi",
        area: "Mid Ahmadi",
        block: "12",
        street: "Hamad al zuwayer st",
        building: "Blue building",
        floor: "Gate 2 inside",
        flat: "",
        phone: "+96599410809",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-02-27T09:20:16.387Z",
        updatedAt: "2024-10-23T03:36:34.160Z",
      },
      deliverectValidationId: "67186fff3bee773669b5b02d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T03:49:43.962Z",
      estimatedDeliveryTime: "2024-10-23T04:04:42.991Z",
      estimatedDeliveryTimeEnd: "2024-10-23T04:36:00.000Z",
      orderId: "67187000b7a34cd09200ec77",
      createdAt: "2024-10-23T03:39:44.056Z",
      updatedAt: "2024-10-23T03:41:06.191Z",
    },
    items: [
      {
        productId: "67186be7b7a34cd09200ca1d",
        productPLU: "2167014-1",
        productDescription:
          "C 32g| P 14g| F 8g\nEnglish muffin with egg whites, blanched spinach, mozzarella cheese and our breakfast sauce.",
        productName: "FM Egg Muffin",
        imageURL:
          "https://resizer.deliverect.com/UDWwpJ2NagrTgNTXcD-YQp6Hbon4KgO_xVwUVvV2eKE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NGJmYTJlMzE0ODFjMTAwMjZjYTEwNTkucG5n",
        productCalories: 256,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 14,
          fat: 8,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186be7b7a34cd09200ca5d",
        productPLU: "2165003-1",
        productDescription: "C 12g | P 2g | F 9g\n",
        productName: "Peanut Bites",
        imageURL:
          "https://resizer.deliverect.com/S2e9SlQ_6ewwX1Z-Pg_RtXB9e4OxquLa6-xOxnwoCJM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNTMtNjRiN2JjNmFkMGE4N2MwMDFmZTYwZjBhLnBuZw==",
        productCalories: 139,
        quantity: 1,
        nutritionInfo: {
          carbs: 12,
          protein: 2,
          fat: 9,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186be7b7a34cd09200ca3a",
        productPLU: "2166044-1",
        productDescription: "C 29.6g | P 13g | F 8.2g",
        productName: "PRO CHIPS Spicy Cheese",
        imageURL:
          "https://resizer.deliverect.com/DI3ZryM4jZ6wfY4bMCrM8iN_pJIJi0F2EnLURGnyrDA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNS02NmM4NWU0MmIzOTY1OGViZWUxZGE2MzYucG5n",
        productCalories: 246,
        quantity: 1,
        nutritionInfo: {
          carbs: 29.6,
          protein: 13,
          fat: 8.2,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:39:44.623Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T03:41:04.449Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T03:41:17.112Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T03:45:28.091Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T03:45:28.091Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T04:02:48.231Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T04:40:58.489Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:39:44.623Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T03:39:48.012Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T03:39:44.623Z",
    updatedAt: "2024-10-23T04:40:58.847Z",
    fleetInfo: null,
  },
  {
    id: "67186ff3b7a34cd09200eb99",
    sid: "241023-920471",
    coupons: [],
    menuId: "67186bebb7a34cd09200ce4f",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67186ff2b7a34cd09200eb8c",
    firstName: "Dalal Abdullah AlHarban",
    lastName: null,
    phoneNumber: "+96599617779",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65d496b9b07238805078ab73",
    delivery: {
      id: "67186ff2b7a34cd09200eb8c",
      customerId: "65d496b9b07238805078ab73",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65d4f3a3393070be7ec3862f",
        name: "Home",
        lng: 48.002124801278114,
        lat: 29.319851516041425,
        city: "Kuwait",
        area: "Surra",
        block: "6",
        street: "4 St",
        building: "House 2",
        floor: "",
        flat: "",
        phone: "+96599617779",
        additionalInfo: "Grey house on the corner",
        isSelected: true,
        createdAt: "2024-02-20T18:46:59.365Z",
        updatedAt: "2024-02-20T18:46:59.365Z",
      },
      deliverectValidationId: "67186ff2b4faa71444ce8469",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T03:49:30.587Z",
      estimatedDeliveryTime: "2024-10-23T04:04:29.313Z",
      estimatedDeliveryTimeEnd: "2024-10-23T04:36:00.000Z",
      orderId: "67186ff3b7a34cd09200eb99",
      createdAt: "2024-10-23T03:39:30.700Z",
      updatedAt: "2024-10-23T03:41:08.266Z",
    },
    items: [
      {
        productId: "67186bebb7a34cd09200ce7c",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 26,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186bebb7a34cd09200ce92",
        productPLU: "2167016-1",
        productDescription:
          "C 30g| P 10g| F 11g\nSourdough bread with low fat halloumi cheee, cucumber slices, black olives, guacamole and our special sauce with coriander.\n\nGood source of protein & calcium, healthy fat",
        productName: "Mini Halloumi Sandwich",
        imageURL:
          "https://resizer.deliverect.com/m-55NsCVcGO4zSYvIr1NjPvniHdSTA8w7DLuZLWTUeM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTWluaSUyMGhhbGxvdW1pJTIwc2FuZHdpY2gtNjRmNDhmYWYxOGIxNGMwMDJjZjg0ZDY2LmpwZw==",
        productCalories: 259,
        quantity: 1,
        nutritionInfo: {
          carbs: 30,
          protein: 10,
          fat: 11,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186bebb7a34cd09200ceac",
        productPLU: "2166011-1",
        productDescription:
          "C 58g| P 11g| F 13g\nChocolate chia & flaxseed oats with peanut butter biscuit, orange zest, hazelnut chocolate and chocolate coverture.\n\nGood source of Vitamins & Dietary fibre",
        productName: "Choco Chia Oats",
        imageURL:
          "https://resizer.deliverect.com/Isi8w_ThjYiyjTMxCBPpzL50QvwOgBOSXLSqnE3bNhM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMi02NTExNmVmYzBhODZkZTcwYTAzMjg0NzkuanBn",
        productCalories: 393,
        quantity: 1,
        nutritionInfo: {
          carbs: 58,
          protein: 11,
          fat: 13,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 350,
    totalExternalFiatCentsPrice: 350,
    totalFmcCentsPriceOriginal: 350,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:39:31.371Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T03:41:04.356Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T03:58:08.792Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T03:58:08.792Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T03:58:08.793Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T03:58:30.639Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T04:12:45.099Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:39:31.371Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T03:39:36.823Z",
      },
    ],
    cutlery: true,
    hasRating: true,
    timeslot: null,
    createdAt: "2024-10-23T03:39:31.371Z",
    updatedAt: "2024-10-23T10:15:59.539Z",
    fleetInfo: null,
  },
  {
    id: "67186fb3b7a34cd09200e949",
    sid: "241023-592393",
    coupons: [],
    menuId: "67186beeb7a34cd09200d2ae",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67186fb2b7a34cd09200e940",
    firstName: "Khaled Alkhaled",
    lastName: null,
    phoneNumber: "+96550555566",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66373cfc89ba1a51b12be40e",
    delivery: {
      id: "67186fb2b7a34cd09200e940",
      customerId: "66373cfc89ba1a51b12be40e",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66373df58b0d42e9ca4d4e4d",
        name: "Home",
        lng: 47.97099441289902,
        lat: 29.31639240168588,
        city: "Kuwait City",
        area: "Yarmouk",
        block: "1",
        street: "1",
        building: "21",
        floor: "",
        flat: "",
        phone: "+96550555566",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-05T08:06:13.233Z",
        updatedAt: "2024-08-25T13:27:50.588Z",
      },
      deliverectValidationId: "67186fb2e7e718a7ef1bd8b0",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T03:48:26.595Z",
      estimatedDeliveryTime: "2024-10-23T04:03:25.360Z",
      estimatedDeliveryTimeEnd: "2024-10-23T04:35:00.000Z",
      orderId: "67186fb3b7a34cd09200e949",
      createdAt: "2024-10-23T03:38:26.813Z",
      updatedAt: "2024-10-23T03:40:05.125Z",
    },
    items: [
      {
        productId: "67186beeb7a34cd09200d2db",
        productPLU: "2161019-1",
        productDescription:
          "C 4g| P 26g| F 12g\nEgg omlete with red & green bell peppers, red onions, tomato paste, cheedar cheese & cottage cheese, served with a rocca cherry tomato salad.\n\nGOOD SOURCE OF PROTEIN",
        productName: "Spanish Omlette",
        imageURL:
          "https://resizer.deliverect.com/94pCOCD70Mqbvn7cVMDt2-bfT5r9m_TOTkidcFeAxMw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BhbmlzaCUyMG9tbGV0dGUoMSktNjU1NDhmNDNmYTUzZGQwMDFlZTk0Njc5LnBuZw==",
        productCalories: 228,
        quantity: 2,
        nutritionInfo: {
          carbs: 4,
          protein: 26,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:38:27.476Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T03:40:03.413Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T03:47:05.021Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T03:52:59.438Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T03:52:59.438Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T03:54:00.137Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T04:10:20.275Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:38:27.476Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T03:38:39.424Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T03:38:27.476Z",
    updatedAt: "2024-10-23T04:10:20.617Z",
    fleetInfo: null,
  },
  {
    id: "67186f50b7a34cd09200e4d2",
    sid: "241023-769185",
    coupons: [],
    menuId: "67186beeb7a34cd09200d2ae",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67186f4fb7a34cd09200e4c5",
    firstName: "مشاري مشعل العتيبي ",
    lastName: null,
    phoneNumber: "+96599279725",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65c50dd8914bef1d8305d09a",
    delivery: {
      id: "67186f4fb7a34cd09200e4c5",
      customerId: "65c50dd8914bef1d8305d09a",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66dd2004ced6c5247eff6fab",
        name: "مدرسه معاذ بن جبل ",
        lng: 47.978831494784814,
        lat: 29.29486083984375,
        city: "Kuwait",
        area: "خيطان",
        block: "5",
        street: "ناصر الجبري ",
        building: "مدرسه معاذ بن جبل ",
        floor: "",
        flat: "",
        phone: "+96599279725",
        additionalInfo: "مدرسه ",
        isSelected: true,
        createdAt: "2024-09-08T03:54:44.397Z",
        updatedAt: "2024-10-23T03:35:17.551Z",
      },
      deliverectValidationId: "67186f4f88e23371a93a2a24",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T03:46:47.146Z",
      estimatedDeliveryTime: "2024-10-23T04:01:45.836Z",
      estimatedDeliveryTimeEnd: "2024-10-23T04:33:00.000Z",
      orderId: "67186f50b7a34cd09200e4d2",
      createdAt: "2024-10-23T03:36:47.275Z",
      updatedAt: "2024-10-23T03:38:05.068Z",
    },
    items: [
      {
        productId: "67186beeb7a34cd09200d2ef",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/1uWrRlXfTE6RYwQoa2vZDD0miTwao6DizisuqC3ZQlY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZWFiOGVjNDM1MDAxYTRjMGJkYy5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "67186beeb7a34cd09200d34b",
            productPLU: "1907028-1",
            productName: "No Pickles",
            productDescription: "C -0.2g| P 0g| F 0g| cal -1| coins 0",
            productCalories: -1,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.2,
              protein: null,
              fat: null,
            },
          },
          {
            productId: "67186beeb7a34cd09200d34f",
            productPLU: "1907029-1",
            productName: "No Tomatoes",
            productDescription: "C -1g| P 0g| F 0g| cal -4.5| coins 0",
            productCalories: -4.5,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: null,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "67186beeb7a34cd09200d2f3",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:36:48.010Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T03:38:03.656Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T03:47:05.112Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T03:51:52.407Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T03:51:52.408Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T03:54:39.805Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T04:07:45.696Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:36:48.010Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T03:36:59.872Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T03:36:48.010Z",
    updatedAt: "2024-10-23T04:07:46.037Z",
    fleetInfo: null,
  },
  {
    id: "67186e05b7a34cd09200dc1e",
    sid: "241023-607185",
    coupons: [],
    menuId: "67186beeb7a34cd09200d2ae",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67186e04b7a34cd09200dc14",
    firstName: "ABDULLAH ALJASSAR",
    lastName: null,
    phoneNumber: "+96596968338",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64e63b9070771121d14e6fba",
    delivery: {
      id: "67186e04b7a34cd09200dc14",
      customerId: "64e63b9070771121d14e6fba",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64f3849f98e65fe43e64271c",
        name: "HOME",
        lng: 47.98831310123205,
        lat: 29.315722361716865,
        city: "Kuwait",
        area: "Qortuba",
        block: "1",
        street: "4 Avenue 2",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96596968338",
        additionalInfo: "Door number 6A ",
        isSelected: true,
        createdAt: "2023-09-02T18:53:19.924Z",
        updatedAt: "2024-09-12T07:06:10.417Z",
      },
      deliverectValidationId: "67186e04b4faa71444ca57db",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-23T03:41:16.142Z",
      estimatedDeliveryTime: "2024-10-23T03:56:14.589Z",
      estimatedDeliveryTimeEnd: "2024-10-23T04:28:00.000Z",
      orderId: "67186e05b7a34cd09200dc1e",
      createdAt: "2024-10-23T03:31:16.266Z",
      updatedAt: "2024-10-23T03:33:05.047Z",
    },
    items: [
      {
        productId: "67186beeb7a34cd09200d2e1",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 3,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "67186beeb7a34cd09200d336",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        nutritionInfo: {
          carbs: 5,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 470,
    totalFmcCentsPrice: 470,
    totalFiatCentsPriceOriginal: 470,
    totalExternalFiatCentsPrice: 470,
    totalFmcCentsPriceOriginal: 470,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:31:17.159Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-23T03:33:04.342Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-23T03:37:50.939Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-23T03:46:54.177Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-23T03:46:54.177Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-23T03:54:11.477Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-23T04:18:06.479Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-23T03:31:17.159Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-23T03:31:29.014Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-23T03:31:17.159Z",
    updatedAt: "2024-10-23T04:18:07.017Z",
    fleetInfo: null,
  },
  {
    id: "67180ae7b7a34cd092ffbbb4",
    sid: "241022-143056",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67180ae7b7a34cd092ffbbab",
    firstName: "Saad Alabdullah",
    lastName: null,
    phoneNumber: "+96595560326",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66c41af56c59ceb40a781b36",
    delivery: {
      id: "67180ae7b7a34cd092ffbbab",
      customerId: "66c41af56c59ceb40a781b36",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66c41c5b6a31f4fea78ea4c5",
        name: "Home",
        lng: 47.98917040228844,
        lat: 29.291154645027653,
        city: "Kuwait",
        area: "Al-Siddeeq",
        block: "5",
        street: "515 St",
        building: "74",
        floor: "3",
        flat: "3",
        phone: "+96595560326",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-20T04:32:27.403Z",
        updatedAt: "2024-10-22T20:27:43.028Z",
      },
      deliverectValidationId: "67180ae6dda2cbde3370af93",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:38:22.894Z",
      estimatedDeliveryTime: "2024-10-22T20:53:21.894Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:25:00.000Z",
      orderId: "67180ae7b7a34cd092ffbbb4",
      createdAt: "2024-10-22T20:28:23.003Z",
      updatedAt: "2024-10-22T20:30:06.357Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f6070b",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 300,
    totalFmcCentsPrice: 300,
    totalFiatCentsPriceOriginal: 300,
    totalExternalFiatCentsPrice: 300,
    totalFmcCentsPriceOriginal: 300,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:28:23.550Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:30:36.834Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:31:11.473Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:39:41.099Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:39:41.100Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:40:02.953Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:53:23.913Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:28:23.550Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:28:35.054Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:28:23.550Z",
    updatedAt: "2024-10-22T20:53:24.253Z",
    fleetInfo: null,
  },
  {
    id: "67180ac7b7a34cd092ffb969",
    sid: "241022-761557",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67180ac5b7a34cd092ffb95d",
    firstName: "Ghanem AlGhanem",
    lastName: null,
    phoneNumber: "+96550097317",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6652f23147e7155f92704cc1",
    delivery: {
      id: "67180ac5b7a34cd092ffb95d",
      customerId: "6652f23147e7155f92704cc1",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66ec883ef646428b83c2ac98",
        name: "ديوان القبندي",
        lng: 48.02854288369417,
        lat: 29.27821948592254,
        city: "Kuwait",
        area: "Hateen",
        block: "3",
        street: "316",
        building: "4",
        floor: "",
        flat: "",
        phone: "+96550097317",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-19T20:23:26.155Z",
        updatedAt: "2024-10-22T19:42:10.070Z",
      },
      deliverectValidationId: "67180ac4827135858ba34f21",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:37:48.937Z",
      estimatedDeliveryTime: "2024-10-22T20:52:47.461Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:24:00.000Z",
      orderId: "67180ac7b7a34cd092ffb969",
      createdAt: "2024-10-22T20:27:49.040Z",
      updatedAt: "2024-10-22T20:29:06.921Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f60732",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60703",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e84fb7a34cd092f607c5",
            productPLU: "1906019-1",
            productName: "Extra  Chicken",
            productDescription: "C 0g| P 18g| F 1.5g| cal 87| coins 0.7",
            productCalories: 87,
            quantity: 1,
            fmcCentsPrice: 70,
            fiatCentsPrice: 70,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: 18,
              fat: 1.5,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 430,
    totalFmcCentsPrice: 430,
    totalFiatCentsPriceOriginal: 430,
    totalExternalFiatCentsPrice: 430,
    totalFmcCentsPriceOriginal: 430,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:27:51.093Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:29:11.226Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:30:06.128Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:42:39.563Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:42:39.563Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:44:16.651Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T21:03:03.073Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:27:51.093Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:28:02.631Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:27:51.093Z",
    updatedAt: "2024-10-22T21:03:03.624Z",
    fleetInfo: null,
  },
  {
    id: "67180a77b7a34cd092ffaef8",
    sid: "241022-228534",
    coupons: [],
    menuId: "6717e842b7a34cd092f5f5cf",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "67180a76b7a34cd092ffae1a",
    firstName: "Nusaiba Bouyabes ",
    lastName: null,
    phoneNumber: "+96555548842",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c3d8e975a6aa442d0f2366",
    delivery: {
      id: "67180a76b7a34cd092ffae1a",
      customerId: "64c3d8e975a6aa442d0f2366",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64cf51765e8f4fe1fb18afad",
        name: "Home",
        lng: 48.066659923642874,
        lat: 29.25842246687821,
        city: "Sabah Al Salem",
        area: "Sabah Al Salem",
        block: "4",
        street: "5",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96555548842",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-06T07:53:26.512Z",
        updatedAt: "2024-10-22T19:55:36.788Z",
      },
      deliverectValidationId: "67180a76ad7b17dcd0692a4f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:36:30.035Z",
      estimatedDeliveryTime: "2024-10-22T20:51:28.839Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:23:00.000Z",
      orderId: "67180a77b7a34cd092ffaef8",
      createdAt: "2024-10-22T20:26:30.205Z",
      updatedAt: "2024-10-22T20:28:05.446Z",
    },
    items: [
      {
        productId: "6717e842b7a34cd092f5f653",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f695",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:26:31.219Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:28:06.522Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:33:01.326Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:36:17.638Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:36:17.638Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:43:32.839Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:59:40.008Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:26:31.219Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:26:42.676Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:26:31.219Z",
    updatedAt: "2024-10-22T20:59:40.367Z",
    fleetInfo: null,
  },
  {
    id: "67180984b7a34cd092ff8303",
    sid: "241022-083710",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67180984b7a34cd092ff82f3",
    firstName: "Nasser Alomran",
    lastName: null,
    phoneNumber: "+96597799933",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66b5f044eb3fe809b1bb45e0",
    delivery: {
      id: "67180984b7a34cd092ff82f3",
      customerId: "66b5f044eb3fe809b1bb45e0",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66b626fc0846fbb356433da1",
        name: "Home",
        lng: 48.045402746647596,
        lat: 29.336984060073505,
        city: "Salmiya",
        area: "Salmiya",
        block: "11",
        street: "Jabir Bin Hayyan St",
        building: "1",
        floor: "5",
        flat: "9",
        phone: "+96597799933",
        additionalInfo: "Sugar salon building",
        isSelected: true,
        createdAt: "2024-08-09T14:26:04.028Z",
        updatedAt: "2024-09-27T11:02:03.049Z",
      },
      deliverectValidationId: "671809829d15dbbf9f84c382",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:32:26.912Z",
      estimatedDeliveryTime: "2024-10-22T20:47:25.712Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:19:00.000Z",
      orderId: "67180984b7a34cd092ff8303",
      createdAt: "2024-10-22T20:22:28.075Z",
      updatedAt: "2024-10-22T20:24:06.781Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed29",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed33",
        productPLU: "2162123-1",
        productDescription:
          "C 32g | P 23g | F 14g \nGlazed bun, beef pattie, mozzarella cheese slice, mushroom sauce with light mayo ",
        productName: "Mushroom Burger",
        imageURL:
          "https://resizer.deliverect.com/ha2uMGKS1nYV_BDUXrhrnd4juPdkh4UN_AL73RV3EbM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBidXJnZXItNjY5MDY2ODYxMGNhZmJmNDkzMTIzNGQ5LnBuZw==",
        productCalories: 346,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 23,
          fat: 14,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e83bb7a34cd092f5edda",
            productPLU: "1906004-1",
            productName: "Extra  Beef Pattie",
            productDescription: "C 2g| P 6g| F 3g| cal 59| coins 0.5",
            productCalories: 59,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 2,
              protein: 6,
              fat: 3,
            },
          },
        ],
      },
      {
        productId: "6717e83bb7a34cd092f5ed8f",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 490,
    totalFmcCentsPrice: 490,
    totalFiatCentsPriceOriginal: 490,
    totalExternalFiatCentsPrice: 490,
    totalFmcCentsPriceOriginal: 490,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:22:28.720Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:24:29.302Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:26:33.610Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:35:37.071Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:35:37.071Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:35:58.920Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:48:16.619Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:22:28.720Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:22:40.087Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:22:28.720Z",
    updatedAt: "2024-10-22T20:48:17.202Z",
    fleetInfo: null,
  },
  {
    id: "67180956b7a34cd092ff79fd",
    sid: "241022-143065",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67180956b7a34cd092ff79ed",
    firstName: "Batoul almousawi",
    lastName: null,
    phoneNumber: "+96567600690",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "663bed7b7015a1cae4818dd7",
    delivery: {
      id: "67180956b7a34cd092ff79ed",
      customerId: "663bed7b7015a1cae4818dd7",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "663bee6f012c929b36b102b5",
        name: "Batoul",
        lng: 48.02224958315492,
        lat: 29.279273701265158,
        city: "Kuwait City",
        area: "Hateen",
        block: "3",
        street: "11",
        building: "10",
        floor: "",
        flat: "",
        phone: "+96567600690",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-08T21:28:15.927Z",
        updatedAt: "2024-05-08T21:28:15.927Z",
      },
      deliverectValidationId: "67180956147ac98c5cd2d759",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:31:42.093Z",
      estimatedDeliveryTime: "2024-10-22T20:46:41.071Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:18:00.000Z",
      orderId: "67180956b7a34cd092ff79fd",
      createdAt: "2024-10-22T20:21:42.188Z",
      updatedAt: "2024-10-22T20:23:06.254Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f60711",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 24,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "Very well done chicken please extra sauce\n",
        subItems: [
          {
            productId: "6717e84fb7a34cd092f607d3",
            productPLU: "1907028-1",
            productName: "No Pickles",
            productDescription: "C -0.2g| P 0g| F 0g| cal -1| coins 0",
            productCalories: -1,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.2,
              protein: null,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "6717e84fb7a34cd092f60746",
        productPLU: "2166020-1",
        productDescription:
          "C 13g| P 2g| F 1g\nBeetroots tossed in olive oil, lemon juice and fresh coriander.\n\nGood source of dietary Fibre, Iron, Potassium and Vitamin C\nContains garlic.",
        productName: "Beetroot Pot",
        imageURL:
          "https://resizer.deliverect.com/4QQmAm_YErxn11xFj_N8IRMBlHt61yeQWJR1VR9vwug/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNy02NTEwMzhmNDI2N2RiNTQwNTI2ZTM4MTguanBn",
        productCalories: 70,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 2,
          fat: 1,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60747",
        productPLU: "2166016-1",
        productDescription:
          "C 23g| P 7g| F 11g\nEdamame with spicy aoili sauce.\n\nGood source of Dietary fibre",
        productName: "Edamame",
        imageURL:
          "https://resizer.deliverect.com/M_MarA40YziTUu2xEfXvbnV7HlmJJ3vSOFpeOcKWcuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNy02NTExNmUyZDRmNWNlOTI2ZTU5MTQxMjguanBn",
        productCalories: 220,
        quantity: 1,
        nutritionInfo: {
          carbs: 23,
          protein: 7,
          fat: 11,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60749",
        productPLU: "2166015-1",
        productDescription:
          "C 28g| P 12g| F 20g\nHummus served with pita chips.\n\nGood source of Vitamin E",
        productName: "Hummus",
        imageURL:
          "https://resizer.deliverect.com/72PxglK3MSw736Em3OxAtr0nEkCtbdmogjJvF3gGaJo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjAyLTY1MDgzMzQ1ZGViYWM1MDAxZjY1N2Q4OC5qcGc=",
        productCalories: 340,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 12,
          fat: 20,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 340,
    totalExternalFiatCentsPrice: 340,
    totalFmcCentsPriceOriginal: 340,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:21:42.801Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:23:18.287Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:24:34.951Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:35:37.893Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:35:37.893Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:44:27.911Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:58:09.237Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:21:42.801Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:21:54.571Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:21:42.801Z",
    updatedAt: "2024-10-22T20:58:09.579Z",
    fleetInfo: null,
  },
  {
    id: "671808fcb7a34cd092ff6c57",
    sid: "241022-153602",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671808fbb7a34cd092ff6bd7",
    firstName: "بدر محمد الديحاني",
    lastName: null,
    phoneNumber: "+96595565512",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "653d7d5f1bfa57c5db0b2ed5",
    delivery: {
      id: "671808fbb7a34cd092ff6bd7",
      customerId: "653d7d5f1bfa57c5db0b2ed5",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "655905cf5ea5b27ca4bdb6f6",
        name: "Bader",
        lng: 47.89093852043152,
        lat: 29.289630403108717,
        city: "العارضية",
        area: "العارضية",
        block: "4",
        street: "4",
        building: "29",
        floor: "",
        flat: "",
        phone: "+96595565512",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-18T18:43:27.307Z",
        updatedAt: "2024-10-22T20:19:20.248Z",
      },
      deliverectValidationId: "671808fb8ee7d1c25632eedb",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:30:11.093Z",
      estimatedDeliveryTime: "2024-10-22T20:45:09.948Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:17:00.000Z",
      orderId: "671808fcb7a34cd092ff6c57",
      createdAt: "2024-10-22T20:20:11.186Z",
      updatedAt: "2024-10-22T20:22:05.879Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606f3",
        productPLU: "2162077-1",
        productDescription:
          "C 43g | P 26g | F 16g\nShort ribs, mixed cabbage, sour cream, spring onion, parsely with tortilla wrap.\n\nGood Source Of Protein, Vitamin B12, Minerals",
        productName: "Short Rib Tacos",
        imageURL:
          "https://resizer.deliverect.com/bwNBblwmBg7NZVLL0zxwD_1h0r1mAgb-zXh607JTGn4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVEFDTy02NTI5ZjMxMWQyNmQ1ZGYyNzhkZDZiYjYucG5n",
        productCalories: 420,
        quantity: 1,
        nutritionInfo: {
          carbs: 43,
          protein: 26,
          fat: 16,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 400,
    totalExternalFiatCentsPrice: 400,
    totalFmcCentsPriceOriginal: 400,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:20:12.493Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:22:18.453Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:24:31.681Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:29:20.097Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:29:20.097Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:29:42.051Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:50:23.906Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:20:12.493Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:20:24.961Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:20:12.493Z",
    updatedAt: "2024-10-22T20:50:24.245Z",
    fleetInfo: null,
  },
  {
    id: "671808a9b7a34cd092ff5fea",
    sid: "241022-409973",
    coupons: [],
    menuId: "6717e842b7a34cd092f5f5cf",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671808a8b7a34cd092ff5ef1",
    firstName: "Ahmad Alhajri ",
    lastName: null,
    phoneNumber: "+96550519551",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67043227906276458c87f028",
    delivery: {
      id: "671808a8b7a34cd092ff5ef1",
      customerId: "67043227906276458c87f028",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670432db218a8c4af2b4ee2e",
        name: "Home",
        lng: 48.0895983427763,
        lat: 29.242793127461137,
        city: "Al-Masayel",
        area: "Al-Masayel",
        block: "2",
        street: "207 St",
        building: "52",
        floor: "",
        flat: "",
        phone: "+96550519551",
        additionalInfo: "White House ",
        isSelected: true,
        createdAt: "2024-10-07T19:13:31.286Z",
        updatedAt: "2024-10-13T19:36:25.995Z",
      },
      deliverectValidationId: "671808a8dda2cbde336f0421",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:28:48.323Z",
      estimatedDeliveryTime: "2024-10-22T20:43:47.238Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:15:00.000Z",
      orderId: "671808a9b7a34cd092ff5fea",
      createdAt: "2024-10-22T20:18:48.418Z",
      updatedAt: "2024-10-22T20:20:06.350Z",
    },
    items: [
      {
        productId: "6717e842b7a34cd092f5f62f",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f643",
        productPLU: "2162111-1",
        productDescription:
          "C 72g | P 23g | F 34g[%Popular%]\nChicken burger with baked sweet potato fries and bbq sauce",
        productName: "Chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/fMQuypdGrqkSRZhbEebRsjjcen1dY6rXtUgYEcTeE9s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cmdlciUyMGNvbWJvLTY3MTM1NzgzMWM2ZDc0YTE4NGJmNTY2ZC5wbmc=",
        productCalories: 686,
        quantity: 1,
        nutritionInfo: {
          carbs: 72,
          protein: 23,
          fat: 34,
        },
        fmcCentsPrice: 340,
        fiatCentsPrice: 340,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e842b7a34cd092f5f6ef",
            productPLU: "1907010-1",
            productName: "No Iceberg Lettuce",
            productDescription: "C -0.3g| P 0g| F 0g| cal -1.4| coins 0",
            productCalories: -1.4,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.3,
              protein: null,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 470,
    totalFmcCentsPrice: 470,
    totalFiatCentsPriceOriginal: 470,
    totalExternalFiatCentsPrice: 470,
    totalFmcCentsPriceOriginal: 470,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:18:49.987Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:20:06.970Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:24:29.394Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:36:06.798Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:36:06.798Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:46:22.246Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:57:21.039Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:18:49.987Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:19:01.991Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:18:49.987Z",
    updatedAt: "2024-10-22T20:57:21.599Z",
    fleetInfo: null,
  },
  {
    id: "67180846b7a34cd092ff4e1e",
    sid: "241022-487733",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67180846b7a34cd092ff4e10",
    firstName: "Yousif",
    lastName: null,
    phoneNumber: "+96555547646",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65e4a41391b902bb6eb407a8",
    delivery: {
      id: "67180846b7a34cd092ff4e10",
      customerId: "65e4a41391b902bb6eb407a8",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65e4a5fa733cd68442cd4e66",
        name: "Yousif ",
        lng: 48.07346099987626,
        lat: 29.311274973834696,
        city: "Rumaithiya",
        area: "Rumaithiya",
        block: "3",
        street: "34",
        building: "24A",
        floor: "",
        flat: "",
        phone: "+96555547646",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-03-03T16:31:54.442Z",
        updatedAt: "2024-10-19T10:23:57.026Z",
      },
      deliverectValidationId: "6718084605f0aa7bf6f76ecd",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:27:10.213Z",
      estimatedDeliveryTime: "2024-10-22T20:42:08.794Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:14:00.000Z",
      orderId: "67180846b7a34cd092ff4e1e",
      createdAt: "2024-10-22T20:17:10.317Z",
      updatedAt: "2024-10-22T20:19:05.372Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed4a",
        productPLU: "2162077-1",
        productDescription:
          "C 43g | P 26g | F 16g\nShort ribs, mixed cabbage, sour cream, spring onion, parsely with tortilla wrap.\n\nGood Source Of Protein, Vitamin B12, Minerals",
        productName: "Short Rib Tacos",
        imageURL:
          "https://resizer.deliverect.com/bwNBblwmBg7NZVLL0zxwD_1h0r1mAgb-zXh607JTGn4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVEFDTy02NTI5ZjMxMWQyNmQ1ZGYyNzhkZDZiYjYucG5n",
        productCalories: 420,
        quantity: 1,
        nutritionInfo: {
          carbs: 43,
          protein: 26,
          fat: 16,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed66",
        productPLU: "2162073-1",
        productDescription:
          "C 55g| P 22g| F 20g [%Popular%]\nCrispy buffalo chicken tenders, iceberg lettuce, cheese with black pepper ranch sauce in a tortilla\n\nGOOD SOURCE OF PROTEIN, IRON, VITAMINS AND MINERALS ",
        productName: "Zinger Wrap",
        imageURL:
          "https://resizer.deliverect.com/HxB6Jk4SYuzyKeoB5PLI0G8vmLwmz39dUm-FWsICaAg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvWmluZ2VyJTIwd3JhcC02NWY2ZTkxOGMxMjg2NmZjODg2NjczZTIuanBn",
        productCalories: 491,
        quantity: 1,
        nutritionInfo: {
          carbs: 55,
          protein: 22,
          fat: 20,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5edc2",
        productPLU: "2202021-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Coca Cola Zero",
        imageURL:
          "https://resizer.deliverect.com/NvHctUF0z3us3Lhe9x5u3_mQIOOXnn8K3FvSZO6MkK4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCgxKS02NGM5MTk1MjZlY2Q3NzAwMjZjNGU1MDUuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 640,
    totalFmcCentsPrice: 640,
    totalFiatCentsPriceOriginal: 640,
    totalExternalFiatCentsPrice: 640,
    totalFmcCentsPriceOriginal: 640,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:17:10.897Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:19:05.986Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:19:31.798Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:36:18.055Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:36:18.055Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:36:39.805Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:55:32.644Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:17:10.897Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:17:22.439Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:17:10.897Z",
    updatedAt: "2024-10-22T20:55:32.976Z",
    fleetInfo: null,
  },
  {
    id: "671807bfb7a34cd092ff406b",
    sid: "241022-941452",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671807beb7a34cd092ff4028",
    firstName: "badriya boodai",
    lastName: null,
    phoneNumber: "+96594779959",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64a0366c54365c63a99746e0",
    delivery: {
      id: "671807beb7a34cd092ff4028",
      customerId: "64a0366c54365c63a99746e0",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64a2b6fc7c8d5486a7803a76",
        name: "home",
        lng: 47.97045877463654,
        lat: 29.34490966796875,
        city: "Al Kuwayt",
        area: "Shamiya",
        block: "9",
        street: "94 St",
        building: "8",
        floor: "",
        flat: "",
        phone: "+96594779959",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-07-03T11:54:36.274Z",
        updatedAt: "2024-08-24T09:12:19.389Z",
      },
      deliverectValidationId: "671807bee2acd8f0561deed5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:24:54.246Z",
      estimatedDeliveryTime: "2024-10-22T20:39:52.269Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:11:00.000Z",
      orderId: "671807bfb7a34cd092ff406b",
      createdAt: "2024-10-22T20:14:54.588Z",
      updatedAt: "2024-10-22T20:16:05.849Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd0f",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd85",
        productPLU: "2166017-1",
        productDescription:
          "C 16g| P 8g| F 11g\nCarrots, cucumber, red & yellow capsicum, celery, purple & green endives with a hummus dip",
        productName: "Veggie Crudites",
        imageURL:
          "https://resizer.deliverect.com/I3FIyxNKPcOIzVfjKga2oVRpt99xbhQflVKJw27qrWM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjA5Xy02NTA4MzM1ZjM5NGU3ODMxZTFiZDMyMDYucG5n",
        productCalories: 195,
        quantity: 1,
        nutritionInfo: {
          carbs: 16,
          protein: 8,
          fat: 11,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd8d",
        productPLU: "2166035-1",
        productDescription: "C 32g | P 1g | F 0g\nRed Grapes",
        productName: "Red Grapes",
        imageURL:
          "https://resizer.deliverect.com/AtcTHpynl67VC2iPbOU8NdD4B36i27YMor_K0a-pcRA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAzXy02NjNmZWU0YWUwMDMyNDNiNWVhOTRjZWUucG5n",
        productCalories: 132,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 1,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fdb2",
        productPLU: "2202038-1",
        productDescription: "C 5g | P 0g | F 0g\nGinger & Green apple",
        productName: "Zing Shot",
        imageURL:
          "https://resizer.deliverect.com/A1YhE0UbB-538XH14kRI_dg992z-fs6jU0BzJTaXJ34/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemluZyUyMHNob3QtNjY0MWRiMWMwYzI0ZDkyNWZjZDNmYWRkLnBuZw==",
        productCalories: 20,
        quantity: 1,
        nutritionInfo: {
          carbs: 5,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 460,
    totalFmcCentsPrice: 460,
    totalFiatCentsPriceOriginal: 460,
    totalExternalFiatCentsPrice: 460,
    totalFmcCentsPriceOriginal: 460,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:14:55.803Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:16:16.078Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:18:16.149Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:24:18.441Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:24:18.441Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:26:43.544Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:39:20.736Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:14:55.803Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:15:15.546Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:14:55.803Z",
    updatedAt: "2024-10-22T20:39:21.094Z",
    fleetInfo: null,
  },
  {
    id: "671807bcb7a34cd092ff3e6e",
    sid: "241022-251782",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671807bbb7a34cd092ff3e59",
    firstName: "abdullah abdulaziz",
    lastName: null,
    phoneNumber: "+96597161705",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "667feacf8c15153ac8956303",
    delivery: {
      id: "671807bbb7a34cd092ff3e59",
      customerId: "667feacf8c15153ac8956303",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "667febe186fe4b6ad18d1748",
        name: "home",
        lng: 48.04824572056532,
        lat: 29.306498360874514,
        city: "Kuwait",
        area: "Bayan",
        block: "2",
        street: "2",
        building: "39",
        floor: "2",
        flat: "",
        phone: "+96597161705",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-29T11:11:29.868Z",
        updatedAt: "2024-10-22T20:14:16.998Z",
      },
      deliverectValidationId: "671807bb0d4544b0524d8d5b",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:24:51.115Z",
      estimatedDeliveryTime: "2024-10-22T20:39:50.059Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:11:00.000Z",
      orderId: "671807bcb7a34cd092ff3e6e",
      createdAt: "2024-10-22T20:14:51.604Z",
      updatedAt: "2024-10-22T20:16:05.733Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed3e",
        productPLU: "2162112-1",
        productDescription:
          "C 65g | P 21g | F 28g[%Popular%]\nFM burger with baked sweet potato fries and bbq sauce",
        productName: "FM burger & fries",
        imageURL:
          "https://resizer.deliverect.com/RmA60bYBZskDxWGZ3uPWH1NZAH_rxnDkyiAlnyV_l1o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBidXJnZXIlMjBjb21iby02NzEzNTc5NTQ4OGM3MjkyNjAyMTliNWYucG5n",
        productCalories: 596,
        quantity: 1,
        nutritionInfo: {
          carbs: 65,
          protein: 21,
          fat: 28,
        },
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 370,
    totalExternalFiatCentsPrice: 370,
    totalFmcCentsPriceOriginal: 370,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:14:52.745Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:16:10.870Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:16:27.848Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:34:48.528Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:34:48.529Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:35:10.373Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:50:12.730Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:14:52.745Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:15:04.300Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:14:52.745Z",
    updatedAt: "2024-10-22T20:50:13.069Z",
    fleetInfo: null,
  },
  {
    id: "67180730b7a34cd092ff2402",
    sid: "241022-423692",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67180730b7a34cd092ff23f6",
    firstName: "MOHAMMAD ALNASRALLAH",
    lastName: null,
    phoneNumber: "+96598889900",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "651a7469c6da5aa4aee56c2a",
    delivery: {
      id: "67180730b7a34cd092ff23f6",
      customerId: "651a7469c6da5aa4aee56c2a",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6538d35b289c08cbdbaebe44",
        name: "The B",
        lng: 48.043805323541164,
        lat: 29.29955737017648,
        city: "Kuwait",
        area: "Bayan",
        block: "6",
        street: "شارع 5 جادة 2",
        building: "134A",
        floor: "2",
        flat: "7",
        phone: "+96598889900",
        additionalInfo: "‏PACI NO 90445344",
        isSelected: true,
        createdAt: "2023-10-25T08:35:39.850Z",
        updatedAt: "2024-10-08T19:03:31.920Z",
      },
      deliverectValidationId: "671807309d15dbbf9f830175",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:22:32.113Z",
      estimatedDeliveryTime: "2024-10-22T20:37:30.765Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:09:00.000Z",
      orderId: "67180730b7a34cd092ff2402",
      createdAt: "2024-10-22T20:12:32.468Z",
      updatedAt: "2024-10-22T20:14:05.858Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed4a",
        productPLU: "2162077-1",
        productDescription:
          "C 43g | P 26g | F 16g\nShort ribs, mixed cabbage, sour cream, spring onion, parsely with tortilla wrap.\n\nGood Source Of Protein, Vitamin B12, Minerals",
        productName: "Short Rib Tacos",
        imageURL:
          "https://resizer.deliverect.com/bwNBblwmBg7NZVLL0zxwD_1h0r1mAgb-zXh607JTGn4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVEFDTy02NTI5ZjMxMWQyNmQ1ZGYyNzhkZDZiYjYucG5n",
        productCalories: 420,
        quantity: 1,
        nutritionInfo: {
          carbs: 43,
          protein: 26,
          fat: 16,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 400,
    totalExternalFiatCentsPrice: 400,
    totalFmcCentsPriceOriginal: 400,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:12:32.956Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:14:15.585Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:16:34.093Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:29:42.179Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:29:42.179Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:30:04.020Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:54:44.812Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:12:32.956Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:12:44.565Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:12:32.956Z",
    updatedAt: "2024-10-22T20:54:45.358Z",
    fleetInfo: null,
  },
  {
    id: "67180724b7a34cd092ff2135",
    sid: "241022-497455",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "67180723b7a34cd092ff212a",
    firstName: "Abdullah AlEssa",
    lastName: null,
    phoneNumber: "+96550553359",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f41e6a8951a675da027cfb",
    delivery: {
      id: "67180723b7a34cd092ff212a",
      customerId: "66f41e6a8951a675da027cfb",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670423a4218a8c4af2ae5ab9",
        name: "Surra",
        lng: 48.01306653767824,
        lat: 29.310071293825107,
        city: "Kuwait",
        area: "Surra",
        block: "3",
        street: "8",
        building: "14",
        floor: "",
        flat: "",
        phone: "+96550553359",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-07T18:08:36.373Z",
        updatedAt: "2024-10-22T20:10:27.243Z",
      },
      deliverectValidationId: "67180723147ac98c5cd17ec0",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:22:19.051Z",
      estimatedDeliveryTime: "2024-10-22T20:37:18.016Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:09:00.000Z",
      orderId: "67180724b7a34cd092ff2135",
      createdAt: "2024-10-22T20:12:19.154Z",
      updatedAt: "2024-10-22T20:14:05.494Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed62",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5edc7",
        productPLU: "2202037-1",
        productDescription:
          "C 17.5g | P 20g | F 5.2g\nMatcha, Milk, Stevia & WHEY Protein",
        productName: "Matcha Protein Shake",
        imageURL:
          "https://resizer.deliverect.com/lUv2lxjReQDQp6KV_QNq1IUV1xoELYwA3kO-GXQIvWw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWF0Y2hhJTIwbGF0dGUtNjY0MWRiZTk3MjA4MjUyZDhhNmJkYzQzLnBuZw==",
        productCalories: 197,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.5,
          protein: 20,
          fat: 5.2,
        },
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 470,
    totalFmcCentsPrice: 470,
    totalFiatCentsPriceOriginal: 470,
    totalExternalFiatCentsPrice: 470,
    totalFmcCentsPriceOriginal: 470,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:12:20.093Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:14:12.088Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:16:44.388Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:33:08.939Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:33:08.940Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:33:30.805Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:48:04.771Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:12:20.093Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:12:32.254Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:12:20.093Z",
    updatedAt: "2024-10-22T20:48:05.227Z",
    fleetInfo: null,
  },
  {
    id: "671806f3b7a34cd092ff1cfb",
    sid: "241022-876794",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671806f2b7a34cd092ff1cec",
    firstName: "Yousef",
    lastName: null,
    phoneNumber: "+96560082829",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6713e028b58bc47308396b87",
    delivery: {
      id: "671806f2b7a34cd092ff1cec",
      customerId: "6713e028b58bc47308396b87",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6713e17bb7a34cd0922b4cdf",
        name: "Yousef haddad",
        lng: 47.99855119999999,
        lat: 29.3290126,
        city: "Kuwait",
        area: "Rawda",
        block: "1",
        street: "Hesham bin Abdul Malik ",
        building: "8",
        floor: "",
        flat: "",
        phone: "+96560082829",
        additionalInfo: "White House in the center",
        isSelected: true,
        createdAt: "2024-10-19T16:42:35.804Z",
        updatedAt: "2024-10-19T16:42:35.804Z",
      },
      deliverectValidationId: "671806f2ce0f62dcdf2e2d95",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:21:30.154Z",
      estimatedDeliveryTime: "2024-10-22T20:36:29.012Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:08:00.000Z",
      orderId: "671806f3b7a34cd092ff1cfb",
      createdAt: "2024-10-22T20:11:30.246Z",
      updatedAt: "2024-10-22T20:13:06.486Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed4c",
        productPLU: "2162006-1",
        productDescription:
          "C 33g| P 19g| F 16g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e83bb7a34cd092f5edeb",
            productPLU: "1907004-1",
            productName: "No Onion",
            productDescription: "C -1g| P 0g| F 0g| cal -3| coins 0\n",
            productCalories: -3,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: null,
              fat: null,
            },
          },
          {
            productId: "6717e83bb7a34cd092f5edec",
            productPLU: "1907005-1",
            productName: "No Mayo ketchup Sauce",
            productDescription: "C -4g| P 0g| F -3g| cal -44| coins 0",
            productCalories: -44,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -4,
              protein: null,
              fat: -3,
            },
          },
        ],
      },
      {
        productId: "6717e83bb7a34cd092f5ed8e",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5edc3",
        productPLU: "2202022-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Sprite Zero",
        imageURL:
          "https://resizer.deliverect.com/ZEZdNlPffzmGS3rg7Tvd5hMcV02zZNG7y9fZavC83oI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMSUyMCgxKS02NGM5MTk0M2E0NTBlNjAwMjY4M2QzODEuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 490,
    totalFmcCentsPrice: 490,
    totalFiatCentsPriceOriginal: 490,
    totalExternalFiatCentsPrice: 490,
    totalFmcCentsPriceOriginal: 490,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:11:31.050Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:13:10.788Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:16:41.330Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:32:13.720Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:32:13.720Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:32:35.831Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:53:10.156Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:11:31.050Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:11:42.679Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:11:31.050Z",
    updatedAt: "2024-10-22T20:53:10.612Z",
    fleetInfo: null,
  },
  {
    id: "67180647b7a34cd092ff06f4",
    sid: "241022-152082",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67180646b7a34cd092ff06e5",
    firstName: "Abdullah Alosaimi",
    lastName: null,
    phoneNumber: "+96598854462",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d92a17dfecd0088b52684d",
    delivery: {
      id: "67180646b7a34cd092ff06e5",
      customerId: "64d92a17dfecd0088b52684d",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66b676000846fbb3564f9275",
        name: "Home",
        lng: 47.97497548162937,
        lat: 29.305490859257482,
        city: "Kuwait",
        area: "Yarmouk",
        block: "2",
        street: "1 St.",
        building: "27",
        floor: "",
        flat: "",
        phone: "+96598854462",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-09T20:03:12.248Z",
        updatedAt: "2024-10-15T19:34:44.177Z",
      },
      deliverectValidationId: "67180646827135858ba0d73a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:18:38.239Z",
      estimatedDeliveryTime: "2024-10-22T20:33:37.126Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:05:00.000Z",
      orderId: "67180647b7a34cd092ff06f4",
      createdAt: "2024-10-22T20:08:38.324Z",
      updatedAt: "2024-10-22T20:10:04.743Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606f4",
        productPLU: "2162008-1",
        productDescription:
          "C 40g | P 21g | F 24g [%Popular%]\nCrispy chicken with pepper jack cheese, coleslaw mix, dill pickles & a spicy mayo sauce.",
        productName: "Spicy Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/V3b1iOeX1UmC1nng8xEmFG8Y02arGnZCgSM1cET5hoo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BpY3klMjBjaGlja2VuJTIwYnVyZ2VyKDEpLTY1NTQ4ZjI5ODEyNDJlMDAxOGYwYWIyZi5wbmc=",
        productCalories: 460,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 24,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e84fb7a34cd092f60798",
            productPLU: "1907008-1",
            productName: "No Dill Pickles",
            productDescription: "C 0g| P 0g| F 0g| cal -0.4| coins 0",
            productCalories: -0.4,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: null,
              fat: null,
            },
          },
          {
            productId: "6717e84fb7a34cd092f607aa",
            productPLU: "1907013-1",
            productName: "No Coleslaw Mix",
            productDescription: "C -0.6g| P -0.3g| F -1g| cal -20| coins 0",
            productCalories: -20,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.6,
              protein: -0.3,
              fat: -1,
            },
          },
        ],
      },
      {
        productId: "6717e84fb7a34cd092f606e6",
        productPLU: "2162111-1",
        productDescription:
          "C 72g | P 23g | F 34g[%Popular%]\nChicken burger with baked sweet potato fries and bbq sauce",
        productName: "Chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/fMQuypdGrqkSRZhbEebRsjjcen1dY6rXtUgYEcTeE9s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cmdlciUyMGNvbWJvLTY3MTM1NzgzMWM2ZDc0YTE4NGJmNTY2ZC5wbmc=",
        productCalories: 686,
        quantity: 1,
        nutritionInfo: {
          carbs: 72,
          protein: 23,
          fat: 34,
        },
        fmcCentsPrice: 340,
        fiatCentsPrice: 340,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e84fb7a34cd092f60792",
            productPLU: "1907010-1",
            productName: "No Iceberg Lettuce",
            productDescription: "C -0.3g| P 0g| F 0g| cal -1.4| coins 0",
            productCalories: -1.4,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.3,
              protein: null,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 540,
    totalFmcCentsPrice: 540,
    totalFiatCentsPriceOriginal: 540,
    totalExternalFiatCentsPrice: 540,
    totalFmcCentsPriceOriginal: 540,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:08:39.049Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:10:16.388Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:11:18.576Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:26:58.673Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:26:58.674Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:27:20.729Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:37:43.738Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:08:39.049Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:08:50.663Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:08:39.049Z",
    updatedAt: "2024-10-22T20:37:44.090Z",
    fleetInfo: null,
  },
  {
    id: "67180611b7a34cd092ff032c",
    sid: "241022-930500",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67180610b7a34cd092ff031f",
    firstName: "Khaled Aldaihani",
    lastName: null,
    phoneNumber: "+96560077024",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66fee635906276458c7c6997",
    delivery: {
      id: "67180610b7a34cd092ff031f",
      customerId: "66fee635906276458c7c6997",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66fee712218a8c4af2ee65be",
        name: "Home ",
        lng: 47.870995961129665,
        lat: 29.27039888458356,
        city: "Sabah Al Nasser",
        area: "Sabah Al Nasser",
        block: "3",
        street: "21",
        building: "51",
        floor: "",
        flat: "",
        phone: "+96560077024",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-03T18:48:50.806Z",
        updatedAt: "2024-10-19T19:32:46.424Z",
      },
      deliverectValidationId: "67180610b8212c160f95c9b7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:17:44.359Z",
      estimatedDeliveryTime: "2024-10-22T20:32:43.143Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:04:00.000Z",
      orderId: "67180611b7a34cd092ff032c",
      createdAt: "2024-10-22T20:07:44.490Z",
      updatedAt: "2024-10-22T20:09:05.909Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606f7",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 2,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e84fb7a34cd092f6078f",
            productPLU: "1906003-1",
            productName: "Extra Chedder Cheese",
            productDescription: "C 0g| P 2g| F 3g| cal 40| coins 0.2",
            productCalories: 40,
            quantity: 1,
            fmcCentsPrice: 20,
            fiatCentsPrice: 20,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: 2,
              fat: 3,
            },
          },
          {
            productId: "6717e84fb7a34cd092f60790",
            productPLU: "1906005-1",
            productName: "Extra  Chicken Pattie",
            productDescription: "C 13g| P 15g| F 2g| cal 136| coins 0.5",
            productCalories: 136,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 13,
              protein: 15,
              fat: 2,
            },
          },
        ],
      },
      {
        productId: "6717e84fb7a34cd092f60738",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 600,
    totalFmcCentsPrice: 600,
    totalFiatCentsPriceOriginal: 600,
    totalExternalFiatCentsPrice: 600,
    totalFmcCentsPriceOriginal: 600,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:07:45.446Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:09:17.895Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:10:46.301Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:26:10.323Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:26:10.323Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:26:32.242Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:45:36.168Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:07:45.446Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:07:56.870Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:07:45.446Z",
    updatedAt: "2024-10-22T20:45:36.608Z",
    fleetInfo: null,
  },
  {
    id: "671805dbb7a34cd092fef474",
    sid: "241022-076285",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "671805dab7a34cd092fef462",
    firstName: "Sabah al sabah",
    lastName: null,
    phoneNumber: "+96551000009",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6702ddce906276458c84491e",
    delivery: {
      id: "671805dab7a34cd092fef462",
      customerId: "6702ddce906276458c84491e",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6706d453f495b693cdadc2ce",
        name: "Home",
        lng: 47.95178422928987,
        lat: 29.354881781366327,
        city: "Kuwait",
        area: "Shuwaikh Residential",
        block: "5",
        street: "55 St",
        building: "1",
        floor: "",
        flat: "",
        phone: "+96551000009",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-09T19:06:59.284Z",
        updatedAt: "2024-10-09T19:06:59.284Z",
      },
      deliverectValidationId: "671805dac581fc4bf1ef5330",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:16:50.221Z",
      estimatedDeliveryTime: "2024-10-22T20:31:48.944Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:03:00.000Z",
      orderId: "671805dbb7a34cd092fef474",
      createdAt: "2024-10-22T20:06:50.368Z",
      updatedAt: "2024-10-22T20:08:05.643Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd34",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd3f",
        productPLU: "2162030-1",
        productDescription:
          "C 42g | P 26g | F 21g\nLo mein noodles, beef, carrots, cabbage & zucchini.\n\n*Good source of Protein & vitamins*",
        productName: "Lo Mein Noodles",
        imageURL:
          "https://resizer.deliverect.com/gtToqQp69qSy57Ivr2G-IxNvZrR3UxiMiFxzdOFokw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTE8lMjBNRUlOJTIwTk9PRExFUy02NTc1OTAxZDRmNzg3N2JlMWJhMWIyN2MucG5n",
        productCalories: 461,
        quantity: 1,
        nutritionInfo: {
          carbs: 42,
          protein: 26,
          fat: 21,
        },
        fmcCentsPrice: 390,
        fiatCentsPrice: 390,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd6f",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd30",
        productPLU: "2162077-1",
        productDescription:
          "C 43g | P 26g | F 16g\nShort ribs, mixed cabbage, sour cream, spring onion, parsely with tortilla wrap.\n\nGood Source Of Protein, Vitamin B12, Minerals",
        productName: "Short Rib Tacos",
        imageURL:
          "https://resizer.deliverect.com/bwNBblwmBg7NZVLL0zxwD_1h0r1mAgb-zXh607JTGn4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVEFDTy02NTI5ZjMxMWQyNmQ1ZGYyNzhkZDZiYjYucG5n",
        productCalories: 420,
        quantity: 1,
        nutritionInfo: {
          carbs: 43,
          protein: 26,
          fat: 16,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd1f",
        productPLU: "2162117-1",
        productDescription:
          "C 60g | P 22g | F 15g\nTomato sauce, cheese & oregano.",
        productName: "Classic Margherita Pizza",
        imageURL:
          "https://resizer.deliverect.com/gXmec37h0lqeQkrMSIQRiRdHLwG9kVK9Ukz9m_Kw930/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWFyZ2hhcml0YS02NjZiMzAxMWQ2NmMzMDFhNDE0NmYxNTMucG5n",
        productCalories: 463,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 22,
          fat: 15,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 1420,
    totalFmcCentsPrice: 1420,
    totalFiatCentsPriceOriginal: 1420,
    totalExternalFiatCentsPrice: 1420,
    totalFmcCentsPriceOriginal: 1420,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:06:51.107Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:08:14.772Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:09:08.711Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:24:07.516Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:24:07.516Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:26:05.642Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:32:35.975Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:06:51.107Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:07:02.788Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:06:51.107Z",
    updatedAt: "2024-10-22T20:32:36.308Z",
    fleetInfo: null,
  },
  {
    id: "67180540b7a34cd092fee5ee",
    sid: "241022-449095",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "67180540b7a34cd092fee5de",
    firstName: "Raghad USF",
    lastName: null,
    phoneNumber: "+96599319393",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "651c8998c6da5aa4aee8434e",
    delivery: {
      id: "67180540b7a34cd092fee5de",
      customerId: "651c8998c6da5aa4aee8434e",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6717aca0b7a34cd092e417dc",
        name: "My home🤍",
        lng: 48.00630033016205,
        lat: 29.346500137042643,
        city: "Kuwait",
        area: "Qadsiya",
        block: "8",
        street: "87 st corner 82 st",
        building: "6",
        floor: "",
        flat: "",
        phone: "+96599319393",
        additionalInfo: "Call or ring the bell press number 1",
        isSelected: true,
        createdAt: "2024-10-22T13:46:08.814Z",
        updatedAt: "2024-10-22T13:46:08.814Z",
      },
      deliverectValidationId: "671805404cb7e660cd02f0d8",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:14:16.254Z",
      estimatedDeliveryTime: "2024-10-22T20:29:15.239Z",
      estimatedDeliveryTimeEnd: "2024-10-22T21:01:00.000Z",
      orderId: "67180540b7a34cd092fee5ee",
      createdAt: "2024-10-22T20:04:16.351Z",
      updatedAt: "2024-10-22T20:06:10.315Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd48",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd4a",
        productPLU: "2162076-1",
        productDescription:
          "C 57g| P 32g| F 12g\nGrilled chicken, rice, corn, mixed beans sauce, iceburg lettuce, chedder cheese, mozarella cheese & ranch all wrapped in a tortilla wrap.\n\nGOOD SOURCE OF PROTEIN, VITAMINS AND MINERALS",
        productName: "Chicken Burrito",
        imageURL:
          "https://resizer.deliverect.com/6YIIS1bTpbzMr3WFtmPqqbcL9yd4ZmTrgUo1MJkaRzE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cnJpdG8lMjB3cmFwLTY1NzU4ZmY2MTk4NDQxOWFmYWNhOTI3MS5qcGc=",
        productCalories: 464,
        quantity: 1,
        nutritionInfo: {
          carbs: 57,
          protein: 32,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd81",
        productPLU: "2166037-1",
        productDescription:
          "C 31g | P 3g | F 8g \nTortilla chips, cheddar cheese, & pickled jalapeños. ",
        productName: "Jalapeño Cheddar Nachos ",
        imageURL:
          "https://resizer.deliverect.com/Igtv_X3IIKdvVYw3drGOS6DlDQf47bmEkgol0b9fBZ0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFsYXBlbm8lMjBjaGVlc2UlMjBuYWNob3MtNjY2MWNkZWQ5YWNlYTFmMWZlZWVkOTU5LnBuZw==",
        productCalories: 208,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 3,
          fat: 8,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fda8",
        productPLU: "2202021-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Coca Cola Zero",
        imageURL:
          "https://resizer.deliverect.com/NvHctUF0z3us3Lhe9x5u3_mQIOOXnn8K3FvSZO6MkK4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCgxKS02NGM5MTk1MjZlY2Q3NzAwMjZjNGU1MDUuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 730,
    totalFmcCentsPrice: 730,
    totalFiatCentsPriceOriginal: 730,
    totalExternalFiatCentsPrice: 730,
    totalFmcCentsPriceOriginal: 730,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:04:16.995Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:06:18.932Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:07:43.038Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:22:29.409Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:22:29.410Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:23:19.657Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:40:03.118Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:04:16.995Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:04:28.509Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:04:16.995Z",
    updatedAt: "2024-10-22T20:40:03.457Z",
    fleetInfo: null,
  },
  {
    id: "671804a7b7a34cd092fed635",
    sid: "241022-906884",
    coupons: [],
    menuId: "6717e842b7a34cd092f5f5cf",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "671804a7b7a34cd092fed627",
    firstName: "Entesar",
    lastName: null,
    phoneNumber: "+96550012777",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66e6a586aa1645e3a51a41b7",
    delivery: {
      id: "671804a7b7a34cd092fed627",
      customerId: "66e6a586aa1645e3a51a41b7",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f1819a1294dc02ca4c9e4f",
        name: "Home",
        lng: 48.1014895811677,
        lat: 29.202526842114985,
        city: "Kuwait",
        area: "Abu 'Fteira",
        block: "4",
        street: "Street 248",
        building: "365",
        floor: "",
        flat: "",
        phone: "+96550012777",
        additionalInfo: "Brown house in the corner",
        isSelected: true,
        createdAt: "2024-09-23T14:56:26.446Z",
        updatedAt: "2024-10-21T16:03:44.206Z",
      },
      deliverectValidationId: "671804a64cb7e660cd02a552",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:11:42.751Z",
      estimatedDeliveryTime: "2024-10-22T20:26:41.586Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:58:00.000Z",
      orderId: "671804a7b7a34cd092fed635",
      createdAt: "2024-10-22T20:01:43.035Z",
      updatedAt: "2024-10-22T20:03:06.092Z",
    },
    items: [
      {
        productId: "6717e842b7a34cd092f5f644",
        productPLU: "2162112-1",
        productDescription:
          "C 65g | P 21g | F 28g[%Popular%]\nFM burger with baked sweet potato fries and bbq sauce",
        productName: "FM burger & fries",
        imageURL:
          "https://resizer.deliverect.com/RmA60bYBZskDxWGZ3uPWH1NZAH_rxnDkyiAlnyV_l1o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBidXJnZXIlMjBjb21iby02NzEzNTc5NTQ4OGM3MjkyNjAyMTliNWYucG5n",
        productCalories: 596,
        quantity: 1,
        nutritionInfo: {
          carbs: 65,
          protein: 21,
          fat: 28,
        },
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f671",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e842b7a34cd092f5f733",
            productPLU: "1907027-1",
            productName: "No Egg",
            productDescription: "C -0.4g| P -6.2g| F -4.7g| cal -71| coins 0",
            productCalories: -71,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.4,
              protein: -6.2,
              fat: -4.7,
            },
          },
          {
            productId: "6717e842b7a34cd092f5f730",
            productPLU: "1907028-1",
            productName: "No Pickles",
            productDescription: "C -0.2g| P 0g| F 0g| cal -1| coins 0",
            productCalories: -1,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.2,
              protein: null,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 570,
    totalFmcCentsPrice: 570,
    totalFiatCentsPriceOriginal: 570,
    totalExternalFiatCentsPrice: 570,
    totalFmcCentsPriceOriginal: 570,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:01:43.952Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:03:07.721Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:08:30.340Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:19:07.498Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:19:07.498Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:19:42.203Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:40:48.299Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:01:43.952Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:01:55.882Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:01:43.952Z",
    updatedAt: "2024-10-22T20:40:48.740Z",
    fleetInfo: null,
  },
  {
    id: "67180483b7a34cd092fed3c9",
    sid: "241022-418215",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67180482b7a34cd092fed3be",
    firstName: "Abdullah",
    lastName: null,
    phoneNumber: "+96599213018",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65f786cdb938b90683854a1c",
    delivery: {
      id: "67180482b7a34cd092fed3be",
      customerId: "65f786cdb938b90683854a1c",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66121042425da3cb149764ce",
        name: "العارضية",
        lng: 47.89099082350731,
        lat: 29.290098242372466,
        city: "Ardiya",
        area: "Ardiya",
        block: "4",
        street: "4",
        building: "29",
        floor: "",
        flat: "",
        phone: "+96599213018",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-07T03:17:22.459Z",
        updatedAt: "2024-08-23T20:22:12.138Z",
      },
      deliverectValidationId: "67180482e9cbdcf0e32c5a34",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:11:06.229Z",
      estimatedDeliveryTime: "2024-10-22T20:26:05.182Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:58:00.000Z",
      orderId: "67180483b7a34cd092fed3c9",
      createdAt: "2024-10-22T20:01:06.325Z",
      updatedAt: "2024-10-22T20:03:08.119Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606fd",
        productPLU: "2162044-1",
        productDescription:
          "C 46g| P 32g| F 8g\nButter chicken served with white basmati rice and a brocolli garnish with coriander.\n\nGluten free, Good source of Vitamins & Iron.",
        productName: "Butter Chicken",
        imageURL:
          "https://resizer.deliverect.com/kNZO7hSeSAa7aTJWFU1oyK8hGOHaTbLbvvvMD_J1ft0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnV0dGVyJTIwY2hpY2tlbi02NWY2ZWEzZTUxOTBiNjZmYzkxYmQ2MzMuanBn",
        productCalories: 384,
        quantity: 1,
        nutritionInfo: {
          carbs: 46,
          protein: 32,
          fat: 8,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f6076d",
        productPLU: "2202024-1",
        productDescription: "C 0g| P 0g| F 0g",
        productName: "Voda Voda water 330 ml",
        imageURL:
          "https://resizer.deliverect.com/rREX_if98r0ZPCForFZZOMM-QG7U7usZElGULr0shy4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NTZlZjljOTE5ODQ0MTlhZmFjYTY4YjIuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 320,
    totalFmcCentsPrice: 320,
    totalFiatCentsPriceOriginal: 320,
    totalExternalFiatCentsPrice: 320,
    totalFmcCentsPriceOriginal: 320,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:01:07.014Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:03:10.491Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:08:05.772Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:10:56.890Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:10:56.890Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:30:16.171Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:50:01.077Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T20:01:07.014Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T20:01:18.679Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T20:01:07.014Z",
    updatedAt: "2024-10-22T20:50:01.524Z",
    fleetInfo: null,
  },
  {
    id: "6718041ab7a34cd092fec6e8",
    sid: "241022-348342",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67180419b7a34cd092fec6de",
    firstName: "Muneera Almutairi",
    lastName: null,
    phoneNumber: "+96550546666",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f14c437f0597f029e0f5d1",
    delivery: {
      id: "67180419b7a34cd092fec6de",
      customerId: "66f14c437f0597f029e0f5d1",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f160b51294dc02ca45125a",
        name: "Home",
        lng: 47.94888596981764,
        lat: 29.27852099586783,
        city: "Kuwait",
        area: "Ishbiliya",
        block: "1",
        street: "110",
        building: "30",
        floor: "",
        flat: "",
        phone: "+96550546666",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-23T12:36:05.911Z",
        updatedAt: "2024-09-23T12:36:05.911Z",
      },
      deliverectValidationId: "67180419bd36c20e51dfbb49",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:09:21.346Z",
      estimatedDeliveryTime: "2024-10-22T20:24:20.140Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:56:00.000Z",
      orderId: "6718041ab7a34cd092fec6e8",
      createdAt: "2024-10-22T19:59:21.440Z",
      updatedAt: "2024-10-22T20:01:05.337Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606ef",
        productPLU: "2162089-1",
        productDescription:
          "C 36g | P 43g | F 18g\nMongolian beef with green bell peppers & green onions served with bulgur veggie mix.\n\nGOOD SOURCE OF PROTEIN & VITAMINA B12& VITAMIN C",
        productName: "Mongolian Beef",
        imageURL:
          "https://resizer.deliverect.com/ZXcmtUYh2Cc-oEj8YoLx8mWIX35FnYCLywx_hPNI4_I/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNSUyMCgxKS02NjUxZGY2Yzc0OGYzNjdjMTMxNzJhZGQucG5n",
        productCalories: 478,
        quantity: 1,
        nutritionInfo: {
          carbs: 36,
          protein: 43,
          fat: 18,
        },
        fmcCentsPrice: 460,
        fiatCentsPrice: 460,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f6076b",
        productPLU: "2202021-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Coca Cola Zero",
        imageURL:
          "https://resizer.deliverect.com/NvHctUF0z3us3Lhe9x5u3_mQIOOXnn8K3FvSZO6MkK4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCgxKS02NGM5MTk1MjZlY2Q3NzAwMjZjNGU1MDUuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 510,
    totalFmcCentsPrice: 510,
    totalFiatCentsPriceOriginal: 510,
    totalExternalFiatCentsPrice: 510,
    totalFmcCentsPriceOriginal: 510,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:59:22.191Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T20:01:17.442Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:02:20.136Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:08:02.093Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:08:02.094Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:08:18.891Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:41:30.676Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:59:22.191Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:59:33.697Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:59:22.191Z",
    updatedAt: "2024-10-22T20:41:31.023Z",
    fleetInfo: null,
  },
  {
    id: "67180334b7a34cd092fe8e65",
    sid: "241022-667206",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67180333b7a34cd092fe8d7e",
    firstName: "Bader Alswarij",
    lastName: null,
    phoneNumber: "+96555550964",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "661709989e5cbbdc44c6bbc3",
    delivery: {
      id: "67180333b7a34cd092fe8d7e",
      customerId: "661709989e5cbbdc44c6bbc3",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6654d24becc6fd38a616c56a",
        name: "ديوانية الغضوري ",
        lng: 47.88932710133164,
        lat: 29.244125366210938,
        city: "عبدالله المبارك الصباح",
        area: "عبدالله المبارك الصباح",
        block: "8",
        street: "811",
        building: "12",
        floor: "",
        flat: "",
        phone: "+96555550964",
        additionalInfo: "الباب الجانبي ",
        isSelected: true,
        createdAt: "2024-05-27T18:34:51.749Z",
        updatedAt: "2024-10-22T19:53:47.926Z",
      },
      deliverectValidationId: "67180333e630c246d2a91b6e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:05:31.646Z",
      estimatedDeliveryTime: "2024-10-22T20:20:30.662Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:52:00.000Z",
      orderId: "67180334b7a34cd092fe8e65",
      createdAt: "2024-10-22T19:55:31.750Z",
      updatedAt: "2024-10-22T19:57:07.698Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f60737",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f606e6",
        productPLU: "2162111-1",
        productDescription:
          "C 72g | P 23g | F 34g[%Popular%]\nChicken burger with baked sweet potato fries and bbq sauce",
        productName: "Chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/fMQuypdGrqkSRZhbEebRsjjcen1dY6rXtUgYEcTeE9s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cmdlciUyMGNvbWJvLTY3MTM1NzgzMWM2ZDc0YTE4NGJmNTY2ZC5wbmc=",
        productCalories: 686,
        quantity: 1,
        nutritionInfo: {
          carbs: 72,
          protein: 23,
          fat: 34,
        },
        fmcCentsPrice: 340,
        fiatCentsPrice: 340,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 550,
    totalFmcCentsPrice: 550,
    totalFiatCentsPriceOriginal: 550,
    totalExternalFiatCentsPrice: 550,
    totalFmcCentsPriceOriginal: 550,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:55:32.798Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:57:40.805Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:58:38.887Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:11:07.737Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:11:07.737Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:11:44.827Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:36:39.890Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:55:32.798Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:55:44.284Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:55:32.798Z",
    updatedAt: "2024-10-22T20:36:40.225Z",
    fleetInfo: null,
  },
  {
    id: "6718028db7a34cd092fe77d5",
    sid: "241022-928300",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6718028db7a34cd092fe77c3",
    firstName: "Abdulrahman",
    lastName: null,
    phoneNumber: "+96597477752",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f58b9a8951a675da05743f",
    delivery: {
      id: "6718028db7a34cd092fe77c3",
      customerId: "66f58b9a8951a675da05743f",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f95e41ee3a746f7fa03a1e",
        name: "Nuzha",
        lng: 47.994742691516876,
        lat: 29.346071402226066,
        city: "Kuwait",
        area: "Nuzha",
        block: "1",
        street: "11",
        building: "10",
        floor: "",
        flat: "",
        phone: "+96597477752",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-29T14:03:45.173Z",
        updatedAt: "2024-10-21T20:23:09.798Z",
      },
      deliverectValidationId: "6718028cdda2cbde336bea75",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:02:44.971Z",
      estimatedDeliveryTime: "2024-10-22T20:17:43.859Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:49:00.000Z",
      orderId: "6718028db7a34cd092fe77d5",
      createdAt: "2024-10-22T19:52:45.065Z",
      updatedAt: "2024-10-22T19:54:06.347Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd6a",
        productPLU: "2162021-1",
        productDescription:
          "C 60g| P 50g| F 16g\nChicken shawarma served with calrose rice & drizzled with our shawarma sauce and sumac white onions.\n\nHigh in protein, ",
        productName: "Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/wpaWs0_xzYbkz_6vxHW2469_71kHXwgrjXuSA4epEwY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2hpY2tlbiUyMFNoYXdhcm1hJTIwYm93bC02NGY0OTRlOTFhNzliZjNkNzNkYjUzNjcuanBn",
        productCalories: 584,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 50,
          fat: 16,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e846b7a34cd092f5fe0e",
            productPLU: "1907030-1",
            productName: "No Sumac Onion",
            productDescription: "C -2.3g| P -0.3g| F 0g| cal -10| coins 0",
            productCalories: -10,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -2.3,
              protein: -0.3,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "6717e846b7a34cd092f5fd6f",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd3c",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 330,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e846b7a34cd092f5fdfd",
            productPLU: "1906021-1",
            productName: "Extra  Crispy Chicken",
            productDescription: "C 24g| P 24g| F 11g| cal 296| coins 0.8",
            productCalories: 296,
            quantity: 1,
            fmcCentsPrice: 80,
            fiatCentsPrice: 80,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 24,
              protein: 24,
              fat: 11,
            },
          },
        ],
      },
      {
        productId: "6717e846b7a34cd092f5fda9",
        productPLU: "2202022-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Sprite Zero",
        imageURL:
          "https://resizer.deliverect.com/ZEZdNlPffzmGS3rg7Tvd5hMcV02zZNG7y9fZavC83oI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMSUyMCgxKS02NGM5MTk0M2E0NTBlNjAwMjY4M2QzODEuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 730,
    totalFmcCentsPrice: 730,
    totalFiatCentsPriceOriginal: 730,
    totalExternalFiatCentsPrice: 730,
    totalFmcCentsPriceOriginal: 730,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:52:45.942Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:54:15.783Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:54:58.637Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:07:31.842Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:07:31.842Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:10:20.600Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:23:31.507Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:52:45.942Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:53:06.138Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:52:45.942Z",
    updatedAt: "2024-10-22T20:23:31.849Z",
    fleetInfo: null,
  },
  {
    id: "6718028bb7a34cd092fe77b4",
    sid: "241022-508251",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6718028ab7a34cd092fe76de",
    firstName: "Ziad AlAbdulghafour",
    lastName: null,
    phoneNumber: "+96596616800",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65a4d26c2d5e4e992694854b",
    delivery: {
      id: "6718028ab7a34cd092fe76de",
      customerId: "65a4d26c2d5e4e992694854b",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "65c287754a64488bb64ab458",
        name: "Duwaniya ",
        lng: 47.997493632137775,
        lat: 29.30878989071218,
        city: "Kuwait",
        area: "Qortuba",
        block: "2",
        street: "1 St. Av.3",
        building: "83",
        floor: "",
        flat: "",
        phone: "+96596616800",
        additionalInfo: "Duwaneya on the back side go from very small street",
        isSelected: true,
        createdAt: "2024-02-06T19:24:37.698Z",
        updatedAt: "2024-10-22T19:45:10.035Z",
      },
      deliverectValidationId: "6718028a827135858b9e388a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:02:42.365Z",
      estimatedDeliveryTime: "2024-10-22T20:17:41.369Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:49:00.000Z",
      orderId: "6718028bb7a34cd092fe77b4",
      createdAt: "2024-10-22T19:52:42.469Z",
      updatedAt: "2024-10-22T19:54:04.977Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f6072c",
        productPLU: "2162056-1",
        productDescription:
          "C 52g| P 40g| F 18g\nGrilled tenderloin steak served with egyptian rice, charred corn, mushroom sauce, grilled onion topped with chives.\n\nGood source of iron & B-Complex vitamins",
        productName: "Mushroom Steak Bowl",
        imageURL:
          "https://resizer.deliverect.com/mI46_G8ohOUlax6lzY869gIqShTLi01aFmd1aeKxxuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGJvd2wlMjAtNjRmNDhmODQxNDI5ODQwMDFlOGVjOGVkLmpwZw==",
        productCalories: 530,
        quantity: 1,
        nutritionInfo: {
          carbs: 52,
          protein: 40,
          fat: 18,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60732",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60765",
        productPLU: "2202050-1",
        productDescription:
          "C 27g | P 1.5g | F 0g\nOrange \n\nGood source of Vitamin C",
        productName: "Orange Citrus",
        imageURL:
          "https://resizer.deliverect.com/92Cgq5w07iSWvqOzcojq0Egx0BDIxsVNTfIhlYhtUmU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTMtNjY1OTE5ODQ0MjllZTgwN2Q2OWQxYmZkLnBuZw==",
        productCalories: 116,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 1.5,
          fat: null,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 640,
    totalFmcCentsPrice: 640,
    totalFiatCentsPriceOriginal: 640,
    totalExternalFiatCentsPrice: 640,
    totalFmcCentsPriceOriginal: 640,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:52:43.611Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:54:16.883Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:57:44.521Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:08:17.087Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:08:17.088Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:10:31.872Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:28:27.783Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:52:43.611Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:52:55.157Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:52:43.611Z",
    updatedAt: "2024-10-22T20:28:28.128Z",
    fleetInfo: null,
  },
  {
    id: "67180248b7a34cd092fe67d0",
    sid: "241022-840616",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67180247b7a34cd092fe672a",
    firstName: "Nour",
    lastName: null,
    phoneNumber: "+96598999424",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65b58a67d3514bca667cf917",
    delivery: {
      id: "67180247b7a34cd092fe672a",
      customerId: "65b58a67d3514bca667cf917",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65b58abfb1aa073e20deb65b",
        name: "Salam",
        lng: 48.00475244414807,
        lat: 29.293212890625,
        city: "Kuwait",
        area: "Salam",
        block: "7",
        street: "710 St",
        building: "19",
        floor: "",
        flat: "",
        phone: "+96598999424",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-27T22:59:11.482Z",
        updatedAt: "2024-08-24T08:47:27.312Z",
      },
      deliverectValidationId: "67180247c581fc4bf1ed069a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T20:01:35.093Z",
      estimatedDeliveryTime: "2024-10-22T20:16:33.966Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:48:00.000Z",
      orderId: "67180248b7a34cd092fe67d0",
      createdAt: "2024-10-22T19:51:35.196Z",
      updatedAt: "2024-10-22T19:53:07.241Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f60732",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60739",
        productPLU: "2166031-1",
        productDescription:
          "C 32g| P 12g| F 12g\nBaked sweet potato fries with mayo barbeque sauce.",
        productName: "Baked Sweet Potato Fries",
        imageURL:
          "https://resizer.deliverect.com/EexbDtzbKG0sVOjjNXiIlT_MIN2doyc2NlpJu2_T6nc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3dlZXQlMjBwb3RhdG8lMjBmcmllcygxKS02NTU0OGY3NzYyYWIwZTAwMWYzYTczNzEucG5n",
        productCalories: 244,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 12,
          fat: 12,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:51:36.577Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:53:10.982Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:58:05.573Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:59:14.585Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:59:14.586Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:12:25.872Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:36:51.076Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:51:36.577Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:51:48.087Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:51:36.577Z",
    updatedAt: "2024-10-22T20:36:51.543Z",
    fleetInfo: null,
  },
  {
    id: "671801b6b7a34cd092fe4552",
    sid: "241022-287586",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "671801b5b7a34cd092fe4547",
    firstName: "Musaed alosaimi",
    lastName: null,
    phoneNumber: "+96594457797",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6626a9cdaa408215b396f903",
    delivery: {
      id: "671801b5b7a34cd092fe4547",
      customerId: "6626a9cdaa408215b396f903",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6718016fb7a34cd092fe394e",
        name: "18",
        lng: 47.88605287671089,
        lat: 29.294181722488304,
        city: "Ardiya",
        area: "Ardiya",
        block: "3",
        street: "6",
        building: "18",
        floor: "",
        flat: "",
        phone: "+96594457797",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-22T19:47:59.591Z",
        updatedAt: "2024-10-22T19:47:59.591Z",
      },
      deliverectValidationId: "671801b5fbbda76c3f08d401",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:59:09.822Z",
      estimatedDeliveryTime: "2024-10-22T20:14:08.784Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:46:00.000Z",
      orderId: "671801b6b7a34cd092fe4552",
      createdAt: "2024-10-22T19:49:09.911Z",
      updatedAt: "2024-10-22T19:51:05.216Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f60721",
        productPLU: "2163011-1",
        productDescription:
          "C 8g| P 5g| F 20g\nMixed greens, eggplant feta, carrots & red & white cabbage drizzed with a zesty vinaigrette dressing.\n\nGOOD SOURCE OF VITAMIN E & VITAMIN K",
        productName: "Eggplant Feta Salad",
        imageURL:
          "https://resizer.deliverect.com/pERu_JbxdO1wsoe98smib3-rtsS87wXQnqud_Pox7cY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2lkZSUyMDQtNjUxMDM4NjM2OTU5NzA3ZDUwMWUwMzY2LmpwZw==",
        productCalories: 232,
        quantity: 1,
        nutritionInfo: {
          carbs: 8,
          protein: 5,
          fat: 20,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f606e7",
        productPLU: "2162112-1",
        productDescription:
          "C 65g | P 21g | F 28g[%Popular%]\nFM burger with baked sweet potato fries and bbq sauce",
        productName: "FM burger & fries",
        imageURL:
          "https://resizer.deliverect.com/RmA60bYBZskDxWGZ3uPWH1NZAH_rxnDkyiAlnyV_l1o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBidXJnZXIlMjBjb21iby02NzEzNTc5NTQ4OGM3MjkyNjAyMTliNWYucG5n",
        productCalories: 596,
        quantity: 1,
        nutritionInfo: {
          carbs: 65,
          protein: 21,
          fat: 28,
        },
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 600,
    totalFmcCentsPrice: 600,
    totalFiatCentsPriceOriginal: 600,
    totalExternalFiatCentsPrice: 600,
    totalFmcCentsPriceOriginal: 600,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:49:10.807Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:51:16.797Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:58:01.000Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:59:25.466Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:59:25.466Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:08:30.078Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:26:43.660Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:49:10.807Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:49:22.222Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:49:10.807Z",
    updatedAt: "2024-10-22T20:26:44.007Z",
    fleetInfo: null,
  },
  {
    id: "671801a4b7a34cd092fe3fd0",
    sid: "241022-587491",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671801a3b7a34cd092fe3fb1",
    firstName: "يوسف كمال مصطفوي",
    lastName: null,
    phoneNumber: "+96555500288",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64f4be411324bbcd6481e323",
    delivery: {
      id: "671801a3b7a34cd092fe3fb1",
      customerId: "64f4be411324bbcd6481e323",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65243f5df3f5a7e1da1e6c49",
        name: "شقة الجابرية",
        lng: 48.04554272443056,
        lat: 29.31940898478546,
        city: "الجابرية",
        area: "الجابرية",
        block: "12",
        street: "7",
        building: "28",
        floor: "G",
        flat: "1",
        phone: "+96555500288",
        additionalInfo: "The door is across the street ",
        isSelected: true,
        createdAt: "2023-10-09T17:58:53.982Z",
        updatedAt: "2024-10-22T19:41:34.177Z",
      },
      deliverectValidationId: "671801a30425e1b14b047453",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:58:51.365Z",
      estimatedDeliveryTime: "2024-10-22T20:13:50.303Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:45:00.000Z",
      orderId: "671801a4b7a34cd092fe3fd0",
      createdAt: "2024-10-22T19:48:51.457Z",
      updatedAt: "2024-10-22T19:50:06.032Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed56",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 330,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "Without vegetables and rice ",
        subItems: [
          {
            productId: "6717e83bb7a34cd092f5ee17",
            productPLU: "1906021-1",
            productName: "Extra  Crispy Chicken",
            productDescription: "C 24g| P 24g| F 11g| cal 296| coins 0.8",
            productCalories: 296,
            quantity: 1,
            fmcCentsPrice: 80,
            fiatCentsPrice: 80,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 24,
              protein: 24,
              fat: 11,
            },
          },
          {
            productId: "6717e83bb7a34cd092f5ee18",
            productPLU: "1907021-1",
            productName: "No Katsu Sauce",
            productDescription: "C -3.6g| P -0.5g| F -1g| cal -21| coins 0",
            productCalories: -21,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -3.6,
              protein: -0.5,
              fat: -1,
            },
          },
          {
            productId: "6717e83bb7a34cd092f5ee12",
            productPLU: "1907016-1",
            productName: "No White Rice",
            productDescription: "C -35g| P -3g| F -0.4g| cal -156| coins 0",
            productCalories: -156,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -35,
              protein: -3,
              fat: -0.4,
            },
          },
        ],
      },
      {
        productId: "6717e83bb7a34cd092f5ed8d",
        productPLU: "2166033-1",
        productDescription:
          "C 28g| P 15g| F 12g\nSpicy crispy shrimp bites served with ranch sauce.\n\nGood source of Protein, Vitamin B12 and Iron",
        productName: "Buffalo Shrimp Bites",
        imageURL:
          "https://resizer.deliverect.com/rvAuXhjdUl1okUgfHDTv8pSQt2Rjhn13XhdwXCPIEyw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnVmZmFsbyUyMHNocmltcCUyMGJpdGVzLTY1Y2IxNGU4Zjc3ODBhZDhlOTQyYWZlMy5wbmc=",
        productCalories: 280,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 15,
          fat: 12,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 240,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e83bb7a34cd092f5ee47",
            productPLU: "1906058-1",
            productName: "Extra  Breaded Shrimp",
            productDescription: "C 26g| P 15g| F 11g| cal 265| coins 1.6",
            productCalories: 265,
            quantity: 1,
            fmcCentsPrice: 160,
            fiatCentsPrice: 160,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 26,
              protein: 15,
              fat: 11,
            },
          },
        ],
      },
      {
        productId: "6717e83bb7a34cd092f5edbe",
        productPLU: "2202052-1",
        productDescription:
          "C 13g | P 0g | F 0g\nPomegranate, lemon\n\nGood source of Vitamin C",
        productName: "Pomade",
        imageURL:
          "https://resizer.deliverect.com/JpImLqr5JfNLPk8gTnl6NoZd9i6mdIhqPB4QA7H5lw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEtNjY1OTFhNzgxNmEzMzQyNTY5OGVhM2QxLnBuZw==",
        productCalories: 54,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 170,
        fiatCentsPrice: 170,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5edbd",
        productPLU: "2202051-1",
        productDescription:
          "C 51g | P 0.25g | F 0g\nWatermelon, lemon, mint\n\nGood source of Vitamin C",
        productName: "Waterlemon",
        imageURL:
          "https://resizer.deliverect.com/Z6SJv4l04sxDRKAG7bkaZpBT06F5O-JLEkDJxwTujFY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTQtNjY1OTE5Y2UxNmEzMzQyNTY5OGVhM2NiLnBuZw==",
        productCalories: 194,
        quantity: 1,
        nutritionInfo: {
          carbs: 51,
          protein: 0.25,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed44",
        productPLU: "2162100-1",
        productDescription:
          "C 21g | P 43g | F 14g\nGrilled peri peri chicken served with carrots, broccoli, mashed cauliflower, mashed potatoes & peri peri sauce.\n\nGood source of Protein, Vitamin A & C",
        productName: "Peri Peri Chicken",
        imageURL:
          "https://resizer.deliverect.com/AknH0sQlCRFCVD-wgAZApwhopxSIbFs4YPWXQoH9G88/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUEVSSSUyMFBFUkklMjBDSElDS0VOJTIwKDEpLTY2NDRiYzhhM2NiZjdhMzY3ZmI0YTI2MS5qcGc=",
        productCalories: 382,
        quantity: 1,
        nutritionInfo: {
          carbs: 21,
          protein: 43,
          fat: 14,
        },
        fmcCentsPrice: 440,
        fiatCentsPrice: 310,
        externalFiatCentsPrice: 0,
        remark: "Without vegetables ",
        subItems: [
          {
            productId: "6717e83bb7a34cd092f5edf8",
            productPLU: "1906066-1",
            productName: "Extra grilled peri peri Chicken",
            productDescription: "C 0.6g| P 39g| F 4g| cal 200| coins 1.3\n",
            productCalories: 200,
            quantity: 1,
            fmcCentsPrice: 130,
            fiatCentsPrice: 130,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 0.6,
              protein: 39,
              fat: 4,
            },
          },
          {
            productId: "6717e83bb7a34cd092f5edf9",
            productPLU: "1907071-1",
            productName: "No Cailiflower Mashed Potato Mix",
            productDescription: "C -14g| P -2g| F -3g| cal -91| coins 0\n",
            productCalories: -91,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -14,
              protein: -2,
              fat: -3,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 1490,
    totalFmcCentsPrice: 1490,
    totalFiatCentsPriceOriginal: 1490,
    totalExternalFiatCentsPrice: 1490,
    totalFmcCentsPriceOriginal: 1490,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:48:52.402Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:50:12.221Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:50:58.739Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:07:20.945Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:07:20.945Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:09:03.248Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:17:53.865Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:48:52.402Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:49:05.982Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:48:52.403Z",
    updatedAt: "2024-10-22T20:17:54.411Z",
    fleetInfo: null,
  },
  {
    id: "67180199b7a34cd092fe3e82",
    sid: "241022-116008",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "67180198b7a34cd092fe3e72",
    firstName: "Sulaiman alwahaibi ",
    lastName: null,
    phoneNumber: "+96596666137",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f9b2d28951a675da0d8bf3",
    delivery: {
      id: "67180198b7a34cd092fe3e72",
      customerId: "66f9b2d28951a675da0d8bf3",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f9b509ee3a746f7fbb5730",
        name: "Sulaiman. Sideeq",
        lng: 47.996928691864014,
        lat: 29.287460628693772,
        city: "Zahra",
        area: "Al-Siddeeq",
        block: "6",
        street: "601",
        building: "House number . 79",
        floor: "",
        flat: "",
        phone: "+96596666137",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-29T20:14:01.650Z",
        updatedAt: "2024-09-29T20:14:01.650Z",
      },
      deliverectValidationId: "67180198bd36c20e51deca25",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:58:40.377Z",
      estimatedDeliveryTime: "2024-10-22T20:13:39.308Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:45:00.000Z",
      orderId: "67180199b7a34cd092fe3e82",
      createdAt: "2024-10-22T19:48:40.465Z",
      updatedAt: "2024-10-22T19:50:06.266Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606fe",
        productPLU: "2162035-1",
        productDescription:
          "C 50g| P 32g| F 15g\nBlack pepper beef with veggies served with egyptian rice.\n\nGood source of Iron & B-12 Vitamins",
        productName: "Black Pepper Beef",
        imageURL:
          "https://resizer.deliverect.com/FBy7BxqQqLr64Ae34YvyRhL0jHWvib_v5F9riYKNFsc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNzYtNjRiN2IwNWM5Mjc2OTYwMDE5NmViYjFjLnBuZw==",
        productCalories: 474,
        quantity: 1,
        nutritionInfo: {
          carbs: 50,
          protein: 32,
          fat: 15,
        },
        fmcCentsPrice: 330,
        fiatCentsPrice: 330,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60711",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 24,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e84fb7a34cd092f607d3",
            productPLU: "1907028-1",
            productName: "No Pickles",
            productDescription: "C -0.2g| P 0g| F 0g| cal -1| coins 0",
            productCalories: -1,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -0.2,
              protein: null,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 430,
    totalFmcCentsPrice: 430,
    totalFiatCentsPriceOriginal: 430,
    totalExternalFiatCentsPrice: 430,
    totalFmcCentsPriceOriginal: 430,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:48:41.266Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:50:17.855Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:58:02.343Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:59:36.407Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:59:36.407Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:01:40.454Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:24:48.976Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:48:41.266Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:48:52.816Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:48:41.266Z",
    updatedAt: "2024-10-22T20:24:49.317Z",
    fleetInfo: null,
  },
  {
    id: "671800eeb7a34cd092fe222e",
    sid: "241022-376153",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "671800edb7a34cd092fe2223",
    firstName: "Mishari Al Dhulaei",
    lastName: null,
    phoneNumber: "+96566615671",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "668c0d6fe02b51659fd7a9e8",
    delivery: {
      id: "671800edb7a34cd092fe2223",
      customerId: "668c0d6fe02b51659fd7a9e8",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "668c0e4cbd8f0d8945e01dda",
        name: "Home",
        lng: 48.07689070701599,
        lat: 29.28431652689073,
        city: "Kuwait",
        area: "Salwa",
        block: "10",
        street: "1 St",
        building: "478",
        floor: "Ground",
        flat: "2",
        phone: "+96566615671",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-08T16:05:32.530Z",
        updatedAt: "2024-10-12T20:22:23.994Z",
      },
      deliverectValidationId: "671800ed2e12b6895077c5bf",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:55:49.703Z",
      estimatedDeliveryTime: "2024-10-22T20:10:48.601Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:42:00.000Z",
      orderId: "671800eeb7a34cd092fe222e",
      createdAt: "2024-10-22T19:45:49.799Z",
      updatedAt: "2024-10-22T19:47:05.602Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed5a",
        productPLU: "2162042-1",
        productDescription:
          "C 45g | P 31g | F 21g\nChicken alfredo pasta with truffle & mushrooms topped with parmesan cheese.\n\nGOOD  SOURCE OF PROTEIN AND VITAMINS",
        productName: "Truffle Chicken Alfredo",
        imageURL:
          "https://resizer.deliverect.com/KnOI9eoRZhWnaINgcAPgz2L1aJPoXQ_1V9aflYVvLuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTEwMC02NTE3ZWVhOTYyNmYyOWExYWRmYmZhNTguanBn",
        productCalories: 493,
        quantity: 1,
        nutritionInfo: {
          carbs: 45,
          protein: 31,
          fat: 21,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5edb7",
        productPLU: "2165005-1",
        productDescription: "C 13g| P 1g| F 10g",
        productName: "Salted Pecan Bites",
        imageURL:
          "https://resizer.deliverect.com/p3z6wZFsuIaNIkw99YRPwzlHvwGLg970lPG5QDvZQrc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDgtNjRiN2JjOTE2ZjhhYmY0M2UzMWQxMjBjLnBuZw==",
        productCalories: 151,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 1,
          fat: 10,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 310,
    totalFmcCentsPrice: 310,
    totalFiatCentsPriceOriginal: 310,
    totalExternalFiatCentsPrice: 310,
    totalFmcCentsPriceOriginal: 310,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:45:50.583Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:47:10.097Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T20:03:23.940Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T20:03:28.810Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T20:03:28.812Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:03:53.526Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:23:20.292Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:45:50.583Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:46:02.159Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:45:50.583Z",
    updatedAt: "2024-10-22T20:23:20.665Z",
    fleetInfo: null,
  },
  {
    id: "6717fff9b7a34cd092fdf876",
    sid: "241022-596312",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717fff8b7a34cd092fdf86d",
    firstName: "Namareq Khaled alansari",
    lastName: null,
    phoneNumber: "+96560056568",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "665ceacd0eba0d08a57169e6",
    delivery: {
      id: "6717fff8b7a34cd092fdf86d",
      customerId: "665ceacd0eba0d08a57169e6",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "665cf7af93dc7e98b4c0cf4e",
        name: "Home",
        lng: 47.901813704520464,
        lat: 29.233328867848776,
        city: "Abdullah Al Mubarak Al Sabah",
        area: "Abdullah Al Mubarak Al Sabah",
        block: "٦",
        street: "٦٢٩",
        building: "١٨",
        floor: "٢",
        flat: "٢",
        phone: "+96560056568",
        additionalInfo: "استخدام الباب على الجانب الأيمن",
        isSelected: true,
        createdAt: "2024-06-02T22:52:31.695Z",
        updatedAt: "2024-08-28T17:21:35.209Z",
      },
      deliverectValidationId: "6717fff8827135858b9cbc57",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:51:44.487Z",
      estimatedDeliveryTime: "2024-10-22T20:06:43.464Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:38:00.000Z",
      orderId: "6717fff9b7a34cd092fdf876",
      createdAt: "2024-10-22T19:41:44.576Z",
      updatedAt: "2024-10-22T19:43:04.513Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606ed",
        productPLU: "2162100-1",
        productDescription:
          "C 21g | P 43g | F 14g\nGrilled peri peri chicken served with carrots, broccoli, mashed cauliflower, mashed potatoes & peri peri sauce.\n\nGood source of Protein, Vitamin A & C",
        productName: "Peri Peri Chicken",
        imageURL:
          "https://resizer.deliverect.com/AknH0sQlCRFCVD-wgAZApwhopxSIbFs4YPWXQoH9G88/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUEVSSSUyMFBFUkklMjBDSElDS0VOJTIwKDEpLTY2NDRiYzhhM2NiZjdhMzY3ZmI0YTI2MS5qcGc=",
        productCalories: 382,
        quantity: 1,
        nutritionInfo: {
          carbs: 21,
          protein: 43,
          fat: 14,
        },
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 310,
    totalFmcCentsPrice: 310,
    totalFiatCentsPriceOriginal: 310,
    totalExternalFiatCentsPrice: 310,
    totalFmcCentsPriceOriginal: 310,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:41:45.386Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:43:18.314Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:43:57.513Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:58:42.552Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:58:42.553Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:11:33.587Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:28:39.356Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:41:45.386Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:41:56.977Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:41:45.386Z",
    updatedAt: "2024-10-22T20:28:39.693Z",
    fleetInfo: null,
  },
  {
    id: "6717ffbab7a34cd092fde6b0",
    sid: "241022-666451",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717ffb9b7a34cd092fde6a6",
    firstName: "Ahmad",
    lastName: null,
    phoneNumber: "+96569999647",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f5c34e8951a675da060ede",
    delivery: {
      id: "6717ffb9b7a34cd092fde6a6",
      customerId: "66f5c34e8951a675da060ede",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f5c432ee3a746f7f3d893a",
        name: "Dwanyat qourtoba",
        lng: 47.99126063775875,
        lat: 29.307785034179688,
        city: "Kuwait",
        area: "Qortuba",
        block: "2",
        street: "2 St. 6 Ln",
        building: "4",
        floor: "",
        flat: "",
        phone: "+96569999647",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-26T20:29:38.406Z",
        updatedAt: "2024-10-17T18:45:32.626Z",
      },
      deliverectValidationId: "6717ffb9ce0f62dcdf2a36ef",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:50:41.406Z",
      estimatedDeliveryTime: "2024-10-22T20:05:40.020Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:37:00.000Z",
      orderId: "6717ffbab7a34cd092fde6b0",
      createdAt: "2024-10-22T19:40:41.497Z",
      updatedAt: "2024-10-22T19:42:04.713Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f60702",
        productPLU: "2162030-1",
        productDescription:
          "C 42g | P 26g | F 21g\nLo mein noodles, beef, carrots, cabbage & zucchini.\n\n*Good source of Protein & vitamins*",
        productName: "Lo Mein Noodles",
        imageURL:
          "https://resizer.deliverect.com/gtToqQp69qSy57Ivr2G-IxNvZrR3UxiMiFxzdOFokw4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTE8lMjBNRUlOJTIwTk9PRExFUy02NTc1OTAxZDRmNzg3N2JlMWJhMWIyN2MucG5n",
        productCalories: 461,
        quantity: 1,
        nutritionInfo: {
          carbs: 42,
          protein: 26,
          fat: 21,
        },
        fmcCentsPrice: 390,
        fiatCentsPrice: 390,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60747",
        productPLU: "2166016-1",
        productDescription:
          "C 23g| P 7g| F 11g\nEdamame with spicy aoili sauce.\n\nGood source of Dietary fibre",
        productName: "Edamame",
        imageURL:
          "https://resizer.deliverect.com/M_MarA40YziTUu2xEfXvbnV7HlmJJ3vSOFpeOcKWcuw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNy02NTExNmUyZDRmNWNlOTI2ZTU5MTQxMjguanBn",
        productCalories: 220,
        quantity: 1,
        nutritionInfo: {
          carbs: 23,
          protein: 7,
          fat: 11,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 510,
    totalFmcCentsPrice: 510,
    totalFiatCentsPriceOriginal: 510,
    totalExternalFiatCentsPrice: 510,
    totalFmcCentsPriceOriginal: 510,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:40:42.148Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:42:17.379Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:42:53.161Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:57:50.142Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:57:50.142Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:58:31.260Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:18:12.447Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:40:42.148Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:40:53.718Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:40:42.148Z",
    updatedAt: "2024-10-22T20:18:12.781Z",
    fleetInfo: null,
  },
  {
    id: "6717ff5ab7a34cd092fdd6af",
    sid: "241022-222895",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717ff5ab7a34cd092fdd6a6",
    firstName: "Qutaiba AlAbdali",
    lastName: null,
    phoneNumber: "+96566639944",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66a7a24ef6ed0b29489a6fe7",
    delivery: {
      id: "6717ff5ab7a34cd092fdd6a6",
      customerId: "66a7a24ef6ed0b29489a6fe7",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "66a7caaa1c3dd8f82bca5ef1",
        name: "ديوانية السرة",
        lng: 47.99541290849447,
        lat: 29.318476155034027,
        city: "Kuwait",
        area: "Surra",
        block: "5",
        street: "3",
        building: "51",
        floor: "",
        flat: "",
        phone: "+96566639944",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-29T17:00:26.321Z",
        updatedAt: "2024-08-17T15:01:37.600Z",
      },
      deliverectValidationId: "6717ff5ae367b8b902c87d73",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:49:06.006Z",
      estimatedDeliveryTime: "2024-10-22T20:04:05.083Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:36:00.000Z",
      orderId: "6717ff5ab7a34cd092fdd6af",
      createdAt: "2024-10-22T19:39:06.146Z",
      updatedAt: "2024-10-22T19:41:05.578Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed47",
        productPLU: "2162087-1",
        productDescription:
          "C 46g| P 25g| F 12g\nSpaghetti pasta with shrimp & mushrooms, topped with parmesan cheese & parsley.\n\n*GOOD SOURCE OF PROTEIN,VITAMINS & MINERALS*",
        productName: "Shrimp Spaghetti",
        imageURL:
          "https://resizer.deliverect.com/IkDFhUuqKSMXxAw20RhORnBDSWi0egKJvZApFvBpa40/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc2hyaW1wJTIwcGFzdGEtNjcxMzU2MjNhYmRkODhiZjQyOWJlNTZlLnBuZw==",
        productCalories: 392,
        quantity: 1,
        nutritionInfo: {
          carbs: 46,
          protein: 25,
          fat: 12,
        },
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 310,
    totalFmcCentsPrice: 310,
    totalFiatCentsPriceOriginal: 310,
    totalExternalFiatCentsPrice: 310,
    totalFmcCentsPriceOriginal: 310,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:39:06.794Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:41:10.936Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:41:29.490Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:55:51.054Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:55:51.054Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:56:14.230Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:04:45.493Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:39:06.794Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:39:18.350Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:39:06.794Z",
    updatedAt: "2024-10-22T20:04:45.835Z",
    fleetInfo: null,
  },
  {
    id: "6717ff25b7a34cd092fdd0d9",
    sid: "241022-094426",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717ff24b7a34cd092fdd0d0",
    firstName: "Sherifa Osamah Alasousi",
    lastName: null,
    phoneNumber: "+96598990993",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65f96df2f669fdc7a27b3aad",
    delivery: {
      id: "6717ff24b7a34cd092fdd0d0",
      customerId: "65f96df2f669fdc7a27b3aad",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66530412de3f2e06c6582ce9",
        name: "Home sweet home",
        lng: 48.00011079758406,
        lat: 29.30125220973399,
        city: "Kuwait",
        area: "Al-Siddeeq",
        block: "1",
        street: "104",
        building: "83",
        floor: "",
        flat: "",
        phone: "+96598990993",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-26T09:42:42.767Z",
        updatedAt: "2024-10-21T18:18:10.523Z",
      },
      deliverectValidationId: "6717ff248ee7d1c2562d623a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:48:12.436Z",
      estimatedDeliveryTime: "2024-10-22T20:03:11.024Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:35:00.000Z",
      orderId: "6717ff25b7a34cd092fdd0d9",
      createdAt: "2024-10-22T19:38:12.581Z",
      updatedAt: "2024-10-22T19:40:07.998Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606eb",
        productPLU: "2162109-1",
        productDescription:
          "C 23g | P 29g | F 16g \n Beef Lasagna with zucchini & basil\n\nGood source of protein and vitamin A",
        productName: "Zucchini Beef Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SGhwFjSpMczUdKMaEIYf4gYXABWfE33sF_WueZYOSfI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGxhc2FnbmEtNjY0NGFmZDU3NDhmMzY3YzEzMTZlNWZhLnBuZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 23,
          protein: 29,
          fat: 16,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:38:13.086Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:40:22.910Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:42:40.725Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:47:56.634Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:47:56.635Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:01:11.275Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:17:05.196Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:38:13.086Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:38:24.507Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:38:13.086Z",
    updatedAt: "2024-10-22T20:17:05.532Z",
    fleetInfo: null,
  },
  {
    id: "6717ff14b7a34cd092fdc9f1",
    sid: "241022-943036",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717ff13b7a34cd092fdc9ce",
    firstName: "Dana alkhamees",
    lastName: null,
    phoneNumber: "+96594424460",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "652289ddc6da5aa4aeed5c59",
    delivery: {
      id: "6717ff13b7a34cd092fdc9ce",
      customerId: "652289ddc6da5aa4aeed5c59",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6522c9f3f3f5a7e1da159caa",
        name: "My apartment ",
        lng: 48.01190690687972,
        lat: 29.28643798828125,
        city: "Kuwait",
        area: "Hateen",
        block: "2",
        street: "220 ",
        building: "54",
        floor: "2",
        flat: "1",
        phone: "+96594424460",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-08T15:25:39.538Z",
        updatedAt: "2024-10-22T19:33:16.102Z",
      },
      deliverectValidationId: "6717ff13fbbda76c3f07d408",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:47:55.513Z",
      estimatedDeliveryTime: "2024-10-22T20:02:53.673Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:34:00.000Z",
      orderId: "6717ff14b7a34cd092fdc9f1",
      createdAt: "2024-10-22T19:37:55.634Z",
      updatedAt: "2024-10-22T19:39:05.724Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606ff",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:37:56.302Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:39:19.744Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:40:06.825Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:57:29.952Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:57:29.952Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:09:03.409Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:37:56.302Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:38:07.888Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:37:56.302Z",
    updatedAt: "2024-10-22T20:09:03.743Z",
    fleetInfo: null,
  },
  {
    id: "6717fe8bb7a34cd092fdb4af",
    sid: "241022-793562",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717fe8bb7a34cd092fdb4a0",
    firstName: "Abdullah h alroumi",
    lastName: null,
    phoneNumber: "+96599068582",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d96d4edfecd0088b52bcf5",
    delivery: {
      id: "6717fe8bb7a34cd092fdb4a0",
      customerId: "64d96d4edfecd0088b52bcf5",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "651418370a91a2efcd63a70d",
        name: "Home",
        lng: 48.06138686835766,
        lat: 29.29509108600071,
        city: "Kuwait",
        area: "Bayan",
        block: "10",
        street: "1",
        building: "Ave 15, house 13",
        floor: "",
        flat: "",
        phone: "+96599068582",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-09-27T11:55:35.924Z",
        updatedAt: "2024-10-22T19:34:37.376Z",
      },
      deliverectValidationId: "6717fe8b1cb32e3bce91d6b2",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:45:39.030Z",
      estimatedDeliveryTime: "2024-10-22T20:00:38.041Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:32:00.000Z",
      orderId: "6717fe8bb7a34cd092fdb4af",
      createdAt: "2024-10-22T19:35:39.144Z",
      updatedAt: "2024-10-22T19:37:06.018Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed4d",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e83bb7a34cd092f5edda",
            productPLU: "1906004-1",
            productName: "Extra  Beef Pattie",
            productDescription: "C 2g| P 6g| F 3g| cal 59| coins 0.5",
            productCalories: 59,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 2,
              protein: 6,
              fat: 3,
            },
          },
          {
            productId: "6717e83bb7a34cd092f5edef",
            productPLU: "1907008-1",
            productName: "No Dill Pickles",
            productDescription: "C 0g| P 0g| F 0g| cal -0.4| coins 0",
            productCalories: -0.4,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: null,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 280,
    totalFmcCentsPrice: 280,
    totalFiatCentsPriceOriginal: 280,
    totalExternalFiatCentsPrice: 280,
    totalFmcCentsPriceOriginal: 280,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:35:39.831Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:37:13.436Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:38:16.059Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:46:04.782Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:46:04.782Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:47:44.727Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:03:56.750Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:35:39.831Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:35:51.379Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:35:39.831Z",
    updatedAt: "2024-10-22T20:03:57.089Z",
    fleetInfo: null,
  },
  {
    id: "6717fe0bb7a34cd092fd9d39",
    sid: "241022-026475",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717fe0ab7a34cd092fd9d2e",
    firstName: "Nour mubarak ahmed",
    lastName: null,
    phoneNumber: "+96560066707",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66fb8f9c8951a675da116e97",
    delivery: {
      id: "6717fe0ab7a34cd092fd9d2e",
      customerId: "66fb8f9c8951a675da116e97",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6717fd81b7a34cd092fd8556",
        name: "NOUR",
        lng: 48.07911259999999,
        lat: 29.3063513,
        city: "Kuwait",
        area: "Salwa",
        block: "3",
        street: "6",
        building: "9",
        floor: "",
        flat: "",
        phone: "+96560066707",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-22T19:31:13.467Z",
        updatedAt: "2024-10-22T19:31:13.467Z",
      },
      deliverectValidationId: "6717fe0a6a3e6800dd0ca67a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:43:30.135Z",
      estimatedDeliveryTime: "2024-10-22T19:58:29.164Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:30:00.000Z",
      orderId: "6717fe0bb7a34cd092fd9d39",
      createdAt: "2024-10-22T19:33:30.241Z",
      updatedAt: "2024-10-22T19:35:05.850Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed4c",
        productPLU: "2162006-1",
        productDescription:
          "C 33g| P 19g| F 16g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed8f",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:33:31.021Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:35:05.516Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:36:33.915Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:46:15.639Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:46:15.640Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:55:50.915Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:30:16.238Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:33:31.021Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:33:42.581Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:33:31.021Z",
    updatedAt: "2024-10-22T20:30:16.584Z",
    fleetInfo: null,
  },
  {
    id: "6717fd87b7a34cd092fd871f",
    sid: "241022-161180",
    coupons: [],
    menuId: "6717e842b7a34cd092f5f5cf",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6717fd87b7a34cd092fd8713",
    firstName: "mohammad alkandari",
    lastName: null,
    phoneNumber: "+96555566673",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "650306fae057bc99ba5886e0",
    delivery: {
      id: "6717fd87b7a34cd092fd8713",
      customerId: "650306fae057bc99ba5886e0",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "650971d30a91a2efcd3938f6",
        name: "Home",
        lng: 48.09455841779709,
        lat: 29.21512140262271,
        city: "Fnaitees",
        area: "Fnaitees",
        block: "7",
        street: "702",
        building: "63",
        floor: "grand floor",
        flat: "",
        phone: "55566673",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-09-19T10:02:59.854Z",
        updatedAt: "2024-10-22T19:25:04.963Z",
      },
      deliverectValidationId: "6717fd878ee7d1c2562c61d3",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:41:19.131Z",
      estimatedDeliveryTime: "2024-10-22T19:56:17.094Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:28:00.000Z",
      orderId: "6717fd87b7a34cd092fd871f",
      createdAt: "2024-10-22T19:31:19.348Z",
      updatedAt: "2024-10-22T19:33:04.939Z",
    },
    items: [
      {
        productId: "6717e842b7a34cd092f5f647",
        productPLU: "2162107-1",
        productDescription:
          "C 30g | P 46g | F 9g\nGrilled zaatar chicken with saj bread and zaatar yogurt sauce. Contains coriander \n\nGood source of Protein ",
        productName: "Zaatar Chicken",
        imageURL:
          "https://resizer.deliverect.com/HLEGxWozw0ZDVfWyAN_TK_oChgkvffkzz-_bxUrGMJI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvemFhdGFyJTIwY2hpY2tlbi02NjQ0YjBjMTc0NWEzOTM5ZTIxNjY5MjAucG5n",
        productCalories: 385,
        quantity: 1,
        nutritionInfo: {
          carbs: 30,
          protein: 46,
          fat: 9,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e842b7a34cd092f5f6f9",
            productPLU: "1907073-1",
            productName: "No Saj bread",
            productDescription: "C -23g| P -3g| F -1g| cal -112| coins 0\n",
            productCalories: -112,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -23,
              protein: -3,
              fat: -1,
            },
          },
        ],
      },
      {
        productId: "6717e842b7a34cd092f5f695",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 350,
    totalExternalFiatCentsPrice: 350,
    totalFmcCentsPriceOriginal: 350,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:31:19.923Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:33:11.344Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:42:58.489Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:47:33.650Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:47:33.650Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:51:37.358Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:05:01.617Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:31:19.923Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:31:31.342Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:31:19.923Z",
    updatedAt: "2024-10-22T20:05:02.001Z",
    fleetInfo: null,
  },
  {
    id: "6717fd68b7a34cd092fd84b7",
    sid: "241022-111484",
    coupons: [],
    menuId: "6717e842b7a34cd092f5f5cf",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6717fd68b7a34cd092fd84ab",
    firstName: "Ohood ali",
    lastName: null,
    phoneNumber: "+96550020005",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "667c37848c15153ac88ebb81",
    delivery: {
      id: "6717fd68b7a34cd092fd84ab",
      customerId: "667c37848c15153ac88ebb81",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "667c385b86fe4b6ad12b9beb",
        name: "Home ",
        lng: 48.12473091019397,
        lat: 29.117279052734375,
        city: "Mangaf",
        area: "Mangaf",
        block: "1",
        street: "166 St",
        building: "33",
        floor: "",
        flat: "",
        phone: "+96550020005",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-06-26T15:48:43.485Z",
        updatedAt: "2024-10-01T17:52:53.220Z",
      },
      deliverectValidationId: "6717fd687b41664b9961ebea",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:40:48.108Z",
      estimatedDeliveryTime: "2024-10-22T19:55:47.118Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:27:00.000Z",
      orderId: "6717fd68b7a34cd092fd84b7",
      createdAt: "2024-10-22T19:30:48.214Z",
      updatedAt: "2024-10-22T19:32:05.358Z",
    },
    items: [
      {
        productId: "6717e842b7a34cd092f5f654",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e842b7a34cd092f5f6f0",
            productPLU: "1907011-1",
            productName: "No Lite Mayonnaise",
            productDescription: "C -1g| P 0g| F -3g| cal -30| coins 0",
            productCalories: -30,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: null,
              fat: -3,
            },
          },
        ],
      },
      {
        productId: "6717e842b7a34cd092f5f696",
        productPLU: "2166031-1",
        productDescription:
          "C 32g| P 12g| F 12g\nBaked sweet potato fries with mayo barbeque sauce.",
        productName: "Baked Sweet Potato Fries",
        imageURL:
          "https://resizer.deliverect.com/EexbDtzbKG0sVOjjNXiIlT_MIN2doyc2NlpJu2_T6nc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3dlZXQlMjBwb3RhdG8lMjBmcmllcygxKS02NTU0OGY3NzYyYWIwZTAwMWYzYTczNzEucG5n",
        productCalories: 244,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 12,
          fat: 12,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 350,
    totalExternalFiatCentsPrice: 350,
    totalFmcCentsPriceOriginal: 350,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:30:48.824Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:32:06.217Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:42:57.049Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:47:22.802Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:47:22.802Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T20:00:48.782Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:24:30.788Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:30:48.824Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:31:00.368Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:30:48.824Z",
    updatedAt: "2024-10-22T20:24:31.146Z",
    fleetInfo: null,
  },
  {
    id: "6717fceab7a34cd092fd635d",
    sid: "241022-818043",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717fce8b7a34cd092fd6107",
    firstName: "Fahad Alwazzan",
    lastName: null,
    phoneNumber: "+96560005576",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64d148bb65d114f018b967b9",
    delivery: {
      id: "6717fce8b7a34cd092fd6107",
      customerId: "64d148bb65d114f018b967b9",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64d9752a3902308f22ea5309",
        name: "home",
        lng: 47.95989146951223,
        lat: 29.327896118164062,
        city: "Kuwait City",
        area: "Khaldiya",
        block: "1",
        street: "16",
        building: "2",
        floor: "2",
        flat: "2",
        phone: "+96560005576",
        additionalInfo:
          "On the left of the house there is a door, continue stra",
        isSelected: true,
        createdAt: "2023-08-14T00:28:26.498Z",
        updatedAt: "2024-10-16T04:53:53.120Z",
      },
      deliverectValidationId: "6717fce7fbbda76c3f06a81d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:38:39.554Z",
      estimatedDeliveryTime: "2024-10-22T19:53:38.396Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:25:00.000Z",
      orderId: "6717fceab7a34cd092fd635d",
      createdAt: "2024-10-22T19:28:40.077Z",
      updatedAt: "2024-10-22T19:30:05.010Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606f6",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "add lettuce please",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60737",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60777",
        productPLU: "2202040-1",
        productDescription: "C 4.8g | P 0g | F 0g\nOrange, Grapefruit & Lemon",
        productName: "Immunity Shot",
        imageURL:
          "https://resizer.deliverect.com/WkO7ZBCzCZBVTRau0FCMoAizLyoqJmikHQl5iJRWhJs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvaW1tdW5pdHklMjBzaG90LTY2NDFkYjEwYWIxMzE0ZDg1Njc3OGNkZS5wbmc=",
        productCalories: 19,
        quantity: 1,
        nutritionInfo: {
          carbs: 4.8,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f606f6",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "add lettuce please",
        subItems: [
          {
            productId: "6717e84fb7a34cd092f6078f",
            productPLU: "1906003-1",
            productName: "Extra Chedder Cheese",
            productDescription: "C 0g| P 2g| F 3g| cal 40| coins 0.2",
            productCalories: 40,
            quantity: 1,
            fmcCentsPrice: 20,
            fiatCentsPrice: 20,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: 2,
              fat: 3,
            },
          },
          {
            productId: "6717e84fb7a34cd092f60783",
            productPLU: "1906004-1",
            productName: "Extra  Beef Pattie",
            productDescription: "C 2g| P 6g| F 3g| cal 59| coins 0.5",
            productCalories: 59,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 2,
              protein: 6,
              fat: 3,
            },
          },
        ],
      },
      {
        productId: "6717e84fb7a34cd092f60760",
        productPLU: "2165005-1",
        productDescription: "C 13g| P 1g| F 10g",
        productName: "Salted Pecan Bites",
        imageURL:
          "https://resizer.deliverect.com/p3z6wZFsuIaNIkw99YRPwzlHvwGLg970lPG5QDvZQrc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDgtNjRiN2JjOTE2ZjhhYmY0M2UzMWQxMjBjLnBuZw==",
        productCalories: 151,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 1,
          fat: 10,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 900,
    totalFmcCentsPrice: 900,
    totalFiatCentsPriceOriginal: 900,
    totalExternalFiatCentsPrice: 900,
    totalFmcCentsPriceOriginal: 900,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:28:42.767Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:30:23.002Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:33:10.370Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:47:45.771Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:47:45.772Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:56:25.910Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:16:12.310Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:28:42.767Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:28:54.225Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:28:42.767Z",
    updatedAt: "2024-10-22T20:16:12.767Z",
    fleetInfo: null,
  },
  {
    id: "6717fc9fb7a34cd092fd51e0",
    sid: "241022-553607",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717fc9fb7a34cd092fd51d4",
    firstName: "Abdullrahman Alrubaiaan",
    lastName: null,
    phoneNumber: "+96541116261",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67156793b58bc473083db756",
    delivery: {
      id: "6717fc9fb7a34cd092fd51d4",
      customerId: "67156793b58bc473083db756",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6717fbe9b7a34cd092fd228f",
        name: "D1 omarya",
        lng: 47.95580204576254,
        lat: 29.296879769210477,
        city: "Kuwait",
        area: "Omariya",
        block: "3",
        street: "شارع1",
        building: "27",
        floor: "28",
        flat: "",
        phone: "+96541116261",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-22T19:24:25.693Z",
        updatedAt: "2024-10-22T19:24:25.693Z",
      },
      deliverectValidationId: "6717fc9e88e23371a902a255",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:37:26.969Z",
      estimatedDeliveryTime: "2024-10-22T19:52:25.919Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:24:00.000Z",
      orderId: "6717fc9fb7a34cd092fd51e0",
      createdAt: "2024-10-22T19:27:27.064Z",
      updatedAt: "2024-10-22T19:29:05.481Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f6070f",
        productPLU: "2162073-1",
        productDescription:
          "C 55g| P 22g| F 20g [%Popular%]\nCrispy buffalo chicken tenders, iceberg lettuce, cheese with black pepper ranch sauce in a tortilla\n\nGOOD SOURCE OF PROTEIN, IRON, VITAMINS AND MINERALS ",
        productName: "Zinger Wrap",
        imageURL:
          "https://resizer.deliverect.com/HxB6Jk4SYuzyKeoB5PLI0G8vmLwmz39dUm-FWsICaAg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvWmluZ2VyJTIwd3JhcC02NWY2ZTkxOGMxMjg2NmZjODg2NjczZTIuanBn",
        productCalories: 491,
        quantity: 1,
        nutritionInfo: {
          carbs: 55,
          protein: 22,
          fat: 20,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f606ff",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 440,
    totalFmcCentsPrice: 440,
    totalFiatCentsPriceOriginal: 440,
    totalExternalFiatCentsPrice: 440,
    totalFmcCentsPriceOriginal: 440,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:27:27.755Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:29:16.607Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:33:13.153Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:44:09.911Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:44:09.912Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:45:52.702Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:04:11.877Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:27:27.755Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:27:39.239Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:27:27.755Z",
    updatedAt: "2024-10-22T20:04:12.217Z",
    fleetInfo: null,
  },
  {
    id: "6717fbfab7a34cd092fd27d2",
    sid: "241022-858371",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6717fbfab7a34cd092fd27c7",
    firstName: "Nasser alhadi",
    lastName: null,
    phoneNumber: "+96592222994",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66b79a0deb3fe809b1bdba87",
    delivery: {
      id: "6717fbfab7a34cd092fd27c7",
      customerId: "66b79a0deb3fe809b1bdba87",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66b79bf20846fbb356677322",
        name: "Almnar complax ",
        lng: 48.010382652282715,
        lat: 29.368585469224364,
        city: "Kuwait",
        area: "بنيد القار",
        block: "1",
        street: "Bour side st ",
        building: "Almnar ",
        floor: "16",
        flat: "",
        phone: "+96592222994",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-10T16:57:22.319Z",
        updatedAt: "2024-08-10T16:57:22.319Z",
      },
      deliverectValidationId: "6717fbfa827135858b9b2229",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:34:42.114Z",
      estimatedDeliveryTime: "2024-10-22T19:49:40.635Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:21:00.000Z",
      orderId: "6717fbfab7a34cd092fd27d2",
      createdAt: "2024-10-22T19:24:42.225Z",
      updatedAt: "2024-10-22T19:26:07.340Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd2e",
        productPLU: "2162047-1",
        productDescription:
          "C 51g| P 31g| F 11g \nPenne pasta in a mushroom & parmesan sauce, topped with grilled dijon chicken. \n\n*Good source of protein, Vitamin  D, & minerals*",
        productName: "Dijon Chicken Pasta",
        imageURL:
          "https://resizer.deliverect.com/LI0VRiKQ75jObW5px0dylKLXpRerpSCN0-9_VLTemyk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGFuZCUyMG11c2hyb29tJTIwcGFzdGEtNjY0NGJjYjAyNTFjMmQ2ZTEyNjQ3NTUwLnBuZw==",
        productCalories: 427,
        quantity: 1,
        nutritionInfo: {
          carbs: 51,
          protein: 31,
          fat: 11,
        },
        fmcCentsPrice: 280,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd71",
        productPLU: "2168002-1",
        productDescription:
          "C 4g | P 2g | F 2g|\nCreamy mushroom soup topped with parsley. ",
        productName: "Mushroom Soup",
        imageURL:
          "https://resizer.deliverect.com/VvtgR2CAH02ajyt1L7X3df8XtKjNQ2Kx-d7hCJY0Q1w/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBzb3VwLTY2NDM3YTg5ZTFhZTBmYmZhZDYyYmVhZC5qcGc=",
        productCalories: 42,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 2,
          fat: 2,
        },
        fmcCentsPrice: 140,
        fiatCentsPrice: 140,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 420,
    totalFmcCentsPrice: 420,
    totalFiatCentsPriceOriginal: 420,
    totalExternalFiatCentsPrice: 420,
    totalFmcCentsPriceOriginal: 420,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:24:42.789Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:26:16.280Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:35:30.667Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:35:47.183Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:35:47.183Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:36:08.717Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:00:48.848Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:24:42.789Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:24:54.348Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:24:42.789Z",
    updatedAt: "2024-10-22T20:00:49.187Z",
    fleetInfo: null,
  },
  {
    id: "6717fbeeb7a34cd092fd254f",
    sid: "241022-801761",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717fbedb7a34cd092fd252e",
    firstName: "Rawan alzamel ",
    lastName: null,
    phoneNumber: "+96596697777",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65ed88bd3ecea306239e418a",
    delivery: {
      id: "6717fbedb7a34cd092fd252e",
      customerId: "65ed88bd3ecea306239e418a",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65ed8af754bdfad2312d30a6",
        name: "Alshaya ",
        lng: 47.99031872302294,
        lat: 29.319377120827184,
        city: "Kuwait",
        area: "Qortuba",
        block: "1",
        street: "1 St avenue 3",
        building: "17",
        floor: "",
        flat: "",
        phone: "+96596697777",
        additionalInfo: "Avenue 3",
        isSelected: true,
        createdAt: "2024-03-10T10:27:03.971Z",
        updatedAt: "2024-10-05T13:15:24.110Z",
      },
      deliverectValidationId: "6717fbec621b7746ab4f3f31",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:34:28.942Z",
      estimatedDeliveryTime: "2024-10-22T19:49:27.536Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:21:00.000Z",
      orderId: "6717fbeeb7a34cd092fd254f",
      createdAt: "2024-10-22T19:24:29.418Z",
      updatedAt: "2024-10-22T19:26:06.404Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f60731",
        productPLU: "2150014-1",
        productDescription: "C 2g| P 30g| F 6g\n",
        productName: "Chicken Shawarma(100GM)",
        imageURL:
          "https://resizer.deliverect.com/5s0dpyz1hk8m1gvg8YGrM7u6yPuJzcNpjKxdUNjSi6g/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMHNoYXdhcm1hJTIwKDEpLTY0ZDc1YjZhNjg1NDg1MDAyNzQwZTNmNy5qcGc=",
        productCalories: 182,
        quantity: 1,
        nutritionInfo: {
          carbs: 2,
          protein: 30,
          fat: 6,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60743",
        productPLU: "2166036-1",
        productDescription:
          "C 30g | P 4g | F 9g \nTortilla chips,  guacamole & salsa",
        productName: "Salsa & Guac Nachos",
        imageURL:
          "https://resizer.deliverect.com/0WMmSGxzjSPDoEdAbH-9LvVLhnBDISj1o9ayRRQBqWA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU2Fsc2ElMjAlMjYlMjBndWFjJTIwbmFjaG9zLTY2NjFjZGE0NDliZDAxOWI4YmRhYWNkNS5wbmc=",
        productCalories: 217,
        quantity: 1,
        nutritionInfo: {
          carbs: 30,
          protein: 4,
          fat: 9,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60748",
        productPLU: "2166017-1",
        productDescription:
          "C 16g| P 8g| F 11g\nCarrots, cucumber, red & yellow capsicum, celery, purple & green endives with a hummus dip",
        productName: "Veggie Crudites",
        imageURL:
          "https://resizer.deliverect.com/I3FIyxNKPcOIzVfjKga2oVRpt99xbhQflVKJw27qrWM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcmUlMjA5Xy02NTA4MzM1ZjM5NGU3ODMxZTFiZDMyMDYucG5n",
        productCalories: 195,
        quantity: 1,
        nutritionInfo: {
          carbs: 16,
          protein: 8,
          fat: 11,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 430,
    totalFmcCentsPrice: 430,
    totalFiatCentsPriceOriginal: 430,
    totalExternalFiatCentsPrice: 430,
    totalFmcCentsPriceOriginal: 430,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:24:30.302Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:26:17.911Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:26:38.116Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:33:32.595Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:33:32.595Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:41:28.625Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:08:40.923Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:24:30.302Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:24:41.726Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:24:30.302Z",
    updatedAt: "2024-10-22T20:08:41.259Z",
    fleetInfo: null,
  },
  {
    id: "6717fbcab7a34cd092fd1b0c",
    sid: "241022-964992",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717fbc9b7a34cd092fd1b04",
    firstName: "Aisha hs",
    lastName: null,
    phoneNumber: "+96599977748",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "669a372cea7d0049ce6910c2",
    delivery: {
      id: "6717fbc9b7a34cd092fd1b04",
      customerId: "669a372cea7d0049ce6910c2",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6717faf2b7a34cd092fcdce2",
        name: "9A",
        lng: 48.000054471194744,
        lat: 29.310461004461516,
        city: "Kuwait",
        area: "Surra",
        block: "4",
        street: "16 St",
        building: "9A",
        floor: "",
        flat: "",
        phone: "+96599977748",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-22T19:20:18.449Z",
        updatedAt: "2024-10-22T19:20:18.449Z",
      },
      deliverectValidationId: "6717fbc9b8212c160f9101cb",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:33:53.436Z",
      estimatedDeliveryTime: "2024-10-22T19:48:51.856Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:20:00.000Z",
      orderId: "6717fbcab7a34cd092fd1b0c",
      createdAt: "2024-10-22T19:23:53.606Z",
      updatedAt: "2024-10-22T19:25:09.311Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed36",
        productPLU: "2162118-1",
        productDescription:
          "C 60g | P 22g | F 17g \nTomato sauce, cheese & pepperoni\n\nGood source of Protein ",
        productName: "Pepperoni Pizza",
        imageURL:
          "https://resizer.deliverect.com/QJO9SowT2ihHOIWJoe4NojUcvMSrdQhHrE3QE_iNeHs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvcGVwcGVyb25pLTY2NzQzMzkzMjU0YjRkYjE0ZjE5OTk4MS5wbmc=",
        productCalories: 481,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 22,
          fat: 17,
        },
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 310,
    totalFmcCentsPrice: 310,
    totalFiatCentsPriceOriginal: 310,
    totalExternalFiatCentsPrice: 310,
    totalFmcCentsPriceOriginal: 310,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:23:54.204Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:25:15.965Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:36:29.280Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:38:21.173Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:38:21.173Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:38:43.118Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:48:06.581Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:23:54.204Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:24:05.748Z",
      },
    ],
    cutlery: true,
    hasRating: true,
    timeslot: null,
    createdAt: "2024-10-22T19:23:54.204Z",
    updatedAt: "2024-10-22T19:55:00.895Z",
    fleetInfo: null,
  },
  {
    id: "6717fb44b7a34cd092fcff57",
    sid: "241022-428673",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717fb43b7a34cd092fcfe69",
    firstName: "Mohammad alabdulrazzaq ",
    lastName: null,
    phoneNumber: "+96599977747",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66e20d0aaa1645e3a5143c4c",
    delivery: {
      id: "6717fb43b7a34cd092fcfe69",
      customerId: "66e20d0aaa1645e3a5143c4c",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66e6c7edced6c5247e377ec2",
        name: "Home",
        lng: 47.98292638733983,
        lat: 29.31639760286176,
        city: "Kuwait",
        area: "Qortuba",
        block: "4",
        street: "2 St",
        building: "35",
        floor: "",
        flat: "",
        phone: "+96599977747",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-15T11:41:33.363Z",
        updatedAt: "2024-10-03T08:36:05.202Z",
      },
      deliverectValidationId: "6717fb423bbe5b0ef2275bb7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:31:38.819Z",
      estimatedDeliveryTime: "2024-10-22T19:46:37.371Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:18:00.000Z",
      orderId: "6717fb44b7a34cd092fcff57",
      createdAt: "2024-10-22T19:21:39.056Z",
      updatedAt: "2024-10-22T19:23:10.681Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606f5",
        productPLU: "2162006-1",
        productDescription:
          "C 33g| P 19g| F 16g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60738",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:21:40.061Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:23:22.268Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:25:15.827Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:33:39.575Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:33:39.576Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:41:39.945Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:58:31.448Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:21:40.061Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:21:43.762Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:21:40.061Z",
    updatedAt: "2024-10-22T19:58:31.801Z",
    fleetInfo: null,
  },
  {
    id: "6717fb0bb7a34cd092fce82c",
    sid: "241022-402368",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6717fb0ab7a34cd092fce81b",
    firstName: "Abdulaziz ",
    lastName: null,
    phoneNumber: "+96594900757",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "654753e29746b5cbf57f93b9",
    delivery: {
      id: "6717fb0ab7a34cd092fce81b",
      customerId: "654753e29746b5cbf57f93b9",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "654bad5a38bbe60b69f37756",
        name: "Diwaniya",
        lng: 47.97116909176111,
        lat: 29.3560475200857,
        city: "Kuwait",
        area: "Shamiya",
        block: "2",
        street: "22",
        building: "7",
        floor: "",
        flat: "",
        phone: "+96594900757",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-08T15:46:34.063Z",
        updatedAt: "2024-10-22T19:01:55.131Z",
      },
      deliverectValidationId: "6717fb0a07c41046b593504a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:30:42.288Z",
      estimatedDeliveryTime: "2024-10-22T19:45:41.265Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:17:00.000Z",
      orderId: "6717fb0bb7a34cd092fce82c",
      createdAt: "2024-10-22T19:20:42.380Z",
      updatedAt: "2024-10-22T19:22:06.314Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd1d",
        productPLU: "2162120-1",
        productDescription:
          "C 63g | P 29g | F 12g \nTomato sauce, cheese, chicken topped with BBQ sauce\n\nGood source of Protein ",
        productName: "BBQ Chicken Pizza",
        imageURL:
          "https://resizer.deliverect.com/oLi5aqw9dJEyegq-8KQAZCtGuNR2P65Gdii-y51sbns/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmJxJTIwcGl6emEtNjY3NDM0MDZhZjdmODYxNjFhMjNhOWYyLnBuZw==",
        productCalories: 476,
        quantity: 1,
        nutritionInfo: {
          carbs: 63,
          protein: 29,
          fat: 12,
        },
        fmcCentsPrice: 310,
        fiatCentsPrice: 310,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd14",
        productPLU: "2162129-1",
        productDescription:
          "C 47g | P 33g | F 14g\nPenne Pasta with chicken breast, broccoli based sauce topped with parmesan cheese & oregano",
        productName: "Broccoli Pasta",
        imageURL:
          "https://resizer.deliverect.com/q-jXVupL9907eYxO0rhsjsRQBexYeTKdjyCeZLvseEY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnJvY29sbGklMjBwYXN0YS02NmQwM2I5MTA4MjMwZDI3MGEwOWJhM2MucG5n",
        productCalories: 446,
        quantity: 1,
        nutritionInfo: {
          carbs: 47,
          protein: 33,
          fat: 14,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fda8",
        productPLU: "2202021-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Coca Cola Zero",
        imageURL:
          "https://resizer.deliverect.com/NvHctUF0z3us3Lhe9x5u3_mQIOOXnn8K3FvSZO6MkK4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCgxKS02NGM5MTk1MjZlY2Q3NzAwMjZjNGU1MDUuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 630,
    totalFmcCentsPrice: 630,
    totalFiatCentsPriceOriginal: 630,
    totalExternalFiatCentsPrice: 630,
    totalFmcCentsPriceOriginal: 630,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:20:43.003Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:22:15.173Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:23:52.120Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:35:25.241Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:35:25.241Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:35:47.090Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:41:57.859Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:20:43.003Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:20:54.441Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:20:43.004Z",
    updatedAt: "2024-10-22T19:41:58.198Z",
    fleetInfo: null,
  },
  {
    id: "6717faf9b7a34cd092fce331",
    sid: "241022-469744",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717faf8b7a34cd092fce313",
    firstName: "Saood Ben salamah",
    lastName: null,
    phoneNumber: "+96599441543",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64e8a2178073f39665e5589c",
    delivery: {
      id: "6717faf8b7a34cd092fce313",
      customerId: "64e8a2178073f39665e5589c",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "653cfba2289c08cbdbc1fa6d",
        name: "Home",
        lng: 47.97415304929018,
        lat: 29.31433783223742,
        city: "Kuwait",
        area: "Yarmouk",
        block: "1",
        street: "1",
        building: "50",
        floor: "",
        flat: "",
        phone: "+96599441543",
        additionalInfo: "House with a red shade",
        isSelected: true,
        createdAt: "2023-10-28T12:16:34.941Z",
        updatedAt: "2024-07-19T16:59:33.447Z",
      },
      deliverectValidationId: "6717faf810394423ecd7ede5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:30:24.895Z",
      estimatedDeliveryTime: "2024-10-22T19:45:23.730Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:17:00.000Z",
      orderId: "6717faf9b7a34cd092fce331",
      createdAt: "2024-10-22T19:20:24.997Z",
      updatedAt: "2024-10-22T19:22:06.114Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606d7",
        productPLU: "2162129-1",
        productDescription:
          "C 47g | P 33g | F 14g\nPenne Pasta with chicken breast, broccoli based sauce topped with parmesan cheese & oregano",
        productName: "Broccoli Pasta",
        imageURL:
          "https://resizer.deliverect.com/q-jXVupL9907eYxO0rhsjsRQBexYeTKdjyCeZLvseEY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnJvY29sbGklMjBwYXN0YS02NmQwM2I5MTA4MjMwZDI3MGEwOWJhM2MucG5n",
        productCalories: 446,
        quantity: 1,
        nutritionInfo: {
          carbs: 47,
          protein: 33,
          fat: 14,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f6074a",
        productPLU: "2166011-1",
        productDescription:
          "C 58g| P 11g| F 13g\nChocolate chia & flaxseed oats with peanut butter biscuit, orange zest, hazelnut chocolate and chocolate coverture.\n\nGood source of Vitamins & Dietary fibre",
        productName: "Choco Chia Oats",
        imageURL:
          "https://resizer.deliverect.com/Isi8w_ThjYiyjTMxCBPpzL50QvwOgBOSXLSqnE3bNhM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMi02NTExNmVmYzBhODZkZTcwYTAzMjg0NzkuanBn",
        productCalories: 393,
        quantity: 1,
        nutritionInfo: {
          carbs: 58,
          protein: 11,
          fat: 13,
        },
        fmcCentsPrice: 70,
        fiatCentsPrice: 70,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 340,
    totalExternalFiatCentsPrice: 340,
    totalFmcCentsPriceOriginal: 340,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:20:25.814Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:22:16.557Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:25:15.736Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:32:52.781Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:32:52.781Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:36:20.219Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:50:45.775Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:20:25.814Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:20:37.363Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:20:25.815Z",
    updatedAt: "2024-10-22T19:50:46.241Z",
    fleetInfo: null,
  },
  {
    id: "6717fa71b7a34cd092fcbce3",
    sid: "241022-688345",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717fa71b7a34cd092fcbcd7",
    firstName: "Abdulaziz Alnafisi",
    lastName: null,
    phoneNumber: "+96592292277",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67054696906276458c8a639a",
    delivery: {
      id: "6717fa71b7a34cd092fcbcd7",
      customerId: "67054696906276458c8a639a",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670a8871f495b693cd133bdd",
        name: "Diwanyat elsurra ",
        lng: 48.00991728901863,
        lat: 29.307813298701525,
        city: "Kuwait",
        area: "Surra",
        block: "3",
        street: "9",
        building: "16",
        floor: "",
        flat: "",
        phone: "+96592292277",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-12T14:32:17.969Z",
        updatedAt: "2024-10-22T19:16:14.929Z",
      },
      deliverectValidationId: "6717fa702924cb5a737faf7c",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:28:08.933Z",
      estimatedDeliveryTime: "2024-10-22T19:43:07.824Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:15:00.000Z",
      orderId: "6717fa71b7a34cd092fcbce3",
      createdAt: "2024-10-22T19:18:09.044Z",
      updatedAt: "2024-10-22T19:20:05.949Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed63",
        productPLU: "2162079-1",
        productDescription:
          "C 49g| P 12g| F 17g\nBaked cauliflower with  eggplant, potato, cucumber and falafel drizzled with tahini sauce in saj bread. \n\nGOOD SOURCE OF PROTEIN VITAMINS AND FIBER",
        productName: "Mushakal Wrap",
        imageURL:
          "https://resizer.deliverect.com/GAAYXxN6zgWfrJVNB3YeUO6Fr9gMhYKrP5SEQdiGD-s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaGFrZWwlMjB3cmFwLTY1NzU4ZmRlYjAxYTgyMzljNzk1ZmE2My5qcGc=",
        productCalories: 397,
        quantity: 1,
        nutritionInfo: {
          carbs: 49,
          protein: 12,
          fat: 17,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed42",
        productPLU: "2162109-1",
        productDescription:
          "C 23g | P 29g | F 16g \n Beef Lasagna with zucchini & basil\n\nGood source of protein and vitamin A",
        productName: "Zucchini Beef Lasagna",
        imageURL:
          "https://resizer.deliverect.com/SGhwFjSpMczUdKMaEIYf4gYXABWfE33sF_WueZYOSfI/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmVlZiUyMGxhc2FnbmEtNjY0NGFmZDU3NDhmMzY3YzEzMTZlNWZhLnBuZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 23,
          protein: 29,
          fat: 16,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:18:09.673Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:20:12.072Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:34:07.388Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:34:07.388Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:34:07.388Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:34:29.502Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:42:59.400Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:18:09.673Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:18:21.084Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:18:09.673Z",
    updatedAt: "2024-10-22T19:42:59.753Z",
    fleetInfo: null,
  },
  {
    id: "6717fa64b7a34cd092fcb7c8",
    sid: "241022-243986",
    coupons: [],
    menuId: "6717e842b7a34cd092f5f5cf",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6717fa62b7a34cd092fcb6ee",
    firstName: "Fahad",
    lastName: null,
    phoneNumber: "+96590959690",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "662ea9997ef16a176ee5b612",
    delivery: {
      id: "6717fa62b7a34cd092fcb6ee",
      customerId: "662ea9997ef16a176ee5b612",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "662eaa06c37a2a2c2661fd15",
        name: "Home",
        lng: 48.11569891870022,
        lat: 29.083498045733016,
        city: "Kuwait",
        area: "Fahaheel",
        block: "1",
        street: "10",
        building: "246",
        floor: "",
        flat: "",
        phone: "+96590959690",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-04-28T19:56:54.682Z",
        updatedAt: "2024-07-31T14:07:30.817Z",
      },
      deliverectValidationId: "6717fa624cb7e660cdfc81ea",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:27:54.468Z",
      estimatedDeliveryTime: "2024-10-22T19:42:52.940Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:14:00.000Z",
      orderId: "6717fa64b7a34cd092fcb7c8",
      createdAt: "2024-10-22T19:17:54.569Z",
      updatedAt: "2024-10-22T19:19:05.957Z",
    },
    items: [
      {
        productId: "6717e842b7a34cd092f5f68a",
        productPLU: "2162021-1",
        productDescription:
          "C 60g| P 50g| F 16g\nChicken shawarma served with calrose rice & drizzled with our shawarma sauce and sumac white onions.\n\nHigh in protein, ",
        productName: "Shawarma Bowl",
        imageURL:
          "https://resizer.deliverect.com/wpaWs0_xzYbkz_6vxHW2469_71kHXwgrjXuSA4epEwY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2hpY2tlbiUyMFNoYXdhcm1hJTIwYm93bC02NGY0OTRlOTFhNzliZjNkNzNkYjUzNjcuanBn",
        productCalories: 584,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 50,
          fat: 16,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f632",
        productPLU: "2162131-1",
        productDescription:
          "C 44g | P 19g | F 16g [%New%]\n\nSlider bun, beef patty, cheddar cheese, cranberry mayo sauce, grilled white onion, rocca (arugula), tomato slice",
        productName: "Rocca Slider",
        imageURL:
          "https://resizer.deliverect.com/RFgoMv_Ln3gENNMlWPtsbz7A-EydnJQdumAQGSYp2Ow/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjByb2NjYSUyMHNsaWRlci02NmY1NmYwMzVhMmUwYjZhZDQxZGNjZWQucG5n",
        productCalories: 396,
        quantity: 1,
        nutritionInfo: {
          carbs: 44,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 260,
        fiatCentsPrice: 260,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f693",
        productPLU: "2166033-1",
        productDescription:
          "C 28g| P 15g| F 12g\nSpicy crispy shrimp bites served with ranch sauce.\n\nGood source of Protein, Vitamin B12 and Iron",
        productName: "Buffalo Shrimp Bites",
        imageURL:
          "https://resizer.deliverect.com/rvAuXhjdUl1okUgfHDTv8pSQt2Rjhn13XhdwXCPIEyw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnVmZmFsbyUyMHNocmltcCUyMGJpdGVzLTY1Y2IxNGU4Zjc3ODBhZDhlOTQyYWZlMy5wbmc=",
        productCalories: 280,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 15,
          fat: 12,
        },
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 720,
    totalFmcCentsPrice: 720,
    totalFiatCentsPriceOriginal: 720,
    totalExternalFiatCentsPrice: 720,
    totalFmcCentsPriceOriginal: 720,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:17:56.174Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:19:13.342Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:25:47.088Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:34:29.559Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:34:29.560Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:34:51.409Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:01:16.913Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:17:56.174Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:18:08.231Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:17:56.174Z",
    updatedAt: "2024-10-22T20:01:17.362Z",
    fleetInfo: null,
  },
  {
    id: "6717f9b7b7a34cd092fc8d64",
    sid: "241022-772062",
    coupons: [],
    menuId: "6717e842b7a34cd092f5f5cf",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6717f9b6b7a34cd092fc8d4c",
    firstName: "Abdulaziz Alazmi",
    lastName: null,
    phoneNumber: "+96594008797",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c3c3f875a6aa442d0f06c3",
    delivery: {
      id: "6717f9b6b7a34cd092fc8d4c",
      customerId: "64c3c3f875a6aa442d0f06c3",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64ce9dad5e8f4fe1fb1860f1",
        name: "Aziz",
        lng: 48.09314925223589,
        lat: 29.22188659837488,
        city: "Fnaitees",
        area: "Fnaitees",
        block: "4",
        street: "402",
        building: "23",
        floor: "",
        flat: "",
        phone: "+96594008797",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-05T19:06:21.716Z",
        updatedAt: "2024-10-22T11:20:22.834Z",
      },
      deliverectValidationId: "6717f9b62e12b68950740d49",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:25:02.564Z",
      estimatedDeliveryTime: "2024-10-22T19:40:01.525Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:12:00.000Z",
      orderId: "6717f9b7b7a34cd092fc8d64",
      createdAt: "2024-10-22T19:15:02.853Z",
      updatedAt: "2024-10-22T19:17:08.710Z",
    },
    items: [
      {
        productId: "6717e842b7a34cd092f5f652",
        productPLU: "2162006-1",
        productDescription:
          "C 33g| P 19g| F 16g [%Popular%]\nDouble beef patties with melted cheddar cheese, iceberg lettuce, white onions, dill pickles & our special sauce.\n\nGood Source of Niacin & Vitamin B 12",
        productName: "FM Burger",
        imageURL:
          "https://resizer.deliverect.com/FQEzm47rraJ2s_TXvZjHQ71nFf7GYJEF8JJ7oHXuswM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMS02NDQ4ZmM4NjI2Y2ZhYTFmOTNkMDcwMjkuanBlZw==",
        productCalories: 352,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 19,
          fat: 16,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f697",
        productPLU: "2161023-1",
        productDescription:
          "C 37g| P 17g| F 13g\nFries topped with caramelized onions, ground beef, pickled jalapeños, american cheese & our special sauce. \n\n*GOOD SOURCE OF PROTEIN,VITAMINB12*",
        productName: "Messy Fries",
        imageURL:
          "https://resizer.deliverect.com/uhTtheRt0RZXomxI_1n5O4G2cP8KUacFKd3MVV-dhio/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTUVTU1klMjBGUklFUy02NTc1OGZhM2QyZWUyNWQ5YmZkZDE4YWUucG5n",
        productCalories: 333,
        quantity: 1,
        nutritionInfo: {
          carbs: 37,
          protein: 17,
          fat: 13,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f6bd",
        productPLU: "2165005-1",
        productDescription: "C 13g| P 1g| F 10g",
        productName: "Salted Pecan Bites",
        imageURL:
          "https://resizer.deliverect.com/p3z6wZFsuIaNIkw99YRPwzlHvwGLg970lPG5QDvZQrc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDgtNjRiN2JjOTE2ZjhhYmY0M2UzMWQxMjBjLnBuZw==",
        productCalories: 151,
        quantity: 1,
        nutritionInfo: {
          carbs: 13,
          protein: 1,
          fat: 10,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 500,
    totalFmcCentsPrice: 500,
    totalFiatCentsPriceOriginal: 500,
    totalExternalFiatCentsPrice: 500,
    totalFmcCentsPriceOriginal: 500,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:15:03.712Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:17:31.133Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:25:48.391Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:36:42.774Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:36:42.774Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:37:04.753Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T20:09:14.586Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:15:03.712Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:15:15.666Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:15:03.712Z",
    updatedAt: "2024-10-22T20:09:14.949Z",
    fleetInfo: null,
  },
  {
    id: "6717f9a9b7a34cd092fc8a68",
    sid: "241022-502747",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6717f9a8b7a34cd092fc8a53",
    firstName: "Mohammad m dashti",
    lastName: null,
    phoneNumber: "+96594017560",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67002c26906276458c7e13d8",
    delivery: {
      id: "6717f9a8b7a34cd092fc8a53",
      customerId: "67002c26906276458c7e13d8",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6700479d218a8c4af20cea53",
        name: "Mansourya ",
        lng: 47.990464947052665,
        lat: 29.358963012695312,
        city: "Kuwait",
        area: "Mansouriya",
        block: "1",
        street: "15 St",
        building: "16B",
        floor: "",
        flat: "",
        phone: "+96594017560",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-04T19:53:01.148Z",
        updatedAt: "2024-10-04T19:53:01.148Z",
      },
      deliverectValidationId: "6717f9a84871f32c098a7167",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:24:48.346Z",
      estimatedDeliveryTime: "2024-10-22T19:39:47.148Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:11:00.000Z",
      orderId: "6717f9a9b7a34cd092fc8a68",
      createdAt: "2024-10-22T19:14:48.746Z",
      updatedAt: "2024-10-22T19:16:05.300Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd23",
        productPLU: "2162111-1",
        productDescription:
          "C 72g | P 23g | F 34g[%Popular%]\nChicken burger with baked sweet potato fries and bbq sauce",
        productName: "Chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/fMQuypdGrqkSRZhbEebRsjjcen1dY6rXtUgYEcTeE9s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cmdlciUyMGNvbWJvLTY3MTM1NzgzMWM2ZDc0YTE4NGJmNTY2ZC5wbmc=",
        productCalories: 686,
        quantity: 1,
        nutritionInfo: {
          carbs: 72,
          protein: 23,
          fat: 34,
        },
        fmcCentsPrice: 410,
        fiatCentsPrice: 340,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e846b7a34cd092f5fdcc",
            productPLU: "1906003-1",
            productName: "Extra Chedder Cheese",
            productDescription: "C 0g| P 2g| F 3g| cal 40| coins 0.2",
            productCalories: 40,
            quantity: 1,
            fmcCentsPrice: 20,
            fiatCentsPrice: 20,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: 2,
              fat: 3,
            },
          },
          {
            productId: "6717e846b7a34cd092f5fdcd",
            productPLU: "1906005-1",
            productName: "Extra  Chicken Pattie",
            productDescription: "C 13g| P 15g| F 2g| cal 136| coins 0.5",
            productCalories: 136,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 13,
              protein: 15,
              fat: 2,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 410,
    totalFmcCentsPrice: 410,
    totalFiatCentsPriceOriginal: 410,
    totalExternalFiatCentsPrice: 410,
    totalFmcCentsPriceOriginal: 410,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:14:49.428Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:16:14.091Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:16:50.872Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:33:33.708Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:33:33.709Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:33:55.578Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:47:23.154Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:14:49.428Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:15:01.964Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:14:49.428Z",
    updatedAt: "2024-10-22T19:47:23.612Z",
    fleetInfo: null,
  },
  {
    id: "6717f92ab7a34cd092fc72c4",
    sid: "241022-994891",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717f929b7a34cd092fc726d",
    firstName: "Dalal Abdullah AlHarban",
    lastName: null,
    phoneNumber: "+96599617779",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65d496b9b07238805078ab73",
    delivery: {
      id: "6717f929b7a34cd092fc726d",
      customerId: "65d496b9b07238805078ab73",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65d4f3a3393070be7ec3862f",
        name: "Home",
        lng: 48.002124801278114,
        lat: 29.319851516041425,
        city: "Kuwait",
        area: "Surra",
        block: "6",
        street: "4 St",
        building: "House 2",
        floor: "",
        flat: "",
        phone: "+96599617779",
        additionalInfo: "Grey house on the corner",
        isSelected: true,
        createdAt: "2024-02-20T18:46:59.365Z",
        updatedAt: "2024-02-20T18:46:59.365Z",
      },
      deliverectValidationId: "6717f928e3fb9ce5e7da9200",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:22:40.629Z",
      estimatedDeliveryTime: "2024-10-22T19:37:39.476Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:09:00.000Z",
      orderId: "6717f92ab7a34cd092fc72c4",
      createdAt: "2024-10-22T19:12:41.654Z",
      updatedAt: "2024-10-22T19:14:05.482Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed64",
        productPLU: "2162076-1",
        productDescription:
          "C 57g| P 32g| F 12g\nGrilled chicken, rice, corn, mixed beans sauce, iceburg lettuce, chedder cheese, mozarella cheese & ranch all wrapped in a tortilla wrap.\n\nGOOD SOURCE OF PROTEIN, VITAMINS AND MINERALS",
        productName: "Chicken Burrito",
        imageURL:
          "https://resizer.deliverect.com/6YIIS1bTpbzMr3WFtmPqqbcL9yd4ZmTrgUo1MJkaRzE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cnJpdG8lMjB3cmFwLTY1NzU4ZmY2MTk4NDQxOWFmYWNhOTI3MS5qcGc=",
        productCalories: 464,
        quantity: 1,
        nutritionInfo: {
          carbs: 57,
          protein: 32,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed8f",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5edb0",
        productPLU: "2166050-1",
        productDescription:
          "C 6g | P 2g | F 8g [%New%]\n\nPecan nuts, digestive, stevia, unsalted butter, cream cheese, heavy cream, egg, sour cream, all purpose flour, vanilla extract, syrup.",
        productName: "Pecan cheesecake",
        imageURL:
          "https://resizer.deliverect.com/V5P-zIm2i9d9zMnIsCSZ3RcaQiBGbqJ8glcvZNImsFM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hlZXNjYWtlJTIwYml0ZS02NmY5MGRhZmE0OGIxYjQxMWRmZTBiZTAucG5n",
        productCalories: 104,
        quantity: 1,
        nutritionInfo: {
          carbs: 6,
          protein: 2,
          fat: 8,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed7e",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.2,
          protein: 2.8,
          fat: 8.6,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 430,
    totalFmcCentsPrice: 430,
    totalFiatCentsPriceOriginal: 430,
    totalExternalFiatCentsPrice: 430,
    totalFmcCentsPriceOriginal: 430,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:12:42.560Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:14:16.042Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:32:11.789Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:34:51.498Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:34:51.498Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:35:13.391Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:52:56.283Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:12:42.560Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:13:00.119Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:12:42.560Z",
    updatedAt: "2024-10-22T19:52:56.618Z",
    fleetInfo: null,
  },
  {
    id: "6717f925b7a34cd092fc6f5b",
    sid: "241022-465367",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717f924b7a34cd092fc6f52",
    firstName: "Maryam alnemash",
    lastName: null,
    phoneNumber: "+96555090936",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65d66e09a19a3e2ee61a8bb2",
    delivery: {
      id: "6717f924b7a34cd092fc6f52",
      customerId: "65d66e09a19a3e2ee61a8bb2",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: 100,
      deliveryAddress: {
        id: "65d66eb1393070be7ec880ff",
        name: "Home",
        lng: 48.057798743247986,
        lat: 29.30574166877848,
        city: "Kuwait City",
        area: "Bayan",
        block: "4",
        street: "5",
        building: "5",
        floor: "",
        flat: "",
        phone: "+96555090936",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-02-21T21:44:17.495Z",
        updatedAt: "2024-06-09T14:12:15.389Z",
      },
      deliverectValidationId: "6717f9240348c4b0ea81b9da",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:22:36.449Z",
      estimatedDeliveryTime: "2024-10-22T19:37:34.572Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:09:00.000Z",
      orderId: "6717f925b7a34cd092fc6f5b",
      createdAt: "2024-10-22T19:12:36.693Z",
      updatedAt: "2024-10-22T19:14:05.419Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed56",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:12:37.509Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:14:16.250Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:32:01.956Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:32:03.866Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:32:03.867Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:32:25.713Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:59:56.661Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:12:37.509Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:12:49.142Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:12:37.509Z",
    updatedAt: "2024-10-22T19:59:57.025Z",
    fleetInfo: null,
  },
  {
    id: "6717f8b8b7a34cd092fc4bd7",
    sid: "241022-243952",
    coupons: [],
    menuId: "6717e842b7a34cd092f5f5cf",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6717f8b8b7a34cd092fc4bcd",
    firstName: "Nura almesnid",
    lastName: null,
    phoneNumber: "+96555288887",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64fea0a0ec522879c1b428a8",
    delivery: {
      id: "6717f8b8b7a34cd092fc4bcd",
      customerId: "64fea0a0ec522879c1b428a8",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "652bec66289c08cbdb717cc3",
        name: "Home",
        lng: 48.057927622827556,
        lat: 29.234573364257812,
        city: "Sabah Al Salem",
        area: "Al-Adan District",
        block: "2",
        street: "45",
        building: "13",
        floor: "",
        flat: "",
        phone: "+96555288887",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-15T13:43:02.374Z",
        updatedAt: "2024-10-22T19:08:12.390Z",
      },
      deliverectValidationId: "6717f8b8c581fc4bf1e845c2",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:20:48.022Z",
      estimatedDeliveryTime: "2024-10-22T19:35:46.761Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:07:00.000Z",
      orderId: "6717f8b8b7a34cd092fc4bd7",
      createdAt: "2024-10-22T19:10:48.128Z",
      updatedAt: "2024-10-22T19:12:06.465Z",
    },
    items: [
      {
        productId: "6717e842b7a34cd092f5f64e",
        productPLU: "2162047-1",
        productDescription:
          "C 51g| P 31g| F 11g \nPenne pasta in a mushroom & parmesan sauce, topped with grilled dijon chicken. \n\n*Good source of protein, Vitamin  D, & minerals*",
        productName: "Dijon Chicken Pasta",
        imageURL:
          "https://resizer.deliverect.com/LI0VRiKQ75jObW5px0dylKLXpRerpSCN0-9_VLTemyk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGFuZCUyMG11c2hyb29tJTIwcGFzdGEtNjY0NGJjYjAyNTFjMmQ2ZTEyNjQ3NTUwLnBuZw==",
        productCalories: 427,
        quantity: 1,
        nutritionInfo: {
          carbs: 51,
          protein: 31,
          fat: 11,
        },
        fmcCentsPrice: 340,
        fiatCentsPrice: 280,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e842b7a34cd092f5f702",
            productPLU: "1906009-1",
            productName: "Extra  Grilled Chicken Dijun",
            productDescription: "C 1g| P 17g| F 2.5g| cal 96| coins 0.6",
            productCalories: 96,
            quantity: 1,
            fmcCentsPrice: 60,
            fiatCentsPrice: 60,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 1,
              protein: 17,
              fat: 2.5,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 340,
    totalExternalFiatCentsPrice: 340,
    totalFmcCentsPriceOriginal: 340,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:10:48.917Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:12:07.276Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:13:03.986Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:25:52.227Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:25:52.227Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:33:22.289Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:51:13.061Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:10:48.917Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:11:00.457Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:10:48.917Z",
    updatedAt: "2024-10-22T19:51:13.403Z",
    fleetInfo: null,
  },
  {
    id: "6717f88eb7a34cd092fc3eac",
    sid: "241022-253411",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6717f88db7a34cd092fc3ea1",
    firstName: "Fahad Aldowaisan",
    lastName: null,
    phoneNumber: "+96567674784",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "650c7f66e1d91353418a527d",
    delivery: {
      id: "6717f88db7a34cd092fc3ea1",
      customerId: "650c7f66e1d91353418a527d",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65422a3ef91376d39457fff7",
        name: "Home",
        lng: 47.972038462758064,
        lat: 29.345289320552173,
        city: "Kuwait",
        area: "Shamiya",
        block: "9",
        street: "92",
        building: "20",
        floor: "",
        flat: "",
        phone: "+96567674784",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-01T10:36:46.546Z",
        updatedAt: "2024-10-19T08:57:08.090Z",
      },
      deliverectValidationId: "6717f88de3fb9ce5e7da678f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:20:05.724Z",
      estimatedDeliveryTime: "2024-10-22T19:35:04.727Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:07:00.000Z",
      orderId: "6717f88eb7a34cd092fc3eac",
      createdAt: "2024-10-22T19:10:05.860Z",
      updatedAt: "2024-10-22T19:12:11.127Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd74",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd3b",
        productPLU: "2162035-1",
        productDescription:
          "C 50g| P 32g| F 15g\nBlack pepper beef with veggies served with egyptian rice.\n\nGood source of Iron & B-12 Vitamins",
        productName: "Black Pepper Beef",
        imageURL:
          "https://resizer.deliverect.com/FBy7BxqQqLr64Ae34YvyRhL0jHWvib_v5F9riYKNFsc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNzYtNjRiN2IwNWM5Mjc2OTYwMDE5NmViYjFjLnBuZw==",
        productCalories: 474,
        quantity: 1,
        nutritionInfo: {
          carbs: 50,
          protein: 32,
          fat: 15,
        },
        fmcCentsPrice: 330,
        fiatCentsPrice: 330,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 540,
    totalFmcCentsPrice: 540,
    totalFiatCentsPriceOriginal: 540,
    totalExternalFiatCentsPrice: 540,
    totalFmcCentsPriceOriginal: 540,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:10:06.368Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:12:09.016Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:12:47.785Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:28:04.819Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:28:04.819Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:28:26.692Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:37:47.758Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:10:06.368Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:10:19.423Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:10:06.369Z",
    updatedAt: "2024-10-22T19:37:48.113Z",
    fleetInfo: null,
  },
  {
    id: "6717f884b7a34cd092fc3d53",
    sid: "241022-466796",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717f883b7a34cd092fc3bb2",
    firstName: "Mohammad alshamlan",
    lastName: null,
    phoneNumber: "+96599352352",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66c239276c59ceb40a753224",
    delivery: {
      id: "6717f883b7a34cd092fc3bb2",
      customerId: "66c239276c59ceb40a753224",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66c23a426a31f4fea75b90d8",
        name: "Home",
        lng: 48.05414121598005,
        lat: 29.293122323652803,
        city: "Kuwait",
        area: "Bayan",
        block: "9",
        street: "1",
        building: "12",
        floor: "Avenue 5",
        flat: "",
        phone: "+96599352352",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-18T18:15:30.697Z",
        updatedAt: "2024-10-22T19:08:02.045Z",
      },
      deliverectValidationId: "6717f8821cb32e3bce8df202",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:19:54.973Z",
      estimatedDeliveryTime: "2024-10-22T19:34:53.499Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:06:00.000Z",
      orderId: "6717f884b7a34cd092fc3d53",
      createdAt: "2024-10-22T19:09:55.092Z",
      updatedAt: "2024-10-22T19:11:07.880Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed55",
        productPLU: "2162035-1",
        productDescription:
          "C 50g| P 32g| F 15g\nBlack pepper beef with veggies served with egyptian rice.\n\nGood source of Iron & B-12 Vitamins",
        productName: "Black Pepper Beef",
        imageURL:
          "https://resizer.deliverect.com/FBy7BxqQqLr64Ae34YvyRhL0jHWvib_v5F9riYKNFsc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNzYtNjRiN2IwNWM5Mjc2OTYwMDE5NmViYjFjLnBuZw==",
        productCalories: 474,
        quantity: 1,
        nutritionInfo: {
          carbs: 50,
          protein: 32,
          fat: 15,
        },
        fmcCentsPrice: 330,
        fiatCentsPrice: 330,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed7b",
        productPLU: "2163010-1",
        productDescription:
          "C 25g| P 34g| F 15g\nGrilled chicken with baby gem lettuce, caesar dressing topped with sourdough croutons & shaved parmesan cheese.\n\nGood source of Vitamin A &Potein",
        productName: "Chicken Ceasar Salad",
        imageURL:
          "https://resizer.deliverect.com/aOyM36xC7gBB5GwjyrYYA3YsTJ8-9RRXq22EPXeQlkM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NGI3YjVjOWU0ZDVjMzAwMThiNjViNmIucG5n",
        productCalories: 371,
        quantity: 1,
        nutritionInfo: {
          carbs: 25,
          protein: 34,
          fat: 15,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 600,
    totalFmcCentsPrice: 600,
    totalFiatCentsPriceOriginal: 600,
    totalExternalFiatCentsPrice: 600,
    totalFmcCentsPriceOriginal: 600,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:09:56.945Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:11:26.512Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:11:59.180Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:31:07.784Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:31:07.784Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:31:29.657Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:52:15.272Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:09:56.945Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:10:08.516Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:09:56.946Z",
    updatedAt: "2024-10-22T19:52:15.721Z",
    fleetInfo: null,
  },
  {
    id: "6717f870b7a34cd092fc352e",
    sid: "241022-066313",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6717f870b7a34cd092fc3521",
    firstName: "homoud ",
    lastName: null,
    phoneNumber: "+96599920007",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "670ff940aa779ec4a78f4c36",
    delivery: {
      id: "6717f870b7a34cd092fc3521",
      customerId: "670ff940aa779ec4a78f4c36",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670ff9e7f495b693cd1aae6b",
        name: "dowaneya",
        lng: 47.99310218542814,
        lat: 29.337415699397184,
        city: "Kuwait",
        area: "Nuzha",
        block: "3",
        street: "34",
        building: "15",
        floor: "",
        flat: "",
        phone: "+96599920007",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-16T17:37:43.921Z",
        updatedAt: "2024-10-22T19:07:48.971Z",
      },
      deliverectValidationId: "6717f8704cb7e660cdfb6f54",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:19:36.002Z",
      estimatedDeliveryTime: "2024-10-22T19:34:34.778Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:06:00.000Z",
      orderId: "6717f870b7a34cd092fc352e",
      createdAt: "2024-10-22T19:09:36.143Z",
      updatedAt: "2024-10-22T19:11:06.542Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd31",
        productPLU: "2162008-1",
        productDescription:
          "C 40g | P 21g | F 24g [%Popular%]\nCrispy chicken with pepper jack cheese, coleslaw mix, dill pickles & a spicy mayo sauce.",
        productName: "Spicy Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/V3b1iOeX1UmC1nng8xEmFG8Y02arGnZCgSM1cET5hoo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BpY3klMjBjaGlja2VuJTIwYnVyZ2VyKDEpLTY1NTQ4ZjI5ODEyNDJlMDAxOGYwYWIyZi5wbmc=",
        productCalories: 460,
        quantity: 2,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 24,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd59",
        productPLU: "2162106-1",
        productDescription:
          "C 31g | P 7g | F 18g\nCorn, shishito pepper, jalapeno, coriander, feta cheese, red onion, shishito salad dressing\n\nGood source of Vitamins ",
        productName: "Shishito Corn Salad",
        imageURL:
          "https://resizer.deliverect.com/6rdXnPFXcSncZOuJkUg1u6lTeZ62zbWaCh8VKxZvZJw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCUyQiUyMHJlc2l6ZWQtNjY0NGFmYWQ1YzZkZWYwMWMzM2MzYzE1LnBuZw==",
        productCalories: 314,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 7,
          fat: 18,
        },
        fmcCentsPrice: 350,
        fiatCentsPrice: 350,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd74",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 960,
    totalFmcCentsPrice: 960,
    totalFiatCentsPriceOriginal: 960,
    totalExternalFiatCentsPrice: 960,
    totalFmcCentsPriceOriginal: 960,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:09:36.955Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:11:07.841Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:11:54.134Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:28:26.852Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:28:26.852Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:28:49.086Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:51:43.445Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:09:36.955Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:09:48.371Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:09:36.955Z",
    updatedAt: "2024-10-22T19:51:43.788Z",
    fleetInfo: null,
  },
  {
    id: "6717f855b7a34cd092fc32b7",
    sid: "241022-514705",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f854b7a34cd092fc32ac",
    firstName: "Fajer aldhumaid",
    lastName: null,
    phoneNumber: "+96599169632",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67165516b58bc473083fbced",
    delivery: {
      id: "6717f854b7a34cd092fc32ac",
      customerId: "67165516b58bc473083fbced",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67165602b7a34cd092a3fe29",
        name: "Fajer",
        lng: 48.01521670105871,
        lat: 29.297805786132812,
        city: "Kuwait",
        area: "Salam",
        block: "3",
        street: "306",
        building: "14",
        floor: "Main door garden",
        flat: "",
        phone: "+96599169632",
        additionalInfo: "Corner House with garden ",
        isSelected: true,
        createdAt: "2024-10-21T13:24:18.902Z",
        updatedAt: "2024-10-21T13:24:18.902Z",
      },
      deliverectValidationId: "6717f854e0ba6b8ca0f570f7",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:19:08.713Z",
      estimatedDeliveryTime: "2024-10-22T19:34:06.770Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:06:00.000Z",
      orderId: "6717f855b7a34cd092fc32b7",
      createdAt: "2024-10-22T19:09:08.855Z",
      updatedAt: "2024-10-22T19:11:05.908Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f60744",
        productPLU: "2166037-1",
        productDescription:
          "C 31g | P 3g | F 8g \nTortilla chips, cheddar cheese, & pickled jalapeños. ",
        productName: "Jalapeño Cheddar Nachos ",
        imageURL:
          "https://resizer.deliverect.com/Igtv_X3IIKdvVYw3drGOS6DlDQf47bmEkgol0b9fBZ0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFsYXBlbm8lMjBjaGVlc2UlMjBuYWNob3MtNjY2MWNkZWQ5YWNlYTFmMWZlZWVkOTU5LnBuZw==",
        productCalories: 208,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 3,
          fat: 8,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60711",
        productPLU: "2167002-1",
        productDescription:
          "C 27g| P 24g| F 7g [%Best seller%]\nChicken with our special sauce and pickled cucumber wrapped in tortilla.",
        productName: "Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/HEyEG0TkmlGlqN1vsuE_sHWX8A3C7OxcrAZjV-kjs5Y/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMjQtNjRiN2I2YjFhZTM2MzAwMDE4YTcwYTM2LnBuZw==",
        productCalories: 272,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 24,
          fat: 7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:09:09.806Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:11:16.672Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:15:54.117Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:30:23.275Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:30:23.276Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:30:45.299Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:46:04.180Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:09:09.806Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:09:21.147Z",
      },
    ],
    cutlery: true,
    hasRating: true,
    timeslot: null,
    createdAt: "2024-10-22T19:09:09.806Z",
    updatedAt: "2024-10-23T14:11:57.640Z",
    fleetInfo: null,
  },
  {
    id: "6717f840b7a34cd092fc2e2b",
    sid: "241022-716772",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f83fb7a34cd092fc2e1e",
    firstName: "Mohammad Al Marshoud",
    lastName: null,
    phoneNumber: "+96596633528",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "661c51d7b0d178e632059723",
    delivery: {
      id: "6717f83fb7a34cd092fc2e1e",
      customerId: "661c51d7b0d178e632059723",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6717f72eb7a34cd092fbdd18",
        name: "Aboud",
        lng: 48.00162911258629,
        lat: 29.27391984177914,
        city: "Zahra",
        area: "Zahra",
        block: "5",
        street: "Sulaiman Ibraheem Al Musallam St",
        building: "67",
        floor: "",
        flat: "",
        phone: "+96596633528",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-22T19:04:14.595Z",
        updatedAt: "2024-10-22T19:04:14.595Z",
      },
      deliverectValidationId: "6717f83fc47d7273b38d2774",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:18:47.296Z",
      estimatedDeliveryTime: "2024-10-22T19:33:45.999Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:05:00.000Z",
      orderId: "6717f840b7a34cd092fc2e2b",
      createdAt: "2024-10-22T19:08:47.413Z",
      updatedAt: "2024-10-22T19:10:08.973Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606e8",
        productPLU: "2162113-1",
        productDescription:
          "C 67g | P 21g | F 25g[%Popular%]\nCheeseburger with baked sweet potato fries and bbq sauce",
        productName: "Cheeseburger & fries",
        imageURL:
          "https://resizer.deliverect.com/7r3h13J-z3i_dUkPi81fBjeHfz7KOpOpG45voMIj2xA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hlZXNlYnVyZ2VyJTIwY29tYm8tNjcxMzU3NjhmNTg1MzQ0Mzg3MTVlNGIyLnBuZw==",
        productCalories: 577,
        quantity: 1,
        nutritionInfo: {
          carbs: 67,
          protein: 21,
          fat: 25,
        },
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e84fb7a34cd092f60794",
            productPLU: "1907004-1",
            productName: "No Onion",
            productDescription: "C -1g| P 0g| F 0g| cal -3| coins 0\n",
            productCalories: -3,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: null,
              fat: null,
            },
          },
          {
            productId: "6717e84fb7a34cd092f60798",
            productPLU: "1907008-1",
            productName: "No Dill Pickles",
            productDescription: "C 0g| P 0g| F 0g| cal -0.4| coins 0",
            productCalories: -0.4,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: null,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "6717e84fb7a34cd092f60737",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 580,
    totalFmcCentsPrice: 580,
    totalFiatCentsPriceOriginal: 580,
    totalExternalFiatCentsPrice: 580,
    totalFmcCentsPriceOriginal: 580,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:08:48.133Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:10:29.833Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:15:51.027Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:29:45.835Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:29:45.835Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:32:36.885Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:48:53.659Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:08:48.133Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:08:59.561Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:08:48.133Z",
    updatedAt: "2024-10-22T19:48:54.022Z",
    fleetInfo: null,
  },
  {
    id: "6717f81eb7a34cd092fc28fb",
    sid: "241022-817669",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f81cb7a34cd092fc281c",
    firstName: "Wahab",
    lastName: null,
    phoneNumber: "+96590005689",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64da55a770771121d14826bc",
    delivery: {
      id: "6717f81cb7a34cd092fc281c",
      customerId: "64da55a770771121d14826bc",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6511589a0a91a2efcd55b1b5",
        name: "Home",
        lng: 48.021721355617046,
        lat: 29.28511222061119,
        city: "Zahra",
        area: "Hateen",
        block: "1",
        street: "119",
        building: "39",
        floor: "0",
        flat: "0",
        phone: "+96590005689",
        additionalInfo: "Call",
        isSelected: true,
        createdAt: "2023-09-25T09:53:30.755Z",
        updatedAt: "2024-10-20T20:44:49.353Z",
      },
      deliverectValidationId: "6717f81ce630c246d2a33d5d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:18:12.787Z",
      estimatedDeliveryTime: "2024-10-22T19:33:11.670Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:05:00.000Z",
      orderId: "6717f81eb7a34cd092fc28fb",
      createdAt: "2024-10-22T19:08:12.880Z",
      updatedAt: "2024-10-22T19:10:04.865Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606e2",
        productPLU: "2162117-1",
        productDescription:
          "C 60g | P 22g | F 15g\nTomato sauce, cheese & oregano.",
        productName: "Classic Margherita Pizza",
        imageURL:
          "https://resizer.deliverect.com/gXmec37h0lqeQkrMSIQRiRdHLwG9kVK9Ukz9m_Kw930/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWFyZ2hhcml0YS02NjZiMzAxMWQ2NmMzMDFhNDE0NmYxNTMucG5n",
        productCalories: 463,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 22,
          fat: 15,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f606ec",
        productPLU: "2162004-1",
        productDescription:
          "C 57g | P 42g | F 11g [%Popular%]\nFlavorful chicken machboos made with marinated chicken breast, aromatic basmati rice, topped with hashu & served with dakkus.",
        productName: "Chicken Machboos",
        imageURL:
          "https://resizer.deliverect.com/wylOEkBBpXVNBS2HAi9pQ55oF2RJyK2HNyEERWjweQ4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWFjaGJvdXMtNjY0NGIwMjllMWFlMGZiZmFkNjJjNzYxLnBuZw==",
        productCalories: 495,
        quantity: 1,
        nutritionInfo: {
          carbs: 57,
          protein: 42,
          fat: 11,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e84fb7a34cd092f6079f",
            productPLU: "1907075-1",
            productName: "No Mabooch",
            productDescription: "C -5g| P -1g| F -1g| cal -27| coins 0",
            productCalories: -27,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -5,
              protein: -1,
              fat: -1,
            },
          },
        ],
      },
      {
        productId: "6717e84fb7a34cd092f60736",
        productPLU: "2166033-1",
        productDescription:
          "C 28g| P 15g| F 12g\nSpicy crispy shrimp bites served with ranch sauce.\n\nGood source of Protein, Vitamin B12 and Iron",
        productName: "Buffalo Shrimp Bites",
        imageURL:
          "https://resizer.deliverect.com/rvAuXhjdUl1okUgfHDTv8pSQt2Rjhn13XhdwXCPIEyw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnVmZmFsbyUyMHNocmltcCUyMGJpdGVzLTY1Y2IxNGU4Zjc3ODBhZDhlOTQyYWZlMy5wbmc=",
        productCalories: 280,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 15,
          fat: 12,
        },
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60739",
        productPLU: "2166031-1",
        productDescription:
          "C 32g| P 12g| F 12g\nBaked sweet potato fries with mayo barbeque sauce.",
        productName: "Baked Sweet Potato Fries",
        imageURL:
          "https://resizer.deliverect.com/EexbDtzbKG0sVOjjNXiIlT_MIN2doyc2NlpJu2_T6nc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU3dlZXQlMjBwb3RhdG8lMjBmcmllcygxKS02NTU0OGY3NzYyYWIwZTAwMWYzYTczNzEucG5n",
        productCalories: 244,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 12,
          fat: 12,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 940,
    totalFmcCentsPrice: 940,
    totalFiatCentsPriceOriginal: 940,
    totalExternalFiatCentsPrice: 940,
    totalFmcCentsPriceOriginal: 940,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:08:14.022Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:10:19.708Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:15:34.710Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:29:49.203Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:29:49.203Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:31:40.848Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:46:15.613Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:08:14.022Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:08:25.441Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:08:14.022Z",
    updatedAt: "2024-10-22T19:46:16.400Z",
    fleetInfo: null,
  },
  {
    id: "6717f805b7a34cd092fc1f21",
    sid: "241022-088391",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f804b7a34cd092fc1e32",
    firstName: "Omar Alboloshi",
    lastName: null,
    phoneNumber: "+96599133301",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6639523b89ba1a51b1315619",
    delivery: {
      id: "6717f804b7a34cd092fc1e32",
      customerId: "6639523b89ba1a51b1315619",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6639530ea2ffa46fcdc94cc9",
        name: "Home",
        lng: 47.99216844141483,
        lat: 29.281972384949377,
        city: "Zahra",
        area: "Zahra",
        block: "3",
        street: "306 St",
        building: "16",
        floor: "1",
        flat: "1",
        phone: "+96599133301",
        additionalInfo: "Left black side door ",
        isSelected: true,
        createdAt: "2024-05-06T22:00:46.582Z",
        updatedAt: "2024-10-08T17:18:35.674Z",
      },
      deliverectValidationId: "6717f8044cb7e660cdfb4b08",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:17:48.423Z",
      estimatedDeliveryTime: "2024-10-22T19:32:46.775Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:04:00.000Z",
      orderId: "6717f805b7a34cd092fc1f21",
      createdAt: "2024-10-22T19:07:48.881Z",
      updatedAt: "2024-10-22T19:09:06.997Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606e8",
        productPLU: "2162113-1",
        productDescription:
          "C 67g | P 21g | F 25g[%Popular%]\nCheeseburger with baked sweet potato fries and bbq sauce",
        productName: "Cheeseburger & fries",
        imageURL:
          "https://resizer.deliverect.com/7r3h13J-z3i_dUkPi81fBjeHfz7KOpOpG45voMIj2xA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hlZXNlYnVyZ2VyJTIwY29tYm8tNjcxMzU3NjhmNTg1MzQ0Mzg3MTVlNGIyLnBuZw==",
        productCalories: 577,
        quantity: 1,
        nutritionInfo: {
          carbs: 67,
          protein: 21,
          fat: 25,
        },
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e84fb7a34cd092f60794",
            productPLU: "1907004-1",
            productName: "No Onion",
            productDescription: "C -1g| P 0g| F 0g| cal -3| coins 0\n",
            productCalories: -3,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: null,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 370,
    totalExternalFiatCentsPrice: 370,
    totalFmcCentsPriceOriginal: 370,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:07:49.890Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:09:31.492Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:15:38.149Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:29:58.980Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:29:58.980Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:32:48.333Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:42:15.329Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:07:49.890Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:08:07.053Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:07:49.890Z",
    updatedAt: "2024-10-22T19:42:15.665Z",
    fleetInfo: null,
  },
  {
    id: "6717f7fab7a34cd092fc1871",
    sid: "241022-587380",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717f7fab7a34cd092fc185b",
    firstName: "Jasem AlOmani",
    lastName: null,
    phoneNumber: "+96599995859",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c3c40575a6aa442d0f0707",
    delivery: {
      id: "6717f7fab7a34cd092fc185b",
      customerId: "64c3c40575a6aa442d0f0707",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64ce51e35e8f4fe1fb17bef4",
        name: "Home",
        lng: 48.001678213477135,
        lat: 29.32572541940006,
        city: "Kuwait",
        area: "Rawda",
        block: "2",
        street: "22",
        building: "4",
        floor: "First floor",
        flat: "",
        phone: "+96599995859",
        additionalInfo:
          "Left side corridor, you will find a door. Use stairs or elevator to go to first floor ",
        isSelected: true,
        createdAt: "2023-08-05T13:42:59.856Z",
        updatedAt: "2024-10-22T19:05:20.447Z",
      },
      deliverectValidationId: "6717f7f9b63f266a7d8137c1",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:17:37.881Z",
      estimatedDeliveryTime: "2024-10-22T19:32:36.872Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:04:00.000Z",
      orderId: "6717f7fab7a34cd092fc1871",
      createdAt: "2024-10-22T19:07:38.001Z",
      updatedAt: "2024-10-22T19:09:10.853Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed25",
        productPLU: "2161006-1",
        productDescription:
          "C 27g | P 28g | F 28g\nScrambled eggs, sourdough toast, guacamole,grilled bacon, blanched green beans, grilled cherry tomato with coriander.\n\nGood source of protien & healthy fat",
        productName: "FM  Breakfast",
        imageURL:
          "https://resizer.deliverect.com/Q1wfz8V9k1eV52eKoDg2053U4MKQ3ahaibZUrGgKe1U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRk0lMjBicmVha2Zhc3QlMjAoMSktNjYzNjAwYTI4NGMwMDk0NWNmMTJiOTYxLmpwZw==",
        productCalories: 472,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 28,
          fat: 28,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed29",
        productPLU: "2167015-1",
        productDescription:
          "C 33g| P 18g| F 13g [%Best seller%]\nEnglish muffin with egg whites, turkey bacon, Emmental cheese and our breakfast sauce.",
        productName: "Bacon & Egg Muffin ",
        imageURL:
          "https://resizer.deliverect.com/mQhNQBYyiOxcpYTmJUuiEtyEUXBbuisxAOMflBlic8A/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMy02NGJmYTM1MGI4MzczNjAwMTg5MGY0M2YucG5n",
        productCalories: 321,
        quantity: 1,
        nutritionInfo: {
          carbs: 33,
          protein: 18,
          fat: 13,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed27",
        productPLU: "2167014-1",
        productDescription:
          "C 32g| P 14g| F 8g\nEnglish muffin with egg whites, blanched spinach, mozzarella cheese and our breakfast sauce.",
        productName: "FM Egg Muffin",
        imageURL:
          "https://resizer.deliverect.com/UDWwpJ2NagrTgNTXcD-YQp6Hbon4KgO_xVwUVvV2eKE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNC02NGJmYTJlMzE0ODFjMTAwMjZjYTEwNTkucG5n",
        productCalories: 256,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 14,
          fat: 8,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 480,
    totalFmcCentsPrice: 480,
    totalFiatCentsPriceOriginal: 480,
    totalExternalFiatCentsPrice: 480,
    totalFmcCentsPriceOriginal: 480,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:07:38.754Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:09:11.348Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:11:59.835Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:21:32.353Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:21:32.353Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:21:56.632Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:33:22.364Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:07:38.754Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:07:55.698Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:07:38.754Z",
    updatedAt: "2024-10-22T19:33:22.718Z",
    fleetInfo: null,
  },
  {
    id: "6717f7f5b7a34cd092fc16f3",
    sid: "241022-455969",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f7f4b7a34cd092fc161a",
    firstName: "Abdulmuhsen almoqatei",
    lastName: null,
    phoneNumber: "+96567777792",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66e1e63caa1645e3a513ee80",
    delivery: {
      id: "6717f7f4b7a34cd092fc161a",
      customerId: "66e1e63caa1645e3a513ee80",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66e1e711ced6c5247ecc93ce",
        name: "Home",
        lng: 47.97292426228523,
        lat: 29.305425665647398,
        city: "Kuwait",
        area: "Yarmouk",
        block: "2",
        street: "1 st",
        building: "12",
        floor: "House ",
        flat: "",
        phone: "+96567777792",
        additionalInfo: "House ",
        isSelected: true,
        createdAt: "2024-09-11T18:53:05.944Z",
        updatedAt: "2024-10-22T19:06:36.993Z",
      },
      deliverectValidationId: "6717f7f3e630c246d2a32fef",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:17:31.786Z",
      estimatedDeliveryTime: "2024-10-22T19:32:30.447Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:04:00.000Z",
      orderId: "6717f7f5b7a34cd092fc16f3",
      createdAt: "2024-10-22T19:07:32.200Z",
      updatedAt: "2024-10-22T19:09:06.159Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606f7",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f6070b",
        productPLU: "2162014-1",
        productDescription:
          " C 41g | P 30g | F 10g\nGrilled chicken, sweet corn, grilled onions, cheddar cheese & mayo jalapeño sauce all wraped in a tortilla wrap, with a side of wedges. \n\n*GOOD  SOURCE OF PROTEIN*",
        productName: "Grilled Chicken Quesadillas",
        imageURL:
          "https://resizer.deliverect.com/xOppiJNYhktpt7nEhKtp1g0fmHHcaGU6TPtYQW6uk4s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUVVFU0FESUxMQV8tNjU3NThmYzViNDcxYmMyZGIzYmU2NTNkLnBuZw==",
        productCalories: 374,
        quantity: 1,
        nutritionInfo: {
          carbs: 41,
          protein: 30,
          fat: 10,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 300,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 500,
    totalFmcCentsPrice: 500,
    totalFiatCentsPriceOriginal: 500,
    totalExternalFiatCentsPrice: 500,
    totalFmcCentsPriceOriginal: 500,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:07:33.383Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:09:28.153Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:17:31.052Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:25:04.912Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:25:04.912Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:29:59.227Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:46:37.660Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:07:33.383Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:07:44.770Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:07:33.383Z",
    updatedAt: "2024-10-22T19:46:38.001Z",
    fleetInfo: null,
  },
  {
    id: "6717f7d8b7a34cd092fc0fcc",
    sid: "241022-662582",
    coupons: [],
    menuId: "6717e842b7a34cd092f5f5cf",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6717f7d7b7a34cd092fc0f99",
    firstName: "Abdulmohsen Aloraifan",
    lastName: null,
    phoneNumber: "+96566807700",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66b2696deb3fe809b1b53297",
    delivery: {
      id: "6717f7d7b7a34cd092fc0f99",
      customerId: "66b2696deb3fe809b1b53297",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66b26a210846fbb356d983ac",
        name: "Home",
        lng: 48.09689839054283,
        lat: 29.238327026367188,
        city: "Kuwait City",
        area: "Messila",
        block: "6",
        street: "31",
        building: "3",
        floor: "",
        flat: "",
        phone: "+96566807700",
        additionalInfo: "White house on the corner",
        isSelected: true,
        createdAt: "2024-08-06T18:23:29.511Z",
        updatedAt: "2024-10-22T19:04:57.852Z",
      },
      deliverectValidationId: "6717f7d77b41664b995f07e9",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:17:03.386Z",
      estimatedDeliveryTime: "2024-10-22T19:32:01.577Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:02:01.577Z",
      orderId: "6717f7d8b7a34cd092fc0fcc",
      createdAt: "2024-10-22T19:07:03.613Z",
      updatedAt: "2024-10-22T19:07:04.267Z",
    },
    items: [
      {
        productId: "6717e842b7a34cd092f5f654",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e842b7a34cd092f5f6ee",
            productPLU: "1907003-1",
            productName: "No Cheddar Cheese",
            productDescription: "C 0g| P -2g| F -3g| cal -40| coins 0\n\n",
            productCalories: -40,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: -2,
              fat: -3,
            },
          },
        ],
      },
      {
        productId: "6717e842b7a34cd092f5f694",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 410,
    totalFmcCentsPrice: 410,
    totalFiatCentsPriceOriginal: 410,
    totalExternalFiatCentsPrice: 410,
    totalFmcCentsPriceOriginal: 410,
    orderStatus: "canceled",
    chargeStatus: "refund_succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:07:04.248Z",
      },
      {
        previousStatus: "pending",
        newStatus: "canceled",
        timestamp: "2024-10-22T19:08:01.104Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:07:04.248Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:07:15.795Z",
      },
      {
        previousStatus: "succeeded",
        newStatus: "refund_requested",
        timestamp: "2024-10-22T19:08:01.104Z",
      },
      {
        previousStatus: "refund_requested",
        newStatus: "refund_failed",
        timestamp: "2024-10-22T19:08:06.975Z",
      },
      {
        previousStatus: "refund_failed",
        newStatus: "refund_succeeded",
        timestamp: "2024-10-22T19:08:36.716Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-22T19:07:04.248Z",
    updatedAt: "2024-10-22T19:08:36.717Z",
    fleetInfo: null,
  },
  {
    id: "6717f779b7a34cd092fbf531",
    sid: "241022-481206",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f777b7a34cd092fbf398",
    firstName: "Sager",
    lastName: null,
    phoneNumber: "+96597687768",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6677307d8c15153ac88182d3",
    delivery: {
      id: "6717f777b7a34cd092fbf398",
      customerId: "6677307d8c15153ac88182d3",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f59b39ee3a746f7f34e23c",
        name: "Appartment ",
        lng: 47.98371244221926,
        lat: 29.312924044504364,
        city: "Kuwait",
        area: "Qortuba",
        block: "4",
        street: "Mohammed alhumoud",
        building: "53",
        floor: "1",
        flat: "",
        phone: "+96597687768",
        additionalInfo: "Enter glass door, go first floor",
        isSelected: true,
        createdAt: "2024-09-26T17:34:49.551Z",
        updatedAt: "2024-10-22T19:03:59.881Z",
      },
      deliverectValidationId: "6717f777b8212c160f8f712f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:15:27.417Z",
      estimatedDeliveryTime: "2024-10-22T19:30:26.169Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:02:00.000Z",
      orderId: "6717f779b7a34cd092fbf531",
      createdAt: "2024-10-22T19:05:27.782Z",
      updatedAt: "2024-10-22T19:07:05.293Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f60710",
        productPLU: "2167013-1",
        productDescription:
          "C 42g| P 28g| F 12g [%Popular%]\nBeef with our special sauce and sumac onion wrapped in tortilla.\n\nGood source of Vitamin B6 & Vitamin B12",
        productName: "Beef Shawarma",
        imageURL:
          "https://resizer.deliverect.com/DQl32OjdVbaHyJyht46g35g33tk0bsgQNbyyp4jWAdc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQmVlZiUyMHNoYXdhcm1hLTY1ZjZlOThhMzRiNTQ3YTg5NDYyNmEwZS5qcGc=",
        productCalories: 388,
        quantity: 1,
        nutritionInfo: {
          carbs: 42,
          protein: 28,
          fat: 12,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f6073a",
        productPLU: "2161023-1",
        productDescription:
          "C 37g| P 17g| F 13g\nFries topped with caramelized onions, ground beef, pickled jalapeños, american cheese & our special sauce. \n\n*GOOD SOURCE OF PROTEIN,VITAMINB12*",
        productName: "Messy Fries",
        imageURL:
          "https://resizer.deliverect.com/uhTtheRt0RZXomxI_1n5O4G2cP8KUacFKd3MVV-dhio/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTUVTU1klMjBGUklFUy02NTc1OGZhM2QyZWUyNWQ5YmZkZDE4YWUucG5n",
        productCalories: 333,
        quantity: 1,
        nutritionInfo: {
          carbs: 37,
          protein: 17,
          fat: 13,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:05:29.255Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:07:16.686Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:07:45.943Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:24:41.357Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:24:41.357Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:26:03.339Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:46:26.456Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:05:29.255Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:05:40.647Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:05:29.255Z",
    updatedAt: "2024-10-22T19:46:26.799Z",
    fleetInfo: null,
  },
  {
    id: "6717f754b7a34cd092fbe6d5",
    sid: "241022-771540",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f752b7a34cd092fbe607",
    firstName: "Alaa alenezi",
    lastName: null,
    phoneNumber: "+96599781237",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66cde22ab3b61346bdc366e9",
    delivery: {
      id: "6717f752b7a34cd092fbe607",
      customerId: "66cde22ab3b61346bdc366e9",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66cde2f5f8ac6d2277a9cd34",
        name: "Home",
        lng: 47.99555406817203,
        lat: 29.311431884765625,
        city: "Kuwait",
        area: "Qortuba",
        block: "2",
        street: "1 St",
        building: "House 114",
        floor: "",
        flat: "",
        phone: "+96599781237",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-27T14:30:13.695Z",
        updatedAt: "2024-08-27T14:30:13.695Z",
      },
      deliverectValidationId: "6717f752cb1dacdbc9151469",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:14:50.375Z",
      estimatedDeliveryTime: "2024-10-22T19:29:49.389Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:01:00.000Z",
      orderId: "6717f754b7a34cd092fbe6d5",
      createdAt: "2024-10-22T19:04:50.502Z",
      updatedAt: "2024-10-22T19:06:06.625Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f6071b",
        productPLU: "2163019-1",
        productDescription:
          "C 21g | P 6g | F 17g \nQuinoa, Cucumber, beetroot, parsley, spring onion, mint, kale,  pomegranate seeds, feta cheese and spring dressing. \n\nGood source of Vitamin C & K",
        productName: "Spring Salad",
        imageURL:
          "https://resizer.deliverect.com/Ie4UluZ6Zpy11uE4mml8eAE-iGHGSk3zhVI2_OjBREU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3ByaW5nJTIwc2FsYWQtNjY5MDY1ZTAwMjQyYTUwMWQ5ZTc4YTg2LnBuZw==",
        productCalories: 261,
        quantity: 1,
        nutritionInfo: {
          carbs: 21,
          protein: 6,
          fat: 17,
        },
        fmcCentsPrice: 220,
        fiatCentsPrice: 220,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60732",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 350,
    totalExternalFiatCentsPrice: 350,
    totalFmcCentsPriceOriginal: 350,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:04:52.002Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:06:18.431Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:07:46.541Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:24:52.196Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:24:52.196Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:29:48.065Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:57:36.315Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:04:52.002Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:05:03.428Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:04:52.002Z",
    updatedAt: "2024-10-22T19:57:36.655Z",
    fleetInfo: null,
  },
  {
    id: "6717f732b7a34cd092fbdf22",
    sid: "241022-700858",
    coupons: [],
    menuId: "6717e842b7a34cd092f5f5cf",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6717f732b7a34cd092fbdf07",
    firstName: "Mohammad AlRayes",
    lastName: null,
    phoneNumber: "+96592224233",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "67166b0fb58bc473084002b0",
    delivery: {
      id: "6717f732b7a34cd092fbdf07",
      customerId: "67166b0fb58bc473084002b0",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67166be9b7a34cd092a97f5d",
        name: "Masayel ",
        lng: 48.0893047478314,
        lat: 29.238784790039062,
        city: "Al-Masayel",
        area: "Al-Masayel",
        block: "2 ",
        street: "210 St",
        building: "55",
        floor: "2 ",
        flat: "1",
        phone: "+96592224233",
        additionalInfo: "Please call when arrived ",
        isSelected: true,
        createdAt: "2024-10-21T14:57:45.125Z",
        updatedAt: "2024-10-21T14:57:45.125Z",
      },
      deliverectValidationId: "6717f7323bee7736697891e8",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:14:18.041Z",
      estimatedDeliveryTime: "2024-10-22T19:29:17.022Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:01:00.000Z",
      orderId: "6717f732b7a34cd092fbdf22",
      createdAt: "2024-10-22T19:04:18.150Z",
      updatedAt: "2024-10-22T19:06:05.857Z",
    },
    items: [
      {
        productId: "6717e842b7a34cd092f5f653",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 300,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e842b7a34cd092f5f6ec",
            productPLU: "1906003-1",
            productName: "Extra Chedder Cheese",
            productDescription: "C 0g| P 2g| F 3g| cal 40| coins 0.2",
            productCalories: 40,
            quantity: 1,
            fmcCentsPrice: 20,
            fiatCentsPrice: 20,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: null,
              protein: 2,
              fat: 3,
            },
          },
          {
            productId: "6717e842b7a34cd092f5f6e0",
            productPLU: "1906004-1",
            productName: "Extra  Beef Pattie",
            productDescription: "C 2g| P 6g| F 3g| cal 59| coins 0.5",
            productCalories: 59,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 2,
              protein: 6,
              fat: 3,
            },
          },
        ],
      },
      {
        productId: "6717e842b7a34cd092f5f671",
        productPLU: "2167006-1",
        productDescription:
          "C 54g| P 32g| F 17g\nGrilled chicken, smoked turkey, egg omlette, romaine lettuce, tomatoes, pickled cucumbers with our dijon mayo all in sourdough toast. \n\nGood source of Vitamin B12",
        productName: "Club Sandwich",
        imageURL:
          "https://resizer.deliverect.com/Ut9IZWDiEFerQLetAfvmsISAfYyPbhIkr8laMwwBPo0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQ2x1YiUyMHNhbmR3aWNoJTIwLTY0ZjQ3ZTU4MzRlNWQ4M2Q1OGQ1MmNmZi5qcGc=",
        productCalories: 497,
        quantity: 1,
        nutritionInfo: {
          carbs: 54,
          protein: 32,
          fat: 17,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f690",
        productPLU: "2168001-1",
        productDescription:
          "C 14g | P 5g | F 1g\nRed lentil soup topped with coriander & served with a lemon wedge. ",
        productName: "Lentil Soup",
        imageURL:
          "https://resizer.deliverect.com/5EbDdbzPc4MFBEpLJbi04Urr0_H4N9CuGy35JtE6XBM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbGVudGlsJTIwc291cC02NjQxZGJjOTNjYmY3YTM2N2ZiNDhiN2IuanBn",
        productCalories: 85,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 5,
          fat: 1,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f693",
        productPLU: "2166033-1",
        productDescription:
          "C 28g| P 15g| F 12g\nSpicy crispy shrimp bites served with ranch sauce.\n\nGood source of Protein, Vitamin B12 and Iron",
        productName: "Buffalo Shrimp Bites",
        imageURL:
          "https://resizer.deliverect.com/rvAuXhjdUl1okUgfHDTv8pSQt2Rjhn13XhdwXCPIEyw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvQnVmZmFsbyUyMHNocmltcCUyMGJpdGVzLTY1Y2IxNGU4Zjc3ODBhZDhlOTQyYWZlMy5wbmc=",
        productCalories: 280,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 15,
          fat: 12,
        },
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f6a1",
        productPLU: "2166037-1",
        productDescription:
          "C 31g | P 3g | F 8g \nTortilla chips, cheddar cheese, & pickled jalapeños. ",
        productName: "Jalapeño Cheddar Nachos ",
        imageURL:
          "https://resizer.deliverect.com/Igtv_X3IIKdvVYw3drGOS6DlDQf47bmEkgol0b9fBZ0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvamFsYXBlbm8lMjBjaGVlc2UlMjBuYWNob3MtNjY2MWNkZWQ5YWNlYTFmMWZlZWVkOTU5LnBuZw==",
        productCalories: 208,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 3,
          fat: 8,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f6c8",
        productPLU: "2202021-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Coca Cola Zero",
        imageURL:
          "https://resizer.deliverect.com/NvHctUF0z3us3Lhe9x5u3_mQIOOXnn8K3FvSZO6MkK4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCgxKS02NGM5MTk1MjZlY2Q3NzAwMjZjNGU1MDUuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 1090,
    totalFmcCentsPrice: 1090,
    totalFiatCentsPriceOriginal: 1090,
    totalExternalFiatCentsPrice: 1090,
    totalFmcCentsPriceOriginal: 1090,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:04:18.903Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:06:08.275Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:06:32.751Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:11:45.609Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:11:45.609Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:21:20.706Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:42:26.541Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:04:18.903Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:04:39.876Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:04:18.903Z",
    updatedAt: "2024-10-22T19:42:26.961Z",
    fleetInfo: null,
  },
  {
    id: "6717f731b7a34cd092fbdefc",
    sid: "241022-826305",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6717f730b7a34cd092fbddf2",
    firstName: "Yousif ahmad",
    lastName: null,
    phoneNumber: "+96555556545",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66ff2bc6906276458c7cb610",
    delivery: {
      id: "6717f730b7a34cd092fbddf2",
      customerId: "66ff2bc6906276458c7cb610",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66fffba4218a8c4af20021b2",
        name: "Home ",
        lng: 47.96480285228528,
        lat: 29.33441162109375,
        city: "Kuwait",
        area: "Kaifan",
        block: "4",
        street: "Arafat St",
        building: "31",
        floor: "",
        flat: "",
        phone: "+96555556545",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-04T14:28:52.030Z",
        updatedAt: "2024-10-22T18:56:01.648Z",
      },
      deliverectValidationId: "6717f72ffbbda76c3f038dc6",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:14:15.808Z",
      estimatedDeliveryTime: "2024-10-22T19:29:14.549Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:01:00.000Z",
      orderId: "6717f731b7a34cd092fbdefc",
      createdAt: "2024-10-22T19:04:16.009Z",
      updatedAt: "2024-10-22T19:06:05.685Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd6f",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd4c",
        productPLU: "2162073-1",
        productDescription:
          "C 55g| P 22g| F 20g [%Popular%]\nCrispy buffalo chicken tenders, iceberg lettuce, cheese with black pepper ranch sauce in a tortilla\n\nGOOD SOURCE OF PROTEIN, IRON, VITAMINS AND MINERALS ",
        productName: "Zinger Wrap",
        imageURL:
          "https://resizer.deliverect.com/HxB6Jk4SYuzyKeoB5PLI0G8vmLwmz39dUm-FWsICaAg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvWmluZ2VyJTIwd3JhcC02NWY2ZTkxOGMxMjg2NmZjODg2NjczZTIuanBn",
        productCalories: 491,
        quantity: 1,
        nutritionInfo: {
          carbs: 55,
          protein: 22,
          fat: 20,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd75",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 380,
    totalFmcCentsPrice: 380,
    totalFiatCentsPriceOriginal: 380,
    totalExternalFiatCentsPrice: 380,
    totalFmcCentsPriceOriginal: 380,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:04:17.321Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:06:15.216Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:07:26.324Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:30:08.630Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:30:08.631Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:30:23.205Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:49:37.309Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:04:17.321Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:04:28.926Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:04:17.321Z",
    updatedAt: "2024-10-22T19:49:37.721Z",
    fleetInfo: null,
  },
  {
    id: "6717f6eeb7a34cd092fbcf66",
    sid: "241022-834237",
    coupons: [],
    menuId: "6717e842b7a34cd092f5f5cf",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6717f6edb7a34cd092fbcf53",
    firstName: "hasan asiri",
    lastName: null,
    phoneNumber: "+96594447840",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "657b327efcc803b6c2c13574",
    delivery: {
      id: "6717f6edb7a34cd092fbcf53",
      customerId: "657b327efcc803b6c2c13574",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "657b3373db039d9402e6af52",
        name: "home",
        lng: 48.082829620689154,
        lat: 29.23325548742615,
        city: "Kuwait",
        area: "Al-Adan District",
        block: "7",
        street: "13 St",
        building: "36",
        floor: "",
        flat: "",
        phone: "+96594447840",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-14T16:55:15.117Z",
        updatedAt: "2024-10-22T07:40:16.105Z",
      },
      deliverectValidationId: "6717f6ed10394423ecd637c5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:13:09.355Z",
      estimatedDeliveryTime: "2024-10-22T19:28:06.605Z",
      estimatedDeliveryTimeEnd: "2024-10-22T20:00:00.000Z",
      orderId: "6717f6eeb7a34cd092fbcf66",
      createdAt: "2024-10-22T19:03:09.457Z",
      updatedAt: "2024-10-22T19:05:05.888Z",
    },
    items: [
      {
        productId: "6717e842b7a34cd092f5f680",
        productPLU: "2163003-1",
        productDescription:
          "C 36g | P 22g | F 11g\nShredded chicken, mixed slaw, edamame, & crunchy toasted peanut, iceberg lettuce, mandarin, all drizzled with a peanut dressing and topped with sesame seeds with coriander.\n\nGood source of Vitamins A, K & C",
        productName: "Asian Peanut Salad",
        imageURL:
          "https://resizer.deliverect.com/humW3Ra_sNPdK2LTBHq_jLtgrnRK92UjDwqvK9r2I2o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvdGhhaSUyMHBlYW51dCUyMHNhbGFkLTY0YjdiNWI2N2U5MmVhMTFiYjcwZGExMy5wbmc=",
        productCalories: 333,
        quantity: 1,
        nutritionInfo: {
          carbs: 36,
          protein: 22,
          fat: 11,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f644",
        productPLU: "2162112-1",
        productDescription:
          "C 65g | P 21g | F 28g[%Popular%]\nFM burger with baked sweet potato fries and bbq sauce",
        productName: "FM burger & fries",
        imageURL:
          "https://resizer.deliverect.com/RmA60bYBZskDxWGZ3uPWH1NZAH_rxnDkyiAlnyV_l1o/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvZm0lMjBidXJnZXIlMjBjb21iby02NzEzNTc5NTQ4OGM3MjkyNjAyMTliNWYucG5n",
        productCalories: 596,
        quantity: 1,
        nutritionInfo: {
          carbs: 65,
          protein: 21,
          fat: 28,
        },
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e842b7a34cd092f5f6f1",
            productPLU: "1907004-1",
            productName: "No Onion",
            productDescription: "C -1g| P 0g| F 0g| cal -3| coins 0\n",
            productCalories: -3,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: null,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "6717e842b7a34cd092f5f65b",
        productPLU: "2162035-1",
        productDescription:
          "C 50g| P 32g| F 15g\nBlack pepper beef with veggies served with egyptian rice.\n\nGood source of Iron & B-12 Vitamins",
        productName: "Black Pepper Beef",
        imageURL:
          "https://resizer.deliverect.com/FBy7BxqQqLr64Ae34YvyRhL0jHWvib_v5F9riYKNFsc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNzYtNjRiN2IwNWM5Mjc2OTYwMDE5NmViYjFjLnBuZw==",
        productCalories: 474,
        quantity: 1,
        nutritionInfo: {
          carbs: 50,
          protein: 32,
          fat: 15,
        },
        fmcCentsPrice: 330,
        fiatCentsPrice: 330,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 930,
    totalFmcCentsPrice: 930,
    totalFiatCentsPriceOriginal: 930,
    totalExternalFiatCentsPrice: 930,
    totalFmcCentsPriceOriginal: 930,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:03:10.118Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:05:06.763Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:06:15.079Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:26:03.161Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:26:03.162Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:36:31.384Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:54:13.880Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:03:10.118Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:03:21.842Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:03:10.118Z",
    updatedAt: "2024-10-22T19:54:14.322Z",
    fleetInfo: null,
  },
  {
    id: "6717f6e1b7a34cd092fbccf1",
    sid: "241022-144137",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6717f6e0b7a34cd092fbcce5",
    firstName: "Hamed",
    lastName: null,
    phoneNumber: "+96596669669",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65abab6497e5f13760b56f29",
    delivery: {
      id: "6717f6e0b7a34cd092fbcce5",
      customerId: "65abab6497e5f13760b56f29",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65abad3d1c5f840f7ccf19ec",
        name: "Home",
        lng: 47.97375105321407,
        lat: 29.34118069267123,
        city: "Kuwait City",
        area: "Faiha",
        block: "1",
        street: "15",
        building: "7",
        floor: "",
        flat: "",
        phone: "+96596669669",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-01-20T11:23:41.825Z",
        updatedAt: "2024-10-22T19:02:26.493Z",
      },
      deliverectValidationId: "6717f6dfdda2cbde3364b87a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:12:55.916Z",
      estimatedDeliveryTime: "2024-10-22T19:27:54.608Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:59:00.000Z",
      orderId: "6717f6e1b7a34cd092fbccf1",
      createdAt: "2024-10-22T19:02:56.119Z",
      updatedAt: "2024-10-22T19:04:05.526Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd59",
        productPLU: "2162106-1",
        productDescription:
          "C 31g | P 7g | F 18g\nCorn, shishito pepper, jalapeno, coriander, feta cheese, red onion, shishito salad dressing\n\nGood source of Vitamins ",
        productName: "Shishito Corn Salad",
        imageURL:
          "https://resizer.deliverect.com/6rdXnPFXcSncZOuJkUg1u6lTeZ62zbWaCh8VKxZvZJw/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCUyQiUyMHJlc2l6ZWQtNjY0NGFmYWQ1YzZkZWYwMWMzM2MzYzE1LnBuZw==",
        productCalories: 314,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 7,
          fat: 18,
        },
        fmcCentsPrice: 350,
        fiatCentsPrice: 350,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 350,
    totalExternalFiatCentsPrice: 350,
    totalFmcCentsPriceOriginal: 350,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:02:57.101Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:04:14.586Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:04:34.323Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:04:55.153Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:04:55.153Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:14:20.000Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:31:40.927Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:02:57.101Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:03:08.658Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:02:57.101Z",
    updatedAt: "2024-10-22T19:31:41.267Z",
    fleetInfo: null,
  },
  {
    id: "6717f695b7a34cd092fbaed1",
    sid: "241022-404241",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717f694b7a34cd092fbaead",
    firstName: "Bader ",
    lastName: null,
    phoneNumber: "+96597444739",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "668add248c15153ac8b1c753",
    delivery: {
      id: "6717f694b7a34cd092fbaead",
      customerId: "668add248c15153ac8b1c753",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66dc3306ced6c5247ee0fe31",
        name: "Home",
        lng: 48.024548753528954,
        lat: 29.31549072265625,
        city: "Jabriya",
        area: "Jabriya",
        block: "5",
        street: "9",
        building: "House 26",
        floor: "",
        flat: "",
        phone: "+96597444739",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-07T11:03:34.204Z",
        updatedAt: "2024-10-22T19:00:22.021Z",
      },
      deliverectValidationId: "6717f693fbbda76c3f03411d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:11:39.998Z",
      estimatedDeliveryTime: "2024-10-22T19:26:38.963Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:58:00.000Z",
      orderId: "6717f695b7a34cd092fbaed1",
      createdAt: "2024-10-22T19:01:40.519Z",
      updatedAt: "2024-10-22T19:03:11.675Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed3d",
        productPLU: "2162111-1",
        productDescription:
          "C 72g | P 23g | F 34g[%Popular%]\nChicken burger with baked sweet potato fries and bbq sauce",
        productName: "Chicken burger & fries",
        imageURL:
          "https://resizer.deliverect.com/fMQuypdGrqkSRZhbEebRsjjcen1dY6rXtUgYEcTeE9s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMGJ1cmdlciUyMGNvbWJvLTY3MTM1NzgzMWM2ZDc0YTE4NGJmNTY2ZC5wbmc=",
        productCalories: 686,
        quantity: 1,
        nutritionInfo: {
          carbs: 72,
          protein: 23,
          fat: 34,
        },
        fmcCentsPrice: 340,
        fiatCentsPrice: 340,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 340,
    totalFmcCentsPrice: 340,
    totalFiatCentsPriceOriginal: 340,
    totalExternalFiatCentsPrice: 340,
    totalFmcCentsPriceOriginal: 340,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:01:41.584Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:03:08.662Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:06:13.702Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:16:23.928Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:16:23.928Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:16:45.891Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:20:12.068Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:01:41.584Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:01:53.110Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:01:41.584Z",
    updatedAt: "2024-10-22T19:32:29.311Z",
    fleetInfo: null,
  },
  {
    id: "6717f695b7a34cd092fbaec7",
    sid: "241022-570937",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f694b7a34cd092fbaeab",
    firstName: "Maryam alnajar",
    lastName: null,
    phoneNumber: "+96569960090",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66c6e2b37cfb5b3acb16dfbb",
    delivery: {
      id: "6717f694b7a34cd092fbaeab",
      customerId: "66c6e2b37cfb5b3acb16dfbb",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66c6e3679bb4637074ebb441",
        name: "My home",
        lng: 47.978249453008175,
        lat: 29.313233939186915,
        city: "Kuwait",
        area: "Qortuba",
        block: "4",
        street: "1",
        building: "80",
        floor: "",
        flat: "",
        phone: "+96569960090",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-22T07:06:15.585Z",
        updatedAt: "2024-10-10T12:51:12.157Z",
      },
      deliverectValidationId: "6717f693e0ba6b8ca0f45a46",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:11:39.565Z",
      estimatedDeliveryTime: "2024-10-22T19:26:37.985Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:58:00.000Z",
      orderId: "6717f695b7a34cd092fbaec7",
      createdAt: "2024-10-22T19:01:40.304Z",
      updatedAt: "2024-10-22T19:03:05.797Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f6070f",
        productPLU: "2162073-1",
        productDescription:
          "C 55g| P 22g| F 20g [%Popular%]\nCrispy buffalo chicken tenders, iceberg lettuce, cheese with black pepper ranch sauce in a tortilla\n\nGOOD SOURCE OF PROTEIN, IRON, VITAMINS AND MINERALS ",
        productName: "Zinger Wrap",
        imageURL:
          "https://resizer.deliverect.com/HxB6Jk4SYuzyKeoB5PLI0G8vmLwmz39dUm-FWsICaAg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvWmluZ2VyJTIwd3JhcC02NWY2ZTkxOGMxMjg2NmZjODg2NjczZTIuanBn",
        productCalories: 491,
        quantity: 1,
        nutritionInfo: {
          carbs: 55,
          protein: 22,
          fat: 20,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "The chicken well well well done please",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60738",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 250,
    totalFmcCentsPrice: 250,
    totalFiatCentsPriceOriginal: 250,
    totalExternalFiatCentsPrice: 250,
    totalFmcCentsPriceOriginal: 250,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:01:41.278Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:03:54.992Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:04:52.880Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:21:23.514Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:21:23.514Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:24:03.710Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:46:49.932Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:01:41.278Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:02:04.057Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:01:41.278Z",
    updatedAt: "2024-10-22T19:46:50.278Z",
    fleetInfo: null,
  },
  {
    id: "6717f672b7a34cd092fb9cd4",
    sid: "241022-932709",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f672b7a34cd092fb9cb1",
    firstName: "Hamzah Habeeb",
    lastName: null,
    phoneNumber: "+96565551774",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64c3d53e75a6aa442d0f1e14",
    delivery: {
      id: "6717f672b7a34cd092fb9cb1",
      customerId: "64c3d53e75a6aa442d0f1e14",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "64da6a6ae4583466bfb98227",
        name: "هوم",
        lng: 48.02814922709452,
        lat: 29.283233642578125,
        city: "Kuwait",
        area: "Hateen",
        block: "3",
        street: "320",
        building: "12",
        floor: "",
        flat: "",
        phone: "+96565551774",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-08-14T17:54:50.740Z",
        updatedAt: "2024-10-10T19:37:18.845Z",
      },
      deliverectValidationId: "6717f671e3fb9ce5e7d9099e",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:11:05.852Z",
      estimatedDeliveryTime: "2024-10-22T19:26:04.500Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:58:00.000Z",
      orderId: "6717f672b7a34cd092fb9cd4",
      createdAt: "2024-10-22T19:01:06.042Z",
      updatedAt: "2024-10-22T19:03:04.428Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606f4",
        productPLU: "2162008-1",
        productDescription:
          "C 40g | P 21g | F 24g [%Popular%]\nCrispy chicken with pepper jack cheese, coleslaw mix, dill pickles & a spicy mayo sauce.",
        productName: "Spicy Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/V3b1iOeX1UmC1nng8xEmFG8Y02arGnZCgSM1cET5hoo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BpY3klMjBjaGlja2VuJTIwYnVyZ2VyKDEpLTY1NTQ4ZjI5ODEyNDJlMDAxOGYwYWIyZi5wbmc=",
        productCalories: 460,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 24,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f606f6",
        productPLU: "2162007-1",
        productDescription:
          "C 35g | P 19g | F 12g [%Best seller%]\nBeef patty with melted cheddar cheese, white onion, dill pickle, mustard & ketchup.\n\nGood Source of  Niacin & Vitamin B 12",
        productName: "Cheeseburger",
        imageURL:
          "https://resizer.deliverect.com/K_yiSyjRtsGD0IJv2PzQZLbz-3aMdLYsw1RTqBqW0ks/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTgtNjRiN2FlMjRiYTVmYzkwMDFmMmYxMTk2LnBuZw==",
        productCalories: 324,
        quantity: 1,
        nutritionInfo: {
          carbs: 35,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60734",
        productPLU: "2168002-1",
        productDescription:
          "C 4g | P 2g | F 2g|\nCreamy mushroom soup topped with parsley. ",
        productName: "Mushroom Soup",
        imageURL:
          "https://resizer.deliverect.com/VvtgR2CAH02ajyt1L7X3df8XtKjNQ2Kx-d7hCJY0Q1w/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBzb3VwLTY2NDM3YTg5ZTFhZTBmYmZhZDYyYmVhZC5qcGc=",
        productCalories: 42,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 2,
          fat: 2,
        },
        fmcCentsPrice: 140,
        fiatCentsPrice: 140,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 570,
    totalFmcCentsPrice: 570,
    totalFiatCentsPriceOriginal: 570,
    totalExternalFiatCentsPrice: 570,
    totalFmcCentsPriceOriginal: 570,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:01:06.522Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:03:47.909Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:07:57.635Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:21:09.674Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:21:09.674Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:24:17.504Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:43:13.207Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:01:06.522Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:01:17.927Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:01:06.522Z",
    updatedAt: "2024-10-22T19:43:13.547Z",
    fleetInfo: null,
  },
  {
    id: "6717f64eb7a34cd092fb9223",
    sid: "241022-848260",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f64db7a34cd092fb9138",
    firstName: "Khloud aljumah",
    lastName: null,
    phoneNumber: "+96565999870",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66c20cb36c59ceb40a74a151",
    delivery: {
      id: "6717f64db7a34cd092fb9138",
      customerId: "66c20cb36c59ceb40a74a151",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66c20d6d6a31f4fea7505789",
        name: "Shuhada b3 st1 h3",
        lng: 48.0332398,
        lat: 29.270894,
        city: "Kuwait",
        area: "Shuhada",
        block: "3",
        street: "1",
        building: "3",
        floor: "0",
        flat: "0",
        phone: "+96565999870",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-18T15:04:13.026Z",
        updatedAt: "2024-10-19T11:28:37.529Z",
      },
      deliverectValidationId: "6717f64c8ee7d1c25628a192",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:10:28.068Z",
      estimatedDeliveryTime: "2024-10-22T19:25:26.924Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:57:00.000Z",
      orderId: "6717f64eb7a34cd092fb9223",
      createdAt: "2024-10-22T19:00:29.202Z",
      updatedAt: "2024-10-22T19:02:11.383Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f60706",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 1,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60726",
        productPLU: "2163012-1",
        productDescription:
          "C 6g| P 12g| F 5g\nChicken caesar salad with grilled chicken topped with sourdough croutons & shaved parmesan cheese.  \n\nGOOD SOURCE OF PROTIEN , VITAMIN K & A",
        productName: "MINI CAESAR SALAD",
        imageURL:
          "https://resizer.deliverect.com/pn19DI6nKfoNFDytlww7s2gKrhsydFXuV2FgKSAnmjs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNhZXNhciUyMHNhbGFkLTY1MDQ4YTQ3YzQwODk3MzFlMzM5OTFkZi5qcGc=",
        productCalories: 117,
        quantity: 1,
        nutritionInfo: {
          carbs: 6,
          protein: 12,
          fat: 5,
        },
        fmcCentsPrice: 140,
        fiatCentsPrice: 140,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 270,
    totalExternalFiatCentsPrice: 270,
    totalFmcCentsPriceOriginal: 270,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:00:30.303Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:02:12.249Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:03:04.753Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:15:28.661Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:15:28.661Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:15:50.771Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:38:23.753Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T19:00:30.303Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:00:41.866Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T19:00:30.303Z",
    updatedAt: "2024-10-22T19:38:24.105Z",
    fleetInfo: null,
  },
  {
    id: "6717f61ab7a34cd092fb7f5d",
    sid: "241022-804170",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f618b7a34cd092fb7e72",
    firstName: "Abdulrahman Alowisi",
    lastName: null,
    phoneNumber: "+96566169041",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66cb6a44c41ac94683a50e91",
    delivery: {
      id: "6717f618b7a34cd092fb7e72",
      customerId: "66cb6a44c41ac94683a50e91",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66cb6ab1a48a4cf50faa0f37",
        name: "Home",
        lng: 48.00259452313185,
        lat: 29.303145997241792,
        city: "Kuwait",
        area: "Salam",
        block: "1",
        street: "116 St",
        building: "588",
        floor: "2",
        flat: "7",
        phone: "+96566169041",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-08-25T17:32:33.894Z",
        updatedAt: "2024-08-25T17:32:33.894Z",
      },
      deliverectValidationId: "6717f618e3fb9ce5e7d8cba1",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:09:36.651Z",
      estimatedDeliveryTime: "2024-10-22T19:24:35.605Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:56:00.000Z",
      orderId: "6717f61ab7a34cd092fb7f5d",
      createdAt: "2024-10-22T18:59:36.883Z",
      updatedAt: "2024-10-22T19:01:13.507Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606f7",
        productPLU: "2162049-1",
        productDescription:
          "C 40g| P 21g| F 22g [[%Best seller%]]\nCrispy chicken topped with our light mayo, cheddar cheese & iceberg lettuce.",
        productName: "Chicken Burger",
        imageURL:
          "https://resizer.deliverect.com/krxICS8IGn1smBZLeJiI2x-TebYufgoyUB3g8zNXof8/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTktNjRiN2FlNGMyZWNhMDcwMDIwYTRlZGUxLnBuZw==",
        productCalories: 442,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 21,
          fat: 22,
        },
        fmcCentsPrice: 200,
        fiatCentsPrice: 200,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60737",
        productPLU: "2166032-1",
        productDescription:
          "C 28g | P 14g | F 12g\nCrispy & spicy chicken bites served with ranch. Contains (CORIANDER)\n\nGOOD SOURCE OF PROTEIN",
        productName: "Nashville Hot Bites",
        imageURL:
          "https://resizer.deliverect.com/oqGK22r84Wwk7MxRTTR18-yWnYcvB0rkdYy8xrcNbBE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbmFzaHZpbGxlJTIwYml0ZXMtNjVjYjE0YmUzMjdhOGY4YTRmOTcwMTkwLnBuZw==",
        productCalories: 276,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 14,
          fat: 12,
        },
        fmcCentsPrice: 210,
        fiatCentsPrice: 210,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f60738",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e84fb7a34cd092f606ff",
        productPLU: "2162029-1",
        productDescription:
          "C 59g| P 28g| F 19g\nCrispy chicken breast with rice & savoury katsu sauce.\n\nGood source of Vitamin A & Protein",
        productName: "Katsu Curry",
        imageURL:
          "https://resizer.deliverect.com/K5ibqyM8Py6DWkgNaRC2X-x8gPBAy9nVMFS1pOt8Wpc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUva2F0c3UtNjRiN2IxZjFhYzgyOTIwMDJkMzk2MTJmLnBuZw==",
        productCalories: 519,
        quantity: 1,
        nutritionInfo: {
          carbs: 59,
          protein: 28,
          fat: 19,
        },
        fmcCentsPrice: 250,
        fiatCentsPrice: 250,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 720,
    totalFmcCentsPrice: 720,
    totalFiatCentsPriceOriginal: 720,
    totalExternalFiatCentsPrice: 720,
    totalFmcCentsPriceOriginal: 720,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:59:38.471Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:01:26.592Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:03:09.985Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:21:56.694Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:21:56.695Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:22:18.976Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:48:28.377Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:59:38.471Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T19:00:00.024Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T18:59:38.471Z",
    updatedAt: "2024-10-22T19:48:28.928Z",
    fleetInfo: null,
  },
  {
    id: "6717f613b7a34cd092fb7b61",
    sid: "241022-159237",
    coupons: [],
    menuId: "6717e842b7a34cd092f5f5cf",
    locationId: "64c21023966460755c5339cf",
    locationName: "FM  Yard",
    deliveryId: "6717f612b7a34cd092fb7b55",
    firstName: "Saif alhajeri ",
    lastName: null,
    phoneNumber: "+96598746767",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64cd65eff119a7788c97c5f9",
    delivery: {
      id: "6717f612b7a34cd092fb7b55",
      customerId: "64cd65eff119a7788c97c5f9",
      locationId: "64c21023966460755c5339cf",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6717f5e5b7a34cd092fb660d",
        name: "Naser home",
        lng: 48.0897917971015,
        lat: 29.20223916251193,
        city: "Kuwait",
        area: "Al-Qurain District",
        block: "1",
        street: "11 St",
        building: "24",
        floor: "",
        flat: "",
        phone: "+96598746767",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-22T18:58:45.251Z",
        updatedAt: "2024-10-22T18:58:45.251Z",
      },
      deliverectValidationId: "6717f61207c41046b5905760",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:09:30.890Z",
      estimatedDeliveryTime: "2024-10-22T19:24:29.717Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:56:00.000Z",
      orderId: "6717f613b7a34cd092fb7b61",
      createdAt: "2024-10-22T18:59:30.996Z",
      updatedAt: "2024-10-22T19:01:06.848Z",
    },
    items: [
      {
        productId: "6717e842b7a34cd092f5f68e",
        productPLU: "2150014-1",
        productDescription: "C 2g| P 30g| F 6g\n",
        productName: "Chicken Shawarma(100GM)",
        imageURL:
          "https://resizer.deliverect.com/5s0dpyz1hk8m1gvg8YGrM7u6yPuJzcNpjKxdUNjSi6g/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hpY2tlbiUyMHNoYXdhcm1hJTIwKDEpLTY0ZDc1YjZhNjg1NDg1MDAyNzQwZTNmNy5qcGc=",
        productCalories: 182,
        quantity: 2,
        nutritionInfo: {
          carbs: 2,
          protein: 30,
          fat: 6,
        },
        fmcCentsPrice: 120,
        fiatCentsPrice: 120,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f695",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e842b7a34cd092f5f6c8",
        productPLU: "2202021-1",
        productDescription: "C 0g| P 0g| F 0g\nZero Sugar",
        productName: "Coca Cola Zero",
        imageURL:
          "https://resizer.deliverect.com/NvHctUF0z3us3Lhe9x5u3_mQIOOXnn8K3FvSZO6MkK4/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMiUyMCgxKS02NGM5MTk1MjZlY2Q3NzAwMjZjNGU1MDUuanBn",
        productCalories: 0,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 50,
        fiatCentsPrice: 50,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 350,
    totalFmcCentsPrice: 350,
    totalFiatCentsPriceOriginal: 350,
    totalExternalFiatCentsPrice: 350,
    totalFmcCentsPriceOriginal: 350,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:59:31.629Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:01:12.885Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:06:03.509Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:20:46.895Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:20:46.895Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:21:09.524Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:37:16.043Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:59:31.629Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:59:49.123Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T18:59:31.629Z",
    updatedAt: "2024-10-22T19:37:16.383Z",
    fleetInfo: null,
  },
  {
    id: "6717f60eb7a34cd092fb791e",
    sid: "241022-010806",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f60eb7a34cd092fb78ef",
    firstName: "Shouq almusallam",
    lastName: null,
    phoneNumber: "+96566062500",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65a8fd61cd9aef3291a9e395",
    delivery: {
      id: "6717f60eb7a34cd092fb78ef",
      customerId: "65a8fd61cd9aef3291a9e395",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f95279ee3a746f7f9d3350",
        name: "Shouq",
        lng: 48.01959883785926,
        lat: 29.278717041015625,
        city: "Kuwait City",
        area: "Hateen",
        block: "4",
        street: "411",
        building: "39",
        floor: "",
        flat: "",
        phone: "+96566062500",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-29T13:13:29.470Z",
        updatedAt: "2024-10-22T18:57:02.257Z",
      },
      deliverectValidationId: "6717f60d10394423ecd58083",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:09:25.630Z",
      estimatedDeliveryTime: "2024-10-22T19:24:23.403Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:56:00.000Z",
      orderId: "6717f60eb7a34cd092fb791e",
      createdAt: "2024-10-22T18:59:26.001Z",
      updatedAt: "2024-10-22T19:01:14.091Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606ee",
        productPLU: "2162096-1",
        productDescription:
          "C 48g| P 17g| F 17g\nCrispy fish tenders served with baked fries & tartar sauce.\n\nGood source of Protein, Vitamins and Minerals",
        productName: "Fish N' Chips",
        imageURL:
          "https://resizer.deliverect.com/nIuQ090dCYLyovaNm9H_VJzVg3JpgGl0gzEm4NvBR6s/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRmlzaCUyME4nJTIwQ2hpcHMtNjVmNmVjMDViOWNjMGMzODFjY2YwOTQyLnBuZw==",
        productCalories: 413,
        quantity: 1,
        nutritionInfo: {
          carbs: 48,
          protein: 17,
          fat: 17,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 400,
    totalFmcCentsPrice: 400,
    totalFiatCentsPriceOriginal: 400,
    totalExternalFiatCentsPrice: 400,
    totalFmcCentsPriceOriginal: 400,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:59:26.607Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:01:19.348Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:03:06.705Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:17:19.581Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:17:19.582Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:17:42.657Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:36:31.527Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:59:26.607Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:59:38.079Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T18:59:26.607Z",
    updatedAt: "2024-10-22T19:36:31.866Z",
    fleetInfo: null,
  },
  {
    id: "6717f5f8b7a34cd092fb71d0",
    sid: "241022-467714",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6717f5f8b7a34cd092fb71a0",
    firstName: "shaikha ahmad",
    lastName: null,
    phoneNumber: "+96560009552",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "670fc6d5aa779ec4a78e98c2",
    delivery: {
      id: "6717f5f8b7a34cd092fb71a0",
      customerId: "670fc6d5aa779ec4a78e98c2",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "670fc72ff495b693cd0b08f7",
        name: "daiya",
        lng: 48.00942622752768,
        lat: 29.35489331085068,
        city: "Kuwait City",
        area: "Daiya",
        block: "1",
        street: "13",
        building: "6",
        floor: "g",
        flat: "2",
        phone: "+96560009552",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-16T14:01:19.676Z",
        updatedAt: "2024-10-22T18:58:06.200Z",
      },
      deliverectValidationId: "6717f5f8e9cbdcf0e3246fb1",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:09:04.055Z",
      estimatedDeliveryTime: "2024-10-22T19:24:02.664Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:56:00.000Z",
      orderId: "6717f5f8b7a34cd092fb71d0",
      createdAt: "2024-10-22T18:59:04.159Z",
      updatedAt: "2024-10-22T19:01:06.287Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd22",
        productPLU: "2162108-1",
        productDescription:
          "C 20g | P 39g | F 28g\nSesame chicken with snow peas topped with roasted white sesame served with veggie bulgur rice.\n\nGood source of Protein & Vitamin C",
        productName: "Sesame chicken & snow peas",
        imageURL:
          "https://resizer.deliverect.com/yyleY01jv8RuFpYLVQ0J9ADHIJK5V3bCte9f4aRy4cE/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU2VzYW1lJTIwY2hpY2tlbiUyMCUyNiUyMHBlYXMtNjY0NGIwOWUzZmNiOWNjYjY2MmQ4NTVlLnBuZw==",
        productCalories: 488,
        quantity: 1,
        nutritionInfo: {
          carbs: 20,
          protein: 39,
          fat: 28,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 270,
    totalExternalFiatCentsPrice: 270,
    totalFmcCentsPriceOriginal: 270,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:59:04.985Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T19:01:07.491Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:04:18.718Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:18:07.876Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:18:07.877Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:19:25.660Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:37:27.363Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:59:04.985Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:59:16.720Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T18:59:04.985Z",
    updatedAt: "2024-10-22T19:37:27.705Z",
    fleetInfo: null,
  },
  {
    id: "6717f5acb7a34cd092fb5f0c",
    sid: "241022-724337",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6717f5abb7a34cd092fb5efe",
    firstName: " Khalaf alnajjar",
    lastName: null,
    phoneNumber: "+96566685000",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6528311d1bfa57c5dbf6ae04",
    delivery: {
      id: "6717f5abb7a34cd092fb5efe",
      customerId: "6528311d1bfa57c5dbf6ae04",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66aa85e81c3dd8f82b3a85ff",
        name: "Dwanyiat eid",
        lng: 47.96032290905714,
        lat: 29.356132557653197,
        city: "Kuwait City",
        area: "Shuwaikh Residential",
        block: "1",
        street: "Ahmad mohammed albahar",
        building: "2",
        floor: "",
        flat: "",
        phone: "+96566685000",
        additionalInfo: "White corner house informant of garden",
        isSelected: true,
        createdAt: "2024-07-31T18:43:52.184Z",
        updatedAt: "2024-10-22T18:55:43.534Z",
      },
      deliverectValidationId: "6717f5abb4faa714448c3dc9",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:07:47.555Z",
      estimatedDeliveryTime: "2024-10-22T19:22:46.077Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:54:00.000Z",
      orderId: "6717f5acb7a34cd092fb5f0c",
      createdAt: "2024-10-22T18:57:47.674Z",
      updatedAt: "2024-10-22T18:59:09.480Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd4f",
        productPLU: "2167009-1",
        productDescription:
          "C 40g| P 34g| F 20g\nRoast beef, arugula, caramelized onions, american cheese & mozarella cheese.\n\n*GOOD SOURCE OF PROTEIN,VITAMINB12 *",
        productName: "Steak Beef Sandwich",
        imageURL:
          "https://resizer.deliverect.com/DtNUTtvxCtTpYduIHX1EtM3gopyjoTP3-HuOderSW-c/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU1RFQUslMjBTQU5EV0hJQ0gtNjU3NTkwMGE0OGU3YWFhNzJiYzFjNDY5LnBuZw==",
        productCalories: 476,
        quantity: 1,
        nutritionInfo: {
          carbs: 40,
          protein: 34,
          fat: 20,
        },
        fmcCentsPrice: 330,
        fiatCentsPrice: 330,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e846b7a34cd092f5fe11",
            productPLU: "1907040-1",
            productName: "No Caramelized Onion",
            productDescription: "C -6g| P -0.6g| F 0g| cal -25| coins 0",
            productCalories: -25,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -6,
              protein: -0.6,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 330,
    totalFmcCentsPrice: 330,
    totalFiatCentsPriceOriginal: 330,
    totalExternalFiatCentsPrice: 330,
    totalFmcCentsPriceOriginal: 330,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:57:48.307Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T18:59:15.234Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T18:59:46.263Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:05:31.835Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:05:31.835Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:05:53.697Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:21:20.787Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:57:48.307Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:58:00.875Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T18:57:48.307Z",
    updatedAt: "2024-10-22T19:21:21.164Z",
    fleetInfo: null,
  },
  {
    id: "6717f58bb7a34cd092fb4fe9",
    sid: "241022-929915",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f58ab7a34cd092fb4fdb",
    firstName: "Bader alkandari",
    lastName: null,
    phoneNumber: "+96599999567",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "665dc0420eba0d08a57313b8",
    delivery: {
      id: "6717f58ab7a34cd092fb4fdb",
      customerId: "665dc0420eba0d08a57313b8",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6717f571b7a34cd092fb450c",
        name: "Home",
        lng: 48.02013013511896,
        lat: 29.289822433412425,
        city: "Kuwait",
        area: "Salam",
        block: "5",
        street: "501 St",
        building: "8",
        floor: "0",
        flat: "",
        phone: "+96599999567",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-22T18:56:49.605Z",
        updatedAt: "2024-10-22T18:56:49.605Z",
      },
      deliverectValidationId: "6717f58a2e12b68950717b21",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:07:14.745Z",
      estimatedDeliveryTime: "2024-10-22T19:22:13.763Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:54:00.000Z",
      orderId: "6717f58bb7a34cd092fb4fe9",
      createdAt: "2024-10-22T18:57:14.847Z",
      updatedAt: "2024-10-22T18:59:06.488Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f60707",
        productPLU: "2167026-1",
        productDescription:
          "C 31g | P 19g | F 12g [%New%]\n\nTortilla, sun-dried tomato paste, turkey breast, light mayo, iceberg lettuce, fresh tomato, cheddar cheese, avocado.",
        productName: "Sundried Tomato & Turkey Wrap",
        imageURL:
          "https://resizer.deliverect.com/lkBFjz6fT3ypjhfEPm4jFgiCjqU4b3hZdgbi5fTcJJo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3VuJTIwZHJpZWQlMjB0dXJrZXklMjB3cmFwLTY2ZjkwZDU2MzNmN2I4MjhjMTYwY2U3ZC5wbmc=",
        productCalories: 308,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 290,
    totalFmcCentsPrice: 290,
    totalFiatCentsPriceOriginal: 290,
    totalExternalFiatCentsPrice: 290,
    totalFmcCentsPriceOriginal: 290,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:57:15.486Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T18:59:19.292Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T19:03:00.092Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:17:08.731Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:17:08.731Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:23:03.895Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:38:43.231Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:57:15.486Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:57:32.258Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T18:57:15.486Z",
    updatedAt: "2024-10-22T19:38:43.580Z",
    fleetInfo: null,
  },
  {
    id: "6717f585b7a34cd092fb4e60",
    sid: "241022-742864",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717f585b7a34cd092fb4e48",
    firstName: "Aziz almeer",
    lastName: null,
    phoneNumber: "+96597100097",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66f1b1c47f0597f029e27351",
    delivery: {
      id: "6717f585b7a34cd092fb4e48",
      customerId: "66f1b1c47f0597f029e27351",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66f1b26d1294dc02ca5c321e",
        name: "Home",
        lng: 47.98172075338688,
        lat: 29.321044921875,
        city: "Kuwait",
        area: "Adailiya",
        block: "2",
        street: "22",
        building: "4",
        floor: "",
        flat: "",
        phone: "+96597100097",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-23T18:24:45.428Z",
        updatedAt: "2024-09-23T18:24:45.428Z",
      },
      deliverectValidationId: "6717f584fbbda76c3f02946d",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:07:08.832Z",
      estimatedDeliveryTime: "2024-10-22T19:22:07.753Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:54:00.000Z",
      orderId: "6717f585b7a34cd092fb4e60",
      createdAt: "2024-10-22T18:57:09.219Z",
      updatedAt: "2024-10-22T18:59:05.181Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed3f",
        productPLU: "2162113-1",
        productDescription:
          "C 67g | P 21g | F 25g[%Popular%]\nCheeseburger with baked sweet potato fries and bbq sauce",
        productName: "Cheeseburger & fries",
        imageURL:
          "https://resizer.deliverect.com/7r3h13J-z3i_dUkPi81fBjeHfz7KOpOpG45voMIj2xA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hlZXNlYnVyZ2VyJTIwY29tYm8tNjcxMzU3NjhmNTg1MzQ0Mzg3MTVlNGIyLnBuZw==",
        productCalories: 577,
        quantity: 1,
        nutritionInfo: {
          carbs: 67,
          protein: 21,
          fat: 25,
        },
        fmcCentsPrice: 420,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e83bb7a34cd092f5edda",
            productPLU: "1906004-1",
            productName: "Extra  Beef Pattie",
            productDescription: "C 2g| P 6g| F 3g| cal 59| coins 0.5",
            productCalories: 59,
            quantity: 1,
            fmcCentsPrice: 50,
            fiatCentsPrice: 50,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: 2,
              protein: 6,
              fat: 3,
            },
          },
          {
            productId: "6717e83bb7a34cd092f5edeb",
            productPLU: "1907004-1",
            productName: "No Onion",
            productDescription: "C -1g| P 0g| F 0g| cal -3| coins 0\n",
            productCalories: -3,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: null,
              fat: null,
            },
          },
        ],
      },
    ],
    totalFiatCentsPrice: 420,
    totalFmcCentsPrice: 420,
    totalFiatCentsPriceOriginal: 420,
    totalExternalFiatCentsPrice: 420,
    totalFmcCentsPriceOriginal: 420,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:57:09.873Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T18:59:10.449Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T18:59:50.748Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:20:24.944Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:20:24.944Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:20:46.817Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:40:22.825Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:57:09.873Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:57:21.276Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T18:57:09.873Z",
    updatedAt: "2024-10-22T19:40:23.165Z",
    fleetInfo: null,
  },
  {
    id: "6717f55db7a34cd092fb4453",
    sid: "241022-521678",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6717f55db7a34cd092fb4448",
    firstName: "Shaikha AlHudaib",
    lastName: null,
    phoneNumber: "+96590994246",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "64ca9faa75a6aa442d137936",
    delivery: {
      id: "6717f55db7a34cd092fb4448",
      customerId: "64ca9faa75a6aa442d137936",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6574b1d4f093f3245802ef68",
        name: "Home",
        lng: 47.96701755914889,
        lat: 29.337417602539062,
        city: "Kuwait",
        area: "Kaifan",
        block: "1",
        street: "16",
        building: "41A",
        floor: "",
        flat: "",
        phone: "+96590994246",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-09T18:28:36.158Z",
        updatedAt: "2023-12-09T18:28:36.158Z",
      },
      deliverectValidationId: "6717f55dc47d7273b38b67a5",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:06:29.018Z",
      estimatedDeliveryTime: "2024-10-22T19:21:27.961Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:53:00.000Z",
      orderId: "6717f55db7a34cd092fb4453",
      createdAt: "2024-10-22T18:56:29.118Z",
      updatedAt: "2024-10-22T18:58:05.987Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd4c",
        productPLU: "2162073-1",
        productDescription:
          "C 55g| P 22g| F 20g [%Popular%]\nCrispy buffalo chicken tenders, iceberg lettuce, cheese with black pepper ranch sauce in a tortilla\n\nGOOD SOURCE OF PROTEIN, IRON, VITAMINS AND MINERALS ",
        productName: "Zinger Wrap",
        imageURL:
          "https://resizer.deliverect.com/HxB6Jk4SYuzyKeoB5PLI0G8vmLwmz39dUm-FWsICaAg/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvWmluZ2VyJTIwd3JhcC02NWY2ZTkxOGMxMjg2NmZjODg2NjczZTIuanBn",
        productCalories: 491,
        quantity: 1,
        nutritionInfo: {
          carbs: 55,
          protein: 22,
          fat: 20,
        },
        fmcCentsPrice: 190,
        fiatCentsPrice: 190,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd64",
        productPLU: "2166013-1",
        productDescription:
          "C 17.2g| P 2.8g| F 8.6g[%Best seller%]\nCouscous with beetroots, pomegranates, spring onions, parsley & fresh mint all mixed with olive oil & fresh lemon juice.",
        productName: "Couscous Beetroot Tabbouleh",
        imageURL:
          "https://resizer.deliverect.com/U0yH7Rn8y26HQjz21tZ2hgogrHG94rJPWCSUyxBf1ts/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDYtNjQ5ODE1NTE2YzIxZTEwMDIwNWVlNmNjLmpwZw==",
        productCalories: 158,
        quantity: 1,
        nutritionInfo: {
          carbs: 17.2,
          protein: 2.8,
          fat: 8.6,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 270,
    totalFmcCentsPrice: 270,
    totalFiatCentsPriceOriginal: 270,
    totalExternalFiatCentsPrice: 270,
    totalFmcCentsPriceOriginal: 270,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:56:29.774Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T18:58:07.884Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T18:59:29.059Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:16:57.618Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:16:57.618Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:31:07.655Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:45:07.818Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:56:29.774Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:56:41.166Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T18:56:29.774Z",
    updatedAt: "2024-10-22T19:45:08.270Z",
    fleetInfo: null,
  },
  {
    id: "6717f559b7a34cd092fb431b",
    sid: "241022-057603",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6717f559b7a34cd092fb430e",
    firstName: "Lulu Boodai",
    lastName: null,
    phoneNumber: "+96599440006",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66dd781aaa1645e3a508d082",
    delivery: {
      id: "6717f559b7a34cd092fb430e",
      customerId: "66dd781aaa1645e3a508d082",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66dd78c3ced6c5247e0a69cd",
        name: "home",
        lng: 47.9854951892495,
        lat: 29.359375,
        city: "Kuwait City",
        area: "Abdullah al-Salem",
        block: "1",
        street: "12 St",
        building: "10 A",
        floor: "2",
        flat: "4",
        phone: "+96599440006",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-09-08T10:13:23.917Z",
        updatedAt: "2024-09-08T10:13:23.917Z",
      },
      deliverectValidationId: "6717f559e0ba6b8ca0f3ca03",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:06:25.004Z",
      estimatedDeliveryTime: "2024-10-22T19:21:23.309Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:53:00.000Z",
      orderId: "6717f559b7a34cd092fb431b",
      createdAt: "2024-10-22T18:56:25.223Z",
      updatedAt: "2024-10-22T18:58:06.413Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd47",
        productPLU: "2167022-1",
        productDescription:
          "C 29g | P 21g | F 16g\nSpicy chicken shawarma with spring onions, pomegranates, parsley, pickled cucumbers & spicy sauce wrapped in tortilla. \n\nGood source of Protein",
        productName: "Spicy Chicken Shawarma",
        imageURL:
          "https://resizer.deliverect.com/7iczQVW8ZJbNN179Yvo32X8zQsGcTbj5NwVYyyacwCk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvU0hBV0FSTUlBKDEpLTY2NDRiYzU4M2NiZjdhMzY3ZmI0YTI1ZC5qcGc=",
        productCalories: 344,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 21,
          fat: 16,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd75",
        productPLU: "2166030-1",
        productDescription:
          "C 29g| P 2g| F 4g[[%Best seller%]]\nBaked french fries with ketchup.",
        productName: "Baked Fries",
        imageURL:
          "https://resizer.deliverect.com/B7W3zaRiivFRQ7BlJTa22zI-PY2_hBuleLb66K7eHfY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvRnJpZXMoMSktNjU1NDhmOGQyMDliNDcwMDIxM2IzNmUwLnBuZw==",
        productCalories: 160,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 2,
          fat: 4,
        },
        fmcCentsPrice: 60,
        fiatCentsPrice: 60,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd7f",
        productPLU: "2166039-1",
        productDescription: "C 26.2g | P 13g | F 5.7g",
        productName: "PROPUFFS Spicy",
        imageURL:
          "https://resizer.deliverect.com/mx55iZdZ7KcI8JZ3nkFGc2JC1ULwgk7Ghc7nv13KIdY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3BpY3klMjBwdWZmLTY2YWJmNTJjNDVlNTQwODQ4MTQ0ZDVlMy5wbmc=",
        productCalories: 172,
        quantity: 2,
        nutritionInfo: {
          carbs: 26.2,
          protein: 13,
          fat: 5.7,
        },
        fmcCentsPrice: 100,
        fiatCentsPrice: 100,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 410,
    totalFmcCentsPrice: 410,
    totalFiatCentsPriceOriginal: 410,
    totalExternalFiatCentsPrice: 410,
    totalFmcCentsPriceOriginal: 410,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:56:25.837Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T18:58:13.064Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T18:59:27.892Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:16:26.862Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:16:26.862Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:18:05.289Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:26:14.678Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:56:25.837Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:56:30.133Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T18:56:25.837Z",
    updatedAt: "2024-10-22T19:26:15.304Z",
    fleetInfo: null,
  },
  {
    id: "6717f53eb7a34cd092fb35d7",
    sid: "241022-897294",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717f53eb7a34cd092fb35c4",
    firstName: "Latifa Almasoud",
    lastName: null,
    phoneNumber: "+96599396733",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "66a62069ac4eb4f3eefbecb6",
    delivery: {
      id: "6717f53eb7a34cd092fb35c4",
      customerId: "66a62069ac4eb4f3eefbecb6",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "66a622bf1683a2e7268d3068",
        name: "Almasoud",
        lng: 48.01334833726287,
        lat: 29.315986617261714,
        city: "Ardiya",
        area: "Surra",
        block: "2",
        street: "3",
        building: "2",
        floor: "",
        flat: "",
        phone: "+96599396733",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-07-28T10:51:43.563Z",
        updatedAt: "2024-07-28T10:51:43.563Z",
      },
      deliverectValidationId: "6717f53d0425e1b14bfc454f",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:05:57.989Z",
      estimatedDeliveryTime: "2024-10-22T19:20:56.974Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:50:56.974Z",
      orderId: "6717f53eb7a34cd092fb35d7",
      createdAt: "2024-10-22T18:55:58.132Z",
      updatedAt: "2024-10-22T18:55:58.777Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed6c",
        productPLU: "2167007-1",
        productDescription:
          "C 60g| P 24g| F 17g [%Best seller%]\nSmoked turkey with grilled turkey bacon, avocado puree, rocca, pesto & emmental cheese all in sourdough toast. ",
        productName: "Turkey Pesto",
        imageURL:
          "https://resizer.deliverect.com/d4E--e7KeFg5pz7n8OJh2vgevZuUdJEXur9hGCWc7sY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvVHVya2V5JTIwc2FuZHdpY2gtNjRmNDkwMzljNDA4OTczMWUzMzk4OTc0LmpwZw==",
        productCalories: 489,
        quantity: 1,
        nutritionInfo: {
          carbs: 60,
          protein: 24,
          fat: 17,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed7d",
        productPLU: "2163012-1",
        productDescription:
          "C 6g| P 12g| F 5g\nChicken caesar salad with grilled chicken topped with sourdough croutons & shaved parmesan cheese.  \n\nGOOD SOURCE OF PROTIEN , VITAMIN K & A",
        productName: "MINI CAESAR SALAD",
        imageURL:
          "https://resizer.deliverect.com/pn19DI6nKfoNFDytlww7s2gKrhsydFXuV2FgKSAnmjs/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNhZXNhciUyMHNhbGFkLTY1MDQ4YTQ3YzQwODk3MzFlMzM5OTFkZi5qcGc=",
        productCalories: 117,
        quantity: 1,
        nutritionInfo: {
          carbs: 6,
          protein: 12,
          fat: 5,
        },
        fmcCentsPrice: 140,
        fiatCentsPrice: 140,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 370,
    totalExternalFiatCentsPrice: 370,
    totalFmcCentsPriceOriginal: 370,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:55:58.760Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T18:57:20.700Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T18:59:51.118Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:19:49.513Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:19:49.513Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:20:11.805Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:25:04.592Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:55:58.760Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:56:08.862Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T18:55:58.760Z",
    updatedAt: "2024-10-22T19:32:29.565Z",
    fleetInfo: null,
  },
  {
    id: "6717f527b7a34cd092fb2a5d",
    sid: "241022-074269",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717f527b7a34cd092fb2a4e",
    firstName: "Mohammed Meshari Zaman",
    lastName: null,
    phoneNumber: "+96569998862",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "671655bcb58bc473083fbefd",
    delivery: {
      id: "6717f527b7a34cd092fb2a4e",
      customerId: "671655bcb58bc473083fbefd",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "67165915b7a34cd092a4cafc",
        name: "Moh zaman",
        lng: 48.08185497298837,
        lat: 29.322792087475896,
        city: "Rumaithiya",
        area: "Rumaithiya",
        block: "7",
        street: "73",
        building: "Building 1 / apartment 7",
        floor: "1",
        flat: "",
        phone: "+96569998862",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-10-21T13:37:25.352Z",
        updatedAt: "2024-10-21T13:37:25.352Z",
      },
      deliverectValidationId: "6717f526fbbda76c3f027a7a",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:05:34.844Z",
      estimatedDeliveryTime: "2024-10-22T19:20:33.759Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:52:00.000Z",
      orderId: "6717f527b7a34cd092fb2a5d",
      createdAt: "2024-10-22T18:55:35.078Z",
      updatedAt: "2024-10-22T18:57:06.646Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed49",
        productPLU: "2162082-1",
        productDescription:
          "C 44g| P 39g| F 6g\nChicken breast with baked eggplant, cauliflower, onions, carrots, red bell peppers in rice with CORIANDER\n\nGOOD SOURCE OF PROTEIN,VITAMIN A &C",
        productName: "Maqlouba",
        imageURL:
          "https://resizer.deliverect.com/ImlBCJvVJcyYJBWth7S7FEgLNUj1i0B0vhTLvEmLoDY/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NjQ0YmNjMzc0OGYzNjdjMTMxNmU2ODgucG5n",
        productCalories: 386,
        quantity: 1,
        nutritionInfo: {
          carbs: 44,
          protein: 39,
          fat: 6,
        },
        fmcCentsPrice: 240,
        fiatCentsPrice: 240,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed8b",
        productPLU: "2168002-1",
        productDescription:
          "C 4g | P 2g | F 2g|\nCreamy mushroom soup topped with parsley. ",
        productName: "Mushroom Soup",
        imageURL:
          "https://resizer.deliverect.com/VvtgR2CAH02ajyt1L7X3df8XtKjNQ2Kx-d7hCJY0Q1w/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbXVzaHJvb20lMjBzb3VwLTY2NDM3YTg5ZTFhZTBmYmZhZDYyYmVhZC5qcGc=",
        productCalories: 42,
        quantity: 1,
        nutritionInfo: {
          carbs: 4,
          protein: 2,
          fat: 2,
        },
        fmcCentsPrice: 140,
        fiatCentsPrice: 140,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5edbc",
        productPLU: "2202050-1",
        productDescription:
          "C 27g | P 1.5g | F 0g\nOrange \n\nGood source of Vitamin C",
        productName: "Orange Citrus",
        imageURL:
          "https://resizer.deliverect.com/92Cgq5w07iSWvqOzcojq0Egx0BDIxsVNTfIhlYhtUmU/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvMTMtNjY1OTE5ODQ0MjllZTgwN2Q2OWQxYmZkLnBuZw==",
        productCalories: 116,
        quantity: 1,
        nutritionInfo: {
          carbs: 27,
          protein: 1.5,
          fat: null,
        },
        fmcCentsPrice: 110,
        fiatCentsPrice: 110,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed7b",
        productPLU: "2163010-1",
        productDescription:
          "C 25g| P 34g| F 15g\nGrilled chicken with baby gem lettuce, caesar dressing topped with sourdough croutons & shaved parmesan cheese.\n\nGood source of Vitamin A &Potein",
        productName: "Chicken Ceasar Salad",
        imageURL:
          "https://resizer.deliverect.com/aOyM36xC7gBB5GwjyrYYA3YsTJ8-9RRXq22EPXeQlkM/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNi02NGI3YjVjOWU0ZDVjMzAwMThiNjViNmIucG5n",
        productCalories: 371,
        quantity: 1,
        nutritionInfo: {
          carbs: 25,
          protein: 34,
          fat: 15,
        },
        fmcCentsPrice: 270,
        fiatCentsPrice: 270,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 760,
    totalFmcCentsPrice: 760,
    totalFiatCentsPriceOriginal: 760,
    totalExternalFiatCentsPrice: 760,
    totalFmcCentsPriceOriginal: 760,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:55:35.692Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T18:57:20.579Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T18:59:50.661Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:22:30.509Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:22:30.509Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:22:52.373Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:39:30.890Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:55:35.692Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:55:47.080Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T18:55:35.692Z",
    updatedAt: "2024-10-22T19:39:31.354Z",
    fleetInfo: null,
  },
  {
    id: "6717f512b7a34cd092fb20c0",
    sid: "241022-831608",
    coupons: [],
    menuId: "6717e846b7a34cd092f5fcad",
    locationId: "645a3d26d812e8a27b06d717",
    locationName: "FM City",
    deliveryId: "6717f511b7a34cd092fb209a",
    firstName: "Haya khalifa alhajeri",
    lastName: null,
    phoneNumber: "+96566552269",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65632d00e0d78832aa8f2dea",
    delivery: {
      id: "6717f511b7a34cd092fb209a",
      customerId: "65632d00e0d78832aa8f2dea",
      locationId: "645a3d26d812e8a27b06d717",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "65632e3102b2e21f1faf4d57",
        name: "Mansoriya, B2 st29 H20",
        lng: 47.99491403552668,
        lat: 29.352981567382812,
        city: "Kuwait",
        area: "Mansouriya",
        block: "2",
        street: "29",
        building: "20B",
        floor: "2",
        flat: "",
        phone: "+96566552269",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-11-26T11:38:25.139Z",
        updatedAt: "2024-09-12T19:14:43.389Z",
      },
      deliverectValidationId: "6717f5113946960ae1254a44",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:05:13.443Z",
      estimatedDeliveryTime: "2024-10-22T19:20:12.379Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:52:00.000Z",
      orderId: "6717f512b7a34cd092fb20c0",
      createdAt: "2024-10-22T18:55:13.536Z",
      updatedAt: "2024-10-22T18:57:06.648Z",
    },
    items: [
      {
        productId: "6717e846b7a34cd092f5fd43",
        productPLU: "2167027-1",
        productDescription:
          "C 18g | P 11g | F 11g [%New%]\n\nTortilla, chicken patty, mayo sauce mix, cheddar cheese and iceberg lettuce\n\nGood source of Protein",
        productName: "Mini chicken wrap",
        imageURL:
          "https://resizer.deliverect.com/5rDC0HPtRc5f68N8jtG0A9WC8J-6kc7XkDKmTlZ3qLk/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjB3cmFwLTY3MTM1MGNkMTVhMWRhMWVkNDcxY2E4Ni5wbmc=",
        productCalories: 215,
        quantity: 2,
        nutritionInfo: {
          carbs: 18,
          protein: 11,
          fat: 11,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fd6d",
        productPLU: "2150004-1",
        productDescription: "C 28g| P 5g| F 11g\nContains coriander.",
        productName: "Batata Harra (100GM)",
        imageURL:
          "https://resizer.deliverect.com/u6QAZJk-DEsLbrspCI76OZkhV7dEOnByA2HrQZeDbD0/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvYmF0YXRhJTIwaGFycmEtNjRkNzViMTc0YWIyYTcwMDE4MmY4NDNiLmpwZw==",
        productCalories: 231,
        quantity: 1,
        nutritionInfo: {
          carbs: 28,
          protein: 5,
          fat: 11,
        },
        fmcCentsPrice: 90,
        fiatCentsPrice: 90,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e846b7a34cd092f5fda0",
        productPLU: "2202055-1",
        productDescription: "C 0g | P 0g | F 0g ",
        productName: "Celsius Cola",
        imageURL:
          "https://resizer.deliverect.com/BY78vNMNBCBYUUYrwhumRcBaZ__owWkEHv-C0kvAh5w/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2Vsc2l1cyUyMGNvbGEtNjY2YjJlZDVlYTA2OTU5ODUyYzNiYTYxLnBuZw==",
        productCalories: 10,
        quantity: 1,
        nutritionInfo: {
          carbs: null,
          protein: null,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 500,
    totalFmcCentsPrice: 500,
    totalFiatCentsPriceOriginal: 500,
    totalExternalFiatCentsPrice: 500,
    totalFmcCentsPriceOriginal: 500,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:55:14.271Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T18:57:08.694Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T18:58:50.783Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:15:19.634Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:15:19.634Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:18:27.747Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:37:04.817Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:55:14.271Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:55:25.685Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T18:55:14.271Z",
    updatedAt: "2024-10-22T19:37:05.158Z",
    fleetInfo: null,
  },
  {
    id: "6717f495b7a34cd092fb037e",
    sid: "241022-800672",
    coupons: [],
    menuId: "6717e84fb7a34cd092f60672",
    locationId: "64b66609f9b191f3652edc8b",
    locationName: "FM Khaitan",
    deliveryId: "6717f495b7a34cd092fb0375",
    firstName: "Fahad alawadhi",
    lastName: null,
    phoneNumber: "+96560999628",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6569c7c146956c2566bc4415",
    delivery: {
      id: "6717f495b7a34cd092fb0375",
      customerId: "6569c7c146956c2566bc4415",
      locationId: "64b66609f9b191f3652edc8b",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6569c900b17b87d5d2dbaa7c",
        name: "Home",
        lng: 48.03489251062274,
        lat: 29.268682372577622,
        city: "Kuwait",
        area: "Shuhada",
        block: "2",
        street: "203 St",
        building: "20",
        floor: "",
        flat: "",
        phone: "+96560999628",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-12-01T11:52:32.425Z",
        updatedAt: "2024-10-22T18:51:38.305Z",
      },
      deliverectValidationId: "6717f4950d4544b0524307b1",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:03:09.257Z",
      estimatedDeliveryTime: "2024-10-22T19:18:07.962Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:50:00.000Z",
      orderId: "6717f495b7a34cd092fb037e",
      createdAt: "2024-10-22T18:53:09.358Z",
      updatedAt: "2024-10-22T18:55:06.195Z",
    },
    items: [
      {
        productId: "6717e84fb7a34cd092f606e8",
        productPLU: "2162113-1",
        productDescription:
          "C 67g | P 21g | F 25g[%Popular%]\nCheeseburger with baked sweet potato fries and bbq sauce",
        productName: "Cheeseburger & fries",
        imageURL:
          "https://resizer.deliverect.com/7r3h13J-z3i_dUkPi81fBjeHfz7KOpOpG45voMIj2xA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hlZXNlYnVyZ2VyJTIwY29tYm8tNjcxMzU3NjhmNTg1MzQ0Mzg3MTVlNGIyLnBuZw==",
        productCalories: 577,
        quantity: 1,
        nutritionInfo: {
          carbs: 67,
          protein: 21,
          fat: 25,
        },
        fmcCentsPrice: 370,
        fiatCentsPrice: 370,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 370,
    totalFmcCentsPrice: 370,
    totalFiatCentsPriceOriginal: 370,
    totalExternalFiatCentsPrice: 370,
    totalFmcCentsPriceOriginal: 370,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:53:09.960Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T18:55:17.299Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T18:55:36.616Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:15:06.283Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:15:06.283Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:15:28.329Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:31:52.152Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:53:09.960Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:53:22.736Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-22T18:53:09.960Z",
    updatedAt: "2024-10-22T19:31:52.599Z",
    fleetInfo: null,
  },
  {
    id: "6717f47cb7a34cd092faf5c6",
    sid: "241022-273555",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717f47bb7a34cd092faf5bc",
    firstName: "Nawaf faisal",
    lastName: null,
    phoneNumber: "+96599213139",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "6654a86047e7155f92750a16",
    delivery: {
      id: "6717f47bb7a34cd092faf5bc",
      customerId: "6654a86047e7155f92750a16",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6654a968de3f2e06c695d2c5",
        name: "Nawaf",
        lng: 48.03814955055714,
        lat: 29.31445185078306,
        city: "Jabriya",
        area: "Jabriya",
        block: "10",
        street: "15",
        building: "11",
        floor: "",
        flat: "",
        phone: "+96599213139",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2024-05-27T15:40:24.513Z",
        updatedAt: "2024-10-05T18:06:13.713Z",
      },
      deliverectValidationId: "6717f47b07c41046b58f83f3",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:02:43.472Z",
      estimatedDeliveryTime: "2024-10-22T19:17:42.472Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:49:00.000Z",
      orderId: "6717f47cb7a34cd092faf5c6",
      createdAt: "2024-10-22T18:52:43.636Z",
      updatedAt: "2024-10-22T18:54:05.123Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed5f",
        productPLU: "2167025-1",
        productDescription:
          "C 29g | P 31g | F 12g\nTortilla with chicken breast, Mexican sauce, cheddar cheese, red & green capsicum.\n\nGood source of Protein & Vitamin C",
        productName: "Mexican wrap",
        imageURL:
          "https://resizer.deliverect.com/rAzOkou3Hx2u334uLtcNGO0XSk3R-noyrtj8ts7K71I/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvTWV4aWNhbiUyMHdyYXAtNjZjODU4YzEyZTkyNDE5N2YyNWEzYzc4LnBuZw==",
        productCalories: 348,
        quantity: 1,
        nutritionInfo: {
          carbs: 29,
          protein: 31,
          fat: 12,
        },
        fmcCentsPrice: 230,
        fiatCentsPrice: 230,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed80",
        productPLU: "2162137-1",
        productDescription:
          "C 58g | P 26g | F 15g [%New%]\n\nEgyptian rice topped with chopped steak and mixed veggies, served with roasted sweet potato, sauce, air-fried onion, and garnished with chives, sesame seeds and sumac. ( Includes Coriander )\n\nGood source of Protein & Vitamins",
        productName: "Chopped steak rice bowl",
        imageURL:
          "https://resizer.deliverect.com/_zrZW_wKv-53wc25P7NkxtvjUm6QJfutpW1Mat8BQ0U/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvY2hvcHBlZCUyMHN0ZWFrLTY3MTM0ZjkwYmJlZWUwMmFhMDk5ZWI3Zi5wbmc=",
        productCalories: 471,
        quantity: 1,
        nutritionInfo: {
          carbs: 58,
          protein: 26,
          fat: 15,
        },
        fmcCentsPrice: 400,
        fiatCentsPrice: 400,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 630,
    totalFmcCentsPrice: 630,
    totalFiatCentsPriceOriginal: 630,
    totalExternalFiatCentsPrice: 630,
    totalFmcCentsPriceOriginal: 630,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:52:44.209Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T18:54:15.591Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T18:59:58.132Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:23:29.277Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:23:29.278Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:23:51.395Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:35:13.498Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:52:44.209Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:52:55.724Z",
      },
    ],
    cutlery: true,
    timeslot: null,
    createdAt: "2024-10-22T18:52:44.209Z",
    updatedAt: "2024-10-22T19:35:13.843Z",
    fleetInfo: null,
  },
  {
    id: "6717f447b7a34cd092fae5b9",
    sid: "241022-406510",
    coupons: [],
    menuId: "6717e83bb7a34cd092f5ecc9",
    locationId: "64634f2fcdf25f405d574f88",
    locationName: "FM Jabriya",
    deliveryId: "6717f445b7a34cd092fae45c",
    firstName: "Maytham Mohammad",
    lastName: null,
    phoneNumber: "+96597303013",
    paymentType: "fmcCents",
    type: "instantOrder",
    customerId: "65023c4de057bc99ba581b8b",
    delivery: {
      id: "6717f445b7a34cd092fae45c",
      customerId: "65023c4de057bc99ba581b8b",
      locationId: "64634f2fcdf25f405d574f88",
      deliveryFeeFiatCents: null,
      deliveryFeeFmcCents: null,
      deliveryAddress: {
        id: "6530fb8e289c08cbdb8a1af3",
        name: "Home",
        lng: 48.050432600075844,
        lat: 29.280059814453125,
        city: "Mubarak Al-Abdullah",
        area: "Mubarak Al-Abdullah",
        block: "1",
        street: "106 ",
        building: "100",
        floor: "",
        flat: "",
        phone: "+96597303013",
        additionalInfo: "",
        isSelected: true,
        createdAt: "2023-10-19T09:49:02.175Z",
        updatedAt: "2024-07-20T19:26:14.519Z",
      },
      deliverectValidationId: "6717f445c581fc4bf1e5ec72",
      deliveryProvider: "deliverect",
      expiresAt: "2024-10-22T19:01:49.170Z",
      estimatedDeliveryTime: "2024-10-22T19:16:47.861Z",
      estimatedDeliveryTimeEnd: "2024-10-22T19:48:00.000Z",
      orderId: "6717f447b7a34cd092fae5b9",
      createdAt: "2024-10-22T18:51:49.641Z",
      updatedAt: "2024-10-22T18:53:06.223Z",
    },
    items: [
      {
        productId: "6717e83bb7a34cd092f5ed5e",
        productPLU: "2167026-1",
        productDescription:
          "C 31g | P 19g | F 12g [%New%]\n\nTortilla, sun-dried tomato paste, turkey breast, light mayo, iceberg lettuce, fresh tomato, cheddar cheese, avocado.",
        productName: "Sundried Tomato & Turkey Wrap",
        imageURL:
          "https://resizer.deliverect.com/lkBFjz6fT3ypjhfEPm4jFgiCjqU4b3hZdgbi5fTcJJo/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvc3VuJTIwZHJpZWQlMjB0dXJrZXklMjB3cmFwLTY2ZjkwZDU2MzNmN2I4MjhjMTYwY2U3ZC5wbmc=",
        productCalories: 308,
        quantity: 1,
        nutritionInfo: {
          carbs: 31,
          protein: 19,
          fat: 12,
        },
        fmcCentsPrice: 290,
        fiatCentsPrice: 290,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [
          {
            productId: "6717e83bb7a34cd092f5ee1f",
            productPLU: "1907090-1",
            productName: "Remove Fresh tomato",
            productDescription: "C -1g| P -0.1g| F -0g| cal -4| coins 0",
            productCalories: -4,
            quantity: 1,
            fmcCentsPrice: 0,
            fiatCentsPrice: 0,
            externalFiatCentsPrice: 0,
            nutritionInfo: {
              carbs: -1,
              protein: -0.1,
              fat: null,
            },
          },
        ],
      },
      {
        productId: "6717e83bb7a34cd092f5eda7",
        productPLU: "2166035-1",
        productDescription: "C 32g | P 1g | F 0g\nRed Grapes",
        productName: "Red Grapes",
        imageURL:
          "https://resizer.deliverect.com/AtcTHpynl67VC2iPbOU8NdD4B36i27YMor_K0a-pcRA/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvUkUlMjAzXy02NjNmZWU0YWUwMDMyNDNiNWVhOTRjZWUucG5n",
        productCalories: 132,
        quantity: 1,
        nutritionInfo: {
          carbs: 32,
          protein: 1,
          fat: null,
        },
        fmcCentsPrice: 150,
        fiatCentsPrice: 150,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5edb7",
        productPLU: "2165005-1",
        productDescription: "C 13g| P 1g| F 10g",
        productName: "Salted Pecan Bites",
        imageURL:
          "https://resizer.deliverect.com/p3z6wZFsuIaNIkw99YRPwzlHvwGLg970lPG5QDvZQrc/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvNDgtNjRiN2JjOTE2ZjhhYmY0M2UzMWQxMjBjLnBuZw==",
        productCalories: 151,
        quantity: 2,
        nutritionInfo: {
          carbs: 13,
          protein: 1,
          fat: 10,
        },
        fmcCentsPrice: 80,
        fiatCentsPrice: 80,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
      {
        productId: "6717e83bb7a34cd092f5ed89",
        productPLU: "2150029-1",
        productDescription:
          "C 14g | P 16g | F 15g [%New%]\n\nChicken nuggets with honey mayo sauce. ",
        productName: "Mini chicken nuggets",
        imageURL:
          "https://resizer.deliverect.com/7bP7emN6y29KWypV3kD1jGpz9yT3KIW_9gpsjsDGN1k/rt:fill/g:ce/el:0/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2lrb25hLWJ1Y2tldC1wcm9kdWN0aW9uL2ltYWdlcy82NDEwNDk1NzFjZWNiYzZjMzNhNmM2NzUvbWluaSUyMGNoaWNrZW4lMjBudWdnZXRzLTY3MTM1MTJkMTVhMWRhMWVkNDcxY2E4OS5wbmc=",
        productCalories: 255,
        quantity: 1,
        nutritionInfo: {
          carbs: 14,
          protein: 16,
          fat: 15,
        },
        fmcCentsPrice: 130,
        fiatCentsPrice: 130,
        externalFiatCentsPrice: 0,
        remark: "",
        subItems: [],
      },
    ],
    totalFiatCentsPrice: 730,
    totalFmcCentsPrice: 730,
    totalFiatCentsPriceOriginal: 730,
    totalExternalFiatCentsPrice: 730,
    totalFmcCentsPriceOriginal: 730,
    orderStatus: "delivered",
    chargeStatus: "succeeded",
    chargeFailureReason: null,
    orderStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:51:51.308Z",
      },
      {
        previousStatus: "pending",
        newStatus: "accepted",
        timestamp: "2024-10-22T18:53:26.559Z",
      },
      {
        previousStatus: "accepted",
        newStatus: "preparing",
        timestamp: "2024-10-22T18:59:57.645Z",
      },
      {
        previousStatus: "preparing",
        newStatus: "prepared",
        timestamp: "2024-10-22T19:23:07.339Z",
      },
      {
        previousStatus: "prepared",
        newStatus: "ready_for_pickup",
        timestamp: "2024-10-22T19:23:07.340Z",
      },
      {
        previousStatus: "ready_for_pickup",
        newStatus: "in_delivery",
        timestamp: "2024-10-22T19:23:29.195Z",
      },
      {
        previousStatus: "in_delivery",
        newStatus: "delivered",
        timestamp: "2024-10-22T19:45:52.829Z",
      },
    ],
    chargeStatusHistory: [
      {
        previousStatus: null,
        newStatus: "pending",
        timestamp: "2024-10-22T18:51:51.308Z",
      },
      {
        previousStatus: "pending",
        newStatus: "succeeded",
        timestamp: "2024-10-22T18:52:03.009Z",
      },
    ],
    cutlery: false,
    timeslot: null,
    createdAt: "2024-10-22T18:51:51.308Z",
    updatedAt: "2024-10-22T19:45:53.167Z",
    fleetInfo: null,
  },
];
