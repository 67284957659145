import styled from "styled-components";
import { FlexContainer } from "styled/Box";

export const UploadContainer = styled(FlexContainer)`
  width: 280px;
  height: 280px;
`;

export const ButtonsContainer = styled(FlexContainer)`
  position: absolute;
  right: 32px;
  top: 36px;
`;
