import styled from "styled-components";
import { FlexContainer } from "styled/Box";
import { theme } from "theme";

export const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px;
  gap: 16px;
  flex-wrap: wrap;
`;

export const PageWrapper = styled(FlexContainer)`
  min-height: 100%;
`;

export const TableShadowContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: ${theme.shadow.table};
  border-radius: ${theme.borderRadius.base};
  margin: 24px 0;
`;
