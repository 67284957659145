// import { ItemTitleButton } from "./styled";

import { Link } from "react-router-dom";

export const columns = [
  {
    title: "Menu Item",
    dataIndex: "name",
    key: "name",
    render: (text: string, record) => (
      <Link to={`/loop/analytics/menus-analytics/${record.id}`}> {text}</Link>
    ),
  },
  {
    title: "Total Sold",
    dataIndex: "totalSold",
    key: "totalSold",
    render: (text: string) => `KWD ${text}`,
  },
  {
    title: "Items sold individually",
    dataIndex: "soldIndividualy",
    key: "soldIndividualy",
    render: (text: string) => `KWD ${text}`,
  },
];
