import styled from "styled-components";
import { FlexContainer } from "styled/Box";

export const PageWrapper = styled(FlexContainer)`
  min-height: 100%;
`;

export const SelectContainer = styled.div`
  min-width: 200px;
`;
