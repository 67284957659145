import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";
import { KitchenOrderTab, kitchenTabsStatuses } from "types/kitchen";

export const kitchenOrdersUrlBuilder = {
  getNewOrders: ({
    siteId,
    query,
  }: {
    siteId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(`${endpoints.kitchen}${siteId}/orders`, {
      statuses: kitchenTabsStatuses[KitchenOrderTab.NEW],
      ...query,
    });
  },
  getInProgressOrders: ({
    siteId,
    query,
  }: {
    siteId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(`${endpoints.kitchen}${siteId}/orders`, {
      statuses: kitchenTabsStatuses[KitchenOrderTab.INPROGRESS],
      ...query,
    });
  },
  getFinishedOrders: ({
    siteId,
    query,
  }: {
    siteId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(`${endpoints.kitchen}${siteId}/orders`, {
      statuses: kitchenTabsStatuses[KitchenOrderTab.FINISHED],
      ...query,
    });
  },
  getScheduledOrders: ({
    siteId,
    query,
  }: {
    siteId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(`${endpoints.kitchen}${siteId}/orders`, {
      statuses: kitchenTabsStatuses[KitchenOrderTab.SCHEDULED],
      ...query,
    });
  },
  getOrderDetails: ({
    orderId,
    siteId,
  }: {
    orderId: string;
    siteId: string;
  }): string => {
    return `${endpoints.kitchen}${siteId}/orders/${orderId}`;
  },
  updateOrder: ({
    orderId,
    siteId,
  }: {
    orderId: string;
    siteId: string;
  }): string => {
    return `${endpoints.kitchen}${siteId}/orders/${orderId}`;
  },
};
