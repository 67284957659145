import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const ArrowUpwardIcon: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_10120_921)">
      <path
        d="M8.66667 12.6666V5.21995L11.92 8.47328C12.18 8.73328 12.6067 8.73328 12.8667 8.47328C13.1267 8.21328 13.1267 7.79328 12.8667 7.53328L8.47334 3.13995C8.21334 2.87995 7.79334 2.87995 7.53334 3.13995L3.13334 7.52661C2.87334 7.78661 2.87334 8.20661 3.13334 8.46661C3.39334 8.72661 3.81334 8.72661 4.07334 8.46661L7.33334 5.21995V12.6666C7.33334 13.0333 7.63334 13.3333 8.00001 13.3333C8.36667 13.3333 8.66667 13.0333 8.66667 12.6666Z"
        fill={fill || "white"}
      />
    </g>
    <defs>
      <clipPath id="clip0_10120_921">
        <rect width="16" height="16" fill={fill || "white"} />
      </clipPath>
    </defs>
  </svg>
);
