// Lib
import { FC, useCallback, useEffect, useRef, useState } from "react";
// Api
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
import {
  useLazyGetLoopKitchenSettingsQuery,
  useUpdateLoopKitchenSettingsMutation,
} from "rtkQuery/query/kitchenSettingsAPI";
// Hooks
import { useDebounce, useNotification } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { SettingsFormState } from "../types";
// Helpers
import { createStateSettings } from "../helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Select } from "components/Form";
import { SettingsForm } from "../components";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";

import { notificationMessage, SettingsKeys } from "../config";

export const Settings: FC = () => {
  const companyId = useAppSelector(getUserCompanyId);

  const { openNotification } = useNotification();

  const tempSettings = useRef<SettingsFormState | null>(null);

  const [locationIdValue, setLocationIdValue] = useState<string>("");

  const [settings, setSettings] = useState<SettingsFormState | null>(null);

  const {
    data: locationsData,
    isLoading: isLocationsLoading,
    error: locationsError,
  } = useGetLocationsQuery({ companyId }, { refetchOnMountOrArgChange: true });

  const [getSettings, { isFetching }] = useLazyGetLoopKitchenSettingsQuery();
  const [updateSettings] = useUpdateLoopKitchenSettingsMutation();

  const debouncedPreparationTime = useDebounce(
    settings?.[SettingsKeys.DefaultPreparationTime]?.value,
    1500,
  );

  const handleGetSettings = async () => {
    try {
      const settingsData = await getSettings({
        siteId: locationIdValue,
        companyId,
      }).unwrap();

      const settings = createStateSettings(settingsData);

      tempSettings.current = settings;

      setSettings(settings);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  useEffect(() => {
    if (!locationIdValue) {
      return;
    }

    handleGetSettings();
  }, [locationIdValue]);

  useEffect(() => {
    if (locationsError) {
      errorHandler({ error: locationsError, openNotification });
    }
  }, [locationsError]);

  const handleLocationChange = (id: string) => {
    setLocationIdValue(id);
  };

  const locations =
    locationsData?.map(el => ({ label: el.name, value: el.id })) || [];

  const handleBooleanValueSettingsChange = useCallback(
    async (key: SettingsKeys, value: boolean) => {
      try {
        setSettings(prev => ({
          ...prev,
          [key]: { value, isLoading: true },
        }));

        await updateSettings({
          companyId,
          siteId: locationIdValue,
          data: { [key]: value },
        }).unwrap();

        setSettings(prev => ({
          ...prev,
          [key]: { ...prev[key], isLoading: false },
        }));

        openNotification({ message: notificationMessage[key] });
      } catch (error) {
        errorHandler({ error, openNotification });

        setSettings(prev => ({
          ...prev,
          [key]: { value: !value, isLoading: false },
        }));
      }
    },
    [locationIdValue],
  );

  const handleSettingsChange = useCallback(
    async (key: SettingsKeys, value) => {
      const prevValue = settings[key]?.value;

      try {
        setSettings(prev => {
          return { ...prev, [key]: { value, isLoading: true } };
        });

        await updateSettings({
          companyId,
          siteId: locationIdValue,
          data: { [key]: value },
        }).unwrap();

        setSettings(prev => ({
          ...prev,
          [key]: { ...prev[key], isLoading: false },
        }));

        openNotification({ message: notificationMessage[key] });
      } catch (error) {
        errorHandler({ error, openNotification });

        setSettings(prev => ({
          ...prev,
          [key]: { value: prevValue, isLoading: false },
        }));
      }
    },
    [locationIdValue, settings],
  );

  const handleChangeInputValue = useCallback(
    async (isIncrement: boolean, key: SettingsKeys) => {
      setSettings(prev => {
        const value = prev[key].value;

        if (typeof value !== "number") {
          return;
        }

        if (!isIncrement && value <= 0) {
          return prev;
        }

        return {
          ...prev,
          [key]: {
            ...prev[key],
            value: isIncrement ? value + 1 : value - 1,
          },
        };
      });
    },
    [locationIdValue],
  );

  const handleChangeInputValueManualy = useCallback(
    async (value: number, key: SettingsKeys) => {
      setSettings(prev => ({
        ...prev,
        [key]: {
          ...prev[key],
          value,
        },
      }));
    },
    [locationIdValue],
  );

  const handleUpdateInputSettings = async (
    value: number,
    prevValue: number,
    key: SettingsKeys,
  ) => {
    try {
      setSettings(prev => ({
        ...prev,
        [key]: {
          ...prev[key],
          isLoading: true,
        },
      }));

      await updateSettings({
        companyId,
        siteId: locationIdValue,
        data: { [key]: value },
      }).unwrap();

      tempSettings.current[key].value = value;

      setSettings(prev => ({
        ...prev,
        [key]: {
          ...prev[key],
          isLoading: false,
        },
      }));

      openNotification({ message: notificationMessage[key] });
    } catch (error) {
      errorHandler({ error, openNotification });

      setSettings(prev => ({
        ...prev,
        [key]: {
          value: prevValue,
          isLoading: false,
        },
      }));
    }
  };

  useEffect(() => {
    if (!debouncedPreparationTime) {
      return;
    }

    const prevValue =
      tempSettings.current?.[SettingsKeys.DefaultPreparationTime]?.value;

    if (debouncedPreparationTime !== prevValue) {
      handleUpdateInputSettings(
        debouncedPreparationTime,
        prevValue,
        SettingsKeys.DefaultPreparationTime,
      );
    }
  }, [debouncedPreparationTime]);

  return (
    <>
      <FlexContainer
        $fullwidth
        $padding="0 0 32px"
        $align="center"
        $justify="space-between"
        $wrap
        $gap={8}
      >
        <Typography.H1>Settings</Typography.H1>

        <Select
          style={{ width: 220 }}
          label="Location"
          loading={isLocationsLoading}
          value={locationIdValue}
          options={locations}
          onChange={handleLocationChange}
        />
      </FlexContainer>

      <SettingsForm
        isLoading={isFetching}
        settings={settings}
        handleSettingsChange={handleSettingsChange}
        handleBooleanValueSettingsChange={handleBooleanValueSettingsChange}
        handleChangeInputValueManualy={handleChangeInputValueManualy}
        handleChangeInputValue={handleChangeInputValue}
      />
    </>
  );
};
