import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const InventoryIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_9500_1999)">
      <path
        d="M5 5H7V6C7 7.1 7.9 8 9 8H15C16.1 8 17 7.1 17 6V5H19V10H21V5C21 3.9 20.1 3 19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H11V19H5V5ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3Z"
        fill="#646965"
      />
      <path
        d="M21.75 12.25C21.34 11.84 20.66 11.84 20.25 12.25L15.51 17L13.25 14.75C12.84 14.34 12.17 14.34 11.75 14.75C11.34 15.16 11.34 15.84 11.75 16.25L14.8 19.29C15.19 19.68 15.82 19.68 16.21 19.29L21.74 13.75C22.16 13.34 22.16 12.66 21.75 12.25Z"
        fill="#646965"
      />
    </g>
    <defs>
      <clipPath id="clip0_9500_1999">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
