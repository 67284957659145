// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  GetKitchenSettingsResponse,
  UpdateKitchenSettingsRequest,
  UpdateLoopKitchenSettingsRequest,
} from "types/kitchen";
// Helpers
import { kitchenSettingsUrlBuilder } from "./urlBuilder/kitchenSettings";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";

export const kitchenSettingsAPI = createApi({
  reducerPath: "kitchenSettingsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: build => ({
    getKitchenSettings: build.query<
      GetKitchenSettingsResponse,
      { siteId: string }
    >({
      query: ({ siteId }) => ({
        url: kitchenSettingsUrlBuilder.getSettings(siteId),
        method: "GET",
      }),
    }),
    updateKitchenSettings: build.mutation<void, UpdateKitchenSettingsRequest>({
      query: ({ siteId, data }) => ({
        url: kitchenSettingsUrlBuilder.updateSettings(siteId),
        method: "PATCH",
        data,
      }),
    }),
    getLoopKitchenSettings: build.query<
      GetKitchenSettingsResponse,
      { siteId: string; companyId: string }
    >({
      query: ({ siteId, companyId }) => ({
        url: kitchenSettingsUrlBuilder.getLoopKitchenSettings({
          siteId,
          companyId,
        }),
        method: "GET",
      }),
    }),
    updateLoopKitchenSettings: build.mutation<
      void,
      UpdateLoopKitchenSettingsRequest
    >({
      query: ({ siteId, companyId, data }) => ({
        url: kitchenSettingsUrlBuilder.updateLoopKitchenSettings({
          siteId,
          companyId,
        }),
        method: "PATCH",
        data,
      }),
    }),
  }),
});

export const {
  useGetKitchenSettingsQuery,
  useLazyGetLoopKitchenSettingsQuery,
  useUpdateLoopKitchenSettingsMutation,
  useLazyGetKitchenSettingsQuery,
  useUpdateKitchenSettingsMutation,
} = kitchenSettingsAPI;
