import { ScheduleDay, ScheduleDayLabels } from "types/menuScheduling";

export const selectDayButtonValues = [
  { label: ScheduleDayLabels[ScheduleDay.Monday], value: ScheduleDay.Monday },
  { label: ScheduleDayLabels[ScheduleDay.Tuesday], value: ScheduleDay.Tuesday },
  {
    label: ScheduleDayLabels[ScheduleDay.Wednesday],
    value: ScheduleDay.Wednesday,
  },
  {
    label: ScheduleDayLabels[ScheduleDay.Thursday],
    value: ScheduleDay.Thursday,
  },
  { label: ScheduleDayLabels[ScheduleDay.Friday], value: ScheduleDay.Friday },
  {
    label: ScheduleDayLabels[ScheduleDay.Saturday],
    value: ScheduleDay.Saturday,
  },
  { label: ScheduleDayLabels[ScheduleDay.Sunday], value: ScheduleDay.Sunday },
];
