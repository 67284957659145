// Lib
import { FC } from "react";
// Types
import { ModifierType } from "types/common";
import { KitchenOrderItem } from "types/kitchen";
// Styled
import { Typography } from "styled/Typography";
import { FlexContainer } from "styled/Box";
import { ModifierTitle, Note } from "../../styled";

interface OrderItemsListProps {
  orderItems: KitchenOrderItem[];
}

export const OrderItemsList: FC<OrderItemsListProps> = ({ orderItems }) => {
  if (!orderItems?.length) {
    return null;
  }

  const productsList = orderItems.map(item => ({
    id: item.id,
    title: `${item?.quantity || 1} x ${item?.productName}`,
    note: item?.remark,
    modifiers:
      item?.modifiers?.map(modifier => ({
        id: modifier.id,
        title:
          modifier.product.modifierType === ModifierType.Addon
            ? `${modifier?.quantity || 1} x ${modifier?.product?.name}`
            : modifier?.product?.name,
      })) || [],
  }));

  return (
    <>
      {productsList.map(product => (
        <FlexContainer key={product?.id} $column $gap={8}>
          <Typography.Title>{product.title}</Typography.Title>

          {!!product.modifiers?.length && (
            <FlexContainer $column $gap={4}>
              {product?.modifiers.map(modifier => (
                <ModifierTitle key={`${modifier.id}+${product.id}`}>
                  {modifier.title}
                </ModifierTitle>
              ))}
            </FlexContainer>
          )}

          {!!product.note && (
            <Note>
              Note:{" "}
              <Typography.DescriptionThin as="span">
                {product.note}
              </Typography.DescriptionThin>
            </Note>
          )}
        </FlexContainer>
      ))}
    </>
  );
};
