import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const AddIcon: FC<SvgIconConstituentValues> = ({ fill, ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Placeholder" clipPath="url(#clip0_2932_12555)">
      <path
        id="Vector"
        d="M15 10.8332H10.8333V14.9998C10.8333 15.4582 10.4583 15.8332 9.99996 15.8332C9.54163 15.8332 9.16663 15.4582 9.16663 14.9998V10.8332H4.99996C4.54163 10.8332 4.16663 10.4582 4.16663 9.99984C4.16663 9.5415 4.54163 9.1665 4.99996 9.1665H9.16663V4.99984C9.16663 4.5415 9.54163 4.1665 9.99996 4.1665C10.4583 4.1665 10.8333 4.5415 10.8333 4.99984V9.1665H15C15.4583 9.1665 15.8333 9.5415 15.8333 9.99984C15.8333 10.4582 15.4583 10.8332 15 10.8332Z"
        fill={fill || "#3662B6"}
      />
    </g>
    <defs>
      <clipPath id="clip0_2932_12555">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
