import { styled } from "styled-components";
import { theme } from "theme";

export const TableShadowContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: ${theme.shadow.table};
  border-radius: ${theme.borderRadius.base};
  margin: 24px 0;
`;
