import { theme } from "theme";
import styled from "styled-components";

export const StyledGrid = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  @media (max-width: ${theme.breakpoints.xl}) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${theme.breakpoints.l}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 920px) {
    grid-template-columns: 1fr;
  }
`;
