import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const ordersUrlBuilder = {
  getOrders: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/orders/`,
      query,
    );
  },
  getOrder: (id: string): string => {
    return `${endpoints.orders}${id}`;
  },
};
