import { ChartLegendConfig, ChartLinesConfig } from "./types";

export const mainChartLineConfig: ChartLinesConfig = {
  dataKey: "current",
  stroke: "rgba(26, 134, 54, 1)",
  activeDot: { r: 6, stroke: "rgba(26, 134, 54, 1)", fill: "#fff" },
};

export const prevChartLineConfig: ChartLinesConfig = {
  dataKey: "prev",
  stroke: "rgba(142, 143, 143, 1)",
  activeDot: { r: 0 },
  strokeDasharray: "5 5",
};

export const mainLegendConfig: ChartLegendConfig = {
  title: "",
  color: "rgba(26, 134, 54, 1)",
};

export const prevLegendConfig: ChartLegendConfig = {
  title: "",
  color: "rgba(142, 143, 143, 1)",
  textColor: "rgba(142, 143, 143, 1)",
};

export const analyticsChartLocationsColors = [
  "rgba(26, 134, 54, 1)",
  "rgba(54, 98, 182, 1)",
  "rgba(250, 80, 69, 1)",
  "rgba(255, 199, 69, 1)",
  "rgba(0, 46, 7, 1)",
  "rgba(87, 188, 220, 1)",
  "rgb(71, 206, 4)",
];
