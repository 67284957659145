import styled from "styled-components";
import { theme } from "theme";
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { MoreVertIcon } from "icons";
import { Badge } from "components/Badge";

interface WrapperProps {
  checked: boolean;
  disabled: boolean;
}

export const Wrapper = styled(FlexContainer)<WrapperProps>`
  border-radius: ${theme.borderRadius.base};
  border: 1px solid ${theme.color.borderLight};

  ${({ checked }) =>
    checked &&
    `border-color: ${theme.color.brand}; 
    background: ${theme.color.background.brand};`}

  ${({ disabled }) => disabled && `background: ${theme.color.background.gray};`}

  position: relative;
`;

export const Image = styled.img`
  display: block;
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: ${theme.borderRadius.base};
`;

export const Title = styled(Typography.Title)`
  font-size: ${theme.fontSize.subheading};
  font-weight: ${theme.lineHeight.md};
  color: ${theme.color.text.primary2};
  width: 100%;
`;

export const Description = styled(Typography.DescriptionThin)`
  height: 40px;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Param = styled(Title)`
  font-size: ${theme.fontSize.md};
  width: auto;
`;

export const More = styled(MoreVertIcon)`
  cursor: pointer;
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const DisableBadgeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
`;

export const BadgeContainer = styled(FlexContainer)`
  /* height: 24px; */
  flex-wrap: wrap;
`;

export const ImagePlaceholder = styled.div`
  display: block;
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: ${theme.borderRadius.base};
`;

export const ModifierItem = styled.div`
  border: 1px solid ${theme.color.borderLight};
  border-radius: ${theme.borderRadius.base};
  padding: 7px 12px;
`;

export const StyledLabelBadgeWrapper = styled(DisableBadgeWrapper)``;

export const BadgesWrapper = styled.div`
  position: absolute;
  top: -15px;
  left: -15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
`;

export const EditItemToolsContainer = styled(FlexContainer)`
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  @media (max-width: 480px) {
    position: absolute;

    gap: 11px;

    top: 12px;
    right: 7px;
  }
`;
