import { endpoints } from "consts";

export const kitchenLocationsUrlBuilder = {
  getLocationChannelLinks: (siteId: string) => {
    return `${endpoints.kitchen}${siteId}/channels/`;
  },
  updateChannelLink: (id: string, siteId: string): string => {
    return `${endpoints.kitchen}${siteId}/channels/${id}/`;
  },
};
