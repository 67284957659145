// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  GetKitchenOrderDetailsResponse,
  GetKitchenOrdersDetailsRequest,
  GetKitchenOrdersListRequest,
  GetKitchenOrdersResponse,
  UpdateKitchenOrderRequest,
} from "types/kitchen";
// Helpers
import { kitchenOrdersUrlBuilder } from "./urlBuilder/kitchenOrders";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";

export const kitchenOrdersAPI = createApi({
  reducerPath: "kitchenOrdersAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["new", "inProgress", "finished", "scheduled", "details"],
  endpoints: build => ({
    getNewOrders: build.query<
      GetKitchenOrdersResponse,
      GetKitchenOrdersListRequest
    >({
      query: ({ siteId, query }) => {
        return {
          url: kitchenOrdersUrlBuilder.getNewOrders({ siteId, query }),
          method: "GET",
        };
      },
      providesTags: ["new"],
    }),
    getInprogressOrders: build.query<
      GetKitchenOrdersResponse,
      GetKitchenOrdersListRequest
    >({
      query: ({ siteId, query }) => {
        return {
          url: kitchenOrdersUrlBuilder.getInProgressOrders({
            siteId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["inProgress"],
    }),
    getFinishedOrders: build.query<
      GetKitchenOrdersResponse,
      GetKitchenOrdersListRequest
    >({
      query: ({ siteId, query }) => {
        return {
          url: kitchenOrdersUrlBuilder.getFinishedOrders({
            siteId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["finished"],
    }),
    getScheduledOrders: build.query<
      GetKitchenOrdersResponse,
      GetKitchenOrdersListRequest
    >({
      query: ({ siteId, query }) => {
        return {
          url: kitchenOrdersUrlBuilder.getScheduledOrders({ siteId, query }),
          method: "GET",
        };
      },
      providesTags: ["scheduled"],
    }),
    getOrderDetails: build.query<
      GetKitchenOrderDetailsResponse,
      GetKitchenOrdersDetailsRequest
    >({
      query: ({ selectedOrder, siteId }) => {
        return {
          url: kitchenOrdersUrlBuilder.getOrderDetails({
            siteId,
            orderId: selectedOrder.id,
          }),
          method: "GET",
        };
      },
      providesTags: ["details"],
    }),
    updateOrder: build.mutation<void, UpdateKitchenOrderRequest>({
      query: ({ orderId, siteId, data }) => {
        return {
          url: kitchenOrdersUrlBuilder.updateOrder({ orderId, siteId }),
          method: "PATCH",
          data,
        };
      },
    }),
    updateNewOrder: build.mutation<void, UpdateKitchenOrderRequest>({
      query: ({ orderId, siteId, data }) => {
        return {
          url: kitchenOrdersUrlBuilder.updateOrder({ orderId, siteId }),
          method: "PATCH",
          data,
        };
      },
    }),
  }),
});

export const {
  useGetNewOrdersQuery,
  useGetInprogressOrdersQuery,
  useGetFinishedOrdersQuery,
  useGetScheduledOrdersQuery,
  useGetOrderDetailsQuery,
  useUpdateOrderMutation,
  useUpdateNewOrderMutation,
} = kitchenOrdersAPI;
