import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const menusUrlBuilder = {
  getMenus: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/menus/`,
      query,
    );
  },
  getKitchenMenus: (id: string): string => {
    return `${endpoints.kitchen}${id}/menus/`;
  },

  deleteMenus: (): string => {
    return `${endpoints.menus}`;
  },
  createMenu: (companyId: string): string => {
    return `${endpoints.companies}${companyId}/menus/`;
  },
  publishMenu: (id: string): string => {
    return `${endpoints.menus}${id}/publish/`;
  },
  getMenu: (menuId: string): string => {
    return `${endpoints.menus}${menuId}/`;
  },
  updateMenu: (menuId: string): string => {
    return `${endpoints.menus}${menuId}/`;
  },
};
