// Icons
import { LogoIcon } from "icons";
// Components
import { LoginForm } from "./components";
// Styles
import { ContentContainer, PageWrapper } from "./styled";

export const Login = () => {
  return (
    <PageWrapper>
      <ContentContainer>
        <LogoIcon />

        <LoginForm />
      </ContentContainer>
    </PageWrapper>
  );
  return;
};
