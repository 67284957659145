import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const CloseBlackIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_83_26559)">
      <path
        d="M16.7782 7.22943C16.4824 6.93364 16.0045 6.93364 15.7088 7.22943L12 10.9306L8.29124 7.22184C7.99545 6.92605 7.51763 6.92605 7.22184 7.22184C6.92605 7.51763 6.92605 7.99545 7.22184 8.29124L10.9306 12L7.22184 15.7088C6.92605 16.0045 6.92605 16.4824 7.22184 16.7782C7.51763 17.0739 7.99545 17.0739 8.29124 16.7782L12 13.0694L15.7088 16.7782C16.0045 17.0739 16.4824 17.0739 16.7782 16.7782C17.0739 16.4824 17.0739 16.0045 16.7782 15.7088L13.0694 12L16.7782 8.29124C17.0664 8.00303 17.0664 7.51763 16.7782 7.22943Z"
        fill="#646965"
      />
    </g>
    <defs>
      <clipPath id="clip0_83_26559">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
