// Lib
import { FC, ReactNode } from "react";
import { useDroppable } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
// Hooks
import { useViewport } from "hooks";
// Types
import { MenuCategoryItemType } from "types/menus";
// Theme
import { theme } from "theme";
// Helpers
import { generateUniqueId } from "helpers/dataHelpers";
import { createDropDownOptions } from "helpers/createDropDownOptions";
// Icons
import {
  EyeInvisibleIcon,
  ImportExportIcon,
  MenuIcon,
  MoreVertIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "icons";
// Components
import { Badge, DropDown, NoItemsContent } from "components";
// Styled
import { ContentBox, FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import { StyledGrid } from "./styled";

interface CategoryProps {
  categoryId: string;
  title: string;
  isPreview?: boolean;
  disabled: boolean;
  categoryItems: MenuCategoryItemType[];
  handleOpenCategoryModal?: (id: string) => void;
  handleOpenCategoryItemsModal?: (data: {
    categoryId: string;
    categoryName: string;
  }) => void;
  handleCategoryItemsData?: (id: string, items: MenuCategoryItemType[]) => void;
  handleDisableCategory?: (id: string, arg: boolean) => void;
  handleDeleteCategory?: (id: string) => void;
  children: ReactNode;
}

export const Category: FC<CategoryProps> = ({
  categoryId,
  disabled,
  title,
  isPreview,
  categoryItems,
  handleOpenCategoryModal,
  handleOpenCategoryItemsModal,
  handleCategoryItemsData,
  handleDisableCategory,
  handleDeleteCategory,
  children,
}) => {
  const { isTabletPortrait } = useViewport();
  // dnd start

  const { setNodeRef } = useDroppable({ id: categoryId });

  const handleSort = () => {
    const sortedData = [...categoryItems];

    const isSortedAscending =
      sortedData[0].order < sortedData[sortedData.length - 1].order;

    sortedData.sort((a, b) => {
      if (
        a.product.name.toLocaleLowerCase() < b.product.name.toLocaleLowerCase()
      ) {
        return isSortedAscending ? 1 : -1;
      }
      if (
        a.product.name.toLocaleLowerCase() > b.product.name.toLocaleLowerCase()
      ) {
        return isSortedAscending ? -1 : 1;
      }
      return 0;
    });

    if (sortedData[0].order === categoryItems[0].order) {
      handleCategoryItemsData(categoryId, sortedData.reverse());

      return;
    }

    handleCategoryItemsData(categoryId, sortedData);
  };

  const handleEditCategory = () => {
    handleOpenCategoryModal(categoryId);
  };

  const handleAddMenuItem = () => {
    handleOpenCategoryItemsModal({ categoryId, categoryName: title });
  };

  const categoryOptions = createDropDownOptions(
    isTabletPortrait
      ? [
          {
            id: generateUniqueId(),
            label: "Add menu item",
            Icon: PlusIcon,
            onClick: handleAddMenuItem,
          },
          {
            id: generateUniqueId(),
            label: "Edit",
            Icon: PencilIcon,
            onClick: handleEditCategory,
          },
          {
            id: generateUniqueId(),
            label: "Sort",
            Icon: ImportExportIcon,
            onClick: handleSort,
          },
          {
            id: generateUniqueId(),
            label: disabled ? "Enable Category" : "Disable category",
            Icon: EyeInvisibleIcon,
            onClick: () => handleDisableCategory(categoryId, !disabled),
          },
          {
            id: generateUniqueId(),
            label: "Delete category",
            Icon: TrashIcon,
            onClick: () => handleDeleteCategory(categoryId),
          },
        ]
      : [
          {
            id: generateUniqueId(),
            label: disabled ? "Enable Category" : "Disable category",
            Icon: EyeInvisibleIcon,
            onClick: () => handleDisableCategory(categoryId, !disabled),
          },
          {
            id: generateUniqueId(),
            label: "Delete category",
            Icon: TrashIcon,
            onClick: () => handleDeleteCategory(categoryId),
          },
        ],
  );

  return (
    <ContentBox ref={setNodeRef} id={categoryId} $column $gap={20}>
      <FlexContainer $fullwidth $align="center" $justify="space-between">
        <FlexContainer $align="center" $gap={8}>
          <Typography.H2>{title}</Typography.H2>

          {!!disabled && <Badge title="Disabled" />}
        </FlexContainer>

        {!isPreview && (
          <FlexContainer $gap={8}>
            {!isTabletPortrait && (
              <>
                <Button.Form
                  type="primary"
                  icon={<PlusIcon fill="white" />}
                  onClick={handleAddMenuItem}
                >
                  Add menu item
                </Button.Form>

                <Button.Form icon={<PencilIcon />} onClick={handleEditCategory}>
                  Edit
                </Button.Form>

                <Button.Form
                  icon={<ImportExportIcon fill={theme.color.text.tertiary} />}
                  onClick={handleSort}
                >
                  Sort
                </Button.Form>
              </>
            )}

            <DropDown items={categoryOptions} trigger={["click"]}>
              <div>
                <Button.SquaredIcon
                  icon={<MoreVertIcon fill={theme.color.text.tertiary} />}
                />
              </div>
            </DropDown>
          </FlexContainer>
        )}
      </FlexContainer>

      <SortableContext
        id={categoryId}
        items={categoryItems}
        strategy={rectSortingStrategy}
        disabled={disabled}
      >
        <div ref={setNodeRef}>
          {!categoryItems?.length && (
            <NoItemsContent
              message="No menu item added"
              description="Added menu will displayed here"
              Icon={MenuIcon}
            />
          )}

          <StyledGrid>{children}</StyledGrid>
        </div>
      </SortableContext>
    </ContentBox>
  );
};
