import { AccentText } from "components/Table/styled";
import { dateTransform } from "helpers/dataHelpers";

export const createColumns = (period: "month" | "day") => {
  const isMonth = period === "month";

  return [
    {
      title: "Date",
      dataIndex: "period",
      key: "period",
      render: date => (
        <AccentText>
          {dateTransform({
            date,
            format: isMonth ? "YYYY-MM" : "YYYY-MM-DD",
          })}
        </AccentText>
      ),
    },
    {
      title: "Revenue",
      dataIndex: "totalRevenue",
      key: "totalRevenue",
      render: text => `KWD ${text}`,
    },
    {
      title: "Discount total",
      dataIndex: "totalDiscount",
      key: "totalDiscount",
      render: text => `KWD ${text}`,
    },
    {
      title: "Delivery cost",
      dataIndex: "totalDeliveryCost",
      key: "totalDeliveryCost",
      render: text => `KWD ${text}`,
    },
  ];
};

export const revenueOptions = [
  {
    value: "month",
    label: "Month",
  },
  {
    value: "day",
    label: "Day",
  },
];
