// Lib
import { FC } from "react";
// Types
import { CheckboxProps as AntCheckboxProps } from "antd";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { SvgIconConstituentValues } from "types/common";
// Components
import { Checkbox } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { MockedImage, TagImage } from "./styled";
import { DiningIcon } from "icons";

interface CheckboxWithTheImageProps extends AntCheckboxProps {
  label: string;
  imageUrl?: string;
  field: ControllerRenderProps<any, any>;
  disabled: boolean;
}

export const CheckboxWithTheImage: FC<CheckboxWithTheImageProps> = ({
  label,
  imageUrl,
  field,
  disabled,
}) => {
  const image = imageUrl ? (
    <TagImage src={imageUrl} alt="label" />
  ) : (
    <MockedImage>
      <DiningIcon />
    </MockedImage>
  );

  return (
    <FlexContainer $align="center" $justify="space-between">
      <FlexContainer $align="center">
        {image}
        <div style={{ marginLeft: 10 }}>{label}</div>
      </FlexContainer>
      <Checkbox
        {...field}
        checked={field.value}
        disabled={disabled}
        onChange={e => field.onChange(e.target.checked)}
      />
    </FlexContainer>
  );
};
