import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const modifiersUrlBuilder = {
  getModifiers: ({
    companyId,
    siteId,
    query,
  }: {
    companyId: string;
    siteId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/modifiers/sites/${siteId}`,
      query,
    );
  },
};
