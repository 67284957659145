import type { AxiosRequestConfig, AxiosError } from "axios";

import type { BaseQueryFn } from "@reduxjs/toolkit/query";

import axiosAPI from "./axios";

interface RequestParams {
  url: string;
  method: AxiosRequestConfig["method"];
  data?: AxiosRequestConfig["data"];
  params?: AxiosRequestConfig["params"];
  headers?: AxiosRequestConfig["headers"];
}

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" },
  ): BaseQueryFn<RequestParams, unknown, unknown> =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await axiosAPI({
        url: `${baseUrl}/${url}`,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
