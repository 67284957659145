import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const ArrowDownIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5303 0.46967C13.2374 0.176777 12.7626 0.176777 12.4697 0.46967L7 5.93934L1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033L13.5303 1.53033C13.8232 1.23744 13.8232 0.762563 13.5303 0.46967Z"
      fill={props.fill || "#EFF7EE"}
    />
  </svg>
);
