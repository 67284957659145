// Lib
import { FC } from "react";
// Icons
import { LocationsIcon } from "icons";
// Components
import { Badge } from "components";
// Styled
import {
  DescriptionCopy,
  HeadingTitleContainer,
  IconContainer,
  TitleContainer,
  TitleCopy,
} from "./styled";

interface LocationItemTitleProps {
  title: string;
  main?: boolean;
  description?: string;
}

export const LocationItemTitle: FC<LocationItemTitleProps> = ({
  title,
  main,
  description,
}) => {
  return (
    <HeadingTitleContainer>
      <IconContainer>
        <LocationsIcon />
      </IconContainer>

      <TitleContainer $column>
        <TitleCopy>
          {title} {main && <Badge title="Main" />}
        </TitleCopy>

        {description && <DescriptionCopy>{description}</DescriptionCopy>}
      </TitleContainer>
    </HeadingTitleContainer>
  );
};
