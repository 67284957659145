import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const LogoutIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M9.16667 15C9.16667 16.6667 9.16667 17.5 5.83333 17.5C2.5 17.5 2.5 16.6667 2.5 10C2.5 3.33333 2.5 2.5 5.83333 2.5C9.16667 2.5 9.16667 3.33333 9.16667 5"
      stroke="#646965"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M9.1665 10H17.4998"
      stroke="#646965"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M14.1665 13.3333L17.4998 9.99999L14.1665 6.66666"
      stroke="#646965"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
