// Lib
import { FC } from "react";
// Types
import { OrderStatus, OrderStatusLabel } from "types/orders";
// Constants
import { orderStatusColors } from "consts";
// Styled
import { Dot, Text } from "./styled";
import { FlexContainer } from "styled/Box";

interface OrderMarkerProps {
  status: OrderStatus;
}

export const OrderDotStatus: FC<OrderMarkerProps> = ({ status }) => {
  const dotColor = orderStatusColors[status];
  return (
    <FlexContainer $align="center" $margin="0 0 0 8px">
      <Dot $color={dotColor} />

      <Text>{OrderStatusLabel[status]}</Text>
    </FlexContainer>
  );
};
