import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, array, date } from "yup";
import { ScheduleFormTypes } from "./types";

const schema = object().shape({
  timezone: string().nullable().required("Timezone is required"),
  menuIds: string().nullable().required("Menu is required"),
  sitesIds: array()
    .of(string())
    .required("At least one location is required")
    .min(1, "At least one location is required"),
  channelIds: array()
    .of(string())
    .required("At least one channel is required")
    .min(1, "At least one channel is required"),
  publishTimes: array()
    .of(
      object().shape({
        days: array()
          .of(string())
          .required("At least one day of the week is required")
          .min(1, "At least one day of the week is required"),
        time: date().required("Time is required"),
      }),
    )
    .required("Publish Time is required")
    .min(1, "At least one Publish Time is required"),
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const resolver = yupResolver<ScheduleFormTypes>(schema);
