import { Badge } from "components";
import { MONTH_DAY_YEAR_HOURS_MINUTES_DATE } from "consts";
import { dateTransform, getFullName } from "helpers/dataHelpers";
import { theme } from "theme";
import { EFilterDateLabel, EFilterDateValue } from "types/common";
import {
  InventoryStockHistory,
  InventoryFilterNames,
  InventoryFilterTypes,
  InventoryUserDetails,
} from "types/inventory";
import {
  FilterField,
  FilterFieldDropdownOption,
  FilterOption,
} from "types/tableFilters";

export const columns = [
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",

    sorter: true,
    render: (date: string) =>
      dateTransform({ date, format: MONTH_DAY_YEAR_HOURS_MINUTES_DATE }),
  },
  {
    title: "User/Order Number",
    dataIndex: "user",
    key: "user",
    render: (user: InventoryUserDetails, record: InventoryStockHistory) =>
      user?.id ? (
        <Badge
          textColor={theme.color.text.primary2}
          title={getFullName({
            firstName: user?.firstName,
            lastName: user?.lastName,
          })}
        />
      ) : (
        record?.order
      ),
  },
  {
    title: "Change",
    dataIndex: "quantityChange",
    key: "quantityChange",
    width: 150,
  },
  {
    title: "Closing",
    dataIndex: "remainingQuantity",
    key: "remainingQuantity",
    width: 150,
    render: (quantity: number) => (
      <span style={{ color: quantity < 1 && theme.color.error }}>
        {quantity}
      </span>
    ),
  },
];

const dateTimeDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: EFilterDateValue.TODAY,
    label: EFilterDateLabel[EFilterDateValue.TODAY],
  },
  {
    type: EFilterDateValue.YESTERDAY,
    label: EFilterDateLabel[EFilterDateValue.YESTERDAY],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_7_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_7_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_30_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_30_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_90_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_90_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_12_MONTHS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_12_MONTHS],
  },
  {
    type: EFilterDateValue.CUSTOM,
    label: EFilterDateLabel[EFilterDateValue.CUSTOM],
  },
];

const outOfStockDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: "in_stock",
    label: "Yes",
  },
  {
    type: "out_of_stock",
    label: "No",
  },
];

export const filtersList: FilterOption[] = [
  {
    key: InventoryFilterTypes.date,
    label: InventoryFilterNames[InventoryFilterTypes.date],
    field: FilterField.DATE_RANGE,
    options: dateTimeDropdownFields,
  },
  {
    key: InventoryFilterTypes.outOfStock,
    label: InventoryFilterNames[InventoryFilterTypes.outOfStock],
    field: FilterField.SELECT,
    options: outOfStockDropdownFields,
  },
];
