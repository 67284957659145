import { UrlQueryParams } from "./common";

export enum OperationReportFilterTypes {
  date = "date",
  types = "types",
  sites = "sites",
  deliveryChannel = "deliveryChannel",
  statuses = "statuses",
}

export enum OperationReportFilterNames {
  date = "Operation reports",
  types = "Operation type",
  sites = "Location",
  deliveryChannel = "Delivery channel",
  statuses = "Status",
}

export enum OperationLogStatus {
  SUCCESS = "success",
  FAILED = "failed",
  ABORTED = "aborted",
  AWAIT = "await",
  RUNNING = "running",
  UNKNOWN = "unknown",
}

export enum OperationLogStatusLabel {
  success = "Success",
  failed = "Failed",
  aborted = "Aborted",
  await = "Await",
  running = "Running",
  unknown = "Unknown",
}

export enum OperationLogType {
  PUBLISH_MENU = "publish_menu",
  CREATE_MENU = "create_menu",
  UPDATE_MENU = "update_menu",
  UPDATE_ORDER = "update_order",
  ACTIVATE_CHANNEL = "activate_channel",
  DEACTIVATE_CHANNEL = "deactivate_channel",
  SNOOZE_REPORT = "snooze_report",
  UN_SNOOZE_REPORT = "un_snooze_report",
  PRODUCT_SNOOZE = "product_snooze",
  PRODUCT_UNSNOOZE = "product_unsnooze",
  PRODUCT_SYNC = "site_product_sync",
  POS_EMPLOYEE = "active_pos_employee",
  CREATE_SITE = "create_site",
  UPDATE_SITE = "update_site",
  UPDATE_PRODUCT = "update_product",
  EXPORT = "export",
}

export enum OperationLogTypeLabel {
  publish_menu = "Publish menu",
  create_menu = "Create menu",
  update_menu = "Update menu",
  update_order = "Update order",
  activate_channel = "Activate channel",
  deactivate_channel = "Deactivate channel",
  snooze_report = "Snooze",
  un_snooze_report = "Unsnooze",
  product_snooze = "Snooze",
  product_unsnooze = "Unsnooze",
  site_product_sync = "Product Sync",
  active_pos_employee = "Active POS Employee",
  create_site = "Create location",
  update_site = "Update location",
  update_product = "Update product",
  export = "Export",
}

export interface GetOperationReportsRequest {
  query: UrlQueryParams;
}

export interface OperationReportChannel {
  id: string;
  name: string;
}
export interface OperationReportLocation {
  id: string;
  name: string;
}

export interface OperationReportDto {
  id: string;
  type: OperationLogType;
  status: OperationLogStatus;
  createdAt: string;
  userId: string;
  channel: OperationReportChannel;
  sites: OperationReportLocation[];
}

export interface GetOperationReportsResponse {
  data: OperationReportDto[];
  page: number;
  size: number;
  total: number;
  totalPages: number;
}

export interface OperationReportMenuItem {
  name: string;
  plu: string;
  id: string;
  snoozedUntil: string;
  snoozedAt: string;
  updatedBy: string;
}

export interface OperationReportDetailsData {
  name?: string;
  menuItems?: OperationReportMenuItem[];
  summary?: {
    products?: {
      inserted?: 0;
      removed?: 0;
    };
    message: string;
  };
  insertedItems?: {
    name: string;
    plu: string;
    price: string;
  }[];
  isHomeSite?: boolean;
  country?: string;
  street?: string;
  number?: string;
  city?: string;
  postcode?: string;
  phoneCode?: string;
  phoneNumber?: string;
  email?: string;
  simphonyNodeId?: string;
  lat?: string;
  lng?: string;
}

export interface GetOperationReportDetailsResponse {
  id: string;
  type: OperationLogType;
  status: OperationLogStatus;
  data: OperationReportDetailsData | null;
  details: Record<string, string | number>;
  message: null | string;
  createdAt: string;
  user: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    fullName?: string;
  };
  channel: OperationReportChannel;
  sites: OperationReportLocation[];
}

export interface GetExportSnoozeReportRequest {
  query: UrlQueryParams;
}

export interface GetExportSnoozeReportData {
  id: string;
  userId: string;
  type: OperationLogType;
  status: OperationLogStatus;
  channelName: string;
  siteName: string;
  operationStart: string;
  itemName: string;
  snoozeStart: string | null;
  snoozeEnd: string | null;
  plu: string;
}
