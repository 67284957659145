import styled from "styled-components";
import { FlexContainer } from "styled/Box";

export const LoaderContainer = styled(FlexContainer)`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
