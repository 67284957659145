// Lib
import { FC, useCallback, useEffect, useMemo, useState } from "react";
// Api
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
import { useGetMenusQuery } from "rtkQuery/query/menusAPI";
import {
  useGetMenusScheduleCalendarQuery,
  useGetMenusScheduleListQuery,
} from "rtkQuery/query/menuSchedulingAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useNotification } from "hooks";
// Actions
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
import { getTableFilters } from "rtkQuery/selectors/tableFilters";
// Types
import { TabsProps } from "antd";
import { ETable } from "types/tableFilters";
import { MenusSchedule } from "types/menuScheduling";
// Theme
// Constants
// Helpers
import { isMatch } from "helpers/dataHelpers";
import { getFiltersQuery } from "helpers/tableFiltersHelpers";
import { createFilters, filtersList } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
// Layouts
// Components
import { Tabs } from "components";
import { MenuScheduling, Overview, ScheduleModal } from "./components";
// Styled
import { Typography } from "styled/Typography";
import { FlexContainer } from "styled/Box";

export const Schedule: FC = () => {
  const { openNotification } = useNotification();

  const companyId = useAppSelector(getUserCompanyId);
  const filters = useAppSelector(getTableFilters);

  const [query, setQuery] = useState({});

  const queryFilters = getFiltersQuery(filters[ETable.MenuScheduling]);

  //to prevent refetch on each state change
  useEffect(() => {
    if (isMatch(queryFilters, query)) return;

    setQuery(queryFilters);
  }, [filters]);

  const [scheduleModal, setScheduleModal] = useState<MenusSchedule | boolean>(
    false,
  );

  const { data: locationsData, error: locationsError } = useGetLocationsQuery(
    { companyId },
    { skip: !companyId, refetchOnMountOrArgChange: true },
  );

  const { data: menusData, error: menusError } = useGetMenusQuery(
    { companyId },
    { refetchOnMountOrArgChange: true, skip: !companyId },
  );

  const {
    data: calendarData,
    isFetching: isCalendarDataLoading,
    error: calendarDataError,
  } = useGetMenusScheduleCalendarQuery(
    { companyId, query },
    { refetchOnMountOrArgChange: true, skip: !companyId },
  );

  const {
    data: schedulingData,
    isFetching: isSchedulingDataLoading,
    error: schedulingError,
  } = useGetMenusScheduleListQuery(
    { companyId, query },
    { refetchOnMountOrArgChange: true, skip: !companyId },
  );

  useEffect(() => {
    if (calendarDataError) {
      errorHandler({ error: calendarDataError, openNotification });
    }
  }, [calendarDataError]);

  useEffect(() => {
    if (schedulingError) {
      errorHandler({ error: schedulingError, openNotification });
    }
  }, [schedulingError]);

  useEffect(() => {
    if (locationsError) {
      errorHandler({ error: locationsError, openNotification });
    }
  }, [locationsError]);

  useEffect(() => {
    if (menusError) {
      errorHandler({ error: menusError, openNotification });
    }
  }, [menusError]);

  const [activeTab, setActiveTab] = useState("Overview");

  const handleOpenScheduleModal = useCallback((value: true | MenusSchedule) => {
    setScheduleModal(value);
  }, []);

  const handleCloseScheduleModal = useCallback(() => {
    setScheduleModal(false);
  }, []);

  const onEventClick = useCallback(
    (eventId: string) => {
      const data = schedulingData?.find(schedule => schedule.id === eventId);

      if (data) {
        handleOpenScheduleModal(data);
      }
    },
    [schedulingData],
  );

  const filtersData = useMemo(
    () => createFilters({ locationsData, filtersList, menusData }),
    [locationsData, menusData],
  );

  const items: TabsProps["items"] = [
    {
      key: "Overview",
      label: "Overview",
      children: (
        <Overview
          isActive={activeTab === "Overview"}
          isLoading={isCalendarDataLoading}
          filtersData={filtersData}
          data={calendarData}
          onEventClick={onEventClick}
        />
      ),
    },
    {
      key: "Menu scheduling",
      label: "Menu scheduling",
      children: (
        <MenuScheduling
          isLoading={isSchedulingDataLoading}
          filtersData={filtersData}
          data={schedulingData}
          handleOpenScheduleModal={handleOpenScheduleModal}
        />
      ),
    },
  ];

  return (
    <>
      <FlexContainer $margin="0 0 24px">
        <Typography.H1>Schedule</Typography.H1>
      </FlexContainer>

      <Tabs
        activeKey={activeTab}
        items={items}
        onChange={activeKey => setActiveTab(activeKey)}
      />

      <ScheduleModal
        isLoading={false}
        scheduleModal={scheduleModal}
        locationsData={locationsData}
        menusData={menusData}
        onClose={handleCloseScheduleModal}
      />
    </>
  );
};
