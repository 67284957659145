import { MenuCategoryType } from "types/menus";

export const createDefaultValues = (data: MenuCategoryType | boolean) => {
  if (typeof data === "boolean") {
    return undefined;
  }

  return {
    id: data.id,
    name: data.name,
    description: data.description,
    image: data.image,
  };
};
