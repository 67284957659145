import { Dayjs } from "dayjs";
import { TableColoredCell, TimeTitle } from "./styled";

const createCell = (value: number) => {
  const isHightValue = value > 15;
  const isLowValue = value < 16 && value > 0;
  return (
    <TableColoredCell $high={isHightValue} $low={isLowValue} $value={value}>
      {value ? value : "-"}
    </TableColoredCell>
  );
};

export const createColumns = (ordersDate: Dayjs) => {
  return [
    {
      title: "",
      dataIndex: "time",
      key: "time",
      render: value => <TimeTitle>{value}</TimeTitle>,
      width: 120,
    },
    {
      title: ordersDate.format("ddd (MMM DD)"),
      dataIndex: "mon",
      key: "mon",
      render: value => createCell(value),
    },
    {
      title: ordersDate.add(1, "day").format("ddd (MMM DD)"),
      dataIndex: "tue",
      key: "tue",
      render: value => createCell(value),
    },
    {
      title: ordersDate.add(2, "day").format("ddd (MMM DD)"),
      dataIndex: "wed",
      key: "wed",
      render: value => createCell(value),
    },
    {
      title: ordersDate.add(3, "day").format("ddd (MMM DD)"),
      dataIndex: "thu",
      key: "thu",
      render: value => createCell(value),
    },
    {
      title: ordersDate.add(4, "day").format("ddd (MMM DD)"),
      dataIndex: "fri",
      key: "fri",
      render: value => createCell(value),
    },
    {
      title: ordersDate.add(5, "day").format("ddd (MMM DD)"),
      dataIndex: "sat",
      key: "sat",
      render: value => createCell(value),
    },
    {
      title: ordersDate.add(6, "day").format("ddd (MMM DD)"),
      dataIndex: "sun",
      key: "sun",
      render: value => createCell(value),
    },
  ];
};
