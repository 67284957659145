import { FC } from "react";
import { MenuProps } from "antd";
import { theme } from "theme";
import { SvgIconConstituentValues } from "types/common";
import { FlexContainer } from "styled/Box";

interface Option {
  id: string;
  label: string;
  disabled?: boolean;
  Icon: FC<SvgIconConstituentValues>;
  onClick: () => void;
}

export const createDropDownOptions = (
  options: Option[],
): MenuProps["items"] => {
  const createdOptions = options
    ?.filter(el => !el?.disabled)
    ?.map(({ id, label, Icon, onClick }) => ({
      key: id,
      label: (
        <FlexContainer $align="center" $gap={8}>
          <Icon fill={theme.color.text.tertiary} /> <div>{label}</div>
        </FlexContainer>
      ),
      onClick,
    }));
  return createdOptions;
};
