import { yupResolver } from "@hookform/resolvers/yup";
import { bool, object, string } from "yup";

const schema = object({
  isHomeSite: bool(),
  name: string()
    .trim()
    .max(50, "Location name must be less than 50 characters")
    .required("Location name is required"),
  country: string()
    .trim()
    .max(50, "Country must be less than 50 characters")
    .required("Country is required"),
  street: string()
    .trim()
    .max(50, "Street must be less than 50 characters")
    .required("Street is required"),
  number: string()
    .trim()
    .max(50, "Number must be less than 50 characters")
    .required("Number is required"),
  city: string()
    .trim()
    .max(50, "City must be less than 50 characters")
    .required("City is required"),
  postcode: string()
    .trim()
    .max(50, "Postal code must be less than 50 characters")
    .required("Postal code is required"),
  phoneCode: string()
    .trim()
    .max(50, "Phone code must be less than 50 characters"),
  phoneNumber: string()
    .nullable()
    .trim()
    .max(50, "Phone number must be less than 50 characters"),
  email: string()
    .nullable()
    .email("E-mail must be valid")
    .max(50, "Email must be less than 50 characters"),
  simphonyNodeId: string().nullable().required("Simphony NODE ID is required"),
}).required();

export const resolver = yupResolver(schema);
