import { CloseBlackIcon } from "icons";
import styled from "styled-components";
import { Tag as AntDTag } from "antd";
import { theme } from "theme";

export const CloseIcon = styled(CloseBlackIcon)``;

export const StyledTag = styled(AntDTag)`
  color: ${theme.color.brand} !important;
  display: flex;
  align-items: center;
  padding: 2px 6px;
  margin: 2px 0;
  min-height: 30px;

  font-family: "Inter";
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.base};

  .ant-tag-close-icon {
    display: flex;
    align-items: center;
  }
`;
