import { RootState } from "store";

export const getUser = (state: RootState) => state.user;

export const getUserName = (state: RootState) => state.user.email;

export const getUserCompanyId = (state: RootState) => state.user.companyId;

export const getUserSites = (state: RootState) => state.user.sites;

export const getUserRole = (state: RootState) => state.user.roleId;

export const getUserPermissions = (state: RootState) => state.user.permissions;
