// Lib
import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
// Hooks
import { usePermissions, useTable, useViewport } from "hooks";
// Types
import { ETable } from "types/tableFilters";
import { SnoozingCategoryType } from "types/menus";
import { CheckedItems, SnoozeTableItems } from "../../types";
// Helpers
import { isMatch } from "helpers/dataHelpers";
import { transformFromTableData, transformToTableData } from "../../helpers";
// Icons
import { SearchBlackIcon } from "icons";
// Components
import { Modal, Pagination, SegmentedButtons, Table } from "components";
import { DatePicker, Input, Select } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import {
  DatePickerContainer,
  MobileDatePickerContainer,
  SnoozeToolsContainer,
} from "./styled";

import { snoozeOptions, tableColumns } from "../config";

const pageSizeOptions = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
];

interface SortedData {
  key: string;
  id: string;
  name: string;
  plu: string;
  snooze_start: string;
  snooze_end: string;
}

interface SnoozedItemsModalProps {
  isLoading: boolean;
  open: boolean;
  data: {
    totalCount: number;
    filteredData: SnoozingCategoryType[];
  };
  handleSnoozeTableItems: (args: SnoozeTableItems) => void;
  handleUnsnoozeTableItems: (selectedIds: CheckedItems[]) => void;
  handleClose: () => void;
}

export const SnoozedItemsModal: FC<SnoozedItemsModalProps> = ({
  isLoading,
  open,
  data,
  handleSnoozeTableItems,
  handleUnsnoozeTableItems,
  handleClose,
}) => {
  const { isMobile, isLargeMobile, isDesktop } = useViewport();

  const { canSnoozeMenuItems } = usePermissions();

  const {
    orderDirection,
    orderBy,
    currentPage,
    pageSize,
    search,
    setCurrentPage,
    setPageSize,
    setSearch,
    handleSort,
    handleReset,
  } = useTable({
    name: ETable.SnoozedItemsModal,
    removeQueryParams: true,
  });

  const [sortedData, setSortedData] = useState<SortedData[]>([]);

  const [tabsValue, setTabsValue] = useState<string | number>(
    snoozeOptions[0].value,
  );

  const [untilDate, setUntilDate] = useState<dayjs.Dayjs | string>("");

  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    if (!!untilDate && tabsValue !== "snooze_until") {
      setUntilDate(null);
    }
  }, [tabsValue]);

  useEffect(() => {
    if (!open) {
      setTabsValue(snoozeOptions[0].value);
      setSelected([]);
      handleReset();
    }
  }, [open]);

  useEffect(() => {
    if (!data) return;

    if (
      !orderDirection &&
      !search &&
      !isMatch(transformToTableData(data?.filteredData), sortedData)
    ) {
      setSortedData(transformToTableData(data.filteredData));
      return;
    }
    const sortedArray = [...transformToTableData(data.filteredData)]
      .sort((a, b) => {
        const valA = a[orderBy];
        const valB = b[orderBy];
        if (valA < valB) return orderDirection === "ASC" ? -1 : 1;
        if (valA > valB) return orderDirection === "ASC" ? 1 : -1;
        return 0;
      })
      .filter(el =>
        search ? el.name.toLowerCase().includes(search.toLowerCase()) : el,
      );
    setSortedData(sortedArray);
  }, [data, search, orderBy, orderDirection]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  const onChangeDate = (e: dayjs.Dayjs) => {
    setUntilDate(e);
  };

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: any[]) => {
      setSelected(selectedRows.map(row => row.id));
    },
  };

  const onClose = () => {
    if (isLoading) {
      return;
    }
    setSelected([]);
    handleClose();
  };

  const onSave = () => {
    handleSnoozeTableItems({
      value: tabsValue.toString(),
      until: untilDate.toString(),
      selected: transformFromTableData(data.filteredData, selected),
    });
    setSelected([]);
  };

  const onUnsnooze = () => {
    handleUnsnoozeTableItems(
      transformFromTableData(data.filteredData, selected),
    );
    setSelected([]);
  };

  const paginate = (
    array: SortedData[],
    currentPage: number,
    pageSize: number,
  ) => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return array.slice(start, end);
  };

  const paginatedData = paginate(sortedData, currentPage, pageSize);

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const getCurrentOrder = () => {
    const startItem = (currentPage - 1) * pageSize + 1;
    const endItem = Math.min(currentPage * pageSize, data.totalCount);
    return `${startItem}-${endItem} of ${data.totalCount}`;
  };

  const currentItemsOrder = sortedData.length && getCurrentOrder();

  const isDisabled = !selected?.length;

  return (
    <Modal title="Snoozed items" width={1160} open={open} onClose={onClose}>
      <FlexContainer $fullwidth $column $gap={20}>
        <FlexContainer
          $fullwidth={isLargeMobile}
          $column={isLargeMobile}
          $gap={8}
          $align={isLargeMobile ? "stretch" : "center"}
          $justify="space-between"
        >
          <Typography.DescriptionThin>
            {`Showing ${currentItemsOrder} snoozed item`}
          </Typography.DescriptionThin>

          <Input
            prefix={<SearchBlackIcon />}
            placeholder="Search item"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </FlexContainer>

        {!isLargeMobile ? (
          <SnoozeToolsContainer>
            <Button.Heading
              type="primary"
              disabled={!canSnoozeMenuItems || isDisabled || isLoading}
              onClick={onUnsnooze}
            >
              Unsnooze
            </Button.Heading>

            <FlexContainer
              $fullwidth
              $align="center"
              $justify="flex-end"
              $gap={8}
            >
              <Typography.DescriptionThin>
                Available in
              </Typography.DescriptionThin>

              {isDesktop ? (
                <SegmentedButtons
                  value={tabsValue}
                  setValue={setTabsValue}
                  options={snoozeOptions}
                />
              ) : (
                <FlexContainer $column $width="200px" $align="stretch">
                  <Select
                    value={tabsValue}
                    onChange={setTabsValue}
                    options={snoozeOptions}
                  />
                </FlexContainer>
              )}

              <DatePickerContainer $open={tabsValue === "snooze_until"}>
                <DatePicker
                  placeholder="DD/MM/YYYY"
                  format={"DD/MM/YYYY"}
                  onChange={onChangeDate}
                />
              </DatePickerContainer>

              <FlexContainer $align="center" $justify="center">
                <Button.Base
                  type="primary"
                  onClick={onSave}
                  disabled={!canSnoozeMenuItems || isDisabled || isLoading}
                >
                  Save
                </Button.Base>
              </FlexContainer>
            </FlexContainer>
          </SnoozeToolsContainer>
        ) : (
          <FlexContainer $gap={8} $column>
            <FlexContainer $column $align="stretch">
              <Select
                label="Available in"
                value={tabsValue}
                onChange={setTabsValue}
                options={snoozeOptions}
              />
            </FlexContainer>

            <MobileDatePickerContainer $open={tabsValue === "snooze_until"}>
              <DatePicker
                placeholder="DD/MM/YYYY"
                format={"DD/MM/YYYY"}
                onChange={onChangeDate}
              />
            </MobileDatePickerContainer>
          </FlexContainer>
        )}

        <Table
          columns={tableColumns}
          loading={isLoading}
          dataSource={paginatedData}
          wrap={isLargeMobile}
          scroll={{ y: 488, x: isLargeMobile && "150vw" }}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selected,
            ...rowSelection,
          }}
          onChange={handleTableChange}
        />

        <Pagination
          pageSizeOptions={pageSizeOptions}
          pageSize={pageSize}
          currentPage={currentPage}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          totalItems={sortedData.length}
        />

        {isLargeMobile && (
          <FlexContainer
            $fullwidth
            $column={isMobile}
            $gap={8}
            $justify="flex-end"
          >
            <Button.Heading
              type="primary"
              $fullWidth={isMobile}
              disabled={!canSnoozeMenuItems || isDisabled || isLoading}
              onClick={onUnsnooze}
            >
              Unsnooze
            </Button.Heading>

            <Button.Heading
              type="primary"
              $fullWidth={isMobile}
              onClick={onSave}
              disabled={!canSnoozeMenuItems || isDisabled || isLoading}
            >
              Save
            </Button.Heading>
          </FlexContainer>
        )}
      </FlexContainer>
    </Modal>
  );
};
