import dayjs from "dayjs";
import {
  Day,
  GetOpeningHoursRequest,
  OpeningHoursFormTypes,
} from "types/locations";

export const convertToRequestData = (day: Day) => {
  return {
    available: day.available,
    time: day.time?.map(t => ({
      from: dayjs(t.from).format("HH:mm"),
      to: dayjs(t.to).format("HH:mm"),
    })),
  };
};

export const convertToFormData = (
  data: GetOpeningHoursRequest,
): OpeningHoursFormTypes => {
  const days = Object.keys(data.days).reduce((acc, day) => {
    const timeArray = data.days[day].time;
    const dateArray = timeArray?.length
      ? timeArray.map(t => {
          const timeFrom = t?.from?.split(":") || [];
          const timeTo = t?.to?.split(":") || [];

          return {
            from: timeFrom?.length
              ? dayjs().hour(Number(timeFrom[0])).minute(Number(timeFrom[1]))
              : null,
            to: timeTo?.length
              ? dayjs().hour(Number(timeTo[0])).minute(Number(timeTo[1]))
              : null,
          };
        })
      : [];
    acc[day] = { ...data.days[day], time: dateArray };
    return acc;
  }, {});

  return {
    ...data,
    days,
  };
};
