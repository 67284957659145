import { styled } from "styled-components";
import { ContentBox, FlexContainer } from "styled/Box";

export const Wrapper = styled(FlexContainer)`
  gap: 24px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const MainInfoWrapper = styled(FlexContainer)`
  width: calc(100% - 384px);

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const HistoryWrapper = styled(ContentBox)`
  flex-direction: column;
  gap: 20px;
  width: 360px;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
