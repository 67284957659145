import styled from "styled-components";
import { theme } from "theme";
import { PlusIcon, RefreshIcon } from "icons";
import { FlexContainer } from "styled/Box";

export const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const Refresh = styled(RefreshIcon)`
  path {
    fill: ${theme.color.text.tertiary};
  }
`;

export const Plus = styled(PlusIcon)`
  path {
    fill: ${theme.color.white};
  }
`;

export const PageWrapper = styled(FlexContainer)`
  min-height: 100%;
`;
