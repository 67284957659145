import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const DeliveryDining: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Placeholder" clipPath="url(#clip0_5892_589)">
      <g id="Group">
        <g id="Group_2">
          <g id="Group_3">
            <path
              id="Vector"
              d="M19.85 6.75002C19.85 5.59502 18.905 4.65002 17.75 4.65002H15.65C15.0725 4.65002 14.6 5.12252 14.6 5.70002C14.6 6.27752 15.0725 6.75002 15.65 6.75002H17.75V9.53252L14.096 14.1H10.4V9.90002C10.4 9.32252 9.9275 8.85002 9.35 8.85002H6.2C3.8795 8.85002 2 10.7295 2 13.05V15.15C2 15.7275 2.4725 16.2 3.05 16.2H4.1C4.1 17.943 5.507 19.35 7.25 19.35C8.993 19.35 10.4 17.943 10.4 16.2H14.096C14.7365 16.2 15.335 15.906 15.734 15.4125L19.388 10.845C19.6925 10.467 19.85 10.005 19.85 9.53252V6.75002ZM7.25 17.25C6.6725 17.25 6.2 16.7775 6.2 16.2H8.3C8.3 16.7775 7.8275 17.25 7.25 17.25Z"
              fill={fill || "#646965"}
            />
            <path
              id="Vector_2"
              d="M6.1999 5.70001H9.3499C9.9274 5.70001 10.3999 6.17251 10.3999 6.75001C10.3999 7.32751 9.9274 7.80001 9.3499 7.80001H6.1999C5.6224 7.80001 5.1499 7.32751 5.1499 6.75001C5.1499 6.17251 5.6224 5.70001 6.1999 5.70001Z"
              fill={fill || "#646965"}
            />
            <path
              id="Vector_3"
              d="M19.8502 13.05C18.1072 13.05 16.7002 14.457 16.7002 16.2C16.7002 17.943 18.1072 19.35 19.8502 19.35C21.5932 19.35 23.0002 17.943 23.0002 16.2C23.0002 14.457 21.5932 13.05 19.8502 13.05ZM19.8502 17.25C19.2727 17.25 18.8002 16.7775 18.8002 16.2C18.8002 15.6225 19.2727 15.15 19.8502 15.15C20.4277 15.15 20.9002 15.6225 20.9002 16.2C20.9002 16.7775 20.4277 17.25 19.8502 17.25Z"
              fill={fill || "#646965"}
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5892_589">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
