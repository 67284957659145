// Lib
import { useEffect, FC } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Skeleton, TabsProps } from "antd";
// Api
import { useGetOrdersQuery } from "rtkQuery/query/ordersAPI";
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
import { useGetCustomerQuery } from "rtkQuery/query/customersAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useNotification, useTable, useViewport } from "hooks";
//Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { ETable } from "types/tableFilters";
// Icons
// Constants
import { ADMIN_ROUTES, queryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Helpers
import { getFullName } from "helpers/dataHelpers";
// Components
import { ArrowBackLink, Avatar, Pagination, Tabs } from "components";
import { SummaryTab, OrdersTab } from "../components";
// Styled
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
  PageWrapper,
} from "styled/Box";
import { Typography } from "styled/Typography";

import { TabKey } from "./config";

export const CustomerDetails: FC = () => {
  const { id } = useParams();

  const { isMobile } = useViewport();

  const companyId = useAppSelector(getUserCompanyId);

  const { openNotification } = useNotification();

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get("tab") || TabKey.Summary;

  const setActiveTab = (tab: TabKey) => {
    setSearchParams({ tab });
  };

  const {
    orderDirection: ordersSortingOrder,
    orderBy: ordersSortBy,
    currentPage: ordersPage,
    pageSize: ordersLimit,
    debouncedSearch: debouncedOrdersSearch,
    debouncedFiltersQuery: debouncedOrdersFiltersQuery,
    setCurrentPage: setOrdersPage,
    setPageSize: setOrdersLimit,
    handleSort: handleOrdersSort,
  } = useTable({ name: ETable.CustomerOrders, removeQueryParams: true });

  const {
    data: ordersData,
    isFetching: isOrdersLoading,
    error: ordersError,
  } = useGetOrdersQuery(
    {
      companyId,
      query: {
        ...(debouncedOrdersFiltersQuery && debouncedOrdersFiltersQuery),
        customerId: id,
        page: ordersPage,
        size: ordersLimit,
        sortBy: ordersSortBy,
        sortingOrder: ordersSortingOrder,
        search: debouncedOrdersSearch,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: activeTab !== TabKey.Orders,
    },
  );

  useEffect(() => {
    if (ordersError) {
      errorHandler({ error: ordersError, openNotification });
    }
  }, [ordersError]);

  const {
    data: customerData,
    isFetching: isCustomerDataLoading,
    error: customerError,
  } = useGetCustomerQuery({ id }, queryParams);

  const {
    data: locationsData,
    isError: isLocationsError,
    error: locationsError,
  } = useGetLocationsQuery(
    { companyId },
    { skip: !companyId, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (customerError) {
      errorHandler({ error: customerError, openNotification });
    }
  }, [customerError]);

  useEffect(() => {
    if (isLocationsError) {
      errorHandler({ error: locationsError, openNotification });
    }
  }, [isLocationsError]);

  const items: TabsProps["items"] = [
    {
      key: TabKey.Summary,
      label: TabKey.Summary,
      children: (
        <SummaryTab
          isCustomerDataLoading={isCustomerDataLoading}
          customerData={customerData}
        />
      ),
    },
    {
      key: TabKey.Orders,
      label: TabKey.Orders,
      children: (
        <OrdersTab
          isLoading={isOrdersLoading}
          locationsData={locationsData}
          ordersData={ordersData}
          handleSort={handleOrdersSort}
        />
      ),
    },
  ];

  const setLimit = (limit: number) => {
    switch (activeTab) {
      case TabKey.Orders:
        setOrdersLimit(limit);
        break;

      default:
        break;
    }
  };

  const setPage = (page: number) => {
    switch (activeTab) {
      case TabKey.Orders:
        setOrdersPage(page);
        break;

      default:
        break;
    }
  };

  const { firstName, lastName } = customerData || {};

  const name = getFullName({ firstName, lastName });

  const isPagination = activeTab === TabKey.Orders;

  const limit = activeTab === TabKey.Orders ? ordersLimit : undefined;

  const page = activeTab === TabKey.Orders ? ordersPage : undefined;

  const totalItems =
    activeTab === TabKey.Orders ? ordersData?.total : undefined;

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer $fullwidth $column $gap={24} $grow={1}>
          <PageHeadingContainer
            $column={isMobile}
            $fullwidth={isMobile}
            $align={isMobile ? "flex-start" : undefined}
          >
            <PageTitleContainer
              $column
              $fullwidth={isMobile}
              $align={isMobile ? "flex-start" : undefined}
            >
              <ArrowBackLink
                title="Back to Customers"
                path={ADMIN_ROUTES.CUSTOMERS.path}
              />

              <FlexContainer
                $gap={12}
                $align="center"
                $justify="center"
                $margin="20px 0 0 0"
              >
                {isCustomerDataLoading ? (
                  <>
                    <Skeleton.Avatar active size={"default"} shape={"circle"} />
                    <Skeleton.Input active size={"default"} />
                  </>
                ) : name ? (
                  <>
                    <Avatar name={name} /> <Typography.H1>{name}</Typography.H1>{" "}
                  </>
                ) : null}
              </FlexContainer>
            </PageTitleContainer>
          </PageHeadingContainer>

          <Tabs
            activeKey={activeTab}
            items={items}
            onChange={(activeKey: TabKey) => setActiveTab(activeKey)}
          />
        </FlexContainer>

        {isPagination && (
          <Pagination
            onPage
            padding="12px 16px"
            pageSize={limit}
            currentPage={page}
            setPageSize={setLimit}
            setCurrentPage={setPage}
            totalItems={totalItems}
          />
        )}
      </PageWrapper>
    </>
  );
};
