import styled from "styled-components";
import { Upload } from "antd";
import { theme } from "theme";
import { Button } from "styled/Buttons";

const styles = `
&:not(:disabled):not(.ant-btn-disabled):hover {
  color: #093b0f;
  border-color: #093b0f;

  .ant-btn-icon {
    svg {
      g {
        path {
          fill: #093b0f;
        }
      }
    }
  }
}

&.ant-btn-default:disabled {
  .ant-btn-icon {
    svg {
      g {
        path {
          fill: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
`;

export const Wrapper = styled.div<{ $errorMessage: boolean }>`
  margin-bottom: ${({ $errorMessage }) => ($errorMessage ? "10px" : "31px")};
`;

export const StyledUpload = styled(Upload)<{ $errorMessage: boolean }>`
  .ant-upload-list-item {
    ${({ $errorMessage }) =>
      $errorMessage && `border-color: ${theme.color.error} !important`};
  }
`;

export const UploadButton = styled(Button.Form)`
  ${styles}
`;
