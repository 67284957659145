// Lib
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Api
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "rtkQuery/query/usersAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useNotification } from "hooks";
// Actions
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { UserFormTypes } from "../types";
// Theme
// Constants
import { ADMIN_ROUTES } from "consts";
// Helpers
import { createInitialValues } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
// Layouts
// Components
import { ArrowBackLink, Loader } from "components";
import { UserForm } from "../components";
// Styled
import { Typography } from "styled/Typography";
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";

export const EditUser: FC = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const { openNotification } = useNotification();

  const companyId = useAppSelector(getUserCompanyId);

  const [update, { isLoading: isUpdateLoading }] = useUpdateUserMutation();

  const { data: locationsData, error: locationsError } = useGetLocationsQuery(
    {
      companyId,
    },
    {
      skip: !companyId,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    },
  );

  const {
    data: userData,
    isFetching: isUserDataFetching,
    error: userDataError,
  } = useGetUserQuery({ id }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (locationsError) {
      errorHandler({ error: locationsError, openNotification });
    }
  }, [locationsError]);
  useEffect(() => {
    if (userDataError) {
      errorHandler({ error: userDataError, openNotification });
    }
  }, [userDataError]);

  const handleSendRequest = async (formValues: UserFormTypes) => {
    const { permissions, role, locations, password, ...data } = formValues;
    try {
      await update({
        ...data,
        id,
        companyId,
        permissionIds: permissions,
        siteIds: locations,
        roleId: role,
      }).unwrap();

      navigate(-1);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const locationsOptions =
    locationsData?.map(location => ({
      label: location.name,
      value: location.id,
      renderValue: location.name,
    })) || [];

  const additionalLocationsOptions =
    userData?.roleAssignment?.sites
      ?.map(site => ({
        label: site.name,
        value: site.id,
        renderValue: site.name,
      }))
      .filter(site => !locationsOptions.find(l => l.value === site.value)) ||
    [];

  const initialData = useMemo(
    () => createInitialValues({ userData }),
    [userData],
  );

  return (
    <>
      {isUserDataFetching && <Loader />}

      <FlexContainer $column $gap={24}>
        <PageHeadingContainer>
          <PageTitleContainer $column $gap={16}>
            <ArrowBackLink
              title="Back to Users"
              path={ADMIN_ROUTES.USERS.path}
            />

            <Typography.H1>Edit User</Typography.H1>
          </PageTitleContainer>
        </PageHeadingContainer>

        <UserForm
          isEdit
          isSubmitLoading={isUpdateLoading}
          initialData={initialData}
          locationsOptions={locationsOptions}
          additionalLocationsOptions={additionalLocationsOptions}
          handleSendRequest={handleSendRequest}
        />
      </FlexContainer>
    </>
  );
};
