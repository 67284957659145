import { TabsProps } from "antd/es/tabs";
import { Badge } from "components";
import { convertStatusToText, dateTransform } from "helpers/dataHelpers";
import { theme } from "theme";
import { StatusType } from "types/common";

export const items: TabsProps["items"] = [
  {
    key: "",
    label: `All`,
  },
  {
    key: StatusType.Active,
    label: `Active`,
  },
  {
    key: StatusType.Suspended,
    label: `Disabled`,
  },
];

export const columns = [
  {
    title: "",
    dataIndex: "imageUrl",
    key: "imageUrl",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    align: "right" as const,
    render: (text: string) => <div>{`${text} KWD`}</div>,
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: text => (
      <div style={{ width: "74px" }}>
        <Badge
          status={text === StatusType.Active ? "active" : "disabled"}
          textColor={
            text === StatusType.Active
              ? theme.color.text.primary2
              : theme.color.red
          }
          title={convertStatusToText(
            text === StatusType.Active ? "active" : "disabled",
          )}
        />
      </div>
    ),
    sorter: true,
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    render: (text: string) => dateTransform({ date: text }),
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    sorter: true,
    render: (text: string) => dateTransform({ date: text }),
  },
];
