import dayjs, { Dayjs } from "dayjs";
import { generateUniqueId } from "helpers/dataHelpers";
import { ScheduledEvent } from "types/common";
import { MenusScheduleCalendarEvent, ScheduleDay } from "types/menuScheduling";

const getDayNumber = (day: ScheduleDay): number => {
  switch (day) {
    case ScheduleDay.Sunday:
      return 7;
    case ScheduleDay.Monday:
      return 1;
    case ScheduleDay.Tuesday:
      return 2;
    case ScheduleDay.Wednesday:
      return 3;
    case ScheduleDay.Thursday:
      return 4;
    case ScheduleDay.Friday:
      return 5;
    case ScheduleDay.Saturday:
      return 6;

    default:
      break;
  }
  return 0;
};

const getSplittedTime = (time: string) => {
  const data = time.split(":");
  const hours = +data[0];
  const minutes = +data[1];
  const seconds = +data[2];

  return {
    hours,
    minutes,
    seconds,
  };
};

export const createEvents = (
  data: MenusScheduleCalendarEvent[],
  selectedDate: Dayjs,
) => {
  if (!data?.length) {
    return [];
  }
  const startInitialDate = dayjs.utc(selectedDate).startOf("week");

  const eventsData: ScheduledEvent[] = data.reduce((acc, el) => {
    const day = getDayNumber(el.day);

    el.schedules.forEach(schedule => {
      const startTime = getSplittedTime(schedule.startTime);
      const endTime = getSplittedTime(schedule.endTime);

      const startDate = startInitialDate
        .day(day)
        .hour(startTime.hours)
        .minute(startTime.minutes)
        .second(startTime.seconds);

      const endDate = startInitialDate
        .day(day)
        .hour(endTime.hours)
        .minute(endTime.minutes)
        .second(endTime.seconds);

      const event: ScheduledEvent = {
        id: generateUniqueId(),
        dataId: schedule.scheduleId,
        title: schedule.menu.map(menu => menu.name).join(", "),
        start: startDate.toISOString(),
        end: endDate.toISOString(),
      };

      acc.push(event);
    });

    return acc;
  }, []);

  return eventsData;
};
