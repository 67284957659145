// Lib
import { FC } from "react";
import { CollapseProps } from "antd";
// Styled
import { Collapse } from "./styled";

interface AccordionProps {
  disabled?: boolean;
  nested?: boolean;
  items: CollapseProps["items"];
}

export const Accordion: FC<AccordionProps & CollapseProps> = ({
  disabled,
  nested = false,
  items,
  ...props
}) => {
  return (
    <Collapse
      expandIconPosition={"end"}
      {...props}
      $nested={nested}
      collapsible={disabled ? "disabled" : "header"}
      items={items}
    />
  );
};
