import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 24px;
  width: 100%;
`;

export const ChartWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 384px;
  margin-left: -10px;
`;
