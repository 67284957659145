// Lib
import { FC } from "react";
// Hooks
import { usePermissions } from "hooks";
// Types
import {
  ESnoozeType,
  SnoozeBulkItems,
  SnoozeCategory,
  SnoozeItem,
} from "../../types";
// Helpers
import { getTotal } from "../../helpers";
// Icons
import { AlarmIcon } from "icons";
// Components
import { ConfirmDialog } from "components";

interface UnsnoozeModalProps {
  isLoading: boolean;
  open: boolean;
  data: SnoozeCategory | SnoozeItem | SnoozeBulkItems | null;
  onConfirm: () => void;
  handleClose: () => void;
}

export const UnsnoozeModal: FC<UnsnoozeModalProps> = ({
  isLoading,
  open,
  data,
  onConfirm,
  handleClose,
}) => {
  const { canSnoozeMenuItems } = usePermissions();

  const total = getTotal(data);

  const message =
    data && data?.type === ESnoozeType.ITEM
      ? "Unsnooze item"
      : data?.type === ESnoozeType.CATEGORY
      ? `${total} items will be unsnoozed in the ${data.category.name} category`
      : data?.type === ESnoozeType.BULK_ITEMS
      ? `${total} items will be unsnoozed`
      : null;

  const description =
    data && data?.type === ESnoozeType.ITEM
      ? `Do you want to unsnooze "${data.item.name}"?`
      : "Are you sure to continue this action?";

  return (
    <ConfirmDialog
      open={open}
      Icon={AlarmIcon}
      message={message}
      description={description}
      onCancel={handleClose}
      firstCTAButton={{
        title: "Confirm",
        disabled: !canSnoozeMenuItems,
        loading: isLoading,
        type: "primary",
        onClick: () => onConfirm(),
      }}
    />
  );
};
