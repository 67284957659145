import dayjs from "dayjs";
import styled from "styled-components";

import { EFilterDateValue } from "types/common";

import {
  AdditionalFilter,
  FilterField,
  FilterStateItem,
  FilterOption,
} from "types/tableFilters";

import {
  CalendarTodayIcon,
  ExpandCircleIcon,
  TagIcon,
  PersonIcon,
} from "icons";
import {
  dateCreate,
  dateTransformToRequestFormat,
  getRageDate,
} from "./dataHelpers";

export const getFiltersQuery = (filters: FilterStateItem[]) => {
  if (!filters?.length) return {};

  return filters.reduce((acc: Record<string, string>, f: FilterStateItem) => {
    if (!f.value) return { ...acc };

    if (f.field === FilterField.DATE) {
      if (
        f.name.toLowerCase().includes("after") &&
        typeof f.value === "string"
      ) {
        return { ...acc, [f.name]: dayjs(f.value).endOf("day")?.toISOString() };
      }
    }
    if (f.field === FilterField.MULTISELECT_SINGLE_DATE) {
      if (
        f.name.toLowerCase().includes("after") &&
        typeof f.value === "string"
      ) {
        switch (f.value) {
          case EFilterDateValue.CUSTOM:
            return !f?.subValue?.date?.start
              ? { ...acc }
              : {
                  ...acc,
                  [f.name]: dayjs(f?.subValue?.date?.start)
                    .startOf("day")
                    ?.toISOString(),
                };

          case EFilterDateValue.TODAY:
            return {
              ...acc,
              [f.name]: dayjs().startOf("day")?.toISOString(),
            };
          case EFilterDateValue.YESTERDAY:
            return {
              ...acc,
              [f.name]: dayjs()
                .startOf("day")
                .subtract(1, "day")
                ?.toISOString(),
            };
          case EFilterDateValue.IN_THE_LAST_7_DAYS:
            return {
              ...acc,
              [f.name]: dayjs()
                .startOf("day")
                .subtract(7, "day")
                ?.toISOString(),
            };
          case EFilterDateValue.IN_THE_LAST_30_DAYS:
            return {
              ...acc,
              [f.name]: dayjs()
                .startOf("day")
                .subtract(30, "day")
                ?.toISOString(),
            };
          case EFilterDateValue.IN_THE_LAST_90_DAYS:
            return {
              ...acc,
              [f.name]: dayjs()
                .startOf("day")
                .subtract(90, "day")
                ?.toISOString(),
            };
          case EFilterDateValue.IN_THE_12_MONTHS:
            return {
              ...acc,
              [f.name]: dayjs()
                .startOf("day")
                .subtract(12, "month")
                ?.toISOString(),
            };

          default:
            break;
        }

        return { ...acc, [f.name]: dayjs(f.value).endOf("day")?.toISOString() };
      }
    }

    if (f.field === FilterField.DATE_RANGE) {
      if (f.value === EFilterDateValue.CUSTOM) {
        const isDaysRageDate =
          !!f.subValue?.date?.start && !!f.subValue?.date?.end;

        if (!isDaysRageDate) return acc;

        const isEmptyOneTimeRangeField =
          (!!f.subValue.time?.start && !f.subValue.time?.end) ||
          (!f.subValue.time?.start && !!f.subValue.time?.end);

        if (isEmptyOneTimeRangeField) {
          return acc;
        } else if (!isEmptyOneTimeRangeField) {
          return {
            ...acc,
            [`${f.name}Before`]: dateTransformToRequestFormat(
              dateCreate(f.subValue?.date?.start)
                .hour(dateCreate(f.subValue?.time?.start)?.hour() || 0)
                .minute(dateCreate(f.subValue?.time?.start)?.minute() || 0),
            ),
            [`${f.name}After`]: dateTransformToRequestFormat(
              dateCreate(f.subValue?.date?.end)
                .hour(dateCreate(f.subValue?.time?.end)?.hour() || 0)
                .minute(dateCreate(f.subValue?.time?.start)?.minute() || 0),
            ),
          };
        }
        return {
          ...acc,
          [`${f.name}Before`]: dateTransformToRequestFormat(
            dateCreate(f.subValue?.date?.start).startOf("day"),
          ),
          [`${f.name}After`]: dateTransformToRequestFormat(
            dateCreate(f.subValue?.date?.end).endOf("day"),
          ),
        };
      }

      if (Array.isArray(f.value)) return { ...acc };

      const dates = getRageDate(f.value, f.name);

      return {
        ...acc,
        ...dates,
      };
    }

    return {
      ...acc,
      [f.name]: f.value,
    };
  }, {});
};

const getDropDownOptionIcon = (field: FilterField) => {
  switch (field) {
    case FilterField.DATE_RANGE:
      return <CalendarTodayIcon style={{ margin: 0 }} />;
    case FilterField.INPUT:
      return <TagIcon />;
    case FilterField.SELECT:
      return <ExpandCircleIcon />;
    case FilterField.MULTISELECT:
      return <ExpandCircleIcon />;
    case FilterField.BOOLEAN:
      return <ExpandCircleIcon />;
    case FilterField.USER_SELECT:
      return <PersonIcon style={{ marginInlineEnd: 0 }} />;

    default:
      return <ExpandCircleIcon />;
  }
};

export const FiltersDropdownLabel = styled.div`
  margin-left: 8px;
`;

export const createFiltersDropdown = (filters: FilterOption[]) => {
  return filters.map(filter => ({
    ...filter,
    label: <FiltersDropdownLabel>{filter.label}</FiltersDropdownLabel>,
    icon: filter?.icon || getDropDownOptionIcon(filter.field),
  }));
};

export const updateFiltersData = (
  filters: FilterOption[],
  include?: AdditionalFilter[],
) => {
  if (include?.includes(AdditionalFilter.Users)) {
    return filters.concat({
      key: "userId",
      label: "User",
      field: FilterField.USER_SELECT,
    });
  }

  return filters;
};
