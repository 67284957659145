import { ScheduleFormTypes } from "./types";

export const defaultScheduleValue = { days: [], time: null };

export const defaultValues: ScheduleFormTypes = {
  timezone: "",
  menuIds: "",
  sitesIds: [],
  channelIds: [],
  publishTimes: [defaultScheduleValue],
};
