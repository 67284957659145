import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const LogoIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="99"
    height="36"
    viewBox="0 0 99 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9429 24.6857C20.6353 24.6857 23.6286 21.6924 23.6286 18C23.6286 14.3075 20.6353 11.3142 16.9429 11.3142C13.2505 11.3142 10.2572 14.3075 10.2572 18C10.2572 21.6924 13.2505 24.6857 16.9429 24.6857ZM16.9429 28.7999C22.9076 28.7999 27.7429 23.9646 27.7429 18C27.7429 12.0353 22.9076 7.19995 16.9429 7.19995C10.9782 7.19995 6.14288 12.0353 6.14288 18C6.14288 23.9646 10.9782 28.7999 16.9429 28.7999Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.6 24.6857C44.2924 24.6857 47.2857 21.6924 47.2857 18C47.2857 14.3075 44.2924 11.3142 40.6 11.3142C36.9076 11.3142 33.9143 14.3075 33.9143 18C33.9143 21.6924 36.9076 24.6857 40.6 24.6857ZM40.6 28.7999C46.5647 28.7999 51.4 23.9646 51.4 18C51.4 12.0353 46.5647 7.19995 40.6 7.19995C34.6353 7.19995 29.8 12.0353 29.8 18C29.8 23.9646 34.6353 28.7999 40.6 28.7999Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.2572 24.6857C67.9496 24.6857 70.9429 21.6924 70.9429 18C70.9429 14.3075 67.9496 11.3142 64.2572 11.3142C60.5647 11.3142 57.5714 14.3075 57.5714 18C57.5714 21.6924 60.5647 24.6857 64.2572 24.6857ZM64.2572 28.7999C70.2218 28.7999 75.0572 23.9646 75.0572 18C75.0572 12.0353 70.2218 7.19995 64.2572 7.19995C58.2925 7.19995 53.4572 12.0353 53.4572 18C53.4572 23.9646 58.2925 28.7999 64.2572 28.7999Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.4 20.0572H25.1714V15.9429H33.4V20.0572Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.5143 20.0572H50.3714V15.9429H55.5143V20.0572Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-2.89154e-05 28.8L-2.76566e-05 -1.79841e-07L4.11426 0L4.11426 28.8L-2.89154e-05 28.8Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.8857 24.6857C91.5781 24.6857 94.5714 21.6924 94.5714 18C94.5714 14.3075 91.5781 11.3142 87.8857 11.3142C84.1933 11.3142 81.2 14.3075 81.2 18C81.2 21.6924 84.1933 24.6857 87.8857 24.6857ZM87.8857 28.7999C93.8504 28.7999 98.6857 23.9646 98.6857 18C98.6857 12.0353 93.8504 7.19995 87.8857 7.19995C81.921 7.19995 77.0857 12.0353 77.0857 18C77.0857 23.9646 81.921 28.7999 87.8857 28.7999Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.0857 36L77.0857 7.19995L81.2 7.19995L81.2 36L77.0857 36Z"
      fill="black"
    />
  </svg>
);
