import { Layout } from "antd";
import styled from "styled-components";
import { theme } from "theme";
import { RefreshIcon } from "icons";
import { Tabs } from "components/Tabs";
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";

const { Sider } = Layout;

export const PageTitleContainer = styled.div<{ $width?: string }>`
  display: flex;
  position: fixed;
  background-color: ${theme.color.white};
  top: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${theme.color.borderMedium};

  width: ${({ $width }) => $width && $width};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const Refresh = styled(RefreshIcon)`
  path {
    fill: ${theme.color.text.tertiary};
  }
`;

export const PageWrapper = styled(FlexContainer)`
  min-height: 100%;
  display: flex;
  flex-direction: row;
`;

export const ItemsContainer = styled.div`
  overflow: auto;
  margin-top: 192px;
`;

export const OrdersSidebar = styled(Sider)`
  background: ${theme.color.white} !important;
  position: fixed !important;
  height: 100vh;
  margin-left: -32px;
  top: 0;
  bottom: 0;
  border-right: 1px solid ${theme.color.borderLight};

  .ant-layout-sider-children {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 64px;
  }

  @media (max-width: 1023px) {
    margin-left: -16px;
  }
`;

export const SidebarTabs = styled(Tabs)<{ $width?: string }>`
  position: fixed;
  top: 149px;
  background-color: ${theme.color.white};

  width: ${({ $width }) => $width && $width};

  .ant-tabs-nav {
    border-bottom: 1px solid ${theme.color.borderLight};
    border-right: 1px solid ${theme.color.borderLight};
    background-color: ${theme.color.white};
  }

  &.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 -16px 0px;
  }
`;

export const SidebarOrderCountInfo = styled(Typography.Title)<{
  $width?: string;
}>`
  padding: 16px 24px;
  position: fixed;
  top: 198px;
  background-color: ${theme.color.white};
  border-bottom: 1px solid ${theme.color.borderLight};
  border-right: 1px solid ${theme.color.borderLight};

  width: ${({ $width }) => $width && $width};
`;
