import { getKitchenSettings } from "rtkQuery/selectors";
import { useAppDispatch, useAppSelector } from "./redux";
import { setAllSettings, setOneSetting } from "rtkQuery/slices";
import { GetKitchenSettingsResponse, KitchenSetting } from "types/kitchen";

export const useKitchenSettings = () => {
  const dispatch = useAppDispatch();

  const settings = useAppSelector(getKitchenSettings);

  const setAllConfigs = (data: GetKitchenSettingsResponse) => {
    dispatch(setAllSettings(data));
  };

  const setConfig = (data: KitchenSetting) => {
    dispatch(setOneSetting(data));
  };

  return {
    ...settings,
    setConfig,
    setAllConfigs,
  };
};
