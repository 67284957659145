export const endpoints = {
  refreshToken: "auth/refresh/",
  auth: "auth/",
  companies: "companies/",
  sites: "sites/",
  orders: "orders/",
  channelLinks: "sites/channels/",
  products: "products/",
  snooze: "snooze/",
  menus: "menus/",
  users: "users/",
  inventory: "inventory/",
  media: "media/upload/",
  kitchen: "kitchen-pwa/",
  menuSchedule: "schedule-menu/",
  roles: "roles/",
  operationReports: "operation-reports/",
  customer_profiles: "customer-profiles/",
};
