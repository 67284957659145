import { ArrowDownIcon } from "icons";
import styled from "styled-components";
import { theme } from "theme";

export const ArrowDown = styled(ArrowDownIcon)`
  rotate: -90deg;
  path {
    fill: ${theme.color.text.primary2};
  }
`;
