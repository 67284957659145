// Lib
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from "rtkQuery/query/usersAPI";
// Hooks
import { useNotification, useUserRole, useViewport } from "hooks";
// Types
import { UserProfileResponseDto } from "types/users";
import { TableAction } from "types/common";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS, Role } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PencilIcon, RightBurgerMenuIcon, TrashIcon } from "icons";
// Components
import { ConfirmDialog, DropDown, Table } from "components";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import {
  ButtonsContainer,
  PageTitleContainer,
  PageWrapper,
  Plus,
  Refresh,
} from "./styled";

import { tableColumns } from "./config";

export const Users: FC = () => {
  const { isDesktop } = useViewport();

  const { openNotification } = useNotification();
  const { isAdminUser, isAccountManagerUser } = useUserRole();

  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [deleteModal, setDeleteModal] = useState<
    false | UserProfileResponseDto
  >(false);

  const [remove, { isLoading: isUserDeleteLoading }] = useDeleteUserMutation();

  const {
    data: usersData,
    isFetching: isUsersDataFetching,
    error: usersDataError,
    refetch,
  } = useGetUsersQuery(
    {
      query: {
        q: search,
      },
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (usersDataError) {
      errorHandler({ error: usersDataError, openNotification });
    }
  }, [usersDataError]);

  const handleAddUser = () => {
    navigate(ADMIN_ROUTES.USERS_CREATE.path);
  };

  const validateRowActions = row => {
    if (isAdminUser) {
      if (row?.roleAssignment?.role?.id !== Role.LoopAdmin) {
        return false;
      }
      return true;
    }

    if (isAccountManagerUser) {
      if (row?.roleAssignment?.role?.id === Role.LoopAdmin) {
        return true;
      }

      if (row?.roleAssignment?.role?.id === Role.Admin) {
        return true;
      }

      return false;
    }

    return true;
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const onConfirmDelete = async () => {
    if (!deleteModal) return;
    try {
      await remove({ id: deleteModal?.id });

      setDeleteModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const actions: TableAction[] = [
    {
      title: "",
      Icon: PencilIcon,
      disableFunction: row => validateRowActions(row),
      onClick: row => navigate(`/loop/users/${row.id}`),
    },
    {
      title: "",
      type: "Delete",
      Icon: TrashIcon,
      disableFunction: row => validateRowActions(row),
      onClick: row => setDeleteModal(row),
    },
  ];

  const dropDownMenuItems = [
    {
      key: "2",
      label: "Add User",
      onClick: handleAddUser,
    },
    {
      key: "3",
      label: "Refresh",
      onClick: refetch,
    },
  ];

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $gap={20}
          $grow={1}
          $padding="0 0 32px"
        >
          <PageTitleContainer>
            <Typography.H1>Users</Typography.H1>
            {isDesktop ? (
              <ButtonsContainer>
                <Button.Heading icon={<Refresh />} onClick={() => refetch()}>
                  Refresh
                </Button.Heading>

                <Button.Heading
                  icon={<Plus />}
                  type="primary"
                  onClick={handleAddUser}
                >
                  Add User
                </Button.Heading>
              </ButtonsContainer>
            ) : (
              <DropDown trigger={["click"]} items={dropDownMenuItems}>
                <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
              </DropDown>
            )}
          </PageTitleContainer>

          <Table
            isLoading={isUsersDataFetching}
            accentColumn={1}
            dataSource={usersData}
            header={{
              totalCount: usersData?.length,
              search: {
                value: search,
                setValue: setSearch,
                placeholder: "Search User by first name, last name or email",
              },
            }}
            actions={actions}
            columns={tableColumns}
          />
        </FlexContainer>
      </PageWrapper>

      <ConfirmDialog
        open={!!deleteModal}
        Icon={TrashIcon}
        message={deleteModal && `${deleteModal?.fullName} will be deleted`}
        description={NOTIFICATIONS.APROVE_ACTION_MESSAGE}
        firstCTAButton={{
          title: "Delete User",
          status: "danger",
          loading: isUserDeleteLoading,
          onClick: () => onConfirmDelete(),
        }}
        onCancel={handleCloseDeleteModal}
      />
    </>
  );
};
