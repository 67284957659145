// Lib
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import { FilterStateItem, ETable, TableFiltersState } from "types/tableFilters";

const initialState: TableFiltersState = {
  [ETable.Orders]: [],
  [ETable.Customers]: [],
  [ETable.CustomerOrders]: [],
  [ETable.OperationReports]: [],
  [ETable.InventoryHistory]: [],
  [ETable.MenuScheduling]: [],
  [ETable.SnoozedItemsModal]: [],
  [ETable.Tags]: [],
  [ETable.Modifiers]: [],
  [ETable.Products]: [],
  [ETable.OperationReportDetails]: [],
  [ETable.AddonModifierModal]: [],
  [ETable.AddProductsModal]: [],
  [ETable.AnalyticsMenuItems]: [],
  [ETable.AnalyticsLocations]: [],
  [ETable.Inventory]: [],
  [ETable.InventoryDetails]: [],
  [ETable.KitchenInventory]: [],
  [ETable.Menus]: [],
  [ETable.TabletsSettingsProfiles]: [],
  [ETable.TabletsSettingsDevices]: [],
};

export const tableFiltersSlice = createSlice({
  name: "tableFilters",
  initialState,
  reducers: {
    setTableFilters: (
      state,
      action: PayloadAction<{ name: ETable; filters: FilterStateItem[] }>,
    ) => {
      state[action.payload.name] = action.payload.filters;
    },
  },
});

export const { setTableFilters } = tableFiltersSlice.actions;
export const tableFiltersSliceReducer = tableFiltersSlice.reducer;
