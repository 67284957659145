import { FlexContainer } from "styled/Box";
import { OrdersTableRowData } from "./types";
import { Typography } from "styled/Typography";
import { DescriptionGray, ItemId } from "../../styled";
import { ModifierType } from "types/common";

const PRODUCT_PADDINGS = "5px 0 5px 5px";
const MODIFIERS_PADDINGS = "0 0 5px 5px";

export const columns = [
  {
    title: "Item",
    key: "name",
    dataIndex: "name",
    render: (name: string, record: OrdersTableRowData) =>
      record.type === "product" ? (
        <FlexContainer $column $padding={PRODUCT_PADDINGS}>
          <Typography.Title>{`${record?.quantity} x ${name}`}</Typography.Title>
          <ItemId>{`ID  ${record.id}`}</ItemId>

          {!!record?.remark && (
            <Typography.Description style={{ whiteSpace: "normal" }}>
              Note:{" "}
              <Typography.DescriptionThin as="span">
                {record.remark}
              </Typography.DescriptionThin>{" "}
            </Typography.Description>
          )}
        </FlexContainer>
      ) : (
        <FlexContainer $column $padding="0 0 5px 30px">
          <DescriptionGray>
            {record.type === ModifierType.Addon
              ? `${record?.quantity} x ${name}`
              : name}
          </DescriptionGray>
          <DescriptionGray>{`ID  ${record.id}`}</DescriptionGray>
        </FlexContainer>
      ),
  },
  {
    title: "Item price",
    key: "price",
    dataIndex: "price",
    align: "right" as const,
    render: (price: string, record: OrdersTableRowData) => (
      <FlexContainer
        $justify="flex-end"
        $padding={
          record?.type === "product" ? PRODUCT_PADDINGS : MODIFIERS_PADDINGS
        }
      >
        {record?.type === "product" ? (
          <Typography.Title>{price}</Typography.Title>
        ) : (
          <DescriptionGray>{price}</DescriptionGray>
        )}
      </FlexContainer>
    ),
  },
  {
    title: "Total",
    key: "total",
    dataIndex: "total",
    align: "right" as const,
    render: (total: string, record: OrdersTableRowData) => {
      return (
        <FlexContainer
          $justify="flex-end"
          $padding={
            record?.type === "product" ? PRODUCT_PADDINGS : MODIFIERS_PADDINGS
          }
        >
          {record?.type === "product" ? (
            <Typography.Title>{total}</Typography.Title>
          ) : (
            <DescriptionGray>{total}</DescriptionGray>
          )}
        </FlexContainer>
      );
    },
  },
];
