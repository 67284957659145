// Lib
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
// Api
import { authAPI } from "./rtkQuery/query/authAPI";
import { locationsAPI } from "rtkQuery/query/locationsAPI";
import { ordersAPI } from "./rtkQuery/query/ordersAPI";
import { productsAPI } from "rtkQuery/query/productsAPI";
import { snoozeAPI } from "rtkQuery/query/snoozeAPI";
import { menusAPI } from "rtkQuery/query/menusAPI";
import { kitchenOrdersAPI } from "rtkQuery/query/kitchenOrdersAPI";
import { kitchenLocationsAPI } from "rtkQuery/query/kitchenLocationsAPI";
import { kitchenSettingsAPI } from "rtkQuery/query/kitchenSettingsAPI";
import { uploadMediaAPI } from "rtkQuery/query/uploadMediaAPI";
import { inventoryAPI } from "rtkQuery/query/inventoryAPI";
import { usersAPI } from "rtkQuery/query/usersAPI";
import { menuSchedulingAPI } from "rtkQuery/query/menuSchedulingAPI";
import { customersAPI } from "rtkQuery/query/customersAPI";
import { operationReportsAPI } from "rtkQuery/query/operationReportsAPI";
import { analyticsAPI } from "rtkQuery/query/analyticsAPI";
// Selectors
import {
  authSliceReducer,
  userSliceReducer,
  uiSliceReducer,
  kitchenSliceReducer,
  tableFiltersSliceReducer,
} from "rtkQuery/slices";
// Types
import { userState } from "types/user";
import { AuthState } from "types/auth";
import { UiState } from "types/ui";
import { OrderFiltersState } from "types/orders";
import { KitchenState } from "types/kitchen";
// Utils
import { token } from "utils/handleToken";
import { TableFiltersState } from "types/tableFilters";
import { modifiersAPI } from "rtkQuery/query/modifiersAPI";
import { AnalyticsState } from "types/analytics";
import { analyticsSliceReducer } from "rtkQuery/slices/analytics";

const LOGOUT_ACTIONS = ["auth/userLogout"];

const reducers = combineReducers({
  auth: authSliceReducer,
  user: userSliceReducer,
  tableFilters: tableFiltersSliceReducer,
  analytics: analyticsSliceReducer,
  ui: uiSliceReducer,
  kitchen: kitchenSliceReducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [analyticsAPI.reducerPath]: analyticsAPI.reducer,
  [locationsAPI.reducerPath]: locationsAPI.reducer,
  [ordersAPI.reducerPath]: ordersAPI.reducer,
  [customersAPI.reducerPath]: customersAPI.reducer,
  [kitchenOrdersAPI.reducerPath]: kitchenOrdersAPI.reducer,
  [kitchenLocationsAPI.reducerPath]: kitchenLocationsAPI.reducer,
  [kitchenSettingsAPI.reducerPath]: kitchenSettingsAPI.reducer,
  [productsAPI.reducerPath]: productsAPI.reducer,
  [modifiersAPI.reducerPath]: modifiersAPI.reducer,
  [snoozeAPI.reducerPath]: snoozeAPI.reducer,
  [menusAPI.reducerPath]: menusAPI.reducer,
  [inventoryAPI.reducerPath]: inventoryAPI.reducer,
  [usersAPI.reducerPath]: usersAPI.reducer,
  [uploadMediaAPI.reducerPath]: uploadMediaAPI.reducer,
  [menuSchedulingAPI.reducerPath]: menuSchedulingAPI.reducer,
  [operationReportsAPI.reducerPath]: operationReportsAPI.reducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = (state, action) => {
  if (LOGOUT_ACTIONS.includes(action.type)) {
    state = undefined;
    token.clear();
  }

  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authAPI.middleware,
      locationsAPI.middleware,
      ordersAPI.middleware,
      customersAPI.middleware,
      kitchenOrdersAPI.middleware,
      kitchenLocationsAPI.middleware,
      kitchenSettingsAPI.middleware,
      productsAPI.middleware,
      snoozeAPI.middleware,
      menusAPI.middleware,
      inventoryAPI.middleware,
      analyticsAPI.middleware,
      usersAPI.middleware,
      modifiersAPI.middleware,
      uploadMediaAPI.middleware,
      menuSchedulingAPI.middleware,
      operationReportsAPI.middleware,
    ),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export type RootState = {
  auth: AuthState;
  user: userState;
  ui: UiState;
  orderFilters: OrderFiltersState;
  tableFilters: TableFiltersState;
  kitchen: KitchenState;
  analytics: AnalyticsState;
};
