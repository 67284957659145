import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { GetMenuResponse, SnoozingCategoryType } from "types/menus";
import {
  SnoozeItemsRequest,
  SnoozeValuesTypes,
  UnsnoozeItemsRequest,
} from "types/snooze";
import {
  CheckedItems,
  ESnoozeType,
  SnoozeBulkItems,
  SnoozeCategory,
  SnoozeItem,
} from "./types";
import { generateTags, getLabel } from "helpers/dataHelpers";

dayjs.extend(utc);
dayjs.extend(timezone);

export const getTotal = (
  data: SnoozeCategory | SnoozeItem | SnoozeBulkItems | null,
) => {
  if (!data) return;
  if (data.type === ESnoozeType.CATEGORY) {
    return data.category.items.length;
  }

  if (data.type === ESnoozeType.BULK_ITEMS) {
    let totalCount = 0;

    for (const item of data.items) {
      totalCount += item.items.length;
    }
    return totalCount;
  }
};

export const getAllSnoozedItems = (
  data: SnoozingCategoryType[],
): { totalCount: number; filteredData: SnoozingCategoryType[] } => {
  let totalCount = 0;
  const filteredData = data.map(category => {
    const filteredItems = category.items.filter(
      item => item.isSnoozed === true,
    );
    totalCount += filteredItems.length;
    return {
      ...category,
      items: filteredItems,
    };
  });

  return { filteredData, totalCount };
};

export const transformToTableData = (data: SnoozingCategoryType[]) => {
  return data
    .map(c =>
      c.items.map(i => ({
        key: i.id,
        id: i.id,
        name: i.name,
        plu: i.plu,
        snooze_start: i.snoozedAt?.toString(),
        snooze_end: i.snoozedUntil?.toString(),
      })),
    )
    .flat();
};

export const transformFromTableData = (
  data: SnoozingCategoryType[],
  checked: string[],
): CheckedItems[] => {
  return data
    .map(c => {
      const checkedItems = c.items.filter(item => checked.includes(item.id));

      if (checkedItems.length) {
        return {
          categoryId: c.id,
          items: checkedItems.map(i => ({
            itemId: i.id,
            isSnoozed: i.isSnoozed,
          })),
        };
      }

      return null;
    })
    .filter(Boolean);
};

export const snoozeUntilDateHandler = (
  value: SnoozeValuesTypes,
  until: string,
): dayjs.Dayjs | null => {
  if (!value) return null;

  if (value === SnoozeValuesTypes.SnoozeUntil) {
    return dayjs(until);
  }

  if (value === SnoozeValuesTypes.Snooze1H) {
    return dayjs().add(1, "hour");
  }
  if (value === SnoozeValuesTypes.Snooze2H) {
    return dayjs().add(2, "hour");
  }
  if (value === SnoozeValuesTypes.Snooze4H) {
    return dayjs().add(4, "hour");
  }
  if (value === SnoozeValuesTypes.Snooze6H) {
    return dayjs().add(6, "hour");
  }
  if (value === SnoozeValuesTypes.Snooze12H) {
    return dayjs().add(12, "hour");
  }
  if (value === SnoozeValuesTypes.SnoozeIndefinitely) {
    return null;
  }

  return null;
};

export const transformToSnoozeRequestData = (
  snoozedItemsData: SnoozeCategory | SnoozeItem | SnoozeBulkItems,
  value: SnoozeValuesTypes,
  until: string,
  siteId: string,
): SnoozeItemsRequest => {
  const date = snoozeUntilDateHandler(value, until);

  const formattedDate = date
    ? dayjs.utc(date).toISOString()
    : dayjs().add(10, "year").toISOString();

  switch (snoozedItemsData.type) {
    case ESnoozeType.CATEGORY:
      return {
        data: {
          siteId,
          items: snoozedItemsData.category.items.map(i => ({
            snoozedUntil: formattedDate,
            menuItemId: i.id,
          })),
        },
      };
    case ESnoozeType.ITEM:
      return {
        data: {
          siteId,
          items: [
            {
              snoozedUntil: formattedDate,
              menuItemId: snoozedItemsData.item.id,
            },
          ],
        },
      };
    case ESnoozeType.BULK_ITEMS:
      return {
        data: {
          siteId,
          items: snoozedItemsData.items
            .map(i =>
              i.items.map(item => ({
                menuItemId: item.itemId,
                snoozedUntil: formattedDate,
              })),
            )
            .flat(),
        },
      };

    default:
      break;
  }
};

export const transformToUnsnoozeRequestData = (
  snoozedItemsData: SnoozeCategory | SnoozeItem | SnoozeBulkItems,
  siteId: string,
): UnsnoozeItemsRequest => {
  if (snoozedItemsData.type === ESnoozeType.CATEGORY) {
    const data = {
      siteId,
      items: snoozedItemsData.category.items.map(i => i.id),
    };
    return { data };
  }

  if (snoozedItemsData.type === ESnoozeType.ITEM) {
    const data = {
      siteId,
      items: [snoozedItemsData.item.id],
    };
    return { data };
  }

  if (snoozedItemsData.type === ESnoozeType.BULK_ITEMS) {
    const data = {
      siteId,
      items: snoozedItemsData.items
        .map(i => i.items.map(item => item.itemId))
        .flat(),
    };
    return { data };
  }
};

const getSnoozedDate = (date: string | null) => {
  if (!date) {
    return null;
  }

  if (dayjs(date).diff(dayjs(), "year") > 2) {
    return null;
  }

  return dayjs(date);
};

export const transformSnoozeMenuData = (
  data: GetMenuResponse,
): SnoozingCategoryType[] => {
  const result = data.categories.map(cat => {
    const childSnoozedUntils = cat.menuItems.map(item => item.snoozedUntil);

    const isSnoozedUntilSame = childSnoozedUntils.every(
      (snoozedUntil, i, arr) => snoozedUntil === arr[0],
    );

    const snoozedUntil = isSnoozedUntilSame ? childSnoozedUntils[0] : null;

    return {
      id: cat.id,
      name: cat.name,
      isSnoozed: cat?.menuItems?.every(i => !!i.isSnoozed),
      disabled: cat?.menuItems?.every(i => i.isDisabled === true) || false,
      snoozedUntil: getSnoozedDate(snoozedUntil),
      items: cat.menuItems.map(item => ({
        id: item.id,
        image: item.product?.imageUrl,
        name: item.product?.name,
        description: item.product?.description,
        kcal: item.product?.kcal,
        price: item.product?.price,
        tags: generateTags(item.product),
        labels: getLabel(item.product),
        isSnoozed: item?.isSnoozed || false,
        disabled: item?.isDisabled || false,
        snoozedUntil: getSnoozedDate(item.snoozedUntil),
        snoozedAt: item.snoozedAt ? dayjs(item.snoozedAt) : null,
        plu: item.product.plu,
        tax: item.product.tax,
        modifiers: item?.modifiers || [],
      })),
    };
  });

  return result;
};

export const searchSnoozedItems = (
  value: string,
  data: SnoozingCategoryType[],
): SnoozingCategoryType[] => {
  const result = data.reduce(
    (
      acc: SnoozingCategoryType[],
      el: SnoozingCategoryType,
    ): SnoozingCategoryType[] | [] => {
      const filteredCategory = {
        ...el,
        items: el.items.filter(i =>
          i.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
        ),
      };

      if (filteredCategory.items.length) {
        acc.push(filteredCategory);
      }

      return acc;
    },
    [] as SnoozingCategoryType[],
  );

  return result;
};

export const getTotalBulkItems = (data: SnoozeBulkItems) => {
  return data.items.reduce((total, itemsData) => {
    const { items } = itemsData;

    return total + items.length;
  }, 0);
};
