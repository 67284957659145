// Lib
import { FC } from "react";
// Theme
import { theme } from "theme";
// Constants
import { TABLE_DATE } from "consts";
// Helpers
import { dateTransform } from "helpers/dataHelpers";
// Icons
import { FileUploadIcon } from "icons";
// Styled
import { ExportButton } from "./styled";
import { DropDown } from "components/DropDown";

function extractValues(keys: string[], data: Record<string, unknown>[]) {
  const rowsArray = data.map(d => {
    const rows = keys.map(k => {
      if (k === "createdAt" || k === "updatedAt") {
        return dateTransform({
          date: d[k] as string,
          format: TABLE_DATE,
        });
      }
      return d[k];
    });

    return rows;
  });

  return rowsArray;
}

interface ExportDropDownProps {
  type?: "Heading" | "Base";
  isLoading: boolean;
  isDataLoading: boolean;
  isDisabled: boolean;

  items: {
    key: string;
    label: string;
    fileName: string;
    getExportData: () => Promise<Record<string, unknown>[]>;
    columns: Record<string, unknown>[];
  }[];
}

export const ExportDropDown: FC<ExportDropDownProps> = ({
  type = "Heading",
  isLoading,
  isDataLoading,
  isDisabled,
  items,
}) => {
  const convertToCSV = (arr: unknown[][]) => {
    return arr
      .map(row =>
        row
          .map(data => {
            if (typeof data === "string" && data.includes(",")) {
              return `"${data}"`;
            }

            return data;
          })
          .join(","),
      )
      .join("\n");
  };

  const downloadCSV = async (
    getExportData: () => Promise<Record<string, unknown>[]>,
    fileName: string,
    columns: Record<string, unknown>[],
  ) => {
    if (isDataLoading) {
      return;
    }
    const titles = columns.map(c => c?.title);

    const keys = columns
      .map(c => c?.key)
      .filter(el => typeof el === "string") as string[];

    const data = await getExportData();

    const rowsArray = extractValues(keys, data);

    const csvData = [titles, ...rowsArray];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      encodeURIComponent(convertToCSV(csvData));
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const Component = ExportButton[type];

  return (
    <DropDown
      items={items.map(item => ({
        ...item,
        onClick: () =>
          downloadCSV(item.getExportData, item.fileName, item.columns),
      }))}
      disabled={isDisabled}
      trigger={["click"]}
    >
      <Component
        icon={<FileUploadIcon fill={theme.color.text.tertiary} />}
        loading={isLoading}
        disabled={isDisabled}
      >
        Export
      </Component>
    </DropDown>
  );
};
