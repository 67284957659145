// Lib
import { FC, memo, useCallback, useMemo, useState } from "react";
import { DatePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
// Api
import { useGetOrderHourlyDistributionAnalyticsQuery } from "rtkQuery/query/analyticsAPI";
// Hooks
import { useViewport } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Theme
import { theme } from "theme";
// Constants
import { queryParams } from "consts";
// Icons
import { ArrowDownIcon, CalendarTodayIcon } from "icons";
// Components
import { DropDown, Table } from "components";
import { DatePicker } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { BackIcon, ForwardIcon, MobileTableDateWrapper } from "./styled";

import { createColumns } from "./config";

export const CalendarTable: FC = memo(() => {
  const companyId = useAppSelector(getUserCompanyId);

  const { isLargeMobile } = useViewport();

  const [ordersPerHoursDates, setOrdersPerHoursDates] = useState<
    [Dayjs, Dayjs]
  >(() => [dayjs().startOf("week"), dayjs().endOf("week")]);

  const dateBefore = ordersPerHoursDates[0].startOf("day").toISOString();
  const dateAfter = ordersPerHoursDates[1].endOf("day").toISOString();

  const { data, isFetching } = useGetOrderHourlyDistributionAnalyticsQuery(
    {
      companyId,
      query: {
        dateBefore,
        dateAfter,
      },
    },
    queryParams,
  );

  const dateMove = useCallback((isForward?: boolean) => {
    const value = isForward ? 1 : -1;

    setOrdersPerHoursDates(prev => [
      prev[0].add(value, "week"),
      prev[1].add(value, "week"),
    ]);
  }, []);

  const orderColumns = useMemo(
    () => createColumns(ordersPerHoursDates[0]),
    [ordersPerHoursDates],
  );

  const customWeekStartEndFormat: DatePickerProps["format"] = useCallback(
    value =>
      `${dayjs(value).startOf("week").format("DD/MM/YYYY")} - ${dayjs(value)
        .endOf("week")
        .format("DD/MM/YYYY")}`,
    [],
  );

  const desktopTableCalendarSuffix = (
    <FlexContainer $gap={4}>
      <BackIcon
        icon={<ArrowDownIcon fill={theme.color.text.tertiary} />}
        onClick={() => dateMove()}
      />

      <DatePicker
        style={{ width: 215, textAlign: "center" }}
        picker="week"
        suffixIcon={null}
        allowClear={false}
        format={customWeekStartEndFormat}
        value={ordersPerHoursDates[0]}
        onChange={v =>
          setOrdersPerHoursDates([
            v.startOf("week").startOf("day"),
            v.endOf("week").endOf("day"),
          ])
        }
      />

      <ForwardIcon
        icon={<ArrowDownIcon fill={theme.color.text.tertiary} />}
        onClick={() => dateMove(true)}
      />
    </FlexContainer>
  );

  const mobileTableSuffix = (
    <DropDown
      trigger={["click"]}
      items={[]}
      dropdownRender={() => (
        <MobileTableDateWrapper>
          <DatePicker
            style={{ width: 215, textAlign: "center" }}
            picker="week"
            suffixIcon={null}
            allowClear={false}
            format={customWeekStartEndFormat}
            value={ordersPerHoursDates[0]}
            onChange={v =>
              setOrdersPerHoursDates([
                v.startOf("week").startOf("day"),
                v.endOf("week").endOf("day"),
              ])
            }
          />
        </MobileTableDateWrapper>
      )}
    >
      <Button.SquaredIcon icon={<CalendarTodayIcon />} />
    </DropDown>
  );

  return (
    <Table
      rowKey="time"
      isLoading={isFetching}
      ordersCalendarTable={true}
      dataSource={data?.items || []}
      columns={orderColumns}
      header={{
        leftTitle: "Orders per hours",
        suffix: isLargeMobile ? mobileTableSuffix : desktopTableCalendarSuffix,
      }}
    />
  );
});

CalendarTable.displayName = "CalendarTable";
