// Lib
import { FC, memo, useCallback, useMemo, useState } from "react";
// Api
import { useDeleteMenuScheduleMutation } from "rtkQuery/query/menuSchedulingAPI";
// Hooks
import { useNotification, useTable, useViewport } from "hooks";
// Types
import { TableAction } from "types/common";
import { ETable, FilterOption } from "types/tableFilters";
import { MenusSchedule } from "types/menuScheduling";
import { ListModalData } from "../../../../../../components/ListModal/types";
// Theme
import { theme } from "theme";
// Constants
import { NOTIFICATIONS } from "consts";
// Helpers
import { createColumns } from "../../helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PencilIcon, PlusIcon, TrashIcon } from "icons";
// Components
import { ConfirmDialog, Table, ListModal } from "components";
// Styled
import { Button } from "styled/Buttons";

interface MenuSchedulingProps {
  isLoading: boolean;
  filtersData: FilterOption[];
  data: MenusSchedule[];
  handleOpenScheduleModal: (value: true | MenusSchedule) => void;
}

export const MenuScheduling: FC<MenuSchedulingProps> = memo(
  ({ isLoading, filtersData, data, handleOpenScheduleModal }) => {
    const { isDesktop } = useViewport();

    const { openNotification } = useNotification();

    const { selectedFilters, handleSetTableFilterValues } = useTable({
      name: ETable.MenuScheduling,
      removeQueryParams: true,
    });

    const [remove, { isLoading: isDeleteLoading }] =
      useDeleteMenuScheduleMutation();

    const [isListModal, setIsListModal] = useState<ListModalData | false>(
      false,
    );

    const [scheduleDeleteModal, setScheduleDeleteModal] = useState<
      string | false
    >(false);

    const handleOpenListModal = useCallback((data: ListModalData) => {
      setIsListModal(data);
    }, []);

    const handleCloseListModal = useCallback(() => {
      setIsListModal(false);
    }, []);

    const handleDeleteConfirm = useCallback(async () => {
      if (!scheduleDeleteModal) {
        return;
      }

      try {
        await remove({ id: scheduleDeleteModal }).unwrap();

        openNotification({ message: NOTIFICATIONS.SCHEDULE_DELETED });

        setScheduleDeleteModal(false);
      } catch (error) {
        errorHandler({ error, openNotification });
      }
    }, [scheduleDeleteModal]);

    const columns = useMemo(
      () => createColumns({ handleOpenListModal }),
      [handleOpenListModal],
    );

    const actions: TableAction[] = useMemo(
      () => [
        {
          title: "",
          Icon: PencilIcon,
          onClick: (record: MenusSchedule) => handleOpenScheduleModal(record),
        },
        {
          title: "",
          type: "Delete",
          Icon: TrashIcon,
          onClick: (row: MenusSchedule) => setScheduleDeleteModal(row.id),
        },
      ],
      [],
    );

    return (
      <>
        <Table
          isLoading={isLoading}
          header={{
            totalCount: 5,
            filter: {
              filters: filtersData,
              selected: selectedFilters,
              setValue: handleSetTableFilterValues,
            },
            suffix: isDesktop ? (
              <Button.Form
                type="primary"
                icon={<PlusIcon fill={theme.color.white} />}
                onClick={() => handleOpenScheduleModal(true)}
              >
                Add Schedule
              </Button.Form>
            ) : (
              <Button.SquaredIcon
                type="primary"
                icon={<PlusIcon fill={theme.color.white} />}
                onClick={() => handleOpenScheduleModal(true)}
              />
            ),
          }}
          dataSource={data}
          columns={columns}
          actions={actions}
        />

        <ListModal data={isListModal} onClose={handleCloseListModal} />

        <ConfirmDialog
          open={!!scheduleDeleteModal}
          Icon={TrashIcon}
          message="This schedule will be deleted"
          description="Are you sure to continue this action?"
          onCancel={() => setScheduleDeleteModal(false)}
          firstCTAButton={{
            title: "Delete Schedule",
            status: "danger",
            loading: isDeleteLoading,
            onClick: handleDeleteConfirm,
          }}
        />
      </>
    );
  },
);

MenuScheduling.displayName = "MenuScheduling";
