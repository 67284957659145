// Lib
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Api
import {
  useGetLocationsQuery,
  useGetOpeningHoursQuery,
  useUpdateOpeningHoursMutation,
} from "rtkQuery/query/locationsAPI";
// Hooks
import { useNotification, usePermissions, useViewport } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { OpeningHoursFormTypes } from "types/locations";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS } from "consts";
// Helpers
import { convertToFormData, convertToRequestData } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { ArrowBackLink, Loader } from "components";
import { OpeningHoursForm } from "./components";
// Styled
import { Typography } from "styled/Typography";
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";

export const OpeningHours: FC = () => {
  const { isMobile } = useViewport();

  const { id } = useParams();
  const companyId = useAppSelector(getUserCompanyId);

  const navigate = useNavigate();

  const { openNotification } = useNotification();

  const { canReadSite, canUpdateSite } = usePermissions();

  if (!canReadSite) {
    navigate(ADMIN_ROUTES.LOCATIONS.path);
  }

  const { data: locationsData } = useGetLocationsQuery({
    companyId,
  });

  const {
    data,
    isFetching: isGetOpeningHoursFetching,
    isError,
    error,
  } = useGetOpeningHoursQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isError) {
      errorHandler({ error, openNotification });
    }
  }, [isError, error]);

  const [updateOpeningHours, { isLoading: isUpdateOpeningHoursLoading }] =
    useUpdateOpeningHoursMutation();

  const handleSendRequest = async (formValues: OpeningHoursFormTypes) => {
    const data = {
      locations: formValues.locations.map(
        el => locationsData?.find(l => l.name === el)?.id,
      ),
      days: {
        monday: convertToRequestData(formValues.days.monday),
        tuesday: convertToRequestData(formValues.days.tuesday),
        wednesday: convertToRequestData(formValues.days.wednesday),
        thursday: convertToRequestData(formValues.days.thursday),
        friday: convertToRequestData(formValues.days.friday),
        saturday: convertToRequestData(formValues.days.saturday),
        sunday: convertToRequestData(formValues.days.sunday),
      },
    };
    try {
      await updateOpeningHours({ id, data }).unwrap();

      openNotification({ message: NOTIFICATIONS.OPENING_HOURS_UPDATED });

      navigate(ADMIN_ROUTES.LOCATIONS.path);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const isLoading = isUpdateOpeningHoursLoading || isGetOpeningHoursFetching;

  const selectOptions = locationsData?.map(loc => ({
    id: loc.id,
    name: loc.name,
  }));

  return (
    <>
      {isLoading && <Loader />}

      <FlexContainer $column $gap={24}>
        <PageHeadingContainer>
          <PageTitleContainer $column $gap={16}>
            <ArrowBackLink
              title="Back to Location"
              path={ADMIN_ROUTES.LOCATIONS.path}
            />

            <Typography.H1>
              {isMobile ? "Edit opening hours" : "Bulk edit opening hours"}
            </Typography.H1>
          </PageTitleContainer>
        </PageHeadingContainer>

        {data && (
          <OpeningHoursForm
            disabled={!canUpdateSite}
            openingHoursData={convertToFormData(data)}
            handleSendRequest={handleSendRequest}
            selectOptions={selectOptions}
          />
        )}
      </FlexContainer>
    </>
  );
};
