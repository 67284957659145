// Lib
import { FC } from "react";
// Styled
import { StyledAvatarImage, StyledAvatarPlaceholder } from "styled/Box";

interface AvatarProps {
  image?: string;
  name?: string;
}

export const Avatar: FC<AvatarProps> = ({ image, name }) => {
  if (image) {
    return <StyledAvatarImage src={image} />;
  }

  return (
    <StyledAvatarPlaceholder>
      {Array.from(name as string)[0]?.toUpperCase()}
    </StyledAvatarPlaceholder>
  );
};
