import { Role, roleLabel } from "consts/roles";
import { UserFormTypes } from "./types";

export const defaultValues: UserFormTypes = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  role: "",
  locations: [],
  permissions: [],
};

export const rolesOptions = [
  { label: roleLabel[Role.AccountManager], value: Role.AccountManager },
  { label: roleLabel[Role.Admin], value: Role.Admin },
  { label: roleLabel[Role.Manager], value: Role.Manager },
  { label: roleLabel[Role.LoopAdmin], value: Role.LoopAdmin },
];
