import dayjs from "dayjs";

import { dateTransform, isTimeBetween } from "helpers/dataHelpers";

import {
  analyticsChartLocationsColors,
  mainChartLineConfig,
  mainLegendConfig,
  prevChartLineConfig,
  prevLegendConfig,
} from "pages/Loop/Analytics/config";

import {
  createSlotCurrentLocationValues,
  generateDates,
  getFormattedDate,
  getRandomColor,
} from "pages/Loop/Analytics/helpers";

import { OrderStatus } from "types/orders";

import {
  AnalitycsOrderItem,
  AnalyticsPeriodGroupByValues,
  AnalyticsState,
  AnalyticsStateDateFilterValue,
  AnalyticsStatsSummaryKey,
  ChartCompareValues,
  ChartLinesData,
  ChartSlotData,
  CreateChartDataOutput,
  GetOrdersAnalyticsResponse,
  MergedChartSlotData,
} from "types/analytics";

import {
  ChartLegendConfig,
  ChartLinesConfig,
} from "components/Chart/LineChart/types";

export const createOrderTabs = (data: GetOrdersAnalyticsResponse) => {
  if (!data) {
    return [];
  }

  return [
    {
      dataKey: AnalyticsStatsSummaryKey.TotalOrders,
      value: Number(data.current.totalOrders),
      prevValue: Number(data.prev.totalOrders),
    },
    {
      dataKey: AnalyticsStatsSummaryKey.Revenue,
      value: Number(data.current.totalRevenue),
      prevValue: Number(data.prev.totalRevenue),
    },
    {
      dataKey: AnalyticsStatsSummaryKey.AverageOrderValue,
      value: Number(data.current.averageOrderValue),
      prevValue: Number(data.prev.averageOrderValue),
    },
    {
      dataKey: AnalyticsStatsSummaryKey.OrderIssueOvertime,
      value: Number(data.current.totalFailedOrders),
      prevValue: Number(data.prev.totalFailedOrders),
    },
  ];
};

const getOrdersFilteredByDates = (
  data: AnalitycsOrderItem[],
  dateFilter: AnalyticsStateDateFilterValue,
): {
  current: AnalitycsOrderItem[];
  prev: AnalitycsOrderItem[];
  groupBy: AnalyticsPeriodGroupByValues;
} => {
  const result = {
    current: [],
    prev: [],
  };

  const { current, prev, groupBy } = generateDates(dateFilter);

  data.forEach(element => {
    if (isTimeBetween(current.startTime, current.endTime, element.updatedAt)) {
      result.current.push(element);
    }
    if (isTimeBetween(prev.startTime, prev.endTime, element.updatedAt)) {
      result.prev.push(element);
    }
  });

  return { ...result, groupBy };
};

const getChartComparedWithLocationsLinesData = (
  data: {
    current: AnalitycsOrderItem[];
    prev: AnalitycsOrderItem[];
    groupBy: AnalyticsPeriodGroupByValues;
  },
  dateFilter: AnalyticsStateDateFilterValue,
  locations: {
    label: string;
    value: string;
  }[],
  activeLocation: string,
): ChartLinesData => {
  const { groupBy, current } = generateDates(dateFilter);

  const locationsIds = locations.map(location => location.value);

  const slotsData: MergedChartSlotData[] = [];

  if (groupBy === AnalyticsPeriodGroupByValues.Hour) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("hour");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot: MergedChartSlotData = {
        name: `${dateTransform({
          date: currentSlot,
          format: "HH:mm",
        })} - ${dateTransform({ date: endDate, format: "HH:mm" })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Hour,
        [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
          locationsIds,
          isFuture,
        ),
        [AnalyticsStatsSummaryKey.AverageOrderValue]:
          createSlotCurrentLocationValues(locationsIds, isFuture),
        [AnalyticsStatsSummaryKey.OrderIssueOvertime]:
          createSlotCurrentLocationValues(locationsIds, isFuture),
        [AnalyticsStatsSummaryKey.TotalOrders]: createSlotCurrentLocationValues(
          locationsIds,
          isFuture,
        ),
        [AnalyticsStatsSummaryKey.TotalRevenue]:
          createSlotCurrentLocationValues(locationsIds, isFuture),
      };

      slotsData.push(slot);

      currentSlot = currentSlot.add(1, "hour");
    }
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Day) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("day");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "DD MMM",
        })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Day,

        [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
          locationsIds,
          isFuture,
        ),
        [AnalyticsStatsSummaryKey.AverageOrderValue]:
          createSlotCurrentLocationValues(locationsIds, isFuture),
        [AnalyticsStatsSummaryKey.OrderIssueOvertime]:
          createSlotCurrentLocationValues(locationsIds, isFuture),
        [AnalyticsStatsSummaryKey.TotalOrders]: createSlotCurrentLocationValues(
          locationsIds,
          isFuture,
        ),
        [AnalyticsStatsSummaryKey.TotalRevenue]:
          createSlotCurrentLocationValues(locationsIds, isFuture),
      };

      slotsData.push(slot);

      currentSlot = currentSlot.add(1, "day");
    }
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Week) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const calculatedEndTime = dayjs(currentSlot).add(6, "days").endOf("day");

      const endDate = calculatedEndTime.isBefore(dayjs(current.endTime))
        ? calculatedEndTime
        : dayjs(current.endTime);

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "DD MMM",
        })} - ${dateTransform({
          date: endDate,
          format: "DD MMM",
        })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Week,

        [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
          locationsIds,
          isFuture,
        ),
        [AnalyticsStatsSummaryKey.AverageOrderValue]:
          createSlotCurrentLocationValues(locationsIds, isFuture),
        [AnalyticsStatsSummaryKey.OrderIssueOvertime]:
          createSlotCurrentLocationValues(locationsIds, isFuture),
        [AnalyticsStatsSummaryKey.TotalOrders]: createSlotCurrentLocationValues(
          locationsIds,
          isFuture,
        ),
        [AnalyticsStatsSummaryKey.TotalRevenue]:
          createSlotCurrentLocationValues(locationsIds, isFuture),
      };

      slotsData.push(slot);

      currentSlot = currentSlot.add(7, "days").startOf("day");
      // currentSlot = currentSlot.add(1, "week").startOf("week");
    }
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Month) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("month");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "MMM",
        })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Month,

        [AnalyticsStatsSummaryKey.Revenue]: createSlotCurrentLocationValues(
          locationsIds,
          isFuture,
        ),
        [AnalyticsStatsSummaryKey.AverageOrderValue]:
          createSlotCurrentLocationValues(locationsIds, isFuture),
        [AnalyticsStatsSummaryKey.OrderIssueOvertime]:
          createSlotCurrentLocationValues(locationsIds, isFuture),
        [AnalyticsStatsSummaryKey.TotalOrders]: createSlotCurrentLocationValues(
          locationsIds,
          isFuture,
        ),
        [AnalyticsStatsSummaryKey.TotalRevenue]:
          createSlotCurrentLocationValues(locationsIds, isFuture),
      };

      slotsData.push(slot);

      currentSlot = currentSlot.add(1, "month").startOf("month");
    }
  }

  data.current.forEach(order => {
    const slotIndex = slotsData.findIndex(slot =>
      isTimeBetween(slot.slotStartTime, slot.slotEndTime, order.updatedAt),
    );

    const isFailed = order.status === OrderStatus.FAILED;
    const isCanceled = order.status === OrderStatus.CANCELED;
    const isDelivered = order.status === OrderStatus.DELIVERED;

    if (isFailed || isCanceled || isDelivered) {
      if (slotIndex !== undefined && !!slotsData[slotIndex]) {
        slotsData[slotIndex][AnalyticsStatsSummaryKey.TotalOrders][
          order?.site?.id
        ] =
          (slotsData[slotIndex][AnalyticsStatsSummaryKey.TotalOrders][
            order?.site?.id
          ] || 0) + 1;

        slotsData[slotIndex][AnalyticsStatsSummaryKey.TotalRevenue][
          order?.site?.id
        ] =
          (slotsData[slotIndex][AnalyticsStatsSummaryKey.TotalRevenue][
            order?.site?.id
          ] || 0) + Number(order.totalFiatCentsPrice);

        slotsData[slotIndex][AnalyticsStatsSummaryKey.Revenue][
          order?.site?.id
        ] =
          (slotsData[slotIndex][AnalyticsStatsSummaryKey.Revenue][
            order?.site?.id
          ] || 0) + (isDelivered ? Number(order.totalFiatCentsPrice) : 0);

        slotsData[slotIndex][AnalyticsStatsSummaryKey.AverageOrderValue][
          order?.site?.id
        ] =
          slotsData[slotIndex][AnalyticsStatsSummaryKey.TotalRevenue][
            order?.site?.id
          ] /
          slotsData[slotIndex][AnalyticsStatsSummaryKey.TotalOrders][
            order?.site?.id
          ];

        slotsData[slotIndex][AnalyticsStatsSummaryKey.OrderIssueOvertime][
          order?.site?.id
        ] =
          (slotsData[slotIndex][AnalyticsStatsSummaryKey.OrderIssueOvertime][
            order?.site?.id
          ] || 0) + (isFailed ? 1 : 0);
      }
    }
  });

  const locationsChartData = locations.reduce((acc, el, i) => {
    const color = analyticsChartLocationsColors[i] || getRandomColor();
    const isActiveLocation = activeLocation === el.value;

    acc[el.value] = {
      title: el.label || el.value,
      color,
      dataKey: el.value,
      stroke: color,
      activeDot: { r: isActiveLocation ? 6 : 0, stroke: color, fill: "#fff" },
    };

    return acc;
  }, {});

  const legendConfig: ChartLegendConfig[] = locations.map((location, i) => ({
    title: locationsChartData[location.value].title,
    color: locationsChartData[location.value].color,
  }));

  const linesConfig: ChartLinesConfig[] = locations.map((location, i) => ({
    dataKey: locationsChartData[location.value].dataKey,
    stroke: locationsChartData[location.value].stroke,
    activeDot: locationsChartData[location.value].activeDot,
  }));

  return {
    slotsData,
    legendConfig,
    linesConfig,
  };
};

const getChartComparedWithPrevLinesData = (
  data: {
    current: AnalitycsOrderItem[];
    prev: AnalitycsOrderItem[];
    groupBy: AnalyticsPeriodGroupByValues;
  },
  dateFilter: AnalyticsStateDateFilterValue,
): ChartLinesData => {
  const { groupBy, current, prev } = generateDates(dateFilter);

  const currentSlots: ChartSlotData[] = [];
  const prevSlots: ChartSlotData[] = [];

  if (groupBy === AnalyticsPeriodGroupByValues.Hour) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("hour");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "HH:mm",
        })} - ${dateTransform({ date: endDate, format: "HH:mm" })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Hour,
        data: {
          [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.AverageOrderValue]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.TotalOrders]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.TotalRevenue]: isFuture ? null : 0,
        },
      };

      currentSlots.push(slot);

      currentSlot = currentSlot.add(1, "hour");
    }

    let prevSlot = dayjs(prev.startTime);

    while (dayjs(prevSlot).isBefore(dayjs(prev.endTime))) {
      const endDate = dayjs(prevSlot).endOf("hour");

      const slot = {
        name: `${dateTransform({
          date: prevSlot,
          format: "HH:mm",
        })} - ${dateTransform({ date: endDate, format: "HH:mm" })}`,
        slotStartTime: prevSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Hour,
        data: {
          [AnalyticsStatsSummaryKey.Revenue]: 0,
          [AnalyticsStatsSummaryKey.AverageOrderValue]: 0,
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]: 0,
          [AnalyticsStatsSummaryKey.TotalOrders]: 0,
          [AnalyticsStatsSummaryKey.TotalRevenue]: 0,
        },
      };

      prevSlots.push(slot);

      prevSlot = prevSlot.add(1, "hour");
    }
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Day) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("day");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "DD MMM",
        })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Day,
        data: {
          [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.AverageOrderValue]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.TotalOrders]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.TotalRevenue]: isFuture ? null : 0,
        },
      };

      currentSlots.push(slot);

      currentSlot = currentSlot.add(1, "day");
    }

    let prevSlot = dayjs(prev.startTime);

    while (dayjs(prevSlot).isBefore(dayjs(prev.endTime))) {
      const endDate = dayjs(prevSlot).endOf("day");

      const slot = {
        name: `${dateTransform({
          date: prevSlot,
          format: "DD MMM",
        })}`,
        slotStartTime: prevSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Day,
        data: {
          [AnalyticsStatsSummaryKey.Revenue]: 0,
          [AnalyticsStatsSummaryKey.AverageOrderValue]: 0,
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]: 0,
          [AnalyticsStatsSummaryKey.TotalOrders]: 0,
          [AnalyticsStatsSummaryKey.TotalRevenue]: 0,
        },
      };

      prevSlots.push(slot);

      prevSlot = prevSlot.add(1, "day");
    }
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Week) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const calculatedEndTime = dayjs(currentSlot).add(6, "days").endOf("day");

      const endDate = calculatedEndTime.isBefore(dayjs(current.endTime))
        ? calculatedEndTime
        : dayjs(current.endTime);

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "DD MMM",
        })} - ${dateTransform({
          date: endDate,
          format: "DD MMM",
        })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Week,
        data: {
          [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.AverageOrderValue]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.TotalOrders]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.TotalRevenue]: isFuture ? null : 0,
        },
      };

      currentSlots.push(slot);

      // currentSlot = currentSlot.add(1, "week").startOf("week");
      currentSlot = currentSlot.add(7, "days").startOf("day");
    }

    let prevSlot = dayjs(prev.startTime);

    while (dayjs(prevSlot).isBefore(dayjs(prev.endTime))) {
      const calculatedEndTime = dayjs(prevSlot).add(6, "days").endOf("day");

      const endDate = calculatedEndTime.isBefore(dayjs(prev.endTime))
        ? calculatedEndTime
        : dayjs(prev.endTime);

      const slot = {
        name: `${dateTransform({
          date: prevSlot,
          format: "DD MMM",
        })} - ${dateTransform({
          date: endDate,
          format: "DD MMM",
        })}`,
        slotStartTime: prevSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Week,
        data: {
          [AnalyticsStatsSummaryKey.Revenue]: 0,
          [AnalyticsStatsSummaryKey.AverageOrderValue]: 0,
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]: 0,
          [AnalyticsStatsSummaryKey.TotalOrders]: 0,
          [AnalyticsStatsSummaryKey.TotalRevenue]: 0,
        },
      };

      prevSlots.push(slot);

      // prevSlot = prevSlot.add(1, "week").startOf("week");
      prevSlot = prevSlot.add(7, "days").startOf("day");
    }
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Month) {
    let currentSlot = dayjs(current.startTime);

    while (dayjs(currentSlot).isBefore(dayjs(current.endTime))) {
      const endDate = dayjs(currentSlot).endOf("month");

      const isFuture = dayjs().isBefore(currentSlot);

      const slot = {
        name: `${dateTransform({
          date: currentSlot,
          format: "MMM",
        })}`,
        slotStartTime: currentSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Month,
        data: {
          [AnalyticsStatsSummaryKey.Revenue]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.AverageOrderValue]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.TotalOrders]: isFuture ? null : 0,
          [AnalyticsStatsSummaryKey.TotalRevenue]: isFuture ? null : 0,
        },
      };

      currentSlots.push(slot);

      currentSlot = currentSlot.add(1, "month").startOf("month");
    }

    let prevSlot = dayjs(prev.startTime);

    while (dayjs(prevSlot).isBefore(dayjs(prev.endTime))) {
      const endDate = dayjs(prevSlot).endOf("month");

      const slot = {
        name: `${dateTransform({
          date: prevSlot,
          format: "MMM",
        })}`,
        slotStartTime: prevSlot,
        slotEndTime: endDate,
        groupBy: AnalyticsPeriodGroupByValues.Month,
        data: {
          [AnalyticsStatsSummaryKey.Revenue]: 0,
          [AnalyticsStatsSummaryKey.AverageOrderValue]: 0,
          [AnalyticsStatsSummaryKey.OrderIssueOvertime]: 0,
          [AnalyticsStatsSummaryKey.TotalOrders]: 0,
          [AnalyticsStatsSummaryKey.TotalRevenue]: 0,
        },
      };

      prevSlots.push(slot);

      prevSlot = prevSlot.add(1, "month").startOf("month");
    }
  }

  data.current.forEach(order => {
    const slotIndex = currentSlots.findIndex(slot =>
      isTimeBetween(slot.slotStartTime, slot.slotEndTime, order.updatedAt),
    );

    const isFailed = order.status === OrderStatus.FAILED;
    const isCanceled = order.status === OrderStatus.CANCELED;
    const isDelivered = order.status === OrderStatus.DELIVERED;

    if (isFailed || isCanceled || isDelivered) {
      if (slotIndex !== undefined && !!currentSlots[slotIndex]) {
        const isFailed = order.status === OrderStatus.FAILED;

        currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.TotalOrders] =
          (currentSlots[slotIndex].data?.[
            AnalyticsStatsSummaryKey.TotalOrders
          ] || 0) + 1;

        currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.Revenue] =
          (currentSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.Revenue] ||
            0) + (isDelivered ? Number(order.totalFiatCentsPrice) : 0);

        currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.TotalRevenue] =
          (currentSlots[slotIndex].data?.[
            AnalyticsStatsSummaryKey.TotalRevenue
          ] || 0) + Number(order.totalFiatCentsPrice);

        currentSlots[slotIndex].data[
          AnalyticsStatsSummaryKey.OrderIssueOvertime
        ] =
          (currentSlots[slotIndex].data?.[
            AnalyticsStatsSummaryKey.OrderIssueOvertime
          ] || 0) + (isFailed ? 1 : 0);

        currentSlots[slotIndex].data[
          AnalyticsStatsSummaryKey.AverageOrderValue
        ] = Number(
          (
            currentSlots[slotIndex].data[
              AnalyticsStatsSummaryKey.TotalRevenue
            ] /
            currentSlots[slotIndex].data[AnalyticsStatsSummaryKey.TotalOrders]
          ).toFixed(3),
        );
      }
    }
  });

  data.prev.forEach(order => {
    const slotIndex = prevSlots.findIndex(slot =>
      isTimeBetween(slot.slotStartTime, slot.slotEndTime, order.updatedAt),
    );

    const isFailed = order.status === OrderStatus.FAILED;
    const isCanceled = order.status === OrderStatus.CANCELED;
    const isDelivered = order.status === OrderStatus.DELIVERED;

    if (isFailed || isCanceled || isDelivered) {
      if (slotIndex !== undefined && !!prevSlots[slotIndex]) {
        prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.TotalOrders] =
          (prevSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.TotalOrders] ||
            0) + 1;

        prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.Revenue] =
          (prevSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.Revenue] || 0) +
          (isDelivered ? Number(order.totalFiatCentsPrice) : 0);

        prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.TotalRevenue] =
          (prevSlots[slotIndex].data?.[AnalyticsStatsSummaryKey.TotalRevenue] ||
            0) + Number(order.totalFiatCentsPrice);

        prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.OrderIssueOvertime] =
          (prevSlots[slotIndex].data?.[
            AnalyticsStatsSummaryKey.OrderIssueOvertime
          ] || 0) + (isFailed ? 1 : 0);

        prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.AverageOrderValue] =
          Number(
            (
              prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.TotalRevenue] /
              prevSlots[slotIndex].data[AnalyticsStatsSummaryKey.TotalOrders]
            ).toFixed(3),
          );
      }
    }
  });

  const isNeedAdditinalSlots = prevSlots?.length > currentSlots?.length;

  let slotsData: MergedChartSlotData[] = [];

  if (!isNeedAdditinalSlots) {
    const slots: MergedChartSlotData[] = currentSlots.map((el, i) => {
      const { data, ...rest } = el;

      return {
        ...rest,
        [AnalyticsStatsSummaryKey.TotalOrders]: {
          current: data?.[AnalyticsStatsSummaryKey.TotalOrders],
          prev:
            prevSlots[i]?.data?.[AnalyticsStatsSummaryKey.TotalOrders] !==
            undefined
              ? prevSlots[i].data[AnalyticsStatsSummaryKey.TotalOrders]
              : null,
        },
        [AnalyticsStatsSummaryKey.Revenue]: {
          current: data[AnalyticsStatsSummaryKey.Revenue],
          prev:
            prevSlots[i]?.data?.[AnalyticsStatsSummaryKey.Revenue] !== undefined
              ? prevSlots[i].data[AnalyticsStatsSummaryKey.Revenue]
              : null,
        },
        [AnalyticsStatsSummaryKey.AverageOrderValue]: {
          current: data[AnalyticsStatsSummaryKey.AverageOrderValue],
          prev:
            prevSlots[i]?.data?.[AnalyticsStatsSummaryKey.AverageOrderValue] !==
            undefined
              ? prevSlots[i].data[AnalyticsStatsSummaryKey.AverageOrderValue]
              : null,
        },
        [AnalyticsStatsSummaryKey.OrderIssueOvertime]: {
          current: data[AnalyticsStatsSummaryKey.OrderIssueOvertime],
          prev:
            prevSlots[i]?.data?.[
              AnalyticsStatsSummaryKey.OrderIssueOvertime
            ] !== undefined
              ? prevSlots[i].data[AnalyticsStatsSummaryKey.OrderIssueOvertime]
              : null,
        },
        prevSlotStartTime: prevSlots[i]?.slotStartTime || null,
        prevSlotEndTime: prevSlots[i]?.slotEndTime || null,
      };
    });

    slotsData = slotsData.concat(slots);
  }

  if (isNeedAdditinalSlots) {
    const slots: MergedChartSlotData[] = prevSlots.map((el, i) => {
      const { data, name, slotStartTime, slotEndTime, ...rest } = el;

      return {
        ...rest,
        name: currentSlots[i]?.name || `(${name})`,
        [AnalyticsStatsSummaryKey.TotalOrders]: {
          prev: data?.[AnalyticsStatsSummaryKey.TotalOrders],
          current:
            currentSlots[i]?.data?.[AnalyticsStatsSummaryKey.TotalOrders] !==
            undefined
              ? currentSlots[i]?.data[AnalyticsStatsSummaryKey.TotalOrders]
              : null,
        },
        [AnalyticsStatsSummaryKey.Revenue]: {
          prev: data[AnalyticsStatsSummaryKey.Revenue],
          current:
            currentSlots[i]?.data?.[AnalyticsStatsSummaryKey.Revenue] !==
            undefined
              ? currentSlots[i]?.data[AnalyticsStatsSummaryKey.Revenue]
              : null,
        },
        [AnalyticsStatsSummaryKey.AverageOrderValue]: {
          prev: data[AnalyticsStatsSummaryKey.AverageOrderValue],
          current:
            currentSlots[i]?.data?.[
              AnalyticsStatsSummaryKey.AverageOrderValue
            ] !== undefined
              ? currentSlots[i]?.data[
                  AnalyticsStatsSummaryKey.AverageOrderValue
                ]
              : null,
        },
        [AnalyticsStatsSummaryKey.OrderIssueOvertime]: {
          prev: data[AnalyticsStatsSummaryKey.OrderIssueOvertime],
          current:
            currentSlots[i]?.data?.[
              AnalyticsStatsSummaryKey.OrderIssueOvertime
            ] !== undefined
              ? currentSlots[i]?.data[
                  AnalyticsStatsSummaryKey.OrderIssueOvertime
                ]
              : null,
        },
        slotStartTime: currentSlots[i]?.slotStartTime || null,
        slotEndTime: currentSlots[i]?.slotEndTime || null,
        prevSlotStartTime: slotStartTime,
        prevSlotEndTime: slotEndTime,
      };
    });

    slotsData = slotsData.concat(slots);
  }

  const legendConfig = [
    {
      ...mainLegendConfig,
      title: `Current period (${getFormattedDate(
        current.startTime,
      )} - ${getFormattedDate(current.endTime)})`,
    },
    {
      ...prevLegendConfig,
      title: `Previous period (${getFormattedDate(
        prev.startTime,
      )} - ${getFormattedDate(prev.endTime)})`,
    },
  ];

  return {
    slotsData,
    legendConfig,
    linesConfig: [mainChartLineConfig, prevChartLineConfig],
  };
};

export const createOrdersChartLines = ({
  orders,
  analyticsFilters,
  locations,
}: {
  orders: AnalitycsOrderItem[];
  analyticsFilters: AnalyticsState;
  locations: { label: string; value: string }[];
}): CreateChartDataOutput => {
  if (!locations?.length || !orders) {
    return {
      chartLinesData: { slotsData: [], linesConfig: [], legendConfig: [] },
    };
  }

  const isCompareBetweenLocations =
    analyticsFilters.comparing === ChartCompareValues.SelectedLocation;

  const filteredByDateOrders = getOrdersFilteredByDates(
    orders,
    analyticsFilters.dateFilter,
  );

  const chartLinesData: ChartLinesData = isCompareBetweenLocations
    ? getChartComparedWithLocationsLinesData(
        filteredByDateOrders,
        analyticsFilters.dateFilter,
        locations,
        analyticsFilters.location,
      )
    : getChartComparedWithPrevLinesData(
        filteredByDateOrders,
        analyticsFilters.dateFilter,
      );

  return { chartLinesData };
};
