import styled from "styled-components";
import { theme } from "theme";
import { Typography } from "styled/Typography";

export const Dot = styled.div<{ $color?: string }>`
  background-color: ${props => props.$color || theme.color.status.neutral};
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const Text = styled(Typography.Title)`
  text-transform: capitalize;
`;
