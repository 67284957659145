import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

const schema = object({
  name: string()
    .trim()
    .min(3, "Tag name must be more than 3 characters")
    .max(50, "Tag name must be less than 50 characters")
    .required("Tag name is required"),
  imageUrl: string().nullable(),
}).required();

export const resolver = yupResolver(schema);
