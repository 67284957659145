// Lib
import { FC } from "react";
// Types
import { OrderHistories } from "types/orders";
// Components
import { OrderStatusItem } from "../OrderStatusItem";
// Styled
import { ContentBox } from "styled/Box";
import { Typography } from "styled/Typography";

interface StatusTabProps {
  orderHistories: OrderHistories[];
}

export const StatusTab: FC<StatusTabProps> = ({ orderHistories }) => {
  // const orderInfo = mockedOrdersResponse.items.find(item => item.id === id);

  // const statusHistory = [...orderInfo.orderStatusHistory].reverse();
  // const chargeStatusHistory = [...orderInfo.chargeStatusHistory].reverse();

  return (
    <>
      {/* <Row> */}
      {/* <Col className="gutter-row" span={12}> */}
      <ContentBox $column $margin="16px 0">
        <Typography.H2>Status History</Typography.H2>

        {!!orderHistories?.length &&
          orderHistories.map(({ status, createdAt }, i) => (
            <OrderStatusItem
              active={i === 0}
              key={createdAt}
              status={status}
              date={createdAt}
              isLast={orderHistories.length - 1 === i}
            />
          ))}
      </ContentBox>

      {/* <ContentBox column margin="16px 0">
            <Typography.H2>Courier Update History</Typography.H2>
            {statusHistory?.map((order, i) => (
              <OrderStatusItem
                active={i === 0}
                key={order.timestamp}
                status={order.newStatus}
                date={order.timestamp}
                isLast={statusHistory.length - 1 === i}
              />
            ))}
          </ContentBox> */}
      {/* </Col> */}

      {/* <Col className="gutter-row" span={12}>
          <ContentBox column margin="16px">
            <Typography.H2>Channel Status History</Typography.H2>

            {chargeStatusHistory?.map((order, i) => (
              <OrderStatusItem
                active={i === 0}
                key={order.timestamp}
                status={order.newStatus}
                date={order.timestamp}
                isLast={chargeStatusHistory.length - 1 === i}
              />
            ))}
          </ContentBox>
        </Col> */}
      {/* </Row> */}
    </>
  );
};
