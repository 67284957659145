// Lib
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api
import { useLazySyncLocationProductsQuery } from "rtkQuery/query/productsAPI";
// Hooks
import { useNotification, usePermissions, useViewport } from "hooks";
// Types
import { StatusType } from "types/common";
import { BusyModeChannelLinksType, ChannelLink } from "types/locations";
// Theme
import { theme } from "theme";
// Helpers
import { isMatch } from "helpers/dataHelpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import {
  PencilIcon,
  PlusIcon,
  RefreshIcon,
  ScheduleIcon,
  TrashIcon,
} from "icons";
// Components
import { ConfirmDialog, Tooltip } from "components";
import { Channel } from "../Channel";
import { BusyModeForm } from "../BusyModeForm";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import {
  ButtonsContainer,
  ChannelId,
  ChannelIdContaniner,
  ChannelItem,
  ChannelLinksContaner,
  ChannelLinksTitle,
  Info,
  Wrapper,
} from "./styled";

interface LocationItemBodyProps {
  locationName: string;
  locationId: string;
  channelLinks: ChannelLink[];
  handleDeleteLocation: () => void;
  updateChannelLinks: (args: {
    id: string;
    data: ChannelLink[];
  }) => Promise<void>;
}

export const LocationItemBody: FC<LocationItemBodyProps> = ({
  locationName,
  locationId,
  channelLinks,
  updateChannelLinks,
  handleDeleteLocation,
}) => {
  const { isMobile } = useViewport();
  const navigate = useNavigate();

  const { openNotification } = useNotification();

  const { canUpdateSite } = usePermissions();

  const [sync, { isLoading: isLoadingSync }] =
    useLazySyncLocationProductsQuery();

  const [deleteChannelLinkModal, setDeleteChannelLinkModal] = useState<
    false | ChannelLink
  >(false);
  const [busyModeChannelLinks, setBusyModeChannelLinks] = useState<
    never[] | BusyModeChannelLinksType[]
  >([]);

  const handleEditLocation = () => {
    navigate(`/loop/location/edit/${locationId}`);
  };

  const handleEditOpeningHours = () => {
    navigate(`/loop/location/opening_hours/${locationId}`);
  };

  const handleEditChannelLink = (id: string) => {
    navigate(`/loop/location/channel_link/${id}/${locationId}`);
  };

  const handleAddChannelLink = () => {
    navigate(`/loop/location/channel_link/create/${locationId}`);
  };

  const handleDeleteChannelLink = (id: string) => {
    const channelLink = channelLinks.find(channelLink => channelLink.id === id);

    if (!channelLink) {
      return;
    }

    setDeleteChannelLinkModal(channelLink);
  };

  const handleDeleteChannelLinkConfirm = () => {
    if (!deleteChannelLinkModal) {
      return;
    }
    //request here
    setDeleteChannelLinkModal(false);
  };

  const showBusyModeForm = () => {
    const links = channelLinks.map(link => ({
      ...link,
      active: link.status === StatusType.Active ? true : false,
    }));

    setBusyModeChannelLinks(links);
  };

  const hideBusyModeForm = (data: BusyModeChannelLinksType[]) => {
    setBusyModeChannelLinks([]);
    if (!isMatch(busyModeChannelLinks, data)) {
      updateChannelLinks({
        id: locationId,
        data: data.map(link => ({
          id: link.id,
          name: link.name,
          siteId: locationId,
          status: link.active ? StatusType.Active : StatusType.Suspended,
          simphonyRevenuCenterId: link.simphonyRevenuCenterId,
        })),
      });
    }
  };

  const handleCopyToClipBoard = async (id: string) => {
    try {
      await navigator.clipboard.writeText(id);
      openNotification({ message: "Copied to clipboard" });
    } catch (error) {
      openNotification({ message: "Something went wrong", type: "error" });
    }
  };

  const handleSyncProducts = async () => {
    try {
      await sync({ locationId }).unwrap();
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  return (
    <>
      <Wrapper $column>
        <ButtonsContainer>
          <Button.Form
            icon={<RefreshIcon fill={theme.color.text.tertiary} />}
            loading={isLoadingSync}
            onClick={handleSyncProducts}
          >
            Sync products
          </Button.Form>

          <Button.Form icon={<PencilIcon />} onClick={handleEditLocation}>
            Edit location
          </Button.Form>

          {canUpdateSite && !channelLinks?.length ? (
            <Button.Form icon={<PlusIcon />} onClick={handleAddChannelLink}>
              Channel link
            </Button.Form>
          ) : null}

          <Button.Form icon={<ScheduleIcon />} onClick={handleEditOpeningHours}>
            Opening hours
          </Button.Form>

          {channelLinks?.length ? (
            <Button.Form icon={<ScheduleIcon />} onClick={showBusyModeForm}>
              Busy mode
            </Button.Form>
          ) : (
            <Tooltip title="Location doesn`t have any Channel links">
              <Button.Form
                icon={<ScheduleIcon />}
                disabled
                onClick={showBusyModeForm}
              >
                Busy mode
              </Button.Form>
            </Tooltip>
          )}

          <Button.Form
            icon={<TrashIcon fill={theme.color.text.tertiary} />}
            onClick={handleDeleteLocation}
          >
            Delete location
          </Button.Form>
        </ButtonsContainer>

        <ChannelLinksContaner $column>
          <ChannelLinksTitle>
            Channel links
            <Tooltip
              custom
              arrow={false}
              title="Channel links link your delivery platforms to your location"
              trigger="click"
              placement="bottom"
            >
              <span>
                <Info />
              </span>
            </Tooltip>
          </ChannelLinksTitle>

          {!!channelLinks?.length &&
            channelLinks.map(({ name, status, id }) => (
              <ChannelItem key={id}>
                <Channel
                  title={name}
                  statusText={
                    status === StatusType.Active ? "Open" : "Disabled"
                  }
                  status={status === StatusType.Active ? "success" : "danger"}
                />

                <ChannelIdContaniner>
                  <Tooltip title="Copy Channel Link ID">
                    <ChannelId onClick={() => handleCopyToClipBoard(id)}>
                      {id}
                    </ChannelId>
                  </Tooltip>

                  <FlexContainer $gap={8} $column={isMobile}>
                    {canUpdateSite ? (
                      <Button.SquaredIcon
                        icon={<PencilIcon />}
                        onClick={() => handleEditChannelLink(id)}
                      />
                    ) : null}

                    <Button.SquaredIcon
                      icon={<TrashIcon fill={theme.color.text.tertiary} />}
                      onClick={() => handleDeleteChannelLink(id)}
                    />
                  </FlexContainer>
                </ChannelIdContaniner>
              </ChannelItem>
            ))}
        </ChannelLinksContaner>
      </Wrapper>

      <BusyModeForm
        locationName={locationName}
        isFormOpen={!!busyModeChannelLinks.length}
        channelLinks={busyModeChannelLinks}
        hideForm={hideBusyModeForm}
      />

      <ConfirmDialog
        open={!!deleteChannelLinkModal}
        Icon={TrashIcon}
        message={
          !!deleteChannelLinkModal &&
          `${deleteChannelLinkModal.name} will be deleted`
        }
        description="Are you sure to continue this action?"
        onCancel={() => setDeleteChannelLinkModal(false)}
        firstCTAButton={{
          title: "Delete",
          status: "danger",
          loading: false,
          onClick: handleDeleteChannelLinkConfirm,
        }}
      />
    </>
  );
};
