// Lib
import { FC, useEffect, useState } from "react";
// Hooks
import { useDebounce } from "hooks";
// Components
import { InputNumber } from "components/Form";

interface EditableCellProps {
  initialValue: number;
  onCellValueChange: (arg: number) => void;
}

export const EditableCell: FC<EditableCellProps> = ({
  initialValue,
  onCellValueChange,
}) => {
  const [isChanged, setIsChanged] = useState(false);
  const [value, setValue] = useState<number | null | undefined>(undefined);

  const debouncedValue = useDebounce(value, 1000);

  useEffect(() => {
    if (typeof initialValue === "number") {
      setValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (!isChanged) return;
    if (typeof debouncedValue === "number") {
      onCellValueChange(debouncedValue);
    }
  }, [debouncedValue]);

  const handleChange = (value: number) => {
    setIsChanged(true);
    setValue(value);
  };

  const isError = () => {
    if (!value && value !== undefined) {
      return true;
    }

    if (value < 0) {
      return true;
    }

    return false;
  };

  return (
    <InputNumber
      style={{ width: 80 }}
      value={value}
      fieldError={isError()}
      onChange={(value: number) => handleChange(value)}
    />
  );
};
