// Lib
import { FC, useEffect } from "react";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
// Hooks
import { useViewport } from "hooks";
// Types
import { ProductTag } from "types/products";
// Components
import { Modal } from "components";
import { Input } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";

import { resolver } from "./validation";

const defaultValues = { imageUrl: null, name: "" };

export interface LabelCreateEditForm {
  name: string;
}

interface LabelCreateEditModalProps {
  data: boolean | ProductTag;
  isLoading: boolean;
  onSave: ({
    id,
    formData,
  }: {
    id: string | null;
    formData: LabelCreateEditForm;
  }) => void;
  onClose: () => void;
}

export const LabelCreateEditModal: FC<LabelCreateEditModalProps> = ({
  data,
  isLoading,
  onSave,
  onClose,
}) => {
  const { isMobile } = useViewport();

  const { handleSubmit, reset, control } = useForm<LabelCreateEditForm>({
    defaultValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver,
  });

  const isEdit = data && typeof data !== "boolean";

  useEffect(() => {
    if (typeof data !== "boolean") {
      const { name } = data;

      reset({
        name,
      });
    } else {
      reset(defaultValues);
    }
  }, [data]);

  const onSubmit: SubmitHandler<LabelCreateEditForm> = formData => {
    const id = typeof data !== "boolean" ? data.id : null;

    onSave({ id, formData });
  };

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    reset(defaultValues);
    onClose();
  };

  return (
    <Modal
      open={!!data}
      title={`${isEdit ? "Edit" : "Add"} Label`}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={"name"}
          control={control}
          render={({ field, fieldState }) => (
            <Input
              required
              label="Name"
              placeholder="Enter tag name"
              {...field}
              fieldState={fieldState}
              disabled={isLoading}
            />
          )}
        />

        <FlexContainer
          $fullwidth
          $column={isMobile}
          $justify="flex-end"
          $gap={8}
          $padding="24px 0 0"
        >
          <Button.Heading
            $fullWidth={isMobile}
            disabled={isLoading}
            onClick={handleClose}
          >
            Cancel
          </Button.Heading>

          <Button.Heading
            $fullWidth={isMobile}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Save
          </Button.Heading>
        </FlexContainer>
      </form>
    </Modal>
  );
};
