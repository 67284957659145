// Lib
import styled from "styled-components";
// Icons
import { ArrowDownIcon, InfoIcon } from "icons";
// Theme
import { theme } from "theme";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";

export const Wrapper = styled(FlexContainer)`
  width: 100%;
  padding: 8px 0;
  gap: 24px;
`;

export const ButtonsContainer = styled(FlexContainer)`
  gap: 10px;
  flex-wrap: wrap;

  @media (max-width: 710px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 580px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 433px) {
    grid-template-columns: 1fr;
  }
`;

export const ChannelLinksContaner = styled(FlexContainer)`
  width: 100%;
  gap: 12px;
`;
export const ChannelItem = styled(FlexContainer)`
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px;
  gap: 16px;
  background: ${theme.color.background.lightgray};
  border: 1px solid ${theme.color.borderLight};
  border-radius: ${theme.borderRadius.base};
`;

export const IconContainer = styled(FlexContainer)`
  align-items: center;
  justify-content: center;
  background-color: ${theme.color.background.gray};
  width: 56px;
  height: 56px;
`;

export const ImageContainer = styled.div`
  height: 56px;
  width: 56px;
  border-radius: 50%;
`;

export const ChannelIdContaniner = styled(FlexContainer)`
  gap: 8px;
`;

export const ChannelId = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;
  background: ${theme.color.white};
  border: 1px solid ${theme.color.borderMedium};
  border-radius: ${theme.borderRadius.base};
  font-weight: ${theme.fontWeight.medium};
  cursor: pointer;
`;

export const ChannelLinksTitle = styled(Typography.Title)`
  display: flex;
  gap: 7px;
`;

export const Info = styled(InfoIcon)`
  cursor: pointer;
`;

export const Description = styled(Typography.DescriptionThin)`
  color: ${theme.color.text.tertiary};
`;

export const ArrowDown = styled(ArrowDownIcon)`
  path {
    fill: ${theme.color.text.tertiary};
  }
`;
