// Lib
import { FC } from "react";
// Api
import { useGetOrderDetailsQuery } from "rtkQuery/query/kitchenOrdersAPI";
// Hooks
import { useKitchenSettings, useKitchenOrders, useViewport } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getActiveKitchen } from "rtkQuery/selectors";
// Types
import { ModifierType } from "types/common";
import { EPaymentMethod, OrderStatus } from "types/orders";
// Theme
import { theme } from "theme";
// Icons
import { CallBlackIcon, InfoIcon } from "icons";
// Helpers
import {
  getNextStatus,
  getOrderButtonColor,
  getOrderType,
} from "../../helpers";
// Components
import { InfoItem, Loader, NoItemsContent, OrderDotStatus } from "components";
// Styled
import { ContentBox, FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import {
  CallButton,
  Divider,
  GridDataContainer,
  HeaderButton,
  SelectedOrderContainer,
  SelectedOrderWrapper,
} from "./styled";

import { ButtonTitles } from "../config";

export const SelectedOrder: FC = () => {
  const kitchen = useAppSelector(getActiveKitchen);

  const { width, isMiddleDesktop } = useViewport();

  const isOneColumnContent = width < 930;

  const { displayItemPrices, displayCourierName, manualHandleDelivery } =
    useKitchenSettings();

  const { isUpdateOrderLoading, selectedOrder, handleChangeOrderStatus } =
    useKitchenOrders();

  const { data, isFetching } = useGetOrderDetailsQuery(
    { selectedOrder, siteId: kitchen?.id },
    {
      skip: !selectedOrder,
    },
  );

  const onChangeStatus = (status: OrderStatus) => {
    const next = getNextStatus(status);
    handleChangeOrderStatus({ id: data?.id, next, prev: data?.status });
  };

  const handleMarkAsDelivered = () => {
    handleChangeOrderStatus({
      id: data?.id,
      next: OrderStatus.DELIVERED,
      prev: data?.status,
    });
  };

  const handleCall = (phoneNumber: string) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const buttonText =
    !!data?.status && data.status === OrderStatus.READY_FOR_PICKUP
      ? manualHandleDelivery
        ? ButtonTitles[data?.status]
        : ""
      : ButtonTitles[data?.status];

  const price =
    data?.paymentMethod === EPaymentMethod.FIAT
      ? ` (${data?.totalFiatCentsPrice} KWD)`
      : ` (${data?.totalFmcCentsPrice} FM Coins)`;

  const itemsSummary = `${data?.orderItems?.length} item(s)${
    displayItemPrices ? price : ""
  }`;

  const isShowLoader = isFetching || isUpdateOrderLoading;

  if (!data) {
    return (
      <SelectedOrderWrapper $sideWidth={isMiddleDesktop ? 480 : 280}>
        <SelectedOrderContainer $fullwidth>
          {isShowLoader && <Loader />}

          <NoItemsContent
            Icon={InfoIcon}
            iconCircleBgColor={theme.color.background.secondary}
            message="No order selected"
            description="Please, select and order in the left to see order details"
          />
        </SelectedOrderContainer>
      </SelectedOrderWrapper>
    );
  }

  return (
    <SelectedOrderWrapper $sideWidth={isMiddleDesktop ? 480 : 280}>
      <SelectedOrderContainer $fullwidth>
        {isShowLoader && <Loader />}

        <FlexContainer $column $fullwidth>
          <FlexContainer
            $column={isOneColumnContent}
            $gap={16}
            $justify="space-between"
            style={{ minHeight: "40px" }}
          >
            <FlexContainer $align="center">
              {!!data?.sid && <Typography.H2>{`#${data?.sid}`}</Typography.H2>}

              {!!data?.status && <OrderDotStatus status={data?.status} />}
            </FlexContainer>

            <FlexContainer $align="center" $gap={8}>
              {!!buttonText && (
                <HeaderButton
                  disabled={isShowLoader}
                  $bgColor={getOrderButtonColor(data?.status)}
                  onClick={() => onChangeStatus(data?.status)}
                >
                  {buttonText}
                </HeaderButton>
              )}

              {data?.status !== OrderStatus.DELIVERED &&
                manualHandleDelivery && (
                  <HeaderButton
                    disabled={isShowLoader}
                    $bgColor={getOrderButtonColor(OrderStatus.DELIVERED)}
                    onClick={handleMarkAsDelivered}
                  >
                    Mark as Delivered
                  </HeaderButton>
                )}
            </FlexContainer>
          </FlexContainer>

          <ContentBox $column $margin="24px 0 0">
            <GridDataContainer>
              {!!data?.customerName && (
                <InfoItem
                  type="profile"
                  label="Customer info"
                  info={data?.customerName}
                />
              )}

              <InfoItem type="store" label="Store" info="Forevermore" />
            </GridDataContainer>

            {!!data?.deliveryAddress && (
              <InfoItem
                type="location"
                label="Address"
                info={data?.deliveryAddress}
              />
            )}

            <Divider />

            <GridDataContainer>
              <InfoItem type="schedule" label="Driver ETA" info="16:22" />

              {displayCourierName && !!data?.courierInfo ? (
                <InfoItem
                  type="delivery"
                  label="Driver Info"
                  info={data?.courierInfo}
                />
              ) : null}
            </GridDataContainer>
          </ContentBox>

          {!!data?.note && (
            <ContentBox $column $margin="16px 0 0">
              <InfoItem type="note" label="Order note" info={data?.note} />
            </ContentBox>
          )}

          <ContentBox $column $margin="16px 0 0">
            <FlexContainer $align="center" $justify="space-between">
              <Typography.H2>Receipt Details</Typography.H2>

              <Typography.Title>{itemsSummary}</Typography.Title>
            </FlexContainer>

            <FlexContainer $justify="space-between" $column>
              {!!data?.orderItems?.length &&
                data?.orderItems?.map(item => (
                  <FlexContainer
                    key={item.id}
                    $column
                    $justify="space-between"
                    $margin="12px 0 0"
                  >
                    <InfoItem
                      type="asText"
                      label={`${item?.quantity}`}
                      info={item?.productName}
                    />

                    {!!item?.modifiers?.length &&
                      item?.modifiers.map(modifier =>
                        modifier.product.modifierType === ModifierType.Addon ? (
                          <InfoItem
                            key={modifier.id}
                            type="addon"
                            info={`${modifier?.quantity || 1} x ${
                              modifier?.product.name
                            }`}
                          />
                        ) : (
                          <InfoItem
                            key={modifier.id}
                            type="modifier"
                            info={modifier?.product.name}
                          />
                        ),
                      )}

                    {!!item?.remark && (
                      <FlexContainer $margin="5px 0 0 52px">
                        <Typography.Description>
                          Note:{" "}
                          <Typography.DescriptionThin as="span">
                            {item?.remark}
                          </Typography.DescriptionThin>{" "}
                        </Typography.Description>
                      </FlexContainer>
                    )}
                  </FlexContainer>
                ))}
            </FlexContainer>
          </ContentBox>

          <ContentBox $column $margin="16px 0">
            <FlexContainer $align="center" $justify="space-between">
              <Typography.H2>Delivery Information</Typography.H2>

              <Typography.Title>{itemsSummary}</Typography.Title>
            </FlexContainer>

            <GridDataContainer>
              <InfoItem
                type="menu"
                label="Order type"
                info={getOrderType(data?.type)}
              />

              <InfoItem
                type="location"
                label="Address"
                info={data?.deliveryAddress}
              />

              {displayCourierName && !!data?.courierInfo && (
                <InfoItem
                  type="menu"
                  label="Courier info"
                  info={data?.courierInfo}
                />
              )}

              <div>
                <InfoItem
                  type="profile"
                  label="Customer info"
                  info={`${data?.customerName} ${data?.email || ""}`}
                />

                {!!data?.phoneNumber && (
                  <CallButton
                    type="primary"
                    onClick={() => handleCall(data?.phoneNumber)}
                  >
                    <CallBlackIcon fill={theme.color.white} />
                    <span
                      style={{ marginLeft: 8 }}
                    >{`Call ${data?.phoneNumber}`}</span>
                  </CallButton>
                )}
              </div>
            </GridDataContainer>
          </ContentBox>
        </FlexContainer>
      </SelectedOrderContainer>
    </SelectedOrderWrapper>
  );
};
