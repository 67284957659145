// Lib
import { FC } from "react";
import { CodeBlock, a11yLight } from "react-code-blocks";
import { Skeleton } from "antd";
// Styled
import { Typography } from "styled/Typography";
import { ContentBox } from "styled/Box";
import { CodeWrapper } from "./styled";

interface DetailsProps {
  isLoading: boolean;
  data: Record<string, string | number>;
}

export const Details: FC<DetailsProps> = ({ isLoading, data }) => {
  return (
    <ContentBox $column $gap={20}>
      <Typography.H2>Details</Typography.H2>

      {isLoading ? (
        <Skeleton active />
      ) : (
        <CodeWrapper>
          <CodeBlock
            language="json"
            showLineNumbers
            theme={a11yLight}
            text={JSON.stringify(data, null, 2)}
          />
        </CodeWrapper>
      )}
    </ContentBox>
  );
};
