// Lib
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
// Api
import { useGetOperationReportQuery } from "rtkQuery/query/operationReportsAPI";
// Hooks
import { useNotification } from "hooks";
// Actions
// Selectors
// Types
// Theme
// Constants
import { ADMIN_ROUTES, queryParams } from "consts";
// Helpers
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
// Layouts
// Components
import { ArrowBackLink, Table } from "components";
import { Summary, Details } from "../components";
// Styled
import { Typography } from "styled/Typography";
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";

import { menuItemsColumns, insertedItemsColumns } from "./config";

export const OperationReportDetails: FC = () => {
  const { id } = useParams();

  const { openNotification } = useNotification();

  const { data, isFetching, error } = useGetOperationReportQuery(
    { id },
    queryParams,
  );

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const isDetails = !!data?.details && Object.entries(data?.details)?.length;

  const isMenuItems = !!data?.data?.menuItems?.length;

  const isInsertedItems = !!data?.data?.insertedItems?.length;

  return (
    <FlexContainer $fullwidth $column $gap={24}>
      <PageHeadingContainer>
        <PageTitleContainer $column $gap={16}>
          <ArrowBackLink
            title="Back to Operations Reports"
            path={ADMIN_ROUTES.OPERATIONS_REPORT.path}
          />

          <Typography.H1>Operations Report</Typography.H1>
        </PageTitleContainer>
      </PageHeadingContainer>

      <Summary isLoading={isFetching} data={data} />

      {!isFetching && isMenuItems && (
        <Table
          columns={menuItemsColumns}
          header={{ totalCount: data?.data?.menuItems?.length }}
          dataSource={data?.data?.menuItems}
        />
      )}

      {!isFetching && isInsertedItems && (
        <Table
          columns={insertedItemsColumns}
          header={{ totalCount: data?.data?.insertedItems?.length }}
          dataSource={data?.data?.insertedItems}
        />
      )}

      {isDetails && <Details isLoading={isFetching} data={data.details} />}
    </FlexContainer>
  );
};
