// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  GetInventoryDetailsRequest,
  GetInventoryDetailsResponse,
  GetInventoryRequest,
  GetInventoryResponse,
  GetKitchenInventoryDetailsRequest,
  GetKitchenInventoryRequest,
  UpdateInventoryRequest,
  UpdateKitchenInventoryRequest,
} from "types/inventory";
// Helpers
import { inventoryUrlBuilder } from "./urlBuilder/inventory";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";

export const inventoryAPI = createApi({
  reducerPath: "inventoryAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: build => ({
    getInventory: build.query<GetInventoryResponse, GetInventoryRequest>({
      query: data => ({
        url: inventoryUrlBuilder.getInventory(data),
        method: "GET",
      }),
    }),
    getKitchenInventory: build.query<
      GetInventoryResponse,
      GetKitchenInventoryRequest
    >({
      query: data => ({
        url: inventoryUrlBuilder.getKitchenInventory(data),
        method: "GET",
      }),
    }),
    getInventoryItemDetails: build.query<
      GetInventoryDetailsResponse,
      GetInventoryDetailsRequest
    >({
      query: data => ({
        url: inventoryUrlBuilder.getInventoryDetails(data),
        method: "GET",
      }),
    }),
    getKitchenInventoryItemDetails: build.query<
      GetInventoryDetailsResponse,
      GetKitchenInventoryDetailsRequest
    >({
      query: data => ({
        url: inventoryUrlBuilder.getKitchenInventoryDetails(data),
        method: "GET",
      }),
    }),
    updateInventory: build.mutation<void, UpdateInventoryRequest>({
      query: ({ menuId, menuItemId, data }) => ({
        url: inventoryUrlBuilder.updateInventory(menuId, menuItemId),
        method: "PATCH",
        data,
      }),
    }),
    updateKitchenInventory: build.mutation<void, UpdateKitchenInventoryRequest>(
      {
        query: ({ menuId, menuItemId, kitchenId, data }) => ({
          url: inventoryUrlBuilder.updateKitchenInventory({
            kitchenId,
            menuId,
            menuItemId,
          }),
          method: "PATCH",
          data,
        }),
      },
    ),
  }),
});

export const {
  useGetInventoryQuery,
  useLazyGetInventoryQuery,
  useGetKitchenInventoryQuery,
  useUpdateInventoryMutation,
  useUpdateKitchenInventoryMutation,
  useGetInventoryItemDetailsQuery,
  useGetKitchenInventoryItemDetailsQuery,
} = inventoryAPI;
