// Lib
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
// Api
// Hooks
import { useExport, useNotification, useTable, useViewport } from "hooks";
// Actions
// Selectors
// Types
import { ETable } from "types/tableFilters";
// Theme
import { theme } from "theme";
// Constants
// Helpers
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { FileUploadIcon } from "icons";
// Layouts
// Components
import { Export, Pagination, Table } from "components";
import { Filters } from "../../components";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import {
  PageTitleContainer,
  PageWrapper,
  TableShadowContainer,
} from "./styled";

import { columns } from "./config";

import { mockedLocationsData } from "../../mockData";

export const locationAnalytics = mockedLocationsData.map((location, i) => ({
  ...location,
  order: i % 2 == 0 ? "591 (87%)" : "87 (13%)",
  revenue: "KWD (4,865.800)",
  grossSales: "KWD (4,865.800)",
  avgOrderValue: "KWD 11.794",
}));

export const AnalyticsLocations: FC = () => {
  const navigate = useNavigate();
  const { isDesktop } = useViewport();
  const { downloadCSV } = useExport();

  const { openNotification } = useNotification();

  const {
    search,
    setSearch,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  } = useTable({ name: ETable.AnalyticsLocations });

  // const { data, isLoading: isOrdersLoading } = useGetOrdersQuery({ limit: 10 }, { skip: false });

  const getExportData = async (): Promise<Record<string, unknown>[]> => {
    try {
      //request here
      const data = [];

      return data;
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const onRow = useCallback(record => {
    return {
      onClick: () => {
        navigate(`/loop/analytics/locations-analytics/${record?.id}`);
      },
    };
  }, []);

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer $fullwidth $column $grow={1} $padding="0 0 32px">
        <PageTitleContainer>
          <Typography.H1>Locations Analytics</Typography.H1>

          <Filters skipLocation skipOrderStatus />
        </PageTitleContainer>

        <TableShadowContainer>
          <Table
            shadow={false}
            withPagination={true}
            dataSource={locationAnalytics}
            columns={columns}
            header={{
              leftTitle: "Summary",
              search: { value: search, setValue: setSearch },
              suffix: isDesktop ? (
                <Export
                  type="Base"
                  fileName="locations"
                  isLoading={false}
                  isDataLoading={false}
                  isDisabled={false}
                  columns={columns}
                  getExportData={getExportData}
                />
              ) : (
                <Button.SquaredIcon
                  icon={<FileUploadIcon fill={theme.color.text.tertiary} />}
                  onClick={() =>
                    downloadCSV({
                      fileName: "product_sales",
                      isDataLoading: false,
                      columns: columns,
                      getExportData: getExportData,
                    })
                  }
                />
              ),
            }}
            onRow={onRow}
          />

          <Pagination
            padding="12px 16px"
            pageSize={pageSize}
            currentPage={currentPage}
            setPageSize={setPageSize}
            totalItems={locationAnalytics.length}
            setCurrentPage={setCurrentPage}
          />
        </TableShadowContainer>
      </FlexContainer>
    </PageWrapper>
  );
};
