// Lib
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Api
import { useGetCustomersQuery } from "rtkQuery/query/customersAPI";
// Hooks
import { useNotification, useTable, useViewport } from "hooks";
// Types
import { TablePaginationConfig } from "antd/es/table";
import { FilterValue } from "antd/es/table/interface";
import { ETable } from "types/tableFilters";
// Theme
// Constants
import { queryParams } from "consts";
// Icons
import { DescriptionBlackIcon } from "icons";
// Helpers
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Pagination, Table } from "components";
// Styled
import { FlexContainer, PageWrapper } from "styled/Box";
import { Typography } from "styled/Typography";

import { columns } from "./config";

export const Customers: FC = () => {
  const navigate = useNavigate();
  const { openNotification } = useNotification();

  const { isDesktop } = useViewport();

  const {
    orderDirection,
    orderBy,
    currentPage,
    pageSize,
    search,
    debouncedSearch,
    setPageSize,
    setCurrentPage,
    setSearch,
    handleSort,
  } = useTable({ name: ETable.Customers });

  const {
    data: customersData,
    isFetching: isLoading,
    error: customersError,
  } = useGetCustomersQuery(
    {
      query: {
        page: currentPage,
        size: pageSize,
        orderBy,
        orderDirection,
        search: debouncedSearch,
      },
    },
    queryParams,
  );

  useEffect(() => {
    if (customersError) {
      errorHandler({ error: customersError, openNotification });
    }
  }, [customersError]);

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: any,
  ) => {
    handleSort(sorter?.columnKey, sorter?.order);
  };

  const onRow = record => {
    return {
      onClick: () => {
        navigate(`/loop/customers/${record.id}`);
      },
    };
  };

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $grow={1}
          $padding={isDesktop && "0 0 32px"}
        >
          <FlexContainer
            $padding="0 0 24px"
            $align="center"
            $justify="space-between"
          >
            <Typography.H1>Customers</Typography.H1>
          </FlexContainer>

          <Table
            isLoading={isLoading}
            withPagination={!isDesktop}
            rowKey={record => record.id}
            dataSource={customersData?.data || []}
            columns={columns}
            empty={{
              icon: DescriptionBlackIcon,
              title: "No customer to show.",
              description: "Try changing sections or change the filters",
            }}
            header={{
              search: {
                placeholder: "Search name",
                value: search,
                setValue: setSearch,
              },
              totalCount: customersData?.total,
            }}
            onChange={handleTableChange}
            onRow={onRow}
          />
        </FlexContainer>

        <Pagination
          onPage
          padding="12px 16px"
          pageSize={pageSize}
          currentPage={currentPage}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          showSizeChanger={!isDesktop}
          totalItems={customersData?.total}
        />
      </PageWrapper>
    </>
  );
};
