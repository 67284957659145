import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const EditIcon: FC<SvgIconConstituentValues> = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_8329_6454)">
      <path
        d="M2 11.4997V13.9997H4.5L11.8733 6.62638L9.37333 4.12638L2 11.4997ZM13.8067 4.69305C14.0667 4.43305 14.0667 4.01305 13.8067 3.75305L12.2467 2.19305C11.9867 1.93305 11.5667 1.93305 11.3067 2.19305L10.0867 3.41305L12.5867 5.91305L13.8067 4.69305Z"
        fill={fill || "black"}
      />
    </g>
    <defs>
      <clipPath id="clip0_8329_6454">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
