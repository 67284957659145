// Lib
import { FC, memo } from "react";
// Api
import { ListModalProps } from "./types";
// Helpers
import { generateUniqueId } from "helpers/dataHelpers";
// Components
import { Modal } from "components";
// Styled
import { List } from "./styled";

export const ListModal: FC<ListModalProps> = memo(({ data, onClose }) => {
  const id = generateUniqueId();
  return (
    <Modal
      open={!!data}
      title={!!data && data.title}
      width={400}
      onClose={onClose}
    >
      <List>
        {!!data && data.list.map(item => <li key={`${item}-${id}`}>{item}</li>)}
      </List>
    </Modal>
  );
});

ListModal.displayName = "ListModal";
