export enum Role {
  LoopAdmin = "fd811aee-da4b-48de-b346-2f8568e45fd9",
  Admin = "40950c79-2fde-42dd-881a-22e0c6c4d3b6",
  Manager = "2d8580a2-3930-4f86-a84c-23e17eceb0f4",
  AccountManager = "4febcedf-e5ce-400c-b188-54aa646a86a7",
}

export const roleLabel = {
  [Role.AccountManager]: "Account Manager",
  [Role.Manager]: "PWA Employee",
  [Role.Admin]: "Company Admin",
  [Role.LoopAdmin]: "Loop Admin",
};
