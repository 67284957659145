// Lib
import { FC, useState, useEffect, useRef } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Hooks
import { useViewport } from "hooks";
// Types
import { MenuCategoryItemType, MenuItemFormTypes } from "types/menus";
import { Modifier } from "types/modifiers";
// Helpers
import { createDefaultValues } from "./helpers";
// Icons
import { TrashIcon } from "icons";
// Layouts
// Components
import { Accordion, Modal } from "components";
import { ImageUpload, Input, NumericInput, TextArea } from "components/Form";
import { AddModifiersModal } from "../AddModifiersModal";
// Styled
import { Typography } from "styled/Typography";
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import {
  DeleteButton,
  InputsContainer,
  ModifierContainer,
  ModifierItem,
} from "./styled";

import { resolver } from "./validation";

interface CategoryItemFormProps {
  open: boolean;
  locationId: string;
  itemData: boolean | { categoryId: string; item: MenuCategoryItemType };
  handleClose: () => void;
  handleSave: (data: MenuItemFormTypes, modifiers: Modifier[]) => void;
  handleDelete: () => void;
}

export const CategoryItemForm: FC<CategoryItemFormProps> = ({
  open,
  locationId,
  itemData,
  handleClose,
  handleSave,
  handleDelete,
}) => {
  const { isMobile, isLargeMobile } = useViewport();

  const [keys, setKeys] = useState<string>("");
  const [itemModifiers, setItemModifiers] = useState<Modifier[]>([]);
  const [modifiersModal, setModifiersModal] = useState(false);

  const { handleSubmit, control, watch, setValue, reset } =
    useForm<MenuItemFormTypes>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolver,
    });

  const imageValue = watch("image");

  useEffect(() => {
    if (!itemData) {
      return;
    }
    const defaultValues = createDefaultValues(itemData);

    if (defaultValues) {
      setValue("name", defaultValues.name);
      setValue("description", defaultValues.description);
      setValue("price", defaultValues.price);
      setValue("image", defaultValues.image);
      setValue("plu", defaultValues.plu);
      setValue("tax", defaultValues.tax);
      setItemModifiers(defaultValues.modifiers);
    }
  }, [itemData]);

  useEffect(() => {
    if (!open) {
      setKeys("");
    }
  }, [open]);

  const onSubmit: SubmitHandler<MenuItemFormTypes> = data => {
    handleSave(data, itemModifiers);
  };

  const setImage = (image: string) => {
    setValue("image", image);
  };

  const handleCloseForm = () => {
    handleClose();
    reset();
    setItemModifiers([]);
    setKeys("");
  };

  const onModifierDelete = (id: string) => {
    setItemModifiers(prev => prev.filter(el => el.id !== id));
  };

  const productId =
    typeof itemData === "object" ? itemData?.item?.product?.id : "";

  const items = [
    {
      key: "1",
      label: (
        <Typography.Title>
          Addons & Modifiers ({itemModifiers?.length})
        </Typography.Title>
      ),
      children: (
        <>
          <ModifierContainer>
            {itemModifiers?.map(({ id, name }) => (
              <ModifierItem key={id}>
                <span>{name}</span>
                <Button.Native $danger onClick={() => onModifierDelete(id)}>
                  <TrashIcon />
                </Button.Native>
              </ModifierItem>
            ))}
          </ModifierContainer>

          <Button.Form $fullWidth onClick={() => setModifiersModal(true)}>
            Add Addons & Modifiers
          </Button.Form>
        </>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Edit Menu Item"
        width={800}
        open={open}
        onClose={handleCloseForm}
      >
        <FlexContainer
          as="form"
          $column
          $gap={24}
          $padding="0 0 4px"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FlexContainer $gap={6} $fullwidth $column>
            <FlexContainer $gap={16} $column={isLargeMobile}>
              {!!imageValue && (
                <ImageUpload value={imageValue} disabled onChange={setImage} />
              )}

              <InputsContainer $column $gap={16} $withImage={!!imageValue}>
                <Controller
                  name={"name"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      required
                      label="Name"
                      disabled
                      {...field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  name={"plu"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      disabled
                      label="PLU"
                      {...field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  name={"description"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextArea
                      label="Descriptions"
                      disabled
                      {...field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <FlexContainer $fullwidth $gap={16}>
                  <Controller
                    name={"price"}
                    control={control}
                    render={({ field, fieldState }) => (
                      <NumericInput
                        label="Price* (KWD)"
                        disabled
                        {...field}
                        fieldState={fieldState}
                        fieldChange={str => setValue("price", str)}
                      />
                    )}
                  />
                  <Controller
                    name={"tax"}
                    control={control}
                    render={({ field, fieldState }) => (
                      <NumericInput
                        label="Delivery tax* (%)"
                        disabled
                        {...field}
                        fieldState={fieldState}
                        fieldChange={str => setValue("tax", str)}
                      />
                    )}
                  />
                </FlexContainer>
              </InputsContainer>
            </FlexContainer>

            <Accordion
              nested
              activeKey={keys}
              items={items}
              onChange={(value: string) => setKeys(value)}
            />
          </FlexContainer>

          <FlexContainer
            $column={isMobile}
            $align="center"
            $justify="space-between"
            $gap={8}
          >
            <Button.Heading $fullWidth={isMobile} onClick={handleCloseForm}>
              Cancel
            </Button.Heading>

            <FlexContainer
              $column={isMobile}
              $fullwidth={isMobile}
              $align="center"
              $justify="flex-end"
              $gap={8}
            >
              <DeleteButton $fullWidth={isMobile} onClick={handleDelete}>
                Delete item
              </DeleteButton>

              <Button.Heading
                $fullWidth={isMobile}
                type="primary"
                htmlType="submit"
              >
                Save Edit
              </Button.Heading>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </Modal>

      <AddModifiersModal
        open={modifiersModal}
        locationId={locationId}
        productId={productId}
        handleClose={() => setModifiersModal(false)}
        selectedModifiers={itemModifiers}
        handleAddModifiers={(modifiers: Modifier[]) =>
          setItemModifiers(modifiers)
        }
      />
    </>
  );
};
