import { useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";

export const useSocket = ({
  serverUrl,
  active,
}: {
  serverUrl?: string;
  active: boolean;
}): Socket | null => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (!active) {
      return;
    }

    const newSocket = io(serverUrl || process.env.REACT_APP_BASE_URL);
    setSocket(newSocket);

    const pingInterval = setInterval(() => {
      if (newSocket.connected) {
        newSocket.emit("ping");
      }
    }, 2000);

    newSocket.on("pong", () => {
      console.log("Received pong");
    });

    return () => {
      clearInterval(pingInterval);

      newSocket.disconnect();
    };
  }, [serverUrl, active]);

  return socket;
};
